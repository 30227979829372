import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LeadCreationService } from './leadcreation.service';
import { LeadCreationModel, LeadFollowupModel } from './leadcreation.model';
import { Employee } from '../../configuration/usermanage/user.model';
import { UserService } from '../../configuration/usermanage/user.service';
import { ViewChild, ElementRef } from '@angular/core';
import * as XLSX from 'xlsx';
import { getTime } from 'ngx-bootstrap/chronos/utils/date-getters';
import { ReportAttribute } from 'src/app/admin/academic/studentmanagement/report.model';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { environment } from '../../../../environments/environment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import { DatePipe } from '@angular/common';


declare var $: any;
@Component({
  selector: 'app-leadcreation',
  templateUrl: './leadcreation.component.html',
  styleUrls: ['./leadcreation.component.css']
})
export class LeadcreationComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('sd') sdate: ElementRef;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  leadCreationForm: UntypedFormGroup;
  leadFollowupForm: UntypedFormGroup;
  leadcreationServiceList: LeadCreationModel[] = [];
  leadFollowuplist: LeadFollowupModel[] = [];
  leadCreationFilteredList: LeadCreationModel[] = [];
  leadFollowupFilteredList: LeadFollowupModel[] = [];
  leadList: LeadCreationModel[] = [];
  employeeList: Employee[] = [];
  employeeId: string;
  submitted = false;
  submittedFollowup = false;
  leadId: string;
  LeadID: string;
  demoScheduledDate: string;
  demoScheduleTime: string;
  nextFollowupDate: string;
  leadDate: string;
  demoFollowupScheduledDate: string;
  demoFollowScheduleTime: string;
  isDemoScheduled = false;

  statusDrp = 'All';
  statusDemoDrp = 'All';
  dateFrom: string;
  dateTo: string;
  /*Institution details*/
  EnquiredBy: string;
  InstitutionName: string;
  InstitutionId: string;
  InstitutionType: string;
  InstitutionCode: string;
  LeadDate: string;
  Location: string;
  AffiliationType: string;
  AffiliationID: string;
  Medium: string;
  ContactPerson: string;
  MobileNo: string;
  EmailID: string;
  DemoDate: string;
  DemoTime: string;
  By: string;
  LeadCode: string;
  ScheduledDate: string;
  Nextfollowup: string;
  
  /*    End     */
  IsShow: any = false;
  isChecked: any = false;
  chkReceive: any = true;
  chkNoResponse: any = false;
  // public dateFrom: Date;
  // public dateTo:string;
  // public statusDrp:string;
  constructor(
    public leadCreationService: LeadCreationService,
    public userService: UserService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private datePipe:DatePipe
  ) { }


  ListEmployee() {
    this.userService.getAll().subscribe((data: Employee[]) => {
      console.log(data);
      this.employeeList = data;
    }); 
  }

  leadlist() {

    this.leadCreationService.getAll().subscribe((data: LeadCreationModel[]) => {
      console.log(data);
      this.leadCreationFilteredList = this.leadcreationServiceList = data;
    });
  }
  leadFollowupList(leadId: number) {
    this.leadCreationService.getAllFollowup(leadId).subscribe((data: LeadFollowupModel[]) => {
      this.leadFollowupFilteredList = this.leadFollowuplist = data;
    });

  }
  generateLeadId() {
    this.leadCreationService.generateLeadCode().subscribe((data: string) => {
      console.log(data);
      this.LeadID = data;
    });
  }

  createLead() {
    debugger;
    this.submitted = true;
    // this.leadFollowupForm.get("leadcode").patchValue(this.LeadID);
    // stop here if form is invalid
    
    this.leadCreationForm.get('leadDate').patchValue(this.leadDate);
    if (this.demoScheduledDate !== '' && this.demoScheduledDate !== undefined) {
      this.leadCreationForm.get('demoDate').patchValue(this.demoScheduledDate);
      this.leadCreationForm.get('demobyemployeeid').patchValue(this.employeeId);
    }
    this.leadCreationForm.get('source').patchValue('Offline');
    this.leadCreationForm.get('demoStatus').patchValue('Pending');
    this.leadCreationForm.get('leadStatus').patchValue('Active');
    // console.log(this.demoScheduledDate);
    if (this.leadCreationForm.invalid) {
      return;
    }

    if (this.leadCreationForm.get('id').value === 0||this.leadCreationForm.get('id').value === '') {
      this.leadCreationForm.get('leadCode').patchValue(this.LeadID);
      this.leadCreationService.create(this.leadCreationForm.value).subscribe(res => {
        // this.ListServices();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        this.leadlist();
        // close your modal
        this.closeModal();
      });
    } else {
      this.leadCreationService.update(this.leadCreationForm.get('id').value, this.leadCreationForm.value).subscribe(res => {
        // this.ListServices();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        this.leadlist();
        // close your modal
        this.closeModal();
      });
    }

  }

  filterData() {
    this.leadList=[];
    this.leadCreationFilteredList=[];
    $('#dateFrom').datepicker().on('changeDate', (e) => {

      self.dateFrom = e.date;

    });
    $('#dateTo').datepicker().on('changeDate', (e) => {

      self.dateTo = e.date;

    });
    debugger;
    const self = this;
    const drpValue = this.statusDrp;
    const drpDemoValue = this.statusDemoDrp;
    const fromDate = this.dateFrom;
    const toDate = this.dateTo;
    const resultDateFilteredList: any = []
    let startDt: Date;
    let endDt: Date;
    this.leadList = this.leadcreationServiceList;
    this.leadCreationFilteredList = this.leadcreationServiceList;
    if (typeof this.dateFrom !== 'undefined' && typeof this.dateTo !== 'undefined') {
      startDt = new Date(fromDate);
      endDt = new Date(toDate);
    }
    if (drpValue === 'All' || drpValue === '' || drpValue === 'Status' || drpValue === 'undefined') {
      if (typeof this.dateFrom !== 'undefined' && typeof this.dateTo !== 'undefined') {
        this.leadList = this.leadList.filter(d => {
          const date = new Date(d.createdDate);
          return (startDt.getTime() <= date.getTime() && endDt.getTime() >= date.getTime());
        });
      }
      this.leadCreationFilteredList = this.leadList;
    } else {

      this.leadList = this.leadCreationFilteredList.filter((leadlist) => {
        return leadlist.leadStatus === drpValue;
      });
      if (typeof this.dateFrom !== 'undefined' && typeof this.dateTo !== 'undefined') {
        this.leadList = this.leadList.filter(d => {
          const date = new Date(d.createdDate);
          return (startDt.getTime() <= date.getTime() && endDt.getTime() >= date.getTime());
        });
      }
      this.leadCreationFilteredList = this.leadList;
    }
    if (drpDemoValue === 'All' || drpDemoValue === '' || drpDemoValue === 'Status' || drpDemoValue === 'undefined') {
      if (typeof this.dateFrom !== 'undefined' && typeof this.dateTo !== 'undefined') {
        this.leadList = this.leadCreationFilteredList.filter(d => {
          const date = new Date(d.createdDate);
          return (startDt.getTime() <= date.getTime() && endDt.getTime() >= date.getTime());
        });
      }
    this.leadCreationFilteredList = this.leadList;
    } else {
      this.leadList = this.leadCreationFilteredList.filter((leadlist) => {
        return leadlist.demoStatus === drpDemoValue;
      });
      if (typeof this.dateFrom !== 'undefined' && typeof this.dateTo !== 'undefined') {
        this.leadList = this.leadCreationFilteredList.filter(d => {
          const date = new Date(d.createdDate);
          return (startDt.getTime() <= date.getTime() && endDt.getTime() >= date.getTime());
        });
      }
      this.leadCreationFilteredList = this.leadList;
    }

    
  }
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    debugger;
    let leadTempList=this.leadcreationServiceList;
    //this.leadCreationFilteredList=this.leadcreationServiceList;
    //console.log(this.leadcreationServiceList);
    if (keyword === '') {
      // this.leadList = this.leadcreationServiceList;
       leadTempList=this.leadcreationServiceList;

      this.filterData();
    } else {
      leadTempList = this.leadcreationServiceList.filter(d=>
        
          d.leadCode.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
          (d.source !== null && d.source.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())) ||
          (d.mobileNo !== null && d.mobileNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())) ||
          (d.location !== null && d.location.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())) ||
          d.institutionName.toLowerCase().includes(keyword.toLocaleLowerCase())
          
      );
      /*leadTempList=this.leadcreationServiceList.filter(d=>d.leadCode.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()));
      leadTempList=this.leadcreationServiceList.filter(d=>d.source !== null ? d.source.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()): '');
      leadTempList=this.leadcreationServiceList.filter(d=>d.mobileNo !== null ? d.mobileNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) : '');
      leadTempList=this.leadcreationServiceList.filter(d=>d.location !== null ? d.location.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) : '');
      leadTempList=this.leadcreationServiceList.filter(d=>d.institutionName.toLowerCase().includes(keyword.toLocaleLowerCase()));*/

      
       this.leadCreationFilteredList = leadTempList;
    }
  }
  onStatusChange(value) {
    this.statusDrp = value;
    this.filterData();
  }
  onDemoStatusChange(value) {
    debugger;
    this.statusDemoDrp = value;
    this.filterData();
  }
  get f() { return this.leadCreationForm.controls; }
  get fl() { return this.leadFollowupForm.controls; }

  resetForm() {
    this.submitted = false;
    const self = this;
    this.leadCreationForm = this.fb.group({
      id: [0],
      leadCode: [self.LeadID],
      leadDate: [self.formatDate(new Date)],
      enquiredBy: ['', Validators.required],
      institutionName: ['', Validators.required],
      location: ['', Validators.required],
      institutionType: ['', Validators.required],
      affiliationType: ['', Validators.required],
      affiliationCode: [''],
      medium: ['', Validators.required],
      contactPerson: [''],
      mobileNo: [''],
      emailId: [''],
      demoTime: [''],
      demoDate: [''],
      demobyemployeeid: [''],
      remarks: [''],
      statusDrp: [''],
      demoStatus: [''],
      leadStatus: [''],
      source: ['']
    });

  }
  resetFollowupForm() {
    this.submittedFollowup = false;
    this.isChecked = false;   
    this.IntializeFollowUpform();

  }

  onScheduleChange(event: any) {
    this.isDemoScheduled = event.target.checked;
  }

  getInstitutionDetails(id: number) {
    this.leadCreationService.getById(id).subscribe((data: LeadCreationModel) => {
      this.EnquiredBy = data.enquiredBy;
      this.InstitutionName = data.institutionName;
      this.InstitutionType = data.institutionType;
      this.InstitutionCode = data.leadCode;
      this.LeadDate = data.leadDate;
      this.Location = data.location;
      this.AffiliationType = data.affiliationType;
      this.AffiliationID = data.affiliationCode;
      this.Medium = data.medium;
      this.ContactPerson = data.contactPerson;
      this.MobileNo = data.mobileNo;
      this.EmailID = data.emailId;
      this.DemoDate = data.demoDate;
      this.DemoTime = data.demoTime;
      this.By = data.demoByEmployeeId;
      // });

    });
  }
  getFollowupdetails(id: number) {
    this.leadId = id.toString();
    this.leadCreationService.getById(id).subscribe((data: LeadCreationModel) => {
      console.log(data);
    // this.leadFollowupForm.get('callStatus').patchValue(data.callStatus);
      this.EnquiredBy = data.enquiredBy;
      this.InstitutionName = data.institutionName;
      this.InstitutionType = data.institutionType;
      this.LeadCode = data.leadCode;
      this.LeadDate = data.leadDate;
      this.Location = data.location;
      this.AffiliationType = data.affiliationType;
      this.AffiliationID = data.affiliationCode;
      this.Medium = data.medium;
      this.ContactPerson = data.contactPerson;
      this.MobileNo = data.mobileNo;
      this.EmailID = data.emailId;
      this.DemoDate = data.demoDate;
      this.DemoTime = data.demoTime;
      this.By = data.demoByEmployeeId;
      this.leadFollowupList(id);
    });

  }
  /*Lead followup*/
  createLeadFollowup() {
    debugger;
    //alert("createLeadFollowup");
    this.submittedFollowup = true;
    this.leadFollowupForm.get('leadId').patchValue(this.leadId);
    this.leadFollowupForm.get('demoScheduledDate').patchValue(this.demoFollowupScheduledDate);
    this.leadFollowupForm.get('nextFollowupDate').patchValue(this.Nextfollowup);
    this.leadFollowupForm.get('isDemoScheduled').patchValue(this.isDemoScheduled);

  
    
    if (this.leadFollowupForm.value.isDemoScheduled==true || this.leadFollowupForm.value.followupStatus === 'Re-Schedule')  {

      if (this.leadFollowupForm.value.demoScheduledDate == null || this.leadFollowupForm.value.demoScheduledTime == null ) {
       this.toastr.warning('Please select valid date and time', 'Warning!', {
         timeOut: 3000
       });
       return;
      }
      this.leadFollowupForm.get('demoStatus').patchValue('Scheduled');
    }

    if (this.leadFollowupForm.invalid) {
      return;
    }
    console.log(this.leadFollowupForm.value);    
    this.leadCreationService.createFollowup(this.leadFollowupForm.value).subscribe(res => {
      // this.ListServices();
      this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
        timeOut: 3000
      });
      this.submittedFollowup = false;
      this.resetFollowupForm();
      this.leadFollowupList(parseInt(this.leadId, 0));
      this.leadlist();
      // close your modal
      this.leadFollowupForm.reset();
      //this.closeModal();
    });

  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('-');
  }
  checkValue(event: any) {
    this.IsShow = true;

  }

  ngOnInit(): void {
    const self = this;    
    $('#scheduledDate').datepicker().on('changeDate', (e) => {
      self.demoScheduledDate = this.formatDate(e.date);
    });
    $('#nextFollowupDate').datepicker().on('changeDate', (e) => {
      self.Nextfollowup = this.formatDate(e.date);
    });
    $('#leaddate').datepicker().on('changeDate', (e) => {
      self.leadDate = this.formatDate(e.date);
    });
    $('#demoScheduledDate').datepicker().on('changeDate', (e) => {
      self.demoFollowupScheduledDate = this.formatDate(e.date);
    });

    $('#dateFrom').datepicker().on('changeDate', (e) => {
      self.dateFrom = e.date;
    });
    $('#dateTo').datepicker().on('changeDate', (e) => {
      self.dateTo = e.date;
      console.log("ToDate : + "+e.date);
    });

    $('.select2').select2();
    $('.select2').on('change', function() {
        self.employeeId = $(this).val();
    });
    
    this.ListEmployee();
    this.leadlist();

    this.leadDate = self.formatDate(new Date);

    this.leadCreationForm =new UntypedFormGroup({
      "id":new UntypedFormControl(''),
      "leadCode": new UntypedFormControl(self.LeadID),
      "leadDate": new UntypedFormControl(self.formatDate(new Date)),
      "enquiredBy":new UntypedFormControl('', Validators.required),
      institutionName: new UntypedFormControl('', Validators.required),
      location: new UntypedFormControl('', Validators.required),
      institutionType: new UntypedFormControl('', Validators.required),
      affiliationType:new UntypedFormControl('', Validators.required),
      affiliationCode: new UntypedFormControl(''),
      medium: new UntypedFormControl('', Validators.required),
      //status: new FormControl('', Validators.required),
      status: new UntypedFormControl(''),
      contactPerson:new UntypedFormControl(''),
      mobileNo: new UntypedFormControl(''),
      emailId: new UntypedFormControl(''),
      demoTime:new UntypedFormControl(''),
      demoDate:new UntypedFormControl(''),
      demobyemployeeid:new UntypedFormControl(''),
      remarks: new UntypedFormControl(''),
      demoStatus: new UntypedFormControl(''),
      leadStatus:new UntypedFormControl(''),
      source: new UntypedFormControl(''),
      callStatus:new UntypedFormControl(''),
      // fromDate:[''],
      // toDate:['']
    });
    this.IntializeFollowUpform();
    this.generateLeadId();
   

  }

getLeadDetails(id:any){
  this.leadCreationService.getById(id).subscribe(data=>{
    
    this.leadCreationForm =new UntypedFormGroup({
      "id":new UntypedFormControl(data.id),
      "leadCode": new UntypedFormControl(data.leadCode),
      "leadDate": new UntypedFormControl(this.formatDate(data.leadDate)),
      "enquiredBy":new UntypedFormControl(data.enquiredBy, Validators.required),
      institutionName: new UntypedFormControl(data.institutionName, Validators.required),
      location: new UntypedFormControl(data.location, Validators.required),
      institutionType: new UntypedFormControl(data.institutionType, Validators.required),
      affiliationType:new UntypedFormControl(data.affiliationType, Validators.required),
      affiliationCode: new UntypedFormControl(data.affiliationCode),
      medium: new UntypedFormControl(data.medium, Validators.required),
      //status: new FormControl('', Validators.required),
      status: new UntypedFormControl(data.status),
      contactPerson:new UntypedFormControl(data.contactPerson),
      mobileNo: new UntypedFormControl(data.mobileNo),
      emailId: new UntypedFormControl(data.emailId),
      demoTime:new UntypedFormControl(this.datePipe.transform(data.demoTime,'hh:mm')),
      demoDate:new UntypedFormControl(this.formatDate(data.demoDate)),
      demobyemployeeid:new UntypedFormControl(data.demoByEmployeeId),
      remarks: new UntypedFormControl(data.remarks),
      demoStatus: new UntypedFormControl(data.demoStatus),
      leadStatus:new UntypedFormControl(data.leadStatus),
      source: new UntypedFormControl(data.source),
      callStatus:new UntypedFormControl(data.callStatus),
      // fromDate:[''],
      // toDate:['']
    });
    console.log(data);
  });

}

  IntializeFollowUpform(){
    this.leadFollowupForm = new UntypedFormGroup({
      id: new UntypedFormControl(0),
      leadId: new UntypedFormControl(''),
      callStatus:new UntypedFormControl('', Validators.required),
      priority:new UntypedFormControl ('Medium', Validators.required),
      nextFollowupDate: new UntypedFormControl(''),
      followupStatus: new UntypedFormControl('Process', Validators.required),
      isDemoScheduled: new UntypedFormControl(''),
      demoScheduledDate: new UntypedFormControl(''),
      demoScheduledTime: new UntypedFormControl(''),
      remarks: new UntypedFormControl('', Validators.required),
      demoStatus: new UntypedFormControl(''),
    });
  }
  // call this wherever you want to close modal
  private closeModal(): void {
    this.closeBtn.nativeElement.click();
    document.getElementById('close').click()
  }
  fileName = 'EntranceSummaryReport.xlsx';
  exportexcel() {
        
       const element = document.getElementById('leadCreationFilteredList');
       const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);     
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Entrance Result');       
       XLSX.writeFile(wb, this.fileName);
  }
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  institution: any;
  generatePDF(){
    this.headerList = [];
    this.fieldList = [];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    for(var i = 0 ; i < this.leadCreationFilteredList.length ; i++)
    this.leadCreationFilteredList[i]["index"] = i+1;
    const widthList = [];
    for (const item of this.headerList) {
        widthList.push('auto');
    }
    const docDefinition = {
      content: [
        {
            table: {
                widths: ['auto', '*'],
                body: [
                    [ {
                        width: 100,
                        height: 100,
                        image: 'imageKey'
                    }, {
                        width: '*',
                        alignment: 'center',
                        stack: [
                            {
                                style: 'h1',
                                text: this.institution.institutionName
                            },
                            {
                              style: 'h2',
                              text: this.institution.address
                            },
                            {
                                style: 'h2',
                                text: '2021-22'
                            },
                            {
                                style: 'h1',
                                text: 'Student Birthday List'
                            }
                        ]
                    }
                    ]
                ]
            },
            layout: {
                hLineWidth: (line) => line === 1,
                vLineWidth: () => 0,
                paddingBottom: () => 5
            },
          },
          {
            table: {
              headerRows: 1,
              widths: widthList,
              body: [
                this.headerList,
                ...this.leadCreationFilteredList.map((p) => {
                  const temp = [];
                  for (const y of this.fieldList) {
                    if (y === 'dob') {
                      temp.push(this.formatDate(p[y]));
                    } else {
                      temp.push(p[y]);
                    }
                  }
                  return temp;
                })
              ]
            }
          }
      ],
      images: {
        imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl :
        environment.imageUrl + '/images/avatar-1.png'
      },
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 16,
          bold: true
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        }
      }
    };
    pdfMake.createPdf(docDefinition).download();
  }

  resetFilter(){
    this.leadlist();
    this.statusDrp="All";
    $('#dateFrom').val('');
    $('#dateTo').val('');
  }
}




