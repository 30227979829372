import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LoaderComponent } from 'src/app/shared/loader/loader.component';
import { SessionPlannerService } from '../session-planner.service';
import {  StreamingAPISetting } from './model/StreamingAPISetting';
 

@Component({
  selector: 'app-lms-api-setting',
  templateUrl: './lms-api-setting.component.html',
  styleUrls: ['./lms-api-setting.component.css']
})
export class LMSAPISettingComponent implements OnInit {
  submitted: boolean;
  Id= 0;
  
  public lstStreamingAPI: StreamingAPISetting[] = new Array<StreamingAPISetting>();


  constructor(private coursePlannerService: SessionPlannerService, 
    private toastr: ToastrService,
    private load: LoaderComponent,private formBuilder: UntypedFormBuilder) { }


  sessionPlannerForm: UntypedFormGroup;


  ngOnInit(): void {

    this.sessionPlannerForm = this.formBuilder.group({
      id: 0,
      ProviderName: [, Validators.required],
      APIURL: [, Validators.required],
      // UserId: [, Validators.required],
      // Password: "",
      // AccessId: [, Validators.required],
      // AccessToken: [, Validators.required],
      Status: true,
     
     });


      this.gettAllAPI();
  }


  public gettAllAPI(){

    this.coursePlannerService.getAllStreamingAPISetting().subscribe((response) => {
      ;
      this.lstStreamingAPI = response;


      
    });
  }


  public saveSessionPlanner() {

    ;
    this.submitted = true;

    // this.load.show();

  //  var sDate = this.sessionPlannerForm.get('sessionDate').value;

  //  var day = moment(this.formatDate(this.sessionDate));
    // var date = new Date(this.formatDate(this.sessionDate));
    // this.sessionPlannerForm.get('sessionDate').patchValue(date);

    // this.sessionPlannerForm.value
    if (this.sessionPlannerForm.valid) {
      if(this.Id == 0){
        
        //this.sessionPlannerForm.get('sessionDate').patchValue( sDate.year +"-"+ (sDate.month > 2 ? sDate.month : "0"+ sDate.month) +"-"+(sDate.day > 2 ? sDate.day : "0"+ sDate.day) );


        this.coursePlannerService.SaveStreamingAPISetting(this.sessionPlannerForm.value).subscribe((response) => {
          ;
          this.load.ngOnInit();
          
        
          this.resetForm();
          this.toastr.success("Succesfully Added Course Plan", 'Success!',{timeOut: 1000});
        });
      }
      else{
       
      //  this.sessionPlannerForm.get('sessionDate').patchValue( sDate.year +"-"+ (sDate.month > 2 ? sDate.month : "0"+ sDate.month) +"-"+(sDate.day > 2 ? sDate.day : "0"+ sDate.day) );

        this.coursePlannerService.UpdateCoursePlan(this.sessionPlannerForm.value).subscribe((response) => {
        

          this.toastr.success("Succesfully Updated Session Plan", 'Success!',{timeOut: 1000});
        });
      }
    } 
  
  }


  public resetForm(){
    this.sessionPlannerForm = this.formBuilder.group({
      id: 0,
      ProviderName: [, Validators.required],
      APIURL: [, Validators.required],
      UserId: [, Validators.required],
      Password: "",
      AccessId: [, Validators.required],
      AccessToken: [, Validators.required],
      Status: true,
     
     });
  
  
      
  }

  
  updateForm(id: number) {
 
   

    this.coursePlannerService.getById(id).subscribe((data: any) => {
  
    // this.GetAllSubjectByBatchID(data.batchId);
;
    ;
    this.Id= data.id;
      
    this.sessionPlannerForm = this.formBuilder.group({
      id: 0,
      ProviderName: [, Validators.required],
      APIURL: [, Validators.required],
      // UserId: [, Validators.required],
      // Password: "",
      // AccessId: [, Validators.required],
      // AccessToken: [, Validators.required],
      Status: true,
     
     });

  });
}


}
