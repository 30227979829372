import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { EnquiryreportComponent } from './enquiryreport/enquiryreport.component';
import { EnquiryfollowupComponent } from './enquiryfollowup/enquiryfollowup.component';


const routes: Routes = [
  {path: 'enquiry', component: EnquiryComponent},
  {path: 'enquiryreport', component: EnquiryreportComponent},
  {path: 'enquiryfollowup', component: EnquiryfollowupComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontofficeRoutingModule { }
