import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SubjectcreationService } from '../subjectcreation/subjectcreation.service';
import { from } from 'rxjs';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';


import { TermService } from '../../mastersettings/term/term.service';
import { GradeScaleService } from '../gradescale/gradescale.service';
import { SubjectCreation } from '../subjectcreation/subjectcreation.model';
import { GradeScale } from '../gradescale/gradescale.model';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { assignsubjectservice } from './assignsubject.service';
import { assignsubjet } from './assignsubject.model';
import { EvalutiontypeService } from '../evalutiontype/evalutiontype.service';
import { EvaluationType } from '../../offline-examination/ExamSetting/models/evaluation-type.model';
import { Term } from '../../mastersettings/models/term';
@Component({
  selector: 'app-assignsubject',
  templateUrl: './assignsubject.component.html',
  styleUrls: ['./assignsubject.component.css']
})
export class AssignsubjectComponent implements OnInit {
  classcourses: ClassCourse[];
  terms: Term[];
  subjects: SubjectCreation[] = [];
  gradeScale: GradeScale[] = [];
  evaluationTypes: EvaluationType[] = [];
  AssignsubjectForm: UntypedFormGroup;
  submitted = false;
  batches: Batch[] = [];
  batchesBind: Batch[] = [];
  classId = '';
  isMasterSel = false;
  sectionData: any;
  assignsubjectList: assignsubjet[] = [];
  assignsubjectListFilter: assignsubjet[] = [];
  assignsubjectListFilterTemp: assignsubjet[] = [];
  classValue = '0';
  termValue = '-1';
  constructor(
    private classcourseService: ClassCourseService,
    private termService: TermService,
    public subjectService: SubjectcreationService,
    public gradeScaleService: GradeScaleService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private batchService: BatchService,
    private subjectAssignservice: assignsubjectservice,
    private evaluationService:EvalutiontypeService
  ) { }

  @ViewChild('modalClose') modalClose: ElementRef;

  ngOnInit(): void {
    this.AssignsubjectForm = this.fb.group({
      Id: [0],
      ClassCourseId: ['', Validators.required],
      TermId: ['', Validators.required],
      evaluationTypeId: ['', Validators.required],
      SubjectId: ['', Validators.required],
      Section: ['', Validators.required],
      Type: ['', Validators.required],
      IsMaking: [0, Validators.nullValidator]
    });
    this.ListClass();
    this.ListTerm();
    this.ListSubjects();
    //this.ListGroupScale();
    this.ListAssignSubjects();
    this.ListEvalutionTypes();
  }
  get f() { return this.AssignsubjectForm.controls; }
  ListClass() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      console.log(data);
      this.classcourses = data;
    });
  }
  ListTerm() {
    this.termService.getAll().subscribe((data: Term[]) => {
      console.log(data);
      this.terms = data;
    });
  }
  ListSubjects() {
    this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
      this.subjects = data;
    });
  }

  ListGroupScale() {
    this.gradeScaleService.getAll().subscribe((data: GradeScale[]) => {
      this.gradeScale = data;
    });
  }
  ListEvalutionTypes(){
    this.evaluationService.getAllEvalutaionTypes().subscribe((data:EvaluationType[])=>{
      this.evaluationTypes=data;
      console.log(data);
    });
}
  ListAssignSubjects() {
    this.subjectAssignservice.getAll().subscribe((data: assignsubjet[]) => {
      this.assignsubjectListFilterTemp = this.assignsubjectListFilter = this.assignsubjectList = data;
      if (this.classValue !== '0') {
        this.onClassChanged(this.classValue);
      }
      if (this.termValue !== '-1') {
        this.onTermChanged(this.termValue);
      }
    });
  }

  onClassSelected(value: string) {
    this.classId = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());

    });
  }
  checkAllCheckBox(ev) {
    this.sectionData = '';
    this.batches.forEach(x => x.checked = ev.target.checked);
    for (let i = 0; i < this.batches.length; i++) {
      if (this.batches[i].checked) {
        if (this.sectionData === '') {
          this.sectionData = this.batches[i].batchName;
        } else {
          this.sectionData = this.sectionData + '|' + this.batches[i].batchName;
        }
      }
    }
  }

  isAllCheckBoxChecked() {
    this.sectionData = '';
    const val = this.batches.every(p => p.checked);
    for (let i = 0; i < this.batches.length; i++) {
      if (this.batches[i].checked) {
        if (this.sectionData === '') {
          this.sectionData = this.batches[i].batchName;
        } else {
          this.sectionData = this.sectionData + '|' + this.batches[i].batchName;
        }
      }
    }
    return val;
  }

  selectBatchCheck(data: any, id: any) {
    // this.onClassSelected(id);

    this.classId = id;
    const selectedBatch = data.split('|');
    this.batchService.getAll().subscribe((dta: Batch[]) => {
      this.batches = dta.filter((batch: Batch) => batch.classId.toString() === id.toString());

      for (let i = 0; i < selectedBatch.length; i++) {
        const ind = this.batches.findIndex(c => c.batchName === selectedBatch[i]);
        // if (this.batches.filter(c => c.batchName === s/electedBatch[i]).length > 0) {
        if (ind >= 0) {
          this.batches[ind].checked = true;
          this.batches[ind].selected = true;
        }

      }

      // loaders stop
      // show modal
    });



  }

  updateForm(id: number) {
    this.subjectAssignservice.getById(id).subscribe((data: assignsubjet) => {
      this.selectBatchCheck(data.section, data.classCourseId);
      this.AssignsubjectForm = this.fb.group({
        Id: [data.id],
        ClassCourseId: [data.classCourseId.toString(), Validators.required],
        TermId: [data.termId.toString(), Validators.required],
        evaluationTypeId: [data.evaluationTypeId.toString(), Validators.required],
        SubjectId: [data.subjectId.toString(), Validators.required],
        Section: [data.section.toString(), Validators.required],
        Type: [data.type.toString(), Validators.required],
        IsMaking: [data.isMaking === true ? 1 : 0, Validators.nullValidator]
      });
    });
  }
  validateData(data: any): boolean {
    let result = false;
    let filteredList = JSON.parse(JSON.stringify(this.assignsubjectListFilter));
    filteredList = filteredList.filter(c => c.classCourseId.toString() === data.ClassCourseId
      && c.subjectId === data.SubjectId);
    if (filteredList.length > 0) {
      result = true;
    } else {
      result = false;
    }

    return result;
  }

  submitForm() {
    this.submitted = true;
    // stop here if form is invalid
    this.AssignsubjectForm.get('Section').patchValue(this.sectionData);
    if (this.sectionData === '') {
      this.toastr.warning('Please select batch', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }
    if (this.AssignsubjectForm.invalid) {
      return;
    }
    if (this.validateData(this.AssignsubjectForm.value)) {
      this.toastr.warning('The subject and class combination is there..', 'SUCCESS!', {
        timeOut: 3000
      });
      return;
    }
    if (this.AssignsubjectForm.get('Id').value === 0) {
      console.log(this.AssignsubjectForm.value);
      this.subjectAssignservice.create(this.AssignsubjectForm.value).subscribe(res => {
        this.ListAssignSubjects();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        this.modalClose.nativeElement.click();
      });
    } else {
      this.subjectAssignservice.update(this.AssignsubjectForm.get('Id').value, this.AssignsubjectForm.value).subscribe(res => {
        this.ListAssignSubjects();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        this.modalClose.nativeElement.click();
      });
    }
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === '') {
      this.assignsubjectListFilter = this.assignsubjectList;
    } else {
      this.assignsubjectListFilter = this.assignsubjectList
        .filter((data: assignsubjet) => data.className.toLocaleLowerCase()
          .includes(keyword.toLocaleLowerCase()));
    }
  }

  onTermChanged(value: any) {
    // if (value === 'Select Term') {
    //     this.assignsubjectListFilter = this.assignsubjectList;
    // } else {
    //   this.assignsubjectListFilter = this.assignsubjectList.filter((data: assignsubjet) => {
    //     return data.termId.toString() == value;
    //   });
    // }


    const contClass = this.classValue;
    this.termValue = value;
    if (value === '0' || value === 'All' || value === '' || value === '-1') {
      if (contClass === '-1' || contClass === '0') {
        this.assignsubjectListFilter = this.assignsubjectListFilterTemp;
      } else {
        this.assignsubjectListFilter = this.assignsubjectListFilterTemp.filter((data: assignsubjet) => {
          return data.classCourseId.toString() === contClass;
        });
      }
    } else {
      if (contClass === '-1' || contClass === '0') {
        this.assignsubjectListFilter = this.assignsubjectListFilterTemp.filter((data: assignsubjet) => {
          return data.termId.toString() === value;
        });
      } else {
        this.assignsubjectListFilter = this.assignsubjectListFilterTemp.filter((data: assignsubjet) => {
          return data.termId.toString() === value && data.classCourseId.toString() === this.classValue;
        });
      }
    }

  }

  onClassChanged(value: any) {
    // if (value === 'Select Class') {
    //   this.assignsubjectListFilter = this.assignsubjectList;
    // } else {
    //   this.assignsubjectListFilter = this.assignsubjectList.filter((data: assignsubjet) => {
    //     return data.classCourseId.toString() == value;
    //   });
    // }

    this.classValue = value;
    if (value === '0') {
      this.assignsubjectListFilter = this.assignsubjectListFilterTemp;
    } else {
      this.assignsubjectListFilter = this.assignsubjectListFilterTemp.filter((data: assignsubjet) => {
        return data.classCourseId.toString() === value;
      });
    }
  }

  resetForm() {
    this.submitted = false;
    this.batches = [];
    this.AssignsubjectForm = this.fb.group({
      Id: [0],
      ClassCourseId: ['', Validators.required],
      TermId: ['', Validators.required],
      evaluationTypeId: ['', Validators.required],
      SubjectId: ['', Validators.required],
      Section: ['', Validators.required],
      Type: ['', Validators.required],
      IsMaking: [0, Validators.nullValidator]
    });
  }

  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.subjectAssignservice.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListAssignSubjects();
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }
}
