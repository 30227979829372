import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelect2Module } from 'ng-select2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FrontofficeRoutingModule } from './frontoffice-routing.module';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { EnquiryreportComponent } from './enquiryreport/enquiryreport.component';
import { EnquiryfollowupComponent } from './enquiryfollowup/enquiryfollowup.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [EnquiryComponent, EnquiryreportComponent, EnquiryfollowupComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelect2Module,
    FrontofficeRoutingModule,
    NgSelectModule
  ]
})
export class FrontofficeModule { }
