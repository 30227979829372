import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class InstitutionGuard  {

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.canLoad(route);
  }

  canLoad(instco) {
    //debugger;
    // this.authService.userType = instco.data.role;
    if (instco.params.instcode !== undefined) {
      this.authService.institutionCode = instco.params.instcode;
    }

    console.log("route hicham : "+instco.params.instcode);
    if (!this.authService.isLoggedIn()) {
      this.router.navigate([instco.params.instcode + '/login'], { relativeTo: this.activatedRoute });

    }

    if(this.router.url === '/' && instco.params.instcode==='edumaq'){
      //debugger;
      
      this.router.navigate(['/'+this.authService.institutionCode], { relativeTo: this.activatedRoute });
    }
    if(this.activatedRoute.snapshot.params.edumaq==='404'){
     // debugger;
      this.router.navigate(['/'+this.authService.institutionCode], { relativeTo: this.activatedRoute });
    }

    const paths = JSON.parse(localStorage.getItem('disAllowedPaths'));
    const startIndex = instco._routerState.url.indexOf(this.authService.institutionCode);
    const endIndex = instco._routerState.url.indexOf('/', startIndex);
    const url = instco._routerState.url.substring(endIndex);
    if(paths && paths.length > 0 && paths.includes(url) && !this.allowedPaths.includes(url)){
      debugger;
      this.router.navigate(['/' + this.authService.institutionCode + '/admin/not-found'], { relativeTo: this.activatedRoute });
    }
    return this.authService.isLoggedIn();
  }

  canActivateChild(croute: ActivatedRouteSnapshot): boolean | UrlTree {
      // console.log(croute);
      return this.canLoad(croute);
  }

   allowedPaths: any[] = [
    "/admin/frontoffice/enquiry",
    "/admin/academic/registration",
    "/admin/academic/student/studentsummary",
    "/admin/academic/feemanagement/feecollection",
    "/admin/academic/feemanagement/feecollectionnew",
    "/admin/academic/feemanagement/report/feetransactionreport",
    "/admin/academic/feemanagement/report/feecollection",
    "/admin/operation/hrmspayroll/payroll/employeeattendance/attendanceregister",
    "/admin/operation/inventory/saleinvoice",
  ];

}
