import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PeriodComponent } from './period/period.component';
import { TimetableComponent } from './timetable/timetable.component';
import { WeekdayComponent } from './weekday/weekday.component';
import { TimetableRoutingModule } from './timetable.routing.module';
import { ReportComponent } from './report/report.component';
import { AddEditTimeTableDialogComponent } from './timetable/add-edit-time-table-dialog/add-edit-time-table-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  exports: [
    MatDialogModule
],
  declarations: [PeriodComponent, TimetableComponent, WeekdayComponent, ReportComponent, AddEditTimeTableDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TimetableRoutingModule
  ]
})
export class TimetableModule { }
