import { Component, OnInit } from '@angular/core';
import { Designation } from '../../../OperationActivity/hrmspayroll/master/models/designation.model';
import { Department } from '../../../OperationActivity/hrmspayroll/master/models/departement.model';
import { Position } from '../../../operationactivity/hrmspayroll/master/models/position';
import { Employeeadmission, Qualification, EmployeeType,
   JobType } from '../../../OperationActivity/hrmspayroll/hrms/models/employeeadmission.model';
import { EmployeeadmissionService } from '../../../OperationActivity/hrmspayroll/hrms/employeeadmission.service';
import { DesignationService } from '../../../operationactivity/hrmspayroll/master/designation.service';
import { DepartmentService } from '../../../operationactivity/hrmspayroll/master/department.service';
import { PositionService } from '../../../operationactivity/hrmspayroll/master/position.service';
import { SettingsService } from '../../../academic/mastersettings/settings.service';
import { Academicyear } from '../../../academic/mastersettings/models/academicyear';
import { ReportAttribute } from './employeereport';

import { environment } from '../../../../../environments/environment';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable.js';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-employeereport',
  templateUrl: './employeereport.component.html',
  styleUrls: ['./employeereport.component.css']
})
export class EmployeereportComponent implements OnInit {

  designations: Designation[] = [];
  departments: Department[] = [];
  positions: Position[] = [];
  qualifications: Qualification[] = [];
  employeeAdmissions: Employeeadmission[] = [];
  defaultEmployeeAdmissions: Employeeadmission[] = [];
  sortedEmployeeAdmissions: Employeeadmission[] = [];
  employeeTypes: EmployeeType[] = [];
  jobTypes: JobType[] = [];

  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  selectedAttributes: ReportAttribute[] = [];

  qualification = '0';
  jobType = '0';
  PositionId = '0';
  DesignationId = '0';
  DepartmentId = '0';
  gender = '';
  employeeType = '';
  seqno = 1;
  institution: any;

  chkEmployeeType = false;
  chkGender = false;
  chkDepartment = false;
  chkDesignation = false;
  chkPosition = false;
  chkQualification = false;
  chkJobType = false;

  academicYears: Academicyear[] = [];
  currentAcademicYearId: number;
  currentAcademicYearName = '';

  constructor(
    private designationService: DesignationService,
    private departmentService: DepartmentService,
    private positionService: PositionService,
    private employeeService: EmployeeadmissionService,
    private toastr: ToastrService,
    private academicYearService: SettingsService
  ) { 
    this.GetCurrentAcademicYear();
  }

  generateAttributes() {
    this.attributes = [
    {name: 'Employee Id', isChecked: false, fieldName: 'employeeId' },
    {name: 'Birth Date', isChecked: false, fieldName: 'birthDate' },
    {name: 'Employee Name', isChecked: false, fieldName: 'employeeName' },
    {name: 'Department', isChecked: false, fieldName: 'departmentName' },
    {name: 'Designation', isChecked: false, fieldName: 'designationName' },
    {name: 'Position', isChecked: false, fieldName: 'positionName' },
    {name: 'Gender', isChecked: false, fieldName: 'gender' },
    {name:'Email Id',isChecked:false,fieldName:'emailId'},
    {name:'Mobile No',isChecked:false,fieldName:'mobileNo'},
    {name:'Father Name',isChecked:false,fieldName:'fatherName'},
    {name:'Mother Name',isChecked:false,fieldName:'motherName'},
    {name:'Bank Name',isChecked:false,fieldName:'bankName'},
    {name:'Account Number',isChecked:false,fieldName:'accountNo'},
    {name:'Account Name',isChecked:false,fieldName:'accountName'},
    {name:'Aadhar Number',isChecked:false,fieldName:'adhaarNumber'},
    {name:'Blood Group',isChecked:false,fieldName:'bloodGroup'},
    {name:'Employee Type',isChecked:false,fieldName:'employeeTypeId'},
    {name:'Qualification',isChecked:false,fieldName:'qualification'},
    {name:'Job Type',isChecked:false,fieldName:'jobType'},
    ];
  }

  onCheckAttribute(evnt: any, name: any, aName: any) {
    if (evnt.target.checked) {
      this.selectedAttributes.push(this.attributes[name]);
      this.headerList.push(this.attributes[name].name);
      this.fieldList.push(this.attributes[name].fieldName);
    } else {
      // this.selectedAttributes.splice(name, 1);
      // this.headerList.splice(name, 1);
      // this.fieldList.splice(name, 1);
      let selectedIndex: number;
      this.selectedAttributes.forEach((x, i) => {
        if (x.name === aName) {
          selectedIndex = i;
        }
      });
      this.selectedAttributes.splice(selectedIndex, 1);
      this.headerList.splice(selectedIndex, 1);
      this.fieldList.splice(selectedIndex, 1);
    }
  }

  selecetAllConditions(evnt: any) {
    if (evnt.target.checked) {
    this.chkEmployeeType = true;
    this.chkGender = true;
    this.chkDepartment = true;
    this.chkDesignation = true;
    this.chkPosition = true;
    this.chkQualification = true;
    this.chkJobType = true;
    } else {
      this.chkEmployeeType = false;
      this.chkGender = false;
      this.chkDepartment = false;
      this.chkDesignation = false;
      this.chkPosition = false;
      this.chkQualification = false;
      this.chkJobType = false;
    }
  }

  isAllAttributeCheckBoxChecked() {
    return this.attributes.every(p => p.isChecked);
  }

  GetCurrentAcademicYear() {
    this.academicYearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
      this.currentAcademicYear();
    });
  }

  currentAcademicYear() {
    this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.currentAcademicYearName = this.academicYears.find(x => x.id.toString() === data.id.toString()).name;
     
    });
  }

  ListDepartment() {
    this.departmentService.getAll().subscribe((data: Department[]) => {
      this.departments = data.filter((departments: Department) => departments.isDeleted !== true);
    });
  }

  ListDesignation(event) {
    this.designationService.getAll().subscribe((data: Designation[]) => {
      this.designations = data.filter((designation: Designation) =>
      designation.isDeleted === false && designation.departmentId.toString() === event.target.value.toString());
    });
  }
  ListPosition(event) {
    this.positionService.getAll().subscribe((data: Position[]) => {
      this.positions = data.filter((position: Position) =>
      position.isDeleted === false && position.designationId.toString() === event.target.value.toString());
    });
  }
  ListEmployeeType() {
    this.employeeService.getAllEmployeeType().subscribe((data: EmployeeType[]) => {
      this.employeeTypes = data.filter((employeeType: EmployeeType) => employeeType.isDeleted !== true);
    });
  }

  ListEmployee() {
    this.employeeService.getAllEmployee().subscribe((data: Employeeadmission[]) => {
      console.log(data);
      this.defaultEmployeeAdmissions = this.sortedEmployeeAdmissions = this.employeeAdmissions =
      data.filter((employeeAdmission: Employeeadmission) => employeeAdmission.isDeleted !== true);
    });
  }
  ListQualification() {
    this.employeeService.getAllQualification().subscribe((data: Qualification[]) => {
      console.log(data);
      this.qualifications = data.filter((qualification: Qualification) => qualification.isDeleted !== true);
    });
  }

  ListJobType() {
    this.employeeService.getAllJobType().subscribe((data: JobType[]) => {
      console.log(data);
      this.jobTypes = data.filter((jobType: JobType) => jobType.isDeleted !== true);
    });
  }

  sortEmployee() {
   
      this.employeeAdmissions = this.defaultEmployeeAdmissions;
      this.employeeAdmissions.forEach((x) => {
        if (x.gender === '1') {
          x.gender = 'Male';
        } else if (x.gender === '2') {
          x.gender = 'Female'; 
        } else if (x.gender === '3') {
          x.gender = 'Other';
        }
      });
      
      this.sortedEmployeeAdmissions = this.employeeAdmissions;
      this.seqno = 1;
      this.sortedEmployeeAdmissions.forEach((x) => {
        x.sno = this.seqno++;
      });

      if(this.employeeType!=''){
        this.sortedEmployeeAdmissions = this.sortedEmployeeAdmissions.filter((e: Employeeadmission) => {
          return e.employeeTypeId === Number(this.employeeType);
        });
      }
      if (this.gender !== '') {
          this.sortedEmployeeAdmissions = this.sortedEmployeeAdmissions.filter((e: Employeeadmission) => {
            return e.gender === this.gender;
          });
      }  
      if (this.DepartmentId !== '0') {
          this.sortedEmployeeAdmissions = this.sortedEmployeeAdmissions.filter((e: Employeeadmission) => {
            return e.departmentId.toString() === this.DepartmentId.toString();
          });
      } 
      if (this.DesignationId !== '0') {
          this.sortedEmployeeAdmissions = this.sortedEmployeeAdmissions.filter((e: Employeeadmission) => {
            return e.DesignationId.toString() === this.DesignationId;
          });
      } 
      if (this.PositionId !== '0') {
          this.sortedEmployeeAdmissions = this.sortedEmployeeAdmissions.filter((e: Employeeadmission) => {
            return e.positionId.toString() === this.PositionId;
          });
      } 
      if (this.jobType !== '0') {
          this.sortedEmployeeAdmissions = this.sortedEmployeeAdmissions.filter((e: Employeeadmission) => {
            return e.jobType.toString() === this.jobType;
          });
      } 
      if (this.qualification !== '0' ) {
          this.sortedEmployeeAdmissions = this.sortedEmployeeAdmissions.filter((e: Employeeadmission) => {
            return e.qualification.toString() === this.qualification;
          });
      }
  }

  resetAll() {
    this.sortedEmployeeAdmissions = this.employeeAdmissions;
    this.selectedAttributes = [];
    this.attributes.forEach( x => x.isChecked = false);

    this.qualification = '0';
    this.jobType = '0';
    this.PositionId = '0';
    this.DesignationId = '0';
    this.DepartmentId = '0';
    this.gender = '';
    this.employeeType = '';

    this.chkEmployeeType = false;
    this.chkGender = false;
    this.chkDepartment = false;
    this.chkDesignation = false;
    this.chkPosition = false;
    this.chkQualification = false;
    this.chkJobType = false;
  }

  generateExcel() {
    if (this.attributes.every(p => !p.isChecked)) {
      this.toastr.warning('Please select attribute', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    this.sortEmployee();
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Employee Data');
    const header = [];
    const fields = [];
    header.push('S.No.');
    fields.push('sno');
    for (const item of this.headerList) {
      header.push(item);
    }
    for (const item of this.fieldList) {
      fields.push(item);
    }
    // this.selectedAttributes.forEach(element => {
    //   header.push(element.name);
    //   fields.push(element.fieldName);
    // });
    const headerRow = worksheet.addRow(header);
    for (const x1 of this.sortedEmployeeAdmissions) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of fields) {
        if(y=='jobType')
        {
            let jobType = this.jobTypes.find(_jobType=>_jobType.id == Number(x1[y]));
            temp.push(jobType!=undefined?jobType.jobTypeName:'');
        }
        else if(y=='qualification')
        {
            let qualification = this.qualifications.find(_qualification=>_qualification.id == Number(x1[y]));
            temp.push(qualification!=undefined?qualification.qualificationName:'');
        }
        else if (y=='employeeTypeId')
        {
            temp.push(Number(x1[y])==1?'Teacher':'Non Teacher');
        }
        else
        {
          temp.push(x1[y]);
        }
      }
      worksheet.addRow(temp);
    }
    const fname = 'Employee Report';

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }

  generatePDF() {
    if (this.attributes.every(p => !p.isChecked)) {
      this.toastr.warning('Please select attribute', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    this.sortEmployee();
    const header = [];
    const fields = [];
    header.push('S.No.');
    fields.push('sno');
    for (const item of this.headerList) {
      header.push(item);
    }
    for (const item of this.fieldList) {
      fields.push(item);
    }
    const widthList = [];
    for (const item of header) {
        widthList.push('auto');
    }

    const docDefinition = {
      pageOrientation: 'landscape',
      content: [
        {
          table: {
              widths: ['auto', '*'],
              body: [
                  [ {
                      width: 100,
                      height: 100,
                      image: 'imageKey'
                  }, {
                      width: '*',
                      alignment: 'center',
                      stack: [
                          {
                              style: 'h1',
                              text: this.institution.institutionName
                          },
                          {
                            style: 'h2',
                            text: this.institution.address
                          },
                          {
                              style: 'h2',
                              text: this.currentAcademicYearName
                          },
                          {
                              style: 'h1',
                              text: 'EMPLOYEE REPORT',
                              alignment: 'center',
                              decoration: 'underline',
                              color: 'skyblue'
                          }
                      ]
                  }
                  ]
              ]
          },
          layout: {
              hLineWidth: (line) => line === 1,
              vLineWidth: () => 0,
              paddingBottom: () => 5
          },
        },
        {
          table: {
            headerRows: 1,
            widths: widthList,
            body: [
              //this.headerList,
              header,
              ...this.sortedEmployeeAdmissions.map((p) => {
                const temp = [];
                for (const y of fields) {
                  if(y=='jobType')
                  {
                      let jobType = this.jobTypes.find(_jobType=>_jobType.id == Number(p[y]));
                      temp.push(jobType!=undefined?jobType.jobTypeName:'');
                  }
                  else if(y=='qualification')
                  {
                      let qualification = this.qualifications.find(_qualification=>_qualification.id == Number(p[y]));
                      temp.push(qualification!=undefined?qualification.qualificationName:'');
                  }
                  else if (y=='employeeTypeId')
                  {
                      temp.push(Number(p[y])==1?'Teacher':'Non Teacher');
                  }
                  else
                  {
                    temp.push(p[y]);
                  }
                }
                return temp;
              })
            ]
          }
        },
      ],
      images: {
        imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
        // via URL address, which can be referenced by this key
     },
      styles: {
        header: {
         margin: [0, 15, 0, 15]
        },
        sectionHeader: {
          fontSize: 5,
         margin: [0, 15, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 16,
          bold: true
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        }
      }
    };

    pdfMake.createPdf(docDefinition).download('EMPLOYEE REPORT.pdf');

  }

  ngOnInit(): void {
    this.generateAttributes();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.ListDepartment();
    this.ListQualification();
    this.ListEmployee();
    this.ListEmployeeType();
    this.ListJobType();
  }

}
