import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs"; 
import { GradescalegroupService } from "../services/gradescalegroup.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ExamResultReportService } from "../services/exam-result-report.service";
import { StudentWiseReportCardService } from "../services/student-wise-report-card.service";


@Component({
  selector: 'app-subject-wise-exam-report',
  templateUrl: './subject-wise-exam-report.component.html',
  styleUrls: ['./subject-wise-exam-report.component.css'],
  providers: [FormGroupDirective],
})
export class SubjectWiseExamReportComponent implements OnInit {
  submitted = false; 
  categorydropDown: any = [];
  examNamedropDown: any = [];
  finalexamNamedropDown: any = [];
  examClassdropDown: any = [];
  StudentsList: any = [];
  classDropDown: any = [];
  section: any = [];
  DetailsResult: any = [];
  isLoading: boolean = false;
  disabled = true;
  constructor(
    private gradeService: GradescalegroupService,
    private toastr: ToastrService,
    public fb: UntypedFormBuilder,
    public service: ExamResultReportService,
    public examService: StudentWiseReportCardService,
    public formDirective: FormGroupDirective
  ) {}
  subjectwiseexamreport: UntypedFormGroup;
  classBatachData: any = [];

  ngOnInit(): void {
    this.subjectwiseexamreport = this.fb.group({
      id: [0],
      examCategory: ["", Validators.required],
      examName: ["", Validators.required],
      classId: ["", Validators.required],
      classBatch: ["", Validators.required]
    });
    this.getCategoryDropDown();
  }

  get f() {
    return this.subjectwiseexamreport.controls;
  }
  getCategoryDropDown() {
    this.service.getAllCatefgoryDropDown().subscribe((data) => {
      this.categorydropDown = data;
    });
    this.checkingButton();
  }
  onCategoryEvent() {
    this.subjectwiseexamreport.value.examName = "";
    this.finalexamNamedropDown = [];
    this.examClassdropDown = [];
    this.StudentsList = [];
    this.getNameDropDown();
    this.checkingButton();   
    this.subjectwiseexamreport = this.fb.group({
      id: [0],
      examCategory: [this.subjectwiseexamreport.controls.examCategory.value, Validators.required],
      examName: ["", Validators.required],
      classId: ["", Validators.required],
      classBatch: ["", Validators.required]
    }); 
  }
  submitForm() {}

  getNameDropDown() {
    this.service.getExamNameList().subscribe((data) => {
      this.examNamedropDown = data;
      this.finalexamNamedropDown = this.examNamedropDown.filter(
        (filterList: any) =>
          filterList.examCategoryId === this.subjectwiseexamreport.value.examCategory
      );
    });
    this.checkingButton();
    
  }

  getClassDropDown() {
    this.service
      .getAllClass(this.subjectwiseexamreport.value.examName)
      .subscribe((data) => {
        this.examClassdropDown = data;
        this.classDropDown = [
          ...new Map(
            this.examClassdropDown.map((item) => [item["className"], item])
          ).values(),
        ];
        this.StudentsList = [];
        this.checkingButton();
      });
      this.subjectwiseexamreport = this.fb.group({
        id: [0],
        examCategory: [this.subjectwiseexamreport.controls.examCategory.value, Validators.required],
        examName: [this.subjectwiseexamreport.controls.examName.value, Validators.required],
        classId: ["", Validators.required],
        classBatch: ["", Validators.required]
      }); 
  }

  selectedClass(id) {
    let batchList = [];
    this.StudentsList = [];
    if (this.subjectwiseexamreport.value.classId !== "") {
      batchList = this.examClassdropDown.filter((element) => element.id == id);
      this.classBatachData = batchList;
      this.checkingButton();
    }
    this.subjectwiseexamreport = this.fb.group({
      id: [0],
      examCategory: [this.subjectwiseexamreport.controls.examCategory.value, Validators.required],
      examName: [this.subjectwiseexamreport.controls.examName.value, Validators.required],
      classId: [this.subjectwiseexamreport.controls.classId.value, Validators.required],
      classBatch: ["", Validators.required]
    }); 
  }

  selectedClassBatch() {
    this.StudentsList = [];
    if (this.subjectwiseexamreport.value.classBatch !== "") {
      this.section = this.classBatachData.filter(
        (element) =>
          element.batchId === this.subjectwiseexamreport.controls.classBatch.value
      );
      this.checkingButton();
    }
  }



  checkingButton() {
    if (
      this.subjectwiseexamreport.controls.examCategory.value &&
      this.subjectwiseexamreport.controls.examName.value &&
      this.subjectwiseexamreport.controls.classId.value &&
      this.subjectwiseexamreport.controls.classBatch.value
    ) {
      this.disabled = false;
    } 
  }

  getAllStudents() {
    this.isLoading = true;
     if (this.subjectwiseexamreport.valid) {
    this.examService.GetSubjectWiseReport(
          this.subjectwiseexamreport.controls.examCategory.value,
          this.subjectwiseexamreport.controls.examName.value,
          this.subjectwiseexamreport.controls.classId.value,
          this.subjectwiseexamreport.controls.classBatch.value
        ).subscribe((data) => {
          this.DetailsResult = data;
          if(this.DetailsResult.length>0)
          {
            this.StudentsList=this.DetailsResult[0].studentDetails[0];
              
          }       
          this.isLoading = false;
        });
    
  }
  else{
    this.toastr.warning("All field are required", "WARNING!!!", {
      timeOut: 3000,
    });
    this.isLoading = false;
    return;
  }
  }

  resetForm() {
    this.submitted = false;
    this.finalexamNamedropDown = [];
    this.classDropDown = [];
    this.classBatachData = [];
    this.StudentsList = [];
    this.formDirective.reset();
    this.subjectwiseexamreport.reset();
    this.disabled = true;
    this.subjectwiseexamreport = this.fb.group({
        id: [0],
        examCategory: ["", Validators.required],
        examName: ["", Validators.required],
        classId: ["", Validators.required],
        classBatch: ["", Validators.required],
      });
  }
}
