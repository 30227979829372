import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FeeHead } from '../../feehead/feehead.model';
import { FeeHeadService } from '../../feehead/feehead.service';
import { BatchAttribute } from '../../../classmanagement/batchattribute.model';
import { Batch } from '../../../mastersettings/models/batch';
declare var $: any;

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import { environment } from 'src/environments/environment';
import { ConsolidatedReportService } from './consolidatedreport.service';


@Component({
  selector: 'app-consolidatedreport',
  templateUrl: './consolidatedreport.component.html',
  styleUrls: ['./consolidatedreport.component.css']
})
export class ConsolidatedreportComponent implements OnInit {

  feeHeadList: FeeHead[] = [];
  selectedStudentTypeId:number = 1;
  selectedFeeHeadId:number = -1;
  selectedFromDate:any;
  selectedToDate:any;
  mainReportList:any=[]; 
  mainReportListHeaderFooter:any=[]; 
  childReportList:any=[]; 
  childReportListHeaderFooter:any=[]; 
  singleFeeHeadHeaderFooter:any=[]; 
  childReportListFilterByClass:any=[]; 
  isLoading: boolean = false;
  institution: any;
  attributes:BatchAttribute[] = [];
  batches: Batch[] = [];
  headerList: string[] = [];
  fieldList: string[] = [];
  reportDataList:any=[]; 
  
  constructor(
    public consolidatedReportService:ConsolidatedReportService,
    public feeHeadService:FeeHeadService
  ) { }

  ngOnInit(): void {
    this.listFeeHead();
    this.setDefaults();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
  }
  
  listFeeHead() {
    this.feeHeadService.getAll().subscribe((data: FeeHead[]) => {
      this.feeHeadList = data;
    });
  }

  setDefaults()
  {
    // const datePipe = new DatePipe('en-Us');
    // this.selectedFromDate = datePipe.transform(new Date(), 'yyyy-MM-dd');
    // this.selectedToDate = datePipe.transform(new Date(), 'yyyy-MM-dd');
    // var date = new Date();
    // //$('#selectedFromDate').datepicker('setDate', new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    // //$('#selectedToDate').datepicker('setDate', new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    // $('#selectedFromDate').datepicker('setDate', new Date(2022, 3, 1));
    // $('#selectedToDate').datepicker('setDate', new Date(2022, 3, 30));

    const self = this;
    $('#selectedFromDate').datepicker().on('changeDate', (e) => {
      self.selectedFromDate = e.date;
    });
    $('#selectedToDate').datepicker().on('changeDate', (e) => {
      self.selectedToDate = e.date;
    });
    //.onSearch();
  }

  onStudentTypeChanged(id: number) {
    //this.onSearch();
   }

  onFeeHeadChanged(id: number) {
    //this.onSearch();
   }

  onRefresh() {
    this.selectedStudentTypeId = 1;
    this.selectedFeeHeadId = -1;
    this.selectedFromDate = null;
    this.selectedToDate = null;
    $('#selectedFromDate').datepicker('setDate', null);
    $('#selectedToDate').datepicker('setDate', null);
    this.onSearch();
   }

  onSearch()
  {
   
    const datePipe = new DatePipe('en-Us');
    this.selectedFromDate = datePipe.transform(this.selectedFromDate, 'yyyy-MM-dd');
    this.selectedToDate = datePipe.transform(this.selectedToDate, 'yyyy-MM-dd');

    if(this.selectedFromDate == null || this.selectedToDate == null) 
    {
      this.mainReportList = [];
      this.mainReportListHeaderFooter = [];
      this.childReportList = [];
      this.childReportListHeaderFooter = [];
      this.childReportListFilterByClass = [];
      this.singleFeeHeadHeaderFooter = [];
      return;
    }
    
    this.isLoading=true;
    this.consolidatedReportService.getConsolidatedInstallmentReport(this.selectedStudentTypeId,this.selectedFeeHeadId, this.selectedFromDate, this.selectedToDate).subscribe((data) => {

      this.mainReportList = data.table1;
      console.log("mainReportList:"+this.mainReportList[0]);
      this.mainReportListHeaderFooter = data.table1[0];
      console.log("mainReportListHeaderFooter:"+this.mainReportListHeaderFooter);
      this.childReportList=data.table3;
      console.log("childReportList:"+this.childReportList);
      this.childReportListHeaderFooter=data.table3;
      console.log("childReportListHeaderFooter:"+this.childReportListHeaderFooter);
      this.isLoading=false;
      //set first one selected by default 
      if(this.mainReportList.length > 0)
      {
        this.SetChildReport(this.mainReportList[0].classCourseName);
      }
      
    });
  }

  SetChildReport(classCourseName : string)
  {
    debugger;
    //set grid data
    if(this.childReportList.length > 0)
    {
      this.childReportListFilterByClass = this.childReportList.filter((x) => x.classCourseName === classCourseName);
    }

    //set header and footer
    if(this.childReportListHeaderFooter.length > 0)
    {
      this.singleFeeHeadHeaderFooter = this.childReportListHeaderFooter.filter((x) => x.classCourseName === classCourseName)[0];
    }

    //set selected
    this.mainReportList.forEach((x) => {
      if (x.classCourseName === classCourseName) {
          x.selected = true;
      } else {
        x.selected = false;
      }
    });

  }


  generatePDF(reportType : number) {
    this.headerList = [];
    this.fieldList = [];

    this.generateAttributes(reportType);
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
  

    var reportName;
    if(reportType == 1) // parent report
    {
      this.reportDataList = this.mainReportList;
      reportName = "CONSOLIDATED REPORT"; 
    }
    else if(reportType == 2) // child report (Fee Head Wise)
    {
      this.reportDataList = this.childReportListFilterByClass;
      reportName = "CONSOLIDATED FEE HEAD REPORT"; 
      if(this.childReportListFilterByClass.length > 0)
      {
        reportName += ' ( ' + this.childReportListFilterByClass[0].classCourseName + ' )'
      }
    }
    var fileName =  reportName + '.pdf'

    if(this.reportDataList.length == 0)
    {
      return;
    }

    const widthList = [];
    for (const item of this.headerList) {
        widthList.push('auto');
    }
    const docDefinition = {
      content: [
        {
            table: {
                widths: ['auto', '*'],
                body: [
                    [ {
                        width: 100,
                        height: 100,
                        image: 'imageKey'
                    }, {
                        width: '*',
                        alignment: 'center',
                        stack: [
                            {
                                style: 'h1',
                                text: this.institution.institutionName
                            },
                            {
                              style: 'h2',
                              text: this.institution.address
                            },
                            {
                                style: 'h2',
                                text: '2021-22'
                            },
                            {
                                style: 'h2',
                                text: reportName
                            }
                        ]
                    }
                    ]
                ]
            },
            layout: {
                hLineWidth: (line) => line === 1,
                vLineWidth: () => 0,
                paddingBottom: () => 5
            },
          },
          {
            style:'sectionHeader',
            table: {
              
              headerRows: 1,
              widths: widthList,
              
              body: [
                this.headerList,
                ...this.reportDataList.map((p) => {
                  const temp = [];
                  for (const y of this.fieldList) {
                    if (y === 'dob') {
                      temp.push(this.formatDate(p[y]));
                    } else {
                      temp.push(p[y]);
                    }
                  }
                  return temp;
                })
              ]
            }
          }
      ],
      images: {
        imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
  
      },
      styles: {
        sectionHeader: {
          // bold: true,
          // decoration: 'underline',
          fontSize: 12,
          margin: [15, 15, 15, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 16,
          bold: true
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        }
      }
    };
  
    pdfMake.createPdf(docDefinition).download(fileName);
  }


  generateExcel(reportType : number) {
    
    this.headerList = [];
    this.fieldList = [];

    this.generateAttributes(reportType);
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
  
    var reportName;
    if(reportType == 1) // parent report
    {
      this.reportDataList = this.mainReportList;
      reportName = "CONSOLIDATED REPORT"; 
    }
    else if(reportType == 2) // child report (Fee Head Wise)
    {
      this.reportDataList = this.childReportListFilterByClass;
      reportName = "CONSOLIDATED FEE HEAD REPORT"; 
      if(this.childReportListFilterByClass.length > 0)
      {
        reportName += ' ( ' + this.childReportListFilterByClass[0].classCourseName + ' )'
      }
    }

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(reportName);
    
    const titleRow = worksheet.addRow([reportName]);
    const headerRow = worksheet.addRow(this.headerList);
    for (const x1 of this.reportDataList) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'dob') {
          temp.push(this.formatDate(x1[y]));
        } else {
          temp.push(x1[y]);
        }
      }
      worksheet.addRow(temp);
    }
    const fname = reportName;
  
    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });
  
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  generateAttributes(reportType : number ) {

    if(reportType == 1) //parent report
    {
      this.attributes = [
        {name: 'Class', fieldName: 'classCourseName' },
        {name: 'Net Payable Amount', fieldName: 'netPayableAmount' },
        {name: 'Paid Amount', fieldName: 'paidAmount' },
        {name: 'Due Amount', fieldName: 'dueAmount' }
      ];
    }
    else if(reportType == 2) //child report (Fee head)
    {
      this.attributes = [
        {name: 'Fee Head', fieldName: 'feeHeadName' },
        {name: 'Net Payable Amount', fieldName: 'netPayableAmount' },
        {name: 'Paid Amount', fieldName: 'paidAmount' },
        {name: 'Due Amount', fieldName: 'dueAmount' }
      ];
    }
    
  }







}
