import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TeacherComponent } from './teacher/teacher.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RandomGuard } from '../authentication/guards/random.guard';

const routes: Routes = [
  {
    path: ':instcode/teacher',
    component: TeacherComponent,
    canActivate: [RandomGuard],
    canLoad: [RandomGuard],
    children: [
        {path: '', component: DashboardComponent},
        {path: 'dashboard', component: DashboardComponent}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class TeacherRoutingModule { }