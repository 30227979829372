import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {EventCalendar} from './eventcalendar.model';

@Injectable({
    providedIn: 'root'
  })
  export class EventCalendarService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<EventCalendar[]> {
      return this.httpClient.get<EventCalendar[]>(environment.apiUrl + '/EventCalendar/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getHolidaysCount(date:string): Observable<number> {
      return this.httpClient.get<number>(environment.apiUrl + '/EventCalendar/GetNumberOfHolidays/'+date)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<EventCalendar> {
      return this.httpClient.get<EventCalendar>(environment.apiUrl + '/EventCalendar/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    create(eventcalendar): Observable<EventCalendar> {
      return this.httpClient.post<EventCalendar>(environment.apiUrl + '/EventCalendar/', JSON.stringify(eventcalendar), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    update(id, eventcalendar): Observable<EventCalendar> {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.put<EventCalendar>(environment.apiUrl + '/EventCalendar/' + id, JSON.stringify(eventcalendar), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    delete(id) {
      return this.httpClient.delete<EventCalendar>(environment.apiUrl + '/EventCalendar/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }
}
