import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs"; 
import { ActivatedRoute,Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { SubjectCreation } from '../../../academic/academicsettings/subjectcreation/subjectcreation.model';
import { SubjectcreationService } from '../../../academic/academicsettings/subjectcreation/subjectcreation.service';
import { CreateQuestionService } from '../services/create-question.service';
import { DifficultyLevel } from '../../mastersettings/models/difficultylevel';
import { Topic } from '../../mastersettings/models/topic';
import { QuestionType } from '../../mastersettings/models/questiontype';
import { Language } from '../../mastersettings/models/language';
import { CreateQuestionModule } from './create-question.module';
import { CreateQuestion } from '../../mastersettings/models/createquestion';


@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.css'],
  providers: [FormGroupDirective],
})
export class CreateQuestionComponent implements OnInit {
  submitted = false; 
  finalexamNamedropDown: any = [];
  examClassdropDown: any = [];
  classDropDown: any = [];
  DetailsResult: any = [];
  subjects: SubjectCreation[] = [];
  topics: any = [];
  questionTypeList:any=[];
  difficultyLevelList:any=[];
  languageList:any=[];
  isLoading: boolean = false;
  isExplanation : boolean = false;
  isOptional : boolean = false;
  classes: ClassCourse[] = [];
  disabled = true;
  questionId = '';

  constructor(private toastr: ToastrService,
    public fb: UntypedFormBuilder,
    public service: CreateQuestionService,
    public subjectService: SubjectcreationService,
    public classcourseService: ClassCourseService,
    public formDirective: FormGroupDirective,
    private route: ActivatedRoute, private router: Router) { }

    createquestion: UntypedFormGroup;
    topicForm: UntypedFormGroup;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.questionId =params["Id"];
     });
    this.topicForm = this.fb.group({
      id: [0],
      topicName: ["", Validators.required]
    });

    this.createquestion = this.fb.group({
      id: [0],
      classId: ["", Validators.required],
      subjectId: ["", Validators.required],
      languageId: ["", Validators.required],
      topicId: ["", Validators.required], 
      questionTypeId: ["", Validators.required],
      difficultyLevelId: ["", Validators.required],
      marks: ["", Validators.required],
      question: ["", Validators.required],
      answer :["", Validators.required],
      documentList: this.fb.array([
          this.fb.group({
            id: [0],
            questionName: [''],
            optionName: ["A"]
        })
      ]),
    });   
    
    this.isExplanation = true;
    this.isOptional = false;  
    this.getClassDropDown();
    this.getSubjectList(0);
    this.getTopicList(0);
    this.getQuestionTypeList(0);
    this.getDifficultyLevelList(0);
    this.getLanguageList(0);
    if(this.questionId!==undefined)    
    {
      this.editCreateQuestion();
    }
  }

  get f() {
    return this.createquestion.controls;
  }

  get g() {
    return this.topicForm.controls;
  }
  submitForm() {
    this.submitted = true;
    console.log(this.topicForm.value);
    // this.leaveForm.get('source').patchValue('Online');
        // stop here if form is invalid
    if (this.topicForm.invalid) {
      if (this.g.topicName.errors !== null) {
        this.toastr.warning('All field are required', 'WARNING!!!', {
          timeOut: 3000
        });
      }
      return;
    }
    else
    {
      if (this.topicForm.get('id').value === 0) {        
        this.service.createTopic(this.topicForm.value).subscribe(res => {
          console.log('Topic created!');
          this.getTopicList(0);
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetTopicForm();
        });
      } else {
        this.service.updateTopic(this.topicForm.get('id').value, this.topicForm.value).subscribe(res => {
          console.log('Topic updated!');
          this.getTopicList(0);
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetTopicForm();
        });
      }
      this.getTopicList(0);  
    }
  }

  editTopic(id:number){
    this.service.getTopicById(id).subscribe((data:Topic)=>{
      this.topicForm=this.fb.group({
        id:[data.id],
        topicName:[data.topicName, Validators.required]
      })
    })
  }

  editCreateQuestion()
  {
    this.service.getExamQuestionById(this.questionId).subscribe((data:CreateQuestion)=>{
    if(data.questionTypeID===6)
    { 
      this.isExplanation = true;
      this.isOptional = false;  
    }
    else
    {
      this.isExplanation = false;
      this.isOptional = true; 
    }
      
      this.createquestion = this.fb.group({
        id: [data.id],
        classId: [data.classID, Validators.required],
        subjectId: [data.subjectID, Validators.required],
        languageId: [data.languageID, Validators.required],
        topicId: [data.topicID, Validators.required], 
        questionTypeId: [data.questionTypeID, Validators.required],        
        difficultyLevelId: [data.difficultyLevelID, Validators.required],
        marks: [data.marks, Validators.required],
        question: [data.question, Validators.required],
        answer :[data.answer, Validators.required],
        documentList: this.BindArr(data)
      })
    })
  }

  BindArr(data:CreateQuestion) {
    console.log(data)
    let arr = new UntypedFormArray([])
    if(data.documentList){
      data.documentList.forEach(y => {
        arr.push(this.fb.group({
          id: y.id,
          questionName: y.questionName,
          optionName: y.optionName,
          answerStatus:y.answerStatus,
        }))
  
    })
    return  arr;
    }
    
  }

  deleteTopic(id:number){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton:true,
      confirmButtonText:'Yes, delete it!',
      cancelButtonText:'No, keep it'
    }).then((result)=>{
      if(result.value){
        this.service.deleteTopic(id).subscribe(data=>{
          Swal.fire(
            'Deleted!',
            'Topic has been deleted.',
            'success'
          );
          this.getTopicList(0);
        })
      }
    })
  }


  getSubjectList(itemId){
    this.subjectService.getAll().subscribe((data) => {
      data = data.filter((item) => {
        return item.isDeleted==false;
      });
      console.log(data);
      this.subjects = data;
    });
  }

  getTopicList(itemId){
    this.service.getAllTopicList().subscribe((data:Topic[]) => {
      this.topics = data.filter((item) => {
        return item.isDeleted==false;
      });
      console.log(data);      
    });
  }

  getQuestionTypeList(itemId){
    this.service.getAllQuestionTypeList().subscribe((data:QuestionType[]) => {
      this.questionTypeList = data.filter((item) => {
        return item.isDeleted==false;
      });
      console.log(data);      
    });
  }

  getDifficultyLevelList(itemId){
    this.service.getAllDifficultyLevelList().subscribe((data:DifficultyLevel[]) => {
      this.difficultyLevelList = data.filter((item) => {
        return item.isDeleted==false;
      });
      console.log(data);      
    });
  }

  getLanguageList(itemId){
    this.service.getAllLanguageList().subscribe((data:Language[]) => {
      this.languageList = data;//.filter((item) => {
        //return item.isDeleted==false;
      //});
      console.log(data);      
    });
  }

  onClassEvent() {   
    this.checkingButton();   
    this.createquestion = this.fb.group({
      id: [0],
      classId: [this.createquestion.controls.classId.value, Validators.required],
      subjectId: ["", Validators.required],
      languageId: ["", Validators.required],
      topicId: ["", Validators.required], 
      questionTypeId: ["", Validators.required],
      difficultyLevelId: ["", Validators.required],
      marks: ["", Validators.required],
      question: ["", Validators.required],
      answer :["", Validators.required],
      documentList: this.fb.array([
        this.fb.group({
          id: [0],
          questionName: [''],
          optionName: ["A"]
        })
      ]),
    }); 
  }

  onSubjectEvent() {   
    this.checkingButton();   
    this.createquestion = this.fb.group({
      id: [0],
      classId: [this.createquestion.controls.classId.value, Validators.required],
      subjectId: [this.createquestion.controls.subjectId.value, Validators.required],
      languageId: ["", Validators.required],
      topicId: ["", Validators.required], 
      questionTypeId: ["", Validators.required],
      difficultyLevelId: ["", Validators.required],
      marks: ["", Validators.required],
      question: ["", Validators.required],
      answer :["", Validators.required],
      documentList: this.fb.array([
        this.fb.group({
          id: [0],
          questionName: [''],
          optionName: ["A"]
        })
      ]),
    }); 
  }

  onLanguageEvent() {   
    this.checkingButton();   
    this.createquestion = this.fb.group({
      id: [0],
      classId: [this.createquestion.controls.classId.value, Validators.required],
      subjectId: [this.createquestion.controls.subjectId.value, Validators.required],
      languageId: [this.createquestion.controls.languageId.value, Validators.required],
      topicId: ["", Validators.required], 
      questionTypeId: ["", Validators.required],
      difficultyLevelId: ["", Validators.required],
      marks: ["", Validators.required],
      question: ["", Validators.required],
      answer :["", Validators.required],
      documentList: this.fb.array([
        this.fb.group({
          id: [0],
          questionName: [''],
          optionName: ["A"]
        })
      ]),
    }); 
  }

  onTopicEvent() {   
    this.checkingButton();   
    this.createquestion = this.fb.group({
      id: [0],
      classId: [this.createquestion.controls.classId.value, Validators.required],
      subjectId: [this.createquestion.controls.subjectId.value, Validators.required],
      languageId: [this.createquestion.controls.languageId.value, Validators.required],
      topicId: [this.createquestion.controls.topicId.value, Validators.required], 
      questionTypeId: ["", Validators.required],
      difficultyLevelId: ["", Validators.required],
      marks: ["", Validators.required],
      question: ["", Validators.required],
      answer :["", Validators.required],
      documentList: this.fb.array([
        this.fb.group({
          id: [0],
          questionName: [''],
          optionName: ["A"]
        })
      ]),
    }); 
  }

  removeFormControl(i) {
    const usersArray = this.createquestion.get('documentList') as UntypedFormArray;
    usersArray.removeAt(i);
  }

  // patchDocument() {
  //   const control = this.createquestion.get('documentList') as FormArray;
  //   this.documnets.forEach(x => {
  //     control.push(this.patchDocumentValues(x));
  //   });
  // }

  // patchDocumentValues(document: StudentDocument) {
  //   return this.fb.group({
  //     id: document.id.toString(),
  //     fileName: document.fileName,
  //     contentType: document.contentType,
  //     contentFile: document.contentFile
  //   });
  // }

  addFormControl() {
    const documentArray = this.createquestion.get('documentList') as UntypedFormArray;
    const arraylen = documentArray.length;
    if(arraylen<=5)
    {
      if(arraylen===1)
      {
        const newUsergroup: UntypedFormGroup = this.fb.group({
          id: [1],
          questionName: [''],
          optionName: ['B']
        });
        documentArray.insert(arraylen, newUsergroup);
      }
      if(arraylen===2)
      {
        const newUsergroup: UntypedFormGroup = this.fb.group({
          id: [2],
          questionName: [''],
          optionName: ['C']
        });
        documentArray.insert(arraylen, newUsergroup);
      }
      if(arraylen===3)
      {
        const newUsergroup: UntypedFormGroup = this.fb.group({
          id: [3],
          questionName: [''],
          optionName: ['D']
        });
        documentArray.insert(arraylen, newUsergroup);
      }
      if(arraylen===4)
      {
        const newUsergroup: UntypedFormGroup = this.fb.group({
          id: [4],
          questionName: [''],
          optionName: ['E']
        });
        documentArray.insert(arraylen, newUsergroup);
      }
      if(arraylen===5)
      {
        const newUsergroup: UntypedFormGroup = this.fb.group({
          id: [5],
          questionName: [''],
          optionName: ['F']
        });
        documentArray.insert(arraylen, newUsergroup);
      }
        
    }
    else
    {
      this.toastr.error('Maximum 6 option are allow!!!!', 'ERROR!', {
        timeOut: 3000
      });
      return;
    }
    console.log(documentArray);
  }

  //get OptionFormArray() { return this.createquestion.get('documentList') as FormArray; }
  //get OptionsItems() {
      //return this.OptionFormArray.controls.map((control) => control.value);
  //}

  onQuestionTypeEvent(id) {   
    this.checkingButton();
    if(id==="6")
    { 
      this.isExplanation = true;
      this.isOptional = false;  
    }
    else
    {
      this.isExplanation = false;
      this.isOptional = true; 
    }
    this.createquestion = this.fb.group({
      id: [0],
      classId: [this.createquestion.controls.classId.value, Validators.required],
      subjectId: [this.createquestion.controls.subjectId.value, Validators.required],
      languageId: [this.createquestion.controls.languageId.value, Validators.required],
      topicId: [this.createquestion.controls.topicId.value, Validators.required], 
      questionTypeId: [this.createquestion.controls.questionTypeId.value, Validators.required],
      difficultyLevelId: ["", Validators.required],
      marks: ["", Validators.required],
      question: ["", Validators.required],
      answer :["", Validators.required],
      documentList: this.fb.array([
        this.fb.group({
          id: [0],
          questionName: [''],
          optionName: ["A"]
        })
      ]),
    }); 
  }

  onDifficultyLevelEvent() {   
    this.checkingButton();   
    this.createquestion = this.fb.group({
      id: [0],
      classId: [this.createquestion.controls.classId.value, Validators.required],
      subjectId: [this.createquestion.controls.subjectId.value, Validators.required],
      languageId: [this.createquestion.controls.languageId.value, Validators.required],
      topicId: [this.createquestion.controls.topicId.value, Validators.required], 
      questionTypeId: [this.createquestion.controls.questionTypeId.value, Validators.required],
      difficultyLevelId: [this.createquestion.controls.difficultyLevelId.value, Validators.required],
      marks: ["", Validators.required],
      question: ["", Validators.required],
      answer :["", Validators.required],
      documentList: this.fb.array([
        this.fb.group({
          id: [0],
          questionName: [''],
          optionName: ["A"]
        })
      ]),
    }); 
  }

  getClassDropDown() {       
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classDropDown = data;
      this.examClassdropDown = data;
      this.checkingButton();
    });
    
      this.createquestion = this.fb.group({
        id: [0],
        classId: [this.createquestion.controls.classId.value, Validators.required],
        subjectId: ["", Validators.required],
        languageId: ["", Validators.required],
        topicId: ["", Validators.required], 
        questionTypeId: ["", Validators.required],
        difficultyLevelId: ["", Validators.required],
        marks: ["", Validators.required],
      question: ["", Validators.required],
      answer :["", Validators.required],
        documentList: this.fb.array([
          this.fb.group({
            id: [0],
            questionName: [''],
            optionName: ["A"]
          })
        ]),
      }); 
  }
  checkingButton() {
    if (
      this.createquestion.controls.classId.value &&
      this.createquestion.controls.subjectId.value &&
      this.createquestion.controls.languageId.value &&
      this.createquestion.controls.topicId.value &&
      this.createquestion.controls.questionTypeId.value &&
      this.createquestion.controls.difficultyLevelId.value
    ) {
      this.disabled = false;
    } 
  }

  submitcreatequestions() {
    //this.createquestion.value.answer = "op";
    this.createquestion.get('documentList').value.toString();
    if (this.createquestion.invalid) {
      if (this.f.question.errors !== null) {
        this.toastr.warning('All field are required', 'WARNING!!!', {
          timeOut: 3000
        });
      }
      return;
    }
    else
    {
      if (this.createquestion.get('id').value === 0) {        
        this.service.createExamQuestion(this.createquestion.value).subscribe(res => {
          console.log('Question created!');
          //this.getTopicList(0);
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
      } else {
        this.service.updateExamQuestion(this.createquestion.get('id').value, this.createquestion.value).subscribe(res => {
          console.log('Question updated!');
          //this.getTopicList(0);
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
      }
      //this.getTopicList(0);  
    }       
  }

  importcreatequestions() {
        
  }

  resetcreatequestions() {
      this.submitted=false;
      this.resetForm();       
  }

  resetForm() {
    this.submitted = false;
    this.finalexamNamedropDown = [];
    this.classDropDown = [];
    this.formDirective.reset();
    this.createquestion.reset();
    this.disabled = true;
    documentList: this.fb.array([
      this.fb.group({
        id: [0],
        questionName: [''],
        optionName: ["A"]
      })
    ]),
    this.createquestion = this.fb.group({
        id: [0],
        classId: ["", Validators.required],
        subjectId: ["", Validators.required],
        languageId: ["", Validators.required],
        topicId: ["", Validators.required], 
        questionTypeId: ["", Validators.required],
        difficultyLevelId: ["", Validators.required],
        marks: ["", Validators.required],
        question: ["", Validators.required],
        answer :["", Validators.required],
        documentList: this.fb.array([
          this.fb.group({
            id: [0],
            questionName: [''],
            optionName: ["A"]
          })
        ]),
      });
  }

  resetTopicForm() {
    this.submitted = false;    
    this.topicForm=this.fb.group({
      id:[0],
      topicName:['',Validators.required]
    })      
  }
}
