import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MastersettingsComponent } from './mastersettings/mastersettings.component';
import { AcademicyearComponent } from './academicyear/academicyear.component';
import { ClasscourseComponent } from './classcourse/classcourse.component';
import { CampusbuildingComponent } from './campusbuilding/campusbuilding.component';
import { CasteComponent } from './caste/caste.component';
import { CategoryComponent } from './category/category.component';
import { CityComponent } from './city/city.component';
import { CountryComponent } from './country/country.component';
import { FloorComponent } from './floor/floor.component';
import { NationalityComponent } from './nationality/nationality.component';
import { PaymentmodeComponent } from './paymentmode/paymentmode.component';
import { ReligionComponent } from './religion/religion.component';
import { RoomComponent } from './room/room.component';
import { StateComponent } from './state/state.component';
import { StreamComponent } from './stream/stream.component';
import { TermComponent } from './term/term.component';
import { BatchComponent } from './batch/batch.component';

const routes: Routes = [
    {path: 'mastersettings', component: MastersettingsComponent},
    {path: 'mastersettings/academicyear', component: AcademicyearComponent},
    {path: 'mastersettings/batch', component: BatchComponent},
    {path: 'mastersettings/campusbuilding', component: CampusbuildingComponent},
    {path: 'mastersettings/classcourse', component: ClasscourseComponent},
    {path: 'mastersettings/category', component: CategoryComponent},
    {path: 'mastersettings/caste', component: CasteComponent},
    {path: 'mastersettings/city', component: CityComponent},
    {path: 'mastersettings/country', component: CountryComponent},
    {path: 'mastersettings/floor', component: FloorComponent},
    {path: 'mastersettings/nationality', component: NationalityComponent},
    {path: 'mastersettings/paymentmode', component: PaymentmodeComponent},
    {path: 'mastersettings/religion', component: ReligionComponent},
    {path: 'mastersettings/room', component: RoomComponent},
    {path: 'mastersettings/state', component: StateComponent},
    {path: 'mastersettings/stream', component: StreamComponent},
    {path: 'mastersettings/term', component: TermComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MasterSettingsRoutingModule { }
