// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
    .page-break {
        page-break-after: always;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/admin/administration/certificate/printcertificate/printcertificate.component.css"],"names":[],"mappings":"AAAA;IACI;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":["@media print {\r\n    .page-break {\r\n        page-break-after: always;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
