import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiConfigurationModel, ProviderServiceModel} from './apiconfiguration.model';
import {AddServiceModel} from '../addonservice/addServiceModel';

@Injectable({
  providedIn: 'root'
})
export class ApiConfigurationService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<ApiConfigurationModel[]> {
    return this.httpClient.get<ApiConfigurationModel[]>(environment.apiUrl + '/ApiConfiguration/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllService(providerId): Observable<ProviderServiceModel[]> {
    return this.httpClient.get<ProviderServiceModel[]>(environment.apiUrl + '/ApiConfiguration/GetAllService')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<ApiConfigurationModel> {
    return this.httpClient.get<ApiConfigurationModel>(environment.apiUrl + '/ApiConfiguration/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getService(): Observable<ApiConfigurationModel[]> {
    return this.httpClient.get<ApiConfigurationModel[]>(environment.apiUrl + '/ApiConfiguration/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(apiConfiguration): Observable<ApiConfigurationModel> {
  return this.httpClient.post<ApiConfigurationModel>(environment.apiUrl
     + '/ApiConfiguration/', JSON.stringify(apiConfiguration), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, apiConfiguration): Observable<ApiConfigurationModel> {
    return this.httpClient.put<ApiConfigurationModel>(environment.apiUrl
      + '/ApiConfiguration/' + id, JSON.stringify(apiConfiguration), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<ApiConfigurationModel>(environment.apiUrl + '/ApiConfiguration/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  saveServices(providerService) {
    return this.httpClient.post<ProviderServiceModel>(environment.apiUrl
      + '/ApiConfiguration/SaveService/', JSON.stringify(providerService), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );

  }
  updateService(serviceId, providerService): Observable<ProviderServiceModel> {
    return this.httpClient.put<ProviderServiceModel>(environment.apiUrl
      + '/ApiConfiguration/UpdateService/' + parseInt(serviceId, 0), JSON.stringify(providerService), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  deleteService(serviceId) {
    return this.httpClient.delete<ProviderServiceModel>(environment.apiUrl + '/ApiConfiguration/DeleteService/' + serviceId)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  /*List Api provider service */
  GetApiConfigProviderServices(providerId): Observable<ProviderServiceModel[]> {
    return this.httpClient.get<ProviderServiceModel[]>(environment.apiUrl
     + '/ApiConfiguration/GetApiConfigProviderServices/' + parseInt(providerId, 0))
    .pipe(
      catchError(this.errorHandler)
    );
  }
  GetProvederServiceById(serviceId): Observable<ProviderServiceModel> {
    return this.httpClient.get<ProviderServiceModel>(environment.apiUrl + '/ApiConfiguration/GetProvederServiceById/' + serviceId)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }
//Add Service APi
AddsaveServicesAPI(providerService) {debugger;
  return this.httpClient.post<AddServiceModel>(environment.apiUrl
    + '/AddService/SaveServiceApi/', JSON.stringify(providerService), this.httpOptions)
  .pipe(
    catchError(this.errorHandler)
  );

}
updateServiceAPI(serviceId, providerService): Observable<AddServiceModel> {
  return this.httpClient.put<AddServiceModel>(environment.apiUrl
    + '/AddService/UpdateServiceApi/' + parseInt(serviceId, 0), JSON.stringify(providerService), this.httpOptions)
  .pipe(
    catchError(this.errorHandler)
  );
}
deleteServiceAPI(serviceId) {
  return this.httpClient.delete<AddServiceModel>(environment.apiUrl + '/AddService/DeleteServiceApi/' + serviceId)
  .pipe(
    catchError(this.errorHandler)
  );
}
GetServiceAPI(serviceId): Observable<AddServiceModel[]> {
  return this.httpClient.get<AddServiceModel[]>(environment.apiUrl + '/AddService/GetAllServiceApi/'+ serviceId)
  .pipe(
    catchError(this.errorHandler)
  );
}

GetServiceAPIById(serviceId): Observable<AddServiceModel> {
  return this.httpClient.get<AddServiceModel>(environment.apiUrl + '/AddService/GetServiceApiById/' + serviceId)
  .pipe(
    catchError(this.errorHandler)
  );
}

GetApiConfig(providerId): Observable<ProviderServiceModel[]> {
  return this.httpClient.get<ProviderServiceModel[]>(environment.apiUrl
   + '/ApiConfiguration/GetApiConfig/' + parseInt(providerId, 0))
  .pipe(
    catchError(this.errorHandler)
  );
}
}
