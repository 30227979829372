import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl  } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BillingCommonServices } from '../commonServices/billing.common.service';
import { Institution, InstitutionBranch } from '../../institutionmanagement/models/institution.model';
import { PaymentDetails } from '../models/paymentDetails';
declare var $: any;

@Component({
  selector: 'app-update-payment',
  templateUrl: './update-payment.component.html',
  styleUrls: ['./update-payment.component.css']
})
export class UpdatePaymentComponent implements OnInit {
  

  constructor(private billingCommonService: BillingCommonServices, private toster: ToastrService, private fb: UntypedFormBuilder) { }
  
  institutionList: Institution[] = [];
  institutionFilteredList: Institution[] = [];
  branchList: InstitutionBranch[] = [];
  selectedBranch: InstitutionBranch = {} as InstitutionBranch;
  paymentDetailsList: PaymentDetails[] = [];
  selectedPayment: PaymentDetails;
  paymentForm: UntypedFormGroup;
  institutionNameArray: string[];
  countryNameArray: string[];
  stateNameArray: string[];
  cityNameArray: string[];
  SelectedinstitutionId:number;
  selectedBranchId:number;
  getAllInstitutionDetails() {
    this.billingCommonService.getAllInstitutions().subscribe((data: Institution[]) => {     
       
      this.institutionFilteredList = this.institutionList = data;
      ;
      console.log(this.institutionFilteredList);
      this.fetchAllInstitutionNameFormInstitutionList(data);
      this.fetchAllCountryNameFormInstitutionList(data);
      this.fetchAllStateNameFormInstitutionList(data);
      this.fetchAllCityNameFormInstitutionList(data);
    });
  }
  ActiveStudent:number;

  getBranch(institutionId: any,ActiveStudent:any) {

    this.setForm();
    this.SelectedinstitutionId=institutionId;
    this.selectedBranchId=0
    this.paymentForm.disable();
    this.ActiveStudent=ActiveStudent;
    const currentValue: boolean = this.institutionFilteredList.find(institute => institute.id === institutionId).isBranchVisible;
    if (currentValue === true) {
      this.institutionFilteredList.find(institute => institute.id === institutionId).isBranchVisible = false;
      $('#tblBranchDetails').hide();
      $('#divPaydetails').hide();
    } else {
      this.institutionFilteredList.forEach(b => b.isBranchVisible = false);
      this.billingCommonService.getAllBranches(institutionId).subscribe((data: InstitutionBranch[]) => {
        this.branchList = data;
        ;
        $('#tblBranchDetails').show();
        $('#divPaydetails').hide();
        this.institutionFilteredList.find(institute => institute.id === institutionId).isBranchVisible = true;
      });
     
    }

  //   $("tbody tr").click(function() {   
  //     $(this).addClass('table-success').siblings().removeClass("table-success");
  // });
    // $("#tblBranchDetails").toggle('fadeOut');

    // $(this).find('i').toggleClass('fas fa-plus-square fas fa-minus text-danger');
  }
  getPaymentDetails(branchId: any,price:number) {
    alert(branchId)
    this.selectedBranchId=branchId
    this.disable=false;
    this.setForm();
    if(this.ActiveStudent>0)
    this.paymentForm.get('totalAmount').patchValue(this.ActiveStudent*price);
    if ((this.selectedBranch.id === branchId) && (this.selectedBranch.isPaymentVisible === true)) {
      this.branchList.find(branchItem => branchItem.id === branchId).isPaymentVisible = false;
      $("#divPaydetails").hide();
    } else if ((this.selectedBranch.id === branchId) && (this.selectedBranch.isPaymentVisible === false)) {
      this.branchList.find(branchItem => branchItem.id === branchId).isPaymentVisible = true;
      $("#divPaydetails").show();
    } else {
      this.branchList.forEach(b => b.isPaymentVisible = false);
      this.selectedBranch = this.branchList.find(branchItem => branchItem.id === branchId);
      this.branchList.find(branchItem => branchItem.id === branchId).isPaymentVisible = !this.selectedBranch.isPaymentVisible;
      $("#divPaydetails").show();
    }
    this.billingCommonService.getAllPaymentsByInstitutionId(branchId).subscribe((data: PaymentDetails[]) => {      
      this.paymentDetailsList = data;      
    });

  }

  isDue(dueDate: any) {
    const currentDate = new Date();

    if (new Date(dueDate).getTime() <= currentDate.getTime()) {
      return false;
    }
    return true;
  }

  onCheckPayment(event: any, paymentId: any) {
    if (event.target.checked) {
      this.paymentDetailsList.forEach(p => p.isChecked = false);
      this.paymentDetailsList.find(element => element.id == paymentId).isChecked = true;
      this.fillPaymantForm(paymentId);
    } else {
      this.resetForm();
    }
  }
  fillPaymantForm(paymentId: any) {
    const gstPercentage: number = 18.0;

    this.selectedPayment = this.paymentDetailsList.find(payment => payment.id === paymentId);

    const discoutedAmount: any = Number.parseInt(this.selectedPayment.totalAmount, 0)
     - Number.parseInt(this.selectedPayment.discountAmount, 0);
    const gstValue: any = (discoutedAmount * gstPercentage) / 100;
    const dueAmt: number = discoutedAmount + gstValue;

    // this.paymentForm.get('id').patchValue(this.selectedPayment.id);
    this.paymentForm.get('institutionId').patchValue(this.selectedPayment.institutionId);
    this.paymentForm.get('totalAmount').patchValue(this.selectedPayment.totalAmount);
    this.paymentForm.get('discount').patchValue(this.selectedPayment.discountAmount);
    this.paymentForm.get('gst').patchValue(gstValue);



    this.paymentForm.get('paidAmount').patchValue(this.selectedPayment.paidAmount);
    this.paymentForm.get('dueAmount').patchValue(dueAmt);
    this.paymentForm.get('paymentMode').patchValue(this.selectedPayment.paymentMode);
  }

  searchInstitutionById(institutionId: any) {
    
    if (institutionId === '') {
      this.institutionFilteredList = this.institutionList;
      return;
    }
    this.institutionFilteredList = this.institutionList.filter(element =>
      element.institutionCode.toLowerCase() === institutionId.toLowerCase());
    this.branchList = null;
    if (this.institutionFilteredList.length === 0) {
      this.toster.warning('No Institution details Found!', 'Sorry', {
        timeOut: 3000
      });
      this.institutionFilteredList = this.institutionList;

    }
  }

  makePayment() {
    
    this.paymentForm.value.BranchId=this.selectedBranchId;
    this.paymentForm.value.institutionId=this.SelectedinstitutionId;
    if( this.paymentForm.value.BranchId==0 &&  this.paymentForm.value.institutionId==0){
      this.toster.warning('Brabch row not selected', 'Sorry!', {
        timeOut: 3000
      });
      return;
    }
    if (this.paymentForm.invalid) {
      this.toster.warning('The form is not valid!', 'Sorry!', {
        timeOut: 3000
      });
    } else {
      this.billingCommonService.makePayment(this.paymentForm.value).subscribe(res => {
        this.toster.success('Payment data has been successfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.getPaymentDetails(this.selectedBranch.id,0);
        this.resetForm();
      });
    }


  }
  searchByCountry(countryId: any) {
    ;
    if (countryId === '') {
      this.institutionFilteredList = this.institutionList;
      return;
    }
    this.institutionFilteredList = this.institutionList.filter(element => element.countryId === countryId);
    if (this.institutionFilteredList.length === 0) {
      this.toster.warning('No Institution Found!', 'Sorry', {
        timeOut: 3000
      });
      //this.institutionFilteredList = this.institutionList;
    }
  }
  searchByCity(cityId: any) {
    if (cityId === '') {
      this.institutionFilteredList = this.institutionList;
      return;
    }
    this.institutionFilteredList = this.institutionList.filter(element => element.cityId === cityId);
    if (this.institutionFilteredList.length === 0) {
      this.toster.warning('No Institution Found!', 'Sorry', {
        timeOut: 3000
      });
      this.institutionFilteredList = this.institutionList;
    }
  }
  searchByState(stateName: any) {
    if (stateName === '') {
      this.institutionFilteredList = this.institutionList;
      return;
    }
    this.institutionFilteredList = this.institutionList.filter(element => element.stateId === stateName);
    if (this.institutionFilteredList.length === 0) {
      this.toster.warning('No Institution Found!', 'Sorry', {
        timeOut: 3000
      });
      this.institutionFilteredList = this.institutionList;
    }
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    } else {
      return false;
    }
  }
  calculateGST() {
    let gstPercentage: number = 18.0;
    const discount = this.paymentForm.get('discount').value == "" ? 0 : Number.parseInt(this.paymentForm.get('discount').value);

    const discountedAmount: any = Number.parseInt(this.paymentForm.get('totalAmount').value) - discount;
    const gstValue: any = Math.round((discountedAmount * gstPercentage) / 100);
    this.paymentForm.get('gst').patchValue(gstValue);
    const dueAmt: number = Math.round(discountedAmount + gstValue);
    this.paymentForm.get('dueAmount').patchValue(dueAmt);

  }
  calculateDueAmount(paidAmt: any) {
    this.calculateGST();

    const dueAmount = Number.parseInt(this.paymentForm.get('dueAmount').value, 0) - paidAmt;
    this.paymentForm.get('dueAmount').patchValue(dueAmount);

  }
  fetchAllInstitutionNameFormInstitutionList(institutionList: Institution[]) {
    const nameArray = institutionList.map(institution => institution.institutionCode + '-' + institution.institutionName);

    this.institutionNameArray = nameArray.filter((value, index) =>
      nameArray.indexOf(value) === index).filter((el) => {
        return el != null && el !== '';
      });

  }
  fetchAllCountryNameFormInstitutionList(institutionList: Institution[]) {
    const nameArray = institutionList.map(institution => institution.country);
 
    this.countryNameArray = nameArray.filter((value, index) =>
      nameArray.indexOf(value) === index).filter((el) => {
        return el != null && el !== '';
      });

  }
  fetchAllStateNameFormInstitutionList(institutionList: Institution[]) {
    const nameArray = institutionList.map(institution => institution.state);

    this.stateNameArray = nameArray.filter((value, index) =>
      nameArray.indexOf(value) === index).filter((el) => {
        return el != null && el !== '';
      });

  }
  fetchAllCityNameFormInstitutionList(institutionList: Institution[]) {
    const nameArray = institutionList.map(institution => institution.city);

    this.cityNameArray = nameArray.filter((value, index) =>
      nameArray.indexOf(value) === index).filter((el) => {
        return el != null && el !== '';
      });

  }
  searchByInstitutionName(institutionName: any) {
    if (institutionName === '') {
      this.institutionFilteredList = this.institutionList;
      return;
    }
    institutionName = institutionName.substr(institutionName.lastIndexOf('-') + 1); // get institution name from id-name combination
    console.log(institutionName);
    this.institutionFilteredList = this.institutionList.filter(element => element.institutionName === institutionName);
    if (this.institutionFilteredList.length === 0) {
      this.toster.warning('No Institution Found!', 'Sorry', {
        timeOut: 3000
      });
      this.institutionFilteredList = this.institutionList;
    }
  }

  resetForm() {
    this.setForm();
  }
  setForm() {
  
    this.paymentForm = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      institutionId: new UntypedFormControl(''),
      totalAmount: new UntypedFormControl({value:'',disabled:true}),
      discount:new UntypedFormControl({value:'',disabled:this.disable}),
      gst: new UntypedFormControl({value:'',disabled:this.disable}),
      paidAmount:  new UntypedFormControl({value:'',disabled:this.disable},Validators.required),
      dueAmount: new UntypedFormControl({value:'',disabled:this.disable}),
      paymentMode: new UntypedFormControl({value:'',disabled:this.disable},Validators.required),
      remarks: new UntypedFormControl({value:'',disabled:this.disable},Validators.required),
      BranchId: new UntypedFormControl ('')

    });


  }
  // @Input() set disableControl( condition : boolean ) {
  //   const action = condition ? 'disable' : 'enable';
  //   this.ngControl.control[action]();
  // }
  disable :boolean=false;
  ngOnInit(): void { 
    this.setForm();
    $(document).ready(function () {
      $("#divPaydetails").hide();
      $("#tblBranchDetails").hide();
    });

    this.getAllInstitutionDetails();
    // this.selectedBranch = { id: 0, institutionName: '', status: 'true', countryID: '', pubCityId: '',
    //  landLineNumbe: '', websiteURL: '', institutionType: '', weekStartDay: '', timeZone: '', countryCode: '',
    //  schoolCodeId: '', schoolLogo: '', addressLocation: '', stateId: '', contactNumber: '', emailId: '',
    //  affliationId: '', affliationType: '', medium: '', isBranchVisible: false, isPaymentVisible: false,
    //   country: "", state: "", city: "" };

  }
 resetfilter(){
   $("#intiId").val('');
   $("#select2institution").val("");
   $("#state").val("");
   $("#country").val("");
   this.getAllInstitutionDetails();
  this.SelectedinstitutionId=0;
  this.selectedBranchId=0;
 }

}
