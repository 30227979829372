import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamrankService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private http:HttpClient) { }
 // url:string="http://localhost:5000/";
  getClassdropdown(){
    return this.http.get(environment.apiUrl+"/Designation/Classes").pipe(
      catchError(this.errorHandler)
    )
  }
createexamranking(data:any){
  return this.http.post<any>(environment.apiUrl + "/Ranking", JSON.stringify(data), this.httpOptions)
  .pipe(
    catchError(this.errorHandler)
  )
}

saveRanking(id:any,value:any){
 
  return this.http.put(environment.apiUrl+"/Ranking/"+id,JSON.stringify(value),this.httpOptions).pipe(
    catchError(this.errorHandler)
  )
}
getexamranking(){
  return this.http.get(environment.apiUrl+ "/Ranking").pipe(
    catchError(this.errorHandler)
  )
}

getExamrankbyid(id:any){
  return this.http.get(environment.apiUrl+"/Ranking/"+id).pipe(
    catchError(this.errorHandler)
  )

}

deleteRank(id:any){
  return this.http.delete(environment.apiUrl+"/Ranking/"+id,this.httpOptions).pipe(
    catchError(this.errorHandler)
  )
}
// saveRank(id:any,value:any){
//  
//   return this.http.put(this.url+"api/Ranking/"+id,JSON.stringify(value),this.httpOptions).pipe(
//     catchError(this.errorHandler)
//   )
// }

  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
