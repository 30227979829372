// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    font-family: 'Lato', sans-serif;
    color: #888;
    margin: 0;
}

#main{
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
}

.fof{
	  display: table-cell;
	  vertical-align: middle;
}

.fof h1{
	  font-size: 50px;
	  display: inline-block;
	  padding-right: 12px;
	  animation: type .5s alternate infinite;
}

@keyframes type{
	  from{box-shadow: inset -3px 0px 0px #888;}
	  to{box-shadow: inset -3px 0px 0px transparent;}
}`, "",{"version":3,"sources":["webpack://./src/app/admin/not-found/not-found.component.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,WAAW;IACX,SAAS;AACb;;AAEA;IACI,cAAc;IACd,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;;AAEA;GACG,mBAAmB;GACnB,sBAAsB;AACzB;;AAEA;GACG,eAAe;GACf,qBAAqB;GACrB,mBAAmB;GACnB,sCAAsC;AACzC;;AAEA;GACG,KAAK,mCAAmC,CAAC;GACzC,GAAG,0CAA0C,CAAC;AACjD","sourcesContent":["body{\r\n    font-family: 'Lato', sans-serif;\r\n    color: #888;\r\n    margin: 0;\r\n}\r\n\r\n#main{\r\n    display: table;\r\n    width: 100%;\r\n    height: 100vh;\r\n    text-align: center;\r\n}\r\n\r\n.fof{\r\n\t  display: table-cell;\r\n\t  vertical-align: middle;\r\n}\r\n\r\n.fof h1{\r\n\t  font-size: 50px;\r\n\t  display: inline-block;\r\n\t  padding-right: 12px;\r\n\t  animation: type .5s alternate infinite;\r\n}\r\n\r\n@keyframes type{\r\n\t  from{box-shadow: inset -3px 0px 0px #888;}\r\n\t  to{box-shadow: inset -3px 0px 0px transparent;}\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
