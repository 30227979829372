import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExamPlannerComponent } from './exam-planner/exam-planner/exam-planner.component';
import { OfflineExaminationComponent } from './offline-examination/offline-examination.component';
import { ExamPlannerModule } from './exam-planner/exam-planner.module';
import { ExamReportCardComponent } from './exam-report-card/exam-report-card.component';
import { ExamReportCardTermWiseComponent } from './exam-report-card-term-wise/exam-report-card-term-wise.component';
import { ExamReportCardExamWiseComponent } from './exam-report-card-exam-wise/exam-report-card-exam-wise.component';

const routes: Routes = [
  {
    path:'offlineexamination', component: OfflineExaminationComponent,
    children:[
      {path:'examsetting',loadChildren:()=>import('./ExamSetting/exam-setting-routing.module').then(m=>m.ExamSettingRoutingModule)},
      {
        path:'ExamPlanner',
        loadChildren: () =>
        import('./exam-planner/exam-planner.module').then(m => m.ExamPlannerModule)
      },
      {
        path:'ExamScheduling',
        loadChildren: () =>
        import('./exam-scheduling/exam-scheduling.module').then(m => m.ExamSchedulingModule)
      },
      {
        path:'AssignExamMarks',
        loadChildren: () =>
        import('./assign-exam-marks/assign-exam-marks.module').then(m => m.AssignExamMarksModule)
      },
      {
        path:'MarksEntry',
        loadChildren: () =>
        import('./marks-entry/marks-entry.module').then(m => m.MarksEntryModule)
      },
      {
        path:'FreezeExamMark',
        loadChildren: () =>
        import('./freeze-exam-mark/freeze-exam-mark.module').then(m => m.FreezeExamMarkModule)
      },
      {
        path:'ExamRemark',
        loadChildren: () =>
        import('./examremarks/examremarks.module').then(m => m.ExamremarksModule)
      },
      {
        path:'ExamRanking',
        loadChildren: () =>
        import('./examranking/examranking.module').then(m => m.ExamrankingModule)
      },
      {
        path:'ExamWiseAttendence',
        loadChildren: () =>
        import('./exam-wise-attendence/exam-wise-attendence.module').then(m => m.ExamWiseAttendenceModule)
      },
      {
        path:'ExamResultReport',
        loadChildren: () =>
        import('./exam-result-report/exam-result-report.module').then(m => m.ExamResultReportModule)
      },
      {
        path:'StudentWiseReportCard',
        loadChildren: () =>
        import('./student-wise-report-card/student-wise-report-card.module').then(m => m.StudentWiseReportCardModule)
      },
      {
        path:'PublishResultReport',
        loadChildren: () =>
        import('./publish-result-report/publish-result-report.module').then(m => m.PublishResultReportModule)
      },
      {
        path:'FinalReportCard',
        loadChildren: () =>
        import('./final-report-card/final-report-card.module').then(m => m.FinalReportCardModule)
      } ,
      {
        path:'TeacherWiseExamReport',
        loadChildren: () =>
        import('./teacher-wise-exam-report/teacher-wise-exam-report.module').then(m => m.TeacherWiseExamReportModule)
      },
      {
        path:'SubjectWiseExamReport',
        loadChildren: () =>
        import('./subject-wise-exam-report/subject-wise-exam-report.module').then(m => m.SubjectWiseExamReportModule)
      } ,
      {
        path:'ConsolidateExamReport',
        loadChildren: () =>
        import('./consolidate-exam-report/consolidate-exam-report.module').then(m => m.ConsolidateExamReportModule)
      } 
    ]
  },
  {path: 'offlineexamination/examreportcard/:academicYearId/:classId/:batchid/:studentid', component: ExamReportCardComponent},
  {path: 'offlineexamination/examreportcardtermwise/:academicYearId/:termId/:classId/:batchid/:studentid', component: ExamReportCardTermWiseComponent},
  {path: 'offlineexamination/examreportcardexamwise/:academicYearId/:termId/:examId/:classId/:batchid/:studentid', component: ExamReportCardExamWiseComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfflineExaminationRoutingModule { }
