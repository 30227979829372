import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {  throwError, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Notification, BroadcastStatus } from './notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private messageList = new Subject<any>();
  private messageCount = new Subject<any>();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      skip: 'true'
    })
  };
  constructor(private httpClient: HttpClient) { }

  sendUpdate(muList: Notification[]) {
    this.messageList.next(muList);
  }

  getUpdate(): Observable<any> {
      return this.messageList.asObservable();
  }

  setMessageCount(mCount: any) {
    this.messageCount.next(mCount);
  }

  getMessageCount(): Observable<any> {
      return this.messageCount.asObservable();
  }

  getAll(): Observable<Notification[]> {

    return this.httpClient.get<Notification[]>(environment.apiUrl + '/notification/', this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<Notification> {
    return this.httpClient.get<Notification>(environment.apiUrl + '/notification/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getBroadcastStatusById(id): Observable<BroadcastStatus[]> {
    return this.httpClient.get<BroadcastStatus[]>(environment.apiUrl + '/notification/GetBroadcastStatusByID/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(notification): Observable<Notification> {
    return this.httpClient.post<Notification>(environment.apiUrl + '/notification/', JSON.stringify(notification), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, notification): Observable<Notification> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<Notification>(environment.apiUrl + '/notification/' + id, JSON.stringify(notification), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  updateAllViews(): Observable<any> {
    return this.httpClient.get<any>(environment.apiUrl + '/notification/UpdateAllViews', this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  updateIsViewed(id, notification): Observable<Notification> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<Notification>(environment.apiUrl + '/notification/' + id,
    JSON.stringify(notification), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<Notification>(environment.apiUrl + '/notification/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
