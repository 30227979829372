import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {SubscriptionManagerModel, MainModule, SubModule} from './subscriptionmanager.model';
import {SubscriptionManagerService} from './subscriptionmanager.service';

@Component({
  selector: 'app-subscriptionmanager',
  templateUrl: './subscriptionmanager.component.html',
  styleUrls: ['./subscriptionmanager.component.css']
})
export class SubscriptionmanagerComponent implements OnInit {
 /******sub checkbox list*******/

 checkboxesDataList : any = [];
 item: any = "";
/*************************/
  subscriptionForm: UntypedFormGroup;
  form: UntypedFormGroup;
  selectedModuleIds = '';
  selectedSubModulesIds = '';
  submitted = false;
  currentDate: string;
  subscriptions: SubscriptionManagerModel[] = [];
  filteredSubscriptions: SubscriptionManagerModel[] = [];
  mainModule: MainModule[] = [];
  mainFilteredModule: MainModule[] = [];
  selectedModules: MainModule[] = [];

  constructor(
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public subscriptionService: SubscriptionManagerService
  ) { }

  ListSubscriptionModule() {
    this.subscriptionService.getAll().subscribe((data) => {
        console.log(data);
        this.subscriptions = data;
    });
  }

  getAllModules() {
    this.subscriptionService.getAllModules().subscribe((data) => {
        var subMenus = [];
        this.mainModule = data;
        this.mainModule.forEach(item => {
          item.subModules = item.mainMenus;
          item.subModules?.forEach(subModule => {
            subMenus = [];
            subModule.subMenus?.forEach(subMenu1 => {
              if (subMenu1?.subMenus?.length === 0 ) {
                subMenus.push(subMenu1);
              } else {
                subMenu1.subMenus?.forEach(subMenu2 => {
                  if (subMenu2?.subMenus?.length === 0 ) {
                    subMenus.push(subMenu2);
                  } else {
                    subMenu2.subMenus?.forEach(subMenu3 => {
                      if (subMenu3?.subMenus?.length === 0 ) {
                        subMenus.push(subMenu3);
                      } else {
                        subMenu3.subMenus?.forEach(subMenu4 => {
                          if (subMenu4?.subMenus?.length === 0 ) {
                            subMenus.push(subMenu4);
                          } else {
                            subMenu4.subMenus?.forEach(subMenu5 => {
                              subMenus.push(subMenu5);
                            });
                            // subMenus = [...subMenu, ...subMenu?.subMenus];
                          }
                          //subMenus.push(subMenu4);
                        });
                        // subMenus = [...subMenu, ...subMenu?.subMenus];
                      }
                      //subMenus.push(subMenu3);
                    });
                    // subMenus = [...subMenu, ...subMenu?.subMenus];
                  }
                  //subMenus.push(menu);
                });
                // subMenus = [...subMenu, ...subMenu?.subMenus];
              }
            });
            subModule.subMenus = subMenus;
          })
        })
        console.log('getAllModules',this.mainModule);
    });
  }


  keyPress(event: any) {
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode !== '0') {
            event.preventDefault();
    }
  }

  get totalRows(): number {
    return this.subscriptions.length;
  }

  get f() { return this.subscriptionForm.controls; }

  resetForm() {
    this.submitted = false;
    this.subscriptionForm = this.fb.group({
      id: [0],
      moduleName: ['', Validators.required],
      defaultPrice: ['', Validators.required],
      subscriptionType: ['', Validators.required],
      maintanceCostPercentage: ['', Validators.required],
      status: ['true', Validators.required],
    });
    this.mainModule.forEach((x) => {
      x.checked = false;
      x.subModules.forEach((y) => {
        y.checked = false;
        y.subMenus?.forEach(z => z.checked = false);
      });
    });
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  setCurrentDate() {
    const x = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.currentDate = days[x.getDay()] + ' ' + this.formatDate(x);
  }

  onModuleKeyUp(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === '') {
      this.mainFilteredModule = this.mainModule;
    } else {
      this.mainFilteredModule = this.mainModule
        .filter((module: MainModule) =>
          module.moduleName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase())
          );
    }
  }

  onKeyUp(event: any) {
    this.filterSearch(event.target.value);
  }

  filterSearch(keyword: any) {
    if (keyword === '') {
      this.mainFilteredModule = this.mainModule;
    } else {
      this.mainFilteredModule = this.mainModule
        .filter((module: MainModule) =>
          module.moduleName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase())
          );
    }
  }

  submitForm() {        
    this.submitted = true;
    if (this.subscriptionForm.invalid) {
      return;
    }

    var modules = JSON.parse(JSON.stringify(this.mainModule));

    this.selectedModules = [];
    modules.forEach((x) => {
      if (x.checked) {
        let k = 1;
        x.subModuleNames = '';
        const totalSelected = x.subModules.filter(a => a.checked).length;
        x.subModules.forEach((a) => {
          if (a.checked) {
            if (totalSelected !== k) {
              x.subModuleNames += a.id.toString() + '|';
            } else {
              x.subModuleNames += a.id.toString();
            }
            k++;
            a.subMenus = a.subMenus?.filter(a => a.checked);
          }
        });
        x.subModules = x.subModules?.filter(a => a.checked);
        this.selectedModules.push(x);
      }
    });

    if (this.selectedModules.length === 0) {
      this.toastr.warning('Please select Module', 'WARN!', {
        timeOut: 3000
      });
      return;
    }

    const subscription = {} as SubscriptionManagerModel;
    subscription.id = this.subscriptionForm.get('id').value;
    subscription.moduleName = this.subscriptionForm.get('moduleName').value;
    subscription.defaultPrice = this.subscriptionForm.get('defaultPrice').value;
    subscription.maintanceCostPercentage = this.subscriptionForm.get('maintanceCostPercentage').value;
    subscription.status = this.subscriptionForm.get('status').value;
    subscription.subscriptionType = this.subscriptionForm.get('subscriptionType').value;
    subscription.subscriptionDetails = this.selectedModules;

    if (this.subscriptionForm.get('id').value === 0) {
      console.log(this.subscriptionForm.value);
      console.log('BeforeSave',subscription);
      this.subscriptionService.create(subscription).subscribe(res => {
        console.log('Employee created!');
        this.ListSubscriptionModule();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.subscriptionService.update(this.subscriptionForm.get('id').value, subscription).subscribe(res => {
          console.log('Employee updated!');
          this.ListSubscriptionModule();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
         });
      }


}

updateForm(id: number) {
  this.resetForm();
  this.subscriptionService.getById(id).subscribe((data: SubscriptionManagerModel) => {
     console.log(data);
     this.subscriptionForm = this.fb.group({
      id: [data.id],
      moduleName: [data.moduleName, Validators.required],
      defaultPrice: [data.defaultPrice, Validators.required],
      subscriptionType: [data.subscriptionType, Validators.required],
      maintanceCostPercentage: [data.maintanceCostPercentage, Validators.required],
      status: [data.status?.toLowerCase(), Validators.required],
     });

     data.subscriptionDetails.forEach( (x) => {
      x.subModuleNames = ""
      x.subMenuNames = ""
      x.subModules.forEach(subModule => {
        x.subModuleNames += x.subModuleNames === '' ? subModule.menuName : '|'+subModule.menuName;
        subModule.subMenus.forEach(menu => {
          x.subMenuNames += x.subMenuNames === '' ? menu.menuName : '|'+menu.menuName;
        })
      })
     });

     data.subscriptionDetails.forEach( (x) => {
        this.mainModule.forEach((y) => {
           if (x.moduleName === y.moduleName) {
              y.id = x.id;
              y.checked = true;
           }
           x.subModuleNames.split('|').forEach((z) => {
              y.subModules.forEach((u) => {
                  if (z === u.menuName) {
                      u.checked = true;
                  }
              });
           });
           x.subMenuNames.split('|').forEach((z) => {
            y.subModules.forEach((u) => {
              u.subMenus.forEach(s => {
                if (z === s.menuName) {
                  s.checked = true;
                }
              })
            });
         });
        });
     });

   });
  // this.resetForm();
  // this.ListServices();
 }

checkAllCheckBox(ev,position) {
   this.mainModule[position].subModules.forEach(x => {
    x.checked = ev.target.checked;
    x?.subMenus.forEach(y => y.checked = ev.target.checked);
   });
}

isAllCheckBoxChecked(position) {
  return this.mainModule[position].subModules.every(p => p.checked);
}

checkEveryCheckBox(ev) {
  this.mainModule.forEach(x => {
    x.checked = ev.target.checked;
    x.subModules.forEach(y => {
      y.checked = ev.target.checked
      y.subMenus.forEach(z => z.checked = ev.target.checked);
    });
  });
}

isEveryCheckBoxChecked() {
 return this.mainModule.every(p => p.checked);
}

getNextLetter(num): string {
  const code: number = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charCodeAt(num);
  return String.fromCharCode(code);
}
  ngOnInit(): void {
    this.ListSubscriptionModule();
    this.getAllModules();
    // this.mainFilteredModule = this.mainModule = [
    //   {id: 0, moduleName: 'Academics', checked: false, subModuleNames: '', subModules: [
    //     {subModuleName: 'Applicant Registraion', checked: false},
    //     {subModuleName: 'Attendance', checked: false},
    //     {subModuleName: 'Class Management', checked: false},
    //     {subModuleName: 'Event calendar', checked: false},
    //     {subModuleName: 'Master Setting', checked: false},
    //     {subModuleName: 'Student Management', checked: false},
    //   ]
    //  }, {id: 0, moduleName: 'Communication', checked: false, subModuleNames: '', subModules: [
    //   {subModuleName: 'Configuration', checked: false},
    //   {subModuleName: 'Circular Manager', checked: false},
    //   {subModuleName: 'Broadcast Manager', checked: false},
    //   {subModuleName: 'Credit Manager', checked: false}]
    // }];

    this.setCurrentDate();
    this.subscriptionForm = this.fb.group({
      id: [0],
      moduleName: ['', Validators.required],
      defaultPrice: ['', Validators.required],
      subscriptionType: ['', Validators.required],
      maintanceCostPercentage: ['', Validators.required],
      status: ['true', Validators.required],
    });
this.GetAllMainMenu()
 
  }
  pages:[
    {pagename:'page1',pageId:'1',checked: false,parentId:1},
    {pagename:'page2',pageId:'2',checked: false,parentId:1},
    {pagename:'page3',pageId:'3',checked: false,parentId:1},
    {pagename:'page4',pageId:'4',checked: false,parentId:1},
    {pagename:'page5',pageId:'5',checked: false,parentId:1}
]
GetAllMainMenu(){
  this.subscriptionService.GetAllMenuModules().subscribe((data:any)=>{
   
    this.MainMenu=data
  },(err)=>{
    console.log(err)
  })
}
// GetAllSubMenu(){
//   this.subscriptionService.GetAllMenuModules().subscribe((data:any)=>{
//     console.log('SUBData',data)
//   },(err)=>{
//     console.log(err)
//   })
// }
MainMenu:any=[]
SubMenu:any=[]
Pages:any=[]
GetSubmenusForId(id){
  this.subscriptionService.getSubmenuById(id).subscribe((data:any)=>{
this.SubMenu=data;
    console.log(data)
  },(err)=>{
    console.log(err)
  })
}
}
