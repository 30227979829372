import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PaymentCancelledReportModel} from './paymentcancelledreport.model';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentCancelledReportService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  getPaymentCancelledReport(classCourseId:string,batchId:string,fromDate:string,toDate:string): Observable<PaymentCancelledReportModel[]> {
   ;
   return this.httpClient.get<PaymentCancelledReportModel[]>(environment.apiUrl + '/FeeCollection/GetPaymentCancelledReport?classCourseId='+classCourseId+'&batchId='+batchId+'&fromDate='+fromDate+'&toDate='+toDate)
   //return this.httpClient.get<PaymentCancelledReportModel[]>(environment.apiUrl + '/FeeCollection/GetPaymentCancelledReport/'+classCourseId+'/'+batchId+'/'+fromDate+'/'+toDate)
    .pipe(
      catchError(this.errorHandler)
    );
  }
 
errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
