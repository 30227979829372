import { Component, OnInit } from '@angular/core';
import {CoursePlannerService} from '../course-planner/course-planner.service';
import { ClassVM } from '../course-planner/model/ClassVM';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import { CoursePlannerVM } from '../course-planner/model/CoursePlannerVM';
import { ChapterTopicPlanner } from '../course-chapter-planner/model/ChapterTopicPlanner';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DatePipe } from "@angular/common";

import { SubjectVM } from '../course-planner/model/Subject';
declare var $: any;


@Component({
  selector: 'app-course-chapter-planner',
  templateUrl: './course-chapter-planner.component.html',
  styleUrls: ['./course-chapter-planner.component.css']
})
export class CourseChapterPlannerComponent implements OnInit {

  coursePlanForm: UntypedFormGroup;
  public lstCourseList: CoursePlannerVM[] = new Array<CoursePlannerVM>();
  public lstTopicPlannerList: ChapterTopicPlanner[] = new Array<ChapterTopicPlanner>();

  public lstClass: ClassVM[] = new Array<ClassVM>();
  public lstSubject: SubjectVM[] = new Array<SubjectVM>();
  submitted = false;
  Id= 0;
  headline: string;
  HighlightRow : Number;
  ClickedRow:any;


  constructor(private coursePlannerService: CoursePlannerService, private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder) { 

      { 
        this.ClickedRow = function(index){
          this.HighlightRow = index;
      }
    }
  }

   
  ngOnInit(): void {


    this.coursePlanForm = this.formBuilder.group({
      Id: 0,
      ClassId: [0, Validators.required],
      SubjectId: [0, Validators.required],
      CourseId: [, Validators.required],
      TopicPlannerTitle: ['', Validators.required],
      Informations: ['']

      });

    this.getAllClass();
    this.getAllSubject();

  }

  public getAllClass() {
    this.coursePlannerService.GetClass().subscribe((response) => {
      this.lstClass = response;
    });
  }


  public getAllSubject(){

    this.coursePlannerService.GetAllSubject().subscribe((response) => {
      this.lstSubject = response;
    });


  }
  public getClassId(){
    this.lstSubject=null;
    var ClassID = this.coursePlanForm.get('ClassId').value;
    this.headline = this.lstClass.find(x=>x.id == ClassID).classCourseName;

    if(ClassID>0){
      this.getAllSubject();
      this.selectClassbyTopicplan();
      // this.getAllCoursePlanByClass(ClassID);

    }
  }

  get f() { return this.coursePlanForm.controls; }
  
  public saveCourseChapter(){

    ;
    this.submitted = true;
    
    if (this.coursePlanForm.valid) {
       // this.load.show();
      if(this.Id == 0){
        
        this.coursePlannerService.SaveChapterTopic(this.coursePlanForm.value).subscribe((response) => {
         
          this.resetForm();
        

          this.toastr.success("Succesfully Added Course Plan", 'Success!',{timeOut: 1000});
          this.selectCourse();

        });
      }
      else{
        this.coursePlannerService.UpdateTopicPlanner(this.coursePlanForm.value).subscribe((response) => {
          // this.load.ngOnInit();
          
          this.resetForm();
          this.toastr.success("Succesfully Updated Course Plan", 'Success!',{timeOut: 1000});
          this.selectCourse();
          this.Id=0;

        });
      }
    } 

  }

 
  public resetForm(){
    /* this.coursePlanForm = this.formBuilder.group({
      Id: 0,
      ClassId: [parseInt(this.coursePlanForm.get('ClassId').value), Validators.required],
      SubjectId: [parseInt(this.coursePlanForm.get('SubjectId').value), Validators.required],
      CourseId: [parseInt(this.coursePlanForm.get('CourseId').value), Validators.required],
      TopicPlannerTitle: ['', [Validators.required]],
      Informations: ['']

      }); */
      this.coursePlanForm = this.formBuilder.group({
        Id: 0,
        ClassId: [0, Validators.required],
        SubjectId: [0, Validators.required],
        CourseId: [0, Validators.required],
        TopicPlannerTitle: ['', [Validators.required]],
        Informations: ['']
  
        });
      this.getAllClass();
      this.getAllSubject();

      // this.coursePlanForm = this.formBuilder.group({
      //   Id: 0,
      //   ClassId: [, Validators.required],
      //   SubjectId: [, Validators.required],
      //   CourseId: [, Validators.required],
      //   TopicPlannerTitle: ['', [Validators.required]],
      //   });

        
     this.lstTopicPlannerList=[];
     // this.Id= 0;
    this.submitted = false;
  }

  public selectSubjects(){
    var ClassID = this.coursePlanForm.get('ClassId').value;
    var SubjectId = this.coursePlanForm.get('SubjectId').value;

    ;
    this.headline =  this.headline + "   "+ this.lstSubject.find(x=>x.id == parseInt(SubjectId)).subjectName;

    if(ClassID>0){
      this.lstCourseList=null;
      this.getAllCoursePlanByClassSubject(ClassID,SubjectId);
      this.selectClassSubjectbytopicplan();
    }
  }
  public getAllCoursePlanByClassSubject(k,subjectId) {
    this.coursePlannerService.getAllCoursePlanByClassSubject(k,subjectId).subscribe((response) => {
      this.lstCourseList = response;
    });
  }


  public selectCourse() {

    var ClassID = this.coursePlanForm.get('ClassId').value;
    var SubjectId = this.coursePlanForm.get('SubjectId').value;
    var CourseId = this.coursePlanForm.get('CourseId').value;

;
    this.coursePlannerService.getAllTopicChapterPlanByClassSubjectCourse(ClassID,SubjectId,CourseId).subscribe((response) => {

      this.lstTopicPlannerList = response;
    });
  }


  public selectClassbyTopicplan() {

    var ClassID = this.coursePlanForm.get('ClassId').value;
    this.coursePlannerService.getAllTopicChapterPlanByClass(ClassID).subscribe((response) => {

      this.lstTopicPlannerList = response;
    });
  }
  
  public selectClassSubjectbytopicplan() {

    var ClassID = this.coursePlanForm.get('ClassId').value;
    var SubjectId = this.coursePlanForm.get('SubjectId').value;
    var CourseId = this.coursePlanForm.get('CourseId').value;


    this.coursePlannerService.getAllTopicChapterPlanByClassSubject(ClassID,SubjectId).subscribe((response) => {
      this.lstTopicPlannerList = response;
    });
  }
  

  
  public updateForm(id){

    // this.load.show();

    this.coursePlannerService.getTopicPlanById(id).subscribe((data: ChapterTopicPlanner) => {
      // this.load.ngOnInit();
     
  
      this.Id= data.id;
        
      this.coursePlanForm = this.formBuilder.group({
        Id: [data.id],
        ClassId: [data.classId, Validators.required],
        SubjectId: [data.subjectId, Validators.required],
        CourseId: [data.courseId, Validators.required],
        TopicPlannerTitle: [data.topicPlannerTitle, [Validators.required]],
        Informations:[data.informations]
        });

        
      
  
    });


  }
  public deleteForm(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
      // this.load.show();

        this.coursePlannerService.deleteTopicPlan(id).subscribe(res => {
      // this.load.ngOnInit();

          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        this.Id=0;
          this.getClassId();
      //  this.selectCourse();

        });
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      );
      }
    });

  }

}
