import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {CityService} from './city.service';
import {StateService} from './../state/state.service';
import { City } from '../models/city';
import { State } from '../models/state';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css']
})
export class CityComponent implements OnInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false
};

cityForm: UntypedFormGroup;
cities: City[] = [];
states: State[] = [];
cityFilteredList: City[] = [];
submitted = false;
ClickedRow:any;
HighlightRow:number;

  constructor(
    public cityService: CityService,
    public stateService: StateService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) 
  { 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }
  }

  ListCity() {
    this.cityService.getAll().subscribe((data: City[]) => {
      this.cityFilteredList = this.cities = data;
    });
}

StateList() {
  this.stateService.getAll().subscribe((data: State[]) => {
    this.states = data;
  });
}

onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

filter(keyword: any) {
  if ( keyword === '') {
    this.cityFilteredList = this.cities;
  } else {
  this.cityFilteredList = this.cities
                              .filter((city: City) => city.cityName.toLocaleLowerCase()
                              .includes(keyword.toLocaleLowerCase()));
  }
}

get totalRows(): number {
  return this.cityFilteredList.length;
}
get f() { return this.cityForm.controls; }

resetForm() {
  this.submitted = false;
  this.cityForm = this.fb.group({
    id: [0],
    cityName: ['', Validators.required],
    stateId: ['', Validators.required],
    status: ['true', Validators.required]
  });
}

submitForm() {
  this.submitted = true;

      // stop here if form is invalid
  if (this.cityForm.invalid) {
          return;
    }

  if (this.cityForm.get('id').value === 0) {
      this.cityService.create(this.cityForm.value).subscribe(res => {
        console.log('Category created!');
        this.ListCity();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.cityService.update(this.cityForm.get('id').value, this.cityForm.value).subscribe(res => {
          console.log('Caste updated!');
          this.ListCity();
          this.toastr.success('Records has been sucessfully updated.', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
      }


    }

    updateForm(id: number) {
      this.cityService.getById(id).subscribe((data: City) => {
        console.log(data);
        this.cityForm = this.fb.group({
          id: [data.id],
          cityName: [data.cityName, Validators.required],
          stateId: [data.stateId.toString(), Validators.required],
          status: [data.status.toString(), Validators.required]
        });
      });

    }

    deleteForm(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.cityService.delete(id).subscribe(res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            this.ListCity();
          });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
        }
      });
    }



  ngOnInit(): void {
    this.ListCity();
    this.StateList();
    this.cityForm = this.fb.group({
      id: [0],
      cityName: ['', Validators.required],
      stateId: ['', Validators.required],
      status: ['true', Validators.required]
    });
  }

}
