import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExamSettingRoutingModule } from './exam-setting-routing.module';
import { ExamsettingService } from '../services/examsetting.service';
import { GradeScaleGroupComponent } from './grade-scale-group/grade-scale-group.component';
import { ExamCategoryComponent } from './exam-category/exam-category.component';
import { WeightagesCBSEComponent } from './weightages-cbse/weightages-cbse.component';
import { WeightagesICSEComponent } from './weightages-icse/weightages-icse.component';
import { CbseweightageService } from '../services/cbseweightage.service';
import { AssignWeightagesICSEComponent } from './assign-weightages-icse/assign-weightages-icse.component';
import { GradeScaleComponent } from './grade-scale/grade-scale.component';
import { DesignationComponent } from './designation/designation.component';
import { AssignWeightagesCBSEComponent } from './assign-weightages-cbse/assign-weightages-cbse.component';



@NgModule({
  declarations: [GradeScaleGroupComponent,ExamCategoryComponent, WeightagesCBSEComponent, WeightagesICSEComponent, AssignWeightagesICSEComponent,AssignWeightagesICSEComponent, GradeScaleComponent, DesignationComponent, AssignWeightagesCBSEComponent],
  imports: [
    CommonModule,
    ExamSettingRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers:[ExamsettingService,CbseweightageService]
})
export class ExamSettingModule { }
