import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { City, EmployeeAttendanceReport, EmployeeDocuments, EmployeeLeave, EmployeeType, Employeeadmission, JobType, LeaveAllocation, Nationality, NoticePeriod, Qualification, State } from '../OperationActivity/hrmspayroll/hrms/models/employeeadmission.model';
/*import { Department } from '../operationactivity/hrmspayroll/master/models/department';
import { Designation } from '../operationactivity/hrmspayroll/master/models/designation';*/
import { Gender } from '../OperationActivity/hrmspayroll/payroll/mastersettings/models/gender';
import { SalaryProcess } from '../OperationActivity/hrmspayroll/payroll/models/salaryprocess';
import { Position } from '../OperationActivity/hrmspayroll/master/models/position';
import { Payscale } from '../OperationActivity/hrmspayroll/payroll/mastersettings/models/payscale';
/*import { LeaveRequest } from '../operationactivity/hrmspayroll/payroll/employeeleave/models/leaverequest';*/
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeadmissionService } from '../OperationActivity/hrmspayroll/hrms/employeeadmission.service';
/*import { DepartmentService } from '../operationactivity/hrmspayroll/master/department.service';
import { DesignationService } from '../operationactivity/hrmspayroll/master/designation.service';*/
import { PayscaleService } from '../OperationActivity/hrmspayroll/payroll/mastersettings/payscale.service';
import { PositionService } from '../OperationActivity/hrmspayroll/master/position.service';
import { GenderService } from '../OperationActivity/hrmspayroll/payroll/mastersettings/gender.service';
import { SalaryprocessService } from '../OperationActivity/hrmspayroll/payroll/salaryprocess.service';
/*import { EmployeeattendanceService } from '../operationactivity/hrmspayroll/payroll/employeeattendance/employeeattendance.service';
import { LeaverequestService } from '../operationactivity/hrmspayroll/payroll/employeeleave/leaverequest.service';
import { LeaveallocationService } from '../operationactivity/hrmspayroll/payroll/employeeleave/leaveallocation.service';*/
import { TimeTableService } from '../academic/timetable/timetable/timetable.service';
import { PeriodService } from '../academic/timetable/period/period.service';
import { Period } from '../academic/timetable/period/period.model';
import { environment } from 'src/environments/environment';
import { PeriodViewModel, TimeTableViewModel } from '../academic/timetable/timetable/timetable.model';
/*import { Attendance } from '../operationactivity/hrmspayroll/payroll/employeeattendance/models/attendance';*/
import { InstitutionprofileService } from '../academic/institutionprofile/institutionprofile.service';
import { InstitutionProfile } from '../academic/institutionprofile/institutionprofile.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChangeUserPassword } from './changeuserpassword.model';
import { SharedService } from '../shared.service';
import { ToastrService } from 'ngx-toastr';
//import { timeStamp } from 'console';
import { retry } from 'rxjs/operators';
import { Department } from '../OperationActivity/hrmspayroll/master/models/departement.model';
import { Designation } from '../OperationActivity/hrmspayroll/master/models/designation.model';
import { DepartmentService } from '../operationactivity/hrmspayroll/master/department.service';
import { EmployeeattendanceService } from '../operationactivity/hrmspayroll/payroll/employeeattendance/employeeattendance.service';
import { LeaveallocationService } from '../operationactivity/hrmspayroll/payroll/employeeleave/leaveallocation.service';
import { LeaverequestService } from '../operationactivity/hrmspayroll/payroll/employeeleave/leaverequest.service';
import { LeaveRequest } from '../operationactivity/hrmspayroll/payroll/employeeleave/models/leaverequest';
import { Attendance } from '../operationactivity/hrmspayroll/payroll/employeeattendance/models/attendance';
import { DesignationService } from '../operationactivity/hrmspayroll/master/designation.service';


@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.css']
})
export class UserprofileComponent implements OnInit {


  @ViewChild('tblclasswiseReport', { static: false }) pdfTable: ElementRef;
  filename = 'ExcelSheet.xlsx';
  employeeadmissions: Employeeadmission[] = [];
  employeeadmissionFilteredList: Employeeadmission[] = [];
  employeeadmissionFilteredListTemp: Employeeadmission[] = [];
  department: Department[] = [];
  leave: EmployeeLeave[] = [];
  departmentFilteredList: Department[] = [];
  designations: Designation[] = [];
  designationFilteredList: Designation[] = [];
  employeeTypes:EmployeeType[] = [];
  employeeTypeFilteredList:EmployeeType[] = [];
  genderFilteredList:Gender[] = [];
  genderSelectedList:Gender[] = [];
  gender: Gender[] = [];
  employeeadmission: Employeeadmission;
  _departmentName:any;
  _designationName:any;
  _positionName:any;
  _payscaleName:any;
  _jobType:any;
  _joiningDate:any;
  _localState:any;
_localCity:any;
_gender:any;
_permanentState:any;
_permanentCity:any;
  url;
  gradePay:any;
  basicPay:any;
  TotalEarning:any;
  TotalDeduction:any;
  totalSalary:any;
  SalaryProcessFilteredList: SalaryProcess[] = [];

 
  positionFilteredList: Position[] = [];
  payscaleFilteredList: Payscale[] = [];
  qualificationFilteredList: Qualification[] = [];
  noticePeriodFilteredList: NoticePeriod[] = [];
  jobTypeFilteredList: JobType[] = [];
  nationalityFilteredList: Nationality[] = [];
  stateFilteredList: State[] = [];
  cityFilteredList: City[] = [];
  leaveTypeFilteredList: EmployeeLeave[] = [];
  leaveAllocationFilteredList: LeaveAllocation[] = [];
  leaveTypeSelectedList: EmployeeLeave[] = [];
  leaverequestFilteredList: LeaveRequest[] = [];
  attendanceSummeryFilteredList: Attendance[] = [];
  attendanceReportFilteredList:EmployeeAttendanceReport [] = [];
  employeeDocumentFilteredList: EmployeeDocuments[] = [];
  employeeTimeTableList: any[];
  periodsList:string[];

  noOfWorkingDays=0;
  noOfPresent=0;
  noOfAbsent=0;
  noOfHalfday=0;
  noOfLeave=0;
  noOfHoliday=0;


  classcourseid = '-1';
  batchid = '-1';
  shiftid = '-1';
  roomId = '-1';
  subjectId = '-1';
  teacherId = '-1';
  submitted = false;
  times: any[] = [];
  periods: Period[] = [];

  institutionProfile:InstitutionProfile;
  institutionLogo: string;


  AdminPasswordForm: UntypedFormGroup;
  ERPPasswordForm: UntypedFormGroup;
  LoginPasswordForm: UntypedFormGroup;

  changePasswordObject: ChangeUserPassword;

  @ViewChild('closeModalAdmin') private closeModalAdmin: ElementRef;
  @ViewChild('closeModalERP') private closeModalERP: ElementRef;
  @ViewChild('closeModalLogin') private closeModalLogin: ElementRef;

  constructor(private router: Router,public settingsService: EmployeeadmissionService, public departmentService: DepartmentService,public designationService: DesignationService,
    public employeeadmissionService: EmployeeadmissionService,public posistionsService: PositionService, public payscalesService: PayscaleService,
    public salaryProcessService: SalaryprocessService,public genderService:GenderService,
    public employeeattendanceService: EmployeeattendanceService,
    public leaverequestservice:LeaverequestService, public leaveallocationservice:LeaveallocationService,
    private timeTableService: TimeTableService,
    public periodService: PeriodService,
    public timetableservice:TimeTableService,
    private activatedRoute:ActivatedRoute,
    private profileService: InstitutionprofileService,
    private sharedService:SharedService,
    private toastr: ToastrService,
    private fb:UntypedFormBuilder) { }

   ngOnInit(): void {
  
    this.ListEmployee();
    this.ListDepartment();
    this.ListDesignation();
    this.ListEmployeeType();
    this.ListGender();
    this.ListPosition();
    this.ListQualification();
    this.ListNoticePeriod();
    this.ListJobType();
    this.ListPayscale();
    this.ListLeaveType();
    this.ListState();
    this.ListCity();
    this.ListPeriods();
    this.activatedRoute.queryParamMap.subscribe(x=>{
     
      let empId = x.get('id');
      console.log(empId);
     if(empId!=null)
     {

      this.settingsService.getAll().subscribe((data: Employeeadmission[]) => {      
        this.employeeadmissions=data;
        //this.employeeadmissionFilteredList=data;
        this.employeeadmissionFilteredList = data.filter((departments: Employeeadmission) => departments.isDelete!=true);
        this.employeeadmissionFilteredListTemp=this.employeeadmissionFilteredList;
        this.editEmployee(empId);
        console.log(this.employeeadmissionFilteredList);
      });
      //this.ListEmployee();

     }
     else{
      this.getDetailsInstitution();
     }
    })

    this.AdminPasswordForm=this.fb.group({
      currentPasswordAdmin:['',Validators.required],
      newPasswordAdmin:['',Validators.required],
      confirmPasswordAdmin:['',Validators.required]
    },
    {
      validator:this.ConfirmValidator('newPasswordAdmin','confirmPasswordAdmin')
    });
    this.ERPPasswordForm=this.fb.group({
      currentPasswordERP:['',Validators.required],
      newPasswordERP:['',Validators.required],
      confirmPasswordERP:['',Validators.required]
    },
    {
      validator:this.ConfirmValidator('newPasswordERP','confirmPasswordERP')
    });
    this.LoginPasswordForm=this.fb.group({
      currentPasswordLogin:['',Validators.required],
      newPasswordLogin:['',Validators.required],
      confirmPasswordLogin:['',Validators.required]
    },
    {
      validator:this.ConfirmValidator('newPasswordLogin','confirmPasswordLogin')
    }); 
  }

  getDetailsInstitution() {
    this.profileService.getProfile().subscribe((data) => {
      console.log(data);
      this.institutionProfile=data;
      this.institutionLogo=environment.imageUrl+this.institutionProfile.logoUrl;
    });
    

    
  }

  public hideModel(el:ElementRef) {
   el.nativeElement.click();      
}



  submitPasswordERP(){
    if (this.ERPPasswordForm.invalid) {
      console.log(this.ERPPasswordForm.value);
      return;
     }
     this.submitted=true;
    this.changePasswordObject=new ChangeUserPassword();
    this.changePasswordObject.newPassword=this.ERPPasswordForm.get('newPasswordERP').value;
    this.changePasswordObject.oldPassword=this.ERPPasswordForm.get('currentPasswordERP').value;
    this.changePasswordObject.userId=this.employeeadmission.id;
    this.changePasswordObject.userType='ERP';
    this.sharedService.changePassword(this.changePasswordObject).subscribe(res => {
      console.log('Password updated!');
      this.toastr.success('Password has been sucessfully changed', 'SUCCESS!', {
        timeOut: 3000
      });
      
    });
    this.resetForms();
    //$('#changepasswordERP').hide();
    this.hideModel(this.closeModalERP);
  }
  submitPasswordLogin(){
   
    if (this.LoginPasswordForm.invalid) {
      console.log(this.LoginPasswordForm.value);
      return;
     }
     this.submitted=true;
    this.changePasswordObject=new ChangeUserPassword();
    this.changePasswordObject.newPassword=this.LoginPasswordForm.get('newPasswordLogin').value;
    this.changePasswordObject.oldPassword=this.LoginPasswordForm.get('currentPasswordLogin').value;
    this.changePasswordObject.userId=this.employeeadmission.id;
    this.changePasswordObject.userType='Login';
    this.sharedService.changePassword(this.changePasswordObject).subscribe(res => {
      console.log('Password updated!');
      this.toastr.success('Password has been sucessfully changed', 'SUCCESS!', {
        timeOut: 3000
      });
     
    });
    this.resetForms();
    //$('#changepasswordLogin').hide();
    this.hideModel(this.closeModalLogin);
  }
  submitPasswordAdmin(){
    this.submitted = true;
    debugger;
    if (this.AdminPasswordForm.invalid) {
      console.log(this.AdminPasswordForm.value);
      return;
     }
     this.submitted=true;
     this.changePasswordObject=new ChangeUserPassword();
    this.changePasswordObject.newPassword=this.AdminPasswordForm.get('newPasswordAdmin').value;
    this.changePasswordObject.oldPassword=this.AdminPasswordForm.get('currentPasswordAdmin').value;
    this.changePasswordObject.userId=0;
    this.changePasswordObject.userType='Admin';
    this.sharedService.changePasswordAdmin(this.institutionProfile.institutionCode,this.changePasswordObject).subscribe(res => {
      console.log('Password updated!');
      console.log(res);
      this.toastr.success('Password has been sucessfully changed', 'SUCCESS!', {
        timeOut: 3000
      });
      //const modal=document.getElementById('changepasswordAdmin');
      
    });
    this.resetForms();
    //$('#changepasswordAdmin').hide();
    this.hideModel(this.closeModalAdmin);
    
}

ConfirmValidator(controlName:string,matchingControlName:string){
  return (formGroup:UntypedFormGroup )=>{
    const control=formGroup.controls[controlName];
    const matchingControl=formGroup.controls[matchingControlName];
    if(matchingControl.errors && 
      !matchingControl.errors.confirmedValidator
      ){
        return;
      }
      if(control.value!== matchingControl.value){
        matchingControl.setErrors({confirmedValidator:true});
      }else{
        matchingControl.setErrors(null);
      }
  };
}
get adminPasswordForm() { return this.AdminPasswordForm.controls; }
get loginPasswordForm() { return this.LoginPasswordForm.controls; }
get eRPPasswordForm() { return this.ERPPasswordForm.controls; }
resetForms(){
  this.submitted=false;
  this.AdminPasswordForm=this.fb.group({
    currentPasswordAdmin:['',Validators.required],
    newPasswordAdmin:['',Validators.required],
    confirmPasswordAdmin:['',Validators.required]
  },
  {
    validator:this.ConfirmValidator('newPasswordAdmin','confirmPasswordAdmin')
  });
  this.ERPPasswordForm=this.fb.group({
    currentPasswordERP:['',Validators.required],
    newPasswordERP:['',Validators.required],
    confirmPasswordERP:['',Validators.required]
  },
  {
    validator:this.ConfirmValidator('newPasswordERP','confirmPasswordERP')
  });
  this.LoginPasswordForm=this.fb.group({
    currentPasswordLogin:['',Validators.required],
    newPasswordLogin:['',Validators.required],
    confirmPasswordLogin:['',Validators.required]
  },
  {
    validator:this.ConfirmValidator('newPasswordLogin','confirmPasswordLogin')
  }); 

}

  ListPeriods()
  {
    this.periodService.getAll().subscribe((data: Period[]) => {
      this.periods=[];
      data.forEach((x:Period)=>{
        if(this.periods.filter(p=>p.periodName==x.periodName).length==0){
          this.periods.push(x);
        }
    })
    });
  }

  ListPayscale() {
    this.payscalesService.getAll().subscribe((data: Payscale[]) => {
      this.payscaleFilteredList = data.filter((payscale: Payscale) => payscale.isDelete != true);
    });
  }
  ListPosition() {
    this.posistionsService.getAll().subscribe((data: Position[]) => {

      this.positionFilteredList = data.filter((departments: Position) => departments.isDelete != true);
    });
  }
  ListQualification() {
    this.settingsService.getAllQualification().subscribe((data: Qualification[]) => {
      this.qualificationFilteredList = data.filter((departments: Qualification) => departments.isDelete != true);
    });
  }
  ListNoticePeriod() {
    this.settingsService.getAllNoticePeriod().subscribe((data: NoticePeriod[]) => {
      this.noticePeriodFilteredList = data.filter((departments: NoticePeriod) => departments.isDelete != true);
    });
  }
  ListJobType() {
    this.settingsService.getAllJobType().subscribe((data: JobType[]) => {
      this.jobTypeFilteredList = data.filter((departments: JobType) => departments.isDelete != true);
    });
  }
  ListNationality() {
    this.settingsService.getAllNationality().subscribe((data: Nationality[]) => {
      this.nationalityFilteredList = data.filter((departments: Nationality) => departments.isDelete != true);
    });
  }
  ListState() {
    this.settingsService.getAllState().subscribe((data: State[]) => {
      this.stateFilteredList = data.filter((departments: State) => departments.isDelete != true);
    });
  }
  ListCity() {
    this.settingsService.getAllCity().subscribe((data: City[]) => {
      this.cityFilteredList = data.filter((departments: City) => departments.isDelete != true);
    });
  }
  ListLeaveType() {
    this.settingsService.getAllLeaveType().subscribe((data: EmployeeLeave[]) => {

      this.leaveTypeFilteredList = data.filter((departments: EmployeeLeave) => departments.isDelete != true);
    });
  }

  ListDepartment() {
    this.departmentService.getAll().subscribe((data: Department[]) => {
      this.department = data;
      this.departmentFilteredList = data.filter((department: Department) => department.status == true);
    });
  }

  ListGender() {
    this.genderService.getAll().subscribe((data: Gender[]) => {
      this.gender=data;
      this.genderFilteredList = data.filter((gender: Gender) => gender.isDelete != true);
    });
  }

  ListDesignation() {
    this.designationService.getAll().subscribe((data: Designation[]) => {
      this.designations = data;
      this.designationFilteredList = this.designations = data.filter((designation: Designation) => designation.isDelete != true);
    });
  }

  ListEmployeeType() {
    this.employeeadmissionService.getAllEmployeeType().subscribe((data: EmployeeType[]) => {
      this.employeeTypes=data;
      this.employeeTypeFilteredList = data.filter((departments: EmployeeType) => departments.isDelete!=true);
    });
  }




  ListEmployee() {
    this.settingsService.getAll().subscribe((data: Employeeadmission[]) => {      
      this.employeeadmissions=data;
      //this.employeeadmissionFilteredList=data;
      this.employeeadmissionFilteredList = data.filter((departments: Employeeadmission) => departments.isDelete!=true);
      this.employeeadmissionFilteredListTemp=this.employeeadmissionFilteredList;
      console.log(this.employeeadmissionFilteredList);
    });
  }



  editEmployee(employeeId){
    debugger;
    this.employeeadmission=this.employeeadmissionFilteredList.find(s=>s.id==employeeId)
    if(  this.employeeadmission.payScaleId){
      this.payscalesService.getPayScaleById( this.employeeadmission.payScaleId).subscribe((data: Payscale[]) => {


        this.gradePay = data[0].gradePay;
        this.basicPay = data[0].basicPay;
        this.TotalEarning = data[0].totalEarning;
        this.TotalDeduction = data[0].totalDeduction;
        this.totalSalary = data[0].totalSalary
       
      });
    }
    this.getAteendanceSummary(this.employeeadmission.id);
    this.getAteendanceReport(this.employeeadmission.id);
    this.getEmployeeDocumentdetails(this.employeeadmission.id);
    this.getSalaryProcessDetails(this.employeeadmission.id);
    this.getLeavedetails(this.employeeadmission.id);
    this.getLeaveAllocationdetails(this.employeeadmission.id);
    this.getEmployeeTimeTable(this.employeeadmission.id);
    var department=this.departmentFilteredList.find(s=>s.id== this.employeeadmission.departmentId);
    if(department){
      this._departmentName=this.departmentFilteredList.find(s=>s.id== this.employeeadmission.departmentId).departmentName;
    }
    var position=this.positionFilteredList.find(s=>s.id== this.employeeadmission.positionId)
    if(position){
      this._positionName=this.positionFilteredList.find(s=>s.id== this.employeeadmission.positionId).positionName;
    }
    var designation=this.designationFilteredList.find(s=>s.id== this.employeeadmission.designationId)
    if(designation){
      this._designationName=this.designationFilteredList.find(s=>s.id== this.employeeadmission.designationId).designationName;
    }
    var payscale=this.payscaleFilteredList.find(s=>s.id== this.employeeadmission.payScaleId);
    if(payscale){
      this._payscaleName=this.payscaleFilteredList.find(s=>s.id== this.employeeadmission.payScaleId).payScaleName;
    }

    var jobtype=this.jobTypeFilteredList.find(s=>s.id== this.employeeadmission.jobType)
    if(jobtype){
      this._jobType=this.jobTypeFilteredList.find(s=>s.id== this.employeeadmission.jobType).jobTypeName;
    }

    var localstate=this.stateFilteredList.find(s=>s.id== this.employeeadmission.localState);
    if(localstate){
      this._localState=this.stateFilteredList.find(s=>s.id== this.employeeadmission.localState).stateName;
    }
    var localCity=this.cityFilteredList.find(s=>s.id== this.employeeadmission.localCity);
    if(localCity){
      this._localCity=this.cityFilteredList.find(s=>s.id== this.employeeadmission.localCity).cityName;
    }


    if(  this.employeeadmission.isSameAsLocalAddress){
      this._permanentCity= this._localCity;
      this._permanentState= this._localCity;
      this.employeeadmission.permanentAddress=this.employeeadmission.localAddress;
      
    }
    else{
      var permanentstate=this.stateFilteredList.find(s=>s.id== this.employeeadmission.permanentState);
      if(permanentstate){
        this._permanentState=this.stateFilteredList.find(s=>s.id== this.employeeadmission.permanentState).stateName;
      }
  
      var permanentcity=this.cityFilteredList.find(s=>s.id== this.employeeadmission.permanentCity);
      if(permanentcity){
        this._permanentCity=this.cityFilteredList.find(s=>s.id== this.employeeadmission.permanentCity).cityName;
      }
  
    }

    if(this.employeeadmission.imageURI){
      this.url=environment.imageUrl+this.employeeadmission.imageURI
    }
    else{
      this.url="assets/images/thumbnail/default-avatar.jpeg"
    }
  }
  getEmployeeDocumentdetails(EmployeeId){
    
    var id = Number(EmployeeId)
    this.employeeadmissionService.getByEmpId(EmployeeId).subscribe((data: EmployeeDocuments[]) => {

      this.employeeDocumentFilteredList=data;
    });
    
  }
  getEmployeeTimeTable(EmployeeId){
    this.TimeTableForEmployee(EmployeeId);
    this.times=[];
    /*var id = Number(EmployeeId);
    this.timetableservice.getTeacherPeriodDetail(id).subscribe((data: any[])=>{

      this.employeeTimeTableList = data;
      this.periodsList =Array.from(new Set(data.map(x=>x.periodName)));
    });*/
  }
  getLeaveAllocationdetails(EmployeeId){
    
    var id = Number(EmployeeId)
    this.leaveallocationservice.getEmpAll(EmployeeId).subscribe((data: LeaveAllocation[]) => {

      this.leaveAllocationFilteredList=data;
    });
    
  }
  getClass(period,weekName){
    return this.employeeTimeTableList.filter(x=>x.periodName==period && x.weekName==weekName)[0].classCourseName;
  }

  getSubject(period,weekName){
    return this.employeeTimeTableList.filter(x=>x.periodName==period && x.weekName==weekName)[0].subjectName;
  }
  getStartTime(period){
    return this.employeeTimeTableList.filter(x=>x.periodName==period)[0].startTime;
  }

  getEndTime(period){
    return this.employeeTimeTableList.filter(x=>x.periodName==period)[0].endTime;
  }
  
  getLeavedetails(EmployeeId){    
    var id = Number(EmployeeId)
    this.leaverequestservice.getByEmpId(EmployeeId).subscribe((data: LeaveRequest[]) => {
      this.leaverequestFilteredList=data;

    });
    
  }

 
  getSalaryProcessDetails(EmployeeId){    
    var id = Number(EmployeeId)
    this.salaryProcessService.getById(EmployeeId).subscribe((data: SalaryProcess[]) => {
    this.SalaryProcessFilteredList= data.sort(this.comp);
    });    
  }
  comp(a, b) {    
    return new  Date(b.paidDate).getTime()- new Date(a.paidDate).getTime();
  }

getAteendanceSummary(EmployeeId){
  this.employeeattendanceService.GetAttendanceSummaryList(EmployeeId).subscribe((data: Attendance[]) => {
    this.attendanceSummeryFilteredList = data;
  });
}
TimeTableForEmployee(EmployeeId) { 
  this.timeTableService.getPeriodDetail(0, 0, 1, EmployeeId).subscribe((data: any[]) => {
    console.log(data);
    const periodList: TimeTableViewModel[] = [];
    const periodTable = data['Table'];
    const timeTableObj = data['Table1'];
    periodTable.forEach((element: any) => {
      const x2 = Object.values(element);
      const weekName = x2[0];
      const timeObjList: PeriodViewModel[] = [];
      // 
      // x2.forEach((x) => {
      timeTableObj.forEach((element1: any) => {
        if (weekName === element1.WeekName) {
          const timeObj: PeriodViewModel = {} as PeriodViewModel;
          timeObj.periodId = element1.PeriodId;
          timeObj.subjectName = element1.SubjectName;
          timeObj.teacherName = element1.EmployeeName;
          timeObj.periodTime = element1.PeriodTime;
          timeObj.roomName = element1.RoomName;
          timeObj.ClassCourse = element1.ClassCourse;
          timeObj.Batch = element1.Batch;
          timeObjList.push(timeObj);
        }
      });
      const periodObj: TimeTableViewModel = {} as TimeTableViewModel;
      periodObj.weekName = weekName.toString();
      //debugger;
      periodObj.periodDetails = timeObjList;
      periodList.push(periodObj);
      // });
    });
    // console.log(periodList);
    ;
    this.times = periodList;
  });
  // this.subjectService.getAllByClassBatch(parseInt(classId, 0)).subscribe((data: SubjectCreation[]) => {
  //   this.subjects = data;
  // });
}

getAteendanceReport(EmployeeId){
  this.noOfWorkingDays=0;
  this.noOfPresent=0;
  this.noOfAbsent=0;
  this.noOfHalfday=0;
  this.noOfLeave=0;
  this.noOfHoliday=0;
  this.employeeattendanceService.GetEmployeeAttendanceReport(EmployeeId).subscribe((data :EmployeeAttendanceReport[]) => {
  data.forEach((leaveData)=>{      
      this.noOfWorkingDays +=leaveData['workingDays']; 
      this.noOfPresent +=leaveData['presentCount'];
      this.noOfAbsent +=leaveData['absentCount'];
      this.noOfHalfday +=leaveData['halfDayCount'];
      this.noOfLeave +=leaveData['leave'];
      this.noOfHoliday +=leaveData['holiDays'];
  });  
 this.attendanceReportFilteredList = data;
 
});
}

}
