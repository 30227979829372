import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChequeStatusService } from './chequestatus.service';
import { ChequeStatusModel } from './chequestatus.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import { TitleCasePipe } from '@angular/common';
import { ReportAttribute } from '../../../studentmanagement/report.model';
import { environment } from 'src/environments/environment';
import { SettingsService } from '../../../mastersettings/settings.service';
import { Academicyear } from '../../../mastersettings/models/academicyear';
declare var $: any;

@Component({
  selector: 'app-chequestatus',
  templateUrl: './chequestatus.component.html',
  styleUrls: ['./chequestatus.component.css']
})
export class ChequestatusComponent implements OnInit {
  chequeStatus: string;
  classId: string;
  batch: string;
  mode: string;
  fromDate: string;
  toDate: string;
  totalPaidAmount: any;
  dataCount: any;
  institution: any;
  dateValidation:boolean=false;
  currentAcademicYearId: number;
  currentAcademicYearName = '';
  defaultArray = [];
  academicYears: Academicyear[] = [];
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  chequeStatusList: ChequeStatusModel[] = [];
  chequeStatusFilteredList: ChequeStatusModel[] = [];
  chequeStatusTempList: ChequeStatusModel[] = [];
  constructor(
    public chequeStatusService: ChequeStatusService,
    private academicYearService: SettingsService,
    private titlecasePipe: TitleCasePipe,
    private toastr: ToastrService

  ) { }
  onStatus() {
    //this.chequeStatusFilteredList = null;
   // this.chequeStatusTempList = null;
    //this.getChequeStatus();
    this.onDateWiseSearch();
    
  }
  onDateWiseSearch() {
    debugger;
    const dateFrom = this.fromDate;
    const dateTo = this.toDate;
    const status = this.chequeStatus;
    var startDt: Date;
    var endDt: Date;
    
    this.chequeStatusTempList = this.chequeStatusFilteredList;
    if (typeof this.fromDate != 'undefined' && typeof this.toDate != 'undefined' && this.fromDate != '' && this.toDate != '') {
      startDt = new Date(dateFrom);
      endDt = new Date(dateTo);
      this.chequeStatusTempList = this.chequeStatusTempList.filter(d => {
        var date = new Date(d.chequeDate);
        return (startDt.getTime() <= date.getTime() && endDt.getTime() >= date.getTime());
      });
    }
    if (status != "All" && status != "" && status != 'undefined' && status != 'Status') {
      //this.getChequeStatus();
      this.chequeStatusTempList = this.chequeStatusFilteredList;
      this.chequeStatusTempList = this.chequeStatusTempList.filter(d => {
        return (d.status.toLocaleLowerCase().includes(status.toLocaleLowerCase()))
      });
      this.chequeStatusFilteredList = this.chequeStatusTempList;
    }
    else{
        this.getChequeStatus();
    }
    
  }
  onKeyUpEvent(event: any) {
    ;
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === '') {
      this.onDateWiseSearch();
    } else {
      this.chequeStatusTempList = this.chequeStatusTempList.filter(d => {
        return (d.bankName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
          d.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
           d.chequeNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
      });
      this.chequeStatusFilteredList = this.chequeStatusTempList;
    }
  }
  currentAcademicYear() {
    this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.currentAcademicYearName = this.academicYears.find(x => x.id.toString() === data.id.toString()).name;
     
    });
  }
  getChequeStatus() {
    const self = this;
    this.chequeStatusService.getChequeStatus().subscribe((data: ChequeStatusModel[]) => {
      this.chequeStatusFilteredList = this.chequeStatusList = data;
      this.dataCount = data.length;
    });

  }
  generatePDF() {
    debugger;
     const pdffromdate = $('#dateFrom').val();
     const pdftodate = $('#dateTo').val();
 
     
     this.headerList = [];
     this.fieldList = [];
 
     this.attributes.forEach(x => {
       this.headerList.push(x.name);
       this.fieldList.push(x.fieldName);
     });
 
     const widthList = [];
     for (const item of this.headerList) {
       widthList.push(45);
     }
 
     this.defaultArray = [];
     let totalAmount = 0;
     let totalPenalty =0;
     for (var i = 0; i < this.chequeStatusFilteredList.length; i++) {
       let data = {
         "index": i + 1, 
         "chequeno": this.chequeStatusFilteredList[i].chequeNo == undefined ? "" : this.chequeStatusFilteredList[i].chequeNo,
         "bankname": this.chequeStatusFilteredList[i].bankName == undefined ? "" : this.titlecasePipe.transform(this.chequeStatusFilteredList[i].bankName),
         "chequedate": this.chequeStatusFilteredList[i].chequeDate == undefined ? "" : this.chequeStatusFilteredList[i].chequeDate,       
         "paydate": this.chequeStatusFilteredList[i].payDate == undefined ? "" : this.chequeStatusFilteredList[i].payDate,          
         "studentName": this.chequeStatusFilteredList[i].studentName == undefined ? "" : this.titlecasePipe.transform(this.chequeStatusFilteredList[i].studentName),
         "class": this.chequeStatusFilteredList[i].class == undefined ? "" : this.chequeStatusFilteredList[i].class + " " + this.chequeStatusFilteredList[i].batch,
         "batch": this.chequeStatusFilteredList[i].batch == undefined ? "" : this.chequeStatusFilteredList[i].batch,       
         "penalty": this.chequeStatusFilteredList[i].penalty == undefined ? "" : this.chequeStatusFilteredList[i].penalty,             
         "dueAmount": this.chequeStatusFilteredList[i].amount == undefined ? "" : this.chequeStatusFilteredList[i].amount,
       }
       this.defaultArray.push(data);
       totalAmount = totalAmount + parseFloat(this.chequeStatusFilteredList[i].amount);
       totalPenalty = totalPenalty + parseFloat(this.chequeStatusFilteredList[i].penalty);
     }
     let data1 = {
       "index": "", 
       "chequeno":"",
       "bankname":"",
       "chequedate":"",
       "paydate":"",            
       "studentName": "",
       "class": "",
       "batch":"", 
       "penalty":totalPenalty,     
       "dueAmount":totalAmount,
     }
     this.defaultArray.push(data1);
     const docDefinition = {
       pageSize: 'A4',
       pageOrientation: 'portrait',
       content: [
         {
           table: {
             widths: ['auto', '*'],
             //widths: 600,
             body: [
               [{
                 width: 100,
                 height: 100,
                 image: 'imageKey'
               }, {
                 width: '*',
                 alignment: 'center',
                 stack: [
                   {
                     style: 'h1',
                     text: this.institution?.institutionName
                   },
                   {
                     style: 'h2',
                     text: this.institution?.address
                   },
                   {
                     style: 'h2',
                     text: this.currentAcademicYearName
                   },
                   {
                     style: 'h3',
                     text: 'Cheque Status'
                   },
                   {
                     style: 'h3',
                     text: pdffromdate + '   to   ' + pdftodate
                   }
                 ]
               }
               ]
             ]
           },
           layout: {
             hLineWidth: () => 0,
             vLineWidth: () => 0,
             paddingBottom: () => 3,
             paddingLeft: () => 5,
             paddingRight: () => 5
           },
         },
         {
           style: 'sectionHeader',
           table: {
             headerRows: 1,
             widths: widthList,
             autowidth:false,
             body: [
               this.headerList,
               ...this.defaultArray.map((p) => {
                 const temp = [];
                 for (const y of this.fieldList) {                  
                     temp.push(p[y]);                  
                 }
                 return temp;
               })
             ]
           }
         }
       ],
       images: {
         // imageKey: environment.imageUrl + this.institution.logoUrl // via URL address, which can be referenced by this key
         imageKey: this.institution?.logoUrl != null ? environment.imageUrl + this.institution?.logoUrl : environment.imageUrl + '/images/avatar-1.png'
       },
       styles: {
         sectionHeader: {
           fontSize: 8,
           margin: [0, 10, 0, 15],
         },
         h1: {
           margin: [0, 5, 0, 0],
           fontSize: 13,
           bold: true,
         },
         h2: {
           margin: [0, 5, 0, 0],
           fontSize: 10,
           bold: true,
         },
         table:{
           width: 'fixed'
         }
       }
     };
     pdfMake.createPdf(docDefinition).download('Cheque Status Collection.pdf');
   }
 
   generateExcel() {
     this.headerList = [];
     this.fieldList = [];
     this.attributes.forEach(x => {
       this.headerList.push(x.name);
       this.fieldList.push(x.fieldName);
     });
     this.defaultArray = [];
     let totalAmount = 0;
     let totalPenalty = 0;
     for (var i = 0; i < this.chequeStatusFilteredList.length; i++) {
       let data = {            
         "index": i + 1, 
         "chequeno": this.chequeStatusFilteredList[i].chequeNo == undefined ? "" : this.chequeStatusFilteredList[i].chequeNo,
         "bankname": this.chequeStatusFilteredList[i].bankName == undefined ? "" : this.titlecasePipe.transform(this.chequeStatusFilteredList[i].bankName),
         "chequedate": this.chequeStatusFilteredList[i].chequeDate == undefined ? "" : this.chequeStatusFilteredList[i].chequeDate,       
         "paydate": this.chequeStatusFilteredList[i].payDate == undefined ? "" : this.chequeStatusFilteredList[i].payDate,          
         "studentName": this.chequeStatusFilteredList[i].studentName == undefined ? "" : this.titlecasePipe.transform(this.chequeStatusFilteredList[i].studentName),
         "class": this.chequeStatusFilteredList[i].class == undefined ? "" : this.chequeStatusFilteredList[i].class + " " + this.chequeStatusFilteredList[i].batch,
         "batch": this.chequeStatusFilteredList[i].batch == undefined ? "" : this.chequeStatusFilteredList[i].batch,       
         "penalty": this.chequeStatusFilteredList[i].penalty == undefined ? "" : this.chequeStatusFilteredList[i].penalty,             
         "dueAmount": this.chequeStatusFilteredList[i].amount == undefined ? "" : this.chequeStatusFilteredList[i].amount,
       }
       this.defaultArray.push(data);
       totalAmount = totalAmount + Number(this.chequeStatusFilteredList[i].amount);
       totalPenalty = totalPenalty + Number(this.chequeStatusFilteredList[i].penalty);
     }
     let data1 = {
      "index": "", 
       "chequeno":"",
       "bankname":"",
       "chequedate":"",
       "paydate":"",            
       "studentName": "",
       "class": "",
       "batch":"", 
       "penalty":totalPenalty,     
       "dueAmount":totalAmount,
     }
     this.defaultArray.push(data1);
     const workbook = new Excel.Workbook();
     const worksheet = workbook.addWorksheet('Cheque Status');
 
     // const imageId2 = workbook.addImage({
     //   filename: 'images/avatar-1.png',
     //   extension: 'png',
     // });
     // worksheet.addImage(imageId2, 'A1:B1');
 
     const instName = worksheet.addRow([this.institution.institutionName]);
     instName.eachCell(function (cell, colNum) {
       cell.font = {
         name: 'Arial',
         family: 2,
         bold: true,
         size: 10
       }
       cell.alignment = {
         vertical: 'middle',
         horizontal: 'start'
       }
     })
     instName.height = 25;
     const instAddress = worksheet.addRow([this.institution.address]);
     instAddress.eachCell(function (cell, colNum) {
       cell.font = {
         name: 'Arial',
         family: 2,
         bold: true,
         size: 9
       }
       cell.alignment = {
         vertical: 'middle',
         horizontal: 'start'
       }
     })
     instAddress.height = 20;
 
     const instSession = worksheet.addRow([this.currentAcademicYear.name]);
     instSession.eachCell(function (cell, colNum) {
       cell.font = {
         name: 'Arial',
         family: 2,
         bold: true,
         size: 10
       }
       cell.alignment = {
         vertical: 'middle',
         horizontal: 'start'
       }
     })
     instSession.height = 20;
 
     worksheet.addRow([]);
     const titleRow = worksheet.addRow(['Cheque Status']);
     const fromDate = $('#dateFrom').val();
     const toDate = $('#dateTo').val();
     worksheet.addRow([`${fromDate} to ${toDate}`]);
     const headerRow = worksheet.addRow(this.headerList);
     for (var i = 0; i < this.defaultArray.length; i++)
       this.defaultArray[i]["index"] = i + 1;
     for (const x1 of this.defaultArray) {
       const x2 = Object.keys(x1);
       const temp = [];
       for (const y of this.fieldList) {
         if (y === 'payDate') {
           if (x1[y] == "" || x1[y] == undefined) {
             temp.push("");
           } else {
             temp.push(this.formatDate(x1[y], 'dd-MM-yyyy'));
           }
         } else {
           if (y === "paidStatus") {
             if (x1[y] === true || x1[y] === false) {
               temp.push(x1[y] === true ? "Cancelled" : "Paid");
             } else {
               temp.push("")
             }
           }
           else {
             temp.push(x1[y]);
           }
         }
       }
       worksheet.addRow(temp);
     }
     const fname = 'Cheque Status';
 
     worksheet.eachRow((row, rowNumber) => {
 
       row.eachCell((cell, colNumber) => {
         if ([1, 2, 3, 4, 5, 6].includes(rowNumber)) {
         } else {
           // Set border of each cell 
           cell.width = 180;
           cell.border = {
             top: { style: 'thin' },
             left: { style: 'thin' },
             bottom: { style: 'thin' },
             right: { style: 'thin' }
           };
         }
 
       })
       //Commit the changed row to the stream
       row.commit();
     });
 
     // add data and file name and download
     workbook.xlsx.writeBuffer().then((data) => {
       const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
     });
 
   }
   private formatDate(date :Date, format: string) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    switch(format){
      case 'dd-MM-yyyy':
        return [day, month, year].join('-');
        break;
      case 'MM-dd-yyyy':
        return [month, day, year].join('-');
        break;
      default:
        return [day, month, year].join('-');
        break;
    }
  }
   generateAttributes() {
     this.attributes = [
       { name: 'SNO', fieldName: 'index' },       
       { name:'Cheque No',fieldName:"chequeno"}, 
       { name:'Bank Name',fieldName:"bankname"},
       { name:'Cheque Date',fieldName:"chequedate"},
       { name:'Pay Date',fieldName:"paydate"},            
       { name:'Student Name',fieldName: "studentName"},     
       { name: 'CLASS', fieldName: 'class' },
       { name: 'BATCH', fieldName: 'batch' }, 
       { name: 'Penalty', fieldName: 'penalty' } ,     
       { name: 'DUE', fieldName: 'dueAmount' }       
     ];
   }
  ngOnInit(): void {
   
   this.resetAll();   
    this.generateAttributes();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
  }
  resetAll(){
    const self = this;
    this.chequeStatus = "All";
    $('#dateFrom').val(this.formatDate(new Date, 'dd-MM-yyyy'));
    this.fromDate = this.formatDate(new Date, 'dd-MM-yyyy');
    $('#dateTo').val(this.formatDate(new Date, 'dd-MM-yyyy'));
    this.toDate = this.formatDate(new Date, 'dd-MM-yyyy');
    $('#dateFrom').datepicker().on('changeDate', (e) => {
      self.fromDate = e.date;
      this.OnDateFromChangeEvent();
    });
    $('#dateTo').datepicker().on('changeDate', (e) => {
      self.toDate = e.date;
      this.OnDateToChangeEvent();
    });  
    var date = new Date(), year = date.getFullYear(), month = date.getMonth();
    $('#dateFrom').datepicker('update',this.formatDate(new Date(year,month,1), 'dd-MM-yyyy'));
    this.fromDate = this.formatDate(new Date(year,month,1), 'MM-dd-yyyy');
    $('#dateTo').datepicker('update',this.formatDate(new Date(year,month+1,0),'dd-MM-yyyy'));
    this.toDate = this.formatDate(new Date(year,month+1,0), 'MM-dd-yyyy');
    this.OnDateFromChangeEvent();
    this.OnDateToChangeEvent();
    this.getChequeStatus();
  }
  OnDateFromChangeEvent() {
    if (this.toDate != undefined && this.toDate != '' && this.toDate != null) {
       if (this.fromDate >= this.toDate) {
         this.dateValidation = false;
         this.toastr.warning('FromDate is less than ToDate', 'WARNING!', {
           timeOut: 3000
         });
       } else {
         this.dateValidation = true;
       }
     } else {
       this.dateValidation = false;
     }
  }

  OnDateToChangeEvent() {
   
      if (this.fromDate != undefined && this.fromDate != '' && this.fromDate != null) {
        if (this.toDate >= this.fromDate) {
          this.dateValidation = true;
        } else {
          this.dateValidation = false;
          this.toastr.warning('Todate is greater than From date', 'WARNING!', {
            timeOut: 3000
          });
        }
      } else {
        this.dateValidation = false;
      }
  }
}
