import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { StudentService } from './../../studentmanagement/student.service';
import { ClassCourseService } from './../../mastersettings/classcourse/classcourse.service';
import { Student } from './../../studentmanagement/student.model';
import { ClassCourse } from './../../mastersettings/models/classcourse';
import { Batch } from './../../mastersettings/models/batch';
import { BatchService } from './../../mastersettings/batch/batch.service';
import { Attendance } from '../attendance.model';
import { AttendanceService } from '../attendance.service';
import { EventCalendar } from '../../eventcalendar/eventcalendar.model';
import { EventCalendarService } from '../../eventcalendar/eventcalendar.service';
declare var $: any;
import pdfMake from 'pdfmake/build/pdfmake';
import { environment } from '../../../../../environments/environment';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import { ReportAttribute } from '../../studentmanagement/report.model';

@Component({
  selector: 'app-studentwisereport',
  templateUrl: './studentwisereport.component.html',
  styleUrls: ['./studentwisereport.component.css']
})
export class StudentwisereportComponent implements OnInit {

  classcourses: ClassCourse[] = [];
  batches: Batch[] = [];
  students: Student[] = [];
  studentFilteredList: Student[] = [];
  attendances: Attendance[] = [];
  classcourseid = '-1';
  batchid = '-1';
  admissionId = '';
  holidayCount = 0;
  overAllPercent = 0;
  batchNumber = "";
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  institution: any;
  sum_TOTALDAYS: number;
  sum_PERSENT: number;
  sum_ABSENT: number;
  sum_LEAVES: number;
  sum_PERCENTAGE: number;
  constructor(
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    private studentService: StudentService,
    private attendanceService: AttendanceService,
    private eventCalendarService: EventCalendarService
  ) { }

  generateAttributes() {
    this.attributes = [

      { name: 'S NO', fieldName: 'index' },
      { name: 'Month', fieldName: 'month' },
      { name: 'Total Days', fieldName: 'totalDays' },
      { name: 'Present', fieldName: 'present' },
      { name: 'Absent', fieldName: 'absent' },
      { name: 'Leaves', fieldName: 'leaves' },
      { name: 'Percentage', fieldName: 'percentage' }
    ];
  }

  generatePDF() {
    ;
    this.headerList = [];
    this.fieldList = [];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    const widthList = [];
    for (const item of this.headerList) {
      widthList.push('auto');
    }

    for (var i = 0; i < this.attendances.length; i++)
      this.attendances[i]["index"] = i + 1;
    const docDefinition = {
      content: [
        {
          table: {
            widths: ['auto', '*'],
            body: [
              [{
                width: 70,
                height: 80,
                image: 'imageKey'
              }, {
                width: '*',
                alignment: 'center',
                stack: [
                  {
                    style: 'h1',
                    text: this.institution.institutionName
                  },
                  {
                    style: 'h2',
                    text: this.institution.address
                  },
                  {
                    style: 'h2',
                    text: '2021-22'
                  },
                  {
                    style: 'h1',
                    text: 'Student Wise Report'
                  }
                ]
              }
              ]
            ]
          },
          layout: {
            hLineWidth: (line) => line === 1,
            vLineWidth: () => 0,
            paddingBottom: () => 5
          },
        },
        {
          table: {
            headerRows: 1,
            pdfFonts: 6,
            widths: widthList,
            body: [
              this.headerList,
              ...this.attendances.map((p) => {
                const temp = [];
                for (const y of this.fieldList) {
                  if (y === 'dob') {
                    temp.push(this.formatDate(p[y]));
                  } else {
                    temp.push(p[y]);
                  }
                }
                return temp;
              })
            ]
          }
        }
      ],
      images: {
        imageKey: environment.imageUrl + this.institution.logoUrl // via URL address, which can be referenced by this key
      },
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        },
        h1: {
          margin: [0, 0, 0, 0],
          fontSize: 18,
          bold: true
        },
        h2: {
          margin: [0, 0, 0, 0],
          fontSize: 10,
          bold: true
        }
      }
    };

    pdfMake.createPdf(docDefinition).download();

  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('-');
  }

  generateExcel() {
    this.headerList = [];
    this.fieldList = [];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Student Wise Report');
    worksheet.addRow();
    const imageId2 = workbook.addImage({
      base64: this.institution.institutionLogo,
      extension: 'jpg',
    });
    worksheet.addImage(imageId2, 'A1:B1');
    const titleRow = worksheet.addRow(['Student Wise Report']);
    const headerRow = worksheet.addRow(this.headerList);
    for (var i = 0; i < this.attendances.length; i++)
      this.attendances[i]["index"] = i + 1;
    for (const x1 of this.attendances) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'dob') {
          temp.push(this.formatDate(x1[y]));
        } else {
          temp.push(x1[y]);
        }
      }
      worksheet.addRow(temp);
    }
    const fname = 'Student Report';

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }
  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  ListStudents() {
    this.studentService.getAll().subscribe((data: any[]) => {
      console.log(data);
      this.students = data;
    });
  }

  onClassSelected(value: string) {
    this.overAllPercent = 0;
    this.attendances = [];
    //this.batchid = "-1"; 
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
    });
  }

  onBatchSelected(value: string) {
    // this.studentService.getAll().subscribe((data: any[]) => {
    // console.log(data);
    this.overAllPercent = 0;
    this.attendances = [];
    this.studentFilteredList = this.students.filter((student: Student) =>
      student.batchId.toString() === value.toString());
    // });
  }

  getHolidays(currentMonth: string) {
    console.log(currentMonth);
    this.eventCalendarService.getAll().subscribe((data: EventCalendar[]) => {
      console.log(data);
      let totalWorkingDays = 0;
      this.holidayCount = data.filter((x) => {
        // console.log(new Date(x.startDate).getMonth());
        return x.eventType === 'Holiday' && new Date(x.startDate).getMonth() + 1 === 11;
      }).length;
      const days = moment(currentMonth, 'MMM-YYYY').daysInMonth();
      console.log(days);
      totalWorkingDays = days - this.holidayCount;
      return totalWorkingDays;
    });

  }

  caculateSUM(value:Attendance[]){
    this.sum_TOTALDAYS=0;
    this.sum_PERSENT=0;
    this.sum_ABSENT=0;
    this.sum_LEAVES=0;
    this.sum_PERCENTAGE=0;
    value.forEach(item => {
      this.sum_TOTALDAYS += parseInt(item.totalDays);
      this.sum_PERSENT+=parseInt(item.present);
  
      this.sum_ABSENT+=parseInt(item.absent);
      this.sum_LEAVES+=parseInt(item.leaves);
      this.sum_PERCENTAGE+=parseInt(item.percentage);
    });
  }

  onAdmisisonSelected(value: string) {
    this.batchNumber = value;

    //this.eventCalendarService.getAll().subscribe((data: EventCalendar[]) => {
      //console.log(data);
      //let totalWorkingDays = 0;
      //debugger;
      // this.holidayCount = data.filter((x) => {
      //   return x.eventType === 'Holiday' && new Date(x.startDate).getMonth() + 1 === 11;
      // }).length;
      // debugger;
      //alert(value);
      this.attendanceService.getSummarizeDetails(value).subscribe((adata: Attendance[]) => {
        //debugger;
        //console.log(adata);
        var total = 0;
        var count = 0;
        adata.forEach((x) => {
          //const days = moment(x.month, 'MMM-YYYY').daysInMonth();
          //totalWorkingDays = days - this.holidayCount;
          //x.totalDays = totalWorkingDays.toString();
          //x.percentage = (Number((parseInt(x.present, 0) / parseInt(x.totalDays, 0)).toFixed(2))*100).toFixed(2).toString();
          //  x.leaves
          total += Number(x.percentage);
          count++;
        });
        if (count > 0)
          this.overAllPercent = Number((total / count).toFixed(2));
        else
          this.overAllPercent = 0;
        this.attendances = adata;
        this.caculateSUM(adata);
      });
    //});
    
  }

  onKeyUpAdmissionEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    console.log(this.students);
    if (keyword === '') {
      this.studentFilteredList = this.students;
    } else {
      this.studentFilteredList = this.students
        .filter((admission: Student) => admission.admissionNo.toLocaleLowerCase()
          .includes(keyword.toLocaleLowerCase()));
    }
    if (this.studentFilteredList.length === 1) {
      this.attendanceService.getSummarizeDetails(this.studentFilteredList[0].id).subscribe((data: Attendance[]) => {
        console.log(data);
        this.attendances = data;
      });
    }
  }

  search() {

    ;
    var student = [];
    if (this.admissionId.toString() != "") {
      student = this.students.filter((admission: Student) => admission.id.toString() === this.admissionId.toString());
    }
    else {
      this.onAdmisisonSelected(this.batchNumber);
      return;
    }
    console.log(student);
    if (student.length > 0) {
      this.attendanceService.getSummarizeDetails(student[0].id).subscribe((data: Attendance[]) => {
        console.log(data);
        this.attendances = data;
        this.caculateSUM(data);
      });
    }
    else {
      this.attendances = [];
    }

  }

  ngOnInit(): void {
    this.generateAttributes();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    const self = this;
    this.ListClassCourse();
    this.ListStudents();
    $('.select2').select2();
    $('.select2').on('change', function () {
      self.onAdmisisonSelected($(this).val());
    });
  }

}
