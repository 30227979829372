import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OnlineExaminationRoutingModule } from './online-examination-routing.module';
import { OnlineExaminationComponent } from './online-examination/online-examination.component';
import { CreateQuestionModule } from './create-question/create-question.module';
import { QuestionListModule } from './question-list/question-list.module';

@NgModule({
  declarations: [ OnlineExaminationComponent],
  imports: [
    CommonModule,
    OnlineExaminationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CreateQuestionModule,
    QuestionListModule
  ]
})
export class OnlineExaminationModule { }
