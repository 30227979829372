import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { IBackupSetting } from './backup-restore.model';
import { BackupAndRestoreLogs } from './backup-restore.model';
import { IBackupAndRestoreLog } from './backup-restore.model';
import { BackupSettingService } from './backup-setting.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-backup-restore',
  templateUrl: './backup-restore.component.html',
  styleUrls: ['./backup-restore.component.css']
})
export class BackupRestoreComponent implements OnInit {
  submitted = false;
  private initalValue;
  private backupSettingId: number;
  private subscriptions: Subscription[];
  public backupSettingForm: UntypedFormGroup;
  public backupSetting: IBackupSetting;
  backupLog: IBackupAndRestoreLog;
  logs: BackupAndRestoreLogs[] = [];
  progress = 0;
  intervalId: any | null = null;
  progressBarValue: string = '0%';
 
  constructor(
    public formBuilder: UntypedFormBuilder,
    private backupSettingService: BackupSettingService,
    private toaster: ToastrService
  ) {
    this.subscriptions = new Array<Subscription>();
   }

  ngOnInit(): void {
    this.backupSettingForm = this.formBuilder.group({
      id: [0],
      localBackupPath: [''],
      cloudBackupPath: [''],
      whenCloseApplication: [false],
      endOfDay: [false]
    }, {validator: this.checkAnyValuePresent});
    this.initalValue = this.backupSettingForm.value;
    this.getBackupSetting();
    this.ListLogs();
  }

  checkAnyValuePresent(formGroup: UntypedFormGroup) {
    const localBackupPathValue = formGroup.get('localBackupPath').value;
    const cloudBackupPathValue = formGroup.get('cloudBackupPath').value;
    if(!localBackupPathValue && !cloudBackupPathValue) {
      return {anyValueRequired: true}
    }
    return null;
  }

  get f() {
    return this.backupSettingForm.controls;
  }

  ListLogs() {
    this.backupSettingService.getAll().subscribe((data: BackupAndRestoreLogs[]) => {
      this.logs = data;
    });
  }

  getBackupSetting() {
    const subscription = this.backupSettingService.getBackupSetting()
      .subscribe(backupSetting => {
        this.backupSetting = backupSetting;
        this.backupSettingId = this.backupSetting.id;
        this.fillForm();
      });
  }

  backupNow() {
    this.progress = 0;
    this.intervalId =setInterval(() =>{
      this.progress += 5;
      this.progressBarValue = `${this.progress}%`;
      if(this.progress >= 100){
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }, 100);

    if(this.backupSettingForm.hasError('anyValueRequired')) {
      this.submitted = false;
      this.toaster.error('At lease one path is required.');
    }
    debugger;
    if (this.backupSettingForm.valid) {
      const localBackupPathValue = this.backupSettingForm.get('localBackupPath').value;
      const cloudBackupPathValue = this.backupSettingForm.get('cloudBackupPath').value;
      this.backupLog = {
        backupDate: new Date(),
        restoreDate: '',
        size: '',
        backupPath: localBackupPathValue,
        cloudBackupPath: cloudBackupPathValue
      };
      this.backupSettingService.createBackup(this.backupLog).subscribe(_ => {
        this.toaster.success('Backup created Successfully');
        this.ListLogs();
        clearInterval(this.intervalId);
        this.intervalId = null;
      });
    }
  }


  submitForm() {
    if(this.backupSettingForm.hasError('anyValueRequired')) {
      this.submitted = false;
      this.toaster.error('At lease one path is required.');
    }
    this.submitted = true;
    if (this.backupSettingForm.valid) {
       const backupsetting: IBackupSetting = this.backupSettingForm.value;
       backupsetting.id = this.backupSettingId === undefined ? 0 : this.backupSettingId;
       const subscription = this.backupSettingService.saveBackupSetting(backupsetting)
         .subscribe(_ => {
           this.toaster.success('Backup Setting Saved Successfully');
           this.getBackupSetting();
           this.resetForm();
         });
       this.subscriptions.push(subscription);
    }
  }

  fillForm() {
    this.backupSettingForm.reset(this.backupSetting);
    this.backupSettingId = this.backupSetting.id;
  }

  resetForm() {
    this.submitted = false;
    this.backupSettingForm.reset(this.initalValue);
  }

  ngOnDestroy() {
    this.subscriptions.map(subscription => {
      subscription.unsubscribe()
    });
  }


}
