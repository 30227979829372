import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-managedemo',
  templateUrl: './managedemo.component.html',
  styleUrls: ['./managedemo.component.css']
})
export class ManagedemoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
