import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OutstandingDueService } from './outstandingduereport.service';
import {
  OutstandingDueReportModel, OutstandingDueFollowUpDetails,
  OutstandingDueFollowUpList, OutstandingFeeDueDetails, OutstandingFeeDueHeadWise
} from './outstandingduereport.model';
import { ClassCourse } from '../../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../../mastersettings/models/batch';
import { BatchService } from '../../../mastersettings/batch/batch.service';
import { Installment } from '../../installment/installment.model';
import { FeeHead } from '../../feehead/feehead.model';
import { data } from 'jquery';
import { DatePipe } from '@angular/common';
// declare var $: any;
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import { ReportAttribute } from '../../../studentmanagement/report.model';
import { environment } from 'src/environments/environment';
import { SettingsService } from '../../../mastersettings/settings.service';
import { Academicyear } from '../../../mastersettings/models/academicyear';

import { TitleCasePipe } from '@angular/common';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgbDateParserFormatter, NgbDateStruct, NgbInputDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
// import * as $ from 'jquery';
// import 'jquery'

@Component({
  selector: 'app-outstandingduereport',
  templateUrl: './outstandingduereport.component.html',
  styleUrls: ['./outstandingduereport.component.css'],
  providers: [TitleCasePipe]
})
export class OutstandingduereportComponent implements OnInit {
  /*Student Due FollowUp*/

  minDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };

  StudentId: any;
  AcademicYearId: any;
  InstallmentId: any;
  StudentName: string;
  FathersName: string;
  dateValidation: boolean = false;
  Class: string;
  ContactNumber: string;
  TotalFollowup: number;
  PickedCall: string;
  rowid: any;
  UnpickedCall: string;
  DueAmount: any;
  /*End*/
  showFeeDetail: boolean[] = [];
  submitted = false;
  classDrp: string;
  batchDrp: string;
  statusDrp: string;
  fromDate: string;

  toDate: string;
  totalDueAmount: number;
  dataCount: any;
  outstandingDueReportList: OutstandingDueReportModel[] = [];
  outstandingDueReportReportFilteredList: OutstandingDueReportModel[] = [];
  outstandingDueReportTemp: OutstandingDueReportModel[] = [];
  outstandingDueFollowUpDetails: OutstandingDueFollowUpDetails = {} as OutstandingDueFollowUpDetails;
  outstandingFeeDueDetails: OutstandingFeeDueDetails = {} as OutstandingFeeDueDetails;
  outstandingFeeDueDetailsList: OutstandingFeeDueDetails[] = [];
  outstandingDueFollowUpList: OutstandingDueFollowUpList[] = [];
  outstandingFeeDueHeadWiseList: OutstandingFeeDueHeadWise[] = [];
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  installments: Installment[] = [];
  feeheads: FeeHead[] = [];
  studentDueFollowUpForm: UntypedFormGroup;
  headerList: string[] = [];
  fieldList: string[] = [];
  academicYears: Academicyear[] = [];
  attributes: ReportAttribute[] = [];
  institution: any;
  defaultArray = [];
  currentAcademicYearId: number;
  currentAcademicYearName = '';
  dates: string;

  constructor(
    public outstandingDueService: OutstandingDueService,
    public classCourseService: ClassCourseService,
    public batchService: BatchService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private academicYearService: SettingsService,
    private titlecasePipe: TitleCasePipe

  ) {
    this.GetCurrentAcademicYear();
  }
  ListClassCourse() {
   
    this.classCourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }

  GetCurrentAcademicYear() {
    this.academicYearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
      this.currentAcademicYear();
    });
  }

  currentAcademicYear() {
    this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.currentAcademicYearName = this.academicYears.find(x => x.id.toString() === data.id.toString()).name;
     
    });
  }

  onStatusChanged() {
    // this.onSearch();
  }
  onClassSelected(value: string) {
    this.classDrp = value == 'All' ? '-1' : this.classDrp;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => (batch.classId.toString().toLocaleLowerCase()) === (value.toLocaleLowerCase()));
      // this.onSearch();
     
    });

  }
  onBatchChanged(value: string) {
    this.batchDrp = value == 'All' ? '-1' : this.batchDrp;
    // this.onSearch();
  }
  tdtchange(event: any) {
    var tdt = event.target.value
    if (tdt == null || tdt == undefined || tdt == '') {
      this.dateValidation = false;
      this.toDate = "";
    }
  }
  fdtchange(event: any) {
    var tdt = event.target.value
    if (tdt == null || tdt == undefined || tdt == '') {
      this.dateValidation = false;
      this.fromDate = "";
    }
  }
  loader: any = false;
  onSearch() {
    this.loader = true;
    let drpClass = "";
    let drpBatch = "";
    let drpStatus = "";
    this.DueAmount = 0;
    if (this.classDrp == 'All') {
      drpClass = '-1'
    } else {
      drpClass = this.classDrp;
    }
    if (this.batchDrp == 'All') {
      drpBatch = '-1'
    } else {
      drpBatch = this.batchDrp;
    }
    if (this.statusDrp == 'All') {
      drpStatus = '-1'
    } else {
      drpStatus = this.statusDrp == 'Active' ? '1' : '0';
    }
    // this.fromDate = $('#dateFroms').val();
    // this.toDate = $('#dateTos').val();
   ;
    if (this.fromDate != undefined && this.fromDate != '' && this.fromDate != null &&
      this.toDate != undefined && this.toDate != '' && this.toDate != null && this.dateValidation != false) {
      const fromDate = this.fromDate;
      const toDate = this.toDate;
      console.log(drpBatch);
      let startDt: Date;
      let endDt: Date;
      const datePipe = new DatePipe('en-Us');
      var fdt = datePipe.transform(this.fromDate, 'yyyy-MM-dd');
      var tdt = datePipe.transform(this.toDate, 'yyyy-MM-dd');
      this.dates = fdt + ' To ' + tdt;
      let details = [{
        fromdate: fdt,
        todate: tdt,
        class: drpClass,
        batch: drpBatch,
        status: drpStatus
      }]
      //;
      this.outstandingDueService.getOutstandingDueSearchReport(details).subscribe((data: OutstandingDueReportModel[]) => {
        debugger;
        this.loader = false;
        this.outstandingDueReportList = data.filter(x=>x.installment!="0");
        if (this.outstandingDueReportList.length > 0) {
          this.outstandingDueReportReportFilteredList = this.outstandingDueReportList;
          this.outstandingDueReportTemp = this.outstandingDueReportList = this.outstandingDueReportList;
          this.outstandingDueReportReportFilteredList = this.outstandingDueReportTemp;
          this.totalDueAmount = this.outstandingDueReportReportFilteredList.map(item =>
            parseFloat(item.totalDueAmount)).reduce((prev, curr) => prev + curr, 0);
        }else{
          this.outstandingDueReportReportFilteredList=[];
        }

      });


      // if (typeof this.fromDate !== 'undefined' && typeof this.toDate !== 'undefined' && this.fromDate !== '' && this.toDate != '') {
      //   startDt = new Date(fromDate);
      //   endDt = new Date(toDate)
      //   this.outstandingDueReportTemp = this.outstandingDueReportTemp.filter(d => {
      //     const date = new Date(d.installmentDueDate);
      //     return (startDt.getTime() <= date.getTime() && endDt.getTime() >= date.getTime());
      //   });
      // }
      // if (drpClass !== '-1' && drpClass !== '' && drpClass !== 'undefined' && drpClass !== 'Class') {
      //   this.outstandingDueReportTemp = this.outstandingDueReportTemp.filter(d => {
      //     return (d.class.toLowerCase() === drpClass.toLowerCase());
      //   });
      // }

      // if (drpBatch !== '-1' && drpBatch !== '' && drpBatch !== 'undefined' && drpBatch !== 'Batch') {
      //   this.outstandingDueReportTemp = this.outstandingDueReportTemp.filter(d => {
      //     console.log(d.batch.toLocaleLowerCase());
      //     return (d.batch.toLocaleLowerCase() === drpBatch.toLocaleLowerCase());
      //   });
      // }

    } else {
      this.dateValidation = false;
      if (this.fromDate == undefined || this.fromDate == '' || this.fromDate == null ||
        this.toDate == undefined || this.toDate == '' || this.toDate == null) {
        this.toastr.warning('Please select date', 'WARNING!', {
          timeOut: 3000
        });
      } else {
        if (this.fromDate > this.toDate) {
          this.toastr.warning('FromDate is less than ToDate', 'WARNING!', {
            timeOut: 3000
          });
        }
      }

    }

  }
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === '') {
      this.onSearch();
    } else {
      this.outstandingDueReportReportFilteredList = this.outstandingDueReportTemp.filter(d => {
        return (d.studentName?.toLocaleLowerCase().includes(keyword?.toLocaleLowerCase()) ||
          d.fatherName?.toLocaleLowerCase().includes(keyword?.toLocaleLowerCase()) ||
          d.admissionNo?.toLocaleLowerCase().includes(keyword?.toLocaleLowerCase()));
      });
      // this.outstandingDueReportReportFilteredList = this.outstandingDueReportTemp;
      this.totalDueAmount = this.outstandingDueReportReportFilteredList.map(item =>
        parseFloat(item.totalDueAmount)).reduce((prev, curr) => prev + curr, 0);
    }
  }
  getOutstandingDueReport() {
    const self = this;
    this.outstandingDueService.getOutstandingDueReport().subscribe((data: OutstandingDueReportModel[]) => {
      console.log(data);
      this.outstandingDueReportReportFilteredList = this.outstandingDueReportList = data;
      this.totalDueAmount = data.map(item => parseFloat(item.totalDueAmount)).reduce((prev, curr) => prev + curr, 0);
    });

  }

  generatePDF() {
   
    const pdffromdate = $('#dateFroms').val();
    const pdftodate = $('#dateTos').val();
    this.headerList = [];
    this.fieldList = [];

    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const widthList = [];
    for (const item of this.headerList) {
      widthList.push('auto');
    }

    this.defaultArray = [];
    let totalAmount = 0;
    for (var i = 0; i < this.outstandingDueReportReportFilteredList.length; i++) {
      let data = {
        "index": i + 1,
        "admissionNo": this.outstandingDueReportReportFilteredList[i].admissionNo == undefined ? "" : this.outstandingDueReportReportFilteredList[i].admissionNo,
        "studentName": this.outstandingDueReportReportFilteredList[i].studentName == undefined ? "" : this.titlecasePipe.transform(this.outstandingDueReportReportFilteredList[i].studentName),
        "class": this.outstandingDueReportReportFilteredList[i].class == undefined ? "" : this.outstandingDueReportReportFilteredList[i].class + " " + this.outstandingDueReportReportFilteredList[i].batch,
        "batch": this.outstandingDueReportReportFilteredList[i].batch == undefined ? "" : this.outstandingDueReportReportFilteredList[i].batch,
        "rollNo": this.outstandingDueReportReportFilteredList[i].rollNo == undefined ? "" : this.outstandingDueReportReportFilteredList[i].rollNo,
        "mobileNo": this.outstandingDueReportReportFilteredList[i].mobileNo == undefined ? "" : this.outstandingDueReportReportFilteredList[i].mobileNo,
        "installment": this.outstandingDueReportReportFilteredList[i].installment == undefined ? "" : this.outstandingDueReportReportFilteredList[i].installment,
        "fathersName": this.outstandingDueReportReportFilteredList[i].fatherName == undefined ? "" : this.titlecasePipe.transform(this.outstandingDueReportReportFilteredList[i].fatherName),
        "dueAmount": this.outstandingDueReportReportFilteredList[i].totalDueAmount == undefined ? "" : this.outstandingDueReportReportFilteredList[i].totalDueAmount,
      }
      this.defaultArray.push(data);
      totalAmount = totalAmount + Number(this.outstandingDueReportReportFilteredList[i].totalDueAmount);
    }
    let data1 = {
      "index": "",
      "admissionNo": "",
      "studentName": "",
      "class": "",
      "batch": "",
      "rollNo": "",
      "mobileNo": "",
      "installment": "",
      "fathersName": "Total Due",
      "dueAmount": totalAmount,
    }
    this.defaultArray.push(data1);
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      content: [
        {
          table: {
            widths: ['auto', '*'],
            body: [
              [{
                width: 100,
                height: 100,
                image: 'imageKey'
              }, {
                width: '*',
                alignment: 'center',
                stack: [
                  {
                    style: 'h1',
                    text: this.institution?.institutionName
                  },
                  {
                    style: 'h2',
                    text: this.institution?.address
                  },
                  {
                    style: 'h2',
                    text: this.currentAcademicYearName
                  },
                  {
                    style: 'h3',
                    text: 'OUTSTANDING DUE REPORT'
                  },
                  {
                    style: 'h3',
                    text: pdffromdate + '   to   ' + pdftodate
                  }
                ]
              }
              ]
            ]
          },
          layout: {
            hLineWidth: () => 0,
            vLineWidth: () => 0,
            paddingBottom: () => 3,
            paddingLeft: () => 5,
            paddingRight: () => 5
          },
        },
        {
          style: 'sectionHeader',
          table: {
            headerRows: 1,
            widths: widthList,
            body: [
              this.headerList,
              ...this.defaultArray.map((p) => {
                const temp = [];
                for (const y of this.fieldList) {
                  if (y === 'dob') {
                    temp.push(this.formatDate(p[y], 'dd-MM-yyyy'));
                  } else {
                    temp.push(p[y]);
                  }
                }
                return temp;
              })
            ]
          }
        }
      ],
      images: {
        // imageKey: environment.imageUrl + this.institution.logoUrl // via URL address, which can be referenced by this key
        imageKey: this.institution?.logoUrl != null ? environment.imageUrl + this.institution?.logoUrl : environment.imageUrl + '/images/avatar-1.png'
      },
      styles: {
        sectionHeader: {
          fontSize: 8,
          margin: [0, 10, 0, 15],
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 13,
          bold: true,
        },
        h2: {
          margin: [0, 5, 0, 0],
          fontSize: 10,
          bold: true,
        }
      }
    };
    pdfMake.createPdf(docDefinition).download('OUTSTANDING DUE REPORT COLLECTION.pdf');
  }

  generateExcel() {
    this.headerList = [];
    this.fieldList = [];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    this.defaultArray = [];
    let totalAmount = 0;
    for (var i = 0; i < this.outstandingDueReportReportFilteredList.length; i++) {
      let data = {
        "index": i + 1,
        "admissionNo": this.outstandingDueReportReportFilteredList[i].admissionNo == undefined ? "" : this.outstandingDueReportReportFilteredList[i].admissionNo,
        "studentName": this.outstandingDueReportReportFilteredList[i].studentName == undefined ? "" : this.outstandingDueReportReportFilteredList[i].studentName,
        "class": this.outstandingDueReportReportFilteredList[i].class == undefined ? "" : this.outstandingDueReportReportFilteredList[i].class,
        "batch": this.outstandingDueReportReportFilteredList[i].batch == undefined ? "" : this.outstandingDueReportReportFilteredList[i].batch,
        "rollNo": this.outstandingDueReportReportFilteredList[i].rollNo == undefined ? "" : this.outstandingDueReportReportFilteredList[i].rollNo,
        "fathersName": this.outstandingDueReportReportFilteredList[i].fatherName == undefined ? "" : this.outstandingDueReportReportFilteredList[i].fatherName,
        "mobileNo": this.outstandingDueReportReportFilteredList[i].mobileNo == undefined ? "" : this.outstandingDueReportReportFilteredList[i].mobileNo,
        "installment": this.outstandingDueReportReportFilteredList[i].installment == undefined ? "" : this.outstandingDueReportReportFilteredList[i].installment,
        "dueAmount": this.outstandingDueReportReportFilteredList[i].totalDueAmount == undefined ? "" : this.outstandingDueReportReportFilteredList[i].totalDueAmount,
      }
      this.defaultArray.push(data);
      totalAmount = totalAmount + Number(this.outstandingDueReportReportFilteredList[i].totalDueAmount);
    }
    let data1 = {
      "index": "",
      "admissionNo": "",
      "studentName": "",
      "class": "",
      "batch": "",
      "rollNo": "",
      "fathersName": "Total Due",
      "mobileNo": "",
      "installment": "",
      "dueAmount": totalAmount,
    }
    this.defaultArray.push(data1);
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('OUTSTANDING DUE REPORT COLLECTION SUMMARY');

    // const imageId2 = workbook.addImage({
    //   filename: 'images/avatar-1.png',
    //   extension: 'png',
    // });
    // worksheet.addImage(imageId2, 'A1:B1');

    const instName = worksheet.addRow([this.institution.institutionName]);
    instName.eachCell(function (cell, colNum) {
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 10
      }
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'start'
      }
    })
    instName.height = 25;
    const instAddress = worksheet.addRow([this.institution.address]);
    instAddress.eachCell(function (cell, colNum) {
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 9
      }
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'start'
      }
    })
    instAddress.height = 20;

    const instSession = worksheet.addRow([this.currentAcademicYear.name]);
    instSession.eachCell(function (cell, colNum) {
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 10
      }
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'start'
      }
    })
    instSession.height = 20;

    worksheet.addRow([]);
    const titleRow = worksheet.addRow(['OUTSTANDING DUE REPORT COLLECTION FOR']);
    const fromDate = $('#dateFroms').val();
    const toDate = $('#dateTos').val();
    worksheet.addRow([`${fromDate} to ${toDate}`]);
    const headerRow = worksheet.addRow(this.headerList);
    for (var i = 0; i < this.defaultArray.length; i++)
      this.defaultArray[i]["index"] = i + 1;
    for (const x1 of this.defaultArray) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'payDate') {
          if (x1[y] == "" || x1[y] == undefined) {
            temp.push("");
          } else {
            temp.push(this.formatDate(x1[y], 'dd-MM-yyyy'));
          }
        } else {
          if (y === "paidStatus") {
            if (x1[y] === true || x1[y] === false) {
              temp.push(x1[y] === true ? "Cancelled" : "Paid");
            } else {
              temp.push("")
            }
          }
          else {
            temp.push(x1[y]);
          }
        }
      }
      worksheet.addRow(temp);
    }
    const fname = 'OUTSTANDING DUE REPORT COLLECTION SUMMARY';

    worksheet.eachRow((row, rowNumber) => {

      row.eachCell((cell, colNumber) => {
        if ([1, 2, 3, 4, 5, 6].includes(rowNumber)) {
        } else {
          // Set border of each cell 
          cell.width = 180;
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        }

      })
      //Commit the changed row to the stream
      row.commit();
    });

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }

  generateAttributes() {
    this.attributes = [
      { name: 'SNO', fieldName: 'index' },
      { name: 'ADM NO', fieldName: 'admissionNo' },
      { name: 'NAME', fieldName: 'studentName' },
      { name: 'CLASS', fieldName: 'class' },
      // { name: 'BATCH', fieldName: 'batch' },
      { name: 'ROLL', fieldName: 'rollNo' },
      { name: 'CONTACT', fieldName: 'mobileNo' },
      { name: 'INST', fieldName: 'installment' },
      { name: 'FATHER', fieldName: 'fathersName' },
      { name: 'DUE', fieldName: 'dueAmount' }
    ];
  }

  print() {
    if (this.outstandingDueReportReportFilteredList.length > 0) {
      localStorage.setItem('outstandingprint', JSON.stringify(this.outstandingDueReportReportFilteredList));
    }
    else {
      localStorage.setItem('outstandingprint', '');
    }
  }

  private formatDate(date :Date, format: string) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    switch(format){
      case 'dd-MM-yyyy':
        return [day, month, year].join('-');
        break;
      case 'MM-dd-yyyy':
        return [month, day, year].join('-');
        break;
      default:
        return [day, month, year].join('-');
        break;
    }
  }

  travellerAmount: any = 0;
  viewInstallmentDetails(admissionId, classn,batchn, fromdate, todate) {
    debugger;
    this.rowid = 0;
    this.DueAmount = 0;
    const classId = this.classes.filter((x) => x.classCourseName === classn)[0].id;
    const batchId = this.batches.filter((x) => x.batchName=== batchn && x.classId==classId)[0].id;
    this.outstandingDueService.getOutstandingDueFollowUpDetails(admissionId, 0, 0).subscribe((data1: OutstandingDueFollowUpDetails) => {
      this.StudentName = data1.studentName;
    });
    debugger;
    const datePipe = new DatePipe('en-Us');
    var fdt = datePipe.transform(this.fromDate, 'yyyy-MM-dd');
    var tdt = datePipe.transform(this.toDate, 'yyyy-MM-dd');
    //this.outstandingDueService.getFeePayment(admissionId, fdt, tdt).subscribe((inst: Installment[]) => {
    // this.outstandingDueService.getFeePayment(admissionId, fdt, tdt).subscribe((inst) => {
    //  console.log(inst);
    //   inst['table'].forEach((x) => {
    //   //  this.installments = inst;
    //     this.outstandingDueService.getByInstallmentId(x.id, classId, admissionId).subscribe((fHead: FeeHead[]) => {
    //       this.feeheads = fHead;

    //      ;
    //       let feeamount = [];
    //       if (x.fineAmount > 0) {
    //         feeamount.push({
    //           id: x.id,
    //           feeHeadName: "Fine Amount",
    //           amount: x.fineAmount
    //         })
    //         fHead.push(feeamount[0]);

    //       }
    //       x.installmentFeeHead = fHead;    

    //    if(x.installmentId >= (inst['table1'].length>0?inst['table1'][0].installmentId:0))
    //    {
    //    this.travellerAmount = inst['table1'][0].amount;  
    //    }
    //    else
    //    {
    //     this.travellerAmount = 0;
    //    }  
    //     // this.totalAmount = x.installmentFeeHead.reduce((sum: number, current) =>sum + Number(current.amount), 0);
    //      });
    //    });

    //    this.installments = inst['table'];
    //    this.travellerAmount = inst['table1'][0].amount;  
    //    for (var i = 0; i < this.installments.length; i++) { 
    //     if(this.installments[i].installmentId >= (inst['table1'].length>0?inst['table1'][0].installmentId:0))
    //     this.DueAmount = this.DueAmount + this.installments[i].amount + (this.installments[i].fineAmount==undefined?0:this.installments[i].fineAmount) + inst['table1'][0].amount;
    //     else
    //     this.DueAmount = this.DueAmount + this.installments[i].amount + (this.installments[i].fineAmount==undefined?0:this.installments[i].fineAmount); 
    //    } 
    // });
    this.outstandingDueService.getFeePayment(admissionId, fdt, tdt).subscribe((inst: Installment[]) => {
      debugger;
      console.log('instalement : '+inst);
      this.installments=inst.filter(x=>x.paid=='0');
      this.installments.filter(x => x.isWaved == false).forEach((x, y) => {
        this.outstandingDueService.getByInstallmentId(x.id, classId,batchId, admissionId).subscribe((fHead: FeeHead[]) => {
          debugger;
          let feeamount = [];
          if (x.fineAmount > 0) {
            feeamount.push({
              id: x.id,
              feeHeadName: "Fine Amount",
              amount: x.fineAmount
            })
            fHead.push(feeamount[0]);
          }
          x.installmentFeeHead = fHead;
          x.amount = null;
          x.installmentFeeHead.forEach((y) => {
            this.DueAmount += y.amount;
            x.amount += y.amount;
          })
        });
      });
      //this.installments = inst.filter(x=>x.paid=='0');

    });
  }


  showFee(value: number, id: number) {
    // this.feeHeads = [];
    //debugger;
    if (this.rowid == value) {
      this.rowid = 0;
      this.showFeeDetail[id] = !this.showFeeDetail[id];
    } else {
      this.rowid = 0;
      this.rowid = value;
      this.showFeeDetail[id] = !this.showFeeDetail[id];
    }

  }

  onInstallmentDetails(studentId, academicYearId, installmentId) {
    // 
    this.outstandingDueService.getOutstandingFeeDueDetails(1, 2, 3).subscribe(
      (data: OutstandingFeeDueDetails) => {
        // this.outstandingDueFollowUpList=data.forEach((item, index) => {
        // self.outstandingFeeDueHeadWiseList[index]=item;
        // });;
        const self = this;
        data.objOutstandingFeeDueHeadWise.forEach((item, index) => {
          self.outstandingFeeDueHeadWiseList[index] = item;
        });

      });
  }
  onaddFollowUp(studentId, academicYearId, installmentId, tamount, fineamount) {
    this.StudentId = studentId;
    this.outstandingDueService.getOutstandingDueFollowUpDetails(studentId, 0, 0)
      .subscribe((data: OutstandingDueFollowUpDetails) => {
        console.log(data);
       ;
        // outstandingDueFollowUpDetails:OutstandingDueFollowUpDetails[]=[];
        // outstandingDueFollowUpList:OutstandingDueFollowUpList[]=[];
        this.outstandingDueFollowUpDetails = data;
        this.StudentId = data.studentId;
        this.AcademicYearId = data.academicYearId;
        this.InstallmentId = data.installmentId;
        this.StudentName = data.studentName;
        this.FathersName = data.fathersName;
        this.Class = data.class;
        this.ContactNumber = data.mobileNo;
       
        this.TotalFollowup = data.totalFollowUp;
        this.PickedCall = data.pickedCall;
        this.UnpickedCall = data.unpickedCall;
        this.DueAmount = tamount + fineamount;
        // const self = this;
        data.objOutstandingDueFollowUpList.forEach((item, index) => {
          this.outstandingDueFollowUpList[index] = item;
        });

        if (data.objOutstandingDueFollowUpList == null ||
          data.objOutstandingDueFollowUpList.length <= 0) {
          this.outstandingDueFollowUpList = [];
        }
      });
  }
  resetAll() {
   
    const self = this;
    this.statusDrp = 'All';
    this.batchDrp = 'All';
    this.classDrp = 'All';
    var date = new Date();
    // this.fromDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    // this.dateTos = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    $('#dateFroms').datepicker('setDate', new Date(date.getFullYear(), date.getMonth(), 1));
    $('#dateFroms').datepicker().on('changeDate', (e) => {
      self.fromDate = e.date;
    });

    $('#dateTos').datepicker('setDate', new Date(date.getFullYear(), date.getMonth(), 2));
    $('#dateTos').datepicker().on('changeDate', (e) => {
      self.toDate = e.date;
    });

    this.outstandingDueReportReportFilteredList = [];
  }

  resetForm() {
    this.studentDueFollowUpForm = this.fb.group({
      id: [0],
      studentId: [''],
      academicYearId: [''],
      installmentId: [''],
      isCallPicked: [true], // 02-Nov
      remarks: [''], // 02-Nov
      commitmentdate: [''],
      isNotify: ['false'],
      isActive: [true]
    });
  }
  createfollowp() {
    // ;
    this.submitted = true;
    this.studentDueFollowUpForm.get('studentId').patchValue(this.StudentId);
    // this.leadFollowupForm.get("leadcode").patchValue(this.LeadID);
    // stop here if form is invalid
    if (this.studentDueFollowUpForm.invalid) {
      return;
    }

   
    if (this.studentDueFollowUpForm.invalid) {
      return;
    }


   ;
    if (this.studentDueFollowUpForm.get('id').value === 0) {
      this.outstandingDueService.create(this.studentDueFollowUpForm.value).subscribe(res => {
        // this.ListServices();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        this.onaddFollowUp(this.StudentId, 0, 0, 0, 0);
        // close your modal
        // this.closeModal();
      });
    }
  }

  ngOnInit(): void {
    this.ListClassCourse();
    this.generateAttributes();

    this.institution = JSON.parse(localStorage.getItem('Institution'));
    const self = this;
    this.statusDrp = 'All';
    this.batchDrp = 'All';
    this.classDrp = 'All';
    $('#dateFroms').val(this.formatDate(new Date, 'dd-MM-yyyy'));
    this.fromDate = this.formatDate(new Date, 'dd-MM-yyyy');
    $('#dateTos').val(this.formatDate(new Date, 'dd-MM-yyyy'));
    this.toDate = this.formatDate(new Date, 'dd-MM-yyyy');
    $('#dateFroms').datepicker().on('changeDate', (e) => {
      self.fromDate = e.date;
      this.OnDateFromChangeEvent();
    });
    $('#dateTos').datepicker().on('changeDate', (e) => {
      self.toDate = e.date;      
      this.OnDateToChangeEvent();
    });
    //this.getOutstandingDueReport();

    $('#commitmentdate').datepicker().on('changeDate', (e) => {
      this.studentDueFollowUpForm.get('commitmentdate').patchValue(this.formatDate(e.date, 'dd-MM-yyyy'));
      // console.log("ll");
    });

    this.studentDueFollowUpForm = this.fb.group({
      id: [0],
      studentId: [''],
      academicYearId: ['0'],
      installmentId: ['0'],
      isCallPicked: [true], // 02-Nov
      remarks: [''], // 02-Nov      
      commitmentdate: [''],

      isNotify: [''],
      isActive: [true]
    });
    var date = new Date(), year = date.getFullYear(), month = date.getMonth();
    $('#dateFroms').datepicker('update',this.formatDate(new Date(year,month,1), 'dd-MM-yyyy'));
    this.fromDate = this.formatDate(new Date(year,month,1), 'MM-dd-yyyy');
    $('#dateTos').datepicker('update',this.formatDate(new Date(year,month+1,0),'dd-MM-yyyy'));
    this.toDate = this.formatDate(new Date(year,month+1,0), 'MM-dd-yyyy');
    this.OnDateFromChangeEvent();
    this.OnDateToChangeEvent();
  }

  OnDateFromChangeEvent() {
    if (this.toDate != undefined && this.toDate != '' && this.toDate != null) {
       if (this.fromDate >= this.toDate) {
         this.dateValidation = false;
         this.toastr.warning('FromDate is less than ToDate', 'WARNING!', {
           timeOut: 3000
         });
       } else {
         this.dateValidation = true;
       }
     } else {
       this.dateValidation = false;
     }
  }

  OnDateToChangeEvent() {
      if (this.fromDate != undefined && this.fromDate != '' && this.fromDate != null) {
        if (this.toDate >= this.fromDate) {
          this.dateValidation = true;
        } else {
          this.dateValidation = false;
          this.toastr.warning('Todate is greater than From date', 'WARNING!', {
            timeOut: 3000
          });
        }
      } else {
        this.dateValidation = false;
      }
  }

  CSSApllyinstalmentTable(installment: any) {
    if (installment.isWaved == true) {
      return "bg-danger text-white"
    }
  }

  // $('#commitmentdate').datepicker().on('changeDate', (e) => {
  //   this.enquiryForm.get('commitmentdate').patchValue(this.formatDate(e.date));
  // });

  //  formatDate(date) {
  //   const d = new Date(date);
  //   let month = '' + (d.getMonth() + 1);
  //   let day = '' + d.getDate();
  //   const year = d.getFullYear();
  //   if (month.length < 2) { month = '0' + month; }
  //   if (day.length < 2) { day = '0' + day; }
  //   return [day, month, year].join('-');
  // }


  // $('#commitmentdate').datepicker().on('changeDate', (e) => {
  //   this.studentDueFollowUpForm.get('commitmentdate').patchValue(this.formatDate(e.date));
  // });
}