import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AssignweightageicseService } from '../../services/assignweightageicse.service';
import { GradescalegroupService } from '../../services/gradescalegroup.service';
import { IcseweightageService } from '../../services/icseweightage.service';
import { Classes } from '../models/classes.model';
import { IcseAssignWeightage } from '../models/icse-assign-weightage.model';
import { IcseWeightage } from '../models/icse-weightage.model';

@Component({
  selector: 'app-assign-weightages-icse',
  templateUrl: './assign-weightages-icse.component.html',
  styleUrls: ['./assign-weightages-icse.component.css']
})
export class AssignWeightagesICSEComponent implements OnInit {

  icseAssignWeightageForm:UntypedFormGroup;
  icseAssignWeightage:IcseAssignWeightage;
  allAssignWeightage:any[]=[];
  allAssignWeightageFilteredList: any[] = [];
  assignWeightagesCount:number=0;
  classes: Classes[]=[];
  icseWeightages:IcseWeightage[]=[];
  
  selectedWeightage:number;
  editSelected:string="0";

  constructor(private toastr:ToastrService,public fb: UntypedFormBuilder,
    public service: AssignweightageicseService,
    public icseService: IcseweightageService,
    private gradeService: GradescalegroupService) { }

  get f() { return this.icseAssignWeightageForm.controls; }

  ngOnInit(): void {
    
    this.loadWeigtage();
    this.loadClass();
    this.loadAssignWeightageICSE();
    
  }

  selectWeightageICSE(event,id?){    
   this.selectedWeightage=event.target.value==undefined?id:event.target.value;  
   this.editSelected=id;

   this.service.getAssignICSEWeightage().subscribe(((data:any)=>{    
    data.forEach(element=>{
      this.classes.find(obj=>obj.id==element["classId"]).isChecked= true;
      if(this.selectedWeightage!=element["weightageId"])
           this.classes.find(obj=>obj.id==element["classId"]).isActive=false;
      else 
           this.classes.find(obj=>obj.id==element["classId"]).isActive=true;      
    })
   }));   
  }

  editSelectedWeightageICSE(id){
   
  }
  

  assignWeightageICSE(classId,weightageId){
    if(weightageId>=1){   
    var data=new IcseAssignWeightage(0,weightageId,'',classId,'');
    this.service.assignWeightageICSE(data).subscribe(res=>{
      this.loadAssignWeightageICSE();
      this.toastr.success("ICSE Weightage assign successfully","SUCCESS!",{timeOut:3000});
    })
  }
  else{
    this.toastr.warning("Please select Weightage","WARNING!",{timeOut:3000});
  }
  }

  loadAssignWeightageICSE(){
    this.service.loadAssignWeightageICSE().subscribe((data:any)=>{
      //console.log(data);
      this.allAssignWeightageFilteredList= this.allAssignWeightage=data;
      this.assignWeightagesCount=this.allAssignWeightageFilteredList.length;
    })
  }

  loadWeigtage(){
    this.icseService.getAllIcseWeightage().subscribe((data:IcseWeightage[])=>{
      this.icseWeightages=data;  
          
    })
  }

  loadClass(){
    this.gradeService.getAllClass().subscribe((data:Classes[])=>{      
      this.classes=data;
      // data.forEach(element=>{
      //   this.classes.find(obj=>obj.id==element["classId"]).isActive=true;
      // })
    })
  }


  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.allAssignWeightageFilteredList = this.allAssignWeightage;
      this.assignWeightagesCount= this.allAssignWeightageFilteredList.length;
    } else {
    this.allAssignWeightageFilteredList = this.allAssignWeightage
                                .filter((allAssignWeightage: any) => allAssignWeightage.assignWeightage.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
    this.assignWeightagesCount=this.allAssignWeightageFilteredList.length;
    }
  }

}
