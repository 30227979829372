import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Student } from './../../studentmanagement/student.model';
import { StudentService } from './../../studentmanagement/student.service';
import { InstallmentService } from '../../feemanagement/installment/installment.service';
import { Installment } from '../../feemanagement/installment/installment.model';
import { SettingsService } from '../../mastersettings/settings.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
declare var $: any;
@Component({
  selector: 'app-makestudentinactive',
  templateUrl: './makestudentinactive.component.html',
  styleUrls: ['./makestudentinactive.component.css']
})
export class MakestudentinactiveComponent implements OnInit {

  constructor(
    // public feeCollectionService: FeeCollectionService,
    public installmentService: InstallmentService,
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public settingService: SettingsService,
    // public feewaveService: FeeWaveoffService,
    public batchService: BatchService,
    private toastr: ToastrService
  ) { }
  isLoading:boolean=false;
  tblStudentDetails:boolean=true;
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  lookupAdmissions: Student[] = [];
  lAdmissions: Student[] = [];
  studentDetail: Student = {} as Student;
  installments: Installment[] = [];
  classId = '';
  batchId = '';
  admissionno = '';
  admissionId = '';
  inactiveDate = '';
  inactiveReason = '';
  remarks = '';
  installmentIds = '';
  waveOffDate = '';
  lookupMobileNo = '';
  lookupStudentName = '';
  lookupFatherName = '';
  studDetails = false;
  currentAcademicYearId: number;

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  ListStudents() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      console.log(data);
      this.lAdmissions = data.filter((student: Student) => {
        return student.academicYearId.toString() === this.currentAcademicYearId.toString() &&
        student.status.toString() === 'true';
        });
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }

  currentAcademicYear() {
    this.settingService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.ListStudents();
      // this.search();
    });
  }

  onClassSelected(value: string) {
    this.tblStudentDetails=true;
    this.batches = [];
    this.admissions = [];
    this.studentDetail = {} as Student;
    // this.resetEForm();
    this.classId = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
      console.log(this.batches);
    });
  }


  ActiveStudent() {
    if (this.admissionId === '' ) {
      this.toastr.warning('Please select student ', 'WARNING!!!', {
        timeOut: 3000
      });
      return;
    }

    if (this.waveOffDate === '' ) {
      this.toastr.warning('Please select inactive date ', 'WARNING!!!', {
        timeOut: 3000
      });
      return;
    }

    if (this.inactiveReason === '' ) {
      this.toastr.warning('Please enter reason ', 'WARNING!!!', {
        timeOut: 3000
      });
      return;
    }

    const inactiveDetail = { admissionId: this.admissionId, inactiveDate: this.waveOffDate, inactiveReason: this.inactiveReason  };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Make inactive!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.studentService.makeStudentInactive(inactiveDetail).subscribe((data) => {
          this.toastr.success('Student marked as inactive', 'SUCCESS!', {
            timeOut: 3000
          });
          this.batches = [];
          this.admissions = [];
          this.studentDetail = {} as Student;
          this.admissionId = '0';
          this.inactiveReason = '';
          this.classId = '';
          this.installments = [];
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
  onBatchChanged(value: string) {
    this.tblStudentDetails=true;
    this.admissions = [];
    this.studentDetail = {} as Student;
    // this.resetEForm();
    this.studentService.getAll().subscribe((data: Student[]) => {
     // this.ListFeeHead();
      this.admissions = data.filter((student: Student) => {
      return student.academicYearId.toString() === this.currentAcademicYearId.toString() &&
        student.classCourseId.toString() === this.classId &&
        student.batchId.toString() === value.toString();
      });
    });
  }
  onStudentChanged(id: number) {
    this.isLoading=true;
    this.tblStudentDetails=false;
    console.log(id);
    this.admissionId = id.toString();
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.studentDetail = data.filter((student: Student) => {
        return student.id === id;
      })[0];
      this.studentDetail.admissionDate = this.formatDate(this.studentDetail.admissionDate);
      this.studDetails = true;
      this.installmentService.getFeePayment(this.studentDetail.id).subscribe((inst: Installment[]) => {
        console.log(inst);
        this.installments = inst;
     });
      // this.feeRefundService.getPayment(this.studentDetail.id).subscribe((inst: FeeRefundInstallment[]) => {
      //   this.installments = inst;
      // });
      this.isLoading=false;
    });
    // this.studentService.getById(id).subscribe((data: Student) => {
    //     this.studentDetail = data;
    //     this.installmentService.getFeePayment(this.studentDetail.id).subscribe((inst: Installment[]) => {
    //       this.installments = inst;
    //    });
    //  });
  }

  searchLookupStudent() {
    console.log(this.lookupStudentName);
    if (this.lookupStudentName !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
         return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase());
      });
    }
    if (this.lookupFatherName !== '') {
       this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
          return data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase());
       });
     }
    if (this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
         return data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase());
      });
    }

    if (this.lookupStudentName !== '' && this.lookupFatherName !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
         return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase()) &&
                data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase());
      });
    }
    if (this.lookupStudentName !== '' && this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
         return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase()) &&
              data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase());
      });
    }
    if (this.lookupFatherName !== '' && this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
         return data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase()) &&
              data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase());
      });
    }
    if (this.lookupStudentName !== '' && this.lookupFatherName !== '' && this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
         return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase()) &&
              data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase()) &&
              data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase()) ;
      });
    }
  }

  resetLookUpStudent() {
    this.lookupAdmissions = [];
    this.lookupStudentName = '';
    this.lookupMobileNo = '';
    this.lookupFatherName = '';
  }

  openLookUp() {
    this.resetLookUpStudent();
  }

  ngOnInit(): void {
    this.currentAcademicYear();
    const self = this;
    this.ListClassCourse();
    $('.select2').select2();
    $('.select2').on('change', function() {
      self.onStudentChanged(Number($(this).val()));
      self.admissionId = $(this).val();
     });
    $('#inactiveDate').val(this.formatDate(new Date()));
    this.waveOffDate = this.formatDate(new Date());
    $('#inactiveDate').datepicker().on('changeDate', (e) => {
      self.waveOffDate =  this.formatDate(e.date);
    });
  }

}
