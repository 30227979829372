import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AssignSpecialFee, FeeCollectionInstallment } from './assignspecialfee.model';

@Injectable({
    providedIn: 'root'
  })
  export class AssignSpecialFeeService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         enctype: 'multipart/form-data'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<AssignSpecialFee[]> {
      return this.httpClient.get<AssignSpecialFee[]>(environment.apiUrl + '/assignspecialfee/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<AssignSpecialFee> {
      return this.httpClient.get<AssignSpecialFee>(environment.apiUrl + '/assignspecialfee/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getFeeCollectionInstallmentDetails(admissionId): Observable<FeeCollectionInstallment[]> {
      return this.httpClient.get<FeeCollectionInstallment[]>(environment.apiUrl + '/FeeCollection/GetFeeCollectionInstallmentDetails/' + admissionId)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    create(assignspecialfee): Observable<any> {
      return this.httpClient.post(environment.apiUrl + '/assignspecialfee/', assignspecialfee)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    update(id, assignspecialfee): Observable<AssignSpecialFee> {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.put<AssignSpecialFee>(environment.apiUrl + '/assignspecialfee/' + id, JSON.stringify(assignspecialfee), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    delete(id) {
      return this.httpClient.delete<AssignSpecialFee>(environment.apiUrl + '/assignspecialfee/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    deleteList(fees:number[]) {
      /*const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: {
          feeHeads: JSON.stringify(fees)
        },
      };*/
      return this.httpClient.post<AssignSpecialFee[]>(environment.apiUrl + '/assignspecialfee/DeleteAssignedSpecialFeeList/',fees)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }

  }
