import { Pipe, PipeTransform } from '@angular/core';
import { SessionPlannerVM } from '../session-planner/model/SessionPlannerVM';

@Pipe({
  name: 'classSummaryFilter'
})
export class ClassSummaryFilterPipe implements PipeTransform {

  transform(lstSessionPlannerVM: SessionPlannerVM[],classId,batchId,typeId,courseId,tutorId): SessionPlannerVM[] {
    if(!lstSessionPlannerVM)
    {
      return lstSessionPlannerVM;
    }
    if (classId!=0)  { lstSessionPlannerVM= lstSessionPlannerVM.filter(s=>s.classId==classId);}
    if (batchId!=0)  { lstSessionPlannerVM= lstSessionPlannerVM.filter(s=>s.batchId==batchId);}
    if (typeId!=0)  { lstSessionPlannerVM= lstSessionPlannerVM.filter(s=>s.sessionType==typeId);}
    if (courseId!=0)  { lstSessionPlannerVM= lstSessionPlannerVM.filter(s=>s.coursePlannerId==courseId);}
    if (tutorId!=0)  { lstSessionPlannerVM= lstSessionPlannerVM.filter(s=>s.tutorId==tutorId);}
    return lstSessionPlannerVM;
  }

}
