import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LeadCreationModel} from './leadcreation.model';

@Injectable({
  providedIn: 'root'
})
export class LeadCreationService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<LeadCreationModel[]> {
    ;
    return this.httpClient.get<LeadCreationModel[]>(environment.apiUrl + '/LeadManagement/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllService(providerId): Observable<LeadCreationModel[]> {
    ;
    return this.httpClient.get<LeadCreationModel[]>(environment.apiUrl + '/LeadManagement/GetAllService')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<LeadCreationModel> {
    return this.httpClient.get<LeadCreationModel>(environment.apiUrl + '/LeadManagement/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getService(): Observable<LeadCreationModel[]> {
    ;
    return this.httpClient.get<LeadCreationModel[]>(environment.apiUrl + '/LeadManagement/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(apiConfiguration): Observable<LeadCreationModel> {
  ;
  return this.httpClient.post<LeadCreationModel>(environment.apiUrl + '/LeadManagement/', JSON.stringify(apiConfiguration), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id,apiConfiguration): Observable<LeadCreationModel> {
    ;
    return this.httpClient.put<LeadCreationModel>(environment.apiUrl + '/LeadManagement/' + id, JSON.stringify(apiConfiguration), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<LeadCreationModel>(environment.apiUrl + '/LeadManagement/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  saveServices(providerService)
  {
    ;
    return this.httpClient.post<LeadCreationModel>(environment.apiUrl + '/LeadManagement/SaveService', JSON.stringify(providerService), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );

  }
  updateService(serviceId,providerService): Observable<LeadCreationModel> {
    ;
    return this.httpClient.put<LeadCreationModel>(environment.apiUrl + '/LeadManagement/UpdateService' + serviceId, JSON.stringify(providerService), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  deleteService(serviceId) {
    return this.httpClient.delete<LeadCreationModel>(environment.apiUrl + '/LeadManagement/DeleteService' + serviceId)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  /*List Api provider service */
  GetApiConfigProviderServices(providerId): Observable<LeadCreationModel> {
    return this.httpClient.get<LeadCreationModel>(environment.apiUrl + '/LeadManagement/_IapiConfigProviderserviceService' + parseInt(providerId))
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
