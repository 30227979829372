import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { from } from 'rxjs';
export function formatDate(date) {
    const d = new Date(date);
    if(isNaN(d.getTime())){
      return date;
    }
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }
export function formatDateByMoment(date: any, format: string = 'YYYY-MM-DD'): string {
    return moment(date).format(format);
} 
export function validateDate(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {

    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
    }

    // set error on matchingControl if validation fails
    if (Date.parse(control.value) > Date.parse(matchingControl.value)) {
        matchingControl.setErrors({ mustMatch: true });
    } else {
        matchingControl.setErrors(null);
    }
  };
}



export function pdfStyle(){
  return  {
    sectionHeader: {
      // bold: true,
      // decoration: 'underline',
      fontSize: 10,
      margin: [15, 15, 15, 15]
    },
    h1: {
      margin: [0, 5, 0, 0],
      fontSize: 14,
      bold: true
    },
    h2: {
        margin: [0, 5, 0, 0],
        fontSize: 9,
        bold: true
    }
  }

}