import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
userConnected:any;
  constructor(private auth:AuthenticationService) { }

  ngOnInit(): void {
    this.userConnected=localStorage.getItem('user_'+this.auth.institutionCode);
  }

}
