import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Circular } from '../circularmanager/circular.model';
import { Service } from '../creditmanager/service.model';
import { Template } from '../configuration/templatemanager/template.model';
import { Student } from '../../academic/studentmanagement/student.model';
import { SenderIDRequest } from '../configuration/sender-idrequest/senderid.model';
import { Batch, Broadcast, Credit, Gateway, ServiceAvailabilityStatus, GetAvailableCreditByService } from './broadcast.model';
import { Department } from '../../OperationActivity/hrmspayroll/master/models/departement.model';
import { Employeeadmission } from '../../OperationActivity/hrmspayroll/hrms/models/employeeadmission.model';


@Injectable({
    providedIn: 'root'
  })
  export class BroadcastService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         enctype: 'multipart/form-data',
         broadcast: 'true'
      })
    };

    httpNOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         reqfrom: 'sendmessage',
         broadcast: 'true'
      })
    };

    constructor(private httpClient: HttpClient) { }
    create(broadcast): Observable<Broadcast> {
      
      return this.httpClient.post<Broadcast>(environment.apiUrl + '/broadcast/CreateBroadCast', JSON.stringify(broadcast), this.httpNOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    sendMessage(broadcast): Observable<any> {
      
      return this.httpClient.post(environment.apiUrl + '/broadcast/SendMessage/', JSON.stringify(broadcast), this.httpNOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    Send360Message(broadcast): Observable<any> {
      
      return this.httpClient.post(environment.apiUrl + '/broadcast/Send360Message/', broadcast, this.httpNOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    

    getAllStudents(): Observable<Student[]> {
      return this.httpClient.get<Student[]>(environment.apiUrl + '/StudentAdmission/GetStudents')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getAllGateways(): Observable<Gateway[]> {
      return this.httpClient.get<Gateway[]>(environment.apiUrl + '/ApiConfiguration/')
      .pipe(
        catchError(this.errorHandler)
      );                            
    }

    getAllGatewayByServiceId(id): Observable<Gateway[]> {
      return this.httpClient.get<Gateway[]>(environment.apiUrl + '/ApiConfiguration/GetProvidersByServiceId/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getSmsConsumptionByService(id): Observable<GetAvailableCreditByService> {
      const response= this.httpClient.get<GetAvailableCreditByService>(environment.apiUrl + '/Credit/GetSmsConsumptionByServiceId/' + id);
      return response.pipe(
        catchError(this.errorHandler)
      );;
    }
    getAllServiceAvailabilityStatus(): Observable<ServiceAvailabilityStatus[]> {
      return this.httpClient.get<ServiceAvailabilityStatus[]>(environment.apiUrl + '/credit/GetAllServiceAvailabilityStatus/')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getAllCirculars(): Observable<Circular[]> {
      return this.httpClient.get<Circular[]>(environment.apiUrl + '/circular/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getAllServices(): Observable<Service[]> {
        return this.httpClient.get<Service[]>(environment.apiUrl + '/service/')
            .pipe(
                catchError(this.errorHandler)
            );
    }

    getAllSenderIds(serviceId:any, institutionCode:any): Observable<SenderIDRequest[]> {
      return this.httpClient.get<SenderIDRequest[]>(environment.apiUrl + '/senderidrequest/GetAllSenderIds?serviceId='+serviceId+'&institutionCode='+institutionCode)
          .pipe(
              catchError(this.errorHandler)
          );
    }

    getAllCredits(): Observable<Credit[]> {
        return this.httpClient.get<Credit[]>(environment.apiUrl + '/credit/')
            .pipe(
                catchError(this.errorHandler)
            );
    }
    getAllTemplates(): Observable<Template[]> {
      return this.httpClient.get<Template[]>(environment.apiUrl + '/template/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getAllBatchDetails(): Observable<Batch[]> {
      return this.httpClient.get<Batch[]>(environment.apiUrl + '/batch/')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getAllEmployees(): Observable<Employeeadmission[]> {
      
      return this.httpClient.get<Employeeadmission[]>(environment.apiUrl + '/employeeadmission/GetEmployees')      
      //return this.httpClient.get<Employeeadmission[]>(environment.apiUrl + '/Employee/GetAllEmnployee')      
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getAllDepartments(): Observable<Department[]> {
      return this.httpClient.get<Department[]>(environment.apiUrl + '/departments/')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }

  }
