import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventcalendarComponent } from './eventcalendar/eventcalendar.component';
import { EventreportComponent } from './eventreport/eventreport.component';
const routes: Routes = [
  {path: 'event/eventcalendar', component: EventcalendarComponent},
  {path: 'event/report', component: EventreportComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventCalendarRoutingModule { }
