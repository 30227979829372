import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { WelcomeNotification} from './welcomenotification.model';

@Injectable({
  providedIn: 'root'
})
export class WelcomeNotificationService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<WelcomeNotification[]> {
    return this.httpClient.get<WelcomeNotification[]>(environment.apiUrl + '/WelcomeNotification/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<WelcomeNotification> {
    return this.httpClient.get<WelcomeNotification>(environment.apiUrl + '/WelcomeNotification/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(welcomenotification): Observable<WelcomeNotification> {
  return this.httpClient.post<WelcomeNotification>(environment.apiUrl + '/WelcomeNotification/',
   JSON.stringify(welcomenotification), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, welcomenotification): Observable<WelcomeNotification> {
    return this.httpClient.put<WelcomeNotification>(environment.apiUrl + '/WelcomeNotification/' + id,
     JSON.stringify(welcomenotification), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<WelcomeNotification>(environment.apiUrl + '/WelcomeNotification/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
