import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddonserviceComponent } from './addonservice/addonservice.component';
import { ApiconfigurationComponent } from './apiconfiguration/apiconfiguration.component';
import { UsermanageComponent } from './usermanage/usermanage.component';
import { SubscriptionmanagerComponent } from './subscriptionmanager/subscriptionmanager.component';
import { LoginpageconfigComponent } from './loginpageconfig/loginpageconfig.component';
import { SenderidapprovalComponent } from './senderidapproval/senderidapproval.component';
import { WelcomenotificationComponent } from './welcomenotification/welcomenotification.component';
import { InstitutionstatisticsComponent } from './institutionstatistics/institutionstatistics.component';
import { PaymentgatewayComponent } from './paymentgateway/paymentgateway.component';
import { ModulemanagementComponent } from './modulemanagement/modulemanagement.component';

const routes: Routes = [
  {path: 'addonservice', component: AddonserviceComponent},
  {path: 'apiconfiguration', component: ApiconfigurationComponent},
  {path: 'usermanage', component: UsermanageComponent},
  {path: 'statistics', component: InstitutionstatisticsComponent},
  {path: 'subscription', component: SubscriptionmanagerComponent},
  {path: 'loginconfig', component: LoginpageconfigComponent},
  {path: 'paymentconfig', component: PaymentgatewayComponent},
  {path: 'senderidapprove', component: SenderidapprovalComponent},
  {path: 'welcomenotification', component: WelcomenotificationComponent},
  {path: 'modulemanagement', component: ModulemanagementComponent},
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfigurationRoutingModule { }
