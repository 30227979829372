// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thead-light {
    color: #495057;
    background-color: #f8f9fa;
    border-color: #eff2f7;
}
.myOddClass  {   background-color: #1265;} 
.myEvenClass {   background-color: #ffffff; }`, "",{"version":3,"sources":["webpack://./src/app/edumaq/configuration/modulemanagement/modulemanagement.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,yBAAyB;IACzB,qBAAqB;AACzB;AACA,iBAAiB,uBAAuB,CAAC;AACzC,iBAAiB,yBAAyB,EAAE","sourcesContent":[".thead-light {\r\n    color: #495057;\r\n    background-color: #f8f9fa;\r\n    border-color: #eff2f7;\r\n}\r\n.myOddClass  {   background-color: #1265;} \r\n.myEvenClass {   background-color: #ffffff; }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
