import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExamWiseAttendenceRoutingModule } from './exam-wise-attendence-routing.module';
import { ExamWiseAttendenceComponent } from './exam-wise-attendence/exam-wise-attendence.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [ExamWiseAttendenceComponent],
  imports: [
    CommonModule,
    ExamWiseAttendenceRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [RouterModule]
})
export class ExamWiseAttendenceModule { }
