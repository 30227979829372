import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EdumaqComponent } from './edumaq/edumaq.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RandomGuard } from '../authentication/guards/random.guard';

const routes: Routes = [
  {
    path: ':edumaq/edumaq',
    component: EdumaqComponent,
    canActivate: [RandomGuard],
    data: {
      role: 'Edumaq',
    },
    canLoad: [RandomGuard],
    children: [
        {path: '', component: DashboardComponent},
        {path: 'dashboard', component: DashboardComponent},
        {path: 'configuration', loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule)},
        {path: 'institution', loadChildren: () => import('./institutionmanagement/institutionmanagement.module')
        .then(m => m.InstitutionManagementModule)},
        {path: 'support', loadChildren: () => import('./support/support.module').then(m => m.SupportModule)},
        {path: 'leadmanage', loadChildren: () => import('./leadmanage/leadmanage.module').then(m => m.LeadmanageModule)},
        {path: 'billing', loadChildren: () => import('./billingmanagement/billing.module').then(m => m.BillingModule)},
        { path: "**",redirectTo:"edumaq/edumaq"}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class EdumaqRoutingModule { }





