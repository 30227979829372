import { Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CoursePlannerService } from '../course-planner/course-planner.service';
import { BatchVM } from '../course-planner/model/Batch';
import { ClassVM } from '../course-planner/model/ClassVM';
import { CoursePlannerVM } from '../course-planner/model/CoursePlannerVM';
import { Language } from '../course-planner/model/Language';
import { SubjectVM } from '../course-planner/model/Subject';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DatePipe } from "@angular/common";
import { SessionPlannerService } from '../session-planner.service';
import { ChapterTopicPlanner } from '../course-chapter-planner/model/ChapterTopicPlanner';
import { Employees } from '../course-chapter-planner/model/Employees';
import * as moment from 'moment';
import { SessionPlannerVM } from './model/SessionPlannerVM';
import { SessionPlannerContentVm } from './model/SessionPlannerContentVm';
import { AssesmentVm, QuizVm } from './model/AssesmentVm';
import { StudentSessionList, StudentVm } from './model/StudentVm';
import { Exams } from './model/Exams';
import { ExamsCategory } from './model/ExamsCategory';
import { SessionType } from 'src/app/Enums/EnumsType';
import { SessionPlannerDiscussionVm } from './model/SessionPlannerDiscussionVm';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { StreamingAPI } from './model/StreamingAPI';
import { SessionPlannerStudent } from './model/SessionPlannerStudent';
import { SessionApproval } from './model/SessionApproval.model';
declare var $: any;


@Component({
  selector: 'app-session-planner',
  templateUrl: './session-planner.component.html',
  styleUrls: ['./session-planner.component.css']
})
export class SessionPlannerComponent implements OnInit {

  public lstCourseList: CoursePlannerVM[] = new Array<CoursePlannerVM>();
  public lstChapterTopicPlannerList: ChapterTopicPlanner[] = new Array<ChapterTopicPlanner>();
  public lstSessionPlannerVMList: SessionPlannerVM[] = new Array<SessionPlannerVM>();
public templstSesion: SessionPlannerVM[] = new Array<SessionPlannerVM>();
  public lstClass: ClassVM[] = new Array<ClassVM>();
  public lstlanguage: Language[] = new Array<Language>();
  public lstBatch: BatchVM[] = new Array<BatchVM>();
  public lstemployee: Employees[] = new Array<Employees>();

  public lstAssesmentsType: AssesmentVm[] = new Array<AssesmentVm>();
  public lstAssesmentsTtile: AssesmentVm[] = new Array<AssesmentVm>();
  public lstStudent: StudentVm[] = [];
  public lstSessionStudent: StudentVm[] = [];
  public lstExams: Exams[] = new Array<Exams>();
  public lstExamCategory: ExamsCategory[] = new Array<ExamsCategory>();

  public lstStramingAPI: StreamingAPI[] = new Array<StreamingAPI>();

  minDate = {year: new Date().getFullYear(), month: new Date().getMonth()+1, day: new Date().getDate()};




  public lstSubject: SubjectVM[] = new Array<SubjectVM>();
  public modelVM: CoursePlannerVM = new CoursePlannerVM();
  submitted = false;
  formData = new FormData();
  ImageData;
  Id= 0;
  SessionLeftMenu= false;
  sessionDate: string;
  headline: string;
  className: string;
  courseTopicName: string;
  sellectedSession: number;
  public SessionTypeEnum = SessionType;
studentchecked= false;
 totalStudent: Number;
 selectedStudent: Number;
 tempbatchId:Number;
 selectedSessionStudent:Number;
 SelectStudentitem: Array<number>;
_SessionType: number;
 ProviderType:any;
 actionName="Create Session";
 HighlightRow : Number;
  ClickedRow:any;

@ViewChild('classSelect') classSelect:ElementRef;
@ViewChild('batchSelect') batchSelect:ElementRef;
@ViewChild('courseSelect') courseSelect:ElementRef;
isExternalLink=false;
isUploadedFile=false;

  constructor(private sessionPlannerService: SessionPlannerService, private toastr: ToastrService,
         private formBuilder: UntypedFormBuilder) {

          { 
            this.ClickedRow = function(index){
              this.HighlightRow = index;
          }
        }
        }

  
  sessionPlannerForm: UntypedFormGroup;
  sessionPlannerContentForm: UntypedFormGroup;
  sessionPlannerAssesmentForm: UntypedFormGroup;
  sessionPlannerStudentForm: UntypedFormGroup;
  sessionPlannerQuizForm: UntypedFormGroup;
  sessionPlannerDiscussionForm: UntypedFormGroup;
  sessionPlannerApprovalForm: UntypedFormGroup;
  Thumblin: File;
  filePath: string;
  // minDate: any;
  model: NgbDateStruct;
selectedclortable: boolean;
 
  
  ngOnInit(): void {

   
    const datePipe = new DatePipe('en-Us');
    this.sessionDate = datePipe.transform(new Date(), 'dd-MM-yyyy');
    //test
//this.GetAllStudentYear();
// end of test
    $('#sessionDate').datepicker().on('changeDate', (e) => {

      
      this.sessionPlannerForm.get('sessionDate').patchValue(e.date);
    });


    this.sessionPlannerForm = this.formBuilder.group({
      id: 0,
      classId: [0, Validators.required],
      topicPlannerId: [0, Validators.required],
      //batchId: [-1, Validators.min(1)],
      batchId: [-1],
      batchId1: [-1],
      coursePlannerId: [0, Validators.required],
      employeeId: [-1, Validators.min(1)],
      sessiontype: [-1, Validators.min(1)],
      time: [, Validators.required],
      duration: [, Validators.required],
      sessionDate: ['', Validators.required],
     });

     
    this.sessionPlannerContentForm = this.formBuilder.group({
      id: 0,
      sessionType: [0, Validators.required],
      streamingProviderType: [0, Validators.required],
      meetingLink: [''],
      externalLink: [''],
      uploadedLink: [''],
      contentDescription: [''],
      sessionPlannerId: [0, Validators.required]
    });


    this.sessionPlannerAssesmentForm = this.formBuilder.group({
      id: 0,
      assignmentTypeId: [0, Validators.required],
      assignmentTitleId: [0, Validators.required],
      sessionPlannnerId: 0,
    });

    this.sessionPlannerDiscussionForm = this.formBuilder.group({
      id: 0,
      discussionTitle: ["", Validators.required],
      shareWith: [""],
      sessionPlannerId: 0,
      radioAllStudent:['all'],
    });

    this.sessionPlannerQuizForm = this.formBuilder.group({
      id: 0,
      quizTypeId: [0, Validators.required],
      quizNameId: [0, Validators.required],
      sessionPlannnerId: 0,
    });

    this.sessionPlannerStudentForm = this.formBuilder.group({
      id: 0,
      studentId: [0],
      batchId: [0, Validators.required],
      branchId:[0],
      sessionPlannerId: 0,
    });   
    this.sessionPlannerApprovalForm = this.formBuilder.group({
      id:0,
      approvalId: 0,
      description: [''],
      sessionPlannnerId: 0    
      });

    
     
      this.GetAllSubject();
      this.GetAllEmployee();
      this.getAllClass();
      this.getAlllanguage();

  }

  public getAllClass() {
    this.sessionPlannerService.GetClass().subscribe((response) => {
      this.lstClass = response;
    });
  }

  public getAlllanguage() {
    this.sessionPlannerService.GetLanguage().subscribe((response) => {
      this.lstlanguage = response;
    });
  }

  public GetAllSubject() {
    this.sessionPlannerService.GetAllSubject().subscribe((response) => {
      this.lstSubject = response;
    });
  }

  public GetAllEmployee() {
    this.sessionPlannerService.GetAllEmployee().subscribe((response) => {
      this.lstemployee = response;
    });
  }

  public GetAllExams() {
    this.sessionPlannerService.GetAllExams().subscribe((response) => {
      this.lstExams = response;
    });
  }

  public GetAllExamsCategory() {
    this.sessionPlannerService.GetAllExamCategory().subscribe((response) => {
      this.lstExamCategory = response;
    });
  }

  get f() { return this.sessionPlannerForm.controls; }
  

 

  public getClassId(){
    
    var ClassID = this.sessionPlannerForm.get('classId').value;
    if(ClassID>0){
     //this.resetForm();
     this.lstChapterTopicPlannerList = [];
     this.lstCourseList=[];
      this.sessionPlannerForm.get('coursePlannerId').setValue(0);
      this.sessionPlannerForm.get('batchId1').setValue(-1);
      this.headline = this.lstClass.find(x=>x.id == ClassID).classCourseName;

      this.className = this.headline;
      this.getAllBatchByClass(ClassID);
      this.getAllCoursePlanByClass(ClassID);

    }

  }
  public getAllBatchByClass(k) {
    this.sessionPlannerService.getAllBatchByClass(k).subscribe((response) => {
      this.lstBatch = response;


    });
  }

  public getAllCoursePlanByClass(k) {
    this.sessionPlannerService.getAllCoursePlanByClass(k).subscribe((response) => {
      this.lstCourseList = response;
    });
  }

  public getAllAssesmentType(Id) {
    this.sessionPlannerService.getAllAssesmenttypeByClassId(Id).subscribe((response) => {
      this.lstAssesmentsType = response;
      this.lstAssesmentsTtile = response;

    });
  }

  public getAllStudentType(Id) {
    this.sessionPlannerService.getAllAssesmenttypeByClassId(Id).subscribe((response) => {
      this.lstAssesmentsType = response;
      this.lstAssesmentsTtile = response;

    });
  }
   

  public selectSubjects(){
    var BatchID = this.sessionPlannerForm.get('batchId').value;
    var ClassID = this.sessionPlannerForm.get('classId').value;
    var SubjectId = this.sessionPlannerForm.get('subjectId').value;

    if(ClassID>0){
      this.getAllCoursePlanByClassBatch(ClassID,BatchID);
    }
  }

  public getAllCoursePlanByClassBatch(k,subjectId) {
    this.sessionPlannerService.getAllCoursePlanByClassBatch(k,subjectId).subscribe((response) => {
      
      this.lstCourseList = response;
    });
  }

  public CheckedStudent(student, i){
    console.log("CheckedStudent");
    this.lstStudent[i].isChecked =  !this.lstStudent[i].isChecked;
    this.selectedStudent = this.lstStudent.filter(x=>x.isChecked == true).length;
  }
  public CheckedSeesionStudent(student, i){
   console.log("CheckedSeesionStudent");
    this.lstSessionStudent[i].isChecked =  !this.lstSessionStudent[i].isChecked;
    this.selectedSessionStudent = this.lstSessionStudent.filter(x=>x.isChecked == true).length;
  }

  public CheckedAllStudent(){
    
    //  this.studentchecked = !this.studentchecked;
     this.selectedStudent = this.lstStudent.length;

     this.lstStudent.forEach(element => {
      element.isChecked =  true;
    });



  }
  public CheckedAllSessionStudent(){
    
    //  this.studentchecked = !this.studentchecked;
     this.selectedSessionStudent = this.lstSessionStudent.length;

     this.lstSessionStudent.forEach(element => {
      element.isChecked =  true;
    });



  }

  public CustomCheckedAllStudent(){
    
    //  this.studentchecked = !this.studentchecked;
   //  this.selectedStudent = this.lstStudent.length;

   this.lstStudent.forEach(element => {
     element.isChecked =  !element.isChecked;
   });


  }




  public saveSessionPlanner() {

    
    this.submitted = true;

    //this.load.ngOnInit();

    var sDate = this.sessionPlannerForm.get('sessionDate').value;

  //  var day = moment(this.formatDate(this.sessionDate));
    // var date = new Date(this.formatDate(this.sessionDate));
    // this.sessionPlannerForm.get('sessionDate').patchValue(date);

    // this.sessionPlannerForm.value
    if (this.sessionPlannerForm.valid) {
      if(this.Id == 0){
        
        this.sessionPlannerForm.get('sessionDate').patchValue( sDate.year +"-"+ (sDate.month > 2 ? sDate.month : "0"+ sDate.month) +"-"+(sDate.day > 2 ? sDate.day : "0"+ sDate.day) );


        this.sessionPlannerService.SaveChapterTopic(this.sessionPlannerForm.value).subscribe((response) => {
          this.submitted = false;
       //   this.load.ngOnInit();
       //lstSessionPlannerVMList
          //this.getCoursePlannerID();
          //SessionPlannerVM
          this.getSessionPlannerByTopicID();
          //this.SessionLeftMenu=false;
          this.resetForm();
          this.toastr.success("Succesfully Added Course Plan", 'Success!',{timeOut: 1000});
        });
      }
      else{
       
        this.sessionPlannerForm.get('sessionDate').patchValue( sDate.year +"-"+ (sDate.month > 2 ? sDate.month : "0"+ sDate.month) +"-"+(sDate.day > 2 ? sDate.day : "0"+ sDate.day) );

        this.sessionPlannerService.UpdateCoursePlan(this.sessionPlannerForm.value).subscribe((response) => {
        
          this.getSessionPlannerByTopicID();
          this.toastr.success("Succesfully Updated Session Plan", 'Success!',{timeOut: 1000});
          //this.sessionPlannerForm.reset();
          this.resetForm();
        });
      }
    } 
  
  }

  public saveSessionPlannerAssesment() {

    this.submitted = true;

   // this.load.ngOnInit();
 
    this.sessionPlannerAssesmentForm.get('sessionPlannnerId').patchValue(this.sessionPlannerContentForm.get('sessionPlannerId').value);

    // this.sessionPlannerForm.value
    if (this.sessionPlannerAssesmentForm.valid) {
      if(this.sessionPlannerContentForm.get('id').value == 0){
        

        this.sessionPlannerService.SaveSessionPlannerAssement(this.sessionPlannerAssesmentForm.value).subscribe((response) => {          
       //   this.load.ngOnInit();          
          this.getSessionPlannerByTopicID();
          //this.resetForm();
          this.toastr.success("Succesfully Added SessionPlanner Assesment", 'Success!',{timeOut: 1000});
        });
      }
      else{
       // var _date = this.sessionPlannerForm.get('StartDate').value;
        // k.StartDate = new Date(_date);
        this.sessionPlannerService.UpdateCoursePlannerAssesment(this.sessionPlannerAssesmentForm.value).subscribe((response) => {
        

          this.toastr.success("Succesfully Updated SessionPlanner Assesment", 'Success!',{timeOut: 1000});
        });
      }
    } 
  
  }

  public saveSessionPlannerQuiz() {

    
    this.submitted = true;

   // this.load.ngOnInit();
 
    this.sessionPlannerQuizForm.get('sessionPlannnerId').patchValue(this.sessionPlannerContentForm.get('sessionPlannerId').value);

    // this.sessionPlannerForm.value
    if (this.sessionPlannerQuizForm.valid) {
      if(this.sessionPlannerQuizForm.get('id').value == 0){
        

        this.sessionPlannerService.SaveSessionPlannerQuiz(this.sessionPlannerQuizForm.value).subscribe((response) => {
          
       //   this.load.ngOnInit();
          
          this.getSessionPlannerByTopicID();
          //this.resetForm();
          this.toastr.success("Succesfully Added SessionPlanner Quiz", 'Success!',{timeOut: 1000});
        });
      }
      else{
       // var _date = this.sessionPlannerForm.get('StartDate').value;
        // k.StartDate = new Date(_date);
        this.sessionPlannerService.UpdateSessionPlannerQuiz(this.sessionPlannerQuizForm.value).subscribe((response) => {
        

          this.toastr.success("Succesfully Updated SessionPlanner Quiz", 'Success!',{timeOut: 1000});
        });
      }
    } 
  
  }

  public saveSessionPlannerStudent() {

    
    this.submitted = true;
    var sessionStudent:StudentSessionList[]=[];
    var select = this.lstSessionStudent.filter(x=> x.isChecked == true);
    select.forEach(element => {
      let obj = {
        studentId: element.id,
        sessionPlannerId: this.sessionPlannerContentForm.get('sessionPlannerId').value,
        batchId: element.batchId
       };
      sessionStudent.push(obj)
    });
    this.sessionPlannerService.SaveSessionPlannerStudent(sessionStudent,this.sessionPlannerContentForm.get('sessionPlannerId').value).subscribe((response) => {
    
         //this.getSessionPlannerByTopicID();
         this.toastr.success("Succesfully Added SessionPlanner Student", 'Success!',{timeOut: 1000});
     });
   // this.load.ngOnInit();
 
    //this.sessionPlannerStudentForm.get('sessionPlannerId').patchValue(this.sessionPlannerContentForm.get('sessionPlannerId').value);


    // this.sessionPlannerForm.value
    /*if (this.sessionPlannerStudentForm.valid) {
      var sessionStudent:StudentSessionList[]=[];
        var select = this.lstSessionStudent.filter(x=> x.isChecked == true);
        select.forEach(element => {
          let obj = {
            studentId: element.id,
            sessionPlannerId: this.sessionPlannerContentForm.get('sessionPlannerId').value,
            batchId: element.batchId
           };
          sessionStudent.push(obj)
        });
        this.sessionPlannerService.SaveSessionPlannerStudent(sessionStudent).subscribe((response) => {
        
             this.getSessionPlannerByTopicID();
             this.toastr.success("Succesfully Added SessionPlanner Student", 'Success!',{timeOut: 1000});
           });
      if(this.sessionPlannerStudentForm.get('id').value == 0){
        
        if(sessionStudent.length!=0){
          this.sessionPlannerService.SaveSessionPlannerStudent(sessionStudent).subscribe((response) => {
          
            //   this.load.ngOnInit();
               
               this.getSessionPlannerByTopicID();
               //this.resetForm();
               this.toastr.success("Succesfully Added SessionPlanner Student", 'Success!',{timeOut: 1000});
             });
        }

      }
      else{
       // if(sessionStudent.length!=0){
        this.sessionPlannerService.SaveSessionPlannerStudent(sessionStudent).subscribe((response) => {     
          this.toastr.success("Succesfully Updated SessionPlanner Student", 'Success!',{timeOut: 1000});
        });
      //}
      }
    } */
  
  }


  public saveSessionPlannerDiscussion() {
   
    this.submitted = true;

    // this.load.show();
 
    this.sessionPlannerDiscussionForm.get('sessionPlannerId').patchValue(this.sessionPlannerContentForm.get('sessionPlannerId').value);

    var Item= [];
    var select = this.lstStudent.filter(x=> x.isChecked == true);
    select.forEach(element => {
      
      Item.push(element.id);
    });
    this.sessionPlannerDiscussionForm.get('shareWith').patchValue(Item.toString());

    if (this.sessionPlannerDiscussionForm.valid) {
      if(this.sessionPlannerDiscussionForm.get('id').value == 0){
        
        this.sessionPlannerService.SaveSessionPlannerDiscussion(this.sessionPlannerDiscussionForm.value).subscribe((response) => {
          
        //  this.load.ngOnInit();
          
          //this.getSessionPlannerByTopicID();
          //this.resetForm();
          this.toastr.success("Succesfully Added SessionPlanner Discussion", 'Success!',{timeOut: 1000});
        });
      }
      else{
       // var _date = this.sessionPlannerForm.get('StartDate').value;
        // k.StartDate = new Date(_date);
        /*Item=[];
        var select = this.lstStudent.filter(x=> x.isChecked == true);
        select.forEach(element => {
          
          Item.push(element.id);
        });
        console.log("discussion"+Item);
      this.sessionPlannerDiscussionForm.get('shareWith').patchValue(Item.toString());*/


        this.sessionPlannerService.UpdateCoursePlannerDiscussion(this.sessionPlannerDiscussionForm.value).subscribe((response) => {
        

          this.toastr.success("Succesfully Updated SessionPlanner Discussion", 'Success!',{timeOut: 1000});
        });
      }
    } 
  
  }

  public saveSessionPlannerApproval() {
   
    this.submitted = true;

    // this.load.show();
 
    //this.sessionPlannerApprovalForm.get('sessionPlannerId').patchValue(this.sessionPlannerApprovalForm.get('sessionPlannerId').value);
     
    if (this.sessionPlannerApprovalForm.valid) {
      if(this.sessionPlannerApprovalForm.get('id').value == 0){
        this.sessionPlannerService.SaveSessionPlannerApproval(this.sessionPlannerApprovalForm.value).subscribe((response) => {
        this.toastr.success("Succesfully Added SessionPlanner Approval", 'Success!',{timeOut: 1000});
        });
      }
      else{
        this.sessionPlannerService.UpdateSessionPlanerApproval(this.sessionPlannerApprovalForm.value).subscribe((response) => {
        this.toastr.success("Succesfully Updated SessionPlanner Approval", 'Success!',{timeOut: 1000});
        });
      }
    } 
  
  }


// test

public GetAllStudentYear(){
  var BatchID = this.sessionPlannerForm.get('batchId1').value;
  var ClassID = this.sessionPlannerForm.get('classId').value;
  this.sessionPlannerService.GetAllStudentsByClassBatchYear(ClassID,BatchID).subscribe((response) => {
    console.log(response +"this my tests object");
    this.lstStudent =response;
    this.totalStudent = response.length;
    this.selectedStudent = this.lstStudent.length;
  });
  this.sessionPlannerService.GetAllStudentsByClassBatchYear(ClassID,BatchID).subscribe((response) => {
    this.lstSessionStudent= response;
    this.selectedSessionStudent = this.lstSessionStudent.length;
  });
}


  public GetAllStudent(){

    this.sessionPlannerService.GetAllStudent().subscribe((response) => {
      this.lstStudent =response;
      this.totalStudent = response.length;
      this.selectedStudent = this.lstStudent.length;


    });
    this.sessionPlannerService.GetAllStudent().subscribe((response) => {
      this.lstSessionStudent= response;
      this.selectedSessionStudent = this.lstSessionStudent.length;
    });
  }

  public GetAllAcademicStreamingAPI(){
    this.sessionPlannerService.GetAllAcademicStreamingAPISetting().subscribe((response) => {
      this.lstStramingAPI = response;
      console.log(response);
    });
  }
  
public getStramingAPIId(){
  
  console.log(this.ProviderType);
  var streamingProviderType = this.sessionPlannerContentForm.get('streamingProviderType').value;
  if(!streamingProviderType)
  var streamingProviderType = this.sessionPlannerContentForm.value.streamingProviderType;


  this.sessionPlannerService.GetStreamingAPILink(streamingProviderType).subscribe((response) => {

  
  // this.coursePlannerService.GetStreamingAPILink(streamingProviderType).subscribe((response) => {

  // this.coursePlannerService.GetStreamingAPILink(streamingProviderType).subscribe((response) => {
  this.sessionPlannerContentForm.get('meetingLink').patchValue(response.meetingLink);

    
  });


}
changeBatch(event:any){
 
  this.SessionLeftMenu=false;
  var val= event;
  this.tempbatchId=event;
  if(val==-1){
    this.lstSessionPlannerVMList = this.templstSesion;
  }else{
    var result=this.templstSesion.filter((X:any)=>X.batchId==val);
 this.lstSessionPlannerVMList = result;
  }
   
      this.lstSessionPlannerVMList.forEach(element => { 
        element.colorCode = "";
      })
    
  
}
  public getCoursePlannerID(){
   
    //var BatchID = this.sessionPlannerForm.get('batchId1').value;
    var ClassID = this.sessionPlannerForm.get('classId').value;
    var CoursePlannerId = this.sessionPlannerForm.get('coursePlannerId').value;
    this.SessionLeftMenu=false;

   // if(BatchID>0){

      //this.getAllChapterPlannerByCoursePlannerID(CoursePlannerId);
      this.sessionPlannerService.getAllChapterPlannerByCoursePlannerID(CoursePlannerId).subscribe((response) => {
      
        this.lstChapterTopicPlannerList = response;
      });
      this.courseTopicName = this.lstCourseList.find(x=>x.id == CoursePlannerId).courseTitle;
      // this.getAllCoursePlanByClassBatch(ClassID,BatchID)

      //this.GetAllSubject();
   // }
    
  }

  public getAllChapterPlannerByCoursePlannerID(id) {
    this.sessionPlannerService.getAllChapterPlannerByCoursePlannerID(id).subscribe((response) => {
      
      this.lstChapterTopicPlannerList = response;
    });
  }



  public getBatchId(){
    this.lstCourseList=[];
    var BatchID = this.sessionPlannerForm.get('batchId1').value;
    var ClassID = this.sessionPlannerForm.get('classId').value;

    if(BatchID>0){

      //this.getAllCoursePlanByClassBatch(ClassID,BatchID);
      // this.getAllCoursePlanByClassBatch(ClassID,BatchID)
      //this.resetForm();
      this.getAllCoursePlanByClass(ClassID);
      this.lstChapterTopicPlannerList=[];
      this.sessionPlannerForm.get('coursePlannerId').setValue(0);
      this.headline = this.lstClass.find(x=>x.id == ClassID).classCourseName;
      this.headline =  this.headline + " "+ this.lstBatch.find(x=>x.id == BatchID).batchName;
      //this.GetAllSubject();
    }
    else{
      this.getAllCoursePlanByClass(ClassID);
    }
  }


  public getTopicplannerId(i){

    this.SessionLeftMenu=false;
    this.sessionPlannerForm.get('topicPlannerId').patchValue(i);
    this.getSessionPlannerByTopicID();

  }

  public GetAllStudentByClassbatch(sessionPlannerId,batchId){

    //var BatchID = this.sessionPlannerForm.get('batchId1').value;
    //var BatchID = this.sessionPlannerForm.get('batchId').value;
    var ClassID = this.sessionPlannerForm.get('classId').value;
    this.sessionPlannerService.GetAllStudentsByClassBatch(ClassID,batchId).subscribe((response) => {
      console.log("response");
      console.log(response);
      this.lstStudent =response;
      this.totalStudent = response.length;
      this.selectedStudent = this.lstStudent.length;

      //// get Discussion

      this.sessionPlannerService.getSessionPlannerDiscussionById(sessionPlannerId).subscribe((data: SessionPlannerDiscussionVm) => {
        
        if(data == null){
          this.sessionPlannerDiscussionForm = this.formBuilder.group({
            id: 0,
            discussionTitle: ["", Validators.required],
            shareWith: [""],
            sessionPlannerId: sessionPlannerId,
            radioAllStudent:['all']
            
            });
            this.selectedStudent=0;
        }
        else{
          this.sessionPlannerDiscussionForm = this.formBuilder.group({
            id: data.id,
            discussionTitle: [data.discussionTitle, Validators.required],
            shareWith: [data.shareWith, Validators.required],
            sessionPlannerId: data.sessionPlannerId,
            radioAllStudent:['all']

            });
            console.log(this.lstStudent);
            let sharedata = this.sessionPlannerDiscussionForm.get('shareWith').value;

            for (var val of sharedata.split(",")) {
              let b = this.lstStudent.find(x=>x.id == val);
              if(b){ b.isChecked = true;}
            }
           this.selectedStudent = this.lstStudent.filter(x=>x.isChecked == true).length;
        }

      });
    });

    this.sessionPlannerService.GetAllStudentsByClassBatch(ClassID,batchId).subscribe((response) => {
      this.lstSessionStudent= response;
      this.selectedSessionStudent = this.lstSessionStudent.length;
      console.log("Student Session");
      console.log(this.lstSessionStudent);

      this.sessionPlannerService.getSessionPlannerStudentById(sessionPlannerId).subscribe((data:StudentSessionList[]) => {
            for (var val of data) {
              let b = this.lstSessionStudent.find(x=>x.id == val.studentId);
              console.log(b);
              if(b!=undefined){ b.isChecked = true;}
            }

            this.selectedSessionStudent = this.lstSessionStudent.filter(x=>x.isChecked == true).length;
            console.log("data");
           console.log(data);
           console.log("lstSessionStudent");
           console.log(this.lstSessionStudent);
      
      });

    });

    

  }

  public getAssesmenttype(){

    
    var Sessiontype = this.sessionPlannerAssesmentForm.get('assignmentTypeId').value;
   // var s = this.lstAssesmentsType.find(x=>x.id == Sessiontype);


    // this.lstAssesmentsTtile = this.lstAssesmentsType.filter(x=>x.assignmentType == Sessiontype);

    this.lstAssesmentsTtile = this.lstAssesmentsType;


  }

  public OpenLeftTab(sessionPlannerId,batchId){
    
    
    var Sessiontype = this.sessionPlannerForm.get('sessiontype').value;
    this.SessionLeftMenu = true;
    this.GetAllStudentByClassbatch(sessionPlannerId,batchId);
    //this.GetAllStudentYear();
    this.GetAllAcademicStreamingAPI();
    this.GetAllExams();
    this.GetAllExamsCategory();
    var s = this.lstSessionPlannerVMList.find(x=>x.id == sessionPlannerId).sessionType;
    var sss= this.lstSessionPlannerVMList.filter(x=>x.colorCode == '#c9c9d9');
    if(sss!= null){
      sss.forEach(element => { 
        element.colorCode = "";
      })
    }

    this.lstSessionPlannerVMList.find(x=>x.id == sessionPlannerId).colorCode = '#c9c9d9';
  //  this.sessionPlannerContentForm.get('sessionPlannerId').patchValue(i);

      //   this.sellectedSession= i;
      // var s=  this.sessionPlannerForm.get('sessiontype').value;
      //  this.sessionPlannerContentForm.get('sessionType').patchValue(s);
      var ClassID = this.sessionPlannerForm.get('classId').value;


      /// 
      this.getAllAssesmentType(ClassID);


      /// Get Content Information
      this.sessionPlannerService.getSessionPlannerContentById(sessionPlannerId).subscribe((data: SessionPlannerContentVm) => {
       
        
        this._SessionType = s;

        if(data == null){
          this.sessionPlannerContentForm = this.formBuilder.group({
            id: 0,
            sessionType: [s, Validators.required],
            streamingProviderType: [0],
            meetingLink: [''],
            externalLink: [''],
            uploadedLink: [''],
            contentDescription: [''],
            sessionPlannerId: [sessionPlannerId]
            
            });
            
        }
        else{

          this.sessionPlannerContentForm = this.formBuilder.group({
            id: data.id,
            sessionType: [s, Validators.required],
            streamingProviderType: [data.streamingProviderType],
            meetingLink: [data.meetingLink],
            externalLink: [data.externalLink],
            uploadedLink: [data.uploadedLink],
            contentDescription: [data.contentDescription],
            sessionPlannerId: [sessionPlannerId]
            
            });
           
            if (data.externalLink!=""){
              this.isExternalLink=true;
            }   
            if (data.uploadedLink!=""){
              this.isUploadedFile=true;
            }   
            this.sessionPlannerContentForm.get('streamingProviderType').patchValue(data.streamingProviderType);
        }
        if(s==1)
        {
          this.sessionPlannerContentForm.controls["streamingProviderType"].setValidators([Validators.required]);
          this.sessionPlannerContentForm.controls["meetingLink"].setValidators([Validators.required]);
        }
      });


      /// Get Assesment Information
      this.sessionPlannerService.getSessionPlannerAssesmentById(sessionPlannerId).subscribe((data: AssesmentVm) => {
        
        if(data == null){
          this.sessionPlannerAssesmentForm = this.formBuilder.group({
            id: 0,
            assignmentTypeId: [0, Validators.required],
            assignmentTitleId: [0, Validators.required],
            sessionPlannnerId: sessionPlannerId,
  
            
            });
        }
        else{
          this.sessionPlannerAssesmentForm = this.formBuilder.group({
            id: data.id,
            assignmentTypeId: [data.assignmentTypeId, Validators.required],
            assignmentTitleId: [data.assignmentTitleId, Validators.required],
            sessionPlannnerId: data.sessionPlannnerId,
            });
            this.sessionPlannerAssesmentForm.get('assignmentTypeId').patchValue(data.assignmentTypeId);
            this.sessionPlannerAssesmentForm.get('assignmentTitleId').patchValue(data.assignmentTitleId);
        }

      });

      
         /// Get Session Quize

         this.sessionPlannerService.GetQuizBySessionId(sessionPlannerId).subscribe((data: QuizVm) => {
        
          if(data == null){
            this.sessionPlannerQuizForm = this.formBuilder.group({
              id: 0,
              quizTypeId: [0, Validators.required],
              quizNameId: [0, Validators.required],
              sessionPlannnerId: sessionPlannerId,
              
              });
          }
          else{
            this.sessionPlannerQuizForm = this.formBuilder.group({
             
              id: data.id,
              quizTypeId: [data.quizTypeId, Validators.required],
              quizNameId: [data.quizNameId, Validators.required],
              sessionPlannnerId: data.sessionPlannnerId,
              }); 
          }
  
        });
      
      /// Get Session Planner Student

      ///Get Session Approval
      this.sessionPlannerService.getSessionPlannerApprovalById(sessionPlannerId).subscribe((data: SessionApproval) => {
        
        if(data == null){
          this.sessionPlannerApprovalForm = this.formBuilder.group({
            id:0,
            approvalId: 0,
            description: [''],
            sessionPlannnerId: sessionPlannerId,
            
            });
        }
        else{
          this.sessionPlannerApprovalForm = this.formBuilder.group({
            id:data.id,
            approvalId: data.approvalId,
            description: data.description,
            sessionPlannnerId: sessionPlannerId,          
            });
        }

      });
     

  }
  onFileChanged(files) {
   
    this.filePath= "";
    this.ImageData = <File>files[0];
  
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    }
   
    reader.readAsDataURL(this.ImageData);
  }
  public uploadFile = (document, id) => {
   
    this.sessionPlannerService.UploadSessionTypeFile(document, id).subscribe((response) => {
    });
  }

  public saveSessionPlannerContent(){
   
     // this.sessionPlannerForm.value
     if (this.sessionPlannerContentForm.valid) {
      if(this.sessionPlannerContentForm.get('id').value == 0){
        

        this.sessionPlannerService.SaveSessionPlannerContent(this.sessionPlannerContentForm.value).subscribe((response) => {
          if( this._SessionType!=1){
           
            const formData = new FormData();
            if(this.ImageData != undefined){
              formData.append('file', this.ImageData, this.ImageData.name); 
              this.uploadFile(formData, response.id);
            }
          }
          this.saveSessionPlannerAssesment();
          this.saveSessionPlannerDiscussion();
          this.saveSessionPlannerQuiz();
          this.saveSessionPlannerStudent();
          this.saveSessionPlannerApproval();
          //this.savesessionPl

          this.getSessionPlannerByTopicID();
          //this.resetForm();
          
          this.toastr.success("Succesfully Added  SessionPlanner Content", 'Success!',{timeOut: 1000});
        },
        error =>{
          console.log(error);
        });
      }
      else{
       // var _date = this.sessionPlannerForm.get('StartDate').value;
        // k.StartDate = new Date(_date);
        this.sessionPlannerService.UpdateContentPlan(this.sessionPlannerContentForm.value).subscribe((response) => {
          if( this._SessionType!=1){
           
            const formData = new FormData();
            if(this.ImageData != undefined){
              formData.append('file', this.ImageData, this.ImageData.name); 
              this.uploadFile(formData, response.id);
            }
          }
          this.saveSessionPlannerAssesment();
          this.saveSessionPlannerDiscussion();
          this.saveSessionPlannerQuiz();
          this.saveSessionPlannerStudent();
          this.saveSessionPlannerApproval();
         // this.resetForm();
          this.toastr.success("Succesfully Updated SessionPlanner Content", 'Success!',{timeOut: 1000});
        });
      }
    } 


    // this.saveSessionPlannerDiscussion();
    // this.saveSessionPlannerAssesment();
    // this.saveSessionPlannerQuiz();
    // this.saveSessionPlannerStudent();

  }

  public getSessionPlannerByTopicID(){

    var id = this.sessionPlannerForm.get('topicPlannerId').value;
  
    this.sessionPlannerService.getAllSessionPlannerByTopicPlannerID(id).subscribe((response) => {     
      this.lstSessionPlannerVMList = response;
      this.templstSesion=response;
      if(this.tempbatchId!=null && this.tempbatchId!=undefined  && this.tempbatchId!=0){
        this.changeBatch(this.tempbatchId);
      }
    });

  }

  updateForm(id: number) {
 
    
    this.sessionPlannerService.getById(id).subscribe((data: SessionPlannerVM) => {
      // this.GetAllSubject();
      // this.GetAllEmployee();
      // this.getAllClass();
      // this.getAlllanguage();
    this.actionName="Update Session";
    this.Id= data.id;
     // console.log(data);
     var classId=this.sessionPlannerForm.get('classId').value;
     var batchId1= this.sessionPlannerForm.get('batchId1').value;
  var coursePlannerId=this.sessionPlannerForm.get('coursePlannerId').value;
    this.sessionPlannerForm = this.formBuilder.group({
      classId: [classId, Validators.required],
      batchId1: [batchId1, Validators.required],
      coursePlannerId: [coursePlannerId, Validators.required],
      id: data.id,
      topicPlannerId: [data.topicPlannerId, Validators.required],
      batchId: [data.batchId, Validators.required],
      employeeId: [data.employeeId, Validators.required],
      sessiontype: [data.sessionType, Validators.required],
      time: [data.time, Validators.required],
      duration: [data.duration, Validators.required],
      sessionDate: [this.formatedDate(data.sessionDate), Validators.required],
      });

    
      var BatchID = this.sessionPlannerForm.get('employeeId').value;
 

  });
}


deleteForm(id: number) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You won\'t be able to revert this!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {

      this.sessionPlannerService.delete(id).subscribe(res => {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
      this.resetForm();
     // this.selectSubjects();
     this.getSessionPlannerByTopicID();

      });
    // For more information about handling dismissals please visit
    // https://sweetalert2.github.io/#handling-dismissals
    } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire(
      'Cancelled',
      'Your imaginary file is safe :)',
      'error'
    );
    }
  });
}



public resetForm(){
  console.log(this.sessionPlannerForm.value);
  
  var topicId=this.sessionPlannerForm.get('topicPlannerId').value;
  var classId=this.sessionPlannerForm.get('classId').value;
  var batchId1= this.sessionPlannerForm.get('batchId1').value;
  var coursePlannerId=this.sessionPlannerForm.get('coursePlannerId').value;

 /* this.sessionPlannerForm = this.formBuilder.group({
    id: 0,
    classId:this.sessionPlannerForm.get('classId').value,
    topicPlannerId: [this.sessionPlannerForm.get('topicPlannerId').value, Validators.required],
    batchId: [0, Validators.required],
    batchId1: this.sessionPlannerForm.get('batchId1').value,
    coursePlannerId: [this.sessionPlannerForm.get('coursePlannerId').value, Validators.required],
    employeeId: [0, Validators.required],
    sessiontype: [0, Validators.required],
    time: [, Validators.required],
    duration: [, Validators.required],
    sessionDate: ['', Validators.required],
    });*/
    if(this.tempbatchId!=null && this.tempbatchId!=undefined  && this.tempbatchId!=0){
       batchId1=this.tempbatchId;
    }else{
      batchId1=-1;
    }
    this.sessionPlannerForm = this.formBuilder.group({
      id: 0,
      classId:classId,
      topicPlannerId: [topicId, Validators.required],
      //batchId: [batchId1, Validators.min(1)],
      batchId: [batchId1],
      batchId1: ['-1'],
      coursePlannerId: [coursePlannerId, Validators.required],
      employeeId: [-1, Validators.min(1)],
      sessiontype: [-1, Validators.min(1)],
      time: [, Validators.required],
      duration: [, Validators.required],
      sessionDate: ['', Validators.required],
      });

  this.actionName="Create Session";
    //this.sel
  this.SessionLeftMenu = false;
  this.courseTopicName="";
    this.GetAllEmployee();
    this.getAlllanguage();
    //this.getCoursePlannerID();
    console.log("form reseted");
}



  private formatDate(date) {

    var day = date.split("-");

    return day[2]+"-"+day[1]+"-"+day[0];
    
  }

  // private formatedDate(date) {
  //   const d = new Date(date);
  //   let month = '' + (d.getMonth() + 1);
  //   let day = '' + d.getDate();
  //   const year = d.getFullYear();
  //   if (month.length < 2) {month = '0' + month; }
  //   if (day.length < 2) {day = '0' + day; }
  //   return [day, month, year].join('-');
  // } 


  private formatedDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
  
    
    return ({ day: parseInt(day), month: parseInt(month), year: year });
    // return [day, month, year].join('-');
  
  }


get fs() { return this.sessionPlannerForm.controls; }


}







/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
 @Injectable()
 export class CustomDateParserFormatter extends NgbDateParserFormatter {
 
   readonly DELIMITER = '-';
 
   parse(value: string): NgbDateStruct | null {
     if (value) {
       const date = value.split(this.DELIMITER);
       return {
         day : parseInt(date[0], 10),
         month : parseInt(date[1], 10),
         year : parseInt(date[2], 10)
       };
     }
     return null;
   }
 
   format(date: NgbDateStruct | null): string {
     return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
   }
 }
 

 