import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {OutstandingDueReportModel, OutstandingDueFollowUpDetails, OutstandingFeeDueDetails} from './outstandingduereport.model';
import { Installment} from '../../installment/installment.model';
import { FeeHead} from '../../feehead/feehead.model';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OutstandingDueService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  getOutstandingDueReport(): Observable<OutstandingDueReportModel[]> {
   return this.httpClient.get<OutstandingDueReportModel[]>(environment.apiUrl + '/FeeCollection/GetOutstandingDueReport')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getOutstandingDueSearchReport(details:any): Observable<OutstandingDueReportModel[]> {
    return this.httpClient.get<OutstandingDueReportModel[]>(environment.apiUrl + '/FeeCollection/GetOutstandingDueSearchReport?Status='+details[0].status+'&Classid='+details[0].class+'&Batchid='+details[0].batch+'&FeeHeadId=-1'+'&FromDate='+details[0].fromdate+'&ToDate='+details[0].todate)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(studentFeeDueFollowUpDto): Observable<OutstandingDueReportModel> {
    return this.httpClient.post<OutstandingDueReportModel>(environment.apiUrl +
       '/StudentFeeDueFollowUp/', JSON.stringify(studentFeeDueFollowUpDto), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }
  getOutstandingDueFollowUpDetails(studentId, academicYearId, installmentId) {
    return this.httpClient.get<OutstandingDueFollowUpDetails>(environment.apiUrl +
       '/FeeCollection/GetOutstandingDueFollowUpDetails/' + studentId + '/' + academicYearId + '/' + installmentId)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getOutstandingFeeDueDetails(studentId, academicYearId, installmentId) {
    return this.httpClient.get<OutstandingFeeDueDetails>(environment.apiUrl +
       '/FeeCollection/GetOutstandingFeeDueDetails/' + studentId + '/' + academicYearId + '/' + installmentId)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getFeePayment(id, fromdate, todate): Observable<Installment[]> {
    //getFeePayment(id, fromdate, todate): Observable<any> {
    return this.httpClient.get<Installment[]>(environment.apiUrl + '/feeinstallment/GetStudentPayment?id=' + id+'&FromDate='+fromdate+'&ToDate='+todate)
    .pipe(
      catchError(this.errorHandler)
    );
    // return this.httpClient.get<any>(environment.apiUrl + '/feeinstallment/GetStudentPaymentCollection?id=' + id+'&FromDate='+fromdate+'&ToDate='+todate)
    // .pipe(
    //   catchError(this.errorHandler)
    // );
  }

  /*getByInstallmentId(id, classId, studentId): Observable<FeeHead[]> {
    return this.httpClient.get<FeeHead[]>(environment.apiUrl + '/feehead/GetFeeHeadByInstallment/' + id + '/' + classId + '/' + studentId)
    .pipe(
      catchError(this.errorHandler)
    );
  }*/
  getByInstallmentId(id, classId, batchId,studentId): Observable<FeeHead[]> {
    return this.httpClient.get<FeeHead[]>(environment.apiUrl + '/feehead/GetFeeHeadByInstallment/' + id + '/' + classId + '/'+ batchId + '/' + studentId)
    .pipe(
      catchError(this.errorHandler)
    );
  }

errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
