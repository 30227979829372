import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FinalReportCardComponent } from './final-report-card.component';
const routes: Routes = [
  {
    path:'FinalReportCard', component: FinalReportCardComponent
  }
];


@NgModule({
  declarations: [FinalReportCardComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class FinalReportCardModule { }
