import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs";
import { pairwise, startWith } from "rxjs/operators";
import { EvaluationType } from "../ExamSetting/models/evaluation-type.model";
import { GradescalegroupService } from '../services/gradescalegroup.service';
import { MarksEntryService } from "../services/marks-entry.service";

@Component({
  selector: "app-marks-entry",
  templateUrl: "./marks-entry.component.html",
  styleUrls: ["./marks-entry.component.css"],
  providers: [FormGroupDirective],
})
export class MarksEntryComponent implements OnInit {
  submitted = false;
  evaluationType: EvaluationType[] = [];
  categorydropDown: any = [];
  examNamedropDown: any = [];
  finalexamNamedropDown: any = [];
  examClassdropDown: any = [];
  marksEntryList: any = [];
  classDropDown: any = [];
  section: any = [];
  saveDataModel = [];
  maxSubjectCount:number;
  loader: any = false;
  subjectWithFullMarks: any = [];
  evaluation: EvaluationType[]=[];
  disabled = true;
  IsTrue:boolean =false;
  academicYearId:number=0;
  constructor(
    private gradeService: GradescalegroupService,
    private  GradescalegroupService:GradescalegroupService,
    private toastr: ToastrService,
    public fb: UntypedFormBuilder,
    public service: MarksEntryService,
    public formDirective: FormGroupDirective
  ) {}
  marksEntryForm: UntypedFormGroup;
  classBatachData: any = [];

  ngOnInit(): void {
    this.marksEntryForm = this.fb.group({
      id: [0],
      examCategory: ["", Validators.required],
      examName: ["", Validators.required],
      classId: ["", Validators.required],
      classBatch: ["", Validators.required],
      evaluationType: ["all", Validators.required],
    });
    this.getCategoryDropDown();
    this.getAllEvalutionTypes();
  }
  getAllEvalutionTypes(){
    this.GradescalegroupService.getAllEvalutaionTypes().subscribe((data:EvaluationType[])=>{
     
      this.evaluationType=data;
    });
}
   
  get f() {
    return this.marksEntryForm.controls;
  }
  getCategoryDropDown() {
    this.IsTrue=false;
    this.service.getAllCatefgoryDropDown().subscribe((data) => {
      this.categorydropDown = data;
    });
    this.checkingButton();
  }
  onCategoryEvent() {
    this.IsTrue=false;
    this.marksEntryForm.value.examName = "";
    this.finalexamNamedropDown = [];
    this.examClassdropDown = [];
    this.marksEntryList = [];
    this.getNameDropDown();
    this.checkingButton();
    var item=this.categorydropDown.findIndex(x=>x.id==this.marksEntryForm.value.examCategory);
    this.academicYearId=this.categorydropDown[item].academicYearId;
    this.marksEntryForm = this.fb.group({
      id: [0],
      examCategory: [this.marksEntryForm.value.examCategory, Validators.required],
      examName: ["", Validators.required],
      classId: ["", Validators.required],
      classBatch: ["", Validators.required],
      evaluationType: ["all", Validators.required],
    });
  }
  submitForm() {}

  getNameDropDown() {
    this.IsTrue=false;
    this.service.getExamNameList().subscribe((data) => {
      this.examNamedropDown = data;
      this.finalexamNamedropDown = this.examNamedropDown.filter(
        (filterList: any) =>
          filterList.examCategoryId === this.marksEntryForm.value.examCategory
      );
    });
    this.checkingButton();
  }

  getClassDropDown() {
    this.IsTrue=false;
    this.service
      .getAllClass(this.marksEntryForm.value.examName)
      .subscribe((data) => {
        this.examClassdropDown = data;
        this.classDropDown = [
          ...new Map(
            this.examClassdropDown.map((item) => [item["className"], item])
          ).values(),
        ];
        this.marksEntryList = [];
        this.checkingButton();
      });
      this.marksEntryForm = this.fb.group({
        id: [0],
        examCategory: [this.marksEntryForm.value.examCategory, Validators.required],
        examName: [this.marksEntryForm.value.examName, Validators.required],
        classId: ["", Validators.required],
        classBatch: ["", Validators.required],
        evaluationType: ["all", Validators.required],
      });
  }
  getAllEvaluationsType() {
    this.marksEntryList = [];
    this.IsTrue=false;
    this.gradeService
      .getAllEvaluationsType()
      .subscribe((data: EvaluationType[]) => {
        this.evaluationType = data;
      });
  }

  selectedClass(id) {
    let batchList = [];
    this.IsTrue=false;
    this.marksEntryList = [];
    if (this.marksEntryForm.value.classId !== "") {
      batchList = this.examClassdropDown.filter((element) => element.id == id);
      this.classBatachData = batchList;
      this.checkingButton();
      this.marksEntryForm = this.fb.group({
        id: [0],
        examCategory: [this.marksEntryForm.value.examCategory, Validators.required],
        examName: [this.marksEntryForm.value.examName, Validators.required],
        classId: [this.marksEntryForm.value.classId, Validators.required],
        classBatch: ["", Validators.required],
        evaluationType: ["all", Validators.required],
      });
    }
    // if (this.marksEntryForm.value.classId == "8")
    // {
    //   this.marksEntryForm.value.classBatch = "1";
    // }
    // else
    // {
    //   this.marksEntryForm.value.classBatch = "2";
    // }
    
  }

  selectedClassBatch() {
    this.marksEntryList = [];
    this.IsTrue=false;
    if (this.marksEntryForm.value.classBatch !== "") {
      this.section = this.classBatachData.filter(
        (element) =>
          element.batchId === this.marksEntryForm.controls.classBatch.value
      );
      this.checkingButton();
    }
  }

  selectedEvaluation() {
    this.checkingButton();
  }

  checkingButton() {
    if (
      this.marksEntryForm.controls.examCategory.value &&
      this.marksEntryForm.controls.examName.value &&
      this.marksEntryForm.controls.classId.value &&
      this.marksEntryForm.controls.classBatch.value &&
      this.marksEntryForm.controls.evaluationType.value
    ) {
      this.disabled = false;
    } 
  }

  getAllMarksEntry() {
    if (
      this.marksEntryForm.valid
    ) {
      this.loader=true;
      this.IsTrue=false;
      this.service.getAllMarksEntry(
        
        this.marksEntryForm.controls.examCategory.value,
        this.marksEntryForm.controls.examName.value,
        this.marksEntryForm.controls.classId.value,
        this.marksEntryForm.controls.classBatch.value,
        this.marksEntryForm.controls.evaluationType.value
      ).subscribe((data)=>{
         
          this.service.getSubjectWithFullMarks(
            this.marksEntryForm.controls.examCategory.value,
            this.marksEntryForm.controls.examName.value,
            this.marksEntryForm.controls.classId.value,
            this.marksEntryForm.controls.classBatch.value,
            this.marksEntryForm.controls.evaluationType.value
          ).subscribe((data1)=>{
            this.loader=false;
            this.IsTrue=true;
            this.marksEntryList = data;
            this.subjectWithFullMarks = data1;
          });
      });
    // combineLatest([
      
    //   this.service.getAllMarksEntry(
        
    //     this.marksEntryForm.controls.examCategory.value,
    //     this.marksEntryForm.controls.examName.value,
    //     this.marksEntryForm.controls.classId.value,
    //     this.marksEntryForm.controls.classBatch.value,
    //     this.marksEntryForm.controls.evaluationType.value
    //   ),
    //   this.service.getSubjectWithFullMarks(
    //     this.marksEntryForm.controls.classId.value,
    //     this.marksEntryForm.controls.classBatch.value,
    //     this.marksEntryForm.controls.evaluationType.value
    //   )
    // ]).subscribe(([marksEntryList, subjectWithFullMarks]) => {
    //   this.marksEntryList = marksEntryList;
    //   this.subjectWithFullMarks = subjectWithFullMarks;
    // });
  }
  else{
   
    this.toastr.warning("All field are required", "WARNING!!!", {
      timeOut: 3000,
    });
    return;
  }
  }
 
  enforceMinMax(el){
    var x= document.getElementById("fullmark").innerHTML;

    if(el.value != ""){
      if(parseInt(el.value) < parseInt(el.min)){
        el.value = 0;
      }
      if(parseInt(el.value) > parseInt(el.max)){
        el.value = 100;
      }
    }
  }

  getMaxSubject(){

      let total= this.marksEntryList.length;    // 6
      this.maxSubjectCount = this.marksEntryList[0].studentMarks.length;
      for(let i=0;i<total;i++)
      {
          if(this.maxSubjectCount<this.marksEntryList[i].studentMarks.length)
          {
            this.maxSubjectCount=this.marksEntryList[i].studentMarks.length;
          }
      }
 
  }

  submitMarksEntry() {
    this.loader=true;
    this.service.saveMarksEntry(this.saveMarksEntries).subscribe((res) => {
      this.toastr.success("Successfully created", "SUCCESS!", {
        timeOut: 3000,
      });
      this.loader=false;
      this.submitted = false;
      this.saveMarksEntries = [];
      this.resetForm();
      
    });
  }


  saveMarksEntries = [];
  makeModel(marks, studentId, subjectId, optional:any,value) {
    
    let present=false; 
    this.saveMarksEntries = [];
    if(marks <= value.fullMarks){
    let model = {
      id: optional,
      examCategoryId: this.marksEntryForm.value.examCategory,
      examId: this.marksEntryForm.value.examName,
      classId: this.marksEntryForm.value.classId,
      batchId: this.marksEntryForm.value.classBatch,
      isCoScholastic: true,
      studentId: studentId,
      subjectId: subjectId,
      marks: marks,
      academicYearId: this.academicYearId,
      evaluvationType:this.marksEntryForm.value.evaluationType == "all" ? -1 : this.marksEntryForm.value.evaluationType
    };
    if (model.studentId == studentId) {
    //   if(this.saveDataModel.length>0){
    //  for(let item of this.saveDataModel)
    //  {
    //   if(item.studentId ===studentId && item.subjectId===subjectId){
    //     item.marks=marks;
    //      present=true;
    //   }         
    //  }
    // }
      
    //    if(!present){
    //     this.saveDataModel.push(model);
    //    }
    if(this.marksEntryList.length > 0){
      for(let item of this.marksEntryList)
      {
        if(item.studentMarks.find(x => x.studentId == studentId && x.subjectId == subjectId)){
          item.studentMarks.filter(x => x.studentId == studentId && x.subjectId == subjectId)[0].marks = marks;
          present = true;
      }
      }
      for(let item of this.marksEntryList){
        item.studentMarks.forEach((element) => {
        let dataModel = {
          id: 0,
          examCategoryId: this.marksEntryForm.value.examCategory,
          examId: this.marksEntryForm.value.examName,
          classId: this.marksEntryForm.value.classId,
          batchId: this.marksEntryForm.value.classBatch,
          isCoScholastic: true,
          studentId: element.studentId,
          subjectId: element.subjectId,
          marks: element.marks,
          academicYearId: this.academicYearId,
          evaluvationType:this.marksEntryForm.value.evaluationType == "all" ? -1 : this.marksEntryForm.value.evaluationType
        };
        this.saveMarksEntries.push(dataModel);
      });
    }
    }

    if(!present){
      let filteredItem = this.marksEntryList.filter(x => x.studentId == studentId)[0].studentMarks.find(x => x.studentId == studentId && x.subjectId == subjectId);
      this.marksEntryList.filter(x => x.studentId == studentId)[0].studentMarks.splice(filteredItem, 1);
      this.marksEntryList.filter(x => x.studentId == studentId)[0].studentMarks.push(model);
      this.marksEntryList.studentMarks.forEach(element => {
        this.saveMarksEntries.push(element);
      });
    }
    }
  }else{
    value.marks=0;
    this.toastr.error("Subject Marks should not greater than maximum marks", "FAILURE!", {
      timeOut: 3000,
    });
  }
  }
  checkNumber(value1){
    
  }
  resetForm() {
    this.submitted = false;
    this.finalexamNamedropDown = [];
    this.classDropDown = [];
    this.classBatachData = [];
    this.marksEntryList = [];
    this.saveDataModel = [];
    this.formDirective.reset();
    this.marksEntryForm.reset();
    this.disabled = true;
    this.marksEntryForm = this.fb.group({
        id: [0],
        examCategory: ["", Validators.required],
        examName: ["", Validators.required],
        classId: ["", Validators.required],
        classBatch: ["", Validators.required],
        evaluationType: ["all", Validators.required],
      });
  }

 
}
