import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AdminRoutingModule } from './admin-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InstitutionInterceptor } from '../institution.interceptor';
import { WelcomeComponent } from './welcome/welcome.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { AcademicModule } from './academic/academic.module';
import { FrontofficeModule } from './frontoffice/frontoffice.module';
import { CommunicationModule } from './communication/communication.module';
import { ErpsettingsComponent } from './erpsettings/erpsettings.component';
import { AdministrationModule } from './administration/administration.module';
import { NotificationComponent } from './notification/notification.component';
import { PaymentcheckoutComponent } from './paymentcheckout/paymentcheckout.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { LMSModuleModule } from './LMS/lmsmodule.module';
import { BiomatricSettingModule } from './erpsettings/biomatric-setting/biomatric-setting.module';
import { AcademicdashboardComponent } from './academicdashboard/academicdashboard.component';
import { FinancedashboardComponent } from './financedashboard/financedashboard.component';
import { AcademicyearComponent } from './academicyear/academicyear.component';

import { AnalyticalreportingsModule } from './dataandreports/analyticalreportings.module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { HrmsdashboardComponent } from './hrmsdashboard/hrmsdashboard.component';
import { TransportdashboardComponent } from './transportdashboard/transportdashboard.component';
import { ModalModule } from 'ngx-bootstrap/modal';

 
@NgModule({
  declarations: [WelcomeComponent, DashboardComponent, AdminComponent, ErpsettingsComponent, NotificationComponent, 
    PaymentcheckoutComponent, AcademicdashboardComponent, FinancedashboardComponent, HrmsdashboardComponent,
    AcademicyearComponent, UserprofileComponent, TransportdashboardComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AdminRoutingModule,
    AcademicModule,
    FrontofficeModule,
    CommunicationModule,
    NgApexchartsModule,
    AdministrationModule,
    LMSModuleModule,
    BiomatricSettingModule,
  AnalyticalreportingsModule,
  AutocompleteLibModule,
  NgbModule,
  NgSelectModule,
/*   ModalModule.forRoot() */
  ],
  exports:[
    BiomatricSettingModule, AcademicdashboardComponent, FinancedashboardComponent,
     AcademicyearComponent,
     NgbModule,
     NgSelectModule,
     NgApexchartsModule,
     FormsModule
    ],
  providers: [

  ]
})
export class AdminModule { }
