import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Student } from './../../studentmanagement/student.model';
import { StudentService } from './../../studentmanagement/student.service';
import { ManageCheque, ChequeCollectionDetail, ManageChequeDto } from './managecheque.model';
import { ManageChequeService } from './managecheque.service';
import * as moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { environment } from 'src/environments/environment';
import { dA } from '@fullcalendar/core/internal-common';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
declare var $: any;

@Component({
  selector: 'app-managecheque',
  templateUrl: './managecheque.component.html',
  styleUrls: ['./managecheque.component.css']
})
export class ManagechequeComponent implements OnInit {

  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  chequeList: ChequeCollectionDetail[] = [];
  chequeFiltredList: ChequeCollectionDetail[] = [];
  chequeManageList: ManageChequeDto[] = [];
  selectedClassId = '';
  selectedBatchId = '';
  selectedStatus = '';
  fromDate = '';
  toDate = '';
  payDate='';
  chequeForm:UntypedFormGroup;
  submitted: boolean;
  selectedCheque: ChequeCollectionDetail;
  @ViewChild('chequeStatusSelect') chequeStatusSelect :ElementRef;
  selectedChequeFromSelect = '';
  fromDateOfSearch: any;
  toDateOfSearch: any;
  batchId: string;
  totalAmount=0;
  institution: any;
  headerList: any[];
  fieldList: any[];
  constructor(
    public manageChequeService: ManageChequeService,
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }
  get f() { return this.chequeForm.controls; }
  ListCheque() {
    this.manageChequeService.getAllCheques().subscribe((data: ChequeCollectionDetail[]) => {
      console.log(data);
      this.chequeFiltredList=this.chequeList = data;
this.calculateToatlAmount();
      
    });
  }
  calculateToatlAmount(){
    this.totalAmount=0;
    this.chequeFiltredList.forEach(element => {
      this.totalAmount+=parseFloat(element.amount);
    });
  }
  ListManageCheque() {
    this.manageChequeService.getAll().subscribe((data: ManageChequeDto[]) => {
      console.log(data);
      this.chequeManageList = data;
    });
  }

	onSelected():void {
		this.selectedChequeFromSelect = this.chequeStatusSelect.nativeElement.value;
	}
  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }
  onRefreshPage(){
    this.ListCheque();
    this.selectedBatchId='0';
    this.selectedClassId = '0';
    this.toDateOfSearch='';
    this.fromDateOfSearch='';

  }


  generatePDF() {
   
    console.log(this.institution);
   
    this.headerList = [];
    this.fieldList = [];
    const attributes2 = [
      {name: 'S NO', fieldName: 'index' },
      {name: 'RECEIPT', fieldName: 'receiptNo' },
      {name: 'ADMISSION NO', fieldName: 'admissionNo' },
      {name: 'STUDENT NAME', fieldName: 'studentName' },
      {name: 'CLASS', fieldName: 'classCourseName' },
      {name: 'BATCH', fieldName: 'batchName' },
      //{name: 'ROLL NO', fieldName: 'rollNo' },
      {name: 'CHEQUE NO', fieldName: 'chequeNo' },
      {name: 'AMOUNT', fieldName: 'amount' },
      {name: 'BANK NAME', fieldName: 'bankName' },
      {name: 'CHEQUE DATE', fieldName: 'chequeDate' },
      {name: 'DATE', fieldName: 'payDate' },
      {name: 'STATUS', fieldName: 'chequeStatus' },
    ];
    attributes2.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    for (let i = 0; i < this.chequeFiltredList.length; i++) {
      this.chequeFiltredList[i]["index"] = i + 1;
      /*if (this.chequeFiltredList[i]["studentType"] == undefined)
        this.chequeFiltredList[i]["studentType"] = "";*/
    }
    const widthList = [];
    for (const item of this.headerList) {
        widthList.push('auto');
    }
    const docDefinition = {
      content: [
        {
            table: {
                widths: ['auto', '*'],
                fontSize:3,
                body: [
                    [ {
                        width: 100,
                        height: 100,
                        image: 'imageKey'
                    }, {
                        width: '*',
                        alignment: 'center',
                        stack: [
                            {
                                style: 'h1',
                                text: this.institution.institutionName
                            },
                            {
                              style: 'h2',
                              text: this.institution.address
                            },
                            /*{
                                style: 'h2',
                                text: '2021-22'
                            },*/
                            {
                                style: 'h3',
                                text: 'MANAGE CHEQUE'
                            }
                        ]
                    }
                    ]
                ]
            },
            layout: {
                hLineWidth: (line) => line === 1,
                vLineWidth: () => 0,
                paddingBottom: () => 5
            },
          },
          {
            style:'header',
            table: {
              headerRows: 1,
              widths: widthList,
              
              // pdfFonts.fontSize(5),
              body: [
                
                this.headerList,
                ...this.chequeFiltredList.map((p) => {
                  const temp = [];
                  for (const y of this.fieldList) {
                    if (y === 'chequeDate' || y === 'payDate' ) {
                      temp.push(this.formatDate(p[y]));
                    } else {
                      temp.push(p[y]);
                    }
                  }
                  return temp;
                })
              ]
            }
          }
      ],
      images: {
         imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
         // via URL address, which can be referenced by this key
      },
      styles: {
        header: {
          // bold: true,
          // decoration: 'underline',
          fontSize: 9,
          margin: [15, 15, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 16,
          // bold: true
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            // bold: true
        }
      }
    };
    pdfMake.createPdf(docDefinition).download('MANAGE CHEQUE.pdf');
  }
  onClassSelected(value: string) {
    // this.resetAllForm();
    this.selectedClassId = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
    });
    /*if(value!="0"){
      this.chequeFiltredList = this.chequeList.filter((cheque: ChequeCollectionDetail) => cheque.classId.toString() === value.toString());
    }else{
      this.chequeFiltredList=this.chequeList;
    }*/
    this.selectedBatchId='0';
    this.selectedStatus='All';
    this.chequeFiltredList=this.chequeList;
    this.calculateToatlAmount();
  }
  onBatchSelected(value: string) {
    /*if(value!="0"){
      this.chequeFiltredList = this.chequeFiltredList.filter((cheque: ChequeCollectionDetail) => cheque.batchId.toString() === value.toString());
    }else{
      this.chequeFiltredList=this.chequeList;
    }*/
   this.selectedBatchId=value;

  }
  onStatusSelected(value:string){
    /*if(value!='0'){
      this.chequeFiltredList = this.chequeFiltredList.filter((cheque: ChequeCollectionDetail) => cheque.chequeStatus === value);
    }
    else{
      this.chequeFiltredList=this.chequeList;
    }*/
    this.selectedStatus=value;

  }
  onDateSelected(){
    debugger;
    this.chequeFiltredList=this.chequeList;
    if(Number(this.selectedClassId)>0)
    {
      this.chequeFiltredList = this.chequeFiltredList.filter((cheque: ChequeCollectionDetail) => cheque.classId.toString() === this.selectedClassId.toString());
    }
    if(Number(this.selectedBatchId)>0)
    {
      this.chequeFiltredList = this.chequeFiltredList.filter((cheque: ChequeCollectionDetail) => cheque.batchId.toString() === this.selectedBatchId.toString());
    } 
    
     
    if(this.selectedStatus!="All" && this.selectedStatus!=""){
      this.chequeFiltredList = this.chequeFiltredList.filter((cheque: ChequeCollectionDetail) => cheque.chequeStatus === this.selectedStatus);
    }
    if((this.fromDateOfSearch!=undefined && this.toDateOfSearch!=undefined)){
      this.chequeFiltredList = this.chequeFiltredList.filter((item: ChequeCollectionDetail) => {
        return new Date(item.payDate).getTime() >= new Date(this.fromDateOfSearch).getTime() &&
        new Date(item.payDate).getTime() <= new Date(this.toDateOfSearch).getTime();
      });
    }
    this.ListCheque();
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }
  
  filter(keyword: any) {
      if (keyword === '') {
        this.chequeFiltredList = this.chequeList;
      } else {
        this.chequeFiltredList = this.chequeList
          .filter((cheque: ChequeCollectionDetail) => cheque.studentName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase()) ||
            cheque.chequeNo.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase())
          );
      }
  }

  get totalRows(): number {
    return this.chequeList.length;
  }


  submitForm() {
    //debugger;
    if (this.chequeForm.invalid) {
      return;
    }
    if (this.payDate='') {
      return;
    }
   /* $('#payDate').datepicker().on('changeDate', (e) => {
      this.payDate =  new Date(e.date).toDateString();
      console.log( this.payDate);
    });*/
    this.submitted=true;
    //this.chequeForm.get('payDate').patchValue(this.formatDate(this.payDate));
    this.chequeForm.get('transactionId').patchValue(this.selectedCheque.transactionId);
    this.chequeForm.get('studentAdmissionId').patchValue(this.selectedCheque.admissionId);
    console.log(this.chequeForm.value);
    debugger;
    //if (this.chequeForm.get('id').value === 0) {
      this.manageChequeService.create(this.chequeForm.value).subscribe(res => {
        console.log('Group created!');
        this.ListCheque();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        this.manageChequeService.getAll().subscribe((data: ManageChequeDto[]) => {
          console.log(data);
          this.chequeManageList = data.filter(x=>x.transactionId==this.selectedCheque.transactionId);
        });
      });
      /*} else {
        this.manageChequeService.update(this.chequeForm.get('id').value, this.chequeForm.value).subscribe(res => {
          console.log('Cheque updated!');
          this.ListCheque();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });*/
      //}
    }
    resetAllForm() {
      const self = this;
      var date = new Date();
      $('#fromDate').datepicker('setDate', new Date(date.getFullYear(), date.getMonth(), 1));
    $('#fromDate').datepicker().on('changeDate', (e) => {
      self.fromDate = e.date;
      
    });

    $('#toDate').datepicker('setDate', new Date(date.getFullYear(), date.getMonth(), 30));
    $('#toDate').datepicker().on('changeDate', (e) => {
      self.toDate = e.date;
      
    });
    }

  resetForm() {
    this.submitted = false;
    this.classes.forEach(x => x.checked = false);
    this.chequeForm = this.fb.group({
      id: [0],
      payDate: [''],
      chequeStatus: ['', Validators.required],
      remarks: [''],
      bouncingCharge:['0',Validators.required],
      transactionId:['0',Validators.required],
      studentAdmissionId:['0',Validators.required],
      notify:[false]
    });
  }

  updateForm(selectedCheque:any) {
    
  this.selectedCheque=selectedCheque;
  console.log("selectedCheque  "+this.selectedCheque)
  //this.ListManageCheque();
  //this.chequeManageList.filter(x=>x.transactionId==this.selectedCheque.transactionId);
  this.manageChequeService.getAll().subscribe((data: ManageChequeDto[]) => {
    console.log(data);
    this.chequeManageList = data.filter(x=>x.transactionId==this.selectedCheque.transactionId);
  });
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  ngOnInit(): void {
    this.submitted = false;
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    const self = this;
    //this.ListCheque();
    this.ListClassCourse();
    this.chequeForm = this.fb.group({
      id: [0],
      payDate: ['', Validators.required],
      chequeStatus: ['', Validators.required],
      remarks: [''],
      bouncingCharge:['0',Validators.required],
      transactionId:['0',Validators.required],
      studentAdmissionId:['0',Validators.required],
      notify:[false]
    });
    $('#fromDate').datepicker().on('changeDate', (e) => {
      this.fromDateOfSearch =  new Date(e.date);
    });

    $('#toDate').datepicker().on('changeDate', (e) => {
      this.toDateOfSearch = new Date(e.date);
    });

    
    $('#payDate').datepicker().on('changeDate', (e) => {
      this.chequeForm.get('payDate').patchValue(this.formatDate(e.date));
      //this.payDate =  new Date(e.date).toDateString();
      console.log( this.payDate);
    });
    //this.resetForm();
  }

}
