import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssignmentsComponent } from './assignments/assignments.component';
import { ClassworksComponent } from './classworks/classworks.component';
import { HomeworksComponent } from './homeworks/homeworks.component';

const routes: Routes = [
  {path: 'studyplan/assignment', component: AssignmentsComponent},
  {path: 'studyplan/classwork', component: ClassworksComponent},
  {path: 'studyplan/homework', component: HomeworksComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudyplanRoutingModule { }
