import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TicketManagerComponent } from './ticket-manager/ticket-manager.component';
import { SupportRoutingModule } from './support-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TicketReportComponent } from './ticket-report/ticket-report.component';
import { TicketStatusComponent } from './ticket-status/ticket-status.component';



@NgModule({
  declarations: [TicketManagerComponent, TicketReportComponent, TicketStatusComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    SupportRoutingModule
  ]
})
export class SupportModule { }
