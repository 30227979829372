export class GradeScale {
    constructor(
        public id:number,
        public grade:string,
        public minimumPercentage:number,
        public maximumPercentage:number,
        public gradePoint:number,
        public gradeScaleGroupId:number,
        public evaluationId:number
        ){}
}
