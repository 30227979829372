import { Component, OnInit, ViewChild,Input, ElementRef } from '@angular/core';
import { ApexAxisChartSeries, ApexFill, ApexGrid, ApexXAxis, ApexYAxis, ChartComponent, ChartType } from 'ng-apexcharts';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from 'ng-apexcharts';
import { Academicyear } from '../academic/mastersettings/models/academicyear';
import { SettingsService } from '../academic/mastersettings/settings.service';
import { AcademicSummary, CategoryNameReligions, DashboardClassWiseStudentsSummary, DashboardClassWiseStudentsTotalSummary, SessionWiseStudentReport, SessionWiseStudentTotalsReport } from '../dashboard/attendanceSummary.model';
import { DashboardService } from '../dashboard/dashboard.service';
import { SharedService } from '../shared.service';
export interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  plotOptions: any;
  xaxis: ApexXAxis;
  dataLabels: any;
  responsive: ApexResponsive[];
}

export interface PieChartOptions {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  legend: any;
  responsive: ApexResponsive[];
  labels: any;
  colors: any;
}

export interface InstaChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  legend: any;
  responsive: ApexResponsive[];
  labels: any;
  colors: any;
  plotOptions: any;
  dataLabels: any;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  fill: ApexFill;
  title: any;
}

@Component({
  selector: 'app-academicdashboard',
  templateUrl: './academicdashboard.component.html',
  styleUrls: ['./academicdashboard.component.css']
})
export class AcademicdashboardComponent implements OnInit {
  @Input() currentAcademicYearId: number;
  @ViewChild('currentAcademicYearDropDown') currentAcademicYearSelect :ElementRef;
  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('pie_chart') pie_chart: ChartComponent;

  @ViewChild('catwisestudent') catwisestudent: ChartComponent;
  @ViewChild('religinwisestudent') religinwisestudent: ChartComponent;
  @ViewChild('instuchart') instuchart: ChartComponent;
  
  public chartOptions: Partial<ChartOptions>;
  public pieChartOptions: Partial<PieChartOptions>;
  public instuChartOptions: Partial<InstaChartOptions>;
  public religChartOptions: Partial<PieChartOptions>;
  public catChartOptions: Partial<PieChartOptions>;

  chartData: number[] = [];
  chartLables: string[] = [];
  chartType: ChartType = 'pie';
  academicYears: Academicyear[];
  academicSummary:AcademicSummary;

  categoryNameReligions:CategoryNameReligions[]=[];


  sessionWiseStudentReport:SessionWiseStudentReport[]=[];
  sessionWiseStudentTotalsReport:SessionWiseStudentTotalsReport;
  dashboardClassWiseStudentsSummary:DashboardClassWiseStudentsSummary[]=[];
  dashboardClassWiseStudentsTotalSummary:DashboardClassWiseStudentsTotalSummary;

  constructor(
    private dashboardService: DashboardService,
    private sharedService: SharedService,
    private elementRef: ElementRef,
    private academicYearService:SettingsService
  ) {
    this.chartOptions = {
      series: [{
        data: []
      }], // chart data array
      chart: {
      
        height: 400,
        type: 'bar' // chart type such as pie,donut etc
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: { width: 200 }
          }
        }
      ]
    };

    this.pieChartOptions = {
      chart: {
        height: 320,
        type: 'pie'
      },
      series: [44, 55, 41  ],
      labels: ['Series 1', 'Series 2', 'Series 3'  ],
      colors: ['#34c38f', '#556ee6', '#f46a6a' ],
      legend: {
        show: !0,
        position: 'bottom',
        horizontalAlign: 'center',
        verticalAlign: 'middle',
        floating: !1,
        fontSize: '14px',
        offsetX: 0,
        offsetY: -10
      },
      responsive: [{
        breakpoint: 600,
        options: {
          chart: {
            height: 240
          },
          legend: {
            show: !1
          }
        }
      }]
    };

    this.instuChartOptions = {
      chart: {
        height: 235,
        type: 'bar',
        toolbar: {
          show: !1
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top',
          }
        }
      },
      dataLabels: {
        enabled: !0,
        formatter: function (e) {
          return e + "%"
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758']
        }
      },
      series: [{
        name: 'Inflation',
        data: [2.5, 3.2, 5, 10.1, 4.2, 3.8, 3, 2.4, 4, 1.2, 3.5, .8]
      }],
      colors: ['#556ee6'],
      grid: {
        borderColor: '#f1f1f1'
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: 'top',
        labels: {
          offsetY: -18
        },
        axisBorder: {
          show: !1
        },
        axisTicks: {
          show: !1
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: .4,
              opacityTo: .5
            }
          }
        },
        tooltip: {
          enabled: !0,
          offsetY: -35
        }
      },
      fill: {
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: .25,
          gradientToColors: void 0,
          inverseColors: !0,
          opacityFrom: 1,
          opacityTo: 1,
         /*  stops: [50, 0, 100, 100] */
        }
      },
      yaxis: {
        axisBorder: {
          show: !1
        },
        axisTicks: {
          show: !1
        },
        labels: {
          show: !1,
          formatter: function (e) {
            return e + "%"
          }
        }
      },
      title: {
        text: 'Monthly Inflation in Argentina, 2002',
        floating: !0,
        offsetY: 320,
        align: 'center',
        style: {
          color: '#444'
        }
      }
    };

    this.religChartOptions = {
      chart: {
          height: 320,
          type: "donut"
      },
      series: [50, 15, 15, 20],
      labels: ["Hindu", "Muslim", "Sikh", "Christians"],
      colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
      legend: {
          show: !0,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: !1,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10
      },
      responsive: [{
          breakpoint: 600,
          options: {
              chart: {
                  height: 240
              },
              legend: {
                  show: !1
              }
          }
      }]
    };

    this.catChartOptions = {
      chart: {
          height: 320,
          type: "donut"
      },
      series: [40, 30, 15, 15],
      labels: ["Gen", "OBC", "SC", "ST"],
      colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
      legend: {
          show: !0,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: !1,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10
      },
      responsive: [{
          breakpoint: 600,
          options: {
              chart: {
                  height: 240
              },
              legend: {
                  show: !1
              }
          }
      }]
  };

  }

  ngOnInit(): void {

    setTimeout(() => {
      //this.ListAcademicYear();
      this.currentAcademicYearId= this.sharedService.academicYearId;
      this.setAcademicSummary();
      this.setDashBoardSessionWiseStudentReport(); 
      this.setDashboardClassWiseStudentsSummary();
      this.setAcademicCategoryNameReligions();
    }, 100);

  }
 

onChangeAcademicYear(value:number){
    this.getDashBoardSessionWiseStudentReport(value);
    this.getDashboardClassWiseStudentsSummary(value);
    this.getAcademicCategoryNameReligions(value);
}

getDashBoardSessionWiseStudentReport(academicId:number){
    this.dashboardService.getDashBoardSessionWiseStudentReport(academicId).subscribe((data: SessionWiseStudentReport[]) => {
      this.sessionWiseStudentReport = data;
     
      let studentdata=[] ;
      let classdata=[] ;
      for (let d of this.sessionWiseStudentReport)
      {
        studentdata.push(d.registrationCount)
        classdata.push(d.classeName)
      }
      this.chart.updateOptions({
        xaxis: {
          categories: [...classdata]
        },
      });
      this.chart.updateSeries([{
        data:   [...studentdata] 

    },  ]);
      
      this.calcuteTotalsSessionWiseStudentReport();

  });
}
 

calcuteTotalsSessionWiseStudentReport(){
  this.sessionWiseStudentTotalsReport=new SessionWiseStudentTotalsReport;
  this.sessionWiseStudentTotalsReport.enquiryCount= this.sessionWiseStudentReport.reduce((sum, current) => current.enquiryCount, 0);
  this.sessionWiseStudentTotalsReport.registrationCount = this.sessionWiseStudentReport.reduce((sum, current) => sum + current.registrationCount, 0);
  this.sessionWiseStudentTotalsReport.admissionCount = this.sessionWiseStudentReport.reduce((sum, current) => sum + current.admissionCount, 0);
  this.sessionWiseStudentTotalsReport.passoutCount= this.sessionWiseStudentReport.reduce((sum, current) => sum + current.passoutCount, 0);
  this.sessionWiseStudentTotalsReport.leftCount= this.sessionWiseStudentReport.reduce((sum, current) => sum + current.leftCount, 0);
  return this.sessionWiseStudentTotalsReport;
}


getDashboardClassWiseStudentsSummary(academicId:number){
  this.dashboardService.getDashboardClassWiseStudentsSummary(academicId).subscribe((data: DashboardClassWiseStudentsSummary[]) => {
    this.dashboardClassWiseStudentsSummary = data;
         this.calcuteDashboardClassWiseStudentsSummary(); 
    this.pie_chart.updateOptions({
      series: [this.dashboardClassWiseStudentsTotalSummary.male ,this.dashboardClassWiseStudentsTotalSummary.female,this.dashboardClassWiseStudentsTotalSummary.other  ],
      labels: ['Male Student', 'Female Student', 'Other Student'  ],
      colors: ['#34c38f', '#556ee6', '#f46a6a' ],
    });
});
      
}

calcuteDashboardClassWiseStudentsSummary(){
  this.dashboardClassWiseStudentsTotalSummary=new DashboardClassWiseStudentsTotalSummary;
  this.dashboardClassWiseStudentsTotalSummary.nostudent= this.dashboardClassWiseStudentsSummary.reduce((sum, current) => sum +current.nostudent, 0);
  this.dashboardClassWiseStudentsTotalSummary.male = this.dashboardClassWiseStudentsSummary.reduce((sum, current) => sum + current.male, 0);
  this.dashboardClassWiseStudentsTotalSummary.female = this.dashboardClassWiseStudentsSummary.reduce((sum, current) => sum + current.female, 0);
  this.dashboardClassWiseStudentsTotalSummary.other= this.dashboardClassWiseStudentsSummary.reduce((sum, current) => sum + current.other, 0);
 
  return this.dashboardClassWiseStudentsTotalSummary;
}
  // ListAcademicYear() {
  //   this.academicYearService.getAll().subscribe((data: Academicyear[]) => {
  //     this.academicYears = data;
      
      
  //   });
  // }
  getAcademicSummary(academicId:number){
    this.dashboardService.getAcademicSummary(academicId).subscribe((data: AcademicSummary) => {
      this.academicSummary = data;
  });
}

getAcademicCategoryNameReligions(academicId:number){
  this.dashboardService.getAcademicCategoryNameReligions(academicId).subscribe((data: CategoryNameReligions[]) => {
    this.categoryNameReligions = data;
      let categorydata=[] ;
      let categorylable=[] ;
      let religionsdata=[] ;
      let religionslable=[] ;
      for (let d of this.categoryNameReligions)
      {
        if(d.category==='Category')
        {
          categorydata.push(d.total)
          categorylable.push(d.name)
        }
        if(d.category==='Religions')
        {
          religionsdata.push(d.total)
          religionslable.push(d.name)
        }
      }

      this.catwisestudent.updateOptions({
        series: [...categorydata],
        labels: [...categorylable],
        colors: ['#34c38f', '#556ee6', '#f46a6a' ],
      });

      this.religinwisestudent.updateOptions({
        series: [...religionsdata],
        labels: [...religionslable],
        colors: ['#34c38f', '#556ee6', '#f46a6a' ],
      });

});
}

ngAfterViewInit(): void {
  // let s = document.createElement('script');
  // s.type = 'text/javascript';
  // s.src = '../assets/apexcharts/apexcharts.min.js';
  // this.elementRef.nativeElement.appendChild(s);
  // s = document.createElement('script');
  // s.type = 'text/javascript';
  // s.src = '../assets/js/pages/apexcharts.init.js';
  // this.elementRef.nativeElement.appendChild(s);
  //this.currentAcademicYear();
  //this.currentAcademicYearSelect.nativeElement.value=this.sharedService.academicYearId;
  //this.getPendingToDoSummarySummary();
  //this.getAnalyticData(this.periodTypeValue);
}

setAcademicSummary(){
    this.academicSummary = this.sharedService.academicSummary;
}

setAcademicCategoryNameReligions(){

  this.categoryNameReligions = this.sharedService.categoryNameReligions;
    let categorydata=[] ;
    let categorylable=[] ;
    let religionsdata=[] ;
    let religionslable=[] ;
    for (let d of this.categoryNameReligions)
    {
      if(d.category==='Category')
      {
        categorydata.push(d.total)
        categorylable.push(d.name)
      }
      if(d.category==='Religions')
      {
        religionsdata.push(d.total)
        religionslable.push(d.name)
      }
    }

    this.catwisestudent.updateOptions({
      series: [...categorydata],
      labels: [...categorylable],
      colors: ['#34c38f', '#556ee6', '#f46a6a' ],
    });

    this.religinwisestudent.updateOptions({
      series: [...religionsdata],
      labels: [...religionslable],
      colors: ['#34c38f', '#556ee6', '#f46a6a' ],
    });

}

setDashBoardSessionWiseStudentReport(){

    this.sessionWiseStudentReport = this.sharedService.sessionWiseStudentReport;
   
    let studentdata=[] ;
    let classdata=[] ;
    for (let d of this.sessionWiseStudentReport)
    {
      studentdata.push(d.registrationCount)
      classdata.push(d.classeName)
    }
    this.chart.updateOptions({
      xaxis: {
        categories: [...classdata]
      },
    });
    this.chart.updateSeries([{
      data:   [...studentdata] 
      },  
    ]);
    
    this.calcuteTotalsSessionWiseStudentReport();
}



setDashboardClassWiseStudentsSummary(){
 
 this.dashboardClassWiseStudentsSummary = this.sharedService.dashboardClassWiseStudentsSummary; 
  this.calcuteDashboardClassWiseStudentsSummary(); 
  this.pie_chart.updateOptions({
    series: [this.dashboardClassWiseStudentsTotalSummary.male ,this.dashboardClassWiseStudentsTotalSummary.female,this.dashboardClassWiseStudentsTotalSummary.other  ],
    labels: ['Male Student', 'Female Student', 'Other Student'  ],
    colors: ['#34c38f', '#556ee6', '#f46a6a' ],
  });

}

}
