import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CService, UsesReport } from './uses-report.model';
import { BroadcastStatus } from '../broadcast-report/broadcast-report.model';

@Injectable({
    providedIn: 'root'
  })
  export class UsesReportService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         enctype: 'multipart/form-data'
      })
    };
    constructor(private httpClient: HttpClient) { }


    getAllServices(): Observable<CService[]> {
        return this.httpClient.get<CService[]>(environment.apiUrl + '/service/')
            .pipe(
                catchError(this.errorHandler)
            );
    }

    getAllUsesReportData(): Observable<UsesReport[]> {
      return this.httpClient.get<UsesReport[]>(environment.apiUrl + '/Credit/GetAllUsesRecordsForReport/')
      .pipe(
          catchError(this.errorHandler)
      );
      }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }

  }
