import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortwithName'
})
export class SortPipe implements PipeTransform {

  transform(array: any[], field: string): any[] {
    array.sort((a: any, b: any) => {
      if (!a.className.localeCompare(b.className)) {
        return a.batchName.localeCompare(b.batchName);
      }
      return a.className.localeCompare(b.className);
    });
    return array;
  }

}
