import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';
import { promise } from 'selenium-webdriver';

@Injectable({
    providedIn: 'root'
})
export class InstallmentWiseSummaryService {

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    constructor(private httpClient: HttpClient) { }


    //   Getinstallment_wise_summary(ispaid, FromDate,Todate): Observable<any> {
    //     return this.httpClient.get<any>(environment.apiUrl + '/FeeReport/Getinstallment_wise_summary?ispaid='+ispaid+'&FromDate='+FromDate+'&Todate='+Todate)
    //       .pipe(
    //         catchError(this.errorHandler)
    //       );

    //     }

    public async Getinstallment_wise_summary(ispaid, fromDate, todate): Promise<any> {
        return this.httpClient.get<any>(environment.apiUrl + '/FeeReport/Getinstallment_wise_summary?ispaid=' + ispaid + '&FromDate=' + fromDate + '&Todate=' + todate).toPromise();
    }


    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }


}
