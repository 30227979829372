import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Credit, CreditTransaction } from './credit.model';
import { CreditService } from './credit.service';
import { Service, ServiceAvailableQuantity } from './service.model';
import { AdddonServiceService } from './service.service';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from 'ng-apexcharts';
import { ServiceAvailabilityStatus } from '../broadcast/broadcast.model';
import * as $ from 'jquery';
declare var Razorpay: any;

export interface ChartOptions {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  legend: any;
  responsive: ApexResponsive[];
  labels: any;
}

@Component({
  selector: 'app-creditmanager',
  templateUrl: './creditmanager.component.html',
  styleUrls: ['./creditmanager.component.css']
})
export class CreditmanagerComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  creditForm: UntypedFormGroup;
  credits: Credit[] = [];
  creditFilteredList: Credit[] = [];
  services: Service[] = [];
  submitted = false;
  channeltypes = '';
  rate = 0;
  creditseries: number[] = [];
  creditlabels: string[] = [];
  serviceCredit: Credit[] = [];
  totalAmount = 0;
  taxAmount = 0;
  netTotal = 0;
  serviceId = 0;
  code = '';
  name = '';
  keyId = '';
  serviceAvailableQuantity: ServiceAvailableQuantity[] = [];

  constructor(
    public creditService: CreditService,
    public service: AdddonServiceService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) {
    this.chartOptions = {
      series: this.creditseries,
      chart: {
        width: 480,
        type: 'pie'
      },
      legend: {
        position: 'bottom'
      },
      labels: this.creditlabels,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    };
   }


   SaveServiceCredits(){
    
    this.service.saveServiceCredit(this.credits).subscribe((res) => {
      console.log('CreditService!');
      this.resetAll();
      this.GetServiecList();
      this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
        timeOut: 3000
      });
   });
  }

   openRazorpayCheckout() {
    const institution: any =  JSON.parse(localStorage.getItem('Institution'));
    //const institution: any =  JSON.parse(sessionStorage.getItem('Institution'));
    this.keyId = institution.keyId;

    const options = {
      key: this.keyId,
      amount: (Math.round(this.netTotal * 2) / 2)  * 100,
      name: 'Edumaq',
      description: 'Credit Purchase',
      // prefill: {
      //   name: 'Abdhal',
      //   email: 'abdhal098@gmail.com'
      // },
      // notes: {
      //   address: 'Hello World'
      // },
      // theme: {
      //   color: 'green'
      // },
      handler: this.paymentResponseHander
    };

    const rzp = new Razorpay(options);
    rzp.open();
  }
  paymentResponseHander(response) {
    console.log(response);
    console.log(response.razorpay_payment_id);
    if (response.razorpay_payment_id !== undefined || response.razorpay_payment_id !== '') {
      this.submitForm();
    }
   }

  ListCredit() {
    this.creditseries = [];
    this.creditlabels = [];
    this.creditService.getAll().subscribe((data: Credit[]) => {
       data.forEach((credit) => {
          this.creditseries.push(Number(credit.quantity));
          this.creditlabels.push(credit.serviceName.toString());
       });
      });
  }

  GetServiecList() {
    this.creditseries = [];
    this.creditlabels = [];
    this.service.getAvailableServiceQuantity().subscribe((data: ServiceAvailableQuantity[]) => {
       this.serviceAvailableQuantity = data;
       console.log(data);
       data.forEach((credit) => {
          this.creditseries.push(Number(credit.availableQuantity));
          this.creditlabels.push(credit.serviceName.toString());
       });
       this.chartOptions = {
        series: this.creditseries,
        chart: {
          width: 480,
          type: 'pie'
        },
        legend: {
          position: 'bottom'
        },
        labels: this.creditlabels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      };

    });
  }

  ListService() {
    this.service.getAll().subscribe((data: Service[]) => {
      console.log(data);
      this.services = data;
    });
  }

  onServiceChange(id: number) {
    
    this.service.getById(id).subscribe((data: Service) => {
      //console.log(data);
      this.rate = parseFloat(data.rate);
      this.creditForm.patchValue({rate: data.rate});
      this.creditForm.patchValue({serviceId: id});
      this.code = data.code;
      this.name = data.name;
      this.serviceId = id;
    });
  }

  onkeyupChange(event: any) {
    const qty = event.target.value;
    const total = parseFloat(qty) * this.rate;
    this.creditForm.patchValue({total: total.toString() });
  }

  /*MIHIR LAD  */
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  ConvertRoundOff(num,places){
// const roundTo=function(num:number,places:number)
// {
  const factors=10**places;
  return Math.round(num*factors)/factors;
// };
}

  addCredit() {
    
     const credit: Credit = {} as Credit;
     credit.serviceId = this.serviceId.toString();
     credit.serviceCode = this.code;
     credit.serviceName = this.name;
     credit.quantity = this.creditForm.get('quantity').value;
     credit.rate  = this.creditForm.get('rate').value;
     credit.total  = this.creditForm.get('total').value;
     credit.status = this.creditForm.get('status').value;

     if (credit.quantity === '' || credit.quantity === '0' ) {
      this.toastr.warning('Please enter quantity', 'WARNING!', {
        timeOut: 3000
      });
      return;
     }

     let isServiceNameExist = false;
     this.credits.forEach((x) => {
      if (x.serviceName === this.name) {
         isServiceNameExist = true;
      }
    });

     if (isServiceNameExist) {
      this.toastr.warning('This service already exists', 'WARNING!', {
        timeOut: 3000
      });
      return;
     }

     this.credits.push(credit);
     this.totalAmount += this.ConvertRoundOff(Number(credit.total),2);
     this.taxAmount = this.ConvertRoundOff(this.totalAmount * (18 / 100),2);
     this.netTotal = this.ConvertRoundOff(this.totalAmount +  this.taxAmount,2);
     this.resetForm();
  }

  resetAll() {
    this.resetForm();
    this.credits = [];
    this.totalAmount = 0;
    this.taxAmount = 0;
    this.netTotal = 0;
  }
 
  removeCredit(value: any) {
    const tempCredit = this.credits[value];
    this.credits.splice(value, 1);
    this.totalAmount -= Number(tempCredit.total);
    this.taxAmount = 0;
    this.netTotal = this.totalAmount +  this.taxAmount;
  }

  resetForm() {
    this.submitted = false;
    this.creditForm = this.fb.group({
      id: [0],
      serviceId: ['', Validators.required],
      quantity: ['', Validators.required],
      rate: ['', Validators.required],
      total: ['', Validators.required],
      status: ['true', Validators.required]
    });
    this.serviceId = 0;
    this.code = '';
    this.name = '';
  }

  submitForm() {
    this.submitted = true;

        // stop here if form is invalid
    if (this.credits.length === 0) {
      this.toastr.warning('Please add services', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }

    const creditTransaction: CreditTransaction = {} as CreditTransaction;

    creditTransaction.subTotal = this.totalAmount;
    creditTransaction.taxtPercentage = 18;
    creditTransaction.taxAmount = this.taxAmount;
    creditTransaction.total = this.netTotal;
    creditTransaction.credits = this.credits;

    if (this.creditForm.get('id').value === 0) {
        this.creditService.purchaseCredit(creditTransaction).subscribe(res => {
          console.log('Credit created!');
          this.ListCredit();
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          // this.resetForm();
          this.credits = [];
          this.resetAll();
        });
        } else {
          this.creditService.update(this.creditForm.get('id').value, this.creditForm.value).subscribe(res => {
            console.log('Credit updated!');
            this.ListCredit();
            this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submitted = false;
            this.resetAll();
          });
        }
      }

      updateForm(id: number) {
        this.creditService.getById(id).subscribe((data: Credit) => {
          console.log(data);

          this.creditForm = this.fb.group({
            id: [data.id],
            serviceId: [data.serviceId, Validators.required],
            quantity: [data.quantity, Validators.required],
            rate: [data.rate, Validators.required],
            total: [data.total, Validators.required],
            status: ['true', Validators.required]
          });
        });
      }

      deleteForm(id: number) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            this.creditService.delete(id).subscribe(res => {
              this.ListCredit();
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              );
            });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
          }
        });
      }


  ngOnInit(): void {
    this.GetServiecList();
    this.ListService();
    this.creditForm = this.fb.group({
      id: [0],
      serviceId: ['', Validators.required],
      quantity: ['', Validators.required],
      rate: ['', Validators.required],
      total: ['', Validators.required],
      status: ['true', Validators.required]
    });
  }
}
