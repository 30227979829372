import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StudentService} from './../student.service';
import { Student, StudentSibling, StudentDocument } from './../student.model';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { SubjectcreationService } from '../../academicsettings/subjectcreation/subjectcreation.service';
import { SubjectCreation } from '../../academicsettings/subjectcreation/subjectcreation.model';
import { assignsubjectservice } from '../../academicsettings/assignsubject/assignsubject.service';
import { assignsubjet } from '../../academicsettings/assignsubject/assignsubject.model';

import {AdditionalSubjectService} from './additionalsubject.service';
import {AdditionalSubject} from './additionalsubject.model';
import { SettingsService } from '../../mastersettings/settings.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { ConsolidatedreportComponent } from '../../feemanagement/feemanagementreports/consolidatedreport/consolidatedreport.component';

@Component({
  selector: 'app-asignadditionalsubject',
  templateUrl: './asignadditionalsubject.component.html',
  styleUrls: ['./asignadditionalsubject.component.css']
})
export class AsignadditionalsubjectComponent implements OnInit {

  admissions: Student[] = [];
  classcourses: ClassCourse[] = [];
  batches: Batch[] = [];
  admissionsfilteredlist: Student[] = [];
  subjects: SubjectCreation[] = [];
  assignedSubjects: AdditionalSubject[] = [];
  assignsubjectList: assignsubjet[] = [];
  currentAcademicYearId: number;
  individualStudent = true;
  classId = 0;
  admissionId = 0;
  isLoading: boolean=false;

  constructor(
    public settingService: SettingsService,
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    public subjectService: SubjectcreationService,
    public addSubjectService: AdditionalSubjectService,
    private subjectAssignservice: assignsubjectservice,
    private toastr: ToastrService
  ) { }

  currentAcademicYear() {
    this.settingService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.ListAdmissions();
      // this.search();
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  ListAdmissions() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.admissions = data.filter((student: Student) => {
        return student.academicYearId.toString() === this.currentAcademicYearId.toString();
        });
    });
  }

  ListAssignSubjects() {
    this.subjectAssignservice.getAll().subscribe((data: assignsubjet[]) => {
      console.log(data);
      this.assignsubjectList = data;
    });
  }

  onClassSelected(value: string) {
    this.classId = parseInt(value, 0);
    const assignSubList = this.assignsubjectList.filter((x) => x.classCourseId.toString() === this.classId.toString());
    const assignSubIds: number[] = [];
    assignSubList.forEach((m) => {
      assignSubIds.push(parseInt(m.subjectId, 0));
    });
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString().toLocaleLowerCase() === value.toLocaleLowerCase());
    });

    if (this.individualStudent === false) {
      this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
        data = data.filter((item) => {
          return !assignSubIds.includes(item.id);
        });
        console.log(data);
        this.subjects = data;
      });
    }
  }

  onBatchSelected(value: string) {
    this.admissionsfilteredlist=[];
    console.log(value);
    // if (this.individualStudent === false) {
    if (value === '-1') { return; }
    if (value === '0') {
      // this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
        // console.log(data);
        this.admissionsfilteredlist = this.admissions; // = data;
      // });
    } else {
      // this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
        this.admissionsfilteredlist = this.admissions.filter((admission: Student) =>
        admission.classCourseId.toString() === this.classId.toString() &&
        admission.batchId.toString() === value.toString());
      // });
    }
    // }
  }

  resetForm() {
    this.admissions = [];
    this.subjects = [];
  }

  onStudentChange(value: any) {
    this.isLoading=true;
    debugger;
    this.admissionId = parseInt(value.id, 0);
    const assignSubList = this.assignsubjectList.filter((x) => x.classCourseId.toString() === this.classId.toString());
    const assignSubIds: number[] = [];
    assignSubList.forEach((m) => {
      assignSubIds.push(parseInt(m.subjectId, 0));
    });
    this.subjectService.getAll().subscribe((sdata: SubjectCreation[]) => {
      sdata = sdata.filter((item) => {
        return !assignSubIds.includes(item.id);
      });
      this.subjects = sdata;
      // this.subjects = sdata;
      this.addSubjectService.getAll().subscribe((data: AdditionalSubject[]) => {
         this.subjects.forEach((x) => {
            data.forEach((y) => {
              if (x.id === y.subjectId && this.admissionId === y.admissionId) {
                x.checked = true;
              }
            });
         });
         this.isLoading=false;
      });
    });

  }

  onIndividualChange(value: string) {
    if (value === 'Individual') {
        this.individualStudent = true;
        this.classId = 0;
        this.batches = [];
    } else {
        this.individualStudent = false;
        this.classId = 0;
        this.batches = [];
        this.admissionsfilteredlist = [];
    }
  }

  checkAllCheckBox(ev) {
    this.subjects.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.subjects.every(p => p.checked);
  }

  checkAllStudentCheckBox(ev) {
    this.admissions.forEach(x => x.checked = ev.target.checked);
  }

  isAllStudentCheckBoxChecked() {
    return this.admissions.every(p => p.checked);
  }

  get totalRows(): number {
    return this.subjects.length;
  }

  saveData() {
    const subjectList: AdditionalSubject[] = [];
    this.isLoading=true;
    if (this.individualStudent === false) {

      this.admissions.forEach((y) => {
        if (y.checked) {
          this.subjects.forEach((x) => {
            if (x.checked) {
              const addSubject: AdditionalSubject = {} as AdditionalSubject;
              addSubject.admissionId = y.id;
              addSubject.classId = this.classId;
              addSubject.subjectId = x.id;
              subjectList.push(addSubject);
            }
          });
        }
      });
    } else {
        this.subjects.forEach((x) => {
          if (x.checked) {
            const addSubject: AdditionalSubject = {} as AdditionalSubject;
            addSubject.admissionId = this.admissionId;
            addSubject.classId = this.classId;
            addSubject.subjectId = x.id;
            subjectList.push(addSubject);
          }
        });
    }

    this.addSubjectService.create(subjectList).subscribe((res) => {
      this.toastr.success('Additional subjects sucessfully assigned', 'SUCCESS!', {
        timeOut: 3000
      });
      this.isLoading=false;
    });

  }

  ngOnInit(): void {
    this.ListAssignSubjects();
    this.ListClassCourse();
    this.currentAcademicYear();
    // this.ListAdmissions();
  }

}
