import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { InstitutionLicense } from '../../institutionmanagement/models/institution.model';
import { Institution } from '../models/institution.model';
import { InstitutionService } from '../../institutionmanagement/newinstitution/institution.service';

import { Ticket, TicketPriority, TicketType } from '../models/ticket.model';
import { TicketService } from './ticket-manager.service';

import { Employee } from '../../configuration/usermanage/user.model';
import { UserService } from '../../configuration/usermanage/user.service';



@Component({
  selector: 'app-ticket-manager',
  templateUrl: './ticket-manager.component.html',
  styleUrls: ['./ticket-manager.component.css']
})
export class TicketManagerComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload: ElementRef;

  ticketList: Ticket[] = [];
  ticketListFiltered: Ticket[] = [];
  ticketTypes: TicketType[] = [];
  ticketPriority: TicketPriority[] = [];
  employees: Employee[] = [];
  filteredEmployees: Employee[] = [];
  institutions: Institution[] = [];
  ticketForm: UntypedFormGroup;
  institution: Institution;
  isUpdate = false;
  showEmployeeTable = false;
  selectedEmployees = '';
  submitted = false;
  ticketStatus = '';
  ticketSearch = '';
  ticketId = '';
  customId:number = 0;

  constructor(
    private institutionService: InstitutionService,
    private userService: UserService,
    private ticketService: TicketService,
    private fb: UntypedFormBuilder,
    private toster: ToastrService) { }

    get f() { return this.ticketForm.controls; }

    getAllInstitutions() {
      this.institutionService.getAll().subscribe((data: Institution[]) => {
        this.institutions = data;
      });
    }


  getAllEmployees() {
    this.userService.getEmployeesWithTicketCount().subscribe((data: Employee[]) => {
      this.employees = data;
    });
  }

  onDepartmentChange(value: string) {
    this.showEmployeeTable = true;
    this.filteredEmployees = this.employees;
    this.filteredEmployees = this.filteredEmployees.filter((employee) => {
      return employee.departmentName === value;
    });
  }

  onAgentTypeChange(value: string) {
    if (value === 'All Agent Type') {
      this.filteredEmployees = this.employees;
    } else {
      this.filteredEmployees = this.filteredEmployees.filter((employee) => {
        return employee.agentType === value;
      });
    }
  }

  getAllTickets() {
    this.ticketService.getAllTickets().subscribe((data: Ticket[]) => {
      console.log(data)
      this.ticketList = this.ticketListFiltered = data;
    });
  }
  getAllTicketTypes() {
    this.ticketService.getAllTicketTypes().subscribe((data: TicketType[]) => {
      this.ticketTypes = data;
    });
  }
  getAllTicketPriority() {
    this.ticketService.getAllTicketPriority().subscribe((data: TicketPriority[]) => {
      this.ticketPriority = data;
    });
  }

  searchInstitution(InstitutionId: string) {
    this.ticketService.getInstitutionDetailsByCode(InstitutionId).subscribe((data: Institution) => {
      if (data !== null) {
        this.institution = data;
      } else {
        this.toster.warning('No Institution Found', 'Warning!!', {
          timeOut: 3000
        });
        this.institution = {} as Institution;
      }
    });
  }

  getInstitution(InstitutionId: string) {
    this.ticketService.getInstitutionDetailsById(InstitutionId).subscribe((data: Institution) => {
      if (data !== null) {
        this.institution = data;
      } else {
        this.toster.warning('No Institution Found', 'Warning!!', {
          timeOut: 3000
        });
      }
    });
  }

  searchTicketById(TicketId: any) {
    this.ticketListFiltered = this.ticketList.filter(element => element.customId == TicketId);
    if (this.ticketListFiltered.length === 0) {
      this.toster.warning('No Ticket Found!', 'Warning', {
        timeOut: 3000
      });
      this.ticketListFiltered = this.ticketList;
    }
  }

  filterTicketsByStatus(ticketStatus: string) {
    if (ticketStatus === '') {
      this.ticketListFiltered = this.ticketList;
    } else {
      this.ticketListFiltered = this.ticketList.filter(element => element.ticketStatus === ticketStatus);
      if (this.ticketListFiltered.length === 0) {
        this.toster.warning('No Ticket Found!', 'Warning', {
          timeOut: 3000
        });
        // this.ticketListFiltered = this.ticketList;
      }

    }
  }
  onKeyUpEvent(event: any) {
    var keyValue = event.target.value;
    if(event.target.value === ""){
      this.ticketListFiltered = this.ticketList;
    }else{
      this.ticketListFiltered = this.ticketList.filter(element => 
        element.customId == keyValue || 
        element.createdDate == keyValue ||
        element.institutionCode == keyValue ||
        element.institutionName == keyValue ||
        element.location == keyValue ||
        element.affiliationNumber == keyValue ||
        element.ticketType == keyValue ||
        element.priority == keyValue ||
        element.requestedBy == keyValue ||
        element.contactNumber == keyValue);
      if (this.ticketListFiltered.length === 0) {
        this.ticketListFiltered.length = 0;
        // this.toster.warning('No Ticket Found!', 'Warning', {
        //   timeOut: 3000
        // });
      }
    }
  }

  filter(keyword: any) {

    if (keyword === '') {
      this.ticketListFiltered = this.ticketList;
    } else {
      this.ticketListFiltered = this.ticketList
        .filter((ticket: Ticket) =>
          ticket.institutionName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
          || ticket.requestedBy.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
          || ticket.contactNumber.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
          || ticket.description.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
          || ticket.affiliationNumber.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
        );
    }

  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    } else {
      return false;
    }
  }

  onFileChange(event: any) {
    // const usersArray = this.admissionForm.controls.documentList as FormArray;
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const file = target.files[0];
      this.ticketForm.get('AttachmentName').patchValue(file.name);
      this.ticketForm.get('AttachmentExtension').patchValue(file.name.substr(file.name.lastIndexOf('.') + 1));
      let result = '';
      const reader = new FileReader();
      reader.onload = (e => {
        result = reader.result.toString();
        this.ticketForm.get('contentBase').patchValue(result);
        // this.admissionForm.get('studentImage').patchValue(this.imageSrc);
      });

      reader.readAsDataURL(file);
    }
  }
  updateTicket(ticketId: any) {
    let ticket: Ticket;
    this.ticketService.getTicketDetailsById(ticketId).subscribe((data: Ticket) => {
      console.log(data);
      ticket = data;
      // this.fileUpload.nativeElement.value=ticket.attachmentName;
      this.ticketForm = this.fb.group({
        Id: [ticket.id],
        InstitutionId: [ticket.institutionId, Validators.required],
        TicketType: [ticket.ticketType, Validators.required],
        Priority: [ticket.priority, Validators.required],
        RequestedBy: [ticket.requestedBy],
        ContactNumber: [ticket.contactNumber, Validators.required],
        Description: [ticket.description, Validators.required],
        EmployeeIds: [ticket.employeeIds],
        AttachmentName: [ticket.attachmentName],
        AttachmentExtension: [ticket.attachmentExtension],
        contentBase: [ticket.contentBase],
        TicketStatus: [ticket.ticketStatus]
      });
      this.getInstitution(ticket.institutionId);
      this.isUpdate = true;
    });

  }
  reset() {
    this.fileUpload.nativeElement.value = null;
    this.resetForm();
  }

  submitForm() {
    this.submitted = true;

    this.selectedEmployees = '';
    let k = 1;
    const totalSelected = this.filteredEmployees.filter(x => x.checked).length;
    this.filteredEmployees.forEach((x, i) => {
      if (x.checked) {
        if (totalSelected !== k) {
          this.selectedEmployees += x.id.toString() + '|';
        } else {
          this.selectedEmployees += x.id.toString();
        }
        k++;
      }
    });

    this.ticketForm.get('EmployeeIds').patchValue(this.selectedEmployees);
    this.ticketForm.get('InstitutionId').patchValue(this.institution.id);
    if (this.ticketForm.get('InstitutionId').value === undefined || this.ticketForm.get('InstitutionId').value === '' ) {
      this.toster.warning('Please select Institution!', 'Warning!!', {
        timeOut: 3000
      });
      return;
    }

    if (this.ticketForm.invalid) {
      this.toster.warning('The form is not valid!', 'Warning!!', {
        timeOut: 3000
      });
    } else {
      if (this.ticketForm.get('Id').value === 0) {
        this.ticketService.create(this.ticketForm.value).subscribe(result => {
          this.toster.success('The Ticket has been created successfully!', 'Success', {
            timeOut: 3000
          });
          this.getAllTickets();
          this.submitted =  false;
          this.resetForm();
          this.fileUpload.nativeElement.value = null;
        });
      } else {
        this.ticketService.update(this.ticketForm.get('Id').value, this.ticketForm.value).subscribe(res => {
          this.toster.success('Ticket has been updated sucessfully', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
          this.fileUpload.nativeElement.value = null;
        });
      }
    }
  }
  resetForm() {
    this.submitted = false;
    this.institution = {} as Institution;
    this.isUpdate = false;
    this.filteredEmployees = [];

    this.setForm();
    this.getAllEmployees();
    this.getAllInstitutions();
    this.getAllTickets();
    this.getAllTicketTypes();
    this.getAllTicketPriority();
  }

  resetTable() {
    this.ticketStatus = '';
    this.ticketSearch = '';
    this.ticketId = '';
    this.getAllTickets();
  }

  ngOnInit(): void {

    this.resetForm();
    const self = this;
    $('.select2').select2();
    $('.select2').on('change', function() {
      self.getInstitution(String($(this).val()));
    });

  }

  setForm() {
    this.ticketForm = this.fb.group({
      Id: [0],
      InstitutionId: ['', Validators.required],
      TicketType: ['', Validators.required],
      Priority: ['', Validators.required],
      RequestedBy: [''],
      ContactNumber: ['', Validators.required],
      Description: ['', Validators.required],
      EmployeeIds: [''],
      AttachmentName: [''],
      AttachmentExtension: [''],
      contentBase: [''],
      TicketStatus: ['Pending']
    });
  }


}
