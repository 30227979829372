import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InstitutionComponent } from './institution/institution.component';
import { InstitutionGuard } from '../authentication/guards/institution.guard';

const routes: Routes = [
  {
    path: ':instcode',
    component: InstitutionComponent,
    canActivate: [InstitutionGuard],
    canLoad: [InstitutionGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class InstitutionRoutingModule { }





