import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConcessionTemplate, ConcessionTemplateDetail } from './concessiontemplate.model';
import { ConcessionTemplateService } from './concessiontemplate.service';
import { FeeHead } from '../feehead/feehead.model';
import { FeeHeadService } from '../feehead/feehead.service';

@Component({
  selector: 'app-concessiontemplate',
  templateUrl: './concessiontemplate.component.html',
  styleUrls: ['./concessiontemplate.component.css']
})
export class ConcessiontemplateComponent implements OnInit {

  concessionTemplateForm: UntypedFormGroup;
  concessionTemplates: ConcessionTemplate[] = [];
  filteredConcessionTemplates: ConcessionTemplate[] = [];
  feeHeadList: FeeHead[] = [];
  concessionTemplate: ConcessionTemplate;
  submitted = false;
  concessionId = 0;
  concessionName = '';
  description = '';
  status = 'true';
  isDiscountPercentage = false;
  concessionAmountHeading = 'Amount';
  HighlightRow : Number;
  ClickedRow:any;

  constructor(
    public concessionService: ConcessionTemplateService,
    public feeHeadService: FeeHeadService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) 

{ 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }

  }


  ListFeeHead() {
    this.feeHeadList = [];
    this.feeHeadService.getAll().subscribe((data: FeeHead[]) => {
      this.feeHeadList = data;
    });
  }

  ListConcession() {
    this.concessionTemplates = [];
    this.concessionService.getAll().subscribe((data: ConcessionTemplate[]) => {
      console.log(data);
      this.filteredConcessionTemplates = this.concessionTemplates = data;
    });
  }


  onIsDiscountPercentageChange(evnt: any) {
    console.log(evnt.target.checked);
    const checkState = evnt.target.checked;
    this.isDiscountPercentage = checkState;
    if (checkState) {
      this.concessionAmountHeading = 'Amount(%)';
    } else {
      this.concessionAmountHeading = 'Amount';
    }
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
      if (keyword === '') {
        this.filteredConcessionTemplates = this.concessionTemplates;
      } else {
        this.filteredConcessionTemplates = this.concessionTemplates
          .filter((ctemplate: ConcessionTemplate) => ctemplate.concessionName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase())
          );
      }
  }


  get totalRows(): number {
    return this.concessionTemplates.length;
  }

  resetForm() {
    this.concessionId = 0;
    this.concessionName = '';
    this.description = '';
    this.status = 'true';
    this.isDiscountPercentage = false;
    this.concessionAmountHeading = 'Amount';
    this.feeHeadList.forEach((x) => {x.concessionAmount = 0;});
  }

  submitForm() {

    if (this.concessionName === '') {
      this.toastr.warning('Plese enter concession name', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }

    const concessionTemplate = {} as ConcessionTemplate;
    concessionTemplate.concessionTemplateDetails = [];

    concessionTemplate.id = this.concessionId;
    concessionTemplate.concessionName = this.concessionName;
    concessionTemplate.description = this.description;
    concessionTemplate.status = this.status;
    concessionTemplate.isDiscountInPercentage = this.isDiscountPercentage.toString();

    this.feeHeadList.forEach((x) => {
       const concessionDetail = {} as ConcessionTemplateDetail;
       concessionDetail.amount = x.concessionAmount;
       concessionDetail.feeHeadId = x.id;
       concessionTemplate.concessionTemplateDetails.push(concessionDetail);
    });

    if (concessionTemplate.concessionTemplateDetails.length === 0) {
      this.toastr.warning('Plese select feehead', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }

    if (this.concessionId === 0) {
      this.concessionService.create(concessionTemplate).subscribe(res => {
        console.log('Concession created!');
        this.ListConcession();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    } else {
      this.concessionService.update(concessionTemplate.id, concessionTemplate).subscribe(res => {
        console.log('Concession updated!');
        this.ListConcession();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    }
  }

  updateForm(id: number) {
    this.concessionService.getById(id).subscribe((data: ConcessionTemplate) => {
      console.log(data);
      this.concessionId = data.id;
      this.concessionName = data.concessionName;
      this.description = data.description;
      this.status = data.status;
      this.isDiscountPercentage = Boolean(data.isDiscountInPercentage);
      if (this.isDiscountPercentage) {
        this.concessionAmountHeading = 'Amount(%)';
      } else {
        this.concessionAmountHeading = 'Amount';
      }
      this.feeHeadList.forEach((x) => {
        data.concessionTemplateDetails.forEach((y) => {
            if (x.id === y.feeHeadId) {
              x.concessionAmount = y.amount;
            }
        });
      });
    });

  }

  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.concessionService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListConcession();
        });
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      );
      }
    });
  }


  ngOnInit(): void {
    this.ListFeeHead();
    this.ListConcession();
  }

}
