import { Component, HostListener,ViewChild,ElementRef } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
//import { AuthenticationService } from '../../../../../../authentication/authentication.service';
import { AuthenticationService } from './authentication/authentication.service';
import {AdminComponent} from './admin/admin/admin.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  
})


export class AppComponent {
  title = 'edumaq';
  sessionExpiryTime: number;
  idleState = 'Not started.';
  timedOut = false;
  tokenExpirySecond: number = 0;
  @ViewChild('OpenPopow', { static: false }) OpenPopow: ElementRef;
  //@ViewChild('AdminComponent', { static: false }) adminComp: AdminComponent;

  //@ViewChild(AdminComponent) adminComp:AdminComponent;
  
  @HostListener('window:onbeforeunload', ['$event'])
    clearLocalStorage(event) {
        localStorage.clear();
    }
    constructor(
      router:Router
    

    ){
      //this.getSessionExpiryTime();
      //this.idleTimeSetting();
      // this.idle.watch(); 
    }
  
}
