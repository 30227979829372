import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Student } from './../../studentmanagement/student.model';
import { StudentService } from './../../studentmanagement/student.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { SettingsService } from '../../mastersettings/settings.service';
import { environment } from '../../../../../environments/environment';
import { ReportAttribute } from '../../studentmanagement/report.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
/// import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable.js';
//import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { fontStyle } from 'html2canvas/dist/types/css/property-descriptors/font-style';
import { TitleCasePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-studentsummary',
  templateUrl: './studentsummary.component.html',
  styleUrls: ['./studentsummary.component.css']
})
export class StudentsummaryComponent implements OnInit {


  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  filteredAdmissions: Student[] = [];
  selectedFilteredAdmissions: Student[] = [];
  academicyears: Academicyear[] = [];
  academicYearId: number;
  classId = '0';
  batchId = '0';
  status = 'true';
  studentType='Day Scholar';
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  institution: any;
  isLoading :boolean =false;
  academicYearName:string;

  constructor(
    public studentService: StudentService,
    public settingService: SettingsService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    private toastr: ToastrService,
    private titlecasePipe:TitleCasePipe
  ) { }



  ListStudents(academicYearId,classId,batchId,status,studentType) {
     this.isLoading=true;
    let studentStatus=-1;
    if(status=='true')
    {
      studentStatus=1;
    }else if(status=='false')
    {
      studentStatus=0;
    }
    // this.isLoading=!this.isLoading;
   
    this.studentService.getStudentByAcademicYearIdWithType(academicYearId,classId,batchId,studentStatus,studentType).subscribe((data: Student[]) => {
       //console.log(data);
       data.forEach((x) => {
        x.imageUrl = x.imageUrl != null ? environment.imageUrl + x.imageUrl : null;
       });
       this.admissions = data;
       this.filteredAdmissions = this.admissions ;
      this.isLoading=false;
    });
    // this.isLoading=!this.isLoading;
    
  }


  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }

  ListAcademicYear() {
    this.settingService.getAll().subscribe((data: Academicyear[]) => {
      this.academicyears = data;
      this.currentAcademicYear();
      
    });
  }

  currentAcademicYear() {
    this.settingService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.academicYearId = data.id;
      this.academicYearName=data.name;
     
      // this.search();
    });
  }

  onAcademicYearSelected(value: string)
  {
     this.academicYearId=Number(value);
     
     console.log(this.academicYearId);
     console.log(this.admissions);
  }

  onClassSelected(value: string) {
    this.classId = value;
   
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
    });
  }

  // onBatchChanged(value: string) {
  //   if (value === '0') {
  //     this.selectedFilteredAdmissions = this.filteredAdmissions = this.admissions.filter((student: Student) => {
  //       return student.classCourseId.toString() === this.classId &&
  //         student.academicYearId.toString() === this.currentAcademicYearId.toString() ;
  //     });
  //   } else {
  //     this.selectedFilteredAdmissions = this.filteredAdmissions = this.admissions.filter((student: Student) => {
  //       return student.classCourseId.toString() === this.classId &&
  //         student.batchId.toString() === value.toString()  &&
  //         student.academicYearId.toString() === this.currentAcademicYearId.toString() ;
  //     });
  //   }
  //   console.log(this.filteredAdmissions);
  // }

  // onStatusChanged(value: string) {
  //    // this.ListFeeHead();
  //    if (value === '0') {
  //     this.selectedFilteredAdmissions = this.filteredAdmissions = this.admissions.filter((student: Student) => {
  //       return student.classCourseId.toString() === this.classId &&
  //         student.batchId.toString() === this.batchId &&
  //         student.academicYearId.toString() === this.currentAcademicYearId.toString();
  //       });
  //    } else {
  //     this.selectedFilteredAdmissions = this.filteredAdmissions = this.admissions.filter((student: Student) => {
  //     return student.classCourseId.toString() === this.classId &&
  //       student.batchId.toString() === this.batchId &&
  //       student.status.toString() === value.toString() &&
  //       student.academicYearId.toString() === this.currentAcademicYearId.toString();
  //     });
  //   }
  // }

  // onAcademicYearChange(value: string) {
  //   console.log(value);
  //   this.classId = '0';
  //   this.batchId = '0';
  //   if (this.classId === '0' && this.batchId === '0') {
  //     this.filteredAdmissions = this.admissions.filter((student: Student) => {
  //       return student.academicYearId.toString() === this.currentAcademicYearId.toString();
  //     });
  //   } else {
  //     this.filteredAdmissions = this.admissions.filter((student: Student) => {
  //       return student.classCourseId.toString() === this.classId &&
  //       student.batchId.toString() === this.batchId &&
  //       student.academicYearId.toString() === this.currentAcademicYearId.toString();
  //     });
  //   }
  // }

  search() {
   
    //const academicyearId = this.academicYearId.toString();

    this.ListStudents(this.academicYearId,this.classId,this.batchId,this.status,this.studentType);

    

    // console.log(this.admissions);
    // if(this.classId=='0'){
    //   this.batchId='0';
    //   this.status='0';
    // }
    // const classid = this.classId;
    // const batchId = this.batchId;
    // const status = this.status;
    //let sortArray = this.admissions;
    // if (classid != '0' && classid != undefined) {
     
    //   sortArray = sortArray.filter((student: Student) => {
       
    //     return student.classCourseId.toString() === this.classId.toString() ;
    //   });
    // }
    // if (batchId != '0' && batchId != undefined) {
    //   sortArray = sortArray.filter((student: Student) => {
    //     return student.batchId.toString() === this.batchId.toString() ;
    //   });
    // }
    // if (academicyearId != "0" && academicyearId != undefined) {
    //   sortArray = sortArray.filter((student: Student) => {
    //     return student.academicYearId.toString() === academicyearId ;
    //   });
    // }
    // if (status != "0" && status != undefined) {
    //   sortArray = sortArray.filter((student: Student) => {
    //     return student.status.toString() === status ;
    //   });
    // }

    // if (classid !== '0' && classid !== undefined && batchId !== '0' && batchId !== undefined) {
    //   sortArray = sortArray.filter((student: Student) => {
    //     return student.classCourseId.toString() === this.classId.toString() &&
    //      student.batchId.toString() === this.batchId.toString() ;
    //   });
    // }
   
    //this.filteredAdmissions = this.admissions ;
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
   
    if (this.selectedFilteredAdmissions.length === 0) {
      if ( keyword === '') {
        this.filteredAdmissions = this.admissions;
      } else {
      this.filteredAdmissions = this.admissions
                                  .filter((admission: Student) =>
                                  admission.admissionNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.smsMobNumber.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.fatherName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
                                  );
      }
    } else {
      if ( keyword === '') {
        this.filteredAdmissions = this.selectedFilteredAdmissions;
      } else {
      this.filteredAdmissions = this.selectedFilteredAdmissions
                                  .filter((admission: Student) =>
                                  admission.admissionNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.fatherName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
                                  );
      }
    }
  }

  get totalRows(): number {
    return this.filteredAdmissions.length;
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  // getBase64(event): any {
  //   const file = environment.imageUrl + '/images/avatar-1.png';
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     // me.modelvalue = reader.result;
  //     console.log();
  //     return reader.result;
  //   };
  //   reader.onerror = (error) => {
  //     return error.message;
  //     console.log('Error: ', error);
  //   };
  // }

  generateAttributes() {
    this.attributes = [
     /*  {name: 'S NO', fieldName: 'index' },
      {name: 'IMAGE', fieldName: 'imageUrl' },
      {name: 'ACADEMIC', fieldName: 'academicYearName' },
      {name: 'ADMISSION NO', fieldName: 'admissionNo' },
      {name: 'STUDENT NAME', fieldName: 'studentName' },
      {name: 'FATHERS NAME', fieldName: 'fatherName' },
      {name: 'CLASS', fieldName: 'className' },
      {name: 'BATCH', fieldName: 'batchName' },
      {name: 'ROLL NO', fieldName: 'rollNo' },
      {name: 'TYPE', fieldName: 'studentType' },
      {name: 'STATUS', fieldName: 'status' } */
      {name: 'SNO', fieldName: 'index' },
      {name: 'ACADEMIC', fieldName: 'academicYearName' },
      {name: 'ADMS NO', fieldName: 'admissionNo' },
      {name: 'STUDENT NAME', fieldName: 'studentName' },
      {name: 'STUDENT TYPE', fieldName: 'studentType' },
      //{name: 'FATHERS NAME', fieldName: 'fatherName' },
      {name: 'CLASS', fieldName: 'className' },
      {name: 'BATCH', fieldName: 'batchName' },
      {name: 'ROLL', fieldName: 'rollNo' },
      {name: 'TYPE', fieldName: 'previousClassName' }
    ];
  }

  generateExcel() {
    debugger;
    this.headerList =[];

    this.fieldList =[];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('STUDENT SUMMARY');
    worksheet.addRow();
    /* if (this.institution.logoUrl !== null) {
      const imageId2 = workbook.addImage({
        base64: environment.imageUrl + this.institution.logoUrl,
        extension: 'jpg',
      });
      worksheet.addImage(imageId2, 'A1:B1');
    } */

    const titleRow = worksheet.addRow(['STUDENT SUMMARY']);
    const headerRow = worksheet.addRow(this.headerList);
    for(var i = 0 ; i < this.filteredAdmissions.length ; i++){
      this.filteredAdmissions[i]["index"] = i+1;
      this.filteredAdmissions[i]["studentName"] = this.titlecasePipe.transform(this.filteredAdmissions[i]["studentName"]);
      if (this.filteredAdmissions[i]["previousClassName"] !='')
      this.filteredAdmissions[i]["previousClassName"] = "Promoted";
    else{
      this.filteredAdmissions[i]["previousClassName"] = "New";
    }
    }
    debugger;
    for (const x1 of this.filteredAdmissions) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'admissionDate') {
          temp.push(this.formatDate(x1[y]));
        } else {
          temp.push(x1[y]);
        }

        // x2.forEach((e) => {
        //   if (e.toLocaleLowerCase() === y.toLocaleLowerCase()) {
        //   }
        // });
      }
      worksheet.addRow(temp);
    }
    debugger;
    const fname = 'STUDENT SUMMARY';

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }

  generatePDF() {
   
    console.log(this.institution);
   
    this.headerList = [];
    this.fieldList = [];
    const attributes2 = [
      {name: 'SNO', fieldName: 'index' },
      {name: 'ACADEMIC', fieldName: 'academicYearName' },
      {name: 'ADMS NO', fieldName: 'admissionNo' },
      {name: 'STUDENT NAME', fieldName: 'studentName' },
      {name: 'STUDENT TYPE', fieldName: 'studentType' },
      //{name: 'FATHERS NAME', fieldName: 'fatherName' },
      {name: 'CLASS', fieldName: 'className' },
      {name: 'BATCH', fieldName: 'batchName' },
      {name: 'ROLL', fieldName: 'rollNo' },
      {name: 'TYPE', fieldName: 'previousClassName' }
    ];
    attributes2.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    for (let i = 0; i < this.filteredAdmissions.length; i++) {
      this
      this.filteredAdmissions[i]["index"] = i + 1;
      this.filteredAdmissions[i]["studentName"] = this.titlecasePipe.transform(this.filteredAdmissions[i]["studentName"]);
      if (this.filteredAdmissions[i]["previousClassName"] !='')
        this.filteredAdmissions[i]["previousClassName"] = "Promoted";
      else{
        this.filteredAdmissions[i]["previousClassName"] = "New";
      }
    }
    const widthList = [];
    for (const item of this.headerList) {
        widthList.push('auto');
    }
    const docDefinition = {
      content: [
        {
            table: {
                widths: ['auto', '*'],
                fontSize:5,
                body: [
                    [ {
                        width: 100,
                        height: 100,
                        image: 'imageKey'
                    }, {
                        width: '*',
                        alignment: 'center',
                        stack: [
                            {
                                style: 'h1',
                                text: this.institution.institutionName
                            },
                            {
                              style: 'h2',
                              text: this.institution.address
                            },
                            {
                                style: 'h2',
                                text: this.academicYearName
                            },
                            {
                                style: 'h3',
                                text: 'STUDENT SUMMARY'
                            }
                        ]
                    }
                    ]
                ]
            },
            layout: {
               //hLineWidth: (line) => line === 1,
                hLineWidth: (line) => line === 0,
                vLineWidth: () => 0,
                paddingBottom: () => 5
            },
          },
          {
            style:'header',
            table: {
              headerRows: 1,
              widths: widthList,
              
              // pdfFonts.fontSize(5),
              body: [
                
                this.headerList,
                ...this.filteredAdmissions.map((p) => {
                  const temp = [];
                  for (const y of this.fieldList) {
                    if (y === 'admissionDate') {
                      temp.push(this.formatDate(p[y]));
                    } else {
                      temp.push(p[y]);
                    }
                  }
                  return temp;
                })
              ]
            }
          }
      ],
      images: {
         imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
         // via URL address, which can be referenced by this key
      },
      styles: {
        header: {
          // bold: true,
          // decoration: 'underline',
          fontSize: 9,
          margin: [15, 15, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 16,
          // bold: true
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            // bold: true
        }
      }
    };
    pdfMake.createPdf(docDefinition).download('STUDENT SUMMARY.pdf');
  }

  ngOnInit(): void {
    this.generateAttributes();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.ListAcademicYear();
    //this.ListStudents();
    this.ListClassCourse();
  }
}
