import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';

import { Academicyear } from '../../../academic/mastersettings/models/academicyear';
import { ClassCourse } from '../../../academic/mastersettings/models/classcourse';
import { Batch } from '../../../academic/mastersettings/models/batch';
import { certificateType, templateSetting } from '../../../administration/certificate/templatesetting/certificateType.models';
import { StudentCertificate } from '../generatecertificate/generatecertificate.models';


import { BatchService } from '../../../academic/mastersettings/batch/batch.service';
import { ClassCourseService } from '../../../academic/mastersettings/classcourse/classcourse.service';
import { SettingsService } from '../../../academic/mastersettings/settings.service';
import { Student, StudentSibling, StudentDocument } from '../../../academic/studentmanagement/student.model';
import { StudentService } from '../../../academic/studentmanagement/student.service';
import { TemplateSettingsService } from '../../../administration/certificate/templatesetting/template-settings.service';
import { GenerateCertificateService } from '../generatecertificate/generatecertificate.service';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as fs from 'file-saver';
import * as $ from 'jquery';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-certificatereport',
  templateUrl: './certificatereport.component.html',
  styleUrls: ['./certificatereport.component.css']
})
export class CertificatereportComponent implements OnInit {

  academicYears: Academicyear[] = [];
  classcourses: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  sortedAdmissions: any[] = [];
  certificateTypeList:certificateType[];
  templateSettingList:templateSetting[];
  studentCertificateList:StudentCertificate[] = [];
  filtertedStudentCertificateList:StudentCertificate[] = [];
  ctypes:certificateType[];
  studentCertificate: StudentCertificate;
  sortedAdmissionsList: any[] = [];

  classId = '';
  batchId = '';
  certificateTypeId = '';
  templateId = '';
  institution: any;
  academicYearId = '0';
  scIds: string[] = [];
  admissionno = '';
  admissionId = '';

  constructor(
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    private toastr: ToastrService,
    public academicyearService: SettingsService,
    public templateSettingsService: TemplateSettingsService,
    public generateCertificateService: GenerateCertificateService,
    private router: Router
  ) { }

  ListAcademicYear() {
    this.academicyearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
    });
  }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.academicYearId = data.id.toString();
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  onClassSelected(value: string) {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toLocaleLowerCase());
    });
    this.classId = value;
    this.batchId = '';
    this.sortAdmissions();
  }

  onBatchSelected(value: string) {
    this.certificateTypeId = '';
    this.sortAdmissions();
  }

  onCertificateTypeSelected(value: string) {
    this.sortAdmissions();
  }

  getAllBatchDetails() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      console.log(data);
      this.batches = data;
    });
  }

  ListCertificateTypes(){
    this.templateSettingsService.getAll().subscribe((data:certificateType[])=>{
      this.certificateTypeList=data;
      this.ctypes = data.filter((o: any) => o.status === true);
    });
  }

  ListStudents() {
    this.studentService.getAllStudentListForCertificate().subscribe((data: Student[]) => {
      this.admissions = data;
      console.log(data);
      this.sortAdmissions();
    });
  }

  LoadStudents() {
    return this.sortAdmissions();
  }

  sortAdmissions() {
    this.sortedAdmissions = this.admissions;
    if (this.classId != '' && this.batchId == '') {
      this.sortedAdmissions = this.admissions.filter((x) => x.classCourseId.toString() === this.classId.toString());
    }
    if (this.classId != '' && this.batchId != '') {
      this.sortedAdmissions = this.admissions.filter((x) => x.classCourseId.toString() === this.classId.toString() 
      && x.batchId.toString() === this.batchId.toString());
    }

    this.filtertedStudentCertificateList = this.studentCertificateList;
    // const filteredAdmissions = this.sortedAdmissions.filter((x) => {
    //   return this.studentCertificateList.some((y) => {
    //     return y.studentId.toString() == x.id.toString();
    //   });
    // });
    const joinWithAdmissions = this.studentCertificateList.map(leftItem => {
      const rightItem = this.sortedAdmissions.find(right => right.id.toString() == leftItem.studentId.toString());
      //return { ...leftItem, ...rightItem }
      return { ...rightItem, 
        StudentCertificateId: leftItem.id,
        templateId: leftItem.templateId,
        certificateTypeId: leftItem.certificateTypeId,
        generatedDate: leftItem.createdDate,
        generatedBy: leftItem.createdBy
      }
    });
    const joinWithCertificateType = joinWithAdmissions.map(leftItem => {
      const rightItem = this.ctypes.find(right => right.id.toString() == leftItem.certificateTypeId.toString());
      //return { ...leftItem, ...rightItem}
      return { ...leftItem, 
        certificateTypeName: rightItem.certificateTypeName
      }
    });
    this.sortedAdmissions = joinWithCertificateType;
    // this.sortedAdmissions.forEach(item => {
    //   (item as any).generatedDate = item.createdDate;
    //   (item as any).generatedBy = item.createdBy;
    //   (item as any).templateId = item.templateId;
    //   (item as any).StudentCertificateId = item.id;
    // });
    // this.sortedAdmissions.forEach(item => {
    //   const slist =  this.filtertedStudentCertificateList.find(y => y.studentId === item.id);
    //   (item as any).certificateTypeId = slist ? slist.certificateTypeId : '';
    //   (item as any).certificateTypeName = slist ? this.getValueById(slist.certificateTypeId) : '';
    //   (item as any).generatedDate = slist ? slist.createdDate : '';
    //   (item as any).generatedBy = slist ? slist.createdBy : '';
    //   (item as any).templateId = slist ? slist.templateId : '';
    //   (item as any).StudentCertificateId = slist ? slist.id : '';
    // });
    if (this.certificateTypeId != '') {
      this.sortedAdmissions = this.sortedAdmissions.filter((x) => x.certificateTypeId.toString() === this.certificateTypeId.toString());
    }
    if (this.admissionno != '') {
      this.sortedAdmissions = this.sortedAdmissions.filter((x) => x.admissionNo.toString() === this.admissionno.toString());
    }
    this.sortedAdmissionsList = this.sortedAdmissions;
    //return this.sortedAdmissions;
  }

  filterWithAdmissionNo() {
    this.sortAdmissions();
  }

  ListAllCertificates(){
    this.generateCertificateService.getAll().subscribe((data:StudentCertificate[])=>{
      this.studentCertificateList = data;
    });
  }

  ListAllTemplates(){
    this.templateSettingsService.getAllTemplates().subscribe((data:templateSetting[])=>{
      this.templateSettingList=data.filter((o: any) => o.issueFor == 'Student');
    });
  }

  getValueById(id: number) {
    const dataItem = this.ctypes.find(item => item.id === id);
    return dataItem ? dataItem.certificateTypeName : '';
  }

  ngOnInit(): void {
    this.ListClassCourse();
    this.ListAcademicYear();
    this.currentAcademicYear();
    this.ListCertificateTypes();
    this.ListAllCertificates();
    this.ListAllTemplates();
    this.ListStudents();
    //this.sortAdmissions();
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.sortedAdmissions = this.sortedAdmissionsList;
    } else {
      this.sortedAdmissions = this.sortedAdmissionsList.filter((record) => {
        return record.studentName.toLowerCase().includes(keyword.toLowerCase()) ||
          record.admissionNo.toString().toLowerCase().includes(keyword.toLowerCase()) ||
          record.fatherName.toString().toLowerCase().includes(keyword.toLowerCase()) ||
          record.smsMobNumber.toString().toLowerCase().includes(keyword.toLowerCase()) ||
          record.className.toString().toLowerCase().includes(keyword.toLowerCase()) ||
          record.batchName.toString().toLowerCase().includes(keyword.toLowerCase());
      });
    }
  }

  onCheckCertificate(evnt: any, id: any) {
    if (evnt.target.checked) { // on checked
      this.scIds = [];
      // const religionIds: any[] = [];
      this.sortedAdmissions.forEach(x => {
        if (x.checked) {
          this.scIds.push(x.StudentCertificateId);
        }
      });
    }
  }

  selectAllCertificate(evnt: any) {
    if (evnt.target.checked) {
      this.sortedAdmissions.forEach(x => {
        x.checked = true;
        this.scIds.push(x.StudentCertificateId);
      });
    } else {
      this.sortedAdmissions.forEach(x => x.checked = false);
      this.scIds = [];
    }
  }

  generatePDF() {
    const studentCertificateIds: string[] = [];
    this.sortedAdmissions.forEach(x => {
      if (x.checked) {
        studentCertificateIds.push(x.StudentCertificateId);
      }
    });
    //const targetParams = { values: this.stringifyArray(studentCertificateIds)};
    //this.router.navigate(['../../certificate/printcertificate', '-1'], { queryParams: targetParams });
    // const certificateIds: any[] = [];
    // this.sortedAdmissions.forEach(x => {
    //   if (x.checked) {
    //     certificateIds.push({tempId: x.templateId, StudentId: x.id});
    //   }
    // });
    // for (const item of certificateIds) {
    //   this.generate(item);
    // }
  }

  stringifyArray(array: string[]): string {
    return JSON.stringify(array);
  }

  generate(item: any) {
    //const templateRecord=this.templateSettingList.filter((o: any) => o.id.toString() === tempId.toString());
    const templateRecord=this.templateSettingList.find(o => o.id.toString() === item.tempId.toString());
    const templateText = templateRecord.templateText;
    const pdf = new jspdf('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const div = document.createElement('div');
    div.innerHTML = templateText;
    const clonedDiv = div.cloneNode(true) as HTMLDivElement;
    //document.body.appendChild(div);
    html2canvas(div).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(item.StudentId + '.pdf');
    })
  }

}
