import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EmployeereportComponent } from './employeereport/employeereport.component';
import { SiblingreportComponent } from './siblingreport/siblingreport.component';
import { StudentreportComponent } from './studentreport/studentreport.component';
import { StudentreligionreportComponent } from './studentreligionreport/studentreligionreport.component';

const routes: Routes = [
  {path: 'employeereport', component: EmployeereportComponent},
  {path: 'studentreport', component: StudentreportComponent},
  {path: 'siblingreport', component: SiblingreportComponent},
  {path: 'studentreligionreport', component: StudentreligionreportComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DownloadsRoutingModule { }
