// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-error {
    color: #ff1414 !important;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}`, "",{"version":3,"sources":["webpack://./src/app/authentication/forgetpassword/forgetpassword.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,sBAAsB;AAC1B","sourcesContent":[".alert-error {\r\n    color: #ff1414 !important;\r\n    border: 1px solid transparent;\r\n    border-radius: 0.25rem;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
