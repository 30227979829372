import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs"; 
import { SettingsService } from '../../mastersettings/settings.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { StudentWiseReportCardService } from "../services/student-wise-report-card.service";


@Component({
  selector: 'app-teacher-wise-exam-report',
  templateUrl: './teacher-wise-exam-report.component.html',
  styleUrls: ['./teacher-wise-exam-report.component.css'],
  providers: [FormGroupDirective],
})
export class TeacherWiseExamReportComponent implements OnInit {
  submitted = false; 
  categorydropDown: any = [];
  selectedSubject:string="";
  academicyears: Academicyear[] = [];
  academicYearId: number;
  finalexamNamedropDown: any = []; 
  isLoading: boolean = false; 
  selectedRow:number;
  DetailsResult: any = [];
  SummaryResult:any=[];
  examNamedropDown: any = [];
  disabled = true;

  constructor(
    private toastr: ToastrService,
    public fb: UntypedFormBuilder,
    public service: StudentWiseReportCardService,
    public settingService:SettingsService,
    public formDirective: FormGroupDirective) { }
  teacherwiseexamreport: UntypedFormGroup;

  ngOnInit(): void {
    this.teacherwiseexamreport = this.fb.group({
      id: [0],
      academicYearId: ["", Validators.required], 
      examName: ["", Validators.required],     
      examCategory: ["", Validators.required]
    });
    this.ListAcademicYear();
  }

  onAcademicYearSelected(value: string)
    {
       this.academicYearId=Number(value);  
       this.getCategoryDropDown(value)  ;    
       console.log(this.academicYearId);       
    }
  
    get f() {
      return this.teacherwiseexamreport.controls;
    }
    getCategoryDropDown(id) {
      if (this.teacherwiseexamreport.value.academicYearId !== "") { 
        this.service.getAllCatefgoryDropDown(id).subscribe((data) => {
          this.categorydropDown = data;
        });
        this.checkingButton();
    }
    }
    ListAcademicYear() {
      this.settingService.getAll().subscribe((data: Academicyear[]) => {
        this.academicyears = data;
        this.currentAcademicYear();
        this.checkingButton();
      });
      this.teacherwiseexamreport = this.fb.group({
        id: [0],
        academicYearId: ["", Validators.required],
        examName: ["", Validators.required],
        examCategory: ["", Validators.required]
      });
    }

    onCategoryEvent() {
      this.teacherwiseexamreport.value.examName = "";
      this.finalexamNamedropDown = [];
      this.getNameDropDown();
      this.checkingButton();   
      this.teacherwiseexamreport = this.fb.group({
        id: [0],
        examCategory: [this.teacherwiseexamreport.controls.examCategory.value, Validators.required],
        examName: ["", Validators.required],
        academicYearId: [this.teacherwiseexamreport.controls.academicYearId.value, Validators.required]
      }); 
    }

    onexamEvent() {
      this.teacherwiseexamreport.value.examName = "";  
      this.teacherwiseexamreport = this.fb.group({
        id: [0],
        examCategory: [this.teacherwiseexamreport.controls.examCategory.value, Validators.required],
        examName: [this.teacherwiseexamreport.controls.examName.value, Validators.required],
        academicYearId: [this.teacherwiseexamreport.controls.academicYearId.value, Validators.required]
      }); 
      this.checkingButton();   
      this.getExamWiseSubjectWiseTeacherSummary();
    }

    submitForm() {}
  
    getNameDropDown() {
      this.service.getExamNameList().subscribe((data) => {
        this.examNamedropDown = data;
        this.finalexamNamedropDown = this.examNamedropDown.filter(
          (filterList: any) =>
            filterList.examCategoryId === this.teacherwiseexamreport.value.examCategory
        );
      });
      this.checkingButton();
      
    }
  
  
    currentAcademicYear() {
      this.settingService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
        this.academicYearId = data.id; 
        this.getCategoryDropDown(data.id)  ;         
        this.checkingButton();
      });
      this.teacherwiseexamreport = this.fb.group({
        id: [0],
        academicYearId: [this.teacherwiseexamreport.controls.academicYearId.value, Validators.required],
        examName: ["", Validators.required],
        examCategory: ["all", Validators.required]
      });
    }

    checkingButton() {
      if (
        this.teacherwiseexamreport.controls.academicYearId.value &&
        this.teacherwiseexamreport.controls.examName.value &&
        this.teacherwiseexamreport.controls.examCategory.value 
      ) {
        this.disabled = false;
      } 
    } 
    
    getExamWiseSubjectWiseTeacherSummary(){
      this.isLoading = true;
      this.submitted=true;
      this.SummaryResult=[];      
      let details={
        academicYearId:this.teacherwiseexamreport.value.academicYearId,
        examCategoryId:this.teacherwiseexamreport.value.examCategory,
        examId:this.teacherwiseexamreport.value.examName
      };
    this.service.getExamWiseSubjectWiseTeacherSummary(details).subscribe((data) => {
      this.SummaryResult=data;
      this.selectedRow=null;
      this.isLoading = false;
      this.selectedSubject="";
    });
    }

    disableFreezeButton: boolean = false;  
      
    getExamWiseSubjectWiseTeacherDetails(d:any, i:number){
      this.isLoading = true;
      this.DetailsResult=[];
        if (this.teacherwiseexamreport.valid) {
          
        let details={
            academicYearId:this.teacherwiseexamreport.value.academicYearId,
            examCategoryId:this.teacherwiseexamreport.value.examCategory,
            examId:this.teacherwiseexamreport.value.examName,
            subjectId:d.id
        };
        this.selectedRow=i;
        this.service.getExamWiseSubjectWiseTeacherDetails(details).subscribe((data)=>{
          this.DetailsResult=data;
          this.isLoading = false;
        });

      }
      else{
        this.toastr.warning("All field are required", "WARNING!!!", {
          timeOut: 3000,
        });
        this.isLoading = false;
        return;
      }
    }
    
    resetForm() {
      this.submitted = false;
      this.finalexamNamedropDown = [];      
      this.formDirective.reset();
      this.teacherwiseexamreport.reset();
      this.disabled = true;
      this.teacherwiseexamreport = this.fb.group({
          id: [0],
          examCategory: ["", Validators.required],
          examName: ["", Validators.required],
          academicYearId: ["", Validators.required],
        });
    }


}
