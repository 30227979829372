import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Enquiry, EnquiryFollowup } from './enquiry.model';

@Injectable({
  providedIn: 'root'
})
export class EnquiryService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  // getAll(): Observable<Enquiry[]> {
  //   return this.httpClient.get<Enquiry[]>(environment.apiUrl + '/visitorenquiry/')
  //   .pipe(
  //     catchError(this.errorHandler)
  //   );
  // }
  getAll(): Observable<any[]> {
    return this.httpClient.get<any[]>(environment.apiUrl + '/visitorenquiry/getAllData/')
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getById(id): Observable<Enquiry> {
    return this.httpClient.get<Enquiry>(environment.apiUrl + '/visitorenquiry/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  generateEnquiryNo() {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(environment.apiUrl + '/visitorenquiry/GenerateEnquiryNumber', {responseType: 'text'})
    .pipe(
      catchError(this.errorHandler)
    );
  }

  create(visitorenquiry): Observable<Enquiry> {
    return this.httpClient.post<Enquiry>(environment.apiUrl + '/visitorenquiry/', JSON.stringify(visitorenquiry), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, visitorenquiry): Observable<Enquiry> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<Enquiry>(environment.apiUrl + '/visitorenquiry/' + id, JSON.stringify(visitorenquiry), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<Enquiry>(environment.apiUrl + '/visitorenquiry/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  createFollowup(enquiryFollowup): Observable<EnquiryFollowup> {
    return this.httpClient.post<EnquiryFollowup>(environment.apiUrl +
       '/visitorenquiry/CreateEnquiryFollowup', JSON.stringify(enquiryFollowup), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllFollowup(id): Observable<EnquiryFollowup[]> {
    return this.httpClient.get<EnquiryFollowup[]>(environment.apiUrl + '/visitorenquiry/GetFollowUps/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
