import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeeheadComponent } from './feehead/feehead.component';
import { InstallmentComponent } from './installment/installment.component';
import { GroupComponent } from './group/group.component';
import { FeeconfigurationComponent } from './feeconfiguration/feeconfiguration.component';
import { FeesettingComponent } from './feesetting/feesetting.component';
import { FeerefundComponent } from './feerefund/feerefund.component';
import { FeewaveoffComponent } from './feewaveoff/feewaveoff.component';
import { FeecollectionComponent } from './feecollection/feecollection.component';
import { AssignspecialfeeComponent } from './assignspecialfee/assignspecialfee.component';
import { AssignintallmenttogroupComponent } from './assignintallmenttogroup/assignintallmenttogroup.component';
import { ConcessiontemplateComponent } from './concessiontemplate/concessiontemplate.component';
import { ManagechequeComponent } from './managecheque/managecheque.component';
import { PdcentryComponent } from './pdcentry/pdcentry.component';
import { SetstudentconcessionComponent } from './setstudentconcession/setstudentconcession.component';
import { FeereceiptComponent } from './feemanagementreports/feereceipt/feereceipt.component';
import { PaymentgatewayComponent } from 'src/app/edumaq/configuration/paymentgateway/paymentgateway.component';
import { PaymentgatwayComponent } from './paymentgatway/paymentgatway.component';
import { FeeGroupComponent } from './fee-group/fee-group.component';
import { FeecollectionoldComponent } from './feecollectionold/feecollectionold.component';
//import { FeecollectionnewComponent } from './feecollection/feecollectionnew.component';
const routes: Routes = [
  {path: 'feemanagement/feemanagementreports/feereceipt', component: FeereceiptComponent},
  {path: 'feemanagement/feehead', component: FeeheadComponent},
  {path: 'feemanagement/installment', component: InstallmentComponent},
  //{path: 'feemanagement/group', component: GroupComponent},
  {path: 'feemanagement/group', component: FeeGroupComponent},
  {path: 'feemanagement/assigninstallment', component: AssignintallmenttogroupComponent},
  {path: 'feemanagement/feeconfiguration', component: FeeconfigurationComponent},
  {path: 'feemanagement/feesetting', component: FeesettingComponent},
  {path: 'feemanagement/feerefund', component: FeerefundComponent},
  {path: 'feemanagement/feewaveoff', component: FeewaveoffComponent},
  {path: 'feemanagement/feecollection', component: FeecollectionComponent},
  {path: 'feemanagement/feecollectionold', component: FeecollectionoldComponent},
  {path: 'feemanagement/assignspecialfee', component: AssignspecialfeeComponent},
  {path: 'feemanagement/concessiontemplate', component: ConcessiontemplateComponent},
  {path: 'feemanagement/managecheque', component: ManagechequeComponent},
  {path: 'feemanagement/pdcentry', component: PdcentryComponent},
  {path: 'feemanagement/setconcession', component: SetstudentconcessionComponent},
  {path: 'feemanagement/paymentgateway', component: PaymentgatewayComponent},
  {path: 'feemanagement/checkout', component: PaymentgatwayComponent},
  {path: 'feemanagement/report', loadChildren: () => import('./feemanagementreports/feemanagementreports.module')
      .then(m => m.FeeManagementReportsModule)},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeeManagementRoutingModule { }
