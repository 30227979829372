import { Injectable, NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdmissionComponent } from './admission/admission.component';
import {StudentManagementRoutingModule} from './studentmanagement-routing.module';
import { AdmissionreportComponent } from './admissionreport/admissionreport.component';
import { AdmissionformComponent } from './admissionform/admissionform.component';
import { AsignadditionalsubjectComponent } from './asignadditionalsubject/asignadditionalsubject.component';
import { BulkimageuploadComponent } from './bulkimageupload/bulkimageupload.component';
import { BulkuploadstudentComponent } from './bulkuploadstudent/bulkuploadstudent.component';
import { ChangeclassComponent } from './changeclass/changeclass.component';
import { ClasspromotionComponent } from './classpromotion/classpromotion.component';
import { InactivestudentpromotionComponent } from './inactivestudentpromotion/inactivestudentpromotion.component';
import { MakestudentinactiveComponent } from './makestudentinactive/makestudentinactive.component';
import { StudentbirthdayreportComponent } from './studentbirthdayreport/studentbirthdayreport.component';
import { StudentprofileComponent } from './studentprofile/studentprofile.component';
import { StudentsummaryComponent } from './studentsummary/studentsummary.component';
import { UpdatestudentdetailsComponent } from './updatestudentdetails/updatestudentdetails.component';

import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelect2Module } from 'ng-select2';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [AdmissionComponent, AdmissionreportComponent, AdmissionformComponent,
     AsignadditionalsubjectComponent, BulkimageuploadComponent, BulkuploadstudentComponent,
      ChangeclassComponent, ClasspromotionComponent, InactivestudentpromotionComponent,
       MakestudentinactiveComponent, StudentbirthdayreportComponent, StudentprofileComponent,
        StudentsummaryComponent, UpdatestudentdetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StudentManagementRoutingModule,
    SharedModule,
    NgSelect2Module,
    AutocompleteLibModule,
    NgSelectModule,
   
  ],
  exports:[
    SharedModule,
    
  ],
  providers:[
    TitleCasePipe
  ]
})
export class StudentmanagementModule { }
