import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StudentattendanceComponent } from './studentattendance/studentattendance.component';
import { AttendancereportComponent } from './attendancereport/attendancereport.component';
import { StudentwisereportComponent } from './studentwisereport/studentwisereport.component';
import { AttendanceregisterComponent } from './attendanceregister/attendanceregister.component';

const routes: Routes = [
  {path: 'attendance/studentattendance', component: StudentattendanceComponent},
  {path: 'attendance/attendanceregister', component: AttendanceregisterComponent},
  {path: 'attendance/attendancereport', component: AttendancereportComponent},
  {path: 'attendance/studentwisereport', component: StudentwisereportComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AttendanceRoutingModule { }
