// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
	/* Define the hover highlight color for the table row */
    .hoverTable tr:hover {
        background-color: #c7fac0;
    }

.tr-background{
    background-color: #c7fac0;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/communication/report/credit-history/credit-history.component.css"],"names":[],"mappings":";CACC,uDAAuD;IACpD;QACI,yBAAyB;IAC7B;;AAEJ;IACI,yBAAyB;AAC7B","sourcesContent":["\r\n\t/* Define the hover highlight color for the table row */\r\n    .hoverTable tr:hover {\r\n        background-color: #c7fac0;\r\n    }\r\n\r\n.tr-background{\r\n    background-color: #c7fac0;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
