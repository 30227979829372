import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TimeTable, SubjectViewModel } from './timetable.model';

@Injectable({
  providedIn: 'root'
})
export class TimeTableService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<TimeTable[]> {
    return this.httpClient.get<TimeTable[]>(environment.apiUrl + '/timetable/')
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getById(id): Observable<TimeTable> {
    return this.httpClient.get<TimeTable>(environment.apiUrl + '/timetable/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getTimeTable(periodId, weekname): Observable<TimeTable> {
    console.log(weekname);
    return this.httpClient.get<TimeTable>(environment.apiUrl +
      '/timetable/GetTimeTable/' + periodId + '/' + weekname)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getSubjectName(periodId, weekname): Observable<SubjectViewModel> {
    console.log(weekname);
    return this.httpClient.get<SubjectViewModel>(environment.apiUrl +
      '/timetable/GetSubjectName/' + periodId + '/' + weekname)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  create(period): Observable<TimeTable> {
    return this.httpClient.post<TimeTable>(environment.apiUrl + '/timetable/', JSON.stringify(period), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  deleteTT(period): Observable<TimeTable> {
    return this.httpClient.post<TimeTable>(environment.apiUrl + '/timetable/DeleteTT/', JSON.stringify(period), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );

    }
    getTeacherPeriodDetail(id): Observable<any[]> {
      return this.httpClient.get<any[]>(environment.apiUrl +
        '/timetable/GetTeacherTimeTable/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

  getPeriodDetail(classid, batchId, shiftid, teacherId): Observable<any[]> {
    return this.httpClient.get<any[]>(environment.apiUrl +
      '/timetable/GetPeriodDetails/' + classid + '/' + batchId + '/' + shiftid + '/' + teacherId)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  update(id, period): Observable<TimeTable> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<TimeTable>(environment.apiUrl + '/timetable/' + id, JSON.stringify(period), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  delete(id) {
    return this.httpClient.delete<TimeTable>(environment.apiUrl + '/timetable/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  deleteTimetable(id) {
    
    return this.httpClient.delete<TimeTable>(environment.apiUrl + '/timetable/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }


}