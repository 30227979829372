import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { environment } from '../../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AdditionalSubject} from './additionalsubject.model';

@Injectable({
    providedIn: 'root'
  })
  export class AdditionalSubjectService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<AdditionalSubject[]> {
      return this.httpClient.get<AdditionalSubject[]>(environment.apiUrl + '/additionalsubject/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<AdditionalSubject> {
      return this.httpClient.get<AdditionalSubject>(environment.apiUrl + '/additionalsubject/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getDetails(id): Observable<AdditionalSubject> {
      return this.httpClient.get<AdditionalSubject>(environment.apiUrl + '/assignment/GetAssignmentDetails/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    create(additionalsubject): Observable<AdditionalSubject[]> {
      console.log(additionalsubject);
      return this.httpClient.post<AdditionalSubject[]>(environment.apiUrl + '/additionalsubject/', additionalsubject, this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    createMultiple(additionalsubject): Observable<AdditionalSubject> {
      console.log(additionalsubject);
      return this.httpClient.post<AdditionalSubject>(environment.apiUrl + '/additionalsubject/', additionalsubject, this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    // create(assignment): Observable<any> {
    //   return this.httpClient.post(environment.apiUrl + '/assignment/', assignment, {responseType: 'text'})
    //   .pipe(
    //     catchError(this.errorHandler)
    //   );
    // }

    // update(id, assignment): Observable<any> {
    //   return this.httpClient.put(environment.apiUrl + '/assignment/' + id, assignment, {responseType: 'text'})
    //   .pipe(
    //     catchError(this.errorHandler)
    //   );
    // }

    update(id, additionalsubject): Observable<AdditionalSubject> {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.put<AdditionalSubject>(environment.apiUrl + '/additionalsubject/' + id, JSON.stringify(additionalsubject), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    delete(id) {
      return this.httpClient.delete<AdditionalSubject>(environment.apiUrl + '/additionalsubject/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }
}
