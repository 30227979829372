import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssignrollnoComponent } from './assignrollno/assignrollno.component';
import { ClasssummmaryComponent } from './classsummmary/classsummmary.component';
import { BatchsummmaryComponent } from './batchsummmary/batchsummmary.component';
import { AssignteacherComponent } from './assignteacher/assignteacher.component';
import { AssignsubteacherComponent } from './assignsubteacher/assignsubteacher.component';

const routes: Routes = [
  {path: 'class/assignrollno', component: AssignrollnoComponent},
  {path: 'class/assignteacher', component: AssignteacherComponent},
  {path: 'class/assignsubjectteacher', component: AssignsubteacherComponent},
  {path: 'class/classsummary', component: ClasssummmaryComponent},
  {path: 'class/batchsummary', component: BatchsummmaryComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClassManagementRoutingModule { }
