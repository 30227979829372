import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { environment } from '../../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Assignment} from './assignment.model';

@Injectable({
    providedIn: 'root'
  })
  export class AssignmentService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    httpOptionsC = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'enc-type': 'multipart/form-data'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<Assignment[]> {
      return this.httpClient.get<Assignment[]>(environment.apiUrl + '/assignment/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<Assignment> {
      return this.httpClient.get<Assignment>(environment.apiUrl + '/assignment/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getDetails(id): Observable<Assignment> {
      return this.httpClient.get<Assignment>(environment.apiUrl + '/assignment/GetAssignmentDetails/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    public downloadFile(id: number, docFile: string) {
      // return this.httpClient.get(environment.apiUrl  + '/assignment/GetFile/' + id + '/'  + docFile, {
      //     responseType: 'blob'
      // });
      this.httpClient.get(environment.apiUrl  + '/assignment/GetFile/' + id + '/'  + docFile, { responseType: 'blob' }).subscribe(blob => {
        saveAs(blob, 'sample.jpg', {
           type: 'text/plain;charset=windows-1252' // --> or whatever you need here
        });
     });
    }

    // create(assignment): Observable<Assignment> {
    //   console.log(assignment);
    //   return this.httpClient.post<Assignment>(environment.apiUrl + '/assignment/', assignment, this.httpOptions)
    //   .pipe(
    //     catchError(this.errorHandler)
    //   );
    // }
    
    create(assignment): Observable<Assignment> {
      return this.httpClient.post<Assignment>(environment.apiUrl + '/assignment/', JSON.stringify(assignment),this.httpOptionsC)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    update(id, assignment): Observable<Assignment> {
      return this.httpClient.put<Assignment>(environment.apiUrl + '/assignment/' + id, JSON.stringify(assignment),this.httpOptionsC)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    // update(id, assignment): Observable<Assignment> {
    //   // tslint:disable-next-line:max-line-length
    //   return this.httpClient.put<Assignment>(environment.apiUrl + '/assignment/' + id, JSON.stringify(assignment), this.httpOptions)
    //   .pipe(
    //     catchError(this.errorHandler)
    //   );
    // }
  
    delete(id) {
      return this.httpClient.delete<Assignment>(environment.apiUrl + '/assignment/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }
  
  
  }