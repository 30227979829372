import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {BatchService} from './batch.service';
import {ClassCourseService} from './../classcourse/classcourse.service';
import {StreamService} from './../stream/stream.service';
import { Batch } from '../models/batch';
import { ClassCourse } from '../models/classcourse';
import { Stream } from '../models/stream';

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.css']
})
export class BatchComponent implements OnInit {

batchForm: UntypedFormGroup;
batches: Batch[] = [];
batcheFilteredList: Batch[] = [];
classcourses: ClassCourse[] = [];
streams: Stream[] = [];
HighlightRow : Number;
ClickedRow:any;


submitted = false;

  constructor(
    public batchService: BatchService,
    public classcourseService: ClassCourseService,
    public streamService: StreamService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) 
  { 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }
  }

  ListBatch() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      console.log(data);
      
      this.batcheFilteredList = this.batches = data.filter((e) => e.isDeleted.toString() === 'False');
    });
}

ListClass() {
  this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
    console.log(data);
    this.classcourses = data.filter((e) => e.isDeleted === false);
  });
}

ListStream() {
  this.streamService.getAll().subscribe((data: Stream[]) => {
    console.log(data);
    this.streams = data.filter((e) => e.isDeleted === false);
  });
}

onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

filter(keyword: any) {
  if ( keyword === '') {
    this.batcheFilteredList = this.batches;
  } else {
  this.batcheFilteredList = this.batches
                              .filter((batch: Batch) => batch.batchName.toLocaleLowerCase()
                              .includes(keyword.toLocaleLowerCase()));
  }
}

keyPress(event: any) {
  const pattern = /[0-9\+\-\ ]/;
  const inputChar = String.fromCharCode(event.charCode);
  if (!pattern.test(inputChar) && event.charCode !== '0') {
          event.preventDefault();
  }
}

get totalRows(): number {
  return this.batcheFilteredList.length;
}
get f() { return this.batchForm.controls; }
resetForm() {
  this.submitted = false;
  this.batchForm = this.fb.group({
    id: [0],
    batchName: ['', Validators.required],
    seatingCapacity: ['', Validators.required],
    classId: ['', Validators.required],
    streamId: ['', Validators.required],
    status: ['true', Validators.required],
    isDeleted: [false]
  });
}
submitForm() {
  this.submitted = true;

      // stop here if form is invalid
  if (this.batchForm.invalid) {
          return;
    }

  if (this.batchForm.get('id').value === 0) {
      this.batchService.create(this.batchForm.value).subscribe(res => {
        console.log('Batch created!');
        this.ListBatch();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.batchService.update(this.batchForm.get('id').value, this.batchForm.value).subscribe(res => {
          console.log('Batch updated!');
          this.ListBatch();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
      }


    }

    updateForm(id: number) {
      this.batchService.getById(id).subscribe((data: Batch) => {
        console.log(data);
        this.batchForm = this.fb.group({
          id: [data.id],
          batchName: [data.batchName, Validators.required],
          seatingCapacity: [data.seatingCapacity, Validators.required],
          classId: [data.classId.toString(), Validators.required],
          streamId: [data.streamId.toString(), Validators.required],
          status: [data.status.toString(), Validators.required],
          isDeleted: [data.isDeleted]
        });
      });

    }

    deleteForm(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.batchService.delete(id).subscribe(res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            this.ListBatch();
          });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
        }
      });
    }

  ngOnInit(): void {
    this.ListClass();
    this.ListStream();
    this.ListBatch();
    this.batchForm = this.fb.group({
      id: [0],
      batchName: ['', Validators.required],
      seatingCapacity: ['', Validators.required],
      classId: ['', Validators.required],
      streamId: ['', Validators.required],
      status: ['true', Validators.required],
      isDeleted: [false]
    });
  }

}
