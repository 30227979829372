import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { data } from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { ModuleManagementService } from './modulemanagement.service';
import { ModuleManagementServiceModel } from './modeumanagementmodel';
import Swal from 'sweetalert2';
import { SubMenuModel } from './submenumodel';

@Component({
  selector: 'app-modulemanagement',
  templateUrl: './modulemanagement.component.html',
  styleUrls: ['./modulemanagement.component.css']
})
export class ModulemanagementComponent implements OnInit {
  menumanagementForm: UntypedFormGroup;
  imageSrc: string;
  submitted = false;
  subMenuList: SubMenuModel[] = [];
  subMenuFrom: UntypedFormGroup;
  isLoading: boolean = false;
  module_name: string = "";
  selected_module_id: Number = 0;
  HighlightRow: Number = -0;
  menuFrom: UntypedFormGroup;
  module_status: string="";
  menuFilteredList: ModuleManagementServiceModel[] = [];
  subMenusArray: any[] = [];
  activeMenuFilteredList: ModuleManagementServiceModel[] = [];
  constructor(
    public menuService: ModuleManagementService, public fb: UntypedFormBuilder, public formBuilder: UntypedFormBuilder, private toastr: ToastrService
  ) { 
    
     
  }

  ngOnInit(): void {
    this.ListAllActiveMenus();
    this.ListAllMenus();
    this.initForm();
    this.createTableForm()
    this.menuFrom = this.fb.group({
      mainmenus: this.fb.array([])
    });
  }

  get f() { return this.menumanagementForm.controls; }
  initForm() {
    this.menumanagementForm = this.fb.group({
      id: [0],
      moduleName: ['', Validators.required],
      sortOrder: ['', Validators.required],
      status: ['Active', Validators.required],
      iconImage: ['', Validators.required],
      height: ['', Validators.required],
      width: ['', Validators.required]
    });


    console.log(this.subMenuFrom?.controls?.Rows)
  }

  createTableForm(): void {
    this.menuFrom = this.formBuilder.group({
      items: this.formBuilder.array([])
    })
  }
  private createTableRow(): UntypedFormGroup {
    let itemsToAdd = this.formBuilder.group({
      id: [0],
      moduleId: ['', Validators.required],
      menuName: ['', Validators.required],
      sortOrder: ['', Validators.required],
      url: ['', Validators.required],
      pageAction: ['', Validators.required],
      iconImage: ['', Validators.required],
      height: ['', Validators.required],
      width: ['', Validators.required],
      status: ['', Validators.required],
      subMenus: this.formBuilder.array([])
    })

    return itemsToAdd;
  }
  private createSubMenu(): UntypedFormGroup {
    let itemsToAdd = this.formBuilder.group({
      subMenuId: [0],
      moduleId: ['', Validators.required],
      menuName: ['', Validators.required],
      sortOrder: ['', Validators.required],
      url: ['', Validators.required],
      pageAction: ['', Validators.required],
      iconImage: ['', Validators.required],
      height: ['', Validators.required],
      width: ['', Validators.required],
      status: ['', Validators.required],
      subMenusItmes: this.formBuilder.array([])
    })

    return itemsToAdd;
  }

  get itemsArray(): UntypedFormArray {
    return this.menuFrom.get("items") as UntypedFormArray;
  }

  addSubMainMenu() {
    this.itemsArray.push(this.createTableRow())

  }
  onInputChange($event, i) {
    if ($event.length == 1 && $event === "#") {
      this.addSubMenu(i);
    }
  }

  onInputSubChange($event, main_menu_index, sub_menu_index) {
    if ($event.length == 1 && $event === "#") {
      this.addL2SubMenu(main_menu_index, sub_menu_index);
    }
  }

  onInputL1Change($event, main_menu_index, sub_menu_index, l1RowIndex) {
    if ($event.length == 1 && $event === "#") {
      this.addL1SubSubMenu(main_menu_index, sub_menu_index, l1RowIndex);
    }
  }

  onInputL2Change($event, main_menu_index, sub_menu_index, l1RowIndex, l2RowIndex) {
    if ($event.length == 1 && $event === "#") {
      this.addL2SubSubMenu(main_menu_index, sub_menu_index, l1RowIndex, l2RowIndex);
    }
  }

  onInputL3Change($event, main_menu_index, sub_menu_index, l1RowIndex, l2RowIndex, l3RowIndex) {
    if ($event.length == 1 && $event === "#") {
      this.addL4SubSubMenu(main_menu_index, sub_menu_index, l1RowIndex, l2RowIndex, l3RowIndex);
    }
  }

  // onInputL4Change($event,main_menu_index,sub_menu_index,l1RowIndex,l2RowIndex,l3RowIndex,l4RowIndex){
  //   if($event.data.length ==1 &&  $event.data=== "#"){    
  //     this.addL4SubSubMenu(main_menu_index,sub_menu_index,l1RowIndex,l2RowIndex,l3RowIndex,l4RowIndex);      
  //   }
  // }






  submitForm() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.menumanagementForm.invalid) {
      return;
    }

    if (this.menumanagementForm.get('id').value === 0) {
      debugger;
      this.menuService.create(this.menumanagementForm.value).subscribe(res => {
        console.log('Main Module created!');
        this.ListAllMenus();
        this.ListAllActiveMenus();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    } else {
      this.menuService.update(this.menumanagementForm.get('id').value, this.menumanagementForm.value).subscribe(res => {
        console.log('Caste updated!');
        this.ListAllMenus();
        this.ListAllActiveMenus();
        this.toastr.success('Records has been sucessfully updated.', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    }
  }

  ListAllMenus() {
    this.menuService.getAll().subscribe((data: ModuleManagementServiceModel[]) => {
      this.menuFilteredList = data.filter(d => d.isDeleted == "False");;
    });
  }

  ListAllActiveMenus() {
    this.menuService.getAllActiveMenus().subscribe((data: ModuleManagementServiceModel[]) => {
      this.activeMenuFilteredList = data.filter(d => d.isDeleted == "False");
      let item = this.activeMenuFilteredList[0];
      this.LoadSubMenu(item.id,item.moduleName,item.status)
    });
  }


  updateForm(id: number) {
    let data = this.menuFilteredList.find(x => x.id == id);
    this.menumanagementForm = this.fb.group({
      id: [data.id],
      moduleName: [data.moduleName],
      sortOrder: [data.sortOrder],
      status: [data.status],      
      height: [data.height],
      width: [data.width],
      iconImage: [data.iconImage],
    });


  }

  handleUpload(event) {
    const file = event.target.files[0] as File;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var iconImage = this.menumanagementForm.get("iconImage");
      iconImage.setValue(reader.result);
    };
  }

  handleSubMenuUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      debugger;
      let iconImage = this.menuFrom.get("iconImage");
      if (iconImage){
        //iconImage =  reader.result;
      }
      console.log(this.menuFrom.get("iconImage").value);
    };
  }

  track(item: any, index: number) {
    return index;
  }

  resetForm() {
    this.submitted = false;
    this.initForm();
  }


  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.menuService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListAllMenus();
          this.ListAllActiveMenus();
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }



  // -------------------------Dynamic Table Loading-----------------------------------------


  // newSubMenu(): FormGroup {
  //   return this.fb.group({
  //     id: [0],
  //     moduleId: ['', Validators.required],
  //     menuName: ['', Validators.required],
  //     sortOrder: ['', Validators.required],
  //     url: ['', Validators.required],
  //     pageAction: ['', Validators.required],
  //     iconImage: ['', Validators.required],
  //     height: ['', Validators.required],
  //     width: ['', Validators.required],
  //     status: ['', Validators.required],
  //     subMenus: this.formBuilder.array([])
  //   });
  // }


  newMainMenu(): UntypedFormGroup {
    return this.fb.group({
      id: [0],
      moduleId: ['', Validators.required],
      menuName: ['', Validators.required],
      sortOrder: ['', Validators.required],
      url: ['', Validators.required],
      pageAction: ['Self', Validators.required],
      iconImage: ['', Validators.required],
      height: ['', Validators.required],
      width: ['', Validators.required],
      status: [true, Validators.required],
      subMenus: this.formBuilder.array([])
    });
  }

  //Adding Root Menu Inder Root Main Menu
  addMainMeenu() {
    this.mainmenus().push(this.newMainMenu());
  }
  mainmenus(): UntypedFormArray {
    return this.menuFrom.get('mainmenus') as UntypedFormArray;
  }



  //Adding Sub Menu Inder Root Main Menu
  addSubMeenu(main_menu_index: number) {
    this.subMenus(main_menu_index).push(this.newMainMenu());
  }
  subMenus(main_menu_index: number): UntypedFormArray {
    let formArray = this.mainmenus()
      .at(main_menu_index)
      .get('subMenus') as UntypedFormArray;
    return formArray;
  }






  subMenusList(empIndex: number): UntypedFormArray {
    return this.mainmenus()
      .at(empIndex)
      .get('subMenus') as UntypedFormArray;
  }


  l1MenusSubmenuList(main_menu_index: number, sub_menu_index: number): UntypedFormArray {
    console.log(main_menu_index,sub_menu_index)
    let formArray = this.mainmenus()
    .at(main_menu_index)
    .get('subMenus') as UntypedFormArray;
    console.log('formArray',formArray)
    
    //debugger;
    let subformArrayIndex = formArray.at(sub_menu_index)

    let subformArray = subformArrayIndex.get('subMenus') as UntypedFormArray;

    return subformArray;

  }

  l12MenusSubmenuList(main_menu_index: number, sub_menu_index: number, l1_row_index: number): UntypedFormArray {
    let formArray = this.mainmenus()
      .at(main_menu_index)
      .get('subMenus') as UntypedFormArray;

    let subformArray = formArray.at(sub_menu_index)
      .get('subMenus') as UntypedFormArray;

    let subSubFormArray = subformArray.at(l1_row_index)
      .get('subMenus') as UntypedFormArray;

    return subSubFormArray;

  }

  l13MenusSubmenuList(main_menu_index: number, sub_menu_index: number, l1_row_index: number, l2_row_index: number): UntypedFormArray {
    let formArray = this.mainmenus()
      .at(main_menu_index)
      .get('subMenus') as UntypedFormArray;

    let subformArray = formArray.at(sub_menu_index)
      .get('subMenus') as UntypedFormArray;

    let subSubFormArrayL1 = subformArray.at(l1_row_index)
      .get('subMenus') as UntypedFormArray;


    let subSubFormArray = subSubFormArrayL1.at(l2_row_index)
      .get('subMenus') as UntypedFormArray;

    return subSubFormArray;

  }

  l4MenusSubmenuList(main_menu_index: number, sub_menu_index: number, l1_row_index: number, l2_row_index: number, l3_row_index: number): UntypedFormArray {
    let formArray = this.mainmenus()
      .at(main_menu_index)
      .get('subMenus') as UntypedFormArray;

    let subformArray = formArray.at(sub_menu_index)
      .get('subMenus') as UntypedFormArray;

    let subSubFormArrayL1 = subformArray.at(l1_row_index)
      .get('subMenus') as UntypedFormArray;


    let subSubFormArayL2 = subSubFormArrayL1.at(l2_row_index)
      .get('subMenus') as UntypedFormArray;

    let subSubFormArray = subSubFormArayL2.at(l3_row_index)
      .get('subMenus') as UntypedFormArray;
    return subSubFormArray;

  }


  l5MenusSubmenuList(main_menu_index: number, sub_menu_index: number, l1_row_index: number, l2_row_index: number, l3_row_index: number, l4_row_index: number): UntypedFormArray {
    let formArray = this.mainmenus()
      .at(main_menu_index)
      .get('subMenus') as UntypedFormArray;

    let subformArray = formArray.at(sub_menu_index)
      .get('subMenus') as UntypedFormArray;

    let subSubFormArrayL1 = subformArray.at(l1_row_index)
      .get('subMenus') as UntypedFormArray;


    let subSubFormArayL2 = subSubFormArrayL1.at(l2_row_index)
      .get('subMenus') as UntypedFormArray;

    let subSubFormArayL3 = subSubFormArrayL1.at(l2_row_index)
      .get('subMenus') as UntypedFormArray;

    let subSubFormArray = subSubFormArayL3.at(l4_row_index)
      .get('subMenus') as UntypedFormArray;
    console.log(subSubFormArray.controls);
    return subSubFormArray;

  }


  // l3bMenusList(empIndex: number): FormArray {
  //   return this.mainmenus()
  //     .at(empIndex)
  //     .get('l3subMenus') as FormArray;
  // }

  // l4bMenusList(empIndex: number): FormArray {
  //   return this.mainmenus()
  //     .at(empIndex)
  //     .get('l4subMenus') as FormArray;
  // }
  // l5MenusList(empIndex: number): FormArray {
  //   return this.mainmenus()
  //     .at(empIndex)
  //     .get('l5subMenus') as FormArray;
  // }





  addSubMenu(index: number) {
    this.subMenusList(index).push(this.newMainMenu());
  }

  addL2SubMenu(main_menu_index: number, sub_menu_index: number) {
    this.l1MenusSubmenuList(main_menu_index, sub_menu_index).push(this.newMainMenu());
  }
  addL2SubSubMenu(main_menu_index: number, sub_menu_index: number, l1_row_index: number, l2_row_index: number) {
    this.l13MenusSubmenuList(main_menu_index, sub_menu_index, l1_row_index, l2_row_index).push(this.newMainMenu());
  }
  addL1SubSubMenu(main_menu_index: number, sub_menu_index: number, l1_row_index: number) {
    this.l12MenusSubmenuList(main_menu_index, sub_menu_index, l1_row_index).push(this.newMainMenu());
  }

  // addL3SubSubMenu(main_menu_index, sub_menu_index, l1RowIndex, l2RowIndex) {
  //   this.l13MenusSubmenuList(main_menu_index, sub_menu_index, l1RowIndex, l2RowIndex).push(this.newMainMenu());
  // }
  addL4SubSubMenu(main_menu_index, sub_menu_index, l1RowIndex, l2RowIndex, l3RowIndex) {
    this.l4MenusSubmenuList(main_menu_index, sub_menu_index, l1RowIndex, l2RowIndex, l3RowIndex).push(this.newMainMenu());
  }

  LoadSubMenu(module_id: Number, mod_name: string,mod_status: string) {
    console.log(module_id);
    this.module_name = mod_name;
    this.selected_module_id = module_id;
    this.module_status = mod_status;
    this.isLoading = true;
    this.menuService.getSubmenusByModuleId(module_id).subscribe((data: any[]) => {
      console.log(data);
      this.isLoading = false;
      this.subMenusArray = data;
      this.LoadSubMenus(data); 
    });
  }

  LoadSubMenus(data: any[]) {
    //debugger;
    console.log('data',data)
    this.mainmenus().clear();
    let l1dx = 0, l2Idx = 0, l3Idx = 0, I4Idx = 0, I5Idx = 0;
    data.forEach(element => {
      //Level 1 Menus
      let formData = this.prepareData(element)
      this.mainmenus().push(formData);
      console.log('element',element)

      //Level 2 Menuns  
      if (element.subMenus.length > 0) {
        element.subMenus.forEach(subMenuElement => {    
          console.log('subMenuElement',subMenuElement)

          let subMenu1 = this.prepareData(subMenuElement);
          this.subMenus(l1dx).push(subMenu1);
          console.log('subMenu1',subMenu1)


          //Level 3 Menuns  
          if (subMenuElement.subMenus.length > 0) {
            subMenuElement.subMenus.forEach(subMenuElement1 => {   
               console.log('subMenuElement1',subMenuElement1)

              let subMenu2 = this.prepareData(subMenuElement1);
              this.l1MenusSubmenuList(l1dx, l2Idx).push(subMenu2);


              //Level 4 Menuns  
              if (subMenuElement1.subMenus.length > 0) {
                subMenuElement1.subMenus.forEach(subMenuElement2 => {

                  let subMenu3 = this.prepareData(subMenuElement2);
                  this.l12MenusSubmenuList(l1dx, l2Idx, l3Idx).push(subMenu3);


                  //Level 4 Menuns  
                  if (subMenuElement2.subMenus.length > 0) {
                    subMenuElement2.subMenus.forEach(subMenuElement3 => {  

                      let subMenu4 = this.prepareData(subMenuElement3);
                      this.l13MenusSubmenuList(l1dx, l2Idx,l3Idx, I4Idx).push(subMenu4);


                      //Level 5 Menuns  
                      if (subMenuElement3.subMenus.length > 0) {
                        subMenuElement3.subMenus.forEach(subMenuElement4 => {

                          let subMenu5 = this.prepareData(subMenuElement4);
                          this.l4MenusSubmenuList(l1dx, l2Idx, l3Idx, I4Idx, I5Idx).push(subMenu5);

                        });
                      }
                      I5Idx += 1;



                    });
                  }
                  I4Idx += 1;
                  I5Idx = 0;



                });
              }
              l3Idx += 1;
              I5Idx = 0; I4Idx = 0;

            });
          }
          l2Idx += 1;
          I5Idx = 0; I4Idx = 0; l3Idx = 0;



        });
      }
      l1dx += 1;
      I5Idx = 0; I4Idx = 0; l3Idx = 0; l2Idx = 0;





    });


  }

  prepareData(element: any): UntypedFormGroup {
    let formData = this.fb.group({
      id: [element.id],
      moduleId: ['', Validators.required],
      menuName: [element.menuName, Validators.required],
      sortOrder: [element.sortOrder, Validators.required],
      url: [element.url, Validators.required],
      pageAction: [element.pageAction, Validators.required],
      iconImage: [element.iconImage, Validators.required],
      height: [element.height, Validators.required],
      width: [element.width, Validators.required],
      status: [element.status, Validators.required],
      subMenus: this.formBuilder.array([])
    });
    return formData;
  }


  onSubmit() {
    // this.submitted = true;
    // if(this.menuFrom.invalid){
    //     return;
    // }
    if (this.menuFrom.value.mainmenus == undefined || this.menuFrom.value.mainmenus == null || this.menuFrom.value.mainmenus.length == 0) {
      this.toastr.error('Please enter sub menus to proceed', 'FAILEd!', {
        timeOut: 3000
      });
      return;
    }
    let obj = {
      "module_Id": this.selected_module_id,
      "mainMenus": this.menuFrom.value.mainmenus
    }
    this.menuService.createSubMenu(obj).subscribe((data: any) => {
      this.toastr.success('Menus Created Successfully', 'SUCCESS!', {
        timeOut: 3000
      });
      this.mainmenus().clear();
    });
  }


  deleteSubMenu(index: number) {
    let obj = this.mainmenus().value[index];
    console.log(JSON.stringify(obj));
    if (obj?.id > 0) {
      this.DeleteMenu(obj?.id);
    } else {
      let menus = this.mainmenus() as UntypedFormArray
      menus.removeAt(index);
      console.log(menus);
    }
  }

  deleteL2SubMenu(main_menu_index: number, sub_menu_index: number) {
    let obj = this.mainmenus().value[main_menu_index]['subMenus'][sub_menu_index]
    console.log(JSON.stringify(obj));
    if (obj?.id > 0) {
      this.DeleteMenu(obj?.id);
    } else {
      let menus = this.mainmenus().at(main_menu_index).get('subMenus') as UntypedFormArray
      menus.removeAt(sub_menu_index);
      console.log(menus);
    }
  }

  deleteL3SubSubMenu(main_menu_index: number, sub_menu_index: number, l1_row_index: number) {
    let obj = this.mainmenus().value[main_menu_index]['subMenus'][sub_menu_index]['subMenus'][l1_row_index]
    console.log(JSON.stringify(obj));
    if (obj?.id > 0) {
      this.DeleteMenu(obj?.id);
    } else {
      let menus = this.mainmenus().at(main_menu_index).get('subMenus') as UntypedFormArray
      let subMenus = menus.at(sub_menu_index).get('subMenus') as UntypedFormArray
      subMenus.removeAt(l1_row_index);
    }


  }


  deleteL4SubSubMenu(main_menu_index, sub_menu_index, l1RowIndex, l2RowIndex,) {
    let obj = this.mainmenus().value[main_menu_index]['subMenus'][sub_menu_index]['subMenus'][l1RowIndex]['subMenus'][l2RowIndex]
    console.log(JSON.stringify(obj));
    if (obj?.id > 0) {
      this.DeleteMenu(obj?.id);
    } else {
      let menus = this.mainmenus().at(main_menu_index).get('subMenus') as UntypedFormArray
      let subMenus = menus.at(sub_menu_index).get('subMenus') as UntypedFormArray
      let subMenus1 = subMenus.at(l1RowIndex).get('subMenus') as UntypedFormArray
      subMenus1.removeAt(l2RowIndex);
    }
  }

  deleteL5SubSubMenu(main_menu_index, sub_menu_index, l1RowIndex, l2RowIndex, l3RowIndex) {
    let obj = this.mainmenus().value[main_menu_index]['subMenus'][sub_menu_index]['subMenus'][l1RowIndex]['subMenus'][l2RowIndex]['subMenus'][l3RowIndex]
    console.log(JSON.stringify(obj));
    if (obj?.id > 0) {
      this.DeleteMenu(obj?.id);
    } else {
      let menus = this.mainmenus().at(main_menu_index).get('subMenus') as UntypedFormArray
      let subMenus = menus.at(sub_menu_index).get('subMenus') as UntypedFormArray
      let subMenus1 = subMenus.at(l1RowIndex).get('subMenus') as UntypedFormArray
      let subMenus2 = subMenus1.at(l2RowIndex).get('subMenus') as UntypedFormArray
      subMenus2.removeAt(l3RowIndex);
    }
  }
  deleteL6SubSubMenu(main_menu_index, sub_menu_index, l1RowIndex, l2RowIndex, l3RowIndex, l4RowIndex) {
    let obj = this.mainmenus().value[main_menu_index]['subMenus'][sub_menu_index]['subMenus'][l1RowIndex]['subMenus'][l2RowIndex]['subMenus'][l3RowIndex]['subMenus'][l4RowIndex]
    console.log(JSON.stringify(obj));
    if (obj?.id > 0) {
      this.DeleteMenu(obj?.id);
    } else {
      let menus = this.mainmenus().at(main_menu_index).get('subMenus') as UntypedFormArray
      let subMenus = menus.at(sub_menu_index).get('subMenus') as UntypedFormArray
      let subMenus1 = subMenus.at(l1RowIndex).get('subMenus') as UntypedFormArray
      let subMenus2 = subMenus1.at(l2RowIndex).get('subMenus') as UntypedFormArray
      let subMenus3 = subMenus2.at(l3RowIndex).get('subMenus') as UntypedFormArray
      subMenus3.removeAt(l4RowIndex);
    }
  }


  DeleteMenu(menu_id: Number) {
    console.log(menu_id);
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.menuService.deleteSubmenusById(menu_id).subscribe((data: any[]) => {
          Swal.fire(
            'Deleted!',
            'Menu has been deleted.',
            'success'
          );

          this.mainmenus().clear();

          this.LoadSubMenu(this.selected_module_id, this.module_name,this.module_status);
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',

          'Your data is safe :)',
          'error'
        );
      }
    });

  }
 ClickedRow(index){
    this.HighlightRow = index;
    let item = this.activeMenuFilteredList[index];
    this.LoadSubMenu(item.id,item.moduleName,item.status)
}

}
