import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../../admin/shared.service';
import {AuthenticationService} from '../../authentication/authentication.service';
import { Academicyear } from '../../admin/academic/mastersettings/models/academicyear';
import { SettingsService } from '../../admin/academic/mastersettings/settings.service';

@Component({
  selector: 'app-superadmin',
  templateUrl: './superadmin.component.html',
  styleUrls: ['./superadmin.component.css']
})
export class SuperadminComponent implements OnInit {

  academicYears: Academicyear[] = [];
  currentAcademicYearId: number;
  currentAcademicYearName = '';
  institution: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private academicYearService: SettingsService,
    private authService: AuthenticationService
  ) { }

  ListAcademicYear() {
    this.academicYearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
      this.currentAcademicYear();
    });
  }

  currentAcademicYear() {
    this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.currentAcademicYearName = this.academicYears.find(x => x.id.toString() === data.id.toString()).name;
    });
  }

  logout() {
    sessionStorage.removeItem('JWT_TOKEN');
    // localStorage.clear();
    this.router.navigate([this.authService.institutionCode]);
  }
  ngOnInit(): void {
    this.sharedService.institutionCode = this.activatedRoute.snapshot.params.instcode;
    this.ListAcademicYear();
    this.institution = JSON.parse(sessionStorage.getItem('Institution'));
  }

}
