// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_border {
    color: #495057;
    background-color: #f8f9fa;
    border-color: #eff2f7;
}
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #27d1dd; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #27d1dd; 
  }
  .box_height {
    max-height: 50vh;
    overflow-x: hidden;
  }
  .custom_chk {
   vertical-align: sub;
  }
  .chk_height {
    height: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/app/admin/academic/offline-examination/exam-planner/exam-planner/exam-planner.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,yBAAyB;IACzB,qBAAqB;AACzB;AACA;IACI,UAAU;EACZ;;EAEA,UAAU;EACV;IACE,mBAAmB;EACrB;;EAEA,WAAW;EACX;IACE,mBAAmB;EACrB;;EAEA,oBAAoB;EACpB;IACE,mBAAmB;EACrB;EACA;IACE,gBAAgB;IAChB,kBAAkB;EACpB;EACA;GACC,mBAAmB;EACpB;EACA;IACE,YAAY;EACd","sourcesContent":[".header_border {\r\n    color: #495057;\r\n    background-color: #f8f9fa;\r\n    border-color: #eff2f7;\r\n}\r\n::-webkit-scrollbar {\r\n    width: 5px;\r\n  }\r\n  \r\n  /* Track */\r\n  ::-webkit-scrollbar-track {\r\n    background: #f1f1f1; \r\n  }\r\n   \r\n  /* Handle */\r\n  ::-webkit-scrollbar-thumb {\r\n    background: #27d1dd; \r\n  }\r\n  \r\n  /* Handle on hover */\r\n  ::-webkit-scrollbar-thumb:hover {\r\n    background: #27d1dd; \r\n  }\r\n  .box_height {\r\n    max-height: 50vh;\r\n    overflow-x: hidden;\r\n  }\r\n  .custom_chk {\r\n   vertical-align: sub;\r\n  }\r\n  .chk_height {\r\n    height: 20px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
