import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssignWeightagesCBSEComponent } from './assign-weightages-cbse/assign-weightages-cbse.component';
import { AssignWeightagesICSEComponent } from './assign-weightages-icse/assign-weightages-icse.component';
import { DesignationComponent } from './designation/designation.component';
import { ExamCategoryComponent } from './exam-category/exam-category.component';
import { ExamSettingComponent } from './exam-setting/exam-setting.component';
import { GradeScaleGroupComponent } from './grade-scale-group/grade-scale-group.component';
import { GradeScaleComponent } from './grade-scale/grade-scale.component';
import { WeightagesCBSEComponent } from './weightages-cbse/weightages-cbse.component';
import { WeightagesICSEComponent } from './weightages-icse/weightages-icse.component';


const routes: Routes = [
  {path:'examsetting',component: ExamSettingComponent},
  {path:'examsetting/examcategory',component:ExamCategoryComponent},
  {path:'examsetting/gradescalegroup',component:GradeScaleGroupComponent},
  {path:'examsetting/gradescale',component:GradeScaleComponent},
  {path:'examsetting/weightagesCBSE',component:WeightagesCBSEComponent},
  {path:'examsetting/weightagesICSE',component:WeightagesICSEComponent},
  {path:'examsetting/assignweightagesICSE',component:AssignWeightagesICSEComponent},
  {path:'examsetting/assignweightagesCBSE',component:AssignWeightagesCBSEComponent},
  {path:'examsetting/designation',component:DesignationComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExamSettingRoutingModule { }
