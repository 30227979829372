import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IBackupSetting } from './backup-restore.model';
import { BackupAndRestoreLogs } from './backup-restore.model';

@Injectable({
    providedIn: 'root'
  })
  export class BackupSettingService {
  
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    private basePath;
    constructor(private httpClient: HttpClient) {
      this.basePath = environment.apiUrl;
    }

    getAll(): Observable<BackupAndRestoreLogs[]> {
      return this.httpClient.get<BackupAndRestoreLogs[]>(environment.apiUrl + '/BackupAndRestoreLogs/')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    createBackup(shift): Observable<BackupAndRestoreLogs> {
      debugger;
      return this.httpClient.post<BackupAndRestoreLogs>(environment.apiUrl + '/BackupAndRestoreLogs/', JSON.stringify(shift), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getBackupSetting() {
      return this.httpClient.get<IBackupSetting>(this.basePath + '/BackupSetting');
    }
  
    saveBackupSetting(backupSetting: IBackupSetting) {
      console.log(backupSetting);
      return this.httpClient.request<IBackupSetting>('put', this.basePath + '/BackupSetting/' + backupSetting.id,
      {
        body: backupSetting,
      });
    }
    errorHandler(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      return throwError(errorMessage);
    }
   
  }