import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApplicantRegistration, EntranceResult } from './applicantregistration.model';
import {ApplicantRegistrationFollowup} from '../applicantregistrationfollowup/applicantregistrationfollowup.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicantRegistrationService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<ApplicantRegistration[]> {
    return this.httpClient.get<ApplicantRegistration[]>(environment.apiUrl + '/ApplicantRegistration/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<ApplicantRegistration> {
    return this.httpClient.get<ApplicantRegistration>(environment.apiUrl + '/ApplicantRegistration/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  generateRegisterNo() {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(environment.apiUrl + '/ApplicantRegistration/GenerateRegisterNumber', {responseType: 'text'})
    .pipe(
      catchError(this.errorHandler)
    );
  }

  create(batch): Observable<ApplicantRegistration> {
    return this.httpClient.post<ApplicantRegistration>(environment.apiUrl +
       '/ApplicantRegistration/', JSON.stringify(batch), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, batch): Observable<ApplicantRegistration> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<ApplicantRegistration>(environment.apiUrl + '/ApplicantRegistration/' + id, JSON.stringify(batch), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<ApplicantRegistration>(environment.apiUrl + '/ApplicantRegistration/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  setentrancedate(registrationList, entranceDate, entranceTime): Observable<ApplicantRegistration> {
    return this.httpClient.post<ApplicantRegistration>(environment.apiUrl +
       '/ApplicantRegistration/SetEntranceDate/' + entranceDate + '/' + entranceTime,
        JSON.stringify(registrationList), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  saveentranceresult(resultList): Observable<EntranceResult> {
    return this.httpClient.post<ApplicantRegistration>(environment.apiUrl +
       '/ApplicantRegistration/SaveEntranceResult/', JSON.stringify(resultList), {headers: new HttpHeaders({
        'Content-Type': 'application/json', responseType: 'text'
      })})
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllFollowup(id): Observable<ApplicantRegistrationFollowup[]> {
    return this.httpClient.get<ApplicantRegistrationFollowup[]>(environment.apiUrl + '/ApplicantRegistration/GetFollowUps/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getFollowup(): Observable<ApplicantRegistrationFollowup[]> {
    return this.httpClient.get<ApplicantRegistrationFollowup[]>(environment.apiUrl + '/ApplicantRegistration/GetAllFollowUps/')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  createFollowup(batch): Observable<ApplicantRegistrationFollowup> {
    return this.httpClient.post<ApplicantRegistrationFollowup>(environment.apiUrl +
       '/ApplicantRegistration/CreateApplicationFollowup', JSON.stringify(batch), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
