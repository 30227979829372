import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs"; 
import { GradescalegroupService } from "../services/gradescalegroup.service";
import { SettingsService } from '../../mastersettings/settings.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Student } from './../../studentmanagement/student.model';
import { StudentService } from './../../studentmanagement/student.service';
import { StudentWiseReportCardService } from "../services/student-wise-report-card.service";

@Component({
  selector: 'app-student-wise-report-card',
  templateUrl: './student-wise-report-card.component.html',
  styleUrls: ['./student-wise-report-card.component.css'],
  providers: [FormGroupDirective],
})
export class StudentWiseReportCardComponent implements OnInit {
  submitted = false; 
  categorydropDown: any = [];
  academicyears: Academicyear[] = [];
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  academicYearId: number;
  finalexamNamedropDown: any = [];
  classDropDown: any = [];
  isLoading: boolean = false;
  section: any = [];
  saveDataModel = [];
  selectedRow:number;
  maxSubjectCount:number;
  subjectName:any;
  subStatus:any;

  GetAllStudentMarkForSelectedSubject: any = [];
  DetailsResult: any = [];
  disabled = true;

  constructor(private gradeService: GradescalegroupService,
    private toastr: ToastrService,
    public fb: UntypedFormBuilder,
    public service: StudentWiseReportCardService,
    public settingService:SettingsService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    public studentService: StudentService,
    public formDirective: FormGroupDirective) { }

    studentWiseReportCardForm: UntypedFormGroup;
    classBatachData: any = [];
    ngOnInit(): void {
      this.studentWiseReportCardForm = this.fb.group({
        id: [0],
        academicYearId: ["", Validators.required], 
        classId: ["", Validators.required],
        classBatch: ["", Validators.required],
        studentName: ["", Validators.required],
        examCategory: ["all", Validators.required]
      });
      this.ListAcademicYear();
      this.ListClassCourse();
    }

    onAcademicYearSelected(value: string)
    {
       this.academicYearId=Number(value);  
       this.getCategoryDropDown(value)  ;    
       console.log(this.academicYearId);       
    }
  
    get f() {
      return this.studentWiseReportCardForm.controls;
    }
    getCategoryDropDown(id) {
      if (this.studentWiseReportCardForm.value.academicYearId !== "") { 
        this.service.getAllCatefgoryDropDown(id).subscribe((data) => {
          this.categorydropDown = data;
        });
        this.checkingButton();
    }
    }

    
    onCategoryEvent() {
      this.studentWiseReportCardForm.value.examCategory = "";
      this.finalexamNamedropDown = [];      
      this.checkingButton();   
      this.studentWiseReportCardForm = this.fb.group({
        id: [0],
        academicYearId: [this.studentWiseReportCardForm.controls.academicYearId.value, Validators.required],
        classId: [this.studentWiseReportCardForm.controls.classId.value, Validators.required],
        classBatch: [this.studentWiseReportCardForm.controls.classBatch.value, Validators.required],
        studentName: [this.studentWiseReportCardForm.controls.studentName.value, Validators.required],
        examCategory: [this.studentWiseReportCardForm.controls.examCategory.value, Validators.required]
      });
    }
    submitForm() {}
  
    ListAcademicYear() {
      this.settingService.getAll().subscribe((data: Academicyear[]) => {
        this.academicyears = data;
        this.currentAcademicYear();
        this.checkingButton();
      });
      this.studentWiseReportCardForm = this.fb.group({
        id: [0],
        academicYearId: ["", Validators.required],
        classId: ["", Validators.required],
        classBatch: ["", Validators.required],
        studentName: ["", Validators.required],
        examCategory: ["all", Validators.required]
      });
    }
  
    currentAcademicYear() {
      this.settingService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
        this.academicYearId = data.id; 
        this.getCategoryDropDown(data.id)  ;         
        this.checkingButton();
      });
      this.studentWiseReportCardForm = this.fb.group({
        id: [0],
        academicYearId: [this.studentWiseReportCardForm.controls.academicYearId.value, Validators.required],
        classId: ["", Validators.required],
        classBatch: ["", Validators.required],
        studentName: ["", Validators.required],
        examCategory: ["all", Validators.required]
      });
    }
  
    ListClassCourse() {
      this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
        this.classDropDown = data;
        this.checkingButton();
      });

      this.studentWiseReportCardForm = this.fb.group({
        id: [0],
        academicYearId: [this.studentWiseReportCardForm.controls.academicYearId.value, Validators.required],
        classId: ["", Validators.required],
        classBatch: ["", Validators.required],
        studentName: ["", Validators.required],
        examCategory: ["all", Validators.required]
      }); 
    }

    
    selectedClass(id) {
      let batchList = [];
      if (this.studentWiseReportCardForm.value.classId !== "") {        
        this.batchService.getAll().subscribe((data: Batch[]) => {
          this.classBatachData = data.filter((batch: Batch) => batch.classId.toString() === id.toString());
        });
        this.checkingButton();
      }
      this.studentWiseReportCardForm = this.fb.group({
        id: [0],
        academicYearId: [this.studentWiseReportCardForm.controls.academicYearId.value, Validators.required],
        classId: [this.studentWiseReportCardForm.controls.classId.value, Validators.required],
        classBatch: ["", Validators.required],
        studentName: ["", Validators.required],
        examCategory: ["all", Validators.required]
      }); 
    }
  
    onBatchChanged(id) {
      this.admissions = [];     
      this.studentService.getAll().subscribe((data: Student[]) => {
        this.admissions = data.filter((student: Student) => {
         return student.academicYearId.toString() === this.studentWiseReportCardForm.controls.academicYearId.value.toString() &&
           student.classCourseId.toString() === this.studentWiseReportCardForm.controls.classId.value.toString() &&
           student.batchId.toString() === id.toString();
         });
         this.checkingButton();
      });
      this.studentWiseReportCardForm = this.fb.group({
        id: [0],
        academicYearId: [this.studentWiseReportCardForm.controls.academicYearId.value, Validators.required],
        classId: [this.studentWiseReportCardForm.controls.classId.value, Validators.required],
        classBatch: [this.studentWiseReportCardForm.controls.classBatch.value, Validators.required],
        studentName: [this.studentWiseReportCardForm.controls.studentName.value, Validators.required],
        examCategory: ["all", Validators.required]
      }); 
    }
  
  
  
    checkingButton() {
      if (
        this.studentWiseReportCardForm.controls.academicYearId.value &&
        this.studentWiseReportCardForm.controls.classId.value &&
        this.studentWiseReportCardForm.controls.classBatch.value &&
        this.studentWiseReportCardForm.controls.studentName.value &&
        this.studentWiseReportCardForm.controls.examCategory.value == "all" ? -1 : this.studentWiseReportCardForm.controls.examCategory.value 
      ) {
        this.disabled = false;
      } 
    }    
   
    disableFreezeButton: boolean = false;
    subjectId: any;
    studentIds: any[] = [];
    getallstudentMarks(){
      this.isLoading = true;
        if (this.studentWiseReportCardForm.valid) {

        // this.service.GetStudentReportCardSubject(this.studentWiseReportCardForm.controls.examCategory.value, this.studentWiseReportCardForm.controls.academicYearId.value, this.studentWiseReportCardForm.controls.classId.value,this.studentWiseReportCardForm.controls.classBatch.value,this.studentWiseReportCardForm.controls.studentName.value).subscribe((data)=>{
        //   this.DetailsResult=data;
        // });

        this.service.GetStudentReportCardSubject(this.studentWiseReportCardForm.controls.examCategory.value == "all" ? -1 : this.studentWiseReportCardForm.controls.examCategory.value,
          this.studentWiseReportCardForm.controls.academicYearId.value, 
          this.studentWiseReportCardForm.controls.classId.value,
          this.studentWiseReportCardForm.controls.classBatch.value,
          this.studentWiseReportCardForm.controls.studentName.value).subscribe((data)=>{
          this.DetailsResult=data;
          this.isLoading = false;
        });

      }
      else{
        this.toastr.warning("All field are required", "WARNING!!!", {
          timeOut: 3000,
        });
        this.isLoading = false;
        return;
      }
    }    
    enforceMinMax(el){
      // var x= document.getElementById("fullmark").innerHTML;
  
      // if(el.value != ""){
      //   if(parseInt(el.value) < parseInt(el.min)){
      //     el.value = 0;
      //   }
      //   if(parseInt(el.value) > parseInt(el.max)){
      //     el.value = 100;
      //   }
      // }
    }
  
    getMaxSubject(){
  
        // let total= this.StudentsList.length;    // 6
        // this.maxSubjectCount = this.StudentsList[0].studentMarks.length;
        // for(let i=0;i<total;i++)
        // {
        //     if(this.maxSubjectCount<this.StudentsList[i].studentMarks.length)
        //     {
        //       this.maxSubjectCount=this.StudentsList[i].studentMarks.length;
        //     }
        // }
   
    }
    
    resetForm() {
      this.submitted = false;
      this.finalexamNamedropDown = [];
      this.classDropDown = [];
      this.classBatachData = [];
      this.saveDataModel = [];
      this.formDirective.reset();
      this.studentWiseReportCardForm.reset();
      this.disabled = true;
      this.studentWiseReportCardForm = this.fb.group({
          id: [0],
          examCategory: ["", Validators.required],
          examName: ["", Validators.required],
          classId: ["", Validators.required],
          classBatch: ["", Validators.required],
        });
    }

}
