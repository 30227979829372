import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {ClassCourseService} from './classcourse.service';
import { ClassCourse } from '../models/classcourse';

@Component({
  selector: 'app-classcourse',
  templateUrl: './classcourse.component.html',
  styleUrls: ['./classcourse.component.css']
})
export class ClasscourseComponent implements OnInit {
  options = {
    autoClose: true,
    keepAfterRouteChange: false
};

classcourseForm: UntypedFormGroup;
classcourses: ClassCourse[] = [];
classcourseFilteredList: ClassCourse[] = [];
submitted = false;
ClickedRow:any;
HighlightRow:Number;

  constructor(
      public classcourseService: ClassCourseService,
      public fb: UntypedFormBuilder,
      private toastr: ToastrService
  )  { 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      console.log(data);
      this.classcourseFilteredList = this.classcourses = data;
    });
}

onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

filter(keyword: any) {
  if ( keyword === '') {
    this.classcourseFilteredList = this.classcourses;
  } else {
  this.classcourseFilteredList = this.classcourses
                              .filter((classcourse: ClassCourse) => classcourse.classCourseName.toLocaleLowerCase()
                              .includes(keyword.toLocaleLowerCase()));
  }
}

get totalRows(): number {
  return this.classcourseFilteredList.length;
}
get f() { return this.classcourseForm.controls; }
resetForm() {
  this.submitted = false;
  this.classcourseForm = this.fb.group({
    id: [0],
    classCourseName: ['', Validators.required],
    classCode: ['', Validators.required],
    status: ['true', Validators.required],
    isDeleted: false
  });
}
submitForm() {
  this.submitted = true;

      // stop here if form is invalid
  if (this.classcourseForm.invalid) {
          return;
    }

  if (this.classcourseForm.get('id').value === 0) {
      this.classcourseService.create(this.classcourseForm.value).subscribe(res => {
        console.log('Class year created!');
        this.ListClassCourse();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.classcourseService.update(this.classcourseForm.get('id').value, this.classcourseForm.value).subscribe(res => {
          console.log('Academic year created!');
          this.ListClassCourse();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
      }


    }

    updateForm(id: number) {
      this.classcourseService.getById(id).subscribe((data: ClassCourse) => {
        console.log(data);
        this.classcourseForm = this.fb.group({
          id: [data.id],
          classCourseName: [data.classCourseName, Validators.required],
          classCode: [data.classCode, Validators.required],
          status: [data.status.toString(), Validators.required],
          isDeleted: false
        });
      });

    }

    deleteForm(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.classcourseService.delete(id).subscribe(res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            this.ListClassCourse();
          });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
        }
      });
    }

  ngOnInit(): void {
    this.ListClassCourse();
    this.classcourseForm = this.fb.group({
      id: [0],
      classCourseName: ['', Validators.required],
      classCode: ['', Validators.required],
      status: ['true', Validators.required],
      isDeleted: false
    });
  }

}
