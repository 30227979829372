import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeaveManagementRoutingModule } from './leavemanagement-routing.module';
import { LeaveComponent } from './leave/leave.component';
import { LeavereportComponent } from './leavereport/leavereport.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [LeaveComponent, LeavereportComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LeaveManagementRoutingModule,
    SharedModule
  ],
  exports:[
    SharedModule
  ]
})
export class LeavemanagementModule { }
