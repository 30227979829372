import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Demomanagementservice } from './demomanagement.service';
import { LeadCreationService } from '../leadcreation/leadcreation.service';
import { LeadCreationModel } from '../leadcreation/leadcreation.model';
import { DemoManagementModel, LeadDetailsModel } from './demomanagement.model';
import { HttpClient } from '@angular/common/http';
import { ChartComponent, ChartType } from 'ng-apexcharts';
declare var $: any;
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  legend: any;
  responsive: ApexResponsive[];
  labels: any;
};
@Component({
  selector: 'app-demomanagement',
  templateUrl: './demomanagement.component.html',
  styleUrls: ['./demomanagement.component.css']
})
export class DemomanagementComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('startDate') startDate: ElementRef;
  @ViewChild('endDate') endDate: ElementRef;
  public chartOptions: Partial<ChartOptions>;

  chartData: number[] = [];
  chartLables: string[] = [];
  chartType: ChartType = 'pie';

  demoupdationForm: UntypedFormGroup;
  submitted = false;
  ipAddress = '';
  /*Ticket History*/
  totalDemoCount: number;
  pendingDemoCount: number;
  rejectedDemoCount: number;
  completedDemoCount: number;
  scheduledDemoCount: number;
  /*End*/
  /*Demo Update (Pop up)*/
  leadId: number;
  leadNo: string;
  leadDate: string;
  enquiredBy: string;
  contactNumber: string;
  location: string;
  institutionType: string;
  status: string;
  remarks: string;
  updateBySMS: string;
  /*End*/
  demolist: DemoManagementModel[] = [];
  demoFilteredList: DemoManagementModel[] = [];
  leadlist: LeadCreationModel[] = [];
  leadFilteredList: LeadCreationModel[] = [];
  demoupdatedList: DemoManagementModel[] = [];
  demoupdatedFilteredList: DemoManagementModel[] = [];
  leadTempList: LeadCreationModel[] = [];
  statusDrp = 'All';
  dateFrom: string;
  dateTo: string;
  demoCounts: any = [];

  constructor(
    public demoManagementService: Demomanagementservice,
    public leadCreationService: LeadCreationService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private http: HttpClient

  ) {
    this.loadChartData();
  }

  leadList() {

    const self = this;
    this.leadCreationService.getAll().subscribe((data: LeadCreationModel[]) => {
      this.chartLables = [];
      this.demoCounts = [];
      this.leadlist = data;
      this.leadFilteredList =data.filter(d=>d.demoStatus==='Scheduled');
      this.totalDemoCount = this.leadFilteredList.length;
      this.pendingDemoCount = data.filter((element) => {
        return element.demoStatus === 'Pending';
      }).length;

      this.rejectedDemoCount = data.filter((element) => {
        return element.demoStatus === 'Rejected';
      }).length;
      // this.myArray.add(this.rejectedDemoCount);
      this.completedDemoCount = data.filter((element) => {
        return element.demoStatus === 'Completed';
      }).length;
      // this.arr.push(this.completedDemoCount);

      this.scheduledDemoCount = data.filter((element) => {
        return element.demoStatus === 'Scheduled';
      }).length;
      this.demoCounts.push(self.pendingDemoCount, self.completedDemoCount, self.scheduledDemoCount, self.rejectedDemoCount);
      this.chartLables.push('Pending', 'Completed', 'Scheduled', 'Rejected');
      this.loadChartData();
    });
  }

  loadChartData() {
    this.chartOptions = {
      series: this.demoCounts, // chart data array
      chart: {
        width: 350,
        height: 300,
        type: this.chartType // chart type such as pie,donut etc
      },
      legend: {
        position: 'bottom'
      },
      labels: this.chartLables,    // chart labesl like Whatsapp,SMS etc.
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: { width: 200 }, legend: { position: "bottom" }

          }
        }
      ]
    };
  }

  demoList() {
    this.demoManagementService.getAll().subscribe((data: DemoManagementModel[]) => {
      this.demoFilteredList = this.demolist = data;
      this.totalDemoCount = this.demoFilteredList.length;
      /*this.pendingDemoCount = data.filter(function(element){
      return element.demoStatus =="Pending";
      }).length

     this.rejectedDemoCount=data.filter(function(element){
      return element.demoStatus=='Rejected';
     }).length

     this.completedDemoCount=data.filter(function(element){
     return element.demoStatus == 'Completed';
     }).length*/

    });
  }
  updatedDemoList() {
    this.demoManagementService.getUpdatedDemoList(this.leadId).subscribe((data: DemoManagementModel[]) => {
      this.demoupdatedFilteredList = this.demoupdatedList = data;
     /// this.leadList();
    });
  }
  get f() { return this.demoupdationForm.controls; }
  /*Update demo*/
  demoDetails(id: number) {
    debugger;
    this.leadId = id;
    this.leadCreationService.getById(id).subscribe((data: LeadCreationModel) => {
      this.leadNo = data.leadCode;
      this.leadDate = data.leadDate;
      this.enquiredBy = data.enquiredBy;
      this.contactNumber = data.mobileNo;
      this.location = data.location;
      this.status = data.demoStatus;
      this.remarks = data.remarks;
      this.updatedDemoList();
    });
    
  }
  updateDemo() {
    this.submitted = true;
    // this.leadFollowupForm.get("leadcode").patchValue(this.LeadID);
    // stop here if form is invalid
    this.demoupdationForm.get('leadId').patchValue(this.leadId);
    if (this.demoupdationForm.invalid) {
      console.log(this.demoupdationForm.value);
      return;
    }
    // if (this.demoupdationForm.get('id').value === 0) {

    // this.demoupdationForm.get('leadCode').patchValue(this.leadNo);
    this.demoManagementService.create(this.demoupdationForm.value).subscribe(res => {
      // this.ListServices();
      this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
        timeOut: 3000
      });
      this.updatedDemoList();
      this.submitted = false;
      this.resetForm();
      this.closeModal();
    });
    // } else {
    // this.demoManagementService.update(this.demoupdationForm.get('id').value, this.demoupdationForm.value).subscribe(res => {
    // this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
    // timeOut: 3000
    // });
    // this.submitted = false;
    // this.resetForm();

    // });
    // }


  }
  private closeModal(): void {
    this.closeBtn.nativeElement.click();
    document.getElementById('close').click()
  }
  /*End*/
  resetForm() {

    const self = this;
    this.demoupdationForm = this.fb.group({
      id: [0],
      leadId: [self.leadId],
      demoStatus: ['', Validators.required],
      remarks: ['', Validators.required],
      isUpdateBySms: [false, Validators.required]
    });
    this.updatedDemoList();
  }
  getIPAddress() {
    // this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
    //   this.ipAddress = res.ip;
    // });
  }

  filterData() {
    debugger;
    this.leadTempList=[];
    this.leadTempList = this.leadlist;
    $('#dateFrom').datepicker().on('changeDate', (e) => {

      self.dateFrom = e.date;

    });
    $('#dateTo').datepicker().on('changeDate', (e) => {

      self.dateTo = e.date;

    });
    debugger;
    const self = this;
    const drpValue = this.statusDrp;
    //const fromDate = this.startDate.nativeElement.value;
    const fromDate = this.dateFrom;
    const toDate = this.dateTo;
    //const toDate = this.endDate.nativeElement.value;
    const resultDateFilteredList: any = [];
    let startDt: Date;
    let endDt: Date;

    if (typeof this.dateFrom !== 'undefined' && typeof this.dateTo !== 'undefined') {
      startDt = new Date(fromDate);
      endDt = new Date(toDate);
    }else{
      this.leadFilteredList = this.leadTempList;
    }
    if (drpValue === 'All' || drpValue === '' || drpValue === 'Status' || drpValue === 'undefined') {
      if (typeof this.dateFrom !== 'undefined' && typeof this.dateTo !== 'undefined') {
        this.leadTempList = this.leadTempList.filter(d => {
          const date = new Date(d.createdDate);
          return (startDt.getTime() <= date.getTime() && endDt.getTime() >= date.getTime());
        });
      }
      this.leadFilteredList = this.leadTempList;
    } else {
      if (drpValue === 'Completed') {
        this.leadTempList = this.leadTempList.filter((leadlist) => {
          return (leadlist.demoStatus === 'Completed');
        });
      } else if (drpValue === 'Scheduled') {
        this.leadTempList = this.leadTempList.filter((leadlist) => {
          return (leadlist.demoStatus === 'Scheduled');
        });
      } else {
        this.leadTempList = this.leadTempList.filter((leadlist) => {
          return (leadlist.demoStatus === drpValue);
        });
      }
      if (typeof this.dateFrom !== 'undefined' && typeof this.dateTo !== 'undefined') {
        this.leadTempList = this.leadTempList.filter(d => {
          const date = new Date(d.createdDate);
          return (startDt.getTime() <= date.getTime() && endDt.getTime() >= date.getTime());
        });
      }
      this.leadFilteredList = this.leadTempList;
    }

  }
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    this.leadTempList=this.leadlist;
    if (keyword === '') {
      this.leadTempList = this.leadFilteredList;
      // this.leadCreationFilteredList=this.leadcreationServiceList;
      this.filterData();
    } else {
      this.leadTempList = this.leadlist.filter(d => {

        return (
         // d.leadCode.toLowerCase().includes(keyword.toLowerCase()) ||
          //d.source !== null ? d.source.toLowerCase().includes(keyword) : '' ||
          //d.institutionName.toLowerCase().includes(keyword.toLowerCase()) ||
          //d.location !== null ? d.location.toLowerCase().includes(keyword) : '' ||
          //d.mobileNo!==null ? d.mobileNo.toLowerCase().includes(keyword.toLowerCase()):''
          d.leadCode.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
          (d.source !== null && d.source.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())) ||
          (d.mobileNo !== null && d.mobileNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())) ||
          (d.location !== null && d.location.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())) ||
          d.institutionName.toLowerCase().includes(keyword.toLocaleLowerCase())
          );
      });
      this.leadFilteredList = this.leadTempList;
    }
  }
  onStatusChange(value) {
    this.statusDrp = value;
    this.filterData();
  }


  ngOnInit(): void {

    $('#dateFrom').datepicker().on('changeDate', (e) => {

      self.dateFrom = e.date;

    });
    $('#dateTo').datepicker().on('changeDate', (e) => {

      self.dateTo = e.date;

    });

    this.leadList();
    const self = this;
    this.demoupdationForm = this.fb.group({
      id: [0],
      leadId: [self.leadId],
      demoStatus: ['', Validators.required],
      remarks: ['', Validators.required],
      isUpdateBySms: [false, Validators.required]
    });

  }

  resetFilter(){
    this.leadList();
    this.statusDrp="All";
    $('#dateFrom').val('');
    $('#dateTo').val('');
  }
}
