import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Department } from './models/departement.model';




@Injectable({
  providedIn: 'root'
})

export class DepartmentService {


    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<Department[]> {
      return this.httpClient.get<Department[]>(environment.apiUrl + '/departments')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<Department> {
      return this.httpClient.get<Department>(environment.apiUrl + '/departments/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    isCurrentAcademicYearExits(): Observable<Department> {
      return this.httpClient.get<Department>(environment.apiUrl + '/departments/IsCurrentAcademicYearExits')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    create(academicyear): Observable<Department> {
      if (academicyear.status === '0') {
        academicyear.active = false;
      }
      return this.httpClient.post<Department>(environment.apiUrl + '/departments/', JSON.stringify(academicyear), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    update(id, academicyear): Observable<Department> {
      // tslint:disable-next-line:max-line-length
      if (academicyear.status === '0') {
        academicyear.active = false;
      }
      return this.httpClient.put<Department>(environment.apiUrl + '/departments/' + id, JSON.stringify(academicyear), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    delete(id) {
      return this.httpClient.delete<Department>(environment.apiUrl + '/departments/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }
  }
