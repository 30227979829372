import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StudentCertificate } from './generatecertificate.models';

@Injectable({
    providedIn: 'root'
  })
  export class GenerateCertificateService {
  
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    private basePath;
    constructor(private httpClient: HttpClient) {
      this.basePath = environment.apiUrl;
    }

    getAll(): Observable<StudentCertificate[]> {
      return this.httpClient.get<StudentCertificate[]>(environment.apiUrl + '/StudentCertificate/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
  
    create(certificate): Observable<StudentCertificate> {
      debugger;
      return this.httpClient.post<StudentCertificate>(environment.apiUrl + '/StudentCertificate/', JSON.stringify(certificate), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    errorHandler(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      return throwError(errorMessage);
    }
   
  }