import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../authentication.service';
import { Authentication } from '../authentication.model';
import { SharedService } from '../../shared/shared.service';
import { LoginPageConfig } from '../../edumaq/configuration/loginpageconfig/loginpageconfig.model';
import { LoginService } from '../login/login.service'; 
import { duration } from 'moment';
import { InstitutionprofileService } from 'src/app/admin/academic/institutionprofile/institutionprofile.service';
import { environment } from 'src/environments/environment';
import { Institution } from 'src/app/admin/admin/institution.model';
import { AdminService } from 'src/app/admin/admin/admin.service';
import { InstitutionProfile } from 'src/app/admin/academic/institutionprofile/institutionprofile.model';
import { InstitutionVerificationModel } from 'src/app/admin/academic/institutionprofile/institutionverificationmodel';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-findschool',
  templateUrl: './findschool.component.html',
  styleUrls: ['./findschool.component.css']
})

export class FindschoolComponent implements OnInit, OnDestroy {
  form: FormGroup;
  userName = '';
  password = '';
  iserror = false;
  imageSrc = '../assets/images/Logbanner/8.png';
  instCode = '';
  logoUrl = environment.imageUrl;
  institution: Institution;
  loader: any = false;
  schoolcode: string = '';
  schoolNotFoundErrorMessage:string = '';
  verifySchoolSuscription$: any;
  constructor(private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private loginService: LoginService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private profileService: InstitutionprofileService,
    private adminService: AdminService,
  ) { }

  btnContinue() {
    try {
      if (this.form.valid) {
        this.schoolcode = this.form.get('schoolCode').value;
        this.verifySchoolSuscription$ = this.profileService.verifyInstitution(this.schoolcode).subscribe(data=>{
          const instProfile = data as InstitutionVerificationModel;
          if(instProfile && instProfile.isVerified === true && instProfile?.detail?.institutionCode === this.schoolcode){
            this.router.navigateByUrl(instProfile.detail.institutionCode)
          }
          else{
            this.showErrorMessage();
          }
        }, (error)=> {
          console.log(error);
          // this.showErrorMessage();
        })
      } else {
        // Form is invalid
        // You can mark fields as touched to show validation messages
        this.markFormGroupTouched(this.form);
      }  
    }
    catch(error){
      console.log(error);
    }
  }  

  ngOnDestroy(): void {
    this.verifySchoolSuscription$.unsubscribe();
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
    });
  }
  
  showErrorMessage(){
    this.schoolNotFoundErrorMessage = 'School Not Found';
    this.toastr.error('School Not Found',"ERROR!!",{timeOut: 5000});
    // Swal.fire('School not found', 'ERROR!', 'error');
    // this.toastr.error(this.schoolNotFoundErrorMessage);
    // setTimeout(()=>{
    //   this.schoolNotFoundErrorMessage = '';
    // },10000)
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      schoolCode: ['', Validators.required]
    });
  }

}
