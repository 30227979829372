import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FeeHead } from './feehead.model';
//import { promise } from 'protractor';

@Injectable({
    providedIn: 'root'
  })
  export class FeeHeadService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         enctype: 'multipart/form-data'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<FeeHead[]> {
      return this.httpClient.get<FeeHead[]>(environment.apiUrl + '/feehead/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getSpecialFeeHeads(): Observable<FeeHead[]> {
      return this.httpClient.get<FeeHead[]>(environment.apiUrl + '/feehead/getSpecialFeeHeads/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<FeeHead> {
      return this.httpClient.get<FeeHead>(environment.apiUrl + '/feehead/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getByInstallmentId(id, classId,batchId, admissionId): Observable<FeeHead[]> {
      return this.httpClient.get<FeeHead[]>(environment.apiUrl + '/feehead/GetFeeHeadByInstallment/' + id +
       '/' + classId + '/' + batchId + '/' + admissionId)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    // getByInstallmentId_promis(id, classId, admissionId): Observable<FeeHead[]> {
    //   return this.httpClient.get<FeeHead[]>(environment.apiUrl + '/feehead/GetFeeHeadByInstallment/' + id +
    //    '/' + classId + '/' + admissionId)
    //   .pipe(
    //     catchError(this.errorHandler)
    //   );
    // }

    public async getByInstallmentId_promis(id, classId, admissionId): Promise<FeeHead[]> 
    {

      return this.httpClient.get<FeeHead[]>(environment.apiUrl + '/feehead/GetFeeHeadByInstallment/' + id +
       '/' + classId + '/' + admissionId).toPromise()
    }

    getSpecialByClassId(classId): Observable<FeeHead[]> {
      return this.httpClient.get<FeeHead[]>(environment.apiUrl + '/feehead/GetSpecialFeeHeadByClassId/' + classId)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    create(feehead): Observable<any> {
      return this.httpClient.post(environment.apiUrl + '/feehead/', feehead)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    update(id, feehead): Observable<FeeHead> {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.put<FeeHead>(environment.apiUrl + '/feehead/' + id, JSON.stringify(feehead), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    delete(id) {
      return this.httpClient.delete<FeeHead>(environment.apiUrl + '/feehead/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }

  }
