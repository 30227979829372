import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeeCollectionReportService } from './feecollectionreport.service';
import { FeeCollectionReportModel } from './feecollectionreport.model';
import { ClassCourse } from '../../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../../mastersettings/models/batch';
import { BatchService } from '../../../mastersettings/batch/batch.service';
import { FeeReciept, FeeRecieptDetailDetail} from '../feereceipt/feereceipt.model';
import {SettingsService} from '../../../mastersettings/settings.service';
import { Academicyear } from '../../../mastersettings/models/academicyear';
import { ReportAttribute } from '../../../studentmanagement/report.model';
import { environment } from '../../../../../../environments/environment';
import { Student } from './../../../studentmanagement/student.model';
import { StudentService } from './../../../studentmanagement/student.service';
import { getTime } from 'ngx-bootstrap/chronos/utils/date-getters';
//import { InstallmentSummaryModel } from '../installmentsummary/installmentsummary.model';
import { reduce } from 'rxjs/operators';
import { borderTopRightRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import {  DatePipe, formatDate } from '@angular/common';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import { PaymentMode } from '../../../mastersettings/models/paymentmode';
import { PaymentModeService } from '../../../mastersettings/paymentmode/paymentmode.service';
declare var $: any;

@Component({
  selector: 'app-feecollectionreport',
  templateUrl: './feecollectionreport.component.html',
  styleUrls: ['./feecollectionreport.component.css']
})
export class FeecollectionreportComponent implements OnInit {
  classId: string;
  academicId:number;
  batch: string;
  mode: string;
  fromDate: string;
  toDate: string;
  dates:string;
  totalPaidAmount: any;
  advanceAdjusted:any;
  dataCount: any;
  recptid:any;

  dateFrom: any;
  dateTo: any;
  paymentMode: string;
  feeCollectionReportList: FeeCollectionReportModel[] = [];
  feeCollectionReportFilteredList: FeeCollectionReportModel[] = [];
  feeCollectionReportTemp: FeeCollectionReportModel[] = [];
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  academicYear:Academicyear[]=[];
  feeReciept: FeeReciept = {} as FeeReciept;
  feeHeads: FeeRecieptDetailDetail[] = [];
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  institution: any;
  currentAcademicYearName = '';
  lblDueAdvance = 'Due';
  lblDueAdvanceValue=0;
  public FeeReceiptList: any;
  public FeeHeadItemList: any;
  public FeeHeadDataList:any=[];
  public FeeReceiptDataobj: any={};
  public InstallmentNameList: any=[];
  currentAcademicYearId: number;
  paymentsModes: PaymentMode[];
  loader: any = false;
  constructor(
    public feeCollectionReportService: FeeCollectionReportService,
    public classCourseService: ClassCourseService,
    public batchService: BatchService,
    public settingsService:SettingsService,
    private paymentModeService:PaymentModeService
  ) {
    this.GetCurrentAcademicYear()
   }
  ListClassCourse() {
    this.classCourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }
  ListAcademicYear(){
    this.settingsService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYear = data;
      this.academicId=this.academicYear.filter(x=>x.isCurrentAcademicYear==true).map(x=>x.id)[0];
    });
  }

  onClassSelected(value: string) {
    this.classId = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.className.toString() === value.toString());
    });
    this.onSearch();
  }
  

  setCurrentDate() {
    let date = new Date();
    this.dateFrom=new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.dateTo=new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }
  print(){
    if(this.feeCollectionReportFilteredList.length>0){
      localStorage.setItem('feecollectionprint', JSON.stringify(this.feeCollectionReportFilteredList));
    }
   else{
    localStorage.setItem('feecollectionprint', '');
   }
  }
  generateAttributes() {
    this.attributes = [
      {name: 'SNO', fieldName: 'index' },
      {name: 'DATE', fieldName: 'payDate' },
      {name: 'RECEIPT', fieldName: 'receiptNo' },
      {name: 'ADMS NO', fieldName: 'admissionNo' },
      {name: 'STUDENT NAME', fieldName: 'studentName' },
      {name: 'CLASS', fieldName: 'class' },
      {name: 'BATCH', fieldName: 'batch' },
      {name:"TRAN MODE",fieldName:'transactionMode'},
      {name: 'MODE', fieldName: 'payMode' },
      {name: 'TAKEN BY', fieldName: 'takenBy' },
      {name: 'STATUS', fieldName: 'paidStatus' },
      {name: 'ROLL', fieldName: 'rollNo' },
      {name: 'PAID', fieldName: 'paidAmount' }
    ];
  }

  onSearch() {
   
   this.loader=true;
    const drpClass = this.classId;
    const drpBatch = this.batch;
    const drpPayment = this.paymentMode;
    const fromDate = new Date(this.dateFrom).setHours(0,0,0,0);
    const toDate = new Date(this.dateTo).setHours(0,0,0,0);
    const datePipe = new DatePipe('en-Us');
    var fdt=datePipe.transform(this.dateFrom, 'dd-MM-yyyy');
    var tdt=datePipe.transform(this.dateTo, 'dd-MM-yyyy');

    this.dates=fdt+' To '+tdt;
    this.feeCollectionReportTemp = this.feeCollectionReportFilteredList = this.feeCollectionReportList;

    console.log("Academic year filtering id:" + this.academicId);
    if(this.academicId > 0)
    {
      this.feeCollectionReportTemp = this.feeCollectionReportTemp.filter(d=> {
        console.log("Academic year filtered id:" + this.academicId + " -- " + d.academicId);
        return (d.academicId == this.academicId)
      });
    }
    if (typeof this.dateFrom != 'undefined' && typeof this.dateTo != 'undefined' && this.dateFrom != '' && this.dateTo != '') {
      this.feeCollectionReportTemp = this.feeCollectionReportTemp.filter(d => {
        
        var date = new Date(d.payDate.split('T')[0]).setHours(0,0,0,0);
        //console.log("from " + fromDate + " todate " + toDate + " date "+ date + " test" + (date >= fromDate) +" --" + (date <= toDate));    return (date >= fromDate && date <= toDate);
        return(date >=fromDate && date<=toDate);
      });
    }
    console.log(this.feeCollectionReportTemp);
    if (!drpClass.startsWith("All") && drpClass != "" && drpClass != 'undefined') {
      this.feeCollectionReportTemp = this.feeCollectionReportTemp.filter(d => {
        return (d.class.toLowerCase() === drpClass.toLowerCase());
      });
    }
    if (!drpBatch.startsWith("All") && drpBatch != "" && drpBatch != 'undefined') {
      this.feeCollectionReportTemp = this.feeCollectionReportTemp.filter(d => {
        if (this.classId !== '') {
          return (d.batch.toLocaleLowerCase().includes(drpBatch.toLocaleLowerCase()) && 
            d.class.toLowerCase() === this.classId.toLowerCase()
          );
        } else {
          return (d.batch.toLocaleLowerCase().includes(drpBatch.toLocaleLowerCase()));
        }
      });
    }
    if (!drpPayment.startsWith("All") && drpPayment != "" && drpPayment != 'undefined') {
      this.feeCollectionReportTemp = this.feeCollectionReportTemp.filter(d => {
        return (d.payMode.toLocaleLowerCase().includes(drpPayment.toLocaleLowerCase()));
      });
    }
    this.feeCollectionReportFilteredList = this.feeCollectionReportTemp;
    this.getSum();
  this.loader=false;

  }

  onAcademicYearChange(value : number){
    this.academicId = value;
    this.onSearch();
  }

  GetCurrentAcademicYear() {
    this.settingsService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYear = data;
      this.currentAcademicYear();
    });
  }

  currentAcademicYear() {
    this.settingsService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
     
      this.currentAcademicYearId = data.id;
      this.currentAcademicYearName = this.academicYear.find(x => x.id.toString() === data.id.toString()).name;
     
    });
  }

  onPaymentMode() {
    this.onSearch();
  }
  onBatch() {
    this.onSearch();
  }
  ListPaymentModes() {
    this.paymentModeService.getAll().subscribe((data:PaymentMode[])=>
    {
      this.paymentsModes=data;
    });  
    }
  getFeeCollectionReport() {
    this.loader=true;
    this.feeCollectionReportService.getFeeCollectionReport().subscribe((data: FeeCollectionReportModel[]) => {
      this.ListPaymentModes();
      
      this.feeCollectionReportFilteredList = this.feeCollectionReportList = data;
      this.dataCount = data.length;
      this.getSum();
      this.onSearch();
      this.loader=false;
    });

    this.feeCollectionReportTemp = this.feeCollectionReportList;
  }
  
  getPayeModeName(id:number):string{
    return this.paymentsModes.find(x=>x.id==id).paymentModeName;
  }

  get totalRows(): number {
    return this.feeCollectionReportFilteredList.length;
  }

  public formatDate(date,onlyDate =false) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return !onlyDate ? new Date().toLocaleDateString() : [day, month, year].join('-');
  }

  resetAll() {
    this.loader=true;
    const self = this;
    this.paymentMode = 'All';
    this.batch = 'All';
    this.classId = 'All';
    var date=new Date();
    this.dateFrom = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.dateTo = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    $('#dateFrom').datepicker('setDate', this.dateFrom);
    $('#dateFrom').datepicker().on('changeDate', (e) => {
      self.dateFrom = e.date;
    });

    $('#dateTo').datepicker('setDate', this.dateTo);
    $('#dateTo').datepicker().on('changeDate', (e) => {
      self.dateTo = e.date;
    });

    this.feeCollectionReportFilteredList = this.feeCollectionReportList;
    this.loader=false;
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  searchValidate(filtervalue, keyword) {
     if(filtervalue) {
       var filterAttribute = filtervalue + "";
       return filterAttribute.toLocaleLowerCase().includes(keyword.toLocaleLowerCase());
     } else {
      return false;
     }
  }

  serachTakenBy(filtervalue, keyword) {
    if(filtervalue) {
      var filterAttribute = filtervalue == 0 ? "Admin" : "";
      return filterAttribute.toLocaleLowerCase().includes(keyword.toLocaleLowerCase());
    } else {
     return false;
    }
  }

  filter(keyword: any) {
    if (keyword === '') {
      this.getFeeCollectionReport();
    } else {
      this.feeCollectionReportTemp = this.feeCollectionReportTemp.filter(d => {
        return (d.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
           this.searchValidate(d.receiptNo, keyword) ||
           d.payMode.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
           d.transactionMode.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
           this.searchValidate(d.paidAmount, keyword) || 
           this.searchValidate(d.takenBy, keyword) || 
           d.class.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
           d.batch.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
           d.admissionNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()));
      });
      this.feeCollectionReportFilteredList = this.feeCollectionReportTemp;
      this.getSum();
    }
  }
  // onKeyUpEvent(event: any) {
  //   this.filter(event.target.value);
  // }
  
  // filter(keyword: any) {
  //   if (keyword === '') {
  //     this.admissionsfilteredlist = this.selectedadmissionlist;
  //   } else {
  //     this.admissionsfilteredlist = this.admissionsfilteredlist
  //       .filter((admission: Student) =>
  //         admission.studentName.toLocaleLowerCase()
  //           .includes(keyword.toLocaleLowerCase()) ||
  //           admission.fatherName.toLocaleLowerCase()
  //           .includes(keyword.toLocaleLowerCase()) ||
  //           admission.admissionNo.toLocaleLowerCase()
  //           .includes(keyword.toLocaleLowerCase())
  //           );
  //   }
  // }
  





  getSum(){
      const sum = this.feeCollectionReportFilteredList.map(item => parseFloat(item.paidStatus == false ? item.paidAmount : '0')).reduce((prev, curr) => prev + curr, 0);
      this.totalPaidAmount = sum;
  }
  getRecieptPrint(id: any) {
    return;

  }
  getReciept(id: any) {


    
    // id = id.split(',')[0];

    // id = id.split(',')[0];

    this.feeReciept = {} as FeeReciept;
   ;
    this.recptid=id;
    this.feeCollectionReportService.getFeeReciept(id).subscribe((data) => {
      this.feeReciept = {} as FeeReciept;
      console.log(data);      
      this.feeReciept = data[0];
      this.FeeReceiptList =[];
      this.FeeReceiptList = data;
      this.FeeHeadItemList = this.FeeReceiptList[0].feeCollectionHeadInstallment.map(item => item.feeHeadName).filter((value, index, self) => self.indexOf(value) === index)
      this.InstallmentNameList = this.FeeReceiptList[0].feeCollectionHeadInstallment.map(_item => _item.installmentName).filter((value, index, self) => self.indexOf(value) === index)
      this.InstallmentNameList = this.InstallmentNameList.toString();
     ;
      this.FeeReceiptDataobj={
      ReceiptNo: this.FeeReceiptList[0].receiptNo,
      StudentName: this.FeeReceiptList[0].studentName,
      ClassCourseName: this.FeeReceiptList[0].classCourseName,
      BatchName: this.FeeReceiptList[0].batchName,
      AdmissionNo: this.FeeReceiptList[0].admissionNo,
      RollNo: this.FeeReceiptList[0].rollNo,
      Amount: this.FeeReceiptList[0].amount,
      Discount: this.FeeReceiptList[0].discount,
      PaidAmount: this.FeeReceiptList[0].paidAmount,
      PayableAmount: this.FeeReceiptList[0].payableAmount,
      FineAmount: this.FeeReceiptList[0].fineAmount,
      InstallmentAmount: this.FeeReceiptList[0].installmentAmount,
      DueAmount: this.FeeReceiptList[0].dueAmount,
      AdvanceAmount: this.FeeReceiptList[0].advanceAmount,
      PayDate: this.FeeReceiptList[0].payDate,
      PayMode: this.paymentsModes.filter(x=>x.id==this.FeeReceiptList[0].payMode)[0].paymentModeName,
     };
      this.FormatData(this.FeeReceiptDataobj,this.FeeReceiptList[0].feeCollectionHeadInstallment,this.FeeHeadItemList);
      this.lblDueAdvanceValue=0;
      this.advanceAdjusted=0;
      if (this.FeeReceiptList[0].advanceAmount > 0 ) {
        this.lblDueAdvance = 'ADVANCE';
        this.lblDueAdvanceValue=this.FeeReceiptList[0].advanceAmount;
      } else if (this.FeeReceiptList[0].dueAmount > 0) {
        this.lblDueAdvance = 'DUE';
         this.lblDueAdvanceValue=this.FeeReceiptList[0].dueAmount;
      }
      this.advanceAdjusted=this.FeeReceiptList[0].advanceAdjusted;
      this.feeReciept.feeCollectionDetails.forEach((fdata) => {
         fdata.feeCollectionFeeHeadDetails.forEach((x) => {
          this.feeHeads.push(x);
         });
      });
    });
  }

  FormatData(Obj: any, dataList:any=[], installamentNameList:any)
  {
    Obj.FeeHeadDataList = [];
    let TotalAmount = 0;
    //let TotalDiscountAmount = 0;
    for(let instItemName of installamentNameList)
    {
      let SumOfHeadItem = 0;
      let SumOfHeadItemDiscount = 0;
      let SumOfpayableamount=0;
     ;
      let HeadItemList =  dataList.filter(_installmentItem=>_installmentItem.feeHeadName==instItemName);
      for(let _headitem of HeadItemList)
      {
        SumOfHeadItem = SumOfHeadItem + Number(_headitem.amount);
        SumOfHeadItemDiscount = SumOfHeadItemDiscount + Number(_headitem.discountRate);
        SumOfpayableamount=SumOfpayableamount + Number(_headitem.amountPayable);
      }
      TotalAmount = TotalAmount + SumOfHeadItem;
      ///TotalDiscountAmount = TotalDiscountAmount + SumOfHeadItemDiscount;
      let FeeHeadDataObj ={
        FeeHeadName: instItemName,
        Amount: SumOfHeadItem,
        discountRate:SumOfHeadItemDiscount,
        paidamount:SumOfpayableamount
      }      
     ;
      Obj.FeeHeadDataList.push(FeeHeadDataObj);
    }
    Obj.TotalAmount = TotalAmount;
   // Obj.TotalDiscountAmount = TotalDiscountAmount;
  }

  generateExcel() {
    this.headerList =[];
    this.fieldList =[];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    this.defaultArray = [];
    for(var i = 0 ; i < this.feeCollectionReportFilteredList.length ; i++)
    {
      let data ={
        "index" : i+1,
        "payDate" : this.feeCollectionReportFilteredList[i].payDate == undefined ? "":this.feeCollectionReportFilteredList[i].payDate,
        "receiptNo" : this.feeCollectionReportFilteredList[i].receiptNo == undefined ?"": this.feeCollectionReportFilteredList[i].receiptNo ,
        "admissionNo" :this.feeCollectionReportFilteredList[i].admissionNo == undefined ? "" : this.feeCollectionReportFilteredList[i].admissionNo,
        "studentName" : this.feeCollectionReportFilteredList[i].studentName == undefined ? "" :  this.feeCollectionReportFilteredList[i].studentName,
        "class" : this.feeCollectionReportFilteredList[i].class == undefined ? "" : this.feeCollectionReportFilteredList[i].class,
        "batch" : this.feeCollectionReportFilteredList[i].batch == undefined ? "":this.feeCollectionReportFilteredList[i].batch,
        "transactionMode":this.feeCollectionReportFilteredList[i].transactionMode==undefined?"":this.feeCollectionReportFilteredList[i].transactionMode,
        "payMode" : this.feeCollectionReportFilteredList[i].payMode == undefined ? "" :  this.feeCollectionReportFilteredList[i].payMode,
        "takenBy" : this.feeCollectionReportFilteredList[i].takenBy == undefined ? "" : this.feeCollectionReportFilteredList[i].takenBy,
        "paidStatus" : this.feeCollectionReportFilteredList[i].paidStatus == undefined ? "":this.feeCollectionReportFilteredList[i].paidStatus,
        "rollNo" : this.feeCollectionReportFilteredList[i].rollNo == undefined ?"": this.feeCollectionReportFilteredList[i].rollNo ,
        "paidAmount" :this.feeCollectionReportFilteredList[i].paidAmount == undefined ? "" : this.feeCollectionReportFilteredList[i].paidAmount,
      }
      this.defaultArray.push(data);
    }
    let data1 ={
      "index" : "",
      "payDate" : "",
        "receiptNo" : "",
        "admissionNo" :"",
        "studentName" : "",
        "class" : "",
        "batch" : "",
        "payMode" : "",
        "takenBy" : "",
        "paidStatus" : "",
        "rollNo" : "",
        "paidAmount" :this.totalPaidAmount
    }
    this.defaultArray.push(data1);
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('FEE COLLECTION SUMMARY');
    worksheet.addRow();
    // if (this.institution.logoUrl !== null) {
    //   const imageId2 = workbook.addImage({
    //     base64: environment.imageUrl + this.institution.logoUrl,
    //     extension: 'jpg',
    //   });
    //   worksheet.addImage(imageId2, 'A1:B1');
    // }

    const titleRow = worksheet.addRow(['FEE COLLECTION SUMMARY']);
    const headerRow = worksheet.addRow(this.headerList);
    for(var i = 0 ; i < this.defaultArray.length ; i++)
    this.defaultArray[i]["index"] = i+1;
    for (const x1 of this.defaultArray) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'payDate') {
          if(x1[y]=="" || x1[y]==undefined){
            temp.push("");
          }else{
            temp.push(this.formatDate(x1[y],true));
          }
        } else {
          if(y === "paidStatus")
          {
           ;
            if(x1[y] === true || x1[y] === false){
              temp.push(x1[y] === true ?"Cancelled":"Paid");
            }else{
              temp.push("")
            }
          }
          else{
            temp.push(x1[y]);
          }
        }
      }
      worksheet.addRow(temp);
    }
    const fname = 'FEE COLLECTION SUMMARY';

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }
  defaultArray = [];

  generatePDF() {
   
    var pdffromdate=formatDate(this.dateFrom,'dd-MM-yyyy',"en-US");
    var pdftodate=formatDate(this.dateTo,'dd-MM-yyyy',"en-US");
    this.headerList = [];
    this.fieldList = [];
    
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    for (let i = 0; i < this.feeCollectionReportFilteredList.length; i++) {
      this.feeCollectionReportFilteredList[i]["index"] = i + 1;
    }
    
    const widthList = [];
    for (const item of this.headerList) {
        widthList.push('auto');
    }
    console.log("widthList", widthList);
    this.defaultArray = [];
    for(var i = 0 ; i < this.feeCollectionReportFilteredList.length ; i++)
    {
      let data ={
        "index" : i+1,
        "payDate" : this.feeCollectionReportFilteredList[i].payDate == undefined ? "":this.feeCollectionReportFilteredList[i].payDate,
        "receiptNo" : this.feeCollectionReportFilteredList[i].receiptNo == undefined ?"": this.feeCollectionReportFilteredList[i].receiptNo ,
        "admissionNo" :this.feeCollectionReportFilteredList[i].admissionNo == undefined ? "" : this.feeCollectionReportFilteredList[i].admissionNo,
        "studentName" : this.feeCollectionReportFilteredList[i].studentName == undefined ? "" :  this.feeCollectionReportFilteredList[i].studentName,
        "class" : this.feeCollectionReportFilteredList[i].class == undefined ? "" : this.feeCollectionReportFilteredList[i].class,
        "batch" : this.feeCollectionReportFilteredList[i].batch == undefined ? "":this.feeCollectionReportFilteredList[i].batch,
        "transactionMode":this.feeCollectionReportFilteredList[i].transactionMode==undefined?"":this.feeCollectionReportFilteredList[i].transactionMode,
        "payMode" : this.feeCollectionReportFilteredList[i].payMode == undefined ? "" :  this.feeCollectionReportFilteredList[i].payMode,
        "takenBy" : this.feeCollectionReportFilteredList[i].takenBy == undefined ? "" : this.feeCollectionReportFilteredList[i].takenBy,
        "paidStatus" : this.feeCollectionReportFilteredList[i].paidStatus == undefined ? "":this.feeCollectionReportFilteredList[i].paidStatus,
        "rollNo" : this.feeCollectionReportFilteredList[i].rollNo == undefined ?"": this.feeCollectionReportFilteredList[i].rollNo ,
        "paidAmount" :this.feeCollectionReportFilteredList[i].paidAmount == undefined ? "" : this.feeCollectionReportFilteredList[i].paidAmount,
      }
      this.defaultArray.push(data);
    }
    let data1 ={
      "index" : "",
      "payDate" : "",
        "receiptNo" : "",
        "admissionNo" :"",
        "studentName" : "",
        "class" : "",
        "batch" : "",
        "transactionMode":"",
        "payMode" : "",
        "takenBy" : "",
        "paidStatus" : "",
        "rollNo" : "Total Amount",
        "paidAmount" :this.totalPaidAmount,
    }
    this.defaultArray.push(data1);
    const docDefinition = {
      pageSize: 'A4',
      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      // pageMargins: [ 40, 60, 40, 60 ],
      content: [
        {
            table: {
                widths: ['auto', '*'],
                body: [
                    [ {
                        width: 100,
                        height: 100,
                        image: 'imageKey'
                    }, {
                        width: '*',
                        alignment: 'center',
                        stack: [
                            {
                                style: 'h1',
                                text: this.institution.institutionName
                            },
                            {
                              style: 'h2',
                              text: this.institution.address
                            },
                            {
                                style: 'h2',
                                text: this.currentAcademicYearName
                            },
                            {
                                style: 'h3',
                                text: 'FEE COLLECTION REPORT FOR'
                            },
                            {
                              style: 'h3',
                              text: pdffromdate +'   to   '+  pdftodate
                            }
                        ]
                    }
                    ]
                ]
            },
            layout: {
                hLineWidth: () =>  0,
                vLineWidth: () => 0,
                paddingBottom: () => 5,
                paddingLeft:()=> 5,
                paddingRight:()=> 5
            },
          },
          {
            style:'sectionHeader',
            table: {
              headerRows: 1,
              widths: widthList,
              body: [
                this.headerList,
                ...this.defaultArray.map((p) => {
                  const temp = [];
                  for (const y of this.fieldList) {
                    if (y === 'payDate') {
                     ;
                      if(p[y]=="" || p[y]==undefined){
                        temp.push("");
                      }else{
                        temp.push(this.formatDate(p[y],true));
                      }
                    } else {
                      if(y === "paidStatus")
                      {
                       ;
                        if(p[y] === true || p[y] === false){
                          temp.push(p[y] === true ?"Cancelled":"Paid");
                        }else{
                          temp.push("")
                        }
                      }
                      else{
                        temp.push(p[y]);
                      }
                    }
                  }
                  return temp;
                }),
                
                // {
                //   width: '*',
                //   alignment: 'center',
                //   stack: [
                //       {
                //           style: 'h1',
                //           text: "Total Paid Amount :" + this.totalPaidAmount
                //       }
                //     ]
                //   }
              ]
            }
          },
          // { text: 'Total Paid Amount: '+ this.totalPaidAmount, alignment: 'center',fontSize: 14 },
      ],
      images: {
         imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
         // via URL address, which can be referenced by this key
      },
      styles: {
        
        sectionHeader: {
          // bold: true,
          // decoration: 'underline',
          fontSize: 8,
          margin: [0, 10, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 13,
          bold: true,
          // fontWeight:800
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        },
       
      }
    };
    pdfMake.createPdf(docDefinition).download('FEE COLLECTION.pdf');
  }

  ngOnInit(): void {
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.generateAttributes();
    this.resetAll();
    this.setCurrentDate();
    this.ListAcademicYear();
    this.ListClassCourse();
    this.getFeeCollectionReport();
  }

}
