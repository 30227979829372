import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ERPSettingService } from './erpsettings.service';
import { ERPDashboardSetting, ERPGlobalSettings } from './erpsettings.model';
import { Academicyear } from '../academic/mastersettings/models/academicyear';
import { SettingsService } from '../academic/mastersettings/settings.service';
declare var $: any;
@Component({
  selector: 'app-erpsettings',
  templateUrl: './erpsettings.component.html',
  styleUrls: ['./erpsettings.component.css']
})
export class ErpsettingsComponent implements OnInit {
  settingForm: UntypedFormGroup;
  submitted = false;
  academicYears: Academicyear[] = [];
  currentAcademicYearId: number;
  isDisabled = true;
  passCode = '';
  isStartAdmissionYesNo = true;
  //saveStartAdmissionYesNo : false;
  printFeeReceiptCopy:any;

  PrintFeeReceiptCopy_details=[
    {id:1,name:'Student Copy',isSelected:false},
    {id:2,name:'School Copy',isSelected:false},
    {id:3,name:'Both Copy',isSelected:false},
  ]
  constructor(
    public erpSettingService: ERPSettingService,
    public academicyearService: SettingsService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
  ) { }

  
  ListAcademicYear() {
    this.academicyearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
      this.currentAcademicYear();
    });
  }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {

      this.currentAcademicYearId = data.id;
      this.settingForm.get('academicYearId').patchValue(this.currentAcademicYearId);
    });
  }

  Verify() {
    this.erpSettingService.verify(this.passCode).subscribe((data: any) => {

      if (data) {
        $('#passCode').modal('hide');
        this.isDisabled = false;
        Object.keys(this.f).map(key => {
          this.settingForm.get(key).enable();
        });
        this.isStartAdmissionYesNo = false;
      } else {
        this.toastr.warning('Passcode is not correct', 'WARNING!', {
          timeOut: 3000
        });
        this.isDisabled = true;
      }
    });
  }

  updateForm() {
    this.erpSettingService.getAll().subscribe((data: ERPGlobalSettings[]) => {
      let erpsetting = {} as ERPGlobalSettings;
      erpsetting = data[0];
     // alert(JSON.stringify(erpsetting));
      if (erpsetting != null) {
        this.settingForm.patchValue({
          id: erpsetting.id,
          academicYearId: erpsetting.academicYearId,
          admissionSeed: erpsetting.admissionSeed,
          admissionPrefix: erpsetting.admissionPrefix,
          admissionPostFix: erpsetting.admissionPostfix,
          isPreviousSessionAllowed: erpsetting.isPreviousSessionAllowed,
          isFeeDefaultersReportHold: erpsetting.isFeeDefaultersReportHold,
          sentAbsentNotification: erpsetting.sentAbsentNotification,
          sentAbsentNotificationTime: erpsetting.sentAbsentNotificationTime,
          isSessionExpiry: erpsetting.sessionExpiryTime == 0 ? "false" : "true",
          sessionExpiryTime: erpsetting.sessionExpiryTime,
          isStartAdmission : erpsetting.adnoPolicy,
          printFeeReceiptCopy:erpsetting.printFeeReceiptCopy,
        });
        this.printFeeReceiptCopy=erpsetting.printFeeReceiptCopy;
        this.PrintFeeReceiptCopy_details.forEach(x=>{
            if( this.printFeeReceiptCopy==x.id)
              x.isSelected=true;
        });
      }
    });

  }

  get f() { return this.settingForm.controls; }
  resetForm() {
    this.submitted = false;
    this.settingForm.patchValue({
      id: 0,
      academicYearId: '',
      admissionSeed: '',
      admissionPrefix: '',
      admissionPostFix: '',
      isPreviousSessionAllowed: false,
      isFeeDefaultersReportHold: false,
      printFeeReceiptCopy:1,
      sentAbsentNotification: '0',
      sentAbsentNotificationTime: '',
      isSessionExpiry: false,
      sessionExpiryTime: 0
    });
  }
  selectedPrintFeeReceiptCopy(id)
  {

        this.printFeeReceiptCopy=id;

  }
  submitForm() {
    this.submitted = true;

    this.settingForm.patchValue({
      printFeeReceiptCopy:this.printFeeReceiptCopy

    });
    // stop here if form is invalid
    if (this.settingForm.invalid) {
      return;
    }

    //alert(JSON.stringify(this.settingForm.value));
  
    if (this.settingForm.get('id').value === 0) {

      this.erpSettingService.create(this.settingForm.value).subscribe(res => {
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });

        this.submitted = false;
        // this.resetForm();
        this.updateForm();
      });
    } else {
      this.erpSettingService.update(this.settingForm.get('id').value, this.settingForm.value).subscribe(res => {
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        // this.resetForm();
        this.updateForm();
      });
    }

    this.updateAllERPDashboardSettings();

  }


  ngOnInit(): void {
    this.settingForm = this.fb.group({
      id: [0],
      academicYearId: ['', Validators.required],
      admissionSeed: [{ value: '' }, Validators.required],
      admissionPrefix: [{ value: '' }],
      admissionPostFix: [''],
      isPreviousSessionAllowed: [false],
      isFeeDefaultersReportHold: [false],
      printFeeReceiptCopy:1,
      sentAbsentNotification: ['0'],
      sentAbsentNotificationTime: [''],
      isSessionExpiry: [false],
      sessionExpiryTime: [''],
      isStartAdmission :[false]
    });

    Object.keys(this.f).map(key => {
      this.settingForm.get(key).disable();
    });
    this.settingForm.get('isSessionExpiry').valueChanges.subscribe(result => {
      if (result == "false") {
        this.settingForm.get('sessionExpiryTime').setValue(0);
      }
    })

    this.ListAcademicYear();
    this.updateForm();
    this.getAllERPDashboardSettings();

  }

  allERPDashboardSettings : ERPDashboardSetting[] = [];
  getAllERPDashboardSettings(){
    this.erpSettingService.getAllERPDashboardSettings().subscribe((data: ERPDashboardSetting[]) => {
      this.allERPDashboardSettings = data;  
      this.disableCheckBox();
    });
  }

  updateAllERPDashboardSettings(){
    this.allERPDashboardSettings.forEach(setting => 
      this.erpSettingService.updateERPDashboardSetting(setting.id, setting).subscribe(res => { })
    )
  }

  checkERPDashboardSetting(setting: ERPDashboardSetting, ev){
    setting.status = ev.target.checked;
    this.disableCheckBox();
  }
  
  disableCheckBox(){
    const checkedCount = this.allERPDashboardSettings.filter(s => s.status).length;
    this.allERPDashboardSettings.forEach(s => s.disabled = checkedCount >= 5 && !s.status);
  }

  onSettingClick(s){
    const checkedCount = this.allERPDashboardSettings.filter(s => s.status).length;
    if (checkedCount >= 5 && !s.status)
    this.toastr.warning('You can select max 5 settings', 'WARNING!', {
      timeOut: 3000
    });
  }
  checkPasswordVerified() {
    if (this.isDisabled) {
      this.toastr.warning('Please verify the password first', 'SUCCESS!', {
        timeOut: 3000
      });
    }
  }

}
