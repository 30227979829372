import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ExamrankingComponent} from './examranking/examranking.component'


const routes: Routes = [
  {
    path:'examrankings', component: ExamrankingComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExamrankingRoutingModule { }
