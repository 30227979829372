import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs"; 
import { GradescalegroupService } from "../services/gradescalegroup.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ExamResultReportService } from "../services/exam-result-report.service";

@Component({
  selector: "app-exam-result-report",
  templateUrl: "./exam-result-report.component.html",
  styleUrls: ["./exam-result-report.component.css"],
  providers: [FormGroupDirective],
})
export class ExamResultReportComponent implements OnInit {
  submitted = false; 
  loader:any=false;
  categorydropDown: any = [];
  examNamedropDown: any = [];
  finalexamNamedropDown: any = [];
  examClassdropDown: any = [];
  StudentsList: any = [];
  classDropDown: any = [];
  section: any = [];
  saveDataModel = [];
  selectedRow:number;
  maxSubjectCount:number;
  subjectName:any;
  subStatus:any;

  GetAllStudentMarkForSelectedSubject: any = [];
  DetailsResult: any = [];
  disabled = true;

  constructor(
    private gradeService: GradescalegroupService,
    private toastr: ToastrService,
    public fb: UntypedFormBuilder,
    public service: ExamResultReportService,
    public formDirective: FormGroupDirective
  ) {}
  examReportForm: UntypedFormGroup;
  classBatachData: any = [];

  ngOnInit(): void {
    this.examReportForm = this.fb.group({
      id: [0],
      examCategory: ["", Validators.required],
      examName: ["", Validators.required],
      classId: ["", Validators.required],
      classBatch: ["", Validators.required]
    });
    this.getCategoryDropDown();
  }

  get f() {
    return this.examReportForm.controls;
  }
  getCategoryDropDown() {
    this.service.getAllCatefgoryDropDown().subscribe((data) => {
      this.categorydropDown = data;
    });
    this.checkingButton();
  }
  onCategoryEvent() {
    this.examReportForm.value.examName = "";
    this.finalexamNamedropDown = [];
    this.examClassdropDown = [];
    this.StudentsList = [];
    this.getNameDropDown();
    this.checkingButton();   
    this.examReportForm = this.fb.group({
      id: [0],
      examCategory: [this.examReportForm.controls.examCategory.value, Validators.required],
      examName: ["", Validators.required],
      classId: ["", Validators.required],
      classBatch: ["", Validators.required]
    }); 
  }
  submitForm() {}

  getNameDropDown() {
    this.service.getExamNameList().subscribe((data) => {
      this.examNamedropDown = data;
      this.finalexamNamedropDown = this.examNamedropDown.filter(
        (filterList: any) =>
          filterList.examCategoryId === this.examReportForm.value.examCategory
      );
    });
    this.checkingButton();
    
  }

  getClassDropDown() {
    this.service
      .getAllClass(this.examReportForm.value.examName)
      .subscribe((data) => {
        this.examClassdropDown = data;
        this.classDropDown = [
          ...new Map(
            this.examClassdropDown.map((item) => [item["className"], item])
          ).values(),
        ];
        this.StudentsList = [];
        this.checkingButton();
      });
      this.examReportForm = this.fb.group({
        id: [0],
        examCategory: [this.examReportForm.controls.examCategory.value, Validators.required],
        examName: [this.examReportForm.controls.examName.value, Validators.required],
        classId: ["", Validators.required],
        classBatch: ["", Validators.required]
      }); 
  }

  selectedClass(id) {
    let batchList = [];
    this.StudentsList = [];
    if (this.examReportForm.value.classId !== "") {
      batchList = this.examClassdropDown.filter((element) => element.id == id);
      this.classBatachData = batchList;
      this.checkingButton();
    }
    this.examReportForm = this.fb.group({
      id: [0],
      examCategory: [this.examReportForm.controls.examCategory.value, Validators.required],
      examName: [this.examReportForm.controls.examName.value, Validators.required],
      classId: [this.examReportForm.controls.classId.value, Validators.required],
      classBatch: ["", Validators.required]
    }); 
  }

  selectedClassBatch() {
    this.StudentsList = [];
    if (this.examReportForm.value.classBatch !== "") {
      this.section = this.classBatachData.filter(
        (element) =>
          element.batchId === this.examReportForm.controls.classBatch.value
      );
      this.checkingButton();
    }
  }



  checkingButton() {
    if (
      this.examReportForm.controls.examCategory.value &&
      this.examReportForm.controls.examName.value &&
      this.examReportForm.controls.classId.value &&
      this.examReportForm.controls.classBatch.value
    ) {
      this.disabled = false;
    } 
  }

  getAllStudents() {
    this.loader=true;
    this.selectedRow=-1;
    this.GetAllStudentMarkForSelectedSubject = [];
    this.DetailsResult=[];
     if (this.examReportForm.valid) {
    this.service.getAllStudents(
          this.examReportForm.controls.examCategory.value,
          this.examReportForm.controls.examName.value,
          this.examReportForm.controls.classId.value,
          this.examReportForm.controls.classBatch.value
        ).subscribe((data) => {
          this.StudentsList = data;
        });
    
  }
  else{
    this.toastr.warning("All field are required", "WARNING!!!", {
      timeOut: 3000,
    });
    return;
  }
  this.loader=false;
  }
 
  disableFreezeButton: boolean = false;
  subjectId: any;
  studentIds: any[] = [];
  getallstudentMarks(subid,i){

    // if(subid.examStatus != "Completed"){
    //   this.disableFreezeButton = true;
    // }
    // else{
    //   this.disableFreezeButton = false;
    // }
    
    this.selectedRow=i;
    this.subStatus=subid.status;
    this.subjectName=subid.subjectName;
    this.subjectId = subid.subjectId;
    this.examReportForm.controls.classId.value
   // let batchid=this.classBatachData.find((obj)=>{ return obj.batchName===this.marksEntryForm.controls.classBatch.value}).batchId;
    // this.service.GetAllStudentMarkForSelectedSubject(this.examReportForm.controls.examCategory.value, this.examReportForm.controls.examName.value, this.examReportForm.controls.classId.value,this.examReportForm.controls.classBatch.value,subid.id).subscribe((data)=>{
    //   this.GetAllStudentMarkForSelectedSubject=data;
    // });
    debugger;
    this.service.GetExamResultStudentReportDetails(this.examReportForm.controls.examCategory.value, this.examReportForm.controls.examName.value, this.examReportForm.controls.classId.value,this.examReportForm.controls.classBatch.value,subid.id).subscribe((data)=>{
      this.DetailsResult=data;
    });
  }
  freezeMarks(){
    if(this.subStatus==="Freezed"){
      this.toastr.warning("Marks for this exam was already freezed","WARNING",{
        timeOut:3000,
      })
    }else{
      Swal.fire({
        title: 'Are you sure you want to continue?',
        text: 'After freeze the exam marks you will not be enable to edit..!',
        icon: 'warning',
        showCancelButton:true,
        confirmButtonText:'Yes, freeze it!',
        cancelButtonText:'No, keep it'
      }).then((result)=>{
        if(result.value){
          // this.saveDataModel.push({
          //   classId: this.marksEntryForm.value.classId,
          //   batchId: this.marksEntryForm.controls.classBatch.value,
          //   subjectId: this.selectedRow,
          //   examCategoryId: this.marksEntryForm.value.examCategory,
          //   examId: this.marksEntryForm.value.examName,
          // });
          this.GetAllStudentMarkForSelectedSubject.forEach(element => {
            this.studentIds.push(element.studentId);
          });
          let object = {
            classId: this.examReportForm.controls.classId.value,
            batchId: this.examReportForm.controls.classBatch.value,
            subjectId: this.subjectId,
            studentIds: this.studentIds,
            examId: this.examReportForm.controls.examName.value,
            examCategoryId: this.examReportForm.controls.examCategory.value
          };
          this.service.saveFreezeExamMark(object).subscribe((res) => {
            this.toastr.success("Successfully freezed", "SUCCESS!", {
              timeOut: 3000,
            });
          Swal.fire(
            'Freezed!',
            'Freezed Marks successsfully.',
            'success'
          );
         
      this.getAllStudents();
          })
        }
      })
      //alert("pending");
      this.studentIds = [];
    }
  }
  enforceMinMax(el){
    // var x= document.getElementById("fullmark").innerHTML;

    // if(el.value != ""){
    //   if(parseInt(el.value) < parseInt(el.min)){
    //     el.value = 0;
    //   }
    //   if(parseInt(el.value) > parseInt(el.max)){
    //     el.value = 100;
    //   }
    // }
  }

  getMaxSubject(){

      // let total= this.StudentsList.length;    // 6
      // this.maxSubjectCount = this.StudentsList[0].studentMarks.length;
      // for(let i=0;i<total;i++)
      // {
      //     if(this.maxSubjectCount<this.StudentsList[i].studentMarks.length)
      //     {
      //       this.maxSubjectCount=this.StudentsList[i].studentMarks.length;
      //     }
      // }
 
  }

  // submitFreezeExamMark() {
  //   this.service.saveFreezeExamMark(this.saveDataModel).subscribe((res) => {
  //     this.toastr.success("Successfully created", "SUCCESS!", {
  //       timeOut: 3000,
  //     });
  //     this.submitted = false;
  //     this.resetForm();
      
  //   });
  // }

  // makeModel(marks, studentId, subjectId, optional:any) {
  //   let model = {
  //     id: optional,
  //     examCategoryId: this.marksEntryForm.value.examCategory,
  //     examId: this.marksEntryForm.value.examName,
  //     classId: this.marksEntryForm.value.classId,
  //     studentId: studentId,
  //     subjectId: subjectId,
  //     marks: marks,
  //     academicYearId: 0,
  //   };
  //   if (model.studentId == studentId) {
  //     this.saveDataModel.push(model);

  //   }
  // }

  resetForm() {
    this.submitted = false;
    this.finalexamNamedropDown = [];
    this.classDropDown = [];
    this.classBatachData = [];
    this.StudentsList = [];
    this.saveDataModel = [];
    this.formDirective.reset();
    this.examReportForm.reset();
    this.disabled = true;
    this.examReportForm = this.fb.group({
        id: [0],
        examCategory: ["", Validators.required],
        examName: ["", Validators.required],
        classId: ["", Validators.required],
        classBatch: ["", Validators.required],
      });
  }

 
}
