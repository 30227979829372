import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Shift} from './period.model';

@Injectable({
    providedIn: 'root'
  })
  export class ShiftService {
  
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<Shift[]> {
      return this.httpClient.get<Shift[]>(environment.apiUrl + '/shift/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<Shift> {
      return this.httpClient.get<Shift>(environment.apiUrl + '/shift/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    
    create(shift): Observable<Shift> {
      return this.httpClient.post<Shift>(environment.apiUrl + '/shift/', JSON.stringify(shift), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }
  
    update(id, shift): Observable<Shift> {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.put<Shift>(environment.apiUrl + '/shift/' + id, JSON.stringify(shift), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }
  
    delete(id) {
      return this.httpClient.delete<Shift>(environment.apiUrl + '/shift/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }
  
  
  }