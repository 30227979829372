import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { StudentService} from './../../studentmanagement/student.service';
import {ClassCourseService} from './../../mastersettings/classcourse/classcourse.service';
import { Student } from './../../studentmanagement/student.model';
import {ClassCourse} from './../../mastersettings/models/classcourse';
import {Batch} from './../../mastersettings/models/batch';
import {BatchService} from './../../mastersettings/batch/batch.service';
import { Attendance } from '../attendance.model';
import { AttendanceService } from '../attendance.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../mastersettings/settings.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { error } from 'console';

declare var $: any;
@Component({
  selector: 'app-studentattendance',
  templateUrl: './studentattendance.component.html',
  styleUrls: ['./studentattendance.component.css']
})
export class StudentattendanceComponent implements OnInit {
  isLoading =false;
  admissions: Student[] = [];
  admissionsfilteredlist: Student[] = [];
  presentStudentList: Student[] = [];
  absentStudentList: Student[] = [];
  classcourses: ClassCourse[] = [];
  batches: Batch[] = [];
  attendances: Attendance[] = [];
  attendanceList: Attendance[] = [];
  classcourseid = '-1';
  batchid = '-1';
  ataendanceDate: string;
  currentAcademicYearId: number;
  loader:any=false;
  P = 'P';
  constructor(
    public settingService: SettingsService,
    public studentService: StudentService,
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    private attendanceService: AttendanceService,
    private toastr: ToastrService
    ) { }

    currentAcademicYear() {
      this.settingService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
        this.currentAcademicYearId = data.id;
        this.ListStudentAttendances();
        // this.search();
      });
    }

    ListStudentAttendances() {
      this.studentService.getAll().subscribe((data: Student[]) => {
        this.admissions = data.filter((student: Student) => {
          return student.academicYearId.toString() === this.currentAcademicYearId.toString();
          });
      });

    }

    ListClassCourse() {
      this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
        this.classcourses = data;
      });
    }
    onClassSelected(value: string) {
    
      this.batches = [];
      this.admissionsfilteredlist = [];
      this.batchid = '-1';
      this.batchService.getAll().subscribe((data: Batch[]) => {
        this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
      });
    
    }

    onBatchSelected(value: string) {
      //debugger;
   
       if (value === '-1') {return false; }
       console.log('Batch :-' + value);
      // this.studentService.getAll().subscribe((data: Student[]) => {
       this.absentStudentList = this.presentStudentList = this.admissionsfilteredlist = this.admissions.filter((student: Student) =>
        student.classCourseId.toString() === this.classcourseid && student.batchId.toString() === value.toString() && student.academicYearId==this.currentAcademicYearId.toString());
       // console.log(this.admissionsfilteredlist);
      // });
       this.admissionsfilteredlist.forEach(x => { x.attendance = true; x.leave = false; x.remarks = ''; });
       const attDate = this.ataendanceDate;
       this.isLoading=true;
       this.attendanceService.getAll().subscribe((data: Attendance[]) => {
        this.attendanceList = data.filter((x) => x.attendanceDate === attDate && x.batchId.toString() === value.toString()
        && x.classId.toString() === this.classcourseid.toString());
        if (this.attendanceList.length > 0) {
          this.admissionsfilteredlist.forEach((x, i) => {
            x.attendanceId = this.attendanceList[i].id;
            x.attendance = this.attendanceList[i].attendanceStatus;
            x.remarks = this.attendanceList[i].remarks;
            x.leave = this.attendanceList[i].leaveStatus;
         });
         
        }
        this.isLoading=false;
       });
       //this.isLoading=!this.isLoading;
       console.log(this.admissionsfilteredlist);
    }

    onDateChange() {

    }

    onCheckedChange(value, row) {
      //let checkbox=document.getElementById("square-switch"+row);
      //debugger;
      const checkbox = document.getElementById(
        'square-switch'+row+'',
      ) as HTMLInputElement | null;
      const checkboxOnleave = document.getElementById(
        'customCheck'+row+'',
      ) as HTMLInputElement | null;

      if (value === true) {
        this.admissionsfilteredlist[row].attendance = false;
        
      } else {
        this.admissionsfilteredlist[row].attendance = true;
        
      }
      checkbox.checked=!checkboxOnleave.checked;
    }

    get totalRows(): number {
      return this.admissionsfilteredlist.length;
    }

    get totalPresents(): number {
      return this.presentStudentList.filter((student: Student) =>
      student.attendance === true).length;
    }

    get totalAbsenties(): number {
      return this.absentStudentList.filter((student: Student) =>
      student.attendance === false).length;
    }

    private formatDate(date) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) {month = '0' + month; }
      if (day.length < 2) {day = '0' + day; }
      return [day, month, year].join('-');
    }

   resetForm() {
    this.isLoading =true;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Reset it!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.admissionsfilteredlist = [];
        this.batches = [];
        this.ListClassCourse();
      } else if (result.dismiss === Swal.DismissReason.cancel) {}
    });
    this.isLoading =false;
   }

   setAllPresent() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.admissionsfilteredlist.forEach(x => x.attendance = true);
      } else if (result.dismiss === Swal.DismissReason.cancel) {}
    });

   }

   setAllAbsent() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.admissionsfilteredlist.forEach(x => x.attendance = false);
      } else if (result.dismiss === Swal.DismissReason.cancel) {}
    });
   }

   saveAttendance() {
   // debugger;
    
      if (this.classcourseid === '') {
        this.toastr.warning('Please select class', 'WARNING!', {
          timeOut: 3000
        });
        this.isLoading =!this.isLoading;
        return;
      }

      if (this.batchid === '') {
        this.toastr.warning('Please select batch', 'WARNING!', {
          timeOut: 3000
        });
        this.isLoading =!this.isLoading;
        return;
      }

      if (this.ataendanceDate === '') {
        this.toastr.warning('Please select date', 'WARNING!', {
          timeOut: 3000
        });
        this.isLoading =!this.isLoading;
        return;
      }
    if(this.admissionsfilteredlist.length==0){
      this.toastr.warning('Student to avaible selected Class & Batch', 'WARNING!', {
        timeOut: 3000
      });
      //this.isLoading =!this.isLoading;
      return;
    
    }
      
      this.isLoading =true;
      this.attendances = [];
      
      this.admissionsfilteredlist.forEach((x, i) => {
          const attendance = {} as Attendance;
          attendance.id = x.attendanceId;
          attendance.admissionId = x.id.toString();
          attendance.batchId = x.batchId;
          attendance.classId = x.classCourseId;
          attendance.attendanceDate = this.ataendanceDate;
          attendance.attendanceStatus = x.attendance;
          attendance.leaveStatus = x.leave;
          attendance.remarks = x.remarks;
          attendance.attendanceModeId = 1;
          attendance.academicYearId=this.currentAcademicYearId;
          this.attendances.push(attendance);
      });

      console.log(this.attendances);      

      this.attendanceService.create(this.attendances).subscribe(res => {
        this.isLoading =false;
        console.log('Period created!');
        // console.log('Period created!');
        // this.admissionsfilteredlist = [];
        // this.batches = [];
        // this.ListClassCourse();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
       
      },error=>{
        this. onBatchSelected(this.batchid);
        //window.location.reload();
        this.isLoading =false;
      });
      
   }

  ngOnInit(): void {
    
   
    this.ListClassCourse();
    this.currentAcademicYear();
    // this.ListStudentAttendances();

    this.ataendanceDate = moment().format('DD-MM-yyyy').toString();

    $('#adate').datepicker('setDate', new Date()).on('changeDate', (e) => {
      this.ataendanceDate = moment(e.date).format('DD-MM-yyyy').toString();
      this.onBatchSelected(this.batchid);
    });
    
  }

}
