import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {RoomService} from './room.service';
import {FloorService} from './../floor/floor.service';
import {CampusBuildingService} from './../campusbuilding/campusbuilding.service';
import { Room } from '../models/room';
import { Floor } from '../models/floor';
import { CampusBuilding } from '../models/campusbuilding';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.css']
})
export class RoomComponent implements OnInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false
};

roomForm: UntypedFormGroup;
rooms: Room[] = [];
roomFilteredList: Room[] = [];
floors: Floor[] = [];
campusbuildings: CampusBuilding[] = [];
submitted = false;
ClickedRow:any;
HighlightRow:number;

  constructor(
    public roomService: RoomService,
    public floorService: FloorService,
    public campusBuildingService: CampusBuildingService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  )  { 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }
  }

  ListFloors() {
    this.floorService.getAll().subscribe((data: Floor[]) => {
      this.floors = data;
    });
}

ListBuildings() {
  this.campusBuildingService.getAll().subscribe((data: CampusBuilding[]) => {
    this.campusbuildings = data;
  });
}

  ListRoom() {
    this.roomService.getAll().subscribe((data: Room[]) => {
      this.roomFilteredList = this.rooms = data;
    });
}

onBuildingSelected(value: string) {
  console.log(value);
  this.floorService.getAll().subscribe((data: Floor[]) => {
    console.log(data);
    this.floors = data.filter((floor: Floor) => floor.campusBuildingId.toString().includes(value.toLocaleLowerCase()));
  });
}

onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

keyPress(event: any) {
  const pattern = /[0-9\+\-\ ]/;
  const inputChar = String.fromCharCode(event.charCode);
  if (!pattern.test(inputChar) && event.charCode !== '0') {
          event.preventDefault();
  }
}

filter(keyword: any) {
  if ( keyword === '') {
    this.roomFilteredList = this.rooms;
  } else {
  this.roomFilteredList = this.rooms
                              .filter((room: Room) => room.roomName.toLocaleLowerCase()
                              .includes(keyword.toLocaleLowerCase()));
  }
}

get totalRows(): number {
  return this.roomFilteredList.length;
}
get f() { return this.roomForm.controls; }
resetForm() {
  this.roomForm = this.fb.group({
    id: [0],
    roomName: ['', Validators.required],
    seatingCapacity: ['', Validators.required],
    floorId: ['', Validators.required],
    campusBuildingId: ['', Validators.required],
    status: ['true', Validators.required]
  });
}
submitForm() {
  this.submitted = true;

      // stop here if form is invalid
  if (this.roomForm.invalid) {
          return;
    }

  if (this.roomForm.get('id').value === 0) {
      this.roomService.create(this.roomForm.value).subscribe(res => {
        console.log('Nationality created!');
        this.ListRoom();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.roomService.update(this.roomForm.get('id').value, this.roomForm.value).subscribe(res => {
          console.log('Nationality updated!');
          this.ListRoom();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
      }


    }

    updateForm(id: number) {
      this.roomService.getById(id).subscribe((data: Room) => {
        console.log(data);
        this.roomForm = this.fb.group({
          id: [data.id],
          roomName: [data.roomName, Validators.required],
          seatingCapacity: [data.seatingCapacity, Validators.required],
          floorId: [data.floorId.toString(), Validators.required],
          campusBuildingId: [data.campusBuildingId.toString(), Validators.required],
          status: [data.status.toString(), Validators.required]
        });
      });

    }

    deleteForm(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.roomService.delete(id).subscribe(res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            this.ListRoom();
          });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
        }
      });
    }

  ngOnInit(): void {
    this.ListRoom();
    this.ListBuildings();
    // this.ListFloors();
    this.roomForm = this.fb.group({
      id: [0],
      roomName: ['', Validators.required],
      seatingCapacity: ['', Validators.required],
      floorId: ['', Validators.required],
      campusBuildingId: ['', Validators.required],
      status: ['true', Validators.required]
    });
  }

}
