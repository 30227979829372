import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EvaluationType } from '../../offline-examination/ExamSetting/models/evaluation-type.model';
@Injectable({
  providedIn: 'root'
})
export class EvalutiontypeService {

httpOptions={
  headers:new HttpHeaders(
    {
      'Content-Type':'application/json'
    }
  )
}

  constructor(private http:HttpClient) { }


  getAllEvalutaionTypes():Observable<EvaluationType[]>{
    return this.http.get<EvaluationType[]>(environment.apiUrl+"/EvaluationsTypes/").pipe(
      catchError(this.errorHandler)
    )
  }
  getEvaluationTypeById(id):Observable<EvaluationType>{
    return this.http.get<EvaluationType>(environment.apiUrl+"/EvaluationsTypes/Get/"+id).pipe(
      catchError(this.errorHandler)
    )
  }
    createEvaluationType(data):Observable<EvaluationType> {
    return this.http.post<EvaluationType>(environment.apiUrl+"/EvaluationsTypes/Create/",JSON.stringify(data),this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }

  deleteEvaluationType(id){
    return this.http.delete<EvaluationType>(environment.apiUrl+"/EvaluationsTypes/Remove/"+id,this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }
  updateEvaluationType(id,data):Observable<EvaluationType>{
    return this.http.put<EvaluationType>(environment.apiUrl+"/EvaluationsTypes/Update?id="+id,JSON.stringify(data),this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }

  // This code is for error handling
  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
