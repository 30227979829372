import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateQuestionComponent } from './create-question.component';
const routes: Routes = [
  {
    path:'CreateQuestion', component: CreateQuestionComponent
  },
  {path: 'CreateQuestion/:Id', component: CreateQuestionComponent},
];


@NgModule({
  declarations: [CreateQuestionComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class CreateQuestionModule { }
