import { Component, OnInit } from '@angular/core';

import { ClassCourseService } from './../../mastersettings/classcourse/classcourse.service';

import { ClassCourse } from './../../mastersettings/models/classcourse';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { SettingsService } from '../../mastersettings/settings.service';
import { environment } from 'src/environments/environment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { BatchAttribute } from '../batchattribute.model';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import { Student } from '../../studentmanagement/student.model';
import { StudentService } from '../../studentmanagement/student.service';
@Component({
  selector: 'app-batchsummmary',
  templateUrl: './batchsummmary.component.html',
  styleUrls: ['./batchsummmary.component.css']
})
export class BatchsummmaryComponent implements OnInit {

  admissions: Student[] = [];
  admissionsfilteredlist: Student[] = [];
  selectedadmissionlist: Student[] = [];
  classcourses: ClassCourse[] = [];
  classcourseid = '-1';
  batchid = '-1';
  institution: any;
  attributes:BatchAttribute[] = [];
  batches: Batch[] = [];
  headerList: string[] = [];
  fieldList: string[] = [];
  batchadmissions: Student[] = [];
  classadmissions: Student[] = [];
  academicYear: Academicyear = {} as Academicyear;
  isLoading :any =false;
  loader: any = false;

  constructor(
    private studentService: StudentService,
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    private academicyearService: SettingsService
  ) { }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      console.log(data);
      this.academicYear = data;
      this.ListStudents();
    });
  }

  ListStudents() { 
    this.loader=true;
    this.studentService.getAll().subscribe((data: Student[]) => {
      console.log(data);
      this.classadmissions = this.batchadmissions = this.admissions = data.filter((admission: Student) =>
      admission.academicYearId.toString() === this.academicYear.id.toString());
      this.ListClassCourse();
    
    });
    this.loader=false;
  }

  ListClassCourse() {
    this.loader=true;
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      console.log(data);
      this.classcourses = data;
      this.classcourses.forEach((x, i) => {
        const totalStudents = this.classadmissions.filter((admission: Student) =>
          admission.classCourseId.toString() === x.id.toString() &&
          admission.academicYearId.toString() === this.academicYear.id.toString()).length;
        x.totalStudents = totalStudents.toString();
      });
      this.loader=false;
    });
    // this.loader=false;
  }

  onClassSelected(value: number) {
    this.loader=true;
    console.log(value);
    // if (value === '-1') { return; }
    // if (value === '0') {
    //     this.admissionsfilteredlist = this.admissions; // = data;
    // } else {
    this.classcourses.forEach((x) => {
      if (x.id === value) {
          x.selected = true;
      } else {
        x.selected = false;
      }
    });

    this.admissionsfilteredlist = [];
    this.batches = [];
    this.loader=true;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      console.log(data);
      this.batches = data.filter((batch: Batch) => batch.classId.toString()
      === value.toString());
      this.batches.forEach((x, i) => {
        const totalStudents = this.batchadmissions.filter((admission: Student) =>
          admission.batchId.toString() === x.id.toString() &&
          admission.academicYearId.toString() === this.academicYear.id.toString()).length;
        x.totalStudents = totalStudents.toString();
    });
    
  this.loader=false;
  });
  // this.admissionsfilteredlist = this.admissions.filter((admission: Student) =>
  // admission.classCourseId.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
  // }
}

onBatchSelected(value: number) { 
  
  console.log(value);
  this.batches.forEach((x) => {
    if (x.id === value) {
        x.selected = true;
    } else {
      x.selected = false;
    }
    
  });
   
  this.admissionsfilteredlist = this.selectedadmissionlist = this.admissions.filter((admission: Student) =>
    admission.batchId.toString() === value.toString() &&
    admission.academicYearId.toString() === this.academicYear.id.toString());

  console.log(this.admissionsfilteredlist);

  // } 
}

onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

filter(keyword: any) {
  if (keyword === '') {
    this.admissionsfilteredlist = this.selectedadmissionlist;
  } else {
    this.admissionsfilteredlist = this.admissionsfilteredlist
      .filter((admission: Student) =>
        admission.studentName.toLocaleLowerCase()
          .includes(keyword.toLocaleLowerCase()) ||
          admission.fatherName.toLocaleLowerCase()
          .includes(keyword.toLocaleLowerCase()) ||
          admission.admissionNo.toLocaleLowerCase()
          .includes(keyword.toLocaleLowerCase())
          );
  }
}

get totalRows(): number {
  // this.loader=true;
  return this.classadmissions.length;
  // this.loader=false;
}

get totalBatchRows(): number {
  return this.batches.length;
}

get totaladmissionRows(): number {
  return this.admissionsfilteredlist.length;
}

get totalMaleAdmissionRows(): number {
  return this.admissionsfilteredlist.filter((x) => x.gender.toString() === '1').length;
}

get totalFeMaleAdmissionRows(): number {
  return this.admissionsfilteredlist.filter((x) => x.gender.toString() === '2').length;
}

get totalNewAdmissionRows(): number {
  return this.admissionsfilteredlist.filter((x) => x.studentAdmissionType === 'N').length;
}

get totalPromotionAdmissionRows(): number {
  return this.admissionsfilteredlist.filter((x) => x.studentAdmissionType === 'P').length;
}
generateAttributes() {
  this.attributes = [
    {name: 'Roll No', fieldName: 'rollNo' },
    {name: 'Admission No', fieldName: 'admissionNo' },
    {name: 'Student Name', fieldName: 'studentName' },
    {name: 'Class', fieldName: 'className' },
    {name: 'Batch', fieldName: 'batchName' },
    {name: 'Fathers Name', fieldName: 'fatherName' },
    {name: 'Status', fieldName: 'studentAdmissionType' },

  ];
}
ngOnInit(): void {
  // this.loader=true;
  this.loader=true;
  this.generateAttributes();
  this.currentAcademicYear();
  this.institution = JSON.parse(localStorage.getItem('Institution'));
  this.loader=false;
}
generatePDF() {
  this.headerList = [];
  this.fieldList = [];
  this.attributes.forEach(x => {
    this.headerList.push(x.name);
    this.fieldList.push(x.fieldName);
  });

  const widthList = [];
  for (const item of this.headerList) {
      widthList.push('auto');
  }
  const docDefinition = {
    content: [
      {
          table: {
              widths: ['auto', '*'],
              body: [
                  [ {
                      width: 100,
                      height: 100,
                      image: 'imageKey'
                  }, {
                      width: '*',
                      alignment: 'center',
                      stack: [
                          {
                              style: 'h1',
                              text: this.institution.institutionName
                          },
                          {
                            style: 'h2',
                            text: this.institution.address
                          },
                          {
                              style: 'h2',
                              text: '2021-22'
                          },
                          {
                              style: 'h2',
                              text: 'BATCH SUMMARY'
                          }
                      ]
                  }
                  ]
              ]
          },
          layout: {
              hLineWidth: (line) => line === 1,
              vLineWidth: () => 0,
              paddingBottom: () => 5
          },
        },
        {
          style:'sectionHeader',
          table: {
            
            headerRows: 1,
            widths: widthList,
            
            body: [
              this.headerList,
              ...this.admissionsfilteredlist.map((p) => {
                const temp = [];
                for (const y of this.fieldList) {
                  if (y === 'dob') {
                    temp.push(this.formatDate(p[y]));
                  } else {
                    temp.push(p[y]);
                  }
                }
                return temp;
              })
            ]
          }
        }
    ],
    images: {
      imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'

    },
    styles: {
      sectionHeader: {
        // bold: true,
        // decoration: 'underline',
        fontSize: 12,
        margin: [15, 15, 15, 15]
      },
      h1: {
        margin: [0, 5, 0, 0],
        fontSize: 16,
        bold: true
      },
      h2: {
          margin: [0, 5, 0, 0],
          fontSize: 10,
          bold: true
      }
    }
  };

  pdfMake.createPdf(docDefinition).download('BATCH SUMMARY.pdf');
}
private formatDate(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();
  if (month.length < 2) {month = '0' + month; }
  if (day.length < 2) {day = '0' + day; }
  return [day, month, year].join('-');
}

generateExcel() {
  this.headerList = [];
  this.fieldList = [];
  this.attributes.forEach(x => {
    this.headerList.push(x.name);
    this.fieldList.push(x.fieldName);
  });

  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('BATCH SUMMARY');
  // worksheet.addRow();
  // const imageId2 = workbook.addImage({
  //   base64: this.institution.institutionLogo,
  //   extension: 'jpg',
  // });
  // worksheet.addImage(imageId2, 'A1:B1');
  const titleRow = worksheet.addRow(['BATCH SUMMARY']);
  const headerRow = worksheet.addRow(this.headerList);
  for (const x1 of this.admissionsfilteredlist) {
    const x2 = Object.keys(x1);
    const temp = [];
    for (const y of this.fieldList) {
      if (y === 'dob') {
        temp.push(this.formatDate(x1[y]));
      } else {
        temp.push(x1[y]);
      }
    }
    worksheet.addRow(temp);
  }
  const fname = 'BATCH SUMMARY';

  // add data and file name and download
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
  });

}

}
