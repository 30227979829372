import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { PaymentGateway, PaymentMethod, PaymentProvider } from './paymentgateway.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentProviderService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<PaymentProvider[]> {
    return this.httpClient.get<PaymentProvider[]>(environment.apiUrl + '/PaymentProvider/', this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<PaymentProvider> {
    return this.httpClient.get<PaymentProvider>(environment.apiUrl + '/PaymentProvider/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(paymentProvider): Observable<PaymentProvider> {
  return this.httpClient.post<PaymentProvider>(environment.apiUrl + '/PaymentProvider/',
   JSON.stringify(paymentProvider), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, paymentProvider): Observable<PaymentProvider> {
    return this.httpClient.put<PaymentProvider>(environment.apiUrl + '/PaymentProvider/' + id,
     JSON.stringify(paymentProvider), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<PaymentProvider>(environment.apiUrl + '/PaymentProvider/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
