import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GradeScaleService } from './gradescale.service';
import { GradeScaleDetailService } from './gradescaledetail.service';
import { GradeScale, GradeScaleDetail } from './gradescale.model';
import { from } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-gradescale',
  templateUrl: './gradescale.component.html',
  styleUrls: ['./gradescale.component.css']
})
export class GradescaleComponent implements OnInit {
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  gradescaleForm: UntypedFormGroup;
  gradescaleDetailForm: UntypedFormGroup;
  grades: GradeScale[] = [];
  gradeFilteredList: GradeScale[] = [];
  gradeDetails: GradeScaleDetail[] = [];
  gradeDetailEdits: GradeScaleDetail[] = [];
  gradeScaleGroupId: number;
  showDetail = false;
  submitted = false;
  editRowData: any[] = [];
  //isEdit:any[]=[];
  //isSave:any[]=[];
  constructor(
    public gradeservice: GradeScaleService,
    public gradescaleservice: GradeScaleDetailService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }
  ListGrades() {
    this.gradeservice.getAll().subscribe((data: GradeScale[]) => {
      this.gradeFilteredList = this.grades = data;      
    });
  }

  ListGradeDetails() {
    this.gradescaleservice.getAll(this.gradeScaleGroupId).subscribe((data: GradeScaleDetail[]) => {
      this.gradeDetails = data;
      for (let i = 0; i < this.gradeDetails.length; i++) {
        this.editRowData.push(false);
      }
    });
  }
  cancelEdit(index:number)
  {
    this.editRowData[index]=false;
  }
  keyPress(event: any) {
    const pattern = /[0-9\.+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode !== '0') {
      event.preventDefault();
    }
  }

  ValidateGrade(gradeName: any, index: any) {
    var grade = this.gradeDetails.filter(c => c.gradeName.toUpperCase() == gradeName.toUpperCase());
    if (grade.length > 0) {
      this.gradeDetailEdits[index].gradeName = "";
      var msg = "Grade" + gradeName + "is availble..";
      this.toastr.warning(msg, 'Warning!', {
        timeOut: 3000
      });
    }    
  }
  addBlankDetailRow() {
    let gd: GradeScaleDetail = {} as GradeScaleDetail;
    gd.gradeName = '';
    gd.minMark = '';
    gd.maxMark = '';
    gd.creditPoint = '';
    this.gradeDetailEdits.push(gd);
  }
  editDetail(index: any) {
    this.editRowData[index] = true;
    //this.isEdit=true;
   // this.isSave=true;
  }

  saveDetail(index:any) {
    const detailData = this.gradeDetails[index];
    this.gradescaleDetailForm.get('id').patchValue(detailData.id);
    this.gradescaleDetailForm.get('gradeScaleGroupId').patchValue(this.gradeScaleGroupId);
    this.gradescaleDetailForm.get('gradeName').patchValue(detailData.gradeName);
    this.gradescaleDetailForm.get('minMark').patchValue(detailData.minMark);
    this.gradescaleDetailForm.get('maxMark').patchValue(detailData.maxMark);
    this.gradescaleDetailForm.get('creditPoint').patchValue(detailData.creditPoint);
    this.gradescaleservice.update(detailData.id,this.gradescaleDetailForm.value).subscribe(res => {
      console.log('gradescale updated!');
      this.ListGradeDetails();
      this.editRowData[index]=false;
      this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
        timeOut: 3000
      });

      this.gradescaleDetailForm = this.fb.group({
        id: [0],
        gradeScaleGroupId: [0, Validators.required],
        gradeName: ['', Validators.required],
        minMark: ['', Validators.required],
        maxMark: ['', Validators.required],
        creditPoint: ['', Validators.required]
      });
      this.gradeDetailEdits = [];
    });
  }

  saveDetailEdit(index:any) {
    const detailData = this.gradeDetailEdits[index];
    this.gradescaleDetailForm.get('gradeScaleGroupId').patchValue(this.gradeScaleGroupId);
    this.gradescaleDetailForm.get('gradeName').patchValue(detailData.gradeName);
    this.gradescaleDetailForm.get('minMark').patchValue(detailData.minMark);
    this.gradescaleDetailForm.get('maxMark').patchValue(detailData.maxMark);
    this.gradescaleDetailForm.get('creditPoint').patchValue(detailData.creditPoint);
    this.gradescaleservice.create(this.gradescaleDetailForm.value).subscribe(res => {
      console.log('gradescale created!');
      this.editRowData[index]=false;
      this.ListGradeDetails();
      this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
        timeOut: 3000
      });

      this.gradescaleDetailForm = this.fb.group({
        id: [0],
        gradeScaleGroupId: [0, Validators.required],
        gradeName: ['', Validators.required],
        minMark: ['', Validators.required],
        maxMark: ['', Validators.required],
        creditPoint: ['', Validators.required]
      });
      this.gradeDetailEdits = [];
    });
  }

  deleteDetailTemp(index:number)
  {
    this.gradeDetailEdits.splice(index, 1);
  }

  deleteDetail(value: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.gradescaleservice.delete(value).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListGradeDetails();
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }

  get totalRows(): number {
    return this.gradeFilteredList.length;
  }
  get f() { return this.gradescaleForm.controls; }
  resetForm() {
    this.submitted = false;
    this.gradescaleForm = this.fb.group({
      id: [0],
      scaleGroup: ['', Validators.required],
      description: ['', Validators.required],
      status: ['true', Validators.required]
    });
    this.gradeScaleGroupId = 0;
    this.gradeDetails = [];
    this.showDetail = false;
  }
  submitForm() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.gradescaleForm.invalid) {
      return;
    }
    if (this.gradescaleForm.get('id').value === 0) {
      console.log(this.gradescaleForm.value);
      this.gradeservice.create(this.gradescaleForm.value).subscribe(res => {
        console.log('gradescale created!');
        this.ListGrades();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    } else {
      this.gradeservice.update(this.gradescaleForm.get('id').value, this.gradescaleForm.value).subscribe(res => {
        console.log('gradescale updated!');
        this.ListGrades();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        // this.casteForm.reset();
      });
    }
  }
  updateForm(id: number) {
    this.gradeservice.getById(id).subscribe((data: GradeScale) => {
      console.log(data);
      this.gradeScaleGroupId = data.id;
      this.ListGradeDetails();
      this.showDetail = true;
      this.gradescaleForm = this.fb.group({
        id: [data.id],
        scaleGroup: [data.scaleGroup, Validators.required],
        description: [data.description, Validators.required],
        status: [data.status.toString(), Validators.required]
      });
    });
  }

  

  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.gradeservice.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListGrades();
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }

  submitDetailForm() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.gradescaleDetailForm.invalid) {
      return;
    }
    if (this.gradescaleDetailForm.get('id').value === 0) {
      console.log(this.gradescaleDetailForm.value);
      this.gradescaleservice.create(this.gradescaleDetailForm.value).subscribe(res => {
        console.log('gradescale created!');
        this.ListGrades();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    } else {
      this.gradescaleservice.update(this.gradescaleDetailForm.get('id').value, this.gradescaleDetailForm.value).subscribe(res => {
        console.log('gradescale updated!');
        this.ListGrades();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        //this.casteForm.reset();
      });
    }
  }

  ngOnInit(): void {
    this.ListGrades();
    this.gradescaleForm = this.fb.group({
      id: [0],
      scaleGroup: ['', Validators.required],
      description: ['', Validators.required],
      status: ['true', Validators.required]
    });

    this.gradescaleDetailForm = this.fb.group({
      id: [0],
      gradeScaleGroupId: [0, Validators.required],
      gradeName: ['', Validators.required],
      minMark: ['', Validators.required],
      maxMark: ['', Validators.required],
      creditPoint: ['', Validators.required]
    });
  }
}
