import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginPageConfig } from '../../edumaq/configuration/loginpageconfig/loginpageconfig.model';

@Injectable({
    providedIn: 'root'
  })
  export class LoginService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         enctype: 'multipart/form-data'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<LoginPageConfig[]> {
        return this.httpClient.get<LoginPageConfig[]>(environment.apiUrl + '/LoginPageConfiguration/')
        .pipe(
          catchError(this.errorHandler)
        );
      }

    
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }

  }
