import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {SortPipe} from './sortpipe';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AcademicRoutingModule} from './academic-routing.module';
import { MastersettingsModule } from './mastersettings/mastersettings.module';
import { ApplicantregistrationComponent } from './applicantregistration/applicantregistration.component';
import { AcademicComponent } from './academic/academic.component';
import { RegistrationrecieptComponent } from './registrationreciept/registrationreciept.component';
import { AcademicsettingsModule } from './academicsettings/academicsettings.module';
import { ApplicantregistrationreportComponent } from './applicantregistrationreport/applicantregistrationreport.component';
import { ApplicantregistrationfollowupComponent } from './applicantregistrationfollowup/applicantregistrationfollowup.component';
import { AdmissionprocedureComponent } from './admissionprocedure/admissionprocedure.component';
import { SetentrancedateComponent } from './setentrancedate/setentrancedate.component';
import { EntranceresultComponent } from './entranceresult/entranceresult.component';
import { EntrancesummaryComponent } from './entrancesummary/entrancesummary.component';
import { RegFeeCollectionReportComponent } from './reg-fee-collection-report/reg-fee-collection-report.component';
import { StudentmanagementModule } from './studentmanagement/studentmanagement.module';
import { LeavemanagementModule } from './leavemanagement/leavemanagement.module';
import { StudyplanModule } from './studyplan/studyplan.module';
import { TimetableModule } from './timetable/timetable.module';
import { AttendanceModule } from './attendance/attendance.module';
import { EventcalendarModule } from './eventcalendar/eventcalendar.module';
import { ClassmanagementModule } from './classmanagement/classmanagement.module';
import { SyllabusComponent } from './syllabus/syllabus.component';
import { FeemanagementModule } from './feemanagement/feemanagement.module';
import { InstitutionprofileComponent } from './institutionprofile/institutionprofile.component';


import { OfflineExaminationModule } from './offline-examination/offline-examination.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { OnlineExaminationModule } from './online-examination/online-examination.module';

import { TransportModule } from './transport/transport.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogModule } from '@angular/material/dialog';
import { NgSelect2Module } from 'ng-select2';

@NgModule({
  declarations: [ApplicantregistrationComponent, AcademicComponent, RegistrationrecieptComponent,
     ApplicantregistrationreportComponent, ApplicantregistrationfollowupComponent,
      AdmissionprocedureComponent, SetentrancedateComponent,
      EntranceresultComponent, EntrancesummaryComponent, RegFeeCollectionReportComponent,
       SyllabusComponent,
       InstitutionprofileComponent],
  imports: [
    OfflineExaminationModule,
    CommonModule,
    NgbModule,
    NgSelect2Module,
    AcademicRoutingModule,
    MastersettingsModule,
    FormsModule,
    ReactiveFormsModule,
    AcademicsettingsModule,
    StudentmanagementModule,
    LeavemanagementModule,
    StudyplanModule,
    TimetableModule,
    AttendanceModule,
    EventcalendarModule,
    ClassmanagementModule,
    FeemanagementModule,
    //TransportModule,
    SharedModule,
    OnlineExaminationModule
  ],
  exports:[
    CommonModule,
    MatDialogModule,
    SharedModule,
    NgbModule
  ]
})
export class AcademicModule { }
