// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ng-select .ng-clear-wrapper {
    display: none;
}
.ng-select .ng-select-container{
    min-height: 30px ! important;
    max-height: 30px !important;
    height: 30px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/academic/studentmanagement/asignadditionalsubject/asignadditionalsubject.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,4BAA4B;IAC5B,2BAA2B;IAC3B,uBAAuB;AAC3B","sourcesContent":[".ng-select .ng-clear-wrapper {\r\n    display: none;\r\n}\r\n.ng-select .ng-select-container{\r\n    min-height: 30px ! important;\r\n    max-height: 30px !important;\r\n    height: 30px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
