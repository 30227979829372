import { Component, OnInit } from '@angular/core';

import { PaymentGateway, PaymentMethod, PaymentProvider} from './paymentcheckout.model';
import { PaymentCheckoutService } from './paymentcheckout.service';
@Component({
  selector: 'app-paymentcheckout',
  templateUrl: './paymentcheckout.component.html',
  styleUrls: ['./paymentcheckout.component.css']
})
export class PaymentcheckoutComponent implements OnInit {

  paymentGateways: PaymentGateway[] = [];
  paymentMethods: PaymentMethod[] = [];
  paymentProviders: PaymentProvider[] = [];

  constructor(
    private checkoutService: PaymentCheckoutService
  ) { }

  ListPaymenetgateway() {
    this.checkoutService.getAllPaymentGateways().subscribe((data: PaymentGateway[]) => {
        this.paymentGateways = data;
    });
  }

  ListPaymentMethod(gatewayId: any) {
    this.checkoutService.getAllPaymentMethods().subscribe((data: PaymentMethod[]) => {
        this.paymentMethods = data.filter((x: PaymentMethod) => x.gatewayId === gatewayId);
    });
  }

  ListPaymentProvider(methodId: any) {
    this.checkoutService.getAllPaymentProviders().subscribe((data: PaymentProvider[]) => {
        this.paymentProviders = data.filter((x: PaymentProvider) => x.paymentMethodId === methodId);
    });
  }

  ngOnInit(): void {
    this.ListPaymenetgateway();
  }

}
