import { Component, OnInit } from '@angular/core';
import { Student, StudentSibling, StudentDocument, StudentsSiblingsReport } from '../../../academic/studentmanagement/student.model';
import { StudentService } from '../../../academic/studentmanagement/student.service';
import { environment } from '../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import * as $ from 'jquery';

@Component({
  selector: 'app-siblingreport',
  templateUrl: './siblingreport.component.html',
  styleUrls: ['./siblingreport.component.css']
})
export class SiblingreportComponent implements OnInit {
  admissions: Student[] = [];
  sadmissions: Student[] = [];
  oadmissions: Student[] = [];
  siblingAdmissions: Student[] = [];
  showStudentDetail: boolean[] = [];
  studentsSiblingsReport: any[] =[];
  studentsSiblingsReportList: any[] =[];
  institution: any;
  seqno = 1;
  constructor (
    public studentService: StudentService,
    private toastr: ToastrService
  ) { }

  ListStudents() {
    this.studentService.getAllStudentList().subscribe((data: Student[]) => {
      this.sadmissions = data;
      this.getStudentsSiblingsReport();
    });
  }

  getStudentsSiblingsReport(){
    this.studentService.getStudentsSiblingsReports().subscribe((data: any[]) => {
      this.studentsSiblingsReport = data;
      this.getSiblingsDetails();
    });
  }

  getSibllingCount(fatherName: any, list: any) {
      return list.filter((x: Student) => x.fatherName === fatherName).length;
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.studentsSiblingsReport = this.studentsSiblingsReportList;
    } else {
      this.studentsSiblingsReport = this.studentsSiblingsReportList.filter((record) => {
        return record.fatherName.toLowerCase().includes(keyword.toLowerCase()) ||
          record.fatherMobileNo.toString().toLowerCase().includes(keyword.toLowerCase());
      });
    }
  }

  getSiblingsDetails() {
    this.studentsSiblingsReport.forEach((x) => {
      const siblingIds = x.siblingIds;
      const filteredItems = this.sadmissions.filter(item => {
        const sIds: string[] = siblingIds.split(',').map(y => y.trim());
        return sIds.includes(item.id.toString());
      });
      x.studentSiblings = [];
      filteredItems.forEach((z) => {
        const sibling: StudentSibling = {} as StudentSibling;
        sibling.admissionNo = z.admissionNo;
        sibling.batchName = z.batchName;
        sibling.className = z.className;
        sibling.rollNo = z.rollNo;
        sibling.studentName = z.studentName;
        sibling.status = z.status;
        x.studentSiblings.push(sibling);
      });
    });
    this.studentsSiblingsReportList = this.studentsSiblingsReport;
    //console.log(this.studentsSiblingsReport);
  }

  getSiblings(fatherName) {
    if (fatherName === '') {
      this.siblingAdmissions = [];
    } else {
      this.siblingAdmissions = this.admissions.filter((x: Student ) => {
        return x.fatherName.includes(fatherName);
      });
      this.siblingAdmissions.forEach((x, i) => {
       /// x.studentSiblings = [];
        this.showStudentDetail.push(false);
        this.oadmissions = this.sadmissions.filter(y => y.fatherName === x.fatherName);
        this.oadmissions.forEach((z) => {
          const sibling: StudentSibling = {} as StudentSibling;
          sibling.admissionNo = z.admissionNo;
          sibling.batchName = z.batchName;
          sibling.className = z.className;
          sibling.rollNo = z.rollNo;
          sibling.studentName = z.studentName;
          //x.studentSiblings.push(sibling);
        });
      });
    }
  }

  showDetail(siblingIds: string, id: number) {
    this.showStudentDetail[id] = !this.showStudentDetail[id];
  }


  // get totalRows(): number {
  //   return this.casteFilteredList.length;
  // }

  generatePDF() {
    //this.sortAdmissions();
    if (this.studentsSiblingsReport.length === 0) {
      this.toastr.warning('No records found.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }

    this.seqno = 1;
    this.studentsSiblingsReport.forEach((x) => {
      x.sno = this.seqno++;
    });

    //const academicYearId = this.academicYearId;
    const header = ["S.No.", "PARENT NAME", "SIBLING COUNT", "MOBILE NUMBER"];
    const fields = ["sno", "fatherName", "countOfSS", "fatherMobileNo"];
    const widthList = [];
    for (const item of header) {
        widthList.push('auto');
    }
    var i = 0;
    const docDefinition = {
      pageOrientation: 'landscape',
      content: [
        {
          table: {
              widths: ['auto', '*'],
              body: [
                  [ {
                      width: 100,
                      height: 100,
                      image: 'imageKey'
                  }, {
                      width: '*',
                      alignment: 'center',
                      stack: [
                          {
                              style: 'h1',
                              text: this.institution.institutionName
                          },
                          {
                            style: 'h2',
                            text: this.institution.address
                          },
                          {
                              style: 'h1',
                              text: 'SIBLING REPORT'
                          }
                      ]
                  }
                  ]
              ]
          },
          layout: {
              hLineWidth: (line) => line === 1,
              vLineWidth: () => 0,
              paddingBottom: () => 5
          },
        },
        {
          
          table: {
            headerRows: 1,
            widths: widthList,
            style:'sectionHeader',
            body: [
              header,
              ...this.studentsSiblingsReport.map((p) => {
                const temp = [];
                for (const y of fields) {
                  temp.push(p[y]);                   
                }
                return temp;
              })
            ]
          }
        }, 
      ],
      images: {
        imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
        // via URL address, which can be referenced by this key
     },
      styles: {
        sectionHeader: {
          // bold: true,
          // decoration: 'underline',
          fontSize: 5,
         margin: [0, 15, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 16,
          bold: true
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        }
      }
    };
    pdfMake.createPdf(docDefinition).download('SIBLING REPORT.pdf');
  }

  generateExcel() {
    if (this.studentsSiblingsReport.length === 0) {
      this.toastr.warning('No records found.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }

    this.seqno = 1;
    this.studentsSiblingsReport.forEach((x) => {
      x.sno = this.seqno++;
    });

    //const academicYearId = this.academicYearId;
    const header = ["S.No.", "PARENT NAME", "SIBLING COUNT", "MOBILE NUMBER"];
    const fields = ["sno", "fatherName", "countOfSS", "fatherMobileNo"];
    
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Student Data');
    const headerRow = worksheet.addRow(header);
    for (const x1 of this.studentsSiblingsReport) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of fields) {
        temp.push(x1[y]);
      }
      worksheet.addRow(temp);
    }
    const fname = 'Sibling Report';
    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });
    
  }

  ngOnInit(): void {
    this.ListStudents();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
  }

}
