import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs"; 
import { GradescalegroupService } from "../services/gradescalegroup.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ExamResultReportService } from "../services/exam-result-report.service";
import { StudentWiseReportCardService } from "../services/student-wise-report-card.service";
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';


@Component({
  selector: 'app-consolidate-exam-report',
  templateUrl: './consolidate-exam-report.component.html',
  styleUrls: ['./consolidate-exam-report.component.css'],
  providers: [FormGroupDirective],
})
export class ConsolidateExamReportComponent implements OnInit {
  submitted = false; 
  categorydropDown: any = [];
  examNamedropDown: any = [];
  finalexamNamedropDown: any = [];
  examClassdropDown: any = [];
  StudentsList: any = [];
  CategoryiesList:any=[];
  classDropDown: any = [];
  section: any = [];
  DetailsResult: any = [];
  isLoading: boolean = false;
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  disabled = true;
  constructor(private gradeService: GradescalegroupService,
    private toastr: ToastrService,
    public fb: UntypedFormBuilder,
    public service: ExamResultReportService,
    public classcourseService: ClassCourseService,
    public examService: StudentWiseReportCardService,
    public batchService: BatchService,
    public formDirective: FormGroupDirective) { }

    consolidateexamreport: UntypedFormGroup;
    classBatachData: any = [];
  
    ngOnInit(): void {
      this.consolidateexamreport = this.fb.group({
        id: [0],
        examCategory: ["all", Validators.required],
        classId: ["", Validators.required],
        classBatch: ["", Validators.required]
      });
      this.getCategoryDropDown();
      this.getClassDropDown();
    }
  
    get f() {
      return this.consolidateexamreport.controls;
    }
    getCategoryDropDown() {
      this.service.getAllCatefgoryDropDown().subscribe((data) => {
        this.categorydropDown = data;
      });
      this.checkingButton();
    }
    onCategoryEvent() {
      this.consolidateexamreport.value.examName = "";
      this.finalexamNamedropDown = [];
      this.examClassdropDown = [];
      this.StudentsList = []; 
      this.DetailsResult=[];   
      this.CategoryiesList=[];  
      this.checkingButton();   
      this.consolidateexamreport = this.fb.group({
        id: [0],
        examCategory: [this.consolidateexamreport.controls.examCategory.value, Validators.required],
        classId: ["", Validators.required],
        classBatch: ["", Validators.required]
      }); 
    }
    submitForm() {}

    getClassDropDown() {       
      this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
        this.classDropDown = data;
        this.examClassdropDown = data;
        this.checkingButton();
      });
      
        this.consolidateexamreport = this.fb.group({
          id: [0],
          examCategory: [this.consolidateexamreport.controls.examCategory.value, Validators.required],
          classId: ["", Validators.required],
          classBatch: ["", Validators.required]
        }); 
    }
  
    selectedClass(id) {
     
      let batchList = [];
      this.StudentsList = []; 
      this.DetailsResult=[];   
      this.CategoryiesList=[]; 
      if (this.consolidateexamreport.value.classId !== "") {        
        this.batchService.getAll().subscribe((data: Batch[]) => {
          this.classBatachData = data.filter((batch: Batch) => batch.classId.toString() === id.toString());
        });
        this.checkingButton();
      }
      this.consolidateexamreport = this.fb.group({
        id: [0],
        examCategory: [this.consolidateexamreport.controls.examCategory.value, Validators.required],
        classId: [this.consolidateexamreport.controls.classId.value, Validators.required],
        classBatch: ["", Validators.required]
      }); 
    }
  
    selectedClassBatch() {
      this.StudentsList = []; 
      this.DetailsResult=[];   
      this.CategoryiesList=[]; 
      if (this.consolidateexamreport.value.classBatch !== "") {
        this.section = this.classBatachData.filter(
          (element) =>
            element.batchId === this.consolidateexamreport.controls.classBatch.value
        );
        this.checkingButton();
      }
    }
  
  
  
    checkingButton() {
      if (
        this.consolidateexamreport.controls.examCategory.value &&
        this.consolidateexamreport.controls.classId.value &&
        this.consolidateexamreport.controls.classBatch.value
      ) {
        this.disabled = false;
      } 
    }
    getAllStudents() {
      this.isLoading = true;
       if (this.consolidateexamreport.valid) {
      this.examService.GetConsolidateExamReport(
        this.consolidateexamreport.controls.examCategory.value == "all" ? -1 : this.consolidateexamreport.controls.examCategory.value ,
            this.consolidateexamreport.controls.classId.value,
            this.consolidateexamreport.controls.classBatch.value
          ).subscribe((data) => {
            this.DetailsResult = data;
            if(this.DetailsResult[0].studentDetails.length>0)
            {
              this.StudentsList=this.DetailsResult[0].studentDetails[0];
              if(this.DetailsResult[0].categorywisedisplays!==null)
              {
                  this.CategoryiesList=this.DetailsResult[0].categorywisedisplays.filter(x=>x.studentId === this.StudentsList.studentId);
              }
            }
            this.isLoading = false;
          });
      
    }
    else{
      this.toastr.warning("All field are required", "WARNING!!!", {
        timeOut: 3000,
      });
      this.isLoading = false;
      return;
    }
    }

    
  
    resetForm() {
      this.submitted = false;
      this.finalexamNamedropDown = [];
      this.classDropDown = [];
      this.classBatachData = [];
      this.StudentsList = [];
      this.formDirective.reset();
      this.consolidateexamreport.reset();
      this.disabled = true;
      this.consolidateexamreport = this.fb.group({
          id: [0],
          examCategory: ["all", Validators.required],
          classId: ["", Validators.required],
          classBatch: ["", Validators.required],
        });
    }

}
