import { Component, OnInit } from '@angular/core';
import { certificateType, templateSetting } from '../templatesetting/certificateType.models';
import { TemplateSettingsService } from '../templatesetting/template-settings.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-templatelist',
  templateUrl: './templatelist.component.html',
  styleUrls: ['./templatelist.component.css']
})
export class TemplatelistComponent implements OnInit {

  templateSettingList:templateSetting[];
  certificateTypeList:certificateType[];
  ctypes:certificateType[];
  rowData: any;
  templateContent: SafeHtml = '';

  constructor(
    public templateSettingsService: TemplateSettingsService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.onListType();
    this.ListAllTemplates();
  }
  
  onListType(){
    this.templateSettingsService.getAll().subscribe((data:certificateType[])=>{
      this.certificateTypeList=data;
      this.ctypes = data.filter((o: any) => o.status === true);
    });
  }

  ListAllTemplates(){
    this.templateSettingsService.getAllTemplates().subscribe((data:templateSetting[])=>{
      this.templateSettingList=data;
    });
  }

  openPopup(template: any) {
    debugger;
    this.rowData = template;
    this.templateContent = this.sanitizer.bypassSecurityTrustHtml(this.rowData.templateText);

  }

  getValueById(id: number) {
    const dataItem = this.ctypes.find(item => item.id === id);
    return dataItem ? dataItem.certificateTypeName : '';
  }

  deleteTemplate(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.templateSettingsService.deleteTemplate(id).subscribe(res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            this.ListAllTemplates();
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
        }
      });
    }
}
