import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { forkJoin } from 'rxjs';

import { Academicyear } from '../../../academic/mastersettings/models/academicyear';
import { ClassCourse } from '../../../academic/mastersettings/models/classcourse';
import { Batch } from '../../../academic/mastersettings/models/batch';
import { certificateType, templateSetting } from '../../../administration/certificate/templatesetting/certificateType.models';
import { StudentCertificate } from './generatecertificate.models';


import { BatchService } from '../../../academic/mastersettings/batch/batch.service';
import { ClassCourseService } from '../../../academic/mastersettings/classcourse/classcourse.service';
import { SettingsService } from '../../../academic/mastersettings/settings.service';
import { Student, StudentSibling, StudentDocument } from '../../../academic/studentmanagement/student.model';
import { StudentService } from '../../../academic/studentmanagement/student.service';
import { TemplateSettingsService } from '../../../administration/certificate/templatesetting/template-settings.service';
import { GenerateCertificateService } from './generatecertificate.service';

declare var $: any;
@Component({
  selector: 'app-generatecertificate',
  templateUrl: './generatecertificate.component.html',
  styleUrls: ['./generatecertificate.component.css']
})
export class GeneratecertificateComponent implements OnInit {

  loading=false;
  academicYears: Academicyear[] = [];
  classcourses: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  sortedAdmissions: Student[] = [];
  certificateTypeList:certificateType[];
  templateSettingList:templateSetting[];
  studentCertificateList:StudentCertificate[] = [];
  filtertedStudentCertificateList:StudentCertificate[] = [];
  ctypes:certificateType[];
  studentCertificate: StudentCertificate;
  filtertedGeneratedList: any[] = [];
  sortedAdmissionsList: any[] = [];

  classId = '';
  batchId = '';
  certificateTypeId = '';
  templateId = '';
  institution: any;
  academicYearId = '0';
  selectedStudentIds: any[] = [];
  results: any[] = [];

  constructor(
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    private toastr: ToastrService,
    public academicyearService: SettingsService,
    public templateSettingsService: TemplateSettingsService,
    public generateCertificateService: GenerateCertificateService,
    private cdr: ChangeDetectorRef
  ) { }

  ListAcademicYear() {
    this.academicyearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
    });
  }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.academicYearId = data.id.toString();
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  onClassSelected(value: string) {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toLocaleLowerCase());
    });
    this.classId = value;
    this.certificateTypeId = '';
    this.templateId = '';
    this.sortedAdmissions = [];
  }

  onBatchSelected(value: string) {
    this.certificateTypeId = '';
    this.templateId = '';
    this.sortedAdmissions = [];
  }

  onCertificateTypeSelected() {
    this.sortedAdmissions = [];
    this.ListAllTemplates();
  }

  onTemplateSelected() {
    this.sortedAdmissions = [];
  }

  getAllBatchDetails() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      console.log(data);
      this.batches = data;
    });
  }

  ListStudents() {
    this.studentService.getAllStudentListForCertificate().subscribe((data: Student[]) => {
      this.admissions = data;
      console.log(data);
    });
  }

  LoadStudents() {
    if (this.classId == '') {
      this.toastr.warning('Please select class.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    if (this.batchId == '') {
      this.toastr.warning('Please select batch.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    if (this.certificateTypeId == '') {
      this.toastr.warning('Please select certificate type.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    if (this.templateId == '') {
      this.toastr.warning('Please select template.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    this.loading = true;
    this.ListAllCertificates();
    setTimeout(()=>{
      this.loading = false;
    },1000);
  }

  sortAdmissions() {
    this.sortedAdmissions = this.admissions;
    if (this.classId != '' && this.batchId != '') {
      this.sortedAdmissions = this.admissions.filter((x) => x.classCourseId.toString() === this.classId.toString() 
      && x.batchId.toString() === this.batchId.toString());
    }

    this.filtertedStudentCertificateList = this.studentCertificateList.filter((x) => x.certificateTypeId.toString() === this.certificateTypeId.toString()
    && x.templateId.toString() === this.templateId.toString());

    this.sortedAdmissions.forEach(item => {
      const slist =  this.filtertedStudentCertificateList.find(y => y.studentId === item.id);
      (item as any).generated = slist ? true : false;
    });

    this.filtertedGeneratedList = this.sortedAdmissions;
    this.filtertedGeneratedList = this.filtertedGeneratedList.filter(record => record.generated === true);
    this.sortedAdmissionsList = this.sortedAdmissions;
  }

  ListAllCertificates(){
    this.generateCertificateService.getAll().subscribe((data:StudentCertificate[])=>{
      this.studentCertificateList=data;
      this.sortAdmissions();
    });
  }

  get totalCertificates(): number {
    return this.filtertedGeneratedList.length;
  }

  generatecertificate() {
    debugger;
    const studentIds: any[] = [];
    this.sortedAdmissions.forEach(x => {
      if (x.checked) {
        studentIds.push(x.id);
      }
    });
    if (studentIds.length === 0) {
      this.toastr.warning('Please select students.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    const recordsToCreate = [];
    for (const item of studentIds) {
      this.studentCertificate = {
        id: 0,
        studentId: item,
        certificateTypeId: Number(this.certificateTypeId),
        templateId: Number(this.templateId),
        //certificateContent: this.getCertificateContent(item)
      };
      recordsToCreate.push(this.studentCertificate);
    }
    //this.createRecords(recordsToCreate);
    this.forkJoinCreateRecords(recordsToCreate);
    // for (const item of studentIds) {
    //   this.studentCertificate = {
    //     id: 0,
    //     studentId: item,
    //     certificateTypeId: Number(this.certificateTypeId),
    //     templateId: Number(this.templateId),
    //     certificateContent: this.getCertificateContent(item)
    //   };
    //   this.generateCertificateService.create(this.studentCertificate).subscribe(res => {
    //     console.log(' created!');
    //     this.sortedAdmissions.forEach(item => {
    //       item.checked = false;
    //     });
    //     this.ListAllCertificates();
    //   });
    // }
    // studentIds.forEach(item => {
    //   this.studentCertificate = {
    //     id: 0,
    //     studentId: item,
    //     certificateTypeId: Number(this.certificateTypeId),
    //     templateId: Number(this.templateId),
    //     certificateContent: this.getCertificateContent(item)
    //   };
    //   this.generateCertificateService.create(this.studentCertificate).subscribe(res => {
    //     console.log(' created!');
    //     this.sortedAdmissions.forEach(item => {
    //       item.checked = false;
    //     });
    //     this.ListAllCertificates();
    //   });
    // });
    // this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
    //   timeOut: 3000
    // });
    //this.LoadStudents();
    //$('btnLoadStudents').click();
  }

  forkJoinCreateRecords(recordsToCreate){
    const observables = recordsToCreate.map(item => 
        this.generateCertificateService.create(item)
    );
    debugger;
    forkJoin(observables).subscribe(
      results => { 
        debugger;
        this.results = results;
        this.sortedAdmissions.forEach(item => {
          item.checked = false;
        });
        this.ListAllCertificates();
        this.toastr.success('Certificates has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
      },
      error => {
        console.error('Error generating certificates:', error);
      }
    );
  }

  async createRecords(recordsToCreate) {
    debugger;
    const createPromises = recordsToCreate.map(item => {
      this.generateCertificateService.create(item).toPromise()
    });
    try {
      const responses = await Promise.all(createPromises);
      console.log('All certificates generated:', responses);
      this.sortedAdmissions.forEach(item => {
        item.checked = false;
      });
      this.ListAllCertificates();
      this.toastr.success('Certificates has been sucessfully saved', 'SUCCESS!', {
        timeOut: 3000
      });
  } catch (error) {
      console.error('Error generating certificates:', error);
    }
  }

  // getCertificateContent(item) {
  //   debugger;
  //   let cc = this.templateSettingList.find(x => x.id.toString() === this.templateId.toString()).templateText;
  //   if (cc) {
  //       const StudentName = this.sortedAdmissions.find(y => y.id.toString() === item.toString()).studentName;
  //       const FatherName = this.sortedAdmissions.find(y => y.id.toString() === item.toString()).fatherName;
  //       const AdmissionNo = this.sortedAdmissions.find(y => y.id.toString() === item.toString()).admissionNo;
  //       const MobileNo = this.sortedAdmissions.find(y => y.id.toString() === item.toString()).smsMobNumber;
  //       cc = cc.replace('{{StudentName}}', StudentName);
  //       cc = cc.replace('{{FatherName}}', FatherName);
  //   }
  //   return cc;
  // }

  ListCertificateTypes(){
    this.templateSettingsService.getAll().subscribe((data:certificateType[])=>{
      this.certificateTypeList=data;
      this.ctypes = data.filter((o: any) => o.status === true);
    });
  }

  ListAllTemplates(){
    this.templateSettingsService.getAllTemplates().subscribe((data:templateSetting[])=>{
      this.templateSettingList=data.filter((o: any) => o.issueFor == 'Student' && o.certificateTypeId.toString() === this.certificateTypeId.toString());
    });
  }

  resetAll() {
    this.loading=false;
    this.sortedAdmissions = [];
    this.classId = '';
    this.batchId = '';
    this.certificateTypeId = '';
    this.templateId = '';
  }

  get totalRows(): number {
    return this.sortedAdmissions.length;
  }

  ngOnInit(): void {
    this.ListClassCourse();
    this.ListAcademicYear();
    this.currentAcademicYear();
    this.ListStudents();
    this.ListCertificateTypes();
    //this.ListAllTemplates();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
  }

  onKeyUpEvent(event: any) {
    debugger;
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.sortedAdmissions = this.sortedAdmissionsList;
    } else {
      this.sortedAdmissions = this.sortedAdmissionsList.filter((record: Student) => {
        return record.studentName.toLowerCase().includes(keyword.toLowerCase()) ||
          record.admissionNo.toString().toLowerCase().includes(keyword.toLowerCase()) ||
          record.fatherName.toString().toLowerCase().includes(keyword.toLowerCase()) ||
          record.smsMobNumber.toString().toLowerCase().includes(keyword.toLowerCase());
      });
    }
  }
}
