import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {ExamrankService} from '../../services/examrank.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-examranking',
  templateUrl: './examranking.component.html',
  styleUrls: ['./examranking.component.css']
})
export class ExamrankingComponent implements OnInit {

  constructor(public fb:UntypedFormBuilder,public service:ExamrankService, private toastr: ToastrService,) { }
  examRankingfrmgroup:UntypedFormGroup;
  classDropdown:any=[];
  rankslist:any=[];
  rankslistfilter:any=[];
  recordsCount:number;
  classdropdown:any=[];

  ngOnInit(): void {
    this.examRankingfrmgroup=this.fb.group({
      id:[0],
      ClassId:['',Validators.required],
      RankingName:['',Validators.required],
      GPALavel:['',Validators.required],
      GPALimitType:['',Validators.required],
      NoOfSubjects:[0,Validators.required],
      SubjectLimitType:['',Validators.required]
    })
    this.getClassdropdown();
    this.getRanks();
  }

  getclassdropdown(){

  }

  getClassdropdown(){
    this.service.getClassdropdown().subscribe((data)=>{
     ;
       this.classDropdown=data;
    })
  }
  submitForm() {
    if(this.examRankingfrmgroup.invalid){
      this.toastr.warning('All field are required','WARNING!!!',{timeOut:3000});
      return;
    }
    if(this.examRankingfrmgroup.get("id").value === 0){
    // this.service.createexamranking(this.examRankingfrmgroup.value).subscribe((data) => {
    //  if(data==true){
    //   this.toastr.success("Rank insertes successsfully", "SUCCESS!", {
    //     timeOut: 3000,
    //   });

    //   this.resetform();
    //   this.getRanks();
    //  }else{
    //   this.toastr.warning("Combination with this ranking and class already exists", "DUPLICATE!", {
    //     timeOut: 3000,
    //   });
    // }
    // })
    this.service.createexamranking(this.examRankingfrmgroup.value).subscribe(res=>{      
      console.log(res)          
      this.toastr.success("Successfully created","SUCCESS!",{timeOut:3000});
      this.getRanks();
      this.resetform();
    }); 
  }
  else
  {
    // if(this.rankslist.map(x => x.rankingName == this.examRankingfrmgroup.value.RankingName)){
    //   this.toastr.warning("Combination with this ranking and class already exists", "DUPLICATE!", {
    //     timeOut: 3000,
    //   });
    // }
    // else
   // {
    // this.service.saveRanking(this.examRankingfrmgroup.get("id").value,this.examRankingfrmgroup.value).subscribe((data)=>{
    //   if(data==true){
    //   this.toastr.success("Rank successfully updated","SUCCESS!",{timeOut:3000});
    //   this.resetform();
    //   this.getRanks();
    //   }else{
    //     this.toastr.warning("Combination with this ranking and class already exists", "DUPLICATE!", {
    //       timeOut: 3000,
    //     });
    //   }
    // })
   // }
   this.service.saveRanking(this.examRankingfrmgroup.get('id').value,this.examRankingfrmgroup.value).subscribe(res=>{      
    console.log(res)          
    this.toastr.success("Rank successfully updated","SUCCESS!",{timeOut:3000});
    // this.submitted=false;
    this.getRanks()
  }); 
  }
  }

  getRanks(){
    this.service.getexamranking().subscribe((data)=>{
     ;
      this.rankslist=this.rankslistfilter=data;
      this.recordsCount=this.rankslist.length;
    })
  }
  deleterank(id:any){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton:true,
      confirmButtonText:'Yes, delete it!',
      cancelButtonText:'No, keep it'
    }).then((result)=>{
      if(result.value){
        this.service.deleteRank(id).subscribe((data)=>{
        //  ;
        //   if(data==true){
        //   this.toastr.success("Designation deleted successsfully","DELETED!",{
        //     timeOut:3000,
        //   });
        //  this.getRanks();
        // }else{
        //   this.toastr.error("Failed to delete Designation","Not Deleted!",{
        //     timeOut:3000,
        //   });
        //   this.getRanks();
        //}
        Swal.fire(
          'Deleted!',
          'Designation deleted successsfully.',
          'success'
        );
        this.getRanks();
        })
      }
    })
  }
  editrank(id:any){
    this.service.getExamrankbyid(id).subscribe((data)=>{
      
      this.examRankingfrmgroup=this.fb.group({
        id: [data[0]["id"]],
        ClassId:[data[0]["classId"],Validators.required],
        RankingName:[data[0]["rankingName"],Validators.required],
        GPALavel:[data[0]["gpaLavel"],Validators.required],
        GPALimitType:[data[0]["gpaLimitType"],Validators.required],
        NoOfSubjects:[data[0]["noOfSubjects"],Validators.required],
        SubjectLimitType:[data[0]["subjectLimitType"],Validators.required]
       });
    })
    
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
   ;
    if ( keyword === '') {
      this.rankslist = this.rankslistfilter;
      this.recordsCount= this.rankslist.length;
    } else {
    this.rankslist = this.rankslistfilter
                                .filter((x) => x.rankingName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
     this.recordsCount=this.rankslist.length;
    }
  }
  resetform(){
   
    this.examRankingfrmgroup.reset();
    this.examRankingfrmgroup.get("ClassId").setValue("");
    this.examRankingfrmgroup.get("id").setValue(0);
    this.examRankingfrmgroup.get("GPALavel").setValue("");
    this.examRankingfrmgroup.get("GPALimitType").setValue("");
    this.examRankingfrmgroup.get("NoOfSubjects").setValue(0);
    this.examRankingfrmgroup.get("SubjectLimitType").setValue("");
  }
}
