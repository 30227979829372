import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SharedService } from '../../../../shared/shared.service';
import { ToastrService } from 'ngx-toastr';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { SettingsService } from '../../mastersettings/settings.service';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Student, StudentClass, StudentSibling, StudentDocument } from './../student.model';
import { StudentService} from './../student.service';
import { ClassAndBatch } from '../../feemanagement/feemanagementreports/outstandingduesummary/outstandingduesummary.model';
@Component({
  selector: 'app-classpromotion',
  templateUrl: './classpromotion.component.html',
  styleUrls: ['./classpromotion.component.css']
})
export class ClasspromotionComponent implements OnInit {
 
  sourceAdmissions: Student[] = [];
  sourceAcademicYears: Academicyear[] = [];
  sourceClasscourses: ClassCourse[] = [];
  sourceBatches: Batch[] = [];
  academicYearIdSource = '';
  classCourseIdSource = '';
  batchIdSource = '';


  targetAdmissions: Student[] = [];
  targetAcademicYears: Academicyear[] = [];
  targetClasscourses: ClassCourse[] = [];
  targetBatches: Batch[] = [];
  studentClass: StudentClass[] = [];
  academicYearIdTarget = '';
  classCourseIdTarget = '';
  batchIdTarget = '';
  academicYearName = '';
  sourceAcademicYearName = '';
  targetAcademicYearName = '';
  className = '';
  batchName = '';
  selectedClass = '';
  btnText = 'Promote Class';
  isPassOut = false;
  isLoading:boolean=false;
  constructor(
    private batchService: BatchService,
    private classService: ClassCourseService,
    private settingsSerivce: SettingsService,
    private studentService: StudentService,
    private toastr: ToastrService
  ) { }

  ListAcademicYear() {
    this.settingsSerivce.getAll().subscribe((data: Academicyear[]) => {
      this.targetAcademicYears =  this.sourceAcademicYears = data;
    });
  }

  ListClassCourse() {
    debugger;
    // this.isLoading =!this.isLoading;
    this.classService.getAll().subscribe((data: ClassCourse[]) => {
      this.targetClasscourses = this.sourceClasscourses = data;
      // this.isLoading =!this.isLoading;
    });
  }

  onSourceAcademicYearSelected(value: number) {
    if(this.academicYearIdSource !== '') {
      const academicresult =  this.sourceAcademicYears;
      const academicres = academicresult.filter((x) => x.id.toString() === this.academicYearIdSource);
      this.sourceAcademicYearName = academicres[0].name;
    }
    this.setDefaultClassCourse();
    this.setDefaultBatch();
  }

  setDefaultClassCourse(){
    this.selectedClass = '';
    this.classCourseIdSource = '';    
  }

  setDefaultBatch() {
    this.batchIdSource = '';
    this.sourceBatches = [];
    this.sourceAdmissions = [];
  }

  get sourceName(): string {
    return this.sourceAcademicYearName;
  }

  onSourceClassSelected(value: number) {
    debugger;
    this.isLoading =!this.isLoading;
    this.setDefaultBatch();
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.selectedClass = this.sourceClasscourses.filter((x)=>x.id.toString()==value.toString())[0].classCourseName;     
      this.sourceBatches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());      
      this.isLoading =!this.isLoading;
    });
  }
  onPassedOutSelected(ev){
    if(ev.target.checked){
      this.btnText="Pass Out";
      this.isPassOut = true;
    }else{
      this.btnText="Promote Class";
      this.isPassOut = false;
    }
  }
  onSourceBatchSelected(value: string) {
     debugger;
    // this.isLoading =!this.isLoading;
    this.studentService.getAllByAcademicYear(this.academicYearIdSource).subscribe((data: Student[]) => {
      console.log(data);
      this.sourceAdmissions = data.filter((student: Student) => {
        return student.batchId.toString() === value && student.className ==this.selectedClass && student.status.toString() === 'true';
      });
      console.log(this.sourceAdmissions);
      this.studentService.getAll().subscribe((sdata: Student[]) => {
        this.sourceAdmissions.forEach((x) => {
          sdata.forEach((y) => {
            if (x.academicYearId.toString() !== y.academicYearId.toString() &&
                x.id.toString() === y.id.toString()) {
              x.moved = true;
              x.previousClassName = y.previousClassName;
            }
          });
        });
      });
    });
    // this.isLoading =!this.isLoading;
  }

    onTargetAcademicYearSelected(value: number) {
      const academicresult =  this.targetAcademicYears;
      const academicres = academicresult.filter((x) => x.id.toString() === this.academicYearIdTarget);
      this.targetAcademicYearName = academicres[0].name;
    }

    get targetName(): string {
      return this.targetAcademicYearName;
    }

  onTargetClassSelected(value: string) {
    this.targetAdmissions = [];
    this.targetBatches = [];
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.targetBatches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
    });
  }

    onTargetBatchSelected(value: string) {
      console.log(this.academicYearIdTarget);
      this.studentService.getAll().subscribe((data: Student[]) => {
        this.targetAdmissions = data.filter((student: Student) => {
          return student.batchId === value &&
            student.academicYearId === this.academicYearIdTarget;
        });
      });
    }

  checkAllCheckBox(ev) {
    this.sourceAdmissions.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.sourceAdmissions.every(p => p.checked);
  }

  get totalSourceRows(): number {
    return this.sourceAdmissions.length;
  }

  get totalTargetRows(): number {
    return this.targetAdmissions.length;
  }

  moveSourecetoTarget() {

    if (this.academicYearIdTarget === '' ) {
      this.toastr.warning('Please select target academic year ', 'WARNING!!!', {
        timeOut: 3000
      });
      return;
    }

    if (this.classCourseIdTarget === '' ) {
      this.toastr.warning('Please select target Class ', 'WARNING!!!', {
        timeOut: 3000
      });
      return;
    }

    if (this.batchIdTarget === '' ) {
      this.toastr.warning('Please select target Batch ', 'WARNING!!!', {
        timeOut: 3000
      });
      return;
    }

    if (this.academicYearIdSource === this.academicYearIdTarget ) {
      this.toastr.warning('Please select different target Accademic Year ', 'WARNING!!!', {
        timeOut: 3000
      });
      return;
    }

    const academicresult =  this.targetAcademicYears;
    const academicres = academicresult.filter((x) => x.id.toString() === this.academicYearIdTarget);
    this.academicYearName = academicres[0].name;

    const classresult =  this.targetClasscourses;
    const classres = classresult.filter((x) => x.id.toString() === this.classCourseIdTarget);
    this.className = classres[0].classCourseName;

    const batchresult =  this.targetBatches;
    const batchres = batchresult.filter((x) => x.id.toString() === this.batchIdTarget);
    this.batchName = batchres[0].batchName;
    this.targetAdmissions = [];
    this.studentClass = [];
    this.sourceAdmissions.forEach(x => {
      if (x.checked) {
        // x.moved = true;
        x.previousClassName =  '' ; // this.academicYearName + ' ' + this.className + ' ' + this.batchName;
        const y = x;
        y.classCourseId = this.classCourseIdTarget;
        y.batchId = this.batchIdTarget;
        y.academicYearId = this.academicYearIdTarget;
        this.targetAdmissions.push(y);

        const studentClass: StudentClass = {} as StudentClass;
        studentClass.academicYearId = this.academicYearIdTarget;
        studentClass.admissionId = x.id.toString();
        studentClass.batchId = this.batchIdTarget;
        studentClass.classId = this.classCourseIdTarget;
        studentClass.PreviousClassName = x.academicYearName + ' ' + x.className + ' ' + x.batchName;
        this.studentClass.push(studentClass);
      }
    });
  }

  removeClass(value: number) {
    this.targetAdmissions.splice(value, 1);
    // this.sourceAdmissions[value].moved = false;
  }

  resetTable() {
    this.sourceAdmissions = [];
    this.targetAdmissions = [];
    // this.sourceAcademicYears = [];
    // this.sourceClasscourses = [];
    this.classCourseIdSource = '';
    this.classCourseIdTarget = '';
    this.academicYearIdSource = '';
    this.academicYearIdTarget = '';
    this.sourceBatches = [];
    this.targetBatches = [];
  }

  resetForm() {
    this.targetAdmissions = [];
    this.sourceAdmissions.forEach((x) => x.checked = false);
    this.onSourceBatchSelected(this.batchIdSource);
  }

  promoteClass() {
    // this.targetAdmissions.forEach((x) => {
    //   const studentClass: StudentClass = {} as StudentClass;
    //   studentClass.academicYearId = x.academicYearId;
    //   studentClass.admissionId = x.id.toString();
    //   studentClass.batchId = x.batchId;
    //   studentClass.classId = x.classCourseId;
    //   studentClass.PreviousClassName = '';
    //   this.studentClass.push(studentClass);
    // });
    if (this.studentClass.length === 0 && this.isPassOut==false ) {
      this.toastr.warning('Please add new promoted students ', 'WARNING!!!', {
        timeOut: 3000
      });
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Promote!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if(this.studentClass.length==0 && this.isPassOut){
        this.studentClass = [];
        this.sourceAdmissions.forEach(x => {
          if (x.checked) {
            const studentClass: StudentClass = {} as StudentClass;
            studentClass.academicYearId = x.academicYearId;
            studentClass.admissionId = x.id.toString();
            studentClass.batchId = x.batchId;
            studentClass.classId = x.studentClassId;
            this.studentClass.push(studentClass);
          }
        });
      }
      if (result.value) {
        this.studentService.savestudentclass(this.studentClass,this.isPassOut).subscribe((res) => {
          console.log('promoted!');
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.resetForm();
          this.studentClass = [];
          this.resetTable();
          this.sourceAdmissions = [];
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      
      }
    });


    
  }

  ngOnInit(): void {
    this.ListAcademicYear();
    this.ListClassCourse();
  }

}
