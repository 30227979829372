// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content{
    margin-left:0px;
}

.btn-space{
    margin: 1px;
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/academic/offline-examination/ExamSetting/designation/designation.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".main-content{\r\n    margin-left:0px;\r\n}\r\n\r\n.btn-space{\r\n    margin: 1px;\r\n    padding: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
