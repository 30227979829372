import { Pipe, PipeTransform } from '@angular/core';
import { Status } from '../model/status';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, input: any, keys: any[]): any {
    if (input) {
      return value.filter(val => {
        val.statusName = Status[val.status];
        return keys.filter(key => {
          if (val[key]) {
            return val[key].toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        }).length > 0
      });
    }
    else {
      return value;
    }
  }

}
