import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InstallmentSummaryModel} from './installmentsummary.model';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstallmentSummaryService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }

  getInstallmentSummaryReport(academicYear, classId,stypeId): Observable<any> {
    return this.httpClient.get<any>(environment.apiUrl + '/FeeReport/GetInstallmentSummary?AcademicYearId='+academicYear+'&ClassId='+classId+'&StypeId='+stypeId)
      .pipe(
        catchError(this.errorHandler)
      );

    }
    
errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
