import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdministrationRoutingModule } from './administration-routing.module';
import { CertificateModule } from './certificate/certificate.module';
import { DownloadsModule } from './downloads/downloads.module';
import { BackupRestoreRoutingModule } from './backupandrestore/backupandrestore-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BackupRestoreModule } from './backupandrestore/backupandrestore.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AdministrationRoutingModule,
    CertificateModule,
    DownloadsModule,
    BackupRestoreModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AdministrationModule { }
