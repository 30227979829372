import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable, ObservedValueOf } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Country} from '../models/country';
import { State} from '../models/state';
import { City} from '../models/city';
import { Server} from '../models/server';
import { Institution, InstitutionDetail, InstitutionBranch} from '../models/institution.model';
import { Updateinstitute } from '../models/Updateinstitute.model';
import { Reason } from '../models/Reason';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<Institution[]> {
    return this.httpClient.get<Institution[]>(environment.apiUrl + '/institution/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllByearch(id): Observable<Institution[]> {  
    return this.httpClient.get<Institution[]>(environment.apiUrl + '/institution/GetInstitutionBySearch?id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<InstitutionDetail> {
    return this.httpClient.get<InstitutionDetail>(environment.apiUrl + '/institution/GetInstitutionDetailsById/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getInstitutionById(id): Observable<Institution> {
    return this.httpClient.get<Institution>(environment.apiUrl + '/institution/GetInstitutionDetailsById/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  
  getInstitutionDetailByCode(InstitutionCode: string): Observable<Institution> {
    return this.httpClient.get<Institution>(environment.apiUrl + '/institution/GetInstitutionDetailByCode/' + InstitutionCode)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  generateInstitutionCode() {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(environment.apiUrl + '/institution/GenerateInstitutionCode', {responseType: 'text'})
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllBranches(institutionId): Observable<InstitutionBranch[]> {
    return this.httpClient.get<InstitutionBranch[]>(environment.apiUrl + '/institution/GetAllBranches/' + institutionId)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllInstitutionList(): Observable<Institution[]> {
    return this.httpClient.get<Institution[]>(environment.apiUrl + '/institution/GetAllInstitutionList')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllInstitutionSenderIdRequest(): Observable<Institution[]> {
    return this.httpClient.get<Institution[]>(environment.apiUrl + '/institution/GetAllInstitutionSenderIdRequest')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getServerDetail(): Observable<Server> {
    return this.httpClient.get<Server>(environment.apiUrl + '/institution/GetServerDetails')
    .pipe(
      catchError(this.errorHandler)
    );
  }


  create(institution): Observable<Institution> {
  return this.httpClient.post<Institution>(environment.apiUrl + '/institution/', JSON.stringify(institution), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, institution): Observable<Institution> {
    return this.httpClient.put<Institution>(environment.apiUrl + '/institution/' + id, JSON.stringify(institution), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
 updateinstitution(institution:any):Observable<Updateinstitute>{
  console.log(institution)
   return this.httpClient.post<any>(environment.apiUrl+'/institution/ChangeInstitutionStatus',JSON.stringify(institution),this.httpOptions)
   .pipe(
     catchError(this.errorHandler)
   )
 }
  delete(id) {
    return this.httpClient.delete<Institution>(environment.apiUrl + '/institution/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  /*Master Services*/
  getAllCountry(): Observable<Country[]> {
    return this.httpClient.get<Country[]>(environment.apiUrl + '/countries/')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllState(): Observable<State[]> {
    return this.httpClient.get<State[]>(environment.apiUrl + '/state/')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllCity(): Observable<City[]> {
    return this.httpClient.get<City[]>(environment.apiUrl + '/city/')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


  /**
   * Writing Service for getting reason for active and deactive mode
   */
   getReasonForInstitutionById(id:number) : Observable<Reason> {
    return this.httpClient.get<Reason>(environment.apiUrl + '/institution/GetActiveDeactiveReasonMessage/'+id)
    .pipe(catchError(this.errorHandler));
  }


}
