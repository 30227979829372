import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs"; 
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute,Router } from '@angular/router';
import { StudentWiseReportCardService } from "../services/student-wise-report-card.service";
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-exam-report-card',
  templateUrl: './exam-report-card.component.html',
  styleUrls: ['./exam-report-card.component.css']
})
export class ExamReportCardComponent implements OnInit {

  submitted = false; 
  institution: any;
  institutionName = '';
  address = '';
  email = '';
  website = '';
  logoUrl = '';  
  websiteURL='';
  contactNumber='';
  emailId='';
  attendance:any=[];
  isLoading: boolean = false;  
  saveDataModel = [];
  selectedRow:number;
  DetailsResult: any = [];
  disabled = true;
  academicYearId = '';
  classId='';
  batchid='';
  studentid='';
  environmentPath='';


  constructor(private route: ActivatedRoute, private router: Router,private toastr: ToastrService,
    public service: StudentWiseReportCardService) { }

    ngOnInit(): void {    
      this.route.params.subscribe(params => {
        this.academicYearId =params["academicYearId"];   
        this.classId =params["classId"];
        this.batchid =params["batchid"];
        this.studentid =params["studentid"];
       });

      this.institution = JSON.parse(localStorage.getItem('Institution'));
      if(this.institution.logoUrl !== null ) {
        this.institution.logoUrl = environment.imageUrl + this.institution.logoUrl;
      } else if (this.institution.logoUrl === null) {
        this.institution.logoUrl = 'assets/images/SchoolLogo/demologo.png';
      }
      this.logoUrl = this.institution.logoUrl;
      this.institutionName = this.institution.institutionName;
      this.address = this.institution.address;
      this.websiteURL=this.institution.websiteURL;
      this.contactNumber=this.institution.contactNumber;
      this.emailId=this.institution.emailId;
      this.getallstudentMarks();
    }

    submitForm() {}
  
    
    disableFreezeButton: boolean = true;
    getallstudentMarks(){
      this.isLoading = true;
      this.attendance=null;
        if (this.disableFreezeButton) {        
        this.service.GetStudentReportCardDetails("-1", this.academicYearId, 
          this.classId, this.batchid, this.studentid).subscribe((data)=>{
          this.DetailsResult=data;
          this.environmentPath= environment.imageUrl;
          this.DetailsResult[0].studentDetails.forEach((x) => {
             x.studentImage = x.studentImage != null ? environment.imageUrl + x.studentImage : null;
          });
          this.attendance=this.DetailsResult[0].attendanceReportCards[this.DetailsResult[0].attendanceReportCards.length-1];
          this.isLoading = false;
        });

      }
      else{
        this.toastr.warning("All field are required", "WARNING!!!", {
          timeOut: 3000,
        });
        this.isLoading = false;
        return;
      }
    }    
    
    
    resetForm() {
      this.submitted = false
      this.saveDataModel = [];
      this.disabled = true;      
    }
}
