import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConsolidateExamReportComponent } from './consolidate-exam-report.component';
const routes: Routes = [
  {
    path:'ConsolidateExamReport', component: ConsolidateExamReportComponent
  }
];


@NgModule({
  declarations: [ConsolidateExamReportComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ConsolidateExamReportModule { }
