// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursor{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/academic/offline-examination/assign-exam-marks/assign-exam-marks/assign-exam-marks.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB","sourcesContent":[".cursor{\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
