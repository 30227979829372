import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ERPDashboardSetting, ERPGlobalSettings } from './erpsettings.model';

@Injectable({
  providedIn: 'root'
})
export class ERPSettingService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }

  getAll(): Observable<ERPGlobalSettings[]> {
    return this.httpClient.get<ERPGlobalSettings[]>(environment.apiUrl + '/ERPSetting/')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  verify(passcode): Observable<boolean> {
    return this.httpClient.get<boolean>(environment.apiUrl + '/Authorize/Verify/' + passcode)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  create(visitorenquiry): Observable<ERPGlobalSettings> {
    return this.httpClient.post<ERPGlobalSettings>(environment.apiUrl + '/ERPSetting/',
     JSON.stringify(visitorenquiry), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, visitorenquiry): Observable<ERPGlobalSettings> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<ERPGlobalSettings>(environment.apiUrl + '/ERPSetting/' + id,
     JSON.stringify(visitorenquiry), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllERPDashboardSettings(): Observable<ERPDashboardSetting[]> {
    return this.httpClient.get<ERPDashboardSetting[]>(environment.apiUrl + '/ERPDashboardSetting')
      .pipe(
        catchError(this.errorHandler)
      );
  }
  
  updateERPDashboardSetting(id, visitorenquiry): Observable<ERPDashboardSetting> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<ERPDashboardSetting>(environment.apiUrl + '/ERPDashboardSetting/' + id,
     JSON.stringify(visitorenquiry), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }


}
