import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DemoManagementModel,LeadDetailsModel} from './demomanagement.model';

@Injectable({
  providedIn: 'root'
})
export class Demomanagementservice {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<DemoManagementModel[]> {
 return this.httpClient.get<DemoManagementModel[]>(environment.apiUrl + '/Demo/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<LeadDetailsModel> {
   return this.httpClient.get<LeadDetailsModel>(environment.apiUrl + '/Demo/GetDemoManagentList/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  
  update(id, demoManagement): Observable<DemoManagementModel> {
   return this.httpClient.put<DemoManagementModel>(environment.apiUrl + '/Demo/UpdateDemo' + id,
    JSON.stringify(demoManagement), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getUpdatedDemoList(leadId: number): Observable<DemoManagementModel[]> {
   return this.httpClient.get<DemoManagementModel[]>(environment.apiUrl + '/Demo/GetDemoList/'+ leadId)
       .pipe(
         catchError(this.errorHandler)
       );
     }
     create(demoUpdation): Observable<DemoManagementModel> {
     return this.httpClient.post<DemoManagementModel>(environment.apiUrl + '/Demo/', JSON.stringify(demoUpdation), this.httpOptions)
        .pipe(
          catchError(this.errorHandler)
        );
      }

    errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
