import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { SessionPlannerVM } from './session-planner/model/SessionPlannerVM';
import { SessionPlannerContentVm } from './session-planner/model/SessionPlannerContentVm';
import { AssesmentVm, QuizVm } from './session-planner/model/AssesmentVm';
import { SessionPlannerDiscussionVm } from './session-planner/model/SessionPlannerDiscussionVm';
import { StreamingAPISetting } from './lms-api-setting/model/StreamingAPISetting';
import { SessionPlannerStudent } from './session-planner/model/SessionPlannerStudent';
import { StudentSessionList, StudentVm } from './session-planner/model/StudentVm';
import { SessionApproval } from './session-planner/model/SessionApproval.model';
import { SessionStudentAttendanceReport } from './online-class-report/model/SessionStudentAttendanceReport';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SessionPlannerService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  
  constructor(private httpClient: HttpClient) { }


  GetClass(): Observable<any> {
    const url = '/Class/GetAllClass';
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  
  getAllBatchByClass(param): Observable<any> {
    
    const url = '/Batch/GetAllBatchByClassID?ID='+param;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  
  GetLanguage(): Observable<any> {
    const url = '/Language/GetAllLanguage';
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  GetAllEmployee(): Observable<any> {
    const url = '/SessionPlanner/GetEmployees';
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  
  GetAllExams(): Observable<any> {
    const url = '/SessionPlannerContent/GetAllExam';
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  
  GetAllExamCategory(): Observable<any> {
    const url = '/SessionPlannerContent/GetAllExamCategory';
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }




  

  GetSubject(): Observable<any> {
    const url = 'Subject/GetAllSubjects';
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  GetAllSubjectByBatchID(param): Observable<any> {
    
    const url = 'Subject/GetAllSubjectsByBatch?ID='+param;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllSubjectByClass(param): Observable<any> {
    
    const url = '/Subject/getAllSubjectByClass?ID='+param;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }



  getAllCoursePlanByClassBatch(param,batch): Observable<any> {
    
    const url = '/CoursePlanner/GetByClassBatch?ClassId='+param+ '&BatchId='+batch;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  getAllChapterPlannerByCoursePlannerID(id): Observable<any> {
    
    const url = '/ChapterTopicPlanner/GetTopicPlannerListByCoursePlannerID?Id='+id;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllSessionPlannerByTopicPlannerID(id): Observable<any> {
    
    const url = '/SessionPlanner/GetSessionPlannerByTopicPlannerID?Id='+id;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  
  

  GetAllSubject(): Observable<any> {
    
    const url = '/Subject/GetAllSubject';
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  GetAllStudent(): Observable<any> {
    
    const url = '/Student/GetAllStudents';
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

GetAllStudentsByClassBatchYear(classId,batchId): Observable<any> {
    
    const url = '/Student/GetStudentsByCurrentYearClassBatch?ClassId='+classId+'&BatchId='+batchId;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  GetAllStudentsByClassBatch(classId,batchId): Observable<any> {
    
    const url = '/Student/GetAllStudentsByClassBatch?ClassId='+classId+'&BatchId='+batchId;
    return this.httpClient.get<any[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  GetAllStudentsInfoByStudentId(classId): Observable<any> {
    
    
    const url = '/Student/GetStudentsInfoByIds?StudentId='+classId;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  GetAllStudentsAttendanceReport(sessionId): Observable<any> {
    
    
    const url = '/SessionStudentAttendance/GetAllSessionStudentAttendancesBySessionId?sessionId='+sessionId;
    return this.httpClient.get<[SessionStudentAttendanceReport]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  // SaveCoursePlan(param): Observable<CoursePlannerVM> {
    

  //   const url = '/CoursePlanner/Create';

  //   return this.httpClient.post<CoursePlannerVM>(environment.apiUrl + url, JSON.stringify(param), this.httpOptions)
  //   .pipe(
  //     catchError(this.errorHandler)
  //   );
  // }


  SaveChapterTopic(param): Observable<SessionPlannerVM> {
    

    const url = '/SessionPlanner/Create';

    return this.httpClient.post<SessionPlannerVM>(environment.apiUrl + url, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  SaveStreamingAPISetting(param): Observable<StreamingAPISetting> {
    

    const url = '/StreamingAPISetting/Create';

    return this.httpClient.post<StreamingAPISetting>(environment.apiUrl + url, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  

  getAllStreamingAPISetting(): Observable<any> {
    

    const url = '/StreamingAPISetting/GetAll';

    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  

  getAllAcademicStreamingAPISetting(): Observable<any> {
    

    const url = '/StreamingAPISetting/GetAllAcademicAPI';

    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  
  


  SaveAcademicStreamingAPISetting(param): Observable<StreamingAPISetting> {
    

    const url = '/StreamingAPISetting/CreateAcademicAPI';

    return this.httpClient.post<StreamingAPISetting>(environment.apiUrl + url, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  

  UpdateAcademicStreamingAPISetting(param): Observable<StreamingAPISetting> {
    

    const url = '/StreamingAPISetting/UpdateAcademicAPI';
    return this.httpClient.put<StreamingAPISetting>(environment.apiUrl + url+"?Id="+param.id, JSON.stringify(param), this.httpOptions)

    // return this.httpClient.post<StreamingAPISetting>(environment.apiUrl + url, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  


  GetAllAcademicStreamingAPISetting(): Observable<any> {
    

    const url = '/StreamingAPISetting/GetAllAcademicAPI';

    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  GetAcademicStreamingAPISettingById(Id): Observable<any> {
    const url = '/StreamingAPISetting/GetAcademicAPIById';

    return this.httpClient.get<[]>(environment.apiUrl + url+"?Id="+Id)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  GetStreamingAPILink(Id): Observable<any> {
    

    const url = '/StreamingAPISetting/GetStramingProviderLink';

    return this.httpClient.get<[]>(environment.apiUrl + url+"?Id="+Id)
    .pipe(
      catchError(this.errorHandler)
    );
  }



  SaveSessionPlannerContent(param): Observable<SessionPlannerContentVm> {
    

    const url = '/SessionPlannerContent/Create';

    return this.httpClient.post<SessionPlannerContentVm>(environment.apiUrl + url, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  saveSessionPlannerStudent(param):Observable<StudentVm>{
    
    const url = '/SessionPlannerContent/StudentCreate';

    return this.httpClient.post<StudentVm>(environment.apiUrl + url, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
    
  SaveSessionPlannerAssement(param): Observable<AssesmentVm> {
    

    const url = '/SessionPlannerContent/AssesmentCreate';

    return this.httpClient.post<AssesmentVm>(environment.apiUrl + url, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  SaveSessionPlannerQuiz(param): Observable<QuizVm> {
    

    const url = '/SessionPlannerContent/QuizCreate';

    return this.httpClient.post<QuizVm>(environment.apiUrl + url, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  SaveSessionPlannerDiscussion(param): Observable<SessionPlannerDiscussionVm> {
    

    const url = '/SessionPlannerContent/DiscussionCreate';

    return this.httpClient.post<SessionPlannerDiscussionVm>(environment.apiUrl + url, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  SaveSessionPlannerStudent(param,id): Observable<StudentSessionList[]> {
    

    const url = '/SessionPlannerContent/StudentCreate';

    return this.httpClient.post<StudentSessionList[]>(environment.apiUrl + url+"?Id="+id, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  SaveSessionPlannerApproval(param): Observable<SessionApproval> {
    

    const url = '/SessionPlannerContent/ApprovalCreate';

    return this.httpClient.post<SessionApproval>(environment.apiUrl + url, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  
  UpdateCoursePlan(param): Observable<SessionPlannerDiscussionVm> {
    
    const url = '/SessionPlanner/Update';

    
    return this.httpClient.put<SessionPlannerDiscussionVm>(environment.apiUrl + url+"?Id="+param.id, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
    
  }

  UpdateContentPlan(param): Observable<SessionPlannerContentVm> {
    
    const url = '/SessionPlannerContent/Update';

    
    return this.httpClient.put<SessionPlannerContentVm>(environment.apiUrl + url+"?Id="+param.id, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  UpdateSessionPlannerQuiz(param): Observable<QuizVm> {
    
    const url = '/SessionPlannerContent/QuizUpdate';

    
    return this.httpClient.put<QuizVm>(environment.apiUrl + url+"?Id="+param.id, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  UpdateCoursePlannerAssesment(param): Observable<AssesmentVm> {
    
    const url = '/Assesment/UpdateAssesment';

    
    return this.httpClient.put<AssesmentVm>(environment.apiUrl + url+"?Id="+param.id, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  updateSessionPlannerStudent(param): Observable<StudentVm> {
    
    const url = '/SessionPlannerContent/StudentUpdate';

    
    return this.httpClient.put<StudentVm>(environment.apiUrl + url+"?Id="+param.id, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  UpdateCoursePlannerDiscussion(param): Observable<SessionPlannerDiscussionVm> {
    
    const url = '/SessionPlannerContent/DiscussionUpdate';

    
    return this.httpClient.put<SessionPlannerDiscussionVm>(environment.apiUrl + url+"?Id="+param.id, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  UpdateSessionPlanerApproval(param): Observable<SessionApproval> {
    
    const url = '/SessionPlannerContent/ApprovalUpdate';

    
    return this.httpClient.put<SessionApproval>(environment.apiUrl + url+"?Id="+param.id, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
    
  }


  // UpdateTopicPlanner(param): Observable<ChapterTopicPlanner> {
  //   
  //   const url = '/ChapterTopicPlanner/Update';

  //   
  //   return this.httpClient.put<ChapterTopicPlanner>(environment.apiUrl + url+"?Id="+param.Id, JSON.stringify(param), this.httpOptions)
  //   .pipe(
  //     catchError(this.errorHandler)
  //   );
  // }
  

  SessionPlannerUpdateSchedule(param,date,time) {
    
    const url = '/SessionPlanner/SessionPlannerUpdateSchedule?Id='+param+'&date='+date+'&time='+time;
    return this.httpClient.get<SessionPlannerVM>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    ).toPromise();
  }


  getAllCoursePlanByClass(param): Observable<any> {
    
    const url = '/CoursePlanner/GetByClassID?ClassId='+param;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  Subject(param,batch,subject): Observable<any> {
    
    const url = '/CoursePlanner/GetByClassBatchSubject?ClassId='+param+'&BatchId='+batch+'&SubjectId='+subject;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  
  getAllCoursePlanByClassSubject(param,subject): Observable<any> {
    
    const url = '/CoursePlanner/GetByClassSubject?ClassId='+param+'&SubjectId='+subject;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  getAllTopicChapterPlanByClass(param): Observable<any> {
    
    const url = '/ChapterTopicPlanner/GetByClass?ClassId='+param;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllTopicChapterPlanByClassSubject(param,subject): Observable<any> {
    
    const url = '/ChapterTopicPlanner/GetByClassSubject?ClassId='+param+'&SubjectId='+subject;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  getAllTopicChapterPlanByClassSubjectCourse(param,subject,course): Observable<any> {
    
    const url = '/ChapterTopicPlanner/GetByClassSubjectCourse?ClassId='+param+'&SubjectId='+subject+'&CourseId='+course;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  // deleteTopicPlan(id) {
  //   
  //   return this.httpClient.delete<CoursePlannerVM>(environment.apiUrl + 'ChapterTopicPlanner/DeleteTopicPlan?Id=' + id)
  //   .pipe(
  //     catchError(this.errorHandler)
  //   );
  // }
  


  // update(id, academicyear): Observable<CoursePlannerVM> {
  //   // tslint:disable-next-line:max-line-length
  //   return this.httpClient.put<CoursePlannerVM>(environment.apiUrl + 'CoursePlanner/' + id, JSON.stringify(academicyear), this.httpOptions)
  //   .pipe(
  //     catchError(this.errorHandler)
  //   );
  // }

  delete(id) {
    
    return this.httpClient.delete<SessionPlannerVM>(environment.apiUrl + '/SessionPlanner/DeleteSessionPlanner?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  
  getById(id) {
    
    return this.httpClient.get<SessionPlannerVM>(environment.apiUrl + '/SessionPlanner/GetBySessionPlannerId?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  getSessionPlannerDiscussionById(id) {
    
    return this.httpClient.get<SessionPlannerDiscussionVm>(environment.apiUrl + '/SessionPlannerContent/GetDiscussionBySessionId?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getSessionPlannerStudentById(id) {
    
    return this.httpClient.get<StudentSessionList[]>(environment.apiUrl + '/SessionPlannerContent/GetStudentBySessionId?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  getSessionPlannerContentById(id) {
    
    return this.httpClient.get<SessionPlannerContentVm>(environment.apiUrl + '/SessionPlannerContent/GetSessionPlannerContentById?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getSessionPlannerApprovalById(id) {
    
    return this.httpClient.get<SessionApproval>(environment.apiUrl + '/SessionPlannerContent/GetApprovalBySessionId?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  UpdateSessionSummaryApproval(id,status) {
    
    return this.httpClient.get<SessionPlannerVM>(environment.apiUrl + '/SessionPlanner/UpdateSessionPlannerApproval?Id=' + id+'&status='+status)
    .pipe(
      catchError(this.errorHandler)
    ).toPromise();
  }
  UpdateSessionSummaryStatus(id,status) {
    
    return this.httpClient.get<SessionPlannerVM>(environment.apiUrl + '/SessionPlanner/UpdateSessionPlannerStatus?Id=' + id+'&status='+status)
    .pipe(
      catchError(this.errorHandler)
    ).toPromise();
  }

  CopySessionSummary(id,batch,classID,sessionDate,time) {
    let queryParamms=new HttpParams();
    queryParamms=queryParamms.append('Id',id);
    queryParamms=queryParamms.append('BatchId',batch);
    queryParamms=queryParamms.append('ClassId',classID);
    queryParamms=queryParamms.append('SessionDate',sessionDate);
    queryParamms=queryParamms.append('Time',time);

    //const url = '/SessionPlanner/CopySessionPlanner?Id='+id+'&BatchId='+batch+'&SessionDate='+sessionDate+'&Time='+time;
    const url = '/SessionPlanner/CopySessionPlanner';

    return this.httpClient.get<SessionPlannerVM>(environment.apiUrl +url,
      {
        params:queryParamms
      }
      )
    .pipe(
      catchError(this.errorHandler)
    ).toPromise();
  }
 CancelSessionSummary(id,remarque) {
    
    const url = '/SessionPlanner/CancelSessionPlanner?Id='+id+'&Remarque='+remarque;

    return this.httpClient.get<SessionPlannerVM>(environment.apiUrl +url)
    .pipe(
      catchError(this.errorHandler)
    ).toPromise();
  }



  getSessionPlannerAssesmentById(id) {
    
    return this.httpClient.get<AssesmentVm>(environment.apiUrl + '/Assesment/GetAllAssesmentByCoursePlannerId?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  
// ---------------------------------------------- here which I add Ammar ------
  GetQuizBySessionId(id) {
    
    return this.httpClient.get<QuizVm>(environment.apiUrl + '/SessionPlannerContent/GetQuizBySessionId?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  GetStudentBySessionId(id) {
    
    return this.httpClient.get<[]>(environment.apiUrl + '/SessionPlannerContent/GetStudentBySessionId?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  GetApprovalBySessionId(id) {
    
    return this.httpClient.get<[]>(environment.apiUrl + '/SessionPlannerContent/GetApprovalBySessionId?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  GetDiscussionBySessionId(id) {
    
    return this.httpClient.get<[]>(environment.apiUrl + '/SessionPlannerContent/GetDiscussionBySessionId?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  
  GetSessionPlannerContentById(id) {
    
    return this.httpClient.get<[]>(environment.apiUrl + '/SessionPlannerContent/GetSessionPlannerContentById?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  UploadSessionTypeFile(image,id) {
    
   
    return this.httpClient.post(environment.apiUrl + '/SessionPlannerContent/Upload?id='+id,image, {reportProgress: true, observe: 'events'})
    .pipe(
      catchError(this.errorHandler)
    );
  }


  //-------------------------------------------------------------------------------------------



  getAllAssesmenttypeByClassId(id) {
    
    return this.httpClient.get<[]>(environment.apiUrl + '/SessionPlannerContent/GetAllAssignmentTypeByClassId?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  
  // getTopicPlanById(id) {
  //   
  //   return this.httpClient.get<ChapterTopicPlanner>(environment.apiUrl + 'ChapterTopicPlanner/GetTopicPlanByID?Id=' + id)
  //   .pipe(
  //     catchError(this.errorHandler)
  //   );
  // }

  UploadPhoto(id) {
    
    return this.httpClient.post(environment.apiUrl + 'CoursePlanner/Upload',id, {reportProgress: true, observe: 'events'})
    .pipe(
      catchError(this.errorHandler)
    );
  }
  
  
  

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    
    console.log(errorMessage);
    return throwError(errorMessage);
 }



 
}

 