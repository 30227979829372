import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './authentication/login/login.component';
import{ FindschoolComponent } from './authentication/findschool/findschool.component';
import { IloginComponent } from './authentication/ilogin/ilogin.component';
import { AuthGuard } from './authentication/guards/auth.guard';
import {ForgetpasswordComponent} from './authentication/forgetpassword/forgetpassword.component';


const routes: Routes = [
  {
    path: 'findschool',  component: FindschoolComponent
  },
  {
    path: 'forgetpassword',  component: ForgetpasswordComponent
  },
  {path: ':edumaq',  component: LoginComponent},
  {
    path: ':edumaq/login',
    component: LoginComponent,
    canActivate: [AuthGuard],    
  },
  {path: '', component: FindschoolComponent}
  // {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
