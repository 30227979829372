import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AcademicSummary, AttendanceSummary, PendingToDoSummary, SessionWiseStudentReport,
   DashboardClassWiseStudentsSummary, CategoryNameReligions, DashboardDuesPaidDto, 
   FinanceDashboardMonthWiseFeeCollectionReportDto, financeDashboardIncomeExpenseCollectionReport, DepartmentWiseSalaryDto, EmployeeJoiningAndExitSummaryReport, SessionWiseSalary, GetEmployeeLeaveAttendanceReport } from './attendanceSummary.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  httpOptionsC = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'enc-type': 'multipart/form-data'
    })
  };
  constructor(private httpClient: HttpClient) { }

  getEmployeeCount(): Observable<number> {
    return this.httpClient.get<number>(environment.apiUrl + '/Employee/EmployeeCount')
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getEmployeeAttendanceSummaryCount(): Observable<AttendanceSummary> {
    return this.httpClient.get<AttendanceSummary>(environment.apiUrl + '/DashBoard/GetEmployeeAttendanceSummary')
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getStudentAttendanceSummaryCount(): Observable<AttendanceSummary> {
    return this.httpClient.get<AttendanceSummary>(environment.apiUrl + '/DashBoard/GetStudentAttendanceSummary')
      .pipe(
        catchError(this.errorHandler)
      );
  }
  
  getPendingToDoListSummary(): Observable<PendingToDoSummary> {
    const url = '/DashBoard/GetPendingToDoListSummary';
    return this.httpClient.get<PendingToDoSummary>(environment.apiUrl +url)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getAcademicSummary(academicId:number): Observable<AcademicSummary> {
    const url = '/DashBoard/GetAcademicsSummary';
    return this.httpClient.get<AcademicSummary>(environment.apiUrl +url+ "?academicYearId="+academicId)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getDashBoardSessionWiseStudentReport(academicId:number): Observable<SessionWiseStudentReport[]> {
    const url = '/DashBoard/GetDashBoardSessionWiseStudentReport';
    return this.httpClient.get<SessionWiseStudentReport[]>(environment.apiUrl +url+ "?academicYearId="+academicId)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  gettotalduespaid(academicId:number): Observable<DashboardDuesPaidDto> {
    const url = '/DashBoard/GetTotalDashBoardDuesPaid';
    return this.httpClient.get<DashboardDuesPaidDto>(environment.apiUrl +url+ "?academicYearId="+academicId)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getDashboardClassWiseStudentsSummary(academicId:number): Observable<DashboardClassWiseStudentsSummary[]> {
    const url = '/DashBoard/GetDashBoardClassWiseStudentSummary';
    return this.httpClient.get<DashboardClassWiseStudentsSummary[]>(environment.apiUrl +url+ "?academicYearId="+academicId)
      .pipe(
        catchError(this.errorHandler)
      );
  }


  financeDashboardIncomeExpenseCollectionReport(academicId:number, reporttypes:number): Observable<financeDashboardIncomeExpenseCollectionReport[]> {
    const url = '/DashBoard/FinanceDashboardIncomeExpenseCollectionReport';
    return this.httpClient.get<financeDashboardIncomeExpenseCollectionReport[]>(environment.apiUrl +url+ "?academicYearId="+academicId+"&reporttypes="+reporttypes)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getAcademicCategoryNameReligions(academicId:number): Observable<CategoryNameReligions[]> {
    const url = '/DashBoard/GetTotalStudentDashBoardCategoryNameReligions';
    return this.httpClient.get<CategoryNameReligions[]>(environment.apiUrl +url+ "?academicYearId="+academicId)
      .pipe(
        catchError(this.errorHandler)
      );
  }




  getfinanceDashboardMonthWiseFeeCollectionReport(academicId:number): Observable<FinanceDashboardMonthWiseFeeCollectionReportDto[]> {
    const url = '/DashBoard/FinanceDashboardMonthWiseFeeCollectionReport';
    return this.httpClient.get<FinanceDashboardMonthWiseFeeCollectionReportDto[]>(environment.apiUrl +url+ "?academicYearId="+academicId)
      .pipe(
        catchError(this.errorHandler)
      );
  }


  departmentWiseSalary(academicId:number): Observable<DepartmentWiseSalaryDto> {
    const url = '/departmentWiseSalary';
    return this.httpClient.get<DepartmentWiseSalaryDto>(environment.apiUrl +url+ "/"+academicId)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getEmployeeLeaveAttendanceReport(academicId:number): Observable<GetEmployeeLeaveAttendanceReport> {
    const url = '/employeelist/GetEmployeeLeaveAndAttendanceReport';
    return this.httpClient.get<GetEmployeeLeaveAttendanceReport>(environment.apiUrl + url+ "/"+academicId)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  employeeJoiningExit(academicId:number): Observable<EmployeeJoiningAndExitSummaryReport> {
    const url = '/employeeJoiningExit';
    return this.httpClient.get<EmployeeJoiningAndExitSummaryReport>(environment.apiUrl +url+ "/"+academicId)
      .pipe(
        catchError(this.errorHandler)
      );
  }
   
  getSessionWiseSalaryReport(): Observable<SessionWiseSalary []> {
    const url = '/GetSessionWiseSalaryReport';
    return this.httpClient.get<SessionWiseSalary []>(environment.apiUrl + url)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

 
}
