import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {AdmissionProcedureService} from './admissionprocedure.service';
import {ClassCourseService} from '../mastersettings/classcourse/classcourse.service';
import {SettingsService} from '../mastersettings/settings.service';
import { AdmissionProcedure } from './admissionprocedure.model';
import { ClassCourse } from '../mastersettings/models/classcourse';
import { Academicyear } from '../mastersettings/models/academicyear';
declare var $: any;
@Component({
  selector: 'app-admissionprocedure',
  templateUrl: './admissionprocedure.component.html',
  styleUrls: ['./admissionprocedure.component.css']
})
export class AdmissionprocedureComponent implements OnInit {
  admissionProcedureForm: UntypedFormGroup;
  admissionProcedures: AdmissionProcedure[] = [];
  admissionProcedureFilteredList: AdmissionProcedure[] = [];
  classcourses: ClassCourse[] = [];
  academicyears: Academicyear[] = [];
  academicyearId = 0;
  submitted = false;
  startDateUpdated = false;
  endDateUpdated = false;
  
  isLoading:any=false;

  constructor(
    public admissionProcedureService: AdmissionProcedureService,
    public classcourseService: ClassCourseService,
    public settingsService: SettingsService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  ListAdmissionProcedure() {
    this.isLoading=true;
    this.admissionProcedureService.getAll().subscribe((data: AdmissionProcedure[]) => {
      console.log(data);
      this.admissionProcedureFilteredList = this.admissionProcedures = data;
      this.isLoading=false;
    });
}

ListClass() {
  this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
    console.log(data);
    this.classcourses = data;
  });
}

ListAcademicYear() {
  this.settingsService.getAll().subscribe((data: Academicyear[]) => {
    console.log(data);
    this.academicyears = data;
    this.currentAcademicYear();
  });
}

currentAcademicYear() {
  this.settingsService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
    console.log(data.id);
    this.academicyearId = data.id;
    this.admissionProcedureForm.get('academicYearId').patchValue(data.id);
  });
}


onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

filter(keyword: any) {
  if ( keyword === '') {
    this.admissionProcedureFilteredList = this.admissionProcedures;
  } else {
  this.admissionProcedureFilteredList = this.admissionProcedures
                              .filter((admissionProcedure: AdmissionProcedure) => { return admissionProcedure.className.toLowerCase()
                              .includes(keyword.toLowerCase()) || admissionProcedure.regFee.toString()
                              .includes(keyword.toString()) || admissionProcedure.ageLimitMax.toString()
                              .includes(keyword.toString()) || admissionProcedure.regLimit.toString()
                              .includes(keyword.toString()) || admissionProcedure.admissionLimit.toString()
                              .includes(keyword.toString());
                              });
  }
}

keyPress(event: any) {
  const pattern = /[0-9\+\-\ ]/;
  const inputChar = String.fromCharCode(event.charCode);
  if (!pattern.test(inputChar) && event.charCode !== '0') {
          event.preventDefault();
  }
}

validateDate(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {

    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
    }

    // set error on matchingControl if validation fails
    if (Date.parse(control.value) > Date.parse(matchingControl.value)) {
        matchingControl.setErrors({ mustMatch: true });
    } else {
        matchingControl.setErrors(null);
    }
  };
  }

validateAge(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {

    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
    }

    // set error on matchingControl if validation fails
    // tslint:disable-next-line:radix
    if (parseInt(matchingControl.value) < parseInt(control.value)) {
        matchingControl.setErrors({ mustMatch: true });
    } else {
        matchingControl.setErrors(null);
    }
  };
  }
  onAcademicYearChange(value: any) {
    if (value === 0) {
      // this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
        this.admissionProcedureFilteredList = this.admissionProcedures; // = data;
      // });
    } else {
    // this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
      this.admissionProcedureFilteredList = this.admissionProcedures.filter((admissionProcedure: AdmissionProcedure) =>
      admissionProcedure.academicYearId === value);
    // });
  }
  }
private formatDate(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();
  if (month.length < 2) {month = '0' + month; }
  if (day.length < 2) {day = '0' + day; }
  return [day, month, year].join('-');
}
get totalRows(): number {
  return this.admissionProcedureFilteredList.length;
}
get f() { return this.admissionProcedureForm.controls; }
resetForm() {
  this.submitted = false;
  this.isLoading=true;
  this.admissionProcedureForm = this.fb.group({
    id: [0],
    academicYearId: ['', Validators.required],
    classCourseId: ['', Validators.required],
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    ageLimitMin: ['', Validators.required],
    ageLimitMax: ['', Validators.required],
    regFee: ['', Validators.required],
    regLimit: ['', Validators.required],
    admissionLimit: ['', Validators.required],
    status: ['true', Validators.required]
  }, {
    validators: [this.validateDate('startDate', 'endDate'),
     this.validateAge('ageLimitMin', 'ageLimitMax'),
     this.validateAge('admissionLimit', 'regLimit')]
  });
  this.isLoading=false;;
}
submitForm() {
  this.submitted = true;
  this.isLoading=true;
      // stop here if form is invalid
  if (this.admissionProcedureForm.invalid) {
       if (this.f.endDate.errors != null && this.f.endDate.errors.mustMatch) {
        this.toastr.warning('End date should be greater than start date', 'WARNING!', {
          timeOut: 3000
        });
       }
       if (this.f.ageLimitMax.errors != null && this.f.ageLimitMax.errors.mustMatch) {
        this.toastr.warning('Max age should not be less then Min Age', 'WARNING!', {
          timeOut: 3000
        });
       }
       if (this.f.regLimit.errors != null && this.f.regLimit.errors.mustMatch) {
        this.toastr.warning('Admission Limit should not be greater then Registration Limit', 'WARNING!', {
          timeOut: 3000
        });
       }
       return;
    }

  // this.admissionProcedureForm.get('startDate').patchValue(e.date);
  const stDate = new Date(this.admissionProcedureForm.get('startDate').value);
  const endDate = new Date(this.admissionProcedureForm.get('endDate').value);
  console.log(stDate);
  console.log(endDate);
  if (this.startDateUpdated === true) {
    this.admissionProcedureForm.get('startDate').patchValue(this.formatDate(stDate));
  }

  if (this.endDateUpdated === true) {
    this.admissionProcedureForm.get('endDate').patchValue(this.formatDate(endDate));
  }

  if (this.admissionProcedureForm.get('id').value === 0) {
      this.admissionProcedureService.create(this.admissionProcedureForm.value).subscribe(res => {
        console.log('Academic Procedure created!');
        this.ListAdmissionProcedure();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        this.startDateUpdated = false;
        this.endDateUpdated = false;
      });
      } else {
        // this.admissionProcedureForm.get('endDate').patchValue(this.formatDate(endDate));
        // this.admissionProcedureForm.get('startDate').patchValue(this.formatDate(stDate));
        this.admissionProcedureService.update(this.admissionProcedureForm.get('id').value,
         this.admissionProcedureForm.value).subscribe(res => {
          console.log('Academic Procedure updated!');
          this.ListAdmissionProcedure();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
          this.ListAdmissionProcedure();
          this.startDateUpdated = false;
          this.endDateUpdated = false;
        });
      }


    }

    updateForm(id: number) {
      
      this.admissionProcedureService.getById(id).subscribe((data: AdmissionProcedure) => {
        console.log(data);
        this.admissionProcedureForm = this.fb.group({
          id: [data.id],
          academicYearId: [data.academicYearId.toString(), Validators.required],
          classCourseId: [data.classCourseId.toString(), Validators.required],
          startDate: [this.formatDate(data.startDate), Validators.required],
          endDate: [this.formatDate(data.endDate), Validators.required],
          ageLimitMin: [data.ageLimitMin.toString(), Validators.required],
          ageLimitMax: [data.ageLimitMax.toString(), Validators.required],
          regFee: [data.regFee.toString(), Validators.required],
          regLimit: [data.regLimit.toString(), Validators.required],
          admissionLimit: [data.admissionLimit.toString(), Validators.required],
          status: [data.status.toString(), Validators.required]
        }, {
          validators: [this.validateDate('startDate', 'endDate'),
           this.validateAge('ageLimitMin', 'ageLimitMax'),
           this.validateAge('admissionLimit', 'regLimit')]
        });

        $('#startDate').val(data.startDate);
        $('#endDate').val(data.endDate);

      });

    }

    deleteForm(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.admissionProcedureService.delete(id).subscribe(res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            this.ListAdmissionProcedure();
          });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
        }
      });
    }

  ngOnInit(): void {
    this.ListClass();
    this.ListAcademicYear();
    this.ListAdmissionProcedure();
    this.admissionProcedureForm = this.fb.group({
      id: [0],
      academicYearId: ['', Validators.required],
      classCourseId: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      ageLimitMin: ['', Validators.required],
      ageLimitMax: ['', Validators.required ],
      regFee: ['', Validators.required],
      regLimit: ['', Validators.required],
      admissionLimit: ['', Validators.required],
      status: ['true', Validators.required]
    }, {
      validators: [this.validateDate('startDate', 'endDate'),
       this.validateAge('ageLimitMin', 'ageLimitMax'),
       this.validateAge('admissionLimit', 'regLimit')]
    });

    $('#startDate').datepicker().on('changeDate', (e) => {
      this.startDateUpdated = true;
      this.admissionProcedureForm.get('startDate').patchValue(e.date);
      });

    $('#endDate').datepicker().on('changeDate', (e) => {
        this.endDateUpdated =  true;
        this.admissionProcedureForm.get('endDate').patchValue(e.date);
      });
  }

}
