import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplicantregistrationComponent } from './applicantregistration/applicantregistration.component';
import { RegistrationrecieptComponent } from './registrationreciept/registrationreciept.component';
import { AcademicComponent } from './academic/academic.component';
import { ApplicantregistrationreportComponent } from './applicantregistrationreport/applicantregistrationreport.component';
import { ApplicantregistrationfollowupComponent } from './applicantregistrationfollowup/applicantregistrationfollowup.component';
import { AdmissionprocedureComponent } from './admissionprocedure/admissionprocedure.component';
import { SetentrancedateComponent } from './setentrancedate/setentrancedate.component';
import { EntranceresultComponent } from './entranceresult/entranceresult.component';
import { EntrancesummaryComponent } from './entrancesummary/entrancesummary.component';
import { RegFeeCollectionReportComponent } from './reg-fee-collection-report/reg-fee-collection-report.component';
import { SyllabusComponent } from './syllabus/syllabus.component';
import { StudentmanagementModule } from './studentmanagement/studentmanagement.module';
import { InstitutionprofileComponent } from './institutionprofile/institutionprofile.component';
import { InstitutionGuard } from '../../authentication/guards/institution.guard';
import { OfflineExaminationComponent } from './offline-examination/offline-examination/offline-examination.component';
import { OnlineExaminationComponent } from './online-examination/online-examination/online-examination.component';
const routes: Routes = [
  {path: 'academic', component: AcademicComponent,
    children: [
      {path: 'mastersettings', loadChildren: () => import('./mastersettings/mastersettings.module').then(m => m.MastersettingsModule)},
      {path: 'academicsettings', loadChildren: () => import('./academicsettings/academicsettings.module')
      .then(m => m.AcademicsettingsModule)},
      {path: 'studentmanagement', loadChildren: () => import('./studentmanagement/studentmanagement.module')
      .then(m => m.StudentmanagementModule)},
      {path: 'leavemanagement', loadChildren: () => import('./leavemanagement/leavemanagement.module')
      .then(m => m.LeavemanagementModule)},
      {path: 'feemanagement', loadChildren: () => import('./feemanagement/feemanagement.module')
      .then(m => m.FeemanagementModule)},
      {path: 'event', loadChildren: () => import('./eventcalendar/eventcalendar.module')
      .then(m => m.EventcalendarModule)},
      {path: 'class', loadChildren: () => import('./classmanagement/classmanagement.module')
      .then(m => m.ClassmanagementModule)},
      {path: 'studyplan', loadChildren: () => import('./studyplan/studyplan.module')
      .then(m => m.StudyplanModule)},
      {path: 'timetable', loadChildren: () => import('./timetable/timetable.module')
      .then(m => m.TimetableModule)},
      {path: 'attendance', loadChildren: () => import('./attendance/attendance.module')
      .then(m => m.AttendanceModule)},
      {path: 'attendance', loadChildren: () => import('./transport/transport.module')
      .then(m => m.TransportModule)},
      {path: 'offlineexamination', loadChildren: () => import('./offline-examination/offline-examination.module')
      .then(m => m.OfflineExaminationModule)},
      {path: 'onlineexamination', loadChildren: () => import('./online-examination/online-examination.module')
      .then(m => m.OnlineExaminationModule)}
    ]
  },
  {path: 'registration', component: ApplicantregistrationComponent},
  {path: 'registration/:id', component: ApplicantregistrationComponent},
  {path: 'registrationenquiry/:enquiryno', component: ApplicantregistrationComponent},
  {path: 'registrationreport', component: ApplicantregistrationreportComponent},
  {path: 'registrationfollowup', component: ApplicantregistrationfollowupComponent},
  {path: 'admissionprocedure', component: AdmissionprocedureComponent},
  {path: 'setentrancedate', component: SetentrancedateComponent},
  {path: 'entrancesummary', component: EntrancesummaryComponent},
  {path: 'entranceresult', component: EntranceresultComponent},
  {path: 'regfeereport', component: RegFeeCollectionReportComponent},
  {path: 'reciept/:id', component: RegistrationrecieptComponent, canActivate: [InstitutionGuard],
  canLoad: [InstitutionGuard]},
  {path: 'syllabus', component: SyllabusComponent},
  {path: 'institutionprofile', component: InstitutionprofileComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AcademicRoutingModule { }
