import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../../src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ExamPlannerService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private http:HttpClient) { }
  //url:string="http://localhost:5000/";
  getAllCbseDropDown(){
    return this.http.get(environment.apiUrl+"/weightages/GetAllCbseDropDown").pipe(
      catchError(this.errorHandler)
    )
  }
  getAllClassBatch() {
    return this.http.get(environment.apiUrl+"/examplanner/GetAllClasses").pipe(
      catchError(this.errorHandler)
    )
  }
  getExamPlannerList() {
    return this.http.get(environment.apiUrl+"/examplanner").pipe(
      catchError(this.errorHandler)
    )
  }
  
  createexamplanner(data){
    return this.http.post(environment.apiUrl+"/examplanner",JSON.stringify(data),this.httpOptions)
          .pipe(catchError(this.errorHandler))
  }
  updateexamplanner(id,data){
    
    return this.http.put(environment.apiUrl+"/examplanner/"+id,JSON.stringify(data),this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }
  deleteExamplanner(id){
    return this.http.delete(environment.apiUrl+"/examplanner/"+id,this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }
   //This service is used to get the exam planner by id
   getExamPlannerById(id){
    return this.http.get(environment.apiUrl+"/examplanner/"+id).pipe(
      catchError(this.errorHandler)
    )
  }
  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }

 private formatDate(date) {
  var d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();
  if (month.length < 2) {month = '0' + month; }
  if (day.length < 2) {day = '0' + day; }
  return new Date([day, month, year].join('-'));
}
}
