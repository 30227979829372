import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { Payscale, PayscaleDetail } from './models/payscale';

@Injectable({
  providedIn: 'root'
})
export class PayscaleService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<Payscale[]> {
    return this.httpClient.get<Payscale[]>(environment.apiUrl + '/payscales/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<Payscale[]> {
    return this.httpClient.get<Payscale[]>(environment.apiUrl + '/payscalesdetails/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getByIdMonth(id, empId, month): Observable<Payscale[]> {  
     month=( month==null || month==undefined||  month=='')?0:month;
     console.log("month", month);
    let url=environment.apiUrl + '/payscalesdetails/' + id+'/'+empId+'/'+month;
    console.log('url', url);
    return this.httpClient.get<Payscale[]>(environment.apiUrl + '/payscalesdetails/' + id+'/'+empId+'/'+month)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getPayScaleById(id): Observable<Payscale[]> {
    return this.httpClient.get<Payscale[]>(environment.apiUrl + '/payscales/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(academicyear): Observable<PayscaleDetail> {
    return this.httpClient.post<PayscaleDetail>(environment.apiUrl + '/payscalesdetails/', JSON.stringify(academicyear), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, academicyear): Observable<PayscaleDetail> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<PayscaleDetail>(environment.apiUrl + '/payscalesdetails/' + id, JSON.stringify(academicyear), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<Payscale>(environment.apiUrl + '/payscales/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}