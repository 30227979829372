import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CertificateRoutingModule } from './certificate-routing.module';
import { TemplatesettingComponent } from './templatesetting/templatesetting.component';
import { TemplatelistComponent } from './templatelist/templatelist.component';
import { GeneratecertificateComponent } from './generatecertificate/generatecertificate.component';
import { CertificatereportComponent } from './certificatereport/certificatereport.component';
import { PrintcertificateComponent } from './printcertificate/printcertificate.component';
import { GeneratetcComponent } from './generatetc/generatetc.component';
import { TcreportComponent } from './tcreport/tcreport.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [TemplatesettingComponent, TemplatelistComponent,
     GeneratecertificateComponent, CertificatereportComponent, PrintcertificateComponent,
      GeneratetcComponent, TcreportComponent],
  imports: [
    CommonModule,
    CertificateRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class CertificateModule { }
