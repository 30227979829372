import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Period} from './period.model';

@Injectable({
    providedIn: 'root'
  })
  export class PeriodService {
  
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<Period[]> {
      return this.httpClient.get<Period[]>(environment.apiUrl + '/period/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<Period> {
      return this.httpClient.get<Period>(environment.apiUrl + '/period/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    
    create(period): Observable<Period> {
      ;
      return this.httpClient.post<Period>(environment.apiUrl + '/period/create', JSON.stringify(period), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    
    bulkcreate(periods): Observable<any> {
      return this.httpClient.post(environment.apiUrl + '/period/BulkCreatePeriod/', JSON.stringify(periods), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    update(id, period): Observable<Period> {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.put<Period>(environment.apiUrl + '/period/' + id, JSON.stringify(period), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }
  
    delete(id) {
      return this.httpClient.delete<Period>(environment.apiUrl + '/period/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }
  
  
  }