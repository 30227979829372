import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {ApplicantRegistrationService} from '../applicantregistration/applicantregistration.service';
import { ApplicantRegistration, EntranceResult } from '../applicantregistration/applicantregistration.model';
import { ClassCourse } from '../mastersettings/models/classcourse';
import { ClassCourseService } from '../mastersettings/classcourse/classcourse.service';
import * as XLSX from 'xlsx';
import { ReportAttribute } from '../studentmanagement/report.model';
import { environment } from '../../../../environments/environment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as moment from 'moment';
import { FunctionService } from 'src/app/shared/function.service';
import { Academicyear } from '../mastersettings/models/academicyear';
import { SettingsService } from '../mastersettings/settings.service';
@Component({
  selector: 'app-entranceresult',
  templateUrl: './entranceresult.component.html',
  styleUrls: ['./entranceresult.component.css']
})
export class EntranceresultComponent implements OnInit {

  applicantRegistrations: ApplicantRegistration[] = [];
  registrationfilteredlist: ApplicantRegistration[] = [];
  checkedRegistrationList: ApplicantRegistration[] = [];
  checkedResultList: EntranceResult[] = [];
  entranceresult: EntranceResult = new EntranceResult();
  classcourses: ClassCourse[] = [];
  classcourseid = '0';
  status = 'All Status';
  fileName = 'EntranceResult.xlsx';
  allDate: any = []
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = []; 
  currentAcademicYearId: number;
  currentAcademicYearName = '';
  academicYear:Academicyear[]=[];
  institution: any;
  constructor(
    private registrationService: ApplicantRegistrationService,
    private classcourseService: ClassCourseService,
    private toastr: ToastrService,
    public fun: FunctionService,
    public settingsService:SettingsService
  ) {
    this.GetCurrentAcademicYear()
   }

  ListApplicantRegistration() {    
    this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
      console.log(data);
      var sortArray = [];
      for(var i = 0 ; i < data.length ; i++){
        if(data[i].entranceStatus == 'Scheduled')
        sortArray.push(data[i])
      }
    this.applicantRegistrations = sortArray;
    });
  }

  GetCurrentAcademicYear() {
    this.settingsService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYear = data;
      this.currentAcademicYear();
    });
  }

  currentAcademicYear() {
    this.settingsService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.currentAcademicYearName = this.academicYear.find(x => x.id.toString() === data.id.toString()).name;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  // onClassSelected(value: string) {
  //   console.log(value);
  //   if (value === '0') {
  //     // this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
  //       // console.log(data);
  //       this.registrationfilteredlist = this.applicantRegistrations; // = data;
  //     // });
  //   } else {
  //    // this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
  //       this.registrationfilteredlist = this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) =>
  //       applicantRegistration.classCourseId.toString().toLocaleLowerCase() === value.toLocaleLowerCase());
  //     // });
  //   }
  // }

  exportexcel() {
    
       /* table id is passed over here */
       const element = document.getElementById('registrationtable');
       const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Entrance Result');
       /* save to file */
       XLSX.writeFile(wb, this.fileName);
    }

    generatePDF() {
     
      this.headerList =[];
      this.fieldList =[];
      this.attributes.forEach(x => {
        this.headerList.push(x.name);
        this.fieldList.push(x.fieldName);
      });
      const widthList = [];
      for (const item of this.headerList) {
        widthList.push('auto');
      }
     
      for(var i = 0 ; i < this.registrationfilteredlist.length ; i++)
      this.registrationfilteredlist[i]["index"] = i+1;

      // if(this.registrationfilteredlist && this.registrationfilteredlist.length > 0){
      //   this.registrationfilteredlist.forEach(element => {
      //     this.allDate.push(this.fun.transFormDate(element.registrationDate, 'yyyy'))
      //   });
      // }
      // var unique = this.allDate.filter((value, index, array) => array.indexOf(value) === index);
     
      const docDefinition = {
        content: [
          {
              table: {
                  widths: ['auto', '*'],
                  body: [
                      [ {
                          width: 100,
                          height: 100,
                          image: 'imageKey'
                      }, {
                          width: '*',
                          alignment: 'center',
                          stack: [
                              {
                                  style: 'h1',
                                  text: this.institution.institutionName
                              },
                              {
                                style: 'h2',
                                text: this.institution.address
                              },
                              {
                                style: 'h2',
                                text: this.currentAcademicYearName
                              },
                              {
                                  style: 'h1',
                                  text: 'ENTRANCE RESULT'
                              }
                          ]
                      }
                      ]
                  ]
              },
              layout: {
                hLineWidth: () =>  0,
                vLineWidth: () => 0,
                paddingBottom: () => 5,
                paddingLeft:()=> 5,
                paddingRight:()=> 0
              },
            },
            {
              style:'sectionHeader',
              table: {
                headerRows: 1,
                widths: ['5%','10%','18%', '10%', '8%', '18%', '18%', '14%'],
                body: [
                  this.headerList,
                  ...this.registrationfilteredlist.map((p) => {
                    const temp = [];
                    for (const y of this.fieldList) {
                      if (y === 'dob') {
                        temp.push(this.formatDate(p[y]));
                      } else {
                        temp.push(p[y]);
                      }
                    }
                    return temp;
                  })
                ]
              }
            }
        ],
        images: {
          imageKey: environment.imageUrl + this.institution.logoUrl // via URL address, which can be referenced by this key
        },
        styles: {
          sectionHeader: {
            fontSize: 9,
            margin: [0, 15, 0, 15]
          },
          h1: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
          },
          h2: {
              margin: [0, 5, 0, 0],
              fontSize: 10,
              bold: true
          }
        }
      };  
      pdfMake.createPdf(docDefinition).download('ENTRANCE RESULT.pdf');
    }

    generateAttributes() {
      this.attributes = [
        {name: 'S No', fieldName: 'index' },
        {name: 'Reg No', fieldName: 'registerNo' },
        {name: 'Registration Date ', fieldName: 'registrationDate' },
        {name: 'Form No', fieldName: 'formNo' },
        {name: 'Class', fieldName: 'className' },
        {name: 'Student Name', fieldName: 'studentName' },
        {name: 'Father Name', fieldName: 'fatherFullName' },
        {name: 'Status', fieldName: 'entranceStatus' }
      ];
    }

  search(){
    this.registrationfilteredlist =  this.applicantRegistrations;
    var sortArray =  this.registrationfilteredlist ;
    var i = 0 ;
    if(this.classcourseid != "0")
    {
      sortArray = sortArray.filter((applicantRegistration: ApplicantRegistration) =>
      applicantRegistration.classCourseId.toString().toLocaleLowerCase() === this.classcourseid.toLocaleLowerCase());
  i = 1;
    }
    // if(this.status != "All Status")
    // {
    //   sortArray = sortArray.filter((applicantRegistration: ApplicantRegistration) =>
    //   applicantRegistration.entranceStatus.toString().toLocaleLowerCase() === this.status.toLocaleLowerCase());
    // }
    if(i == 1)
    this.registrationfilteredlist = sortArray;
    else
    this.registrationfilteredlist = [];
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.registrationfilteredlist = this.applicantRegistrations;
    } else {
    this.registrationfilteredlist = this.applicantRegistrations
                                .filter((applicantRegistration: ApplicantRegistration) =>
                                applicantRegistration.studentName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
    }
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  get totalRows(): number {
    return this.registrationfilteredlist.length;
  }

  checkAllCheckBox(ev) {
    this.registrationfilteredlist.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.registrationfilteredlist.every(p => p.checked);
  }

  submitForm() {
    this.registrationfilteredlist.forEach(x => {
      if (x.checked) {
        console.log(x.id);
        this.entranceresult.id = x.id;
        this.entranceresult.applicationStatus = x.applicationStatus;
        this.entranceresult.entranceReason = x.entranceReason;
        this.checkedResultList.push(this.entranceresult);
      }
    });
    // console.log(this.checkedRegistrationList);

    this.registrationService.saveentranceresult(this.checkedResultList).subscribe(res => {
        this.ListApplicantRegistration();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
    });
  }

  ngOnInit(): void {
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.generateAttributes();
    this.ListApplicantRegistration();
   this.ListClassCourse();

  }

}
