import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import {SubjectcreationService} from '../../academicsettings/subjectcreation/subjectcreation.service';
import { SubjectCreation } from '../../academicsettings/subjectcreation/subjectcreation.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ClassWork } from './classwork.model';
import { ClassworkService } from './classwork.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { SettingsService } from '../../mastersettings/settings.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
declare var $: any;
@Component({
  selector: 'app-classworks',
  templateUrl: './classworks.component.html',
  styleUrls: ['./classworks.component.css']
})
export class ClassworksComponent implements OnInit {

  classworkForm: UntypedFormGroup;
  classcourses: ClassCourse[];
  subjects: SubjectCreation[] = [];
  classsubjects: SubjectCreation[] = [];
  subjectFilter: SubjectCreation[] = [];
  classworks: ClassWork[] = [];
  classworkData: ClassWork = {} as ClassWork;
  filteredClassworks: ClassWork[] = [];
  searchClassworks: ClassWork[] = [];
  batches: Batch[] = [];
  searchBatches: Batch[] = [];
  batchIds: any[] = [];
  resBatchIds: any[] = [];
  batchId = '0';
  batchNames: any[] = [];
  slectedBatchIds = '';
  classId = '0';
  submitted = false;
  currentDate: string;
  nowDate = new Date();
  contentText:string;
  HighlightRow : Number;
  ClickedRow:any;
  isLoading :boolean =false;
  currentAcademicYearId: any;
  constructor(
    private classworkService: ClassworkService,
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    public subjectService: SubjectcreationService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public academicyearService: SettingsService,

  )

{ 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }

  }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      console.log(data.id);
      this.currentAcademicYearId = data.id;
      this.classworkForm.get('academicYearId').patchValue(this.currentAcademicYearId.toString());
    });
  }

  ListClassworks() {
    this.isLoading =!this.isLoading;
    this.classworkService.getAll().subscribe((data: ClassWork[]) => {
      console.log(data);
      this.filteredClassworks = this.classworks = data;
      this.isLoading =!this.isLoading;
    });
   
  }

  ListSubjects() {
    this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
      this.classsubjects = this.subjectFilter = this.subjects = data;
    });
  }
  ListClass() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  ListBatch() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.searchBatches = data;
    });
  }

  onClassChange(value: string) {
    if (value === '-1') { return; }
    if (value === '0') {
      this.filteredClassworks = this.classworks; // = data;
    } else {
      this.filteredClassworks = this.classworks.filter((assignment: ClassWork) =>
     // assignment.classId.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      assignment.classId.toString() === value.toString());
      this.batchService.getAll().subscribe((data: Batch[]) => {
        this.searchBatches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
      });
    }
  }

  onBatchChange(value: string) {
    if (value === '-1') { return; }
    if (value === '0') {
      this.searchClassworks = this.filteredClassworks = this.classworks; // = data;
    } else {
      this.searchClassworks = this.filteredClassworks = this.classworks.filter((classwork: ClassWork) => {
        const splitArr = classwork.batchIds==null?"": classwork.batchIds.split('|');
        return splitArr.includes(value);
      });
    }
  }

  onClassSelected(value: string) {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString().includes(value.toString()));
    });
  }

  getExistingBatches(value: string) {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
      this.batches.forEach(x => {
        // for (let i = 0; i < this.resBatchIds.length; i++) { this.resBatchIds[i]
        for (const j of this.resBatchIds) {
          if (x.id.toString() === j) {
            x.checked = true;
          }
        }
      });
    });
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (this.searchClassworks.length > 0) {
      if ( keyword === '') {
        this.filteredClassworks = this.searchClassworks;
      } else {
      this.filteredClassworks = this.searchClassworks
                                  .filter((classwork: ClassWork) => { return classwork.classWorkTitle.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase()) ||
                                  classwork.subjectName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  classwork.className.toLocaleLowerCase().includes(keyword.toLocaleLowerCase());
                                });
      }
    } else {
      if ( keyword === '') {
        this.filteredClassworks = this.classworks;
      } else {
      this.filteredClassworks = this.classworks
                                  .filter((classwork: ClassWork) => { return classwork.classWorkTitle.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase()) ||
                                  classwork.subjectName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  classwork.className.toLocaleLowerCase().includes(keyword.toLocaleLowerCase());
                                });
      }
    }
  }

  setCurrentDate() {
    const x = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.currentDate = days[x.getDay()] + ' ' + this.formatDate(x);
  }

  updateCurrentDate(date) {
    const x = new Date(date);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.currentDate = days[x.getDay()] + ' ' + this.formatDate(x);
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  get totalRows(): number {
    return this.filteredClassworks.length;
  }
  get f() { return this.classworkForm.controls; }

  resetForm() {
    this.submitted = false;
    this.batches.forEach(x => x.checked = false);
    this.classworkForm = this.fb.group({
      id: [0],
      classId: ['', Validators.required],
      batchIds: ['', Validators.required],
      subjectId: ['', Validators.required],
      classworkDate: [this.formatDate(new Date()), Validators.required],
      classworkTitle: ['', Validators.required],
      classworkText: [''],
      contentBase: [''],
      uploadFileName: [''],
      academicYearId: [this.currentAcademicYearId],
      status: ['true', Validators.required]
   });
    $('.summernote').summernote('code', '');
    this.contentText='';
  }

  checkAllCheckBox(ev) {
    this.batches.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.batches.every(p => p.checked);
  }

  // downloadDocFile(id, data) {
  //   const DocFileName = data;
  //   const DocFile = DocFileName.slice(0, -5);
  //   this.classworkService.downloadFile(id, data).subscribe((x: any) => {
  //       importedSaveAs(x, data);
  //   });
  // }

  public base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    b64Data = b64Data.split(',')[1];
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  onDownloadDocFile(response: string, filename: string) {
    // this.classworkService.downloadDocFile(id).subscribe((response) => {
        // console.log(response);
        // let file = new Blob([response], { type: 'application/pdf' });
        const mainData = response;
        const base64ContentArray = response;
        const extensn = base64ContentArray.split(';')[0].split('/')[1];
        const base64Data = response.split(',');
        const mimeType = base64Data[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];

        const blob = this.base64ToBlob(mainData, mimeType);
        saveAs(blob, filename);
    // });
  }

  onFileChange(event) {
    // const usersArray = this.admissionForm.controls.documentList as FormArray;
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
        const file = target.files[0];
        let result = '';
        const reader = new FileReader();
        reader.onload = (e => {
          result = reader.result.toString();
          this.classworkForm.get('contentBase').patchValue(result);
          this.classworkForm.get('uploadFileName').patchValue(file.name);
          // this.admissionForm.get('studentImage').patchValue(this.imageSrc);
        });

        reader.readAsDataURL(file);
    }
  }

  // uploadFile(event) {
  //   const leavefile = (event.target as HTMLInputElement).files[0];
  //   console.log(leavefile);
  //   this.classworkForm.patchValue({
  //     file: leavefile
  //   });
  //   this.classworkForm.get('file').updateValueAndValidity();
  // }

  submitForm() {
    this.submitted = true;
    this.slectedBatchIds = '';
    let k = 1;
    const totalSelected = this.batches.filter(x => x.checked).length;
    this.batches.forEach((x, i) => {
      if (x.checked) {
        if (totalSelected !== k) {
          this.slectedBatchIds += x.id.toString() + '|';
        } else {
          this.slectedBatchIds += x.id.toString();
        }
        k++;
      }
    });
    debugger;
    this.classworkForm.get('batchIds').patchValue(this.slectedBatchIds);
    this.classworkForm.get('academicYearId').patchValue= this.currentAcademicYearId;
    this.classworkForm.get('classworkText').patchValue($('.summernote').summernote('code'));
   // this.classworkForm.get('classworkText').patchValue(this.contentText);
    if (this.slectedBatchIds === '') {
      this.toastr.warning('Please select batch', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }
        // stop here if form is invalid
    if (this.classworkForm.invalid) {
      return;
    }

    // console.log(this.classworkForm.get('file').value);

    const formData: any = new FormData();
    formData.append('id', this.classworkForm.get('id').value);
    formData.append('classId', this.classworkForm.get('classId').value);
    formData.append('batchIds', this.classworkForm.get('batchIds').value);
    formData.append('subjectId', this.classworkForm.get('subjectId').value);
    formData.append('classworkDate', this.classworkForm.get('classworkDate').value);
    formData.append('classworkTitle', this.classworkForm.get('classworkTitle').value);
    formData.append('classworkText', this.classworkForm.get('classworkText').value);
    formData.append('status', this.classworkForm.get('status').value);
    formData.append('uploadFileName', this.classworkForm.get('uploadFileName').value);
    formData.append('contentBase', this.classworkForm.get('contentBase').value);
    formData.append('academicYearId', this.classworkForm.get('academicYearId').value);

    // formData.append('file', this.classworkForm.get('file').value);
    console.log(this.classworkForm.value);
    if (this.classworkForm.get('id').value === 0) {
        this.classworkService.create(this.classworkForm.value).subscribe(res => {
          console.log('Classwork created!');
           this.ListClassworks();
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
          setTimeout(() => {
            this.ListClassworks();
          }, 2000);
        });
    } else {
          // this.classworkService.update(this.classworkForm.get('id').value, this.classworkForm.value).subscribe(res => {
          this.classworkService.update(this.classworkForm.get('id').value, this.classworkForm.value).subscribe(res => {
            console.log('subject updated!');
             this.ListClassworks();
            this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submitted = false;
            this.resetForm();
            // this.casteForm.reset();
          });
          setTimeout(() => {
            this.ListClassworks();
          }, 2000);
        }
        
}

      viewForm(id: number) {
        this.classworkService.getDetails(id).subscribe((data: ClassWork) => {
          console.log(data);
          this.batchNames = data.batchName.split('|');
          $('.summernotes').summernote({height: 300, toolbar: false});
          $('.summernotes').summernote('code', data.classWorkText);
          $('.summernotes').summernote('disable');
          this.classworkData = data;
        });
      }

      updateForm(id: number) {
        this.classworkService.getById(id).subscribe((data: ClassWork) => {
          console.log(data);
          this.resBatchIds = data.batchIds.split('|');
          this.getExistingBatches(data.classId.toString());
          debugger;
          this.classworkForm = this.fb.group({
            id: [data.id],
            classId: [data.classId.toString(), Validators.required],
            batchIds: [data.batchIds.toString(), Validators.required],
            subjectId: [data.subjectId.toString(), Validators.required],
            classworkDate: [this.formatDate(data.classWorkDate), Validators.required],
            classworkTitle: [data.classWorkTitle, Validators.required],
            classworkText: [data.classWorkText],
            file: [data.uploadFile],
            uploadFileName: [data.uploadFileName],
            contentBase: [data.contentBase],
            status: [data.status.toString(), Validators.required],
            academicYearId: [data.academicYearId],
          });

          this.classworkForm.get('file').patchValue(data.uploadFile);
          this.classworkForm.get('file').updateValueAndValidity();
          $('.summernote').summernote('code', data.classWorkText);
          //this.contentText= data.classWorkText;
        });

      }

      deleteForm(id: number) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            this.classworkService.delete(id).subscribe(res => {
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              );
              this.ListClassworks();
            });
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
          }
        });
      }

  ngOnInit(): void {
    this.currentAcademicYear();
    this.ListSubjects();
    this.ListClass();
    this.ListClassworks();    
    this.classworkForm = this.fb.group({
      id: [0],
      classId: ['', Validators.required],
      batchIds: ['', Validators.required],
      subjectId: ['', Validators.required],
      classworkDate: [this.formatDate(new Date()), Validators.required],
      classworkTitle: ['', Validators.required],
      classworkText: [''],
      contentBase: [''],
      uploadFileName: [''],
      status: ['true', Validators.required],
      academicYearId: [this.currentAcademicYearId],
   });

    this.setCurrentDate();
    // const nowDate = new Date();
    // $('#classworkDate').val(this.formatDate(nowDate));
    // console.log(this.nowDate);
    $('.summernote').summernote({height: 200});
    $('#classworkDate').datepicker().on('changeDate', (e) => {
      this.classworkForm.get('classworkDate').patchValue(this.formatDate(e.date));
    });
  }

}
