import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { throwError, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Ticket, TicketPriority, TicketType } from '../models/ticket.model';
import { Institution } from '../models/institution.model';




@Injectable({
    providedIn: 'root'
  })
  export class TicketService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         enctype: 'multipart/form-data'
      })
    };
    constructor(private httpClient: HttpClient) { }
    create(ticket): Observable<any> {
        return this.httpClient.post(environment.apiUrl + '/tickets/', ticket)
        .pipe(
          catchError(this.errorHandler)
        );
      }
      update(ticketId, ticket): Observable<Ticket> {
        // tslint:disable-next-line:max-line-length
        return this.httpClient.put<Ticket>(environment.apiUrl + '/tickets/' + ticketId, JSON.stringify(ticket), this.httpOptions)
        .pipe(
          catchError(this.errorHandler)
        );
      }
  
      getAllTickets(): Observable<Ticket[]> {
        return this.httpClient.get<Ticket[]>(environment.apiUrl + '/tickets')
        .pipe(
          catchError(this.errorHandler)
        );
      }

      getTicketDetailsById(TicketId:string): Observable<Ticket> {
        return this.httpClient.get<Ticket>(environment.apiUrl + '/tickets/'+TicketId)
        .pipe(
          catchError(this.errorHandler)
        );
      }

      getInstitutionDetailsById(InstitutionId:string): Observable<Institution> {
        return this.httpClient.get<Institution>(environment.apiUrl + '/institution/' + InstitutionId)
        .pipe(
          catchError(this.errorHandler)
        );
      }

      getInstitutionDetailsByCode(InstitutionCode: string): Observable<Institution> {
        return this.httpClient.get<Institution>(environment.apiUrl + '/institution/GetByInstitutionCode/' + InstitutionCode)
        .pipe(
          catchError(this.errorHandler)
        );
      }

      getAllTicketTypes(): Observable<TicketType[]> {
        //getAllTicketTypes(): Observable<TicketType[]> {
          // return this.httpClient.get<TicketType[]>(environment.apiUrl + '/tickets/types')
        // .pipe(
        //   catchError(this.errorHandler)
        // );

        var ticketTypes:TicketType[]=[];
        ticketTypes=[{Id:0,TypeName:'Others'},
                     {Id:1,TypeName:'Payment'}];
       return of(ticketTypes);


      }
      
      getAllTicketPriority(): Observable<TicketPriority[]> {
        //getAllTicketPriority(): Observable<TicketPriority[]> {
          // return this.httpClient.get<TicketPriority[]>(environment.apiUrl + '/tickets/priority')
        // .pipe(
        //   catchError(this.errorHandler)
        // );

        var ticketPriority:TicketPriority[]=[];
        ticketPriority=[{Id:0,PriorityName:'High'},
                        {Id:1,PriorityName:'Medium'},
                        {Id:2,PriorityName:'Low'}];
       return of(ticketPriority);


      }


      errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.message;
        } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
     }

}
