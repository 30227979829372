import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { examremarks } from '../ExamSetting/models/examremarks.model';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class examremarksService {
  
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private http: HttpClient) { }
  url: string = "http://localhost:5000/";
  //Get All Exam Remarks Records
  getAllExamRemarks() {

    return this.http.get(environment.apiUrl+"/ExamRemark").pipe(
      catchError(this.errorHandler)
    )
  }

  //Saving Exam Marks Records
  CreateExamRemarks(data): Observable<examremarks> {
    return this.http.post<examremarks>(environment.apiUrl+"/ExamRemark", JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

//Update
  updateExamRemarks(id, data): Observable<examremarks> {
    return this.http.put<examremarks>(environment.apiUrl+"/ExamRemark/" + id, JSON.stringify(data),this.httpOptions)
      .pipe(catchError(this.errorHandler)
      )
  }
 //Delete
 Del_ExamRemarks(id){
  return this.http.delete<examremarks>(environment.apiUrl+"/ExamRemark/"+id,this.httpOptions)
        .pipe(
          catchError(this.errorHandler)
        )
}



  getExamRemarksById(id): Observable<examremarks> {
   
    return this.http.get<examremarks>(environment.apiUrl+"/ExamRemark/" + id).pipe(

      catchError(this.errorHandler)
    )
  }





  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
