import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RandomGuard  {
  test: string;

  constructor(private authService: AuthenticationService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.canLoad(route);
  }

  canLoad(route) {    
    console.log(route.url);

    this.test=route.url;
   // this.test=this.getResolvedUrl(route);
    console.log("router :"+this.router);
    console.log("route :"+this.authService.institutionCode);
    this.authService.userType = route.data.role;
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['edumaq/login']);
    }
    /*if (route.params.edumaq!='edumaq'){
      this.router.navigate([route.params.edumaq]);
      //this.router.navigateByUrl(this.test);
    }*/
    return this.authService.isLoggedIn();
  }

 getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
        .map(v => v.url.map(segment => segment.toString()).join('/'))
        .join('/');
}
}
