import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { LeaveAllocation } from './models/leaveallocation';
import { LeaveType } from './models/leavetype';

@Injectable({
  providedIn: 'root'
})
export class LeaveallocationService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getEmpAll(EmpId): Observable<LeaveAllocation[]> {
    return this.httpClient.get<LeaveAllocation[]>(environment.apiUrl + '/leaveallocation/'+EmpId)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAll(): Observable<LeaveType[]> {
    return this.httpClient.get<LeaveType[]>(environment.apiUrl + '/leavetypes/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<LeaveType> {
    return this.httpClient.get<LeaveType>(environment.apiUrl + '/leavetypes/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  isCurrentAcademicYearExits(): Observable<LeaveType> {
    return this.httpClient.get<LeaveType>(environment.apiUrl + '/leavetypes/IsCurrentAcademicYearExits')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(academicyear): Observable<LeaveAllocation> {
    ;
    if(academicyear.status=='0')
    {
      academicyear.active=false;
    }
    return this.httpClient.post<LeaveAllocation>(environment.apiUrl + '/createleaveallocation/', JSON.stringify(academicyear), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, academicyear): Observable<LeaveType> {
    // tslint:disable-next-line:max-line-length
    if(academicyear.status=='0')
    {
      academicyear.active=false;
    }
    return this.httpClient.put<LeaveType>(environment.apiUrl + '/leavetypes/' + id, JSON.stringify(academicyear), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getLeaveAllocation(EmpId,DepartmentId,employeeTypeId,employeeId): Observable<LeaveAllocation[]> {
    return this.httpClient.get<LeaveAllocation[]>(environment.apiUrl + '/leaveallocation/'+EmpId+'/'+DepartmentId+'/'+employeeTypeId+'/'+employeeId)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  
  delete(id) {
    return this.httpClient.delete<LeaveType>(environment.apiUrl + '/leavetypes/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}