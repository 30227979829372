import { Component, OnInit } from '@angular/core';
import { TCReportService } from './tcreport.service';

@Component({
  selector: 'app-tcreport',
  templateUrl: './tcreport.component.html',
  styleUrls: ['./tcreport.component.css']
})
export class TcreportComponent implements OnInit {

  tsummarylist: any[] = [];
  studentTCs: any[] = [];
  studentTCsList: any[] = [];
  studentTCs4Print: any[] = [];
  sumTotalStudents = '0';
  sumTCgenerated = '0';
  selectedRow:number;
  scIds: string[] = [];

  constructor(
    public tcReportService: TCReportService
  ) { }

  ListTCSummary(){
    this.tcReportService.getTCSummary().subscribe((data: any[])=>{
      this.tsummarylist = data;
      const studentsum = this.tsummarylist.reduce((sum, obj) => sum + parseInt(obj.totalStudents), 0);
      const tcsum = this.tsummarylist.reduce((sum, obj) => sum + parseInt(obj.tcGenerated), 0);
      this.sumTotalStudents = studentsum.toString();
      this.sumTCgenerated = tcsum.toString();
    });
  }

  getStudentWiseTC(d:any, i:number) {
    debugger;
    this.selectedRow=i;
    let details = {
      ClassId:d.classId,
      BatchId:d.batchId
    };
    this.tcReportService.getStudentTransferCertificates(details).subscribe((data: any[])=>{
      this.studentTCs = data;
      this.studentTCsList = this.studentTCs;
    });
  }

  ngOnInit(): void {
    this.ListTCSummary();
  }

  onKeyUpEvent(event: any) {
    debugger;
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.studentTCs = this.studentTCsList;
    } else {
      this.studentTCs = this.studentTCsList.filter((record: any) => {
        return record.studentName.toLowerCase().includes(keyword.toLowerCase()) ||
          record.admissionNo.toString().toLowerCase().includes(keyword.toLowerCase()) ||
          (record.rollNo ?? '').toString().toLowerCase().includes(keyword.toLowerCase()) ||
          (record.certificateNo ?? '').toString().toLowerCase().includes(keyword.toLowerCase());
      });
    }
  }

  selectAllCertificate(evnt: any) {
    if (evnt.target.checked) {
      this.tsummarylist.forEach(x => {
        x.checked = true;
      });
    } else {
      this.tsummarylist.forEach(x => {
        x.checked = false
      });
    }
    this.generatePDF();
  }

  onCheckCertificate(evnt: any, id: any) {
    this.generatePDF();
  }
  
  generatePDF() {
    const summaryIds: string[] = [];
    this.scIds = [];
    this.tsummarylist.forEach(x => {
      if (x.checked) {
        summaryIds.push(x.Id);
        let details = {
          ClassId:x.classId,
          BatchId:x.batchId
        };
        this.tcReportService.getStudentTransferCertificates(details).subscribe((data: any[])=>{
          this.studentTCs4Print = data;
          this.studentTCs4Print.forEach(y => {
            this.scIds.push(y.id);
          });
        });
      }
    });
  }
}
