export class FeeHeadwiseCollectionReportModel
{
  feeHead:string;
  date:string;
  receiptNo:string;
  admissionNo:string;
  studentName:string;
  class:string;
  batch:string;
  rollNo:string;
  totalAmount:string;
  discountAmount:string;
  paidAmount:string;
  payMode:string;
}
export interface FeeHeadWiseReportResponse {
  table: FeeHeadwiseCollectionReportModel[];
}
export class FeeHeadWiseReportVm {
  classId?: number;
  batchId?: number;
  feeHeadId?: number;
  fromDate?: Date | string;
  toDate?: Date | string;
}

