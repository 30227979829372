import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BackupRestoreComponent } from './backup-restore/backup-restore.component';

const routes: Routes = [
  {path: 'backuprestore', component: BackupRestoreComponent}
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BackupRestoreRoutingModule { }
