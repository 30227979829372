import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Leave } from '../leave.model';
import { LeaveService } from '../leave.service';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { StudentService} from './../../studentmanagement/student.service';
import { Student } from './../../studentmanagement/student.model';
import { environment } from '../../../../../environments/environment';
declare var $: any;
@Component({
  selector: 'app-leavereport',
  templateUrl: './leavereport.component.html',
  styleUrls: ['./leavereport.component.css']
})
export class LeavereportComponent implements OnInit {

  leaves: Leave[] = [];
  filteredLeaves: Leave[] = [];
  selectedFilteredLeaves: Leave[] = [];
  batches: Batch[] = [];
  classcourses: ClassCourse[] = [];
  studentData: Student = {} as Student;
  leaveData: Leave = {} as Leave;
  fromDate: Date;
  toDate: Date;
  imageSrc = '';
  isLoading:boolean=false;

  constructor(
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    public leaveService: LeaveService
  ) { }

  ListLeaves() {
    this.isLoading=true;
    this.leaveService.getAll().subscribe((data: Leave[]) => {
      /*this.leaves = JSON.parse(JSON.stringify(data));
      this.filteredLeaves = JSON.parse(JSON.stringify(this.leaves));*/
      this.leaves=this.filteredLeaves=data;
      this.isLoading=false;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  onChangeBatch(value: string) {
    
    //this.filteredLeaves = JSON.parse(JSON.stringify(this.leaves));
    this.filteredLeaves = this.leaves;
    this.isLoading=true;
    setTimeout(() => {
      this.filteredLeaves = this.selectedFilteredLeaves = this.filteredLeaves.filter((leave: Leave) =>
    leave.batchId.toString() === value.toString());
    this.isLoading=false;
    }, 1500);
    

  }

  onClassSelected(value: string) {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
    });
  }

  public todayDate() {
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  search() {
    debugger;
    this.isLoading=true;
   setTimeout(() => {
    console.log(new Date(this.fromDate).getTime());
    this.filteredLeaves = this.leaves;
    if(this.fromDate!=undefined&& this.toDate!=undefined){
      if (this.filteredLeaves.length !== 0 ) {
        //this.filteredLeaves = JSON.parse(JSON.stringify(this.leaves));
        //this.selectedFilteredLeaves=this.leaves;
       const filtredArray = this.filteredLeaves.filter((item: Leave) => {
          const cDate = moment(item.createdDate, 'DD-MM-yyyy').toDate();
          /*return cDate.getTime() >= new Date(this.fromDate).getTime() &&
          cDate.getTime() <= new Date(this.toDate).getTime();*/
          return new Date(item.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
          new Date(item.createdDate).getTime() <= new Date(this.toDate).getTime();
        });
        this.filteredLeaves=filtredArray;
      }
    }
    else{
      this.filteredLeaves = this.leaves;
    }
    this.isLoading=false;
    }, 1500);

    
    
  }
  onChangetoDate(){
    this.toDate=$('#toDate').val;
    alert(this.toDate)
  }
  resetTable(){
    this.filteredLeaves=this.leaves;
    this.toDate=undefined;
    this.fromDate=undefined;
  }
  onChangeLeaveStatus(value: string) {
    this.isLoading=true;
    setTimeout(() => {
      if (value === 'Select Status') { return false; }
      if (this.selectedFilteredLeaves.length === 0 ) {
        if (value === 'All') {
          this.filteredLeaves = this.leaves;
        } else {
          // this.filteredLeaves = this.leaves;
          this.filteredLeaves = this.filteredLeaves.filter((leave: Leave) =>
          leave.leaveStatus.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
        }
      } else {
        if (value === 'All') {
          this.filteredLeaves = this.selectedFilteredLeaves;
        } else {
          // this.filteredLeaves = this.leaves;
          this.filteredLeaves = this.selectedFilteredLeaves.filter((leave: Leave) =>
          leave.leaveStatus.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
        }
      }
    }, 1500);
    
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }
  filter(keyword: any) {
    if ( keyword === '') {
      this.filteredLeaves = this.leaves;
    } else {
    this.filteredLeaves = this.filteredLeaves
                          .filter((leave: Leave) => leave.admissionNo.toLocaleLowerCase()
                          .includes(keyword.toLocaleLowerCase()) || leave.studentName.toLocaleLowerCase()
                          .includes(keyword.toLocaleLowerCase())               );
    }
    // if (this.admissionFilteredList.length === 1 ) {
    //   this.studentData = this.admissionFilteredList[0];
    // }
  }

  viewForm(id: string) {
    this.studentService.getdetails(id).subscribe((data: Student) => {
      console.log(data);
      this.imageSrc = data.imageUrl != null ? environment.imageUrl + data.imageUrl : null;
      this.studentData = data;
    });
  }

  updateForm(id: number) {
    this.leaveService.getById(id).subscribe((data: Leave) => {
      console.log(data);
      this.viewForm(data.admissionId);
      this.leaveData = data;

      // this.leaveForm = this.fb.group({
      //   id: [data.id],
      //   reason: [data.reason, Validators.required],
      //   remarks: [''],
      //   leaveStatus: [data.leaveStatus.toString(), Validators.required]
      // });
    });
  }


  ngOnInit(): void {
    this.ListClassCourse();
    this.ListLeaves();

   /* $('#fromDate').datepicker('setDate', new Date()).on('changeDate', (e) => {
      this.fromDate =  new Date(e.date);
   });

    $('#toDate').datepicker('setDate', new Date()).on('changeDate', (e) => {
      this.toDate = new Date(e.date);
    });*/
    $('#fromDate').datepicker().on('changeDate', (e) => {
      this.fromDate =  new Date(e.date);
      console.log('from date'+this.fromDate);
   });

    $('#toDate').datepicker().on('changeDate', (e) => {
     this.toDate = new Date(e.date);
     console.log('To date'+this.toDate);
   });
  }

}
