import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../../src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MarksEntryService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private http:HttpClient) { }
  //url:string="http://localhost:5000/";

  getAllCatefgoryDropDown(){
    return this.http.get(environment.apiUrl+"/examcategory").pipe(
      catchError(this.errorHandler)
    )
  }
  getExamNameList() {
    return this.http.get(environment.apiUrl+"/examplanner").pipe(
      catchError(this.errorHandler)
    )
  }
  getAllClass(dataFilter?:any){
    return this.http.get(environment.apiUrl+"/examschedule/GetClasses?examPlannerId="+dataFilter).pipe(
      catchError(this.errorHandler)
    )
  }

  getAllMarksEntry(examCatId,examId,classId,batchId,isScholastic):Observable<any>{
    if(isScholastic=='all'){
      isScholastic='-1';
    }
    return this.http.get<any>(environment.apiUrl+"/markentry?ExamCatId="+examCatId+"&ExamId="+examId+"&ClassId="+classId+"&BatchId="+batchId+"&EvaluvationType="+isScholastic).pipe(
      catchError(this.errorHandler)
    )
  }

  getSubjectWithFullMarks(examCatId,examid,classId,batchId,scholastic):Observable<any>{
    if(scholastic=='all'){
      scholastic='-1';
    }
    return this.http.get<any>(environment.apiUrl+"/markEntry/GetSubjectWithFullMarks?examCategoryId="+examCatId+"&examId="+examid+"&classId="+classId+"&batchId="+batchId+"&EvaluationTypeId="+scholastic
    ).pipe(
      catchError(this.errorHandler)
    )
  }

  saveMarksEntry(model):Observable<any> {
    return this.http.post(environment.apiUrl+"/markentry",JSON.stringify(model),this.httpOptions)
    .pipe(catchError(this.errorHandler))
  }

  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
