export class CoursePlannerVM {
    id: number;
    classId: number;
    batchId: number;
    subjectId: number;
    courseTitle: string;
    board: string;
    languageId: number;
    durationMonth: number;
    durationDay: number;
    courseThumblineUrl: string;
    cost: number;
    status: number | boolean;
    startDate: Date;
    courseThumblineImage: File;
    Session: number;
    informations:string;

   
  }

 
 

