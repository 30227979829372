import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SuperadminComponent } from './superadmin/superadmin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InstitutionGuard } from '../authentication/guards/institution.guard';

const routes: Routes = [
  {
    path: ':instcode/superadmin',
    component: SuperadminComponent,
    canActivate: [InstitutionGuard],
    data: {
      role: 'Super Admin',
    },
    canLoad: [InstitutionGuard],
    children: [
        {path: '', component: DashboardComponent},
        {path: 'dashboard', component: DashboardComponent}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SuperAdminRoutingModule { }





