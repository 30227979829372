import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExamResultReportService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private http:HttpClient) { }
  url:string="http://localhost:5001/";

  getAllCatefgoryDropDown(){
    return this.http.get(this.url+"api/examcategory").pipe(
      catchError(this.errorHandler)
    )
  }
  getExamNameList() {
    return this.http.get(this.url+"api/examplanner").pipe(
      catchError(this.errorHandler)
    )
  }
  getAllClass(dataFilter?:any){
    return this.http.get(this.url+"api/examschedule/GetClasses?examPlannerId="+dataFilter).pipe(
      catchError(this.errorHandler)
    )
  }

  getAllStudents(examCatId,examId,classId,batchId):Observable<any>{
   
    return this.http.get<any>(this.url+"api/GetExamResultReport?examCatId="+examCatId+"&examId="+examId+"&classId="+classId+"&batch="+batchId).pipe(
      catchError(this.errorHandler)
    )
  }

  GetAllStudentMarkForSelectedSubject(examCatId,examId,classId,batchId, studentId):Observable<any>{
    return this.http.get<any>(this.url+"api/GetExamResultStudentReport?examCatId="+examCatId+"&examId="+examId+"&classId="+classId+"&batch="+batchId+"&studentId="+studentId).pipe(
      catchError(this.errorHandler)
    )
  }

  GetExamResultStudentReportDetails(examCatId,examId,classId,batchId, studentId):Observable<any>{
    return this.http.get<any>(this.url+"api/GetExamResultStudentReportDetails?examCatId="+examCatId+"&examId="+examId+"&classId="+classId+"&batch="+batchId+"&studentId="+studentId).pipe(
      catchError(this.errorHandler)
    )
  }

  saveFreezeExamMark(model):Observable<any> {
    return this.http.post(this.url+"api/freezeexammarkdata",JSON.stringify(model),this.httpOptions)
    .pipe(catchError(this.errorHandler))
  }

  errorHandler(error) {
   
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
