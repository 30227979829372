import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InstitutionProfile } from './institutionprofile.model';
import { InstitutionVerificationModel } from './institutionverificationmodel';

@Injectable({
    providedIn: 'root'
  })
  export class InstitutionprofileService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<InstitutionProfile[]> {
      return this.httpClient.get<InstitutionProfile[]>(environment.apiUrl + '/EventCalendar/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getProfile(): Observable<InstitutionProfile> {
      return this.httpClient.get<InstitutionProfile>(environment.apiUrl + '/Institution/GetInstitutionProfileForInstitution')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getInstaLogo(instaCode:any): Observable<InstitutionProfile> {
      return this.httpClient.get<InstitutionProfile>(environment.apiUrl + '/Institution/GetInstitutionLogo/'+instaCode)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    verifyInstitution(instaCode:any): Observable<InstitutionVerificationModel> {
      return this.httpClient.get<InstitutionVerificationModel>(environment.apiUrl + '/Institution/VerifyInstitution/'+instaCode)
      .pipe(
        catchError(this.errorHandler)
      );
    }


    // create(eventcalendar): Observable<EventCalendar> {
    //   return this.httpClient.post<EventCalendar>(environment.apiUrl + '/EventCalendar/', JSON.stringify(eventcalendar), this.httpOptions)
    //   .pipe(
    //     catchError(this.errorHandler)
    //   );
    // }

    update(id, eventcalendar): Observable<InstitutionProfile> {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.post<InstitutionProfile>(environment.apiUrl + '/Institution/UpdateInstitutionProfile', JSON.stringify(eventcalendar), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    // delete(id) {
    //   return this.httpClient.delete<EventCalendar>(environment.apiUrl + '/EventCalendar/' + id)
    //   .pipe(
    //     catchError(this.errorHandler)
    //   );
    // }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }
}
