
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CbseAssignWeightage } from '../ExamSetting/models/cbse-assign-weightage.model';
import { environment } from '../../../../../../src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AssignweightagecbseService {

  constructor(private http:HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  //url:string="http://localhost:5000/";
  assignWeightageCBSE(data):Observable<CbseAssignWeightage> {
    return this.http.post<CbseAssignWeightage>(environment.apiUrl+"/assignweightagecbse",JSON.stringify(data),this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }

  getAssignCBSEWeightage(){
    return this.http.get(environment.apiUrl+"/assignweightagecbse/GetAssignCBSEWeightage")
          .pipe(catchError(this.errorHandler))
  }

  loadAssignWeightageCBSE(){
    return this.http.get(environment.apiUrl+"/assignweightagecbse")
          .pipe(catchError(this.errorHandler))
  }
  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
