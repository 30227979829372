import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FeeHeadwiseCollectionReportService } from './feeheadwisecollectionreport.service';
import { FeeHeadwiseCollectionReportModel, FeeHeadWiseReportResponse, FeeHeadWiseReportVm } from './feeheadwisecollectionreport.model';
import { FeeHeadService } from './../../feehead/feehead.service';
import { FeeHead } from './../../feehead/feehead.model';
import { ClassCourse } from '../../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../../mastersettings/models/batch';
import { BatchService } from '../../../mastersettings/batch/batch.service';
import { Student } from './../../../studentmanagement/student.model';
import { StudentService } from './../../../studentmanagement/student.service';
import { formatDateByMoment } from 'src/app/shared/helpers/date-utility';
import { formatDate } from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import { ReportAttribute } from '../../../studentmanagement/report.model';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-feeheadwisecollectionreport',
  templateUrl: './feeheadwisecollectionreport.component.html',
  styleUrls: ['./feeheadwisecollectionreport.component.css']
})
export class FeeheadwisecollectionreportComponent implements OnInit {
  classDrp: string;
  batchDrp: string;
  studentDrp: string;
  admissionNo: string;
  specialFeeTypeDrp: string;
  sumTotal: any = 0;
  sumDisc: any = 0;
  sumPaidAmount: any = 0;
  mode: string;
  feeHeadWiseReportVm: FeeHeadWiseReportVm = new FeeHeadWiseReportVm();
  totalPaidAmount: any;
  feeHeadwiseCollectionReportList: FeeHeadwiseCollectionReportModel[] = [];
  feeHeadwiseCollectionReportFilteredList: FeeHeadwiseCollectionReportModel[] = [];
  specialFeeList: FeeHead[] = [];
  admissions: Student[] = [];
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  filteredBatches: Batch[] = [];
  dateRangeIsValid: boolean = false;
  isLoading: boolean = false;
  attributes: ReportAttribute[] = [];
  institution: any;
  currentAcademicYearName = '';
  dateTo:any;
  dateFrom:any;
  constructor(
    public feeHeadwiseCollectionReportService: FeeHeadwiseCollectionReportService,
    public feeHeadService: FeeHeadService,
    public classCourseService: ClassCourseService,
    public batchService: BatchService,
    public studentService: StudentService,
    private toastr: ToastrService
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  onClassSelected(): void {
    this.classDrp = this.classes.filter((x) => x.id === +this.feeHeadWiseReportVm.classId)[0].classCourseName;
    this.getBatchesByClassId(this.feeHeadWiseReportVm.classId);
  }

  onBatchChanged(value: string): void {
    this.batchDrp = this.batches.filter((x) => x.id.toString() === value)[0].batchName;
  }

  onKeyUpEvent(event: any): void {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    
    if (keyword === '') {
      
      this.feeHeadwiseCollectionReportFilteredList = this.feeHeadwiseCollectionReportList;
    }
    else {
       
      const loweredCaseKeyword: string = keyword.toLocaleLowerCase();
     
      this.feeHeadwiseCollectionReportFilteredList = this.feeHeadwiseCollectionReportList.filter(d => {
        return (String(d.rollNo)  === loweredCaseKeyword ||
        String(d.receiptNo.toLocaleLowerCase()) === loweredCaseKeyword ||
          String(d.admissionNo.toLocaleLowerCase()) === loweredCaseKeyword ||
          String(d.studentName.toLocaleLowerCase()).includes(loweredCaseKeyword) ||
          String(d.batch.toLocaleLowerCase()).includes(loweredCaseKeyword) 
          // ||

          // String(d.feeHead.toLocaleLowerCase()).includes(loweredCaseKeyword)
          );
      });
    }
    this.calculateTotalPayments();
  }

  getFeeHeadWiseCollectionReport() {
   ;
    this.isLoading = true;
    this.validateDateRange(this.feeHeadWiseReportVm);
    if (this.dateRangeIsValid) {
      this.feeHeadwiseCollectionReportService.getFeeHeadWiseCollectionReport(this.feeHeadWiseReportVm)
        .subscribe((data: FeeHeadWiseReportResponse) => {
          this.feeHeadwiseCollectionReportFilteredList = this.feeHeadwiseCollectionReportList = data.table;
          this.calculateTotalPayments();
          this.isLoading = false;
        }, () => { // on error
          this.isLoading = false;
        });
    }
    else {
      this.isLoading = false;
    }
  }

  ngOnInit(): void {
    //this.feeHeadWiseReportVm = new FeeHeadWiseReportVm();
    // this.specialFeeTypeDrp = 'Select Fee Head';
    // this.classId = 'Class';
    // this.batchId = 'Batch';
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.generateAttributes();
    this.getAllClassCourses();
    this.getAllBatches();
    this.getAllSpecialFeeList();

    $('#dateFrom').datepicker().on('changeDate', (e) => {
      this.feeHeadWiseReportVm.fromDate = formatDateByMoment(e.date);
    });

    $('#dateTo').datepicker().on('changeDate', (e) => {
      this.feeHeadWiseReportVm.toDate = formatDateByMoment(e.date);
    });
  }

  generateExcel(): void {
    const headerList =[];
    const fieldList =[];
    this.attributes.forEach(x => {
      headerList.push(x.name);
      fieldList.push(x.fieldName);
    });

    const defaultArray = [];
    for(var i = 0 ; i < this.feeHeadwiseCollectionReportFilteredList.length ; i++)
    {
      let data = this.getFileRecordItem(i);
      defaultArray.push(data);
    }
    /* let data1 ={
      "index" : "",
      "payDate" : "",
        "receiptNo" : "",
        "admissionNo" :"",
        "studentName" : "",
        "class" : "",
        "batch" : "",
        "payMode" : "",
        "takenBy" : "",
        "paidStatus" : "",
        "rollNo" : "",
        "paidAmount" :this.totalPaidAmount
    }
    this.defaultArray.push(data1);*/
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('FEE HEAD WISE COLLECTION');
    worksheet.addRow();
    // if (this.institution.logoUrl !== null) {
    //   const imageId2 = workbook.addImage({
    //     base64: environment.imageUrl + this.institution.logoUrl,
    //     extension: 'jpg',
    //   });
    //   worksheet.addImage(imageId2, 'A1:B1');
    // }

    const titleRow = worksheet.addRow(['FEE HEAD WISE COLLECTION']);
    const headerRow = worksheet.addRow(headerList);
    for(var i = 0 ; i < defaultArray.length ; i++)
    defaultArray[i]["index"] = i+1;
    for (const x1 of defaultArray) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of fieldList) {
        if (y === 'payDate') {
          if(x1[y]=="" || x1[y]==undefined){
            temp.push("");
          }else{
            temp.push(formatDateByMoment(x1[y]));
          }
        } else {
          if(y === "paidStatus")
          {
           ;
            if(x1[y] === true || x1[y] === false){
              temp.push(x1[y] === true ?"Cancelled":"Paid");
            }else{
              temp.push("")
            }
          }
          else{
            temp.push(x1[y]);
          }
        }
      }
      worksheet.addRow(temp);
    }
    const fname = 'FEE HEAD WISE COLLECTION';

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }

  generatePDF(): void {
   
    const pdffromdate = formatDate(this.feeHeadWiseReportVm.fromDate, 'dd-MM-yyyy', "en-US");
    var pdftodate = formatDate(this.feeHeadWiseReportVm.toDate, 'dd-MM-yyyy', "en-US");
    const headerList = [];
    const fieldList = [];

    this.attributes.forEach(x => {
      headerList.push(x.name);
      fieldList.push(x.fieldName);
    });
    for (let i = 0; i < this.feeHeadwiseCollectionReportFilteredList.length; i++) {
      this.feeHeadwiseCollectionReportFilteredList[i]["index"] = i + 1;
    }
    const widthList = [];
    for (const item of headerList) {
      widthList.push('auto');
    }
    const defaultArray = [];
    for (var i = 0; i < this.feeHeadwiseCollectionReportFilteredList.length; i++) {
      let data = this.getFileRecordItem(i);
      defaultArray.push(data);
    }
    /*let data1 ={
      "index" : "",
      "payDate" : "",
        "receiptNo" : "",
        "admissionNo" :"",
        "studentName" : "",
        "class" : "",
        "batch" : "",
        "payMode" : "",
        "takenBy" : "",
        "paidStatus" : "",
        "rollNo" : "Total Amount",
        "paidAmount" :this.totalPaidAmount,
    }
    defaultArray.push(data1);*/
    const docDefinition = {
      pageSize: 'A4',
      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      // pageMargins: [ 40, 60, 40, 60 ],
      content: [
        {
          table: {
            widths: ['auto', '*'],
            body: [
              [{
                width: 100,
                height: 100,
                image: 'imageKey'
              }, {
                width: '*',
                alignment: 'center',
                stack: [
                  {
                    style: 'h1',
                    text: this.institution.institutionName
                  },
                  {
                    style: 'h2',
                    text: this.institution.address
                  },
                  {
                    style: 'h2',
                    text: this.currentAcademicYearName
                  },
                  {
                    style: 'h3',
                    text: 'FEE HEAD WISE COLLECTION REPORT FOR'
                  },
                  {
                    style: 'h3',
                    text: pdffromdate + '   to   ' + pdftodate
                  }
                ]
              }
              ]
            ]
          },
          layout: {
            hLineWidth: () => 0,
            vLineWidth: () => 0,
            paddingBottom: () => 5,
            paddingLeft: () => 5,
            paddingRight: () => 5
          },
        },
        {
          style: 'sectionHeader',
          table: {
            headerRows: 1,
            widths: widthList,
            body: [
              headerList,
              ...defaultArray.map((p) => {
                const temp = [];
                for (const y of fieldList) {
                  if (y === 'payDate') {
                   ;
                    if (p[y] == "" || p[y] == undefined) {
                      temp.push("");
                    } else {
                      temp.push(formatDateByMoment(p[y], 'YYYY-MM-DD HH:mm:ss'));
                    }
                  } else {
                    if (y === "paidStatus") {
                     ;
                      if (p[y] === true || p[y] === false) {
                        temp.push(p[y] === true ? "Cancelled" : "Paid");
                      } else {
                        temp.push("")
                      }
                    }
                    else {
                      temp.push(p[y]);
                    }
                  }
                }
                return temp;
              }),

              // {
              //   width: '*',
              //   alignment: 'center',
              //   stack: [
              //       {
              //           style: 'h1',
              //           text: "Total Paid Amount :" + this.totalPaidAmount
              //       }
              //     ]
              //   }
            ]
          }
        },
        // { text: 'Total Paid Amount: '+ this.totalPaidAmount, alignment: 'center',fontSize: 14 },
      ],
      images: {
        imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
        // via URL address, which can be referenced by this key
      },
      styles: {

        sectionHeader: {
          // bold: true,
          // decoration: 'underline',
          fontSize: 8,
          margin: [0, 10, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 13,
          bold: true,
          // fontWeight:800
        },
        h2: {
          margin: [0, 5, 0, 0],
          fontSize: 10,
          bold: true
        },

      }
    };
    pdfMake.createPdf(docDefinition).download('FEE HEAD WISE COLLECTION.pdf');
  }

  /** Fills the classes. */
  private getAllClassCourses() {
    this.classCourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }

  /** Fills the batches. */
  private getAllBatches(): void {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data;
    });
  }

  /** Fills the specialFeeList. */
  private getAllSpecialFeeList() {
    this.feeHeadService.getAll().subscribe((data: FeeHead[]) => {
      this.specialFeeList = data;
    });
  }

  /** Fills the filtered batches. */
  private getBatchesByClassId(classId?: number): void {
    this.filteredBatches = this.batches.filter((batch: Batch) => !classId || batch.classId === classId);
  }

  /** Validates the date range and updates the dateRangeIsValid status. */
  private validateDateRange(feeHeadWiseReportVm: FeeHeadWiseReportVm): void {
   ;
    let message: string;
    if (!feeHeadWiseReportVm.fromDate || !feeHeadWiseReportVm.toDate) {
      message = 'Please select date';
    }
    else {
      if (feeHeadWiseReportVm.fromDate >= feeHeadWiseReportVm.toDate) {
        message = 'FromDate is greater than ToDate';
      }
    }

    if (message) {
      this.dateRangeIsValid = false;
      this.toastr.warning(message, 'WARNING!', {
        timeOut: 3000
      });
    }
    else {
      this.dateRangeIsValid = true;
    }
  }

  /**
   * Calculates total payments according to feeHeadwiseCollectionReportFilteredList and
   * stores them in related variables.
   */
  private calculateTotalPayments(): void {
    this.sumPaidAmount = this.feeHeadwiseCollectionReportFilteredList.map(item =>
      parseFloat(item.paidAmount)).reduce((prev, curr) => prev + curr, 0);
    this.sumDisc = this.feeHeadwiseCollectionReportFilteredList.map(item =>
      parseFloat(item.discountAmount)).reduce((prev, curr) => prev + curr, 0);
    this.sumTotal = this.feeHeadwiseCollectionReportFilteredList.map(item =>
      parseFloat(item.totalAmount)).reduce((prev, curr) => prev + curr, 0);
  }

  private generateAttributes() {
    this.attributes = [
      {name: 'S NO', fieldName: 'index' },
      {name: 'FEE HEAD', fieldName: 'feeHead' },
      {name: 'DATE', fieldName: 'date' },
      {name: 'RECEIPT', fieldName: 'receipt' },
      {name: 'ADMSN NO', fieldName: 'admissionNo' },
      {name: 'STUDENT NAME', fieldName: 'studentName' },
      {name: 'CLASS', fieldName: 'class' },
      {name: 'BATCH', fieldName: 'batch' },
      {name: 'ROLL', fieldName: 'roll' },
      {name: 'TOTAL', fieldName: 'total' },
      {name: 'DIS', fieldName: 'dis' },
      {name: 'PAID', fieldName: 'paid' },
      {name: 'MODE', fieldName: 'mode' }
    ];
  }

  private getFileRecordItem(itemIndex: number): any {
    return {
      "index": itemIndex + 1,
      "feeHead": this.feeHeadwiseCollectionReportFilteredList[itemIndex].feeHead ?? "",
      "date": this.feeHeadwiseCollectionReportFilteredList[itemIndex].date ?
                formatDateByMoment(this.feeHeadwiseCollectionReportFilteredList[itemIndex].date) : "",
      "receipt": this.feeHeadwiseCollectionReportFilteredList[itemIndex].receiptNo ?? "",
      "admissionNo": this.feeHeadwiseCollectionReportFilteredList[itemIndex].admissionNo ?? "",
      "studentName": this.feeHeadwiseCollectionReportFilteredList[itemIndex].studentName ?? "",
      "class": this.feeHeadwiseCollectionReportFilteredList[itemIndex].class ?? "",
      "batch": this.feeHeadwiseCollectionReportFilteredList[itemIndex].batch ?? "",
      "roll": this.feeHeadwiseCollectionReportFilteredList[itemIndex].rollNo ?? "",
      "total": this.feeHeadwiseCollectionReportFilteredList[itemIndex].totalAmount ?? "",
      "dis": this.feeHeadwiseCollectionReportFilteredList[itemIndex].discountAmount ?? "",
      "paid": this.feeHeadwiseCollectionReportFilteredList[itemIndex].paidAmount ?? "",
      "mode": this.feeHeadwiseCollectionReportFilteredList[itemIndex].payMode ?? "",
    };
  }
}
