import { Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import {CoursePlannerService} from '../course-planner.service';
import { BatchVM } from '../model/Batch';
import { ClassVM } from '../model/ClassVM';
import { CoursePlannerVM } from '../model/CoursePlannerVM';
import { SubjectVM } from '../model/Subject';
import { Language } from '../model/Language';
import {ToastrService} from 'ngx-toastr';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DatePipe } from "@angular/common";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
// import { LoaderComponent } from 'src/app/shared/loader/loader.component';
import {  NgbDateParserFormatter, NgbDateStruct, NgbInputDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;


@Component({
  selector: 'app-course-planner',
  templateUrl: './course-planner.component.html',
  styleUrls: ['./course-planner.component.css'],
})


export class CoursePlannerComponent implements OnInit {

  public lstCourseList: CoursePlannerVM[] = new Array<CoursePlannerVM>();
  public lstClass: ClassVM[] = new Array<ClassVM>();
  public lstlanguage: Language[] = new Array<Language>();
  public lstBatch: BatchVM[] = new Array<BatchVM>();
  public lstSubject: SubjectVM[] = new Array<SubjectVM>();
  public modelVM: CoursePlannerVM = new CoursePlannerVM();
  submitted = false;
  formData = new FormData();
  ImageData:File;
  ImageDataCover:File;
  Id= 0;
  startDate: string;
  model2: string;
  minDate = {year: new Date().getFullYear(), month: new Date().getMonth()+1, day: new Date().getDate()};


  constructor(private coursePlannerService: CoursePlannerService,
      private toastr: ToastrService,
     private formBuilder: UntypedFormBuilder,
     private httpClient: HttpClient) { 
     }

  coursePlanForm: FormGroup;
  Thumblin: File;
  filePath: string;
  filePathCover: string;
  CourseInformation :string;
  model: NgbDateStruct;
  file : string; 
  fileCover :string;
  @ViewChild('file') fileUpload: ElementRef;
  @ViewChild('fileCover') fileCoverUpload: ElementRef;



  ngOnInit(): void {
    
    const datePipe = new DatePipe('en-Us');
    // this.minDate = datePipe.transform(new Date(), 'dd-MM-yyyy');
    // this.minDate = {year: 2017, month: 1, day: 1};
    // alert(this.minDate)
    // todayDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    // ;

    $('#startDate').datepicker().on('changeDate', (e) => {

      //;
      this.coursePlanForm.get('startDate').patchValue(e.date);
    });


    this.coursePlanForm = this.formBuilder.group({
      Id: 0,
      ClassId: [, Validators.required],
      BatchId: [, Validators.required],
      SubjectId: [, Validators.required],
      CourseTitle: ['', [Validators.required]],
      Board: ['IND', [Validators.required]],
      LanguageId: [, [Validators.required]],
      DurationMonth: [0, [Validators.required,Validators.min(0)]],
      DurationDay: [0, [Validators.required,Validators.min(0)]],
      // Cost:  [0, [Validators.required, Validators.pattern("^[0-9]*$")]],
      Cost: [0, [Validators.required,Validators.min(0)]],
      Status: [1, [Validators.required]],
      startDate: ['', [Validators.required]],
      courseInfo: [''],
      Informations:['']

      
 
      });
   this.GetAllSubject();

    this.getAllClass();
    this.getAlllanguage();

  }



  public getAllClass() {
    this.coursePlannerService.GetClass().subscribe((response) => {
      this.lstClass = response;
    });
  }

  public getAlllanguage() {
    this.coursePlannerService.GetLanguage().subscribe((response) => {
      this.lstlanguage = response;
    });
  }

  public getAllBatchByClass(k) {
    this.coursePlannerService.getAllBatchByClass(k).subscribe((response) => {
      this.lstBatch = response;
    });
  }

  public getAllCoursePlanByClass(k) {
    this.coursePlannerService.getAllCoursePlanByClass(k).subscribe((response) => {
      this.lstCourseList = response;
    });
  }
  public getAllCoursePlanByClassBatch(k,batchId) {
    this.coursePlannerService.getAllCoursePlanByClassBatch(k,batchId).subscribe((response) => {
      this.lstCourseList = response;
    });
  }
  public getAllCoursePlanByClassBatchSubject(k,batchId,subjectId) {
    this.coursePlannerService.getAllCoursePlanByClassBatchSubject(k,batchId,subjectId).subscribe((response) => {
      this.lstCourseList = response;
    });
  }
  
  public GetAllSubjectByBatchID(k) {
    this.coursePlannerService.GetAllSubjectByBatchID(k).subscribe((response) => {
      this.lstSubject = response;
    });
  }

  public GetAllSubject() {
    this.coursePlannerService.GetAllSubject().subscribe((response) => {
      this.lstSubject = response;
    });
  }

  public saveCoursePlan() {
   debugger;
   console.log(this.coursePlanForm.value);
    this.submitted = true;

    // this.load.show();
    
    //var date = new Date(this.formatDate(this.startDate));
    ;

    var sDate = this.coursePlanForm.get('startDate').value;



    //this.coursePlanForm.get('startDate').patchValue(date);


    ;
    if (this.coursePlanForm.valid) {
      if(this.Id == 0){
        
        
        this.coursePlanForm.get('startDate').patchValue( sDate.year +"-"+ (sDate.month > 2 ? sDate.month : "0"+ sDate.month) +"-"+(sDate.day > 2 ? sDate.day : "0"+ sDate.day) );
        // var sDate = this.coursePlanForm.get('startDate').value;

        this.coursePlannerService.SaveCoursePlan(this.coursePlanForm.value).subscribe((response) => {
          ;
           //this.ngOnInit();
          /* const formData = new FormData();
          debugger;
          if(this.ImageData != undefined){
            formData.append('file', this.ImageData, response.id+'.jpg'); 
            this.httpClient.post(environment.apiUrl + '/CoursePlanner/Upload?id='+response.id,formData, {reportProgress: true, observe: 'events'}).subscribe(event => {
              ;
              // if (event.type === HttpEventType.UploadProgress) {
              //   this.progress = Math.round(100 * event.loaded / event.total);
              // }
              // else if (event.type === HttpEventType.Response) {
              //   this.message = 'Upload success.';
              //   var imagePath = event.body;
              //   //console.log("doc",imagePath)
              //   this.employeeDocumentFilteredList[index].EmployeeFile =imagePath.toString();
              // }
           });
            //this.uploadFile(formData, response.id);
          }
          const formDataForCover=new FormData();
          if(this.ImageDataCover != undefined){
            formDataForCover.append(response.id+".jpg",this.ImageDataCover); 
            this.uploadFileCoverImage(formDataForCover, response.id);
          } */

          if(this.ImageData != undefined){
            const formDataImage = new FormData();
            formDataImage.append('file', this.ImageData, response.id+".jpg"); 
            this.uploadFile(formDataImage, response.id);
          }
          if(this.ImageDataCover != undefined){
            const formDataCoverImage = new FormData();
            formDataCoverImage.append('file', this.ImageDataCover, response.id+".jpg"); 
            this.uploadFileCoverImage(formDataCoverImage, response.id);
          }
      
          this.resetForm();
          this.filePath = "";
          this.filePathCover = "";
          //this.ImageData = "";
          //this.ImageDataCover = "";
          

          ;
          this.selectSubjects();

          // this.getCourses(k.ClassId);
        //  this.lstCourseList.push(k);
    
          this.toastr.success("Succesfully Added Course Plan", 'Success!',{timeOut: 1000});
        });
      }
      else{
    
        this.coursePlanForm.get('startDate').patchValue( sDate.year +"-"+ (sDate.month > 2 ? sDate.month : "0"+ sDate.month) +"-"+(sDate.day > 2 ? sDate.day : "0"+ sDate.day) );

        this.coursePlannerService.UpdateCoursePlan(this.coursePlanForm.value).subscribe((response) => {

          
          if(this.ImageData != undefined){
            const formDataImage = new FormData();
            formDataImage.append('file', this.ImageData, response.id+".jpg"); 
            this.uploadFile(formDataImage, response.id);
          }
          if(this.ImageDataCover != undefined){
            const formDataCoverImage = new FormData();
            formDataCoverImage.append('file', this.ImageDataCover, response.id+".jpg"); 
            this.uploadFileCoverImage(formDataCoverImage, response.id);
          }


        this.resetForm();
         this.selectSubjects();

          this.toastr.success("Succesfully Added Course Plan", 'Success!',{timeOut: 1000});
        });
      }
    } 
  
  }

  public uploadFile = (image, id) => {
    this.coursePlannerService.UploadPhoto(image, id).then(x=>console.log(x));
  }

  public uploadFileCoverImage = (image, id) => {
    this.coursePlannerService.UploadPhotoCoverImage(<FormData>image, id).then(x=>console.log(x));
  }


  public resetForm(){
    this.coursePlanForm = this.formBuilder.group({
      Id: 0,
       ClassId: [this.coursePlanForm.get('ClassId').value, Validators.required],
       BatchId: [this.coursePlanForm.get('BatchId').value, Validators.required],
       SubjectId: [this.coursePlanForm.get('SubjectId').value, Validators.required],
      CourseTitle: ['', [Validators.required]],
      Board: ['IND', [Validators.required]],
      LanguageId: [, [Validators.required]],
      DurationMonth: [0, [Validators.required]],
      DurationDay: [0, [Validators.required]],
      Cost: [0, [Validators.required,Validators.min(0)]],
      Status: [1, [Validators.required]],
      startDate: ['', [Validators.required]],
      Informations:['']
      });

      this.fileUpload.nativeElement.value="";
      this.fileCoverUpload.nativeElement.value="";

      this.file = "";
      this.fileCover ="";
      this.CourseInformation = "";
      this.filePath="";
      this.getAlllanguage();
      // this.getAllClass();
      this.Id= 0;
    //  this.lstCourseList = null;

    this.submitted = false;
  }

  public getClassId(){

    var ClassID = this.coursePlanForm.get('ClassId').value;
    if(ClassID>0){
      this.getAllBatchByClass(ClassID);
      this.getAllCoursePlanByClass(ClassID);

    }
  }

  public selectSubjects(){
    var BatchID = this.coursePlanForm.get('BatchId').value;
    var ClassID = this.coursePlanForm.get('ClassId').value;
    var SubjectId = this.coursePlanForm.get('SubjectId').value;

    if(ClassID>0){
      this.getAllCoursePlanByClassBatchSubject(ClassID,BatchID,SubjectId);
    }
  }


  public getCourses(ClassID){
    ;
    // var ClassID = this.coursePlanForm.get('ClassId').value;
    if(ClassID>0){
     
      this.getAllCoursePlanByClass(ClassID);

    }

  }


  public getBatchId(){
    var BatchID = this.coursePlanForm.get('BatchId').value;
    var ClassID = this.coursePlanForm.get('ClassId').value;

    if(BatchID>0){

       //this.getAllCoursePlanByClassBatch(ClassID,BatchID)

      //  this.GetAllSubjectByBatchID(BatchID);
    }
  }

  public saveCourse(){
   
    //this.saveCoursePlan(this.coursePlanForm.value);
  }

  
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
  

  

  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
    // this.load.show();

        this.coursePlannerService.delete(id).subscribe(res => {
          // this.load.ngOnInit();
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        this.resetForm();
        this.selectSubjects();


        });
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      );
      }
    });
  }

  updateForm(id: number) {
 
    this.getAlllanguage();
    this.GetAllSubject();


    this.coursePlannerService.getById(id).subscribe((data: CoursePlannerVM) => {
  
    // this.GetAllSubjectByBatchID(data.batchId);

    ;
    this.Id= data.id;
      
    this.coursePlanForm = this.formBuilder.group({
      Id: [data.id],
      ClassId: [data.classId, Validators.required],
      BatchId: [data.batchId, Validators.required],
      SubjectId: [data.subjectId, Validators.required],
      CourseTitle: [data.courseTitle, Validators.required],
      Board: [data.board, Validators.required],
      LanguageId: [data.languageId, Validators.required],
      DurationMonth: [data.durationMonth, Validators.required],
      DurationDay: [data.durationDay, Validators.required],
      Cost: [data.cost, Validators.required],
      Status: [data.status, Validators.required],
      CourseThumblineUrl : [data.courseThumblineUrl],
      startDate: [this.formatDate(data.startDate), Validators.required],
      Informations:[data.informations]
      }); 

  });
}
private formatDate(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();
  if (month.length < 2) {month = '0' + month; }
  if (day.length < 2) {day = '0' + day; }

  
  return ({ day: parseInt(day), month: parseInt(month), year: year });
  // return [day, month, year].join('-');

}




onFileChanged(files) {

  this.filePath= "";
  this.ImageData = <File>files[0];

  const reader = new FileReader();
  reader.onload = () => {
    this.filePath = reader.result as string;
  }
  reader.readAsDataURL(this.ImageData);
}



onFileChangedCoverImage(files) {
  this.filePathCover= "";
  this.ImageDataCover = <File>files[0];

  const reader = new FileReader();
  reader.onload = () => {
    this.filePathCover = reader.result as string;
  }
  reader.readAsDataURL(this.ImageDataCover);
}


get f() { return this.coursePlanForm.controls; }

// Only Integer Numbers
keyPressNumbers(event) {
  var charCode = (event.which) ? event.which : event.keyCode;
  
  // Only Numbers 0-9
  if ((charCode < 48 || charCode > 57)) {
    event.preventDefault();
    return false;
  } else {
    return true;
  }
}

 // Only Numbers with Decimals
 keyPressNumbersWithDecimal(event) {
  var charCode = (event.which) ? event.which : event.keyCode;
  if (charCode != 46 && charCode > 31
    && (charCode < 48 || charCode > 57)) {
    event.preventDefault();
    return false;
  }
  return true;
}

 public ValidDateModel(){
   ;
 }

}










/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
 @Injectable()
 export class CustomDateParserFormatter extends NgbDateParserFormatter {
 
   readonly DELIMITER = '-';
 
   parse(value: string): NgbDateStruct | null {
     if (value) {
       const date = value.split(this.DELIMITER);
       return {
         day : parseInt(date[0], 10),
         month : parseInt(date[1], 10),
         year : parseInt(date[2], 10)
       };
     }
     return null;
   }
 
   format(date: NgbDateStruct | null): string {
     return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
   }
 }
 

//  export class NgbdDatepickerAdapter {
 
//   //  model1: string;
//    model2: string;
//    model: NgbDateStruct;
//    constructor(private ngbCalendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>) {}
 
//    get today() {
//      return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
//    }
//  }