import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
//import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OutstandingDueService } from './outstandingduesummary.service';
import { ClassAndBatch, OutstandingDueClassWise, OutstandingDueStudentWise, OutStandingDueSummaryClassCourse, OutstandingDueSummaryReport,OutstandingDueFollowUpList } from './outstandingduesummary.model';
import { ClassCourse } from '../../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../../mastersettings/models/batch';
import { BatchService } from '../../../mastersettings/batch/batch.service';
import { data } from 'jquery';
import { DatePipe } from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import { ReportAttribute } from '../../../studentmanagement/report.model';
import { environment } from 'src/environments/environment';
import { SettingsService } from '../../../mastersettings/settings.service';
import { Academicyear } from '../../../mastersettings/models/academicyear';
import { FeeHead } from '../../feehead/feehead.model';
import { FeeHeadService } from '../../feehead/feehead.service';
import { TitleCasePipe } from '@angular/common';
import { OutstandingDueFollowUpDetails } from '../outstandingduereport/outstandingduereport.model';
import { Installment } from '../../installment/installment.model';
import { float } from 'html2canvas/dist/types/css/property-descriptors/float';
declare var $: any;
@Component({
  selector: 'app-outstandingduesummary',
  templateUrl: './outstandingduesummary.component.html',
  styleUrls: ['./outstandingduesummary.component.css']
})
export class OutstandingduesummaryComponent implements OnInit {
  StudentId: any;
  AcademicYearId: any;
  InstallmentId: any;
  Class: string;    
  FathersName: string;
  ContactNumber: string;
  TotalFollowup: string;
  PickedCall: string;  
  UnpickedCall: string;
  classDrp: string;
  statusDrp:string;
  batchDrp:string;
  feeHeadDrp:string;
  fromDate: string;
  toDate: string;
  dateValidation:boolean=false;
  installments: Installment[] = [];
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  institution: any;
  defaultArray = [];
  currentAcademicYearId: number;
  currentAcademicYearName = '';
  rowid: any;
  StudentName: string;
  DueAmount: any;
  showFeeDetail: boolean[] = [];
  classId: any;
  batchId: any;
  academicYearId: any;
  submitted = false;
  totalDueAmount: number;
  totalDueAmountBatch: number;
  dataCount: number;
  selectedClasses:string;
  studentDueFollowUpForm: UntypedFormGroup;
  academicYears: Academicyear[] = [];
  batches: Batch[] = [];
  classes: OutStandingDueSummaryClassCourse[] = [];
  tempclasses: OutStandingDueSummaryClassCourse[] = [];
  outstandingDueSummaryReportFilteredList: OutstandingDueSummaryReport[] = [];
  outstandingDueFollowUpList: OutstandingDueFollowUpList[] = [];
  outstandingDueFollowUpDetails: OutstandingDueFollowUpDetails = {} as OutstandingDueFollowUpDetails;
  classAndBachList: ClassAndBatch[] = [];
  outstandingDueClassWise: OutstandingDueClassWise[] = [];
  outStandingDueSummaryStudentWise: OutstandingDueStudentWise[] = [];
  outStandingDueSummaryStudentWiseFilterd: OutstandingDueStudentWise[] = [];
  outStandingDueSummaryReport: OutstandingDueSummaryReport[] = [];
  outStandingDueSummaryReportFilterd: OutstandingDueSummaryReport[] = [];
  feeHeads: FeeHead[] = [];
  feeHeadFilteredList: FeeHead[] = [];
  // loader:boolean = false;
  loader: any = false;
  
  constructor(
    public outstandingDueService: OutstandingDueService,
    public classCourseService: ClassCourseService,
    public fb: UntypedFormBuilder,
    public batchService: BatchService,
    public feeHeadService: FeeHeadService,
    private academicYearService: SettingsService,
    private toastr: ToastrService,
    private titlecasePipe: TitleCasePipe
  ) { }
  ListClassCourse() {
    this.classCourseService.getAll().subscribe((data: OutStandingDueSummaryClassCourse[]) => {
     ;
      this.classes = data;
      this.tempclasses=data;
      this.classes.forEach(x => {
        this.batchService.getAll().subscribe((bdata: Batch[]) => {
          const odata = bdata.filter((batch: Batch) => batch.classId.toString() === x.id.toString());
          x.batches = odata;
        });
      });
    });
  }

  ListFeeHead() {
    this.feeHeadService.getAll().subscribe((data: FeeHead[]) => {
      console.log(data);
      this.feeHeadFilteredList = this.feeHeads = data;
    });
  }
  resetForm(){
    debugger;
    this.studentDueFollowUpForm = this.fb.group({
      id: [0],
      studentId: [''],
      academicYearId: [''],
      installmentId: [''],
      isCallPicked: [true], // 02-Nov
      remarks: [''], // 02-Nov
      commitmentdate: [''],
      isNotify: ['false'],
      isActive: [true]
    });
    
  }
  createfollowp(){
    debugger;
    this.submitted = true;
    this.studentDueFollowUpForm.get('studentId').patchValue(this.StudentId);
    // this.leadFollowupForm.get("leadcode").patchValue(this.LeadID);
    // stop here if form is invalid
    if (this.studentDueFollowUpForm.invalid) {
      return;
    }

   
    if (this.studentDueFollowUpForm.invalid) {
      return;
    }


   ;
    if (this.studentDueFollowUpForm.get('id').value === 0) {
      this.outstandingDueService.create(this.studentDueFollowUpForm.value).subscribe(res => {
        // this.ListServices();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        this.onaddFollowUp(this.StudentId, 0, 0, 0, 0);
        // close your modal
        // this.closeModal();
      });
    }
  }
  onClassChecked(id: number, event: any) {
   ;
   this.loader=true;
    console.log(id);  
    //this.outStandingDueSummaryReport=[];
    this.outStandingDueSummaryStudentWise = this.outStandingDueSummaryStudentWiseFilterd=[];
    this.totalDueAmount = 0;
        this.totalDueAmountBatch = 0;
        this.dataCount=0; 
    //this.batchDrp = '0'
    if (event.target.checked) {
      if(this.selectedClasses!='' && this.selectedClasses!=undefined)
      {
        this.selectedClasses = this.selectedClasses +',' +id; 
      }else
      {
        this.selectedClasses =  id.toString(); 
      }      
    }else
    {
      (this.selectedClasses!=''&& this.selectedClasses!=undefined)
      {
        this.selectedClasses = this.selectedClasses.replace(','+id.toString(),'');
        this.selectedClasses = this.selectedClasses.replace(id.toString() ,'');
      }
    }

    // if(this.selectedClasses!='' && this.selectedClasses!=undefined)
    // {
      this.classDrp = this.selectedClasses;
      if(this.selectedClasses=="")
      {
        this.outStandingDueSummaryReport = []; 
        this.classDrp = 'All';
         this.batchDrp = '-1'         
      }
    //}
if(this.selectedClasses!='' && this.selectedClasses!=undefined)
    {
    let drpClass = "";
    let drpBatch = "-1";
    let drpFeeHeadId = "";
    let drpStatus = "";
    if (this.classDrp == 'All') {
      drpClass = '-1'
    } else {
      drpClass = this.classDrp;
    }
    if (this.feeHeadDrp == '0') {
      drpFeeHeadId = '-1'
    } else {
      drpFeeHeadId = this.feeHeadDrp;
    }
    if (this.statusDrp == 'All') {
      drpStatus = '-1'
    } else if(this.statusDrp == 'Active') {
      drpStatus = "1";
    }else
    {
      drpStatus = "0";
    }

    if(this.fromDate!=undefined && this.fromDate!='' && this.fromDate!=null && 
    this.toDate!=undefined && this.toDate!='' && this.toDate!=null){
      const fromDate = this.fromDate;
      const toDate = this.toDate;
      //console.log(drpBatch);
      let startDt: Date;
      let endDt: Date;
      const datePipe = new DatePipe('en-Us');
      var fdt=datePipe.transform(this.fromDate, 'yyyy-MM-dd');
      var tdt=datePipe.transform(this.toDate, 'yyyy-MM-dd');  

    this.outstandingDueService.getOutstandingDueSearchReport(drpStatus,drpClass,drpBatch,drpFeeHeadId,fdt,tdt)
    .subscribe((data: OutstandingDueSummaryReport[]) => {  
      if(data.length>0)
      {  
      this.outStandingDueSummaryReport = data;  

      this.totalDueAmount= this.outStandingDueSummaryReport.map(item =>
        parseFloat(item.totalDueAmount)).reduce((prev, curr) => prev + curr, 0);
      }else
      {       
        this.outStandingDueSummaryReport = [];    
        
      }
    });
 
  }
  this.loader=false;
}
    
  }
  currentAcademicYear() {
    this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.currentAcademicYearName = this.academicYears.find(x => x.id.toString() === data.id.toString()).name;
     
    });
  }
  listClassBatch() {
    this.classId = 54;
    this.outstandingDueService.getClassBatchList(this.classId).subscribe((data: ClassAndBatch[]) => {
      this.classAndBachList = data;
    });
  }
  generatePDF() {
   debugger;
    const pdffromdate = $('#dateFrom').val();
    const pdftodate = $('#dateTo').val();

    
    this.headerList = [];
    this.fieldList = [];

    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const widthList = [];
    for (const item of this.headerList) {
      widthList.push(100);
    }

    this.defaultArray = [];
    let totalAmount = 0;
    for (var i = 0; i < this.outstandingDueClassWise.length; i++) {
      let data = {
        "index": i + 1,        
        "studentName": this.outstandingDueClassWise[i].studentName == undefined ? "" : this.titlecasePipe.transform(this.outstandingDueClassWise[i].studentName),
        "class": this.outstandingDueClassWise[i].class == undefined ? "" : this.outstandingDueClassWise[i].class + " " + this.outstandingDueClassWise[i].batch,
        "batch": this.outstandingDueClassWise[i].batch == undefined ? "" : this.outstandingDueClassWise[i].batch,       
        "dueAmount": this.outstandingDueClassWise[i].totalDueAmount == undefined ? "" : this.outstandingDueClassWise[i].totalDueAmount,
      }
      this.defaultArray.push(data);
      totalAmount = totalAmount + parseFloat(this.outstandingDueClassWise[i].totalDueAmount);
    }
    let data1 = {
      "index": "",      
      //"studentName": "",
      "class": "",
      "batch":"",      
      "dueAmount":totalAmount,
    }
    this.defaultArray.push(data1);
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      content: [
        {
          table: {
            widths: ['auto', '*'],
            //widths: 600,
            body: [
              [{
                width: 100,
                height: 100,
                image: 'imageKey'
              }, {
                width: '*',
                alignment: 'center',
                stack: [
                  {
                    style: 'h1',
                    text: this.institution?.institutionName
                  },
                  {
                    style: 'h2',
                    text: this.institution?.address
                  },
                  {
                    style: 'h2',
                    text: this.currentAcademicYearName
                  },
                  {
                    style: 'h3',
                    text: 'OUTSTANDING DUE SUMMARY'
                  },
                  {
                    style: 'h3',
                    text: pdffromdate + '   to   ' + pdftodate
                  }
                ]
              }
              ]
            ]
          },
          layout: {
            hLineWidth: () => 0,
            vLineWidth: () => 0,
            paddingBottom: () => 3,
            paddingLeft: () => 5,
            paddingRight: () => 5
          },
        },
        {
          style: 'sectionHeader',
          table: {
            headerRows: 1,
            widths: widthList,
            autowidth:false,
            body: [
              this.headerList,
              ...this.defaultArray.map((p) => {
                const temp = [];
                for (const y of this.fieldList) {                  
                    temp.push(p[y]);                  
                }
                return temp;
              })
            ]
          }
        }
      ],
      images: {
        // imageKey: environment.imageUrl + this.institution.logoUrl // via URL address, which can be referenced by this key
        imageKey: this.institution?.logoUrl != null ? environment.imageUrl + this.institution?.logoUrl : environment.imageUrl + '/images/avatar-1.png'
      },
      styles: {
        sectionHeader: {
          fontSize: 8,
          margin: [0, 10, 0, 15],
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 13,
          bold: true,
        },
        h2: {
          margin: [0, 5, 0, 0],
          fontSize: 10,
          bold: true,
        },
        table:{
          width: 'fixed'
        }
      }
    };
    pdfMake.createPdf(docDefinition).download('OUTSTANDING DUE SUMMARY COLLECTION.pdf');
  }

  generateExcel() {
    this.headerList = [];
    this.fieldList = [];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    this.defaultArray = [];
    let totalAmount = 0;
    for (var i = 0; i < this.outstandingDueClassWise.length; i++) {
      let data = {
        "index": i + 1,        
        "studentName": this.outstandingDueClassWise[i].studentName == undefined ? "" : this.outstandingDueClassWise[i].studentName,
        "class": this.outstandingDueClassWise[i].class == undefined ? "" : this.outstandingDueClassWise[i].class,
        "batch": this.outstandingDueClassWise[i].batch == undefined ? "" : this.outstandingDueClassWise[i].batch,        
        "dueAmount": this.outstandingDueClassWise[i].totalDueAmount == undefined ? "" : this.outstandingDueClassWise[i].totalDueAmount,
      }
      this.defaultArray.push(data);
      totalAmount = totalAmount + Number(this.outstandingDueClassWise[i].totalDueAmount);
    }
    let data1 = {
      "index": "",      
      "studentName": "",
      "class": "",
      "batch": "",      
      "dueAmount": totalAmount,
    }
    this.defaultArray.push(data1);
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('OUTSTANDING DUE SUMMARY');

    // const imageId2 = workbook.addImage({
    //   filename: 'images/avatar-1.png',
    //   extension: 'png',
    // });
    // worksheet.addImage(imageId2, 'A1:B1');

    const instName = worksheet.addRow([this.institution.institutionName]);
    instName.eachCell(function (cell, colNum) {
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 10
      }
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'start'
      }
    })
    instName.height = 25;
    const instAddress = worksheet.addRow([this.institution.address]);
    instAddress.eachCell(function (cell, colNum) {
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 9
      }
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'start'
      }
    })
    instAddress.height = 20;

    const instSession = worksheet.addRow([this.currentAcademicYear.name]);
    instSession.eachCell(function (cell, colNum) {
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 10
      }
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'start'
      }
    })
    instSession.height = 20;

    worksheet.addRow([]);
    const titleRow = worksheet.addRow(['OUTSTANDING DUE Summary']);
    const fromDate = $('#dateFrom').val();
    const toDate = $('#dateTo').val();
    worksheet.addRow([`${fromDate} to ${toDate}`]);
    const headerRow = worksheet.addRow(this.headerList);
    for (var i = 0; i < this.defaultArray.length; i++)
      this.defaultArray[i]["index"] = i + 1;
    for (const x1 of this.defaultArray) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'payDate') {
          if (x1[y] == "" || x1[y] == undefined) {
            temp.push("");
          } else {
            temp.push(this.formatDate(x1[y], 'dd-MM-yyyy'));
          }
        } else {
          if (y === "paidStatus") {
            if (x1[y] === true || x1[y] === false) {
              temp.push(x1[y] === true ? "Cancelled" : "Paid");
            } else {
              temp.push("")
            }
          }
          else {
            temp.push(x1[y]);
          }
        }
      }
      worksheet.addRow(temp);
    }
    const fname = 'OUTSTANDING DUE  SUMMARY';

    worksheet.eachRow((row, rowNumber) => {

      row.eachCell((cell, colNumber) => {
        if ([1, 2, 3, 4, 5, 6].includes(rowNumber)) {
        } else {
          // Set border of each cell 
          cell.width = 180;
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        }

      })
      //Commit the changed row to the stream
      row.commit();
    });

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }
  generateAttributes() {
    this.attributes = [
      { name: 'SNO', fieldName: 'index' },      
      { name: 'CLASS', fieldName: 'class' },
      { name: 'BATCH', fieldName: 'batch' },      
      { name: 'DUE', fieldName: 'dueAmount' }
    ];
  }
  getClassBatchWiseDue() {
    this.classId = 0;
    this.batchId = 0;
    this.academicYearId = 0;
    this.outstandingDueService.getClassBatchWiseDue(this.classId, this.batchId, this.academicYearId)
    .subscribe((data: OutstandingDueClassWise[]) => {
      this.outstandingDueClassWise = data;
    });
  }
  onClassSelected(value: string) {
   ;
    this.classDrp = value;
    this.classId = value;
    if(value=='0'){
      this.classes=this.tempclasses;
    }else{
      this.classes=this.tempclasses.filter((x:any)=>x.classCourseName==value);

    }

  } 
  tdtchange(event: any) {
    var tdt = event.target.value
    if (tdt == null || tdt == undefined || tdt == '') {
      this.dateValidation = false;
      this.toDate = "";
    }
  }
  fdtchange(event: any) {
    var tdt = event.target.value
    if (tdt == null || tdt == undefined || tdt == '') {
      this.dateValidation = false;
      this.fromDate = "";
    }
  }
  
  onSearch(){
   ;
   this.loader=true;
    this.outStandingDueSummaryReport =[];
    //this.outstandingDueClassWise=[];
    this.outStandingDueSummaryStudentWise = this.outStandingDueSummaryStudentWiseFilterd=[];
    //debugger;
    let drpClass = "";
    let drpBatch = "-1";
    let drpFeeHeadId = "";
    let drpStatus = "";
    if (this.classDrp == 'All') {
      drpClass = '-1'
    } else {
      drpClass = this.classDrp;
    }
    if (this.feeHeadDrp == '0') {
      drpFeeHeadId = '-1'
    } else {
      drpFeeHeadId = this.feeHeadDrp;
    }
    if (this.statusDrp == 'All') {
      drpStatus = '-1'
    } else if(this.statusDrp == 'Active') {
      drpStatus = "1";
    }else
    {
      drpStatus = "0";
    }
    //debugger;
    if(this.fromDate!=undefined && this.fromDate!='' && this.fromDate!=null && 
    this.toDate!=undefined && this.toDate!='' && this.toDate!=null)
    {
      const fromDate = this.fromDate;
      const toDate = this.toDate;
      //console.log(drpBatch);
      let startDt: Date;
      let endDt: Date;
      const datePipe = new DatePipe('en-Us');
      var fdt=datePipe.transform(this.fromDate, 'yyyy-MM-dd');
      var tdt=datePipe.transform(this.toDate, 'yyyy-MM-dd');    
    
    //  ;
      this.outstandingDueService.getOutstandingDueSearchReport(drpStatus,drpClass,drpBatch,drpFeeHeadId, fdt,tdt)
    .subscribe((data: OutstandingDueClassWise[]) => {
   ;
    if(data.length>0)
    {
      this.outstandingDueClassWise = data;
      this.loader = false;
      this.classes.forEach(x => {
        this.selectedClasses='';
        x.checked=false;
        var amountD:number=0;      
          const odata = this.outstandingDueClassWise.filter(function(duesumarry){
            if(duesumarry.classId ==x.id.toString()){
              x.totalDueAmount= amountD + parseInt(duesumarry.totalDueAmount);
              amountD = x.totalDueAmount;
            }
          })         
      });
    }else
    {
      this.outstandingDueClassWise = [];
    }     
    });
  
  }     
    else{
      if(this.fromDate==undefined || this.fromDate=='' || this.fromDate!=null ||
      this.toDate==undefined || this.toDate=='' || this.toDate==null){
        this.toastr.warning('Please select date', 'WARNING!', {
          timeOut: 3000
        });
      }else{
        if(this.fromDate>=this.toDate){
          this.toastr.warning('FromDate is less than ToDate', 'WARNING!', {
            timeOut: 3000
          });
        }else{
          this.toastr.warning('ToDate is greater than FromDate', 'WARNING!', {
            timeOut: 3000
          });
        }
      }
     
    }
  }
  private formatDate(date :Date, format: string) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    switch(format){
      case 'dd-MM-yyyy':
        return [day, month, year].join('-');
        break;
      case 'MM-dd-yyyy':
        return [month, day, year].join('-');
        break;
      default:
        return [day, month, year].join('-');
        break;
    }
  }
  onClassItemSelected(classId:string,batchId:string)
  {
   ;
   this.loader=true;
    this.classDrp = classId;
    this.batchDrp = batchId;

    let drpClass = "";
    let drpBatch = batchId;
    let drpFeeHeadId = "";
    let drpStatus = "";
    if (this.classDrp == 'All') {
      drpClass = '-1'
    } else {
      drpClass = this.classDrp;
    }
    if (this.feeHeadDrp == '0') {
      drpFeeHeadId = '-1'
    } else {
      drpFeeHeadId = this.feeHeadDrp;
    }
    if (this.statusDrp == 'All') {
      drpStatus = '-1'
    } else if(this.statusDrp == 'Active') {
      drpStatus = "1";
    }else
    {
      drpStatus = "0";
    }
   ;
    if(this.fromDate!=undefined && this.fromDate!='' && this.fromDate!=null && 
    this.toDate!=undefined && this.toDate!='' && this.toDate!=null){
      const fromDate = this.fromDate;
      const toDate = this.toDate;
      console.log(drpBatch);
      let startDt: Date;
      let endDt: Date;
      const datePipe = new DatePipe('en-Us');
      var fdt=datePipe.transform(this.fromDate, 'yyyy-MM-dd');
      var tdt=datePipe.transform(this.toDate, 'yyyy-MM-dd');    
    
    //  ;
      this.outstandingDueService.getOutstandingDueSearchReportByClassId(drpStatus,drpClass,drpBatch,drpFeeHeadId,fdt,tdt)
    .subscribe((data: OutstandingDueStudentWise[]) => {
   ;
      this.outStandingDueSummaryStudentWise = this.outStandingDueSummaryStudentWiseFilterd = data;          
      this.totalDueAmountBatch = this.outStandingDueSummaryStudentWiseFilterd.map(item =>
        parseFloat(item.totalDueAmount)).reduce((prev, curr) => prev + curr, 0);
     this.dataCount = this.outStandingDueSummaryStudentWiseFilterd.length;
    });

    
  }
  this.loader=false;
  }
  
  viewInstallmentDetails(admissionId, classn,batchId, fromdate, todate) {
    this.rowid = 0;
    this.DueAmount = 0;
    console.log(admissionId);
    console.log(this.classes);
    console.log(classn);
   ;
    const classId = this.classes.filter((x) => x.classCourseName === classn)[0].id;
    
    this.outstandingDueService.getOutstandingDueFollowUpDetails(admissionId, 0, 0)
      .subscribe((data1: OutstandingDueFollowUpDetails) => {
       ;
        //this.DueAmount = data1.dueAmount; 
        this.StudentName = data1.studentName;
      });
    const datePipe = new DatePipe('en-Us');
    var fdt = datePipe.transform(this.fromDate, 'yyyy-MM-dd');
    var tdt = datePipe.transform(this.toDate, 'yyyy-MM-dd');
    // this.outstandingDueService.getFeePayment(admissionId, fdt, tdt).subscribe((inst: Installment[]) => {
    //   console.log(inst);
    //  ;
    //   inst.forEach((x, y) => {
    //     this.outstandingDueService.getByInstallmentId(x.id, classId, admissionId).subscribe((fHead: FeeHead[]) => {
    //       // this.feeheads = fHead;
    //      ;
    //       let feeamount = [];
    //       if (x.fineAmount > 0) {
    //         feeamount.push({
    //           id: x.id,
    //           feeHeadName: "Fine Amount",
    //           amount: x.fineAmount
    //         })
    //         fHead.push(feeamount[0]);

    //       }
    //       x.installmentFeeHead = fHead;
    //     });
    //   });

    //   this.installments = inst;
    //  ;
    //   for (var i = 0; i < this.installments.length; i++) {
    //     this.DueAmount = this.DueAmount + this.installments[i].amount + this.installments[i].fineAmount

    //   }

    //   console.log(this.installments);
    // });
    this.outstandingDueService.getFeePayment(admissionId, fdt, tdt).subscribe((inst: Installment[]) => {
      inst.filter(x => x.isWaved == false).forEach((x, y) => {
        this.outstandingDueService.getByInstallmentId(x.id, classId, batchId, admissionId).subscribe((fHead: FeeHead[]) => {
          let feeamount = [];
          if (x.fineAmount > 0) {
            feeamount.push({
              id: x.id,
              feeHeadName: "Fine Amount",
              amount: x.fineAmount
            })
            fHead.push(feeamount[0]);
          }
          x.installmentFeeHead = fHead; 
          x.amount = null;
          x.installmentFeeHead.forEach((y)=>{
            this.DueAmount+=y.amount;
            x.amount+=y.amount;
          })
        });
      });
      this.installments = inst;
    });
  }

  checkAllCheckBox(ev) {
    this.classes.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.classes.every(p => p.checked);
  }

  onDateWise() {
    alert('Date wise filter')
    //getOutStandingDueSummaryStudentWise()
  }
  onSendSMS() {
    alert('in progress');
  }


  ngOnInit(): void {
    this.ListClassCourse();
    this.ListFeeHead();
    this.generateAttributes();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.statusDrp = 'All';
    this.feeHeadDrp = '0';
    this.studentDueFollowUpForm = this.fb.group({
      id: [0],
      studentId: [''],
      academicYearId: ['0'],
      installmentId: ['0'],
      isCallPicked: [true], // 02-Nov
      remarks: [''], // 02-Nov      
      commitmentdate: [''],

      isNotify: [''],
      isActive: [true]
    });
    

    const self = this;
    this.statusDrp = 'All';
    this.batchDrp = '-1';
    this.classDrp = 'All';
    this.feeHeadDrp = '0';
   
    this.feeHeadDrp = '0';
    this.totalDueAmount = 0;
    this.totalDueAmountBatch = 0;
    this.dataCount=0; 
   ;
   $('#dateFrom').val(this.formatDate(new Date, 'dd-MM-yyyy'));
   this.fromDate = this.formatDate(new Date, 'dd-MM-yyyy');
   $('#dateTo').val(this.formatDate(new Date, 'dd-MM-yyyy'));
   this.toDate = this.formatDate(new Date, 'dd-MM-yyyy');
   $('#dateFrom').datepicker().on('changeDate', (e) => {
     self.fromDate = e.date;
     this.OnDateFromChangeEvent();
   });
   $('#dateTo').datepicker().on('changeDate', (e) => {
     self.toDate = e.date;      
     this.OnDateToChangeEvent();
   });
    var date = new Date(), year = date.getFullYear(), month = date.getMonth();
    $('#dateFrom').datepicker('update',this.formatDate(new Date(year,month,1), 'dd-MM-yyyy'));
    this.fromDate = this.formatDate(new Date(year,month,1), 'MM-dd-yyyy');
    $('#dateTo').datepicker('update',this.formatDate(new Date(year,month+1,0),'dd-MM-yyyy'));
    this.toDate = this.formatDate(new Date(year,month+1,0), 'MM-dd-yyyy');
    this.OnDateFromChangeEvent();
    this.OnDateToChangeEvent();
  }
  
  OnDateFromChangeEvent() {
    if (this.toDate != undefined && this.toDate != '' && this.toDate != null) {
       if (this.fromDate >= this.toDate) {
         this.dateValidation = false;
         this.toastr.warning('FromDate is less than ToDate', 'WARNING!', {
           timeOut: 3000
         });
       } else {
         this.dateValidation = true;
       }
     } else {
       this.dateValidation = false;
     }
  }

  OnDateToChangeEvent() {
   
      if (this.fromDate != undefined && this.fromDate != '' && this.fromDate != null) {
        if (this.toDate >= this.fromDate) {
          this.dateValidation = true;
        } else {
          this.dateValidation = false;
          this.toastr.warning('Todate is greater than From date', 'WARNING!', {
            timeOut: 3000
          });
        }
      } else {
        this.dateValidation = false;
      }
  }

  onStatusSelected(value){
   ;
     this.statusDrp = value;
  }

  onFeeHeadSelected(value)
  {
    this.totalDueAmount=0;
    this.totalDueAmountBatch =0;
    this.feeHeadDrp = value;
    this.classDrp = 'All';
    //this.batchDrp = value;
    this.classes.forEach(x => {
      x.checked=false;
      x.totalDueAmount=0;        
    });
    this.onSearch();
  }


  showFee(value: number, id: number) {
    // this.feeHeads = [];
   ;
    if (this.rowid == value) {
      this.rowid = 0;
      this.showFeeDetail[id] = !this.showFeeDetail[id];
    } else {
      this.rowid = 0;
      this.rowid = value;
      this.showFeeDetail[id] = !this.showFeeDetail[id];
    }
  }

  onaddFollowUp(studentId, academicYearId, installmentId, tamount, fineamount) {
    debugger;
    this.StudentId = studentId;
    this.outstandingDueService.getOutstandingDueFollowUpDetails(studentId, 0, 0)
      .subscribe((data: OutstandingDueFollowUpDetails) => {
        console.log(data);
       ;
        // outstandingDueFollowUpDetails:OutstandingDueFollowUpDetails[]=[];
        // outstandingDueFollowUpList:OutstandingDueFollowUpList[]=[];
        this.outstandingDueFollowUpDetails = data;
        this.StudentId = data.studentId;
        this.AcademicYearId = data.academicYearId;
        this.InstallmentId = data.installmentId;
        this.StudentName = data.studentName;
        this.FathersName = data.fathersName;
        this.Class = data.class;
        this.ContactNumber = data.contactNumber;
        //this.TotalFollowup = data.totalFollowup; //hsnc
        this.PickedCall = data.pickedCall;
        this.UnpickedCall = data.unpickedCall;
        
        this.DueAmount = tamount + fineamount;
        // const self = this;
        data.objOutstandingDueFollowUpList.forEach((item, index) => {
          this.outstandingDueFollowUpList[index] = item;
        });
      });
  }
  
  resetAll() {
    debugger;
    const self = this;
    this.statusDrp = 'All';    
    this.feeHeadDrp = '0';
    var date = new Date();
    // this.fromDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    // this.dateTos = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    $('#dateFrom').datepicker('setDate', new Date(date.getFullYear(), date.getMonth(), 1));
    $('#dateFrom').datepicker().on('changeDate', (e) => {
      self.fromDate = e.date;
      this.OnDateFromChangeEvent();
    });

    $('#dateTo').datepicker('setDate', new Date(date.getFullYear(), date.getMonth(), 2));
    $('#dateTo').datepicker().on('changeDate', (e) => {
      self.toDate = e.date;
      this.OnDateToChangeEvent();
    });
  }
  CSSApllyinstalmentTable(installment:any){  
    if(installment.isWaved==true){
     return "bg-danger text-white"
   }   
 }
}


