import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Circular } from './circular.model';
import { CircularService } from './circular.service';
import { Template, TemplateType } from './../configuration/templatemanager/template.model';
import { TemplateService } from './../configuration/templatemanager/template.service';
import { ClassCourse } from '../../academic/mastersettings/models/classcourse';
import { ClassCourseService } from '../../academic/mastersettings/classcourse/classcourse.service';
import { Batch } from '../../academic/mastersettings/models/batch';
import { BatchService } from '../../academic/mastersettings/batch/batch.service';
import { Department } from '../../OperationActivity/hrmspayroll/master/models/departement.model';
import { Employeeadmission } from '../../OperationActivity/hrmspayroll/hrms/models/employeeadmission.model';

@Component({
  selector: 'app-circularmanager',
  templateUrl: './circularmanager.component.html',
  styleUrls: ['./circularmanager.component.css']
})
export class CircularmanagerComponent implements OnInit {

  circularForm: UntypedFormGroup;
  circulars: Circular[] = [];
  circularFilteredList: Circular[] = [];
  searchFilteredList: Circular[] = [];
  circularTypes: TemplateType[] = [];
  templates: Template[] = [];
  alltemplates: Template[] = [];
  submitted = false;
  channeltypes = '';
  circular: Circular = {} as Circular;
  classes: ClassCourse[] = [];
  resClassIds: any[] = [];
  resChannelServices: any[] = [];
  employees: Employeeadmission[];
  employeefilteredlist: Employeeadmission[] = [];
  filteredemployeelist: Employeeadmission[] = [];
  departmentList: Department[] = [];
  departmentListPrimary: Department[] = [];
  selectedClassIds = '';
  channelSelection = [false, true, true, false];
  circularType = '';
  template = '';
  circularBody = '';
  showStudent = false;
  showEmployee = false;

  constructor(
    public circularService: CircularService,
    public templateService: TemplateService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  ListCircular() {
    this.circularService.getAll().subscribe((data: Circular[]) => {
      this.circularFilteredList = this.circulars = data;
    });
  }

  ListTemplates() {
    this.templateService.getAll().subscribe((data: Template[]) => {
      this.alltemplates = data;
  });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
      this.classes.forEach(x => {
        this.batchService.getAll().subscribe((bdata: Batch[]) => {
          const odata = bdata.filter((batch: Batch) => batch.classId.toString().includes(x.id.toString()));
          x.batches = odata;
        });
      });
    });
  }

  ListCircularType() {
    this.templateService.getAllTemplateType().subscribe((data: TemplateType[]) => {
      this.circularTypes = data;
    });
  }

  setCommunicationChannel(channel: string) {
    if (this.channeltypes.includes(channel)) {
      this.removeCommunicationChannel(channel); // remove the channel on unchecking
    } else {
      this.channeltypes = (this.channeltypes === '' ) ? channel : this.channeltypes.concat(' | ', channel);
    }

    console.log(this.channeltypes);
  }

  removeCommunicationChannel(channel: string) {
    this.channeltypes = this.channeltypes.replace(' | ' + channel, ''); // for multiple selection
    this.channeltypes = this.channeltypes.replace(channel, ''); // only one selection
  }

  onAudienceChange(value: string) {
    if (value === 'Student') {
      this.showStudent = true;
      this.showEmployee = false;
    } else if (value === 'Employee') {
      this.showStudent = false;
      this.showEmployee = true;
    } else {
      this.showStudent = false;
      this.showEmployee = false;
    }
  }

  onCircularTypeChange(value: number) {
    this.templateService.getAll().subscribe((data: Template[]) => {
        this.templates = data.filter((template: Template) => {
          return template.templateTypeId.toString() === value.toString();
        });
        console.log(this.templates);
    });
  }

  onTemplateChange(id: number) {
    console.log(id);
    // this.circularForm.patchValue({templateId:id.toString()});
    this.templateService.getById(id).subscribe((data: Template) => {
      console.log(data);
      this.circularForm.get('circularBody').patchValue(data.templateBody);
    });
  }

  checkAllCheckBox(ev) {
    this.classes.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.classes.every(p => p.checked);
  }

  getAllDepartmentDetails() {
    this.circularService.getAllDepartments().subscribe((data: Department[]) => {
      this.departmentList = this.departmentListPrimary = data; // this.findCurrentStudentCount(data);
      this.departmentList.forEach((x, i) => {
        const totalEmployees = this.employees.filter((admission: Employeeadmission) =>
          admission.departmentId.toString() === x.id.toString()).length;
          // admission.academicYearId.toString() === this.academicYear.id.toString()).length;
        x.totalEmployees = totalEmployees;
    });
    });
  }

  listAllEmployees() {
    this.circularService.getAllEmployees().subscribe((data: Employeeadmission[]) => {
      this.employees = data;
      this.getAllDepartmentDetails();
    });
  }

  checkAllDepartmentCheckBox(ev) {
    this.departmentList.forEach(x => x.checked = ev.target.checked);
  }

  isAllDepartmentCheckBoxChecked() {
    return this.departmentList.every(p => p.checked);
  }

  onCheckDepartment(evnt: any, departmentId: any) {
    // if (evnt.target.checked) { // on checked
    //   this.filterEmployeeList(departmentId);
    // } else {// on unchecked
    //   this.removeFromfilteredEmployeeList(departmentId);
    // }
  }

  showCircularManager(id: number) {
    this.circularService.getById(id).subscribe((data: Circular) => {
      console.log(data);
      this.circular = data;
      this.circularBody = data.circularBody;
      this.resChannelServices = data.channelService.split(' | ');
      this.circularTypes.forEach((x) => {
        if ( x.id.toString() === data.circularTypeId) {
          this.circularType = x.name;
        }
      });
      this.alltemplates.forEach((x) => {
        if ( x.id.toString() === data.templateId) {
          this.template = x.title;
        }
      });
      // this.channeltypes = data.channelService;
    });
  }

  // onFeeTypeChanged(value: any) {
  //   if (value === 'All') {
  //    this.searchFilteredList = this.feeHeadFilteredList = this.feeHeads;
  //   } else {
  //     this.searchFilteredList = this.feeHeadFilteredList = this.feeHeads.filter((feehead: FeeHead) => {
  //       return feehead.feeType.toLocaleLowerCase() === value.toLocaleLowerCase();
  //     });
  //   }
  // }

  // onKeyUpEvent(event: any) {
  //   this.filter(event.target.value);
  // }
  // filter(keyword: any) {
  //   if (this.searchFilteredList.length > 0) {
  //     if (keyword === '') {
  //       this.searchFilteredList = this.feeHeads;
  //     } else {
  //       this.searchFilteredList = this.feeHeads
  //         .filter((feehead: FeeHead) => feehead.feeHeadName.toLocaleLowerCase()
  //           .includes(keyword.toLocaleLowerCase()) ||
  //           feehead.feeType.toLocaleLowerCase()
  //             .includes(keyword.toLocaleLowerCase())
  //         );
  //     }
  //   } else {
  //     if (keyword === '') {
  //       this.feeHeadFilteredList = this.feeHeads;
  //     } else {
  //       this.feeHeadFilteredList = this.feeHeads
  //         .filter((feehead: FeeHead) => feehead.feeHeadName.toLocaleLowerCase()
  //           .includes(keyword.toLocaleLowerCase()) ||
  //           feehead.feeType.toLocaleLowerCase()
  //             .includes(keyword.toLocaleLowerCase())
  //         );
  //     }
  //   }
  // }

  get totalRows(): number {
    return this.circularFilteredList.length;
  }
  get f() { return this.circularForm.controls; }
  resetForm() {
    this.submitted = false;
    this.classes.forEach(x => x.checked = false);
    this.circularForm = this.fb.group({
      id: [0],
      circularTypeId: ['', Validators.required],
      templateId: ['', Validators.required],
      classIds: [''],
      audience: ['', Validators.required],
      channelService: ['', Validators.required],
      isAutoSendAfter: [false],
      afterDays: [''],
      afterSendTime: [''],
      isOnDate: [false],
      onSendTime: [''],
      isAutoSendBefore: [false],
      beforeDays: [''],
      beforeSendTime: [''],
      isRepeat: [''],
      circularBody: [''],
      status: ['true', Validators.required]
    });
  }
  submitForm() {
    this.submitted = true;
    console.log( this.channeltypes);
    this.circularForm.patchValue({channelService: this.channeltypes});  // update the form control value with channel local variable
    this.selectedClassIds = '';
    let k = 1;
    if (this.showStudent) {
      const totalSelected = this.classes.filter(x => x.checked).length;
      this.classes.forEach((x, i) => {
        if (x.checked) {
          if (totalSelected !== k) {
            this.selectedClassIds += x.id.toString() + '|';
          } else {
            this.selectedClassIds += x.id.toString();
          }
          k++;
        }
      });
    } else if (this.showEmployee) {
      const totalSelected = this.departmentList.filter(x => x.checked).length;
      this.departmentList.forEach((x, i) => {
        if (x.checked) {
          if (totalSelected !== k) {
            this.selectedClassIds += x.id.toString() + '|';
          } else {
            this.selectedClassIds += x.id.toString();
          }
          k++;
        }
      });
    }
    this.circularForm.get('classIds').patchValue(this.selectedClassIds);
    console.log('Form submitted!');
    console.log(this.circularForm.value);
    console.log(this.circularForm.get('isAutoSendAfter').value);
    if (this.circularForm.get('isAutoSendAfter').value === '' && this.circularForm.get('isOnDate').value === '' &&
       this.circularForm.get('isAutoSendBefore').value === '') {
       this.toastr.warning('Please select one Auto Sender', 'Warning!', {
          timeOut: 3000
       });
       return;
    }

    if (this.circularForm.get('isAutoSendAfter').value) {
      if (this.circularForm.get('afterDays').value === '') {
        this.toastr.warning('Please enter days', 'Warning!', {
          timeOut: 3000
        });
        return;
      }
      if (this.circularForm.get('afterSendTime').value === '') {
        this.toastr.warning('Please enter time', 'Warning!', {
          timeOut: 3000
       });
        return;
      }
    }

    if (this.circularForm.get('isOnDate').value) {
      if (this.circularForm.get('onSendTime').value === '') {
        this.toastr.warning('Please enter time', 'Warning!', {
          timeOut: 3000
       });
        return;
      }
    }

    if (this.circularForm.get('isAutoSendBefore').value) {
      if (this.circularForm.get('beforeDays').value === '') {
        this.toastr.warning('Please enter Days', 'Warning!', {
          timeOut: 3000
       });
        return;
      }
      if (this.circularForm.get('beforeSendTime').value === '') {
        this.toastr.warning('Please enter time', 'Warning!', {
          timeOut: 3000
       });
        return;
      }
    }

    if (!this.circularForm.get('channelService').valid) {
      this.toastr.warning('Please select any Communication Channel', 'Warning!', {
        timeOut: 3000
      });
      return;
    }

    if (!this.circularForm.get('isRepeat').valid) {
      this.toastr.warning('Please choose repeat status', 'Warning!', {
        timeOut: 3000
      });
      return;
    }
        // stop here if form is invalid
    if (this.circularForm.invalid) {
        console.log('Form submitted!- but form is invalid');
        return;
    }

    if (this.circularForm.get('id').value === 0) {
        this.circularService.create(this.circularForm.value).subscribe(res => {
          console.log('Circular created!');
          this.ListCircular();
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
        } else {
          this.circularService.update(this.circularForm.get('id').value, this.circularForm.value).subscribe(res => {
            console.log('Circular updated!');
            this.ListCircular();
            this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submitted = false;
            this.resetForm();
          });
    }
      }
      updateForm(id: number) {
        this.circularService.getById(id).subscribe((data: Circular) => {
          console.log(data);
          this.resClassIds = data.classIds.split('|');
          this.classes.forEach(x => {
            for (let i = 0; i < this.resClassIds.length; i++) {
              if (x.id.toString() === this.resClassIds[i]) {
                x.checked = true;
              }
            }
          });
          this.onCircularTypeChange(Number(data.circularTypeId));
          this.channeltypes = data.channelService;
          this.circularForm = this.fb.group({
            id: [data.id],
            circularTypeId: [data.circularTypeId, Validators.required],
            templateId: [data.templateId, Validators.required],
            classIds: [data.classIds],
            audience: [data.audience, Validators.required],
            channelService: [data.channelService, Validators.required],
            isAutoSendAfter: [data.isAutoSendAfter],
            afterDays: [data.afterDays],
            // if null , assign "" - null will cause error in ConverToModel()
            afterSendTime: [data.afterSendTime == null ? '' : data.afterSendTime],
            isOnDate: [data.isOnDate],
            onSendTime: [data.onSendTime == null ? '' : data.onSendTime], // if null , assign "" - null will cause error in ConverToModel()
            isAutoSendBefore: [data.isAutoSendBefore],
            beforeDays: [data.beforeDays],
            // if null , assign "" - null will cause error in ConverToModel()
            beforeSendTime: [data.beforeSendTime == null ? '' : data.beforeSendTime],
            isRepeat: [data.isRepeat.toString()],
            circularBody: [data.circularBody],
            status: [data.status.toString(), Validators.required]
          });
        });

      }

      deleteForm(id: number) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            this.circularService.delete(id).subscribe(res => {
              this.ListCircular();
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              );
            });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
          }
        });
      }


  ngOnInit(): void {
    this.ListClassCourse();
    this.ListCircularType();
    this.ListCircular();
    this.ListTemplates();
    this.listAllEmployees();
    this.circularForm = this.fb.group({
      id: [0],
      circularTypeId: ['', Validators.required],
      templateId: ['', Validators.required],
      audience: ['', Validators.required],
      channelService: ['', Validators.required],
      classIds: [''],
      isAutoSendAfter: [false],
      afterDays: [''],
      afterSendTime: [''],
      isOnDate: [false],
      onSendTime: [''],
      isAutoSendBefore: [false],
      beforeDays: [''],
      beforeSendTime: [''],
      isRepeat: ['', Validators.required],
      circularBody: [''],
      status: ['true', Validators.required]
    });
  }

}
