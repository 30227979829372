import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {AddonService} from './addonservice.service';
import { AddonserviceModel } from './addonservice.model';

@Component({
  selector: 'app-addonservice',
  templateUrl: './addonservice.component.html',
  styleUrls: ['./addonservice.component.css']
})
export class AddonserviceComponent implements OnInit {
  options = {
    autoClose: true,
    keepAfterRouteChange: false
   };
   addonserviceForm: UntypedFormGroup;
   addonservices: AddonserviceModel[] = [];
   addonserviceFilteredList: AddonserviceModel[] = [];
   imageSrc: string;
   submitted = false;
  constructor(
    public addonService: AddonService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }
  ListServices() {
    this.addonService.getAll().subscribe((data: AddonserviceModel[]) => {
      console.log(data);
      this.addonserviceFilteredList = this.addonservices = data;
    });
  }

  readURL(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const file = target.files[0];

      const reader = new FileReader();
      reader.onload = (e => {
        this.imageSrc = reader.result.toString();
        this.addonserviceForm.get('productImage').patchValue(this.imageSrc);
      });

      reader.readAsDataURL(file);
    }
  }
onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

filter(keyword: any) {
  if ( keyword === '') {
    this.addonserviceFilteredList = this.addonservices;
  } else {
  this.addonserviceFilteredList = this.addonservices
                              .filter((addonService: AddonserviceModel ) => {
                                return addonService.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                addonService.code.toLocaleLowerCase().includes(keyword.toLocaleLowerCase());
                            });
  }
}

keyPress(event: any) {
  const pattern = /[0-9\.]/;  /// [0-9\.\+\-\ ]/;
  const inputChar = String.fromCharCode(event.charCode);
  if (!pattern.test(inputChar) && event.charCode !== '0') {
          event.preventDefault();
  }
}

get totalRows(): number {
  return this.addonserviceFilteredList.length;
}

get f() { return this.addonserviceForm.controls; }
resetForm() {
  this.submitted = false;
  this.addonserviceForm = this.fb.group({
      id: [0],
      name: ['', Validators.required],
      code: ['', Validators.required],
      rate: ['', Validators.required],
      taxPercentage: ['', Validators.required],
      productImage : [''],
      status: ['true', Validators.required]
  });
  this.imageSrc = '';
}

submitForm() {
  this.submitted = true;
   // stop here if form is invalid
  if (this.addonserviceForm.invalid) {
          return;
    }

  if (this.addonserviceForm.get('id').value === 0) {
      console.log(this.addonserviceForm.value);
      this.addonService.create(this.addonserviceForm.value).subscribe(res => {
        console.log('Addon service created!');
        this.ListServices();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.addonService.update(this.addonserviceForm.get('id').value, this.addonserviceForm.value).subscribe(res => {
          console.log('Service updated!');
          this.ListServices();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
         });
      }
}
updateForm(id: number) {
 this.addonService.getById(id).subscribe((data: AddonserviceModel) => {
    console.log(data);
    this.addonserviceForm = this.fb.group({
      id: [data.id],
      name: [data.name, Validators.required],
      code: [data.code, Validators.required],
      rate: [data.rate, Validators.required],
      taxPercentage: [data.taxPercentage, Validators.required],
      productImage : [data.productImage],
      status: [data.status, Validators.required]
    });
    this.imageSrc = data.productImage;
  });

}

deleteForm(id: number) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You won\'t be able to revert this!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.addonService.delete(id).subscribe(res => {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
        this.ListServices();
      });
    // For more information about handling dismissals please visit
    // https://sweetalert2.github.io/#handling-dismissals
    } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire(
      'Cancelled',
      'Your imaginary file is safe :)',
      'error'
    );
    this.ListServices();
    }
  });
}

  ngOnInit(): void {
    this.ListServices();
    this.addonserviceForm = this.fb.group({
      id: [0],
      name: ['', Validators.required],
      code: ['', Validators.required],
      rate: ['', Validators.required],
      taxPercentage: ['', Validators.required],
      productImage : [''],
      status: ['true', Validators.required]
    });
 }

}



