import { Component, OnInit , Input, ViewChild, ElementRef,EventEmitter, Output} from '@angular/core';
import { Academicyear } from '../academic/mastersettings/models/academicyear';
import { SettingsService } from '../academic/mastersettings/settings.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-academicyear',
  templateUrl: './academicyear.component.html',
  styleUrls: ['./academicyear.component.css']
})
export class AcademicyearComponent implements OnInit {
  currentAcademicYearId:any;

  @ViewChild('currentAcademicYearDropDown') currentAcademicYearSelect :ElementRef;
@Output() currentYearId : EventEmitter<number>= new EventEmitter();
  academicYears: Academicyear[];
  constructor(  private sharedService: SharedService,

    private academicYearService:SettingsService
  ) { }
  currentAcademicYear() {
    this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
       this.currentAcademicYearId=this.sharedService.academicYearId;
    
     });
 
   }
  onChangeAcademicYear(value:number){
     this.currentYearId.emit(value);
  }
  ListAcademicYear() {
    this.academicYearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;

      console.log(this.sharedService.academicYearId)
      this.currentAcademicYearId=this.sharedService.academicYearId;


      //this.currentAcademicYearSelect.nativeElement.value=this.sharedService.academicYearId;
    });
  }
  ngOnInit(): void {
    this.ListAcademicYear();
   // this.currentAcademicYear();
  }

}
