import { Component, OnInit, ViewChild } from '@angular/core';
import { TransportdashboardService } from './transportdashboard.service';
import { Transport } from './model/transportmodel';
import { Subscription } from 'rxjs';
import { RoutesDetail } from './model/routesDetail-model';
/* import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexMarkers, ApexPlotOptions, 
  ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent,
  ApexTitleSubtitle,
} from 'apexcharts'; */
/* import { ApexOptions } from 'apexcharts' */
import { DashboardService } from '../dashboard/dashboard.service';
import { SessionWiseSalary } from '../dashboard/attendanceSummary.model';
import { AcademicyearComponent } from '../academicyear/academicyear.component';
import { ClassWise } from './model/classwisetransport-model';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexMarkers, ApexPlotOptions, ApexStroke, ApexTitleSubtitle, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';

export interface RouteChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  labels: string[];
  stroke: any; // ApexStroke;
  markers: ApexMarkers;
  plotOptions: ApexPlotOptions;
  colors: string[];
  fill: ApexFill;
  tooltip: ApexTooltip;
  legend: ApexLegend;
  grid: ApexGrid;
};

export type ClassWiseChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};

@Component({
  selector: 'app-transportdashboard',
  templateUrl: './transportdashboard.component.html',
  styleUrls: ['./transportdashboard.component.css']
})

export class TransportdashboardComponent implements OnInit {
  @ViewChild("routeChart") routeChart: ChartComponent;
  public routeChartOptions: Partial<RouteChartOptions>;

  @ViewChild("classWiseChart") classWiseChart: ChartComponent;
  public classWiseChartOptions: Partial<ClassWiseChartOptions>;

  // currentYearId:number;
  transportDashboardSummary: Transport;
  routesDetailData: RoutesDetail[];
  totalTravellers: number = 0;
  totalStaff: number = 0;
  totalVehicals: number = 0;
  totalRoutes: number = 0;
  classWiseData: ClassWise;
  classTotalTravellers: number = 0 ;
  classTotalClasses: number = 0 ;
  classTotalBatches: number = 0 ;
  classTotalMaleTravelers: number = 0 ;
  classTotalFemaleTravelers: number = 0 ;
  constructor(
    private transportdashboardService: TransportdashboardService,
  ) { 
    this.loadChartData();
    this.loadClassWiseChartData();
  }

  ngOnInit(): void {
    this.getTransportDashboardSummary();
    this.getRoutesByAcademicIdSummary(4);
    this.getTravellersByClassWise(4);
  }

  onChangeAcademicYear(value:number){
    this.getRoutesByAcademicIdSummary(value);
  }
  onChangeAcademicYearClassWise(value:number){
    this.getTravellersByClassWise(value);
  }

  loadChartData() {
    this.routeChartOptions = {
      chart: {
        height: 335,
        type: "line",
        stacked: !1,
        toolbar: {
          show: !1
        }
      },
      stroke: {
        width: [0, 2, 4],
        curve: "smooth"
      },
      plotOptions: {
        bar: {
          columnWidth: "50%"
        }
      },
      colors: ["#556ee6", "#34c38f", "#f46a6a"],
      series: [{
        name: "Traveller",
        type: "column",
        data: [23, 11, 22, 27, 13, 22, 37]
      }, {
        name: "Staff",
        type: "column",
        data: [44, 55, 41, 67, 22, 43, 21]
      }, {
        name: "Vehicle",
        type: "column",
        data: [30, 25, 36, 30, 45, 35, 64]
      }],
      fill: {
        opacity: [.85, .25, 1],
        gradient: {
          inverseColors: !1,
          shade: "light",
          type: "vertical",
          opacityFrom: .85,
          opacityTo: .55,
         /*  stops: [0, 100, 100, 100] */
        }
      },

      legend: {
        offsetY: -10
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      yaxis: {
        title: {
          text: ""
        }
      },
      tooltip: {
        shared: !0,
        intersect: !1
        // y: {
        //     formatter: (e) => {
        //         return void 0 !== e ? e.toFixed(0) + "" : e
        //     }
        // }
      },
      grid: {
        borderColor: "#f1f1f1"
      }
    };
  }

  loadClassWiseChartData(){
    this.classWiseChartOptions = {
      series: [
        
      ],
      chart: {
        type: "bar",
        height: 400,
        stacked: true,
        toolbar: {
          show: !1
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        categories: [2008, 2009, 2010, 2011, 2012, 2013, 2014],
        // labels: {
        //   formatter: function(val) {
        //     return val + "K";
        //   }
        // }
      },
      yaxis: {
        title: {
          text: ""
        }
      },
      tooltip: {
        shared: !0,
        intersect: !1
        // y: {
        //   formatter: function(val) {
        //     return val + "K";
        //   }
        // }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: -10
      }
    };
  }
  getTransportDashboardSummary() {
    this.transportdashboardService.GetTransportDashboard().subscribe((resData: Transport) => {
        this.transportDashboardSummary = resData;
      });   
  }

  getRoutesByAcademicIdSummary(yearId: number) {
    this.transportdashboardService.GetRoutesWithAcademicID(yearId).subscribe((resData: RoutesDetail[]) => {
      this.routesDetailData = resData as RoutesDetail[];
      let routeNames = this.routesDetailData.map(route => route.routeName);
      let travellers = this.routesDetailData.map(route => route.totalStudents);
      let staff = this.routesDetailData.map(route => route.totalStaff);
      let vehicals = this.routesDetailData.map(route => route.totalVehicles);

      let totalStudents = travellers.reduce((acc, cur)=> acc + cur, 0);
      this.totalStaff = staff.reduce((acc, cur)=> acc + cur, 0);
      this.totalTravellers = totalStudents + this.totalStaff;
      this.totalRoutes = routeNames.length;
      this.totalVehicals = vehicals.reduce((acc, cur)=> acc + cur, 0);

      this.routeChart?.updateOptions({
        xaxis: {
          categories: [...routeNames]
        },
      });

      this.routeChart?.updateSeries([{
        name: "Traveller",
        type: "column",
        data: [...travellers]
      }, {
        name: "Staff",
        type: "column",
        data: [...staff]
      }, {
        name: "Vehicle",
        type: "column",
        data: [...vehicals]
      }]);
    });  
  }

  getTravellersByClassWise(yearId: number){
    this.transportdashboardService.classWiseTransportDashboard(yearId).subscribe((resData: ClassWise) => {
      this.classWiseData = resData as ClassWise;
      
      this.classTotalTravellers = this.classWiseData.totalTravelers;
      this.classTotalClasses = this.classWiseData.totalClasses;
      this.classTotalBatches = this.classWiseData.totalBatches;
      this.classTotalMaleTravelers = this.classWiseData.totalMaleTravelers;
      this.classTotalFemaleTravelers = this.classWiseData.totalFeMaleTravelers;

      let classeByBatch = this.classWiseData.batches.map(batch => ({
        name: batch,
        data: this.classWiseData.classDetails
        .map(cd =>  cd.batches.filter(b => b.name === batch).filter(fb => fb.studentCount).map(fb => fb.studentCount))
        .map(x => x[0] || 0)
      }));
      this.classWiseChart?.resetSeries();

      this.classWiseChart?.updateSeries(classeByBatch);

      this.classWiseChart.updateOptions({
          xaxis: {
          categories: [...this.classWiseData.classes]
        },
      });
    });  
  }

  getAllDepartmentWiseEmployee(academicYearId: number) {

  }


}
