import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CoursePlannerComponent } from './course-planner/course-planner/course-planner.component';
import { CourseChapterPlannerComponent } from './course-chapter-planner/course-chapter-planner.component';
import { SessionPlannerComponent } from './session-planner/session-planner.component';
import { OnlineClassReportComponent } from './online-class-report/online-class-report.component';
import { SessionSummaryComponent } from './session-summary/session-summary.component';
import { CoursesComponent } from './courses/courses.component';
import { LMSSettingComponent } from './lmssetting/lmssetting.component';
import { LMSAPISettingComponent } from './lms-api-setting/lms-api-setting.component';



const routes: Routes = [
    {path: 'coursePlanner', component: CoursePlannerComponent},
    {path: 'courseChapterPlanner', component: CourseChapterPlannerComponent},
    {path: 'sessionPlannerComponent', component: SessionPlannerComponent},
    {path: 'sessionSummary', component: SessionSummaryComponent},
    {path: 'onlineClassReport', component: OnlineClassReportComponent},
    {path: 'courses', component: CoursesComponent},
    {path: 'lMSSetting', component: LMSSettingComponent},
    {path: 'lMSAPISetting', component: LMSAPISettingComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LMSRoutingModule { }
