import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import {  throwError, Observable } from 'rxjs';
import { Transport } from './model/transportmodel';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { RoutesDetail } from './model/routesDetail-model';
import { ClassWise } from './model/classwisetransport-model';


@Injectable({
  providedIn: 'root'
})
export class TransportdashboardService {
private httpClientForUpload:HttpClient;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
    httpOptionsForupload = {
    headers: new HttpHeaders({
    //'Content-Type': 'application/form-data',
      //'Content-Type': 'application/json'
      'InstitutionCode': this.authService.institutionCode
    })
  };

  constructor(private httpClient: HttpClient, private handler:HttpBackend, private  authService: AuthenticationService) { 
    this.httpClientForUpload=new HttpClient(handler);
  }

  GetTransportDashboard(): Observable<any> {
    ;
    const url = '/transportDashboard';
    return this.httpClient.get<Transport>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  GetRoutesWithAcademicID(academicId : number): Observable<any> {
    ;
    const url = '/transportDashboard/'+academicId;
    return this.httpClient.get<RoutesDetail[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  classWiseTransportDashboard(academicId : number): Observable<any> {
    ;
    const url = '/transportDashboard/classWiseTransportDashboard/'+academicId;
    return this.httpClient.get<ClassWise>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    ;
    console.log(errorMessage);
    return throwError(errorMessage);
 }

}
