import { DatePipe } from '@angular/common';
import { Conditional } from '@angular/compiler';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
//import { WindowScrollController } from '@fullcalendar/angular';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { interval, Subject } from 'rxjs';
import { SessionType } from 'src/app/Enums/EnumsType';
import { LoaderComponent } from 'src/app/shared/loader/loader.component';
import { CoursePlannerService } from '../course-planner/course-planner.service';
import { BatchVM } from '../course-planner/model/Batch';
import { ClassVM } from '../course-planner/model/ClassVM';
import { CoursePlannerVM } from '../course-planner/model/CoursePlannerVM';
import { SubjectVM } from '../course-planner/model/Subject';
import { OnlineClassReportService } from '../online-class-report.service';
import { ReportVm } from '../online-class-report/model/model';
import { SessionPlannerService } from '../session-planner.service';
import { SessionPlannerVM } from '../session-planner/model/SessionPlannerVM';
import { SummaryReportVm } from './model/model';
export interface TimeSpan {
  hours: number;
  minutes: number;
  seconds: number;
}

@Component({
  selector: 'app-session-summary',
  templateUrl: './session-summary.component.html',
  styleUrls: ['./session-summary.component.css']
})




export class SessionSummaryComponent implements OnInit {

  public lstClass: ClassVM[] = new Array<ClassVM>();
  public lstBatch: BatchVM[] = new Array<BatchVM>();
  public lstSessionClass: ClassVM[] = new Array<ClassVM>();
  public lstSessionBatch: BatchVM[] = new Array<BatchVM>();
  public lstCourseList: CoursePlannerVM[] = new Array<CoursePlannerVM>();
  public lstActualSessionPlannerVM: SessionPlannerVM[] = new Array<SessionPlannerVM>();
  public lstSessionPlannerVM: SessionPlannerVM[] = new Array<SessionPlannerVM>();
  public selectedSession: SessionPlannerVM = new SessionPlannerVM();
  public SessionTypeEnum = SessionType;
  public lstSubject: SubjectVM[] = new Array<SubjectVM>();

  public modelvm: SummaryReportVm = new SummaryReportVm;
  coursePlanForm: UntypedFormGroup;
  sessionPlannerApproveForm: UntypedFormGroup;
  copySessionForm: UntypedFormGroup;
  sessionReScheduleForm: UntypedFormGroup;
  sessionCancelForm:UntypedFormGroup;
  minDate = {year: new Date().getFullYear(), month: new Date().getMonth()+1, day: new Date().getDate()};
  
  submitted = false;
  timeleft;
  model: NgbDateStruct;
  responsemessage="";
  sessionDate: string;
  constructor(private onlineClassReportService: OnlineClassReportService,private changeDetector: ChangeDetectorRef,private toastr: ToastrService,
    private coursePlannerService: CoursePlannerService, private sessionPlannerService: SessionPlannerService,
    private formBuilder: UntypedFormBuilder) { }

    private destroyed$ = new Subject();
  ngOnInit(): void {
    const datePipe = new DatePipe('en-Us');
    this.sessionDate = datePipe.transform(new Date(), 'dd-MM-yyyy');
    //test
//this.GetAllStudentYear();
// end of test

    this.coursePlanForm = this.formBuilder.group({
      Id: 0,
      ClassId: [, Validators.required],
      SubjectId: [, Validators.required],
      CourseId: [, Validators.required],
      BatchId: [, Validators.required],
      coursePlannerId: [, Validators.required],
      sessionType:  [ -1, Validators.required],
      Status: [-1, Validators.required],
      Aproval:[-1, Validators.required]
      });

      this.sessionPlannerApproveForm = this.formBuilder.group({
       
        SessionPlannerId: [, Validators.required],
        ApprovalType: [, Validators.required],
   
        });


        this.copySessionForm = this.formBuilder.group({
          Id: 0,
          ClassId: [, Validators.required],
          BatchId: [, Validators.required],
          sessionDateCopy: ['', Validators.required],
          timeCopy: [, Validators.required]
          });


          this.sessionReScheduleForm = this.formBuilder.group({
            Id: 0,
            SessionDate: [, Validators.required],
            Time: [, Validators.required],
            });
            this.sessionCancelForm = this.formBuilder.group({
              Id: 0,
              Remarque: [, Validators.required]
              });
            
        


    this.GetInitialInfo();
    this.getAllClass();
    this.getAllSubject();
    this.GetAllReport();

    interval(1000).subscribe(() => {
      if (!this.changeDetector['destroyed']) {
        this.changeDetector.detectChanges();
      }
    });

    this.changeDetector.detectChanges();



  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public GetInitialInfo(){
    this.onlineClassReportService.GetSessionSummaryReport().subscribe((response) => {
            this.modelvm = response;
    });
  }

  public getAllClass() {
    this.coursePlannerService.GetClass().subscribe((response) => {
      this.lstClass = response;
      this.lstSessionClass = response;
    });
  }

  public GetAllReport(){
    this.onlineClassReportService.GetOnlineClassReportById().subscribe((response) => {
               this.lstActualSessionPlannerVM = response;
               console.log(response);
    });
  }
  getAllSubject(){
    this.coursePlannerService.GetSubject().subscribe((response) => {
      this.lstSubject = response;
    });
  }

  get f() { return this.copySessionForm.controls; }
  public saveSessionReSchedule(){

    ;
    if (this.sessionReScheduleForm.valid) {
   
      this.responsemessage  = "Waiting";
      // this.coursePlanForm.get('startDate').patchValue( sDate.year +"-"+ (sDate.month > 2 ? sDate.month : "0"+ sDate.month) +"-"+(sDate.day > 2 ? sDate.day : "0"+ sDate.day) );
       var Id = this.selectedSession.id;
       var Date = this.sessionReScheduleForm.get('SessionDate').value;
       var Time = this.sessionReScheduleForm.get('Time').value;
      
       var dateformat= Date.year +"-"+ (Date.month > 2 ? Date.month : "0"+ Date.month) +"-"+(Date.day > 2 ? Date.day : "0"+ Date.day)
       this.sessionPlannerService.SessionPlannerUpdateSchedule(Id,dateformat,Time).then((response) => {

         this.toastr.success("Succesfully Reschedule Session", 'Success!',{timeOut: 1000});

         this.onResetSearch();
       });
    
     
   } 


  }
public onResetSearch(){
  this.coursePlanForm = this.formBuilder.group({
    Id: 0,
    ClassId: [, Validators.required],
    SubjectId: [, Validators.required],
    CourseId: [, Validators.required],
    BatchId: [, Validators.required],
    coursePlannerId: [, Validators.required],
    sessionType:  [ -1, Validators.required],
    Status: [-1, Validators.required],
    Aproval:[-1, Validators.required]
    });

  this.GetInitialInfo();
  this.getAllClass();
  this.getAllSubject();
  this.GetAllReport();
  this.getSessionType();
}
public saveCopySession(){

  ;
  if (this.copySessionForm.valid) {
   
     var id =  this.selectedSession.id;
     var ClassId =  this.copySessionForm.get('ClassId').value;
     var BatchId =  this.copySessionForm.get('BatchId').value;
     var SessionDate =  this.copySessionForm.get('sessionDateCopy').value;
     var Time =  this.copySessionForm.get('timeCopy').value;
     console.log(Time);
     console.log(SessionDate);

     var dateformat= SessionDate.year +"-"+ (SessionDate.month > 2 ? SessionDate.month : "0"+ SessionDate.month) +"-"+(SessionDate.day > 2 ? SessionDate.day : "0"+ SessionDate.day)
     console.log(dateformat);
      this.sessionPlannerService.CopySessionSummary(id,BatchId,ClassId,dateformat,Time).then((res)=>{
        this.toastr.success("Succesfully Copied Session", 'Success!',{timeOut: 1000});
        this.onResetSearch();
        
      });
      
    
  } 

}
public saveCancelSession(){

  ;
  if (this.sessionCancelForm.valid) {
   
     var id =  this.selectedSession.id;
     var remarque =  this.sessionCancelForm.get('Remarque').value;

      this.sessionPlannerService.CancelSessionSummary(id,remarque).then((res)=>{
        this.toastr.success("Succesfully Canceld Session", 'Success!',{timeOut: 1000});
        this.onResetSearch();
        
      });
      
    
  } 

}




  public getSessionType(){
    
    var sessionType = this.coursePlanForm.get('sessionType').value; 

    
    /*this.onlineClassReportService.GetOnlineClassReportById().subscribe((response) => {
      this.lstActualSessionPlannerVM = response;
      this.lstSessionPlannerVM = this.lstActualSessionPlannerVM;
      });*/
      this.lstSessionPlannerVM = this.lstActualSessionPlannerVM.slice();
      console.log(this.lstSessionPlannerVM);
      if(sessionType>0){
        var sessionPlan = this.lstActualSessionPlannerVM.filter(x=> x.sessionType == parseInt(sessionType));
        this.lstSessionPlannerVM = sessionPlan;
      }
      if(sessionType==-1){
        this.lstSessionPlannerVM.splice(0,this.lstSessionPlannerVM.length);
      }
  }


  public getSessionClassId(){
    
    ;
    var ClassID = this.copySessionForm.get('ClassId').value;
    if(ClassID>0){
      this.getAllSessionBatchByClass(ClassID);
    }
  }


  public getClassId(){
    
    //this.GetAllReport();
    var ClassID = this.coursePlanForm.get('ClassId').value;
    var sessiontype = this.coursePlanForm.get('sessionType').value;
    //var sessiontype = this.coursePlanForm.get('batch').value;
    this.getSessionType();
    if(ClassID>0){
      var sessionPlan = this.lstSessionPlannerVM.filter(x=> x.classId == parseInt(ClassID));
      this.lstSessionPlannerVM = sessionPlan;

      this.getAllBatchByClass(ClassID);
      // this.getAllCoursePlanByClass(ClassID);
    }

  }


  

  public getAllSessionBatchByClass(k) {
    ;
    this.coursePlannerService.getAllBatchByClass(k).subscribe((response) => {

      this.lstSessionBatch = response;

    });
  }

  public getAllBatchByClass(k) {
    ;
    this.coursePlannerService.getAllBatchByClass(k).subscribe((response) => {
      this.lstBatch = response;
    });

  }

  public getBatchId(){
    var BatchID = this.coursePlanForm.get('BatchId').value;
    var ClassID = this.coursePlanForm.get('ClassId').value;
    var sessiontype = this.coursePlanForm.get('sessionType').value;
    this.getSessionType()
    if(ClassID>0)
    {
          var sessionPlan=this.lstSessionPlannerVM.filter(x=> x.classId == parseInt(ClassID)); 
     }
    if(BatchID>0){
        sessionPlan = sessionPlan.filter(x=> x.batchId == parseInt(BatchID));   
      }   
      this.lstSessionPlannerVM = sessionPlan;
      //this.getAllSubjectByBatchId(BatchID);
      this.coursePlanForm.get('BatchId').patchValue(BatchID);
  }

  public getAllSubjectByBatchId(BatchId) {
    ;
    var ClassID = this.coursePlanForm.get('ClassId').value;

   /* this.coursePlannerService.getAllSubjectByClass(ClassID).subscribe((response) => {
      //this.lstSubject = response.filter(x=> x.batchId == BatchId);;
      this.lstSubject = response;
      this.coursePlanForm.get('BatchId').patchValue(BatchId);
    });*/
    this.coursePlannerService.GetSubject().subscribe((response) => {
      //this.lstSubject = response.filter(x=> x.batchId == BatchId);;
      this.lstSubject = response;
      this.coursePlanForm.get('BatchId').patchValue(BatchId);
    });
  }


  public getSubjectId(){
    var BatchID = this.coursePlanForm.get('BatchId').value;
    var ClassID = this.coursePlanForm.get('ClassId').value;
    var sessiontype = this.coursePlanForm.get('sessionType').value;
    var SubjectId = this.coursePlanForm.get('SubjectId').value;
    this.getBatchId();
    this.coursePlanForm.get('BatchId').patchValue(BatchID);
    if(SubjectId>0){
      var sessionPlan = this.lstSessionPlannerVM.filter(x=> x.subjectId == SubjectId);
      if(BatchID>0)
      {
        sessionPlan=sessionPlan.filter(x=>x.batchId==BatchID)
      }
      
        this.lstSessionPlannerVM = sessionPlan;
    }

    /*;
    if(SubjectId>0){
      

      this.GetAllReport();
      if(sessiontype == 0){
        var sessionPlan = this.lstActualSessionPlannerVM.filter(x=> x.batchId == parseInt(BatchID) 
                                     && x.classId == ClassID && x.subjectId == SubjectId);
        this.lstSessionPlannerVM = sessionPlan;
      }
      else{
        var sessionPlan = this.lstActualSessionPlannerVM.filter(x=> x.batchId == parseInt(BatchID)&& x.classId == ClassID
                                        && x.sessionType == sessiontype && x.subjectId == SubjectId);
        this.lstSessionPlannerVM = sessionPlan;
  
      }

      var sessionPlan = this.lstSessionPlannerVM.filter(x=> x.batchId == parseInt(BatchID));
      this.lstSessionPlannerVM = sessionPlan;

     //  this.getAllCoursePlanByClassBatch(ClassID,BatchID)

      //this.GetAllSubject();
    }*/
  }
  public onChangeStatus(){
    var BatchID = this.coursePlanForm.get('BatchId').value;
    var ClassID = this.coursePlanForm.get('ClassId').value;
    var sessiontype = this.coursePlanForm.get('sessionType').value;
    var SubjectId = this.coursePlanForm.get('SubjectId').value;
    var status=this.coursePlanForm.get('Status').value;
    this.getSessionType();
    this.getSubjectId();
    this.coursePlanForm.get('BatchId').patchValue(BatchID);

    if(parseInt(status)!=-1){
      switch(parseInt(status)) {
        case 0:
          var sessionPlan = this.lstSessionPlannerVM.filter(x=> !x.status);
          break;
        case 1:
          var sessionPlan = this.lstSessionPlannerVM.filter(x=> x.status);
          break;
          case 2:
          var sessionPlan = this.lstSessionPlannerVM.filter(x=> x.canceled && !x.status);
          break;
      }
      if(BatchID>0)
      {
        sessionPlan=sessionPlan.filter(x=>x.batchId==BatchID)
      }
      
        this.lstSessionPlannerVM = sessionPlan;
    }
  }
  public onChangeAproval(){
    var BatchID = this.coursePlanForm.get('BatchId').value;
    var ClassID = this.coursePlanForm.get('ClassId').value;
    var sessiontype = this.coursePlanForm.get('sessionType').value;
    var SubjectId = this.coursePlanForm.get('SubjectId').value;
    var status=this.coursePlanForm.get('Status').value;
    var aproval=this.coursePlanForm.get('Aproval').value;
   
    this.getSessionType();
    this.onChangeStatus();
    this.coursePlanForm.get('BatchId').patchValue(BatchID);

    if(parseInt(aproval)!=-1){

      switch(parseInt(aproval)) {
        case 0:
          var sessionPlan = this.lstSessionPlannerVM.filter(x=> !x.approval);
          break;
        case 1:
          var sessionPlan = this.lstSessionPlannerVM.filter(x=> x.approval);
          break;
      }

      if(BatchID>0)
      {
        sessionPlan=sessionPlan.filter(x=>x.batchId==BatchID)
      }
      
        this.lstSessionPlannerVM = sessionPlan;
    }
    
  }


  getElapsedTime(entry: SessionPlannerVM): TimeSpan {   

    let totalSeconds = 0;
    let runningSeconds = 0;

    // ;
    if(entry.time != undefined){
      var _time = entry.time.split(":");
      
      var _Date =  new Date(entry.sessionDate).setHours(parseInt(_time[0]),parseInt(_time[1]),parseInt(_time[2]));
       totalSeconds = Math.floor((new Date(_Date).getTime() - new Date().getTime()) / 1000);

       entry.sessionDate = new Date(_Date);

       if(totalSeconds <0){
         runningSeconds = Math.floor((new Date().getTime() - new Date(_Date).getTime()) / 1000);

       }
    }
   
    
    if(totalSeconds > 0){
      entry.joinSessionCheck = false;

      entry.sessionStatus = "Pending";

      let hours = 0;
      let minutes = 0;
      let seconds = 0;
  
      if (totalSeconds >= 3600) {
        hours = Math.floor(totalSeconds / 3600);      
        totalSeconds -= 3600 * hours;      
      }
  
      if (totalSeconds >= 60) {
        minutes = Math.floor(totalSeconds / 60);
        totalSeconds -= 60 * minutes;
      }
  
      seconds = totalSeconds;
      
      return {
        hours: hours,
        minutes: minutes,
        seconds: seconds
      };
    }
    else{

      let hours = 0;
      let minutes = 0;
      let seconds = 0;
  
      if (runningSeconds >= 3600) {
        hours = Math.floor(runningSeconds / 3600);      
        runningSeconds -= 3600 * hours;      
      }
  
      if (runningSeconds >= 60) {
        minutes = Math.floor(runningSeconds / 60);
        runningSeconds -= 60 * minutes;
      }
  
      if(parseInt(entry.duration) > minutes){
        entry.sessionStatus = "Running";
        entry.joinSessionCheck = true;
      }
      else{
        entry.sessionStatus = "Completed";
        entry.joinSessionCheck = false;


      }
      seconds = runningSeconds;
      
      // return {
      //   hours: hours,
      //   minutes: minutes,
      //   seconds: seconds
      // };

      return {
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }
   
  }


  public getCourseId(){

  }


  public saveCourseChapter(){


  }

  public getAllCoursePlanByClass(k) {
    this.coursePlannerService.getAllCoursePlanByClass(k).subscribe((response) => {
      this.lstCourseList = response;
    });
  }


  public getCoursePlannerID(){

  }

  public joinSession(id){

    ;
    this.selectedSession = this.lstSessionPlannerVM.find(x=>x.id == id);
console.log(this.selectedSession);
    var timer = this.getElapsedTime(this.selectedSession);

    this.timeleft = timer.hours+":"+timer.minutes+":"+ timer.seconds;
   
  }

  public sessionDetails(id){
    this.selectedSession = this.lstSessionPlannerVM.find(x=>x.id == id);

;
    this.sessionPlannerApproveForm = this.formBuilder.group({
       
      SessionPlannerId: [this.selectedSession.id, Validators.required],
      ApprovalType: [this.selectedSession.approval, Validators.required],
 
      });
      
  }


  public rescheduleSession(id){
 
    ;
    this.selectedSession = this.lstSessionPlannerVM.find(x=>x.id == id);

  } 
  public cancelSession(id){
    this.selectedSession = this.lstSessionPlannerVM.find(x=>x.id == id);
  }
   public copySession(id){

    this.selectedSession = this.lstSessionPlannerVM.find(x=>x.id == id);
    this.getAllSessionBatchByClass(this.selectedSession.classId);
    this.copySessionForm = this.formBuilder.group({
      Id: this.selectedSession.id,
      ClassId: [this.selectedSession.classId, Validators.required],
      BatchId: [, Validators.required],
      sessionDateCopy: [this.formatedDate(this.selectedSession.sessionDate), Validators.required],
      timeCopy: [this.selectedSession.time, Validators.required]
      });
  }
 
  private formatedDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
  
    
    return ({ day: parseInt(day), month: parseInt(month), year: year });

  
  }

  public saveSessionPlannerApproval(){

    var type = this.sessionPlannerApproveForm.get('ApprovalType').value;
    var PlannerId = this.selectedSession.id;

    this.sessionPlannerService.UpdateSessionSummaryApproval(PlannerId,type).then((res)=>{
      this.toastr.success("Succesfully update Session", 'Success!',{timeOut: 1000});
      this.onResetSearch();
      
    });
    
  }
  meetingLink="";
  public saveStatus(){
    var type = this.sessionPlannerApproveForm.get('ApprovalType').value;
    var PlannerId = this.selectedSession.id;
    this.sessionPlannerService.UpdateSessionSummaryStatus(PlannerId,true)
    .then(
      res=>{
        this.onResetSearch();
        this.toastr.success("Succesfully update session", 'Success!',{timeOut: 1000});
        this.meetingLink=this.selectedSession.meetingLink;
        console.log("meetingLink", this.meetingLink)
        var win = window.open();
        win.document.write('<iframe width="100%" height="100%" src=' + this.meetingLink + ' frameborder="0" allowfullscreen></iframe>')
    
      //   window.open(
      //    this.selectedSession.meetingLink,
      //    '_blank' // <- This is what makes it open in a new window.
      //  );
      }
    );
    
  }

 /* search(term:string) {
    let promise = new Promise((resolve, reject) => {
      let apiURL = `${this.apiRoot}?term=${term}&media=music&limit=20`;
      this.http.get(apiURL)
        .toPromise()
        .then(
          res => { // Success
          this.results = res.json().results;
          resolve();
          },
          msg => { // Error
          reject(msg);
          }
        );
    });
    return promise;
  }*/



  }







/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
 @Injectable()
 export class CustomDateParserFormatter extends NgbDateParserFormatter {
 
   readonly DELIMITER = '-';
 
   parse(value: string): NgbDateStruct | null {
     if (value) {
       const date = value.split(this.DELIMITER);
       return {
         day : parseInt(date[0], 10),
         month : parseInt(date[1], 10),
         year : parseInt(date[2], 10)
       };
     }
     return null;
   }
 
   format(date: NgbDateStruct | null): string {
     return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
   }
 }
 

 