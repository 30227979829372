import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BiomatricSettingModel } from './biomatric-setting.model';
@Injectable({
  providedIn: 'root'
})
export class BiomatricSettingService {


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }

  get(id: number): Observable<BiomatricSettingModel> {
    return this.httpClient.get<BiomatricSettingModel>(environment.apiUrl + '/BiomatricAttendanceSetting/'+id)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getBiometricDetailsByInstitutionId(id: number): Observable<BiomatricSettingModel[]> {
    return this.httpClient.get<BiomatricSettingModel[]>(environment.apiUrl + '/BiomatricAttendanceSetting/GetDetailsByInstitutionId/'+id)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  saveBiometricList(requestBody): Observable<BiomatricSettingModel[]> {
    return this.httpClient.post<BiomatricSettingModel[]>(environment.apiUrl + '/BiomatricAttendanceSetting/saveBiometricList',
      requestBody, this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getAll(): Observable<BiomatricSettingModel[]> {
    return this.httpClient.get<BiomatricSettingModel[]>(environment.apiUrl + '/BiomatricAttendanceSetting')
      .pipe(
        catchError(this.errorHandler)
      );
  }

  create(requestBody): Observable<BiomatricSettingModel> {
    return this.httpClient.post<BiomatricSettingModel>(environment.apiUrl + '/BiomatricAttendanceSetting/',
      requestBody, this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  update(id, requestBody): Observable<BiomatricSettingModel> {
    return this.httpClient.put<BiomatricSettingModel>(environment.apiUrl + '/BiomatricAttendanceSetting/' + id,
      requestBody, this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  delete(id: number) {
    return this.httpClient.delete(environment.apiUrl + '/BiomatricAttendanceSetting/'+id)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
