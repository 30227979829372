import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FeeSetting } from './feesetting.model';
import { FeeSettingService } from './feesetting.service';

@Component({
  selector: 'app-feesetting',
  templateUrl: './feesetting.component.html',
  styleUrls: ['./feesetting.component.css']
})
export class FeesettingComponent implements OnInit {

  feesettingForm: UntypedFormGroup;
  feeSetting: FeeSetting;
  resClassIds: any[] = [];
  selectedClassIds = '';
  submitted = false;
  maxFeeVisible = false;
  fineRuleVisible = false;
  repeatConditionVisible = false;
  repeatFineRuleVisible =  false;
  repeatFineAmountVisible = false;
  feeReciptCopyVisible = false;
  recptSessionwise = false;

  constructor(
    public feeSettingService: FeeSettingService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  onConditionChange(value: string) {
    if ( value === '1') {

    } else if ( value === '2') {
      this.maxFeeVisible = true;
      this.fineRuleVisible = true;
    } else if ( value === '3') {
      this.maxFeeVisible = false;
      this.fineRuleVisible = true;
    } else if ( value === '4') {
      this.maxFeeVisible = true;
      this.fineRuleVisible = true;
      this.repeatConditionVisible = true;
    } else if ( value === '5') {
      this.maxFeeVisible = false;
      this.fineRuleVisible = true;
      this.repeatConditionVisible = true;
    }

  }

  onfeeReceiptCheckedChange(value: boolean) {
    if (value) {
      this.feeReciptCopyVisible = false;
    } else {
      this.feeReciptCopyVisible = true;
    }
  }

  onSessionCheckedChange(value: boolean) {
    if (value) {
      this.recptSessionwise = false;
    } else {
      this.recptSessionwise = true;
    }
  }

  resetForm() {
    this.submitted = false;

    this.feesettingForm = this.fb.group({
      id: [0],
      academicYearId: ['0'],
      conditions: ['', Validators.required],
      fineAmount: ['', Validators.required],
      fineRule: [''],
      maxFine: [''],
      repeatConditions: [''],
      repeatWithAmount: [''],
      repeatFineRule: [''],
      feeReciptCopy: ['', Validators.required],
      enableFeeReceiptNoSessionWise: ['', Validators.required],
      feeReceiptNoSessionWiseRate:  [''],
      finEnableOnlineFeePaymentFromWebeRule: ['', Validators.required],
      enableOnlineFeePaymentFromMobile: ['', Validators.required],
      enablePartialFeePaymentFromERP: ['', Validators.required],
      status: ['true', Validators.required]
    });
  }
  submitForm() {
    this.submitted = true;
        // stop here if form is invalid

    console.log(this.feesettingForm.value);

    if (this.feesettingForm.invalid) {
      return;
    }

    if (this.feesettingForm.get('id').value === 0) {
        this.feeSettingService.create(this.feesettingForm.value).subscribe(res => {
          console.log('Setting created!');
          // this.ListGroup();
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          // this.resetForm();
        });
        } else {
          this.feeSettingService.update(this.feesettingForm.get('id').value, this.feesettingForm.value).subscribe(res => {
            console.log('Setting updated!');
            // this.ListGroup();
            this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submitted = false;
            // this.resetForm();
          });
        }
      }

      updateForm(id: number) {
        this.feeSettingService.getById(id).subscribe((data: FeeSetting) => {
          console.log(data);
          if (data != null) {
          this.feesettingForm = this.fb.group({
            id: [data.id],
            academicYearId: [data.academicYearId],
            conditions: [data.conditions, Validators.required],
            fineAmount: [data.fineAmount, Validators.required],
            fineRule: [data.fineRule],
            maxFine: [data.maxFine],
            repeatConditions: [data.repeatConditions],
            repeatWithAmount: [data.repeatWithAmount],
            repeatFineRule: [data.repeatFineRule],
            feeReciptCopy: [data.feeReciptCopy.toString(), Validators.required],
            enableFeeReceiptNoSessionWise: [data.enableFeeReceiptNoSessionWise.toString(), Validators.required],
            feeReceiptNoSessionWiseRate:  [data.feeReceiptNoSessionWiseRate],
            finEnableOnlineFeePaymentFromWebeRule: [data.finEnableOnlineFeePaymentFromWebeRule.toString(), Validators.required],
            enableOnlineFeePaymentFromMobile: [data.enableOnlineFeePaymentFromMobile.toString(), Validators.required],
            enablePartialFeePaymentFromERP: [data.enablePartialFeePaymentFromERP.toString(), Validators.required],
            status: [data.status.toString(), Validators.required]
          });
          this.onConditionChange(data.conditions);
        }
        });
      }

  ngOnInit(): void {
    // this.ListClassCourse();
    this.updateForm(1);
    this.feesettingForm = this.fb.group({
      id: [0],
      academicYearId: ['0'],
      conditions: ['', Validators.required],
      fineAmount: ['', Validators.required],
      fineRule: [''],
      maxFine: [''],
      repeatConditions: [''],
      repeatWithAmount: [''],
      repeatFineRule: [''],
      feeReciptCopy: [false, Validators.required],
      enableFeeReceiptNoSessionWise: ['male', Validators.required],
      feeReceiptNoSessionWiseRate:  [false],
      finEnableOnlineFeePaymentFromWebeRule: [false, Validators.required],
      enableOnlineFeePaymentFromMobile: [false, Validators.required],
      enablePartialFeePaymentFromERP: [false, Validators.required],
      status: ['true', Validators.required]
    });
  }

}
