import { Component, OnInit } from '@angular/core';
import { AssignweightagecbseService } from '../../services/assignweightagecbse.service';
import { CbseweightageService } from '../../services/cbseweightage.service';
import { GradescalegroupService } from '../../services/gradescalegroup.service';
import { CbseAssignWeightage } from '../models/cbse-assign-weightage.model';
import { CbseWeightage } from '../models/cbse-weightage.model';
import { Classes } from '../models/classes.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-assign-weightages-cbse',
  templateUrl: './assign-weightages-cbse.component.html',
  styleUrls: ['./assign-weightages-cbse.component.css']
})
export class AssignWeightagesCBSEComponent implements OnInit {
  classes: Classes[]=[];
  allAssignWeightage:any[]=[];
  allAssignWeightageFilteredList: any[] = [];
  assignWeightagesCount:number=0;
  cbseWeightages:CbseWeightage[]=[];
  selectedWeightage:number;
  editSelected:string="0";

  constructor( private toastr:ToastrService,public service: AssignweightagecbseService,public cbseService: CbseweightageService,private gradeService: GradescalegroupService) { }

  ngOnInit(): void {
    this.loadWeigtage();
    this.loadClass();
    this.loadAssignWeightageCBSE();

  }
  loadClass(){
    this.gradeService.getAllClass().subscribe((data:Classes[])=>{      
      this.classes=data;
    })
  }
  loadWeigtage(){
    this.cbseService.getAllCbseWeightage().subscribe((data:CbseWeightage[])=>{
      this.cbseWeightages=data;  
          
    })
  }
  loadAssignWeightageCBSE()
  {
    this.service.loadAssignWeightageCBSE().subscribe((data:any)=>{
      this.allAssignWeightageFilteredList= this.allAssignWeightage=data;
      this.assignWeightagesCount=this.allAssignWeightageFilteredList.length;
    })
  }
  selectWeightageCBSE(event,id?){    
    this.selectedWeightage=event.target.value==undefined?id:event.target.value;  
    this.editSelected=id;
 
    this.service.getAssignCBSEWeightage().subscribe(((data:any)=>{    
     data.forEach(element=>{
       this.classes.find(obj=>obj.id==element["classId"]).isChecked= true;
       if(this.selectedWeightage!=element["weightageId"])
            this.classes.find(obj=>obj.id==element["classId"]).isActive=false;
       else
            this.classes.find(obj=>obj.id==element["classId"]).isActive=true;      
     })
    }));   
   }
  assignWeightageCBSE(classId,weightageId){
    if(weightageId>=1){   
    var data=new CbseAssignWeightage(0,weightageId,'',classId,'');
    this.service.assignWeightageCBSE(data).subscribe(res=>{
      this.loadAssignWeightageCBSE();
      this.toastr.success("CBSE Weightage assign successfully","SUCCESS!",{timeOut:3000});
    })
  }
  else{
    this.toastr.warning("Please select Weightage","WARNING!",{timeOut:3000});
  }
  }
}
