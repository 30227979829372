import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {ClassWiseFeeCollectionModel,MonthWiseFeeCollectionModel } from './classwisefeesummary.model';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClassWiseFeeSummaryService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
 getClassWiseFeeCollection(fromDate,toDate): Observable<any> {
   return this.httpClient.get<any>(environment.apiUrl + '/FeeReport/GetClassWiseFeeSummary?FromDate='+fromDate+'&ToDate='+toDate)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getMonthWiseCollection(fromDate,toDate): Observable<MonthWiseFeeCollectionModel[]> {
   return this.httpClient.get<any>(environment.apiUrl + '/FeeHead/GetMonthWiseCollectionReport')
    .pipe(
      catchError(this.errorHandler)
    );
  }
errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
