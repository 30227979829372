import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { EmployeeAttendanceReport } from '../../hrms/models/employeeadmission.model';
import { Attendance } from './models/attendance';

@Injectable({
  providedIn: 'root'
})
export class EmployeeattendanceService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  GetEmployeeAttendance(Attendance): Observable<Attendance[]> {
    ;
    return this.httpClient.get<Attendance[]>(environment.apiUrl + '/employeeattendance?AttendanceDate=' + Attendance.AttendanceDate+'&DepartmentId='+Attendance.departmentId)   
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(Attendance): Observable<Attendance> {
    return this.httpClient.post<Attendance>(environment.apiUrl + '/employeeattendance/', JSON.stringify(Attendance), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  GetAttendanceSummaryList(EmployeeId): Observable<Attendance[]> {
    ;
    return this.httpClient.get<Attendance[]>(environment.apiUrl + '/employeeattendance/' + EmployeeId)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  GetEmployeeAttendanceReport(employeeId): Observable<EmployeeAttendanceReport[]> {
    return this.httpClient.get<EmployeeAttendanceReport[]>(environment.apiUrl + '/employeeattendancereport/' + employeeId)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
