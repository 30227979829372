import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { EnquiryService } from '../enquiry/enquiry.service';
import { Enquiry } from '../enquiry/enquiry.model';
import { ReportAttribute } from '../../academic/studentmanagement/report.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as fs from 'file-saver';
/// import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable.js';
import * as Excel from 'exceljs/dist/exceljs.js';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-enquiryreport',
  templateUrl: './enquiryreport.component.html',
  styleUrls: ['./enquiryreport.component.css']
})
export class EnquiryreportComponent implements OnInit {
  enquiries: Enquiry[] = [];
  enquiryFilteredList: Enquiry[] = [];
  enquiryDefaultList: Enquiry[] = [];
  enquirySelectedList: Enquiry[] = [];
  status = 'All Status';
  purpose = 'All Purpose';
  fromDate: Date;
  toDate: Date;
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  institution: any;

  constructor(
    public enquiryService: EnquiryService,
    private toastr: ToastrService
  ) { }

  ListEnquiry() {
    this.enquiryService.getAll().subscribe((data: any) => {
      this.enquiryDefaultList = this.enquiryFilteredList = this.enquiries = data.Table;

      let date = new Date();
      this.fromDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      this.toDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      this.search();
    });
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('-');
  }

  public ToDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [year, month, day].join('-');
  }

  reverseAndTimeStamp(dateString) {
    const reverse = new Date(dateString.split("-").reverse().join("-"));
    return reverse.getTime();
    }

  search() {
    var TODATE ="";
    ;
    if (this.fromDate === undefined) {
      this.toastr.warning('Please select from date', 'WARN!', {
        timeOut: 3000
      });
      return;
    }

    if (this.toDate === undefined) {
      this.toastr.warning('Please select to date', 'WARN!', {
        timeOut: 3000
      });
      return;
    }
    else{
      TODATE = this.ToDate(this.toDate);
    }
  //  this.enquiryFilteredList = this.enquiries = JSON.parse(JSON.stringify(this.enquiryDefaultList));
    let fromDate = this.fromDate.toString();
    console.log(fromDate);
    this.enquiryFilteredList = this.enquiries = JSON.parse(JSON.stringify(this.enquiryDefaultList));
    var sortList = this.enquiryFilteredList;
    if (this.purpose != 'All Purpose' ){
      sortList =  sortList.filter((enquiry: any) => {
        return enquiry.PurposeOfVisit.toString() === this.purpose;
      });
    }
    if (this.status != 'All Status' ){
      sortList =  sortList.filter((enquiry: any) => {
        return enquiry.EnquiryStatus.toString() === this.status;
      });
    }
    let fromdate=moment(this.fromDate).format('DD-MM-YYYY');
    console.log(fromdate)
    let todate=moment(this.toDate).format('DD-MM-YYYY');
    sortList = sortList.filter((enquiry: any) => {
      if(this.fromDate && this.toDate){ 
      return this.reverseAndTimeStamp(moment(enquiry.CreatedDate).format('DD-MM-YYYY')) >= this.reverseAndTimeStamp(fromdate) && 
      this.reverseAndTimeStamp(moment(enquiry.CreatedDate).format('DD-MM-YYYY')) <= this.reverseAndTimeStamp(todate)  
      } else {
        return  new Date(enquiry.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
          new Date(enquiry.createdDate).getTime() <= new Date(TODATE).getTime();
      }
    });
    this.enquiryFilteredList = sortList;

    // if (this.purpose === 'All Purpose' && this.status === 'All Status' && fromDate === '') {
    //   this.enquiryFilteredList = this.enquiries = JSON.parse(JSON.stringify(this.enquiryDefaultList));
    // } else if (this.purpose !== 'All Purpose' && this.status === 'All Status' && fromDate === '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     return enquiry.purposeOfVisit.toString() === this.purpose;
    //   });
    // } else if (this.purpose === 'All Purpose' && this.status !== 'All Status' && fromDate === '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     return enquiry.enquiryStatus.toString() === this.status;
    //   });
    // } else if (this.purpose !== 'All Purpose' && this.status !== 'All Status' && fromDate === '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     return enquiry.enquiryStatus.toString() === this.status &&
    //       enquiry.purposeOfVisit.toString() === this.purpose;
    //   });
    // } else if (this.purpose === 'All Purpose' && this.status === 'All Status' && fromDate !== '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     // if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //     //   return Math.floor(new Date(enquiry.createdDate).getTime() / 86400000) - 1 ===
    //     //     Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //     // } else {
    //     //   return new Date(enquiry.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
    //     //     new Date(enquiry.createdDate).getTime() <= new Date(this.toDate).getTime();
    //     // }
    //     if(this.toDate == this.fromDate) {
    //       let date = new Date(enquiry.createdDate);
    //       let createdDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    //       return createdDate == this.fromDate;
    //     }
    //     else {
    //       let date = new Date(enquiry.createdDate);
    //       let createdDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    //       return createdDate >= this.fromDate && createdDate <= this.toDate;
    //     }
    //   });
    // } else if (this.purpose !== 'All Purpose' && this.status === 'All Status' && fromDate !== '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //       return enquiry.purposeOfVisit.toString() === this.purpose &&
    //         Math.floor(new Date(enquiry.createdDate).getTime() / 86400000) - 1 ===
    //         Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //     } else {
    //       return enquiry.purposeOfVisit.toString() === this.purpose &&
    //         new Date(enquiry.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
    //         new Date(enquiry.createdDate).getTime() <= new Date(this.toDate).getTime();
    //     }
    //   });
    // } else {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //       return enquiry.enquiryStatus.toString() === this.status &&
    //         enquiry.purposeOfVisit.toString() === this.purpose &&
    //         Math.floor(new Date(enquiry.createdDate).getTime() / 86400000) - 1 ===
    //         Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //     } else {
    //       return enquiry.enquiryStatus.toString() === this.status &&
    //         enquiry.purposeOfVisit.toString() === this.purpose &&
    //         new Date(enquiry.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
    //         new Date(enquiry.createdDate).getTime() <= new Date(this.toDate).getTime();
    //     }
    //   });
    // }
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: string) {
    if (keyword === '') {
      this.enquiryFilteredList = this.enquiries;
    } else {
      this.enquiryFilteredList = this.enquiries
        .filter((enquiry: Enquiry) => {
          return enquiry.visitorName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
            enquiry.contactNumber.toString().includes(keyword) ||
            (enquiry.enquiryNumber !== null ? enquiry.enquiryNumber.toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase()) : '');
        });
    }

    // if (this.enquiryFilteredList.length === 0) {
    //   this.toastr.warning('No Records Found', 'WARNING!!', {
    //       timeOut: 3000
    //   });
    // }
  }

  get totalRows(): number {
    return this.enquiryFilteredList.length;
  }

  onStatusSelected(value: string) {
    console.log(value);
    this.status = value;
    //console.log(this.enquirySelectedList.length);
    // this.enquiryFilteredList = this.enquiries = this.enquiryDefaultList;
    // const fromDate = $('#enquiryReport #fromDate').val();
    // if (this.purpose === 'All Purpose' && this.status === 'All Status' && fromDate === '') {
    //   this.enquiryFilteredList = this.enquiries = this.enquiryDefaultList;
    // } else if (this.purpose !== 'All Purpose' && this.status === 'All Status' && fromDate === '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     return enquiry.purposeOfVisit.toString() === this.purpose;
    //   });
    // } else if (this.purpose === 'All Purpose' && this.status !== 'All Status' && fromDate === '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     return enquiry.enquiryStatus.toString() === this.status;
    //   });
    // } else if (this.purpose !== 'All Purpose' && this.status !== 'All Status' && fromDate === '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     return enquiry.enquiryStatus.toString() === this.status &&
    //       enquiry.purposeOfVisit.toString() === this.purpose;
    //   });
    // } else if (this.purpose === 'All Purpose' && this.status === 'All Status' && fromDate !== '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //       return Math.floor(new Date(enquiry.createdDate).getTime() / 86400000) - 1 ===
    //         Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //     } else {
    //       return new Date(enquiry.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
    //         new Date(enquiry.createdDate).getTime() <= new Date(this.toDate).getTime();
    //     }
    //   });
    // } else if (this.purpose !== 'All Purpose' && this.status === 'All Status' && fromDate !== '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //       return enquiry.purposeOfVisit.toString() === this.purpose &&
    //         Math.floor(new Date(enquiry.createdDate).getTime() / 86400000) - 1 ===
    //         Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //     } else {
    //       return enquiry.purposeOfVisit.toString() === this.purpose &&
    //         new Date(enquiry.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
    //         new Date(enquiry.createdDate).getTime() <= new Date(this.toDate).getTime();
    //     }
    //   });
    // } else {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //       return enquiry.enquiryStatus.toString() === this.status &&
    //         enquiry.purposeOfVisit.toString() === this.purpose &&
    //         Math.floor(new Date(enquiry.createdDate).getTime() / 86400000) - 1 ===
    //         Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //     } else {
    //       return enquiry.enquiryStatus.toString() === this.status &&
    //         enquiry.purposeOfVisit.toString() === this.purpose &&
    //         new Date(enquiry.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
    //         new Date(enquiry.createdDate).getTime() <= new Date(this.toDate).getTime();
    //     }
    //   });
    // }
  }

  onPurposeSelected(value: string) {
    console.log(value);
    ;
    this.purpose = value;
    // this.enquiryFilteredList = this.enquiries = this.enquiryDefaultList;
    // const fromDate = $('#enquiryReport #fromDate').val();
    // if (value === 'All Purpose' && this.status === 'All Status' && fromDate === '') {
    //   this.enquiryFilteredList = this.enquiries = this.enquiryDefaultList;
    // } else if (value !== 'All Purpose' && this.status === 'All Status' && fromDate === '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     return enquiry.purposeOfVisit.toString() === this.purpose;
    //   });
    // } else if (value === 'All Purpose' && this.status !== 'All Status' && fromDate === '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     return enquiry.enquiryStatus.toString() === this.status;
    //   });
    // } else if (value !== 'All Purpose' && this.status !== 'All Status' && fromDate === '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     return enquiry.enquiryStatus.toString() === this.status &&
    //       enquiry.purposeOfVisit.toString() === this.purpose;
    //   });
    // } else if (value === 'All Purpose' && this.status === 'All Status' && fromDate !== '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //       return Math.floor(new Date(enquiry.createdDate).getTime() / 86400000) - 1 ===
    //         Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //     } else {
    //       return new Date(enquiry.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
    //         new Date(enquiry.createdDate).getTime() <= new Date(this.toDate).getTime();
    //     }
    //   });
    // } else if (value !== 'All Purpose' && this.status === 'All Status' && fromDate !== '') {
    //   this.enquiryFilteredList = this.enquirySelectedList = this.enquiries.filter((enquiry: Enquiry) => {
    //     if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //       return enquiry.purposeOfVisit.toString() === this.purpose &&
    //         Math.floor(new Date(enquiry.createdDate).getTime() / 86400000) - 1 ===
    //         Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //     } else {
    //       return enquiry.purposeOfVisit.toString() === this.purpose &&
    //         new Date(enquiry.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
    //         new Date(enquiry.createdDate).getTime() <= new Date(this.toDate).getTime();
    //     }
    //   });
    // } else {
    //   this.enquiryFilteredList = this.enquiries.filter((enquiry: Enquiry) => {
    //     if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //       return enquiry.enquiryStatus.toString() === this.status &&
    //         enquiry.purposeOfVisit.toString() === this.purpose &&
    //         Math.floor(new Date(enquiry.createdDate).getTime() / 86400000) - 1 ===
    //         Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //     } else {
    //       return enquiry.enquiryStatus.toString() === this.status &&
    //         enquiry.purposeOfVisit.toString() === this.purpose &&
    //         new Date(enquiry.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
    //         new Date(enquiry.createdDate).getTime() <= new Date(this.toDate).getTime();
    //     }
    //   });
    // }
  }

  generateAttributes() {
    this.attributes = [
      { name: 'S No', fieldName: 'index' },
      { name: 'Enquiry No', fieldName: 'enquiryNumber' },
      { name: 'Enq Date', fieldName: 'createdDate' },
      { name: 'Visitor Name', fieldName: 'visitorName' },
      { name: 'Contact No', fieldName: 'contactNumber' },
      { name: 'Purpose', fieldName: 'purposeOfVisit' },
     // { name: 'Department', fieldName: 'department' },
      { name: 'Person To Meet', fieldName: 'personToMeet' },
      { name: 'Appointment On', fieldName: 'appointmentDate' }
      // { name: 'Details', fieldName: 'enquiryDetail' },
      // { name: 'Status', fieldName: 'enquiryStatus' }
    ];
  }

  generateExcel() {
    this.headerList =[];
    this.fieldList =[];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Enquiry Report');
    worksheet.addRow();
    const imageId2 = workbook.addImage({
      base64: this.institution.institutionLogo,
      extension: 'jpg',
    });
    worksheet.addImage(imageId2, 'A1:B1');
    const titleRow = worksheet.addRow(['Enquiry Report']);
    const headerRow = worksheet.addRow(this.headerList);
    for(var i = 0 ; i < this.enquiryFilteredList.length ; i++)
    this.enquiryFilteredList[i]["index"] = i+1;
    for (const x1 of this.enquiryFilteredList) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'dob') {
          temp.push(this.formatDate(x1[y]));
        } else {
          temp.push(x1[y]);
        }
        // x2.forEach((e) => {
        //   if (e.toLocaleLowerCase() === y.toLocaleLowerCase()) {
        //   }
        // });
      }
      worksheet.addRow(temp);
    }
    const fname = 'Enquiry Report';

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }

  generatePDF() {
    ;
    this.headerList =[];
    this.fieldList =[];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    const widthList = [];
    for (const item of this.headerList) {
      widthList.push('auto');
    }
    
    for(var i = 0 ; i < this.enquiryFilteredList.length ; i++)
    this.enquiryFilteredList[i]["index"] = i+1;
    const docDefinition = {
      content: [
        {
          table: {
            widths: ['auto', '*'],
            body: [
              [{
                width: 70,
                height: 80,
                image: 'imageKey'
              }, {
                width: '*',
                alignment: 'center',
                stack: [
                  {
                    style: 'h1',
                    text: this.institution.institutionName
                  },
                  {
                    style: 'h2',
                    text: this.institution.address
                  },
                  {
                    style: 'h2',
                    text: '2021-22'
                  },
                  {
                    style: 'h1',
                    text: 'Enquiry Report'
                  }
                ]
              }
              ]
            ]
          },
          layout: {
            hLineWidth: (line) => line === 1,
            vLineWidth: () => 0,
            paddingBottom: () => 5
          },
        },
        {
          table: {
            headerRows: 1,
            pdfFonts:6,
            widths: widthList,
            body: [
              this.headerList,
              ...this.enquiryFilteredList.map((p) => {
                const temp = [];
                for (const y of this.fieldList) {
                  if (y === 'dob') {
                    temp.push(this.formatDate(p[y]));
                  } else {
                    temp.push(p[y]);
                  }
                }
                return temp;
              })
            ]
          }
        }
      ],
      images: {
        imageKey: environment.imageUrl + this.institution.logoUrl // via URL address, which can be referenced by this key
      },
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        },
        h1: {
          margin: [0, 0, 0, 0],
          fontSize: 18,
          bold: true
        },
        h2: {
          margin: [0, 0, 0, 0],
          fontSize: 10,
          bold: true
        }
      }
    };

    pdfMake.createPdf(docDefinition).download();

  }

  resetForm() {
    this.enquiryFilteredList = this.enquiries = this.enquiryDefaultList;
    $('#enquiryReport #fromDate').val('');
    $('#enquiryReport #toDate').val('');
    this.status = 'All Status';
    this.purpose = 'All Purpose';
  }

  ngOnInit(): void {
    this.ListEnquiry();
    this.generateAttributes();
    this.institution = JSON.parse(localStorage.getItem('Institution'));

    $('#enquiryReport #fromDate').datepicker().on('changeDate', (e) => {
      this.fromDate = new Date(e.date);
    });

    $('#enquiryReport #toDate').datepicker().on('changeDate', (e) => {
      this.toDate = new Date(e.date);
    });
  }
}
