import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClassWiseFeeCollectionModel, MonthWiseFeeCollectionModel } from './classwisefeesummary.model';
import { ClassWiseFeeSummaryService } from './classwisefeesummary.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
declare var $: any;
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import { ReportAttribute } from '../../../studentmanagement/report.model';
@Component({
  selector: 'app-classwisefeesummary',
  templateUrl: './classwisefeesummary.component.html',
  styleUrls: ['./classwisefeesummary.component.css']
})
export class ClasswisefeesummaryComponent implements OnInit {

  fromDate: any;
  toDate: any;
  classSumAmount: number = 0;
  monthSumAmount: number = 0;
  tempclassdata: any = [];
  classWiseList: any = [];
  headerList: string[] = [];
  fieldList: string[] = [];
  institution: any;
  isLoading: boolean = false;
  attributes: ReportAttribute[] = [];
  attributesIns: ReportAttribute[] = [];
  installmentwiseList: any = [];
  constructor(
    public classWiseFeeSummaryService: ClassWiseFeeSummaryService, private toastr: ToastrService,

  ) { }

  public datashow: Boolean = false;

  onSearch() {
    this.datashow = true;
    this.getClassWiseFeeCollection();
    // this.getMonthWiseCollection();
  }
  generateAttributes() {
    this.attributes = [
      {name: 'S NO', fieldName: 'index' },
      { name: 'CLASS', fieldName: 'className' },
      { name: 'BATCH', fieldName: 'batchName' },
      { name: 'TOTAL AMOUNT', fieldName: 'totalAmount' },
     
    ];
    this.attributesIns = [
      {name: 'S NO', fieldName: 'indexins' },
      { name: 'INSTALLMENT', fieldName: 'installment' },
      { name: 'TOTAL AMOUNT', fieldName: 'totalinsAmount' },
    ];
  }
  getClassWiseFeeCollection() {
   ;

    this.classSumAmount = 0;
    this.monthSumAmount = 0;
    this.isLoading=true;
    const datePipe = new DatePipe('en-Us');
    this.fromDate = datePipe.transform(this.fromDate, 'yyyy-MM-dd');
    this.toDate = datePipe.transform(this.toDate, 'yyyy-MM-dd');
    if (this.toDate >= this.fromDate) {
      this.classWiseFeeSummaryService.getClassWiseFeeCollection(this.fromDate, this.toDate).subscribe((data) => {
       ;

        this.installmentwiseList = data.table;
        this.classWiseList = data.table1;
        this.isLoading=false;
        if (this.tempclassdata.length > 0) {

        } else {
          this.tempclassdata = this.classWiseList;
        }
        if (this.classWiseList.length > 0) {
          for (var i = 0; i < this.classWiseList.length; i++) {
            this.classSumAmount = this.classSumAmount + this.classWiseList[i].totalAmount ?
              this.classSumAmount + this.classWiseList[i].totalAmount : 0;
          }

        }
        if (this.installmentwiseList.length > 0) {
          for (var i = 0; i < this.installmentwiseList.length; i++) {
            this.monthSumAmount = this.monthSumAmount + this.installmentwiseList[i].installmentAmount ?
            this.monthSumAmount + this.installmentwiseList[i].installmentAmount : 0;
          }
        }
        console.log(data);
        //this.classes = data;
      });
    }
    else {
      this.toastr.warning('Todate is greater than From date', 'WARNING!', {
        timeOut: 3000
      });
      this.isLoading=false;
      this.classWiseList = this.tempclassdata;
      this.installmentwiseList = [];
    }

  }
  getMonthWiseCollection() {
    this.classWiseFeeSummaryService.getMonthWiseCollection(this.fromDate, this.toDate).subscribe((data: MonthWiseFeeCollectionModel[]) => {
     ;
      console.log(data);
      //this.classes = data;
    });
  }


  ngOnInit(): void {

    this.generateAttributes();
    this.institution = JSON.parse(localStorage.getItem('Institution'));

    const datePipe = new DatePipe('en-Us');
    this.fromDate = datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = datePipe.transform(new Date(), 'yyyy-MM-dd');
    var date = new Date();
    $('#dateFrom').datepicker('setDate', new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    $('#dateTo').datepicker('setDate', new Date(date.getFullYear(), date.getMonth(), date.getDate()));

    const self = this;
    $('#dateFrom').datepicker().on('changeDate', (e) => {
      self.fromDate = e.date;
    });
    $('#dateTo').datepicker().on('changeDate', (e) => {
      self.toDate = e.date;
    });
//    this.onSearch();
    this.datashow = false;
  }

  defaultArray = [];
  defaultArrayy = [];
  generatePDF() {
   
    const pdffromdate = $('#dateFrom').val();
    const pdftodate = $('#dateTo').val();
   ;
    console.log(' this.installmentwiseList => ', this.installmentwiseList);
    
    this.headerList = [];
    this.fieldList = [];
    console.log(this.institution.logoUrl);
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    this.attributesIns.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    const widthList = [];
    for (const item of this.headerList) {
      widthList.push('auto');
    }

    this.defaultArray = [];
    for (var i = 0; i < this.classWiseList.length; i++) {
      let data
      let ele = this.classWiseList[i]     
      data = {
        "index" : i+1,
        "className":ele.className == undefined ? "" :ele.className,
        "batchName":ele.batchName == undefined ? "" :ele.batchName,
        "totalAmount":ele.totalAmount == undefined ? "" :ele.totalAmount,
        "indexins" : "",
        "installment": "",
        "totalinsAmount": "",
      }
       
     
      this.defaultArray.push(data);
    }
    for (var i = 0; i < this.installmentwiseList.length; i++) {
      let data
      let ele = this.installmentwiseList[i]     
      data = {
        "index" : "",
        "className":"",
        "batchName":"",
        "totalAmount":"",
        "indexins" : i+1,
        "installment":ele.installmentName == undefined ? "" :ele.installmentName,
        "totalinsAmount":ele.installmentAmount == undefined ? "" :ele.installmentAmount,
      }
       
     
      this.defaultArray.push(data);
    }
    // this.installmentwiseList.forEach(element => {
    //   this.defaultArray.installment = element.installmentName ? element.installmentName : " "
    // });

 

   ;
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('CLASSWISE FEE SUMMARY');
    worksheet.addRow();
    const docDefinition = {
      content: [
        {
          table: {
            widths: ['auto', '*'],
            body: [
              [{
                width: 100,
                height: 100,
                image: 'imageKey'
              }, {
                width: '*',
                alignment: 'center',
                stack: [
                  {
                    style: 'h1',
                    text: this.institution?.institutionName
                  },
                  {
                    style: 'h2',
                    text: this.institution?.address
                  },
                  {
                    style: 'h1',
                    text: 'CLASSWISE FEE SUMMARY REPORT'
                  },
                  {
                    style: 'h3',
                    text: pdffromdate + '   to   ' + pdftodate
                  }
                ]
              }
              ]
            ]
          },
          layout: {
            hLineWidth: (line) => line === 1,
            vLineWidth: () => 0,
            paddingBottom: () => 5
          },

        },
        {
          table: {
            headerRows: 1,
            widths: widthList,

            body: [
              this.headerList,
              ...this.defaultArray.map((p) => {
                const temp = [];
                for (const y of this.fieldList) {
                  if (y === 'dob') {
                    temp.push(this.formatDate(p[y]));
                  } 
                  else {
                    temp.push(p[y]);
                  }
                }
                return temp;
              })
            ]
          }
        },
        {
          // table: {
          //   headerRows: 1,
          //   widths: widthList,
          //   body: [
          //     this.headerList,
          //     ...this.defaultArray.map((p) => {
          //       const temp = [];
          //       for (const y of this.fieldList) {
          //         if (y === 'dob') {
          //           temp.push(this.formatDate(p[y]));
          //         } else {
          //           temp.push(p[y]);
          //         }
          //       }
          //       return temp;
          //     })
          //   ]
          // }
        }

      ],
      images: {
        // imageKey: environment.imageUrl + this.institution.logoUrl // via URL address, which can be referenced by this key
        imageKey: this.institution?.logoUrl != null ? environment.imageUrl + this.institution?.logoUrl : environment.imageUrl + '/images/avatar-1.png'
      },
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 16,
          bold: true
        },
        h2: {
          margin: [0, 5, 0, 0],
          fontSize: 10,
          bold: true
        }
      }
    };

    pdfMake.createPdf(docDefinition).download('CLASSWISE FEE SUMMARY REPORT.pdf');
  }
  public formatDate(date, onlyDate = false) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return !onlyDate ? new Date().toLocaleDateString() : [day, month, year].join('-');
  }

}
