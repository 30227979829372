import { Component, OnInit, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { InstallmentService } from '../installment/installment.service';
 
 
// import * as Razorpay from 'razorpay';

declare var Razorpay: any;

@Component({
  selector: 'app-paymentgatway',
  templateUrl: './paymentgatway.component.html',
  styleUrls: ['./paymentgatway.component.css']
})
export class PaymentgatwayComponent implements OnInit {
  TestPaymentForm: UntypedFormGroup;
  SucessfulPaymentForm: UntypedFormGroup;
  submitted = false;
  constructor(public fb: UntypedFormBuilder,
    public installmentService: InstallmentService) { }

  ngOnInit(): void {
    this.resetForm()
  }

  createRzpayOrder(data) {

    console.log(data);

    // call api to create order_id



  }

  resetForm() {
    this.submitted = false;

    this.TestPaymentForm = this.fb.group({

      AdmissionID: ['', Validators.required],
      RequestedAmount: ['', Validators.required],
      InstallmentIDS: ['', Validators.required]
    });

    this.SucessfulPaymentForm = this.fb.group({

      Id: ['', Validators.required],
      OrderID: ['', Validators.required],
      AdmissionID: ['', Validators.required],
      TransactionAmount: ['', Validators.required],
      GatewayProvider: ['', Validators.required],
      ErrorCode: ['', Validators.required],
      ErrorMessage: ['', Validators.required],
      razorpay_payment_id: ['', Validators.required],
      razorpay_signature: ['', Validators.required]

    });


  }

  submitForm() {
    this.submitted = true;
    // stop here if form is invalid

    console.log(this.TestPaymentForm.value);

    if (this.TestPaymentForm.invalid) {
      return;
    }


    this.installmentService.createorder(this.TestPaymentForm.value).subscribe(res => {
      debugger
      if(res.data == null ){
         alert(res.message)
         return;
      }
     
      console.log('Order Created' + res.data.orderId);
      // this.ListGroup();
      this.submitted = false;
      if (res.data != null && res.data.orderId != null) {

        this.SucessfulPaymentForm.patchValue({
          Id: res.data.id,
          AdmissionID: this.TestPaymentForm.value.AdmissionID,
          TransactionAmount: this.TestPaymentForm.value.RequestedAmount,
          GatewayProvider: res.data.gatewayName
        });

        this.payWithRazor(res.data);
      }
      // this.resetForm();
    });

  }

  payWithRazor(data) {

    var options = {

      "key": data.keyId, // Enter the Key ID generated from the Dashboard

      "amount": data.requestedAmount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise

      "currency": "INR",

      "name": "Edumaq",

      "description": "Fee Collection Detail",

      "image": "https://www.edumaq.in/img/logo3.png",

      "order_id": data.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1

      "handler": function (response) {
        debugger
        var event = new CustomEvent("payment.success",
          {
            detail: response,
            bubbles: true,
            cancelable: true
          }
        );
        window.dispatchEvent(event);
      },

      "prefill": {

        "name": "",

        "email": "",

        "contact": ""

      },

      "notes": {

        "address": "Razorpay Corporate Office"

      },

      "theme": {

        "color": "#3399cc"

      }

    };

    var rzp1 = new Razorpay(options);
    rzp1.open();
    rzp1.on('payment.failed', function (response) {

        var event = new CustomEvent("payment.failed",
        {
          detail: response,
          bubbles: true,
          cancelable: true
        }
      );
    window.dispatchEvent(event);
   rzp1.close();
    });

  }

  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event): void {
    console.log(event);
    debugger;
    this.SucessfulPaymentForm.patchValue({
      OrderID: event.detail.razorpay_order_id,
      ErrorCode: '',
      ErrorMessage: '',
      razorpay_payment_id: event.detail.razorpay_payment_id,
      razorpay_signature: event.detail.razorpay_signature
    });



    this.installmentService.PostSaveOnlineTransaction(this.SucessfulPaymentForm.value.Id, this.SucessfulPaymentForm.value).subscribe(res => {
      alert('Payment Save Successfully!');
      this.submitted = false;
      this.resetForm();
    });
  }

  @HostListener('window:payment.failed', ['$event'])
  onPaymentFailed(event): void {
    console.log(event);
    debugger;

     
      // Todo - store this information in the server
      console.log(event.detail.error.code);
      console.log(event.detail.error.description);
      console.log(event.detail.error.source);
      console.log(event.detail.error.step);
      console.log(event.detail.error.reason);
      console.log(event.detail.error.metadata.order_id);
      console.log(event.detail.error.metadata.payment_id);
 

    this.SucessfulPaymentForm.patchValue({
      OrderID: event.detail.error.metadata.order_id,
      ErrorCode: event.detail.error.code,
      ErrorMessage: event.detail.error.description,
      razorpay_payment_id: event.detail.error.metadata.payment_id,
      razorpay_signature: ''
    });

    this.installmentService.PostSaveOnlineTransaction(this.SucessfulPaymentForm.value.Id, this.SucessfulPaymentForm.value).subscribe(res => {
      alert('Payment Failed!');
      this.submitted = false;
      this.resetForm();
      //this.a
    });
  }
}
