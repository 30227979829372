import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {CountryService} from './country.service';
import { Country } from '../models/country';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit {
  options = {
    autoClose: true,
    keepAfterRouteChange: false
};

countryForm: UntypedFormGroup;
countries: Country[] = [];
countryFilteredList: Country[] = [];
submitted = false;
ClickedRow:any;
HighlightRow:number;


  constructor(
    public countryService: CountryService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) 
  { 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }
  }

  ListCountry() {

    this.countryService.getAll().subscribe((data: Country[]) => {
      console.log(data);

      this.countryFilteredList = this.countries = data;
    });
}

keyPress(event: any) {
  const pattern = /[0-9\+\-\ ]/;
  let inputChar = String.fromCharCode(event.charCode);
  if (!pattern.test(inputChar) && event.charCode !== '0') {
          event.preventDefault();
  }
}

onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

filter(keyword: any) {
  if ( keyword === '') {
    this.countryFilteredList = this.countries;
  } else {
  this.countryFilteredList = this.countries
                              .filter((country: Country) => country.countryName.toLocaleLowerCase()
                              .includes(keyword.toLocaleLowerCase()));
  }
}

get totalRows(): number {
  return this.countryFilteredList.length;
}
get f() { return this.countryForm.controls; }
resetForm() {
  this.submitted = false;
  this.countryForm = this.fb.group({
    id: [0],
    countryName: ['', Validators.required],
    countryCode: ['', Validators.required],
    status: ['true', Validators.required]
  });
}
submitForm() {
  this.submitted = true;

      // stop here if form is invalid
  if (this.countryForm.invalid) {
          return;
    }

  if (this.countryForm.get('id').value === 0) {
      this.countryService.create(this.countryForm.value).subscribe(res => {
        console.log('Country created!');
        this.ListCountry();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.countryService.update(this.countryForm.get('id').value, this.countryForm.value).subscribe(res => {
          console.log('Country updated!');
          this.ListCountry();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
      }


    }

    updateForm(id: number) {
      this.countryService.getById(id).subscribe((data: Country) => {
        console.log(data);
        this.countryForm = this.fb.group({
          id: [data.id],
          countryName: [data.countryName, Validators.required],
          countryCode: [data.countryCode, Validators.required],
          status: [data.status.toString(), Validators.required]
        });
      });

    }

    deleteForm(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.countryService.delete(id).subscribe(res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            this.ListCountry();
          });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
        }
      });
    }


  ngOnInit(): void {
    this.ListCountry();
    this.countryForm = this.fb.group({
      id: [0],
      countryName: ['', Validators.required],
      countryCode: ['', Validators.required],
      status: ['true', Validators.required]
    });
  }

}
