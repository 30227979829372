import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CbseweightageService } from '../../services/cbseweightage.service';
import { CbseWeightage } from '../models/cbse-weightage.model';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-weightages-cbse',
  templateUrl: './weightages-cbse.component.html',
  styleUrls: ['./weightages-cbse.component.css']
})
export class WeightagesCBSEComponent implements OnInit {

  cbseWeightage=new CbseWeightage(0,null,0,null,0,null,null);
  cbseWeightageForm:UntypedFormGroup;
  criterias=[];
  categories=[];
  dropDown:any=[];
  cbseWeightages:CbseWeightage[]=[];
  cbseWeightagesFilteredList: CbseWeightage[] = [];
  submitted = false;
  cbseWeightagesCount:number=0;

  constructor(private toastr:ToastrService,public fb: UntypedFormBuilder,public service: CbseweightageService) { }

  get f() { return this.cbseWeightageForm.controls; }

  ngOnInit(): void {
    
    this.cbseWeightageForm=this.fb.group({
      id:[0],
      weightages:['',Validators.required],
      criteria:['Select Criteria',Validators.required],
      examCategory:['Select Category',Validators.required],
      weightageStatus:[true,Validators.required]
    });
    
    ///this.setForm();
      this.getAllCbseDropDown();
      this.getAllCbseWeightages();  
  }

  setForm(dropDownOption:any=[]){
    this.criterias=dropDownOption.weightageContents;
    this.categories=dropDownOption.examCategories;    
  }

  getAllCbseDropDown(){
    this.service.getAllCbseDropDown().subscribe(data=>{
      this.dropDown=data;
      this.setForm(this.dropDown);
    })
  }

  resetForm(){
    this.submitted = false;
    this.cbseWeightageForm=this.fb.group({
      id:[0],
      weightages:['',Validators.required],
      criteria:['Select Criteria',Validators.required],
      examCategory:['Select Category',Validators.required],
      weightageStatus:[true,Validators.required]
    });

    //this.getAllCbseDropDown();
  }


  submitForm(){      
    this.submitted=true;
    if(this.cbseWeightageForm.invalid){
      this.toastr.warning('All field are required','WARNING!!!',{timeOut:3000});
      return;
    }

    if(this.cbseWeightageForm.get('id').value===0)
    {      
        var cbseId=this.cbseWeightageForm.get('id').value;
        var weightages= this.cbseWeightageForm.get('weightages').value;
        var criteriaId= this.cbseWeightageForm.get('criteria').value;        
        var examCategoryId= this.cbseWeightageForm.get('examCategory').value;
        var weigtageStatus= this.cbseWeightageForm.get('weightageStatus').value;
        var data = new CbseWeightage(cbseId,weightages,criteriaId,'',examCategoryId,'',weigtageStatus);

        this.service.createCbseWeightage(data).subscribe(res=>{                
        this.toastr.success("CBSE Weightage successfully created","SUCCESS!",{timeOut:3000});
        this.submitted=false;
        this.resetForm();
        
        this.getAllCbseWeightages();
        
    });    
   }
   else{
    var cbseId=this.cbseWeightageForm.get('id').value;
    var weightages= this.cbseWeightageForm.get('weightages').value;
    var criteriaId= this.cbseWeightageForm.get('criteria').value;        
    var examCategoryId= this.cbseWeightageForm.get('examCategory').value;
    var weigtageStatus= this.cbseWeightageForm.get('weightageStatus').value;
    var updateData = new CbseWeightage(cbseId,weightages,criteriaId,'',examCategoryId,'',weigtageStatus);
         this.service.updateCbseWeightage(this.cbseWeightageForm.get('id').value,updateData).subscribe(res=>{           
           this.toastr.success("CBSE Weightage successfully updated","SUCCESS!",{timeOut:3000});
           this.getAllCbseWeightages();
         });  
         
         this.resetForm();
   }
  }

  getAllCbseWeightages(){
    this.service.getAllCbseWeightage().subscribe((data:CbseWeightage[])=>{
      this.cbseWeightagesFilteredList=this.cbseWeightages=data;
      this.cbseWeightagesCount=this.cbseWeightagesFilteredList.length;
    })
  }

  editCbseWeightage(id:number){
    this.service.getAllCbseWeightageById(id).subscribe((data:CbseWeightage)=>{
      this.cbseWeightageForm=this.fb.group({
        id:[data.id],        
        weightages:[data.weightages,Validators.required],
        criteria:[data.criteriaId,Validators.required],
        examCategory:[data.examCategoryId,Validators.required],
        weightageStatus:[data.weightageStatus,Validators.required]        
      })
    })
  }

  deleteCbseWeightage(id:number){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton:true,
      confirmButtonText:'Yes, delete it!',
      cancelButtonText:'No, keep it'
    }).then((result)=>{
      if(result.value){
        this.service.deleteCbseWeightage(id).subscribe(data=>{
          Swal.fire(
            'Deleted!',
            'CBSE Weightage has been deleted.',
            'success'
          );
          this.getAllCbseWeightages();
        })
      }
    })
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.cbseWeightagesFilteredList = this.cbseWeightages;
      this.cbseWeightagesCount= this.cbseWeightagesFilteredList.length;
    } else {
    this.cbseWeightagesFilteredList = this.cbseWeightages
                                .filter((cbseWeightages: CbseWeightage) => cbseWeightages.weightages.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
    this.cbseWeightagesCount=this.cbseWeightagesFilteredList.length;
    }
  }
}
