import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {TermService} from './term.service';
import { Term } from '../models/term';

@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.css']
})
export class TermComponent implements OnInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false
};

termForm: UntypedFormGroup;
terms: Term[] = [];
termFilteredList: Term[] = [];
submitted = false;
ClickedRow:any;
HighlighRow:number;

  constructor(
    public termService: TermService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  )  { 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }
  }

  ListTerm() {
    this.termService.getAll().subscribe((data: Term[]) => {
      console.log(data);
      this.termFilteredList = this.terms = data;
    });
}

onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

filter(keyword: any) {
  if ( keyword === '') {
    this.termFilteredList = this.terms;
  } else {
  this.termFilteredList = this.terms
                              .filter((term: Term) => term.termName.toLocaleLowerCase()
                              .includes(keyword.toLocaleLowerCase()));
  }
}


get totalRows(): number {
  return this.termFilteredList.length;
}
get f() { return this.termForm.controls; }
resetForm() {
  this.submitted = false;
  this.termForm = this.fb.group({
    id: [0],
    termName: ['', Validators.required],
    duration: ['', Validators.required],
    status: ['true', Validators.required]
  });
}
submitForm() {
  this.submitted = true;

      // stop here if form is invalid
  if (this.termForm.invalid) {
          return;
    }

  if (this.termForm.get('id').value === 0) {
      this.termService.create(this.termForm.value).subscribe(res => {
        console.log('State created!');
        this.ListTerm();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.termService.update(this.termForm.get('id').value, this.termForm.value).subscribe(res => {
          console.log('State updated!');
          this.ListTerm();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
      }


    }

    updateForm(id: number) {
      this.termService.getById(id).subscribe((data: Term) => {
        console.log(data);
        this.termForm = this.fb.group({
          id: [data.id],
          termName: [data.termName, Validators.required],
          duration: [data.duration, Validators.required],
          status: [data.status.toString(), Validators.required]
        });
      });

    }

    deleteForm(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.termService.delete(id).subscribe(res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            this.ListTerm();
          });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
        }
      });
    }

  ngOnInit(): void {
    this.ListTerm();
    this.termForm = this.fb.group({
      id: [0],
      termName: ['', Validators.required],
      duration: ['', Validators.required],
      status: ['true', Validators.required]
    });
  }

}
