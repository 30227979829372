import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StudentWiseReportCardService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private http:HttpClient) { }
  url:string="http://localhost:5001/";

  getAllCatefgoryDropDown(academicYearId){
    return this.http.get(this.url+"api/examcategory/GetExamCategory?academicYearId="+academicYearId+"").pipe(
      catchError(this.errorHandler)
    )
  }   

  GetStudentReportCardSubject(examCatId,academicYearId,classId,batchId, studentId):Observable<any>{
    return this.http.get<any>(this.url+"api/GetStudentReportCard?examCatId="+examCatId+"&academicYearId="+academicYearId+"&classId="+classId+"&batch="+batchId+"&studentId="+studentId).pipe(
      catchError(this.errorHandler)
    )
  }

  GetStudentReportCardExamWise(examCatId,academicYearId,classId,batchId, studentId,examId):Observable<any>{
    return this.http.get<any>(this.url+"api/GetStudentReportCardExamWise?examCatId="+examCatId+"&academicYearId="+academicYearId+"&classId="+classId+"&batch="+batchId+"&studentId="+studentId+"&examId="+examId).pipe(
      catchError(this.errorHandler)
    )
  }


  GetStudentReportCardDetails(examCatId,academicYearId,classId,batchId, studentId):Observable<any>{
    return this.http.get<any>(this.url+"api/GetStudentReportCardDetails?examCatId="+examCatId+"&academicYearId="+academicYearId+"&classId="+classId+"&batch="+batchId+"&studentId="+studentId).pipe(
      catchError(this.errorHandler)
    )
  }

  getExamNameList() {
    return this.http.get(this.url+"api/examplanner").pipe(
      catchError(this.errorHandler)
    )
  }

  getClassList(details:any){
    return this.http.get(this.url+'api/GetExamWiseClassSummary?ExamCategoryId='+details.examCategoryId+'&ExamId='+details.examId+'&academicYearId='+details.academicYearId).pipe(
      catchError(this.errorHandler)
    )
  }

  getStudentList(details:any){
    return this.http.get(this.url+'api/GetExamWiseClassWiseStudentSummary?ExamCategoryId='+details.examCategoryId+'&ExamId='+details.examId+'&academicYearId='+details.academicYearId+'&classId='+details.classId+'&batchId='+details.batchId).pipe(
      catchError(this.errorHandler)
    )
  }

  GetSubjectWiseReport(examCatId,examId,classId,batchId):Observable<any>{
    return this.http.get<any>(this.url+"api/GetSubjectWiseReport?examCatId="+examCatId+"&examId="+examId+"&classId="+classId+"&batch="+batchId).pipe(
      catchError(this.errorHandler)
    )
  }

  getExamWiseSubjectWiseTeacherSummary(details:any){
    return this.http.get(this.url+'api/GetExamWiseSubjectWiseTeacherSummary?ExamCategoryId='+details.examCategoryId+'&ExamId='+details.examId+'&academicYearId='+details.academicYearId).pipe(
      catchError(this.errorHandler)
    )
  }

  getExamWiseSubjectWiseTeacherDetails(details:any){
    return this.http.get(this.url+'api/GetExamWiseSubjectWiseTeacherDetails?ExamCategoryId='+details.examCategoryId+'&ExamId='+details.examId+'&academicYearId='+details.academicYearId+'&SubjectId='+details.subjectId).pipe(
      catchError(this.errorHandler)
    )
  }

  GetConsolidateExamReport(examCatId,classId,batchId):Observable<any>{
    return this.http.get<any>(this.url+"api/GetConsolidateExamReport?examCatId="+examCatId+"&classId="+classId+"&batch="+batchId).pipe(
      catchError(this.errorHandler)
    )
  }

  errorHandler(error) {
   
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error 
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
