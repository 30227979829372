import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Template, TemplateType, TemplateParameter } from './template.model';
import { TemplateService } from './template.service';

@Component({
  selector: 'app-templatemanager',
  templateUrl: './templatemanager.component.html',
  styleUrls: ['./templatemanager.component.css']
})
export class TemplatemanagerComponent implements OnInit {

  templateForm: UntypedFormGroup;
  templates: Template[] = [];
  templateFilteredList: Template[] = [];
  searchFilteredList: Template[] = [];
  templateTypes: TemplateType[] = [];
  templateParameters: TemplateParameter[] = [];
  templateParameterFilteredList: TemplateParameter[] = [];
  templateEmployeeParameters: TemplateParameter[] = [];
  templateAdminParameters: TemplateParameter[] = [];
  templateAdminParameterFilteredList: TemplateParameter[] = [];
  templateEmployeeParameterFilteredList: TemplateParameter[] = [];
  submitted = false;
  caretPos = 0;
  templateMessage = '';
  chkAdmissionNo = false;
  template = {} as Template;
  showStudentParams = true;
  showEmployeeParams = false;
  parameters="";
  showAdminParams: boolean;
  isAllAdminChecked = false;

  constructor(
    public templateService: TemplateService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  ListTemplate() {
    this.templateService.getAll().subscribe((data: Template[]) => {
      this.templateFilteredList = this.templates = this.templateFilteredList = data;
      console.log(this.templateFilteredList);
      // assign the 'templateFilteredList' too and used to bind data in html
    });
  }

  ListTemplateType() {
    this.templateService.getAllTemplateType().subscribe((data: TemplateType[]) => {
      this.templateTypes = data;
    });
  }
  getTemplateTypeName(tid: number) { // added to get the template typename for grid rows
    return this.templateTypes.find(element => element.id === tid).name;
  }
  onTemplateTypeFilterChanged(value: any) {
    if (value === '') {
      this.searchFilteredList = this.templateFilteredList = this.templates;
    } else {
      // this.searchFilteredList = this.templateFilteredList = this.templates.filter((template: Template) => {
      //   return template.templateTypeId.toLocaleLowerCase() === value.toLocaleLowerCase();
      // });
      this.searchFilteredList = this.templateFilteredList = this.templates.filter(element => element.templateTypeId === value);
    }
  }

  onStudentRadioChanged(event: any) {
    this.parameters='';
    this.templateMessage='';
    if (event) {
      this.showStudentParams = true;
      this.showEmployeeParams = false;
    } else {
      this.showEmployeeParams = false;
    }
  }

  onEmployeeRadioChanged(event: any) {
    this.parameters='';
    this.templateMessage='';
    if (event) {
      this.showEmployeeParams = true;
      this.showStudentParams = false;
    } else {
      this.showStudentParams = false;
    }
  }
  onAdminRadioChanged(event: any) {
    if (event) {
      this.showAdminParams = true;
      this.showEmployeeParams = false;
      this.showStudentParams = false;
      //this.parameters=this.templateAdminParameters.map(m=>m.dbMappedColumn).join(",");
      //this.templateAdminParameters.forEach(x => x.checked = true);
    } else {
      this.showStudentParams = false;
      this.showEmployeeParams = false;
      this.parameters='';
      //this.templateAdminParameters.forEach(x => x.checked = false);
    }
    //this.isAllAdminChecked = event;
    //this.updateTextToCurrentPos(this.templateAdminParameters);
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart === '0') {
      this.caretPos = oField.selectionStart;
    }
  }

  getTypeName(typeId: number): string {
    return this.templateTypes.find(x => x.id === typeId).name;
  }

  setTextToCurrentPos(value: any, evnt: any) {
    const curPos = this.caretPos;
    const x = this.templateMessage;
    // let text_to_insert = $("#insert").val();
    // $("#text1").val(x.slice(0, curPos) + text_to_insert + x.slice(curPos));
    if (evnt.target.checked) {
      this.templateMessage = x.slice(0, curPos) + '{{' + value + '}}' + x.slice(curPos);
      this.caretPos = curPos + value.length + 6;
      this.parameters+=value+",";
    } else {
      
      const word = '{{' + value + '}}';
      this.templateMessage = this.templateMessage.replace(word, '');
      // this.templateMessage = x.slice(0, curPos) + ' {{' + value + '}} ' + x.slice(curPos);
      // this.caretPos = curPos + value.length + 6;
      this.parameters.replace(value+",","");
    }
  }



  showForm(id: number) {
    this.template = this.templates[id];
    this.template.templateType = this.getTemplateTypeName(Number(this.template.templateTypeId));
  }

  onKeyUpParameterEvent(event: any) {
    this.filterParameter(event.target.value);
  }

  filterParameter(keyword: any) {
    this.templateParameterFilteredList = this.templateParameters
      .filter((template: TemplateParameter) => template.name.toLocaleLowerCase()
        .includes(keyword.toLocaleLowerCase())
      );
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (this.searchFilteredList.length > 0) {
      if (keyword === '') {
        this.searchFilteredList = this.templateFilteredList = this.templates;
      } else {
        this.searchFilteredList = this.templateFilteredList = this.templates
          .filter((template: Template) => template.title.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase())
            // ||template.templateTypeId.toLocaleLowerCase()
            //   .includes(keyword.toLocaleLowerCase())
          );
      }
    } else {
      if (keyword === '') {
        this.templateFilteredList = this.templates;
      } else {
        this.templateFilteredList = this.templates
          .filter((template: Template) => template.title.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase())
            // ||template.templateTypeId.toLocaleLowerCase() //getting error while using the toLocaleLowerCase() here
            //   .includes(keyword.toLocaleLowerCase())
          );
      }
    }
  }

  checkAllCheckBox(ev) {
    if(ev.target.checked===true){
    this.parameters=this.templateParameters.map(m=>m.dbMappedColumn).join(",");
    }else{
      this.parameters="";
    }
    this.templateParameters.forEach(x => x.checked = ev.target.checked);
    this.updateTextToCurrentPos(this.templateParameters);
  }

  isAllCheckBoxChecked() {
     this.templateParameters.every(p => p.checked);
  }
  checkAllEmployeeCheckBox(ev) {
    if(ev.target.checked===true){
    this.parameters=this.templateEmployeeParameters.map(m=>m.dbMappedColumn).join(",");
    }else{
      this.parameters="";
    }
    this.templateEmployeeParameters.forEach(x => x.checked = ev.target.checked);
    this.updateTextToCurrentPos(this.templateEmployeeParameters);
  }

  isAllEmployeeCheckBoxChecked() {
     this.templateEmployeeParameters.every(p => p.checked);
  }
  checkAllAdminCheckBox(ev) {
    if(ev.target.checked===true){
    this.parameters=this.templateAdminParameters.map(m=>m.dbMappedColumn).join(",");
    }else{
      this.parameters="";
    }
    this.templateAdminParameters.forEach(x => x.checked = ev.target.checked);
    this.isAllAdminChecked = ev.target.checked;
    this.updateTextToCurrentPos(this.templateAdminParameters);
  }

  // isAllAdminCheckBoxChecked() {
  //    this.templateAdminParameters.every(p => p.checked);
  // }
  updateTextToCurrentPos(templateParamters: TemplateParameter[]) {
    const curPos = this.caretPos;
    this.templateMessage = this.templateMessage==undefined?'':this.templateMessage;
    const x = this.templateMessage;
    for(var i=0;i<templateParamters.length;i++){
      var value = templateParamters[i];
      if(value.checked){
        this.templateMessage += x.slice(0, curPos) + '{{' + value.name + '}}' + x.slice(curPos);
        this.caretPos = curPos + value.name.length + 6;
        this.parameters+=value.name+",";
      }else{
        const word = '{{' + value.name + '}}';
        this.templateMessage = this.templateMessage.replace(word, '');
        this.parameters.replace(value.name+",","");
      }
    }
  }
  get totalRows(): number {
    return this.templateFilteredList.length;
  }
  get f() { return this.templateForm.controls; }
  resetForm() {
    this.submitted = false;
    this.caretPos = 0;
    this.templateParameters.forEach(x => x.checked = false);
    this.templateMessage = '';
    this.templateForm = this.fb.group({
      id: [0],
      templateTypeId: ['', Validators.required],
      title: ['', Validators.required],
      templateBody: ['', Validators.required],
      status: ['true', Validators.required],
      templateId: ['', Validators.required],
      paramaeters: ['', ''],
      templateStatus:['',''],
    });
    this.templateAdminParameters.forEach(x => x.checked = false);
    this.isAllAdminChecked = false;
  }
  submitForm() {
    this.submitted = true;
    console.log(this.parameters); 
    this.templateForm.value.paramaeters=this.parameters;
    console.log(this.templateForm.value.paramaeters); 

    // stop here if form is invalid
    if (this.templateForm.invalid) {
      return;
    }

    if (this.templateForm.get('id').value === 0) {
      this.templateService.create(this.templateForm.value).subscribe(res => {
        this.ListTemplate();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    } else {
      this.templateService.update(this.templateForm.get('id').value, this.templateForm.value).subscribe(res => {
        this.ListTemplate();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    }
  }

  updateForm(id: number) {
    this.templateService.getById(id).subscribe((data: Template) => {
      this.templateParameters.forEach((x) => {
        const word = ' {{' + x.name + '}} ';
        if (data.templateBody.includes(word)) {
          x.checked = true;
        }
      });
      this.templateMessage = data.templateBody;
      this.caretPos = data.templateBody.length;
      
      this.templateForm = this.fb.group({
        id: [data.id],
        templateTypeId: [data.templateTypeId, Validators.required],
        title: [data.title, Validators.required],
        templateBody: [data.templateBody],
        status: [data.status.toString(), Validators.required],
        templateId: [data.templateId],
        paramaeters: this.parameters,
        templateStatus:[data.templateStatus]
      });
      console.log(this.templateForm);

    });

  }

  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.templateService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListTemplate();
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }

  ngOnInit(): void {
    this.ListTemplateType();
    this.ListTemplate();
    this.templateParameterFilteredList = this.templateParameters =
      [{ id: 1, name: 'Admission No', checked: false,dbMappedColumn:'AdmissionNo' },
      { id: 1, name: 'Admission Date', checked: false,dbMappedColumn:'AdmissionDate' },
      { id: 2, name: 'Student Name', checked: false ,dbMappedColumn:'StudentName'},
      { id: 3, name: 'Academic Year', checked: false ,dbMappedColumn:'Name'},
      { id: 4, name: 'Class', checked: false ,dbMappedColumn:'ClassCourseName'},
      { id: 5, name: 'Batch', checked: false ,dbMappedColumn:'BatchName'},
      { id: 6, name: 'Stream', checked: false,dbMappedColumn:'StreamName' },
      { id: 7, name: 'Date of Birth', checked: false,dbMappedColumn:'DOB' },
      { id: 8, name: 'Gender', checked: false ,dbMappedColumn:'Gender'},
      { id: 9, name: 'SMS Mob No', checked: false ,dbMappedColumn:'SMSMobNumber'},
      { id: 10, name: 'WhatsApp No', checked: false,dbMappedColumn:'WhatsAppNo' },
      { id: 11, name: 'Email ID', checked: false ,dbMappedColumn:'EmailId'},
      { id: 12, name: 'Aadhar No', checked: false,dbMappedColumn:'AdharNumber' },
      { id: 13, name: 'House Name', checked: false,dbMappedColumn:'HouseName' },
      { id: 14, name: 'Father\'s Name', checked: false,dbMappedColumn:'FatherName' },
      { id: 15, name: 'Father\'s Email Id', checked: false ,dbMappedColumn:'FatherEmailId'},
      { id: 16, name: 'Father\'s Mobile No', checked: false ,dbMappedColumn:'FatherMobileNo'},
      { id: 17, name: 'Mother\'s Name', checked: false ,dbMappedColumn:'MotherFullName'},
      { id: 18, name: 'Login Id', checked: false ,dbMappedColumn:'LoginId'},
      { id: 19, name: 'Password', checked: false,dbMappedColumn:'Password' },
      ];
    this.templateEmployeeParameterFilteredList = this.templateEmployeeParameters =
      [{ id: 1, name: 'Employee ID', checked: false,dbMappedColumn:'AdmissionNo' },
      { id: 2, name: 'Date of Birth', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 3, name: 'Employee Name', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 4, name: 'Gender', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 5, name: 'Mobile No', checked: false,dbMappedColumn:'AdmissionNo' },
      { id: 6, name: 'Department', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 7, name: 'Designation', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 8, name: 'Position', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 9, name: 'Employee Type', checked: false,dbMappedColumn:'AdmissionNo' },
      { id: 10, name: 'Job Type', checked: false,dbMappedColumn:'AdmissionNo' },
      { id: 11, name: 'User id', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 12, name: 'Password', checked: false,dbMappedColumn:'AdmissionNo' },
      { id: 13, name: 'ErpUser id', checked: false,dbMappedColumn:'AdmissionNo' },
      { id: 14, name: 'Erp password', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 15, name: 'Joining Date', checked: false ,dbMappedColumn:'AdmissionNo'}
      ];
      this.templateAdminParameterFilteredList = this.templateAdminParameters =
      [{ id: 1, name: 'SchoolCode', checked: false,dbMappedColumn:'AdmissionNo' },
      { id: 2, name: 'SchoolName', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 3, name: 'Address/Location', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 4, name: 'Country', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 5, name: 'State', checked: false,dbMappedColumn:'AdmissionNo' },
      { id: 6, name: 'City/Town', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 7, name: 'Contact Number', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 8, name: 'Landline Number', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 9, name: 'Email ID', checked: false,dbMappedColumn:'AdmissionNo' },
      { id: 10, name: 'WebSite URL', checked: false,dbMappedColumn:'AdmissionNo' },
      { id: 11, name: 'SchoolCode', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 12, name: 'SchoolLogo', checked: false,dbMappedColumn:'AdmissionNo' },
      { id: 13, name: 'Medium', checked: false,dbMappedColumn:'AdmissionNo' },
      { id: 14, name: 'Institution Type', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 15, name: 'Affiliation Type', checked: false ,dbMappedColumn:'AdmissionNo'},
      { id: 15, name: 'Affiliation ID', checked: false ,dbMappedColumn:'AdmissionNo'}
      ];

    this.templateForm = this.fb.group({
      id: [0],
      templateTypeId: ['', Validators.required],
      title: ['', Validators.required],
      templateBody: ['', Validators.required],
      status: ['true', Validators.required],
      templateId: ['', Validators.required],
      paramaeters: [this.parameters, ''],
    });
  }


}
