import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { LeadManageRoutingModule } from './leadmanage-routing.module';
import { ManageleadComponent } from './managelead/managelead.component';
import { ManagedemoComponent } from './managedemo/managedemo.component';
import { LeadcreationComponent } from './leadcreation/leadcreation.component';
import { DemomanagementComponent } from './demomanagement/demomanagement.component';


@NgModule({
  declarations: [ManageleadComponent, ManagedemoComponent, LeadcreationComponent, DemomanagementComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    LeadManageRoutingModule
  ]
})
export class LeadmanageModule { }
