import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { Institution } from '../admin/institution.model';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  inst: Institution;

  constructor(private sharedService:SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute
    ) { }

  ngOnInit(): void {
    // debugger;
    // this.inst=JSON.parse(localStorage.getItem('Institution'));
    // if(this.inst.institutionCode!=''){
    //   this.router.navigate([this.inst.institutionCode + '/login'], { relativeTo: this.activatedRoute.root });
    // }else{
    //   this.router.navigate(['/login']);
    // }
  }

}
