import { Component, OnInit } from '@angular/core';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { PaymentMode } from '../../mastersettings/models/paymentmode';
import { Student } from '../../studentmanagement/student.model';
import { FeeDetail, FeeDetailDetail, FeeMaster } from './feecollection.model';
import { FeeHead } from '../feehead/feehead.model';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { Batch } from '../../mastersettings/models/batch';
import { Installment } from '../installment/installment.model';
import { FeeCollectionService } from './feecollection.service';
import { InstallmentService } from '../installment/installment.service';
import { StudentService } from '../../studentmanagement/student.service';
import { FeeHeadService } from '../feehead/feehead.service';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FeeRecieptService } from '../feemanagementreports/feereceipt/feereceipt.service';
import { SettingsService } from '../../mastersettings/settings.service';
import { PaymentModeService } from '../../mastersettings/paymentmode/paymentmode.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { Console } from 'console';
declare var $: any;
declare var xyz: any;

@Component({
  selector: 'app-feecollection',
  templateUrl: './feecollection.component.html',
  styleUrl: './feecollection.component.css'
})
export class FeecollectionComponent implements OnInit {

  showFeeDetail: boolean[] = [];
  showStudentDetail = false;
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  lAdmissions: Student[] = [];
  lookupAdmissions: Student[] = [];
  selectedStudentClassId = '';
  selectedStudentBatchId = '';
  classId = '';
  admissionno = '';
  admissionId = '';
  feeHeads: FeeHead[] = [];
  extraFeeHeads: FeeHead[] = [];
  installments: Installment[] = [];
  feeDetailList: FeeDetail[] = [];
  studentDetail: Student = {} as Student;
  lookupMobileNo = '';
  lookupStudentName = '';
  batchId = 'Select Batch';
  lookupFatherName = '';
  feeTotal: number[] = [];
  feeDiscountTotal: number[] = [];
  feePaidTotal: number[] = [];
  feePayableAmount: number[] = [];
  feeDueTotal: number[] = [];
  feeReciepts: FeeMaster[] = [];
  //grandTotal :number= 0;
  //grandDiscountTotal:number = 0;
  //grandPayableAmount:number = 0;
  grandPaidTotal:number = 0;
  //grandDueTotal:number = 0; 
  xyz = this;
  chequedate: Date;
  chequeDateText: '';
  extraFeeInstallmentId :number= 0;
  paymentMode = '0';
  bankName = '';
  chequeNo = '';
  paymentNote = '';
  totalPaid :number= 0;
  currentDue :number= 0;
  totalDue :number= 0;
  IsChequeSelected = true;
  IsBankSelected = true;
  //dueAdvanceLabel = 'Due';
  cancelReason = '';
  selectedMasterId = 0;
//  isDueAvanceShow = false;
  totalDueAmount :number= 0;
  //dueFineAmount: number = 0
  dueFineAmountPayble: number=0;
  academicYears: Academicyear[] = [];
  AcademicYearId:any=0;
  studentFeeCollection: any[]=[];
  studentTravellerFee: any = 0;
  studentTravellerInstallmentId: any = 0;
  studentPayableAmount: any = 0;
  paymentsModes:PaymentMode[];
  paymentModeNameSelected: PaymentMode={} as PaymentMode;
  loader:boolean=false;
  disabled:boolean=false;
  totalAmount: any;
  totalPayableAmount: any;
  advanceReceipt:string;
  constructor( 
    public feeCollectionService: FeeCollectionService,
    public installmentService: InstallmentService,
    public studentService: StudentService,
    public feeHeadService: FeeHeadService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    private toastr: ToastrService,
    private router: Router,
    private feeReceiptService: FeeRecieptService,
    private activatedRoute: ActivatedRoute,
    public academicyearService: SettingsService,
    public settingsService: SettingsService,
    public paymentModeService:PaymentModeService){

  }

  ngOnInit(): void {
    this.loader=false;
    const self = this;
    this.IsBankSelected = false;
    this.IsChequeSelected = false;

    this.listPaymentModes();
    this.listAcademicYear();
    this.listClassCourse();
    this.listStudents();
    this.listExtraFee();   
    this.paymentMode = '0';    
    $('.select2').select2();
    $('.select2').on('change', function () {
      self.onStudentChanged(Number($(this).val()));
    });

    $('#chequeDate').datepicker().on('changeDate', (e) => {
      this.chequedate = new Date(e.date);      
    });
  }

  listPaymentModes() {
    this.paymentModeService
        .getAll()
        .subscribe((data:PaymentMode[])=>  {
            this.paymentsModes=data;
        });  
  }

  listAcademicYear() {
    this.settingsService
        .getAll()
        .subscribe((data: Academicyear[]) => {
            this.academicYears = data;
            let AcademicYearItem = this.academicYears.find(year=>year.isCurrentAcademicYear==true);
            this.AcademicYearId = AcademicYearItem?.id;
         });
  }

  listClassCourse() {
    this.classcourseService
        .getAll()
        .subscribe((data: ClassCourse[]) => {
              this.classes = data;
          });
  }

  listStudents() {
    this.studentService
        .getAll()
        .subscribe((data: Student[]) => {
            this.lAdmissions =this.admissions= data;
        });
  }

  listExtraFee() {
    this.feeHeadService
        .getAll()
        .subscribe((data: FeeHead[]) => {
          //Sam Changed [Requested by Bapan]
          this.extraFeeHeads = data;
           // this.extraFeeHeads = data.filter((x: FeeHead) => x.feeType === 'Extra');
            //Sam changed end 
        });
  }
  
  keyPress(event: any) {
    const pattern = /[0-9\.\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode !== '0') {
      event.preventDefault();
    }
  }

  onChequeDataChange(value: string) {    
    this.chequedate = new Date(value);
  }

  
  onClassSelected(value: string) {
    this.loader=true;   
    this.resetEForm(); 
    this.resetAllFormVariables();  
    this.resetGrandSummaryVariables();
    this.resetAllFormVariablesForBatch();
    this.resetSummaryCommonDetails();
    this.classId = value;
    this.batchService
        .getAll()
        .subscribe(
          (data: Batch[]) => {
            this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
            this.loader=false;
          },
          (err:any)=>{
            this.loader=false;
          }
        );
    if(this.selectedStudentBatchId!=''){
      this.batchId=this.selectedStudentBatchId;
    }  
  }

  
  onBatchChanged(value?: string) {    
    this.resetAllFormVariablesForBatch();
    this.loader =true;
    this.admissions=[];
    //this.studentService
    //    .getAll()
    //    .subscribe(
    //      (data: Student[]) => {   
    //    //  this.loader =!this.loader;               
    //        if(value){
    //          this.admissions = data.filter((student: Student) => student.classCourseId.toString() === this.classId &&
    //              student.batchId.toString() === value
    //          );
    //        } else {
    //          this.admissions = [];
    //        }
    //      },          
    //      (err:any)=>{
    //        this.loader =false;
    //      }
    //    );
    if(value){      
      this.admissions=this.lAdmissions
                          .filter((student: Student) => student.classCourseId.toString() == this.classId
                                                        && student.batchId.toString() == value
                                  );
                                  
    }
    //else
    // if(this.batchId!=''){
    // // this.admissions = this.admissions
    //                      //  .filter((student: Student) => student.classCourseId.toString() == this.classId &&
    //                         //                             student.batchId.toString() == this.batchId
    //                         ///       );
    //  this.admissions=this.lAdmissions
    //                      .filter((student: Student) => student.classCourseId.toString() == this.classId &&
    //                      student.batchId == this.batchId
    //                                          );
    //}   
    this.loader =false;      
  }

  searchLookupStudent() {      
    if (this.lookupStudentName !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
        return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase());
      });        
    }
    if (this.lookupFatherName !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
        return data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase());
      });
    }
    if (this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
        return data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase());
      });
    }

    if (this.lookupStudentName !== '' && this.lookupFatherName !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
        return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase()) &&
          data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase());
      });
    }
    if (this.lookupStudentName !== '' && this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
        return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase()) &&
          data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase());
      });
    }
    if (this.lookupFatherName !== '' && this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
        return data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase()) &&
          data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase());
      });
    }
    if (this.lookupStudentName !== '' && this.lookupFatherName !== '' && this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
        return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase()) &&
          data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase()) &&
          data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase());
      });
    }
  }

  onStudentChanged(id: number) {        
    this.resetSummaryDuesPaid();
    this.resetGrandSummaryVariables();    
    this.studentService
        .getStudentById(id)
        .subscribe((data: Student) => {
            this.studentDetail = data;
            this.getInstallments(this.studentDetail);    
    });
  }

  
  onStudentChangedLookUp(id: number) {
    
    this.resetSummaryDuesPaid();
    this.resetGrandSummaryVariables();  
    this.admissions=[];
    this.advanceReceipt =""; 
    this.selectedStudentBatchId='0';
    this.studentService
        .getStudentById(id)
        .subscribe((data: Student) => {
          this.loader=true;
          this.studentDetail = data;
          this.classId= this.selectedStudentClassId = this.studentDetail.classCourseId;
          this.batchId=this.selectedStudentBatchId=this.studentDetail.batchId;
          this.onClassSelected(this.classId);
          this.studentDetail = data;
          this.batchId=this.selectedStudentBatchId=this.studentDetail.batchId;
          this.admissionId = this.studentDetail.id.toString();
          this.onBatchChanged(this.batchId);
          this.studentDetail = data;
          this.batchId=this.selectedStudentBatchId=this.studentDetail.batchId;
          
          this.admissionId ="0";
          this.admissions=[];
          this.admissionId = this.studentDetail.id.toString();
          this.admissions=this.lAdmissions
                          .filter((student: Student) => student.classCourseId.toString() == this.classId
                                                        && student.batchId.toString() ==this.batchId
                                  );
          
          this.onStudentChanged(this.studentDetail.id);
          this.admissionId = this.studentDetail.id.toString();
          //this.getInstallments(this.studentDetail);   
          

    });

  }


  searchStudent() {       
    this.resetSummaryDuesPaid();
    this.resetGrandSummaryVariables();
    this.studentDetail = {} as Student;   
    this.studentService
        .getAll()
        .subscribe((data: Student[]) => {
            this.studentDetail = data.filter((student: Student) => {
              return student.admissionNo.toLowerCase() === this.admissionno.toLowerCase();
            })[0];        
           if (this.studentDetail) {                      
              this.classId = this.selectedStudentClassId;
              this.batchId = this.studentDetail.batchId;              
              this.getInstallments(this.studentDetail);
          } else {
              this.resetEForm();                            
              this.toastr.warning('No student records found', 'WARNING!', {
                timeOut: 3000
              });
          }
      });
  }

  private getInstallments(studentDetail: Student) {
    this.installments = []; 
    this.advanceReceipt="";
    this.selectedStudentClassId = studentDetail.classCourseId;
    this.selectedStudentBatchId = studentDetail.batchId;
    this.admissionId = studentDetail.id.toString();
    const currentMonth =  new Date().getMonth();
    const currentYear=new Date().getFullYear();
      this.installmentService
      .getFeePayment(this.studentDetail.id) 
      .subscribe((inst: Installment[]) => {
        inst.forEach((x) => {
          this.advanceReceipt=x.advanceReceiptNo.toString() ;
          x.dueAmount = Math.abs(Number(x.dueAmount)).toString();
          const installemntMonth = new Date(x.startDate).getMonth();
          //if (currentMonth >= installemntMonth && !x.isPaid && !x.isWaved) {
          if(Number(currentYear.toString() + (currentMonth.toString().length<2?'0':'') +currentMonth.toString())>=
            Number(new Date(x.startDate).getFullYear().toString() + (new Date(x.startDate).getMonth().toString().length<2?'0':'') +new Date(x.startDate).getMonth().toString())
             && !x.isPaid && !x.isWaved) {
            x.checked = true;
          }
         
        }
        );
        this.installments = inst.filter((x) => !x.isDeleted);
        this.installmentService
          .getFeePaymentCollection(this.studentDetail.id)
          .subscribe(data => {
            if (this.studentFeeCollection.length > 0) {
              this.studentTravellerFee = this.studentFeeCollection[0].amount;
              this.studentTravellerInstallmentId = this.studentFeeCollection[0].installmentId;
            }
            else {
              this.studentTravellerFee = 0;
              this.studentTravellerInstallmentId = 0;
            }
          });
        this.duedInstallmentsSelected(inst);
      });
  }

   resetSummaryDuesPaid(){
    this.feeTotal = [];
    this.feePayableAmount = [];
    this.totalDue = 0;
    this.totalPaid = 0;
    this.currentDue = 0;
    this.feeHeads = [];
   }
  
  duedInstallmentsSelected(instmnts: Installment[]) { 
     
    const currentMonth = moment(new Date()).format('MM-YYYY');
    const currentDate = new Date().getTime();    
    //this.dueFineAmount = 0;
    let k = 0;
    this.feeTotal=[];
    this.feePayableAmount=[];
    //let advfee = 0;
    //let advfeeForCalc = 0;   
    //<> 

    instmnts.forEach(async (element, i) => {
      if (element.isPaid || parseFloat(element.paid)>0) {
        this.totalPaid += parseFloat(element.paid);
        this.totalPaid.toFixed(2);       
      }
      if ((!element.isPaid || parseFloat(element.dueAmount) !== 0) && !element.isWaved) {
        if (!element.isPaid) {
          this.totalDue +=parseFloat( element.amount + element.fineAmount + this.studentTravellerFee);
        }
        this.totalDue += Number(element.dueAmount);
        this.totalDue.toFixed(2);
      }
      if (currentDate > new Date(element.startDate).getTime() && (!element.isPaid || parseFloat(element.dueAmount) !== 0) && !element.isWaved) {          
        this.currentDue += parseFloat(element.amount + element.fineAmount + this.studentTravellerFee);
        this.currentDue.toFixed(2);
      }
    });
    instmnts.filter(x=>x.checked).forEach(async (element, i) => {
      
      //  if (element.advance) {  
      //   advfee+=parseFloat(element.advance);    
      //   advfeeForCalc = advfee;      
      //  }
      
      const startDate= new Date(element.startDate);
      //if (currentDate > new Date(element.startDate).getTime() && (!element.isPaid || parseFloat(element.dueAmount) !== 0) && !element.isWaved) {          
       // this.currentDue += parseFloat(element.amount + element.fineAmount + this.studentTravellerFee);
        //this.currentDue.toFixed(2);
      //}    
      //if (currentMonth === moment(startDate).format('MM-YYYY') && !element.isPaid && !element.isWaved) {       
      if (element.checked && !element.isPaid && !element.isWaved) 
      {               
        const instmnt = element;     
        let feeDetail= this.buildFeeDetail(instmnt);           
        this.feeHeadService
            .getByInstallmentId(instmnt.id, this.selectedStudentClassId,this.selectedStudentBatchId, this.admissionId)
            .subscribe((data: FeeHead[]) => 
              {
                
                k++;
                this.buildFeeHeaderFine(data,instmnt);
                feeDetail=this.buildFeeHeaderAllForInstallment(instmnt, feeDetail);
                //this.feeHeads.forEach((fHead:FeeHead, index) => {
                //  const discAmmount = fHead.discountRate !== undefined ? fHead.discountRate : 0.00;
                //  const paidAmt = fHead.amountPaid !== undefined ? fHead.amountPaid : 0.00;
                //  const dueAmt = fHead.dueAmount !== undefined ? fHead.dueAmount : 0.00;
                //  const feeDetailDet = {} as FeeDetailDetail;
                //  feeDetailDet.feeHeadId = fHead.id.toString();
                //  feeDetailDet.feeType = fHead.feeType;
                //  feeDetailDet.feeHeadName = fHead.feeHeadName;
                //  feeDetailDet.amountPayable = (Number(fHead.amount) - Number(discAmmount)).toString();
                //  feeDetailDet.amountPaid = (Number(fHead.amount) - Number(discAmmount)).toString();
                //  feeDetailDet.duesAmount = Number(dueAmt).toFixed(2);
                //  feeDetailDet.discountRate = (0).toFixed(2);
                //  feeDetailDet.amount = fHead.amount;
                //  feeDetailDet.isExtra = false;    
                //  feeDetailDet.hasPaid = false;  // this is for identifying feehead has paid any fully or partially
                //  feeDetailDet.isFullyPaid = false;       
                //  //feeDetail.feeCollectionDetailDetailDtos.push(feeDetailDet);             
                //});  
                const feeCollDtos = feeDetail.feeCollectionDetailDetailDtos;
                this.feeDiscountTotal[k - 1] = feeCollDtos.reduce((sum: number, current) =>sum + parseFloat(current.discountRate), 0);
                this.feeDueTotal[k - 1] = feeCollDtos.reduce((sum: number, current) => sum + parseFloat(current.duesAmount), 0);
                this.feePaidTotal[k - 1] = feeCollDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPaid), 0);
                this.feeTotal[k - 1] = feeCollDtos.reduce((sum: number, current) => sum + parseFloat((Number(current.amount)).toString()), 0);                                             
                const payable= feeCollDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);
                const paidAmount=feeCollDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPaid), 0);
                this.feePayableAmount[k - 1] = payable;
                feeDetail.payableAmount = payable.toString();
                feeDetail.paidAmount = paidAmount.toString();
                this.grandPaidTotal= this.grandPayableAmount;
                this.setUpdatePaidTotal();
                //this.dueFineAmount = this.feeDetailList.reduce((sum: number, current) => sum + current.fineAmount, 0);
                // this.totalAmount = this.totalPayableAmount = this.feeDetailList.map(x => Number.parseInt(x.payableAmount)).reduce((acc, curr) => acc + curr, 0);                  
                /* Baban asked to comment for now
                const feeDetailDet = {} as FeeDetailDetail;
                feeDetailDet.feeHeadId = "25".toString();
                feeDetailDet.feeType = "common";
                feeDetailDet.feeHeadName = "Payable Amount"; 
                feeDetailDet.discountRate=(0).toFixed();
                feeDetailDet.amountPaid=(0).toFixed();           
                feeDetailDet.amount = (feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0)).toString();                        
                feeDetail.feeCollectionDetailDetailDtos.push(feeDetailDet);  
                */
              });              
        this.feeDetailList.push(feeDetail);        
      }           
        
    });
  }

  onGrandPaidTotalKeyup(event: any) {        
  }

  removeExtraFee(instIndex: any, index: any) {
    this.feeDetailList[instIndex].feeCollectionDetailDetailDtos.splice(index, 1);
    const feeCollectionDtos = this.feeDetailList[instIndex].feeCollectionDetailDetailDtos;
    const feeDetLength=this.feeDetailList.length - 1;
    this.feeDiscountTotal[feeDetLength] = feeCollectionDtos.reduce((sum: number, current) =>
                                                            sum + parseFloat(current.discountRate), 0);

    this.feeDueTotal[feeDetLength] = feeCollectionDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);

    this.feeTotal[feeDetLength] = feeCollectionDtos.reduce((sum: number, current) => sum + parseFloat(current.amount), 0);
    this.feePaidTotal[feeDetLength]= feeCollectionDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);
    this.feePayableAmount[feeDetLength]= feeCollectionDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);
    this.feeDetailList[instIndex].payableAmount = this.feePayableAmount[feeDetLength].toFixed(2);
    //this.feeDetailList[instIndex].dueAmount = this.feePayableAmount[feeDetLength].toFixed(2);  
    this.setUpdatePaidTotal();
  }

  resetExtraFee() {
    this.listExtraFee();
    this.extraFeeHeads.forEach(x => {
      x.checked = false;
      x.amount = '0';
      x.discountRate = undefined;
    });
  }

  onPaidKeypressEvent(event: any, rowindex: number, id: number) {
    const pattern = /[0-9\.+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode !== '0') {
      event.preventDefault();
    }
    const paidAmount = event.target.value;
    if (paidAmount > this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amount) {
      event.preventDefault();
      return;
    }
  }

  onPaidKeyupEvent(event: any, rowindex: number, id: number) {
  
      let feeCollectionDtos=this.feeDetailList[id].feeCollectionDetailDetailDtos;
      const paidAmount = event.target.value;
      if (paidAmount > feeCollectionDtos[rowindex].amount) {
        return;
      }
      const afterDiscount=(Number(feeCollectionDtos[rowindex].amountPayable) - Number(paidAmount)).toString();
      this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].duesAmount = afterDiscount;
      this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amountPayable = afterDiscount;
      //this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amountPaid = afterDiscount;
      // console.log(this.feeHeads[rowindex].paidAmount);
      this.feePaidTotal[id] = feeCollectionDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPaid), 0);
      this.feeDueTotal[id] = feeCollectionDtos.reduce((sum: number, current) => sum + parseFloat(current.duesAmount), 0);
      //this.feeDetailList[id].paidAmount = this.feePaidTotal[id].toFixed(2);
      //this.feeDetailList[id].dueAmount = this.feeDueTotal[id].toFixed(2);
      this.setUpdatePaidTotal();
      // this.grandDiscountTotal = this.feePaidTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0);
      //this.grandPaidTotal = this.feeDetailList.reduce((sum: number, current) => sum + parseFloat(current.paidAmount), 0);
      //this.grandDueTotal = this.feeDueTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed(2)), 0);
  }

  setUpdatePaidTotal(){
    setTimeout(()=>{
      this.grandPaidTotal= this.grandDueTotalToPay<0?0:this.grandDueTotalToPay;
    },5);    
  }

  onKeyupEvent(event: any, rowindex: number, id: number) {
    
    let discAmount = event.target.value==""?"0":event.target.value;   
    const valamount=this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amount;
    if (parseFloat(discAmount) > parseFloat(valamount)) {
      this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].discountRate = valamount;
      discAmount = valamount;
      Swal.fire({
        title: 'Alert ?',
        html: "You can't enter discount more than amount",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
       
      })
      .then((result) => {
        this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].discountRate = valamount;
        discAmount = valamount;
        this.FeeCalculation(discAmount, rowindex, id)          
      })       
    }     
    this.FeeCalculation(discAmount, rowindex, id)      
  }

  FeeCalculation(discAmount:any, rowindex: number, id: number)
  {
        
    const feeCollDtos=this.feeDetailList[id].feeCollectionDetailDetailDtos;
    if(rowindex>-1){
      this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amountPayable =(parseFloat(feeCollDtos[rowindex].amount) - Number(discAmount)).toString();
      //this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amountPaid =(parseFloat(feeCollDtos[rowindex].amount) - Number(discAmount)).toString();
    }
      this.feeDiscountTotal[id] = feeCollDtos.reduce(( sum: any, current) =>sum + parseFloat(current.discountRate), 0);
      this.feeTotal[id] = feeCollDtos.reduce((sum: number, current) =>sum + parseFloat(current.amount), 0);
      this.feePayableAmount[id] = feeCollDtos.reduce((sum: number, current) =>sum + parseFloat(current.amountPayable), 0);
      this.feePaidTotal[id] = feeCollDtos.reduce((sum: number, current) =>sum + parseFloat(current.amountPaid), 0);    
      this.feeDueTotal[id]= feeCollDtos.reduce((sum: number, current) =>sum + parseFloat(current.duesAmount), 0);    
      this.feeDetailList[id].payableAmount = this.feePayableAmount[id].toFixed(2);   
      //Sam Added start
      this.feeDetailList[id].installmentAmount=this.feeTotal[id].toString(); 
      this.feeDetailList[id].discount= this.feeDiscountTotal[id].toString() ;
      this.feeDetailList[id].payableAmount=this.feePayableAmount[id].toString();
      //this.feeDetailList[id].dueAmount=this.feeDueTotal[id].toFixed(2);
      //Sam added End

    
    this.setUpdatePaidTotal();
}

addExtraFeeHead(rowindex: number) {
  this.resetExtraFee();
  this.extraFeeInstallmentId = rowindex;
}

addExtraFee() {
    
  const installment = this.feeDetailList[this.extraFeeInstallmentId];      
  this.extraFeeHeads.forEach((x) => {
   // if (x.amount !== '0' && !x.checked) {
   //   this.toastr.warning('Please select fee head', 'WARNING!', {
   //     timeOut: 3000
  //    });
  //    return;
  //  }
    if (x.checked) { 
      const feeCollectionDtos= this.feeDetailList[this.extraFeeInstallmentId].feeCollectionDetailDetailDtos;
      const extraOrNew = feeCollectionDtos.filter(y => y.feeHeadId==x.id.toString());
     if(extraOrNew.length>0)
      {
        this.toastr.warning('Fee head already exists', 'WARNING!', {
          timeOut: 3000
        });
        return;
      }
      

      const feeDetailDetail: FeeDetailDetail = {} as FeeDetailDetail;
      const discAmount = x.discountRate === undefined ? (0).toFixed(2) : x.discountRate;        
      if (x.amount === '0' || x.amount === undefined) {
        this.toastr.warning('Please enter amount', 'WARNING!', {
          timeOut: 3000
        });
        return;
      }
      feeDetailDetail.feeHeadId = x.id.toString();
      feeDetailDetail.amount = x.amount;
      feeDetailDetail.duesAmount = (0).toFixed(2);
      feeDetailDetail.discountRate = discAmount;
      feeDetailDetail.feeHeadName = x.feeHeadName;
      feeDetailDetail.feeType = x.feeType;
      feeDetailDetail.amountPayable = (Number(x.amount) - Number(discAmount)).toString();
      //Sam Changed Start
      //feeDetailDetail.amountPaid= (Number(x.amount) - Number(discAmount)).toString();
      feeDetailDetail.amountPaid = (0).toFixed(2);
      //Sam Changed End
      feeDetailDetail.isExtra = true;
      feeDetailDetail.hasPaid = false;
      feeDetailDetail.isFullyPaid = false;
      this.feeDetailList[this.extraFeeInstallmentId].feeCollectionDetailDetailDtos.push(feeDetailDetail);

      
     
      ///Sam Changed Start
      const feeDetLength = this.extraFeeInstallmentId;//this.feeDetailList.length - 1;Sam changed
      //this.feeDiscountTotal[feeDetLength] = feeCollectionDtos.reduce((sum: number, current) => sum + parseFloat(current.discountRate), 0);
      //this.feeDueTotal[feeDetLength] = feeCollectionDtos.reduce((sum: number, current) => sum + parseFloat(current.duesAmount), 0);
      //this.feeTotal[feeDetLength] = feeCollectionDtos.reduce((sum: number, current) => sum + parseFloat(current.amount), 0);
      //this.feePayableAmount[feeDetLength] = feeCollectionDtos
      //                                      .reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);
      this.FeeCalculation(0,-1,this.extraFeeInstallmentId);
      ///Sam Changed End

      if (Number(installment.dueAmount) !== 0) {  
        const extraOrNew = feeCollectionDtos.filter((x: FeeDetailDetail) => !x.hasPaid)
                              .reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);
        this.feePaidTotal[feeDetLength] = Number(installment.dueAmount) + extraOrNew;
        //this.feeDetailList[feeDetLength].dueAmount =
         // (Number(installment.dueAmount) + Number(feeDetailDetail.amountPaid)).toFixed(2);
      } else {
        
        this.feePaidTotal[feeDetLength] = feeCollectionDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);
        //this.feeDetailList[feeDetLength].dueAmount = this.feePaidTotal[feeDetLength].toFixed(2);
      } 
    }
    $('#ExtraCharges').modal('toggle');
  });
  this.setUpdatePaidTotal();
}

// get set properties

get grandDueTotal(): number{ 
  //Sam Changed Strat
 var res=this.grandPayableAmount-this.grandPaidTotal;
 if(this.grandAdvanceTotal>res)
 return this.grandAdvanceTotal-res;
else if(this.grandAdvanceTotal<=res)
  return this.grandAdvanceTotal-res;
else
return res-this.grandPayableAmount;
   // return this.grandPayableAmount-this.grandAdvanceTotal-this.grandPaidTotal;

  //if ((Number(this.grandPaidTotal) ? Number(this.grandPaidTotal) : 0)===0){
    //return 0;
  //}
  //else{
    //return (Number(this.grandPaidTotal) ? Number(this.grandPaidTotal) : 0)  ;
  //}
  //Sam Changed End
}

get grandDueTotalToPay(): number{  
  //= this.feePaidTotal.reduce((sum: number, current) => sum + parseFloat(current.amountPaid), 0).toString();  
  return this.feeDetailList.reduce((sum: number, current) => sum + (Number(current)===0 || !current ? 0: parseFloat(Number(current.payableAmount).toFixed())), 0)-this.grandAdvanceTotal;//Sam Changed
}
///Sam Changed Start
get GetTotalPaid(): number{  
  //= this.feePaidTotal.reduce((sum: number, current) => sum + parseFloat(current.amountPaid), 0).toString();  
  return this.feeDetailList.reduce((sum: number, current) => sum + (Number(current)===0 || !current ? 0: parseFloat(Number(current.payableAmount).toFixed())), 0);//Sam Changed
}
///Sam Changed End
get isDueAvanceShow(): boolean{
  return this.grandDueTotal > 0 || this.grandDueTotal < 0 ? true: false;
}

get dueAdvanceLabel(): string{
  if(this.grandDueTotal>0)
    return "Advance";
  else if( this.grandDueTotal<=0)
    return "Due";
  return "";
}

get grandAdvanceTotal() : number{
  return this.installments.reduce((sum: number, current) =>sum + parseFloat(current.advance), 0);
}

get isAdvancePay() : boolean{
  return this.grandAdvanceTotal >0? true: false;
}
get isAdvance() : boolean{
  if(this.grandDueTotal>0)
    return true;
  else false;
}


get grandDiscountTotal(): number{  
  return this.feeDiscountTotal.reduce((sum: number, current) => sum + (current? parseFloat(current.toFixed()) : 0), 0);
}

get dueFineAmount(): number{
  return this.feeDetailList.reduce((sum: number, current) => sum + (current.fineAmount ? current.fineAmount : 0), 0);
}

/*
get grandPayableAmount(): number{  
  if(this.grandDiscountTotal>0){    
    return ((this.grandTotal)-this.grandDiscountTotal)-this.grandAdvanceTotal;    
  }    
  else{    
    return (this.grandTotal)-this.grandAdvanceTotal;
  }    
}
*/

get grandPayableAmount(): number{  
  return this.feePayableAmount.reduce((sum: number, current) => sum + (Number(current)===0 || !current ?0: parseFloat(current.toFixed())), 0);  
}

get grandTotal(): number{    
  return this.feeTotal.reduce((sum: number, current) => sum + (Number(current)===0 || !current ? 0: parseFloat(current.toFixed())), 0)-this.dueFineAmount;
}

showFee(value: number, id: number) {
  this.feeHeads = [];
  this.showFeeDetail[id] = !this.showFeeDetail[id];
}

checkAllCheckBox(ev) {
    this.resetGrandSummaryVariables();
    if (ev.target.checked) {    
      this.installments.forEach((x,index) => {  
        if ((!x.isPaid || this.getDueAmount(x.id,parseFloat(x.amount),x.fineAmount,parseFloat(x.paid)) != 0) 
              && !x.isWaved) {
          x.checked = true;          
          this.addSelected(index, ev);
          
        }
        //index++;
      });
    } else {
      this.installments.forEach((x) => {
        if ((!x.isPaid || x.dueAmount !== '0') && !x.isWaved) {
          x.checked = false;
        }
      });      
      this.resetGrandSummaryVariables();
      //this.dueFineAmount = 0;
    }
  }
addInstallmentInList(id:number){
  const installment = this.installments[id]; 
  /*     
  let advance= this.installments.reduce((sum: number, current) => sum + parseFloat(current.advance), 0);          
  if (Number(advance) !== 0) { 
    this.grandAdvanceTotal = Number(advance);
    advFee= this.grandAdvanceTotal;
    this.isAdvancePay = true;
  }
  */
  let feeDetail = this.buildFeeDetail(installment);
  this.feeHeadService
      .getByInstallmentId(installment.id, this.selectedStudentClassId,this.selectedStudentBatchId, this.admissionId)
      .subscribe((data: FeeHead[]) => {

        this.buildFeeHeaderFine(data, installment);                        
        feeDetail=this.buildFeeHeaderAllForInstallment(installment, feeDetail);
        const feeDetLength= this.feeDetailList.length - 1;          
        this.feeDiscountTotal[feeDetLength] = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.discountRate), 0);            
        this.feeTotal[feeDetLength] = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amount), 0);            
        this.feePayableAmount[feeDetLength] = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0); 
        const payable= feeDetail.feeCollectionDetailDetailDtos
                                              .reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);  
        feeDetail.payableAmount = payable.toString();
        if (Number(installment.dueAmount) !== 0) {   
          const extraOrNew = feeDetail.feeCollectionDetailDetailDtos.filter((x: FeeDetailDetail) => !x.hasPaid)
                          .reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);
          this.feePaidTotal[feeDetLength] = Number(installment.dueAmount) + extraOrNew;
        } else {              
          this.feePaidTotal[feeDetLength] = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);
        }
        this.totalDueAmount=feeDetail.feeCollectionDetailDetailDtos
        .reduce((sum: number, current) => sum + parseFloat(current.duesAmount), 0);;
        feeDetail.totaldueAmount =feeDetail.feeCollectionDetailDetailDtos
        .reduce((sum: number, current) => sum + parseFloat(current.duesAmount), 0);
        this.setUpdatePaidTotal();
        ///////////////
        /* Baban Said, we can comment for now 
        const feeDetailDett = {} as FeeDetailDetail;
        feeDetailDett.feeHeadId = "25".toString();
        feeDetailDett.feeType = "common";
        feeDetailDett.feeHeadName = "Payable Amount";  
        feeDetailDett.amountPayable = (0).toFixed();          
        feeDetailDett.amount = (feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0)).toString();
        ////hicham
        feeDetailDett.discountRate=(0).toFixed();
        feeDetailDett.amountPaid=(0).toFixed();
        //// hicham

        feeDetail.feeCollectionDetailDetailDtos.push(feeDetailDett);
        */
        //////////////
      });       
   //feeDetail.totaldueAmount = this.grandPayableAmount - Number(this.grandPaidTotal) ? Number(this.grandPaidTotal) : 0;
  
   this.feeDetailList.push(feeDetail);      
   this.setUpdatePaidTotal();

}
  addSelected(id: number, event: any) {
    
   //let advFee = 0;
   //this.dueFineAmount =0;
    if (event.target.checked) {
      this.showFeeDetail.push(false);    
      this.addInstallmentInList(id);  
    } 
    else {
      //const totPaidCount = this.installments.filter((x: Installment) => x.isPaid).length;
      //const toWaveOff = this.installments.filter((x: Installment) => x.isWaved.toString() === 'true').length;      
      let currentindex = 0;
      /*for (let i = 0; i <= this.feeDetailList.length - 1; i++) {
        if (this.feeDetailList[i].installmentId.toString() === this.installments[id].id.toString()) {
          currentindex = i;
        }
      }*/  
      currentindex = this.feeDetailList.findIndex((x: FeeDetail)=> x.installmentId.toString() ===this.installments[id].id.toString());
      this.feeDetailList.splice(currentindex, 1);
      this.feeDiscountTotal.splice(currentindex, 1);
      this.feeDueTotal.splice(currentindex, 1);
      this.feePaidTotal.splice(currentindex, 1);
      this.feeTotal.splice(currentindex, 1); 
      this.feePayableAmount.splice(currentindex, 1);                    
      if (this.feeDetailList.length === 0) {
        this.resetGrandSummaryVariables();
        //this.totalAmount = this.totalPayableAmount = 0;
      }
      this.setUpdatePaidTotal();
    }
  }

  private buildFeeHeaderAllForInstallment(installment: Installment, feeDetail: FeeDetail) : FeeDetail {
    this.feeHeads.forEach((item: FeeHead, index) => {       
      const feeDetailDet = {} as FeeDetailDetail;
      feeDetailDet.id = feeDetail.feeCollectionDetailDetailDtos.length;
      feeDetailDet.feedetailId= "";
      feeDetailDet.feeHeadId= item.id.toString();
      feeDetailDet.feeType= item.feeType;
      feeDetailDet.feeHeadName= item.feeHeadName;
      //if(item.id==1000)
      //{
      //item.amount=installment.fineAmount.toString();
      //item.dueAmount=(installment.fineAmount-Number(item.dueAmount)-installment.fineAmountPaid).toString();
      //}
      feeDetailDet.amount= (Number(item.dueAmount)>0 ? item.dueAmount : item.amount).toString();
      feeDetailDet.discountRate= (0).toFixed(2);
      feeDetailDet.amountPayable=Number(item.dueAmount)>0 ? item.dueAmount : item.amount;
      feeDetailDet.amountPaid= item.amountPaid;
      feeDetailDet.duesAmount= Number(item.dueAmount)>0 ? item.dueAmount : feeDetailDet.amountPayable;
      feeDetailDet.totalAmount= (Number(item.dueAmount)>0 ? Number(item.dueAmount) : Number(item.amount));
      feeDetailDet.isExtra=false;
      feeDetailDet.hasPaid=Math.abs(Number(item.dueAmount))>0?true:false;
      feeDetailDet.isFullyPaid=Number(feeDetailDet.amountPayable)-Number(item.discountRate)==Number(feeDetailDet.amountPaid)&& Math.abs(Number(item.dueAmount))==0?true:false;
      
      if(item.id==1000)
      {
        if(!feeDetailDet.isFullyPaid && Number(feeDetailDet.duesAmount)>0 &&  Number(feeDetailDet.amount)>0)
         { feeDetail.feeCollectionDetailDetailDtos.push(feeDetailDet);}
        else
        feeDetail.fineAmount=Number(feeDetailDet.amountPayable);
      }
      else
      {
        if(!feeDetailDet.isFullyPaid)
          feeDetail.feeCollectionDetailDetailDtos.push(feeDetailDet);
      }
    });
    return feeDetail;
  }

  private buildFeeHeaderFine(data: FeeHead[], installment: Installment) {
    const transFeeHead = data.find((fH: FeeHead) => fH.feeHeadTransactionId != 0);
    //If one item is available in Fee transaction head details table, we will skip the below logic until find the solution for for common fee
    //if (!transFeeHead) {
    //let feeHeadFineAmount = installment.fineAmountPaid != 0 ? installment.fineAmountPaid : installment.fineAmount;
      const FeeHead = {} as FeeHead;
      FeeHead.id = 1000;//Sam Changed FeeHead.id = 19;
      FeeHead.feeType = "Common";
      FeeHead.feeHeadName = "Fine Amount";
      FeeHead.amount = installment.fineAmount.toString();
      FeeHead.discountRate = (0).toFixed(2);
      FeeHead.dueAmount = (0).toFixed(2).toString();
      FeeHead.amountPaid = installment.fineAmountPaid.toFixed(2);
      FeeHead.feeHeadTransactionId = 0;
      
    //}
    const feeheads= this.feeHeads.findIndex(x=>x.id==1000);
    const datafeeheads= data.findIndex(x=>x.id==1000);
    if(datafeeheads==-1)
     { 
      data.push(FeeHead);      
     }
     this.feeHeads = data;
  }

  private buildFeeDetail(installment: Installment) {
    this.showFeeDetail.push(false);
    let feeDetail = {} as FeeDetail;
    feeDetail.installmentId = installment.id.toString();
    feeDetail.installmentName = installment.installmentName;
    feeDetail.installmentAmount = installment.installmentAmount;
    feeDetail.fineAmount = installment.fineAmount;
    feeDetail.discount = installment.discount;
    //feeDetail.paidAmount = (parseFloat(installment.amount) - parseFloat(installment.dueAmount)).toString();
    feeDetail.paidAmount = installment.paid;
    feeDetail.payableAmount= (Number(installment.installmentAmount)+installment.fineAmount-Number(installment.discount)-Number(installment.paid)).toString();//Sam Changed
    //feeDetail.payableAmount = this.getPayableAmount(installment.id,Number(installment.amount),installment.fineAmount,Number(installment.paid)).toString(); 
    //feeDetail.payableAmount = this.getPayableAmount(installment.id,Number(installment.amount),installment.fineAmount,Number(installment.paid),Number(installment.discount)).toString(); 
    if (Number(installment.dueAmount) !== 0) {
      feeDetail.dueAmount = installment.dueAmount.toString();
    } else {
      feeDetail.dueAmount = this.getDueAmount(installment.id, Number(installment.amount), installment.fineAmount, Number(installment.paid)).toString(); // (Number(installment.amount) - Number(installment.paid)).toString();
    }
    feeDetail.feeCollectionDetailDetailDtos = [];
    return feeDetail;
  }

  saveData() {
    
    if (this.feeDetailList.length === 0) {
      this.toastr.warning('Please select installament', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }
    if (this.paymentMode === '0') {
      this.toastr.warning('Please select Payment Mode', 'WARNING!', {
        timeOut: 3000
      });
      return false;
    }

    if (this.paymentModeNameSelected?.paymentModeName === 'Paytm' && this.paymentNote === '') {
      this.toastr.warning('Please enter Payment Note', 'WARNING!', {
        timeOut: 3000
      });
      return false;
    }
    
    if(this.feeDetailList.length>1){
    //   let amount=this.feeDetailList.reduce((sum: number, current) => sum + parseFloat(current.payableAmount), 0);        
    //   amount=amount-parseFloat(this.feeDetailList[this.feeDetailList.length-1].payableAmount)
    //   let validate=true;
    //   if(this.grandPaidTotal<=amount){    
    //     var message="Please pay more then "+amount;
    //     validate=false;    
    //     this.toastr.warning(message, 'WARNING!', {
    //       timeOut: 3000
    //     });
    //     return false;     
    //   }        
     }

    Swal.fire({
      title: 'Confirm Payment ?',
      html: '<i class="fa-solid fa-indian-rupee-sign"></i> ' + this.grandPaidTotal.toString(),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        
        const formData = {} as FeeMaster;
        formData.id = 0;
        formData.AcademicYearId = this.AcademicYearId;
        formData.BranchId = '0';
        formData.ClassId = this.classId;
        formData.FeeCollectionTypeId = '0';
        formData.BatchId = this.batchId;
        formData.admissionId = this.admissionId;
        formData.amount = this.grandTotal.toString();
        formData.chequeDate = this.formatDate(this.chequedate);
        formData.chequeNo = this.chequeNo;
        formData.bankName = this.bankName;
        formData.payDate = this.formatDate(new Date());
        formData.discount =  this.grandDiscountTotal.toString();
        formData.dueAdvance =Math.abs(Number(this.grandPaidTotal) - this.grandDueTotalToPay).toString();
        formData.paid = this.grandPaidTotal.toString();
        formData.payableAmount = this.grandPayableAmount.toString();
        
        if (Number(this.grandPaidTotal) > this.grandDueTotalToPay) {
          formData.dueAdvance = '0';
          formData.advance = this.grandDueTotal.toFixed(2).toString();
          formData.isAdvance = 'true';
        } else {
          if (this.grandAdvanceTotal !== 0) {
            if (this.grandAdvanceTotal >= this.grandTotal) {
              formData.advance =  '-' +(this.grandTotal).toFixed(2).toString();
              formData.isAdvance = 'true';
            } else {
              formData.advance = '-' + this.grandAdvanceTotal.toFixed(2).toString();
            }
          } else {
            formData.advance = '0';
          }
        }

        
        formData.paymentMode = this.paymentMode;
        formData.paymentNote = this.paymentNote;
        const payementName=this.paymentsModes.find(x=>x.id.toString()==this.paymentMode).paymentModeName;
        // if(payementName=='Cash' || payementName=='Cheque'){
        //   formData.transactionMode='Offline';
        // }else{
        //   formData.transactionMode='Online';
        // }

        // sorting the array list
        //this.feeDetailList.sort((a,b)=> parseInt(a.installmentId) - parseInt(b.installmentId));

        formData.transactionMode="Offline";
        /* Commented by Thiru
        this.feeDetailList.forEach((x, i) => {
          
          if (i !== this.feeDetailList.length - 1) {
            if (x.paidAmount === '0' || Number(x.paidAmount) === 0) {
              if (this.grandAdvanceTotal < this.grandTotal) {
                x.paidAmount = x.dueAmount;
              }
              x.dueAmount = '0';
            } else {
              x.paidAmount = x.dueAmount;
              x.dueAmount = '-' + x.dueAmount;
            }
          }
        });                
        if (this.grandPaidTotal > this.grandPayableAmount)
        {
            if (Number(this.feeDetailList[this.feeDetailList.length - 1].paidAmount) > 0) 
            {
              this.feeDetailList[this.feeDetailList.length - 1].dueAmount = '-' + this.feeDetailList[this.feeDetailList.length - 1].dueAmount;
            } 
          else {
              this.feeDetailList[this.feeDetailList.length - 1].dueAmount = '0';
          }
            this.feeDetailList[this.feeDetailList.length - 1].paidAmount = Number(this.grandPaidTotal).toFixed(2);
          } else if (this.grandPaidTotal < this.grandPayableAmount) {

          if (Number(this.feeDetailList[this.feeDetailList.length - 1].paidAmount) > 0 && this.grandAdvanceTotal === 0) {               
              this.feeDetailList[this.feeDetailList.length - 1].dueAmount = '-' + Number(this.grandPaidTotal).toFixed(2);

          } else {
            this.feeDetailList[this.feeDetailList.length - 1].dueAmount = Number(this.grandDueTotal).toFixed(2);
          }
          this.feeDetailList[this.feeDetailList.length - 1].paidAmount = Number(this.grandPaidTotal).toFixed(2);
        } else if ((this.grandPaidTotal === this.grandPayableAmount)) {
          if (Number(this.feeDetailList[this.feeDetailList.length - 1].paidAmount) > 0) {
            this.feeDetailList[this.feeDetailList.length - 1].dueAmount = '-' + this.feeDetailList[this.feeDetailList.length - 1].dueAmount;
          } else {
            this.feeDetailList[this.feeDetailList.length - 1].dueAmount = '0';
          }                        
          this.feeDetailList[this.feeDetailList.length - 1].paidAmount = Number(this.grandPaidTotal).toFixed(2);
        }
        */
        //ajay Sahai for corrrect due and advance payment corrrecton
        console.log("this.feeDetailList",this.feeDetailList);
        if(this.grandDueTotalToPay==this.grandPaidTotal){
          formData.dueAdvance=(0).toFixed(2);
        }else if (this.grandPaidTotal>this.grandDueTotalToPay){
        //  var v=this.grandPayableAmount-this.grandPaidTotal
          //var v = this.grandPayableAmount - this.grandTotal          
          formData.advance = (this.grandPaidTotal - this.grandDueTotalToPay).toString()
        } // this below advance condition changed by thiru - due to condition check was wrong
        else if(this.grandPaidTotal<this.grandDueTotalToPay){
          formData.dueAdvance= (this.grandPaidTotal - this.grandDueTotalToPay).toString();
        }
      // added by thiru
      let feeDetailsDeepCopy= JSON.parse(JSON.stringify(this.feeDetailList));
      const totalPaidAmnt = feeDetailsDeepCopy.reduce((sum: number, calc) => sum + (calc.paidAmount? parseFloat(calc.paidAmount) : 0), 0);              
      if(feeDetailsDeepCopy.length>1){
        //if(this.grandPaidTotal>=this.grandPaidTotal)
          {
          
          let payGrandAmount= Number(this.grandPaidTotal) + totalPaidAmnt;      
          feeDetailsDeepCopy.forEach((x : FeeDetail,index)=>{
               const feeHeadDtos = x.feeCollectionDetailDetailDtos;
               const discount=  feeHeadDtos
                               .reduce((sum: number, calc) => sum + (calc.discountRate? parseFloat(calc.discountRate) : 0), 0);              
               const payable=  feeHeadDtos
                            .reduce((sum: number, calc) => sum + (calc.amountPayable? parseFloat(calc.amountPayable) : 0), 0);                                             
              const amount=  feeHeadDtos
                        .reduce((sum: number, calc) => sum + (calc.amount? parseFloat(calc.amount) : 0), 0);                                             
              let locPayAmount= parseFloat(x.payableAmount);//(parseFloat(x.paidAmount)===0? parseFloat(x.payableAmount) : parseFloat(x.dueAmount))- discount;            
              if(locPayAmount<=payGrandAmount){
                  x.paidAmount = locPayAmount.toString();
                  payGrandAmount -= locPayAmount;
                  x.dueAmount=(0).toString();
              }
              else if(locPayAmount>payGrandAmount && payGrandAmount>0 ) {
                  x.paidAmount = (payGrandAmount).toString();
                  payGrandAmount-=locPayAmount;
                  x.dueAmount=(locPayAmount-parseFloat(x.paidAmount)).toString();
              } else {
                  x.paidAmount=(0).toFixed(2);;
                  payGrandAmount=0;
                  x.dueAmount=(locPayAmount-parseFloat(x.paidAmount)).toString();
              }   
              x.discount=discount.toString();
              x.payableAmount=payable.toString();
              x.installmentAmount = amount.toString();                                                                              
          });
        }
      }
      // added by Thiru for single Installment
      if(feeDetailsDeepCopy.length === 1){
        feeDetailsDeepCopy.forEach((feeDetail : FeeDetail,i)=>{
          const feeHeadDtos = feeDetail.feeCollectionDetailDetailDtos;
          const discount=  feeHeadDtos
                          .reduce((sum: number, calc) => sum + (calc.discountRate? parseFloat(calc.discountRate) : 0), 0);                       
          feeDetail.totaldueAmount= Math.abs(this.grandDueTotal);
          feeDetail.payableAmount = this.grandPayableAmount.toString();
          feeDetail.paidAmount= (Number(formData.paid)).toString();
          feeDetail.installmentAmount = (this.grandTotal+this.dueFineAmount).toString();
          feeDetail.dueAmount = Math.abs(this.grandDueTotal).toString();                    
          feeDetail.discount = discount.toString();
        });
      }
      //Due Ammount Adjsut to installemnt Start 
      var totalPaidAmount =Number( this.grandPaidTotal )+Number(this.grandAdvanceTotal);
      var bal=totalPaidAmount;
      var totpaying=0;
        feeDetailsDeepCopy.forEach((feeDetail : FeeDetail,i)=>{
        feeDetail.discount=  feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, calc) => sum + (calc.discountRate? parseFloat(calc.discountRate) : 0), 0).toString();              
        feeDetail.payableAmount=  feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, calc) => sum + (calc.amountPayable? parseFloat(calc.amountPayable) : 0), 0).toString();                                             

        if(bal==0)
          {feeDetailsDeepCopy.splice(i)}
         else if(Number(feeDetail.payableAmount)>bal)  
          { 
            feeDetail.paidAmount=Math.abs(bal).toString();
            feeDetail.dueAmount =(Number(feeDetail.payableAmount)-Math.abs(bal)).toString();
            bal=Number(feeDetail.paidAmount);
          }
         else if(Number(feeDetail.payableAmount)<=bal)//it will exeute for all advance 
          {
            feeDetail.paidAmount=Math.abs(Number(feeDetail.payableAmount)).toString();
            feeDetail.dueAmount =(0).toFixed(2);;
            bal=Number(feeDetail.paidAmount);
          }
          totpaying+=bal;
         bal=Math.abs(Number(totalPaidAmount))-totpaying;

        });
      ///Sam Changed Auto add next installment if balance is grater than 0
      //Sam Changed end
         bal=totalPaidAmount;
         totpaying=0;
          feeDetailsDeepCopy.forEach((feeDetail : FeeDetail,i)=>{
            //totpaying=0;
            bal=Math.abs(Number(feeDetail.paidAmount));
            //if(bal!=0)
            feeDetail.feeCollectionDetailDetailDtos.forEach((fDetail ,i)=>{
              if(Number(fDetail.amountPayable)>0 && bal>0){
               if(Number(fDetail.amountPayable)>bal)  
                { 
                  fDetail.amountPaid=Math.abs(bal).toString();
                  fDetail.duesAmount =(Number(fDetail.amountPayable)-Math.abs(bal)).toString();
                  bal=Number(fDetail.amountPaid);
                }
               else if(Number(fDetail.amountPayable)<=bal)
                {
                  fDetail.amountPaid=fDetail.amountPayable;
                  fDetail.duesAmount =(0).toFixed(2);;
                  bal=Number(fDetail.amountPaid);
                }
                totpaying+=bal;
               bal=totalPaidAmount-totpaying;
              }
              else{
                if(Number(fDetail.amount)>0)
                {
                fDetail.amountPaid=(0).toFixed(2);;
                fDetail.duesAmount =fDetail.amountPayable;
                }
              }
          });
        });
        formData.adjAdvance="0";
        //var totalpayable=this.feePayableAmount.reduce((sum: number, current) => sum + (Number(current)===0 || !current ?0: parseFloat(current.toFixed())), 0); 
        //if(Number(this.grandAdvanceTotal)<Number(totalpayable)&&Number(this.grandAdvanceTotal)>0)
        //{ formData.adjAdvance=Math.abs(this.grandAdvanceTotal-Number(totalpayable)).toString();}
        //else 
        formData.amount = feeDetailsDeepCopy.reduce((sum: number, calc) => sum + (calc.installmentAmount? parseFloat(calc.installmentAmount) : 0), 0);              
        formData.discount =feeDetailsDeepCopy.reduce((sum: number, calc) => sum + (calc.discount? parseFloat(calc.discount) : 0), 0);
        //formData.paid =feeDetailsDeepCopy.reduce((sum: number, calc) => sum + (calc.paidAmount? parseFloat(calc.paidAmount) : 0), 0);
        formData.payableAmount =feeDetailsDeepCopy.reduce((sum: number, calc) => sum + (calc.payableAmount? parseFloat(calc.payableAmount) : 0), 0);
        formData.fineAmount=feeDetailsDeepCopy.reduce((sum: number, calc) => sum + (calc.fineAmount? parseFloat(calc.fineAmount) : 0), 0);
        formData.amount =(Number(formData.amount) -Number(formData.fineAmount)).toString();
        var totaltopay= Number(formData.payableAmount)- this.grandAdvanceTotal;
        if(totaltopay==this.grandPaidTotal){
          formData.dueAdvance=(0).toFixed(2);
        }else if (this.grandPaidTotal>totaltopay){
               
          formData.advance = (this.grandPaidTotal - totaltopay).toString()
        }
        else if(this.grandPaidTotal<totaltopay){
          formData.dueAdvance= (this.grandPaidTotal - totaltopay).toString();
        }


        if(this.grandAdvanceTotal>0 )
        { 
          var res=this.grandPayableAmount;
          if(this.grandAdvanceTotal>res)
            formData.adjAdvance =res.toString();
           else if(this.grandAdvanceTotal<=res)
            formData.adjAdvance = this.grandAdvanceTotal.toString();
           else
           formData.adjAdvance ="0";
         }

        if(this.isAdvance){
          formData.advance=this.grandDueTotal.toString();
          formData.amount=Math.abs(Number(formData.amount)).toString();
          
          formData.payableAmount=(Number(formData.payableAmount)<0?0:Number(formData.payableAmount)).toString();
          formData.paid=this.grandPaidTotal.toString();
        }else{
          formData.advance="0";
        }

      //Due Ammount Adjsut to installemnt End
        formData.feeCollectionDetailDtos = feeDetailsDeepCopy;


        //formData.fineAmount=this.dueFineAmount.toString();
        
        formData.advanceReceiptNo=this.advanceReceipt;
        formData.isCancelled = 'false'; 
        formData.studentInstallmentId = this.studentTravellerInstallmentId;     
        formData.transportFee = this.studentTravellerFee.toString();
        formData.cancelReason = '';
        formData.dueAdvance=Math.abs(Number(formData.dueAdvance)).toString();
        formData.advanceReceiptNo= this.advanceReceipt;
        this.feeCollectionService.create(formData).subscribe(res => {  
         let TransactionId = res; 
                          
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.resetAfterSaveForm();          
          this.paymentMode = '0';
          Swal.fire({
            title: 'Payment done successfully.',
            html: 'Do you want to print receipt?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then((presult) => {
            
            if (presult.value) {
              this.feeCollectionService.getRecieptsByStudentId(this.admissionId).subscribe((data: FeeMaster[]) => {
                 let result:any=data.filter((x)=>x.id==TransactionId);
                 console.log("result ",result,TransactionId);
                 if(result.length>0){
                  /*
                  this.feeReceiptService.getFeeReciept(result[0].receiptNo).subscribe((data) => {
                    let d=data;
                    console.log("fee receipt data",data);
                  const newRelativeUrl = this.router.createUrlTree(['../report/feereceipt/',''], { relativeTo: this.activatedRoute });
            
                  
                  const baseUrl = window.location.href.replace(this.router.url, '');
                  console.log("baseUrl",baseUrl);
                  console.log("window open", baseUrl + newRelativeUrl+result[0].receiptNo);
                  window.open(baseUrl + newRelativeUrl+result[0].receiptNo, '_blank');
                  
                  });*/
                  // ####################BUG####################
                  const newRelativeUrl = this.router.createUrlTree(['../report/feereceipt/','' /*res*/], { relativeTo: this.activatedRoute });
                
                  const baseUrl = window.location.href.replace(this.router.url, '');
                  window.open(baseUrl + newRelativeUrl+result[0].id, '_blank');
                // >>>>>>> HRMSPayroll
                 }
               
              });

            


            } else if ( presult.dismiss === Swal.DismissReason.cancel) {
            }
          });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });

  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('-');
  }

  listReciepts() {
    this.feeReciepts = [];
    if (this.admissionId === '') {
      this.toastr.warning('Please select student', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }
    this.feeCollectionService
        .getRecieptsByStudentId(this.admissionId)
        .subscribe((data: FeeMaster[]) => {
          this.feeReciepts = data;      
        });
  }

  isAllCheckBoxChecked() {
    return this.installments.every(p => p.checked);
  }

  onPaymentmodeChange(value: string) {
    this.paymentModeNameSelected=this.paymentsModes.filter(x=>x.id==parseInt(value))[0];
     if (this.paymentModeNameSelected?.paymentModeName === 'Cash') {
       this.IsBankSelected = false;
       this.IsChequeSelected = false;
     } else if (this.paymentModeNameSelected?.paymentModeName=== 'Bank') {
       this.IsChequeSelected = false;
       this.IsBankSelected = true;
     } else if (this.paymentModeNameSelected?.paymentModeName === 'Cheque') {
       this.IsChequeSelected = true;
       this.IsBankSelected = true;
     } else {
       this.IsChequeSelected = false;
       this.IsBankSelected = false;
     }
   }
    getInstallmentPayableAmount(id:number)
    {
        return Number(this.feePayableAmount[id]);
    }
   getPayableAmount(id:number,amount:number,fineamount:number,paidAmount:number,installmentAmount:number){    
    let payableAmount =0;
    if(paidAmount>0){
      payableAmount = installmentAmount;
    }else{
      payableAmount = installmentAmount+fineamount;
    }
    if(id>=this.studentTravellerInstallmentId){      
      payableAmount += this.studentTravellerFee;      
    }
    return payableAmount;
  }

  getDueAmount(id:number,amount:number,fineAmount:number,paidAmount:number,dueAmount:number=0){
    //let dueAmount =0;
    let stuTravelFee :number = 0;
    if(id>=this.studentTravellerInstallmentId){
      stuTravelFee=this.studentTravellerFee
    }
    if(paidAmount<=0)      {
      dueAmount = (amount + fineAmount + this.studentTravellerFee) - paidAmount;
    }      
    else if(amount!=paidAmount){
      return dueAmount;
    }
    else{
      dueAmount = 0;
    }
    return dueAmount;
  }

  trackItemByIntsallmentId(index: number, item: Installment){
    return item.installmentId;
  }

  trackFeeDetailsByIntsallmentId(index: number, item: FeeDetail){
    return item.id;
  }

  openLookUp() {
    this.resetLookUpStudent();
  }

  showDetail() {
    if (this.admissionId === '') {
      this.toastr.warning('Please select student', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }else{
      this.showStudentDetail = !this.showStudentDetail;
    }   
  }

  onCancel(id: number) {
    this.selectedMasterId = id;
  }
  onPrint(id: number) {
    this.selectedMasterId = id;
    return "../report/feereceipt/"+id; 
  }

  cancelReciept() {
    this.feeCollectionService.cancel(this.selectedMasterId, this.cancelReason).subscribe(() => {        
      $('#cancelFeeReceipt').modal('toggle');
      this.listReciepts();
      //this.resetAllFormVariables();
      this.resetAfterSaveForm(); 
    });
  }

  CSSApllyinstalmentTable(instal:any){
    if(instal.dueAmount > 0){
      return "bg-danger text-white";
    }
    if(instal.isPaid==true && (instal.dueAmount == 0 && instal.paid>0)){
      return "tdcolor";
    }
    if(this.getDueAmount(instal.id,instal.amount,instal.fineAmount,instal.paid)==0
    && this.getPayableAmount(instal.id,instal.amount,instal.fineAmount,instal.paid,instal.installmentAmount)!=0){
      return "tdcolor";
    }    
    else if(instal.isWaved==true || instal.dueAmount != 0){
      return "bg-danger text-white"
    }
    
   
  }

  resetLookUpStudent() {
    this.lookupAdmissions = [];
    this.lookupStudentName = '';
    this.lookupMobileNo = '';
    this.lookupFatherName = '';
  }

  resetGrandSummaryVariables(){
    this.grandPaidTotal = 0;
    //this.grandDiscountTotal = 0;
    //this.grandDueTotal = 0;
    //this.grandAdvanceTotal = 0;
    //this.grandTotal = 0;
    //this.grandPayableAmount = 0;
    //this.isAdvancePay = false;
    //this.isDueAvanceShow = false;
    this.feeDetailList = [];
    this.feeDiscountTotal = [];
    this.feeTotal =[];
    this.feePayableAmount = [];
    this.feePaidTotal =[];
    this.feeDueTotal = [];
    this.paymentMode = '0';
    this.paymentNote = '';   
  }

  resetSummaryCommonDetails(){    
    this.chequeNo = '';
    this.bankName = '';
    this.paymentMode = '0';
    this.paymentNote = '';
    this.totalPaid = 0;
    this.currentDue = 0;
    this.totalDue = 0;  
    this.installments = [];
    this.admissions = [];    
    this.studentDetail = {} as Student; 
    this.showStudentDetail=false;
  }

  resetAllFormVariables() {
    this.classId = '';
    this.batches = [];//onClassSelected
    this.batchId = '';    
    this.lookupAdmissions = [];    
    this.feeTotal = [];  
    this.feePayableAmount =[];  
    this.admissionno = '';
    this.resetSummaryCommonDetails();             
    this.studentTravellerFee = 0;
    this.feeReciepts = [];
    this.admissionId='';    
  }

  resetAfterSaveForm() {
    this.installments = [];
    this.lookupAdmissions = [];
    this.feeDetailList = [];
    this.grandPaidTotal = 0;    
    this.totalPaid = 0;
    this.totalDue = 0;
    this.currentDue = 0;
    this.chequeNo = '';
    this.bankName = '';
    this.paymentMode = '0';
    this.paymentNote = '';   
    this.resetGrandSummaryVariables();
    this.onPaymentmodeChange('1');
    this.onStudentChanged(Number(this.admissionId));
    this.studentTravellerFee = 0;
  }

  resetAllFormVariablesForBatch() {     
     this.lookupAdmissions = [];    
     this.feeTotal = [];  
     this.feePayableAmount =[];   
     this.admissionno = '';
     this.resetSummaryCommonDetails();    
     this.studentTravellerFee = 0;
     this.feeReciepts = [];
     this.admissionId='';   
     this.paymentMode = '0';
     this.paymentNote = '';   
   }

   resetEForm() {
    this.classId = '';
    this.batches = [];    
    this.lookupAdmissions = [];   
    this.resetSummaryCommonDetails();      
    this.onPaymentmodeChange('1');
    this.showDetail();    
  }
}
