import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {MasterSettingsRoutingModule} from './mastersettings-routing.module';

import { LoaderService } from '../../../shared/loader.service';
import { LoaderInterceptor } from '../../../shared/loader-interceptor.service';
import { LoaderComponent } from '../../../shared/loader/loader.component';

import { MastersettingsComponent } from './mastersettings/mastersettings.component';
import { AcademicyearComponent } from './academicyear/academicyear.component';
import { ClasscourseComponent } from './classcourse/classcourse.component';
import { CampusbuildingComponent } from './campusbuilding/campusbuilding.component';
import { CasteComponent } from './caste/caste.component';
import { CategoryComponent } from './category/category.component';
import { CityComponent } from './city/city.component';
import { CountryComponent } from './country/country.component';
import { FloorComponent } from './floor/floor.component';
import { NationalityComponent } from './nationality/nationality.component';
import { PaymentmodeComponent } from './paymentmode/paymentmode.component';
import { ReligionComponent } from './religion/religion.component';
import { RoomComponent } from './room/room.component';
import { StateComponent } from './state/state.component';
import { StreamComponent } from './stream/stream.component';
import { TermComponent } from './term/term.component';
import { BatchComponent } from './batch/batch.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    // LoaderComponent,
    MastersettingsComponent,
    AcademicyearComponent,
    ClasscourseComponent,
    CampusbuildingComponent,
    CasteComponent,
    CategoryComponent,
    CityComponent,
    CountryComponent,
    FloorComponent,
    NationalityComponent,
    PaymentmodeComponent,
    ReligionComponent,
    RoomComponent,
    StateComponent,
    StreamComponent,
    TermComponent,
    BatchComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    //BsDatepickerModule,
    MasterSettingsRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class MastersettingsModule { }
