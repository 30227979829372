import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CService, UsesReport } from './uses-report.model';
import { UsesReportService } from './uses-report.service';
declare var $: any;
@Component({
  selector: 'app-uses-report',
  templateUrl: './uses-report.component.html',
  styleUrls: ['./uses-report.component.css']
})
export class UsesReportComponent implements OnInit {

  selectedService = '0';
  selectedFromDate: Date;
  selectedToDate: Date;
  defaultValue = '';

  serviceList: CService[] = [];
  usesReport: UsesReport[] = [];
  usesReportList: UsesReport[] = [];

  constructor(private usesreportService: UsesReportService, private toastr: ToastrService) { }

  getAllServices() {
    this.usesreportService.getAllServices().subscribe((data: CService[]) => {
      this.serviceList = data;
      // console.log(this.serviceList);
    });
  }
  getAllUsesReport() {
    this.usesreportService.getAllUsesReportData().subscribe((data: UsesReport[]) => {
      this.usesReport = data;
    });
  }
  onKeyUpEvent(event: any) {
    this.clearFilter() ;
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === '') {
      this.usesReportList = this.usesReport;
    } else {
      this.usesReportList = this.usesReport
        .filter((uses: UsesReport) => uses.serviceName
          .includes(keyword.toLocaleLowerCase())
        );
    }
  }
  filterReport() {
    if (this.selectedFromDate !== undefined &&
      this.selectedService !== undefined &&
      this.selectedToDate !== undefined) {

      this.usesReportList = this.usesReport.filter(element => {
        return element.serviceId.toString() === this.selectedService.toString();
        // && new Date(element.statusDate).getTime() >= new Date(this.selectedFromDate).getTime()
       //  && new Date(element.statusDate).getTime() <= new Date(this.selectedToDate).getTime();
      });


      if (this.usesReportList.length === 0) {
        this.toastr.warning('Records not found!', 'Sorry', {
          timeOut: 4000
        });
        // this.usesReportList = this.usesReport;
      }
    } else {
      this.toastr.warning('All fields are mandatory !', 'Sorry', {
        timeOut: 4000
      });
    }

  }

  clearFilter() {
    this.selectedService = '';
    this.defaultValue = null;
    this.usesReportList = this.usesReport;
  }

  getDateOnly(usesDate: any) {
    return usesDate.split('T')[0];
  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('-');
  }
  ngOnInit(): void {

    this.defaultValue = this.formatDate(new Date());

    $('#fromDate').datepicker().on('changeDate', (e) => {
      this.selectedFromDate = new Date(e.date);
    });

    $('#toDate').datepicker().on('changeDate', (e) => {
      this.selectedToDate = new Date(e.date);
    });
    this.getAllUsesReport();
    this.getAllServices();
  }

}
