import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Institution } from '../admin/institution.model';
import { AdminService } from '../admin/admin.service';
import { AnalyticService } from '../../shared/analytic.service';
import { StudentService } from '../academic/studentmanagement/student.service';
import { Student } from '../academic/studentmanagement/student.model';
import { InstitutionGuard } from 'src/app/authentication/guards/institution.guard';
import { environment } from 'src/environments/environment';

/* import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexMarkers,
  ApexXAxis,
  ApexPlotOptions,
  ApexLegend,
  ApexGrid,
} from 'ng-apexcharts'; */
//import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { DashboardService } from './dashboard.service';
import { AcademicSummary, AttendanceSummary, PendingToDoSummary, SessionWiseStudentReport, SessionWiseStudentTotalsReport, DashboardClassWiseStudentsSummary, DashboardClassWiseStudentsTotalSummary, DashboardDuesPaidDto, DepartmentWiseEmployeeCountDto, DepartmentWiseSalaryDto, EmployeeJoiningAndExitSummaryReport, SessionWiseSalary } from './attendanceSummary.model';
import { Subscription } from 'rxjs';
import { SettingsService } from '../academic/mastersettings/settings.service';
import { Academicyear } from '../academic/mastersettings/models/academicyear';
import { SharedService } from '../shared.service';
import { ERPSettingService } from '../erpsettings/erpsettings.service';
import { ERPDashboardSetting } from '../erpsettings/erpsettings.model';
import { EmployeeadmissionService } from '../OperationActivity/hrmspayroll/hrms/employeeadmission.service';
import { ApexAxisChartSeries, ApexChart, ApexFill, ApexGrid, ApexLegend, ApexMarkers, ApexPlotOptions, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
declare var $: any;

export interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  labels: string[];
  stroke: any; // ApexStroke;
  markers: ApexMarkers;
  plotOptions: ApexPlotOptions;
  colors: string[];
  fill: ApexFill;
  tooltip: ApexTooltip;
  legend: ApexLegend;
  grid: ApexGrid;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChild("chart") chart: ChartComponent;


  public chartOptions: Partial<ChartOptions>;


  instCode = '';
  institution: any; // Institution = {} as Institution;
  currentIns: any;
  analyticData: any;
  periodTypeValue = 6;
  studentCount = 0;
  employeeCount = 0;
  admissions: Student[] = [];
  selectedCurrentYear: number;
  EmployeeAttendanceSummary: AttendanceSummary;
  StudentAttendanceSummary: AttendanceSummary;
  PendingToDoSummarySummary: PendingToDoSummary;
  academicSummary: AcademicSummary;
  subscription: Subscription;
  academicYearIdSelected: any;
  dashboardDuesPaid: DashboardDuesPaidDto;
  @ViewChild('currentAcademicYearDropDown') currentAcademicYearSelect: ElementRef;
  @Input() currentAcademicYearId: number;
  academicYears: Academicyear[];
  paidAmount: number = 0;
  totalDueAmount: number = 0;
  graphlist: any[] = [];
  isLoading: boolean = false;
  isToDoTaskLoading: boolean = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private sharedService: SharedService,
    private studentService: StudentService,
    private dashboardService: DashboardService,
    private adminService: AdminService,
    private analyticService: AnalyticService,
    private academicYearService: SettingsService,
    private erpSettingService: ERPSettingService,
    private empAdmissionService: EmployeeadmissionService

  ) { 
    this.loadChartData();
  }
  tabpanel: Number = 0
  tabclick(settingUrl) {
    if (settingUrl === '#dashboard')
      this.tabpanel = 0;
    else if (settingUrl === '#academics')
      this.tabpanel = 1;
    else if (settingUrl === '#finance')
      this.tabpanel = 2;
    else if (settingUrl === '#hrmspayroll')
      this.tabpanel = 3;
    else if (settingUrl === '#transport')
      this.tabpanel = 4;
    else if (settingUrl === '#operations')
      this.tabpanel = 5;
    else if (settingUrl === '#edulink')
      this.tabpanel = 6;
    else
      this.tabpanel = 0;

  }
  loadChartData() {
    this.chartOptions = {
      chart: {
        height: 335,
        type: "line",
        stacked: !1,
        toolbar: {
          show: !1
        }
      },
      stroke: {
        width: [0, 2, 4],
        curve: "smooth"
      },
      plotOptions: {
        bar: {
          columnWidth: "50%"
        }
      },
      colors: ["#f46a6a", "#556ee6", "#34c38f"],
      series: [{
        name: "Total Admissionssssssss",
        type: "column",
        data: [23, 11, 22, 27, 13, 22, 37]
      }, {
        name: "Total Enquires",
        type: "area",
        data: [44, 55, 41, 67, 22, 43, 21]
      }, {
        name: "Total Registrations",
        type: "line",
        data: [30, 25, 36, 30, 45, 35, 64]
      }],
      fill: {
        opacity: [.85, .25, 1],
        gradient: {
          inverseColors: !1,
          shade: "light",
          type: "vertical",
          opacityFrom: .85,
          opacityTo: .55,
         /*  stops: [0, 100, 100, 100] */
        }
      },

      legend: {
        offsetY: -10
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      yaxis: {
        title: {
          text: ""
        }
      },
      tooltip: {
        shared: !0,
        intersect: !1
        // y: {
        //     formatter: (e) => {
        //         return void 0 !== e ? e.toFixed(0) + "" : e
        //     }
        // }
      },
      grid: {
        borderColor: "#f1f1f1"
      }
    };
  }

  ListInstitutions() {
    /*this.adminService.getByCode(this.instCode).subscribe((data: Institution) => {
     if (data.branchCount.toString().length === 1) {
       data.branchCount = '0' + data.branchCount;
     }
     if (data.medium === '') {
       data.medium = 'English';
     }
     if(data.logoUrl !== null ) {
       data.logoUrl = environment.imageUrl + data.logoUrl;
     } else if (data.logoUrl === null) {
       data.logoUrl = 'assets/images/SchoolLogo/demologo.png';
     }
     this.institution = data;
      //this.institution = data;

    });*/

    ;
    const data = JSON.parse(localStorage.getItem('Institution_' + this.instCode));
    if (data.branchCount.toString().length === 1) {
      data.branchCount = '0' + data.branchCount;
    }
    if (data.medium === '') {
      data.medium = 'English';
    }
    if (data.logoUrl !== null) {
      data.logoUrl = environment.imageUrl + data.logoUrl;
    } else if (data.logoUrl === null) {
      data.logoUrl = 'assets/images/SchoolLogo/demologo.png';
    }
    this.institution = data;




    //this.getPendingToDoSummarySummary();
    /* this.getPendingToDoSummarySummary(3);
     this.getAcademicSummary(3);
     this.getDashBoardSessionWiseStudentReport(3);
     //this.getPendingToDoSummarySummary(this.sharedService.academicYearId);
     //this.getAcademicSummary(this.sharedService.academicYearId);
   /* if (this.sharedService.academicYearId>0)  {
     this.getPendingToDoSummarySummary(this.sharedService.academicYearId);
    }*/

  }
  ListAcademicYear() {
    this.academicYearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
    });
  }

  getStudentCount() {
    const academicYearId = parseInt(localStorage.getItem("academicYearId"));
    this.studentService.getCountStudents(academicYearId).subscribe((data) => {
      this.studentCount = data;
    });
  }
  getEmployeeCount() {
    this.dashboardService.getEmployeeCount().subscribe((data: number) => {
      this.employeeCount = data;


    });
  }

  gettotalduespaid(academicId: number) {
    this.dashboardService.gettotalduespaid(academicId).subscribe((data: DashboardDuesPaidDto) => {
      this.paidAmount = data[0]?.paidAmount;
      this.totalDueAmount = data[0]?.totalDueAmount;


    });
  }
  getStudentAttendanceSummary() {
    this.dashboardService.getStudentAttendanceSummaryCount().subscribe((data: AttendanceSummary) => {
      this.StudentAttendanceSummary = data;
    });
  }
  getEmployeeAttendanceSummary() {
    this.dashboardService.getEmployeeAttendanceSummaryCount().subscribe((data: AttendanceSummary) => {
      this.EmployeeAttendanceSummary = data;


    });
  }

  setCurrentAcademicYear(id: number) {
    return id;
  }
  getPendingToDoSummarySummary() {
    this.dashboardService.getPendingToDoListSummary().subscribe((data: PendingToDoSummary) => {
      this.PendingToDoSummarySummary = data;
      this.setProgressBar("admissionProgress", data.pendingAdmissionEnquiry, data.totalAdmissionEnquiry, "Pending Admission : " + data.pendingAdmissionEnquiry);
      this.setProgressBar("visitorEnquiryProgress", data.pendingVisitorEnquiry, data.totalVisitorEnquiry, "Pending Admission Enquiry : " + data.pendingVisitorEnquiry);
      this.setProgressBar("leaveRequestApprouvalProgress", data.totalLeaveRequestApproval, data.pendingLeaveRequestApproval, "Pending Leave Request : " + data.pendingLeaveRequestApproval);
      this.setProgressBar("complaintProgress", data.totalStudentComplaints, data.totalStudentComplaints, "Complaint : " + data.totalStudentComplaints);
      this.setProgressBar("leaveRequestPendingProgress", data.totalLeaveRequestPending, data.pendingLeaveRequestPending, "Leave Pending Request : " + data.totalLeaveRequestPending);
      this.setProgressBar("feeCollectionProgress", data.totalFees, data.dueFees, "Paid Students : " + data.totalFees);

      this.isToDoTaskLoading = false;
    });
  }
  setProgressBar(progressname: any, nowValue: number, maxValue: number, title: string) {

    var progressBar = document.getElementById(progressname);
    if (progressBar != null) {
      progressBar.setAttribute("role", "progressbar");
      progressBar.setAttribute("aria-valuenow", nowValue.toString());
      progressBar.setAttribute("aria-valuemax", maxValue.toString());
      progressBar.setAttribute("title", title);
    }
  }
  getAnalyticData(periodType) {
    this.analyticService.getAnalyticData(periodType).subscribe((data: any) => {
      console.log(data);
      this.analyticData = data as any;
      this.getAnalyticGraphData(periodType);

    });
  }

  getAnalyticGraphData(periodType) {

    this.analyticService.getAnalyticGraphData(periodType).subscribe((graphdata: any) => {
      console.log(graphdata)
      this.graphlist = graphdata.Table;//JSON.parse(graphdata);
      let lableData = []
      let admissionData = [], visitorData = [], registerData = [];

      for (let d of this.graphlist) {
        if (d.TypeofData === 'StudentAdmissions') {
          lableData.push(d.DaysName)
          admissionData.push(d.DataCount)
        }
        if (d.TypeofData === 'VisitorEnquiries') {
          visitorData.push(d.DataCount)
        }
        if (d.TypeofData === 'ApplicantRegistrations') {
          registerData.push(d.DataCount)
        }
      }

      this.chart?.updateOptions({
        xaxis: {
          categories: [...lableData]
        },
      });

      this.chart?.updateSeries([{
        name: "Total Admissions",
        type: "column",
        data: [...admissionData]
      }, {
        name: "Total Enquires",
        type: "area",
        data: [...visitorData]
      }, {
        name: "Total Registrations",
        type: "line",
        data: [...registerData]
      }]);


    });
    //this.isLoading = false;

  }


  onDataPeriodChange(periodType) {
    this.getAnalyticData(periodType);
  }

  ngOnInit(): void {    
    console.log(JSON.parse(localStorage.getItem('Institution')));
    this.instCode = this.sharedService.institutionCode;
    this.isLoading = true;
    this.isToDoTaskLoading = true
    this.getStudentCount();

    setTimeout(() => {
      this.currentAcademicYear();
      this.ListInstitutions();

      this.ListAcademicYear();
      this.getAnalyticData(this.periodTypeValue);
      this.getEmployeeCount();
      this.getStudentAttendanceSummary();
      this.getEmployeeAttendanceSummary();
      this.getPendingToDoSummarySummary();
      this.getAllERPDashboardSettings(); //loader false set in this method
      $('#myModal').modal('show');
    }, 100);

  }
  allERPDashboardSettings: ERPDashboardSetting[] = [];
  getAllERPDashboardSettings() {
    this.erpSettingService.getAllERPDashboardSettings().subscribe((data: ERPDashboardSetting[]) => {
      this.allERPDashboardSettings = data.filter(d => d.status);
      this.isLoading = false;

    });
  }


  currentAcademicYear() {
    this.currentAcademicYearId = this.sharedService.academicYearId;
    //this.gettotalduespaid(this.currentAcademicYearId);


    // this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
    //    this.currentAcademicYearId=data.id;

    //  });

  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      //HRMS apis
      this.sharedService.getAllDepartmentWiseEmployee(this.sharedService.academicYearId);
      this.sharedService.getDepartmentWiseSalary(this.sharedService.academicYearId);
      this.sharedService.getemployeeJoiningExit(this.sharedService.academicYearId);   
      this.sharedService.getSessionWiseSalaryReport();
      this.sharedService.getEmployeeLeaveAttendanceReport(this.sharedService.academicYearId);

      //Academic apis
      this.sharedService.getAcademicSummary(this.sharedService.academicYearId);
      this.sharedService.getDashBoardSessionWiseStudentReport(this.sharedService.academicYearId); 
      this.sharedService.getDashboardClassWiseStudentsSummary(this.sharedService.academicYearId);
      this.sharedService.getAcademicCategoryNameReligions(this.sharedService.academicYearId);
    }, 100);
   
    // let s = document.createElement('script');
    // s.type = 'text/javascript';
    // s.src = '../assets/apexcharts/apexcharts.min.js';
    // this.elementRef.nativeElement.appendChild(s);
    // s = document.createElement('script');
    // s.type = 'text/javascript';
    // s.src = '../assets/js/pages/apexcharts.init.js';
    // this.elementRef.nativeElement.appendChild(s);


    // //this.currentAcademicYear();
    //this.currentAcademicYearSelect.nativeElement.value=this.sharedService.academicYearId;
    //this.getPendingToDoSummarySummary();

  }

}
