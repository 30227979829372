import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubjectWiseExamReportComponent } from './subject-wise-exam-report.component';
const routes: Routes = [
  {
    path:'SubjectWiseExamReport', component: SubjectWiseExamReportComponent
  }
];


@NgModule({
  declarations: [SubjectWiseExamReportComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SubjectWiseExamReportModule { }
