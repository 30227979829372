import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalyticalreportingsRoutingModule } from './analyticalreportings-routing.module';
import { AnalyticalreportingsComponent } from './analyticalreportings/analyticalreportings.component';


@NgModule({
  declarations: [AnalyticalreportingsComponent],
  imports: [
    CommonModule,
    FormsModule,
ReactiveFormsModule,
    AnalyticalreportingsRoutingModule
  ]
})
export class AnalyticalreportingsModule { }
