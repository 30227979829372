import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AssignInstallmentToGroup } from './assigninstallmenttogroup.model';
import { AssignInstallmentToGroupService } from './assigninstallmenttogroup.service';
import { GroupService } from './../group/group.service';
import { Group } from './../group/group.model';
import { InstallmentService } from './../installment/installment.service';
import { Installment } from './../installment/installment.model';

@Component({
  selector: 'app-assignintallmenttogroup',
  templateUrl: './assignintallmenttogroup.component.html',
  styleUrls: ['./assignintallmenttogroup.component.css']
})
export class AssignintallmenttogroupComponent implements OnInit {

  assignForm: UntypedFormGroup;
  assigngroups: AssignInstallmentToGroup[] = [];
  installments: Installment[] = [];
  selectedinstallment: Installment = {} as Installment;
  groups: Group[] = [];
  groupFilteredList: Group[] = [];
  installmentsFilterList: Installment[] = [];
  assigngroupsFilteredList: AssignInstallmentToGroup[] = [];
  searchFilteredList: AssignInstallmentToGroup[] = [];
  baseFilteredList: AssignInstallmentToGroup[] = [];
  feeGroupId:any;
  HighlightRow : Number;
  ClickedRow:any;

  submitted = false;
  isLoading: boolean=false;

  constructor(
    public assignInstallmentToGroupService: AssignInstallmentToGroupService,
    public installmentService: InstallmentService,
    public groupService: GroupService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  )  

{ 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }

  }


  ngOnInit(): void {
    this.ListGroup();
    this.ListInstallment();
   // this.ListAssigns();

    this.assignForm = this.fb.group({
      id: [0],
      installmentId: ['', Validators.required],
      feeGroupId: ['', Validators.required],
      stypeId:['', Validators.required],
      status: ['true', Validators.required]
    });
  }

  onFilterSelected(value){
    //this.ListAssigns();
    if (value === '') {
      this.groupFilteredList = this.groups;
      this.installmentsFilterList=this.installments;
    } else {
      this.groupFilteredList = this.groups
        .filter((group: Group) => group.stypeId === value);

        this.installmentsFilterList = this.installments
        .filter((installment: Installment) => installment.stypeId === value);
    }
  }

  ListAssigns(){
   this.isLoading=true;
      this.assignInstallmentToGroupService.getAll().subscribe((data: AssignInstallmentToGroup[]) => {
        console.log(data);
         this.baseFilteredList = this.assigngroupsFilteredList = this.assigngroups = data;
         this.feeGroupId=this.assignForm?.controls['feeGroupId']?.value;
         if(this.feeGroupId){
         this.assignForm?.controls['feeGroupId']?.setValue(this.feeGroupId);
          //this.onSearchGroupChange(this.assignForm?.controls['feeGroupId']?.value);
          this.assigngroupsFilteredList=this.assigngroupsFilteredList.filter(x=>x.feeGroupId==this.feeGroupId);
         }
         this.isLoading=false;
      });
   
  }

  ListGroup() {
    this.groupService.getAll().subscribe((data: Group[]) => {
      this.groupFilteredList=this.groups = data;
    });
  }

  ListInstallment() {
    this.installmentService.getAll().subscribe((data: Installment[]) => {
      this.installmentsFilterList=this.installments = data;
    });
  }

  onInstallmentSelected(value: number) {
    this.installmentService.getById(value).subscribe((data: Installment) => {
      console.log(data);
      this.selectedinstallment = data;
      this.ListAssigns();  
    });

  }

  onSearchGroupChange(value: string) {
    this.selectedinstallment = {} as Installment;
    this.assignForm.controls['installmentId'].setValue(''); 
    this.ListAssigns();   
    this.assigngroupsFilteredList = this.assigngroups;
    if (value === '0') {
      this.assigngroupsFilteredList = this.assigngroups;
    } else {
      this.searchFilteredList = this.assigngroupsFilteredList = this.assigngroups.filter((x) => {
        return x.feeGroupId.toString() === value.toString();
      });
    }
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (this.searchFilteredList.length > 0) {
      if (keyword === '') {
        this.assigngroupsFilteredList = this.assigngroups;
      } else {
        this.assigngroupsFilteredList = this.assigngroups
          .filter((assign: AssignInstallmentToGroup) => assign.installmentName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase()) ||
            assign.groupName.toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          );
      }
    } else {
      if (keyword === '') {
        this.assigngroupsFilteredList = this.assigngroups;
      } else {
        this.assigngroupsFilteredList = this.assigngroups
          .filter((assign: AssignInstallmentToGroup) => assign.installmentName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase()) ||
            assign.groupName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
          );
      }
    }
  }

  get totalRows(): number {
    return this.assigngroupsFilteredList.length;
  }
  get f() { return this.assignForm.controls; }
  resetForm() {
    this.submitted = false;
    this.selectedinstallment = {} as Installment;
    this.HighlightRow=-1;
    this.assignForm = this.fb.group({
      id: [0],
      installmentId: ['', Validators.required],
      feeGroupId: ['', Validators.required],
      stypeId:['', Validators.required],
      status: ['true', Validators.required]
    });
  }
  submitForm() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.assignForm.invalid) {
      return;
    }

    if (this.assignForm.get('id').value === 0) {
      //this.assigngroupsFilteredList.push(this.assignForm.value);
      this.assignInstallmentToGroupService.create(this.assignForm.value).subscribe(res => {
        
        console.log('Assign created!');
        
      // this.ListAssigns().then(res => this.onSearchGroupChange(this.assignForm?.controls['feeGroupId']?.value));
     
        
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
     
        this.ListAssigns();
       // this.feeGroupId=this.assignForm?.controls['feeGroupId']?.value;
       
        this.selectedinstallment = {} as Installment;
        this.assignForm.controls['installmentId'].setValue('');
       // this.resetForm();
        this.submitted = false;
        //this.resetForm();
       
      });
    } else {
      this.assignInstallmentToGroupService.update(this.assignForm.get('id').value, this.assignForm.value).subscribe(res => {
        console.log('Assign updated!');
        this.ListAssigns();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        //this.resetForm();
      });
    }


  }

  updateForm(id: number) {
    this.assignInstallmentToGroupService.getById(id).subscribe((data: AssignInstallmentToGroup) => {
      console.log(data);
      this.onInstallmentSelected(parseInt(data.installmentId, 0));
      this.assignForm = this.fb.group({
        id: [data.id],
        installmentId: [data.installmentId.toString(), Validators.required],
        feeGroupId: [data.feeGroupId.toString(), Validators.required],
        stypeId:['', Validators.required],
        status: [data.status.toString(), Validators.required]
      });
    });

  }

  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.assignInstallmentToGroupService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListAssigns();
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }

  

}
