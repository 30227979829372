import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StudentDetailsModel} from './studentpaymentreport.model';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentPaymentReportService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  
  getStudentDetails(formData): Observable<any> {
  return this.httpClient.post(environment.apiUrl + '/FeeCollection/GetStudentDetailsPaymentReport/' ,formData)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getStudentPaymentDetails(formData): Observable<any> {
  
    return this.httpClient.get<any>(environment.apiUrl + '/FeeReport/GetStudentPaymentReport?StudentId='+formData.studentId+'&ClassId='+formData.classId+'&BatchId='+formData.batchId+'&AcademicYearId='+formData.academicYearId+'&AcademicNo='+formData.admissionNumber)
     .pipe(
       catchError(this.errorHandler)
     );
   }
   getFeeReceiptDetails(paymentId): Observable<any> {
    return this.httpClient.get<any>(environment.apiUrl + '/FeeReport/GetFeeReceiptDetails/' +parseInt(paymentId))
      .pipe(
        catchError(this.errorHandler)
      );
    }
errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
