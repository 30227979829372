import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MainModule, SubscriptionManagerModel} from './subscriptionmanager.model';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionManagerService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<SubscriptionManagerModel[]> {
    return this.httpClient.get<SubscriptionManagerModel[]>(environment.apiUrl + '/Subscription/GetAllSubscription/')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllModules(): Observable<MainModule[]> {
    return this.httpClient.get<MainModule[]>(environment.apiUrl + '/MenuMainModule/')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getSubModules(id): Observable<any[]> {
    return this.httpClient.get<any[]>(environment.apiUrl + '/MenuSubModule?module_id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getById(id): Observable<SubscriptionManagerModel> {
    return this.httpClient.get<SubscriptionManagerModel>(environment.apiUrl + '/Subscription/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(addonservice): Observable<SubscriptionManagerModel> {    
  return this.httpClient.post<SubscriptionManagerModel>(environment.apiUrl + '/Subscription/',
   JSON.stringify(addonservice), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, addonservice): Observable<SubscriptionManagerModel> {
    ;
    return this.httpClient.put<SubscriptionManagerModel>(environment.apiUrl + '/Subscription/' + id,
     JSON.stringify(addonservice), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<SubscriptionManagerModel>(environment.apiUrl + '/Subscription/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }

  GetAllMenuModules(){
    return this.httpClient.get(environment.apiUrl+'/MenuMainModule/getallactivemenus') .pipe(
      catchError(this.errorHandler)
    );
  }
  GetAllSubModules(){
    return this.httpClient.get(environment.apiUrl+'/MenuMainModule/MenuSubModule') .pipe(
      catchError(this.errorHandler)
    );
  }
  getSubmenuById(id:number){
    return this.httpClient.get(environment.apiUrl+'/MenuSubModule?module_id'+id).pipe(
      catchError(this.errorHandler)
    );
  }

}
