import { Component, Renderer2, OnInit, ViewChild, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ShiftService } from './../period/shift.service';
import { PeriodService } from './../period/period.service';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Period, Shift } from './../period/period.model';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Room } from '../../mastersettings/models/room';
import { RoomService } from '../../mastersettings/room/room.service';
import { SubjectCreation } from '../../academicsettings/subjectcreation/subjectcreation.model';
import { SubjectcreationService } from '../../academicsettings/subjectcreation/subjectcreation.service';
import { TimeTable, SubjectViewModel, TimeTableViewModel, PeriodViewModel } from './timetable.model';
import { TimeTableService } from './timetable.service';
import { environment } from 'src/environments/environment.prod';

import { AddEditTimeTableDialogComponent } from './add-edit-time-table-dialog/add-edit-time-table-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.css']
})
export class TimetableComponent implements OnInit {

  @ViewChild('closeModal') closeModal: ElementRef;

  timetableForm: UntypedFormGroup;
  classcourses: ClassCourse[];
  batches: Batch[] = [];
  shifts: Shift[] = [];
  periods: Period[] = [];
  times: any[] = [];
  rooms: Room[] = [];
  subjects: SubjectCreation[] = [];
  classSubjects: SubjectCreation[] = [];
  classcourseid = '-1';
  batchid = '-1';
  shiftid = '-1';
  roomId = '-1';
  subjectId = '-1';
  submitted = false;
  subjectname: SubjectViewModel[][] = [];
  apiURL = environment.imageUrl;
  localURL = environment.localUrl;
  noOfPeriods = 0;

  constructor(
    public shiftService: ShiftService,
    public periodService: PeriodService,
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    private timeTableService: TimeTableService,
    private roomService: RoomService,
    private subjectService: SubjectcreationService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private el: ElementRef,
    public dialog: MatDialog
  ) { }

  ListClass() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      console.log(data);
      this.classcourses = data;
    });
  }

  ListShift() {
    this.shiftService.getAll().subscribe((data: Shift[]) => {
      console.log(data);
      this.shifts = data;
    });
  }

  ListRoom() {
    this.roomService.getAll().subscribe((data: Room[]) => {
      this.rooms = data;
    });
  }

  ListSubject() {
    this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
      this.subjects = data.filter(x => x.subjectName != "Lunch");
    });
  }

  ListClassSubjects() {
    this.subjectService.getAllByClassBatch(Number(this.classcourseid)).subscribe((data: SubjectCreation[]) => {
      this.classSubjects = data;
    });
  }
  get t() { return this.timetableForm.controls; }
  onClassSelected(value: string) {
    // this.filteredbatches = this.batches;
    this.batches = [];
    this.periods = [];
    this.times = [];
    this.ListShift();

    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) =>
        batch.classId.toString() === value.toString());
    });
    // this.subjectService.getAll().subscribe((data: subjectcreation[]) => {
    //   this.subjects = data.filter((subject: subjectcreation) =>
    //   subject.classCourseId.toString() === value.toString());
    // });
  }


  table1 = [];
  table2 = [];

  onBatchChange(values: number) {
    this.shiftid = '-1';

  }

  onShiftChange(value: number) {

    const classId = this.classcourseid !== '-1' ? this.classcourseid : '0';
    const batchId = this.batchid !== '-1' ? this.batchid : '0';

    this.periodService.getAll().subscribe((data: Period[]) => {
      this.periods = data.filter((period: Period) =>
        period.classId.toString() === classId.toString() && period.batchIds.toString()==batchId.toString() && period.shiftId.toString() ==value.toString());
    });
    this.timeTableService.getPeriodDetail(classId, batchId, value, 0).subscribe((data: any[]) => {
      console.log(data);
      const periodList: TimeTableViewModel[] = [];
      const periodTable = data['Table'];
      const timeTableObj = data['Table1'];

      this.table1 = periodTable;
      this.table2 = timeTableObj;

      periodTable.forEach((element: any) => {
        const x2 = Object.values(element);
        const weekName = x2[0];
        const timeObjList: PeriodViewModel[] = [];
        // 
        // x2.forEach((x) => {
        timeTableObj.forEach((element1: any) => {
          if (weekName === element1.WeekName) {
            const timeObj: PeriodViewModel = {} as PeriodViewModel;
            timeObj.timeTableId = element1.TimeTableId;
            timeObj.periodId = element1.PeriodId;

            if (element1.ImageURI !== '') {
              timeObj.ImageURI = this.apiURL + element1.ImageURI;
            }
            else {
              timeObj.ImageURI = this.localURL + 'assets/images/PngItem_1468479.png';
            }
            if (element1.SubjectName != 0) {
              timeObj.subjectName = element1.SubjectName;
              timeObj.teacherName = element1.EmployeeName;
            }
            else {
              timeObj.subjectName = "";
              timeObj.teacherName = element1.periodType;
            }
            timeObj.periodTime = element1.PeriodTime;
            timeObj.roomName = element1.RoomName;
            timeObjList.push(timeObj);
          }
        });
        const periodObj: TimeTableViewModel = {} as TimeTableViewModel;
        periodObj.weekName = weekName.toString();
        periodObj.periodDetails = timeObjList;
        periodList.push(periodObj);
        // });
      });
      // console.log(periodList);
      this.times = periodList;
    });



    this.timeTableService.getPeriodDetail(classId, batchId, value, 0).subscribe((data: any[]) => {
      const periodList: TimeTableViewModel[] = [];
      const periodTable = data['Table'];
      const timeTableObj = data['Table1'];
      this.table1 = periodTable;
      this.table2 = timeTableObj;
debugger;
      periodTable.forEach((element: any) => {
        const x2 = Object.values(element);
        const weekName = x2[0];
        const timeObjList: PeriodViewModel[] = [];
        // 
        // x2.forEach((x) => {
        timeTableObj.forEach((element1: any) => {
          if (weekName === element1.WeekName) {
            const timeObj: PeriodViewModel = {} as PeriodViewModel;
            timeObj.timeTableId = element1.TimeTableId;
            timeObj.periodId = element1.PeriodId;

            if (element1.ImageURI !== '') {
              timeObj.ImageURI = this.apiURL + element1.ImageURI;
            }
            else {
              timeObj.ImageURI = this.localURL + 'assets/images/PngItem_1468479.png';
            }
            if (element1.SubjectName != 0) {
              timeObj.subjectName = element1.SubjectName;
              timeObj.teacherName = element1.EmployeeName;
            }
            else {
              timeObj.subjectName = "";
              timeObj.teacherName = element1.periodType;
            }
            timeObj.periodTime = element1.PeriodTime;
            timeObj.roomName = element1.RoomName;
            timeObjList.push(timeObj);
          }
        });
        const periodObj: TimeTableViewModel = {} as TimeTableViewModel;
        periodObj.weekName = weekName.toString();
        periodObj.periodDetails = timeObjList;
        periodList.push(periodObj);
        // });
      });
      // console.log(periodList);
      this.times = periodList;
    });
    // this.subjectService.getAllByClassBatch(parseInt(classId, 0)).subscribe((data: SubjectCreation[]) => {
    //   this.subjects = data;
    // });
  }

  updateSubjects() {
    this.times.forEach((m, i) => {
      this.subjectname[i] = [];
      this.periods.forEach((x, j) => {
        console.log(m.weekName);
        console.log(x.id);
        if (m.weekName !== '' || m.weekName !== undefined) {
          this.timeTableService.getSubjectName(x.id, m.weekName).subscribe((z: SubjectViewModel) => {
            if (z == null) {
              console.log(z);
              const sub: SubjectViewModel = {} as SubjectViewModel;
              sub.subjectName = '';
              sub.roomName = '';
              this.subjectname[i][j] = sub;
            } else {
              this.subjectname[i][j] = z;
            }
          });
        }
      });
    });
  }

  IsPeriodAssigned(weekName: string, period: string)
  {
    const timeTableObj = this.table2;

    timeTableObj.forEach((element1: any) => {
      if (weekName === element1.WeekName && element1.periodId === period) {
        return true;
      }
      });

    return false;
  }

  copySubjectAllDays(periodId: string, weekname: string) {
    this.noOfPeriods=0;
    debugger;
    this.times.forEach((x)=>{
      if(x.weekName!=weekname)
      {
        x.periodDetails.forEach((y)=>{
            if(y.periodId.toString()==periodId && y.subjectName!=''){
              this.noOfPeriods++;
            }
        });
      }
    });
    debugger;
    if(this.noOfPeriods==5){
      this.toastr.warning('Periods already added', 'WARNING!', {
        timeOut: 3000
      });
      this.submitted = false;
      return;
    }else{
    this.timeTableService.getTimeTable(periodId, weekname).subscribe((z: TimeTable) => {
      this.times.forEach((x) => {
        var isExists = this.IsPeriodAssigned(x.weekName, periodId);
        console.log(isExists);
        if(x.weekName!=weekname)
        {
          const timeTable: TimeTable = {} as TimeTable;
          timeTable.periodId = z.periodId;
          timeTable.classId = this.classcourseid;
          timeTable.batchId = this.batchid;
          timeTable.periodType = z.periodType;
          timeTable.roomId = z.roomId;
          timeTable.shiftId = this.shiftid;
          timeTable.subjectId = z.subjectId;
          timeTable.weekName = x.weekName;
          this.timeTableService.create(timeTable).subscribe(res => {
            this.onShiftChange(parseInt(this.shiftid, 0));
            //this.ListShift();
            this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submitted = false;

            // this.resetForm();
          });
       }
       //console.log(timeTableObj);
      });


      const timeTables: TimeTable = {} as TimeTable;
      timeTables.periodId = periodId;
      timeTables.weekName = weekname;

      this.timeTableService.deleteTT(timeTables).subscribe(res => {
        ;
      });

    });
  }
  }

  resetForm() {
    this.submitted = false;
    this.timetableForm = this.fb.group({
      id: [0],
      shiftId: ['', Validators.required],
      periodId: ['', Validators.required],
      subjectId: [''],
      classId: ['', Validators.required],
      roomId: [''],
      periodType: ['', Validators.required],
      weekName: [''],
      batchId:['',Validators.required]
    });
  }

  onChangePeriodTye(event) {
    if (this.timetableForm.controls['periodType'].value == "false") {
      // this.timetableForm.controls['subjectId'].disable();
      ;
      this.timetableForm.controls['roomId'].disable();
      this.timetableForm.controls['subjectId'].disable();
     // this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
     //   this.subjects = data.filter(x => x.subjectName == "Lunch");
      //  this.timetableForm.get('subjectId').patchValue(this.subjects[0].id);
     // });
      this.timetableForm.get('roomId').patchValue(0);
      this.timetableForm.get('subjectId').patchValue(0);
    }
    else {
      this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
        this.subjects = data.filter(x => x.subjectName != "Lunch");
      });

      this.timetableForm.controls['subjectId'].enable();
      this.timetableForm.controls['roomId'].enable();

      this.timetableForm = this.fb.group({
        id: [this.EditData.id],
        shiftId: [this.shiftid, Validators.required],
        periodId: [this.EditData.periodId, Validators.required],
        subjectId: [this.EditData.subjectId],
        classId: [this.classcourseid, Validators.required],
        roomId: [this.EditData.roomId],
        periodType: [this.EditData.periodType, Validators.required],
        weekName: [this.EditData.weekName],
        batchId:[this.batchid]
      });


    }
  }

  ngOnInit(): void {
    this.ListSubject();
    this.ListClassSubjects();
    this.ListClass();
    this.ListShift();
    this.ListRoom();

    this.timetableForm = this.fb.group({
      id: [0],
      shiftId: ['', Validators.required],
      periodId: ['', Validators.required],
      subjectId: [''],
      classId: ['', Validators.required],
      roomId: [''],
      periodType: ['true', Validators.required],
      weekName: [''],
      batchId:['',Validators.required]
    });
  }

  isAssign: boolean = false;
  EditData: any;
  async onTimeTableEdit(periodId: string, timetableId: number, weekName: string) {

   //this.ListSubject();
   await this.ListClassSubjects();

  //  this.subjectService.getAllByClassBatch(Number(this.classcourseid)).subscribe((data: SubjectCreation[]) => {
  //   this.classSubjects = data;
  // });

    this.isAssign = true;
    this.timetableForm.get('shiftId').patchValue(this.shiftid);
    this.timetableForm.get('classId').patchValue(this.classcourseid);
    this.timetableForm.get('batchId').patchValue(this.batchid);
    this.timetableForm.get('periodId').patchValue(periodId.toString());
    this.timetableForm.get('id').patchValue(timetableId);
    this.timetableForm.get('weekName').patchValue(weekName);
    this.timetableForm.controls['periodType'].patchValue('true');
    this.timetableForm.controls['subjectId'].patchValue(0);
    this.timetableForm.controls['roomId'].patchValue(0);
    if (this.timetableForm.controls['periodType'].value == "false") {
      this.timetableForm.controls['roomId'].disable();
      this.timetableForm.controls['subjectId'].disable();
      this.timetableForm.get('roomId').patchValue(0);
      this.timetableForm.get('subjectId').patchValue(0);
    }
    else {
      this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
        this.subjects = data.filter(x => x.subjectName != "Lunch");
      });

      this.timetableForm.controls['subjectId'].enable();
      this.timetableForm.controls['roomId'].enable();
    }
    // if (timetableId !== undefined && timetableId !== 0) {
    //   this.timeTableService.getById(timetableId).subscribe((data: TimeTable) => {
    //     console.log(data);
    //     this.EditData = data;

    //     if (data.periodType.toString() == "false") {
    //       this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
    //         this.subjects = data.filter(x => x.subjectName == "Lunch");
    //       });
    //     }

    //     this.timetableForm = this.fb.group({
    //       id: [data.id],
    //       shiftId: [this.shiftid, Validators.required],
    //       periodId: [data.periodId, Validators.required],
    //       subjectId: [data.subjectId],
    //       classId: [this.classcourseid, Validators.required],
    //       roomId: [data.roomId],
    //       periodType: [data.periodType.toString(), Validators.required],
    //       weekName: [data.weekName]
    //     });
    //   });
    // }
  }
  async onTimeTableDelete(periodId: string, timetableId: number, weekName: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
debugger
        this.isAssign = true;
        this.timetableForm.get('shiftId').patchValue(this.shiftid);
        this.timetableForm.get('classId').patchValue(this.classcourseid);
        this.timetableForm.get('batchId').patchValue(this.batchid);
        this.timetableForm.get('periodId').patchValue(periodId.toString());
        this.timetableForm.get('id').patchValue(timetableId);
        this.timetableForm.get('weekName').patchValue(weekName);
        this.timetableForm.controls['periodType'].patchValue('true');
        this.timetableForm.controls['subjectId'].patchValue(0);
        this.timetableForm.controls['roomId'].patchValue(0);
        if (timetableId !== undefined && timetableId !== 0) {
          // this.timeTableService.getById(timetableId).subscribe((data: TimeTable) => {
          //   console.log(data);
          //   this.EditData = data;

          //   if (data.periodType.toString() == "false") {
          //     this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
          //       this.subjects = data.filter(x => x.subjectName == "Lunch");
          //     });
          //   }

          //   this.timetableForm = this.fb.group({
          //     id: [data.id],
          //     shiftId: [this.shiftid, Validators.required],
          //     periodId: [data.periodId, Validators.required],
          //     subjectId: [data.subjectId],
          //     classId: [this.classcourseid, Validators.required],
          //     roomId: [data.roomId],
          //     periodType: [data.periodType.toString(), Validators.required],
          //     weekName: [data.weekName]
          //   });

            this.timeTableService.deleteTT(this.timetableForm.value).subscribe(res => {
              this.onShiftChange(parseInt(this.shiftid, 0));
              //this.ListShift();
              // this.updateSubjects();
              this.toastr.success('Records has been sucessfully Deleted', 'SUCCESS!', {
                timeOut: 3000
              });
              this.submitted = false;
              this.isAssign = false;
              this.closeModal.nativeElement.click();
            });
         // });
        }

        
    

        // this.homeworkService.delete(id).subscribe(res => {
        //   Swal.fire(
        //     'Deleted!',
        //     'Your file has been deleted.',
        //     'success'
        //   );
        //   // this.ListHomeworks();
        // });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }

  @ViewChild('button', { static: false }) divButton: ElementRef;

  roomName: any;
  subjectName: any;

  submitForm() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.timetableForm.invalid) {
      return;
    }
    // Checking Lunch break
    // tslint:disable-next-line: no-string-literal
    if (this.timetableForm.controls['periodType'].value === true || this.timetableForm.controls['periodType'].value === 'true') {
      // tslint:disable-next-line: no-string-literal
      if (this.timetableForm.controls['subjectId'].value === 0 || this.timetableForm.controls['subjectId'].value === -1 || this.timetableForm.controls['subjectId'].value === '') {
        this.toastr.warning('Select subject ', 'Warning!', {
          timeOut: 1000
        });
        return;
      }
      // tslint:disable-next-line:no-string-literal
      if (this.timetableForm.controls['roomId'].value === -1 || this.timetableForm.controls['roomId'].value === '') {
        this.toastr.warning('Select room ', 'Warning!', {
          timeOut: 1000
        });
        return;
      }
    } else {
      // this.timetableForm.get('subjectId').patchValue(0);
      this.timetableForm.get('roomId').patchValue(0);
    }

    const weekName = this.timetableForm.get('weekName').value;
    if (this.subjects.filter(x => x.id == this.timetableForm.controls['subjectId'].value).length > 0) {
      this.subjectName = this.subjects.filter(x => x.id == this.timetableForm.controls['subjectId'].value)[0].subjectName;
    }else{
      this.subjectName = 'Lunch';
    }
    if (this.rooms.filter(x => x.id == this.timetableForm.controls['roomId'].value).length > 0) {
      this.roomName = this.rooms.filter(x => x.id == this.timetableForm.controls['roomId'].value)[0].roomName;
    }else{
      this.roomName = null;
    }
    
    var isSuccess = this.table2.filter(x => x.SubjectName == this.subjectName && x.WeekName == weekName && x.RoomName == this.roomName).length;

    if (isSuccess >= 1) {
      this.toastr.warning('Subject already exist on this day', 'WARNING!', {
        timeOut: 3000
      });
      return false;
    }

    if (this.timetableForm.get('id').value === 0) {
      this.timeTableService.create(this.timetableForm.value).subscribe(res => {
        this.onShiftChange(parseInt(this.shiftid, 0));
        //this.ListShift();
        // this.updateSubjects();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.isAssign = false;
        this.closeModal.nativeElement.click();
        // this.resetForm();
      });
    } else {
      debugger
      this.timeTableService.update(this.timetableForm.get('id').value, this.timetableForm.value).subscribe(res => {
        this.onShiftChange(parseInt(this.shiftid, 0));
        //this.ListShift();
        // this.updateSubjects();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.closeModal.nativeElement.click();
        // this.resetShiftForm();
      });
    }
  }

}
