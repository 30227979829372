import { Component, OnInit } from '@angular/core';
import { EnquiryService } from '../enquiry/enquiry.service';
import { Enquiry, EnquiryFollowup } from '../enquiry/enquiry.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-enquiryfollowup',
  templateUrl: './enquiryfollowup.component.html',
  styleUrls: ['./enquiryfollowup.component.css']
})
export class EnquiryfollowupComponent implements OnInit {
  enquiries: Enquiry[] = [];
  enquirySearchList: Enquiry[] = [];
  enquiryFilteredList: Enquiry[] = [];
  status = '';
  purpose: string;
  fromDate: Date;
  toDate: Date;
  followupList: EnquiryFollowup[] = [];
  followupForm: UntypedFormGroup;
  submitted = false;
  status2 = '';
  enquiryid: number;
  enquiryno: string;
  createddate: string;
  visitorname: string;
  contactnumber: string;
  email: string;
  purposeMeet: string;
  person: string;
  class: string;
  enquiryDetail: string;
  

  constructor(
    public enquiryService: EnquiryService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  ListEnquiry() {
    //this.enquiryService.getAll().subscribe((data: Enquiry[]) => {
    this.enquiryService.getAll().subscribe((data: any) => {
      console.log(data.Table);
      ;
      // const d = new Date();
      //   let month = '' + (d.getMonth() + 1);
      //   let day = '' + d.getDate();
      //   const year = d.getFullYear();
      //   if (month.length < 2) {month = '0' + month; }
      //   if (day.length < 2) {day = '0' + day; }
      //   var DateData = [day, month, year].join('-');
      
      // for(var i = 0 ; i< data.length ;i++){
      //   data[i]["lastFollowupDate"] = DateData;
      // }
      this.enquiryFilteredList = this.enquiries = data.Table;
    });
  }

  ListEnquiryFollowup(id: number) {
    this.enquiryService.getAllFollowup(id).subscribe((data: EnquiryFollowup[]) => {
      console.log(data);
      this.followupList = data;
    });
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  public ToDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [year, month, day].join('-');
  }

  reverseAndTimeStamp(dateString) {
    const reverse = new Date(dateString.split("-").reverse().join("-"));
    return reverse.getTime();
    }

  search() {
    ;
    var TODATE ="";
    if (this.fromDate === undefined ) {
      this.toastr.warning('Please select from date', 'WARN!', {
       
      });
      return;
    }

    if (this.toDate === undefined ) {
      this.toastr.warning('Please select to date', 'WARN!', {
       
      });
      return;
    }
    else{
      TODATE = this.ToDate(this.toDate);
    }
    var SortList= JSON.parse(JSON.stringify(this.enquiries));
    if(this.status2 != "" && this.status2 != "All Status"){
      SortList = SortList.filter((item: any) => {
          return  item.EnquiryStatus.toString() === this.status2 
      });
    }
    let fromdate=moment(this.fromDate).format('DD-MM-YYYY');
    console.log(fromdate)
    let todate=moment(this.toDate).format('DD-MM-YYYY');
    SortList=  SortList.filter((item: any) => {
      if(this.fromDate && this.toDate){ 
        return this.reverseAndTimeStamp(moment(item.CreatedDate).format('DD-MM-YYYY')) >= this.reverseAndTimeStamp(fromdate) && 
        this.reverseAndTimeStamp(moment(item.CreatedDate).format('DD-MM-YYYY')) <= this.reverseAndTimeStamp(todate)  
      } else {
        // return  new Date(item.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
        // new Date(item.createdDate).getTime() <= new Date(TODATE).getTime();
        this.enquiryFilteredList = SortList;
      }
    });
this.enquiryFilteredList = SortList;
    // if (this.enquirySearchList.length !== 0) {
    //   this.enquiryFilteredList = this.enquirySearchList;
    //   this.enquirySearchList = this.enquiryFilteredList =  this.enquirySearchList.filter((item: Enquiry) => {
    //     if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //       return Math.floor(new Date(item.createdDate).getTime() / 86400000) - 1 ===
    //        Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //     } else {
    //       return  new Date(item.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
    //       new Date(item.createdDate).getTime() <= new Date(this.toDate).getTime();
    //     }
    //   });
    // } else {
    //   this.enquiryFilteredList = JSON.parse(JSON.stringify(this.enquiries));
    //   this.enquirySearchList =  this.enquiryFilteredList = this.enquiries.filter((item: Enquiry) => {
    //     if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //       return Math.floor(new Date(item.createdDate).getTime() / 86400000) - 1 ===
    //        Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //     } else {
    //       return  new Date(item.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
    //       new Date(item.createdDate).getTime() <= new Date(this.toDate).getTime();
    //     }
    //   });
    // }
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
        if (this.enquirySearchList.length !== 0) {
          this.enquiryFilteredList = this.enquirySearchList;
        } else {
          this.enquiryFilteredList = JSON.parse(JSON.stringify(this.enquiries));;
        }
    } else {
      if (this.enquirySearchList.length !== 0) {
        this.enquiryFilteredList = this.enquirySearchList
                                .filter((enquiry: Enquiry) => {
                                  return enquiry.visitorName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                   enquiry.contactNumber.toString().includes(keyword)  ||
                                   (enquiry.enquiryNumber !== null ? enquiry.enquiryNumber.toLocaleLowerCase()
                                   .includes(keyword.toLocaleLowerCase()) : '');
                              });
      } else {
        const enqList = JSON.parse(JSON.stringify(this.enquiries));
        this.enquiryFilteredList = enqList
                                .filter((enquiry: Enquiry) => {
                                  return enquiry.visitorName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                   enquiry.contactNumber.toString().includes(keyword) ||
                                   (enquiry.enquiryNumber !== null ? enquiry.enquiryNumber.toLocaleLowerCase()
                                   .includes(keyword.toLocaleLowerCase()) : '');
                              });
      }
    }
    // if (this.enquiryFilteredList.length === 0) {
    //   this.toastr.warning('No Records Found', 'WARNING!!', {
    //       timeOut: 3000
    //   });
    // }
  }

  get totalRows(): number {
    return this.enquiryFilteredList.length;
  }

  onStatusSelected(value: string) {
    console.log(value);
    this.status2 = value;

  //   if (value === '') {
  //     // this.enquiryService.getAll().subscribe((data: Enquiry[]) => {
  //      // console.log(data);
  //       this.enquirySearchList = this.enquiryFilteredList = JSON.parse(JSON.stringify(this.enquiries));;
  //     // });
  //   } else {
  //     const enqList = JSON.parse(JSON.stringify(this.enquiries));
  //   // this.enquiryService.getAll().subscribe((data: Enquiry[]) => {
  //     this.enquirySearchList = this.enquiryFilteredList = enqList.filter((enquiry: Enquiry) =>
  //     enquiry.enquiryStatus.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
  //   // });
  // }
  }

  openFollowUp(id: number) {
    
    console.log(id);
    this.enquiryid = id;
    this.fillForm(id);
    this.ListEnquiryFollowup(id);
  }

  resetTable() {
    this.enquiryFilteredList = this.enquiries;
    $('#enquiryFollowUp #fromDate').val('');
    $('#enquiryFollowUp #toDate').val('');
    this.status = '';
  }
  get f() { return this.followupForm.controls; }
  resetForm() {
    this.submitted = false;
    this.followupForm = this.fb.group({
      id: [0],
      enquiryId: ['', Validators.required],
      nextFollowupDate: ['', Validators.required],
      enquiryStatus: ['', Validators.required],
      remarks: ['', Validators.required]
    });
    $('#nextFollowupDate').val('');
  }

  submitForm() {
    this.submitted = true;
    this.followupForm.get('enquiryId').patchValue(this.enquiryid.toString());
    if (this.followupForm.invalid) {
      return;
    }
    if (this.followupForm.get('id').value === 0) {
      const d = new Date();
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) {month = '0' + month; }
      if (day.length < 2) {day = '0' + day; }
      this.followupForm.value.lastFollowupDate =  [day, month, year].join('-');
      this.enquiryService.createFollowup(this.followupForm.value).subscribe(res => {
        console.log('folowup created!');
        this.ListEnquiryFollowup(this.enquiryid);
        this.ListEnquiry();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    }
  }

  fillForm(id: number) {
    this.enquiryService.getById(id).subscribe((data: Enquiry) => {
        this.enquiryid = data.id;
        this.enquiryno = data.enquiryNumber;
        this.createddate = this.formatDate(data.createdDate);
        this.visitorname = data.visitorName;
        this.contactnumber = data.contactNumber;
        this.email = data.emailId;
        this.purposeMeet = data.purposeOfVisit;
        this.person = data.personToMeet;
        this.class = data.className;
        this.enquiryDetail = data.enquiryDetail;
    });
  }

  ngOnInit(): void {
    this.ListEnquiry();

    $('#enquiryFollowUp #fromDate').datepicker().on('changeDate', (e) => {
      this.fromDate =  new Date(e.date);
    });

    $('#enquiryFollowUp #toDate').datepicker().on('changeDate', (e) => {
      this.toDate = new Date(e.date);
    });

    $('#addFollowUp').on('shown.bs.modal', (e) => {
      $('#nextFollowupDate').datepicker({format: 'dd-MM-yyyy'}).on('changeDate', (e) => {
        this.followupForm.get('nextFollowupDate').patchValue(this.formatDate(e.date));
      });
    });

    this.followupForm = this.fb.group({
      id: [0],
      enquiryId: ['', Validators.required],
      nextFollowupDate: ['', Validators.required],
      enquiryStatus: ['', Validators.required],
      remarks: ['', Validators.required]
    });
  }

}
