import { Component, OnInit ,ViewChild,ElementRef} from '@angular/core';
import { FeeReciept, FeeRecieptDetailDetail} from './feereceipt.model';
import { FeeRecieptService} from './feereceipt.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { PaymentMode } from '../../../mastersettings/models/paymentmode';
import { PaymentModeService } from '../../../mastersettings/paymentmode/paymentmode.service';
//import { ERPSettingService } from '../../../../../admin/erpsettings/erpsettings.service';
//import { ERPGlobalSettings } from '../../../../../admin/erpsettings/erpsettings.model';
@Component({
  selector: 'app-feereceipt',
  templateUrl: './feereceipt.component.html',
  styleUrls: ['./feereceipt.component.css']
})
export class FeereceiptComponent implements OnInit {
  @ViewChild('FeeReceiptScreen', { static: false }) pdfTable: ElementRef;
  receiptNo: string;
  feeReciept: FeeReciept = {} as FeeReciept;
  feeHeads: FeeRecieptDetailDetail[] = [];
  institution: any;
  institutionName = '';
  address = '';
  email = '';
  website = '';
  logoUrl = '';
  lblDueAdvance = 'Due';
  lblDueAdjust = 'Due';
  lblDueAdvanceValue=0;
  lblDueAdvanceAdjValue=0;
  //erpSettingService: ERPSettingService;
  PrintFeeReceiptCopy_details=[
    {id:1,name:'Student Copy',isSelected:false},
    {id:2,name:'School Copy',isSelected:false},
  ]
 public FeeReceiptList: any;
 public FeeHeadItemList: any;
 public FeeHeadDataList:any=[];
 public FeeReceiptDataobj: any={};
 public InstallmentNameList: any=[];
  paymentsModes: PaymentMode[];
  isLoading: boolean=false;
  constructor(
    private feeReceiptService: FeeRecieptService,
    private route: ActivatedRoute,
    private paymentModeService:PaymentModeService,
  ) { 

    // this.id = this.route.params._value.id

 
  }



  FormatData(Obj: any, dataList:any=[], installamentNameList:any)
  {
    Obj.FeeHeadDataList = [];
    let TotalAmount = 0;
   ;
    //let TotalDiscountAmount = 0;
    for(let instItemName of installamentNameList)
    {
      let SumOfHeadItem = 0;
      let SumOfHeadItemDiscount = 0;
      let HeadItemList =  dataList.filter(_installmentItem=>_installmentItem.feeHeadName==instItemName);
      for(let _headitem of HeadItemList)
      {
        SumOfHeadItem = SumOfHeadItem + Number(_headitem.amount);
        SumOfHeadItemDiscount = SumOfHeadItemDiscount + Number(_headitem.discountRate);
      }
      TotalAmount = TotalAmount + SumOfHeadItem;
      ///TotalDiscountAmount = TotalDiscountAmount + SumOfHeadItemDiscount;
      let FeeHeadDataObj ={
        FeeHeadName: instItemName,
        Amount: SumOfHeadItem
      }      
      Obj.FeeHeadDataList.push(FeeHeadDataObj);
    }
    Obj.TotalAmount = TotalAmount;
   // Obj.TotalDiscountAmount = TotalDiscountAmount;
  }
  createRange(number){
    return new Array(number).fill(0).map((n,index)=>index+1);
  }
  getReciept(id: any) {

    
   /* this.route.params.subscribe(params => {

  
     this.id =params["id"];
      console.log(params["id"]);
      
  });*/
    // id = ._value.id;
    this.isLoading=true;
   

    this.feeReceiptService.getFeeReciept(this.receiptNo).subscribe((data) => {
    
     //this.ListPaymentModes();
        console.log(data);
        this.feeReciept = data[0];
        this.PrintFeeReceiptCopy_details.forEach(x=>{
          if(this.feeReciept.printFeeReceiptCopy==3)
            x.isSelected=true;
          else if(this.feeReciept.printFeeReceiptCopy==x.id)
            x.isSelected=true;
          else
            x.isSelected=false;
        });
        this.FeeReceiptList =[];
        this.FeeReceiptList = data;
        this.FeeHeadItemList = this.FeeReceiptList[0].feeCollectionHeadInstallment.map(item => item.feeHeadName).filter((value, index, self) => self.indexOf(value) === index)
        this.InstallmentNameList = this.FeeReceiptList[0].feeCollectionHeadInstallment.map(_item => _item.installmentName).filter((value, index, self) => self.indexOf(value) === index)
        this.InstallmentNameList = this.InstallmentNameList.toString();
        
        this.FeeReceiptDataobj={
        ReceiptNo: this.FeeReceiptList[0].receiptNo,
        StudentName: this.FeeReceiptList[0].studentName,
        FatherName: this.FeeReceiptList[0].fatherName,
        StudentType: this.FeeReceiptList[0].studentType,
        ClassCourseName: this.FeeReceiptList[0].classCourseName,
        AdmissionNo: this.FeeReceiptList[0].admissionNo,
        RollNo: this.FeeReceiptList[0].rollNo,
        Amount: this.FeeReceiptList[0].amount,
        Discount: this.FeeReceiptList[0].discount,
        PaidAmount: this.FeeReceiptList[0].paidAmount,
        PayableAmount: this.FeeReceiptList[0].payableAmount,
        FineAmount: this.FeeReceiptList[0].fineAmount,
        InstallmentAmount: this.FeeReceiptList[0].installmentAmount,
        DueAmount: Number(this.FeeReceiptList[0].dueAmount),
        AdvanceAmount: Number(this.FeeReceiptList[0].advanceAmount),
        AdvanceAdjusted: Number(this.FeeReceiptList[0].advanceAdjusted),
        DueAdjusted: Number(this.FeeReceiptList[0].dueAdjusted),
        PayDate: this.FeeReceiptList[0].payDate,
        BatchName:this.FeeReceiptList[0].batchName,
        PaymentRemark:this.FeeReceiptList[0].paymentRemark,
        PayMode: this.paymentsModes.find(x=>x.id==this.FeeReceiptList[0].payMode).paymentModeName ,
       };
        this.FormatData(this.FeeReceiptDataobj,this.FeeReceiptList[0].feeCollectionHeadInstallment,this.FeeHeadItemList);

        if (this.FeeReceiptList[0].advanceAdjusted > 0 ) {
          this.lblDueAdvanceAdjValue =this.FeeReceiptList[0].advanceAdjusted;
        } else if (this.FeeReceiptList[0].dueAdjusted > 0) {
          this.lblDueAdvanceAdjValue = this.FeeReceiptList[0].dueAdjusted;
        }
        
        if (this.FeeReceiptList[0].advanceAdjusted > 0 ) {
          this.lblDueAdjust = 'Advance';
        } else if (this.FeeReceiptList[0].dueAdjusted > 0) {
          this.lblDueAdjust = 'Due';
        }
        

        if (this.FeeReceiptList[0].advanceAmount > 0 ) {
          this.lblDueAdvance = 'Advance';
        } else if (this.FeeReceiptList[0].dueAmount > 0) {
          this.lblDueAdvance = 'Due';
        }
        if (this.FeeReceiptList[0].advanceAmount > 0 ) {
          this.lblDueAdvanceValue=this.FeeReceiptList[0].advanceAmount;
        } else if (this.FeeReceiptList[0].dueAmount > 0) {
          this.lblDueAdvanceValue=this.FeeReceiptList[0].dueAmount;
        }

        this.feeReciept.feeCollectionDetails.forEach((fdata) => {
           fdata.feeCollectionFeeHeadDetails.forEach((x) => {
            this.feeHeads.push(x);
           });
        });
        
    });
    this.isLoading=false;
  }
  ListPaymentModes() {
    
    this.paymentModeService.getAll().subscribe((data:PaymentMode[])=>
    {
      this.paymentsModes=data;
      this.getReciept(this.receiptNo);
     
    });  
    }
  ngOnInit(): void {
     
    console.log("params init",this.route.params["id"]);
    this.route.params.subscribe(params => {


     this.receiptNo =params["id"];
      console.log("params",params);
      this.ListPaymentModes();
  });
  
  
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    if(this.institution.logoUrl !== null ) {
      this.institution.logoUrl = environment.imageUrl + this.institution.logoUrl;
    } else if (this.institution.logoUrl === null) {
      this.institution.logoUrl = 'assets/images/SchoolLogo/demologo.png';
    }
    this.logoUrl = this.institution.logoUrl;
    this.institutionName = this.institution.institutionName;
    this.address = this.institution.address;
  }

  public convetToPDF(fileName) {
    let DATA: any = document.getElementById('FeeReceiptScreen');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 200;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jspdf('p', 'mm', 'a4');
      let position = 5;
      PDF.addImage(FILEURI, 'PNG', 5, position, fileWidth, fileHeight); 
      
      PDF.output("dataurlnewwindow");
      //PDF.save(fileName+'.pdf');
      
    });
  }
  printToPdf(){
      
    const printArea:HTMLElement=document.getElementById('FeeReceiptScreen');
    let popupWin = window.open('','PRINT');
    popupWin.document.open();
    let printContents =printArea.innerHTML;
    let printHead = document.head.innerHTML;
    popupWin.document
      .write(`<!DOCTYPE html>

      <html xmlns="http://www.w3.org/1999/xhtml" style="height:auto;width:auto;margin: 0 0 0 0;
      padding: 0 0 0 0; ">
         ${printHead}
        <body onload="window.print();" style="height:auto;width:auto;margin: 0 0 0 0;
        padding: 0 0 0 0;">${printContents}</body></html>`);
    popupWin.focus();
    popupWin.document.close();
}
}
