import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IcseWeightage } from '../ExamSetting/models/icse-weightage.model';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IcseweightageService {

  constructor(private http:HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  //url:string="http://localhost:5000/";

  getAllIcseDropDown(){
    return this.http.get(environment.apiUrl+"/weightages/GetAllIcseDropDown").pipe(
      catchError(this.errorHandler)
    )
  }

  getAllIcseWeightage():Observable<IcseWeightage[]>{
    return this.http.get<IcseWeightage[]>(environment.apiUrl+"/weightages/GetAllIcseWeightages").pipe(
      catchError(this.errorHandler)
    )
  }

  getAllIcseWeightageById(id):Observable<IcseWeightage>{
    return this.http.get<IcseWeightage>(environment.apiUrl+"/weightages/GetIcseWeightagesById?id="+id).pipe(
      catchError(this.errorHandler)
    )
  }

  createIcseWeightage(data):Observable<IcseWeightage>{
    return this.http.post<IcseWeightage>(environment.apiUrl+"/weightages/CreateIcseWeightage",JSON.stringify(data),this.httpOptions)
          .pipe(catchError(this.errorHandler))
  }

  updateIcseWeightage(id,data):Observable<IcseWeightage>{
    return this.http.put<IcseWeightage>(environment.apiUrl+"/weightages/UpdateIcseWeightage?id="+id,JSON.stringify(data),this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }

  deleteIcseWeightage(id){
    return this.http.delete<IcseWeightage>(environment.apiUrl+"/weightages/DeleteIcseWeightage?id="+id,this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }


  // This code is for error handling
  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
