import { Injectable } from '@angular/core';
import { AdmissionType, IAdmissionType } from './model/admissionType';
import { FineConditionType, IFineConditionType } from './model/fineConditionType';
import { IIssueStatus, IssueStatus } from './model/issueStatus';
import { IMaintenanceType, MaintenanceType } from './model/maintenanceType';
import { IPenaltyType, PenaltyType } from './model/penaltyType';
import { IPreFineConditionType, PreFineConditionType } from './model/preFineConditionType';
import { IPurchaseStatus, PurchaseStatus } from './model/purchaseStatus';
import { IReturnBookStatus, ReturnBookStatus } from './model/returnBookStatus';
import { IStatus, Status } from './model/status';
import { IStopType, StopType } from './model/stopType';

@Injectable()
export class SharedService {
 public currentAcademicYearID = 10;
 public currentInstitution: any;
 public institutionCode = '';
  constructor() { }

  // getBasePath() {

  //   return data['default']['BasePath'];
  // }


  getStatusList(): IStatus[] {
    const keys = Object.keys(Status)
      .map(key => Status[key])
      .filter(value => typeof value !== 'string');
    let result = [];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let status = {} as IStatus;
      status.id = key;
      status.name = Status[key];
      result.push(status);
    };
    return result;
  }

  getIssueStatusList() : IIssueStatus[] {
    const keys = Object.keys(IssueStatus)
      .map(key => IssueStatus[key])
      .filter(value => typeof value !== 'string');
    let result = [];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let status = {} as IIssueStatus;
      status.id = key;
      status.name = IssueStatus[key];
      result.push(status);
    };
    return result;
  }

  getAdmissionType() : IAdmissionType[] {
    const keys = Object.keys(AdmissionType)
      .map(key => AdmissionType[key])
      .filter(value => typeof value !== 'string');
    let result = [];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let status = {} as IAdmissionType;
      status.id = key;
      status.name = AdmissionType[key];
      result.push(status);
    };
    return result;
  }

  
  getPenaltyType() : IPenaltyType[] {
    const keys = Object.keys(PenaltyType)
      .map(key => PenaltyType[key])
      .filter(value => typeof value !== 'string');
    let result = [];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let status = {} as IPenaltyType;
      status.id = key;
      status.name = PenaltyType[key];
      result.push(status);
    };
    return result;
  }

  getReturnBookStatusList() : IReturnBookStatus[] {
    const keys = Object.keys(ReturnBookStatus)
      .map(key => ReturnBookStatus[key])
      .filter(value => typeof value !== 'string');
    let result = [];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let status = {} as IReturnBookStatus;
      status.id = key;
      status.name = ReturnBookStatus[key];
      result.push(status);
    };
    return result;
  }

  getPurchaseStatusList() : IPurchaseStatus[] {
    const keys = Object.keys(PurchaseStatus)
      .map(key => PurchaseStatus[key])
      .filter(value => typeof value !== 'string');
    let result = [];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let status = {} as IPurchaseStatus;
      status.id = key;
      status.name = PurchaseStatus[key];
      result.push(status);
    };
    return result;
  }

  getMaintenanceTypeList() : IMaintenanceType[] {
    const keys = Object.keys(MaintenanceType)
      .map(key => MaintenanceType[key])
      .filter(value => typeof value !== 'string');
    let result = [];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let status = {} as IMaintenanceType;
      status.id = key;
      status.name = MaintenanceType[key];
      result.push(status);
    };
    return result;
  }

  getFineConditionTypeList() : IFineConditionType[] {
    const keys = Object.keys(FineConditionType)
      .map(key => FineConditionType[key])
      .filter(value => typeof value !== 'string');
    let result = [];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let status = {} as IFineConditionType;
      status.id = key;
      status.name = FineConditionType[key];
      result.push(status);
    };
    return result;
  }

  getPreFineConditionTypeList() : IPreFineConditionType[] {
    const keys = Object.keys(PreFineConditionType)
      .map(key => PreFineConditionType[key])
      .filter(value => typeof value !== 'string');
    let result = [];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let status = {} as IPreFineConditionType;
      status.id = key;
      status.name = PreFineConditionType[key];
      result.push(status);
    };
    return result;
  }

  getStopTypeList() : IStopType[] {
    const keys = Object.keys(StopType)
      .map(key =>StopType[key])
      .filter(value => typeof value !== 'string');
    let result = [];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let status = {} as IStopType;
      status.id = key;
      status.name = StopType[key];
      result.push(status);
    };
    return result;
  }

}
