export interface Student {
    mobileNo: any;
    id: number;
    admissionNo: string;
    admissionNoCounter: number;
    registrationNo: string;
    admissionDate: string;
    studentName: string;
    studentType:string;
    studentAdmissionType: string;
    academicYearId: string;
    classCourseId: string;
    batchId: string;
    streamId: string;
    dob: string;
    age: string;
    gender: string;
    studentImage: string;
    imageUrl: string;
    imageUrlSrc: string;
    nationalityId: string;
    religionId: string;
    categoryId: string;
    casteId: string;
    motherTounge: string;
    bloodGroup: string;
    smsMobNumber: string;
    whatsAppNo: string;
    emailId: string;
    adharNumber: string;
    houseName: string;
    disabilities: string;
    lastSchool: string;
    yearOfPassing: string;
    classcourse: string;
    tcNumber: string;
    accountHolder: string;
    accountNo: string;
    ifscCode: string;
    spclInterest: string;
    medicalHis: string;
    allergicTo: string;
    medicineAny: string;
    localCountryId: string;
    localStateId: string;
    localCityId: string;
    localPINCode: string;
    localContactNo: string;
    localFullAddress: string;
    copyAddressStatus: string;
    permenantCountryId: string;
    permenantStateId: string;
    permenantCityId: string;
    permenantPINCode: string;
    permenantContactNo: string;
    permenantFullAddress: string;
    fatherName: string;
    fatherQualification: string;
    fatherMobileNo: string;
    fatherEmailId: string;
    fatherAdhaarNumber: string;
    fatherJobDetails: string;
    fatherDesignation: string;
    fatherOrganization: string;
    fatherAnnualIncome: string;
    fatherPhotograph: string;
    fatherImageUrl: string;
    motherFullName: string;
    motherQualification: string;
    motherMobileNumber: string;
    motherEmailId: string;
    motherAdhaarNumber: string;
    motherJobDetails: string;
    motherDesignation: string;
    motherOrganization: string;
    motherAnnualIncome: string;
    motherPhotograph: string;
    motherImageUrl: string;
    rollNo: string;
    loginStatus: string;
    loginId: string;
    password: string;
    biometricCode: string;
    transportService: string;
    hostelService: string;
    libraryService: string;
    messService: string;
    localStateName: string;
    localCityName: string;
    permanentStateName: string;
    permanentCityName: string;
    academicYearName: string;
    batchName: string;
    className: string;
    streamName: string;
    disability: string;
    nationalityName: string;
    religionName: string;
    casteName: string;
    categoryName: string;
    GenderMale: string;
    GenderFemale: string;
    GenderOther: string;
    status: string;
    // studentSiblings: StudentSibling[];
    studentSiblings: string;
    documentlist: StudentDocument[];
    attendance?: boolean;
    leave?: boolean;
    remarks?: string;
    checked?: boolean;
    moved?: boolean;
    attendanceId: number;
    previousClassName: string;
    services: string;
    isAssigned: boolean;
    isBlockFromLibrary: boolean;
    siblingsCount: number;
    studentClassId: string;
    houseId: string;
    studentAdmissionTypeName?: string;
    sno?: number;
    activeConcession:String; 
}

export class StudentDocument {
    id: number;
    academicYearId: number;
    fileName: string;
    contentType: string;
    contentBase: string;
    contentFile = [];
}

export class StudentSibling {
    id: number;
    admissionId: number;
    admissionNo: string;
    studentName: string;
    className: string;
    batchName: string;
    rollNo: string;
    fatherName: string;
    status: string;
}

export class StudentClass {
    id: number;
    admissionId: string;
    academicYearId: string;
    classId: string;
    batchId: string;
    rollNo: string;
    PreviousClassName: string;
}

export class StudentDetails {
    id: number;
    studentClassId: string;
    classCourseId: string;
    batchId: string;
    admissionNo: string;
    admissionNoCounter: number;
    studentName: string;
    studentAdmissionType: string;
    rollNo: string;
    studentImage: string;
    imageUrl: string;
    imageUrlSrc: string;
    checked?: boolean;
 }

export class BulkImage {
    id: number;
    admissionNo: string;
    admissionNoCounter: string;
    studentImage: string;
    ImageUrl: string;
}

export class StudentMinimumInfo {
    id: number;
    StudentName: string;
    AdmissionNo: string;
    RollNo: string;
}

export class StudentReq {
    ClassId: number;
    BatchId: number;
}

export class StudentAdmissionReq {
    admissionNo: string;
}

export class InactiveStudent {
    id: number;
	admissionId: number;
	admissionNo: string;
	studentName: string;
	classId: string;
	className: string;
	batchId: string;
	batchName: string;
	rollNo: string;
	fatherName: string;
	fatherMobileNo: string;
	inactiveDate: string;
	inactiveReason: string;
}

export class StudentsSiblingsReport {
    id: number;
	siblingIds: string;
	countOfSS: string;
	fatherName: string;
	fatherMobileNo : string;
}

