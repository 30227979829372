import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { throwError, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Ticket, TicketPriority, TicketType } from '../models/ticket.model';
import { Institution } from '../models/institution.model';
import { TicketStatusRemark } from '../models/ticket-status-remark.model';



@Injectable({
    providedIn: 'root'
  })
  export class TicketCommonServices {
    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
           enctype: 'multipart/form-data'
        })
      };
      constructor(private httpClient: HttpClient) { }

      getAllTickets(): Observable<Ticket[]> {
        return this.httpClient.get<Ticket[]>(environment.apiUrl + '/tickets')
        .pipe(
          catchError(this.errorHandler)
        );
      }
      getTicketDetailsById(TicketId: string): Observable<Ticket> {
        return this.httpClient.get<Ticket>(environment.apiUrl + '/tickets/' + TicketId)
        .pipe(
          catchError(this.errorHandler)
        );
      }
      update(ticketId, ticket: Ticket, statusRemarks: TicketStatusRemark): Observable<Ticket> {
        //tslint:disable-next-line:max-line-length
        return this.httpClient.put<Ticket>(environment.apiUrl + '/tickets/' + ticketId, JSON.stringify(ticket), this.httpOptions)
        .pipe(
          catchError(this.errorHandler)
        );
      }

      createTicketStatusChanageRemarks(statusRemarks: TicketStatusRemark): Observable<any> {
        console.log(statusRemarks)
        return this.httpClient.post(environment.apiUrl + '/tickets/CreateTicketStatusRemark/', statusRemarks)
            .pipe(
                catchError(this.errorHandler)
            );
      }
      GetTicketRemarksByTicketId(TicketId: string): Observable<TicketStatusRemark[]> {
          return this.httpClient.get<TicketStatusRemark[]>(environment.apiUrl + '/tickets/GetTicketRemarksByTicketId/' + TicketId)
          .pipe(
              catchError(this.errorHandler)
          );
      }

      errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.message;
        } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
     }

  }