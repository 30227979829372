import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Employees } from '../course-chapter-planner/model/Employees';
import { CoursePlannerService } from '../course-planner/course-planner.service';
import { BatchVM } from '../course-planner/model/Batch';
import { ClassVM } from '../course-planner/model/ClassVM';
import { CoursePlannerVM } from '../course-planner/model/CoursePlannerVM';
import { OnlineClassReportService } from '../online-class-report.service';
import { SessionPlannerService } from '../session-planner.service';
import { SessionPlannerVM } from '../session-planner/model/SessionPlannerVM';
import { StudentVm } from '../session-planner/model/StudentVm';
import { ReportVm } from './model/model';
import { SessionStudentAttendanceReport } from './model/SessionStudentAttendanceReport';

@Component({
  selector: 'app-online-class-report',
  templateUrl: './online-class-report.component.html',
  styleUrls: ['./online-class-report.component.css']
})
export class OnlineClassReportComponent implements OnInit {

public lstClass: ClassVM[] = new Array<ClassVM>();
public modelvm: ReportVm = new ReportVm;
public lstBatch: BatchVM[] = new Array<BatchVM>();
public lstCourseList: CoursePlannerVM[] = new Array<CoursePlannerVM>();
public lstSessionPlannerVM: SessionPlannerVM[] = new Array<SessionPlannerVM>();
public lstActualSessionPlannerVM: SessionPlannerVM[] = new Array<SessionPlannerVM>();
public lstemployee: Employees[] = new Array<Employees>();
public lstStudent: StudentVm[] = new Array<StudentVm>();
public lstStudentAttendance: SessionStudentAttendanceReport[] = new Array<SessionStudentAttendanceReport>();
public selectedSession: SessionPlannerVM = new SessionPlannerVM();
classId:number=0;batchId:number=0;typeId:number=0;courseId:number=0;tutorId:number=0;

totalStudent: Number;
selectedStudent: Number;

  coursePlanForm: UntypedFormGroup;
  submitted = false;
  presentStudents: number;
  absentStudents: number;
   



  constructor(private onlineClassReportService: OnlineClassReportService,
    private coursePlannerService: CoursePlannerService,  private sessionPlannerService: SessionPlannerService,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {

    this.getAllClass();

    this.GetAllEmployee();

    this.GetInitialInfo();

    this.coursePlanForm = this.formBuilder.group({
      Id: 0,
      ClassId: [, Validators.required],
      SubjectId: [, Validators.required],
      CourseId: [, Validators.required],
      BatchId: [, Validators.required],
      coursePlannerId: [0, Validators.required],
      sessionType:  [ 0, Validators.required],
      employeeId:  [ 0, Validators.required],

      
      
      // TopicPlannerTitle: ['', [Validators.required]],
      });

      this.GetAllReport();


  }


  public getTutorID(){

    var employeeId = this.coursePlanForm.get('employeeId').value;
    if(employeeId>0){

      ;
      //this.lstSessionPlannerVM  = this.lstActualSessionPlannerVM.filter(x=> x.tutorId == parseInt(employeeId));
      //this.lstSessionPlannerVM = sessionPlan;

    //  this.getAllBatchByClass(ClassID);


    }
    
  }

  public GetAllEmployee() {
    this.sessionPlannerService.GetAllEmployee().subscribe((response) => {
      this.lstemployee = response;
    });
  }
  public GetAllReport(){
    this.onlineClassReportService.GetOnlineClassReportById().subscribe((response) => {
      
      this.lstActualSessionPlannerVM = response;

    });
    console.log(this.lstActualSessionPlannerVM);
  }

  public GetInitialInfo(){
   
    this.onlineClassReportService.GetClass().subscribe((response) => {
      
      this.modelvm = response;

    });

    
  }
  public onResetSearch(){
    this.coursePlanForm = this.formBuilder.group({
      Id: 0,
      ClassId: [, Validators.required],
      SubjectId: [, Validators.required],
      CourseId: [, Validators.required],
      BatchId: [, Validators.required],
      coursePlannerId: [, Validators.required],
      sessionType:  [ -1, Validators.required],
      Status: [-1, Validators.required],
      Aproval:[-1, Validators.required]
      });
  
    this.GetInitialInfo();
    this.getAllClass();
    this.GetAllReport();
    this.getSessionType();
  }

  public getAllClass() {
    this.coursePlannerService.GetClass().subscribe((response) => {
      this.lstClass = response;
    });
  }

  public getAllBatchByClass(k) {
    this.coursePlannerService.getAllBatchByClass(k).subscribe((response) => {
      this.lstBatch = response;
    });
  }


  public getClassId(){
    
    

    var ClassID = this.coursePlanForm.get('ClassId').value;
   /* if(ClassID>0){

      ;
      this.onlineClassReportService.GetOnlineClassReportById().subscribe((response) => {
        console.log(response);
        var sessionPlan = response.filter(x=> x.classId == parseInt(ClassID));
        this.lstSessionPlannerVM = sessionPlan;
        this.lstActualSessionPlannerVM = response;

        this.getAllBatchByClass(ClassID);
  
      });
    


    }*/
    this.lstSessionPlannerVM = this.lstActualSessionPlannerVM.slice();
    if(ClassID>0){
      var sessionPlan = this.lstActualSessionPlannerVM.filter(x=> x.classId == parseInt(ClassID));
      this.lstSessionPlannerVM = sessionPlan;
      this.getAllBatchByClass(ClassID);
    }
    if(ClassID==null){
      this.lstSessionPlannerVM.splice(0,this.lstSessionPlannerVM.length);
    }
    
  }


  public getBatchId(){
    var BatchID = this.coursePlanForm.get('BatchId').value;
    var ClassID = this.coursePlanForm.get('ClassId').value;
    
    if(BatchID>0){

      //this.GetAllReport();
      //this.getClassId();
      //var sessionPlan = this.lstSessionPlannerVM.filter(x=> x.batchId == parseInt(BatchID));
      //this.lstSessionPlannerVM = sessionPlan;
  
      
     this.getAllCoursePlanByClassBatch(ClassID,BatchID)
     //this.coursePlanForm.get('BatchId').patchValue(BatchID);
      //this.GetAllSubject();
    }
    /*else{
      this.getClassId();
      //this.lstSessionPlannerVM = sessionPlan;
    }*/
    
  }

  public getAllCoursePlanByClassBatch(k,batchId) {
    this.coursePlannerService.getAllCoursePlanByClassBatch(k,batchId).subscribe((response) => {
      this.lstCourseList = response;
    });
  }

  public getCourseId(){

  }


  public saveCourseChapter(){


  }

  public getAllCoursePlanByClass(k) {
    this.coursePlannerService.getAllCoursePlanByClass(k).subscribe((response) => {
      this.lstCourseList = response;
    });
  }


  public getCoursePlannerID(){

    var coursePlannerId = this.coursePlanForm.get('coursePlannerId').value; 
    var BatchID = this.coursePlanForm.get('BatchId').value;
    var ClassID = this.coursePlanForm.get('ClassId').value;
    /*this.getSessionType();

    var sessionPlan = this.lstSessionPlannerVM;
    if(coursePlannerId>0)
      {
         sessionPlan = this.lstSessionPlannerVM.filter(x=> x.coursePlannerId == parseInt(coursePlannerId));
      }else {
        this.lstSessionPlannerVM = sessionPlan;
      } */   
      
     
  }


  public getSessionType(){
    
    var sessionType = this.coursePlanForm.get('sessionType').value; 
   
    //this.getClassId();
    /*this.getBatchId();
    if(sessionType != 0){
      var sessionPlan = this.lstSessionPlannerVM.filter(x=> x.sessionType == parseInt(sessionType));
      this.lstSessionPlannerVM = sessionPlan;
    }*/
    
  }


  public sessionDetails(Id){


    var sessionPlan = this.lstActualSessionPlannerVM.find(x=> x.id == Id);

    ;

   // var list = sessionPlan.students.split(',').map(Number);;

   /* this.sessionPlannerService.GetAllStudentsInfoByStudentId(sessionPlan.students).subscribe((response) => {
      ;
      this.lstStudent = response;
      this.totalStudent = response.length;
      this.selectedSession = sessionPlan;

      ;
    });*/
    this.sessionPlannerService.GetAllStudentsAttendanceReport(Id).subscribe((response) => {
      
      this.lstStudentAttendance = response;
      this.totalStudent = response.length;
      this.selectedSession = sessionPlan;
      this.presentStudents=response.filter(st=>st.spendTime !=="00:00:00").length;
      this.absentStudents=response.filter(st=>st.spendTime ==="00:00:00").length;
    
      
    });

    console.log(this.presentStudents);
    console.log(this.absentStudents);
  }
   


}
