import { Component, OnInit, Input} from '@angular/core';
//import { CalendarOptions } from '@fullcalendar/angular';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EventCalendar } from './../eventcalendar.model';
import { EventCalendarService } from './../eventcalendar.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { SettingsService } from '../../mastersettings/settings.service';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { HttpClient } from '@angular/common/http';
import interactionPlugin from '@fullcalendar/interaction';
//import { setTimeout } from 'timers';

declare var $: any;

@Component({
  selector: 'app-eventcalendar',
  templateUrl: './eventcalendar.component.html',
  styleUrls: ['./eventcalendar.component.css']
})
export class EventcalendarComponent implements OnInit {
  @Input() eventData: any = [];

  Events: any[] = [];
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, interactionPlugin],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    //dateClick: this.handleDateClick.bind(this), // bind is important!
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,

    // dayCellContent: function(date,cell){
    //   cell.css("backgroundColor","Blue");
    // },
  };
  
  eventForm: UntypedFormGroup;
  eventsBeforeFilter: EventCalendar[] = [];
  events: EventCalendar[] = [];
  eventsTemp: EventCalendar[] = [];
  submitted = false;
  startDate: any;
  endDate: any;
  sDate = '';
  fDate = '';
  academicYears: Academicyear[] = [];
  AcademicYearId:any=0;
  // eventData: any[] = [];

  constructor(
    private eventCalendarService: EventCalendarService,
    public academicyearService: SettingsService,
    public settingsService: SettingsService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,private httpClient: HttpClient
  ) { }

  onDateClick(arg) {
   const self = this;
   //alert(arg.dateStr);
   $('#addEvent').modal('show');
   this.resetForm();
   // $('#startDate').val(self.formatDate(arg.dateStr));
   this.eventForm.controls['startDate'].setValue(self.formatDate(arg.dateStr));
   this.eventForm.controls['endDate'].setValue(self.formatDate(arg.dateStr));
   this.eventForm.controls['startTime'].setValue('12:00');
   this.eventForm.controls['endTime'].setValue('11:59');
 }
  ListEvents() {
    
    this.eventCalendarService.getAll().subscribe((data: EventCalendar[]) => {
      console.log("---------------")
      console.log(data);
      this.eventData = [];
      this.events = this.eventsBeforeFilter = data;
      this.events.forEach((x: EventCalendar) => {
        const m = {
          title: x.eventName,
          start: x.startDate,
          end: x.endDate
        };

        this.eventData.push(m);
      });
      console.log(this.eventData);
      //this.calendarOptions.events = this.eventData;
      this.getStartEndDate();
    });
  }
  ListAcademicYear() {
    this.settingsService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
      let AcademicYearItem = this.academicYears.find(year=>year.isCurrentAcademicYear==true);
     this.AcademicYearId = AcademicYearItem.id;
    });
  }
  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  formatDateYMD(date) {
    const input = date;
    const [day, month, year] = input.split('-');
    return [year, month, day].join('-');
  }

  onApplyTypeSelected(value: string) {
    if (value === 'All' ) {
      this.eventForm.get('applyType').patchValue('All');
    } else {
      this.eventForm.get('applyType').patchValue(value);
    }
  }

  get f() { return this.eventForm.controls; }
resetForm() {
  this.submitted = false;
  this.eventForm = this.fb.group({
    id: [0],
    academicYearId: [this.AcademicYearId],
    eventName: ['', Validators.required],
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    startTime: ['', Validators.required],
    endTime: ['', Validators.required],
    applyType: ['', Validators.required],
    eventType: ['', Validators.required],
    description: [''],
    status: ['true', Validators.required]
 });
}

submitForm() {
  this.submitted = true;
  
  if (this.eventForm.get('applyType').value === 'All' ) {
    this.eventForm.get('applyType').patchValue('Student | Employee');
  }
  if (this.eventForm.invalid) {
    console.log(this.eventForm.value);
    return;
   }
  // Date validation
  const startDate = this.eventForm.get('startDate').value;
  const endDate = this.eventForm.get('endDate').value;
  let startDt: Date;
  let endDt: Date;
  startDt = new Date(this.formatDateYMD(startDate));
  endDt = new Date(this.formatDateYMD(endDate));
  if (((endDt.getTime()) < (startDt.getTime())) || (startDt.toString() === 'Invalid Date') || (endDt.toString() === 'Invalid Date')) {
    this.toastr.warning('Please select greater date than start date', 'Warning!', {
      timeOut: 3000
    });
    // this.submitted = false;
    return;
  }
  // if (this.eventForm.get('applyType').value === 'All' ) {
  //   this.eventForm.get('applyType').patchValue('Student | Employee');
  // }

  if (this.eventForm.get('id').value === 0) {
     
      console.log(this.eventForm.value);

      this.eventCalendarService.create(this.eventForm.value).subscribe(res => {
        console.log('Event created!');
        this.ListEvents();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.eventCalendarService.update(this.eventForm.get('id').value, this.eventForm.value).subscribe(res => {
          console.log('Event updated!');
          this.ListEvents();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
          // this.casteForm.reset();
        });
      }


    }

    updateForm(id: number) {
      this.eventCalendarService.getById(id).subscribe((data: EventCalendar) => {
        console.log(data);
        //data.academicYearId = '0';
        data.academicYearId = this.AcademicYearId;
        if (data.applyType === 'Student | Employee') {
            data.applyType = 'All';
        }
        this.eventForm = this.fb.group({
          id: [data.id],
          academicYearId: [data.academicYearId, Validators.required],
          eventName: [data.eventName, Validators.required],
          startDate: [this.formatDate(data.startDate), Validators.required],
          endDate: [this.formatDate(data.endDate), Validators.required],
          startTime: [data.startTime, Validators.required],
          endTime: [data.endTime, Validators.required],
          applyType: [data.applyType, Validators.required],
          eventType: [data.eventType, Validators.required],
          description: [data.description, Validators.required],
          status: [data.status, Validators.required]
       });
      });

    }

    deleteForm(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.eventCalendarService.delete(id).subscribe(res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            this.ListEvents();
          });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
        }
      });
    }

  getStartEndDate() {
     ;
    const CalenderDate = $('.fc-toolbar-title').html();
    let Month = CalenderDate.replace(/[0-9]/g, '').replace(/ /g, '');
    const Year = CalenderDate.replace(/[^\d.-]/g, '').replace(/ /g, '');
    const months = ["January", "February", "March", "April", "May",
     "June", "July", "August", "September", "October", "November", "December"];
    for (const i in months) {
      if (months[i] === Month) {
        Month = i;
      }
    }
    const sDate = new Date(Year, Month, 1);
    const eDate = new Date(Year, ++Month, 0);
    this.startDate = sDate;
    this.endDate = eDate;
    this.filterListEvents();
  }
  filterListEvents() {
    this.eventsTemp = this.eventsBeforeFilter.filter(d => {
      const sdate = new Date(d.startDate);
      const edate = new Date(d.endDate);
      return (this.startDate.getMonth() === sdate.getMonth() &&
      this.startDate.getFullYear() === sdate.getFullYear() ||
      this.endDate.getMonth() === edate.getMonth() && this.endDate.getFullYear() === edate.getFullYear());
    });

    this.events = this.eventsTemp;
  }

  ngOnInit(): void {
   this.ListEvents();
   this.ListAcademicYear();
    this.eventForm = this.fb.group({
      id: [0],
      academicYearId: [this.AcademicYearId],
      eventName: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      applyType: ['', Validators.required],
      eventType: ['', Validators.required],
      description: [''],
      status: ['true', Validators.required]
   });

    $('#startDate').datepicker().on('changeDate', (e) => {
      this.eventForm.get('startDate').patchValue(this.formatDate(e.date));
      this.sDate = this.formatDate(e.date);
      console.log(this.sDate);
    });
    $('#endDate').datepicker().on('changeDate', (e) => {
      this.eventForm.get('endDate').patchValue(this.formatDate(e.date));
    });

    // $('#sartTime').datepicker().on('changeDate', (e) => {
    //   this.eventForm.get('sartTime').patchValue(this.formatDate(e.date));
    // });
    // $('#endTime').datepicker().on('changeDate', (e) => {
    //   this.eventForm.get('endTime').patchValue(this.formatDate(e.date));
    // });
    setTimeout(()=>{
      this.calendarOptions = {
        initialView: 'dayGridMonth',
        events: this.Events,
        dateClick: this.onDateClick.bind(this),
      };
    },2500)

    
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    $('.fc-prev-button').on('click', x => {
      this.getStartEndDate();
    });
    $('.fc-next-button').on('click', x => {
      this.getStartEndDate();
    });
    $('.fc-today-button ').on('click', x => {
    this.getStartEndDate();
    });
    this.getStartEndDate();
  }

}
