import { Component, OnInit } from '@angular/core';
import { ExamsettingService } from '../../services/examsetting.service';
import { ExamCategory } from '../models/exam-category.model';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-exam-category',
  templateUrl: './exam-category.component.html',
  styleUrls: ['./exam-category.component.css'],
  
})

export class ExamCategoryComponent implements OnInit {

    
  constructor(private examService :ExamsettingService,private toastr:ToastrService,public fb: UntypedFormBuilder) {
   }

  examCat=new ExamCategory(0,'','',null);  
  examCategory:ExamCategory[]=[];
  examCategoryFilteredList: ExamCategory[] = [];
  examCategoryForm: UntypedFormGroup
  submitted = false;
  searchedKeyword:string;
  categoryCount:number;

  ngOnInit(): void {
    this.examCategoryForm=this.fb.group({
      id:[0],
      category:['',Validators.required],
      description:['',Validators.required],
      categoryStatus:[true,Validators.required]
    });

    this.getAllExamCategory();
  }

  get f() { return this.examCategoryForm.controls; }
  
  resetForm(){
    this.submitted = false;
    this.examCategoryForm=this.fb.group({
      id:[0],
      category:['',Validators.required],
      description:['',Validators.required],
      categoryStatus:[true,Validators.required]
    })
  }
  
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.examCategoryFilteredList = this.examCategory;
      this.categoryCount= this.examCategoryFilteredList.length;
    } else {
    this.examCategoryFilteredList = this.examCategory
                                .filter((examCategory: ExamCategory) => examCategory.category.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
    this.categoryCount=this.examCategoryFilteredList.length;
    }
  }

  submitForm(){      
    this.submitted=true;
    if(this.examCategoryForm.invalid){
      this.toastr.warning('All field are required','WARNING!!!',{timeOut:3000});
      return;
    }

    if(this.examCategoryForm.get('id').value===0)
    {
        this.examService.createExamCategory(this.examCategoryForm.value).subscribe(res=>{                
        this.toastr.success("Exam Category successfully created","SUCCESS!",{timeOut:3000});
        this.submitted=false;
        this.resetForm();
        
        this.getAllExamCategory();
        //this.examCategoryForm.reset();
    });    
   }
   else{
         this.examService.updateExamCategory(this.examCategoryForm.get('id').value,this.examCategoryForm.value).subscribe(res=>{           
           this.toastr.success("Exam Category successfully updated","SUCCESS!",{timeOut:3000});
           this.getAllExamCategory();
         });
   }
  }

  
  getAllExamCategory(){
    this.examService.getAllExamCategory().subscribe((data:ExamCategory[])=>{     
      this.examCategoryFilteredList=this.examCategory=data;  
      this.categoryCount= this.examCategoryFilteredList.length;    
    })
  }

  editExamCategory(id:number){
    this.examService.getExamCategoryById(id).subscribe((data:ExamCategory)=>{
      this.examCategoryForm=this.fb.group({
        id:[data.id],
        category:[data.category, Validators.required],
        description:[data.description,Validators.required],
        categoryStatus:[data.categoryStatus,Validators.required]
      })
    })
  }
  
  deleteExamCategory(id:number){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton:true,
      confirmButtonText:'Yes, delete it!',
      cancelButtonText:'No, keep it'
    }).then((result)=>{
      if(result.value){
        this.examService.deleteExamCategory(id).subscribe(data=>{
          Swal.fire(
            'Deleted!',
            'Exam category has been deleted.',
            'success'
          );
          this.getAllExamCategory();
        })
      }
    })
  }
}
