import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../loader.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  loading: boolean;

  constructor(private loaderService: LoaderService) {



  }
  readonly loader$=this.loaderService.isLoading.pipe(
    map(v=>{
     return  v;
    })
  
  );



  ngOnInit(): void {
  }


}
