import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeeSummaryHeadWiseService } from './feesummaryheadwise.service';
import { FeeSummaryHeadWiseModel } from './feesummaryheadwise.model';
import { ClassCourse } from '../../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../../mastersettings/models/batch';
import { BatchService } from '../../../mastersettings/batch/batch.service';
import { FeeHeadService } from './../../feehead/feehead.service';
import { FeeHead } from './../../feehead/feehead.model';
import { DatePipe, TitleCasePipe } from '@angular/common';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { SettingsService } from '../../../mastersettings/settings.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import { ReportAttribute } from '../../../studentmanagement/report.model';
import { environment } from 'src/environments/environment';
import { Academicyear } from '../../../mastersettings/models/academicyear';
import { FeeHeadWiseReportVm } from '../feeheadwisecollectionreport/feeheadwisecollectionreport.model';
import { formatDateByMoment } from 'src/app/shared/helpers/date-utility';
declare var $: any;
@Component({
  selector: 'app-feesummaryheadwise',
  templateUrl: './feesummaryheadwise.component.html',
  styleUrls: ['./feesummaryheadwise.component.css'],
  providers: [TitleCasePipe]
})
export class FeesummaryheadwiseComponent implements OnInit {
  sumTotalAmt: any;
  sumTotalDiscount: any;
  sumTotalPayable: any;
  sumTotalPaid: any;
  sumTotalRefund: any;
  sumTotalWaveOff: any;
  sumTotalDue: any;
  classDrp: string;
  batchDrp: string;
  feeHeadTypeDrp: string;
  fromDate: string;
  toDate: string;
  totalPaidAmount: any;
  dataCount: any;
  dateFrom: string;
  dateTo: string;
  paymentMode: string;
  feeSummaryHeadWiseList: FeeSummaryHeadWiseModel[] = [];
  feeSummaryHeadWiseFilteredList: FeeSummaryHeadWiseModel[] = [];
  feeSummaryHeadWiseTemp: FeeSummaryHeadWiseModel[] = [];
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  feeHeadList: FeeHead[] = [];

  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  defaultArray = [];
  academicYears: Academicyear[] = [];
  institution: any;
  currentAcademicYearId: number;
  currentAcademicYearName = '';
  dateValidation: boolean = false;
  summarydrp: string;
  feeHeadWiseReportVm: FeeHeadWiseReportVm = new FeeHeadWiseReportVm();

  constructor(
    public feeSummaryHeadWiseService: FeeSummaryHeadWiseService,

    public classCourseService: ClassCourseService,
    public batchService: BatchService,
    public feeHeadService: FeeHeadService,
    private academicYearService: SettingsService,
    private toastr: ToastrService,
    private titlecasePipe: TitleCasePipe
  ) { }
  listClassCourse() {
    this.classCourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }
  getFeeHeadList() {
    this.feeHeadService.getAll().subscribe((data: FeeHead[]) => {
      this.feeHeadList = data;
    });

  }
  onClassSelected(value: string) {
    this.classDrp = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString().toLowerCase() === value.toLowerCase());
    });
    // this.onSearch();
  }

  onFeeHeadChanged() {

  }

  private validateDateRange(feeHeadWiseReportVm: FeeHeadWiseReportVm): void {
   debugger
    let message: string;
    if (!this.fromDate || !this.toDate) {
      message = 'Please select date';
    }
    else {
      if (this.fromDate >= this.toDate) {
        message = 'FromDate is greater than ToDate';
      }
    }

    if (message) {
      //this.dateRangeIsValid = false;
      this.toastr.warning(message, 'WARNING!', {
        timeOut: 3000
      });
    }
    else {
     // this.dateRangeIsValid = true;
    }
  }
  onSearch() {
   
    this.validateDateRange(this.feeHeadWiseReportVm);
    // if (this.fromDate != undefined && this.fromDate != '' && this.fromDate != null &&
    //   this.toDate == undefined || this.toDate == '' || this.toDate == null) {
    //   this.toastr.warning('Please select date', 'WARNING!', {
    //     timeOut: 3000
    //   });
    // }
    this.getfeeSummaryHeadWise();
  }

  onPaymentMode() {
    //  this.onSearch();
  }
  onBatchChanged() {
    // this.onSearch();
  }

  public flag: any = [];
  public data: any;
  public datafield: any;

  async getfeeSummaryHeadWise() {
    // const self = this;
    // this.feeSummaryHeadWiseService.getFeeSummaryHeadWise().subscribe((data: FeeSummaryHeadWiseModel[]) => {
    //   this.feeSummaryHeadWiseFilteredList = this.feeSummaryHeadWiseList = data;
    //   this.dataCount = data.length;
    // });

    // this.feeSummaryHeadWiseTemp = this.feeSummaryHeadWiseFilteredList;

    const drpClass = this.classDrp == 'Select Class' ? 0 : parseInt(this.classDrp);
    const drpBatch = this.batchDrp == 'Batch' ? 0 : parseInt(this.batchDrp);
    const drpfeehead = this.feeHeadTypeDrp == 'Fee Head' ? 0 : parseInt(this.feeHeadTypeDrp);
   
    if (this.fromDate != undefined && this.fromDate != '' && this.fromDate != null &&
      this.toDate != undefined && this.toDate != '' && this.toDate != null) {
      const datePipe = new DatePipe('en-Us');
      var fdt = datePipe.transform(this.fromDate, 'yyyy-MM-dd');
      var tdt = datePipe.transform(this.toDate, 'yyyy-MM-dd');
     
      this.flag = await this.feeSummaryHeadWiseService.getFeeSummaryHeadWise(drpClass, drpBatch, drpfeehead, fdt, tdt);
     
      // this.loader = false;
      if (this.flag != null && this.flag.table != null && this.flag.table.length > 0) {
        this.data = this.flag.table;
        this.datafield = Object.keys(this.flag.table[0]);
      }
      else {
        this.data = [];
        this.datafield = [];
      }


    }
  }
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === '') {
      this.onSearch();
    } else {
      this.feeSummaryHeadWiseTemp = this.feeSummaryHeadWiseTemp.filter(d => {
        return (d.feeHead.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
      });
      this.feeSummaryHeadWiseFilteredList = this.feeSummaryHeadWiseTemp;
    }
  }


  generatePDF() {
   
    this.generateAttributes();
    const pdffromdate = $('#dateFrom').val();
    const pdftodate = $('#dateTo').val();
    this.headerList = [];
    this.fieldList = [];

    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const widthList = [];
    for (const item of this.headerList) {
      widthList.push('auto');
    }


    this.defaultArray = [];
    //let totalAmount = 0;
    this.data.forEach(elementdata => {
      let data
      let item = {};
      this.datafield.forEach(elementfield => {
        item[elementfield] = elementdata[elementfield] == undefined ? "" : elementdata[elementfield];
      });
      this.defaultArray.push(item);
    });


    let item = {};
    this.datafield.forEach(elementfield => {
      item[elementfield] = "";
    });

    this.defaultArray.push(item);

    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      content: [
        {
          table: {
            widths: ['auto', '*'],
          
            body: [
              [{
                width: 100,
                height: 100,
                image: 'imageKey'
              }, {
                width: '*',
                alignment: 'center',
                
                stack: [
                  {
                    style: 'h1',
                    text: this.institution?.institutionName
                  },
                  {
                    style: 'h2',
                    text: this.institution?.address
                  },
                  {
                    style: 'h2',
                    text: this.currentAcademicYearName
                  },
                  {
                    style: 'h3',
                    text: 'FEE SUMMARY HEAD WISE'
                  },
                  {
                    style: 'h3',
                    text: pdffromdate + '   to   ' + pdftodate
                  }
                ]
              }
              ]
            ]
          },
          layout: {
            hLineWidth: () => 0,
            vLineWidth: () => 0,
            paddingBottom: () => 3,
            paddingLeft: () => 5,
            paddingRight: () => 5
          },
        },
        {
          style: 'sectionHeader',
          
          table: {
            headerRows: 1,
          
            widths: widthList,
            body: [
              this.headerList,
              ...this.defaultArray.map((p) => {
                const temp = [];
                for (const y of this.fieldList) {
                  if (y === 'dob') {
                    temp.push(this.formatDate(p[y]));
                  } else {
                    temp.push(p[y]);
                  }
                }
                return temp;
              })
            ]
          }
        }
      ],
      images: {
        // imageKey: environment.imageUrl + this.institution.logoUrl // via URL address, which can be referenced by this key
        imageKey: this.institution?.logoUrl != null ? environment.imageUrl + this.institution?.logoUrl : environment.imageUrl + '/images/avatar-1.png'
      },
      styles: {
        sectionHeader: {
          fontSize: 8,
          margin: [0, 10, 0, 15],
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 13,
          bold: true,
        },
        h2: {
          margin: [0, 5, 0, 0],
          fontSize: 10,
          bold: true,
        }
      }
    };
    pdfMake.createPdf(docDefinition).download('FEE SUMMARY HEAD WISE.pdf');
  }

  generateExcel() {
   
    this.generateAttributes();
    this.headerList = [];
    this.fieldList = [];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    this.defaultArray = [];
    // let totalAmount = 0;
    this.data.forEach(elementdata => {
      let data
      let item = {};
      this.datafield.forEach(elementfield => {
        item[elementfield] = elementdata[elementfield] == undefined ? "" : elementdata[elementfield];
      });
      this.defaultArray.push(item);
    });



    let item = {};
    this.datafield.forEach(elementfield => {
      item[elementfield] = "";
    });

    this.defaultArray.push(item);

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('FEE SUMMARY HEAD WISE');

    // const imageId2 = workbook.addImage({
    //   filename: 'images/avatar-1.png',
    //   extension: 'png',
    // });
    // worksheet.addImage(imageId2, 'A1:B1');

    const instName = worksheet.addRow([this.institution.institutionName]);
    instName.eachCell(function (cell, colNum) {
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 10
      }
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'start'
      }
    })
    instName.height = 25;
    const instAddress = worksheet.addRow([this.institution.address]);
    instAddress.eachCell(function (cell, colNum) {
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 9
      }
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'start'
      }
    })
    instAddress.height = 20;

    const instSession = worksheet.addRow([this.currentAcademicYear.name]);
    instSession.eachCell(function (cell, colNum) {
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 10
      }
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'start'
      }
    })
    instSession.height = 20;

    worksheet.addRow([]);
    const titleRow = worksheet.addRow(['FEE SUMMARY HEAD WISE']);
    const fromDate = $('#dateFrom').val();
    const toDate = $('#dateTo').val();
    worksheet.addRow([`${fromDate} to ${toDate}`]);
    const headerRow = worksheet.addRow(this.headerList);
    for (var i = 0; i < this.defaultArray.length; i++)
      this.defaultArray[i]["index"] = i + 1;
    for (const x1 of this.defaultArray) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'payDate') {
          if (x1[y] == "" || x1[y] == undefined) {
            temp.push("");
          } else {
            temp.push(this.formatDate(x1[y]));
          }
        } else {
          if (y === "paidStatus") {
            if (x1[y] === true || x1[y] === false) {
              temp.push(x1[y] === true ? "Cancelled" : "Paid");
            } else {
              temp.push("")
            }
          }
          else {
            temp.push(x1[y]);
          }
        }
      }
      worksheet.addRow(temp);
    }
    const fname = 'FEE SUMMARY HEAD WISE';

    worksheet.eachRow((row, rowNumber) => {

      row.eachCell((cell, colNumber) => {
        if ([1, 2, 3, 4, 5, 6].includes(rowNumber)) {
        } else {
          // Set border of each cell 
          cell.width = 180;
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        }

      })
      //Commit the changed row to the stream
      row.commit();
    });

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }



  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return [day, month, year].join('-');
  }

  generateAttributes() {
    this.attributes = []
    this.datafield.forEach(elementfield => {
      this.attributes.push({ name: this.titlecasePipe.transform(elementfield), fieldName: elementfield });
    });
  }
  currentAcademicYear() {
    this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.currentAcademicYearName = this.academicYears.find(x => x.id.toString() === data.id.toString()).name;
     
    });
  }

  ngOnInit(): void {
    this.feeHeadWiseReportVm = new FeeHeadWiseReportVm();
    const self = this;
    this.feeHeadTypeDrp = 'Fee Head';
    this.batchDrp = 'Batch';
    this.classDrp = 'Select Class';
    $('#dateFrom').datepicker().on('changeDate', (e) => {
     
      this.feeHeadWiseReportVm.fromDate = formatDateByMoment(e.date);
      this.fromDate = e.date;
      self.dateFrom = e.date;
    });
    $('#dateTo').datepicker().on('changeDate', (e) => {
     
      this.feeHeadWiseReportVm.toDate = formatDateByMoment(e.date);
      this.toDate = e.date;
      self.dateTo = e.date;
    });
    this.listClassCourse();
    this.getFeeHeadList();
    // this.getfeeSummaryHeadWise();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
  }

  tdtchange(event: any) {
    var tdt = event.target.value
    if (tdt == null || tdt == undefined || tdt == '') {
      this.dateValidation = false;
      this.toDate = "";
    }
  }
  fdtchange(event: any) {
    var tdt = event.target.value
    if (tdt == null || tdt == undefined || tdt == '') {
      this.dateValidation = false;
      this.fromDate = "";
    }
  }
  

}
