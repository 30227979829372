import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {assignsubjet} from './assignsubject.model';

@Injectable({
    providedIn: 'root'
  })

export class assignsubjectservice
{
    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      constructor(private httpClient: HttpClient) { }

      getAll(): Observable<assignsubjet[]> {
        return this.httpClient.get<assignsubjet[]>(environment.apiUrl + '/AssignSubject/')
        .pipe(
          catchError(this.errorHandler)
        );
      }
      getById(id:any): Observable<assignsubjet> {
        return this.httpClient.get<assignsubjet>(environment.apiUrl + '/AssignSubject/' + id)
        .pipe(
          catchError(this.errorHandler)
        );
      }
      create(subjectcreation:any): Observable<assignsubjet> {
        return this.httpClient.post<assignsubjet>(environment.apiUrl + '/AssignSubject/',
         JSON.stringify(subjectcreation), this.httpOptions)
        .pipe(
          catchError(this.errorHandler)
        );
      }
    
      update(id:any, subjectcreation:any): Observable<assignsubjet> {
        // tslint:disable-next-line:max-line-length
        return this.httpClient.put<assignsubjet>(environment.apiUrl + '/AssignSubject/' + id, JSON.stringify(subjectcreation), this.httpOptions)
        .pipe(
          catchError(this.errorHandler)
        );
      }
    
      delete(id:any) {
        return this.httpClient.delete<assignsubjet>(environment.apiUrl + '/AssignSubject/' + id)
        .pipe(
          catchError(this.errorHandler)
        );
      }
      errorHandler(error) {
         let errorMessage = '';
         if (error.error instanceof ErrorEvent) {
           // Get client-side error
           errorMessage = error.error.message;
         } else {
           // Get server-side error
           errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
         }
         console.log(errorMessage);
         return throwError(errorMessage);
      }
}