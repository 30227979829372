import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import {StudentService} from './../../studentmanagement/student.service';
import {ClassCourseService} from './../../mastersettings/classcourse/classcourse.service';
import {Student } from './../../studentmanagement/student.model';
import {ClassCourse} from './../../mastersettings/models/classcourse';
import {Batch} from './../../mastersettings/models/batch';
import {BatchService} from './../../mastersettings/batch/batch.service';
import { Attendance } from '../attendance.model';
import { AttendanceService } from '../attendance.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { SettingsService } from '../../mastersettings/settings.service';
import { EventCalendar } from '../../eventcalendar/eventcalendar.model';
import { EventCalendarService } from '../../eventcalendar/eventcalendar.service';

@Component({
  selector: 'app-attendanceregister',
  templateUrl: './attendanceregister.component.html',
  styleUrls: ['./attendanceregister.component.css']
})
export class AttendanceregisterComponent implements OnInit {
isLoading=false;
  monthDays: number[] = [];
  monthList: string[] = [];
  classcourses: ClassCourse[] = [];
  batches: Batch[] = [];
  attendances: Attendance[] = [];
  attendanceList: Attendance[] = [];
  academicYear: Academicyear = {} as Academicyear;
  events: Event[] = [];
  attList: any[] = [];
  classcourseid = '-1';
  batchid = '-1';
  currentMonth = '';
  holidayCount = 0;
  holidays: any[] = [];
  holidayDays: number[] = [];
  totalWorkingDays = 0;
  totalSUndayCount = 0;


  constructor(
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    private academicyearService: SettingsService,
    private attendanceService: AttendanceService,
    private eventCalendarService: EventCalendarService
  ) { }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      console.log(data);
      this.academicYear = data;
      const startMonth = new Date(data.startDate).getMonth() + 1;
      const endMonth =  this.monthDiff(new Date(data.startDate), new Date(data.endDate)); // new Date(data.endDate).getMonth() + 1;
      const startDate = new Date(data.startDate);
      const tempStartDate = new Date(data.startDate);
      const tempCurrentDate = new Date(data.startDate);
      for (let i = 0; i <= endMonth; i++) {
        tempStartDate.setMonth(startDate.getMonth() + i);
        tempCurrentDate.setMonth(startDate.getMonth() + i);
        if ((startDate.getMonth() + i) > 12) {
          tempStartDate.setFullYear(tempCurrentDate.getFullYear() - ((startDate.getMonth() + i) - 12));
        }
        this.monthList.push(moment(tempStartDate).format('MMM-yyyy'));
        this.currentMonth = moment().format('MMM-YYYY');
        // this.currentMonth = moment().format('MMMM');
        console.log(this.currentMonth);
        // this.onMonthChange(this.currentMonth);
      }
    });
  }

  monthDiff(d1, d2) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  getHolidays() {
    if(this.currentMonth!=''){
      const monthselected=new Date(this.currentMonth);
      this.eventCalendarService.getHolidaysCount(this.currentMonth).subscribe((data: number) => {
        console.log(data);
        /*this.holidays = data.filter((x) => {
          // console.log(new Date(x.startDate).getMonth());
          return x.eventType === 'Holiday' && new Date(x.startDate).getMonth() + 1 === 11;
        });
        this.holidayCount = this.holidays.length;
        const days = moment(this.currentMonth, 'MMM-YYYY').daysInMonth();*/
        this.holidayCount = data;
        const days = moment(this.currentMonth, 'MMM-YYYY').daysInMonth();
      this.totalWorkingDays = days-(this.totalSUndayCount+this.holidayCount);
      });
      
    }


  }

  onClassSelected(value: string) {
    this.batchService.getByClasseId(value).subscribe((data: Batch[]) => {
      this.batches = data;
    });
  }

  getTotalAttendanceCount(obj: any): number {
    debugger;
    return obj.filter(x => x.Att1 === true && x.IsHoliday !== 1).length;
  }

  getTotalAbsentCount(obj: any): number {
    debugger;
    return obj.filter(x => x.Att1 === false && x.IsHoliday !== 1).length;
  }

  onBatchSelected(value: string) {
    this.isLoading=true;
        const classId = this.classcourseid !== '-1' ? this.classcourseid : '0';
    const batchId = this.batchid !== '-1' ? this.batchid : '0';
    const monthyear = this.currentMonth;
    this.attendanceService.getAttendanceDetail(classId, batchId, monthyear).subscribe((data: any[]) => {
      console.log(data);
      const currentdays: string[] = [];
      const attendmainList: any[] = [];

      data.forEach(x => {
        currentdays.push(x.TheDay);
        const attendSubList: any[] = [];
        if(x.IsHoliday==0){
        attendmainList.push({AdmissionNo: x.AdmissionNo, RollNo: x.RollNo,
          StudentName: x.StudentName, AttendanceList: attendSubList});}
      });


      this.monthDays = currentdays.map(ar => JSON.stringify(ar))
      .filter((itm, idx, arr) => arr.indexOf(itm) === idx)
      .map(str => JSON.parse(str));

      const unique = data.map(ar => JSON.stringify(ar))
      .filter((itm, idx, arr) => arr.indexOf(itm) === idx)
      .map(str => JSON.parse(str));

      // console.log(unique);
      this.attList = attendmainList.map(ar => JSON.stringify(ar))
      .filter((itm, idx, arr) => arr.indexOf(itm) === idx)
      .map(str => JSON.parse(str));

      this.attList.forEach(x => {
        const subList: any[] = [];
        this.monthDays.forEach(y => {
          const xyz = unique.filter((dat: any) => {
              return dat.StudentName === x.StudentName && dat.TheDay.toString() === y.toString();
          });
          subList.push(xyz[0]);
        });
        x.AttendanceList = subList;
        this.totalSUndayCount=0;
        this.totalSUndayCount = subList.filter(r => r.TheDayName === 'Sunday').length;
      });
      debugger;
      this.getHolidays();  
     
      //this.totalWorkingDays = (this.totalWorkingDays - this.totalSUndayCount)-this.holidayCount;
      // console.log(this.attList[0].AttendanceList[0][0]);
      this.isLoading=false;
    });
    
    
    
  }

  onMonthChange(value: string) {
    this.isLoading=true;
    this.getHolidays();
    const days = moment(value, 'MMM-YYYY').daysInMonth();
    this.monthDays = [];
    // for (let i = 1; i < (days + 1); i++) {
    //   this.monthDays.push(i);
    // }
    //this.totalWorkingDays = days - this.holidayCount;
    //this.totalWorkingDays = (this.totalWorkingDays - this.totalSUndayCount)-this.holidayCount;
    // console.log(moment(new Date('01-' + value), 'dd-MM-yyyy').format('dddd'));
    const classId = this.classcourseid !== '-1' ? this.classcourseid : '0';
    const batchId = this.batchid !== '-1' ? this.batchid : '0';
    //alert(classId + "-" + batchId + "-"+ value);
    this.attendanceService.getAttendanceDetail(classId, batchId, value).subscribe((data: any[]) => {
      console.log(data);
      const currentdays: string[] = [];
      const attendmainList: any[] = [];

      data.forEach(x => {
        currentdays.push(x.TheDay);
        const attendSubList: any[] = [];
        attendmainList.push({AdmissionNo: x.AdmissionNo, RollNo: x.RollNo,
          StudentName: x.StudentName, AttendanceList: attendSubList});
      });


      this.monthDays = currentdays.map(ar => JSON.stringify(ar))
      .filter((itm, idx, arr) => arr.indexOf(itm) === idx)
      .map(str => JSON.parse(str));

      const unique = data.map(ar => JSON.stringify(ar))
      .filter((itm, idx, arr) => arr.indexOf(itm) === idx)
      .map(str => JSON.parse(str));
      // console.log(unique);
      this.attList = attendmainList.map(ar => JSON.stringify(ar))
      .filter((itm, idx, arr) => arr.indexOf(itm) === idx)
      .map(str => JSON.parse(str));

      this.attList.forEach(x => {
        const subList: any[] = [];
        this.monthDays.forEach(y => {
          const xyz = unique.filter((dat: any) => {
              return dat.StudentName === x.StudentName && dat.TheDay.toString() === y.toString();
          });
          subList.push(xyz[0]);
        });
        x.AttendanceList = subList;
        this.totalSUndayCount=0;
        this.totalSUndayCount = subList.filter(r => r.TheDayName === 'Sunday').length;
      });
      //this.totalWorkingDays = this.totalWorkingDays - this.totalSUndayCount;
      this.totalWorkingDays =0;

      this.totalWorkingDays = days-(this.totalSUndayCount+this.holidayCount);
      //console.log(this.totalSUndayCount);
       this.isLoading=false;
    });
   
  }
  
  get totalRows(): number {
    return this.attList.length;
  }

  ngOnInit(): void {
    // for (let i = 1; i < 13; i++) {
    //   let date = '';
    //   if (i < 10 ) {date =  '0' + i + '-' + '01-' + moment().year();
    //   } else {
    //     date =  i + '-' + '01-' + moment().year();
    //   }
    //   this.monthList.push(moment(date).format('MMM-YYYY'));
    // }
    this.currentAcademicYear();
    this.ListClassCourse();
    this.getHolidays();
  }

}
