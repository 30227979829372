import { Component, OnInit } from '@angular/core';
import { StudentService } from '../../StudentManagement/student.service';
import { ClassCourseService } from './../../mastersettings/classcourse/classcourse.service';
import { Student, StudentDetails } from '../../StudentManagement/student.model';
import { ClassCourse } from './../../mastersettings/models/classcourse';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { SettingsService } from '../../mastersettings/settings.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { ReportAttribute } from '../../studentmanagement/report.model';
import { environment } from 'src/environments/environment';
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';

@Component({
  selector: 'app-classsummmary',
  templateUrl: './classsummmary.component.html',
  styleUrls: ['./classsummmary.component.css']
})
export class ClasssummmaryComponent implements OnInit {

  admissions: Student[] = [];
  admissionsfilteredlist: Student[] = [];
  classcourses: ClassCourse[] = [];
  classcourseid = '0';
  batchid = '-1';
  batches: Batch[] = [];
  attributes: ReportAttribute[] = [];
  batcheFilteredList: Batch[] = [];
  headerList: string[] = [];
  fieldList: string[] = [];
  batchadmissions: Student[] = [];
  className = '';
  batchName = '';
  institution: any;
  currentAcademicYearId: number;
  isLoading:boolean=false;

  constructor(
    public settingService: SettingsService,
    private studentService: StudentService,
    private classcourseService: ClassCourseService,
    private batchService: BatchService
  ) { }

  currentAcademicYear() {
    this.settingService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      // this.isLoading =true;
      this.ListStudents();
      // this.isLoading =false;
      // this.search();
    });
  }

  ListStudents() {
    // this.isLoading =true;
    this.studentService.getAll().subscribe((data: Student[]) => {
      console.log(data);
      this.batchadmissions = this.admissions = data.filter((student: Student) => {
        return student.academicYearId.toString() === this.currentAcademicYearId.toString();
        });
      this.ListClassAdmissions();
    });
    // this.isLoading =!this.isLoading;
  }

  ListClassCourse() {
    this.isLoading =!this.isLoading;
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      console.log(data);
      this.classcourses = data;
      this.isLoading =!this.isLoading;
    });
  }

  ListClassAdmissions() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      console.log(data);
      this.batches = data;
      this.batches.forEach((x, i) => {
        let k = 0;
        this.batchadmissions.forEach((y, j) => {
          if (x.id.toString() === y.batchId.toString()) {
            k++;
            x.totalStudents = k.toString();
          }
        });
        if (x.totalStudents === undefined) {
            x.totalStudents = '0';
        }
      });
      this.batcheFilteredList = this.batches;
    });
  }


  generateAttributes() {
    this.attributes = [
      {name: 'S NO', fieldName: 'index' },
      {name: 'CLASS', fieldName: 'className' },
      {name: 'BATCH', fieldName: 'batchName' },
      {name: 'STUDENTS', fieldName: 'totalStudents' },
      {name: 'SEATING CAP', fieldName: 'seatingCapacity' },
      {name: 'AVAILBLE', fieldName: 'availble' },
    ];
  }

  generatePDF() {
    this.headerList =[];
    this.fieldList =[];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const widthList = [];
    for (const item of this.headerList) {
      widthList.push('16.60%');
    }

    for(var i = 0 ; i < this.batcheFilteredList.length ; i++)
    this.batcheFilteredList[i]["index"] = i+1;
    const docDefinition = {
      content: [
        {
          table: {
            body: [
              [{
                width: 100,
                height: 100,
                image: 'imageKey'
              },  {
                width: '*',
                alignment: 'center',
                stack: [
                  {
                    style: 'h1',
                    text: this.institution.institutionName
                  },
                  {
                    style: 'h2',
                    text: this.institution.address
                  },
                  {
                    style: 'h2',
                    text: 'CLASS SUMMARY'
                }
                ]
              }]
            ]
          },
          layout: {
            hLineWidth: (line) => line === 1,
            vLineWidth: () => 0,
            paddingBottom: () => 5
          },          
        },
        {
          table: {
            headerRows: 1,
            widths: widthList,
            style:'sectionHeader',
            body: [
              this.headerList,
              ...this.batcheFilteredList.map((p) => {
                const temp = [];
                for (const y of this.fieldList) {
                  if (y === 'availble') {
                    let totalFind = Number(p.seatingCapacity) - Number(p.totalStudents)
                    temp.push(totalFind)
                  } else {
                    temp.push(p[y]);
                  }
                }
                return temp;
              })
            ]
          }
        }
      ],
      images: {
        imageKey: environment.imageUrl + this.institution.logoUrl // via URL address, which can be referenced by this key
      },
      styles: {
        sectionHeader: {
          fontSize: 15,
          margin: [25, 15, 25, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 14,
          bold: true
        },
        h2: {
          margin: [0, 5, 0, 0],
          fontSize: 10,
          bold: true
        }
      }
    };
    pdfMake.createPdf(docDefinition).download('CLASS SUMMARY.pdf');
  }

  
  generateExcel(){
    this.headerList = [];
    this.fieldList = [];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('CLASS SUMMARY');

    const titleRow = worksheet.addRow(['CLASS SUMMARY']);

    const headerRow = worksheet.addRow(this.headerList);
    for (const x1 of this.batcheFilteredList) {
      const x2 = Object.keys(x1);
     
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'availble') {
          let totalFind = Number(x1.seatingCapacity) - Number(x1.totalStudents)
          temp.push(totalFind)
        } else {
          temp.push(x1[y]);
        }
      }
      worksheet.addRow(temp);
    }
    const fname = 'CLASS SUMMARY';

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });
  }


  onClassSelected(value: string) {
    this.isLoading =!this.isLoading;
    console.log(value);
    if (value === '-1') { return; }
    if (value === '0') {
       // this.admissionsfilteredlist = this.admissions; // = data;
       this.ListClassAdmissions();
    } else {
        this.batchService.getAll().subscribe((data: Batch[]) => {
          console.log(data);
          this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
          this.batches.forEach((x, i) => {
            const totalStudents = this.batchadmissions.filter((admission: Student) =>
            admission.batchId.toString() === x.id.toString()).length;
            x.totalStudents = totalStudents.toString();
          });
          this.batcheFilteredList = this.batches;
        });
        // this.admissionsfilteredlist = this.admissions.filter((admission: Student) =>
        // admission.classCourseId.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
    }
    this.isLoading =!this.isLoading;
  }

  onBatchSelected(value: number, id: number) {
    console.log(value);
    const selectedBatch = this.batcheFilteredList[id];
    this.className = selectedBatch.className;
    this.batchName = selectedBatch.batchName;
    // if (value === '-1') { return; }
    // if (value === '0') {
    //     this.admissionsfilteredlist = this.admissions; // = data;
    // } else {
    this.admissionsfilteredlist = this.admissions.filter((admission: Student) =>
    admission.batchId.toString() === value.toString());
    // }
  }

  loadClass() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      console.log(data);
      this.batches = data; // .filter((batch: Batch) => batch.classId.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      this.batches.forEach((x, i) => {
        const totalStudents = this.batchadmissions.filter((admission: Student) =>
        admission.batchId.toString() === x.id.toString()).length;
        x.totalStudents = totalStudents.toString();
      });
    });
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    console.log(keyword);
    if (keyword === '') {
      this.batcheFilteredList = this.batches;
    } else {
      this.batcheFilteredList = this.batcheFilteredList
        .filter((batch: Batch) =>
        batch.className.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase()) ||
            batch.batchName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase())
            );
    }
  }

  get totalRows(): number {
    return this.batches.length;
  }

  ngOnInit(): void {
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.ListClassCourse();
    this.generateAttributes();
    this.currentAcademicYear();
    // this.ListStudents();
    // this.ListClassAdmissions();
    // this.loadClass();
  }

}
