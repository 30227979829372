import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SuperadminComponent } from './superadmin/superadmin.component';
import { SuperAdminRoutingModule } from './superadmin-routing.module';


@NgModule({
  declarations: [DashboardComponent, SuperadminComponent],
  imports: [
    CommonModule,
    SuperAdminRoutingModule
  ]
})
export class SuperadminModule { }
