import { Injectable } from '@angular/core';
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class FunctionService {

  constructor(private datePipe: DatePipe) { }


  transFormDate(date, sequence= 'MMM dd, yyyy'){
    return this.datePipe.transform(date, sequence)
  }

  convert(str){
    var date = new Date(str),
    mnth = ("0" + (date.getMonth()+ 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
}
