import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeaveComponent } from './leave/leave.component';
import { LeavereportComponent } from './leavereport/leavereport.component';
const routes: Routes = [
  {path: 'leave/leave', component: LeaveComponent},
  {path: 'leave/leavereport', component: LeavereportComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeaveManagementRoutingModule { }
