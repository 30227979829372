import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeeManagementReportsRoutingModule } from './feemanagementreports-routing.module';
import { FeecollectionreportComponent } from './feecollectionreport/feecollectionreport.component';
import { ChequestatusComponent } from './chequestatus/chequestatus.component';
import { FeeconcessionreportComponent } from './feeconcessionreport/feeconcessionreport.component';
import { PaymentcancelledreportComponent } from './paymentcancelledreport/paymentcancelledreport.component';
import { OutstandingduereportComponent } from './outstandingduereport/outstandingduereport.component';
import { FeewaveoffreportComponent } from './feewaveoffreport/feewaveoffreport.component';
import { InstallmentsummaryComponent } from './installmentsummary/installmentsummary.component';
import { CancelledfeereceiptComponent } from './cancelledfeereceipt/cancelledfeereceipt.component';
import { ClasswisefeesummaryComponent } from './classwisefeesummary/classwisefeesummary.component';
import { ConsolidatedreportComponent } from './consolidatedreport/consolidatedreport.component';
import { DownloadfeesummaryComponent } from './downloadfeesummary/downloadfeesummary.component';
import { FeeheadwisecollectionreportComponent } from './feeheadwisecollectionreport/feeheadwisecollectionreport.component';
import { InstallmentwisesummaryComponent } from './installmentwisesummary/installmentwisesummary.component';
import { OutstandingduesummaryComponent } from './outstandingduesummary/outstandingduesummary.component';
import { StudentinstallmentsummaryComponent } from './studentinstallmentsummary/studentinstallmentsummary.component';
import { StudentpaymentreportComponent } from './studentpaymentreport/studentpaymentreport.component';
import { FeereceiptComponent } from './feereceipt/feereceipt.component';
import { FeerefundreportComponent } from './feerefundreport/feerefundreport.component';
import {AssignedspecialfeereportComponent} from './assignedspecialfeereport/assignedspecialfeereport.component';
  import { from } from 'rxjs';
import { FeesummaryheadwiseComponent } from './feesummaryheadwise/feesummaryheadwise.component';
//import { FeetranscationreportComponent } from './feetranscationreport/feetranscationreport.component';
import { FeetransactionreportComponent } from './feetransactionreport/feetransactionreport.component';
import { OutstandingprintComponent } from './outstandingprint/outstandingprint.component';
import { SharedModule } from "../../../../shared/shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelect2Module } from 'ng-select2';
import { OutstandingduereportnewComponent } from './outstandingduereportnew/outstandingduereportnew.component';

@NgModule({
    declarations: [FeecollectionreportComponent, ChequestatusComponent, FeeconcessionreportComponent, PaymentcancelledreportComponent, OutstandingduereportComponent,OutstandingduereportnewComponent, FeewaveoffreportComponent, InstallmentsummaryComponent, CancelledfeereceiptComponent, ClasswisefeesummaryComponent, ConsolidatedreportComponent, DownloadfeesummaryComponent, FeeheadwisecollectionreportComponent, InstallmentwisesummaryComponent, OutstandingduesummaryComponent, StudentinstallmentsummaryComponent, StudentpaymentreportComponent, FeereceiptComponent, FeerefundreportComponent, AssignedspecialfeereportComponent, FeesummaryheadwiseComponent, FeetransactionreportComponent, OutstandingprintComponent],

    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FeeManagementReportsRoutingModule,
        SharedModule,
        NgbModule,
        NgSelectModule,
        NgSelect2Module,
    ],
    exports:[
      SharedModule,
      NgbModule
    ]

})
export class FeeManagementReportsModule { }
