import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Service, ServiceAvailableQuantity } from './service.model';

@Injectable({
    providedIn: 'root'
  })
  export class AdddonServiceService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         enctype: 'multipart/form-data'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<Service[]> {
      return this.httpClient.get<Service[]>(environment.apiUrl + '/service/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<Service> {
      return this.httpClient.get<Service>(environment.apiUrl + '/service/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getAvailableServiceQuantity(): Observable<ServiceAvailableQuantity[]> {
      return this.httpClient.get<ServiceAvailableQuantity[]>(environment.apiUrl + '/service/GetAvailableServiceQuantityFromInstitute')
      .pipe(
        catchError(this.errorHandler)
      );
    }


    saveServiceCredit(resultList): Observable<Service> {
      return this.httpClient.post<Service>(environment.apiUrl +
        '/Service/SaveServiceQuantity/', JSON.stringify(resultList), {
          headers: new HttpHeaders({
            'Content-Type': 'application/json', responseType: 'text'
          })
      })
        .pipe(
          catchError(this.errorHandler)
        );
    }

    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }

  }
