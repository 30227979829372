import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeeManagementRoutingModule } from './feemanagement-routing.module';
import { FeeheadComponent } from './feehead/feehead.component';
import { InstallmentComponent } from './installment/installment.component';
import { GroupComponent } from './group/group.component';
import { FeeconfigurationComponent } from './feeconfiguration/feeconfiguration.component';
import { FeesettingComponent } from './feesetting/feesetting.component';
import { AssignintallmenttogroupComponent } from './assignintallmenttogroup/assignintallmenttogroup.component';
import { AssignspecialfeeComponent } from './assignspecialfee/assignspecialfee.component';
import { ConcessiontemplateComponent } from './concessiontemplate/concessiontemplate.component';
import { FeecollectionoldComponent } from './feecollectionold/feecollectionold.component';
import { FeecollectionComponent } from './feecollection/feecollection.component';
import { FeerefundComponent } from './feerefund/feerefund.component';
import { FeewaveoffComponent } from './feewaveoff/feewaveoff.component';
import { ManagechequeComponent } from './managecheque/managecheque.component';
import { PdcentryComponent } from './pdcentry/pdcentry.component';
import { SetstudentconcessionComponent } from './setstudentconcession/setstudentconcession.component';
import { FeeManagementReportsModule } from './feemanagementreports/feemanagementreports.module';
import { FeecollecctionprintComponent } from './feecollecctionprint/feecollecctionprint.component';
import { PaymentgatwayComponent } from './paymentgatway/paymentgatway.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeeGroupComponent } from './fee-group/fee-group.component';




@NgModule({
  declarations: [FeeheadComponent, InstallmentComponent, GroupComponent, FeeconfigurationComponent,
     FeesettingComponent, AssignintallmenttogroupComponent, AssignspecialfeeComponent, ConcessiontemplateComponent,
      FeecollectionComponent,FeecollectionoldComponent, FeerefundComponent, FeewaveoffComponent, ManagechequeComponent, PdcentryComponent,
       SetstudentconcessionComponent,
       FeecollecctionprintComponent,
       PaymentgatwayComponent,
       FeeGroupComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FeeManagementRoutingModule,
    FeeManagementReportsModule,
    SharedModule,
    NgbModule

  ],
  exports:[
    SharedModule,
    NgbModule
  ]
})
export class FeemanagementModule { }
