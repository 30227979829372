import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BiomatricSettingDomain } from '../biomatric-setting-domain';
import { BiomatricSettingService } from '../biomatric-setting.service';
declare var $: any;
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-biomatric-setting-list',
  templateUrl: './biomatric-setting-list.component.html',
  styleUrls: ['./biomatric-setting-list.component.css']
})
export class BiomatricSettingListComponent extends BiomatricSettingDomain implements OnInit, OnDestroy {

  selectedRecordId: number;
  constructor(private biomatricSettingService: BiomatricSettingService,
    private toastr: ToastrService,
  ) {
    super();
  }


  ngOnInit(): void {
    this.getBiomatricSettingList();
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  getBiomatricSettingList() {
    this.subscription.push(this.biomatricSettingService.getAll().subscribe(response => {
      this.biomatricSettingList = response;
      this.showComponent = true;
      this.isEdit = false;
      this.isAdd = false;
    }))
  }

  delete(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.subscription.push(this.biomatricSettingService.delete(id).subscribe(response => {
          this.toastr.success('Records has been deleted sucessfully', 'SUCCESS!', {
            timeOut: 3000
          });
          this.getBiomatricSettingList();
        }))
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }

  edit(id: number) {
    this.isEdit = true;
    this.selectedRecordId = id;
    setTimeout(() => {
      $('#editBiometricDetails').modal('show');
    });
  }

  checkPasswordVerified() {
    if (this.isDisabled) {
      this.toastr.warning('Please verify the password first', 'SUCCESS!', {
        timeOut: 3000
      });
    }
  }

}
