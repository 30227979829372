import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Institution } from '../admin/institution.model';
import { AdminService } from '../admin/admin.service';
import { AnalyticService } from '../../shared/analytic.service';
import { StudentService } from '../academic/studentmanagement/student.service';
import { Student } from '../academic/studentmanagement/student.model';
import { InstitutionGuard } from 'src/app/authentication/guards/institution.guard';
import { environment } from 'src/environments/environment';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexMarkers,
  ApexXAxis,
  ApexPlotOptions,
  ApexLegend,
  ApexGrid,
  ApexDataLabels,
  NgApexchartsModule,
  ApexResponsive,
  ApexStroke
} from "ng-apexcharts";
//import { typeWithParameters } from '@angular/compiler/src/render3/util';

import { AcademicSummary, AttendanceSummary, PendingToDoSummary, SessionWiseStudentReport, SessionWiseStudentTotalsReport, DashboardClassWiseStudentsSummary, DashboardClassWiseStudentsTotalSummary, DashboardDuesPaidDto, DepartmentWiseEmployeeCountDto, DepartmentWiseSalaryDto, EmployeeJoiningAndExitSummaryReport, SessionWiseSalary, GetEmployeeLeaveAttendanceReport } from '../dashboard/attendanceSummary.model';
import { Subscription } from 'rxjs';
import { SettingsService } from '../academic/mastersettings/settings.service';
import { Academicyear } from '../academic/mastersettings/models/academicyear';
import { SharedService } from '../shared.service';
import { ERPSettingService } from '../erpsettings/erpsettings.service';
import { ERPDashboardSetting } from '../erpsettings/erpsettings.model';
import { EmployeeadmissionService } from '../OperationActivity/hrmspayroll/hrms/employeeadmission.service';
import { DashboardService } from '../dashboard/dashboard.service';


export interface EmpChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  colors: string[];
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  grid: ApexGrid;
}

export interface EmpTypeChartOptions {
  series: any[];
  chart: ApexChart;
  labels: string[];
  responsive: ApexResponsive[];
}

export interface JobTypeChartOptions {
  series: any[];
  chart: ApexChart;
  labels: string[];
  responsive: ApexResponsive[];
}

export interface SalaryChartOptions  {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: any;
  stroke: any; // ApexStroke;
  plotOptions: ApexPlotOptions;
  colors: string[];
  tooltip: ApexTooltip;
};

export interface EmpJoiningExitChartOptions  {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: any;
  stroke: any; // ApexStroke;
};

export interface SessionWiseSalaryChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: any;//ApexYAxis;
  stroke: ApexStroke;
  fill: ApexFill;
};

export interface LeaveAndAttendanceChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: any;//ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  fill: ApexFill;
  tooltip: ApexTooltip;
};

export interface EmpHiringChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  plotOptions: any;
  stroke: any; // ApexStroke;
  title: any;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
}

@Component({
  selector: 'app-hrmsdashboard',
  templateUrl: './hrmsdashboard.component.html',
  styleUrls: ['./hrmsdashboard.component.css']
})

export class HrmsdashboardComponent implements OnInit {
  @ViewChild("empchart") empchart: ChartComponent;
  @ViewChild("emptypechart") emptypechart: ChartComponent;
  @ViewChild("jobtypechart") jobtypechart: ChartComponent;
  @ViewChild("salarychart") salarychart: ChartComponent;
  @ViewChild("empjoiningexitchart") empjoiningexitchart: ChartComponent;
  @ViewChild("sessionwisesalarychart") sessionwisesalarychart: ChartComponent;
  @ViewChild("leaveandattendancechart") leaveandattendancechart: ChartComponent;
  @ViewChild("emphiringchart") emphiringchart: ChartComponent;

  public empChartOptions: Partial<EmpChartOptions>;
  public empTypeChartOptions: Partial<EmpTypeChartOptions>;
  public jobTypeChartOptions: Partial<JobTypeChartOptions>;
  public salaryChartOptions: Partial<SalaryChartOptions>;
  public empJoiningexitChartOptions: Partial<EmpJoiningExitChartOptions>;
  public sessionWiseSalaryChartOptions: Partial<SessionWiseSalaryChartOptions>;
  public leaveAndAttendanceChartOptions: Partial<LeaveAndAttendanceChartOptions>;
  public empHiringChartOptions: Partial<EmpHiringChartOptions>;

  sessionWiseStudentReport: SessionWiseStudentReport[] = [];
  sessionWiseStudentTotalsReport: SessionWiseStudentTotalsReport;
  dashboardClassWiseStudentsSummary: DashboardClassWiseStudentsSummary[] = [];
  departmentWiseEmployeesCount: DepartmentWiseEmployeeCountDto;
  dashboardClassWiseStudentsTotalSummary: DashboardClassWiseStudentsTotalSummary;
  employeeJoiningExit: EmployeeJoiningAndExitSummaryReport;
  departmentWiseSalary: DepartmentWiseSalaryDto;
  employeeAttendanceLeaveReport: GetEmployeeLeaveAttendanceReport;

  dashboardDuesPaid: DashboardDuesPaidDto;
  @ViewChild("currentAcademicYearDropDown")
  currentAcademicYearSelect: ElementRef;
  @Input() currentAcademicYearId: number;
  academicYears: Academicyear[];
  paidAmount: number = 0;
  totalDueAmount: number = 0;
  selectedCurrentYear: number;
  selectedYearForSalaryChart: number;
  selectedYearForEmpChart: number;
  selectedYearForExitChart: number;
  graphlist: any[] = [];
  isLoading: boolean = false;
  isToDoTaskLoading: boolean = false;
  constructor(
    private elementRef: ElementRef,
    private sharedService: SharedService,
    private dashboardService: DashboardService,
    private erpSettingService: ERPSettingService,
    private empAdmissionService: EmployeeadmissionService
  ) {
    this.loadEmpChartData();
    this.loadEmpTypeChartData();
    this.loadJobTypeChartData();
    this.loadSalaryChartData();
    this.loadEmpJoiningExitChartData();
    this.loadSessionWiseSalaryChartData();
    this.loadleaveAndAttendanceChartData();
    this.loadEmpHiringChartData();
  }

  loadEmpChartData() {
    //DEPT WISE EMPLOYEE
    var colors = [
      "#008FFB",
      "#00E396",
      "#FEB019",
      "#FF4560",
      "#775DD0",
      "#546E7A",
      "#26a69a",
      "#D10CE8",
    ];

    this.empChartOptions = {
      series: [
        {
          data: [50, 22, 10, 88, 50, 25, 97, 78],
        },
      ],
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: "60%",
          distributed: true,
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [
          ["Front Office"],
          ["Teaching"],
          ["Mathemetics"],
          "Accountant",
          "Librarian",
          "Marketing",
          "Admin",
          "HR",
        ],
        labels: {
          style: {
            colors: colors,
            fontSize: "12px",
          },
        },
      },
    };
  }

  loadEmpTypeChartData() {
    // EMPLOYE TYPE WISE ANALYSIS
    this.empTypeChartOptions = {
      series: [],
      chart: {
        width: 400,
        type: "donut",
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
  }

  loadJobTypeChartData() {
    //Job Type Analyis
    this.jobTypeChartOptions = {
      series: [],
      chart: {
        width: 380,
        type: "pie",
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
  }

  loadSalaryChartData() {
    //DEPARTMENT WISE SALARY

    this.salaryChartOptions = {
      series: [
        {
          data: [12, 44, 33, 56, 34],
        },
      ],
      chart: {
        type: "bar",
        height: 400,
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: true,
          borderRadius: 3,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: [
        "#33b2df",
        "#546E7A",
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7",
      ],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#fff"],
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    };
  }

  loadEmpJoiningExitChartData() {
    //Employee Joining and Exit Analysis

    this.empJoiningexitChartOptions = {
      series: [
        {
          name: "Joining",
          type: "column",
          data: [],
        },
        {
          name: "Exit",
          type: "line",
          data: [],
        },
      ],
      chart: {
        height: 350,
        type: "line",
        //borderRadius: 3,
      },
      stroke: {
        width: [0, 4],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: [
        {
          title: {
            text: "Joining",
          },
        },
        {
          opposite: true,
          title: {
            text: "Exit",
          },
        },
      ],
    };
  }

  loadSessionWiseSalaryChartData() {
    this.sessionWiseSalaryChartOptions = {
      series: [{
          name: 'Total Salary Paid ₹ ',
          data: [{
              x: '2011-2012',
              y: 1292
          }, {
              x: '2012-2013',
              y: 4432
          }, {
              x: '2013-2014',
              y: 5423
          }, {
              x: '2014-2015',
              y: 6653
          }, {
              x: '2015-2016',
              y: 8133,
              fillColor: '#EB8C87',
              strokeColor: '#C23829'
          }, {
              x: '2016-2017',
              y: 7132
          }, {
              x: '2017-2018',
              y: 7332
          }, {
              x: '2018-2019',
              y: 7553
          }]
      }],
      chart: {
          height: 350,
          type: 'bar',
      },
      plotOptions: {
          bar: {
              horizontal: false,
              columnWidth: '60%',
              borderRadius: 4,
          },
      },
      stroke: {
          width: 0,
      },
      dataLabels: {
          enabled: false
      },
      yaxis: {
          labels: {
              formatter: function (val) {
                  return val / 1000
              }
          }
      },
      fill: {
          opacity: 1,
      },
  };
  }

  loadleaveAndAttendanceChartData() {
    //Employee Leave and Attendance Analysis
    this.leaveAndAttendanceChartOptions = {
      series: [
        {
          name: "Present",
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 87, 68, 97],
        },
        {
          name: "Work Days",
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 81, 68, 17],
        },
        {
          name: "Absent",
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 81, 68, 17],
        },
        {
          name: "Leave",
          data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 87, 55, 91],
        },
      ],
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          endingShape: "rounded",
          borderRadius: 2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        title: {
          text: "Days",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " Percent";
          },
        },
      },
    };
  }

  loadEmpHiringChartData() {
    //Session wise Employee Hiring analysis
    this.empHiringChartOptions = {
      series: [
        {
          name: "Total Applications",
          data: [550, 505, 401, 357, 282, 543, 421],
        },
        {
          name: "Interview Taken",
          data: [530, 500, 400, 300, 200, 402, 410],
        },
        {
          name: "Selected Candidate",
          data: [102, 127, 151, 79, 105, 101, 200],
        },
        {
          name: "Rejected Candidate",
          data: [80, 70, 50, 85, 68, 95, 74],
        },
        {
          name: "Joining",
          data: [75, 102, 109, 302, 205, 204, 100],
        },
      ],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 3,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        text: "",
      },
      xaxis: {
        categories: [2008, 2009, 2010, 2011, 2012, 2013, 2014],
        labels: {
          formatter: function (val) {
            return val + "K";
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "K";
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    };
  }

  ngOnInit(): void {
    this.isLoading = true;

    setTimeout(() => {
      //this.setYearDropDown(this.sharedService.academicYearId); //set for default year selected
      this.setChartAllDepartmentWiseEmployee();
      this.setChartDepartmentWiseSalary();
      this.setChartemployeeJoiningExit();   
      this.setChartSessionWiseSalaryReport();
      this.setChartEmployeeLeaveAttendanceReport();
      this.isLoading = false;
    }, 100);
  }

  // setYearDropDown(yearId: any) {
  //   this.selectedCurrentYear = yearId;
  //   this.selectedYearForSalaryChart = yearId;
  //   this.selectedYearForEmpChart = yearId;
  //   this.selectedYearForExitChart = yearId;
  // }

  getAllDepartmentWiseEmployee(academicYearId: number) {
    this.empAdmissionService
      .GetAllDepartmentWiseEmployee(academicYearId)
      .subscribe((data: DepartmentWiseEmployeeCountDto) => {
        this.departmentWiseEmployeesCount = data;
        console.log("getAllDepartmentWiseEmployee:", data);

        //dep wise chart
        let totalEmployees = [];
        let classifications = [];
        for (let d of data.departmentEmployeeClassifications) {
          totalEmployees.push(d.subTotal);
          classifications.push(d.departmentName);
        }
        this.empchart?.updateOptions({
          xaxis: {
            categories: [...classifications],
          },
        });
        this.empchart?.updateSeries([
          {
            data: [...totalEmployees],
          },
        ]);

        //employee type wise chart
        let empType = [];
        let empTypeCount = [];
        for (let d of data.employeeTypeSummaries) {
          empType.push(d.employeeType);
          empTypeCount.push(d.count);
        }
        this.emptypechart?.updateOptions({
          series: empTypeCount,
          labels: empType,
        });

        //job type wise chart
        let jobType = [];
        let jobTypeCount = [];
        for (let d of data.jobTypeSummaries) {
          jobType.push(d.jobTypeName);
          jobTypeCount.push(d.count);
        }

        this.jobtypechart?.updateOptions({
          series: jobTypeCount,
          labels: jobType,
        });
      });
  }

  getDepartmentWiseSalary(yearId: any) {
    this.dashboardService
      .departmentWiseSalary(yearId)
      .subscribe((data: DepartmentWiseSalaryDto) => {
        this.departmentWiseSalary = data;
        console.log("getDepartmentWiseSalary:", data);

        //dep wise salary chart
        let departmentNames = [];
        let totalSalaries = [];
        for (let d of data.departmentSalaries) {
          departmentNames.push(d.departmentName);
          totalSalaries.push(d.totalSalary);
        }
        this.salarychart?.updateOptions({
          xaxis: {
            categories: [...departmentNames],
          },
        });
        this.salarychart?.updateSeries([
          {
            data: [...totalSalaries],
          },
        ]);
      });
  }

  getemployeeJoiningExit(yearId: any) {
    this.dashboardService
      .employeeJoiningExit(yearId)
      .subscribe((data: EmployeeJoiningAndExitSummaryReport) => {
        this.employeeJoiningExit = data;
        console.log("employeeJoiningExit:", data);

        let totalExits = [];
        let totalJoinings = [];
        let months = [];
        for (let d of data.employeeJoiningsAndExits) {
          totalExits.push(d.totalExits);
          totalJoinings.push(d.totalJoinings);
          months.push(d.month);
        }
        this.empjoiningexitchart?.updateOptions({
          xaxis: {
            categories: [...months],
          },
        });

        this.empjoiningexitchart?.updateSeries([
          {
            name: "Joining",
            type: "column",
            data: [...totalJoinings],
          },
          {
            name: "Exit",
            type: "line",
            data: [...totalExits],
          },
        ]);
      });
  }

  getSessionWiseSalaryReport() {
    this.dashboardService.getSessionWiseSalaryReport().subscribe((resData: SessionWiseSalary[]) => {

        var data = resData.map(d => {
          return { x: d.session, y: d.totalSalaryPaid };
        });

        this.sessionwisesalarychart?.updateSeries([
          {
            name: 'Total Salary Paid ₹ ',
            data: [...data],
          },
        ]);

      });
      
  }

getEmployeeLeaveAttendanceReport(academicYearId: number){
  this.dashboardService.getEmployeeLeaveAttendanceReport(academicYearId).subscribe((data: GetEmployeeLeaveAttendanceReport) => {
    this.employeeAttendanceLeaveReport = data;

    let months = [];
    let days = [];
    let presents = [];
    let absents = [];
    let leavesData = [];
    for (let d of data.employeeAttendanceSummaryReports) {
      months.push(d.monthName);
      days.push(d.workingDays);
      presents.push(d.presentCount);
      absents.push(d.absentCount);
      leavesData.push(d.leaves);
    }

    this.leaveandattendancechart?.updateOptions({
        xaxis: {
          categories: [...months],
        },
      });

      this.leaveandattendancechart?.updateSeries([
        {
          name: "Present",
          data: [...presents],
        },
        {
          name: "Work Days",
          data: [...days],
        },
        {
          name: "Absent",
          data: [...absents],
        },
        {
          name: "Leave",
          data: [...leavesData],
        },
      ]);
  });
}

  ngAfterViewInit(): void {
    // let s = document.createElement('script');
    // s.type = 'text/javascript';
    // s.src = '../assets/apexcharts/apexcharts.min.js';
    // this.elementRef.nativeElement.appendChild(s);
    // s = document.createElement('script');
    // s.type = 'text/javascript';
    // s.src = '../assets/js/pages/apexcharts.init.js';
    // this.elementRef.nativeElement.appendChild(s);
    // //this.currentAcademicYear();
    //this.currentAcademicYearSelect.nativeElement.value=this.sharedService.academicYearId;
    //this.getPendingToDoSummarySummary();
  }

  setChartAllDepartmentWiseEmployee() {

       var data = this.sharedService.departmentWiseEmployeesCount;
       this.departmentWiseEmployeesCount = data;
        //dep wise chart
        let totalEmployees = [];
        let classifications = [];
        for (let d of data.departmentEmployeeClassifications) {
          totalEmployees.push(d.subTotal);
          classifications.push(d.departmentName);
        }
        this.empchart?.updateOptions({
          xaxis: {
            categories: [...classifications],
          },
        });
        this.empchart?.updateSeries([
          {
            data: [...totalEmployees],
          },
        ]);

        //employee type wise chart
        let empType = [];
        let empTypeCount = [];
        for (let d of data.employeeTypeSummaries) {
          empType.push(d.employeeType);
          empTypeCount.push(d.count);
        }
        this.emptypechart?.updateOptions({
          series: empTypeCount,
          labels: empType,
        });

        //job type wise chart
        let jobType = [];
        let jobTypeCount = [];
        for (let d of data.jobTypeSummaries) {
          jobType.push(d.jobTypeName);
          jobTypeCount.push(d.count);
        }

        this.jobtypechart?.updateOptions({
          series: jobTypeCount,
          labels: jobType,
        });

  }

  setChartDepartmentWiseSalary() {
    
    var data = this.sharedService.departmentWiseSalary;
    this.departmentWiseSalary = data;
        //dep wise salary chart
        let departmentNames = [];
        let totalSalaries = [];
        for (let d of data.departmentSalaries) {
          departmentNames.push(d.departmentName);
          totalSalaries.push(d.totalSalary);
        }
        this.salarychart?.updateOptions({
          xaxis: {
            categories: [...departmentNames],
          },
        });
        this.salarychart?.updateSeries([
          {
            data: [...totalSalaries],
          },
        ]);

  }

  setChartemployeeJoiningExit() {
        var data = this.sharedService.employeeJoiningExit;
        this.employeeJoiningExit = data;

        let totalExits = [];
        let totalJoinings = [];
        let months = [];
        for (let d of data.employeeJoiningsAndExits) {
          totalExits.push(d.totalExits);
          totalJoinings.push(d.totalJoinings);
          months.push(d.month);
        }
        this.empjoiningexitchart?.updateOptions({
          xaxis: {
            categories: [...months],
          },
        });

        this.empjoiningexitchart?.updateSeries([
          {
            name: "Joining",
            type: "column",
            data: [...totalJoinings],
          },
          {
            name: "Exit",
            type: "line",
            data: [...totalExits],
          },
        ]);

  }

  
  setChartEmployeeLeaveAttendanceReport() {
    var data = this.sharedService.employeeAttendanceLeaveReport;
       this.employeeAttendanceLeaveReport = data;
      //  let months = data.employeeAttendanceSummaryReports.map(d => {
      //   d.monthName
      // });
      // //var series = data.employeeAttendanceSummaryReports.map
      // this.empchart?.updateOptions({
      //   xaxis: {
      //     categories: [...months],
      //   },
      // });

      let months = [];
      let days = [];
      let presents = [];
      let absents = [];
      let leavesData = [];
      for (let d of data.employeeAttendanceSummaryReports) {
        months.push(d.monthName);
        days.push(d.workingDays);
        presents.push(d.presentCount);
        absents.push(d.absentCount);
        leavesData.push(d.leaves);
      }

      this.leaveandattendancechart?.updateOptions({
          xaxis: {
            categories: [...months],
          },
        });

        this.leaveandattendancechart?.updateSeries([
          {
            name: "Present",
            data: [...presents],
          },
          {
            name: "Work Days",
            data: [...days],
          },
          {
            name: "Absent",
            data: [...absents],
          },
          {
            name: "Leave",
            data: [...leavesData],
          },
        ]);

  }

 setChartSessionWiseSalaryReport() {
       var resData = this.sharedService.sessionWiseSalary;
       
       var data = resData.map(d => {
          return { x: d.session, y: d.totalSalaryPaid };
        });

        this.sessionwisesalarychart?.updateSeries([
          {
            name: 'Total Salary Paid ₹ ',
            data: [...data],
          },
        ]);

      
  }
}
