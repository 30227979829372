import { AfterViewInit, Attribute, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';

import { Academicyear } from '../../../academic/mastersettings/models/academicyear';
import { ClassCourse } from '../../../academic/mastersettings/models/classcourse';
import { Batch } from '../../../academic/mastersettings/models/batch';
import { Category } from '../../../academic/mastersettings/models/category';
import { ReportAttribute } from './studentreport';

import { BatchService } from '../../../academic/mastersettings/batch/batch.service';
import { ClassCourseService } from '../../../academic/mastersettings/classcourse/classcourse.service';
import { SettingsService } from '../../../academic/mastersettings/settings.service';
import { Student, StudentSibling, StudentDocument } from '../../../academic/studentmanagement/student.model';
import { StudentService } from '../../../academic/studentmanagement/student.service';
import { CategoryService } from '../../../academic/mastersettings/category/category.service';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable.js';
import { House } from 'src/app/admin/academic/studentmanagement/house.mode';

@Component({
  selector: 'app-studentreport',
  templateUrl: './studentreport.component.html',
  styleUrls: ['./studentreport.component.css']
})
export class StudentreportComponent implements OnInit, AfterViewInit {

  academicYears: Academicyear[] = [];
  classcourses: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  sortedClassAdmissions: Student[] = [];
  sortedAdmissions: Student[] = [];
  categories: Category[] = [];
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  selectedAttributes: ReportAttribute[] = [];
  academicYearId = '0';
  studentStatusType = 'All';
  studentType = 'All';
  studentAdmissionType = 'All';
  category = 'All';
  gender = '0';
  house = 'All';
  minAge = '0';
  maxAge = '0';
  institution: any;
  seqno = 1;

  selectedBatched: Batch[] = [];
  housesList: House[] = [];
  constructor(
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public academicyearService: SettingsService,
    public categoryService: CategoryService,
    public batchService: BatchService,
    private toastr: ToastrService
  ) { }

  counter(i: number) {
    return new Array(i);
  }

  generateAttributes() {
    this.attributes = [
    {name: 'Academic Year', isChecked: false, fieldName: 'academicYearName' },
    {name: 'Student Name', isChecked: false, fieldName: 'studentName' },
    {name: 'Class', isChecked: false, fieldName: 'className' },
    {name: 'Batch', isChecked: false, fieldName: 'batchName' },
    {name: 'Roll No', isChecked: false, fieldName: 'rollNo' },
    {name: 'Admission No', isChecked: false, fieldName: 'admissionNo' },
    {name: 'Admission Date', isChecked: false, fieldName: 'admissionDate' },
    {name: 'Admission Type', isChecked: false, fieldName: 'studentAdmissionTypeName' },
    {name: 'Stream', isChecked: false, fieldName: 'streamName' },
    {name: 'Date Of Birth', isChecked: false, fieldName: 'dob' },
    {name: 'Age', isChecked: false, fieldName: 'age' },
    {name: 'Gender', isChecked: false, fieldName: 'genderName' },
    {name: 'Status', isChecked: false, fieldName: 'status' },
    //to do {name: 'Day Scholar/Boarding', isChecked: false, fieldName: 'dayScholarBoarding' },
    {name: 'Father Name', isChecked: false, fieldName: 'fatherName' },
    {name: 'Mother Name', isChecked: false, fieldName: 'motherFullName' },
    {name: 'Student Type', isChecked: false, fieldName: 'studentType' },
    {name: 'Religion', isChecked: false, fieldName: 'religionName' },
    {name: 'Category', isChecked: false, fieldName: 'categoryName' },
    {name: 'Caste', isChecked: false, fieldName: 'caste' },
    {name: 'Blood Group', isChecked: false, fieldName: 'bloodGroup' },
    {name: 'Aadhar No', isChecked: false, fieldName: 'adharNumber' },
    {name: 'Contact No', isChecked: false, fieldName: 'localContactNo' },
    {name: 'WhatsApp No', isChecked: false, fieldName: 'whatsAppNo' },
    {name: 'Email', isChecked: false, fieldName: 'emailId' },
    {name: 'House', isChecked: false, fieldName: 'houseId' },
    {name: 'Account No', isChecked: false, fieldName: 'accountNo' },
    {name: 'Account Holder Name', isChecked: false, fieldName: 'accountHolder' },
    {name: 'IFSC Code', isChecked: false, fieldName: 'ifscCode' },
    //to do {name: 'Bank Name', isChecked: false, fieldName: 'Bank Name' },
    //{name: 'Father Contact No', isChecked: false, fieldName: 'fatherMobileNo' },
    //{name: 'Father Contact No', isChecked: false, fieldName: 'motherMobileNo' },
    ];
  }

  selecetAllAttributes(evnt: any) {
    
    if (evnt.target.checked) {
      this.attributes.forEach(x => {
        x.isChecked = true;
        this.selectedAttributes.push(x);
        this.headerList.push(x.name);
        this.fieldList.push(x.fieldName);
      });
    } else {
      this.attributes.forEach(x => {
        x.isChecked = false;
      });
      this.selectedAttributes = [];
      this.headerList = [];
      this.fieldList = [];
    }

  }

  ListHouse() {
    this.studentService.getHouse().subscribe((data: House[]) => {      
      this.housesList = data;
    });
  }
  
  isAllAttributeCheckBoxChecked() {
    return this.attributes.every(p => p.isChecked);
  }

  resetAllCheckedAttributes() {
    this.attributes.forEach(x => x.isChecked = false);
    this.selectedAttributes = [];
    this.headerList = [];
    this.fieldList = [];
  }

  onCheckAttribute(evnt: any, name: any, aName: any) {
    
    if (evnt.target.checked) {
      this.selectedAttributes.push(this.attributes[name]);
      this.headerList.push(this.attributes[name].name);
      this.fieldList.push(this.attributes[name].fieldName);
    } else {
      let selectedIndex: number;
      this.selectedAttributes.forEach((x, i) => {
        if (x.name === aName) {
          selectedIndex = i;
        }
      });
      this.selectedAttributes.splice(selectedIndex, 1);
      this.headerList.splice(selectedIndex, 1);
      this.fieldList.splice(selectedIndex, 1);
    }
  }

  ListAcademicYear() {
    this.academicyearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  ListCategories() {
    this.categoryService.getAll().subscribe((data: Category[]) => {
      this.categories = data;
    });
  }

  getAllBatchDetails() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data;
    });
  }
  // get f() { return this.admissionProcedureForm.controls; }
  selecetAllBatch(evnt: any) {
    if (evnt.target.checked) {
      // this.studentfilteredlist = this.students;
      // this.filteredlist = this.studentfilteredlist;
      this.batches.forEach(x => x.checked = true);
    } else {
      this.batches.forEach(x => x.checked = false);
    }
    const batchIds: any[] = [];
    this.batches.forEach(x => {
      if (x.checked) {
        batchIds.push(x.id);
      }
    });
    this.sortedClassAdmissions = this.admissions.filter((admission: Student) => {
      return batchIds.map(y => y).includes(admission.batchId);
    });
  }

  isAllCheckBoxChecked() {
    return this.batches.every(p => p.checked);
  }
  onCheckBatch(evnt: any, batchId: any, classId: any) {
    //if (evnt.target.checked) { // on checked
      const classIds: any[] = [];
      const batchIds: any[] = [];
      this.batches.forEach(x => {
        if (x.checked) {
          classIds.push(x.classId);
          batchIds.push(x.id);
        }
      });
      ;
     
      // this.filterStudentList(batchId);
      this.sortedClassAdmissions = this.admissions.filter((admission: Student) => {
        return classIds.map(y => y).includes(admission.classCourseId)
        && batchIds.map(y => y).includes(admission.batchId);
      });
    // } else {// on unchecked
    //   // this.removeFromfilteredStudentList(batchId);

    // }
  }

  onClassSelected(value: string) {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
    });
  }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.academicYearId = data.id.toString();
    });
  }

  ListStudents() {
    this.studentService.getAllStudentsReports().subscribe((data: Student[]) => {
      debugger;
      this.sortedAdmissions = this.admissions = data;
    });
  }

  resetAll() {
    this.batches.forEach(x => x.checked = false);
    this.attributes.forEach(x => x.isChecked = false);
    this.sortedClassAdmissions = [];
    this.selectedAttributes = [];
    this.headerList = [];
    this.fieldList = [];
    this.studentStatusType = 'All';
    this.category = 'All';
    this.gender = '0';
    this.house = 'All';
    this.minAge = '0';
    this.maxAge = '0';
  }
  getAge(year_born) 
  {
      var today = new Date();
      var birthDate = new Date(year_born);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
      {
          age--;
      }
      return age;
  }

  sortAdmission() {
    const academicYearId = this.academicYearId;
    // All
    if (this.studentStatusType === 'All' && this.category === 'All' && this.gender === '0'
     && this.house === 'All' && this.minAge === '0' && this.maxAge === '0') {
        this.sortedAdmissions = this.sortedClassAdmissions;
    } else if (this.studentStatusType !== 'All' && this.category === 'All' && this.gender === '0'
    && this.house === 'All' && this.minAge === '0' && this.maxAge === '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {         
          return this.studentStatusType.toString() === (Number(e.status)).toString();
        });
    } else if (this.studentStatusType !== 'All' && this.category !== 'All' && this.gender === '0'
    && this.house === 'All' && this.minAge === '0' && this.maxAge === '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          return this.studentStatusType.toString() === (Number(e.status)).toString() 
          && this.category === e.categoryId.toString(); 
        });
    } else if (this.studentStatusType === 'All' && this.category !== 'All' && this.gender === '0'
    && this.house === 'All' && this.minAge === '0' && this.maxAge === '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          return this.category === e.categoryId.toString(); 
        });
    } else if (this.studentStatusType !== 'All' && this.category !== 'All' && this.gender !== '0'
    && this.house === 'All' && this.minAge === '0' && this.maxAge === '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          return this.studentStatusType.toString() === (Number(e.status)).toString() 
          && this.category === e.categoryId.toString()
          && this.gender.toString() === e.gender.toString();
        });
    }  else if (this.studentStatusType !== 'All' && this.category !== 'All' && this.gender !== '0'
    && this.house === 'All' && this.minAge === '0' && this.maxAge !== '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
         // dob: "2018-09-11T00:00:00"
         // let dob = e
         let year_born: any;
         if(e.dob != null || e.dob != undefined)
          year_born = e.dob.split('T')[0];
         let calculatedAge = this.getAge(year_born);
          return this.studentStatusType.toString() === (Number(e.status)).toString()
          && this.category === e.categoryId.toString()
          && this.gender.toString() === e.gender.toString()
          && Number(this.maxAge) > Number(calculatedAge);
        });
    }  else if (this.studentStatusType !== 'All' && this.category !== 'All' && this.gender !== '0'
    && this.house === 'All' && this.minAge !== '0' && this.maxAge === '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          let year_born: any;
          if(e.dob != null || e.dob != undefined)
           year_born = e.dob.split('T')[0];
          let calculatedAge = this.getAge(year_born);
          return this.studentStatusType.toString() === (Number(e.status)).toString()
          && this.category === e.categoryId.toString()
          && this.gender.toString() === e.gender.toString()
          && Number(this.minAge) > Number(calculatedAge);
        });
    }  else if (this.studentStatusType === 'All' && this.category === 'All' && this.gender === '0'
    && this.house === 'All' && this.minAge !== '0' && this.maxAge === '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          let year_born: any;
          if(e.dob != null || e.dob != undefined)
           year_born = e.dob.split('T')[0];
          let calculatedAge = this.getAge(year_born);
          return Number(this.minAge) > Number(calculatedAge);
        });
    } else if (this.studentStatusType === 'All' && this.category === 'All' && this.gender === '0'
    && this.house === 'All' && this.minAge == '0' && this.maxAge !== '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          let year_born: any;
         if(e.dob != null || e.dob != undefined)
          year_born = e.dob.split('T')[0];
         let calculatedAge = this.getAge(year_born);
          return Number(this.maxAge) > Number(calculatedAge);
        });
    } else if (this.studentStatusType === 'All' && this.category === 'All' && this.gender === '0'
    && this.house === 'All' && this.minAge !== '0' && this.maxAge !== '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          let year_born: any;
         if(e.dob != null || e.dob != undefined)
          year_born = e.dob.split('T')[0];
         let calculatedAge = this.getAge(year_born);
          return  Number(this.minAge) < Number(calculatedAge) 
          && Number(this.maxAge) > Number(calculatedAge);
        });
    } else if (this.studentStatusType !== 'All' && this.category !== 'All' && this.gender !== '0'
    && this.house === 'All' && this.minAge !== '0' && this.maxAge !== '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          let year_born: any;
         if(e.dob != null || e.dob != undefined)
          year_born = e.dob.split('T')[0];
         let calculatedAge = this.getAge(year_born);
          return this.studentStatusType.toString() === (Number(e.status)).toString()
          && this.category === e.categoryId.toString()
          && this.gender.toString() === e.gender.toString()
          && Number(this.minAge) < Number(calculatedAge) 
          && Number(this.maxAge) > Number(calculatedAge);
        });
    } else if (this.studentStatusType === 'All' && this.category === 'All' && this.gender !== '0'
    && this.house === 'All' && this.minAge !== '0' && this.maxAge !== '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          let year_born: any;
         if(e.dob != null || e.dob != undefined)
          year_born = e.dob.split('T')[0];
         let calculatedAge = this.getAge(year_born);
          return  this.gender.toString() === e.gender.toString()
          && Number(calculatedAge) > Number(this.minAge)
          && Number(calculatedAge) < Number(this.maxAge);
        });
    }
    else if (this.studentStatusType !== 'All' && this.category == 'All' && this.gender !== '0'
    && this.house === 'All' && this.minAge === '0' && this.maxAge === '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          return this.studentStatusType.toString() === (Number(e.status)).toString()     
          && this.gender.toString() == e.gender.toString();
        });
    }
    else if (this.studentStatusType === 'All' && this.category == 'All' && this.gender !== '0'
    && this.house === 'All' && this.minAge === '0' && this.maxAge === '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          return this.gender.toString() == e.gender.toString();
        });
    }
    //House
    else if (this.studentStatusType === 'All' && this.category == 'All' && this.gender === '0'
    && this.house !== 'All' && this.minAge === '0' && this.maxAge === '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          return this.house.toString() == e.houseId.toString();
        });
    }
    else if (this.studentStatusType !== 'All' && this.category === 'All' && this.gender === '0'
    && this.house !== 'All' && this.minAge === '0' && this.maxAge === '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          return this.studentStatusType.toString() === (Number(e.status)).toString()  
          && this.house.toString() == e.houseId.toString();
        });
    }
    else if (this.studentStatusType !== 'All' && this.category !== 'All' && this.gender === '0'
    && this.house !== 'All' && this.minAge === '0' && this.maxAge === '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          return this.studentStatusType.toString() === (Number(e.status)).toString()  
          && this.category === e.categoryId.toString()
          && this.house.toString() == e.houseId.toString();
        });
    }
    else if (this.studentStatusType !== 'All' && this.category !== 'All' && this.gender !== '0'
    && this.house !== 'All' && this.minAge === '0' && this.maxAge === '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          return this.studentStatusType.toString() === (Number(e.status)).toString()  
          && this.category === e.categoryId.toString()
          && this.gender.toString() == e.gender.toString()
          && this.house.toString() == e.houseId.toString();
        });
    }
    else if (this.studentStatusType !== 'All' && this.category !== 'All' && this.gender !== '0'
    && this.house !== 'All' && this.minAge !== '0' && this.maxAge === '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          let year_born: any;
          if(e.dob != null || e.dob != undefined)
           year_born = e.dob.split('T')[0];
          let calculatedAge = this.getAge(year_born);
          return this.studentStatusType.toString() === (Number(e.status)).toString()
          && this.category === e.categoryId.toString()
          && this.gender.toString() === e.gender.toString()
          && this.house.toString() == e.houseId.toString()
          && Number(this.minAge) > Number(calculatedAge);
        });
    }
    else if (this.studentStatusType !== 'All' && this.category !== 'All' && this.gender !== '0'
    && this.house !== 'All' && this.minAge === '0' && this.maxAge !== '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          let year_born: any;
          if(e.dob != null || e.dob != undefined)
           year_born = e.dob.split('T')[0];
          let calculatedAge = this.getAge(year_born);
          return this.studentStatusType.toString() === (Number(e.status)).toString()
          && this.category === e.categoryId.toString()
          && this.gender.toString() === e.gender.toString()
          && this.house.toString() == e.houseId.toString()
          && Number(this.maxAge) > Number(calculatedAge) ;
        });
    } else if (this.studentStatusType === 'All' && this.category !== 'All' && this.gender === '0'
    && this.house === 'All' && this.minAge === '0' && this.maxAge !== '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          let year_born: any;
          if(e.dob != null || e.dob != undefined)
           year_born = e.dob.split('T')[0];
          let calculatedAge = this.getAge(year_born);
          return this.category === e.categoryId.toString()        
          && Number(calculatedAge) < Number(this.maxAge);
        });
    } 
    else if (this.studentStatusType !== 'All' && this.category !== 'All' && this.gender !== '0'
    && this.house !== 'All' && this.minAge !== '0' && this.maxAge !== '0') {
        this.sortedAdmissions = this.sortedClassAdmissions.filter((e: Student) => {
          let year_born: any;
          if(e.dob != null || e.dob != undefined)
           year_born = e.dob.split('T')[0];
          let calculatedAge = this.getAge(year_born);
          return this.studentStatusType.toString() === (Number(e.status)).toString()
          && this.category === e.categoryId.toString()
          && this.gender.toString() === e.gender.toString()
          && this.house.toString() == e.houseId.toString()
          && Number(calculatedAge) > Number(this.minAge)
          && Number(calculatedAge) < Number(this.maxAge);
        });
    }
    if (this.studentType !== 'All') {
      this.sortedAdmissions = this.sortedAdmissions.filter(x => x.studentType == this.studentType.toString());
    }
    if (this.studentAdmissionType !== 'All') {
      this.sortedAdmissions = this.sortedAdmissions.filter(x => x.studentAdmissionType == this.studentAdmissionType.toString());
    }
    this.sortedAdmissions = this.sortedAdmissions.filter(yearname=>Number(yearname.academicYearId)== Number(academicYearId));
    this.sortedAdmissions  = this.sortedAdmissions.sort((a, b) => (a.className < b.className) ? -1 : 1);    
    this.seqno = 1;
    this.sortedAdmissions.forEach((x) => {
      x.sno = this.seqno++;
    });
    
  }

  generateExcel() {
    var i = 0;
    if (this.sortedClassAdmissions.length === 0) {
      this.toastr.warning('Please select Class', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    if (this.selectedAttributes.length === 0) {
      this.toastr.warning('Please select Attributes', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    
    if (this.academicYearId === '0') {
      this.toastr.warning('Please select academic year', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }

    this.sortAdmission();

    let sortedArray:any=[];
    for (let batchItem of this.batches) {
      let item = this.sortedAdmissions.filter(item=>item.batchName==batchItem.batchName && item.className==batchItem.className);
      sortedArray.push(item);
    }
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Student Data');
    //const header = [];
    const title = [];
    title.push('Student Report');
    const header = [];
    const fields = [];
    header.push('S.No.');
    fields.push('sno');
    for (const item of this.headerList) {
      header.push(item);
    }
    for (const item of this.fieldList) {
      fields.push(item);
    }
    // this.selectedAttributes.forEach(element => {
    //   header.push(element.name);
    //   fields.push(element.fieldName);
    // });
    //const headerRow = worksheet.addRow(this.headerList);
    let titleRow = worksheet.addRow(title);
    titleRow.font = { size: 16, bold: true };
    const headerRow = worksheet.addRow(header);
    for(let sortedItme of sortedArray) {
      for (const x1 of sortedItme) {
        i = i+1;
        x1.sno = i;
        const x2 = Object.keys(x1);
        const temp = [];
        //temp.push(i);
        for (const y of fields) {
          if (y === 'dob'|| y === 'admissionDate') {
            temp.push(this.formatDate(x1[y]));
          }        
          else if(y=='age'){
            let year_born: any;
            if(x1['dob'] != null || x1['dob'] != undefined)
            year_born = x1['dob'].split('T')[0];
            let calculatedAge = this.getAge(year_born);
            temp.push(calculatedAge); 
          }
          else if(y=="houseId")
          {
            let house = this.housesList.find(_house => _house.id == Number(x1[y]));
            temp.push(house!=undefined?house.houseName:'');
          }
        else if(y=='status')
            temp.push(Boolean(x1[y])==true?'Active':'InActive'); 
          else {
            temp.push(x1[y]);
          }
          // x2.forEach((e) => {
          //   if (e.toLocaleLowerCase() === y.toLocaleLowerCase()) {
          //   }
          // });
        }
      
        worksheet.addRow(temp);
      }
    }
    const fname = 'Student Report';

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }

  generatePDF() {
    if (this.sortedClassAdmissions.length === 0) {
      this.toastr.warning('Please select Class', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    if (this.selectedAttributes.length === 0) {
      this.toastr.warning('Please select Attributes', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }

    if (this.academicYearId === '0') {
      this.toastr.warning('Please select academic year', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }

    this.sortAdmission();

    let sortedArray:any=[];
    for (let batchItem of this.batches) {
    let item = this.sortedAdmissions.filter(item=>item.batchName==batchItem.batchName && item.className==batchItem.className);
    sortedArray.push(item);
    }
    this.sortedAdmissions=[];
    for(let sortedItme of sortedArray){
      for (const _item of sortedItme) {
        this.sortedAdmissions.push(_item);
      }
    }
    this.seqno = 1;
    this.sortedAdmissions.forEach((x) => {
      x.sno = this.seqno++;
    });

    const header = [];
    const fields = [];
    header.push('S.No.');
    fields.push('sno');
    for (const item of this.headerList) {
      header.push(item);
    }
    for (const item of this.fieldList) {
      fields.push(item);
    }
    const widthList = [];
    // this.headerList.splice(0, 0,'S No');
    
    for (const item of header) {
        widthList.push('auto');
    }
    const docDefinition = {
      pageOrientation: 'landscape',
      content: [
        {
          table: {
              widths: ['auto', '*'],
              body: [
                  [ {
                      width: 100,
                      height: 100,
                      image: 'imageKey'
                  }, {
                      width: '*',
                      alignment: 'center',
                      stack: [
                          {
                              style: 'h1',
                              text: this.institution.institutionName
                          },
                          {
                            style: 'h2',
                            text: this.institution.address
                          },
                          {
                              style: 'h2',
                              text: this.academicYears.find(x => x.id.toString() === this.academicYearId.toString()).name
                          },
                          {
                              style: 'h1',
                              text: 'STUDENT REPORT'
                          }
                      ]
                  }
                  ]
              ]
          },
          layout: {
              hLineWidth: (line) => line === 1,
              vLineWidth: () => 0,
              paddingBottom: () => 5
          }
        },
        {
          
          table: {
            headerRows: 1,
            widths: widthList,
            style:'sectionHeader',
            body: [
              //this.headerList,
              header,
              ...this.sortedAdmissions.map((p) => {
                const temp = [];
                for (const y of fields) {

                  let year_born: any;
                  if(y=='age'){
                    if(p['dob'] != null || p['dob'] != undefined)
                    year_born = p['dob'].split('T')[0];
                    let calculatedAge = this.getAge(year_born);
                    temp.push(calculatedAge); 
                  }
                  else if(y=="houseId")
                  {
                    let house = this.housesList.find(_house => _house.id == Number(p[y]));
                    temp.push(house!=undefined?house.houseName:'');
                  }
                  else if (y=='dob')                  
                  temp.push(this.formatDate(p[y]));
                  else if (y == 'admissionDate')
                  temp.push(this.formatDate(p[y]));
                  else if(y=='status')
                   temp.push(Boolean(p[y])==true?'Active':'InActive'); 
                  else
                   temp.push(p[y]);                   
                }
                return temp;
              })      
                 
            ]
          },
          style: {
            overflowX: 'auto',
            width: '100%'
          }
        }, 
      ],
      images: {
        imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
        // via URL address, which can be referenced by this key
      },
      styles: {
        sectionHeader: {
          // bold: true,
          // decoration: 'underline',
          fontSize: 5,
         margin: [0, 25, 0, 25]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 16,
          bold: true
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        }  
      }
    };

    pdfMake.createPdf(docDefinition).download('STUDENT REPORT.pdf');

  }

  ngOnInit(): void {
    this.ListHouse();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.generateAttributes();
    this.ListAcademicYear();
    this.currentAcademicYear();
    this.ListCategories();
    this.getAllBatchDetails();
    this.ListStudents();
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('-');
  }

  ngAfterViewInit(): void {
    // ($('.selectable') as any).selectable({..});
    ($('#tblLocations') as any).sortable({
      items: 'tr:not(tr:first-child)',
      cursor: 'pointer',
      axis: 'y',
      dropOnEmpty: false,
      start: (e, ui) => {
          ui.item.addClass('selected');
      },
      stop: (e, ui) => {
          ui.item.removeClass('selected');
          const headerData = ($('#tblLocations') as any).sortable('toArray', { attribute: 'id' });
          this.headerList = headerData;
          const data = ($('#tblLocations') as any).sortable('toArray', { attribute: 'class' });
          this.fieldList = data;
          $(this).find('tr').each((index: any) => {
             
              if (index > 0) {
                  $(this).find('td').eq(2).html(index);
              }
          });
      }
  });
}
}
