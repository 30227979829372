// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ng-select .ng-clear-wrapper {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/academic/feemanagement/feemanagementreports/studentinstallmentsummary/studentinstallmentsummary.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB","sourcesContent":[".ng-select .ng-clear-wrapper {\r\n    display: none;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
