import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Syllabus} from './syllabus.model';
//import { AnyNaptrRecord } from 'dns';

@Injectable({
    providedIn: 'root'
  })
  export class SyllabusService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<Syllabus[]> {
      return this.httpClient.get<Syllabus[]>(environment.apiUrl + '/syllabus/'+ 8 + '/'+1)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<Syllabus> {
      return this.httpClient.get<Syllabus>(environment.apiUrl + '/syllabus/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getDetails(id): Observable<Syllabus> {
      return this.httpClient.get<Syllabus>(environment.apiUrl + '/syllabus/GetSyllabusDetails/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    public downloadFile(id: number, docFile: string): Observable < Blob > {
      return this.httpClient.get(environment.apiUrl  + '/syllabus/GetFile/' + id + '/'  + docFile, {
          responseType: 'blob'
      });
    }

    // create(assignment): Observable<HomeWork> {
    //   return this.httpClient.post<HomeWork>(environment.apiUrl + '/homework/', JSON.stringify(assignment), this.httpOptions)
    //   .pipe(
    //     catchError(this.errorHandler)
    //   );
    // }

    create(homework): Observable<Syllabus> {
      //return this.httpClient.post(environment.apiUrl + '/syllabus/', homework)
      return this.httpClient.post<Syllabus>(environment.apiUrl + '/syllabus/',  JSON.stringify(homework), 
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'enc-type': 'multipart/form-data'
        })
    }
    )
      .pipe(
        catchError(this.errorHandler)
      );
    }

    update(id, assignment): Observable<Syllabus> {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.put<Syllabus>(environment.apiUrl + '/syllabus/' + id, JSON.stringify(assignment), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'enc-type': 'multipart/form-data'
        })
    })
      .pipe(
        catchError(this.errorHandler)
      );
    }

    delete(id) {
      return this.httpClient.delete<Syllabus>(environment.apiUrl + '/syllabus/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }
  }
