import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventcalendarComponent } from './eventcalendar/eventcalendar.component';
import { EventreportComponent } from './eventreport/eventreport.component';
import { EventCalendarRoutingModule } from './eventcalendar-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { FullCalendarModule } from '@fullcalendar/angular';
import { HttpClientModule } from '@angular/common/http';




// FullCalendarModule.registerPlugins([ // register FullCalendar plugins
//   dayGridPlugin,
//   timeGridPlugin,
//   interactionPlugin
// ]);

@NgModule({
  declarations: [EventcalendarComponent, EventreportComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EventCalendarRoutingModule,
    FullCalendarModule, 
    HttpClientModule,
    
  ],
  providers:[],
  bootstrap:[EventcalendarComponent,EventreportComponent]

})
export class EventcalendarModule { }
