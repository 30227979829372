import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Classes } from '../ExamSetting/models/classes.model';
import { EvaluationType } from '../ExamSetting/models/evaluation-type.model';
import { GradeScaleGroup } from '../ExamSetting/models/grade-scale-group.model';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GradescalegroupService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  //This is the server URL
  //url:string="http://localhost:5000/";
  
  constructor(private http:HttpClient) { }

  //This service is used to get all the grade scale
  getAllGradeScale():Observable<GradeScaleGroup[]>{
    return this.http.get<GradeScaleGroup[]>(environment.apiUrl+"/gradescalegroup/GetAllGradeScaleGroup").pipe(
      catchError(this.errorHandler)
    ) 
  }
  getAllEvalutaionTypes():Observable<EvaluationType[]>{
    return this.http.get<EvaluationType[]>(environment.apiUrl+"/EvaluationsTypes/").pipe(
      catchError(this.errorHandler)
    )
  }

  getAllEvaluationsType():Observable<EvaluationType[]>{
    return this.http.get<EvaluationType[]>(environment.apiUrl+"/gradescalegroup/GetAllEvaluationsType").pipe(
      catchError(this.errorHandler)
    )
  }
 
  getAllClass():Observable<Classes[]>{
    return this.http.get<Classes[]>(environment.apiUrl+"/gradescalegroup/GetAllClasses").pipe(
      catchError(this.errorHandler)
    )
  }

  //This service is used to get the grade scale by id
  getGradeScaleById(id):Observable<GradeScaleGroup>{
    return this.http.get<GradeScaleGroup>(environment.apiUrl+"/gradescalegroup/"+id).pipe(
      catchError(this.errorHandler)
    )
  }

  getEvaluationTypeById(id):Observable<EvaluationType>{
    return this.http.get<EvaluationType>(environment.apiUrl+"/gradescalegroup/GetEvaluationTypeById?id="+id).pipe(
      catchError(this.errorHandler)
    )
  }

  //This service is used to create Grade Scale
  createGradeScale(data):Observable<GradeScaleGroup> {
    return this.http.post<GradeScaleGroup>(environment.apiUrl+"/gradescalegroup",JSON.stringify(data),this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }

  createEvaluationType(data):Observable<EvaluationType> {
    return this.http.post<EvaluationType>(environment.apiUrl+"/gradescalegroup/CreateEvaluationsType/",JSON.stringify(data),this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }

  createMapping(mapMode,evalId, assignId) {
    return this.http.post(environment.apiUrl+"/gradescalegroup/GradeEvaluationClassMapping?mapMode="+mapMode+"&gradeId="+assignId+"&evaClassId="+evalId,this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }

  AllGradeScaleMapping(id){
    return this.http.get(environment.apiUrl+"/gradescalegroup/AllGradeScaleMapping?gradeScaleId="+id).pipe(
      catchError(this.errorHandler)
    )
  }

  //This service is used to update the existing Grade Scale
  updateGradeScale(id,data):Observable<GradeScaleGroup>{
    return this.http.put<GradeScaleGroup>(environment.apiUrl+"/gradescalegroup/"+id,JSON.stringify(data),this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }


  updateEvaluationType(id,data):Observable<EvaluationType>{
    return this.http.put<EvaluationType>(environment.apiUrl+"/gradescalegroup/UpdateEvaluationType?id="+id,JSON.stringify(data),this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }

  //This service is used to delete the existing grade scale
  deleteGradeScale(id){
    return this.http.delete<GradeScaleGroup>(environment.apiUrl+"/gradescalegroup/"+id,this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }

  deleteEvaluationType(id){
    return this.http.delete<EvaluationType>(environment.apiUrl+"/gradescalegroup/DeleteEvaluationType?id="+id,this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }

  // This code is for error handling
  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
