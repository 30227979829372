import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Student } from './../../studentmanagement/student.model';
import { StudentService } from './../../studentmanagement/student.service';
import { FeeHead } from '../feehead/feehead.model';
import { FeeHeadService } from '../feehead/feehead.service';
import { InstallmentService } from './../installment/installment.service';
import { Installment } from './../installment/installment.model';
import { ConcessionTemplate, ConcessionTemplateDetail } from '../concessiontemplate/concessiontemplate.model';
import { ConcessionTemplateService } from '../concessiontemplate/concessiontemplate.service';
import { StudentConcession, StudentConcessionFeeHead, StudentConcessionInstallments,  } from './setstudentconcession.model';
import { SetStudentConcessionService  } from './setstudentconcession.service';

declare var $: any;

@Component({
  selector: 'app-setstudentconcession',
  templateUrl: './setstudentconcession.component.html',
  styleUrls: ['./setstudentconcession.component.css']
})
export class SetstudentconcessionComponent implements OnInit {

  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  concessionTemplates: ConcessionTemplate[] = [];
  classId = '';
  admissionId = '';
  concessionTemplateId: number = -1;
  studentDetail: Student = {} as Student;
  studentConcessionDetail: StudentConcession = {} as StudentConcession;
  conseDetail: ConcessionTemplate = {} as ConcessionTemplate;
  concessionName = '';
  feeHeadList: FeeHead[] = [];
  isDiscountPercentage = false;
  isDeleteBtnVisible = false;
  concessionAmountHeading = 'Amount';
  admissionno = '';
  isDiscountInPercentage = '';
  installments: Installment[] = [];
  concessionInsatllements: StudentConcessionInstallments[] = [];
  selectedInstallments = '';
  selectedFeeHeads = '';
  academicYearId = '';
  

  constructor(
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    public concessionService: ConcessionTemplateService,
    public feeHeadService: FeeHeadService,
    public installmentService: InstallmentService,
    public setConcessionService: SetStudentConcessionService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  ListStudents() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.admissions = data;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }

  ListConcession() {
    this.concessionTemplates = [];
    this.concessionService.getAll().subscribe((data: ConcessionTemplate[]) => {
      this.concessionTemplates = data;
    });
  }



  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  onClassSelected(value: string) {
    this.classId = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toLocaleLowerCase());
    });
  }

  onBatchChanged(value: string) {
    this.studentService.getAll().subscribe((data: Student[]) => {
     // this.ListFeeHead();
      this.admissions = data.filter((student: Student) => {
      return student.classCourseId.toString() === this.classId &&
        student.batchId.toString() === value.toString();
      });
    });
  }

  onStudentChanged(id: number) {
    this.studentConcessionDetail=null;
    this.studentService.getById(id).subscribe((data: Student) => {
        this.clearAllCheckboxSelection();
        this.studentDetail = data;
        this.admissionId = data.id.toString();
        this.academicYearId = data.academicYearId.toString();

        this.installmentService.getFeePayment(this.studentDetail.id).subscribe((inst: Installment[]) => {
          console.log(inst);
          this.installments = inst;
          this.setStudentConcessionDetail();
        });
        this.setConcessionService.getSetConcession(this.academicYearId,this.admissionId).subscribe((data:StudentConcession)=>{
          if(data!=null){
          this.toastr.warning('this student has already a concession', 'Warning!', {
          timeOut: 3000
          });
          this.concessionTemplateId=data.concessionTemplateId;
        this.onConcessionTemplateChanged(data.concessionTemplateId);
          }
          
        });
     });
  }

  get totalRows(): number {
    return this.installments.length;
  }

  onConcessionTemplateChanged(id: number) {
    this.concessionService.getById(id).subscribe((data: ConcessionTemplate) => {
      this.clearAllCheckboxSelection();
      this.conseDetail = data;
      this.feeHeadList = [];
      this.feeHeadService.getAll().subscribe((fdata: FeeHead[]) => {

        if(data != null)
        {
          //set concession details
          this.concessionName = data.concessionName;
          this.isDiscountInPercentage = data.isDiscountInPercentage;
          if (data.isDiscountInPercentage) {
            this.concessionAmountHeading = 'VALUE(%)';
          } else {
            this.concessionAmountHeading = 'VALUE';
          }


          //set concession templte details to fee head
          fdata.forEach((x) => {
            data.concessionTemplateDetails.forEach((y) => {
                if (x.id === y.feeHeadId) {
                  x.concessionAmount = y.amount;
                  this.feeHeadList.push(x);
                }
            });
          });

        }
        
        //set already saved concession detail
        this.setStudentConcessionDetail();
        
      });
   });

  }

  searchStudent() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.studentDetail = data.filter((student: Student) => {
        return student.admissionNo.toLowerCase() === this.admissionno.toLowerCase();
      })[0];
      console.log(this.studentDetail);
    });
  }

  setStudentConcessionDetail() {
    if(this.academicYearId == '' || this.admissionId == '' || this.concessionTemplateId == -1) return;
    debugger;
    //this.setConcessionService.getAll(this.academicYearId, this.admissionId, this.concessionTemplateId).subscribe((data: StudentConcession[]) => {
      this.setConcessionService.getSetConcession(this.academicYearId,this.admissionId).subscribe((data:StudentConcession)=>{
     /* if(data.length > 0)
      {
       
        this.concessionTemplateId=data[0].concessionTemplateId;
        this.studentConcessionDetail = data[0];
        console.log("concession :"+ this.studentConcessionDetail);
        this.concessionTemplateId=this.studentConcessionDetail.concessionTemplateId;
        if(this.studentConcessionDetail.isDeleted == true)
        {
          //if record is deleted then do not show old selected feeHeads and installments
          this.studentConcessionDetail.feeHeadIds = '';
          this.studentConcessionDetail.installmentIds = '';
        }
        this.isDeleteBtnVisible = !this.studentConcessionDetail.isDeleted;
        
      }
      else
      {
        this.studentConcessionDetail = null;
        this.isDeleteBtnVisible = false;
      }
      
      this.setStudentConcessionDefaultSelections();
      
    });*/
    if(data!=null)
    {
     
      this.concessionTemplateId=data.concessionTemplateId;
      this.studentConcessionDetail = data;
      console.log("concession :"+ this.studentConcessionDetail);
      this.concessionTemplateId=this.studentConcessionDetail.concessionTemplateId;
      if(this.studentConcessionDetail.isDeleted == true)
      {
        //if record is deleted then do not show old selected feeHeads and installments
        this.studentConcessionDetail.feeHeadIds = '';
        this.studentConcessionDetail.installmentIds = '';
      }
      this.isDeleteBtnVisible = !this.studentConcessionDetail.isDeleted;
      
    }
    else
    {
      this.studentConcessionDetail = null;
      this.isDeleteBtnVisible = false;
    }
    
    this.setStudentConcessionDefaultSelections();
    
  });
    debugger;
    this.concessionTemplateId=this.studentConcessionDetail.concessionTemplateId;
    ///this.onConcessionTemplateChanged(this.concessionTemplateId);
  }

  setStudentConcessionDefaultSelections()
  {
     
    if(this.studentConcessionDetail != null && this.studentConcessionDetail.id > 0)
    {
      /*this.toastr.warning('this student has already a concession', 'Warning!', {
          timeOut: 3000
        });*/
      //make selection/checked for fee head if it is already save 
      var savedFeeHeadList = this.studentConcessionDetail.feeHeadIds.split("|");
      this.feeHeadList.forEach((x) => {
        savedFeeHeadList.forEach((y) => {
            if (x.id.toString() === y) {
              x.checked = true;
            }
        });
      });

    //make selection/checked for installments if it is already save 
    var savedInsatllmentList = this.studentConcessionDetail.installmentIds.split("|");
      this.installments.forEach((x) => {
        savedInsatllmentList.forEach((y) => {
            if (x.id.toString() === y) {
              x.checked = true;
            }
        });
      });
    }
    else{
      this.clearAllCheckboxSelection();
    }

  }

  clearAllCheckboxSelection() {
    this.feeHeadList.forEach(x => x.checked = false);
    this.installments.forEach(x => x.checked = false);
  }

  checkAllCheckBox(ev) {
    debugger;
    this.feeHeadList.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.feeHeadList.every(p => p.checked);
  }

  checkAllCheckBoxInstallement(ev) {
   
    this.installments.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxCheckedInstallement() {
    return this.installments.every(p => p.checked);
  }

  resetForm() {
    this.batches = [];
    this.admissions = [];
    // this.concessionTemplates = [];
    this.studentDetail = {} as Student;
    this.conseDetail = {} as ConcessionTemplate;
    this.feeHeadList = [];
    this.installments = [];
    this.admissionId = '';
    this.classId = '';
    this.concessionTemplateId = 0;
  }

  onSubmit() {
    const selConcession: StudentConcession = {} as StudentConcession;
    selConcession.admissionId = this.admissionId;
    selConcession.concessionTemplateId = this.concessionTemplateId;
    selConcession.feeHeadIds = this.selectedFeeHeads;
    selConcession.installmentIds =  this.selectedInstallments;
    selConcession.academicYearId =  this.academicYearId;
    

    //validate request
    if(selConcession.feeHeadIds == '' || selConcession.installmentIds == '')
    {
      this.toastr.error('Please select fee head and installment.', 'Error!', {
        timeOut: 3000
      });
      return false;
    }

    if(this.studentConcessionDetail == null || this.studentConcessionDetail.id == 0)
    {
      this.setConcessionService.create(selConcession).subscribe(res => {
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        //this.resetForm();
        this.onConcessionTemplateChanged(this.concessionTemplateId)
      });
    }
    else
    {
      this.setConcessionService.update(this.studentConcessionDetail.id, selConcession).subscribe(res => {
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        //this.resetForm();
        this.onConcessionTemplateChanged(this.concessionTemplateId)
      });
    }
  }

  onSave() {

    this.concessionInsatllements = [];
    this.selectedInstallments = '';
    let k = 1;
    const totalSelected = this.installments.filter(x => x.checked).length;
    const feeHeadsSelected = this.feeHeadList.filter(x => x.checked).length;
    if(totalSelected==0 || feeHeadsSelected==0){
      this.toastr.error('Please select fee heads and installements first to save', 'ERROR!', {
        timeOut: 3000
      });
      return;
    }else{
      document.getElementById("openModalButton").click();
      this.installments.forEach((x) => {
        const concessionInsatllement: StudentConcessionInstallments = {} as StudentConcessionInstallments;
        if (x.checked) {
          if (totalSelected !== k) {
            this.selectedInstallments += x.id.toString() + '|';
          } else {
            this.selectedInstallments += x.id.toString();
          }
          k++;
          concessionInsatllement.feeHeads = [];
          concessionInsatllement.installmentName = x.installmentName;
          concessionInsatllement.payStatus = x.isPaid ? 'Paid' : '';
          let j = 1;
          const totalFeeSelected = this.feeHeadList.filter(y => y.checked).length;
          this.selectedFeeHeads = '';
          this.feeHeadList.forEach((y) => {
            const conFeeHead: StudentConcessionFeeHead = {} as StudentConcessionFeeHead;
            if (y.checked) {
              if (totalFeeSelected !== j) {
                this.selectedFeeHeads += y.id.toString() + '|';
              } else {
                this.selectedFeeHeads += y.id.toString();
              }
              j++;
              conFeeHead.amount = y.concessionAmount.toString();
              conFeeHead.feeHeadName = y.feeHeadName;
              conFeeHead.id = y.id;
              concessionInsatllement.feeHeads.push(conFeeHead);
            }
          });
          this.concessionInsatllements.push(concessionInsatllement);
        }
      });
  
    console.log(this.concessionInsatllements);
    }


  }

  onDelete() {

    if(this.studentConcessionDetail == null || this.studentConcessionDetail.id == 0)
    {
      this.toastr.error('No record found', 'Error!', {
        timeOut: 3000
      });
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.setConcessionService.delete(this.studentConcessionDetail.id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your record has been deleted.',
            'success'
          );
          this.onConcessionTemplateChanged(this.concessionTemplateId)
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your record is safe :)',
          'error'
        );
      }
    });
  }

  ngOnInit(): void {
    this.ListClassCourse();
    this.ListConcession();
    this.studentConcessionDetail=null;
    const self = this;
    $('.select2').select2();
    $('.select2').on('change', function() {
      console.log($(this).val());
      self.onStudentChanged(Number($(this).val()));
    });
  }

}
