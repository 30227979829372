import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClassCourse } from '../../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../../mastersettings/models/batch';
import { BatchService } from '../../../mastersettings/batch/batch.service';
import { FilterModel, StudentDetailsModel, StudentPaymentDetailsModel, FeeReceiptModel } from './studentpaymentreport.model';
import { StudentPaymentReportService } from './studentpaymentreport.service';
import { Student } from './../../../studentmanagement/student.model';
import { StudentService } from './../../../studentmanagement/student.service';
import { SettingsService } from '../../,,/../../mastersettings/settings.service';
import { Academicyear } from '../,,/../../../mastersettings/models/academicyear';
import { filter } from 'rxjs/operators';
import { FeeReciept, FeeRecieptDetailDetail } from '../feereceipt/feereceipt.model';
import { FeeCollectionReportService } from '../feecollectionreport/feecollectionreport.service';
import { ReportAttribute } from '../../../studentmanagement/report.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
//const htmlToPdfmake = require("html-to-pdfmake");
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { NgSelectComponent } from '@ng-select/ng-select';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-studentpaymentreport',
  templateUrl: './studentpaymentreport.component.html',
  styleUrls: ['./studentpaymentreport.component.css']
})
export class StudentpaymentreportComponent implements OnInit {
  
  @ViewChild('pdfDiv') pdfDiv!: ElementRef;
  classDrp: string;
  batchDrp: string;
  studentDrp: string;
  academicDrp: string;
  admissionNo: string;
  sumTotal: number;
  sumDiscount: number;
  sumPaid: number;
  dataCount: any;
  isHidden = true;
  studentName = '---';
  class = '---';
  rollNo = '---';
  fathersName = '---';
  mobileNo = '---';
  academicName='---';
  admissionNumber = '---';
  academicYears: Academicyear[] = [];
  studentDetailsList: StudentDetailsModel[] = [];
  studentDetailsFilteredList: StudentDetailsModel[] = [];
  studentPaymentDetailsList: StudentPaymentDetailsModel[] = [];
  studentPaymentDetailsFilteredList: StudentPaymentDetailsModel[] = [];
  feeReceiptList: FeeReceiptModel = {} as FeeReceiptModel;
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  filterValues: FilterModel[] = [];
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  institution: any;
  totalPaidAmount: any;
  isLoading: Boolean=false;
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  constructor(
    public studentPaymentReportService: StudentPaymentReportService,
    public feeCollectionReportService: FeeCollectionReportService,
    public classCourseService: ClassCourseService,
    public batchService: BatchService,
    public studentService: StudentService,
    public settingsService: SettingsService


  ) { }
  listStudents() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.admissions = data;
    });
  }
  listClassCourse() {
    this.classCourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }
  listAcademicYear() {
    this.settingsService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
      this.currentAcademicYear();
    });
  }

  currentAcademicYear() {
    this.settingsService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.academicDrp = data.id.toString();
    });
  }

  clearForm()
  {
    this.studentDrp = "0";
    this.onDrpFilter();
    this.admissionNo = null;
    this.classDrp = "0";    
    this.studentDrp = "0";        
    this.batchDrp="0";
    this.admissions=[];
    this.isLoading=false;
  }

  onClassSelected(value: string) {
    this.classDrp = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => (batch.classId.toString().toLocaleLowerCase()) === (value.toLocaleLowerCase()));
      console.log(this.batches);
    });
    this.studentDrp = 'Select Student';
    this.isLoading=false;
  }

  onBatchChanged(value: string) {
    this.studentDrp = 'Select Student';
    //this.ngSelectComponent.handleClearClick();
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.admissions = data.filter((student: Student) => {
        return student.classCourseId.toString() === this.classDrp &&
          student.batchId.toString() === value.toString();
      });
    });
    this.isLoading=false;
  }
  onAcademicYearChanged(id: number) {
    this.onDrpFilter();
    this.isLoading=false;
  }
  onStudentChanged($selected: any) {
    this.studentDrp=$selected;
    this.onDrpFilter();
    this.isLoading=false;
  }
  onSearchAdmissionNumber() {
    const admission = this.admissionNo;
    if (admission !== '0') {
      this.onDrpFilter();
      this.isLoading=false;
    }
  }
  onDrpFilter() {    
    this.studentPaymentDetailsFilteredList = null;
    this.studentDetailsFilteredList = null;
    this.sumTotal = 0;
    this.sumDiscount = 0;
    this.sumPaid = 0;
    this.studentName = '---';
    this.class = '---';
    this.rollNo = '---';
    this.fathersName = '---';
    this.mobileNo = '---';
    this.admissionNumber = '---';
    this.isHidden = true;
    const classSelected = this.classDrp || null;
    const batchSelected = this.batchDrp || null;
    const studentSelected = this.studentDrp || null;
    const academicYearSelected = this.academicDrp || null;
    const admissionNumber = this.admissionNo || null;
    console.log(this.studentDrp);
    if (classSelected !== 'Class' && batchSelected !== 'Batch' && studentSelected !== 'Select Student' && academicYearSelected !== 'Academic Year') {
      this.getStudentDetails(classSelected, batchSelected, studentSelected, academicYearSelected, admissionNumber);
    } else if (admissionNumber !== '0' && admissionNumber !== '0' && admissionNumber !== null) {
      this.getStudentDetails(0, 0, 0, academicYearSelected, admissionNumber);
    }
    
  }
  getStudentDetails(classSelected, batchSelected, studentSelected, academicYearSelected, admissionNumber) {

    const formData = {} as FilterModel;
    formData.studentId = studentSelected.toString();
    formData.classId = classSelected.toString();
    formData.batchId = batchSelected.toString();
    formData.academicYearId = academicYearSelected.toString();
    formData.admissionNumber = admissionNumber || "0";
    console.log(formData.studentId);
    // this.filterValues=[classSelected,batchSelected,studentSelected,academicYearSelected,admissionNumber]
    const self = this;
    // this.studentPaymentReportService.getStudentDetails(formData).subscribe((data: StudentDetailsModel[]) => {
    //   this.studentDetailsFilteredList = this.studentDetailsList = data;
    //   this.studentName = data[0].studentName;
    //   this.class = data[0].class;
    //   this.rollNo = data[0].rollNo;
    //   this.admissionNumber = data[0].admissionNo;
    //   this.fathersName = data[0].fathersName
    //   this.mobileNo = data[0].mobileNumber
    // });
    this.isHidden = false;
    this.getStudentPaymentDetails(formData);
  }
  studentDetailsInstallmentList: any;
  arrayInstallmentList: any = [];
  filteredInstallmentList:any;
  stringInstallmentList: any;
  studentList: any;
  temp:any;
  getStudentPaymentDetails(formData) {
    this.isLoading=true;
    this.studentPaymentReportService.getStudentPaymentDetails(formData).subscribe((data: StudentPaymentDetailsModel[]) => {
      
      this.studentPaymentDetailsFilteredList = this.studentPaymentDetailsList = data['table'];
      if(data['table'].length===0){
       // this.listStudents();
        
        const studentS=this.admissions.filter(x=>x.id.toString()==this.studentDrp && x.academicYearId==this.academicDrp)[0];
        this.studentName =studentS.studentName;
        this.class = studentS.className;
        this.rollNo = studentS.rollNo;
        this.admissionNumber = studentS.admissionNo;
        this.fathersName = studentS.fatherName;
        this.mobileNo = studentS.mobileNo;
        this.academicName = this.studentList.academicId;
       // this.onBatchChanged(studentS.batchId);
      }else{
        this.studentDetailsInstallmentList = data['table1'];
        this.studentList = this.studentPaymentDetailsFilteredList.filter(x => x.studentName)[0];
        this.studentName = this.studentList.studentName;
        this.class = this.studentList.class;
        this.rollNo = this.studentList.rollNo;
        this.admissionNumber = this.studentList.admissionNo;
        this.fathersName = this.studentList.fatherName
        this.mobileNo = this.studentList.mobileNo
        this.academicName = this.studentList.academicId
        this.sumTotal = 0;
        this.sumPaid = data['table'].map(item => parseInt(item.totalPaidAmount)).reduce((prev, curr) => prev + curr, 0);
        this.sumDiscount = data['table'].map(item => parseInt(item.discount)).reduce((prev, curr) => prev + curr, 0);
        this.arrayInstallmentList = [];
        this.studentPaymentDetailsList.forEach(item=>{
          this.temp = item;
          this.filteredInstallmentList = [];
          this.temp.id.split(',').forEach(element => {
              this.filteredInstallmentList.push(this.studentDetailsInstallmentList.filter(x=>x.feeCollectionMastersId==element)[0]);
            });
           this.arrayInstallmentList.push(this.filteredInstallmentList.filter(x => x != undefined).map(x => x.installmentName).join(', '));
       });
      }
     this.isLoading=false;
    // this.arrayInstallmentList.push(this.studentDetailsInstallmentList.map(x => x.installmentName).join(', '));
    // this.arrayInstallmentListPdf.push(this.studentDetailsInstallmentList.map(x => x.installmentName).join(', '));
    // this.arrayInstallmentListExcel.push(this.studentDetailsInstallmentList.map(x => x.installmentName).join(', '));
  });
  }
  // onFeeReceiptDetails(value: number) {
  //   this.getFeeDetailsReceipt(value);
  // }
  // getFeeDetailsReceipt(value: number) {
  //   this.studentPaymentReportService.getFeeReceiptDetails(value).subscribe((data: FeeReceiptModel) => {
  //     this.feeReceiptList = data;

  //   });
  // }
  
  onPrintReceipt() {

  }
  generateAttributes() {
    this.attributes = [
      {name: 'S NO', fieldName: 'index' },
      {name: 'DATE', fieldName: 'payDate' },
      {name: 'RECEIPT NO', fieldName: 'receiptNo' },
      {name: 'INSTALLMENT', fieldName: 'installmentName' },
      {name: 'TOTAL AMOUNT', fieldName: 'totalAmount' },
      {name: 'DISCOUNT', fieldName: 'discount' },
      {name: 'PAID AMOUNT', fieldName: 'paidAmount' },
      {name: 'PAY MODE', fieldName: 'payMode' },
      {name: 'TAKEN BY', fieldName: 'takenBy' }
    ];
  }

  public formatDate(date,onlyDate =false) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return !onlyDate ? new Date().toLocaleDateString() : [day, month, year].join('-');
  }

  FormatData(Obj: any, dataList:any=[], installamentNameList:any)
  {
    Obj.FeeHeadDataList = [];
    let TotalAmount = 0;
    //let TotalDiscountAmount = 0;
    for(let instItemName of installamentNameList)
    {
      let SumOfHeadItem = 0;
      let SumOfHeadItemDiscount = 0;
      let SumOfpayableamount=0;
     ;
      let HeadItemList =  dataList.filter(_installmentItem=>_installmentItem.feeHeadName==instItemName);
      for(let _headitem of HeadItemList)
      {
        SumOfHeadItem = SumOfHeadItem + Number(_headitem.amount);
        SumOfHeadItemDiscount = SumOfHeadItemDiscount + Number(_headitem.discountRate);
        SumOfpayableamount=SumOfpayableamount + Number(_headitem.amountPayable);
      }
      TotalAmount = TotalAmount + SumOfHeadItem;
      ///TotalDiscountAmount = TotalDiscountAmount + SumOfHeadItemDiscount;
      let FeeHeadDataObj ={
        FeeHeadName: instItemName,
        Amount: SumOfHeadItem,
        discountRate:SumOfHeadItemDiscount,
        paidamount:SumOfpayableamount
      }      
     ;
      Obj.FeeHeadDataList.push(FeeHeadDataObj);
    }
    Obj.TotalAmount = TotalAmount;
   // Obj.TotalDiscountAmount = TotalDiscountAmount;
  }
  generateExcel() {
    this.headerList =[];
    this.fieldList =[];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    this.defaultArray = [];
    for(var i = 0 ; i < this.studentPaymentDetailsFilteredList.length ; i++)
    {
      let data ={
        "index" : i+1,
        "payDate" : this.studentPaymentDetailsFilteredList[i].payDate == undefined ? "":this.studentPaymentDetailsFilteredList[i].payDate,
        "receiptNo" : this.studentPaymentDetailsFilteredList[i].receiptNo == undefined ?"": this.studentPaymentDetailsFilteredList[i].receiptNo ,
        "admissionNo" :this.studentPaymentDetailsFilteredList[i].admissionNo == undefined ? "" : this.studentPaymentDetailsFilteredList[i].admissionNo,
        "studentName" : this.studentPaymentDetailsFilteredList[i].studentName == undefined ? "" :  this.studentPaymentDetailsFilteredList[i].studentName,
        "class" : this.studentPaymentDetailsFilteredList[i].class == undefined ? "" : this.studentPaymentDetailsFilteredList[i].class,
        "batch" : this.studentPaymentDetailsFilteredList[i].batch == undefined ? "":this.studentPaymentDetailsFilteredList[i].batch,
        "rollNo" : this.studentPaymentDetailsFilteredList[i].rollNo == undefined ?"": this.studentPaymentDetailsFilteredList[i].rollNo ,
        "paidAmount" : this.studentPaymentDetailsFilteredList[i].totalPaidAmount == undefined ?"": this.studentPaymentDetailsFilteredList[i].totalPaidAmount ,
        "payMode" : this.studentPaymentDetailsFilteredList[i].payMode == undefined ? "" :  this.studentPaymentDetailsFilteredList[i].payMode,
        "takenBy" : this.studentPaymentDetailsFilteredList[i].takenBy == undefined ? "" : this.studentPaymentDetailsFilteredList[i].takenBy,
        "paidStatus" : this.studentPaymentDetailsFilteredList[i].paidStatus == undefined ? "":this.studentPaymentDetailsFilteredList[i].paidStatus,
        "discount" : this.studentPaymentDetailsFilteredList[i].discount == undefined ? "":this.studentPaymentDetailsFilteredList[i].discount,
        "totalAmount" : this.studentPaymentDetailsFilteredList[i].totalAmount == undefined ? "":this.studentPaymentDetailsFilteredList[i].totalAmount,
        "installmentName": this.arrayInstallmentList[i]
      }
      this.defaultArray.push(data);
    }
    let data1 ={
      "index" : "",
      "payDate" : "",
        "receiptNo" : "",
        "admissionNo" :"",
        "studentName" : "",
        "class" : "",
        "batch" : "",
        "rollNo" : "Total Amount",
        "paidAmount" :this.sumPaid,
        "payMode" : "",
        "takenBy" : "",
        "paidStatus" : "",
        "discount": "",
        "totalAmount":"",
        "installmentName": "Total Amount"
    }
    this.defaultArray.push(data1);
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('STUDENT PAYMENT DETAILS SUMMARY -'+this.academicName);
    worksheet.addRow();
    // if (this.institution.logoUrl !== null) {
    //   const imageId2 = workbook.addImage({
    //     base64: environment.imageUrl + this.institution.logoUrl,
    //     extension: 'jpg',
    //   });
    //   worksheet.addImage(imageId2, 'A1:B1');
    // }

    const titleRow = worksheet.addRow(['STUDENT PAYMENT DETAILS SUMMARY -'+this.academicName]);
    const headerRow = worksheet.addRow(this.headerList);
    for(var i = 0 ; i < this.defaultArray.length ; i++)
    this.defaultArray[i]["index"] = i+1;
    for (const x1 of this.defaultArray) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'payDate') {
          if(x1[y]=="" || x1[y]==undefined){
            temp.push("");
          }else{
            temp.push(this.formatDate(x1[y],true));
          }
        } else {
          if(y === "paidStatus")
          {
           ;
            if(x1[y] === true || x1[y] === false){
              temp.push(x1[y] === true ?"Cancelled":"Paid");
            }else{
              temp.push("")
            }
          }
          else{
            temp.push(x1[y]);
          }
        }
      }
      worksheet.addRow(temp);
    }
    const fname = 'STUDENT PAYMENT DETAILS SUMMARY';

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }
  defaultArray = [];
  generatePDF() {
    
    console.log(this.institution);
    console.log(this.studentPaymentDetailsFilteredList);
    this.headerList = [];
    this.fieldList = [];
    
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    for (let i = 0; i < this.studentPaymentDetailsFilteredList.length; i++) {
      this.studentPaymentDetailsFilteredList[i]["index"] = i + 1;
    }
    const widthList = [];
    for (const item of this.headerList) {
        widthList.push('auto');
    }
    this.defaultArray = [];
   ;
    for(var i = 0 ; i < this.studentPaymentDetailsFilteredList.length ; i++)
    {
      let data ={
        "index" : i+1,
        "payDate" : this.studentPaymentDetailsFilteredList[i].payDate == undefined ? "":this.studentPaymentDetailsFilteredList[i].payDate,
        "receiptNo" : this.studentPaymentDetailsFilteredList[i].receiptNo == undefined ?"": this.studentPaymentDetailsFilteredList[i].receiptNo ,
        "admissionNo" :this.studentPaymentDetailsFilteredList[i].admissionNo == undefined ? "" : this.studentPaymentDetailsFilteredList[i].admissionNo,
        "studentName" : this.studentPaymentDetailsFilteredList[i].studentName == undefined ? "" :  this.studentPaymentDetailsFilteredList[i].studentName,
        "class" : this.studentPaymentDetailsFilteredList[i].class == undefined ? "" : this.studentPaymentDetailsFilteredList[i].class,
        "batch" : this.studentPaymentDetailsFilteredList[i].batch == undefined ? "":this.studentPaymentDetailsFilteredList[i].batch,
        "rollNo" : this.studentPaymentDetailsFilteredList[i].rollNo == undefined ?"": this.studentPaymentDetailsFilteredList[i].rollNo ,
        "paidAmount" : this.studentPaymentDetailsFilteredList[i].totalPaidAmount == undefined ?"": this.studentPaymentDetailsFilteredList[i].totalPaidAmount ,
        "payMode" : this.studentPaymentDetailsFilteredList[i].payMode == undefined ? "" :  this.studentPaymentDetailsFilteredList[i].payMode,
        "takenBy" : this.studentPaymentDetailsFilteredList[i].takenBy == undefined ? "" : this.studentPaymentDetailsFilteredList[i].takenBy,
        "paidStatus" : this.studentPaymentDetailsFilteredList[i].paidStatus == undefined ? "":this.studentPaymentDetailsFilteredList[i].paidStatus,
        "discount" : this.studentPaymentDetailsFilteredList[i].discount == undefined ? "":this.studentPaymentDetailsFilteredList[i].discount,
        "totalAmount" : this.studentPaymentDetailsFilteredList[i].totalPaidAmount == undefined ? "":this.studentPaymentDetailsFilteredList[i].totalPaidAmount,
        "installmentName": this.arrayInstallmentList[i]
      }
      this.defaultArray.push(data);
    }
    let data1 ={
      "index" : "",
      "payDate" : "",
        "receiptNo" : "",
        "admissionNo" :"",
        "studentName" : "",
        "class" : "",
        "batch" : "",
        "rollNo" : "Total Amount",
        "paidAmount" :this.sumPaid,
        "payMode" : "",
        "takenBy" : "",
        "paidStatus" : "",
        "discount": "",
        "totalAmount":this.sumPaid,
        "installmentName": "Total Amount"
    }
    this.defaultArray.push(data1);
    const docDefinition = {
      pageSize: 'A4',

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      // pageMargins: [ 40, 60, 40, 60 ],
      content: [
        {
            table: {
                widths: ['auto', '*'],
                body: [
                    [ {
                        width: 100,
                        height: 100,
                        image: 'imageKey'
                    }, {
                        width: '*',
                        alignment: 'center',
                        stack: [
                            {
                                style: 'h1',
                                text: this.institution.institutionName
                            },
                            {
                              style: 'h2',
                              text: this.institution.address
                            },
                            {
                                style: 'h2',
                                text: this.academicName
                            },
                            {
                                style: 'h3',
                                text: 'STUDENT PAYMENT REPORT'                            
                            },
                            {
                              style: 'h2',
                              text: 'Student Name :'+this.studentName +' '+
                              'Class : '+this.class+' '+
                              'Roll : '+ this.rollNo
                            },
                            {
                              style: 'h2',
                              text: 'Father name : '+this.fathersName+' '+
                              'Phone : '+ this.mobileNo
                          },
                        ]
                    },
                    

                    ]
                ]
            },
            layout: {
                hLineWidth: () =>  0,
                vLineWidth: () => 0,
                paddingBottom: () => 5,
                paddingLeft:()=> 5,
                paddingRight:()=> 5
            },
          },
          {
            style:'sectionHeader',
            table: {
              headerRows: 1,
              widths: widthList,
              body: [
                this.headerList,
                ...this.defaultArray.map((p) => {
                  const temp = [];
                  for (const y of this.fieldList) {
                    if (y === 'payDate') {
                     ;
                      if(p[y]=="" || p[y]==undefined){
                        temp.push("");
                      }else{
                        temp.push(this.formatDate(p[y],true));
                      }
                    } else {
                      if(y === "paidStatus")
                      {
                       ;
                        if(p[y] === true || p[y] === false){
                          temp.push(p[y] === true ?"Cancelled":"Paid");
                        }else{
                          temp.push("")
                        }
                      }
                      else{
                        temp.push(p[y]);
                      }
                    }
                  }
                  
                  return temp;
                  
                }),
                
                // {
                //   width: '*',
                //   alignment: 'center',
                //   stack: [
                //       {
                //           style: 'h1',
                //           text: "Total Paid Amount :" + this.totalPaidAmount
                //       }
                //     ]
                //   }
              ]
            }
          },
          // { text: 'Total Paid Amount: '+ this.totalPaidAmount, alignment: 'center',fontSize: 14 },
      ],
      images: {
         imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
         // via URL address, which can be referenced by this key
      },
      styles: {
        
        sectionHeader: {
          // bold: true,
          // decoration: 'underline',
          fontSize: 8,
          margin: [0, 10, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 13,
          bold: true,
          // fontWeight:800
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        },
       
      }
    };
    pdfMake.createPdf(docDefinition).download('STUDENT PAYMENT REPORT.pdf');
  }

  exportPDF() {
   ;
    // const pdfDiv = this.pdfDiv.nativeElement;
    // var html = htmlToPdfmake(pdfDiv.innerHTML);
    // const documentDefinition = { content: html };
    // pdfMake.createPdf(documentDefinition).download(); 
     
  }
  
  ngOnInit(): void {
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.classDrp = 'Class';
    this.batchDrp = 'Batch';
    this.studentDrp = 'Select Student';
    this.academicDrp = 'Academic Year';
    // this.getStudentPaymentReport();
    this.listClassCourse();
    this.listAcademicYear();
    this.listStudents();
    this.generateAttributes();
    const self = this;
   $('.select2').select2();
   $('.select2').on('change', function () {
    self.studentDrp=$(this).val().toString();
     self.studentDrp=$(this).val().toString();
   });
  }

}
