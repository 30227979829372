import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { InstitutionProfile } from './institutionprofile.model';
import { InstitutionprofileService } from './institutionprofile.service';

@Component({
  selector: 'app-institutionprofile',
  templateUrl: './institutionprofile.component.html',
  styleUrls: ['./institutionprofile.component.css']
})
export class InstitutionprofileComponent implements OnInit {

  profileForm: UntypedFormGroup;
  submitted = false;
  baseUrl = '';
  logoUrl = environment.imageUrl;
  imageSrc: string;

  constructor(
    private profileService: InstitutionprofileService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  readURL(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
        const file = target.files[0];

        const reader = new FileReader();
        reader.onload = (e => {
          this.logoUrl = reader.result.toString();
          this.profileForm.get('institutionLogo').patchValue(this.logoUrl);
        });

        reader.readAsDataURL(file);
    }
}

  updateForm() {
    this.profileService.getProfile().subscribe((data) => {
      console.log(data);
      this.logoUrl += data.logoUrl;
      this.profileForm = this.fb.group({
        id: [data.id],
        institutionName: [data.institutionname],
        addressLocation: [data.addressLocation],
        country: [data.country],
        state: [data.state],
        city: [data.city],
        contactNumber: [data.contactNumber],
        landLine: [data.landLine],
        emailId: [data.emailId],
        websiteURL: [data.websiteURL],
        affiliationId: [data.affiliationId],
        affiliationType: [data.affiliationType],
        weekendStartDay: [data.weekendStartDay],
        medium: [data.medium],
        timeZone: [data.timeZone],
        currencySymbol: [data.currencySymbol],
        countryCode: [data.countryCode],
        institutionCode: [data.institutionCode],
        logoUrl: [data.logoUrl],
        institutionLogo: [''],
     });
    });
  }


  submitForm() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      console.log(this.profileForm.value);
      return;
     }
    console.log(this.profileForm.value);
    this.profileService.update(this.profileForm.get('id').value, this.profileForm.value).subscribe(res => {
      console.log('Institution updated!');
      this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
        timeOut: 3000
      });
      this.submitted = false;
      // this.resetForm();
      // this.casteForm.reset();
      
    });
}

  ngOnInit(): void {
    this.profileForm = this.fb.group({
        id: [0],
        institutionName: [''],
        addressLocation: [''],
        country: [''],
        state: [''],
        city: [''],
        contactNumber: [''],
        landLine: [''],
        emailId: [''],
        websiteURL: [''],
        affiliationId: [''],
        affiliationType: [''],
        weekendStartDay: [''],
        medium: [''],
        timeZone: [''],
        currencySymbol: [''],
        countryCode: [''],
        institutionCode: [''],
        institutionLogo: [''],
        logoUrl: ['']
    });
    this.updateForm();
  }

}
