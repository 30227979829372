import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-examination',
  templateUrl: './online-examination.component.html',
  styleUrls: ['./online-examination.component.css']
})
export class OnlineExaminationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
