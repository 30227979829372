import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {  throwError, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { PaymentGateway, PaymentMethod, PaymentProvider } from './paymentcheckout.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentCheckoutService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      skip: 'true'
    })
  };
  constructor(private httpClient: HttpClient) { }

  getAllPaymentGateways(): Observable<PaymentGateway[]> {
    return this.httpClient.get<PaymentGateway[]>(environment.apiUrl + '/PaymentGateway/GetAllPaymentGateways', this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllPaymentMethods(): Observable<PaymentMethod[]> {
    return this.httpClient.get<PaymentMethod[]>(environment.apiUrl + '/PaymentMethod/GetAllPaymentMethods', this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllPaymentProviders(): Observable<PaymentProvider[]> {
    return this.httpClient.get<PaymentProvider[]>(environment.apiUrl + '/PaymentProvider/GetAllPaymentProviders', this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
