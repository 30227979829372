// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profImage{
    height:200px;
    width:180px;
    border:2px solid lightgray;
    border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/academic/studentmanagement/admission/admission.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,0BAA0B;IAC1B,kBAAkB;AACtB","sourcesContent":[".profImage{\r\n    height:200px;\r\n    width:180px;\r\n    border:2px solid lightgray;\r\n    border-radius: 8px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
