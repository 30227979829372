import { Component, OnInit } from '@angular/core';
import { Credit, CreditTransaction } from '../../creditmanager/credit.model';
import { CreditService } from '../../creditmanager/credit.service';

@Component({
  selector: 'app-credit-history',
  templateUrl: './credit-history.component.html',
  styleUrls: ['./credit-history.component.css']
})
export class CreditHistoryComponent implements OnInit {
  creditList: Credit[] = [];
  creditListFiltered: Credit[] = [];
  creditTransaction: CreditTransaction = {} as CreditTransaction;
  WTotal = 0;
  STotal = 0;
  EMTotal = 0;
  VTotal = 0;
  selected = '';

  constructor(private creditService: CreditService) { }

  loadCreditList() {
    this.creditService.getAll().subscribe((data: Credit[]) => {
    console.log(data);
    this.creditList = this.creditListFiltered = data;
    this.calculateTotalPurchaseCreditCount();
  });


}
calculateTotalPurchaseCreditCount() {
  let smsTot = 0;
  let whatsappTot = 0;
  let emailTot = 0;
  let voiceTot = 0;
  const len = this.creditList.length;
  let name: string;
  let val: any;
  for (let i = 0; i < len; i++) {
    name = this.creditList[i].serviceName.toLocaleLowerCase();
    val = this.creditList[i].quantity;
    switch (name) {
      case 'whatsapp': whatsappTot += parseInt(val, 0);
                       break;
      case 'sms': smsTot += parseInt(val, 0);
                  break;
      case 'email': emailTot += parseInt(val, 0);
                    break;
      case 'voice': voiceTot += parseInt(val, 0);
                    break;
    }
  }
  this.WTotal = whatsappTot;
  this.STotal = smsTot;
  this.VTotal = voiceTot;
  this.EMTotal = emailTot;
}

getPurchaseTransaction(creditTransactionId: number) {
  this.creditService.getPurchaseCreditById(creditTransactionId).subscribe((data: CreditTransaction) => {
    this.creditTransaction = data;
  });
}

filterServiceList(serviceName: string) {
  this.selected = serviceName;
  this.creditListFiltered = this.creditList.filter(element => element.serviceName.toLocaleLowerCase() === serviceName);
}

onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

filter(keyword: any) {
    if (keyword === '') {
      this.creditListFiltered = this.creditList;
    } else {
      this.creditListFiltered = this.creditList
        // filter column need to be changed - service name is used for testing
        .filter((cred: Credit) => cred.serviceName.toLocaleLowerCase()
          .includes(keyword.toLocaleLowerCase())
        );
    }
}

  ngOnInit(): void {
    this.loadCreditList();
  }

}
