import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WelcomeNotification } from './welcomenotification.model';
import { WelcomeNotificationService } from './welcomenotification.service';
declare var $: any;

@Component({
  selector: 'app-welcomenotification',
  templateUrl: './welcomenotification.component.html',
  styleUrls: ['./welcomenotification.component.css']
})
export class WelcomenotificationComponent implements OnInit {

   welcomenotificationForm: UntypedFormGroup;
   welcomenotifications: WelcomeNotification[] = [];
   welcomenotificationFilteredList: WelcomeNotification[] = [];
   submitted = false;
   displayFor = '';
   chkAdmin = false;
   chkSuperAdmin = false;
   chkParent = false;
   chkTeacher = false;
  constructor(
    public welcomeNotificationService: WelcomeNotificationService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  ListNotifications() {
    this.welcomeNotificationService.getAll().subscribe((data: WelcomeNotification[]) => {
      console.log(data);
      this.welcomenotificationFilteredList = this.welcomenotifications = data;
    });
  }

  onCheckChange(evt: any, value: any) {
    if (evt.currentTarget.checked) {
      if (this.displayFor === '' ) {
        this.displayFor += value;
      } else {
        this.displayFor += '|' + value;
      }
    } else {
      if (this.displayFor === value) {
        this.displayFor = this.displayFor.replace(value, '');
      } else if (this.displayFor.includes('|' + value)) {
        this.displayFor = this.displayFor.replace('|' + value, '');
      } else {
        this.displayFor = this.displayFor.replace(value + '|', '');
      }
    }
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  validateDate(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
  
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
  
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }
  
      console.log(Date.parse(control.value));
      console.log(Date.parse(matchingControl.value));
      // set error on matchingControl if validation fails
      if (Date.parse(control.value) > Date.parse(matchingControl.value)) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
    };
    }

  get totalRows(): number {
    return this.welcomenotificationFilteredList.length;
  }

  get f() { return this.welcomenotificationForm.controls; }
  resetForm() {
    this.submitted = false;
    this.welcomenotificationForm = this.fb.group({
      id: [0],
      title: ['', Validators.required],
      notificationType: ['', Validators.required],
      displayFrom: ['', Validators.required],
      displayTo: ['', Validators.required],
      displayFor : ['', Validators.required],
      notificationMessage : ['', Validators.required]
    }, {
      validator: this.validateDate('displayFrom', 'displayTo')
    });
    $('.summernote').summernote('code', '');
    $('#displayFrom').val('');
    $('#displayTo').val('');
    this.displayFor = '';
    this.chkAdmin = false;
    this.chkSuperAdmin = false;
    this.chkParent = false;
    this.chkTeacher = false;
  }

  submitForm() {
    this.submitted = true;
     // stop here if form is invalid
    this.welcomenotificationForm.get('displayFor').patchValue(this.displayFor);
    this.welcomenotificationForm.get('notificationMessage').patchValue($('.summernote').summernote('code'));
    if (this.welcomenotificationForm.invalid) {
      if (this.f.displayTo.errors.mustMatch) {
        this.toastr.warning('Display to is greater than Display From', 'WARNING!', {
          timeOut: 3000
        });
      }
      return;
    }

    const DsiplayFromDate = this.welcomenotificationForm.get('displayFrom').value;
    const DsiplayToDate = this.welcomenotificationForm.get('displayTo').value ;

    console.log(DsiplayFromDate);

    this.welcomenotificationForm.get('displayFrom').patchValue(this.formatDate(DsiplayFromDate));
    this.welcomenotificationForm.get('displayTo').patchValue(this.formatDate(DsiplayToDate));


    if (this.welcomenotificationForm.get('id').value === 0) {
        console.log(this.welcomenotificationForm.value);
        this.welcomeNotificationService.create(this.welcomenotificationForm.value).subscribe(res => {
          console.log('Notification created!');
          this.ListNotifications();
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
        } else {
          this.welcomeNotificationService.update(this.welcomenotificationForm.get('id').value,
           this.welcomenotificationForm.value).subscribe(res => {
            console.log('Notification page updated!');
            this.ListNotifications();
            this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submitted = false;
            this.resetForm();
           });
        }
  }
  updateForm(id: number) {
   this.welcomeNotificationService.getById(id).subscribe((data: WelcomeNotification) => {
      console.log(data);
      this.welcomenotificationForm = this.fb.group({
        id: [data.id],
        title: [data.title, Validators.required],
        notificationType: [data.notificationType, Validators.required],
        displayFrom: [data.displayFrom, Validators.required],
        displayTo: [data.displayTo, Validators.required],
        displayFor : [data.displayFor, Validators.required],
        notificationMessage : [data.notificationMessage, Validators.required]
      }, {
        validator: this.validateDate('displayFrom', 'displayTo')
      });
      this.displayFor = data.displayFor;
      $('#displayFrom').val(this.formatDate(data.displayFrom));
      $('#displayTo').val(this.formatDate(data.displayTo));
      $('.summernote').summernote('code', data.notificationMessage);
      this.displayFor.split('|').forEach((x) => {
        if (x === 'Admin') {
          this.chkAdmin = true;
        } else if (x === 'Super Admin') {
          this.chkSuperAdmin = true;
        } else if (x === 'Parent') {
          this.chkParent = true;
        } else if (x === 'Teacher') {
          this.chkTeacher = true;
        }
      });
    });
  }

  viewForm(id: number) {
    this.welcomeNotificationService.getById(id).subscribe((data: WelcomeNotification) => {
      console.log(data);
      // this.batchNames = data.batchName.split('|');
      $('.summernotes').summernote({height: 300, toolbar: false});
      $('.summernotes').summernote('code', data.notificationMessage);
      $('.summernotes').summernote('disable');
      // this.assignmentData = data;
    });
  }

  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.welcomeNotificationService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListNotifications();
        });
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      );
      }
    });
  }

  ngOnInit(): void {
    this.ListNotifications();
    this.welcomenotificationForm = this.fb.group({
      id: [0],
      title: ['', Validators.required],
      notificationType: [''],
      displayFrom: ['', Validators.required],
      displayTo: ['', Validators.required],
      displayFor : [''],
      notificationMessage : ['', Validators.required]
    }, {
      validator: this.validateDate('displayFrom', 'displayTo')
    });
    const self =  this;
    $('.summernote').summernote({height: 300});
    $('#displayFrom').datepicker().on('changeDate', (e) => {
      self.welcomenotificationForm.get('displayFrom').patchValue(e.date);
    });
    $('#displayTo').datepicker().on('changeDate', (e) => {
      self.welcomenotificationForm.get('displayTo').patchValue(e.date);
    });
  }

}
