import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { ClassCourseService } from './../../mastersettings/classcourse/classcourse.service';
import { Student, StudentDetails } from '../../studentmanagement/student.model';
import { ClassCourse } from './../../mastersettings/models/classcourse';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { SettingsService } from '../../mastersettings/settings.service';
import { AssignSubjectTeacher, AssignSubjectEmployee } from './assignsubteacher.model';
import { AssignSubjectTeacherService } from './assignsubteacher.service';
import { AssignTeacherService } from '../assignteacher/assignteacher.service';
import { AssignTeacherEmployee } from '../assignteacher/assignteacher.model';

@Component({
  selector: 'app-assignsubteacher',
  templateUrl: './assignsubteacher.component.html',
  styleUrls: ['./assignsubteacher.component.css']
})
export class AssignsubteacherComponent implements OnInit {

  classcourses: ClassCourse[] = [];
  classcourseid = '-1';
  batchid = '-1';
  batches: Batch[] = [];
  classCount= 0;
  classpopCount= 0;
  academicYear: Academicyear = {} as Academicyear;
  assignedTeachers: AssignSubjectTeacher[] = [];
  selectedAssignedTeachers: AssignSubjectTeacher[] = [];
  employees: AssignTeacherEmployee[] = [];
  selectedClassId = 0;
  subjectData = [];
  selectedBatchId = 0;
  selectedEmployeeId = 0;
  selectedSubjectId = 0;
  selectedClassName = '';
  selectedBatchName = '';
  selectedSubjectName = '';
  batxhDummyData =[];
  employeeDummyData =[];


  constructor(
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    private assignSubTeacherService: AssignSubjectTeacherService,
    private toastr: ToastrService,
    private academicyearService: SettingsService, private assignTeacherService: AssignTeacherService,
  ) { }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      console.log(data);
      this.academicYear = data;
      // this.ListStudents();
    });
  }
  ngOnInit(): void {
    this.ListAllAssignedSubjects();
    this.ListClassCourse();
    this.ListAllEmployees();
  }
  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  ListBatch() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data;
    });
  }

  ListAllAssignedSubjects() {
    this.assignSubTeacherService.getAll().subscribe((data: AssignSubjectTeacher[]) => {
      console.log(data);
      this.assignedTeachers = data;
      if (this.classcourseid.toString() !== '-1' && this.batchid.toString() !== '-1') {
        this.onBatchSelected(this.batchid);
      }
    });
  }

  async ListAllEmployees() {
    await this.assignTeacherService.getAllEmployees().subscribe((data: AssignTeacherEmployee[]) => {
      console.log(data);
      
      this.employees = data;
      this.employeeDummyData = data;
    });
  }
  // ListAllEmployees() {
  //   this.assignTeacherService.getAllEmployees().subscribe((data: AssignTeacherEmployee[]) => {
  //     ;
  //     this.employees = data;
  //   });
  // }

  get totalRows(): number {
    return this.selectedAssignedTeachers.length;
  }

  get totalEmployeeRows(): number {
    return this.employees.length;
  }

  onClassSelected(value: string) {
    ;
   
    if (value === '-1') {  this.selectedAssignedTeachers= [] ;this.batches ==null;return; }
    if (value === '0') {

    } else {
      // this.filteredAssignments = this.assignments.filter((assignment: Assignment) =>
      // assignment.classId.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      this.batchService.getAll().subscribe((data: Batch[]) => {

        ;
        this.batches = data.filter((batch: Batch) => batch.classId.toString() === value);
      });
    }
    this.selectedAssignedTeachers= [] ;
    this.classCount = 0;
    // this.selectedAssignedTeachers = this.assignedTeachers
    // .filter((data: AssignTeacher) => data.classId.toString() === value.toString());
  }

  search(e){
;
var value = e.target.value;
this.selectedAssignedTeachers = this.selectedAssignedTeachers
.filter((data: AssignSubjectTeacher) => data.subjectName.toString() === this.classcourseid.toString()
&& data.batchId.toString() === value.toString());
  }
  onBatchSelected(value: string) {
    ;
    this.selectedAssignedTeachers = this.assignedTeachers
    .filter((data: AssignSubjectTeacher) => data.classId.toString() === this.classcourseid.toString()
    && data.batchId.toString() === value.toString());
    console.log(this.selectedAssignedTeachers);
    var dataSort = [];
    for(var k = this.selectedAssignedTeachers.length-1 ; k>=0 ; k--){
      dataSort.push(this.selectedAssignedTeachers[k]);
    }
    this.selectedAssignedTeachers = dataSort;
  this.batxhDummyData =    dataSort;
    this.classCount =  this.selectedAssignedTeachers.length;
  }

  onCheckChanged(ev, id) {
    ;
    console.log(ev.target.checked);
    console.log(id);
    if (ev.target.checked) {
      this.selectedEmployeeId = id;
      this.employees.forEach(x => {
        if (x.id === id) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });
    } else {
      this.selectedEmployeeId = 0;
    }
  }

  onShowEmployee(classid: number, batchId: number, subjectId: number,
                 className: string, batchName: string, subjectName: string) {
                   ;
                   this.employees.forEach(x => {
                    if (x.id === this.selectedEmployeeId) {
                      x.checked = false;
                    } 
                  });           
    console.log(subjectId);
    this.selectedClassId = classid;
    this.selectedBatchId = batchId;
    this.selectedSubjectId = subjectId;
    this.selectedClassName = className;
    this.selectedBatchName = batchName;
    this.selectedSubjectName = subjectName;
    // console.log(this.selectedClassId);
    // console.log(this.selectedBatchId);
  }

  asssignTeacher() {
    // this.selectedEmployeeId = this.employees.filter((x) => x.checked).map(y => y.id)[0];
    ;
    const assignSubjectTeacher: AssignSubjectTeacher = {} as AssignSubjectTeacher;
    assignSubjectTeacher.classId = this.selectedClassId;
    assignSubjectTeacher.batchId = this.selectedBatchId;
    assignSubjectTeacher.academicYearId = 0;
    assignSubjectTeacher.employeeId = this.selectedEmployeeId;
    assignSubjectTeacher.subjectId = this.selectedSubjectId;

    console.log(assignSubjectTeacher);

    this.assignSubTeacherService.create(assignSubjectTeacher).subscribe((data: AssignSubjectTeacher) => {
      // this.ListAllAssignedClasses();
      
      this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
        timeOut: 3000
      });
      setTimeout(() => {
        this.ListAllAssignedSubjects();
        this.ListAllEmployees();
      }, 1000);
    });

  }

  deallocateTeacher(id: any) {
    Swal.fire({
      title: 'Confirm',
      text: 'Do you want to deallocate teacher?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.assignSubTeacherService.delete(id).subscribe((data: AssignSubjectTeacher) => {
          setTimeout(() => {
            this.ListAllAssignedSubjects();
          }, 1000);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

 

  
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    console.log(keyword);
    if (keyword === '') {
      this.selectedAssignedTeachers = this.batxhDummyData;
    } else {
      this.selectedAssignedTeachers = this.selectedAssignedTeachers.filter((batch: AssignSubjectTeacher) =>
        batch.subjectName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase()) ||
            batch.employeeName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase())
            );
    }
  }

  onKeyUpEvent2(event: any) {
    this.filter2(event.target.value);
  }

  filter2(keyword: any) {
    console.log(keyword);
    if (keyword === '') {
      this.employees = this.employeeDummyData;
    } else {
      this.employees = this.employees.filter((batch) =>
        batch.employeeName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase()) ||
            batch.departmentName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase())
            );
    }
  }
}
