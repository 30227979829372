import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IcseWeightage } from '../models/icse-weightage.model';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { IcseweightageService } from '../../services/icseweightage.service';

@Component({
  selector: 'app-weightages-icse',
  templateUrl: './weightages-icse.component.html',
  styleUrls: ['./weightages-icse.component.css']
})
export class WeightagesICSEComponent implements OnInit {

  icseWeightage=new IcseWeightage(0,null,0,null,0,null,0,0,null,null);
  icseWeightageForm:UntypedFormGroup;
  reportMarkType=[];
  categories=[];
  dropDown:any=[];
  icseWeightages:IcseWeightage[]=[];
  icseWeightagesFilteredList: IcseWeightage[] = [];
  submitted = false;
  icseWeightagesCount:number=0;

  constructor(private toastr:ToastrService,public fb: UntypedFormBuilder,public service: IcseweightageService) { }

  ngOnInit(): void {
    this.icseWeightageForm=this.fb.group({
      id:[0],
      weightages:['',Validators.required],
      reportMarkType:['Select Mark',Validators.required],             
      examCategory:['Select Category',Validators.required],
      ea:['',Validators.required],
      ia:['',Validators.required],
      coCurricular:['Select Curricular',Validators.required],
      weightageStatus:[true,Validators.required]
    });
    
    ///this.setForm();
      this.getAllIcseDropDown();
      this.getAllIcseWeightages();
  }

  get f() { return this.icseWeightageForm.controls; }

  setForm(dropDownOption:any=[]){
    this.reportMarkType=dropDownOption.weightageContents;
    this.categories=dropDownOption.examCategories;    
  }

  getAllIcseDropDown(){
    this.service.getAllIcseDropDown().subscribe(data=>{
      this.dropDown=data;
      this.setForm(this.dropDown);
    })
  }

  resetForm(){
    this.submitted = false;
    this.icseWeightageForm=this.fb.group({
        id:[0],
        weightages:['',Validators.required],
        reportMarkType:['Select Mark',Validators.required],             
        examCategory:['Select Category',Validators.required],
        ea:['',Validators.required],
        ia:['',Validators.required],
        coCurricular:['Select Curricular',Validators.required],
        weightageStatus:[true,Validators.required]
    });

    //this.getAllIcseDropDown();
  }

  submitForm(){      
    this.submitted=true;
    if(this.icseWeightageForm.invalid){
      this.toastr.warning('All field are required','WARNING!!!',{timeOut:3000});
      return;
    }

    if(this.icseWeightageForm.get('id').value===0)
    {      
        var icseId=this.icseWeightageForm.get('id').value;
        var weightages= this.icseWeightageForm.get('weightages').value;
        var reportMarkTypeId= this.icseWeightageForm.get('reportMarkType').value;             
        var examCategoryId= this.icseWeightageForm.get('examCategory').value;
        var ea=this.icseWeightageForm.get('ea').value;
        var ia=this.icseWeightageForm.get('ia').value;
        var coCurricular=this.icseWeightageForm.get('coCurricular').value;
        var weightageStatus= this.icseWeightageForm.get('weightageStatus').value;
        var data = new IcseWeightage(icseId,weightages,reportMarkTypeId,'',examCategoryId,'',ea,ia,coCurricular,weightageStatus);

        this.service.createIcseWeightage(data).subscribe(res=>{                
        this.toastr.success("ICSE Weightage successfully created","SUCCESS!",{timeOut:3000});
        this.submitted=false;
        this.resetForm();
        
        this.getAllIcseWeightages();
        
    });    
   }
   else{
    var icseId=this.icseWeightageForm.get('id').value;
    var weightages= this.icseWeightageForm.get('weightages').value;
    var reportMarkTypeId= this.icseWeightageForm.get('reportMarkType').value;             
    var examCategoryId= this.icseWeightageForm.get('examCategory').value;
    var ea=this.icseWeightageForm.get('ea').value;
    var ia=this.icseWeightageForm.get('ia').value;
    var coCurricular=this.icseWeightageForm.get('coCurricular').value;
    var weigtageStatus= this.icseWeightageForm.get('weightageStatus').value;
    var data = new IcseWeightage(icseId,weightages,reportMarkTypeId,'',examCategoryId,'',ea,ia,coCurricular,weigtageStatus);

    var updateData = new IcseWeightage(icseId,weightages,reportMarkTypeId,'',examCategoryId,'',ea,ia,coCurricular,weigtageStatus);
         this.service.updateIcseWeightage(this.icseWeightageForm.get('id').value,updateData).subscribe(res=>{           
           this.toastr.success("ICSE Weightage successfully updated","SUCCESS!",{timeOut:3000});
           this.getAllIcseWeightages();
         });  
         
         this.resetForm();
   }
  }

  getAllIcseWeightages(){
    this.service.getAllIcseWeightage().subscribe((data:IcseWeightage[])=>{
      this.icseWeightagesFilteredList=this.icseWeightages=data;
      this.icseWeightagesCount=this.icseWeightagesFilteredList.length;
    })
  }

  editIcseWeightage(id:number){
    this.service.getAllIcseWeightageById(id).subscribe((data:IcseWeightage)=>{
      this.icseWeightageForm=this.fb.group({
        id:[data.id],
        weightages:[data.weightages,Validators.required],
        reportMarkType:[data.reportMarkTypeId,Validators.required],             
        examCategory:[data.examCategoryId,Validators.required],
        ea:[data.ea,Validators.required],
        ia:[data.ia,Validators.required],
        coCurricular:[data.coCurricular,Validators.required],
        weightageStatus:[data.weightageStatus,Validators.required]        
      })
    })
  }

  deleteIcseWeightage(id:number){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton:true,
      confirmButtonText:'Yes, delete it!',
      cancelButtonText:'No, keep it'
    }).then((result)=>{
      if(result.value){
        this.service.deleteIcseWeightage(id).subscribe(data=>{
          Swal.fire(
            'Deleted!',
            'ICSE Weightage has been deleted.',
            'success'
          );
          this.getAllIcseWeightages();
        })
      }
    })
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.icseWeightagesFilteredList = this.icseWeightages;
      this.icseWeightagesCount= this.icseWeightagesFilteredList.length;
    } else {
    this.icseWeightagesFilteredList = this.icseWeightages
                                .filter((cbseWeightages: IcseWeightage) => cbseWeightages.weightages.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
    this.icseWeightagesCount=this.icseWeightagesFilteredList.length;
    }
  }
}
