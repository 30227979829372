import { Injectable } from '@angular/core';
import { Authentication } from '../authentication/authentication.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ChangeUserPassword } from './userprofile/changeuserpassword.model';
import { DashboardService } from './dashboard/dashboard.service';
import { EmployeeadmissionService } from './OperationActivity/hrmspayroll/hrms/employeeadmission.service';
import { AcademicSummary, CategoryNameReligions, DashboardClassWiseStudentsSummary, DepartmentWiseEmployeeCountDto, DepartmentWiseSalaryDto, EmployeeJoiningAndExitSummaryReport, GetEmployeeLeaveAttendanceReport, SessionWiseSalary, SessionWiseStudentReport } from './dashboard/attendanceSummary.model';
import { SettingsService } from './academic/mastersettings/settings.service';
import { Academicyear } from './academic/mastersettings/models/academicyear';

@Injectable({
    providedIn: 'root'
  })
  export class SharedService {
    public institutionCode = '';
    public academicYearId=0;
    
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
/**
 *
 */
constructor(
  private httpClient: HttpClient,
  private dashboardService: DashboardService,
  private empAdmissionService: EmployeeadmissionService,
  private academicYearService:SettingsService
            ) {}

changePassword(user): Observable<ChangeUserPassword> {

    return this.httpClient.post<ChangeUserPassword>(environment.apiUrl + '/membership/ChangeLoginPassword', JSON.stringify(user),
    this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
}
changePasswordAdmin(instacode,user): Observable<ChangeUserPassword> {

  return this.httpClient.post<ChangeUserPassword>(environment.apiUrl + '/membership/ChangeAdminPassword/'+instacode, JSON.stringify(user),
  this.httpOptions)
  .pipe(
    catchError(this.errorHandler)
  );
}

errorHandler(error) {
  console.log(error.error.message);
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}

menuMainModules: any[];
setMenuMainModules(modules: any[]){
  this.menuMainModules = modules;
}
getMenuMainModules(): any[]{
  return JSON.parse(JSON.stringify(this.menuMainModules));
}

// HRMS tab Start
departmentWiseEmployeesCount: DepartmentWiseEmployeeCountDto;
getAllDepartmentWiseEmployee(academicYearId: number) {
  this.empAdmissionService.GetAllDepartmentWiseEmployee(academicYearId)
    .subscribe((data: DepartmentWiseEmployeeCountDto) => {
     this.departmentWiseEmployeesCount = data;
    });
}

employeeAttendanceLeaveReport: GetEmployeeLeaveAttendanceReport;
getEmployeeLeaveAttendanceReport(academicYearId: number){
  this.dashboardService.getEmployeeLeaveAttendanceReport(academicYearId).subscribe((data: GetEmployeeLeaveAttendanceReport) => {
    this.employeeAttendanceLeaveReport = data;
  });
}

departmentWiseSalary: DepartmentWiseSalaryDto;
getDepartmentWiseSalary(yearId: any) {
  this.dashboardService.departmentWiseSalary(yearId).subscribe((data: DepartmentWiseSalaryDto) => {
     this.departmentWiseSalary = data;
    });
}

employeeJoiningExit: EmployeeJoiningAndExitSummaryReport;
getemployeeJoiningExit(yearId: any) {
  this.dashboardService
    .employeeJoiningExit(yearId)
    .subscribe((data: EmployeeJoiningAndExitSummaryReport) => {
     this.employeeJoiningExit = data;
    });
}

sessionWiseSalary: SessionWiseSalary[]=[];
getSessionWiseSalaryReport() {
  this.dashboardService.getSessionWiseSalaryReport().subscribe((data: SessionWiseSalary[]) => {
    this.sessionWiseSalary = data;

    });
    
}
// HRMS tab End

// Academic tab Start
academicYears: Academicyear[];
ListAcademicYear() {
  this.academicYearService.getAll().subscribe((data: Academicyear[]) => {
    this.academicYears = data;           
  });
}

academicSummary:AcademicSummary;
getAcademicSummary(academicId:number){
  this.dashboardService.getAcademicSummary(academicId).subscribe((data: AcademicSummary) => {
    this.academicSummary = data;
  });
}

categoryNameReligions:CategoryNameReligions[]=[];
getAcademicCategoryNameReligions(academicId:number){
  this.dashboardService.getAcademicCategoryNameReligions(academicId).subscribe((data: CategoryNameReligions[]) => {
  this.categoryNameReligions = data;
 });
}

dashboardClassWiseStudentsSummary:DashboardClassWiseStudentsSummary[]=[];
getDashboardClassWiseStudentsSummary(academicId:number){
  this.dashboardService.getDashboardClassWiseStudentsSummary(academicId).subscribe((data: DashboardClassWiseStudentsSummary[]) => {
    this.dashboardClassWiseStudentsSummary = data;      
  });      
}


sessionWiseStudentReport:SessionWiseStudentReport[]=[];
getDashBoardSessionWiseStudentReport(academicId:number){
  this.dashboardService.getDashBoardSessionWiseStudentReport(academicId).subscribe((data: SessionWiseStudentReport[]) => {
    this.sessionWiseStudentReport = data;  

});
}
// Academic tab End


  }
