import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BiomatricSettingDomain } from '../biomatric-setting-domain';
import { BiomatricSettingService } from '../biomatric-setting.service';
declare var $: any;
@Component({
  selector: 'app-biomatric-setting-edit',
  templateUrl: './biomatric-setting-edit.component.html',
  styleUrls: ['./biomatric-setting-edit.component.css']
})
export class BiomatricSettingEditComponent extends BiomatricSettingDomain implements OnInit, OnDestroy {


  constructor(private biomatricSettingService: BiomatricSettingService,
    private toastr: ToastrService,
  ) {
    super();
  }


  ngOnInit(): void {
    this.get();
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  edit() {
    this.submitted = true;
    if (this.biomatricSettingFormGroup.valid) {
      this.subscription.push(this.biomatricSettingService.update(this.id,this.biomatricSettingFormGroup.value).subscribe(response => {
        this.submitted = false;
        this.toastr.success('Records has been updated sucessfully', 'SUCCESS!', {
          timeOut: 3000
        });
        this.loadBiomatricSetting.emit(true);
        $('#editBiometricDetails').modal('hide');
      }))
    }
  }

  get() {
    this.subscription.push(this.biomatricSettingService.get(this.id).subscribe(response => {
      this.biomatricSettingFormGroup = this.bindFormGroup();
      this.biomatricSettingFormGroup.patchValue(response);
      this.showComponent = true;
    }))
  }


}
