import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CommunicationRoutingModule} from './communication-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BroadcastComponent } from './broadcast/broadcast.component';
import { CircularmanagerComponent } from './circularmanager/circularmanager.component';
import { CreditmanagerComponent } from './creditmanager/creditmanager.component';
import { TemplatemanagerComponent } from './configuration/templatemanager/templatemanager.component';
import { CircularReportComponent } from './report/circularreport/circularreport.component';
import { CreditHistoryComponent } from './report/credit-history/credit-history.component';
import { BroadcastReportComponent } from './report/broadcast-report/broadcast-report.component';
import { UsesReportComponent } from './report/uses-report/uses-report.component';
import { SenderIDRequestComponent } from './configuration/sender-idrequest/sender-idrequest.component';
import { ActivitylogComponent } from './activitylog/activitylog.component';
import { AppbroadcastComponent } from './appbroadcast/appbroadcast.component';
import { AppbroadcastreportComponent } from './report/appbroadcastreport/appbroadcastreport.component';


@NgModule({
  declarations: [BroadcastComponent, CircularmanagerComponent,
     CreditmanagerComponent, TemplatemanagerComponent, CircularReportComponent, BroadcastReportComponent,
      UsesReportComponent, CreditHistoryComponent, SenderIDRequestComponent, ActivitylogComponent,
       AppbroadcastComponent, AppbroadcastreportComponent
    ],
  imports: [
    CommonModule,
    CommunicationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule
  ]
})
export class CommunicationModule { }
