import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../authentication.service';
import { UserInfo } from '../models/user-model';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.css']
})
export class ForgetpasswordComponent implements OnInit{
  forgetPasswordForm: FormGroup;
  isLoading = false;
  isSchoolExist = false;
  userTypes = [
    {value: 'super-admin', viewValue: 'Super Admin'},
    {value: 'admin', viewValue: 'Admin'},
    {value: 'employee', viewValue: 'Employee'},
    {value: 'student', viewValue: 'Student'}
  ];
  schoolNotFoundErrorMessage: string;
  private subscription: Subscription;
  private forgotPasswordSubscription: Subscription;

  constructor(private fb: FormBuilder,
              private authService: AuthenticationService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.schoolNotFoundErrorMessage = '';
    this.forgetPasswordForm = this.fb.group({
      schoolcode: ['', Validators.required],
      username: ['', Validators.required],
      usertype: ['', Validators.required]
    });

    this.onSchoolcodeChanges();
  }

  resetForm() {
    this.isLoading = false;
    this.forgetPasswordForm = this.fb.group({
      schoolcode: ['', Validators.required],
      username: ['', Validators.required],
      usertype: ['', Validators.required]
    });
  }

  onSchoolcodeChanges(): void {
    this.subscription = this.forgetPasswordForm.get('schoolcode').valueChanges
        .pipe(
          debounceTime(500), // Wait 500ms after last keystroke
          distinctUntilChanged())
        .subscribe(instCode => {
          if (instCode.length === 5) {
            this.authService.isInstituteExist(instCode).subscribe(
              res => {
                console.log('isSchool', res)
                this.isSchoolExist = true;
              },
              err => {
                this.forgetPasswordForm.get('schoolcode').setErrors({ notFound: true });
                this.isSchoolExist = false;
              }
            );
            console.log(instCode);
            // call API here with the new form values
          }
          });
  }

  onSubmit() {
    //this.formSubmitAttempt = true;
     this.forgetPasswordForm.markAllAsTouched();
     if(this.forgetPasswordForm.invalid){
      return false;
     }
    console.log(this.forgetPasswordForm.value);
    this.isLoading = true;
    this.forgotPasswordSubscription = this.authService.forgetPassword(this.forgetPasswordForm.value).subscribe(
      (res)=> {
        const userProfile = res as UserInfo;
        if(userProfile.status == 502){
          this.toastr.success(userProfile.message);
          this.resetForm();
        }
        else{
          this.showErrorMessage(userProfile);
          // this.toastr.error(userProfile.message);
        }
        console.log('response forget password', res);
      },
      (error)=> {
        console.log('error forget password', error);
        this.isLoading = false;
      }
    );

  }

  showErrorMessage(userInfo){
    this.schoolNotFoundErrorMessage = userInfo.errorMessage || userInfo.message ;    
    this.toastr.error(this.schoolNotFoundErrorMessage);
    this.isLoading = false;
    setTimeout(()=>{
      this.schoolNotFoundErrorMessage = '';
    },10000)
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.forgotPasswordSubscription) {
      this.forgotPasswordSubscription.unsubscribe();
    }
  }
  
}
