import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs"; 
import { GradescalegroupService } from "../services/gradescalegroup.service";
import { SettingsService } from '../../mastersettings/settings.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Student } from './../../studentmanagement/student.model';
import { StudentService } from './../../studentmanagement/student.service';
import { PublishResultReportService } from "../services/publish-result-report.service";


@Component({
  selector: 'app-publish-result-report',
  templateUrl: './publish-result-report.component.html',
  styleUrls: ['./publish-result-report.component.css'],
  providers: [FormGroupDirective],
})
export class PublishResultReportComponent implements OnInit {

  submitted = false; 
  checkboxs: any;
  custom_chk: any;  
  categorydropDown: any = [];
  admissions: Student[] = [];
  finalexamNamedropDown: any = [];
  examNamedropDown: any = [];
  saveDataModel = [];
  selectedRow:number;
  examId:number;
  maxSubjectCount:number;
  subjectName:any;
  subStatus:any;
  isLoading: boolean = false;
  examWiseClassList:any=[];
  DetailsResult: any = [];
  disabled = true;
  selectedexam:string="";

  constructor(private gradeService: GradescalegroupService,
    private toastr: ToastrService,
    public fb: UntypedFormBuilder,
    public service: PublishResultReportService,
    public settingService:SettingsService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    public studentService: StudentService,
    public formDirective: FormGroupDirective) { }

    publishresultReportForm: UntypedFormGroup;
    classBatachData: any = [];

  ngOnInit(): void {
    this.publishresultReportForm = this.fb.group({
      id: [0],     
      examReportId: ["", Validators.required],
      examCategoryId: ["", Validators.required],
      examID: ["0", Validators.required],
      examClass: this.fb.array([])
    });
   
  }

  getexamReportDropDown(id) {
    this.finalexamNamedropDown = [];
    this.examWiseClassList=[];
    this.getCategoryDropDown();
    this.checkingButton();
    this.publishresultReportForm = this.fb.group({
      id: [0],
      examReportId: [this.publishresultReportForm.controls.examReportId.value, Validators.required],
      examCategoryId: ["", Validators.required],
      examID: ["0", Validators.required],
      examClass: this.fb.array([])
      }); 
  }

  getCategoryDropDown() {
    this.service.getAllCatefgoryDropDown().subscribe((data) => {
      this.categorydropDown = data;
    });
    this.checkingButton();
  }

  get f() {
    return this.publishresultReportForm.controls;
  }
  onCategoryEvent() {
   
    this.finalexamNamedropDown = [];
      this.examWiseClassList=[];    
    this.checkingButton();
    this.ListNameDropDown();   
    this.publishresultReportForm = this.fb.group({
      id: [0],
      examReportId: [this.publishresultReportForm.controls.examReportId.value, Validators.required],
      examCategoryId: [this.publishresultReportForm.controls.examCategoryId.value, Validators.required],
      examID: ["0", Validators.required],
      examClass: this.fb.array([])
      }); 
  }
    submitForm() {}
    
    
    
    ListNameDropDown() {
      let details={
        ExamCategoryId:this.publishresultReportForm.controls.examCategoryId.value
      };
      this.finalexamNamedropDown = [];
      this.examWiseClassList=[];
      this.isLoading = true;
      this.service.getExamNameList(details).subscribe((data) => {
        this.examNamedropDown = data;
        this.isLoading = false;
        this.finalexamNamedropDown = this.examNamedropDown.filter(
          (filterList: any) =>
            filterList.examCategoryId === this.publishresultReportForm.controls.examCategoryId.value
        );
      });
      this.checkingButton();
      
    }
  
  
  
    checkingButton() {
      if (
        this.publishresultReportForm.controls.examCategoryId.value &&
        this.publishresultReportForm.controls.examReportId.value &&
        this.publishresultReportForm.controls.examID.value
      ) {
        this.disabled = false;
      } 
    }    
   
    disableFreezeButton: boolean = false;
    subjectId: any;
    studentIds: any[] = [];
    
    getallClasStatuss(examid,i){
      this.isLoading = true;
      this.publishresultReportForm = this.fb.group({
        id: [0],
        examReportId: [this.publishresultReportForm.controls.examReportId.value, Validators.required],
        examCategoryId: [this.publishresultReportForm.controls.examCategoryId.value, Validators.required],
        examID: ["0", Validators.required],
        examClass: this.fb.array([])
        }); 
        if (this.publishresultReportForm.valid) {
          this.publishresultReportForm.value.examID =examid;
          this.examId=examid;
          this.selectedRow=i;          
          this.selectedexam=examid;
          let details={
            ExamCategoryId:this.publishresultReportForm.controls.examCategoryId.value,
            ExamId:examid
          };
          this.service.getClassList(details).subscribe((data) => {          
          this.examWiseClassList=data;
          const formArray: UntypedFormArray = this.publishresultReportForm.get('classBatch') as UntypedFormArray;
          if(formArray!==null)
          {
            formArray.reset();
          }
          if(this.examWiseClassList.length === 0) { 
            this.toastr.warning("Class wise marks is not assigned", "WARNING!!!", {
              timeOut: 3000,
            });
            this.isLoading = false;
          }
          this.isLoading = false;      
        });

      }
      else{
        this.toastr.warning("All field are required", "WARNING!!!", {
          timeOut: 3000,
        });
        this.isLoading = false;
        return;
      }
    }    
    enforceMinMax(el){
      // var x= document.getElementById("fullmark").innerHTML;
  
      // if(el.value != ""){
      //   if(parseInt(el.value) < parseInt(el.min)){
      //     el.value = 0;
      //   }
      //   if(parseInt(el.value) > parseInt(el.max)){
      //     el.value = 100;
      //   }
      // }
    }

    get examClass() {
      return this.publishresultReportForm.get("examClass") as UntypedFormArray;
    }

   
    assignclassBatch(item: any,data) {
      console.log(item.currentTarget.checked)
      if(item.currentTarget.checked){
        
        this.examClass.push(this.fb.group({ id: 0, examPublishId: 0,classId:data.classId,BatchId:data.id,publishStatus:true }))
      } else {
        const formArray: UntypedFormArray = this.publishresultReportForm.get('examClass') as UntypedFormArray;
        let i: number = 0;
  
        formArray.controls.forEach((ctrl: UntypedFormControl) => {
          if(ctrl.value.batchId == data.id) {
            // Remove the unselected element from the arrayForm
            formArray.removeAt(i);
            return;
          }
    
          i++;
        });
      }
      
      
    }
  
    selectAll(ev:any,item)
    {
       this.checkboxs = document.querySelectorAll('input[type="checkbox"]');
       this.custom_chk = document.getElementsByClassName('custom_chk');
      
       if(item.checked)
       {
          for(var i =0; i<this.custom_chk.length;i++ )
            {
              this.custom_chk[i].checked=false;
              item.checked=false;
              this.assignclassBatch(ev,this.custom_chk[i]);
            }
       } 
       else 
       {
          for(var i =0; i<this.custom_chk.length;i++ )
            {
              this.custom_chk[i].checked=true;
              item.checked=true;
              this.assignclassBatch(ev,this.custom_chk[i]);
            }
      }
    }

    saveRecord(){
      this.isLoading = true;
      if(this.publishresultReportForm.invalid){
        this.toastr.warning('All field are required','WARNING!!!',{timeOut:3000});
        this.isLoading = false;
        return;
      }
      this.submitted = true;
      this.publishresultReportForm.value.examID =this.examId;
      if (!this.publishresultReportForm.value.examClass || !this.publishresultReportForm.value.examClass.length) {
        this.toastr.warning('Select Atleast one classs', 'WARNING!!!', {
          timeOut: 3000
        });
        this.isLoading = false;
        return;
      }
      this.service.saveClassPublish(this.publishresultReportForm.value).subscribe(res=>{           
         this.toastr.success("Successfully pulished","SUCCESS!",{timeOut:3000});
         this.submitted=false;
         this.isLoading = false;
         this.resetForm();
         this.examWiseClassList= [];
         this.disabled = true;      
       });
    }
    
    resetForm() {
      this.submitted = false;
      this.finalexamNamedropDown = [];
      this.saveDataModel = [];
      this.formDirective.reset();
      this.publishresultReportForm.reset();
      this.disabled = true;
      this.publishresultReportForm = this.fb.group({
          id: [0],
          examReportId: ["", Validators.required],
          examCategoryId: ["", Validators.required],
          examID: ["0", Validators.required],
          examClass: this.fb.array([]),
        });
        // const formArray: FormArray = this.publishresultReportForm.get('classBatch') as FormArray;
        //   if(formArray!==null)
        //   {
        //     formArray.reset();
        //   }
    }
}
