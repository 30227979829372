import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {path: 'certificate', loadChildren: () => import('./certificate/certificate.module').then(m => m.CertificateModule)},
  {path: 'downloads', loadChildren: () => import('./downloads/downloads.module').then(m => m.DownloadsModule)},
  {path: 'audit', loadChildren: () => import('./Audit/audit.module').then(m => m.AuditModule)},
  {path: 'backupandrestore', loadChildren: () => import('./backupandrestore/backupandrestore.module').then(m => m.BackupRestoreModule)},
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }
