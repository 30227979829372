import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Attendance } from './attendance.model';

@Injectable({
    providedIn: 'root'
  })
  export class AttendanceService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<Attendance[]> {
      return this.httpClient.get<Attendance[]>(environment.apiUrl + '/studentattendance/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<Attendance> {
      return this.httpClient.get<Attendance>(environment.apiUrl + '/studentattendance/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    // Academic Year start
    getSummarizeDetails(admissionid): Observable<Attendance[]> {
      return this.httpClient.get<Attendance[]>(environment.apiUrl + '/studentattendance/GetSummarizeDetails/' + admissionid)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getAttendanceDetail(classid, batchId, monthyear): Observable<any[]> {
      return this.httpClient.get<any[]>(environment.apiUrl +
        '/studentattendance/GetAttendanceDetails/' + classid + '/' + batchId + '/' + monthyear)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    create(attendance): Observable<Attendance> {
      return this.httpClient.post<Attendance>(environment.apiUrl +
        '/studentattendance/SaveAttendance', JSON.stringify(attendance), {headers: new HttpHeaders({
          'Content-Type': 'application/json', responseType: 'text'
        })})
      .pipe(
        catchError(this.errorHandler)
      );
    }

    update(id, attendance): Observable<Attendance> {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.put<Attendance>(environment.apiUrl + '/studentattendance/' + id, JSON.stringify(attendance), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    delete(id) {
      return this.httpClient.delete<Attendance>(environment.apiUrl + '/studentattendance/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }


    GetAttendancesDateBY(AttendanceDate): Observable<any[]> {
      return this.httpClient.get<any[]>(environment.apiUrl +
        '/studentattendance/GetAttendancesDateBY/?AttendanceDate=' + AttendanceDate )
      .pipe(
        catchError(this.errorHandler)
      );
    }
    GetAttendances_Date_BY_Class_Batch(ClassId, BatchId, AttendanceDate): Observable<any[]> {
      return this.httpClient.get<any[]>(environment.apiUrl +
        '/studentattendance/GetAttendances_Date_BY_Class_Batch?AttendanceDate='+AttendanceDate+'&ClassId='+ClassId+'&BatchId='+BatchId )
      .pipe(
        catchError(this.errorHandler)
      );
    }
  }
