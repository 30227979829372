import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcademicSettingsRoutingModule } from './academicsettings.routing.module';
import { SubjectcreationComponent } from './subjectcreation/subjectcreation.component';
import { GradescaleComponent } from './gradescale/gradescale.component';
import { AssignsubjectComponent } from './assignsubject/assignsubject.component';
import {AssignbookComponent} from './assignbook/assignbook.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import{EvalutiontypeComponent} from './evalutiontype/evalutiontype.component'


@NgModule({
  declarations: [SubjectcreationComponent, GradescaleComponent, AssignsubjectComponent,
    EvalutiontypeComponent,AssignbookComponent],
  imports: [
    CommonModule,
    AcademicSettingsRoutingModule, FormsModule,
    ReactiveFormsModule,
  ]
})
export class AcademicsettingsModule { }
