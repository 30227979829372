import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, FormGroupDirective } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AssignExamMarksService } from "../../services/assign-exam-marks.service";

@Component({
  selector: "app-assign-exam-marks",
  templateUrl: "./assign-exam-marks.component.html",
  styleUrls: ["./assign-exam-marks.component.css"],
  providers: [FormGroupDirective]
})
export class AssignExamMarksComponent implements OnInit {
  submitted = false;
  categorydropDown: any = [];
  examNamedropDown: any = [];
  finalexamNamedropDown: any = [];
  examClassdropDown: any = [];
  assignExamMarksForm: UntypedFormGroup;
  assignExamMarksAForm: UntypedFormGroup;
  assignExamMarksSectionAForm: UntypedFormGroup;
  assignExamMarksList: any = [];
  assignExamMarksSectionAList: any = [];
  assignExamMarksSectionBList: any = [];
  copyFullMarkInput: boolean;
  copyPassMarkInput: boolean;
  copyConvertedToInput: boolean;
  disabled: boolean = true;
  classBatachData: any = [];
  classDropDown: any = [];
  length:any;


  @ViewChild('passMarks') passMarks;
  @ViewChild('converted') convertedToMarks;
  @ViewChild('convertedToA',{static:false, read: ElementRef}) convertedToMarksA;
  constructor(
    private toastr: ToastrService,
    public fb: UntypedFormBuilder,
    public service: AssignExamMarksService,
    public formDirective: FormGroupDirective
  ) {
    
  }

  ngOnInit(): void {
    this.assignExamMarksForm = this.fb.group({
      id: [0],
      examCategory: ["", Validators.required],
      examName: ["", Validators.required],
      classId: ["", Validators.required],
    });
    this.assignExamMarksAForm = this.fb.group({
      capabilities: this.fb.array([])
    });

    this.getCategoryDropDown();
  
  }
  get f() {
    return this.assignExamMarksForm.controls;
  }
  getCategoryDropDown() {
    this.service.getAllCatefgoryDropDown().subscribe((data) => {
      this.categorydropDown = data;
    });
  }
  onCategoryEvent() {
    this.assignExamMarksForm.value.examName = "";
    this.assignExamMarksForm.value.classId="";
    this.finalexamNamedropDown = [];
    this.examClassdropDown = [];
    this.classDropDown=[];
    this.assignExamMarksList = [];
    this.getNameDropDown();
  }

  getNameDropDown() {
    this.service.getExamNameList().subscribe((data) => {
      this.examNamedropDown = data;
      this.finalexamNamedropDown = this.examNamedropDown.filter(
        (filterList: any) =>
          filterList.examCategoryId ===
          this.assignExamMarksForm.value.examCategory
      );
    });
  }

  // getClassDropDown() {
  //   this.service
  //     .getAllClass(this.assignExamMarksForm.value.examName)
  //     .subscribe((data) => {
  //         this.examClassdropDown = data;
  //     });
      
  // }
  // selectedClass(){
  //   if(this.assignExamMarksForm.value.classId !== '') {
  //      this.disabled = false;
  //   }
  // }

  getClassDropDown(){

    this.service
      .getAllClass(this.assignExamMarksForm.value.examName)
      .subscribe((data) => {
        this.assignExamMarksList = [];
        this.examClassdropDown = data;
        this.classDropDown = [
          ...new Map(
            this.examClassdropDown.map((item) => [item["className"], item])
          ).values(),
        ];
        //this.marksEntryList = [];
        // this.checkingButton();
      });
    }
  
  selectedClass(id) {
    
    let batchList = [];
    this.assignExamMarksList = [];
    if (this.assignExamMarksForm.value.classId !== "") {
      batchList = this.examClassdropDown.filter((element) => element.id == id);
      this.classBatachData = batchList;
      // this.checkingButton();
    }
  }

  getAssignExamsMarksList() {
    // if (this.scheduleForm.invalid) {
    //   return;
    // }
    if (this.assignExamMarksForm.invalid) {
      this.toastr.warning("All field are required", "WARNING!!!", {
        timeOut: 3000,
      });
      return;
    }
    let details=[{
      categoryid:this.assignExamMarksForm.value.examCategory,
      examid:this.assignExamMarksForm.value.examName,
      classid:this.assignExamMarksForm.value.classId
      
    }]
    this.service
      .getAssignExamMarksList(details)
      .subscribe((data) => {
        let batchList = [];
        let newList:any = [];
        this.assignExamMarksList = [];
        newList = data; ({ a, b }) => ({a, b})
        if(newList.length>0)
        {            
            batchList = newList.map(({ batchName, className }) =>({batchName, className}));
            batchList = this.filterbatchlist(batchList);
            batchList.map((data)=>{
              this.assignExamMarksList.push({label: data,value: newList.filter( sectionA => sectionA.batchName === data.batchName)});
            });
            this.assignExamMarksSectionAList = this.assignExamMarksList.filter( sectionA => sectionA.batchName === 'A');
            this.assignExamMarksForm.setControl('capabilities',
              this.fb.array(
                this.assignExamMarksList.map(datum => this.generateDatumFormGroup(datum.value,'formA')) 
              )
            );
        
            // this.assignExamMarksSectionBList = this.assignExamMarksList.filter( sectionA => sectionA.batchName === 'B');
            // console.log(this.assignExamMarksSectionBList ,'this.assignExamMarksSectionBList ')

            // this.assignExamMarksAForm = this.fb.group({
            //   capabilities: this.fb.array(
            //     this.assignExamMarksSectionBList.map(datum => this.generateDatumFormGroup(datum,'formB')) 
            //   )
            // });
            this.copyFullMarkInput = false;
            this.copyPassMarkInput = false;
            this.copyConvertedToInput = false;
        }
        else
        {
          console.log(data)
          this.toastr.warning('subject not assign ! Please assign the subject','WARNING!!!',{timeOut:3000});
          return;
        }
      
      });
      
  }

  filterbatchlist(batchList){
    let flags = {};
let newPlaces = batchList.filter(function(data) {
    if (flags[data.batchName]) {
        return false;
    }
    flags[data.batchName] = true;
    return true;
});
return newPlaces;
  }
  
  get studentsArray(): UntypedFormArray {
    return this.assignExamMarksAForm.get('capabilities') as UntypedFormArray;
  }

  private generateDatumFormGroup(datum,form) {
    let formData:any;
      formData = this.fb.group({
        fullMarks: this.fb.control({ value: datum.fullMarks}),
        passMarks: this.fb.control({ value: datum.passMarks }),
        convertedTo: this.fb.control({ value: datum.convertedTo }),
      });
      return formData;

    }

  submitForm() {}

  submitAssignMarks() {
    let assignExamMarksList = [];
    this.assignExamMarksList.map((x,i) => assignExamMarksList = assignExamMarksList.concat(...x.value));
    let temArr=[];
    for(let m of assignExamMarksList){
      m.examCategoryId = this.assignExamMarksForm.value.examCategory;
      m.examId = this.assignExamMarksForm.value.examName;
      m.classId =this.assignExamMarksForm.value.classId; 
      m.assignSubjectId = m.assignSubjectId;
      if(m.fullMarks){
        temArr.push(m)
      }
    }
    this.service.saveAssignMArks(temArr).subscribe(res=>{           
      this.toastr.success("Successfully created","SUCCESS!",{timeOut:3000});
      this.submitted=false;
      this.resetForm();
      assignExamMarksList= [];
      this.assignExamMarksList= [];
      this.disabled = true;      
    });    
  }

  resetForm(){
    this.submitted = false;
    //this.finalexamNamedropDown = [];
    //this.examClassdropDown = [];
    //this.formDirective.reset();
    //this.assignExamMarksForm.reset();
  }

  copyFullMarks(fullMarks){
    this.copyFullMarkInput = true;
    this.assignExamMarksList.forEach(element => {
      element.value.map((data)=> data.fullMarks = fullMarks.value)
    });
  }

  copyPassMarks(passMarks){
    this.copyPassMarkInput = true;
    this.assignExamMarksList.forEach(element => {
      element.value.map((data)=> data.passMarks = passMarks.value)
    });
  }

  convertedTo(convertedTo){
    this.copyConvertedToInput = true;
    this.assignExamMarksList.forEach(element => {
      element.value.map((data)=> data.convertedTo = convertedTo.value)
    });
  }

  validationMarks(fullMarks?,passMarks?){
    if(passMarks > fullMarks){
      this.toastr.warning("Pass Marks should be less than Full Marks", "WARNING!!!", {
        timeOut: 3000,
      });
      return '';
    }
  }

  valiDation(fullMarks?,passMarks?,inputFiled?){
    fullMarks =  parseInt(fullMarks);
    passMarks =  parseInt(passMarks);
    if((passMarks > fullMarks) && inputFiled === 'passMarks'){
      this.toastr.warning("Pass Marks should be less than Full Marks", "WARNING!!!", {
        timeOut: 3000,
      });
      this.passMarks.nativeElement.value = '';
    }
    if((passMarks > fullMarks) && inputFiled === 'convertedTo'){
      this.toastr.warning("Converted To should be less than Full Marks", "WARNING!!!", {
        timeOut: 3000,
      });
      this.convertedToMarks.nativeElement.value = '';
    }
    if((passMarks > fullMarks) && inputFiled === 'convertedToA'){
      this.toastr.warning("Converted To should be less than Full Marks", "WARNING!!!", {
        timeOut: 3000,
      });
      this.convertedToMarksA.nativeElement.value = '';
    }
  }
}
