import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../notification/notification.service';
import { BroadcastStatus, Notification } from '../../notification/notification.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-activitylog',
  templateUrl: './activitylog.component.html',
  styleUrls: ['./activitylog.component.css']
})
export class ActivitylogComponent implements OnInit {

  notifications: Notification[] = [];
  broadcastStatusList: BroadcastStatus[] = [];

  constructor(public notificationService: NotificationService) { }


  ListNotification() {
    this.notificationService.getAll().subscribe((data: Notification[]) => {
      this.notifications = data;
    });
  }

  ListBroadcastStatus(broadcastId: number) {
    this.notificationService.getBroadcastStatusById(broadcastId).subscribe((data: BroadcastStatus[]) => {
      this.broadcastStatusList = data;
    });
  }

  ngOnInit(): void {
    this.ListNotification();
  }

}
