import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AttendanceService } from '../attendance.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-attendancereport',
  templateUrl: './attendancereport.component.html',
  styleUrls: ['./attendancereport.component.css']
})
export class AttendancereportComponent implements OnInit {
  
  attendancelist: any[];
  mymodel:any=''; 
  todayDate: string;
  @ViewChild('OpenPopow', {static:false}) OpenPopow: ElementRef;
  detialAttendance: any=[];
  AttendancelistDetial: any[]=[];
  isLoading=false;
  constructor(private attendanceService: AttendanceService,public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.isLoading=true;
    let currentDateTime =this.datepipe.transform((new Date), 'dd/MMM/yyyy');
    this.todayDate= moment().format('DD-MM-yyyy').toString();
    
    this.binddata(currentDateTime);
    this.isLoading=false;
  }

  onSearchChange(searchValue: string): void {  
    this.binddata(searchValue);
     
  }
 
  binddata(AttendanceDate: string) {
   
    this.attendanceService.GetAttendancesDateBY(AttendanceDate).subscribe((data: any[]) => {
      this.attendancelist= data;

      
    });
  }


  getrecord(list)
  {
    let el: HTMLElement = this.OpenPopow.nativeElement as HTMLElement;
      el.click();
      this.detialAttendance=list;
      this.attendanceService.GetAttendances_Date_BY_Class_Batch(list.classId, list.id, this.todayDate).subscribe((data: any[]) => {
        this.AttendancelistDetial= data;
        console.log(data);
      });
  }
}
