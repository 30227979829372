import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Institution, InstitutionDetail, InstitutionBranch } from '../../institutionmanagement/models/institution.model';
import { InstitutionService } from '../../institutionmanagement/newinstitution/institution.service';
import { Country} from '../../institutionmanagement/models/country';
import { State} from '../../institutionmanagement/models/state';
import { City} from '../../institutionmanagement/models/city';
import { SenderIDRequest } from './senderid.model';
import { SenderIDApprovalService } from './senderid-approval.service';

@Component({
  selector: 'app-senderidapproval',
  templateUrl: './senderidapproval.component.html',
  styleUrls: ['./senderidapproval.component.css']
})
export class SenderidapprovalComponent implements OnInit {

  institutions: Institution[];
  filteredInstitutions: Institution[];
  institutionBranchess: InstitutionBranch[];

  countries: Country[] = [];
  states: State[] = [];
  cities: City[] = [];
  countryId = 'Country';
  stateId = 'State';
  cityId = 'City';
  requestStatus = '';
  remarks = '';
  senderRequestId = '';
  institutionCode = '';
  submitted = false;


  constructor(
    public institutionService: InstitutionService,
    private requestService: SenderIDApprovalService,
    private toastr: ToastrService
  ) { }

  ListInstitutions() {
    this.institutionService.getAllInstitutionSenderIdRequest().subscribe((data: Institution[]) => {
      console.log(data);
      this.filteredInstitutions = this.institutions = data;
    });
  }

  ListCountry() {
    this.institutionService.getAllCountry().subscribe((data: Country[]) => {
      console.log(data);
      this.countries = data;
    });
  }

  onCountrySelected(value: string) {
    if (value !== 'Country') {
      this.institutionService.getAllState().subscribe((data: State[]) => {
        this.states = data.filter((state: State) => state.countryId.toString() === value.toString());
      });
    }
  }

  onStateSelected(value: string) {
    console.log(value);
    if (value !== 'State') {
      this.institutionService.getAllCity().subscribe((data: City[]) => {
        this.cities = data.filter((city: City) => city.stateId.toString() ===  value.toString());
      });
    }
  }

  onCitySelected(value: string) {
    console.log(value);
    this.filteredInstitutions = this.institutions;
    if (value !== 'City') {
      this.filteredInstitutions = this.filteredInstitutions.filter((x) => x.cityId.toString() === value);
    }
  }

  onInstitutionChange(id: any) {
    this.filteredInstitutions = this.institutions;
    this.filteredInstitutions = this.filteredInstitutions.filter((x) => x.id === id);
  }

  search(value: string) {
    this.filteredInstitutions = this.institutions;
    if (value !== '') {
      this.filteredInstitutions = this.filteredInstitutions.filter((x) => x.institutionCode.toLowerCase()
      .includes(value.toLowerCase()));
    }
  }

 onApprovalOpen(value: string, institutionCode: string) {
    console.log(value);
    this.senderRequestId = value;
    this.institutionCode = institutionCode;
 }



  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  updateRequest() {
     this.submitted = true;
     if (this.requestStatus === '' ||  this.remarks === '') {
       return;
     }

     const request: SenderIDRequest = {} as SenderIDRequest;
     request.id = Number(this.senderRequestId);
     request.institutionCode = this.institutionCode;
     request.approvedDate = this.formatDate(new Date());
     request.requestStatus = this.requestStatus;
     request.remarks = this.remarks;
     console.log(request);
     this.requestService.update(request).subscribe(res => {
        console.log('Sender Id request updated!');
        this.ListInstitutions();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
           // this.resetForm();
        this.requestStatus = '';
        this.remarks = '';
     });
  }

  ngOnInit(): void {
    const self = this;
    $('.select2').select2();
    $('.select2').on('change', function() {
      self.onInstitutionChange(Number($(this).val()));
    });
    this.ListInstitutions();
    this.ListCountry();
  }

}
