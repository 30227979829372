import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ExamSchedulingComponent } from './exam-scheduling/exam-scheduling.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
const routes: Routes = [
  {
    path:'ExamScheduling/examscheduling', component: ExamSchedulingComponent
  }
];

@NgModule({
  declarations: [ExamSchedulingComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ExamSchedulingModule { }
