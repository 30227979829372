import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FeeMaster } from './feecollection.model';

@Injectable({
    providedIn: 'root'
  })
  export class FeeCollectionService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         enctype: 'multipart/form-data'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<FeeMaster[]> {
      
      return this.httpClient.get<FeeMaster[]>(environment.apiUrl + '/feecollection/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<FeeMaster> {
      return this.httpClient.get<FeeMaster>(environment.apiUrl + '/feecollection/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getRecieptsByStudentId(studentid): Observable<FeeMaster[]> {
      return this.httpClient.get<FeeMaster[]>(environment.apiUrl + '/feecollection/GetRecieptsByAdmissionId/' + studentid)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    cancel(id, reason): Observable<any> {
      return this.httpClient.get<any>(environment.apiUrl + '/feecollection/CancelReceipt/' + id + '/' + reason)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    create(feehead): Observable<any> {
      console.log("-------------------------------------------");
      return this.httpClient.post(environment.apiUrl + '/feecollection/', feehead)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    update(id, feehead): Observable<FeeMaster> {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.put<FeeMaster>(environment.apiUrl + '/feecollection/' + id, JSON.stringify(feehead), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    delete(id) {
      return this.httpClient.delete<FeeMaster>(environment.apiUrl + '/feecollection/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }

  }
