export interface IPreFineConditionType {
    id: number,
    name: string
}

export enum PreFineConditionType {
    'OneTime' = 1,
    'Repeat (with max fine)' = 2,
    'Repeat (without max fine)' = 3,
    'Repeat With Conditions(with max fine)' = 4,
    'Repeat With Conditions(without max fine)' = 5
}