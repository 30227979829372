export class SummaryReportVm {
  totalCourse: number;
  totalSubject: number;
  completedSession: number;
  pendingSession: number;

   
    
  }

 
 

