import { Component, DebugElement, OnInit, ViewChild } from '@angular/core';
import { ClassCourse } from '../../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../../mastersettings/models/batch';
import { BatchService } from '../../../mastersettings/batch/batch.service';
import { StudentInstallmentSummaryService } from './studentinstallmentsummary.service';
import { StudentInstallmentSummaryModel } from './studentinstallmentsummary.model';
import { Student } from './../../../studentmanagement/student.model';
import { StudentService } from './../../../studentmanagement/student.service';
import { SettingsService } from '../../,,/../../mastersettings/settings.service';
import { Academicyear } from '../,,/../../../mastersettings/models/academicyear';
import { StudentPaymentReportService } from '../studentpaymentreport/studentpaymentreport.service';
import { FilterModel, StudentDetailsModel, StudentInStallmentDetailsModel, FeeReceiptModel } from './../studentinstallmentsummary/studentinstallmentsummary.model';
import jspdf, { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { environment } from 'src/environments/environment';
import { NgSelectComponent } from '@ng-select/ng-select';
@Component({
  selector: 'app-studentinstallmentsummary',
  templateUrl: './studentinstallmentsummary.component.html',
  styleUrls: ['./studentinstallmentsummary.component.css']
})
export class StudentinstallmentsummaryComponent implements OnInit {
  classDrp: any = "0";
  batchDrp: any = "0";
  studentDrp: any = "0";
  academicDrp: any = "3";
  admissionNo: any;
  sumTotal: number;
  sumDiscount: number;
  sumPaid: number;
  dataCount: any;
  isHidden: boolean = true;
  isLoading: boolean = false;
  studentName: string = "---";
  class: string = "---";
  rollNo: string = "---";
  fathersName: string = "---";
  mobileNo: string = "---";
  academicName:string="---";
  admissionNumber: string = "---";
  academicYears: Academicyear[] = [];
  studentInstallmentSummary: StudentInstallmentSummaryModel[] = [];
  studentInstallmentSummaryFilteredList: StudentInstallmentSummaryModel[] = [];
  studentInstallmentSummaryTemp: StudentInstallmentSummaryModel[] = [];
  studentDetailsList: StudentDetailsModel[] = [];
  studentDetailsFilteredList: StudentDetailsModel[] = [];
  studentInStallmentDetailsList: StudentInStallmentDetailsModel[] = [];
  studentInStallmentDetailsFilteredList: StudentInStallmentDetailsModel[] = [];
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  filterValues: StudentInstallmentSummaryModel[] = [];
  currentAcademicYearId: number;
  institution: any;
  institutionName: any;
  address: any;
  logoUrl: any;
  export: boolean=false;
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  constructor(
    public studentInstallmentSummaryService: StudentInstallmentSummaryService,
    public classCourseService: ClassCourseService,
    public batchService: BatchService,
    public studentService: StudentService,
    public settingsService: SettingsService,
    public studentPaymentReportService: StudentPaymentReportService

  ) { }
  listStudents() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.admissions = data;
    });
  }
  listClassCourse() {
    this.classCourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }
  listAcademicYear() {
    this.settingsService.getAll().subscribe((data: Academicyear[]) => {
      
      this.academicYears = data;
      //this.academicDrp=3;
      this.currentAcademicYear();
    });
    
  }

  clearForm()
  {
    this.studentDrp = "0";
    this.onDrpFilter();
    this.admissionNo = null;
    this.classDrp = "0";    
    this.studentDrp = "0";        
    this.batchDrp="0";
    this.admissions=[];
    
  }
  onClassSelected(value: string) {
    
    this.classDrp = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => (batch.classId.toString().toLocaleLowerCase()) === (value.toLocaleLowerCase()));
    });
    this.studentDrp = "0";
    this.onDrpFilter();
    this.admissionNo = null;
  }
  currentAcademicYear() {

    this.settingsService.getCurrentAcademicYear().subscribe((data: Academicyear) => {

      this.academicDrp = data.id.toString();
      this.onBatchChanged(this.academicDrp);
    });

  }

  onBatchChanged(value: string) {
    
    this.ngSelectComponent.handleClearClick();
    //this.studentDrp = "0";
    this.admissions =[];
    this.studentService.getAll().subscribe((data: Student[]) => {
      // this.ListFeeHead();
        this.admissions = data.filter((student: Student) => {
        return student.classCourseId.toString() === this.classDrp &&
          student.batchId.toString() === value.toString();
      //  return student.academicYearId == value;

      });
      /*this.admissions=data;
      console.log("admission batch",this.admissions)*/
    });
   
   // this.onDrpFilter();
  }
  onAcademicYearChanged(id: number) {
    
    this.resetDropDowns();
    if(id!=undefined || id!=null){
      this.academicDrp = id.toString();
      this.onBatchChanged(id.toString());
      //this.onDrpFilter();
    }
   
  }
 /* onStudentChanged(id: number) {
    this.onDrpFilter();
  }*/
  onStudentChanged(selected){
    
    this.studentDrp=selected;
    if(this.admissions.length!=0){
      this.onDrpFilter();
    }

  }

  resetDropDowns(){
    this.classDrp = "0";    
    this.studentDrp = "0";        
    this.batchDrp="0";
    this.admissions=[];
    
  }
  onSearchAdmissionNumber() {
    
   


    this.classDrp = "0";    
    this.studentDrp = "0";        
    this.batchDrp="0";
    //this.onClassSelected("0");
    const admission = this.admissionNo;
    if (admission != "0") {
      this.onDrpFilter();
    }
    this.listAcademicYear();

  }
  onDrpFilter() {
    this.sumTotal = 0;
    this.sumDiscount = 0;
    this.sumPaid = 0;
    this.studentName = "---";
    this.class = "---";
    this.rollNo = "---";
    this.fathersName = "---";
    this.mobileNo = "---";
    this.admissionNumber = "---";
    this.isHidden = true;
    const classSelected = this.classDrp || null;
    //const classSelected = this.admissions.filter(x=>x.id==this.studentDrp)[0].classCourseId || null;
    //const classSelected =  null;
    const batchSelected = this.batchDrp || null;
    const studentSelected = this.studentDrp || null;
    const academicYearSelected = this.academicDrp || null;
    const admissionNumber = this.admissionNo || null;
    if (classSelected != "0" && batchSelected != "0" && studentSelected != "0" && academicYearSelected != "0") {
      //this.getStudentDetails(classSelected, batchSelected, studentSelected, academicYearSelected, admissionNumber);
      this.getStudentDetails(classSelected, batchSelected, studentSelected, academicYearSelected, admissionNumber);
    }
    else if (admissionNumber !=null && admissionNumber !=""  && admissionNumber != "0" && admissionNumber != "0" && academicYearSelected != "0") {
      //this.getStudentDetails(0, 0, 0, academicYearSelected, admissionNumber);
      this.getStudentDetails(0, 0, 0, academicYearSelected, admissionNumber);
    }
    /*if(studentSelected!='0' && studentSelected!=undefined){
      //this.getStudentDetails(8, 0, studentSelected, academicYearSelected, studentSelected);
      this.getStudentDetails(classSelected, 0, studentSelected, academicYearSelected, studentSelected);
    }*/
  }
  getStudentDetails(classSelected, batchSelected, studentSelected, academicYearSelected, admissionNumber) {

    const formData = {} as FilterModel;
    formData.classId = classSelected.toString();
    formData.batchId = batchSelected.toString();
    formData.studentId = studentSelected.toString();
    formData.academicYearId = academicYearSelected.toString();
    formData.admissionNumber = admissionNumber || "0";
    //this.filterValues=[classSelected,batchSelected,studentSelected,academicYearSelected,admissionNumber]
    const self = this;
    //   this.studentInstallmentSummaryService.getStudentDetails(formData).subscribe((data: StudentDetailsModel[]) => {
    //    this.studentDetailsFilteredList=this.studentDetailsList  = data;
    //     this.studentName=data[0].studentName;
    //     this.class=data[0].class;
    //     this.rollNo=data[0].rollNo;
    //     this.admissionNumber=data[0].admissionNo;
    //     this.fathersName=data[0].fathersName
    //     this.mobileNo=data[0].mobileNumber
    // });
    this.isHidden = false;
    this.getStudentInstallmentSummary(formData);
  }

  studentDetailsInstallmentList: any;
  studentList: any;

  getStudentInstallmentSummary(formData) {
    
    this.isLoading = true;
    this.studentInstallmentSummaryService.getStudentInstallmentSummary(formData).subscribe((data: StudentInStallmentDetailsModel[]) => {
      this.studentInStallmentDetailsFilteredList = this.studentInStallmentDetailsList = data['table'];

      this.studentDetailsInstallmentList = data['table1'];
      
      this.studentDetailsInstallmentList.forEach(element => {
        element.detailslist = JSON.parse(element.jsondetails)
      });
      this.isLoading = false;
     
      this.studentList = this.studentInStallmentDetailsFilteredList.filter(x => x.studentName)[0];
      this.studentName = this.studentList.studentName;
      this.class = this.studentList.class;
      this.rollNo = this.studentList.rollNo;
      this.admissionNumber = this.studentList.admissionNo;
      this.fathersName = this.studentList.fatherName
      this.mobileNo = this.studentList.mobileNo
      this.academicName = this.studentList.academicId
      this.sumTotal = 0;
      this.institution = JSON.parse(localStorage.getItem('Institution'));
      if(this.institution.logoUrl !== null ) {
        this.institution.logoUrl = environment.imageUrl + this.institution.logoUrl;
      } else if (this.institution.logoUrl === null) {
        this.institution.logoUrl = 'assets/images/SchoolLogo/demologo.png';
      }
      this.logoUrl = this.institution.logoUrl;
      this.institutionName = this.institution.institutionName;
      this.address = this.institution.address;
      this.export=true;
      // this.sumPaid = data['table'].map(item => parseInt(item.totalPaidAmount)).reduce((prev, curr) => prev + curr, 0);
      // this.sumDiscount = data['table'].map(item => parseInt(item.discount)).reduce((prev, curr) => prev + curr, 0);
      // this.arrayInstallmentList = [];
      // this.studentInStallmentDetailsList.forEach(item=>{
      //   this.temp = item;
      //   this.filteredInstallmentList = [];
      //   this.temp.id.split(',').forEach(element => {
      //       this.filteredInstallmentList.push(this.studentDetailsInstallmentList.filter(x=>x.installmentId==element)[0]);
      //     });
      //    this.arrayInstallmentList.push(this.filteredInstallmentList.filter(x => x != undefined).map(x => x.installmentName).join(', '));
      //});
      // this.arrayInstallmentList.push(this.studentDetailsInstallmentList.map(x => x.installmentName).join(', '));
      // this.arrayInstallmentListPdf.push(this.studentDetailsInstallmentList.map(x => x.installmentName).join(', '));
      // this.arrayInstallmentListExcel.push(this.studentDetailsInstallmentList.map(x => x.installmentName).join(', '));
    });
  }
  public convet11ToPDF() {
   

    
    let DATA: any = document.getElementById('pdfAreaToExport');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jspdf('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('studentinstallementsummary.pdf');
    });
  }

  convetToPDF() {

    const div = document.getElementById('pdfAreaToExport');
    const options = {
      background: 'white',
      scale: 3
    };

    html2canvas(div, options).then((canvas) => {

      var img = canvas.toDataURL("image/PNG");
      var doc = new jsPDF('l', 'mm', 'a4');

      // Add image Canvas to PDF
      const bufferX = 5;
      const bufferY = 5;
      const imgProps = (<any>doc).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

      return doc;
    }).then((doc) => {
      doc.save('StudentInstallementSummary.pdf');  
    });
  }



  customSearchFn(term: string, item) {
    item.name = item.name.replace(',','');
        term = term.toLocaleLowerCase();
        return item.name.toLocaleLowerCase().indexOf(term) > -1;
}
  ngOnInit(): void {
    this.listClassCourse();
    this.listAcademicYear();

   const self = this;
   $('.select2').select2();
   $('.select2').on('change', function () {
    self.studentDrp=$(this).val().toString();
     self.onStudentChanged(parseInt($(this).val().toString()));
   });
  }

}
