import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ShiftService } from './../period/shift.service';
import { PeriodService } from './../period/period.service';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Period, Shift } from './../period/period.model';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Room } from '../../mastersettings/models/room';
import { RoomService } from '../../mastersettings/room/room.service';
import { SubjectCreation,ReportAttribute } from '../../academicsettings/subjectcreation/subjectcreation.model';
import { SubjectcreationService } from '../../academicsettings/subjectcreation/subjectcreation.service';
import { TimeTable, SubjectViewModel, TimeTableViewModel, PeriodViewModel } from '../timetable/timetable.model';
import { TimeTableService } from '../timetable/timetable.service';
import { environment } from '../../../../../environments/environment';
import { EmployeeadmissionService } from 'src/app/admin/OperationActivity/hrmspayroll/hrms/employeeadmission.service';
import { Employeeadmission } from 'src/app/admin/OperationActivity/hrmspayroll/hrms/models/employeeadmission.model';
import { employeeData } from 'src/app/admin/OperationActivity/financeaccount/sync-ledger/sync-ledger.component';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import {  DatePipe, formatDate } from '@angular/common';
import { SettingsService } from '../../mastersettings/settings.service';
import { Academicyear } from '../../mastersettings/models/academicyear';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  @ViewChild('tblclasswiseReport', { static: false }) pdfTable: ElementRef;

  timetableForm: UntypedFormGroup;
  classcourses: ClassCourse[];
  employeeList: Employeeadmission[];
  batches: Batch[] = [];
  shifts: Shift[] = [];
  periods: Period[] = [];
  times: any[] = [];
  rooms: Room[] = [];
  subjects: SubjectCreation[] = [];
  classcourseid = '-1';
  batchid = '-1';
  shiftid = '-1';
  roomId = '-1';
  subjectId = '-1';
  teacherId = '-1';
  submitted = false;
  subjectname: SubjectViewModel[][] = [];
  timetableType = false;
  attributes: ReportAttribute[] = [];
  headerList: string[] = [];
  fieldList: string[] = [];
  dateFrom: any;
  dateTo: any;
  institution: any;
  currentAcademicYearId: number;
  currentAcademicYearName = '';
  timeTableDetail = '';
  academicYear:Academicyear[]=[];

  constructor(
    public shiftService: ShiftService,
    public periodService: PeriodService,
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    private timeTableService: TimeTableService,
    private employeeServie: EmployeeadmissionService,
    private roomService: RoomService,
    private subjectService: SubjectcreationService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public settingsService:SettingsService
  ) { }

  EmployeeList() {
    this.employeeServie.getAll().subscribe((data: Employeeadmission[]) => {
      console.log(data);
      this.employeeList = data;
    });
  }

  ListClass() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      console.log(data);
      this.classcourses = data;
    });
  }

  ListShift() {
    this.shiftService.getAll().subscribe((data: Shift[]) => {
      console.log(data);
      this.shifts = data;
    });
  }

  onClassSelected(value: string) {
    // this.filteredbatches = this.batches;
    this.batches = [];
    this.periods = [];
    this.times = [];
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) =>
        batch.classId.toString() === value.toString());
    });

    this.periodService.getAll().subscribe((data: Period[]) => {
        this.periods=[];
        data.filter((period: Period) =>
        period.classId.toString() === value.toString()).forEach((x:Period)=>{
          if(this.periods.filter(p=>p.periodName==x.periodName).length==0){
            this.periods.push(x);
          }
      });
    });
    // this.subjectService.getAll().subscribe((data: subjectcreation[]) => {
    //   this.subjects = data.filter((subject: subjectcreation) =>
    //   subject.classCourseId.toString() === value.toString());
    // });
  }

  onShiftChange(value: number) {
    const classId = this.classcourseid !== '-1' ? this.classcourseid : '0';
    const batchId = this.batchid !== '-1' ? this.batchid : '0';
    const tId = this.teacherId !== '-1' ? this.teacherId : '0';
    console.log("classId", classId);
console.log("batchId", batchId);
console.log("value", value);
console.log("tId", tId);

    this.timeTableService.getPeriodDetail(classId, batchId, value, tId).subscribe((data: any[]) => {
      console.log(data);
      const periodList: TimeTableViewModel[] = [];
      const periodTable = data['Table'];
      const timeTableObj = data['Table1'];
      periodTable.forEach((element: any) => {
        const x2 = Object.values(element);
        const weekName = x2[0];
        const timeObjList: PeriodViewModel[] = [];
        // 
        // x2.forEach((x) => {
        timeTableObj.forEach((element1: any) => {
          if (weekName === element1.WeekName) {
            const timeObj: PeriodViewModel = {} as PeriodViewModel;
            timeObj.periodId = element1.PeriodId;
            timeObj.subjectName = element1.SubjectName;
            timeObj.teacherName = element1.EmployeeName;
            timeObj.periodTime = element1.PeriodTime;
            timeObj.roomName = element1.RoomName;
            timeObj.ClassCourse = element1.ClassCourse;
            timeObj.Batch = element1.Batch;
            timeObjList.push(timeObj);
          }
        });
        const periodObj: TimeTableViewModel = {} as TimeTableViewModel;
        periodObj.weekName = weekName.toString();
        //debugger;
        periodObj.periodDetails = timeObjList;
        periodList.push(periodObj);
        // });
      });
      // console.log(periodList);
      ;
      this.times = periodList;
    });
    // this.subjectService.getAllByClassBatch(parseInt(classId, 0)).subscribe((data: SubjectCreation[]) => {
    //   this.subjects = data;
    // });
  }

  onTimeTableTypeChange(value: string) {

    this.times = [];
    this.periods = [];
    this.ListShift();
    this.batches = [];
    this.classcourseid = '-1';
    this.batchid = '-1';
    this.shiftid = '-1';
    this.roomId = '-1';
    this.subjectId = '-1';
    this.teacherId = '-1';

    if (value === '1') {
      this.timetableType = false;
      this.ListClass();
    } else if (value === '2') {
      this.EmployeeList();
      this.periodService.getAll().subscribe((data: Period[]) => {
        this.periods=[];
        data.forEach((x:Period)=>{
          if(this.periods.filter(p=>p.periodName==x.periodName).length==0){
            this.periods.push(x);
          }
      });
      });
      this.timetableType = true;
    }
  }

  ngOnInit(): void {
    this.ListClass();
    this.ListShift();
    this.periodService.getAll().subscribe((data: Period[]) => {
      data.forEach((x:Period)=>{
          if(this.periods.filter(p=>p.periodName==x.periodName).length==0){
            this.periods.push(x);
          }
      });
    });
    this.GetCurrentAcademicYear();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
  }
  GetCurrentAcademicYear() {
    this.settingsService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYear = data;
      this.currentAcademicYear();
    });
  }
  currentAcademicYear() {
    this.settingsService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.currentAcademicYearName = this.academicYear.find(x => x.id.toString() === data.id.toString()).name;
    });
  }
  fileName = 'ExcelSheet.xlsx';
  exportexcel(): void {
    ;
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }

    generateAttributes() {
      this.attributes = [];
      this.timeTableDetail = this.timetableType?("TEACHER NAME: "+this.times[0].periodDetails[0].teacherName+
      "\t SHIFT: "+this.shifts.filter(x=>x.id.toString()==this.shiftid.toString())[0].shiftName):
      ("CLASS: "+this.classcourses.filter(x=>x.id.toString()==this.classcourseid.toString())[0].classCourseName+
      "\t BATCH: "+this.batches.filter(x=>x.id.toString()==this.batchid.toString())[0].batchName+
      "\t SHIFT: "+this.shifts.filter(x=>x.id.toString()==this.shiftid.toString())[0].shiftName);
      let data = {
        "name":"WEEK DAYS",
        "fieldName":"weekdays"
      }
      this.attributes.push(data);
      for(var i=0;i<this.periods.length;i++){
        let data = {
          "name":this.periods[i].periodName,
          "fieldName":this.periods[i].periodName
        }
        this.attributes.push(data);
      }
    }
    defaultArray = [];
    public formatDate(date) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) { month = '0' + month; }
      if (day.length < 2) { day = '0' + day; }
      return [day, month, year].join('-');
    }
    convetToPDF() {
      this.generateAttributes();
      this.headerList = [];
      this.fieldList = [];
      
      this.attributes.forEach(x => {
        this.headerList.push(x.name);
        this.fieldList.push(x.fieldName);
      });
      
      const widthList = [];
      for (const item of this.headerList) {
          widthList.push('17%');
      }
      this.defaultArray = [];
      for(var i=0;i<this.times.length;i++){
          const data = new Map<string, string>()
          data.set("weekdays",this.times[i].weekName);
          for(var j=0;j<this.periods.length;j++){
           // debugger;
            var dataColumn = this.times[i].periodDetails[j].periodTime==undefined?"":this.times[i].periodDetails[j].periodTime;
            dataColumn +=" \n "+(this.times[i].periodDetails[j].subjectName==undefined?"":this.times[i].periodDetails[j].subjectName);
            dataColumn +=" \n "+(this.times[i].periodDetails[j].teacherName==undefined?"":this.times[i].periodDetails[j].teacherName);
            dataColumn +=" \n "+(this.times[i].periodDetails[j].roomName==undefined?"":this.times[i].periodDetails[j].roomName);
            if(this.timetableType){
              dataColumn +=" \n "+(this.times[i].periodDetails[j].ClassCourse==undefined?"":this.times[i].periodDetails[j].ClassCourse);
              dataColumn +=" | "+(this.times[i].periodDetails[j].Batch==undefined?"":this.times[i].periodDetails[j].Batch);
            }
            data.set(this.periods[j].periodName,dataColumn);
          }
          this.defaultArray.push(data);
      }
      const docDefinition = {
        pageSize: 'A4',
        // by default we use portrait, you can change it to landscape if you wish
        pageOrientation: 'portrait',
        content: [
          {
              table: {
                  widths: ['auto', '*'],
                  body: [
                      [ {
                          width: 100,
                          height: 100,
                          image: 'imageKey'
                      }, {
                          width: '*',
                          alignment: 'center',
                          stack: [
                              {
                                  style: 'h1',
                                  text: this.institution.institutionName
                              },
                              {
                                style: 'h2',
                                text: this.institution.address
                              },
                              {
                                  style: 'h2',
                                  text: this.currentAcademicYearName
                              },
                              {
                                style: 'h2',
                                text: this.timeTableDetail
                              },
                              {
                                  style: 'h3',
                                  text: 'TIMETABLE REPORT'
                              },
                          ]
                      }
                      ]
                  ]
              },
              layout: {
                  hLineWidth: () =>  0,
                  vLineWidth: () => 0,
                  paddingBottom: () => 5,
                  paddingLeft:()=> 5,
                  paddingRight:()=> 5
              },
            },
            {
              style:'sectionHeader',
              table: {
                headerRows: 1,
                widths: widthList,
                body: [
                  this.headerList,
                  ...this.defaultArray.map((p) => {
                    const temp = [];
                    for (const y of this.fieldList) {
                      temp.push(p.get(y));
                    }
                    return temp;
                  }),
                ]
              }
            },
        ],
        images: {
           imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
           // via URL address, which can be referenced by this key
        },
        styles: {
          
          sectionHeader: {
            fontSize: 8,
            margin: [0, 10, 0, 15]
          },
          h1: {
            margin: [0, 5, 0, 0],
            fontSize: 13,
            bold: true,
          },
          h2: {
              margin: [0, 5, 0, 0],
              fontSize: 10,
              bold: true
          },
         
        }
      };
      pdfMake.createPdf(docDefinition).download('TIMETABLE REPORT.pdf');
    }

  onBatchChange(values: number) {
    this.shiftid = '-1';

    const classId = this.classcourseid !== '-1' ? this.classcourseid : '0';
    const batchId = this.batchid !== '-1' ? this.batchid : '0';
    const tId = this.teacherId !== '-1' ? this.teacherId : '0';

    this.timeTableService.getPeriodDetail(classId, batchId, 0, tId).subscribe((data: any[]) => {
      console.log(data);
      const periodList: TimeTableViewModel[] = [];
      const periodTable = data['Table'];
      const timeTableObj = data['Table1'];
      periodTable.forEach((element: any) => {
        const x2 = Object.values(element);
        const weekName = x2[0];
        const timeObjList: PeriodViewModel[] = [];
        // 
        // x2.forEach((x) => {
        timeTableObj.forEach((element1: any) => {
          if (weekName === element1.WeekName) {
            const timeObj: PeriodViewModel = {} as PeriodViewModel;
            timeObj.periodId = element1.PeriodId;
            timeObj.subjectName = element1.SubjectName;
            timeObj.teacherName = element1.EmployeeName;
            timeObj.periodTime = element1.PeriodTime;
            timeObj.roomName = element1.RoomName;
            timeObj.ClassCourse = element1.ClassCourse;
            timeObj.Batch = element1.Batch;
            timeObjList.push(timeObj);
          }
        });
        const periodObj: TimeTableViewModel = {} as TimeTableViewModel;
        periodObj.weekName = weekName.toString();
        periodObj.periodDetails = timeObjList;
        periodList.push(periodObj);
      });
      ;
      this.times = periodList;
    });
  }

}
