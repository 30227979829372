import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {OutstandingDueClassWise,OutstandingDueStudentWise,ClassAndBatch, OutstandingDueSummaryReport} from './outstandingduesummary.model';
import { environment } from '../../../../../../environments/environment';
import { OutstandingDueReportModel,OutstandingDueFollowUpDetails } from '../outstandingduereport/outstandingduereport.model';
import { Installment } from '../../installment/installment.model';
import { FeeHead } from '../../feehead/feehead.model';

@Injectable({
  providedIn: 'root'
})
export class OutstandingDueService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }  
    getOutstandingDueSearchReport(status,classId, batchId,FeeHeadId, fromDate, toDate): Observable<OutstandingDueSummaryReport[]>{    
      return this.httpClient.get<OutstandingDueSummaryReport[]>(environment.apiUrl + '/OutstandingDueSummaryReport/GetOutstandingDueSearchReport?Status='+status+'&Classid='+classId+'&Batchid='+batchId+'&FeeHeadId='+FeeHeadId+'&FromDate='+fromDate+'&ToDate='+toDate)       
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getOutstandingDueSearchReportByClassId(status,classId, batchId,FeeHeadId, fromDate, toDate): Observable<OutstandingDueSummaryReport[]>{
    return this.httpClient.get<OutstandingDueStudentWise[]>(environment.apiUrl + '/OutstandingDueSummaryReport/GetOutstandingDueSummaryReportByClassId?Status='+status+'&Classid='+classId+'&Batchid='+batchId+'&FeeHeadId='+FeeHeadId+'&FromDate='+fromDate+'&ToDate='+toDate)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getOutstandingDueFollowUpDetails(studentId, academicYearId, installmentId) {
    return this.httpClient.get<OutstandingDueFollowUpDetails>(environment.apiUrl +
       '/FeeCollection/GetOutstandingDueFollowUpDetails/' + studentId + '/' + academicYearId + '/' + installmentId)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getFeePayment(id, fromdate, todate): Observable<Installment[]> {
   
    return this.httpClient.get<Installment[]>(environment.apiUrl + '/feeinstallment/GetStudentPayment?id=' + id+'&FromDate='+fromdate+'&ToDate='+todate)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getByInstallmentId(id, classId,batchId, studentId): Observable<FeeHead[]> {
    return this.httpClient.get<FeeHead[]>(environment.apiUrl + '/feehead/GetFeeHeadByInstallment/' + id + '/' + classId + '/'+batchId+'/' + studentId)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(studentFeeDueFollowUpDto): Observable<OutstandingDueReportModel> {
    return this.httpClient.post<OutstandingDueReportModel>(environment.apiUrl +
       '/StudentFeeDueFollowUp/', JSON.stringify(studentFeeDueFollowUpDto), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }
  getClassBatchList(classId): Observable<ClassAndBatch[]> {
    return this.httpClient.get<ClassAndBatch[]>(environment.apiUrl + '/FeeCollection/GetClassBatchList/' +parseInt(classId))
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getClassBatchWiseDue(classId, batchId, academicYearId): Observable<OutstandingDueClassWise[]> {
    return this.httpClient.get<OutstandingDueClassWise[]>(environment.apiUrl +
       '/FeeCollection/GetClassBatchWiseDue/' + parseInt(classId) + "/" + parseInt(batchId) +
       "/"+parseInt(academicYearId))
    .pipe(
      catchError(this.errorHandler)
    );
  }
errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
