import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExamremarksRoutingModule } from './examremarks-routing.module';
import { ExamremarksComponent } from './examremarks/examremarks.component';
import { Routes, RouterModule } from '@angular/router';


@NgModule({
  declarations: [ExamremarksComponent],
  imports: [
    CommonModule,
    ExamremarksRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [RouterModule]
})
export class ExamremarksModule { }
