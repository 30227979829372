import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ShiftService } from './shift.service';
import { PeriodService } from './period.service';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Period, Shift } from './period.model';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { esLocale } from 'ngx-bootstrap/chronos';
declare var  $: any;
@Component({
  selector: 'app-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.css']
})
export class PeriodComponent implements OnInit {
  HighlightRow : Number;
  ClickedRow:any;
  ClickedPeriod:any;
  shiftForm: UntypedFormGroup;
  shifts: Shift[] = [];
  shiftSubmitted = false;
classId = "0";
  periodForm: UntypedFormGroup;
  periods: Period[] = [];
  periodFilteredList: Period[] = [];
  periodSubmitted = false;
  classcourses: ClassCourse[];
  selectedclasscourses: number[] = [];
  selectedperiods: Period[] = [];
  noPeriods = 0;
  shiftStartTime: any;
  shiftEndTime: any;
  duration: any;
  shiftId: string;
  batches: Batch[] = [];
  filteredbatches: Batch[] = [];
  batchIds: any[] = [];
  resBatchIds: any[] = [];
  selectedBatchIds = '';
  selectedTime = '';
  SelectedsortClass = '';
  constructor(
    public shiftService: ShiftService,
    public periodService: PeriodService,
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  )
  // ClickedPeriod
   { 
    this.ClickedRow = function(index){this.HighlightRow = index;}

    this.ClickedPeriod = function(index){this.HighlightRow = index;}
  }

  ListClass() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      console.log(data);
      this.classcourses = data;
    });
  }

  ListBatch() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      console.log(data);
      this.batches = data;
    });
  }

  ListShift() {
    this.shifts = [];
    this.shiftService.getAll().subscribe((data: Shift[]) => {
      console.log(data);
      this.shifts = data;
    });
  }

  ListPeriod(selectedClass = '') {
    this.periodService.getAll().subscribe((data: Period[]) => {
      console.log(data);
      this.periodFilteredList = this.periods = data;
      if(selectedClass != null && selectedClass != ''){
        this.onSortClassChange(this.SelectedsortClass);
      }
    });
  }
  calculateTimeDifference() {
    this.onShiftStartEndTimeValidation();
    const periodStartTime = this.periodForm.get('startTime').value;
    const periodEndTime = this.periodForm.get('endTime').value;
    const start = periodStartTime.split(':');
    const end = periodEndTime.toString().split(':');
    const time1 = ((parseInt(start[0], 0) * 60) + parseInt(start[1], 0));
    const time2 = ((parseInt(end[0], 0) * 60) + parseInt(end[1], 0));
    const time3 = ((time2 - time1) / 60);
    const timeHr = parseInt('' + time3, 0);
    const timeMin = ((time2 - time1) % 60);
    let totalTime = ('0' + timeHr).slice(-2) + ':' + ('0' + timeMin).slice(-2);

    if (timeHr.toString().charAt(0) === '-' || timeMin.toString().charAt(0) === '-' ||
     (timeHr.toString().charAt(0) === '0' && timeMin.toString().charAt(0) === '0')) {
      this.toastr.warning('Please select a valid time ', 'warning!', {
        timeOut: 3000
      });
      totalTime = '00:00';
      this.periodForm.get('duration').patchValue(totalTime);
      // this.periodForm.controls['duration'].setValue(totalTime);
      return;
    } else {
      this.periodForm.get('duration').patchValue(totalTime);
      if (this.periodForm.controls['classId'].value === '0') {
        this.onDurationChange(totalTime);
      }
    }
  }
  onShiftStartEndTimeValidation() {
    const shiftStartTime = new Date(this.shiftStartTime);
    const shiftEndTime = new Date(this.shiftEndTime);
    const periodStartTime = this.periodForm.get('startTime').value;
    console.log('shiftStartTime', shiftStartTime);
    console.log('shiftEndTime', shiftEndTime);
    console.log('periodStartTime', periodStartTime);
    const stDate = new Date(); // Creates a Date Object using the clients current time
    // tslint:disable-next-line:prefer-const
    let [hours, minutes, seconds] = periodStartTime.toString().split(':');
    seconds = 0;
    stDate.setHours(+hours); // Set the hours, using implicit type coercion
    stDate.setMinutes(minutes); // You can  pass Number or String. It doesn't really matter
    stDate.setSeconds(seconds);
    if (shiftStartTime.getTime() > stDate.getTime()) {
      this.toastr.warning('Start time must be greater than : ' + this.format24hours(shiftStartTime), ' warning!', {
        timeOut: 3000
      });
      return;
    }

    const periodEndTime = this.periodForm.get('endTime').value || 0;
    if (periodEndTime !== '0' || periodEndTime !== '') {
      const edDate = new Date();
      let [eHours, eMinutes, eSeconds] = periodEndTime.toString().split(':');
      eSeconds = 0;
      edDate.setHours(+eHours);
      edDate.setMinutes(eMinutes);
      edDate.setSeconds(eSeconds);
      edDate.getTime();
      if (shiftEndTime.getTime() >= edDate.getTime()) {
        this.toastr.warning('End time must be less than    :     ' + this.format24hours(shiftEndTime), 'warning!', {
          timeOut: 3000
        });
        return;
      }
    }
  }

  openShift() {
    this.resetShiftForm();
  }

  onShiftChange(value: string) {
    this.shiftId = value;
    const selectedShift = this.shifts.filter((shift: Shift) => shift.id.toString().includes(value.toString()));
    console.log(selectedShift);
    this.shiftStartTime = selectedShift[0].startTime;
    this.shiftEndTime =  selectedShift[0].endTime;
    const time = new Date(this.shiftStartTime);
    console.log(time.getHours() + ':' + time.getMinutes());
  }

  onDurationChange(value: any) {
    console.log(value);
    this.duration = value;
    console.log(moment.duration(value).asMinutes());
    const time = new Date(this.shiftStartTime);
    const newDateObj = moment(this.shiftStartTime).add(value, 'm').toDate();
    const startTime = new Date(this.shiftStartTime); // , 'HH:mm:ss');
    const endsTime =  new Date(this.shiftEndTime); // , 'HH:mm:ss');
    const d = moment.duration(moment.utc(moment(endsTime, 'DD/MM/YYYY HH:mm:ss')
    .diff(moment(startTime, 'DD/MM/YYYY HH:mm:ss'))).format('hh:mm:ss'));
    const s = parseFloat(d.asHours().toString()) * 60; // Math.floor(d.asHours());
    const noOfPeriods = (s / parseInt(moment.duration(value).asMinutes().toString(), 0)).toFixed(2);
    this.noPeriods = parseFloat(noOfPeriods);
    let hrs = '';
    let mnts = '';
    let shrs = ''; let smnts = '';
    if (newDateObj.getHours() < 10) {
      hrs = '0' + newDateObj.getHours();
    } else {
      hrs = newDateObj.getHours().toString();
    }

    if (time.getHours() < 10) {
      shrs = '0' + time.getHours();
    } else {
      shrs = time.getHours().toString();
    }

    if (newDateObj.getMinutes() < 10) {
      mnts = '0' + newDateObj.getMinutes();
    } else {
      mnts = newDateObj.getMinutes().toString();
    }

    if (time.getMinutes() < 10) {
      smnts = '0' + time.getMinutes();
    } else {
      smnts = time.getMinutes().toString();
    }

    const endTime = hrs + ':' + mnts;
    console.log(endTime);
    this.periodForm.get('startTime').patchValue(shrs + ':' + smnts);
    this.periodForm.get('endTime').patchValue(endTime);
  }

  async onClassSelected(value: string) {
    
    if (value === '0') {
      this.filteredbatches = [];
    } else {
     await this.batchService.getAll().subscribe((data: Batch[]) => {
        this.batches = data.filter((batch: Batch) => batch.classId.toString().includes(value.toString()));
        this.filteredbatches = this.batches;
        // this.batchService.getAll().subscribe((data: Batch[]) => {
          this.filteredbatches = this.filteredbatches.filter((batch: Batch) =>
          batch.classId.toString() === value.toString());
          this.onSortClassChange(value.toString());
       //  this.periodForm.controls["sortClass"].setValue(value) ;
       //this.sortClass = 9 ;
       //  = value.toString();
      });
    }
    // });
  }

  onBatchSelected(value: string) {
    console.log(value);
    if (value === '0') {
      if(this.SelectedsortClass.toString() === '0'){
        this.periodFilteredList = this.periods;
      }
      else{
        this.periodFilteredList = this.periods.filter((period: Period) =>
      period.classId.toString() === this.SelectedsortClass.toString());
      }
    } else {
      this.periodFilteredList = this.periods.filter((period: Period) =>
      period.classId.toString() === this.SelectedsortClass.toString() && period.batchIds.toString() === value.toString());
    }
  }

  onSortClassChange(value: string) {
    this.classId = value;
    if (value === '0') {
      this.periodFilteredList = this.periods;
    } else {
      this.periodFilteredList = this.periods.filter((period: Period) =>
      period.classId.toString() === value.toString());
    }
    this.SelectedsortClass = value.toString();
  }

  onChangeCopyPeriodUpto(value: number) {
   if (value === -1) {
      return false;
    } else if ( value === 0) {
      this.classcourses.forEach((x) => {
          this.selectedclasscourses.push(x.id);
      });
    } else {
      const startClassId = this.periodForm.get('classId').value;
      this.classcourses.forEach((x) => {
        if (x.id >= startClassId && x.id <= value) {
          this.selectedclasscourses.push(x.id);
        }
      });
    }
  }

  onSubmitPeriod() {
    this.selectedperiods = [];
    if (this.periodForm.controls["classId"].value === '0') {
      this.classcourses.forEach((data) => {
        if (data.id > 0) {
          this.selectedclasscourses.push(data.id);
        }
      });
    }
    // this.noPeriods = 4;
    this.selectedclasscourses.forEach((x) => {
    this.filteredbatches = this.batches;
    this.selectedBatchIds = ' ';
     // this.batchService.getAll().subscribe((data: Batch[]) => {
    if (this.filteredbatches.length === 0) {
      this.filteredbatches = this.filteredbatches.filter((batch: Batch) => batch.classId.toString()
      === x.toString());
    } else {
      this.filteredbatches = this.filteredbatches.filter((batch: Batch) => batch.classId.toString()
      === x.toString());
    }
    const durationValue = this.periodForm.controls["duration"].value;
    console.log('Period count ' + this.noPeriods);
    console.log('Batches ' + this.filteredbatches);
    for ( let p = 1; p <= this.noPeriods ; p++) {
      let period: Period = {} as Period;
      this.filteredbatches.forEach((b) => {
      period = {} as Period;
      period.classId = x.toString();
      period.batchIds = b.id.toString();
      period.duration = durationValue;
      period.periodName = 'Period ' + p;
      period.startTime = this.periodForm.controls["startTime"].value;
      period.endTime = this.periodForm.controls["endTime"].value;
      period.shiftId = this.shiftId;
      period.status = 'true';
      this.selectedperiods.push(period);
      });
      console.log(this.selectedperiods);
    }
      // });
    });
    console.log(this.selectedperiods);
    this.periodService.bulkcreate(this.selectedperiods).subscribe(res => {
      console.log('Period created!');
      this.ListPeriod();
      this.selectedperiods = [];
      this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
        timeOut: 3000
      });
      this.periodSubmitted = false;
      this.resetPeriodForm();
      this.HighlightRow=-1;
    });
  }

  // onSubmitPeriod() {
  //   this.selectedperiods = [];
  //   this.selectedclasscourses.forEach((x) => {

  //       this.filteredbatches = this.batches;
  //     // this.onClassSelected(x.toString());
  //    // this.batchService.getAll().subscribe((data: Batch[]) => {
  //       this.filteredbatches = this.filteredbatches.filter((batch: Batch) => batch.classId.toString()
  //       .toLocaleLowerCase().includes(x.toString().toLocaleLowerCase()));
  //       this.selectedBatchIds = '';
  //       this.filteredbatches.forEach((k, i) => {

  //         if ((i + 1) === this.filteredbatches.length) {
  //           this.selectedBatchIds += k.id.toString();
  //         } else {
  //           this.selectedBatchIds += k.id.toString() + '|';
  //         }
  //       });
  //       const value = this.duration;
  //       let time = new Date(this.shiftStartTime);
  //       let newDateObj = moment(this.shiftStartTime).add(value, 'm').toDate();
  //       for ( let p = 1; p <= this.noPeriods ; p++) {
  //         let hrs = '';
  //         let mnts = '';
  //         let shrs = ''; let smnts = '';
  //         if (newDateObj.getHours() < 10) {
  //           hrs = '0' + newDateObj.getHours();
  //         } else {
  //           hrs = newDateObj.getHours().toString();
  //         }

  //         if (time.getHours() < 10) {
  //           shrs = '0' + time.getHours();
  //         } else {
  //           shrs = time.getHours().toString();
  //         }

  //         if (newDateObj.getMinutes() < 10) {
  //           mnts = '0' + newDateObj.getMinutes();
  //         } else {
  //           mnts = newDateObj.getMinutes().toString();
  //         }

  //         if (time.getMinutes() < 10) {
  //           smnts = '0' + time.getMinutes();
  //         } else {
  //           smnts = time.getMinutes().toString();
  //         }
  //         const endTime = hrs + ':' + mnts;
  //         const pTime = shrs + ':' + smnts;
  //         time = newDateObj;
  //         newDateObj = moment(newDateObj).add(value, 'm').toDate();

  //         const period: Period = {} as Period;
  //         period.classId = x.toString();
  //         period.batchIds = this.selectedBatchIds;
  //         period.duration = value;
  //         period.periodName = 'Period ' + p;
  //         period.startTime = pTime;
  //         period.endTime = endTime;
  //         period.shiftId = this.shiftId;
  //         period.status = 'true';
  //         this.selectedperiods.push(period);
  //       }
  //     // });
  //   });

  //   this.periodService.bulkcreate(this.selectedperiods).subscribe(res => {
  //     console.log('Period created!');
  //     this.ListPeriod();
  //     this.selectedperiods = [];
  //     this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
  //       timeOut: 3000
  //     });
  //     this.periodSubmitted = false;
  //     this.resetPeriodForm();
  //   });
  // }

  async getExistingBatches(value: string) {
    await this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString().includes(value.toString()));
      this.batches.forEach(x => {
        for (let i = 0; i < this.resBatchIds.length; i++) {
          if (x.id.toString() === this.resBatchIds[i]) {
            console.log(x.id);
            x.checked = true;
          }
        }
      });
    });
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    console.log(keyword);
    if (keyword === '') {
      this.periodFilteredList = this.periods;
    } else {
      this.periodFilteredList = this.periods
        .filter((period: Period) => period.periodName.toLocaleLowerCase()
          .includes(keyword.toLocaleLowerCase()));
    }
  }


  checkAllCheckBox(ev) {
    this.batches.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.batches.every(p => p.checked);
  }

  get totalRows(): number {
    return this.periodFilteredList.length;
  }
  get f() { return this.shiftForm.controls; }
  get p() { return this.periodForm.controls; }

  validateTime(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {

      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      console.log(control.value);
      console.log(matchingControl.value);
      // set error on matchingControl if validation fails
      if (control.value > matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  validatePeriodTime(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {

      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      console.log(control.value);
      console.log(matchingControl.value);
      // set error on matchingControl if validation fails
      if (control.value > matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  resetShiftForm() {
    this.shiftSubmitted = false;
    this.shiftForm = this.fb.group({
      id: [0],
      shiftName: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      status: ['true', Validators.required]
    });
    this.HighlightRow=-1;
  }

  submitShiftForm() {
    this.shiftSubmitted = true;

    // stop here if form is invalid
    if (this.shiftForm.invalid) {
      if (this.f.endTime.errors.mustMatch) {
        this.toastr.warning('End time should not less than start time ', 'WARNING!!!', {
          timeOut: 3000
        });
      }
      return;
    }

    if (this.shiftForm.get('id').value === 0) {
      console.log(this.shiftForm.value);
      this.shiftService.create(this.shiftForm.value).subscribe(res => {
        console.log('Shift created!');
        this.ListShift();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.shiftSubmitted = false;
        this.resetShiftForm();
      });
    } else {
      this.shiftService.update(this.shiftForm.get('id').value, this.shiftForm.value).subscribe(res => {
        console.log('Shift updated!');
        this.ListShift();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.shiftSubmitted = false;
        this.resetShiftForm();
      });
    }
  }

  updateShiftForm(id: number) {
    this.shiftService.getById(id).subscribe((data: Shift) => {
      console.log(data);
      this.shiftForm = this.fb.group({
        id: [data.id],
        shiftName: [data.shiftName, Validators.required],
        startTime: [this.format24hours(data.startTime), Validators.required],
        endTime: [this.format24hours(data.endTime), Validators.required],
        status: [data.status, Validators.required]
      });
    });

  }

  deleteShiftForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.shiftService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
         
       this.ListShift();
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }

  resetPeriodForm() {
    this.periodSubmitted = false;
    this.batches.forEach((x) => x.checked = false);
    this.periodForm = this.fb.group({
      id: [0],
      batchIds: ['0', Validators.required],
      classId: ['0', Validators.required],
      duration: ['', Validators.required],
      periodName: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      shiftId: ['1', Validators.required],
      status: ['true', Validators.required]
    });
    this.HighlightRow=-1;
  }

  submitPeriodForm() {
    this.periodSubmitted = true;

    if (this.periodForm.controls['duration'].value === '00:00' || this.periodForm.controls['duration'].value=='0:0') { 
        this.toastr.warning('Please select a valid start and end time', 'Warning!', {
         timeOut: 3000
        });
        return;
     }

    if (this.periodForm.controls["classId"].value === '0' ||
    this.periodForm.controls["classId"].value === 0) {
     this.onSubmitPeriod();
     return false;
    }

     // stop here if form is invalid
    if (this.periodForm.invalid) {
      this.toastr.warning('Please fill all require field.', 'Warning!');
      return;
    }


    if (this.periodForm.get('id').value === 0) {
      console.log(this.periodForm.value);
      this.periodService.create(this.periodForm.value).subscribe(res => {
        console.log('Period created!');
        this.ListPeriod(this.SelectedsortClass);
       
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.periodSubmitted = false;
        this.resetPeriodForm();
        this.HighlightRow=-1;
      });
    } else {
      this.periodService.update(this.periodForm.get('id').value, this.periodForm.value).subscribe(res => {
        console.log('Caste updated!');
        this.ListPeriod(this.SelectedsortClass);
        
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.periodSubmitted = false;
        this.resetPeriodForm();
        this.HighlightRow=-1;
      });
    }


  }
  format24hours(date) {
    // date = new Date(date);
    // const hours = date.getHours();
    // const minutes = date.getMinutes();
    // const strTime = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + '00';
    // return strTime;
    date = new Date(date);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const isAm = hours < 12;
    const hour12 = (hours % 12) || 12;
    const strTime = ('0' + hour12).slice(-2) + ':' + ('0' + minutes).slice(-2) + ' ' + (isAm ? 'AM' : 'PM');
    return strTime;
  }

  // updatePeriodForm(id: number) {
  //   this.periodService.getById(id).subscribe((data: Period) => {
  //     this.resBatchIds = data.batchIds.split('|');
  //     this.getExistingBatches(data.classId.toString());
  //     this.periodForm = this.fb.group({
  //         id: [data.id],
  //         batchIds: [data.batchIds, Validators.required],
  //         classId: [data.classId.toString(), Validators.required],
  //         duration: [new Date(data.duration).getHours() + ':' + new Date(data.duration).getMinutes(), Validators.required],
  //         periodName: [data.periodName, Validators.required],
  //         startTime: [this.format24hours(data.startTime), Validators.required],
  //         endTime: [this.format24hours(data.endTime), Validators.required],
  //         shiftId: [data.shiftId.toString(), Validators.required],
  //         status: ['true', Validators.required]
  //       });
  //   });

  // }

  updatePeriodForm(id: number) {
    
    
    this.periodService.getById(id).subscribe((data: Period) => {
    this.resBatchIds = data.batchIds.split('|');

    this.onClassSelected(data.classId.toString()) ;
     this.getExistingBatches(data.classId.toString());
    
    this.periodForm = this.fb.group({
        id: [data.id],
        batchIds: [data.batchIds, Validators.required],
        classId: [data.classId.toString(), Validators.required],
        duration: [('0' + (new Date(data.duration).getHours())).slice(-2) + ':' + ('0' + new Date(data.duration)
        .getMinutes()).slice(-2), Validators.required],
        periodName: [data.periodName, Validators.required],
        startTime: [this.format24hours(data.startTime), Validators.required],
        endTime: [this.format24hours(data.endTime), Validators.required],
        shiftId: [data.shiftId.toString(), Validators.required],
        status: ['true', Validators.required]
        
      });
    
    });
  }

  deletePeriodForm(id: number) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.periodService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListPeriod();
          this.onSortClassChange(this.classId);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }


  ngOnInit(): void {
    this.ListBatch();
    this.ListShift();
    this.ListPeriod();
    this.ListClass();
    this.shiftForm = this.fb.group({
      id: [0],
      shiftName: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      status: ['true', Validators.required]
    }, {
      validator: this.validateTime('startTime', 'endTime')
    });

    this.periodForm = this.fb.group({
      id: [0],
      batchIds: ['', Validators.required],
      classId: ['', Validators.required],
      duration: ['', Validators.required],
      periodName: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      copyPeriodUpTo: ['0', Validators.required],
      shiftId: ['', Validators.required],
      status: ['true', Validators.required]
    }, {
    validator: this.validatePeriodTime('startTime', 'endTime')
    });
  }

}
