import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {AuthenticationService} from '../authentication.service';
import {Authentication} from '../authentication.model';
import { SharedService } from '../../shared/shared.service';
import { LoginService } from './login.service';
import { LoginPageConfig } from '../../edumaq/configuration/loginpageconfig/loginpageconfig.model';

@Component({
  selector: 'app-ilogin',
  templateUrl: './ilogin.component.html',
  styleUrls: ['./ilogin.component.css']
})
export class IloginComponent implements OnInit {
  userName = '';
  password = '';
  iserror = false;
  imageSrc = '../assets/images/Logbanner/8.jpg';
  instCode = '';

  constructor(
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private loginService: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ListLoginPages() {
    this.loginService.getAll().subscribe((data: LoginPageConfig[]) => {
      console.log(data);
      if (data != null && data.length > 0) {
        this.imageSrc = data[0].image;
      }
    });
  }

  login() {
    debugger;
    const authModel: Authentication = {} as Authentication;
    authModel.username = this.userName;
    authModel.password = this.password;
    localStorage.setItem('uname', this.userName);

    console.log(this.authService.userType);
    if (this.userName !== '' && this.password !== '') {
      this.authService.ilogin(this.userName, this.password, this.instCode, this.authService.userType).subscribe((response: any) => {
        console.log(response);
        console.log(response.user);
        debugger;
        this.sharedService.currentInstitution = response.user;
        const token = response.token;
        console.log(token);
        localStorage.setItem('JWT_TOKEN', token);

        
        // this.invalidLogin = false;
        if (response.user != null) {
          this.authService.userType = response.user.userType;
        }
        console.log(response.user.userType);
        if ( token != null ) {
          this.authService.institutionCode = this.instCode;
          
          if (response.user.userType === 'Super Admin') {
            this.router.navigate([this.instCode + '/superadmin']);
          } else if (response.user.userType === 'Admin') {
            this.router.navigate([this.instCode + '/admin']);
          }
        } else {
          this.iserror = true;
        }
      }, (err) => {
        this.iserror = true;
      });

    } else {
      this.iserror = true;
    }
  }

  logout() {
    // localStorage.removeItem('JWT_TOKEN');
    localStorage.clear();
    this.router.navigate(['/'], {relativeTo: this.activatedRoute});
  }

  ngOnInit(): void {
    localStorage.removeItem('uname');
    this.ListLoginPages();
    this.instCode = this.activatedRoute.snapshot.params.instcode;
    console.log(this.instCode);
  }

}
