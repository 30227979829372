import { Component, OnInit } from '@angular/core';
import { StudentService} from './../student.service';
import { Student, StudentSibling } from './../student.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-studentprofile',
  templateUrl: './studentprofile.component.html',
  styleUrls: ['./studentprofile.component.css']
})
export class StudentprofileComponent implements OnInit {
  admission: Student = {} as Student;
  admissionid: number;
  extensn = '';
  mimeType = '';
  siblingAdmissions: StudentSibling[] = [];
  constructor(
    private studentService: StudentService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  viewForm(id: number) {
    this.studentService.getdetails(id).subscribe((data: Student) => {
      console.log(data);
      let services: string = '';
      this.admission = data;
      this.admission.imageUrl = data.imageUrl != null ? environment.imageUrl + data.imageUrl : null;
      this.admission.fatherImageUrl = data.fatherImageUrl != null ? environment.imageUrl + data.fatherImageUrl : null;
      this.admission.motherImageUrl = data.motherImageUrl != null ? environment.imageUrl + data.motherImageUrl : null;
      if (data.hostelService === 'True') {
        if (data.libraryService === 'True' || data.transportService === 'True') {
          services += 'Hostel |';
        } else {
          services += 'Hostel';
        }
      }
      if (data.libraryService === 'True') {
        if (data.transportService === 'False') {
          services += 'Library';
        } else {
          services += 'Library |';
        }
      }
      if (data.transportService === 'True') {
        services += 'Transport';
      }
      this.admission.services = services;
      if(this.admission.studentSiblings!=null){
        this.studentService.getAll().subscribe(data=>{
  
          let siblingIds=this.admission.studentSiblings.split(',');
          siblingIds.forEach(sId=>{
            if(sId!=null && sId!=',' && sId!=id.toString()){
              let filtredstudent=data.filter(x=>x.id==parseInt(sId));
              let studentSibling=new StudentSibling()
                studentSibling.admissionId=filtredstudent[0].id;
                studentSibling.admissionNo=filtredstudent[0].admissionNo;
                studentSibling.fatherName=filtredstudent[0].fatherName;
                studentSibling.rollNo=filtredstudent[0].rollNo;
                studentSibling.studentName=filtredstudent[0].studentName;
                studentSibling.className=filtredstudent[0].className;
                studentSibling.batchName=filtredstudent[0].batchName;
                this.siblingAdmissions.push(studentSibling); 
               
                //this.filteredSiblings.push(studentSibling);
            }
          })
        });
      }
    });
  }

  public base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    b64Data = b64Data.split(',')[1];
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  onDownloadDocFile(id: number) {
    this.studentService.downloadDocFile(id).subscribe((response) => {
        // console.log(response);
        // let file = new Blob([response], { type: 'application/pdf' });
        const mainData = response;
        const base64ContentArray = response;
        const extensn = base64ContentArray.split(';')[0].split('/')[1];
        const base64Data = response.split(',');
        const mimeType = base64Data[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];

        const blob = this.base64ToBlob(mainData, mimeType);
        saveAs(blob, 'test.' + extensn);
    });
  }

  ngOnInit(): void {
    this.admissionid = this.activatedRoute.snapshot.params.id;

    if (this.admissionid !== 0 && this.admissionid !== undefined) {
      this.viewForm(this.admissionid);
    }
  }


}
