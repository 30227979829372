import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { City } from '../../institutionmanagement/models/city';
import { Country } from '../../institutionmanagement/models/country';
import { State } from '../../institutionmanagement/models/state';
import { InstitutionService } from '../../institutionmanagement/newinstitution/institution.service';
import { BillingCommonServices } from '../commonServices/billing.common.service';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {

  constructor(private billingCommonService: BillingCommonServices,public institutionService: InstitutionService,
     private toster: ToastrService, private fb: UntypedFormBuilder) { }
     countries: Country[] = [];
     states:State[]=[];
     cities:City[]=[];
     countryName:any='';
     stateId:any='';
     cityId:any='';
    institutionsList:any[]=[];
  ngOnInit(): void {
    this.ListCountry();
    this.ListInstitutions();
  }
  ListCountry() {
    this.institutionService.getAllCountry().subscribe((data: Country[]) => {    
      this.countries = data;
    });
  }
  onCountrySelected() {
    this.institutionService.getAllState().subscribe((data: State[]) => {
      this.states = data.filter((state: State) => state.countryId.toString() ===this.countryName.toString());      
    });
  }
  onStateSelected() {    
    this.institutionService.getAllCity().subscribe((data: City[]) => {
      this.cities = data.filter((city: City) => city.stateId.toString() === this.stateId.toString());
    });
  }
  ListInstitutions() {
    this.institutionService.getAllInstitutionList().subscribe(data => {      
      this.institutionsList= data; 
      console.log(data)          
    });
  }
  
}
