import { Component, OnInit, ViewChild } from "@angular/core";
import * as XLSX from "xlsx";
import { Circular } from "../circularmanager/circular.model";
import { Service } from "../creditmanager/service.model";
import { Notification } from "../../notification/notification.model";
import { NotificationService } from "../../notification/notification.service";
import { BroadcastService } from "./broadcast.service";
import { ToastrService } from "ngx-toastr";
import { ChartComponent, ChartType } from "ng-apexcharts";
declare var $: any;

import {ApexNonAxisChartSeries, ApexResponsive, ApexChart } from "ng-apexcharts";
import { Template } from "../configuration/templatemanager/template.model";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Student } from "../../academic/studentmanagement/student.model";
import { SenderIDRequest } from "../configuration/sender-idrequest/senderid.model";
import { Batch, Credit, Gateway, ServiceAvailabilityStatus, GetAvailableCreditByService } from "./broadcast.model";
import { Employeeadmission } from "../../OperationActivity/hrmspayroll/hrms/models/employeeadmission.model";
import { Employee } from "src/app/edumaq/configuration/usermanage/user.model";
import { Department } from "../../OperationActivity/hrmspayroll/master/models/departement.model";
import { SharedService } from "../../shared.service";

export interface ChartOptions {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  legend: any;
  responsive: ApexResponsive[];
  labels: any;
}

declare var $: any;
@Component({
  selector: "app-broadcast",
  templateUrl: "./broadcast.component.html",
  styleUrls: ["./broadcast.component.css"],
})
export class BroadcastComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  chartData: number[] = [];
  chartLables: string[] = [];
  chartType: ChartType = "pie";
  broadcastForm: UntypedFormGroup;

  brodcastName = "Broadcast_";
  broadcastNumbers = "";
  broadcastNumbersCount = 0;
  studentIds = "";
  broadcastEmployeeNumbers = "";
  studentNumbers = "";
  creditTotal = 0;
  circulars: Circular[] = [];
  templates: Template[] = [];
  serviceList: Service[] = [];
  senderIdList: SenderIDRequest[] = [];
  gatewayList: Gateway[] = [];
  gatewayFilteredList: Gateway[] = [];
  senderIDList: string[] = [];
  creditList: Credit[] = [];
  batchList: Batch[] = [];
  batchListPrimary: Batch[] = [];
  departmentList: Department[] = [];
  departmentListPrimary: Department[] = [];
  selectedExcel: DataTransfer = null;
  selectedFileName = "";
  broadcastMessage = "";
  filteredlist: Student[] = [];
  studentfilteredlist: Student[] = [];
  students: Student[] = [];
  selecetedStudentCnt = 0;
  selecetedEmployeeCnt = 0;
  employees: Employeeadmission[];
  employeefilteredlist: Employeeadmission[] = [];
  filteredemployeelist: Employeeadmission[] = [];
  smsConsumption: GetAvailableCreditByService;
  isSchedule = false;
  isUnicodeChecked = false;
  serviceAavailabilityList: ServiceAvailabilityStatus[] = [];
  incidentDescription = 0;
  maxCount = 1224;
  messageCount = 0;
  selectedServiceName = "";
  contactCharacterCount = 0;
  isTemplateDisabled = true;
  isStudentDetails = true;
  loader = false;

  constructor(
    private broadcastService: BroadcastService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private sharedService: SharedService
  ) {
    this.chartOptions = {
      series: this.chartData, // chart data array
      chart: {
        width: 350,
        height: 300,
        type: this.chartType, // chart type such as pie,donut etc
      },
      legend: {
        position: "bottom",
      },
      labels: this.chartLables, // chart labesl like Whatsapp,SMS etc.
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: { width: 200 },
            legend: { position: "bottom" },
          },
        },
      ],
    };
  }

  getBroadcastName() {
    this.brodcastName = "Broadcast_";
    const datetime = new Date();

    const dat = datetime.getDate();
    const month = datetime.getMonth() + 1;
    const hour = datetime.getHours();
    const min = datetime.getMinutes();
    const sec = datetime.getSeconds();
    dat < 10
      ? (this.brodcastName += "0" + dat + "-")
      : (this.brodcastName += +dat + "-");
    month < 10
      ? (this.brodcastName += "0" + month + "-")
      : (this.brodcastName += +month + "-");
    this.brodcastName += datetime.getFullYear() + "_";
    hour < 10
      ? (this.brodcastName += "0" + hour + ":")
      : (this.brodcastName += +hour + ":");
    min < 10
      ? (this.brodcastName += "0" + min + ":")
      : (this.brodcastName += +min + ":");
    sec < 10
      ? (this.brodcastName += "0" + sec + ":")
      : (this.brodcastName += sec);
    hour < 12 ? (this.brodcastName += "AM") : (this.brodcastName += "PM");
  }

  loadChartData() {
    this.chartOptions = {
      series: this.chartData, // chart data array
      chart: {
        width: 350,
        height: 300,
        type: this.chartType, // chart type such as pie,donut etc
      },
      legend: {
        position: "bottom",
      },
      labels: this.chartLables, // chart labesl like Whatsapp,SMS etc.
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: { width: 200 },
            legend: { position: "bottom" },
          },
        },
      ],
    };
  }
  getAllServiceAvailabilityStatus() {
    this.broadcastService
      .getAllServiceAvailabilityStatus()
      .subscribe((data: ServiceAvailabilityStatus[]) => {
        this.serviceAavailabilityList = data;
        console.log(this.serviceAavailabilityList);
        this.chartLables = this.createChartLabels(); // create chart label from credit list
        this.chartData = this.createChartData(); // create chart values from credit list
        // this.calculateSubTotal();//calculate sub total
        // this.loadChartData();
        // this.calculateCreditTotal();// find credit total count
      });
  }
  createChartLabels() {
    const chartlabel: string[] = [];
    this.serviceAavailabilityList.forEach((value) => {
      chartlabel.push(value.serviceName);
    });
    console.log(chartlabel);
    return chartlabel;
  }
  createChartData() {
    const chartdata: any[] = [];
    let val: any;
    const len = this.serviceAavailabilityList.length;
    for (let i = 0; i < len; i++) {
      val = this.serviceAavailabilityList[i].availableQty;
      chartdata[i] = parseInt(val, 0);
    }
    console.log(chartdata);
    return chartdata;
  }
  getAvailableQty(ServiceId: any) {
    debugger;
    if(ServiceId == ""){
      return;
    }
    this.getAllSenderIDs();
    this.broadcastService.getSmsConsumptionByService(Number(ServiceId)).subscribe((data: GetAvailableCreditByService) => {
        this.smsConsumption = data;
        this.creditTotal = this.smsConsumption.smsRemaining;
    });
    //this.creditTotal = this.serviceAavailabilityList.find(element => element.serviceId.toString() === ServiceId).availableQty;
    this.selectedServiceName = this.serviceList.find((x) => x.id.toString() === ServiceId).name;
    if (this.selectedServiceName === "WhatsApp") {
      const self = this;
      $(".summernote").summernote({
        height: 300,
        callbacks: {
          onKeyup: (e) => {
            self.onKeyUpChange(e);
          },
          onChange: (contents, $editable) => {},
        },
      });
    }
    this.broadcastService.getAllGatewayByServiceId(Number(ServiceId)).subscribe((data: Gateway[]) => {
        this.gatewayFilteredList = this.gatewayList = data;
    });
    this.filterGatewayList(ServiceId);
  }

  filterGatewayList(ServiceId: any) {
    this.gatewayFilteredList = this.gatewayList.filter(
      (element) => element.serviceId === ServiceId
    );
  }

  getTemplates() {
    this.broadcastService.getAllTemplates().subscribe((data: Template[]) => {
      this.templates = data.filter(
        (element) => element.templateStatus == "Approved"
      );
    });
  }
  getAllServices() {
    this.broadcastService.getAllServices().subscribe((data: Service[]) => {
      console.log(data);
      this.serviceList = data;
    });
  }
  getAllGatewayList() {
    this.broadcastService.getAllGateways().subscribe((data: Gateway[]) => {
      this.gatewayFilteredList = this.gatewayList = data;
    });
    // this.gatewayList.push("AddHippo"); // dummy array
  }
  getAllSenderIDs() {
    this.broadcastService
      .getAllSenderIds(
        this.broadcastForm.get("serviceId").value,
        this.sharedService.institutionCode
      )
      .subscribe((data: SenderIDRequest[]) => {
        debugger;
        this.senderIdList = data;
      });
  }
  getAllBatchDetails() {
    this.broadcastService.getAllBatchDetails().subscribe((data: Batch[]) => {
      this.batchList = this.batchListPrimary = data; // this.findCurrentStudentCount(data);
      this.batchList.forEach((x, i) => {
        const totalStudents = this.students.filter(
          (admission: Student) =>
            admission.batchId.toString() === x.id.toString()
        ).length;
        // admission.academicYearId.toString() === this.academicYear.id.toString()).length;
        x.currentStudentsCount = totalStudents;
      });
    });
  }

  getAllDepartmentDetails() {
    this.broadcastService
      .getAllDepartments()
      .subscribe((data: Department[]) => {
        this.departmentList = this.departmentListPrimary = data; // this.findCurrentStudentCount(data);
        this.departmentList.forEach((x, i) => {
          const totalEmployees = this.employees.filter(
            (admission: Employeeadmission) =>
              admission.departmentId.toString() === x.id.toString()
          ).length;
          // admission.academicYearId.toString() === this.academicYear.id.toString()).length;
          x.totalEmployees = totalEmployees;
        });
      });
  }

  listAllStudents() {
    this.broadcastService.getAllStudents().subscribe((data: Student[]) => {
      this.students = data;
      this.getAllBatchDetails();
    });
  }

  listAllEmployees() {
    this.broadcastService
      .getAllEmployees()
      .subscribe((data: Employeeadmission[]) => {
        this.employees = data;
        this.getAllDepartmentDetails();
      });
  }

  findCurrentStudentCount(batchData: Batch[]) {
    let len: number;
    len = batchData.length;
    if (len > 0) {
      for (let i = 0; i < len; i++) {
        batchData[i].currentStudentsCount = this.getStudentCount(
          batchData[i].id
        );
      }
    }
    return batchData;
  }
  getStudentCount(batchId: any): number {
    return this.students.filter((element) => element.batchId === batchId)
      .length;
  }

  openModal(event: any) {
    const commservice = this.broadcastForm.get("serviceId").value;
    const modal = document.getElementById('useFromDatabase');
    if (commservice == "") {
      event.stopPropagation();
      this.toastr.warning("Please select communication service.", "WARN!", {
        timeOut: 3000,
      });
    }
  }

  onDbRadioChange(event: any) {
    if (event === true) {
      this.isTemplateDisabled = false;
    } else {
      this.isTemplateDisabled = true;
    }
  }

  onNumberRadioChange(event: any) {
    if (event) {
      this.isTemplateDisabled = true;
    }
  }

  onExcelRadioChange(event: any) {
    if (event) {
      this.isTemplateDisabled = true;
    }
  }

  onStudentRadioChanged(event: any) {
    this.employeefilteredlist.forEach((x) => (x.checked = false));
    if (event) {
      this.isStudentDetails = true;
    }
  }

  onEmployeeRadioChanged(event: any) {
    this.studentfilteredlist.forEach((x) => (x.checked = false));
    if (event) {
      this.isStudentDetails = false;
    }
  }

  onScheduleChange(event: any) {
    this.isSchedule = event.target.checked;
  }

  onUnicodeChanged(event: any) {
    this.isUnicodeChecked = event.target.checked;
    if (event.target.checked === true) {
      this.maxCount = 536;
    } else {
      this.maxCount = 1224;
    }
  }

  onRemoveDuplicates(event: any) {
    if (event.target.checked) {
      this.removeDuplicateNumbers();
    }
  }
  removeDuplicateNumbers() {
    let numArray: string[] = [];
    numArray = this.broadcastNumbers.split(",");
    numArray = numArray
      .filter((value, index) => numArray.indexOf(value) === index)
      .filter((el) => {
        return el != null && el !== "";
      });
    this.broadcastNumbers = numArray.join(",");
    this.countContactNumbers();
  }

  onContactNumberKeyUp(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode === 44) {
      this.contactCharacterCount++;

      if (this.contactCharacterCount === 10) {
        this.contactCharacterCount = 0;
        this.broadcastNumbers += ",";
      }
    } else if (charCode === 8 || charCode === 46) {
      this.contactCharacterCount--;
    }
    this.countContactNumbers();
  }

  countContactNumbers() {
    if (this.broadcastNumbers === "") {
      this.broadcastNumbersCount = 0;
    } else {
      let numArray: string[] = [];
      numArray = this.broadcastNumbers.replace(/^,|,$/g, "").split(",");
      this.broadcastNumbersCount = numArray.length;
    }
  }

  importNumberFromExcel() {
    if (this.selectedExcel == null) {
      this.toastr.warning("Please select the excel file!", "Sorry", {
        timeOut: 4000,
      });
    } else {
      const reader: FileReader = new FileReader();
      reader.readAsBinaryString(this.selectedExcel.files[0]);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary" });

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
        // console.log(data[0]); // Data will be logged in array format containing objects
        this.fetchNumbersFromExcel(data);
      };
    }
  }

  fetchNumbersFromExcel(exceldata) {
    const contactNumners: string[] = [];
    let len: number;
    len = exceldata.length;
    for (let i = 0; i < len; i++) {
      contactNumners.push(exceldata[i]["Phone Number"]);
    }

    // this.broadcastNumbers += ',';
    this.broadcastNumbers += contactNumners.join(",").replace(/^,|,$/g, "");
    this.removeDuplicateNumbers();
  }

  onFileChange(event: any) {
    /* wire up file reader */
    if (
      event.target.files[0].name.split(".")[1] !== "xlsx" ||
      event.target.files[0].name.split(".")[1] !== "xls"
    ) {
      this.toastr.warning("PLease select excel file", "WARNING!", {
        timeOut: 3000,
      });
      return;
    }
    const target: DataTransfer = event.target as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error("Cannot use multiple files");
    } else {
      this.selectedExcel = target;
    }
  }

  onTemplateChanged(id: any) {
    if (this.isTemplateDisabled) {
      this.toastr.warning("Only from database can select template", "WARN!", {
        timeOut: 3000,
      });
      this.broadcastForm.get("templateId").patchValue("");
      return false;
    }
    const temp: Template[] = [];
    const bod = "";
    this.broadcastService.getAllTemplates().subscribe((data: Template[]) => {
      this.broadcastMessage = data.find(
        (template: Template) => template.templateId.toString() === id.toString()
      ).templateBody;
      $(".summernote").summernote("code", this.broadcastMessage);
    });
  }

  onTemplateClicked() {
    // if (this.isTemplateDisabled ) {
    //     this.toastr.warning('Only from database can select template', 'WARN!', {
    //       timeOut: 3000
    //     });
    //     return false;
    // }
  }

  onChangeBroadcastMessage(event: any) {
    console.log(this.broadcastMessage);
  }

  onCheckBatch(evnt: any, batchId: any) {
    if (evnt.target.checked) {
      // on checked
      this.filterStudentList(batchId);
    } else {
      // on unchecked
      this.removeFromfilteredStudentList(batchId);
    }
  }
  removeFromfilteredStudentList(batchId: any) {
    this.studentfilteredlist = this.studentfilteredlist.filter(
      (element) => element.batchId !== batchId
    );
    this.filteredlist = this.studentfilteredlist;
  }
  filterStudentList(batchId: any) {
    this.studentfilteredlist = [
      ...this.studentfilteredlist,
      ...this.students.filter((element) => element.batchId === batchId),
    ];
    this.studentfilteredlist = this.studentfilteredlist
      .filter(
        (value, index) => this.studentfilteredlist.indexOf(value) === index
      )
      .filter((el) => {
        return el != null;
      });
    this.filteredlist = this.studentfilteredlist;
  }
  selecetAllBatch(evnt: any) {
    if (evnt.target.checked) {
      this.studentfilteredlist = this.students;
      this.filteredlist = this.studentfilteredlist;
      this.batchList.forEach((x) => (x.checked = true));
    } else {
      this.batchList.forEach((x) => (x.checked = false));
      this.students.forEach((x) => (x.checked = false));
      this.studentfilteredlist = [];
      this.filteredlist = this.studentfilteredlist;
      this.selectedStudentCount();
    }
  }

  isAllCheckBoxChecked() {
    return this.batchList.every((p) => p.checked);
  }
  selecetAllStudent(evnt: any) {
    this.studentfilteredlist.forEach((x) => (x.checked = evnt));
    this.filteredlist = this.studentfilteredlist;
    this.selectedStudentCount();
  }

  isAllStudentCheckBoxChecked() {
    if (this.studentfilteredlist.length === 0) {
      return false;
    }

    return this.studentfilteredlist.every((p) => p.checked);
  }
  onCheckStudent(evnt: any, studentId: any) {
    this.selectedStudentCount();
    this.studentfilteredlist.forEach(
      (x) => (x.checked = x.id == studentId ? true : x.checked)
    );
  }
  selectedStudentCount() {
    this.selecetedStudentCnt = this.studentfilteredlist.filter(
      (element) => element.checked === true
    ).length;
  }
  onChangeStudentStatusFilter(status: any) {
    let stat = false;
    if (status === "") {
      this.studentfilteredlist = this.filteredlist;
      return;
    }
    if (status === "true") {
      stat = true;
    }
    this.studentfilteredlist = this.filteredlist.filter(
      (element) => Boolean(element.status) === stat
    );
  }
  onSearch(evnt: any) {
    
    if (this.isStudentDetails) {
      //alert(evnt.target.value);
      this.filter(evnt.target.value);
    } else {
      //alert("else - " + evnt.target.value);
      this.employeefilter(evnt.target.value);
    }
  }

  filter(keyword: any) {
    //alert("filter-" + keyword);
    if (keyword === "") {
      this.studentfilteredlist = this.filteredlist;
    } else {
      this.studentfilteredlist = this.filteredlist.filter((stud: Student) => 
           stud.studentName
          .toLocaleLowerCase() // filter column need to be changed - audience is used for testing
          .includes(keyword.toLocaleLowerCase()) ||  
           stud.smsMobNumber         
          .toLocaleLowerCase()
          .includes(keyword.toLocaleLowerCase()) ||
          stud.whatsAppNo         
          .toLocaleLowerCase()
          .includes(keyword.toLocaleLowerCase())          
      );
    }
  }

  employeefilter(keyword: any) {
    //alert(keyword);
    if (keyword === "") {
      this.employeefilteredlist = this.filteredemployeelist;
    } else {
      
      this.employeefilteredlist = this.filteredemployeelist.filter(        
        (employee: Employeeadmission) =>
          employee.employeeName
            .toLocaleLowerCase() // filter column need to be changed - audience is used for testing
            .includes(keyword.toLocaleLowerCase())
      );
    }
  }

  importContactNumbers() {
    this.broadcastNumbers = "";
    const contactNumners: string[] = [];
    const studentIds: number[] = [];
    const contactEmployeeNumnbrs: string[] = [];
    let studentlen: number;
    let employeelen: number;
    let selectedStudents: Student[] = [];
    selectedStudents = this.studentfilteredlist.filter(
      (element) => element.checked === true
    );
    console.log(this.studentfilteredlist);
    let selectedEmployees: Employeeadmission[] = [];
    selectedEmployees = this.employeefilteredlist.filter(
      (element) => element.checked === true
    );

    studentlen = selectedStudents.length;
    employeelen = selectedEmployees.length;
    if (studentlen === 0 && employeelen === 0) {
      this.toastr.error("Plese select atleast one Record!", "WARN!", {
        timeOut: 3000,
      });
    } else {
      for (let i = 0; i < studentlen; i++) {
        studentIds.push(selectedStudents[i].id);
        if (this.broadcastForm.get("serviceId").value == 2) {
          contactNumners.push(selectedStudents[i].smsMobNumber);
        } else if (this.broadcastForm.get("serviceId").value == 1) {
          contactNumners.push(selectedStudents[i].whatsAppNo);
        }
      }
      this.studentNumbers += ",";
      this.studentNumbers += contactNumners.join(",");
      for (let i = 0; i < employeelen; i++) {
        contactNumners.push(selectedEmployees[i].mobileNo);
        contactEmployeeNumnbrs.push(selectedEmployees[i].mobileNo);
      }
      this.broadcastEmployeeNumbers += ",";
      this.broadcastEmployeeNumbers += contactEmployeeNumnbrs.join(",");
      this.broadcastNumbers += ",";
      this.broadcastNumbers += contactNumners.join(",");
      this.studentIds += ",";
      this.studentIds += studentIds.join(",");
      this.removeDuplicateNumbers();
      $("#useFromDatabase").modal("hide");
    }
  }

  importStudentContactNumbers() {
    const contactNumners: string[] = [];
    let len: number;
    let selectedStudents: Student[] = [];
    selectedStudents = this.studentfilteredlist.filter(
      (element) => element.checked === true
    );
    console.log(selectedStudents);

    len = selectedStudents.length;
    if (len === 0) {
      this.toastr.error("Plese select atleast one Record!", "Sorry!", {
        timeOut: 3000,
      });
    } else {
      for (let i = 0; i < len; i++) {
        contactNumners.push(selectedStudents[i].localContactNo);
      }

      this.broadcastNumbers += ",";
      this.broadcastNumbers += contactNumners.join(",");
      this.removeDuplicateNumbers();
    }
  }

  importEmployeeContactNumbers() {
    const contactNumners: string[] = [];
    let len: number;
    let selectedEmployees: Employeeadmission[] = [];
    selectedEmployees = this.employeefilteredlist.filter(
      (element) => element.checked === true
    );
    console.log(selectedEmployees);

    len = selectedEmployees.length;
    if (len === 0) {
      this.toastr.error("Plese select atleast one Record!", "Sorry!", {
        timeOut: 3000,
      });
    } else {
      for (let i = 0; i < len; i++) {
        contactNumners.push(selectedEmployees[i].mobileNo);
      }

      this.broadcastNumbers += ",";
      this.broadcastNumbers += contactNumners.join(",");
      this.removeDuplicateNumbers();
      $("#useFromDatabase").modal("hide");
    }
  }

  resetStudentPopup() {
    if (this.isStudentDetails) {
      this.studentfilteredlist.forEach((x) => (x.checked = false));
      this.studentfilteredlist = [];
      this.batchList.forEach((x) => (x.checked = false));
      this.batchList = this.batchListPrimary;
      this.selecetedStudentCnt = 0;
    } else {
      this.employeefilteredlist.forEach((x) => (x.checked = false));
      this.employeefilteredlist = [];
      this.departmentList.forEach((x) => (x.checked = false));
      this.departmentList = this.departmentListPrimary;
      this.selecetedStudentCnt = 0;
    }
  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode === 44) {
      if (this.broadcastNumbers.endsWith(",") && charCode === 44) {
        return false;
      }
      if (this.broadcastNumbers.length === 0 && charCode === 44) {
        return false;
      }
      return true;
    }
    return false;
  }
  showSelectedStudent() {}

  countChar(event, value) {
    console.log(value);
  }

  resetUploadFileData() {
    this.selectedFileName = "";
    this.selectedExcel = null;
  }

  onKeyUpChange(evnt: any) {
    const t = evnt.currentTarget.innerText;
    if (evnt.keyCode !== 13 && evnt.keyCode !== 18 && evnt.keyCode !== 27) {
      this.incidentDescription = t.length;
      this.messageCount =
        parseInt((parseInt(t.length, 0) / 160).toString(), 0) + 1;
      if (t.length >= this.maxCount) {
        //this.toastr.success('Size of SMS exceeded the limit of 1224 Characters', 'Exceed!')
      } else {
      }
    }
  }

  onCheckDepartment(evnt: any, departmentId: any) {
    if (evnt.target.checked) {
      // on checked
      this.filterEmployeeList(departmentId);
    } else {
      // on unchecked
      this.removeFromfilteredEmployeeList(departmentId);
    }
  }

  removeFromfilteredEmployeeList(departmentId: any) {
    this.employeefilteredlist = this.employeefilteredlist.filter(
      (element) => element.departmentId !== departmentId
    );
    this.filteredemployeelist = this.employeefilteredlist;
  }
  filterEmployeeList(departmentId: any) {
    this.employeefilteredlist = [
      ...this.employeefilteredlist,
      ...this.employees.filter(
        (element) => element.departmentId === departmentId
      ),
    ];
    this.employeefilteredlist = this.employeefilteredlist
      .filter(
        (value, index) => this.employeefilteredlist.indexOf(value) === index
      )
      .filter((el) => {
        return el != null;
      });
    this.filteredemployeelist = this.employeefilteredlist;
  }

  isAllEmployeeCheckBoxChecked() {
    if (this.employeefilteredlist.length === 0) {
      return false;
    }
    return this.employeefilteredlist.every((p) => p.checked);
  }

  selecetAllEmployee(evnt: any) {
    this.employeefilteredlist.forEach((x) => (x.checked = evnt));
    this.filteredemployeelist = this.employeefilteredlist;
    this.selectedEmployeeCount();
  }

  onCheckEmployee(evnt: any, studentId: any) {
    this.selectedEmployeeCount();
    this.employeefilteredlist.forEach(
      (x) => (x.checked = x.id == studentId ? true : x.checked)
    );
  }

  selectedEmployeeCount() {
    this.selecetedStudentCnt = this.employeefilteredlist.filter(
      (element) => element.checked === true
    ).length;
  }

  selecetAllDepartment(evnt: any) {
    if (evnt.target.checked) {
      this.employeefilteredlist = this.employees;
      this.filteredemployeelist = this.employeefilteredlist;
      this.departmentList.forEach((x) => (x.checked = true));
    } else {
      this.departmentList.forEach((x) => (x.checked = false));
      this.employees.forEach((x) => (x.checked = false));
      this.employeefilteredlist = [];
      this.filteredemployeelist = this.employeefilteredlist;
      this.selectedEmployeeCount();
    }
  }

  isAllCheckBoxDepartmentChecked() {
    return this.departmentList.every((p) => p.checked);
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [day, month, year].join("-");
  }

  submitForm() {
    let mess: string;
    let msgCnt: number;
    let notiId = 0;
    debugger;
    const isUnicode = this.broadcastForm.get("isUnicode").value;
    mess = $(".summernote").summernote("code");
    this.broadcastForm.get("messageBody").patchValue(mess);
    if (isUnicode) {
      msgCnt = Math.ceil(mess.length / 70);
    } else {
      msgCnt = Math.ceil(mess.length / 160);
    }
    this.broadcastForm.get("messageCount").patchValue(msgCnt);
    this.broadcastForm.get("serviceName").patchValue(this.selectedServiceName);
    if (this.isSchedule) {
      this.broadcastForm.get("isScheduled").patchValue(this.isSchedule);
    } else {
      this.broadcastForm.get("isScheduled").patchValue(false);
    }

    if (this.broadcastNumbersCount * msgCnt > this.creditTotal) {
      this.toastr.warning("Credits not available", "WARN!", {
        timeOut: 3000,
      });
      return;
    }

    if (this.broadcastForm.invalid) {
      const controls = this.broadcastForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          this.toastr.warning("Please enter " + name, "WARN!", {
            timeOut: 3000,
          });
        }
      }
    } else {
      this.loader = true;
      if (this.broadcastForm.get("id").value === 0) {
        const brdTitle = this.broadcastForm.get("broadcastTitle").value;
        let notification: Notification = {} as Notification;
        notification = {
          id: 0,
          isViewed: false,
          isCompleted: false,
          message: brdTitle,
          title: "Broadcast",
          activityStatus: "Processing",
          createdDate: "",
        };
        console.log(notification);

        this.notificationService.create(notification).subscribe((res: any) => {
          console.log(res);
          notiId = res.result.id;
          this.notificationService
            .getAll()
            .subscribe((data: Notification[]) => {
              this.notificationService.sendUpdate(data);
              this.notificationService.setMessageCount(
                data.filter((x: Notification) => {
                  return x.isViewed === false;
                }).length
              );

              this.broadcastForm
                .get("employeeMobileNumber")
                .patchValue(this.broadcastEmployeeNumbers);
              this.broadcastForm
                .get("studentMobileNumber")
                .patchValue(this.studentNumbers);
              this.broadcastForm.get("studentIds").patchValue(this.studentIds);
              this.broadcastForm.get("notificationId").patchValue(notiId);
              this.broadcastForm
                .get("scheduledDate")
                .patchValue(this.formatDate(new Date()));
              console.log(this.broadcastForm.value);
              debugger;
              this.broadcastService.create(this.broadcastForm.value).subscribe(
                (ress) => {
                  console.log(ress);
                  notification = {
                    id: notiId,
                    isViewed: false,
                    isCompleted: true,
                    message: brdTitle,
                    title: "Broadcast",
                    activityStatus: "Success",
                    createdDate: "",
                  };
                  this.notificationService
                    .update(notiId, notification)
                    .subscribe((ndata) => {
                      this.notificationService
                        .getAll()
                        .subscribe((adata: Notification[]) => {
                          this.notificationService.sendUpdate(adata);
                          this.notificationService.setMessageCount(
                            adata.filter((x: Notification) => {
                              return x.isViewed === false;
                            }).length
                          );
                          this.loader = false;
                          this.toastr.success(
                            "Broadcast Completed",
                            "SUCCESS!",
                            {
                              timeOut: 3000,
                            }
                          );
                        });
                    });
                },
                (error) => {
                  this.loader = false;
                  console.log(error);
                  notification = {
                    id: notiId,
                    isViewed: false,
                    isCompleted: true,
                    message: brdTitle,
                    title: "Broadcast",
                    activityStatus: "Failed",
                    createdDate: "",
                  };
                  this.notificationService
                    .update(notiId, notification)
                    .subscribe((ndata) => {
                      this.notificationService
                        .getAll()
                        .subscribe((adata: Notification[]) => {
                          this.notificationService.sendUpdate(adata);
                          this.notificationService.setMessageCount(
                            adata.filter((x: Notification) => {
                              return x.isViewed === false;
                            }).length
                          );
                        });
                    });
                }
              );

              //});

              this.resetForm();
            });
        });
      }
    }
  }
  resetForm() {
    this.setForm();
    this.incidentDescription = 0;
    this.maxCount = 1224;
    this.messageCount = 0;
    this.creditTotal = 0;
    this.smsConsumption = undefined;

    this.studentfilteredlist.forEach((x) => (x.checked = false));
    this.studentfilteredlist = [];
    this.batchList.forEach((x) => (x.checked = false));
    this.batchList = this.batchListPrimary;
    this.selecetedStudentCnt = 0;
    this.employeefilteredlist.forEach((x) => (x.checked = false));
    this.employeefilteredlist = [];
    this.departmentList.forEach((x) => (x.checked = false));
    this.departmentList = this.departmentListPrimary;
    this.selecetedStudentCnt = 0;

    $(".summernote").summernote("code", "");
    this.getBroadcastName(); // get new name
    this.broadcastForm.get("broadcastTitle").setValue(this.brodcastName);
    console.log(this.brodcastName);
  }

  setForm() {
    this.broadcastForm = this.fb.group({
      id: [0],
      serviceId: ["", Validators.required],
      gatewayId: ["", Validators.required],
      senderRequestId: ["", Validators.required],
      broadcastTitle: ["", Validators.required],
      templateId: [""],
      contactNumbers: ["", Validators.required],
      messageBody: ["", Validators.required],
      isUnicode: [false],
      employeeMobileNumber: [""],
      studentMobileNumber: [""],
      studentIds: [""],
      institutionCode: [this.sharedService.institutionCode],
      messageLanguage: [""],
      isScheduled: [false],
      scheduledDate: [""],
      scheduledTime: [],
      messageCount: [0],
      serviceName: [""],
      notificationId: [0],
    });
  }
  addServicesAPI() {}
  ngOnInit(): void {
    const self = this;
    $(".summernote").summernote({
      height: 300,
      visible: false,
      callbacks: {
        onKeydown: function (e) {
          var t = e.currentTarget.innerText;
          if (t.trim().length >= this.maxCount) {
            this.toastr.warning(
              "Size of SMS exceeded the limit of 1224 Characters !",
              "Exceed",
              {
                timeOut: 4000,
              }
            );
            e.preventDefault();
          }
        },
        onKeyup: (e) => {
          self.onKeyUpChange(e);
        },
        onChange: (contents, $editable) => {},
      },
    });
    this.setForm();

    this.getBroadcastName();
    this.getTemplates();
    this.getAllServices();
    this.getAllGatewayList();
    this.getAllServiceAvailabilityStatus();
    this.listAllStudents();
    this.listAllEmployees();
    $("#scheduledDate")
      .datepicker()
      .on("changeDate", (e) => {
        console.log(new Date(e.date));
        this.broadcastForm.get("scheduledDate").patchValue(new Date(e.date));
      });
  }
}
