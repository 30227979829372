import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Student, StudentClass, StudentSibling, StudentDocument } from './../student.model';
import { StudentService} from './../student.service';
import { SharedService} from '../../../../shared/shared.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { SettingsService } from '../../mastersettings/settings.service';

@Component({
  selector: 'app-changeclass',
  templateUrl: './changeclass.component.html',
  styleUrls: ['./changeclass.component.css']
})
export class ChangeclassComponent implements OnInit {

  sourceAdmissions: Student[] = [];
  sourceClasscourses: ClassCourse[] = [];
  sourceBatches: Batch[] = [];
  classCourseIdSource = '';
  batchIdSource = '';
  currentAcademicYearId: number;


  targetAdmissions: Student[] = [];
  tempTargetAdmission: Student[] = [];
  targetClasscourses: ClassCourse[] = [];
  targetBatches: Batch[] = [];
  studentClass: StudentClass[] = [];
  classCourseIdTarget = '';
  batchIdTarget = '';
  isLoading:boolean=false;
  

  constructor(
    private batchService: BatchService,
    private classService: ClassCourseService,
    private studentService: StudentService,
    public settingService: SettingsService,
    private toastr: ToastrService,
    private sharedService: SharedService
  ) { }

  ListClassCourse() {
    this.classService.getAll().subscribe((data: ClassCourse[]) => {
      this.targetClasscourses = this.sourceClasscourses = data;
    });
  }

  currentAcademicYear() {
    this.settingService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
    });
  }

  onSourceClassSelected(value: number) {
  
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.sourceBatches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
    });
    
  }

  onSourceBatchSelected(value: string) { 
    this.isLoading=true;
    console.log(this.sharedService.currentAcademicYearID);
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.sourceAdmissions = data.filter((student: Student) => {
        return student.batchId.toString() === value &&
        student.academicYearId.toString() === this.currentAcademicYearId.toString() &&
        student.status.toString() === 'true';
      });
      this.isLoading=false;
    });
  }

  onTargetClassSelected(value: string) {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.targetBatches = data.filter((batch: Batch) => batch.classId.toString() === value);
    });
  }

    onTargetBatchSelected(value: string) {
      console.log(value);
      this.studentService.getAll().subscribe((data: Student[]) => {
        console.log(data);
        this.tempTargetAdmission = data.filter((student: Student) => {
          return student.batchId.toString() === value &&
          student.academicYearId.toString() === this.currentAcademicYearId.toString();
        });
        console.log(this.tempTargetAdmission);
      });
    }

  checkAllCheckBox(ev) {
    this.sourceAdmissions.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.sourceAdmissions.every(p => p.checked);
  }

  get totalSourceRows(): number {
    return this.sourceAdmissions.length;
  }

  get totalTargetRows(): number {
    return this.targetAdmissions.length;
  }

  moveSourecetoTarget() {

    if (this.classCourseIdTarget === '' ) {
      this.toastr.warning('Please select target Class ', 'WARNING!!!', {
        timeOut: 3000
      });
      return;
    }

    if (this.batchIdTarget === '' ) {
      this.toastr.warning('Please select target Batch ', 'WARNING!!!', {
        timeOut: 3000
      });
      return;
    }

    if (this.classCourseIdSource === this.classCourseIdTarget && this.batchIdSource === this.batchIdTarget ) {
      this.toastr.warning('Please select different target Class ', 'WARNING!!!', {
        timeOut: 3000
      });
      return;
    }

    // if (this.batchIdSource === this.batchIdTarget ) {
    //   this.toastr.warning('Please select different target Batch ', 'WARNING!!!', {
    //     timeOut: 3000
    //   });
    //   return;
    // }

    // const max =  this.tempTargetAdmission.reduce((acc, shot) => acc = acc > Number(shot.rollNo) ? acc : Number(shot.rollNo, 0));
    console.log(this.tempTargetAdmission);
    const result = this.tempTargetAdmission.map((y) => y.rollNo);
    console.log(result);
    const max = Math.max.apply(null, result);
    console.log(max);
    const nextRollNo = max == '-Infinity' ? 0 : max;

    this.targetAdmissions = [];
    let k = 0;
    this.sourceAdmissions.forEach(x => {
        if (x.checked) {
          k++;
          x.moved = true;
          const y = x;
          y.classCourseId = this.classCourseIdTarget;
          y.batchId = this.batchIdTarget;
          y.id = x.id;
          y.academicYearId = x.academicYearId;
          y.rollNo = (nextRollNo + k).toString();
          this.targetAdmissions.push(y);
        }
    });
  }

  resetTable() {
    this.sourceAdmissions.forEach((x) => x.checked = false);
    this.targetAdmissions = [];
    // this.sourceAcademicYears = [];
    // this.sourceClasscourses = [];
    this.classCourseIdTarget = '';
    this.targetBatches = [];
  }

  removeClass(value: number) {
    this.targetAdmissions.splice(value, 1);
  }

  promoteClass() {
   
    this.targetAdmissions.forEach((x) => {
      console.log(x.id.toString());
      const studentClass: StudentClass = {} as StudentClass;
      studentClass.rollNo = x.rollNo;
      studentClass.academicYearId = x.academicYearId;
      studentClass.admissionId = x.id.toString();
      studentClass.batchId = x.batchId;
      studentClass.classId = x.classCourseId;
      this.studentClass.push(studentClass);
    });

    console.log(this.studentClass);

    if (this.studentClass.length === 0 ) {
      this.toastr.warning('Please add new promoted students ', 'WARNING!!!', {
        timeOut: 3000
      });
      return;
    }

    this.isLoading =true;
    this.studentService.changeclass(this.studentClass).subscribe((res) => {
      console.log('promoted!');
      this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
        timeOut: 3000
      });
    });
    this.isLoading =!this.isLoading;
  }

  ngOnInit(): void {
    this.ListClassCourse();
    this.currentAcademicYear();
  }

}
