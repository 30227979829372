import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { IloginComponent } from './ilogin/ilogin.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import { RandomGuard } from './guards/random.guard';
import { InstitutionGuard } from './guards/institution.guard';
import { TokenInterceptor } from './token.interceptor';
import { FindschoolComponent } from './findschool/findschool.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [LoginComponent, IloginComponent, FindschoolComponent, ForgetpasswordComponent],
  providers: [
    AuthGuard,
    InstitutionGuard,
    RandomGuard
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule
  ]
})
export class AuthenticationModule { }
