
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedService } from './shared.service';
import { FilterPipe } from './pipes/filter.pipe';
import { LoaderComponent } from './loader/loader.component';
import { LoadingComponent } from './loading/loading.component';
//import {Select2Module} from 'ng2-select2';
// import {Select2Module} from 'ng2-select2';
@NgModule({
  providers: [SharedService],
  imports: [
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FilterPipe,
    LoaderComponent,
    //Select2Module
    // Select2Module
    LoadingComponent
  ],
  declarations: [FilterPipe, LoaderComponent
    , LoadingComponent
  ]
})
export class SharedModule { }
