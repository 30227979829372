import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Institution } from '../admin/institution.model';
import { AdminService } from '../admin/admin.service';
import { AnalyticService } from '../../shared/analytic.service';
import { StudentService} from '../academic/studentmanagement/student.service';
import { Student } from '../academic/studentmanagement/student.model';
import { InstitutionGuard } from 'src/app/authentication/guards/institution.guard';
import { environment } from 'src/environments/environment';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from 'ng-apexcharts';

import {
  ChartComponent,
  ApexAxisChartSeries,
  
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexMarkers,
  ApexXAxis,
  ApexPlotOptions,
  ApexLegend,
  ApexGrid,
  NgApexchartsModule
} from "ng-apexcharts";
//import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { DashboardService } from '../dashboard/dashboard.service';
 import { Subscription, combineLatest } from 'rxjs';
import { SettingsService } from '../academic/mastersettings/settings.service';
import { Academicyear } from '../academic/mastersettings/models/academicyear';
import { SharedService } from '../shared.service';
import { financeDashboardIncomeExpenseCollectionReport, FinanceDashboardMonthWiseFeeCollectionReportDto, DashboardDuesPaidDto } from '../dashboard/attendanceSummary.model';
import { switchMap, tap } from 'rxjs/operators';
import { data } from 'jquery';
import { AcademicModule } from '../academic/academic.module';
import { OutstandingDueService } from '../academic/feemanagement/feemanagementreports/outstandingduereport/outstandingduereport.service';
import { OutstandingDueReportModel } from '../academic/feemanagement/feemanagementreports/outstandingduereport/outstandingduereport.model';
import { FeeCollectionReportModel } from '../academic/feemanagement/feemanagementreports/feecollectionreport/feecollectionreport.model';
import { privateDecrypt } from 'crypto';
import { FeeCollectionReportService } from '../academic/feemanagement/feemanagementreports/feecollectionreport/feecollectionreport.service';


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  labels: string[];
  stroke: any; // ApexStroke;
  markers: ApexMarkers;
  plotOptions: ApexPlotOptions;
  colors:string[];
  fill: ApexFill;
  tooltip: ApexTooltip;
  legend: ApexLegend;
  grid: ApexGrid;
  dataLabels:boolean;
};
export interface PieChartOptions {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  legend: any;
  responsive: ApexResponsive[];
  labels: any;
  colors: any;
}


@Component({
  selector: 'app-financedashboard',
  templateUrl: './financedashboard.component.html',
  styleUrls: ['./financedashboard.component.css']
})
export class FinancedashboardComponent implements OnInit, AfterViewInit,OnDestroy  {
  @ViewChild('currentAcademicYearDropDown') currentAcademicYearSelect :ElementRef;
  @Input() currentAcademicYearId: number;
  academicYears: Academicyear[];
  @ViewChild("chart") chart: ChartComponent;

  @ViewChild('increportcatwise') increportcatwise: ChartComponent;
  @ViewChild('expreportcatwise') expreportcatwise: ChartComponent;
  @ViewChild('sessionwiseiexChart') sessionwiseiexChart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public incomeChartOptions: Partial<PieChartOptions>;
  public expenseChartOptions: Partial<PieChartOptions>;
  public sessionwiseiexChartOptions: Partial<ChartOptions>;


  financeDashboardMonthWiseFeeCollectionReport:FinanceDashboardMonthWiseFeeCollectionReportDto[]=[];

  financeIncomeExpenseCollectionReport: any=[];
  IncomeReportCategoryWise: any=[];
  ExpenseReportCategoryWise: any =[];
  finalMonthWiseFeeCollectionReport:FinanceDashboardMonthWiseFeeCollectionReportDto[]=[];
  paidAmount: number=0;
  totalDueAmount: number=0;
  totalIncome: number=0;
  totalExpense: number=0;
  isLoading: boolean=false;
  totaldueSubscription$: Subscription;
  currentAcademicYearObject: Academicyear;
  totalFeeCollectionPaidSubscription$: any;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private sharedService: SharedService,
    private studentService: StudentService,
    private dashboardService: DashboardService,
    private adminService: AdminService,
    private analyticService: AnalyticService,
    private academicYearService:SettingsService,
    private outstandingDueService:OutstandingDueService,
    private feeCollectionReportService:FeeCollectionReportService) { 
     // this.loadChartData()

     /*  this.incomeChartOptions = {
        chart: {
            height: 320,
            type: "donut"
        },
        series: [50, 15  ],
        labels: ["Applicant Registrations", "Fee Collection"   ],
        colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1"],
        legend: {
            show: !0,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: !1,
            fontSize: "14px",
            offsetX: 0,
            offsetY: -10
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    height: 240
                },
                legend: {
                    show: !1
                }
            }
        }]
      };

      this.expenseChartOptions = {
        chart: {
            height: 320,
            type: "donut"
        },
        series: [50, 15, 15, 20],
        labels: ["Fuel Entry", "Grn Purchases", "Loan And Advance	", "Salary Process"],
        colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
        legend: {
            show: !0,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: !1,
            fontSize: "14px",
            offsetX: 0,
            offsetY: -10
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    height: 240
                },
                legend: {
                    show: !1
                }
            }
        }]
      };



      this.sessionwiseiexChartOptions = {
        chart: {
          height: 350,
          type: "bar", 
          toolbar: {
              
          }
        },
        stroke: {
          show: !0,
          width: 2,
          colors: ["transparent"]
        },
         
        plotOptions: {
            bar: {
              horizontal: !1,
            columnWidth: "70%"
            }
        },
        colors: ["#34c38f", "#f46a6a"],
        series: [{
            name: "Income",
            type: "column",
            data: []
        }, {
            name: "Expense",
            type: "column",
            data: []
        } ],
        fill: {
          opacity: [1, 1],
          gradient: {
              inverseColors: !1,
              shade: "light",
              type: "vertical",
              opacityFrom: .85,
              opacityTo: .55
             
          }
      },
      tooltip: {
          y: {
              formatter: function (e) {
                  return " ₹ " + e + " Thousands"
              }
          }
      },
      dataLabels:false,
        legend: {
            offsetY: -10
        },
       
        xaxis: {
            categories: [],
        },
        yaxis: {
            title: {
              text: "₹ (Thousands)"
            }
        },
        
        grid: {
            borderColor: "#f1f1f1"
        }
    }; */
      }
  ngOnDestroy(): void {
    this.academicSuscription.unsubscribe();
    this.totaldueSubscription$.unsubscribe();
    this.totalFeeCollectionPaidSubscription$.unsubscribe();
  }

 
      
      loadChartData() {
        this.chartOptions = {
          chart: {
              height: 400,
              type: "bar",
              stacked: true,
               
          },
         
          plotOptions: {
              bar: {
                horizontal: true,
                
                 
              }
          },
          stroke: {
            width: 1,
            colors: ['#fff']
        },
        
          
          series: [{
              name: "",
              
              data: []
          }, {
              name: "",
             
              data: []
          }, {
              name: "",
            
              data: []
          }],
          
      
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          xaxis: {
              categories: [],
              labels: {
                formatter: function (val) {
                    return val + "K"
                }
            }
          },
          yaxis: {
              title: {
                  text: ""
              }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                  return val + " Thousand"
              }
          }
          },
          fill: {
            opacity: 1
        },
        
      };
      }
    academicSuscription:Subscription;
  ngOnInit(): void {
    // this.isLoading=true;
    //setTimeout(this.currentAcademicYear, 3000);
    //this.isLoading=false;
 this.currentAcademicYear();
    
    
   
  }

  gettotalduespaid(currentAcademicYearObject:Academicyear) {
    /* this.totaldueSubscription$=this.dashboardService.gettotalduespaid(academicId).subscribe((data: DashboardDuesPaidDto) => {
      console.log(data);
        this.paidAmount = data[0].paidAmount;
        this.totalDueAmount = data[0].totalDueAmount;
    }); */


    let details = [{
      fromdate: currentAcademicYearObject.startDate,
      todate: currentAcademicYearObject.endDate,
      class: '-1',
      batch: '-1',
      status: '-1'
    }]
    //;
    /* this.totaldueSubscription$=this.outstandingDueService.getOutstandingDueSearchReport(details).subscribe((data: OutstandingDueReportModel[]) => {
        this.totalDueAmount = data.map(item =>
          parseFloat(item.totalDueAmount)).reduce((prev, curr) => prev + curr, 0);
      
    }); */
    this.totalFeeCollectionPaidSubscription$=this.feeCollectionReportService.getFeeCollectionReport().subscribe((data: FeeCollectionReportModel[]) => {
      const sum = data.map(item => parseFloat(item.paidStatus == false ? item.paidAmount : '0')).reduce((prev, curr) => prev + curr, 0);
      this.paidAmount = sum;

    });
  }

  SessionwiseIncomeExpense(value:number)

  {
    
    this.dashboardService.financeDashboardIncomeExpenseCollectionReport(value, 1).subscribe((data: financeDashboardIncomeExpenseCollectionReport[]) => {
      this.totalIncome = data[0].incomeAmount;
      this.totalExpense = data[0].expenseAmount;
      
  });

  }

  MonthssWiseFeeCollectionReport(value:number)
  {
    this.dashboardService.financeDashboardIncomeExpenseCollectionReport(value, 2).subscribe((data: financeDashboardIncomeExpenseCollectionReport[]) => {
      this.financeIncomeExpenseCollectionReport = data;
      console.log(data);
      let Monthslable =[] ;
      let IncomeAmount =[] ;
      let ExpenseAmount =[] ;
      // commented for test porpuse
      /* for (let d of data)
      {
        Monthslable.push(d.months);
        IncomeAmount.push(d.incomeAmount);
        ExpenseAmount.push(d.expenseAmount);
      }
      this.sessionwiseiexChart.updateOptions({
        xaxis: {
          categories: [...Monthslable]
        },
      });
      this.sessionwiseiexChart.updateSeries([{
        name: "Income",
        type: "column",
        data: [...IncomeAmount]
    }, {
      name: "Expense",
      type: "column",
      data: [...ExpenseAmount]
    }]); */

  });

  }


  incomeReportCategoryWise(value:number)
  {
    this.dashboardService.financeDashboardIncomeExpenseCollectionReport(value, 3).subscribe((data: financeDashboardIncomeExpenseCollectionReport[]) => {
      this.IncomeReportCategoryWise = data.filter(x=> x.entryType==='Credit');
      this.ExpenseReportCategoryWise = data.filter(x=> x.entryType==='Debit');

    let IncomeCategoryWise =[] ;
    let ExpenseCategoryWise =[] ;
    let IncomeAmount =[] ;
    let ExpenseAmount =[] ;
    //commented for test porpose
    for (let d of data)
    {
      if(d.entryType==='Credit')
      {
        IncomeCategoryWise.push(d.incomeExpenseCategory)
        IncomeAmount.push(d.incomeAmount)
      }
      else
      {
        ExpenseCategoryWise.push(d.incomeExpenseCategory)
        ExpenseAmount.push(d.expenseAmount)
      }
       
    }

   this.increportcatwise.updateOptions({
      series: [...IncomeAmount],
      labels: [...IncomeCategoryWise],
      colors: ['#34c38f', '#556ee6', '#f46a6a' ],
    });

    this.expreportcatwise.updateOptions({
      series: [...ExpenseAmount],
      labels: [...ExpenseCategoryWise],
      colors: ['#34c38f', '#556ee6', '#f46a6a' ],
    })  
  });
  
  }
  OverallFinincialStatus(value:number)
  {
    console.log(value);
  }
  getfinanceDashboardMonthWiseFeeCollectionReport(academicId:number){
    this.dashboardService.getfinanceDashboardMonthWiseFeeCollectionReport(academicId).subscribe((data: FinanceDashboardMonthWiseFeeCollectionReportDto[]) => {
      this.financeDashboardMonthWiseFeeCollectionReport = data;
      console.log(this.financeDashboardMonthWiseFeeCollectionReport);
      var  newdata=[];
      var nexttotalfee=0;
      var nextunpaidfee=0;
      for (var i =0; i < this.financeDashboardMonthWiseFeeCollectionReport.length; i++ )
      {
         
        if(i===0)
        {
           
        nextunpaidfee =  this.financeDashboardMonthWiseFeeCollectionReport[i].totalFee- this.financeDashboardMonthWiseFeeCollectionReport[i].paidAmount;
          if(nextunpaidfee<0)
          {
            nextunpaidfee=0;
          }

        newdata.push({"installmentName": this.financeDashboardMonthWiseFeeCollectionReport[i].installmentName, "CtotalFee" : this.financeDashboardMonthWiseFeeCollectionReport[i].totalFee ,  "totalFee" : this.financeDashboardMonthWiseFeeCollectionReport[i].totalFee   , "unpaid" : nextunpaidfee, "fineAmount": this.financeDashboardMonthWiseFeeCollectionReport[i].fineAmount , "discountAmount": this.financeDashboardMonthWiseFeeCollectionReport[i].discountAmount,  "paidAmount": this.financeDashboardMonthWiseFeeCollectionReport[i].paidAmount  } )
        }
        else
        {
          nexttotalfee=  this.financeDashboardMonthWiseFeeCollectionReport[i].totalFee   + nextunpaidfee
          nextunpaidfee =nexttotalfee-this.financeDashboardMonthWiseFeeCollectionReport[i].paidAmount
          if(this.financeDashboardMonthWiseFeeCollectionReport[i].paidAmount> nexttotalfee) 
          {
            nextunpaidfee=0;
          }
          newdata.push({"installmentName": this.financeDashboardMonthWiseFeeCollectionReport[i].installmentName, "CtotalFee" : this.financeDashboardMonthWiseFeeCollectionReport[i].totalFee , "totalFee" : nexttotalfee  , "unpaid" : nextunpaidfee, "fineAmount": this.financeDashboardMonthWiseFeeCollectionReport[i].fineAmount , "discountAmount": this.financeDashboardMonthWiseFeeCollectionReport[i].discountAmount ,  "paidAmount": this.financeDashboardMonthWiseFeeCollectionReport[i].paidAmount } )
      
        }
      }
      
      this.finalMonthWiseFeeCollectionReport= newdata;
      this.updatechatgetfinanceDashboardMonthWiseFeeCollectionReport(newdata)
  });
  }
  updatechatgetfinanceDashboardMonthWiseFeeCollectionReport(newdata)
  {

    let unpaid=[] ;
    let fineAmount=[] ;
    let paidAmount=[]
    for (let d of newdata)
    {
      unpaid.push(d.unpaid)
      fineAmount.push(d.fineAmount)
      paidAmount.push(d.paidAmount)
    }
    
    this.chart.updateSeries([{
      name: "Paid",
      data: paidAmount
  }, {
      name: "UnPaid",
      data: unpaid
  }, {
      name: "Fine",
      data: fineAmount
  }]);
    

  }

  
  currentAcademicYear() {
   
    //this.currentAcademicYearId= this.sharedService.academicYearId;
        //this.getfinanceDashboardMonthWiseFeeCollectionReport(this.currentAcademicYearId) ;
        //this.gettotalduespaid(this.currentAcademicYearId);
        /* this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
          console.log(data);
          this.currentAcademicYearId = data.id;
          // this.ListStudents();
          //this.SessionwiseIncomeExpense(data.id);
          //this.incomeReportCategoryWise(data.id);
          //this.MonthssWiseFeeCollectionReport(data.id);

          this.isLoading=false;
        }); */
        this.isLoading=true;
        this.academicSuscription= this.academicYearService.getCurrentAcademicYear().subscribe((data:Academicyear)=>{
        this.currentAcademicYearId=data.id;
        this.currentAcademicYearObject=data;
        //this.gettotalduespaid(this.currentAcademicYearObject);
        //
       },null,
       ()=>{
       
        
        this.isLoading=false;
       }
         

         
          // this.ListStudents();
          //this.SessionwiseIncomeExpense(data.id);
          //this.incomeReportCategoryWise(data.id);
          //this.MonthssWiseFeeCollectionReport(data.id); 
        )
       /*  ,
        tap(e=>this.SessionwiseIncomeExpense(this.currentAcademicYearId)),
        tap(e=>this.incomeReportCategoryWise(this.currentAcademicYearId)),
        tap(e=>this.MonthssWiseFeeCollectionReport(this.currentAcademicYearId)),
        tap(e=>this.isLoading=false) */
        

       
        
   }
  ngAfterViewInit(): void {
    // let s = document.createElement('script');
    // s.type = 'text/javascript';
    // s.src = '../assets/apexcharts/apexcharts.min.js';
    // this.elementRef.nativeElement.appendChild(s);
    // s = document.createElement('script');
    // s.type = 'text/javascript';
    // s.src = '../assets/js/pages/apexcharts.init.js';
    // this.elementRef.nativeElement.appendChild(s);
    /* this.SessionwiseIncomeExpense(this.currentAcademicYearId); */
  /*   this.incomeReportCategoryWise(this.currentAcademicYearId);
    this.MonthssWiseFeeCollectionReport(this.currentAcademicYearId);  */
    
  }

}
