import { EventEmitter, Input, Output, Directive } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { BiomatricSettingModel } from "./biomatric-setting.model";

@Directive()
export class BiomatricSettingDomain {

    @Output() loadBiomatricSetting: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() id: number = 0;
    @Input() isDisabled: boolean = false;
    
    biomatricSettingList: BiomatricSettingModel[] = [];
    biomatricSettingFormGroup: UntypedFormGroup;
    showComponent = false;
    isEdit = false;
    submitted = false;
    isAdd = false;
    subscription: Subscription[] = [];

    bindFormGroup(): UntypedFormGroup {
        return new UntypedFormGroup({
            id: new UntypedFormControl(0),
            erpGlobalSettingId: new UntypedFormControl(1, [Validators.required]), // to do
            deviceId: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
            deviceName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
            serverMode: new UntypedFormControl('', []),
            domainName: new UntypedFormControl('', [Validators.maxLength(50)]),
            serverAddress: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
            serverPort: new UntypedFormControl('', [Validators.required,Validators.maxLength(5),Validators.pattern('^[0-9]*')]),
            ethernetIp: new UntypedFormControl('', [Validators.maxLength(50)]),
            subnetMask: new UntypedFormControl('', [Validators.maxLength(50)]),
            dns: new UntypedFormControl('', [Validators.maxLength(50)]),
            tcpCommunicationPort: new UntypedFormControl('', [Validators.maxLength(5),Validators.pattern('^[0-9]*')]),
            adminId: new UntypedFormControl(0), // to do
            status: new UntypedFormControl(true),
        });
    }

    close() {
        this.loadBiomatricSetting.emit(true);
    }

    reset() {
        this.submitted = false;
        this.biomatricSettingFormGroup = this.bindFormGroup();
        this.biomatricSettingFormGroup.patchValue({ id: this.id });
    }

    // Validation To do

}
