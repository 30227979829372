import { NgModule } from '@angular/core';
import {Routes,RouterModule} from '@angular/router';
import { TicketManagerComponent } from './ticket-manager/ticket-manager.component';
import { TicketReportComponent } from './ticket-report/ticket-report.component';
import { TicketStatusComponent } from './ticket-status/ticket-status.component';

const routes: Routes = [
{path: 'manage-ticket', component: TicketManagerComponent},
{path: 'ticket-report', component: TicketReportComponent},
{path: 'ticket-status', component: TicketStatusComponent}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class SupportRoutingModule
{
    
}