import { Component, OnInit } from '@angular/core';
//import { Select2OptionData } from 'ng-Select2';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EnquiryService } from './enquiry.service';
import { Enquiry } from './enquiry.model';
import { ClassCourse } from '../../academic/mastersettings/models/classcourse';
import { ClassCourseService } from '../../academic/mastersettings/classcourse/classcourse.service';
import { Department } from '../../OperationActivity/hrmspayroll/master/models/departement.model';
import { DepartmentService } from '../../operationactivity/hrmspayroll/master/department.service';
import { Employeeadmission } from '../../OperationActivity/hrmspayroll/hrms/models/employeeadmission.model';
import { EmployeeadmissionService } from '../../OperationActivity/hrmspayroll/hrms/employeeadmission.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Academicyear } from '../../academic/mastersettings/models/academicyear';
import { SettingsService } from '../../academic/mastersettings/settings.service';
declare var $: any;
@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit {
  enquiryForm: UntypedFormGroup;
  enquiries: Enquiry[] = [];
  enquiryFilteredList: Enquiry[] = [];
  classcourses: ClassCourse[] = [];
  departments: Department[] = [];
  employees: Employeeadmission[] = [];
  submitted = false;
  enquiryNo: string;
  currentDate: string;
  fromDate: Date;
  toDate: Date;
  fDate = '';
  tDate = '';
  isAdmission: boolean;
  searchText = '';
  personToMeetVal='';
  department='';
  //public exampleData: Array<Select2OptionData>;
  AcademicYearId: number=0;

  constructor(
    public enquiryService: EnquiryService,
    private classcourseService: ClassCourseService,
    private departmentService: DepartmentService,
    private employeeService: EmployeeadmissionService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public academicyearService: SettingsService,
  ) { }

  ListEnquiry() {
   // this.enquiryService.getAll().subscribe((data: Enquiry[]) => {
    this.enquiryService.getAll().subscribe((data: any) => {
      console.log(data.Table);
      this.enquiryFilteredList = this.enquiries = data.Table;

      let date = new Date();
      this.fromDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
     
      this.toDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      this.search();

    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  ListDepartments() {
    this.departmentService.getAll().subscribe((data: Department[]) => {
      this.departments = data;
    });
  }

  onDepartmentChanged(departmentId: any) {
    this.employeeService.getAll().subscribe((data: Employeeadmission[]) => {
      this.employees = data.filter(x => x.departmentId.toString() === departmentId.toString());
    });
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode !== '0') {
      event.preventDefault();
    }
  }

  generateEnquiryNo() {
    this.enquiryService.generateEnquiryNo().subscribe(res => {
      this.enquiryNo = res;
    });
  }

  setCurrentDate() {
    const x = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.currentDate = days[x.getDay()] + ' ' + this.formatDate(x);
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('-');
  }

  private formatTime(date) {
    const d = new Date(date);
    let hours = '' + (d.getHours());
    let minutes = '' + d.getMinutes();
    if (hours.length < 2) { hours = '0' + hours; }
    if (minutes.length < 2) { minutes = '0' + minutes; }
    return [hours, minutes].join(':');
  }

  public ToDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  reverseAndTimeStamp(dateString) {
    const reverse = new Date(dateString.split("-").reverse().join("-"));
    return reverse.getTime();
    }

  search() {
    
    this.enquiryFilteredList = this.enquiries;
    console.log(this.fromDate);
    console.log(this.toDate);
    if (this.fromDate === undefined) {
      this.toastr.warning('Please select from date', 'WARN!', {
        timeOut: 3000
      });
      return;
    }

    if (this.toDate === undefined) {
      this.toastr.warning('Please select to date', 'WARN!', {
        timeOut: 3000
      });
      return;
    }
    // const todatee = new Date(this.toDate);
    // const ttttt =  new Date(todatee.setDate(todatee.getDate()+1));
    let fromdate=moment(this.fromDate).format('DD-MM-YYYY');
    console.log(fromdate)
    let todate=moment(this.toDate).format('DD-MM-YYYY');
   // this.enquiryFilteredList = this.enquiries.filter((item: Enquiry) => {
   // this.enquiryFilteredList = this.enquiries;
    this.enquiryFilteredList = this.enquiries.filter((item: any) => {
      // if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
      //   return Math.floor(new Date(item.createdDate).getTime() / 86400000) - 1 ===
      //     Math.floor(new Date(this.fromDate).getTime() / 86400000); 
      if(this.fromDate && this.toDate){ 
      return this.reverseAndTimeStamp(moment(item.CreatedDate).format('DD-MM-YYYY')) >= this.reverseAndTimeStamp(fromdate) && 
      this.reverseAndTimeStamp(moment(item.CreatedDate).format('DD-MM-YYYY')) <= this.reverseAndTimeStamp(todate)     
      } else {
    //  New  ToDate=  new Date(this.toDate.setDate(this.toDate.getDate()+1));
     
        // return new Date(item.createdDate).getTime() >= new Date(this.fromDate).getTime() &&
        // new Date(item.createdDate).getTime() <= new Date(ttttt).getTime();
        this.enquiryFilteredList = this.enquiries;
      }
    });
  }

  onPurposeSelected(value: any) {
    if (value === 'Admission Enquiry') {
      this.isAdmission = true;
    } else {
      this.isAdmission = false;
    }

  }
  onKeyUpEvent(event:   any) {
    this.filter(event.target.value);
  }

  filter(keyword: string) {
    if (keyword === '') {
      this.enquiryFilteredList = this.enquiries;
    } else {
      this.enquiryFilteredList = this.enquiries
       // .filter((enquiry: Enquiry) => {
        .filter((enquiry: any) => {
          return enquiry.visitorName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
            enquiry.contactNumber.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
            enquiry.purposeOfVisit.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
            (enquiry.enquiryNumber !== null ? enquiry.enquiryNumber.toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase()) : '');
        });
    }


  }

  resetList() {
    this.enquiryFilteredList = this.enquiries;
    this.searchText = '';
    this.fDate = '';
    this.tDate = '';
    // $('#enquiry #fromDate').val('');
    // $('#enquiry #toDate').val('');
    this.fromDate= new Date();
    this.toDate = new Date();
    //this.enquiryFilteredList = null;
    this.search();
  }

  get totalRows(): number {
    return this.enquiryFilteredList.length;
  }
  get f() { return this.enquiryForm.controls; }
  resetForm() {
    debugger;
    this.submitted = false; 
    this.enquiryForm = this.fb.group({
      id: [0],
      enquiryNumber: ['', Validators.required],
      enquiryStatus: [''],
      visitorName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      emailId: ['', [Validators.email]],
      // purposeOfVisit: ['',Validators.required],
      department: ['',Validators.required],
      personToMeet: ['',Validators.required],
      appointmentDate: ['', Validators.required],
      appointmentTime: ['',Validators.required],
      enquiryDetail: ['', Validators.required],
      academicYearId:this.AcademicYearId,
    });
   // $('.select2').select2('val', '');
    this.generateEnquiryNo();
  }
  onSelectEmployeeDrop(value:any){
    this.personToMeetVal=value.id;
  }
  submitForm() {
    this.submitted = true;
debugger;
    // stop here if form is invalid
    const departmentName = this.departments.find(x => x.id.toString() === this.enquiryForm.get('department').value).departmentName;
    console.log(departmentName);
    this.enquiryForm.get('enquiryNumber').patchValue(this.enquiryNo);
    this.enquiryForm.get('personToMeet').patchValue(this.personToMeetVal);

    if (this.enquiryForm.invalid) {
      // this.toastr.warning('Invalid Form', 'WARNING!!', {
      //   timeOut: 3000
      // });
      return;
    }
    this.enquiryForm.get('department').patchValue(departmentName);
    this.enquiryForm.get('academicYearId').patchValue(this.AcademicYearId);
    
   
    if (this.enquiryForm.get('id').value === 0) {
      //this.enquiries.a
      this.enquiryForm.get('enquiryStatus').patchValue('Pending');
      console.log(this.enquiryForm.value);
       
      this.enquiryService.create(this.enquiryForm.value).subscribe(res => {
        console.log('Enquiry created!');
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.ListEnquiry();
        this.generateEnquiryNo();
        this.resetForm();
      });
    } else {
      this.enquiryService.update(this.enquiryForm.get('id').value, this.enquiryForm.value).subscribe(res => {
        console.log('Enquiry updated!');
        console.log(this.enquiryForm.value);
        this.ListEnquiry();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    }


  }

  updateForm(id: number) {
    this.enquiryService.getById(id).subscribe((data: Enquiry) => {
      this.enquiryNo = data.enquiryNumber;
      this.enquiryForm = this.fb.group({
        id: [data.id],
        enquiryNumber: [data.enquiryNumber, Validators.required],
        enquiryStatus: [data.enquiryStatus],
        visitorName: [data.visitorName, Validators.required],
        contactNumber: [data.contactNumber, Validators.required],
        emailId: [data.emailId, [Validators.email]],
        purposeOfVisit: [data.purposeOfVisit],
        department: [data.department],
        personToMeet: [data.personToMeet],
        appointmentDate: [this.formatDate(data.appointmentDate), Validators.required],
        appointmentTime: [this.formatTime(data.appointmentTime)],
        enquiryDetail: [data.enquiryDetail, Validators.required],
        createdDate: [this.formatDate(data.createdDate)],
        academicYearId:this.AcademicYearId,
        
      });
    });

  }

  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.enquiryService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListEnquiry();
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }

  redirectForm(enquiryNo: any) {
    this.router.navigate(['../../academic/registrationenquiry', enquiryNo], { relativeTo: this.activatedRoute });
  }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
    this.AcademicYearId=data.id;
      
    
    });
  }
  ngOnInit(): void {
    this.currentAcademicYear();
    this.ListEnquiry();
    this.ListClassCourse();
    this.ListDepartments();
    this.generateEnquiryNo();
    this.setCurrentDate();
    this.enquiryForm = this.fb.group({
      id: [0],
      enquiryNumber: ['', Validators.required],
      enquiryStatus: [''],
      visitorName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      emailId: ['', [Validators.email]],
      purposeOfVisit: [''],
      department: [''],
      personToMeet: [''],
      appointmentDate: ['', Validators.required],
      appointmentTime: ['', Validators.required],
      enquiryDetail: ['', Validators.required],
      academicYearId:this.AcademicYearId,
    });
    $('#appointmentDate').datepicker().on('changeDate', (e) => {
      this.enquiryForm.get('appointmentDate').patchValue(this.formatDate(e.date));
    });

    // $('#appointmentTime').datepicker().on('changeDate', (e) => {
    //     this.enquiryForm.get('appointmentTime').patchValue(this.formatDate(e.date));
    //   });

    $('#enquiry #fromDate').datepicker().on('changeDate', (e) => {
      this.fromDate = new Date(e.date);
    });

    $('#enquiry #toDate').datepicker().on('changeDate', (e) => {
      this.toDate = new Date(e.date);
    });

    $('.select2').select2();
    $('#personToMeet').on("change", function(){
      this.personToMeetVal=$(this).val();
      //alert(this.personToMeetVal);
      //this.enquiryForm.get('personToMeet').patchValue(this.personToMeetVal);
     });
  }

}
