import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Designation } from './models/designation.model';

@Injectable({
  providedIn: 'root'
})
export class DesignationService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<Designation[]> {
      return this.httpClient.get<Designation[]>(environment.apiUrl + '/designations/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<Designation> {
      return this.httpClient.get<Designation>(environment.apiUrl + '/designations/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    isCurrentAcademicYearExits(): Observable<Designation> {
      return this.httpClient.get<Designation>(environment.apiUrl + '/designations/IsCurrentAcademicYearExits')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    create(academicyear): Observable<Designation> {
      if(academicyear.status=='0')
      {
        academicyear.active=false;
      }else 
      {
      academicyear.active=true;
      }
      return this.httpClient.post<Designation>(environment.apiUrl + '/designations/', JSON.stringify(academicyear), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }
  
    update(id, academicyear): Observable<Designation> {
      if(academicyear.status=='0')
      {
        academicyear.active=false;
      }else 
      {
      academicyear.active=true;
      }
      // tslint:disable-next-line:max-line-length
      return this.httpClient.put<Designation>(environment.apiUrl + '/designations/' + id, JSON.stringify(academicyear), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }
  
    delete(id) {
      return this.httpClient.delete<Designation>(environment.apiUrl + '/designations/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }
  
  
  }