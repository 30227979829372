import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ClassCourse } from '../mastersettings/models/classcourse';
import { ClassCourseService } from '../mastersettings/classcourse/classcourse.service';
import { SubjectcreationService} from '../academicsettings/subjectcreation/subjectcreation.service';
import { SubjectCreation} from '../academicsettings/subjectcreation/subjectcreation.model';
import { Batch } from '../mastersettings/models/batch';
import { BatchService } from '../mastersettings/batch/batch.service';
import { Term } from '../mastersettings/models/term';
import { TermService } from '../mastersettings/term/term.service';
import { Syllabus } from './syllabus.model';
import { SyllabusService } from './syllabus.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-syllabus',
  templateUrl: './syllabus.component.html',
  styleUrls: ['./syllabus.component.css']
})
export class SyllabusComponent implements OnInit {
  isLoading:boolean=false;
  syllabusForm: UntypedFormGroup;
  syllabuses: Syllabus[] = [];
  syllabusData: Syllabus = {} as Syllabus;
  filteredSyllabuses: Syllabus[] = [];
  searchSyllabuses: Syllabus[] = [];
  classcourses: ClassCourse[];
  subjects: SubjectCreation[] = [];
  classSubjects: SubjectCreation[] = [];
  filteredSubjects: SubjectCreation[] = [];
  terms: Term[] = [];
  batches: Batch[] = [];
  searchBatches: Batch[] = [];
  batchIds: any[] = [];
  resBatchIds: any[] = [];
  slectedBatchIds = '';
  batchNames: any[] = [];
  termId = '-1';
  subjectId = '';
  classId = '-1';
  submitted = false;
  selectedFile: string;
  HighlightRow : Number;
  ClickedRow:any;


  constructor(
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    private termService: TermService,
    private syllabusService: SyllabusService,
    public subjectService: SubjectcreationService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private sharedserv:SharedService
  )  
  { 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }

  }

  ListSyllabus() {
    this.isLoading=true;
    this.syllabusService.getAll().subscribe((data: Syllabus[]) => {
      console.log(data);
      this.filteredSyllabuses = this.syllabuses = data;
      this.isLoading=false;
    });


    this.isVisible=false;
    
  }

  ngAfterViewInit(){
let val=this.syllabusForm.get('classId').value;
console.log(val);
    if(val =="" || val==null){
      this.isVisible=false;
    }
    else{
      this.isVisible=true;
    }
  }
  ListSubjects() {
    this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
      this.classSubjects = this.filteredSubjects = this.subjects = data;
    });
  }

  ListTerm() {
    this.termService.getAll().subscribe((data: Term[]) => {
      console.log(data);
      this.terms = data;
    });
  }

  ListClass() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      console.log(data);
      this.classcourses = data;
    });
  }

  ListBatch() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      console.log(data);
      this.searchBatches = data;
    });
  }

  onClassChange(value: string) {
    console.log(value);
    if (value === '-1') { return; }
    if (value === '0') {
      this.searchSyllabuses = this.filteredSyllabuses = this.syllabuses; // = data;
    } else {
      this.searchSyllabuses = this.filteredSyllabuses = this.syllabuses.filter((syllabus: Syllabus) =>
      syllabus.classId.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
    }
  }

  onTermChange(value: string) {
    if (value === '-1') { return; }
    if (value === '0') {
      this.searchSyllabuses = this.filteredSyllabuses = this.syllabuses; // = data;
    } else {
      this.searchSyllabuses = this.filteredSyllabuses = this.filteredSyllabuses.filter((syllabus: Syllabus) =>
      syllabus.termId.toString() === value.toString());
    }
  }

  enableDiableChk:boolean=false;
  onClassSelected(value: string) {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toLocaleLowerCase());
      // this.classSubjects = this.filteredSubjects.filter((subject: subjectcreation) =>
      // subject.classCourseId.toString() === value.toString());
    });
    let val=this.syllabusForm.get('classId').value;
    console.log(val);
        if(val =="" || val==null){
          this.isVisible=false;
        }
        else{
          this.isVisible=true;
        }
  }

  getExistingBatches(value: string) {
    debugger;
    this.batches=[];
    this.batchService.getByClasseId(value).subscribe((data: Batch[]) => {
      //this.batches = data.filter((batch: Batch) => batch.classId.toString().includes(value.toString()));
      this.batches = data;
      console.log('batchs edit : '+data);
      this.batches.forEach(x => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.resBatchIds.length; i++) {
          if (x.id.toString() === this.resBatchIds[i]) {
            console.log(x.id);
            x.checked = true;
          }
        }
      });
    });
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (this.searchSyllabuses.length > 0) {
      if ( keyword === '') {
        this.filteredSyllabuses = this.searchSyllabuses;
      } else {
        this.filteredSyllabuses = this.searchSyllabuses
                                  .filter((syllabus: Syllabus) => syllabus.title.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase()) ||
                                  syllabus.className.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase()) ||
                                  syllabus.termName.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase()) ||
                                  syllabus.chapterName.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase()) ||
                                  syllabus.subjectName.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase())
                                  );
      }
    } else {
      if ( keyword === '') {
        this.filteredSyllabuses = this.syllabuses;
      } else {
        this.filteredSyllabuses = this.syllabuses
                                  .filter((syllabus: Syllabus) => syllabus.title.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase()) ||
                                  syllabus.className.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase()) ||
                                  syllabus.termName.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase()) ||
                                  syllabus.chapterName.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase()) ||
                                  syllabus.subjectName.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase())
                                  );
      }
    }
  }

  get totalRows(): number {
    return this.filteredSyllabuses.length;
  }
  get f() { return this.syllabusForm.controls; }

  resetForm() {
    this.submitted = false;
    this.batches.forEach(x => x.checked = false);
    this.HighlightRow=-1;
    this.syllabusForm = this.fb.group({
      id: [0],
      classId: ['', Validators.required],
      batchIds: [''],
      subjectId: ['', Validators.required],
      termId: ['', Validators.required],
      chapterName: ['', Validators.required],
      title: ['', Validators.required],
      description: [''],
      file: [''],
      fileExtension: [''],
      status: ['true', Validators.required],
      chkBatch:[{value:null},Validators.required]
   });
  }

  checkAllCheckBox(ev) {
    this.batches.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.batches.every(p => p.checked);
  }

  downloadDocFile(id, data) {
    const DocFileName = data;
    const DocFile = DocFileName.slice(0, -5);
    this.syllabusService.downloadFile(id, data).subscribe((x: any) => {
        importedSaveAs(x, data);
    });
  }

  uploadFile(event) {
   /* const leavefile = (event.target as HTMLInputElement).files[0];
    this.syllabusForm.patchValue({
      fileAttach: leavefile,
      
    });
    this.syllabusForm.get('file').updateValueAndValidity();
    this.selectedFile=<File>event.target.files[0];*/
   /* if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.syllabusForm.patchValue({
        fileAttach: file,
      });
    }*/
    debugger;
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
        const file = target.files[0];
        const fileExtension=file.name.split('.').pop();
        const reader = new FileReader();
        reader.onload = (e => {
          this.selectedFile = reader.result.toString();
          this.syllabusForm.get('file').patchValue(this.selectedFile);
          this.syllabusForm.get('file').patchValue(this.selectedFile);
          
        });
        reader.readAsDataURL(file);
        this.syllabusForm.get('fileExtension').patchValue(fileExtension);
  }
}


  submitForm() {
    debugger;
    this.submitted = true;
    this.slectedBatchIds = '';
    let k = 1;
    const totalSelected = this.batches.filter(x => x.checked).length;
    this.batches.forEach((x, i) => {
      if (x.checked) {
        if (totalSelected !== k) {
          this.slectedBatchIds += x.id.toString() + '|';
        } else {
          this.slectedBatchIds += x.id.toString();
        }
        k++;
      }
    });

    this.syllabusForm.get('batchIds').patchValue(this.slectedBatchIds);
    console.log(this.syllabusForm.get('batchIds').value);
        // stop here if form is invalid
    if (this.syllabusForm.invalid) {
            return;
    }
    const formData = new FormData();
    for (const key of Object.keys(this.syllabusForm.value)) {
      const value = this.syllabusForm.value[key];
      formData.append(key, value);
    }
    /*console.log(this.syllabusForm.get('classId').value);
    const formData = new FormData();
    formData.append('id', this.syllabusForm.get('id').value);
    formData.append('classId', this.syllabusForm.get('classId').value);
    formData.append('batchIds', this.syllabusForm.get('batchIds').value);
    formData.append('subjectId', this.syllabusForm.get('subjectId').value);
    formData.append('termId', this.syllabusForm.get('termId').value);
    formData.append('title', this.syllabusForm.get('title').value);
    formData.append('chapterName', this.syllabusForm.get('chapterName').value);
    formData.append('description', this.syllabusForm.get('description').value);
    formData.append('status', this.syllabusForm.get('status').value);
    if(this.syllabusForm.get('file')!=null){
      //formData.append('fileAttach', this.syllabusForm.get('file').value);
      
    }
formData.append('fileAttach', this.selectedFile);*/
//this.syllabusForm.get('fileAttach').patchValue(this.selectedFile);
    if (this.syllabusForm.get('id').value === 0) {
        console.log(this.syllabusForm.value);
        

        this.syllabusService.create(this.syllabusForm.value).subscribe(res => {
          console.log('syllabus created!');
          this.ListSyllabus();
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
        } else {
          this.syllabusService.update(this.syllabusForm.get('id').value, this.syllabusForm.value).subscribe(res => {
            console.log('subject updated!');
            this.ListSyllabus();
            this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submitted = false;
            this.resetForm();
            // this.casteForm.reset();
          });
        }
    this.ListSyllabus();
}
  viewForm(id: number) {
    this.syllabusService.getDetails(id).subscribe((data: Syllabus) => {
      console.log(data);
      this.batchNames = data.batchName.split('|');
      this.syllabusData = data;
      this.syllabusData.fileUrl=environment.imageUrl+this.syllabusData.fileUrl;

    });
  }

  updateForm(id: number) {
    this.syllabusService.getById(id).subscribe((data: Syllabus) => {
      debugger;
      console.log(data);
      if (data.batchIds !== null) {
        this.resBatchIds = data.batchIds.split('|');
        this.getExistingBatches(data.classId.toString());
      } else {
        this.onClassSelected(data.classId.toString());
      }
      this.syllabusForm = this.fb.group({
        id: [data.id],
        classId: [data.classId.toString(), Validators.required],
        batchIds: [data.batchIds != null ? data.batchIds.toString() : '', Validators.required],
        subjectId: [data.subjectId.toString(), Validators.required],
        termId: [data.termId, Validators.required],
        title: [data.title, Validators.required],
        chapterName: [data.chapterName],
        description: [data.description],
        status: [data.status.toString(), Validators.required],
        chkBatch:[{value:null},Validators.required],
        file: [''],
        fileExtension: [''],
      });
    });

  }

  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.syllabusService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListSyllabus();
          this.HighlightRow=-1;
        });
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      );
      }
    });
    this.HighlightRow=-1;
  }

  isVisible=false;
  ngOnInit(): void {
    this.ListTerm();
    this.ListClass();
    this.ListBatch();
    this.ListSubjects();
    this.ListSyllabus();
    this.syllabusForm = this.fb.group({
      id: [0],
      classId: ['', Validators.required],
      batchIds: [''],
      subjectId: ['', Validators.required],
      termId: ['', Validators.required],
      chapterName: ['', Validators.required],
      title: ['', Validators.required],
      description: [''],
      file: [''],
      fileExtension: [''],
      status: ['true', Validators.required],
      chkBatch:[{value:null},Validators.required]

   });

   this.syllabusForm.get('classId').valueChanges.subscribe(value=>{
    
    if(value){
      this.isVisible=true;
      //this.syllabusForm.get('chkClass').enable();
    }
    else{
      this.isVisible=false;
      //this.syllabusForm.get('chkClass').disable();
    }
   });
  }

}
