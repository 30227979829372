import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Institution } from 'src/app/edumaq/billingmanagement/models/institutions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-feecollecctionprint',
  templateUrl: './feecollecctionprint.component.html',
  styleUrls: ['./feecollecctionprint.component.css']
})
export class FeecollecctionprintComponent implements OnInit {
  institution: any;

  constructor( private route: ActivatedRoute) { 

  }
  lstfeecollectionprint: any = []
  totalPaidAmount: any;
  dates:any;

  ngOnInit(): void {
    this.route.params.subscribe(params => {

     ;
     this.dates =params["dates"];
      
  });
  this.ListInstitutions();
 ;
    var result = JSON.parse(localStorage.getItem('feecollectionprint'));
   ;
    if (result.length > 0) {
      this.lstfeecollectionprint = result;
      const sum = this.lstfeecollectionprint.map(item => parseFloat(item.paidStatus == false ? item.paidAmount : '0')).reduce((prev, curr) => prev + curr, 0);
    this.totalPaidAmount = sum;
    }
  }

  ListInstitutions() {
    const data = JSON.parse(localStorage.getItem('Institution'));
    console.log(data);
    if (data.branchCount.toString().length === 1) {
      data.branchCount = '0' + data.branchCount;
    }
    if (data.medium === '') {
      data.medium = 'English';
    }
    if(data.logoUrl !== null ) {
      data.logoUrl = environment.imageUrl + data.logoUrl;
    } else if (data.logoUrl === null) {
      data.logoUrl = 'assets/images/SchoolLogo/demologo.png';
    }
    this.institution = data;

  }
}
