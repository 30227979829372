import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs"; 
import { GradescalegroupService } from "../services/gradescalegroup.service";
import { SettingsService } from '../../mastersettings/settings.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Student } from './../../studentmanagement/student.model';
import { StudentService } from './../../studentmanagement/student.service';
import { StudentWiseReportCardService } from "../services/student-wise-report-card.service";
///import { ElementAst } from "@angular/compiler";

@Component({
  selector: 'app-final-report-card',
  templateUrl: './final-report-card.component.html',
  styleUrls: ['./final-report-card.component.css'],
  providers: [FormGroupDirective],
})
export class FinalReportCardComponent implements OnInit {
  submitted = false; 
  categorydropDown: any = [];
  academicyears: Academicyear[] = [];
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  academicYearId: number;
  selectedclass:string="";
  finalexamNamedropDown: any = [];
  classDropDown: any = [];
  isLoading: boolean = false;
  section: any = [];
  saveDataModel = [];
  selectedRow:number;
  maxSubjectCount:number;
  subjectName:any;
  subStatus:any;

  GetAllStudentMarkForSelectedSubject: any = [];
  DetailsResult: any = [];
  examNamedropDown: any = [];
  disabled = true;
  alldisabled = false;
  termdisabled = false;
  examdisabled = false;

  constructor(private gradeService: GradescalegroupService,
    private toastr: ToastrService,
    public fb: UntypedFormBuilder,
    public service: StudentWiseReportCardService,
    public settingService:SettingsService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    public studentService: StudentService,
    public formDirective: FormGroupDirective) { }

    finalReportCardForm: UntypedFormGroup;
    classBatachData: any = [];
    
    ngOnInit(): void {
      this.finalReportCardForm = this.fb.group({
        id: [0],
        academicYearId: ["", Validators.required], 
        examName: ["all", Validators.required],
        examCategory: ["all", Validators.required]
      });
      this.alldisabled = true;
      this.termdisabled = false;
      this.examdisabled = false;
      this.ListAcademicYear();
    
    }

    onAcademicYearSelected(value: string)
    {
       this.academicYearId=Number(value);  
       this.getCategoryDropDown(value)  ;
       //this.getClass() ;   
       console.log(this.academicYearId);       
    }
  
    get f() {
      return this.finalReportCardForm.controls;
    }
    getCategoryDropDown(id) {
      this.finalReportCardForm.value.academicYearId =id;
      if (this.finalReportCardForm.value.academicYearId !== "") { 
        this.service.getAllCatefgoryDropDown(id).subscribe((data) => {
          this.categorydropDown = data;
        });
        
        this.getClass();
        this.checkingButton();
    }
    }

    
    onCategoryEvent() {     
      this.finalexamNamedropDown = [];
      this.DetailsResult=[];
      this.getNameDropDown();      
      this.checkingButton(); 
      this.getClass();  
      this.finalReportCardForm = this.fb.group({
        id: [0],
        academicYearId: [this.finalReportCardForm.controls.academicYearId.value, Validators.required],
        examName: [this.finalReportCardForm.controls.examName.value, Validators.required],
        examCategory: [this.finalReportCardForm.controls.examCategory.value, Validators.required]
      });
    }

    getNameDropDown() {
      this.service.getExamNameList().subscribe((data) => {
        this.examNamedropDown = data;
        this.finalexamNamedropDown = this.examNamedropDown.filter(
          (filterList: any) =>
            filterList.examCategoryId === this.finalReportCardForm.controls.examCategory.value
        );
      });
      this.checkingButton();
      
    }

    submitForm() {}
  
    ListAcademicYear() {
      this.settingService.getAll().subscribe((data: Academicyear[]) => {
        this.academicyears = data;
        this.currentAcademicYear();
        this.checkingButton();
      });
      this.finalReportCardForm = this.fb.group({
        id: [0],
        academicYearId: ["", Validators.required],
        examName: ["all", Validators.required],
        examCategory: ["all", Validators.required]
      });
    }
  
    currentAcademicYear() {
      this.settingService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
        this.academicYearId = data.id; 
        this.getCategoryDropDown(data.id)  ;         
        this.checkingButton();
        
      });
      this.finalReportCardForm = this.fb.group({
        id: [0],
        academicYearId: [this.finalReportCardForm.controls.academicYearId.value, Validators.required],
        examName: ["all", Validators.required],
        examCategory: ["all", Validators.required]
      });
      //this.getClass();
    }

    getClass(){
      this.isLoading = true;
      this.submitted=true;
      this.classDropDown=[];
      this.DetailsResult=[];
      // if (this.finalReportCardForm.value.academicYearId!=="" || this.finalReportCardForm.value.examCategory!==null) {
      //   this.toastr.warning("All field are required", "WARNING!!!", {
      //     timeOut: 3000,
      //   });
      //   return;
      // }
      //debugger;
      let details={
        academicYearId:this.finalReportCardForm.value.academicYearId,
        examCategoryId:(this.finalReportCardForm.value.examCategory=="all" || this.finalReportCardForm.value.examCategory==null)?0:this.finalReportCardForm.value.examCategory,
        examId:(this.finalReportCardForm.value.examName=="all" || this.finalReportCardForm.value.examName==null)?0:this.finalReportCardForm.value.examName
      };
    this.service.getClassList(details).subscribe((data) => {
      this.classDropDown=data;
      this.selectedRow=null;
      this.isLoading = false;
      this.selectedclass="";
      this.resetStudent();
    });
    }

    resetStudent(){
      // this.examWiseAttendanceStudentList=[];
      // this.attendenceCount=0;
      // this.absentCount=0;    
    }
   
    onBatchChanged(id) {
      this.admissions = []; 
      this.DetailsResult=[];    
      this.studentService.getAll().subscribe((data: Student[]) => {
        this.admissions = data.filter((student: Student) => {
         return student.academicYearId.toString() === this.finalReportCardForm.controls.academicYearId.value.toString() &&
           student.classCourseId.toString() === this.finalReportCardForm.controls.classId.value.toString() &&
           student.batchId.toString() === id.toString();
         });
         this.checkingButton();
      });
      this.finalReportCardForm = this.fb.group({
        id: [0],
        academicYearId: [this.finalReportCardForm.controls.academicYearId.value, Validators.required],
        examName: [this.finalReportCardForm.controls.examName.value, Validators.required],
        examCategory: ["all", Validators.required]
      }); 
    }
  
  
  
    checkingButton() {
      if (
        this.finalReportCardForm.controls.academicYearId.value &&
        this.finalReportCardForm.controls.examName.value == "all" ? -1 : this.finalReportCardForm.controls.examName.value ,
        this.finalReportCardForm.controls.examCategory.value == "all" ? -1 : this.finalReportCardForm.controls.examCategory.value 
      ) {
        this.disabled = false;
      } 
    }    
   
    disableFreezeButton: boolean = false;
    subjectId: any;
    studentIds: any[] = [];
    
    getallstudentMarks(d:any, i:number){
      this.isLoading = true;
        if (this.finalReportCardForm.valid) {

          if(this.finalReportCardForm.value.academicYearId!=="" && this.finalReportCardForm.value.examCategory==="all" && this.finalReportCardForm.value.examName==="all")
          {
            this.alldisabled = true;
            this.termdisabled = false;
            this.examdisabled = false;
          }
          else if(this.finalReportCardForm.value.academicYearId!=="" && this.finalReportCardForm.value.examCategory!=="all" && this.finalReportCardForm.value.examName==="all")
          {
            this.alldisabled = false;
            this.termdisabled = true;
            this.examdisabled = false;
          }
          else if(this.finalReportCardForm.value.academicYearId!=="" && this.finalReportCardForm.value.examCategory!=="all" && this.finalReportCardForm.value.examName!=="all")
          {
            this.alldisabled = false;
            this.termdisabled = false;
            this.examdisabled = true;
          }
          else
          {
            this.alldisabled = true;
            this.termdisabled = false;
            this.examdisabled = false;
          }

        let details={
            academicYearId:this.finalReportCardForm.value.academicYearId,
            examCategoryId:(this.finalReportCardForm.value.examCategory=="all" || this.finalReportCardForm.value.examCategory==null)?0:this.finalReportCardForm.value.examCategory,
            examId:(this.finalReportCardForm.value.examName=="all" || this.finalReportCardForm.value.examName==null)?0:this.finalReportCardForm.value.examName,
            classId:d.classId,
            batchId:d.batchId
        };
        this.selectedRow=i;
        this.service.getStudentList(details).subscribe((data)=>{
          this.DetailsResult=data;
          this.isLoading = false;
        });

      }
      else{
        this.toastr.warning("All field are required", "WARNING!!!", {
          timeOut: 3000,
        });
        this.isLoading = false;
        return;
      }
    }    
    
    resetForm() {
      this.submitted = false;
      this.finalexamNamedropDown = [];
      this.classDropDown = [];
      this.DetailsResult=[];
      this.classBatachData = [];
      this.saveDataModel = [];
      this.formDirective.reset();
      this.finalReportCardForm.reset();
      this.disabled = true;
      this.finalReportCardForm = this.fb.group({
          id: [0],
          examCategory: ["", Validators.required],
          examName: ["", Validators.required],
          academicYearId: ["", Validators.required]
        });
    }

}
