import { ApplicationRef, Component, OnInit } from '@angular/core';
import {ApplicantRegistrationService} from '../applicantregistration/applicantregistration.service';
import { ApplicantRegistration } from '../applicantregistration/applicantregistration.model';
import { ClassCourse } from '../mastersettings/models/classcourse';
import { ClassCourseService } from '../mastersettings/classcourse/classcourse.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { environment } from '../../../../environments/environment';
import { ReportAttribute } from '../studentmanagement/report.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
/// import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable.js';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { SettingsService } from '../mastersettings/settings.service';
import { Academicyear } from '../mastersettings/models/academicyear';
declare var $: any;

@Component({
  selector: 'app-reg-fee-collection-report',
  templateUrl: './reg-fee-collection-report.component.html',
  styleUrls: ['./reg-fee-collection-report.component.css']
})
export class RegFeeCollectionReportComponent implements OnInit {
  applicantRegistrations: ApplicantRegistration[] = [];
  registrationfilteredlist: ApplicantRegistration[] = [];
  registrationSearchList: ApplicantRegistration[] = [];
  variablereg: ApplicantRegistration;
  classcourses: ClassCourse[] = [];
  classcourseid = '0';
  currentAcademicYearName = '';
  fromDate: Date;
  toDate: Date;
  fileName = 'FeeCollectionReport.xlsx';
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  institution: any;
  academicYear:Academicyear[]=[];
  currentAcademicYearId: number;
  defaultArray = [];
  currentAcademicYear:Academicyear;

  constructor(
    private registrationService: ApplicantRegistrationService,
    private classcourseService: ClassCourseService,
    private academicYearService: SettingsService,
    private toastr: ToastrService
  ) { 
    this.GetCurrentAcademicYear();
  }

  generateExcel() {
    this.headerList =[];
    this.fieldList =[];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    this.defaultArray = [];
    for(var i = 0 ; i < this.registrationfilteredlist.length ; i++)
    {
      let data ={
        "index" : i+1,
        "registerNo" : this.registrationfilteredlist[i].registerNo == undefined ? "":this.registrationfilteredlist[i].registerNo,
        "registrationDate" : this.registrationfilteredlist[i].registrationDate == undefined ?"": this.registrationfilteredlist[i].registrationDate ,
        "formNo" :this.registrationfilteredlist[i].formNo == undefined ? "" : this.registrationfilteredlist[i].formNo,
        "studentName" : this.registrationfilteredlist[i].studentName == undefined ? "" :  this.registrationfilteredlist[i].studentName,
        "contactNumber" : this.registrationfilteredlist[i].contactNumber == undefined ? "" : this.registrationfilteredlist[i].contactNumber,
        "applicationFee" : this.registrationfilteredlist[i].applicationFee == undefined ? "":this.registrationfilteredlist[i].applicationFee,
      }

      this.defaultArray.push(data);
    }
    let data1 ={
      "index" : "",
      "registerNo" :"",
      "registrationDate" : "",
      "formNo" :"",
      "studentName" : "",
      "contactNumber" : "Total Amount",
      "applicationFee" : this.sumRows == undefined ? 0 : this.sumRows.toString(),
    }
    this.defaultArray.push(data1);
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('REGISTRATION FEE COLLECTION SUMMARY');
 
    // const imageId2 = workbook.addImage({
    //   filename: 'images/avatar-1.png',
    //   extension: 'png',
    // });
    // worksheet.addImage(imageId2, 'A1:B1');

    const instName = worksheet.addRow([this.institution.institutionName]);
    instName.eachCell(function(cell,colNum){
       cell.font = {
          name:'Arial',
          family: 2,
          bold: true,
          size: 14
       }
       cell.alignment = {
          vertical : 'middle',
          horizontal: 'start'
       }
    })
    instName.height = 25;
    const instAddress = worksheet.addRow([this.institution.address]);
    instAddress.eachCell(function(cell,colNum){
      cell.font = {
         name:'Arial',
         family: 2,
         bold: true,
         size: 11
      }
      cell.alignment = {
        vertical : 'middle',
        horizontal: 'start'
     }
    })
    instAddress.height = 20;

    const instSession = worksheet.addRow([this.currentAcademicYear.name]);
    instSession.eachCell(function(cell,colNum){
      cell.font = {
         name:'Arial',
         family: 2,
         bold: true,
         size: 11
      }
      cell.alignment = {
        vertical : 'middle',
        horizontal: 'start'
      }
    })
    instSession.height = 20;

    worksheet.addRow([]);
    const titleRow = worksheet.addRow(['REGISTRATION FEE COLLECTION REPORT FOR']);
    const fromDate = $('#fromDate').val();
    const toDate = $('#toDate').val();
    worksheet.addRow([`${fromDate} to ${toDate}`]);
    const headerRow = worksheet.addRow(this.headerList);
    for(var i = 0 ; i < this.defaultArray.length ; i++)
    this.defaultArray[i]["index"] = i+1;
    for (const x1 of this.defaultArray) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'payDate') {
          if(x1[y]=="" || x1[y]==undefined){
            temp.push("");
          }else{
            temp.push(this.formatDate(x1[y]));
          }
        } else {
          if(y === "paidStatus")
          {
            if(x1[y] === true || x1[y] === false){
              temp.push(x1[y] === true ?"Cancelled":"Paid");
            }else{
              temp.push("")
            }
          }
          else{
            temp.push(x1[y]);
          }
        }
      }
      worksheet.addRow(temp);
    }
    const fname = 'REGISTRATION FEE COLLECTION SUMMARY';

    worksheet.eachRow((row, rowNumber) => {

      row.eachCell((cell, colNumber) => {
          if ([1,2,3,4,5,6].includes(rowNumber)) {
          }else{
           // Set border of each cell 
            cell.width = 150;
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          }
 
      })
      //Commit the changed row to the stream
      row.commit();
  });

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }

  GetCurrentAllcademicYear() {
    this.academicYearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYear = data;
      this.currentAcademYear();
    });
  }

  currentAcademYear() {
    debugger
    this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.currentAcademicYearName = this.academicYear.find(x => x.id.toString() === data.id.toString()).name;
    });
  }


  GetCurrentAcademicYear() {
    this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYear = data;
    });
  }
  
  generatePDF() {
    var pdffromdate= $('#fromDate').val();
    var pdftodate= $('#toDate').val();
    this.headerList =[];
    this.fieldList =[];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    const widthList = [];
    for (const item of this.headerList) {
        widthList.push('auto');
    }
    this.defaultArray = [];
    for(var i = 0 ; i < this.registrationfilteredlist.length ; i++)
    {
      let data ={
        "index" : i+1,
        "registerNo" : this.registrationfilteredlist[i].registerNo == undefined ? "":this.registrationfilteredlist[i].registerNo,
        "registrationDate" : this.registrationfilteredlist[i].registrationDate == undefined ?"": this.registrationfilteredlist[i].registrationDate ,
        "formNo" :this.registrationfilteredlist[i].formNo == undefined ? "" : this.registrationfilteredlist[i].formNo,
        "studentName" : this.registrationfilteredlist[i].studentName == undefined ? "" :  this.registrationfilteredlist[i].studentName,
        "contactNumber" : this.registrationfilteredlist[i].contactNumber == undefined ? "" : this.registrationfilteredlist[i].contactNumber,
        "applicationFee" : this.registrationfilteredlist[i].applicationFee == undefined ? "":this.registrationfilteredlist[i].applicationFee,
      }
      this.defaultArray.push(data);
    }
    let data1 ={
      "index" : "",
      "registerNo" :"",
      "registrationDate" : "",
      "formNo" :"",
      "studentName" : "",
      "contactNumber" : "Total Amount",
      "applicationFee" : this.sumRows == undefined ? 0 : this.sumRows.toString(),
    }
    this.defaultArray.push(data1);
    const docDefinition = {
      content: [
        {
            table: {
                widths: ['auto', '*'],
                body: [
                    [ {
                        width: 100,
                        height: 100,
                        image: 'imageKey'
                    }, {
                        width: '*',
                        alignment: 'center',
                        stack: [
                            {
                                style: 'h1',
                                text: this.institution.institutionName
                            },
                            {
                              style: 'h2',
                              text: this.institution.address
                            },
                            {
                                style: 'h2',
                                text: this.currentAcademicYearName
                            },
                            {
                                style: 'h1',
                                text: 'REGISTRATION FEE COLLECTION REPORT'
                            },
                            {
                              style: 'h3',
                              text: pdffromdate +'   to   '+  pdftodate
                            }
                        ]
                    }
                    ]
                ]
            },
            layout: {
              hLineWidth: () =>  0,
              vLineWidth: () => 0,
              paddingBottom: () => 5,
              paddingLeft:()=> 5,
              paddingRight:()=> 0
            },
          },
          {
            style:'sectionHeader',
            table: {
              headerRows: 1,
              widths: ['7%','11%','17%', '10%', '20%', '17%', '18%'],
              body: [
                this.headerList,
                ...this.defaultArray.map((p) => {
                  const temp = [];
                  for (const y of this.fieldList) {
                    if (y === 'dob') {
                      temp.push(this.formatDate(p[y]));
                    } else {
                      temp.push(p[y]);
                    }
                  }
                  return temp;
                })
              ]
            }
          }
      ],
      images: {
        imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
      },
      styles: {
        sectionHeader: {
          fontSize: 11,
          margin: [0, 15, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 12,
          bold: true
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        }
      }
    };

    pdfMake.createPdf(docDefinition).download('REGISTRATION FEE COLLECTION.pdf');
  }

  generateAttributes() {
    this.attributes = [
      {name: 'S No', fieldName: 'index' },
      {name: 'Reg No', fieldName: 'registerNo' },
      {name: 'Reg Date ', fieldName: 'registrationDate' },
      {name: 'Form No', fieldName: 'formNo' },
      {name: 'Student Name', fieldName: 'studentName' },
      {name: 'Contact', fieldName: 'contactNumber' },
      {name: 'Application Fee', fieldName: 'applicationFee' }
    ];
  }
  // exportAsPDF() {
  //   const data = document.getElementById('FeeCollectionReport');
  //   html2canvas(data).then(canvas => {
  //     const contentDataURL = canvas.toDataURL('image/png');
  //     const pdf = new jspdf('l', 'cm', 'a4'); // Generates PDF in landscape mode
  //     // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
  //     pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
  //     pdf.save('FeeCollectionReport.pdf');
  //   });
  // }

  exportexcel(): void {
       /* table id is passed over here */
       let element = document.getElementById('FeeCollectionReport');
       const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'FeeCollectionReport');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);

    }

  ListApplicantRegistration() {
    this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
      console.log(data);
      this.registrationfilteredlist = this.applicantRegistrations = data;
       this.search();
    });
  }
  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }
  resetTable() {
    this.registrationfilteredlist = this.applicantRegistrations;
    this.registrationSearchList = [];
    this.classcourseid = '0';
    $('#fromDate').datepicker({format: 'dd-mm-yyyy'},
    { defaultDate: moment(new Date(), 'dd-MM-yyyy')}
    );
    $('#fromDate').datepicker('setDate', '-0d');

    $('#toDate').datepicker(
      {format: 'dd-mm-yyyy'},
      { defaultDate: moment(new Date(), 'dd-MM-yyyy')}
    );
    $('#toDate').datepicker('setDate', '-0d');
  }

  search() {
    
    var fromDate = $('#fromDate').val();
    //var fromDate2 = $('#fromDate').val();
    var toDate2 = $('#toDate').val();
    var toDate22 = $('#toDate').val();
     
     if (fromDate  === undefined || fromDate  === "" ) {
      this.toastr.warning('Please select from date', 'WARN!', {
        timeOut: 3000
      });
      return;
    }
    else{
      var dd =$('#fromDate').val().toString().substring(0,2)
      var mm =$('#fromDate').val().toString().substring(3,5)
      var yy = $('#fromDate').val().toString().substring(6,10)
      fromDate = mm + '-'  + dd + '-' + yy;
      fromDate = new Date(fromDate) ; 
     
    }
    if (toDate2 === undefined ||  toDate2 === "") {
      this.toastr.warning('Please select to date', 'WARN!', {
        timeOut: 3000
      });
      return;
    }
    else{
      var dd =$('#toDate').val().toString().substring(0,2)
      var mm =$('#toDate').val().toString().substring(3,5)
      var yy = $('#toDate').val().toString().substring(6,10)
      toDate2 = mm + '-'  + dd + '-' + yy;
      toDate2 = new Date(toDate2) ; 
      toDate2.setDate(toDate2.getDate()+1); 
      toDate22 = mm + '-'  + dd + '-' + yy;
      toDate22 = new Date(toDate22) ; 
    //  toDate22.setDate(toDate22.getDate()); 
    }
    this.registrationfilteredlist = this.applicantRegistrations;
    var SortArray = this.applicantRegistrations;
if(this.classcourseid != '0'){
  SortArray = SortArray.filter((applicantRegistration: ApplicantRegistration) =>
  applicantRegistration.classCourseId.toString() === this.classcourseid.toString());
}
SortArray = SortArray.filter((applicantRegistration: ApplicantRegistration) => {
      if (Math.floor(new Date(toDate22).getTime() / 86400000) === Math.floor(new Date(fromDate).getTime() / 86400000)) {
        return  Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000)  === Math.floor(new Date(toDate2).getTime() / 86400000);
      } else {
        return  new Date(applicantRegistration.registrationDate).getTime() >= new Date(fromDate).getTime() &&
        new Date(applicantRegistration.registrationDate).getTime() <= new Date(toDate2).getTime();
      }
    });
    this.registrationfilteredlist = SortArray;
    

    // if (this.classcourseid === '-1'){
    //   this.registrationfilteredlist = this.applicantRegistrations;
    // }
    // this.registrationfilteredlist = this.registrationfilteredlist.filter((item: ApplicantRegistration) => {
    //   return new Date(item.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
    //   new Date(item.registrationDate).getTime() <= new Date(this.toDate).getTime();
    // });
  
    // if (this.registrationSearchList.length > 0) {
    //   this.registrationfilteredlist = this.registrationSearchList;
    //   this.registrationfilteredlist = this.registrationSearchList.filter((item: ApplicantRegistration) => {
    //     return new Date(item.registrationDate) >= new Date(this.fromDate) &&
    //     new Date(item.registrationDate) <= new Date(new Date(this.toDate).getTime() + 330 * 60000);
    //   });
    // } else {
    //   this.registrationfilteredlist = this.applicantRegistrations;
    //   this.registrationfilteredlist = this.registrationfilteredlist.filter((item: ApplicantRegistration) => {
    //     return new Date(item.registrationDate) >= new Date(this.fromDate) &&
    //     new Date(item.registrationDate) <= new Date(new Date(this.toDate).getTime() + 330 * 60000);
    //   });
   
   
    // }
    // this.registrationfilteredlist = this.applicantRegistrations;
  }

  // onClassSelected(value: string) {
  //   if (value === '-1') { return; }
  //   if (value === '0') {
  //     // this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
  //       // console.log(data);
  //       this.registrationSearchList = this.registrationfilteredlist = this.applicantRegistrations; // = data;
  //     // });
  //   } else {
  //     // this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
  //       this.registrationSearchList = this.registrationfilteredlist = this.applicantRegistrations
  //       .filter((applicantRegistration: ApplicantRegistration) =>
  //       applicantRegistration.classCourseId.toString() === value.toString());
  //     // });
  //   }
  // }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (this.registrationSearchList.length === 0) {
    if ( keyword === '') {
      this.registrationfilteredlist = this.applicantRegistrations;
    } else {
    this.registrationfilteredlist = this.applicantRegistrations
                                .filter((applicantRegistration: ApplicantRegistration) =>
                                applicantRegistration.studentName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()) ||
                                applicantRegistration.registerNo.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()) ||
                                applicantRegistration.formNo.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()) ||
                                applicantRegistration.contactNumber.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
    }
  } else {
    if ( keyword === '') {
      this.registrationfilteredlist = this.registrationSearchList;
    } else {
    this.registrationfilteredlist = this.registrationSearchList
                                .filter((applicantRegistration: ApplicantRegistration) =>
                                applicantRegistration.studentName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()) ||
                                applicantRegistration.registerNo.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()) ||
                                applicantRegistration.formNo.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()) ||
                                applicantRegistration.contactNumber.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
    }
  }
  }

  get totalRows(): number {
    return this.registrationfilteredlist.length;
  }

  get sumRows(): number {
    return this.registrationfilteredlist.reduce((sum, current) => sum + parseFloat(current.applicationFee), 0);
  }
  private formatDate(date) {
    const d = new Date(date);
    debugger
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  parseDDate(value: any): Date | null {
    if (typeof value === 'string' && value.includes('/')) {
      const str = value.split('/');

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    } else if (typeof value === 'string' && value === '') {
      return new Date();
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  ngOnInit(): void {
    this.generateAttributes();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.GetCurrentAllcademicYear();

    $('#fromDate').datepicker({format: 'dd-mm-yyyy'},
    { defaultDate: moment(new Date(), 'dd-MM-yyyy')}
    ).on('changeDate', (e) => {
       this.fromDate =  new Date(e.date);
    });
    $('#fromDate').datepicker('setDate', '-0d');

    $('#toDate').datepicker(
      {format: 'dd-mm-yyyy'},
      { defaultDate: moment(new Date(), 'dd-MM-yyyy')}
    ).on('changeDate', (e) => {
      this.toDate = new Date(e.date);
    });
    $('#toDate').datepicker('setDate', '-0d');
    this.ListApplicantRegistration();
    this.ListClassCourse();
  }

}
