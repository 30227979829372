import { Component, OnInit } from '@angular/core';
import { AppBroadcast, AppBroadcastUserDetail } from '../../appbroadcast/appbroadcast.model';
import { AppBroadcastService } from '../../appbroadcast/appbroadcast.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-appbroadcastreport',
  templateUrl: './appbroadcastreport.component.html',
  styleUrls: ['./appbroadcastreport.component.css']
})
export class AppbroadcastreportComponent implements OnInit {

  appBroadcasts: AppBroadcast[] = [];
  filteredAppBroadcasts: AppBroadcast[] = [];
  tempAppBroadcasts: AppBroadcast[] = [];
  selectedAppBroadcastType = '';
  selectedStatus = '';
  fromDate = '';
  toDate = '';
  selectedMessageBody = '';


  constructor(
    private appBroadcastService: AppBroadcastService,
    private toastr: ToastrService
  ) { }

  listAppBroadcasts() {
    this.appBroadcastService.getAllAppBroadcasts().subscribe((data: AppBroadcast[]) => {
      console.log(data);
      this.appBroadcasts = data;
    });
  }

  filterAppBroadCast() {
    
    console.log(this.selectedAppBroadcastType);
    if (this.fromDate !== '' && this.toDate !== '') {
    if (this.selectedAppBroadcastType === 'Announcement') {
      this.filteredAppBroadcasts = this.appBroadcasts.filter((data: AppBroadcast) => {
          return data.isAnnouncement === 'True' &&
          data.createdDate >= this.fromDate && data.createdDate <= this.toDate;
      });
    } else if (this.selectedAppBroadcastType === 'Flash') {
      this.filteredAppBroadcasts = this.appBroadcasts.filter((data: AppBroadcast) => {
          return data.isFlash === 'True' &&
          data.createdDate >= this.fromDate && data.createdDate <= this.toDate;
      });
    } else {
      this.filteredAppBroadcasts = this.appBroadcasts.filter((data: AppBroadcast) => {
        return data.createdDate >= this.fromDate && data.createdDate <= this.toDate;
    });
    }

    if (this.filteredAppBroadcasts === null) {
      this.toastr.warning('Records not found!', 'Sorry', {
        timeOut: 4000
      });
    } else {
      this.tempAppBroadcasts = JSON.parse(JSON.stringify(this.filteredAppBroadcasts));
      this.filteredAppBroadcasts.map((item: AppBroadcast) => {
        return item.messageBody = item.messageBody.replace(/<img[^>]*>/g, '');
      });
    }
  } else {
    this.toastr.warning('All fields are mandatory !', 'Sorry', {
      timeOut: 4000
    });
  }
  }

  showMessageBody(index: number) {
    this.selectedMessageBody = this.tempAppBroadcasts[index].messageBody;
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.filteredAppBroadcasts = this.appBroadcasts;
    } else {
      this.filteredAppBroadcasts = this.appBroadcasts
        .filter((appBroadcast: AppBroadcast) => {
          return appBroadcast.broadcastTitle.toLowerCase()
            .includes(keyword.toLowerCase());
        }
        );
    }
  }

  resetAll() {
    this.filteredAppBroadcasts = [];
    this.selectedAppBroadcastType = '';
    this.selectedStatus = '';
  }

  ngOnInit(): void {
    this.listAppBroadcasts();
    this.fromDate = this.formatDate(new Date());
    this.toDate = this.formatDate(new Date());
    $('#fromDate').datepicker().on('changeDate', (e) => {
      console.log(new Date(e.date));
      this.fromDate = this.formatDate(e.date);
    });
    $('#toDate').datepicker().on('changeDate', (e) => {
      // console.log(new Date(e.date));
      this.toDate = this.formatDate(e.date);
    });
  }

}
