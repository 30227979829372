import { Component, OnInit } from '@angular/core';
import {ApplicantRegistrationService} from '../applicantregistration/applicantregistration.service';
import { ApplicantRegistration } from '../applicantregistration/applicantregistration.model';
import { ClassCourse } from '../mastersettings/models/classcourse';
import { ClassCourseService } from '../mastersettings/classcourse/classcourse.service';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ReportAttribute } from '../studentmanagement/report.model';
import { environment } from '../../../../environments/environment';
import { FunctionService } from 'src/app/shared/function.service';
import { SettingsService } from '../mastersettings/settings.service';
import { Academicyear } from '../mastersettings/models/academicyear';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-entrancesummary',
  templateUrl: './entrancesummary.component.html',
  styleUrls: ['./entrancesummary.component.css']
})
export class EntrancesummaryComponent implements OnInit {

  applicantRegistrations: ApplicantRegistration[] = [];
  registrationfilteredlist: ApplicantRegistration[] = [];
  checkedRegistrationList: number[] = [];
  classcourses: ClassCourse[] = [];
  classcourseid = '0';
  status = 'Select Status';
  fileName = 'EntranceSummaryReport.xlsx';
  formDt = '';
  toDateArr = [];
  allDate:any = []
  headerList: string[] = [];
  fieldList: string[] = [];
  academicYear:Academicyear[]=[];
  attributes: ReportAttribute[] = []; 
  institution: any;
  currentAcademicYearId: number;
  currentAcademicYearName = '';
  constructor(
    private registrationService: ApplicantRegistrationService,
    private classcourseService: ClassCourseService,
    public fun: FunctionService,
    public settingsService:SettingsService,
  ) { 
    this.GetCurrentAcademicYear()
  }

  // exportAsPDF() {
  //   
  //   const data = document.getElementById('registrationtable');
  //   html2canvas(data).then(canvas => {
  //     const contentDataURL = canvas.toDataURL('image/png');
  //     const pdf = new jspdf('l', 'cm', 'a4'); // Generates PDF in landscape mode
  //     // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
  //     pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
  //     pdf.save('EntranceSummaryReport.pdf');
  //   });
  // }

  GetCurrentAcademicYear() {
    this.settingsService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYear = data;
      this.currentAcademicYear();
    });
  }

  currentAcademicYear() {
    this.settingsService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.currentAcademicYearName = this.academicYear.find(x => x.id.toString() === data.id.toString()).name;
    });
  }

  generateAttributes() {
    this.attributes = [
      {name: 'S NO', fieldName: 'index' },
      {name: 'REG NO', fieldName: 'registerNo' },
      {name: 'APPLICANT ', fieldName: 'studentName' },
      {name: 'FATHER NAME', fieldName: 'fatherFullName' },
      {name: 'Class', fieldName: 'className' },
      {name: 'REG DATE', fieldName: 'registrationDate' },
      {name: 'Status', fieldName: 'applicationStatus' }
    ];
  }

  generatePDF() {
    this.headerList =[];
    this.fieldList =[];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    const widthList = [];
    for (const item of this.headerList) {
        widthList.push('14%');
    }
    for(var i = 0 ; i < this.registrationfilteredlist.length ; i++)
    this.registrationfilteredlist[i]["index"] = i+1;
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      content: [
        {
            table: {
                widths: ['auto', '*'],
                body: [
                    [ {
                        width: 100,
                        height: 100,
                        image: 'imageKey'
                    }, {
                        width: '*',
                        alignment: 'center',
                        stack: [
                            {
                                style: 'h1',
                                text: this.institution.institutionName
                            },
                            {
                              style: 'h2',
                              text: this.institution.address
                            },
                            {
                                style: 'h2',
                                text: this.currentAcademicYearName
                            },
                            {
                                style: 'h1',
                                text: 'Entrance Summary'
                            }
                        ]
                    }
                    ]
                ]
            },
            layout: {
              hLineWidth: () =>  0,
              vLineWidth: () => 0,
              paddingBottom: () => 5,
              paddingLeft:()=> 5,
              paddingRight:()=> 5
            },
          },
          {
            style:'sectionHeader',
            table: {
              headerRows: 1,
              widths: widthList,
              body: [
                this.headerList,
                ...this.registrationfilteredlist.map((p) => {
                  const temp = [];
                  this.allDate.push(p.registrationDate)
                  this.toDateArr.push(p.entranceDate)
                  for (const y of this.fieldList) {
                    if (y === 'dob') {
                      temp.push(this.formatDate(p[y]));
                    } else {
                      temp.push(p[y]);
                    }
                  }
                  return temp;
                })
              ]
            }
          }
      ],
      images: {
        imageKey: environment.imageUrl + this.institution.logoUrl // via URL address, which can be referenced by this key
      },
      styles: {
        sectionHeader: {
          fontSize: 8,
          margin: [0, 10, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 10,
          bold: true
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        }
      }
    };

    pdfMake.createPdf(docDefinition).download('ENTRANCE SUMMARY.pdf');
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  exportexcel() {
    
       /* table id is passed over here */
       const element = document.getElementById('registrationtable');
       const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Entrance Summary');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
    }

  ListApplicantRegistration() {
    this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
      console.log(data);
      this.applicantRegistrations = data;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
      console.log(this.classcourses);
    });
  }


  onSelectSearch(){
   // this.registrationfilteredlist = this.applicantRegistrations;
    var SortArray =  this.applicantRegistrations;
    var i = 0;
    if (this.classcourseid != '0') {
      SortArray = SortArray.filter((applicantRegistration: ApplicantRegistration) =>
      applicantRegistration.classCourseId.toString().toLocaleLowerCase() === this.classcourseid.toLocaleLowerCase());
   i =1;
    }
    if (this.status != 'Select Status') {
      SortArray = SortArray.filter((applicantRegistration: ApplicantRegistration) =>
      applicantRegistration.applicationStatus.toString().toLocaleLowerCase() === this.status.toLocaleLowerCase());
    }
    if( i ==1)
    this.registrationfilteredlist = SortArray;
    else
    this.registrationfilteredlist = [];
  }
  // onClassSelected(value: string) {
  //   console.log(value);
  //   if (value === '0') {
  //     // this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
  //       // console.log(data);
  //       this.registrationfilteredlist = this.applicantRegistrations; // = data;
  //     // });
  //   } else {
  //    // this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
  //       this.registrationfilteredlist = this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) =>
  //       applicantRegistration.classCourseId.toString().toLocaleLowerCase() === value.toLocaleLowerCase());
  //     // });
  //   }
  // }

  // onStatusSelected(value: string) {
  //   console.log(value);
  //   if (value === 'All Status' && this.classcourseid === '0') {
  //       this.registrationfilteredlist = this.applicantRegistrations;
  //   } else if (parseInt(this.classcourseid, 0) > 0 && value === 'All Status') {
  //     this.registrationfilteredlist = this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) =>
  //       applicantRegistration.classCourseId.toString().toLocaleLowerCase() === this.classcourseid.toLocaleLowerCase());
  //   } else if (parseInt(this.classcourseid, 0) > 0) {
  //     this.registrationfilteredlist = this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) =>
  //     applicantRegistration.applicationStatus.toString().toLocaleLowerCase() === value.toLocaleLowerCase() &&
  //     applicantRegistration.classCourseId.toString().toLocaleLowerCase() === this.classcourseid.toLocaleLowerCase());
  //   } else {
  //     this.registrationfilteredlist = this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) =>
  //     applicantRegistration.applicationStatus.toString().toLocaleLowerCase() === value.toLocaleLowerCase());
  //   }
  // }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.registrationfilteredlist = this.applicantRegistrations;
    } else {
    this.registrationfilteredlist = this.applicantRegistrations
                                .filter((applicantRegistration: ApplicantRegistration) =>
                                applicantRegistration.studentName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
    }
  }

  get totalRows(): number {
    return this.registrationfilteredlist.length;
  }


  ngOnInit(): void {
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.generateAttributes();
    this.ListApplicantRegistration();
    this.ListClassCourse();
  }

}
