import { Component, computed, inject, signal } from '@angular/core';

import { ClassCourseService } from '../../../mastersettings/classcourse/classcourse.service';
import { ClassCourse } from '../../../mastersettings/models/classcourse';
import{ toSignal } from '@angular/core/rxjs-interop';
import { SettingsService } from '../../../mastersettings/settings.service';
import { Batch } from '../../../mastersettings/models/batch';
import { BatchService } from '../../../mastersettings/batch/batch.service';
import { OutstandingDueReportModel } from '../outstandingduereport/outstandingduereport.model';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-outstandingduereportnew',
  templateUrl: './outstandingduereportnew.component.html',
  styleUrl: './outstandingduereportnew.component.css'
})
export class OutstandingduereportnewComponent {






  classService=inject(ClassCourseService);
  batchService=inject(BatchService);
  academicYearService=inject(SettingsService);
  
  status=signal<string>("All");
  classes=toSignal<ClassCourse[],ClassCourse[]>(this.classService.getAll(),{initialValue:[] as ClassCourse[]});
  batches=toSignal<Batch[],Batch[]>(this.batchService.getAll(),{initialValue:[] as Batch[]});
  currentAcademicYear=toSignal(this.academicYearService.getCurrentAcademicYear());
  selectedBatch=signal<Batch[]>(undefined);
  selectedBatchId=signal<number>(-1);
  selectedClassId=signal<number>(-1);
  fromDate=signal<string>("");
  toDate=signal<string>("");
  outstandingDueReportReport=signal<OutstandingDueReportModel[]>(undefined);
  onClassSelected(classId:number) {
    const selectedBatch=computed(()=>this.batches().filter(x=>x.classId==classId));
    this.selectedBatch.set(selectedBatch());
    this.selectedClassId.set(classId);
    }
    onBatchSelected(batchId: number) {
      this.selectedBatchId.set(batchId);
      alert(this.selectedBatchId())
    }

    tdtchange(event: any) {
      const tdt = event.target.value
      /* if (tdt == null || tdt == undefined || tdt == '') {
        //this.dateValidation = false;
        this.toDate = "";
      } */
      this.toDate.set(tdt);
    }
    fdtchange(event: any) {
      const tdt = event.target.value
      /* if (tdt == null || tdt == undefined || tdt == '') {
        this.dateValidation = false;
        this.fromDate = "";
      } */
      this.fromDate.set(tdt);
    }

    onSearch() {
       
     /*  if (this.fromDate != undefined && this.fromDate != '' && this.fromDate != null &&
        this.toDate != undefined && this.toDate != '' && this.toDate != null && this.dateValidation != false) {
        const fromDate = this.fromDate();
        const toDate = this.toDate;
        console.log(drpBatch);
        let startDt: Date;
        let endDt: Date;
        const datePipe = new DatePipe('en-Us');
        var fdt = datePipe.transform(this.fromDate(), 'yyyy-MM-dd');
        var tdt = datePipe.transform(this.toDate(), 'yyyy-MM-dd');
        this.dates = fdt + ' To ' + tdt;
        let details = [{
          fromdate: fdt,
          todate: tdt,
          class: this.c,
          batch: drpBatch,
          status: drpStatus
        }]
        //;
        this.outstandingDueService.getOutstandingDueSearchReport(details).subscribe((data: OutstandingDueReportModel[]) => {
          debugger;
          this.loader = false;
          this.outstandingDueReportList = data.filter(x=>x.installment!="0");
          if (this.outstandingDueReportList.length > 0) {
            this.outstandingDueReportReportFilteredList = this.outstandingDueReportList;
            this.outstandingDueReportTemp = this.outstandingDueReportList = this.outstandingDueReportList;
            this.outstandingDueReportReportFilteredList = this.outstandingDueReportTemp;
            this.totalDueAmount = this.outstandingDueReportReportFilteredList.map(item =>
              parseFloat(item.totalDueAmount)).reduce((prev, curr) => prev + curr, 0);
          }else{
            this.outstandingDueReportReportFilteredList=[];
          }
  
        });

      } else {
        this.dateValidation = false;
        if (this.fromDate == undefined || this.fromDate == '' || this.fromDate == null ||
          this.toDate == undefined || this.toDate == '' || this.toDate == null) {
          this.toastr.warning('Please select date', 'WARNING!', {
            timeOut: 3000
          });
        } else {
          if (this.fromDate > this.toDate) {
            this.toastr.warning('FromDate is less than ToDate', 'WARNING!', {
              timeOut: 3000
            });
          }
        }
  
      }  */
  
    }

}
