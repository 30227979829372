import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Roles, RolesLinkedPagePermission } from './audit.model';


@Injectable({
  providedIn: 'root'
})
export class AuditService {


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }

  saveRolesLinkedPermissions(permissions): Observable<RolesLinkedPagePermission[]> {
    return this.httpClient.post<RolesLinkedPagePermission[]>(environment.apiUrl + '/SaveRolesLinkedPermissions',JSON.stringify(permissions), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getRolesLinkedPermissions(): Observable<RolesLinkedPagePermission[]> {
    return this.httpClient.get<RolesLinkedPagePermission[]>(environment.apiUrl + '/GetRolesLinkedPermissions/')
    .pipe(
      catchError(this.errorHandler)
    );
  } 

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }


}
