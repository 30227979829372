import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Installment } from './installment.model';
import { InstallmentService } from './installment.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { SettingsService } from '../../mastersettings/settings.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-installment',
  templateUrl: './installment.component.html',
  styleUrls: ['./installment.component.css']
})
export class InstallmentComponent implements OnInit {

  installmentForm: UntypedFormGroup;
  installments: Installment[] = [];
  installmentFilteredList: Installment[] = [];
  currentAcademicYear: Academicyear = {} as Academicyear;
  academicYears: Academicyear[] = [];
  academicYearIdSelected = '';
  // classes: ClassCourse[] = [];
  // resClassIds: any[] = [];
  // selectedClassIds = '';
  submitted = false;
  isLoading:boolean=false;
  HighlightRow : Number;
  startDate:Date;
  endDate:Date;
  paymentDate:Date;
  lastPayDate:Date;
  ClickedRow:any;

  constructor(
    public installmentService: InstallmentService,
    private academicYearService: SettingsService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private datepipe:DatePipe
  ) 
  { 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }

  }

  public formatDate(date) {
    debugger;
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('-');
  }

  public datePickerFormate(date) {
    const [day, month, year] = date.split('-');
    const d = new Date(`${year}-${month}-${day}`);
    
    const formattedDay = ('0' + d.getDate()).slice(-2);
    const formattedMonth = ('0' + (d.getMonth() + 1)).slice(-2);
    const formattedYear = d.getFullYear();
    
    return [formattedDay, formattedMonth, formattedYear].join('-');  
  }

  validateDate(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      console.log(Date.parse(control.value));
      console.log(Date.parse(matchingControl.value));
      // set error on matchingControl if validation fails
      if (Date.parse(control.value) > Date.parse(matchingControl.value)) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  validateDateRule(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      console.log(Date.parse(control.value));
      console.log(Date.parse(matchingControl.value));
      // set error on matchingControl if validation fails
      if (Date.parse(control.value) < Date.parse(matchingControl.value)) {
        matchingControl.setErrors({ mustMatchDate: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  GetCurrentAcademicYear() {
    this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      console.log(data);
      this.currentAcademicYear = data;
      this.installmentForm.get('academicYearId').patchValue(data.id);
    });
  }

  ListAcademicYear() {
    this.academicYearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
    });
  }

  ListInstallment() {
    this.isLoading=true;
    this.installmentService.getAll().subscribe((data: Installment[]) => {
      this.installmentFilteredList = this.installments = data;
      this.isLoading=false;
    });
  }

  onFilterSelected(value){
    if (value === '') {
      this.installmentFilteredList = this.installments;
    } else {
      this.installmentFilteredList = this.installments
        .filter((installment: Installment) => installment.stypeId === value);
    }
  }

  onAcademicYearChange(value: string) {
    if (value === '') {
      this.installmentFilteredList = this.installments;
    } else {
      this.installmentFilteredList = this.installments
        .filter((installment: Installment) => installment.academicYearId.toString() === value);
    }
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === '') {
      this.installmentFilteredList = this.installments;
    } else {
      this.installmentFilteredList = this.installments
        .filter((installment: Installment) => installment.installmentName.toLocaleLowerCase()
          .includes(keyword.toLocaleLowerCase())
        );
    }
  }

  get totalRows(): number {
    return this.installmentFilteredList.length;
  }
  get f() { return this.installmentForm.controls; }
  resetForm() {
    this.submitted = false;
    this.installmentForm = this.fb.group({
      id: [0],
      academicYearId: ['', Validators.required],
      installmentName: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      paymentDate: ['', Validators.required],
      lastPayDate: ['', Validators.required],
      description: [''],
      status: ['true', Validators.required],
      stypeId:['',Validators.required]
    }, {
      validator: [this.validateDate('startDate', 'endDate'), this.validateDate('startDate', 'paymentDate'),
      this.validateDate('paymentDate', 'lastPayDate'), this.validateDate('lastPayDate', 'endDate'),
      this.validateDateRule('endDate', 'paymentDate'), this.validateDateRule('endDate', 'lastPayDate')]
    });
    this.GetCurrentAcademicYear();
    this.HighlightRow=-1;
  }

  FormattingDate(){
    
  }
  private formtDte(date:Date):string{
    let newDt=Intl.DateTimeFormat('en-US',{
      year:'numeric',
      month:'long',
      day:'numeric',
    }).format(date);
    return newDt;
  }

  private convertToDDMMYY(strdate:string):string{
    const date=new Date(strdate);
    const day=date.getDate().toString().padStart(2,'0');
    const month= (date.getMonth()+1).toString().padStart(2,'0');
    const year =date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  submitForm() {
   debugger;
    this.submitted = true;
    console.log(this.installmentForm.value);
    this.startDate = this.installmentForm.get('startDate').value;
    this.endDate = this.installmentForm.get('endDate').value;
    this.paymentDate = this.installmentForm.get('paymentDate').value;
    this.lastPayDate = this.installmentForm.get('lastPayDate').value;
    //  if (thisstartDate !== '') {
    //     let sDate = moment()
        let sdate=moment(this.startDate).format('DD-MM-YYYY');
        if(sdate =="Invalid date")
        {
          this.installmentForm.get('startDate').setValue(this.startDate);
        }
        else{
          this.installmentForm.get('startDate').patchValue(this.datePickerFormate(sdate));
        }
    //  }
    //  if (endDate !== '') {
    //   //const parsedDate=this.formatDate(endDate);
    
    let edate=moment(this.endDate).format('DD-MM-YYYY');
    if(edate=="Invalid date"){
      // const [day,month,year]=this.endDate.toString().split('-');
      // const parsEndDate=new Date(Number(year),Number(month)-1,Number(day),0,0,0,0);
      // let parsedEndDate=this.formtDte(parsEndDate);
      // this.installmentForm.get('endDate').patchValue(parsedEndDate);
      this.installmentForm.get('endDate').setValue(this.endDate);
    }
    // else if(!(this.endDate instanceof Date))
    // {
    //   let parsedEndDate=this.formtDte(this.endDate);
    //   this.installmentForm.get('endDate').patchValue(this.formatDate(parsedEndDate));
    // }
    else{
     // this.installmentForm.get('endDate').patchValue(this.formatDate(edate));
     let eg= this.convertToDDMMYY(this.endDate.toString());
      this.installmentForm.get('endDate').setValue(eg);
    }
   

   
       let pdate=moment(this.paymentDate).format('DD-MM-YYYY');
       if(pdate=="Invalid date")
       {
        this.installmentForm.get('paymentDate').setValue(this.paymentDate);
       }
      else{
        this.installmentForm.get('paymentDate').patchValue(this.datePickerFormate(pdate));
      }
   


      let lpdate=moment(this.lastPayDate).format('DD-MM-YYYY');
      if(lpdate=="Invalid date"){

        // const [day,month,year]=this.lastPayDate.toString().split('-');
        // const parsLastPayDate=new Date(Number(year),Number(month)-1,Number(day),0,0,0,0);
        // let parsedLastPaydDate=this.formtDte(parsLastPayDate);
        // this.installmentForm.get('lastPayDate').patchValue(parsedLastPaydDate);
        this.installmentForm.get('lastPayDate').setValue(this.lastPayDate);
      }
      // else if(!isNaN(this.lastPayDate.getTime())){
      //   let parsedLastPaydDate=this.formtDte(this.lastPayDate);
      //   this.installmentForm.get('lastPayDate').patchValue(this.formatDate(parsedLastPaydDate));
      // }
      else{
        //this.installmentForm.get('lastPayDate').patchValue(this.formatDate(lpdate));
        let eg2= this.convertToDDMMYY(this.lastPayDate.toString());
        this.installmentForm.get('lastPayDate').setValue(eg2);
      }
    

    if (this.installmentForm.invalid) {
      if (this.f.endDate.errors !== null && this.f.endDate.errors.mustMatch) {
        this.toastr.warning('End date should not less than start date ', 'WARNING!!!', {
          timeOut: 3000
        });
      }
      if (this.f.lastPayDate.errors !== null && this.f.lastPayDate.errors.mustMatch) {
        this.toastr.warning('Lastpay date should not less than payment date ', 'WARNING!!!', {
          timeOut: 3000
        });
      }
      if (this.f.paymentDate.errors !== null && this.f.paymentDate.errors.mustMatch) {
        this.toastr.warning('Payment date should not less than start date ', 'WARNING!!!', {
          timeOut: 3000
        });
      }
      if (this.f.lastPayDate.errors !== null && this.f.lastPayDate.errors.mustMatchDate) {
        this.toastr.warning('Lastpay date should not greater than end date ', 'WARNING!!!', {
          timeOut: 3000
        });
      }
      if (this.f.paymentDate.errors !== null && this.f.paymentDate.errors.mustMatchDate) {
        this.toastr.warning('Payment date should not greater than end date ', 'WARNING!!!', {
          timeOut: 3000
        });
      }
      return;
    }

   
    /*if (paymentDate < startDate) {
      this.toastr.warning('Payment date should not less than start date ', 'WARNING!!!', {
        timeOut: 3000
      });
      return;
    }*/
   ;
    if (this.installmentForm.get('id').value === 0) {
      // this.installmentForm.get('startDate').patchValue(this.formatDate(startDate));
      // this.installmentForm.get('endDate').patchValue(this.formatDate(endDate));
      // this.installmentForm.get('paymentDate').patchValue(this.formatDate(paymentDate));
      // this.installmentForm.get('lastPayDate').patchValue(this.formatDate(lastPayDate));
      this.installmentService.create(this.installmentForm.value).subscribe(res => {
        console.log('Installment created!');
        this.ListInstallment();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        //this.HighlightRow=-1;
      });
    } else {
     ;
      this.installmentService.update(this.installmentForm.get('id').value, this.installmentForm.value).subscribe(res => {
        console.log('Installment updated!');
        this.ListInstallment();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        //this.HighlightRow=0;
      });
    }
  }
  updateForm(id: number) {
    this.installmentService.getById(id).subscribe((data: Installment) => {
      console.log(data);
      this.installmentForm = this.fb.group({
        id: [data.id],
        academicYearId: [data.academicYearId, Validators.required],
        installmentName: [data.installmentName, Validators.required],
        startDate: [this.formatDate(data.startDate), Validators.required],
        endDate: [this.formatDate(data.endDate), Validators.required],
        paymentDate: [this.formatDate(data.paymentDate), Validators.required],
        lastPayDate: [this.formatDate(data.lastPayDate), Validators.required],
        description: [data.description],
        status: ['true', Validators.required],
        stypeId:[data.stypeId, Validators.required],
      }, {
        validator: [this.validateDate('startDate', 'endDate'), this.validateDate('startDate', 'paymentDate'),
        this.validateDate('paymentDate', 'lastPayDate'), this.validateDate('lastPayDate', 'endDate'),
        this.validateDateRule('endDate', 'paymentDate'), this.validateDateRule('endDate', 'lastPayDate')]
      });
    });

  }

  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.installmentService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListInstallment();
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }

  ngOnInit(): void {
    this.ListAcademicYear();
    this.ListInstallment();
    this.GetCurrentAcademicYear();
    this.installmentForm = this.fb.group({
      id: [0],
      academicYearId: ['', Validators.required],
      installmentName: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      paymentDate: ['', Validators.required],
      lastPayDate: ['', Validators.required],
      description: [''],
      status: ['true', Validators.required],
      stypeId: ['', Validators.required]
    }, {
      validator: [this.validateDate('startDate', 'endDate'), this.validateDate('startDate', 'paymentDate'),
      this.validateDate('paymentDate', 'lastPayDate'), this.validateDate('lastPayDate', 'endDate'),
      this.validateDateRule('endDate', 'paymentDate'), this.validateDateRule('endDate', 'lastPayDate')]
    });

    $('#startDate').datepicker({
      format: "dd-mm-yyyy"
    }).on('changeDate', (e) => {
      if (e.date !== '' && e.date != null && e.date !== undefined) {
        this.installmentForm.get('startDate').patchValue(new Date(e.date));
      }
    });

    // $('#startDate').datepicker().on('show', (e) => {
    //   if (this.installmentForm.get('startDate').value !== '') {
    //     $('#startDate').datepicker('setDate', this.installmentForm.get('startDate').value);
    //   } // else {
    //   //   $('#startDate').datepicker('setDate', '');
    //   // }
    // });

    $('#endDate').datepicker(
      {
        format: "dd-mm-yyyy"
      }
    ).on('changeDate', (e) => {
      //this.installmentForm.get('endDate').patchValue(e.date);
      if (e.date !== '' && e.date != null && e.date !== undefined) {
          this.installmentForm.get('endDate').patchValue(new Date(e.date));
      }
    });
    // $('#endDate').datepicker().on('show', (e) => {
    //   if (this.installmentForm.get('endDate').value !== '') {
    //     $('#endDate').datepicker('setDate', this.installmentForm.get('endDate').value);
    //   } else {
    //     $('#endDate').datepicker('setDate', '');
    //   }
    // });

    $('#paymentDate').datepicker(
      {
        format: "dd-mm-yyyy"
      }
    ).on('changeDate', (e) => {
      ///this.installmentForm.get('paymentDate').patchValue(e.date);
      if (e.date !== '' && e.date != null && e.date !== undefined) {
        this.installmentForm.get('paymentDate').patchValue(e.date);
      }
    });
    // $('#paymentDate').datepicker().on('show', (e) => {
    //   if (this.installmentForm.get('paymentDate').value !== '') {
    //     $('#paymentDate').datepicker('setDate', this.installmentForm.get('paymentDate').value);
    //   } else {
    //     $('#paymentDate').datepicker('setDate', '');
    //   }
    // });
    $('#lastPayDate').datepicker(
      {
        format: "dd-mm-yyyy"
      }
    ).on('changeDate', (e) => {
      //this.installmentForm.get('lastPayDate').patchValue(e.date);
      if (e.date !== '' && e.date != null && e.date !== undefined) {
        this.installmentForm.get('lastPayDate').patchValue(e.date);
      }
    });
    // $('#lastPayDate').datepicker().on('show', (e) => {
    //   console.log(this.installmentForm.get('lastPayDate').value);
    //   if (this.installmentForm.get('lastPayDate').value !== '') {
    //     $('#lastPayDate').datepicker('setDate', this.installmentForm.get('lastPayDate').value);
    //   } else {
    //     $('#lastPayDate').datepicker('setDate', '');
    //   }
    // });
  }

}
