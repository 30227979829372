// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active{
    background-color:#eef0f3 !important;
    color: #1b1919;
  }
  .twitter-bs-wizard .twitter-bs-wizard-nav {
    padding: 4px;
    background-color: rgb(239 212 212 / 53%);
}
.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active {
  color: #fff;
  background-color: #556ee6 !important;
}
.reportCardPre{
  height: auto;max-width: 745px; 
  border: 2px solid gray;
    border-radius: 3px;
}
.reportCardCh{
  width: 215px;
    border: 2px solid gray;
    border-radius: 3px;
}
a{
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/academic/offline-examination/publish-result-report/publish-result-report.component.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,cAAc;EAChB;EACA;IACE,YAAY;IACZ,wCAAwC;AAC5C;AACA;EACE,WAAW;EACX,oCAAoC;AACtC;AACA;EACE,YAAY,CAAC,gBAAgB;EAC7B,sBAAsB;IACpB,kBAAkB;AACtB;AACA;EACE,YAAY;IACV,sBAAsB;IACtB,kBAAkB;AACtB;AACA;EACE,eAAe;AACjB","sourcesContent":[".active{\r\n    background-color:#eef0f3 !important;\r\n    color: #1b1919;\r\n  }\r\n  .twitter-bs-wizard .twitter-bs-wizard-nav {\r\n    padding: 4px;\r\n    background-color: rgb(239 212 212 / 53%);\r\n}\r\n.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active {\r\n  color: #fff;\r\n  background-color: #556ee6 !important;\r\n}\r\n.reportCardPre{\r\n  height: auto;max-width: 745px; \r\n  border: 2px solid gray;\r\n    border-radius: 3px;\r\n}\r\n.reportCardCh{\r\n  width: 215px;\r\n    border: 2px solid gray;\r\n    border-radius: 3px;\r\n}\r\na{\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
