import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TemplatesettingComponent } from './templatesetting/templatesetting.component';
import { TemplatelistComponent } from './templatelist/templatelist.component';
import { GeneratecertificateComponent } from './generatecertificate/generatecertificate.component';
import { CertificatereportComponent } from './certificatereport/certificatereport.component';
import { PrintcertificateComponent } from './printcertificate/printcertificate.component';
import { GeneratetcComponent } from './generatetc/generatetc.component';
import { TcreportComponent } from './tcreport/tcreport.component';

const routes: Routes = [
  {path: 'templatesetting', component: TemplatesettingComponent},
  {path: 'templatesetting/edit/:id', component: TemplatesettingComponent},
  {path: 'templatelist', component: TemplatelistComponent},
  {path: 'generatecertificate', component: GeneratecertificateComponent},
  {path: 'certificatereport', component: CertificatereportComponent},
  {path: 'printcertificate/:id', component: PrintcertificateComponent},
  {path: 'generatetc', component: GeneratetcComponent},
  {path:'tcreport',component:TcreportComponent},
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CertificateRoutingModule { }
