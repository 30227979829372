import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GradescaleService } from '../../services/gradescale.service';
import { GradescalegroupService } from '../../services/gradescalegroup.service';
import { EvaluationType } from '../models/evaluation-type.model';
import { GradeScaleGroup } from '../models/grade-scale-group.model';
import { GradeScale } from '../models/grade-scale.model';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-grade-scale',
  templateUrl: './grade-scale.component.html',
  styleUrls: ['./grade-scale.component.css']
})
export class GradeScaleComponent implements OnInit {

  gradeScale=new GradeScale(0,'',0,0,0,0,0);
  evaluationType:EvaluationType[]=[];
  gradeScaleGroup:GradeScaleGroup[]=[];
  gradeScales:GradeScale[]=[];
  showEvaluation:boolean=false;
  showGradeScale:boolean=false;
  selectedGradeScaleId:number;
  selectedEvaluationId:number;
  addRow:boolean=false;
  submitted = false;
  gradeScaleForm:UntypedFormGroup;
  editedId:number;
  isEdited:boolean=false;
  selected:number;
  
  constructor(private gradeGroupService: GradescalegroupService,
    private gradeScaleService:GradescaleService,
    public fb: UntypedFormBuilder,
    private toastr:ToastrService) { }

  get f() { return this.gradeScaleForm.controls; }
  ngOnInit(): void {
    this.gradeScaleForm=this.fb.group({
      id:[0],
      grade:['',Validators.required],
      minimumPercentage:['',Validators.required],
      maximumPercentage:['',Validators.required],
      gradePoint:['',Validators.required],
      gradeScaleGroupId:[0],
      evaluationId:[0]
    });
    this.getAllGradeScaleGroup();
    this.getAllEvalutionTypes();
  }
getAllEvalutionTypes(){
    this.gradeGroupService.getAllEvalutaionTypes().subscribe((data:EvaluationType[])=>{
     
      this.evaluationType=data;
    });
}

  getAllGradeScaleGroup(){
    this.gradeGroupService.getAllGradeScale().subscribe((data:GradeScaleGroup[])=>{
      this.gradeScaleGroup=data;
    })
  }

  selectGradeScaleGroup(event){
    this.showEvaluation=true;
    this.showGradeScale=false;
    this.addRow=false;
    this.selected=0;
    this.selectedGradeScaleId=event.target.value;
    // this.gradeScaleService.getEvaluationsTypesByGradeScale(this.selectedGradeScaleId).subscribe((data:EvaluationType[])=>{
    //   this.evaluationType=data;
    // })
  }

  getAllGradeScale()
  {
    this.gradeScaleService.getAllGradeScale(this.selectedGradeScaleId,this.selectedEvaluationId).subscribe((data:GradeScale[])=>{
      this.gradeScales=data;
      this.addRow=this.gradeScales.length==0?true:false;
    })
  }

  selectEvaluation(id,i){
    if(this.selectedGradeScaleId!=0 && this.selectedGradeScaleId!=null && this.selectedGradeScaleId!=undefined){
      this.showGradeScale=true;
      this.selectedEvaluationId=id;
      this.selected=i+1
      this.getAllGradeScale();
    }else{
      this.showGradeScale=false;
      this.toastr.warning('All field are required','WARNING!!!',{timeOut:3000});
    }
   
  }

  addNewRow(){
    this.addRow=true;
  }

  submitForm(){
    this.submitted=true;
    if(this.gradeScaleForm.invalid){
      this.toastr.warning('All field are required','WARNING!!!',{timeOut:3000});
      return;
    }
    if(this.gradeScaleForm.get('id').value===0)
    { 
      var id=this.gradeScaleForm.get('id').value;
      var grade=this.gradeScaleForm.get('grade').value;
      var minimumPercentage=this.gradeScaleForm.get('minimumPercentage').value;
      var maximumPercentage=this.gradeScaleForm.get('maximumPercentage').value;
      var gradePoint=this.gradeScaleForm.get('gradePoint').value;
      var gradeScaleGroupId= this.selectedGradeScaleId;
      var evaluationId=this.selectedEvaluationId;

      var data=new GradeScale(id,grade,minimumPercentage,maximumPercentage,gradePoint,gradeScaleGroupId,evaluationId);
      this.gradeScaleService.createGradeScale(data).subscribe(res=>{
        this.toastr.success("Grade Scale successfully created","SUCCESS!",{timeOut:3000});
        this.submitted=false;
        this.cancel();
        this.getAllGradeScale();
      })
    }
    else
    {
      this.gradeScaleService.updateGradeScale(this.gradeScaleForm.get('id').value,this.gradeScaleForm.value).subscribe(res=>{
        this.toastr.success("Grade Scale successfully updated","SUCCESS!",{timeOut:3000});
        this.cancel();
        this.getAllGradeScale();
      })
    }
  }

  cancel(){
    this.gradeScaleForm=this.fb.group({
      id:[0],
      grade:['',Validators.required],
      minimumPercentage:['',Validators.required],
      maximumPercentage:['',Validators.required],
      gradePoint:['',Validators.required],
      gradeScaleGroupId:[0],
      evaluationId:[0]
    });
    this.submitted=false;
    this.addRow=false;
    this.isEdited=false;
    this.editedId=null;
  }

  edit(id){
    this.editedId=id;
    this.isEdited=true;
    this.gradeScaleService.getGradeScaleById(id).subscribe((data:GradeScale)=>{
      this.gradeScaleForm=this.fb.group({
        id:[data.id],
        grade:[data.grade,Validators.required],
        minimumPercentage:[data.minimumPercentage,Validators.required],
        maximumPercentage:[data.maximumPercentage,Validators.required],
        gradePoint:[data.gradePoint,Validators.required],
        gradeScaleGroupId:[data.gradeScaleGroupId],
        evaluationId:[data.evaluationId]
      });
    })    
  }

  delete(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton:true,
      confirmButtonText:'Yes, delete it!',
      cancelButtonText:'No, keep it'
    }).then((result)=>{
      if(result.value){
        this.gradeScaleService.deleteGradeScale(id).subscribe(res=>{
          Swal.fire(
            'Deleted!',
            'Grade Scale has been deleted.',
            'success'
          );
          this.getAllGradeScale();
        })  
      }
    })    
  }
}
