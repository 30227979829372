import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ExamSchedulngService } from '../../services/exam-schedulng.service';
//import { CbseWeightage } from 'src/app/admin/offline-examination/ExamSetting/models/cbse-weightage.model';
declare var $: any;
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-exam-scheduling',
  templateUrl: './exam-scheduling.component.html',
  styleUrls: ['./exam-scheduling.component.css']
})
export class ExamSchedulingComponent implements OnInit {
  loader:any=false;
  submitted = false;
  categorydropDown: any = [];
  dates:any;
  starttime:any;
  endtime:any;
  examNamedropDown: any = [];
  finalexamNamedropDown: any = [];
  finalBatchNamedropDown: any = [];
  finalClassNamedropDown: any = [];
  examClassdropDown: any = [];
  classDropDown: any = [];
  scheduleForm:UntypedFormGroup;
  marksEntryList: any = [];
  classBatachData: any = [];
  section: any = [];



  constructor(private toastr:ToastrService,public fb: UntypedFormBuilder,
    public service: ExamSchedulngService) { }

  

  ngOnInit(): void {
    this.scheduleForm=this.fb.group({
      id:[0],
      examCategory:['',Validators.required],
      examName:['',Validators.required],
      classId:['',Validators.required],
    });
    
      this.getCategoryDropDown();
  }
  get f() { return this.scheduleForm.controls; }
  getCategoryDropDown(){
    this.service.getAllCatefgoryDropDown().subscribe(data=>{
      this.categorydropDown=data;
     
    })
  }
  getNameDropDown(){
    this.service.getExamNameList().subscribe(data=>{
      this.examNamedropDown=data;
      this.finalexamNamedropDown=this.examNamedropDown.filter((filterList: any) =>
        filterList.examCategoryId === this.scheduleForm.value.examCategory
    );
    })
  }
  getClassDropDown(){

    this.service
      .getAllClass(this.scheduleForm.value.examName)
      .subscribe((data) => {
        this.examClassdropDown = data;
        this.examschedulerList=[];
        this.dates="";
        this.starttime="";
        this.endtime="";
        this.classDropDown = [
          ...new Map(
            this.examClassdropDown.map((item) => [item["className"], item])
          ).values(),
        ];
        this.marksEntryList = [];
        // this.checkingButton();
      });


    this.service.getAllClass(this.scheduleForm.value.examName).subscribe(data=>{
        this.examClassdropDown=data;
        console.log(this.examClassdropDown);
      });
      }


      selectedClass(id) {
        let batchList = [];
        this.dates="";
        this.starttime="";
        this.endtime="";
        this.examschedulerList=[];
        if (this.scheduleForm.value.classId !== "") {
          batchList = this.examClassdropDown.filter((element) => element.id == id);
          this.classBatachData = batchList;
          // this.checkingButton();
        }
      }


      selectedClassBatch() {
         
        if (this.scheduleForm.value.classBatch !== "") {
          this.section = this.classBatachData.filter(
            (element) =>
              element.batchId === this.scheduleForm.controls.classBatch.value
          );
         // this.checkingButton();
        }
      }
  
  

  examschedulerList: any = [];
  batchAflag: boolean = false;
  batchBflag: boolean = false;
  getschedullerList(){
    // if (this.scheduleForm.invalid) {
    //   return;
    // }
    if(this.scheduleForm.invalid){
      this.toastr.warning('All field are required','WARNING!!!',{timeOut:3000});
      return;
    }
    let details=[{
      categoryid:this.scheduleForm.value.examCategory,
      examid:this.scheduleForm.value.examName,
      classid:this.scheduleForm.value.classId
      
    }]
    this.service.getschedulerList(details).subscribe(data=>{
      this.examschedulerList=data;
      this.dates="";
      this.starttime="";
      this.endtime="";
      if(this.examschedulerList.length>0)
      {
        for(let m of this.examschedulerList){
          if( m.examDate){
            m.examDate = this.formatDate(m.examDate)
          }
        }
        for(let m of this.examschedulerList){
          let tempsection = m.section.includes("|")
          if(!tempsection){
            if(m.section == 'A'){
              this.batchAflag = true;
            }
            if(m.section == 'B'){
              this.batchBflag = true;
            }
          }
          if(tempsection ){
            this.batchAflag = true;
            this.batchBflag = true;
          }
        }
        console.log(this.examschedulerList)
      }
      else
      {
        console.log(this.examschedulerList)
        this.toastr.warning('subject not assign ! Please assign the subject','WARNING!!!',{timeOut:3000});
        return;
      }
      
    })
  }

  resetForm(){
    this.loader=true;
    this.submitted = false;
    this.scheduleForm=this.fb.group({
      id:[0],
      examCategory:['',Validators.required],
      examName:['',Validators.required],
      classId:['',Validators.required],
    });
    this.loader=false;
  }

  submitForm(){      
   
  }
  onCategoryEvent() {
    this.scheduleForm.value.examName = '';
    this.finalexamNamedropDown=[];
    this.dates="";
    this.starttime="";
    this.endtime="";
    this.examschedulerList=[];
    this.getNameDropDown();
  }
  copyMarking: any;
  examDate: any ;
  copydate(){
    if(this.dates!="" && this.dates!=null && this.dates!=undefined){
      for(var i=0;i<this.examschedulerList.length;i++){
        this.examschedulerList[i].examDate=this.dates
      }
    }
    
  }
  copystartime() {
    // var timeSplit = data.startTime.split(':'),
    // hours,
    // minutes,
    // meridian;
    // hours = timeSplit[0];
    // minutes = timeSplit[1];
    // if (hours > 12) {
    //   meridian = 'PM';
    //   hours -= 12;
    // } else if (hours < 12) {
    //   meridian = 'AM';
    //   if (hours == 0) {
    //     hours = 12;
    //   }
    // } else {
    //   meridian = 'PM';
    // }
    // debugger;
    // data.startTime = hours + ':' + minutes + ' ' + meridian
    
    if(this.starttime!="" && this.starttime!=null && this.starttime!=undefined){
    for(var i=0;i<this.examschedulerList.length;i++){
      this.examschedulerList[i].startTime=this.starttime
    }
  }
  // alert(hours + ':' + minutes + ' ' + meridian);
    
  }
  copyendtime() {
    // var timeSplit = data.endTime.split(':'),
    // hours,
    // minutes,
    // meridian;
    // hours = timeSplit[0];
    // minutes = timeSplit[1];
    // if (hours > 12) {
    //   meridian = 'PM';
    //   hours -= 12;
    // } else if (hours < 12) {
    //   meridian = 'AM';
    //   if (hours == 0) {
    //     hours = 12;
    //   }
    // } else {
    //   meridian = 'PM';
    // }
    // debugger;
    // data.endTime = hours + ':' + minutes + ' ' + meridian
    if(this.endtime!="" && this.endtime!=null && this.endtime!=undefined){
    for(var i=0;i<this.examschedulerList.length;i++){
      this.examschedulerList[i].endTime=this.endtime
    }
  }
  // alert(hours + ':' + minutes + ' ' + meridian);
    
  }
  startTimeEvent() {

  }
  submitExam() {

    console.log(this.examschedulerList)
    this.loader=true;
    let temArr=[];
    for(let m of this.examschedulerList){
      m.examCategoryId = this.scheduleForm.value.examCategory;
      m.examId = this.scheduleForm.value.examName;
      m.classId =this.scheduleForm.value.classId; 
      m.assignSubjectId = m.assignSubjectId;
      if(m.examDate){
        temArr.push(m)
      }
    }
    this.service.saveSchedule(temArr).subscribe(res=>{           
      this.toastr.success("Successfully created","SUCCESS!",{timeOut:3000});
      this.submitted=false;
      this.resetForm();
      this.examschedulerList= [];
      this.batchAflag = false;
      this.batchBflag= false;
    
    });    
  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [year, month,day ].join('-');
  }
  exalist = [
    {
      "id": 2,
      "subjectId": 22,
      "subjectName": "SST",
      "isMarking": true,
      "batchId": 1,
      "batchName": "A",
      "section": "A|B",
      "className": "Class I",
      "examDate": null,
      "startTime": null,
      "endTime": null
    },
    {
      "id": 3,
      "subjectId": 21,
      "subjectName": "Math",
      "isMarking": false,
      "batchId": 2,
      "batchName": "B",
      "section": "A|B",
      "className": "Class I",
      "examDate": null,
      "startTime": null,
      "endTime": null
    },
    {
      "id": 39,
      "subjectId": 23,
      "subjectName": "Physics",
      "isMarking": true,
      "batchId": 1,
      "batchName": "A",
      "section": "A",
      "className": "Class I",
      "examDate": null,
      "startTime": null,
      "endTime": null
    },
    {
      "id": 39,
      "subjectId": 23,
      "subjectName": "Physics",
      "isMarking": true,
      "batchId": 2,
      "batchName": "B",
      "section": "A",
      "className": "Class I",
      "examDate": null,
      "startTime": null,
      "endTime": null
    }
  ]
}
