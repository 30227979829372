import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {GradeScale} from './gradescale.model';

@Injectable({
    providedIn: 'root'
  })
  export class GradeScaleService {
  
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    constructor(private httpClient: HttpClient) { }
    getAll(): Observable<GradeScale[]> {
      return this.httpClient.get<GradeScale[]>(environment.apiUrl + '/GradeScaleGroup/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<GradeScale> {
      return this.httpClient.get<GradeScale>(environment.apiUrl + '/GradeScaleGroup/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    create(gradescale): Observable<GradeScale> {
      return this.httpClient.post<GradeScale>(environment.apiUrl + '/GradeScaleGroup/', JSON.stringify(gradescale), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }
  
    update(id, gradescale): Observable<GradeScale> {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.put<GradeScale>(environment.apiUrl + '/GradeScaleGroup/' + id, JSON.stringify(gradescale), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }
  
    delete(id) {
      return this.httpClient.delete<GradeScale>(environment.apiUrl + '/GradeScaleGroup/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }
  
  
  }