// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active{
    background-color:#34c38f !important;
    color: #1b1919;
  }`, "",{"version":3,"sources":["webpack://./src/app/admin/academic/offline-examination/exam-result-report/exam-result-report.component.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,cAAc;EAChB","sourcesContent":[".active{\r\n    background-color:#34c38f !important;\r\n    color: #1b1919;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
