import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApplicantRegistrationService } from './applicantregistration.service';
import { ApplicantRegistration } from './applicantregistration.model';
import { Academicyear } from '../mastersettings/models/academicyear';
import { ClassCourse } from '../mastersettings/models/classcourse';
import { Nationality } from '../mastersettings/models/nationality';
import { Religion } from '../mastersettings/models/religion';
import { Category } from '../mastersettings/models/category';
import { Caste } from '../mastersettings/models/caste';
import { Country } from '../mastersettings/models/country';
import { State } from '../mastersettings/models/state';
import { City } from '../mastersettings/models/city';
import { ClassCourseService } from '../mastersettings/classcourse/classcourse.service';
import { SettingsService } from '../mastersettings/settings.service';
import { NationalityService } from '../mastersettings/nationality/nationality.service';
import { ReligionService } from '../mastersettings/religion/religion.service';
import { CategoryService } from '../mastersettings/category/category.service';
import { CasteService } from '../mastersettings/caste/caste.service';
import { CountryService } from '../mastersettings/country/country.service';
import { StateService } from '../mastersettings/state/state.service';
import { CityService } from '../mastersettings/city/city.service';
import { AcademicyearComponent } from '../mastersettings/academicyear/academicyear.component';
import { AdmissionProcedureService } from '../admissionProcedure/admissionprocedure.service';
import { AdmissionProcedure } from '../admissionProcedure/admissionprocedure.model';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-applicantregistration',
  templateUrl: './applicantregistration.component.html',
  styleUrls: ['./applicantregistration.component.css']
})
export class ApplicantregistrationComponent implements OnInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  registrationForm: UntypedFormGroup;
  academicYears: Academicyear[] = [];
  classcourses: ClassCourse[] = [];
  nationalities: Nationality[] = [];
  religions: Religion[] = [];
  categories: Category[] = [];
  castes: Caste[] = [];
  countries: Country[] = [];
  states: State[] = [];
  cities: City[] = [];
  admissionProcedures: AdmissionProcedure;
  submitted = false;
  male: boolean;
  female: boolean;
  other: boolean;
  registerNo: string;
  currentDate: string;
  tempDate: string;
  registrationid: number;
  enquiryno: string;
  registrationFee: any = '';
  admissionProcedureFilteredList: AdmissionProcedure[] = [];
  constructor(
    public registrationService: ApplicantRegistrationService,
    public classcourseService: ClassCourseService,
    public academicyearService: SettingsService,
    public nationalityService: NationalityService,
    public religionService: ReligionService,
    public categoryService: CategoryService,
    public casteService: CasteService,
    public countryService: CountryService,
    public stateService: StateService,
    public cityService: CityService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public admissionProcedureServiceobj: AdmissionProcedureService
  ) { }

  ListAcademicYears() {
    this.academicyearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
    });
  }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      console.log(data.id);
      this.registrationForm.get('AcademicYearId').patchValue(data.id);
      // this.registrationForm.controls['AcademicYearId'].setValue(data.id);
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  ListNationalities() {
    this.nationalityService.getAll().subscribe((data: Nationality[]) => {
      this.nationalities = data;
    });
  }

  ListReligions() {
    this.religionService.getAll().subscribe((data: Religion[]) => {
      this.religions = data;
    });
  }

  ListCategories() {
    this.categoryService.getAll().subscribe((data: Category[]) => {
      this.categories = data;
    });
  }

  ListCastes() {
    this.casteService.getAll().subscribe((data: Caste[]) => {
      this.castes = data;
    });
  }

  ListCountry() {
    this.countryService.getAll().subscribe((data: Country[]) => {
      console.log(data);
      this.countries = data;
    });
  }

  ListState(countryId: any) {
    this.stateService.getAll().subscribe((data: State[]) => {
      this.states = data;
    });
  }

  ListCity() {
    this.cityService.getAll().subscribe((data: City[]) => {
      console.log(data);
      this.cities = data;
    });
  }
  onChangeClassCourse(event: any) {
    this.admissionProcedureServiceobj.getAll().subscribe((data: AdmissionProcedure[]) => {
      this.admissionProcedureFilteredList = data;
      this.admissionProcedures = this.admissionProcedureFilteredList.find(c => c.classCourseId === event.target.value);
      this.registrationFee = this.admissionProcedures?.regFee;
      this.registrationForm.get('ApplicationFee').patchValue(this.registrationFee);
    });

  }
  onChange(value: string, event: any) {
    if (value != null) {
      this.registrationForm.get('Gender').patchValue(value);
    }
    if (value === '1') {
      this.female = false;
      this.other = false;
      this.registrationForm.get('GenderFemale').patchValue('');
      this.registrationForm.get('GenderOther').patchValue('');
    } else if (value === '2') {
      this.male = false;
      this.other = false;
      this.registrationForm.get('GenderMale').patchValue('');
      this.registrationForm.get('GenderOther').patchValue('');
    } else if (value === '3') {
      this.female = false;
      this.male = false;
      this.registrationForm.get('GenderMale').patchValue('');
      this.registrationForm.get('GenderFemale').patchValue('');
    }
  }

  onCopyChange(event: any) {
    if (event.target.checked) {
      this.registrationForm.get('PermenantCountryId').patchValue(this.registrationForm.get('LocalCountryId').value);
      this.registrationForm.get('PermenantStateId').patchValue(this.registrationForm.get('LocalStateId').value);
      this.registrationForm.get('PermenantCityId').patchValue(this.registrationForm.get('LocalCityId').value);
      this.registrationForm.get('PermenantPINCode').patchValue(this.registrationForm.get('LocalPINCode').value);
      this.registrationForm.get('PermenantFullAddress').patchValue(this.registrationForm.get('LocalFullAddress').value);
    } else {
      this.registrationForm.get('PermenantCountryId').patchValue(0);
      this.registrationForm.get('PermenantStateId').patchValue(0);
      this.registrationForm.get('PermenantCityId').patchValue(0);
      this.registrationForm.get('PermenantPINCode').patchValue('');
      this.registrationForm.get('PermenantFullAddress').patchValue('');
    }
  }

  onCountrySelected(value: string) {
    this.stateService.getAll().subscribe((data: State[]) => {
      this.states = data.filter((state: State) => state.countryId.toString().includes(value.toLocaleLowerCase()));
    });
  }

  onStateSelected(value: string) {
    console.log(value);
    this.cityService.getAll().subscribe((data: City[]) => {
      this.cities = data.filter((city: City) => city.stateId.toString().includes(value.toLocaleLowerCase()));
    });
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode !== '0') {
      event.preventDefault();
    }
  }

  setCurrentDate() {
    const x = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.currentDate = days[x.getDay()] + ' ' + this.formatDate(x);
  }

  updateCurrentDate(date) {
    const x = new Date(date);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.currentDate = days[x.getDay()] + ' ' + this.formatDate(x);
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('-');
  }

  generateRegisterNo() {
    this.registrationService.generateRegisterNo().subscribe(res => {
      this.registerNo = res;
    });
  }

  updateForm(id: number) {
    this.registrationService.getById(id).subscribe((data: ApplicantRegistration) => {
      console.log(data);
      this.registerNo = data.registerNo;
      this.tempDate = data.registrationDate;
      this.updateCurrentDate(data.registrationDate);
      this.registrationForm = this.fb.group({
        id: [data.id],
        AcademicYearId: [data.academicYearId.toString(), Validators.required],
        ClassCourseId: [data.classCourseId.toString(), Validators.required],
        ApplicationFee: [data.applicationFee.toString(), Validators.required],
        FormNo: [data.formNo, Validators.required],
        StudentName: [data.studentName, Validators.required],
        DOB: [this.formatDate(data.dob), Validators.required],
        Age: [data.age.toString()],
        Gender: [data.gender.toString(), Validators.required],
        AdharNumber: [data.adharNumber, [Validators.pattern('^[0-9]{12}$')]],
        ContactNumber: [data.contactNumber, [Validators.required, Validators.pattern('^[0-9]{10}$')]],
        EmailId: [data.emailId],
        NationalityId: [data.nationalityId.toString()],
        ReligionId: [data.religionId.toString()],
        CategoryId: [data.categoryId.toString()],
        CasteId: [data.casteId.toString()],
        PreviousSchoolName: [data.previousSchoolName],
        YearOfPassing: [data.yearOfPassing],
        classcourse: [data.classcourse],
        TCNumber: [data.tcNumber],
        RefferdBy: [data.refferdBy],
        FatherFullName: [data.fatherFullName],
        FatherQualification: [data.fatherQualification],
        FatherOccupation: [data.fatherOccupation],
        FatherContactNumber: [data.fatherContactNumber],
        FatherEmailId: [data.fatherEmailId],
        MotherFullName: [data.motherFullName],
        MotherQualification: [data.motherQualification],
        MotherOccupation: [data.motherOccupation],
        MotherContactNumber: [data.motherContactNumber],
        MotherEmailId: [data.motherEmailId],
        LocalCountryId: [data.localCountryId.toString()],
        LocalStateId: [data.localStateId.toString()],
        LocalCityId: [data.localCityId.toString()],
        LocalPINCode: [data.localPINCode],
        LocalFullAddress: [data.localFullAddress],
        CopyAddressStatus: [data.copyAddressStatus],
        PermenantCountryId: [data.permenantCountryId.toString()],
        PermenantStateId: [data.permenantStateId.toString()],
        PermenantCityId: [data.permenantCityId.toString()],
        PermenantPINCode: [data.permenantPINCode],
        PermenantFullAddress: [data.permenantFullAddress],
        GenderMale: [data.gender.toString() === '1' ? true : false],
        GenderFemale: [data.gender.toString() === '2' ? true : false],
        GenderOther: [data.gender.toString() === '3' ? true : false],
        RegisterNo: [data.registerNo],
        RegistrationDate: [data.registrationDate],
        ApplicationSource: [data.applicationSource],
        ApplicationStatus: [data.applicationStatus]
      });
      // this.academicyears = data;
      $('#DOB').datepicker('update', this.formatDate(data.dob));
    });

  }

  ngOnInit(): void {
    this.registrationid = this.activatedRoute.snapshot.params.id;
    this.enquiryno = this.activatedRoute.snapshot.params.enquiryno;
    if (this.registrationid !== 0 && this.registrationid !== undefined) {
      this.updateForm(this.registrationid);
    } else {
      this.generateRegisterNo();
      this.setCurrentDate();
    }
    this.currentAcademicYear();
    this.ListState(0);
    this.ListCity();
    this.ListAcademicYears();
    this.ListCastes();
    this.ListCategories();
    this.ListClassCourse();
    this.ListCountry();
    this.ListNationalities();
    this.ListReligions();
    this.registrationForm = this.fb.group({
      id: [0],
      AcademicYearId: ['', Validators.required],
      ClassCourseId: ['', Validators.required],
      ApplicationFee: [0, Validators.required],
      FormNo: ['', Validators.required],
      StudentName: ['', Validators.required],
      DOB: ['', Validators.required],
      Age: [''],
      Gender: ['', Validators.required],
      AdharNumber: ['', [Validators.pattern('^[0-9]{12}$')]],
      ContactNumber: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      EmailId: [''],
      NationalityId: ['0'],
      ReligionId: ['0'],
      CategoryId: ['0'],
      CasteId: ['0'],
      PreviousSchoolName: [''],
      YearOfPassing: [''],
      classcourse: [''],
      TCNumber: [''],
      RefferdBy: [''],
      FatherFullName: [''],
      FatherQualification: [''],
      FatherOccupation: [''],
      FatherContactNumber: [''],
      FatherEmailId: [''],
      MotherFullName: [''],
      MotherQualification: [''],
      MotherOccupation: [''],
      MotherContactNumber: [''],
      MotherEmailId: [''],
      LocalCountryId: ['0'],
      LocalStateId: ['0'],
      LocalCityId: ['0'],
      LocalPINCode: [''],
      LocalFullAddress: [''],
      CopyAddressStatus: [false],
      PermenantCountryId: ['0'],
      PermenantStateId: ['0'],
      PermenantCityId: ['0'],
      PermenantPINCode: [''],
      PermenantFullAddress: [''],
      GenderMale: [''],
      GenderFemale: [''],
      GenderOther: [''],
      RegisterNo: [''],
      EnquiryNo: [''],
      RegistrationDate: [''],
      ApplicationSource: [''],
      ApplicationStatus: [''],
      EntranceStatus: ['Pending']
    });

    $('#DOB').datepicker().on('changeDate', (e) => {
      const dateParts = this.formatDate(e.date).split('-');
      const month = dateParts[0];
      const day = dateParts[1];
      const year = dateParts[2];
      let ageValue = 0;
      const condition1: number = (parseInt(month, 0) + parseInt(day, 0));

      const todayDate = new Date();
      const todayYear = (todayDate.getFullYear()).toString();
      const todayDay = (todayDate.getDate()).toString();
      const todayMonth = (todayDate.getMonth() + 1).toString();
      const condition2: number = parseInt(todayMonth, 0) + parseInt(todayDay, 0);

      if (condition2 >= condition1) {
        ageValue = parseInt(todayYear, 0) - (parseInt(year, 0));
      } else {
        ageValue = parseInt(todayYear, 0) - ((parseInt(year, 0) + 1));
      }
      // 0-11 is Month in JavaScript
      this.registrationForm.get('DOB').patchValue(this.formatDate(e.date));
      this.registrationForm.get('Age').patchValue(ageValue.toFixed(0));
    });

  }

  get f() { return this.registrationForm.controls; }
  resetForm() {
    this.submitted = false;
    this.registrationForm = this.fb.group({
      id: [0],
      AcademicYearId: ['', Validators.required],
      ClassCourseId: ['', Validators.required],
      ApplicationFee: ['', Validators.required],
      FormNo: [''],
      StudentName: ['', Validators.required],
      DOB: ['', Validators.required],
      Age: [''],
      Gender: ['', Validators.required],
      AdharNumber: ['', [Validators.pattern('^[0-9]{12}$')]],
      ContactNumber: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      EmailId: [''],
      NationalityId: ['0'],
      ReligionId: ['0'],
      CategoryId: ['0'],
      CasteId: ['0'],
      PreviousSchoolName: [''],
      YearOfPassing: [''],
      classcourse: [''],
      TCNumber: [''],
      RefferdBy: [''],
      FatherFullName: [''],
      FatherQualification: [''],
      FatherOccupation: [''],
      FatherContactNumber: [''],
      FatherEmailId: [''],
      MotherFullName: [''],
      MotherQualification: [''],
      MotherOccupation: [''],
      MotherContactNumber: [''],
      MotherEmailId: [''],
      LocalCountryId: ['0'],
      LocalStateId: ['0'],
      LocalCityId: ['0'],
      LocalPINCode: [''],
      LocalFullAddress: [''],
      CopyAddressStatus: [''],
      PermenantCountryId: ['0'],
      PermenantStateId: ['0'],
      PermenantCityId: ['0'],
      PermenantPINCode: [''],
      PermenantFullAddress: [''],
      GenderMale: [''],
      GenderFemale: [''],
      GenderOther: [''],
      RegisterNo: [''],
      ApplicationSource: [''],
      ApplicationStatus: ['']
    });

    this.generateRegisterNo();

  }

  submitForm() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      return;
    }
    this.registrationForm.get('CopyAddressStatus').patchValue(this.registrationForm.get('CopyAddressStatus').value.toString());
    if (this.registrationForm.get('id').value === 0) {
      this.registrationForm.get('RegistrationDate').patchValue(this.formatDate(new Date()));
      this.registrationForm.get('RegisterNo').patchValue(this.registerNo);
      this.registrationForm.get('ApplicationSource').patchValue('Offline');
      this.registrationForm.get('ApplicationStatus').patchValue('Pending');
      this.registrationForm.get('CopyAddressStatus').patchValue(this.registrationForm.get('CopyAddressStatus').value.toString());
      console.log(this.registrationForm.value);
      this.registrationService.create(this.registrationForm.value).subscribe(res => {
        console.log(res);
        console.log('Registration created!');
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        Swal.fire({
          title: 'Print Receipt?',
          text: 'Registration has been save successfully. Do You want to print Receipt..?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.value) {
           // window.open(url, '_blank')
          //  this.router.navigate(['../reciept', res.id], { relativeTo: this.activatedRoute });
            this.router.navigate(['../reciept', res.id], { relativeTo: this.activatedRoute });
          } else if (result.dismiss === Swal.DismissReason.cancel) {

          }
        });

        this.submitted = false;
        this.resetForm();
        this.generateRegisterNo();
      });
    } else {
      this.registrationForm.get('RegistrationDate').patchValue(this.formatDate(this.tempDate));
      this.registrationService.update(this.registrationForm.get('id').value, this.registrationForm.value).subscribe(res => {
        console.log('Registration updated!');
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        Swal.fire({
          title: 'Print Receipt?',
          text: 'Registration has been update successfully. Do You want to print Receipt..?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['../../reciept', res.id], { relativeTo: this.activatedRoute });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.router.navigate(['../../registrationreport'], { relativeTo: this.activatedRoute });
          }
        });
        this.submitted = false;
        this.resetForm();
      });
    }
  }
}
