import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DownloadsRoutingModule } from './downloads-routing.module';
import { EmployeereportComponent } from './employeereport/employeereport.component';
import { SiblingreportComponent } from './siblingreport/siblingreport.component';
import { StudentreportComponent } from './studentreport/studentreport.component';
import { StudentreligionreportComponent } from './studentreligionreport/studentreligionreport.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [EmployeereportComponent, SiblingreportComponent, StudentreportComponent, StudentreligionreportComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DownloadsRoutingModule
  ]
})
export class DownloadsModule { }
