import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ConfigurationRoutingModule} from './configuration-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AddonserviceComponent } from './addonservice/addonservice.component';
import { ApiconfigurationComponent } from './apiconfiguration/apiconfiguration.component';
import { SubscriptionmanagerComponent } from './subscriptionmanager/subscriptionmanager.component';
import { UsermanageComponent } from './usermanage/usermanage.component';
import { LoginpageconfigComponent } from './loginpageconfig/loginpageconfig.component';
import { SenderidapprovalComponent } from './senderidapproval/senderidapproval.component';
import { WelcomenotificationComponent } from './welcomenotification/welcomenotification.component';
import { InstitutionstatisticsComponent } from './institutionstatistics/institutionstatistics.component';
import { PaymentgatewayComponent } from './paymentgateway/paymentgateway.component';

@NgModule({
  declarations: [ AddonserviceComponent, ApiconfigurationComponent, UsermanageComponent, SubscriptionmanagerComponent,
     LoginpageconfigComponent, SenderidapprovalComponent, WelcomenotificationComponent,
      InstitutionstatisticsComponent, PaymentgatewayComponent ],
  imports: [
    CommonModule,
    ConfigurationRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ConfigurationModule { }
