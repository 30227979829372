import { Component, OnInit } from '@angular/core';
import { examremarksService } from '../../services/examremark.service';
import { examremarks } from '../../ExamSetting/models/examremarks.model';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-examremarks',
  templateUrl: './examremarks.component.html',
  styleUrls: ['./examremarks.component.css']
})
export class ExamremarksComponent implements OnInit {

  constructor(private examRemarksService: examremarksService, private toastr: ToastrService,
    public fb: UntypedFormBuilder) { }
  examRemarksfrmGroup: UntypedFormGroup;
  examRemarksFilterList: examremarks[] = [];
  exam_Remarks: examremarks[] = [];
  allData !: any;
  examRemarksCount: number;
  submitted = false;

  ngOnInit(): void {
    this.examRemarksfrmGroup = this.fb.group({
      id: [0],
      remarks: ['', Validators.required],
      status: ['', Validators.required]
    });

    this.getAllExamRemark();
  }
  get f() { return this.examRemarksfrmGroup.controls; }


  // this.categoryCount= this.examCategoryFilteredList.length; 
  getAllExamRemark() {
    this.examRemarksService.getAllExamRemarks().subscribe((data: examremarks[]) => {
      this.examRemarksFilterList = this.exam_Remarks = data;
      this.examRemarksCount = this.examRemarksFilterList.length;
      
      console.log(data);
      //  alert(this.categoryCount);

    })

  }


  

// save & Update Exam Remarks
  submitForm() {
    this.submitted = true;
    if (this.examRemarksfrmGroup.invalid) {
      this.toastr.warning('All field are required', 'WARNING!!!', { timeOut: 3000 });
      return;
    }

    if (this.examRemarksfrmGroup.get('id').value === 0) {

     
      this.examRemarksService.CreateExamRemarks(this.examRemarksfrmGroup.value).subscribe(res => {
        this.toastr.success("Exam Category successfully created", "SUCCESS!", { timeOut: 3000 });
        this.submitted = false;
        this.resetForm();

        this.getAllExamRemark();
        //this.examCategoryForm.reset();
      });
    }
     else{
       this.examRemarksService.updateExamRemarks(this.examRemarksfrmGroup.get('id').value,this.examRemarksfrmGroup.value).subscribe(res=>{
        this.toastr.success("Exam Category successfully updated","SUCCESS!",{timeOut:3000});
        this.getAllExamRemark();
        this.resetForm();
       })
          //  this.examRemarksService.updateExamRemarks(this.examRemarksfrmGroup.get('id').value,this.examRemarksfrmGroup.value).subscribe(res=>{           
          //    this.toastr.success("Exam Category successfully updated","SUCCESS!",{timeOut:3000});
          //    this.getAllExamRemark();
          //  });
     }
  }

// Get Exam Remarks By Id
  GetEditId(id: number) {
    
    this.examRemarksService.getExamRemarksById(id).subscribe((data: examremarks) => {
      this.examRemarksfrmGroup = this.fb.group({
        id: [data.id],
        remarks: [data.remarks, Validators.required],
        status: [data.status, Validators.required]

      })

    })
  }

//Deleting
deleteExamRemarks(id:number){
  Swal.fire({
    title: 'Are you sure?',
    text: 'You won\'t be able to revert this!',
    icon: 'warning',
    showCancelButton:true,
    confirmButtonText:'Yes, delete it!',
    cancelButtonText:'No, keep it'
  }).then((result)=>{
    if(result.value){
      this.examRemarksService.Del_ExamRemarks(id).subscribe(data=>{
        Swal.fire(
          'Deleted!',
          'Exam category has been deleted.',
          'success'
        );
        this.getAllExamRemark();
      })
    }
  })
}


onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

filter(keyword: any) {
  if ( keyword === '') {
    this.examRemarksFilterList = this.exam_Remarks;
    this.examRemarksCount= this.examRemarksFilterList.length;
  } else {
  this.examRemarksFilterList = this.exam_Remarks
                              .filter((examRe: examremarks) => examRe.remarks.toLocaleLowerCase()
                              .includes(keyword.toLocaleLowerCase()));
  this.examRemarksCount=this.examRemarksFilterList.length;
  }
}



// Reseting the form contrils
  resetForm() {
    this.submitted = false;
    this.examRemarksfrmGroup = this.fb.group({
      id: [0],
      remarks: ['', Validators.required],
      status: ['', Validators.required]
    })
  }
  btnReset() {
    
    this.submitted = false;
    this.examRemarksfrmGroup = this.fb.group({
      id: [0],
      remarks: '',
      status: ''
    })
  }

}
