import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SenderIDRequest } from './senderid.model';
import { Service } from '../../creditmanager/service.model';


@Injectable({
    providedIn: 'root'
})
export class SenderIDRequestService {

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            enctype: 'multipart/form-data'
        })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAllSenderIDRequests(): Observable<SenderIDRequest[]> {
        return this.httpClient.get<SenderIDRequest[]>(environment.apiUrl + '/senderidrequest/')
            .pipe(
                catchError(this.errorHandler)
            );
    }
    create(feehead): Observable<any> {
        return this.httpClient.post(environment.apiUrl + '/senderidrequest/', feehead)
            .pipe(
                catchError(this.errorHandler)
            );
    }
    update(id, feehead): Observable<SenderIDRequest> {
        // tslint:disable-next-line:max-line-length
        return this.httpClient.put<SenderIDRequest>(environment.apiUrl + '/senderidrequest/' + id, JSON.stringify(feehead), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            );
    }
    delete(id) {
        return this.httpClient.delete<SenderIDRequest>(environment.apiUrl + '/senderidrequest/' + id)
            .pipe(
                catchError(this.errorHandler)
            );
    }
    getAllServices(): Observable<Service[]> {
        return this.httpClient.get<Service[]>(environment.apiUrl + '/service/')
            .pipe(
                catchError(this.errorHandler)
            );
    }
    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }

}
