import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../../src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CreateQuestionService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private http:HttpClient) { }
  //url:string="http://localhost:5000/";
  getAllTopicList(){
    return this.http.get(environment.apiUrl+"/Topic").pipe(
      catchError(this.errorHandler)
    )
  }

  createTopic(topicForm){
    return this.http.post(environment.apiUrl+"/Topic",JSON.stringify(topicForm)).pipe(
      catchError(this.errorHandler)
    )
  }

  updateTopic(id,topicForm){
    return this.http.put(environment.apiUrl+"/Topic/"+ id,JSON.stringify(topicForm)).pipe(
      catchError(this.errorHandler)
    )
  }

  deleteTopic(id){
    return this.http.delete(environment.apiUrl+"/Topic/"+ id).pipe(
      catchError(this.errorHandler)
    )
  }

  getTopicById(id){
    return this.http.get(environment.apiUrl+"/Topic/"+ id).pipe(
      catchError(this.errorHandler)
    )
  }

  getAllQuestionTypeList(){
    return this.http.get(environment.apiUrl+"/QuestionType").pipe(
      catchError(this.errorHandler)
    )
  }

  getAllDifficultyLevelList(){
    return this.http.get(environment.apiUrl+"/DifficultyLevel").pipe(
      catchError(this.errorHandler)
    )
  }

  getAllLanguageList(){
    return this.http.get(environment.apiUrl+"/language").pipe(
      catchError(this.errorHandler)
    )
  }

  getAllClassBatch() {
    return this.http.get(environment.apiUrl+"/examplanner/GetAllClasses").pipe(
      catchError(this.errorHandler)
    )
  } 

  createExamQuestion(ExamQuestionForm){
    return this.http.post(environment.apiUrl+"/ExamQuestion",JSON.stringify(ExamQuestionForm)).pipe(
      catchError(this.errorHandler)
    )
  }

  updateExamQuestion(id,ExamQuestionForm){
    return this.http.put(environment.apiUrl+"/ExamQuestion/"+ id,JSON.stringify(ExamQuestionForm)).pipe(
      catchError(this.errorHandler)
    )
  }

  deleteExamQuestion(id){
    return this.http.delete(environment.apiUrl+"/ExamQuestion/"+ id).pipe(
      catchError(this.errorHandler)
    )
  }

  getExamQuestionById(id){
    return this.http.get(environment.apiUrl+"/ExamQuestion/"+ id).pipe(
      catchError(this.errorHandler)
    )
  }

  GetQuestionList(classId,subjectId,languageId,topicId):Observable<any>{
    return this.http.get<any>(environment.apiUrl+"/GetExamQuestionList?classId="+classId+"&subjectId="+subjectId+"&languageId="+languageId+"&topicId="+topicId).pipe(
      catchError(this.errorHandler)
    )
  }

  GetExamQuestionDetails(id):Observable<any>{
    return this.http.get<any>(environment.apiUrl+"/GetExamQuestionDetails?Id="+id).pipe(
      catchError(this.errorHandler)
    )
  }

  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 } 
}
