import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, FormGroupDirective } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import {ExamDesignationService} from '../../services/exam-designation.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.css']
  //providers: [FormGroupDirective]
})
export class DesignationComponent implements OnInit {
 designationform:UntypedFormGroup;
 designationeditform:UntypedFormGroup;
 classDropdown:any=[];
 designationsList:any=[];
 designationsListfilter:any=[];
 recordsCount:number;
 editEnable:boolean=false;
 editbuttonEnable:boolean=false;
 showButton:boolean=true;
  constructor(
    private toastr: ToastrService,
    public fb: UntypedFormBuilder,
    //public formDirective: FormGroupDirective
    public service:ExamDesignationService
  ) { }

  ngOnInit(): void {
     this.designationform=this.fb.group({
      id: [0],
      ClassId: ["", Validators.required],
      DName: ["", Validators.required],
      CCPA: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
     });
    //  this.designationeditform=this.fb.group({
    //   id: [""],
    //   ClassId: ["", Validators.required],
    //   DName: ["", Validators.required],
    //   CCPA: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
    //  });
     this.getClassdropdown();
     this.getDesignations();
  }
  getClassdropdown(){
    this.service.getClassdropdown().subscribe((data)=>{
     
       this.classDropdown=data;
    })
  }
  submitForm() {}

  createDesignation() {
    if (this.designationform.get("id").value === 0) {
      this.service.addDesignation(this.designationform.value).subscribe((data) => {
       
        if(data==true){
        this.toastr.success("Designations insertes successsfully", "SUCCESS!", {
          timeOut: 3000,
        });

        this.resetform();
        this.getDesignations();
      }else{
        this.toastr.warning("Combination with this designation and class already exists", "DUPLICATE!", {
          timeOut: 3000,
        });
      }
      })
    }else{
      this.service.saveDesignation(this.designationform.get("id").value,this.designationform.value).subscribe((data)=>{
        this.toastr.success("Designation successfully updated","SUCCESS!",{timeOut:3000});
        this.resetform();
        this.getDesignations();
      })
    }
  }

  getDesignations(){
    this.service.getAlldesignations().subscribe((data)=>{
     
      this.designationsList=this.designationsListfilter=data;
      this.recordsCount=this.designationsList.length;
    })
  }
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.designationsList = this.designationsListfilter;
      this.recordsCount= this.designationsList.length;
    } else {
    this.designationsList = this.designationsListfilter
                                .filter((x) => x.dName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
     this.recordsCount=this.designationsList.length;
    }
  }
  // deleteDesignation(id:any){
  //   this.service.deleteDesignation(id).subscribe((data)=>{
  //    
  //     if(data==true){
  //     this.toastr.success("Designation deleted successsfully","DELETED!",{
  //       timeOut:3000,
  //     });
  //     this.getDesignations();
  //   }else{
  //     this.toastr.error("Failed to delete Designation","Not Deleted!",{
  //       timeOut:3000,
  //     });
  //     this.getDesignations();
  //   }
  //   })
  // }

  deleteDesignation(id:number){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton:true,
      confirmButtonText:'Yes, delete it!',
      cancelButtonText:'No, keep it'
    }).then((result)=>{
      if(result.value){
        this.service.deleteDesignation(id).subscribe((data)=>{
         
          if(data==true){
          this.toastr.success("Designation deleted successsfully","DELETED!",{
            timeOut:3000,
          });
          this.getDesignations();
        }else{
          this.toastr.error("Failed to delete Designation","Not Deleted!",{
            timeOut:3000,
          });
          this.getDesignations();
        }
        })
      }
    })
  }

  resetform(){
   
    this.designationform.reset();
    this.designationform.get("ClassId").setValue("");
    this.designationform.get("id").setValue(0);
  }

  editDesignation(id:any){
    this.service.getDesignationbyid(id).subscribe((data)=>{
      this.designationform=this.fb.group({
        id: [data["id"]],
        ClassId: [data["classId"], Validators.required],
        DName: [data["dName"], Validators.required],
        CCPA: [data["ccpa"], [Validators.required, Validators.pattern("^[0-9]*$")]],
       });
    })
    
  }
  

  save(designation){
   this.service.saveDesignation(designation.id,designation).subscribe((data)=>{
    this.toastr.success("Designations updated successsfully","SUCCESS!",{
      timeOut:3000,
    });
    designation.enable = false;
    this.editbuttonEnable=false;
   })
  }
}
