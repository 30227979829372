import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from '@angular/core';
import { DatePipe, TitleCasePipe } from '@angular/common';
/* import { ToastrModule } from 'ngx-toastr'; */

import {AdminModule} from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LoaderService } from './shared/loader.service';
import { LoaderInterceptor } from './shared/loader-interceptor.service';
import { TokenInterceptor } from './authentication/token.interceptor';
import { InstitutionInterceptor } from './institution.interceptor';
import { LoaderComponent } from './shared/loader/loader.component';

import { SharedService } from './shared/shared.service';
import {EdumaqModule} from './edumaq/edumaq.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { SuperadminModule } from './superadmin/superadmin.module';
import { ParentchildModule } from './parentchild/parentchild.module';
import { TeacherModule } from './teacher/teacher.module';
import { InstitutionModule } from './institution/institution.module';
import { SharedModule } from './shared/shared.module';
// import { EvalutiontypeComponent } from './admin/academic/academicsettings/evalutiontype/evalutiontype.component';//
//import { NgIdleModule} from '@ng-idle/core';
import { NotFoundComponent } from './admin/not-found/not-found.component';
import { CommonModule } from '@angular/common';
/* import { AgmDirectionModule } from 'agm-direction'; */
//import { AgmCoreModule } from '@agm/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './authentication/login/login.component';
import { AuthGuard } from './authentication/guards/auth.guard';
import { AcademicModule } from './admin/academic/academic.module';
import { NgIdleModule } from '@ng-idle/core';
import { FindschoolComponent } from './authentication/findschool/findschool.component';
import {ForgetpasswordComponent} from './authentication/forgetpassword/forgetpassword.component';




//import { VoucherEntryComponent } from './admin/OperationActivity/financeaccount/voucher-entry/voucher-entry.component';
import {AgmCoreModule} from '@agm/core';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
//import {AgmDirectionModule} from 'agm-direction';
//import {Select2Directive} from './shared/controls/select-2-directive';

const routes: Routes = [
  {path: 'findschool', component: FindschoolComponent},
  {path: 'forgetpassword', component: ForgetpasswordComponent},
  {path: ':edumaq',  component: LoginComponent},
  {
    path: ':edumaq/login',
    component: LoginComponent,
    canActivate: [AuthGuard]
  },
  {path: '', component: FindschoolComponent}
  // {path: '**', redirectTo: ''}
];
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent
    //VoucherEntryComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
   //BsDatepickerModule.forRoot(),
     ToastrModule.forRoot(),
    NgIdleModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    AdminModule,
    EdumaqModule,
    AuthenticationModule,
    SuperadminModule,
    ParentchildModule,
    TeacherModule,
    InstitutionModule,
    AcademicModule,
    SharedModule,
    CommonModule,
    
   /*  AgmCoreModule.forRoot({
      apiKey: 'AlzaSyCPDBxeJLf94uBjB1m2P7PP0XNI86fTMgM'
    }),
    AgmDirectionModule, */
    FormsModule,
    /* ModalModule.forRoot() */
  ],
  exports: [  
    //BsDatepickerModule,
    // LoaderComponent
    RouterModule,
  /*   ModalModule.forRoot() */
  ],
  providers: [
    LoaderService,
    DatePipe,
    //TitleCasePipe, 
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InstitutionInterceptor, multi: true },
    SharedService
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
