import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {HomeWork} from './homework.model';

@Injectable({
    providedIn: 'root'
  })
  export class HomeworkService {
  
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<HomeWork[]> {
      return this.httpClient.get<HomeWork[]>(environment.apiUrl + '/homework/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<HomeWork> {
      return this.httpClient.get<HomeWork>(environment.apiUrl + '/homework/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getDetails(id): Observable<HomeWork> {
      return this.httpClient.get<HomeWork>(environment.apiUrl + '/homework/GetHomeworkDetails/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    public downloadFile(id: number, docFile: string): Observable < Blob > {
      return this.httpClient.get(environment.apiUrl  + '/homework/GetFile/' + id + '/'  + docFile, {
          responseType: 'blob'
      });
    }

    // create(assignment): Observable<HomeWork> {
    //   return this.httpClient.post<HomeWork>(environment.apiUrl + '/homework/', JSON.stringify(assignment), this.httpOptions)
    //   .pipe(
    //     catchError(this.errorHandler)
    //   );
    // }
    
    create(homework): Observable<any> {
      return this.httpClient.post(environment.apiUrl + '/homework/', homework, {responseType: 'text'})
      .pipe(
        catchError(this.errorHandler)
      );
    }

    update(id, homework): Observable<any> {
      return this.httpClient.put(environment.apiUrl + '/homework/' + id, homework, {responseType: 'text'})
      .pipe(
        catchError(this.errorHandler)
      );
    }

    // update(id, assignment): Observable<HomeWork> {
    //   // tslint:disable-next-line:max-line-length
    //   return this.httpClient.put<HomeWork>(environment.apiUrl + '/homework/' + id, JSON.stringify(assignment), this.httpOptions)
    //   .pipe(
    //     catchError(this.errorHandler)
    //   );
    // }
  
    delete(id) {
      return this.httpClient.delete<HomeWork>(environment.apiUrl + '/homework/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }
  
  
  }