import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsolidatedReportService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }

  getConsolidatedInstallmentReport(studentTypeId, feeHeadId, fromDate, toDate): Observable<any> {
    return this.httpClient.get<any>(environment.apiUrl + '/FeeReport/GetConsolidatedInstallmentReport?StudentTypeId='+studentTypeId+'&FeeHeadId='+feeHeadId+'&FromDate='+fromDate+'&ToDate='+toDate)
      .pipe(
        catchError(this.errorHandler)
      );

    }
errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
