import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import {SubjectcreationService} from '../../academicsettings/subjectcreation/subjectcreation.service';
import { SubjectCreation } from '../../academicsettings/subjectcreation/subjectcreation.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HomeWork } from './homework.model';
import { HomeworkService } from './homework.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { saveAs } from 'file-saver';
import { FeeSetting } from '../../feemanagement/feesetting/feesetting.model';
import { SettingsService } from '../../mastersettings/settings.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
declare var $: any;
@Component({
  selector: 'app-homeworks',
  templateUrl: './homeworks.component.html',
  styleUrls: ['./homeworks.component.css']
})
export class HomeworksComponent implements OnInit {

  isLoading:boolean=false;
  homeworkForm: UntypedFormGroup;
  classcourses: ClassCourse[];
  subjects: SubjectCreation[] = [];
  classSubjects: SubjectCreation[] = [];
  subjectFilter: SubjectCreation[] = [];
  homeworks: HomeWork[] = [];
  homeworkData: HomeWork = {} as HomeWork;
  filteredHomeworks: HomeWork[] = [];
  searchHomeworks: HomeWork[] = [];
  batches: Batch[] = [];
  searchBatches: Batch[] = [];
  batchIds: any[] = [];
  resBatchIds: any[] = [];
  slectedBatchIds = '';
  batchNames: any[] = [];
  batchId = '0';
  classId = '0';
  submitted = false;
  currentDate: string;
  currentAcademicYearId: number;

  constructor(
    private homeworkService: HomeworkService,
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    public subjectService: SubjectcreationService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private academicyearService:SettingsService
  ) { }

  ListHomeworks() {
    this.isLoading=true;
    this.homeworkService.getAll().subscribe((data: HomeWork[]) => {
      console.log(data);
      this.filteredHomeworks = this.homeworks = data;
      this.isLoading=false;
    });
    
  }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      console.log(data.id);
      this.currentAcademicYearId = data.id;
      this.homeworkForm.get('academicYearId').patchValue(this.currentAcademicYearId.toString());
    });
  }


  ListSubjects() {
    this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
      this.classSubjects = this.subjectFilter = this.subjects = data;
    });
  }
  ListClass() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      console.log(data);
      this.classcourses = data;
    });
  }

  ListBatch() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      console.log(data);
      this.searchBatches = data;
    });
  }

  onClassChange(value: string) {
    if (value === '-1') { return; }
    if (value === '0') {
      this.searchHomeworks = this.filteredHomeworks = this.homeworks; // = data;
    } else {
      this.searchHomeworks = this.filteredHomeworks = this.homeworks.filter((assignment: HomeWork) =>
      assignment.classId.toString() === value.toString());

      this.batchService.getAll().subscribe((data: Batch[]) => {
        this.searchBatches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
      });

    }
  }

  onBatchChange(value: string) {
    if (value === '-1') { return; }
    if (value === '0') {
      this.filteredHomeworks = this.searchHomeworks; // = this.homeworks; // = data;
    } else {
      this.filteredHomeworks = this.searchHomeworks.filter((assignment: HomeWork) => {
        const splitArr = assignment.batchIds==null?"": assignment.batchIds.split('|');
        return splitArr.includes(value);
      });
    }
  }

  onClassSelected(value: string) {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
    });
  }

  getExistingBatches(value: string) {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
      this.batches.forEach(x => {
        for (let i = 0; i < this.resBatchIds.length; i++) {
          if (x.id.toString() === this.resBatchIds[i]) {
            console.log(x.id);
            x.checked = true;
          }
        }
      });
    });
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (this.searchHomeworks.length > 0) {
      if ( keyword === '') {
        this.filteredHomeworks = this.searchHomeworks;
      } else {
        this.filteredHomeworks = this.searchHomeworks
                                  .filter((assignment: HomeWork) => assignment.homeWorkTitle.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase()) ||
                                  assignment.subjectName.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase()) ||
                                  assignment.className.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase())
                                  );
      }
    } else {
      if ( keyword === '') {
        this.filteredHomeworks = this.homeworks;
      } else {
        this.filteredHomeworks = this.homeworks
                                  .filter((assignment: HomeWork) => assignment.homeWorkTitle.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase()) ||
                                  assignment.subjectName.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase()) ||
                                  assignment.className.toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase())
                                  );
      }
    }
  }

  setCurrentDate() {
    const x = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.currentDate = days[x.getDay()] + ' ' + this.formatDate(x);
  }

  updateCurrentDate(date) {
    const x = new Date(date);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.currentDate = days[x.getDay()] + ' ' + this.formatDate(x);
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  get totalRows(): number {
    return this.filteredHomeworks.length;
  }
  get f() { return this.homeworkForm.controls; }

  resetForm() {
    this.submitted = false;
    this.batches.forEach(x => x.checked = false);
    this.homeworkForm = this.fb.group({
      id: [0],
      classId: ['', Validators.required],
      batchIds: [''],
      subjectId: ['', Validators.required],
      homeworkDate: [this.formatDate(new Date()), Validators.required],
      homeworkTitle: ['', Validators.required],
      homeworkText: [''],
      contentBase: [''],
      uploadFileName: [''],
      documentUrl: [''],
      status: ['true', Validators.required],
      academicYearId:[this.currentAcademicYearId]
    });
    $('.summernote').summernote('code', '');
  }

  checkAllCheckBox(ev) {
    this.batches.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.batches.every(p => p.checked);
  }

  // downloadDocFile(id, data) {
  //   const DocFileName = data;
  //   const DocFile = DocFileName.slice(0, -5);
  //   this.homeworkService.downloadFile(id, data).subscribe((x: any) => {
  //       importedSaveAs(x, data);
  //   });
  // }

  public base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    b64Data = b64Data.split(',')[1];
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  onDownloadDocFile(response: string, filename: string) {
    // this.classworkService.downloadDocFile(id).subscribe((response) => {
        // console.log(response);
        // let file = new Blob([response], { type: 'application/pdf' });
        const mainData = response;
        const base64ContentArray = response;
        const extensn = base64ContentArray.split(';')[0].split('/')[1];
        const base64Data = response.split(',');
        const mimeType = base64Data[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];

        const blob = this.base64ToBlob(mainData, mimeType);
        saveAs(blob, filename);
    // });
  }

  onFileChange(event) {
    // const usersArray = this.admissionForm.controls.documentList as FormArray;
    const target = event.target as HTMLInputElement;
    debugger;
    if (target.files && target.files[0]) {
        const file = target.files[0];
        let result = '';
        const reader = new FileReader();
        reader.onload = (e => {
          result = reader.result.toString();
          //this.homeworkForm.get('contentBase').patchValue(result);
          this.homeworkForm.get('uploadFileName').patchValue(file.name);
          this.homeworkForm.get('documentUrl').patchValue(file.webkitRelativePath);
        });

        reader.readAsDataURL(file);
    }
  }

  // uploadFile(event) {
  //   const leavefile = (event.target as HTMLInputElement).files[0];
  //   this.homeworkForm.patchValue({
  //     file: leavefile
  //   });
  //   this.homeworkForm.get('file').updateValueAndValidity();
  // }

  submitForm() {
    this.submitted = true;
    this.slectedBatchIds = '';
    let k = 1;
    const totalSelected = this.batches.filter(x => x.checked).length;
    this.batches.forEach((x, i) => {
      if (x.checked) {
        if (totalSelected !== k) {
          this.slectedBatchIds += x.id.toString() + '|';
        } else {
          this.slectedBatchIds += x.id.toString();
        }
        k++;
      }
    });

    this.homeworkForm.get('batchIds').patchValue(this.slectedBatchIds);
    this.homeworkForm.get('academicYearId').patchValue(this.currentAcademicYearId.toString());
    //this.homeworkForm.get('homeworkText').patchValue($('.summernote').summernote('code'));
    if (this.slectedBatchIds === '') {
      this.toastr.warning('Please select batch', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }

    if (this.homeworkForm.invalid) {
            return;
    }
debugger;
    const formData: any = new FormData();
    formData.append('id', this.homeworkForm.get('id').value);
    formData.append('classId', this.homeworkForm.get('classId').value);
    formData.append('batchIds', this.homeworkForm.get('batchIds').value);
    formData.append('subjectId', this.homeworkForm.get('subjectId').value);
    formData.append('homeworkDate', this.homeworkForm.get('homeworkDate').value);
    formData.append('homeworkTitle', this.homeworkForm.get('homeworkTitle').value);
    formData.append('homeworkText', this.homeworkForm.get('homeworkText').value);
    formData.append('status', this.homeworkForm.get('status').value);
    formData.append('uploadFileName', this.homeworkForm.get('uploadFileName').value);
    formData.append('documentUrl',this.homeworkForm.get('documentUrl').value)
    //formData.append('contentBase', this.homeworkForm.get('contentBase').value);
    formData.append('academicYearId', this.homeworkForm.get('academicYearId').value);
    

    if (this.homeworkForm.get('id').value === 0) {
        this.homeworkService.create(this.homeworkForm.value).subscribe(res => {
          console.log('homework created!');
          
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
          setTimeout(() => {
            this.ListHomeworks();
          }, 2000);
        });
        } else {
          // this.homeworkService.update(this.homeworkForm.get('id').value, this.homeworkForm.value).subscribe(res => {
          this.homeworkService.update(this.homeworkForm.get('id').value, this.homeworkForm.value).subscribe(res => {
            console.log('homework updated!');
            this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submitted = false;
            this.resetForm();
            // this.casteForm.reset();
            setTimeout(() => {
              this.ListHomeworks();
            }, 2000);
          });
        }
    // this.ListClassworks();
}

viewForm(id: number) {
        this.homeworkService.getDetails(id).subscribe((data: HomeWork) => {
          console.log(data);
          this.batchNames = data.batchName.split('|');
          $('.summernotes').summernote({height: 300, toolbar: false});
          $('.summernotes').summernote('code', data.homeWorkText);
          $('.summernotes').summernote('disable');
          this.homeworkData = data;
        });
      }

      updateForm(id: number) {
        this.homeworkService.getById(id).subscribe((data: HomeWork) => {
          console.log(data);
          if (data.batchIds !== null) {
            this.resBatchIds = data.batchIds.split('|');
            this.getExistingBatches(data.classId.toString());
          } else {
            this.onClassSelected(data.classId.toString());
          }
          this.homeworkForm = this.fb.group({
            id: [data.id],
            classId: [data.classId.toString(), Validators.required],
            batchIds: [data.batchIds != null ? data.batchIds.toString() : '', Validators.required],
            subjectId: [data.subjectId.toString(), Validators.required],
            homeworkDate: [this.formatDate(data.homeWorkDate), Validators.required],
            homeworkTitle: [data.homeWorkTitle, Validators.required],
            homeworkText: [data.homeWorkText],
            contentBase: [data.contentBase],
            uploadFileName: [data.uploadFileName],
            documentUrl:[data.documentUrl],
            status: [data.status.toString(), Validators.required],
            academicYearId:[data.academicYearId]
            
          });
          $('.summernote').summernote('code', data.homeWorkText);
        });
      }

      deleteForm(id: number) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            this.homeworkService.delete(id).subscribe(res => {
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              );
              this.ListHomeworks();
            });
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
          }
        });
      }

  ngOnInit(): void {
   this.currentAcademicYear();
    this.ListSubjects();
    this.ListClass();
    this.ListHomeworks();
    this.homeworkForm = this.fb.group({
      id: [0],
      classId: ['', Validators.required],
      batchIds: ['', Validators.required],
      subjectId: ['', Validators.required],
      homeworkDate: [this.formatDate(new Date()), Validators.required],
      homeworkTitle: ['', Validators.required],
      homeworkText: [''],
      contentBase: [''],
      uploadFileName: [''],
      documentUrl: [''],
      status: ['true', Validators.required],
      academicYearId:[this.currentAcademicYearId]
   });

    this.setCurrentDate();

    $('.summernote').summernote({height: 200});
    $('#homeworkDate').datepicker().on('changeDate', (e) => {
      this.homeworkForm.get('homeworkDate').patchValue(this.formatDate(e.date));
    });

  }

}
