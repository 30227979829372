import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Credit } from '../../creditmanager/credit.model';
import { BroadcastStatus, BroadcastTemplate} from './broadcast-report.model';
import { BroadcastReportService } from './broadcast-report.service';
import { BroadcastService } from '../../broadcast/broadcast.service';
import { Gateway } from '../../broadcast/broadcast.model';
declare var $: any;

@Component({
  selector: 'app-broadcast-report',
  templateUrl: './broadcast-report.component.html',
  styleUrls: ['./broadcast-report.component.css']
})
export class BroadcastReportComponent implements OnInit {

  selectedChannel = '';
  selectedGateway = '';
  selectedFromDate: Date;
  selectedToDate: Date;
  defaultValue = '';

  broadcastList: BroadcastTemplate[] = [];
  broadcast: BroadcastTemplate[] = [];
  channelTypes: Credit[] = [];
  gatewayTypes: any[] = [];
  broadcastStatusList: BroadcastStatus[] = [];
  broadcastStatus: BroadcastStatus[] = [];
  gatewayList: Gateway[] = [];

  serviceList: Credit[] = [];

  constructor(
    private toastr: ToastrService,
    private broadcastService: BroadcastService,
    private broadcastReportService: BroadcastReportService) { }

  getAllBroadcastRecords() {
    
    this.broadcastReportService.getAllBroadastRecords().subscribe((data: BroadcastTemplate[]) => {
      this.broadcast = data;
      console.log(this.broadcast);
    });
  }

  getAllServices() {
    this.broadcastReportService.getAllServices().subscribe((data: Credit[]) => {
      this.serviceList = data;
      console.log(this.serviceList);
    });
  }
  onServiceChange(ServiceId: any) {
    this.broadcastService.getAllGatewayByServiceId(Number(ServiceId)).subscribe((data: Gateway[]) => {
      console.log(data);
      this.gatewayList = data;
    });
    // this.filterGatewayList(ServiceId);
  }
  getAllBroadcastStatus() {
    this.broadcastReportService.getAllMessageSentStatus().subscribe((data: BroadcastStatus[]) => {
      this.broadcastStatusList = this.broadcastStatus = data;
    });
  }

  getAllGatewayList() {
    this.broadcastService.getAllGateways().subscribe((data: Gateway[]) => {
      this.gatewayList = data;
    });
    // this.gatewayList.push("AddHippo"); // dummy array
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === '') {
      this.broadcastList = this.broadcast;
    } else {
      this.broadcastList = this.broadcast
        .filter((broadcast: BroadcastTemplate) => broadcast.senderIdRequestName // filter column need to be changed -
                                                                                // senderIdRequestName is used for testing
          .includes(keyword.toLocaleLowerCase())
        );
    }
  }
  filterReport() {

    if (this.selectedFromDate !== undefined && this.selectedChannel !== undefined &&
      this.selectedToDate !== undefined && this.selectedGateway !== undefined) {

      this.broadcastList = this.broadcast.filter((element) => {
        console.log(element.serviceId === this.selectedChannel);
        console.log(this.selectedGateway);
        console.log(new Date(element.createdDate).getTime());
        console.log(new Date(this.selectedToDate).getTime());
        return element.serviceId.toString() === this.selectedChannel.toString() &&
        element.gatewayId.toString() === this.selectedGateway.toString() &&
        new Date(element.createdDate) >= new Date(this.selectedFromDate)
        && new Date(element.createdDate) <= new Date(this.selectedToDate);
      });

      if (this.broadcastList.length === 0) {
        this.toastr.warning('Records not found!', 'Sorry', {
          timeOut: 4000
        });
      }
    } else {
      this.toastr.warning('All fields are mandatory !', 'Sorry', {
        timeOut: 4000
      });
    }
  }

  onKeyUpEventBroadcastPopup(event: any) {
    this.filterMessageStatus(event.target.value);
  }

    filterMessageStatus(keyword: any) {
      if (keyword === '') {
        this.broadcastStatusList = this.broadcastStatus;
      } else {
        this.broadcastStatusList = this.broadcastStatus
          .filter((brd: BroadcastStatus) => brd.contactNumber // filter column need to be changed - senderIdRequestName is used for testing
            .includes(keyword.toLocaleLowerCase())
          );
      }
  }
  clearFilter() {
    this.selectedChannel = this.selectedGateway = '';
    this.defaultValue = null;
    this.broadcastList = [];
  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('-');
  }


  ngOnInit(): void {

    this.defaultValue = this.formatDate(new Date());
    this.selectedFromDate = new Date();
    this.selectedToDate = new Date();

    $('#fromDate').datepicker().on('changeDate', (e) => {
      this.selectedFromDate = new Date(e.date);
    });

    $('#toDate').datepicker().on('changeDate', (e) => {
      this.selectedToDate = new Date(e.date);
    });

    this.getAllBroadcastRecords();
    this.getAllServices();
    this.getAllBroadcastStatus();

  }

}
