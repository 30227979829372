import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { UpdatePaymentComponent } from './update-payment/update-payment.component';

const routes: Routes = [
    {path: 'update-payment', component: UpdatePaymentComponent},
    {path: 'payment-history', component: PaymentHistoryComponent}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BillingRoutingModule { }
