import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeeTransactionReportService } from './feetransactionreprt.service';
import { FeeTransactionReportModel } from './feetransactionreport.model';
import { ClassCourse } from '../../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../../mastersettings/models/batch';
import { BatchService } from '../../../mastersettings/batch/batch.service';
import { FeeReciept, FeeRecieptDetailDetail, onlinefeeRecieptsDetail} from '../feereceipt/feereceipt.model';
import {SettingsService} from '../../../mastersettings/settings.service';
import { Academicyear } from '../../../mastersettings/models/academicyear';
import { ReportAttribute } from '../../../studentmanagement/report.model';
import { environment } from '../../../../../../environments/environment';
import { Student } from './../../../studentmanagement/student.model';
import { StudentService } from './../../../studentmanagement/student.service';
import { getTime } from 'ngx-bootstrap/chronos/utils/date-getters';
//import { InstallmentSummaryModel } from '../installmentsummary/installmentsummary.model';
import { reduce } from 'rxjs/operators';
import { borderTopRightRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import {  DatePipe, formatDate } from '@angular/common';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';

import { PaymentMode } from '../../../mastersettings/models/paymentmode';
import { FeeCollectionReportModel } from '../feecollectionreport/feecollectionreport.model';
import { FeeCollectionService } from '../../feecollection/feecollection.service';
import { FeeMaster } from '../../feecollection/feecollection.model';
import { FeeCollectionReportService } from '../feecollectionreport/feecollectionreport.service';
import { PaymentModeService } from '../../../mastersettings/paymentmode/paymentmode.service';
declare var $: any;


@Component({
  selector: 'app-feetransactionreport',
  templateUrl: './feetransactionreport.component.html',
  styleUrls: ['./feetransactionreport.component.css']
})
export class FeetransactionreportComponent implements OnInit {
  //fromDate: string;
  //toDate: string;
  //dates:string;
  dateFrom: any;
  dateTo: any;
  transactionMode: string;
  paymentStatus: string;
  payMode: string;
  totalTransactionAmount: any;
  feesColumn: ReportAttribute[] = [];
  paymentModes:PaymentMode[] = [];
  feeTransactionReportList: FeeTransactionReportModel[] = [];
  feeTransactionReportFilteredList: FeeTransactionReportModel[] = [];
  feeTransactionReportTemp: FeeTransactionReportModel[] = [];
  feeReciept: FeeReciept = {} as FeeReciept;
  onlinefeeReciepts:  onlinefeeRecieptsDetail[] = [] ;
  //Report
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  feeCollectionReportFilteredList: FeeCollectionReportModel[] = [];
  totalPaidAmount: any;
  institution: any;
  currentAcademicYearName = '';
  isLoading: boolean = false;
  paymentsModes: PaymentMode[];
  constructor(
    public feetranSvc: FeeTransactionReportService,
    public feeCollectionService: FeeCollectionService,
    public feeCollectionReportService: FeeCollectionReportService,
    private paymentModeService:PaymentModeService
    ) { }
    generateAttributes() {
      
      this.attributes = [
        {name: 'S NO', fieldName: 'index' },
        {name: 'Transaction ID', fieldName: 'transactionId' },
        {name: 'Transaction Date', fieldName: 'transactionDate' },
        {name: 'Admission No', fieldName: 'admissionId' },
        {name: 'Receit No', fieldName: 'receiptNo' },
        {name: 'Payment Mode', fieldName: 'payMode' },
        {name: 'Payment Method', fieldName: 'payMethod' },
        {name: 'Amount', fieldName: 'amount' },
        {name: 'Status', fieldName: 'paymentStatus' },
       // {name: 'paidAmount', fieldName: 'paidAmount' },
        
      ];
    }
  ngOnInit(): void { 

    
   this.generateAttributes();
    this.transactionMode="-1";
    this.payMode="-1";
    this.paymentStatus="2";
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    // date 
    // let date = new Date();
    // this.dateFrom=new Date(date.getFullYear(), date.getMonth(), date.getDate());
    // this.dateTo=new Date(date.getFullYear(), date.getMonth(), date.getDate());
    // const datePipe = new DatePipe('en-Us');
    // var fdt=datePipe.transform(this.dateFrom, 'yyyy-MM-dd');
    // var tdt=datePipe.transform(this.dateTo, 'yyyy-MM-dd');

    var date = new Date();
    const datePipe = new DatePipe('en-Us');
    var firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    var lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    var fdt=datePipe.transform(firstDay, 'yyyy-MM-dd');
     var tdt=datePipe.transform(lastDay, 'yyyy-MM-dd');
   // this.setCurrentDate();

    //  const datePipe = new DatePipe('en-Us');
    //  var fdt=datePipe.transform(this.dateFrom, 'yyyy-MM-dd');
    //  var tdt=datePipe.transform(this.dateTo, 'yyyy-MM-dd');
    console.log(fdt)
    console.log(tdt)

    this.getFeeTransactionData("-1","-1",2,fdt,tdt);
    this.setColumnHeader();
    this.getAllPaymentMethod();
    // this.getAllFeeTransactionData();
    this.setCurrentDate();
   
  }

  public formatDate(date,onlyDate =false) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return !onlyDate ? new Date().toLocaleDateString() : [day, month, year].join('-');
  }
  generateExcel() {
    this.headerList =[];
    this.fieldList =[];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    this.defaultArray = [];
    for(var i = 0 ; i < this.feeTransactionReportFilteredList.length ; i++)
    {
      let data ={
        "index" : i+1,
        "transactionId" : this.feeTransactionReportFilteredList[i].transactionId == undefined ? "":this.feeTransactionReportFilteredList[i].transactionId,
        "transactionDate" : this.feeTransactionReportFilteredList[i].transactionDate == undefined ?"": this.feeTransactionReportFilteredList[i].transactionDate ,
        "admissionId" :this.feeTransactionReportFilteredList[i].admissionId == undefined ? "" : this.feeTransactionReportFilteredList[i].admissionId,
        "receiptNo" : this.feeTransactionReportFilteredList[i].receiptNo == undefined ? "" :  this.feeTransactionReportFilteredList[i].receiptNo,
        "payMode" : this.feeTransactionReportFilteredList[i].transactionMode == undefined ? "" : this.feeTransactionReportFilteredList[i].transactionMode,
        "payMethod" : this.feeTransactionReportFilteredList[i].payMethod == undefined ? "":this.feeTransactionReportFilteredList[i].payMethod,
        "amount" : this.feeTransactionReportFilteredList[i].amount == undefined ? "" :  this.feeTransactionReportFilteredList[i].amount,
        "paymentStatus" : this.feeTransactionReportFilteredList[i].paymentStatus == undefined ? "" : this.feeTransactionReportFilteredList[i].paymentStatus,
        //"paidAmount" : this.feeTransactionReportFilteredList[i].amount == undefined ? "" : this.feeTransactionReportFilteredList[i].amount,
        
      }
      this.defaultArray.push(data);
    }
    let data1 ={
      "index" : "",
      "transactionId" : "",
        "transactionDate" : "",
        "admissionId" :"",
        "receiptNo" : "",
        "payMode" : "",
        "payMethod" : "Total Amount",
        "amount" : this.totalTransactionAmount,
        "paymentStatus" : "",
    }
    this.defaultArray.push(data1);
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('FEE COLLECTION SUMMARY');
    worksheet.addRow();

    const titleRow = worksheet.addRow(['FEE COLLECTION SUMMARY']);
    const headerRow = worksheet.addRow(this.headerList);
    for(var i = 0 ; i < this.defaultArray.length ; i++)
    this.defaultArray[i]["index"] = i+1;
    for (const x1 of this.defaultArray) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'transactionDate') {
          if(x1[y]=="" || x1[y]==undefined){
            temp.push("");
          }else{
            temp.push(this.formatDate(x1[y],true));
          }
        } else {
          if(y === "paymentStatus")
          {
           ;
            if(x1[y] === "Success" ){
              temp.push(x1[y]);
            }
            else if(x1[y] === "Failed" ){
              temp.push(x1[y]);
            }else{
              temp.push("")
            }
          }
          else{
            temp.push(x1[y]);
          }
        }
      }
      worksheet.addRow(temp);
    }
    const fname = 'FEE COLLECTION SUMMARY';
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }
  defaultArray = [];
  generatePDF() {
   
    var pdffromdate=formatDate(this.dateFrom,'dd-MM-yyyy',"en-US");
    var pdftodate=formatDate(this.dateTo,'dd-MM-yyyy',"en-US");
    this.headerList = [];
    this.fieldList = [];
    
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    //debugger
    for (let i = 0; i < this.feeTransactionReportFilteredList.length; i++) {
      this.feeTransactionReportFilteredList[i]["index"] = i + 1;
    }
    const widthList = [];
    for (const item of this.headerList) {
        widthList.push('auto');
    }
    //console.log("widthList", widthList);
    this.defaultArray = [];
    for(var i = 0 ; i < this.feeTransactionReportFilteredList.length ; i++)
    {
      let data ={
        "index" : i+1,
        "transactionId" : this.feeTransactionReportFilteredList[i].transactionId == undefined ? "":this.feeTransactionReportFilteredList[i].transactionId,
        "transactionDate" : this.feeTransactionReportFilteredList[i].transactionDate == undefined ?"": this.feeTransactionReportFilteredList[i].transactionDate ,
        "admissionId" :this.feeTransactionReportFilteredList[i].admissionId == undefined ? "" : this.feeTransactionReportFilteredList[i].admissionId,
        "receiptNo" : this.feeTransactionReportFilteredList[i].receiptNo == undefined ? "" :  this.feeTransactionReportFilteredList[i].receiptNo,
        "payMode" : this.feeTransactionReportFilteredList[i].transactionMode == undefined ? "" : this.feeTransactionReportFilteredList[i].transactionMode,
        "payMethod" : this.feeTransactionReportFilteredList[i].payMethod == undefined ? "":this.feeTransactionReportFilteredList[i].payMethod,
        "amount" : this.feeTransactionReportFilteredList[i].amount == undefined ? "" :  this.feeTransactionReportFilteredList[i].amount,
        "paymentStatus" : this.feeTransactionReportFilteredList[i].paymentStatus == undefined ? "" : this.feeTransactionReportFilteredList[i].paymentStatus,
        //"paidAmount" : this.feeTransactionReportFilteredList[i].amount == undefined ? "" : this.feeTransactionReportFilteredList[i].amount,
        
      }
      this.defaultArray.push(data);
    }
    let data1 ={
      "index" : "",
      "transactionId" : "",
        "transactionDate" : "",
        "admissionId" :"",
        "receiptNo" : "",
        "payMode" : "",
        "payMethod" : "Total Amount",
        "amount" : this.totalTransactionAmount,
        "paymentStatus" : "",
        
        //"paidStatus" : "",
        //"rollNo" : "Total Amount",
        //"paidAmount" :this.totalTransactionAmount,
    }
    
    this.defaultArray.push(data1);
    const docDefinition = {
      pageSize: 'A4',
      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      // pageMargins: [ 40, 60, 40, 60 ],
      content: [
        {
            table: {
                widths: ['auto', '*'],
                body: [
                    [ {
                        width: 100,
                        height: 100,
                        image: 'imageKey'
                    }, {
                        width: '*',
                        alignment: 'center',
                        stack: [
                            {
                                style: 'h1',
                                text: this.institution.institutionName
                            },
                            {
                              style: 'h2',
                              text: this.institution.address
                            },
                            {
                                style: 'h2',
                                text: this.currentAcademicYearName
                            },
                            {
                                style: 'h3',
                                text: 'FEE TRANSACTION REPORT'
                            },
                            {
                              style: 'h3',
                              text: pdffromdate +'   to   '+  pdftodate
                            }
                        ]
                    }
                    ]
                ]
            },
            layout: {
                hLineWidth: () =>  0,
                vLineWidth: () => 0,
                paddingBottom: () => 5,
                paddingLeft:()=> 5,
                paddingRight:()=> 5
            },
          },
          {
            style:'sectionHeader',
            table: {
              headerRows: 1,
              widths: widthList,
              body: [
                this.headerList,
                ...this.defaultArray.map((p) => {
                  const temp = [];
                  for (const y of this.fieldList) {
                    if (y === 'transactionDate') {
                     
                      if(p[y]=="" || p[y]==undefined){
                        temp.push("");
                      }else{
                        temp.push(this.formatDate(p[y],true));
                      }
                    } else {
                      if(y === "paymentStatus")
                      {
                       ;
                        if(p[y] === "Success"){
                          temp.push(p[y]);
                        }
                        else if(p[y] === "Failed"){
                          temp.push(p[y]);
                        }else{
                          temp.push("")
                        }
                      }
                      else{
                        temp.push(p[y]);
                      }

                       
                    }
                  }
                  return temp;
                }),
                
                // {
                //   width: '*',
                //   alignment: 'center',
                //   stack: [
                //       {
                //           style: 'h1',
                //           text: "Total Paid Amount :" + this.totalPaidAmount
                //       }
                //     ]
                //   }
              ]
            }
          },
          // { text: 'Total Paid Amount: '+ this.totalPaidAmount, alignment: 'center',fontSize: 14 },
      ],
      images: {
         imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
         // via URL address, which can be referenced by this key
      },
      styles: {
        
        sectionHeader: {
          // bold: true,
          // decoration: 'underline',
          fontSize: 8,
          margin: [0, 10, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 13,
          bold: true,
          // fontWeight:800
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        },
       
      }
    };
    pdfMake.createPdf(docDefinition).download('FEE COLLECTION.pdf');
  }
  getAllPaymentMethod()
  {
    this.feetranSvc.getAllpaymentMethod().subscribe((data:PaymentMode[]) => {
        this.paymentModes = data;
        console.log(this.paymentModes)
    });
  }

  
  getAllFeeTransactionData()
  {   
     this.feetranSvc.getAllFeeTransactionReport().subscribe((data: any) => {
       this.feeTransactionReportFilteredList = this.feeTransactionReportList = data.table;
       this.getSum();
     });
     this.feeTransactionReportTemp = this.feeTransactionReportList;
  }

  getFeeTransactionData(transactionMode,payMode,paymentStatus,dateFrom,dateTo)
  {   
    this.isLoading = true;
     this.feetranSvc.getFeeTransactionReport(transactionMode,payMode,paymentStatus,dateFrom,dateTo).subscribe((data: any) => {
       this.feeTransactionReportFilteredList = this.feeTransactionReportList = data.table;
       this.getSum();
       this.isLoading = false;
     });
     this.feeTransactionReportTemp = this.feeTransactionReportList;
  }
  //public onlinefeeReciepts: any=[];
  GetonData (item: any ){
    debugger
    this.feeCollectionReportService.getById(item.orderId).subscribe((data) => {
      this.onlinefeeReciepts = data;
      console.log(data);
    });
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === '') {
      var date = new Date();
      const datePipe = new DatePipe('en-Us');
      var firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      var lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      var fdt=datePipe.transform(firstDay, 'yyyy-MM-dd');
       var tdt=datePipe.transform(lastDay, 'yyyy-MM-dd');
       
      this.getFeeTransactionData("-1","-1",0,fdt,tdt);
    } else {
      this.feeTransactionReportTemp = this.feeTransactionReportTemp.filter(d => {
        return (d.transactionMode.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
          d.receiptNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
          d.payMode.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()));
      });
      this.feeTransactionReportFilteredList = this.feeTransactionReportTemp;
      this.getSum();

    }
  }




  getSum(){
    const sum = this.feeTransactionReportFilteredList.map(item => parseFloat(item.paymentStatus === 'Success' ? item.amount : '0')).reduce((prev, curr) => prev + curr, 0);
    this.totalTransactionAmount = sum;
  }

  setCurrentDate() {
    let date = new Date();
    this.dateFrom=new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.dateTo=new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }


  ListPaymentModes() {
    this.paymentModeService.getAll().subscribe((data:PaymentMode[])=>
    {
      this.paymentsModes=data;
    });  
    }

  recptid:any;
  public FeeReceiptList: any;
  public FeeHeadItemList: any;
  public InstallmentNameList: any=[];
  public FeeReceiptDataobj: any={};
  lblDueAdvance = 'Due';
  feeHeads: FeeRecieptDetailDetail[] = [];
  getReciept(id: any) {
    debugger
    this.feeReciept = {} as FeeReciept;
    this.recptid=id;
    this.ListPaymentModes();
    this.isLoading = true;
    this.feeCollectionReportService.getFeeReciept(id).subscribe((data) => {
      ;
       console.log(data);      
       this.feeReciept = data[0];
       this.FeeReceiptList =[];
       this.FeeReceiptList = data;
       this.FeeHeadItemList = this.FeeReceiptList[0].feeCollectionHeadInstallment.map(item => item.feeHeadName).filter((value, index, self) => self.indexOf(value) === index)
       this.InstallmentNameList = this.FeeReceiptList[0].feeCollectionHeadInstallment.map(_item => _item.installmentName).filter((value, index, self) => self.indexOf(value) === index)
       this.InstallmentNameList = this.InstallmentNameList.toString();
      ;
      
       this.FeeReceiptDataobj={
       ReceiptNo: this.FeeReceiptList[0].receiptNo,
       StudentName: this.FeeReceiptList[0].studentName,
       ClassCourseName: this.FeeReceiptList[0].classCourseName,
       BatchName: this.FeeReceiptList[0].batchName,
       AdmissionNo: this.FeeReceiptList[0].admissionNo,
       RollNo: this.FeeReceiptList[0].rollNo,
       Amount: this.FeeReceiptList[0].amount,
       Discount: this.FeeReceiptList[0].discount,
       PaidAmount: this.FeeReceiptList[0].paidAmount,
       PayableAmount: this.FeeReceiptList[0].payableAmount,
       FineAmount: this.FeeReceiptList[0].fineAmount,
       InstallmentAmount: this.FeeReceiptList[0].installmentAmount,
       DueAmount: this.FeeReceiptList[0].dueAmount,
       AdvanceAmount: this.FeeReceiptList[0].advanceAmount,
       PayDate: this.FeeReceiptList[0].payDate,
       PayMode: this.paymentsModes.find(x=>x.id==this.FeeReceiptList[0].payMode).paymentModeName,
      };
     
       this.FormatData(this.FeeReceiptDataobj,this.FeeReceiptList[0].feeCollectionHeadInstallment,this.FeeHeadItemList);
       this.isLoading = false;
       if (this.FeeReceiptList[0].advanceAmount > 0 ) {
         this.lblDueAdvance = 'ADVANCE';
       } else if (this.FeeReceiptList[0].dueAmount > 0) {
         this.lblDueAdvance = 'DUE';
       }
       this.feeReciept.feeCollectionDetails.forEach((fdata) => {
          fdata.feeCollectionFeeHeadDetails.forEach((x) => {
           this.feeHeads.push(x);
          });
       });
     });
  }
  FormatData(Obj: any, dataList:any=[], installamentNameList:any)
  {
    Obj.FeeHeadDataList = [];
    let TotalAmount = 0;
    //let TotalDiscountAmount = 0;
    for(let instItemName of installamentNameList)
    {
      let SumOfHeadItem = 0;
      let SumOfHeadItemDiscount = 0;
      let SumOfpayableamount=0;
     ;
      let HeadItemList =  dataList.filter(_installmentItem=>_installmentItem.feeHeadName==instItemName);
      for(let _headitem of HeadItemList)
      {
        SumOfHeadItem = SumOfHeadItem + Number(_headitem.amount);
        SumOfHeadItemDiscount = SumOfHeadItemDiscount + Number(_headitem.discountRate);
        SumOfpayableamount=SumOfpayableamount + Number(_headitem.amountPayable);
      }
      TotalAmount = TotalAmount + SumOfHeadItem;
      ///TotalDiscountAmount = TotalDiscountAmount + SumOfHeadItemDiscount;
      let FeeHeadDataObj ={
        FeeHeadName: instItemName,
        Amount: SumOfHeadItem,
        discountRate:SumOfHeadItemDiscount,
        paidamount:SumOfpayableamount
      }      
     ;
      Obj.FeeHeadDataList.push(FeeHeadDataObj);
    }
    Obj.TotalAmount = TotalAmount;
   // Obj.TotalDiscountAmount = TotalDiscountAmount;
  }
  onSearch() {

    // const drpPayment = this.payMode;
    // console.log(drpPayment)
    // const fromDate = new Date(this.dateFrom).setHours(0,0,0,0);
    // const toDate = new Date(this.dateTo).setHours(0,0,0,0);
   
    //var fdf=datePipe.transform($("#dateFrom").val(), 'dd-MM-yyyy');
    //var tdf=datePipe.transform($("#dateTo").val(), 'dd-MM-yyyy');

    let fdfv=new Date($("#dateFrom").datepicker('getDate'));
    let tdfv=new Date($("#dateTo").datepicker('getDate'));
    
    this.dateFrom=new Date(fdfv.getFullYear(),fdfv.getMonth(),fdfv.getDate());
    this.dateTo=new Date(tdfv.getFullYear(), tdfv.getMonth(),tdfv.getDate());
 
    const datePipe = new DatePipe('en-Us');
    var fdt=datePipe.transform(this.dateFrom, 'yyyy-MM-dd');
    var tdt=datePipe.transform(this.dateTo, 'yyyy-MM-dd');
    this.dateFrom=fdt;
    this.dateTo=tdt;

     console.log(this.payMode);
     console.log(fdt);
     console.log(tdt);
     console.log("From date:"+ $("#dateFrom").datepicker('getDate'));

    this.getFeeTransactionData(this.transactionMode,this.payMode,this.paymentStatus,fdt,tdt);
    // console.log(this.payMode);
  

    // this.dates=fdt+' To '+tdt;
    // this.feeTransactionReportTemp = this.feeTransactionReportFilteredList = this.feeTransactionReportList;


    // if (typeof this.dateFrom != 'undefined' && typeof this.dateTo != 'undefined' && this.dateFrom != '' && this.dateTo != '') {
    //   this.feeTransactionReportTemp = this.feeTransactionReportTemp.filter(d => {
        
    //     var date = new Date(d.TransactionDate.split('T')[0]).setHours(0,0,0,0);
    //     //console.log("from " + fromDate + " todate " + toDate + " date "+ date + " test" + (date >= fromDate) +" --" + (date <= toDate));    return (date >= fromDate && date <= toDate);
    //     return(date >=fromDate && date<=toDate);
    //   });
    // }
 

    // if (!drpPayment.startsWith("All") && drpPayment != "" && drpPayment != 'undefined') {
    //   this.feeTransactionReportTemp = this.feeTransactionReportTemp.filter(d => {
    //     return (d.payMode.toLocaleLowerCase().includes(drpPayment.toLocaleLowerCase()));
    //   });
    // }
    // this.feeTransactionReportFilteredList = this.feeTransactionReportList;
    // this.getSum();

  }


  setColumnHeader() {
    this.feesColumn = [
      {name: 'Sno', fieldName: 'slno' },
      {name: 'Transaction ID', fieldName: 'TransactionId' },
      {name: 'Transaction Date', fieldName: 'TransactionDate' },
      {name: 'Admission No', fieldName: 'AdmissionId' },
      {name: 'Receit No', fieldName: 'ReceiptNo' },
      {name: 'Payment Mode', fieldName: 'PayMode' },
      {name: 'Payment Method', fieldName: 'PayMethod' },
      {name: 'Amount', fieldName: 'Amount' },
      {name: 'STATUS', fieldName: 'PaymentStatus' },
      
    ];
  }

}
