import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Student } from './../../studentmanagement/student.model';
import { StudentService } from './../../studentmanagement/student.service';
import { InstallmentService } from './../installment/installment.service';
import { Installment } from './../installment/installment.model';
import { FeeMaster, FeeDetail, FeeDetailDetail } from '../feecollection/feecollection.model';
import { FeeCollectionService } from '../feecollection/feecollection.service';
import { FeeWaveoff } from './feewaveoff.model';
import { FeeWaveoffService } from './feewaveoff.service';

declare var $: any;
@Component({
  selector: 'app-feewaveoff',
  templateUrl: './feewaveoff.component.html',
  styleUrls: ['./feewaveoff.component.css']
})
export class FeewaveoffComponent implements OnInit {

  constructor(
    public feeCollectionService: FeeCollectionService,
    public installmentService: InstallmentService,
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public feewaveService: FeeWaveoffService,
    public batchService: BatchService,
    private toastr: ToastrService
  ) { }

  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  lookupAdmissions: Student[] = [];
  lAdmissions: Student[] = [];
  studentDetail: Student = {} as Student;
  installments: Installment[] = [];
  classId = '';
  batchId = '';
  admissionno = '';
  admissionId = '';
  remarks = '';
  installmentIds = '';
  waveOffDate = '';
  lookupMobileNo = '';
  lookupStudentName = '';
  lookupFatherName = '';
  studDetails = false;

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  ListStudents() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      console.log(data);
      this.lAdmissions = data;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }

  onClassSelected(value: string) {
    this.batches = [];
    this.admissions = [];
    this.studentDetail = {} as Student;
    this.resetEForm();
    this.classId = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
      console.log(this.batches);
    });
  }

  onBatchChanged(value: string) {
    this.admissions = [];
    this.studentDetail = {} as Student;
    this.resetEForm();
    this.studentService.getAll().subscribe((data: Student[]) => {
     // this.ListFeeHead();
      this.admissions = data.filter((student: Student) => {
      return student.classCourseId.toString() === this.classId &&
        student.batchId.toString() === value.toString();
      });
    });
  }
  onStudentChanged(id: number) {
    console.log(id);
    this.admissionId = id.toString();
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.studentDetail = data.filter((student: Student) => {
        return student.id === id;
      })[0];
      this.studDetails = true;
      this.installmentService.getFeePayment(this.studentDetail.id).subscribe((inst: Installment[]) => {
        console.log(inst);
        this.installments = inst;
     });
      // this.feeRefundService.getPayment(this.studentDetail.id).subscribe((inst: FeeRefundInstallment[]) => {
      //   this.installments = inst;
      // });
    });
    // this.studentService.getById(id).subscribe((data: Student) => {
    //     this.studentDetail = data;
    //     this.installmentService.getFeePayment(this.studentDetail.id).subscribe((inst: Installment[]) => {
    //       this.installments = inst;
    //    });
    //  });
  }

  searchLookupStudent() {
    console.log(this.lookupStudentName);
    if (this.lookupStudentName !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
         return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase());
      });
    }
    if (this.lookupFatherName !== '') {
       this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
          return data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase());
       });
     }
    if (this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
         return data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase());
      });
    }

    if (this.lookupStudentName !== '' && this.lookupFatherName !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
         return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase()) &&
                data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase());
      });
    }
    if (this.lookupStudentName !== '' && this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
         return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase()) &&
              data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase());
      });
    }
    if (this.lookupFatherName !== '' && this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
         return data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase()) &&
              data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase());
      });
    }
    if (this.lookupStudentName !== '' && this.lookupFatherName !== '' && this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
         return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase()) &&
              data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase()) &&
              data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase()) ;
      });
    }
  }

  resetLookUpStudent() {
    this.lookupAdmissions = [];
    this.lookupStudentName = '';
    this.lookupMobileNo = '';
    this.lookupFatherName = '';
  }

  openLookUp() {
    this.resetLookUpStudent();
  }

  resetForm() {
    
    this.installments = [];
    this.installmentIds = '';
    this.batches = [];
    this.classId = '';
    this.batchId = '';
    this.lookupAdmissions = [];
    this.admissionno = '';
    this.admissionId = '';
    this.remarks = '';
    $('#waveoffDate').val(this.formatDate(new Date()));
    this.waveOffDate = this.formatDate(new Date());
    this.lookupMobileNo = '';
    this.lookupStudentName = '';
    this.lookupFatherName = '';
    this.studentDetail = {} as Student;
    this.studDetails = false;
    this.admissions = [];
    $('.select2').select2('data', null);
  }

  resetEForm() {
    this.installments = [];
    this.installmentIds = '';
    this.lookupAdmissions = [];
    this.admissionno = '';
    this.admissionId = '';
    this.remarks = '';
    $('#waveoffDate').val(this.formatDate(new Date()));
    this.waveOffDate = this.formatDate(new Date());
    this.lookupMobileNo = '';
    this.lookupStudentName = '';
    this.lookupFatherName = '';
    this.studentDetail = {} as Student;
    this.studDetails = false;
    // $('.select2').select2('val', '');
  }

   submitForm() {
    
    this.installmentIds = '';
    let k = 1;
    const totalSelected = this.installments.filter(x => x.checked).length;
    this.installments.forEach((x, i) => {
      if (x.checked) {
        if (totalSelected !== k) {
          this.installmentIds += x.id.toString() + '|';
        } else {
          this.installmentIds += x.id.toString();
        }
        k++;
      }
    });


    if (this.installmentIds === '') {
      this.toastr.warning('Please select installment', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }

    if (this.waveOffDate.toString() === '') {
      this.toastr.warning('Please select date', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }

    if (this.remarks === '') {
      this.toastr.warning('Please enter remarks', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }
        // stop here if form is invalid

    // console.log(this.classworkForm.get('file').value);
    const feeWaveOff = {} as FeeWaveoff;
    feeWaveOff.id = 0;
    feeWaveOff.admissionId = this.admissionId;
    feeWaveOff.academicYearId = this.lAdmissions[0].academicYearId;
    feeWaveOff.installmentIds = this.installmentIds;
    feeWaveOff.remarks = this.remarks;
    feeWaveOff.waveOffDate = this.waveOffDate;
    feeWaveOff.status = 'true';

    // if (this.classworkForm.get('id').value === 0) {
    this.feewaveService.create(feeWaveOff).subscribe(res => {
      console.log('Fee wave created!');
      // this.ListClassworks();
      this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
        timeOut: 3000
      });
      // this.submitted = false;
      // this.resetForm();
      this.onStudentChanged(Number(this.admissionId));
    });

    // } else {
          // this.classworkService.update(this.classworkForm.get('id').value, this.classworkForm.value).subscribe(res => {
          // this.classworkService.update(this.classworkForm.get('id').value, formData).subscribe(res => {
          //   console.log('subject updated!');
          //   this.ListClassworks();
          //   this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          //     timeOut: 3000
          //   });
          //   this.submitted = false;
          //   this.resetForm();
          //   // this.casteForm.reset();
          // });
      //  }
   }


  ngOnInit(): void {
    this.ListStudents();
    const self = this;
    this.ListClassCourse();
    $('.select2').select2();
    $('.select2').on('change', function() {
      self.onStudentChanged(Number($(this).val()));
      self.admissionId = $(this).val();
     });
    $('#waveoffDate').val(this.formatDate(new Date()));
    this.waveOffDate = this.formatDate(new Date());
    $('#waveoffDate').datepicker().on('changeDate', (e) => {
      self.waveOffDate =  this.formatDate(e.date);
    });
  }

}
