import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Template } from '../configuration/templatemanager/template.model';
import { Student } from '../../academic/studentmanagement/student.model';

import { AppBroadcast } from './appbroadcast.model';
import { Department } from '../../OperationActivity/hrmspayroll/master/models/departement.model';
import { Employeeadmission } from '../../OperationActivity/hrmspayroll/hrms/models/employeeadmission.model';
import { Batch } from '../../academic/mastersettings/models/batch';


@Injectable({
    providedIn: 'root'
  })
  export class AppBroadcastService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         enctype: 'multipart/form-data'
      })
    };

    constructor(private httpClient: HttpClient) { }
    getAllAppBroadcasts(): Observable<AppBroadcast[]> {
      return this.httpClient.get<AppBroadcast[]>(environment.apiUrl + '/appbroadcast/')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    create(appbroadcast): Observable<AppBroadcast> {
      return this.httpClient.post<AppBroadcast>(environment.apiUrl + '/appbroadcast/', appbroadcast, this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getAllStudents(): Observable<Student[]> {
      return this.httpClient.get<Student[]>(environment.apiUrl + '/StudentAdmission/GetStudents')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getAllTemplates(): Observable<Template[]> {
      return this.httpClient.get<Template[]>(environment.apiUrl + '/template/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getAllBatchDetails(): Observable<Batch[]> {
      return this.httpClient.get<Batch[]>(environment.apiUrl + '/batch/')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getAllEmployees(): Observable<Employeeadmission[]> {
      return this.httpClient.get<Employeeadmission[]>(environment.apiUrl + '/employeeadmission/GetEmployees')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getAllDepartments(): Observable<Department[]> {
      return this.httpClient.get<Department[]>(environment.apiUrl + '/departments/')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }

  }
