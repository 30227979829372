import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FeewaveoffReportModel} from './feewaveoffreport.model';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeeWaveOffReportService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  getFeewaveoffReport(ClassId ,  BatchId , fromDate  , toDate ,  AdmissionNo ): Observable<FeewaveoffReportModel[]> {
  
   return this.httpClient.get<FeewaveoffReportModel[]>(environment.apiUrl + '/FeeCollection/GetFeewaveoffReport?ClassId='+ClassId+'&BatchId='+BatchId+'&fromDate='+fromDate+'&toDate='+toDate+'&AdmissionNo='+AdmissionNo)
    .pipe(
      catchError(this.errorHandler)
    );
  }
 
errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
