import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { City, Employeeadmission, EmployeeBiometric, EmployeeDocument, EmployeeLeave, EmployeeType, JobType, LeaveAllocation, LeaveType, Nationality, NoticePeriod, Qualification, State, Role } from './models/employeeadmission.model';
import { PaymentMode } from 'src/app/admin/academic/mastersettings/models/paymentmode';
// import { GetEmployeeLeaveAttendanceReport } from 'src/app/admin/dashboard/attendanceSummary.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeadmissionService {
  formdata:FormData=new FormData();
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Employee Admission start
  getAll(): Observable<Employeeadmission[]> {
    return this.httpClient.get<Employeeadmission[]>(environment.apiUrl + '/employeelist/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  
 // PaymentMode  
getPaymentModeAll(): Observable<PaymentMode[]> {
  return this.httpClient.get<PaymentMode[]>(environment.apiUrl + '/paymentModelist/')
  .pipe(
    catchError(this.errorHandler)
  );
}

  download(url):Observable<Blob>{
    return this.httpClient.get(environment.imageUrl+url,{responseType:'blob'}).pipe(
      catchError(this.errorHandler)
    );
  }
  getEditById(EmpId): Observable<Employeeadmission> {
    return this.httpClient.get<Employeeadmission>(environment.apiUrl + '/employeelist/'+EmpId)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllEmployee(): Observable<Employeeadmission[]> {
    return this.httpClient.get<Employeeadmission[]>(environment.apiUrl + '/employeeadmission/GetEmployees')
      .pipe(
        catchError(this.errorHandler)
      );
  }
  
  getCout() {
    return this.httpClient.get(environment.apiUrl + '/employeelistCount/')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  GetAllDepartmentWiseEmployee(academicYearId: number) {
    return this.httpClient.get(environment.apiUrl + '/employeelist/GetAllDepartmentWiseEmployee/'+academicYearId)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllEmployeeType(): Observable<EmployeeType[]> {
    return this.httpClient.get<EmployeeType[]>(environment.apiUrl + '/employeetypes/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllQualification(): Observable<Qualification[]> {
    return this.httpClient.get<Qualification[]>(environment.apiUrl + '/qualifications/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllNoticePeriod(): Observable<NoticePeriod[]> {
    return this.httpClient.get<NoticePeriod[]>(environment.apiUrl + '/noticeperiods/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllJobType(): Observable<JobType[]> {
    return this.httpClient.get<JobType[]>(environment.apiUrl + '/jobtypes/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllNationality(): Observable<Nationality[]> {
    return this.httpClient.get<Nationality[]>(environment.apiUrl + '/country/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllRole(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(environment.apiUrl + '/roles/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllState(): Observable<State[]> {
    return this.httpClient.get<State[]>(environment.apiUrl + '/state/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllCity(): Observable<City[]> {
    return this.httpClient.get<City[]>(environment.apiUrl + '/city/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllLeaveType(): Observable<EmployeeLeave[]> {
    return this.httpClient.get<EmployeeLeave[]>(environment.apiUrl + '/leavetypes/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getEmpAllLeaveAllocation(EmpId): Observable<LeaveAllocation[]> {
    return this.httpClient.get<LeaveAllocation[]>(environment.apiUrl + '/leaveallocation/'+EmpId)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getByEmpId(EmpId): Observable<EmployeeDocument[]> {
    return this.httpClient.get<EmployeeDocument[]>(environment.apiUrl + '/employeedocument/' + EmpId)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  employeelistCheckById(EmpId):Observable<any>
  {
    return this.httpClient.get<any>(environment.apiUrl + '/employeelistCheckById/' + EmpId)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getById(id): Observable<Employeeadmission> {
    return this.httpClient.get<Employeeadmission>(environment.apiUrl + '/employeeadmissions/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  
  isCurrentAcademicYearExits(): Observable<Employeeadmission> {
    return this.httpClient.get<Employeeadmission>(environment.apiUrl + '/employeeadmissions/IsCurrentAcademicYearExits')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(academicyear,employeedocumentlist,employeeleavelist,employeeImage,employeeId,basicPay,gradePay,path,genderId,academicYearId,roleId): Observable<Employeeadmission> {
    academicyear.NoticePeriod=+academicyear.NoticePeriod;
    academicyear.NoOfChild=+academicyear.NoOfChild;
    academicyear.JobType=+academicyear.JobType;
    academicyear.Nationality=academicyear.Nationality;
    academicyear.EmergencyNo=+academicyear.EmergencyNo;
    //academicyear.BankName=+academicyear.BankName;    
    academicyear.AccountNo=+academicyear.AccountNo;
    academicyear.PFAccount=+academicyear.PFAccountNo;
    academicyear.UANNo=academicyear.UANNo;
    academicyear.PayScaleId=+academicyear.PayScaleId;
    academicyear.LocalState=+academicyear.LocalState;
    academicyear.LocalCity=+academicyear.LocalCity;
    academicyear.LocalPinCode=+academicyear.LocalPinCode;
    academicyear.LocalContactNo=+academicyear.LocalContactNo;
    academicyear.PermanentState=+academicyear.PermanentState;
    academicyear.PermanentCity=+academicyear.PermanentCity;
    academicyear.PermanentPinCode=+academicyear.PermanentPinCode;
    academicyear.PermanentContactNo=+academicyear.PermanentContactNo;
    academicyear.ImageURI=path;
    academicyear.genderId=genderId;
    academicyear.EmployeeLeaves=employeeleavelist;
    academicyear.EmployeeDocuments=employeedocumentlist;
    academicyear.employeeImage=employeeImage;
    academicyear.employeeId=employeeId;
    academicyear.GradePay=gradePay;
    academicyear.BasicPay=basicPay;
    academicyear.ERPLoginId=employeeId+'-ERP';
    academicyear.TotalExperienceYear=+ academicyear.Year;
    academicyear.Month=+ academicyear.Month;
    academicyear.RoleId=roleId;

    academicyear.EmployeeLoginId=employeeId;    
    academicyear.academicYearId=Number(academicYearId);
    // this.uploadImage(fieToUpload).subscribe(res=>{
    // console.log(res);
     //  academicyear.ImageURI=res;
     //});
   // this.formdata.append('EmployeeDetail',JSON.stringify(academicyear));

    return this.httpClient.post<Employeeadmission>(environment.apiUrl + '/employeeadmission/',JSON.stringify(academicyear), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id,academicyear,employeedocumentlist,employeeleavelist,employeeImage,employeeId,basicPay,gradePay,path,genderId,academicYearId,roleId): Observable<Employeeadmission> {
    ;
    academicyear.NoticePeriod=+academicyear.NoticePeriod;
    academicyear.NoOfChild=+academicyear.NoOfChild;
    academicyear.JobType=+academicyear.JobType;
    academicyear.Nationality=academicyear.Nationality;
    academicyear.EmergencyNo=+academicyear.EmergencyNo;
    //academicyear.BankName=+academicyear.BankName;
    academicyear.AccountNo=+academicyear.AccountNo;
    academicyear.PFAccount=+academicyear.PFAccountNo;
    academicyear.UANNo=academicyear.UANNo;
    academicyear.PayScaleId=+academicyear.PayScaleId;
    academicyear.LocalState=+academicyear.LocalState;
    academicyear.LocalCity=+academicyear.LocalCity;
    academicyear.LocalPinCode=+academicyear.LocalPinCode;
    academicyear.LocalContactNo=+academicyear.LocalContactNo;
    academicyear.PermanentState=+academicyear.PermanentState;
    academicyear.PermanentCity=+academicyear.PermanentCity;
    academicyear.PermanentPinCode=+academicyear.PermanentPinCode;
    academicyear.PermanentContactNo=+academicyear.PermanentContactNo;
    academicyear.ImageURI=path;
    academicyear.genderId=genderId;
    academicyear.EmployeeLeaves=employeeleavelist;
    academicyear.EmployeeDocuments=employeedocumentlist;
    academicyear.employeeImage=employeeImage;
    academicyear.EmployeeTypeId=+academicyear.EmployeeTypeId;
    // academicyear.employeeId=employeeId;
    academicyear.GradePay=gradePay;
    academicyear.BasicPay=basicPay;
    academicyear.RoleId = roleId;
    // academicyear.ERPLoginId=employeeId+'/ERP';
    academicyear.TotalExperienceYear=+ academicyear.Year;
    academicyear.Month=+ academicyear.Month;


    academicyear.EmployeeLoginId=employeeId;
    academicyear.academicYearId=Number(academicYearId);
    // this.uploadImage(fieToUpload).subscribe(res=>{
    //   console.log(res);
    //   academicyear.ImageURI=res;
    // });
   // this.formdata.append('EmployeeDetail',JSON.stringify(academicyear));
    ;
    return this.httpClient.post<Employeeadmission>(environment.apiUrl + '/employeeadmission/'+id,JSON.stringify(academicyear), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  uploadImage(file:any){
   return this.httpClient.post(environment.apiUrl+'/upload', file)
      .pipe(catchError(this.errorHandler));
  }
  UpdateBiometricCode(id): Observable<Employeeadmission> {
    ;
   
    return this.httpClient.post<Employeeadmission>(environment.apiUrl + '/insertupdatebiometeric/', JSON.stringify(id), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllEmployeesBiometric(): Observable<EmployeeBiometric[]> {
    return this.httpClient.get<EmployeeBiometric[]>(environment.apiUrl + '/GetAllEmployeesBiometric/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}