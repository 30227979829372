import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Position } from './models/position';

@Injectable({
  providedIn: 'root'
})
export class PositionService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<Position[]> {
    return this.httpClient.get<Position[]>(environment.apiUrl + '/positions/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<Position> {
    return this.httpClient.get<Position>(environment.apiUrl + '/positions/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  isCurrentAcademicYearExits(): Observable<Position> {
    return this.httpClient.get<Position>(environment.apiUrl + '/positions/IsCurrentAcademicYearExits')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(position): Observable<Position> {
    if(position.status=='1')
      {
        position.active=true;
      }else 
      {
        position.active=false;
      }
    return this.httpClient.post<Position>(environment.apiUrl + '/positions/', JSON.stringify(position), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, academicyear): Observable<Position> {
    // tslint:disable-next-line:max-line-length
    if(academicyear.status=='1')
      {
        academicyear.active=true;
      }else 
      {
        academicyear.active=false;
      }
    return this.httpClient.put<Position>(environment.apiUrl + '/positions/' + id, JSON.stringify(academicyear), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<Position>(environment.apiUrl + '/positions/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}