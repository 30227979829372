import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offline-examination',
  templateUrl: './offline-examination.component.html',
  styleUrls: ['./offline-examination.component.css']
})
export class OfflineExaminationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
