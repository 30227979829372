import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Credit, CreditTransaction } from './credit.model';

@Injectable({
    providedIn: 'root'
  })
  export class CreditService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         enctype: 'multipart/form-data'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<Credit[]> {
      return this.httpClient.get<Credit[]>(environment.apiUrl + '/credit/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<Credit> {
      return this.httpClient.get<Credit>(environment.apiUrl + '/credit/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getPurchaseCreditById(id): Observable<CreditTransaction> {
      return this.httpClient.get<CreditTransaction>(environment.apiUrl + '/credit/GetCreditTransaction/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    create(credit): Observable<any> {
      return this.httpClient.post(environment.apiUrl + '/credit/', credit)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    purchaseCredit(credit): Observable<any> {
      return this.httpClient.post(environment.apiUrl + '/credit/PurchaseCredit', credit)
      .pipe(
        catchError(this.errorHandler)
      );
    }



    update(id, feehead): Observable<Credit> {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.put<Credit>(environment.apiUrl + '/credit/' + id, JSON.stringify(feehead), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    delete(id) {
      return this.httpClient.delete<Credit>(environment.apiUrl + '/credit/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }

  }
