import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExamWiseAttendenceComponent } from './exam-wise-attendence/exam-wise-attendence.component';


const routes: Routes = [ {
  path:'examwiseattendence', component: ExamWiseAttendenceComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExamWiseAttendenceRoutingModule { }
