import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EvaluationType } from '../ExamSetting/models/evaluation-type.model';
import { GradeScale } from '../ExamSetting/models/grade-scale.model';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GradescaleService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  
//  url:string="http://localhost:5000/";

  constructor(private http:HttpClient) { }

  getEvaluationsTypesByGradeScale(id):Observable<EvaluationType[]>{
    return this.http.get<EvaluationType[]>(environment.apiUrl+"/gradescale/GetEvaluationsTypesByGradeScale?id="+id).pipe(
      catchError(this.errorHandler)
    )
  }

  getAllGradeScale(gsgId,evalId):Observable<GradeScale[]>{
    return this.http.get<GradeScale[]>(environment.apiUrl+"/gradescale/GetGradeScale?gradeScaleGroupId="+gsgId+"&evaluationId="+evalId).pipe(
      catchError(this.errorHandler)
    )
  }

  getGradeScaleById(id):Observable<GradeScale>{
    return this.http.get<GradeScale>(environment.apiUrl+"/gradescale/"+id).pipe(
      catchError(this.errorHandler)
    )
  }
 

  deleteGradeScale(id){
    return this.http.delete<GradeScale>(environment.apiUrl+"/gradescale/"+id,this.httpOptions).pipe(
      catchError(this.errorHandler)
    )
  }

  updateGradeScale(id,data):Observable<GradeScale>{
    return this.http.put<GradeScale>(environment.apiUrl+"/gradescale/"+id,JSON.stringify(data),this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }

  createGradeScale(data):Observable<GradeScale>{
    return this.http.post<GradeScale>(environment.apiUrl+"/gradescale",JSON.stringify(data),this.httpOptions)
          .pipe(catchError(this.errorHandler));
  }

  // This code is for error handling
  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
