import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { City } from '../models/city';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<City[]> {
    return this.httpClient.get<City[]>(environment.apiUrl + '/city/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<City> {
    return this.httpClient.get<City>(environment.apiUrl + '/city/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(city): Observable<City> {
    return this.httpClient.post<City>(environment.apiUrl + '/city/', JSON.stringify(city), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, city): Observable<City> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<City>(environment.apiUrl + '/city/' + id, JSON.stringify(city), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<City>(environment.apiUrl + '/city/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
