// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table tr.active td {
    background-color:#c3e6cb !important;
    /*color: white;*/
}`, "",{"version":3,"sources":["webpack://./src/app/admin/academic/timetable/period/period.component.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,gBAAgB;AACpB","sourcesContent":[".table tr.active td {\r\n    background-color:#c3e6cb !important;\r\n    /*color: white;*/\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
