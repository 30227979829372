import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import {SubjectcreationService} from '../../academicsettings/subjectcreation/subjectcreation.service';
import { SubjectCreation } from '../../academicsettings/subjectcreation/subjectcreation.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Assignment } from './assignment.model';
import { AssignmentService } from './assignment.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { saveAs } from 'file-saver';
import { SettingsService } from '../../mastersettings/settings.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
declare var $: any;
@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.css']
})
export class AssignmentsComponent implements OnInit {

  assignForm: UntypedFormGroup;
  classcourses: ClassCourse[];
  subjects: SubjectCreation[] = [];
  classSubjects: SubjectCreation[] = [];
  subjectFilter: SubjectCreation[] = [];
  assignments: Assignment[] = [];
  assignmentData: Assignment = {} as Assignment;
  filteredAssignments: Assignment[] = [];
  batches: Batch[] = [];
  searchBatches: Batch[] = [];
  batchIds: any[] = [];
  resBatchIds: any[] = [];
  batchId = '0';
  slectedBatchIds = '';
  batchNames: any[] = [];
  classId = '0';
  submitted = false;
  currentDate: string;
  viewDate: string;
  tempFile: File;
  
  isLoading :boolean =false;
  currentAcademicYearId: number;

  constructor(
    private assignmentService: AssignmentService,
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    public subjectService: SubjectcreationService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private academicyearService:SettingsService
  ) { }

  ListAssignments() {
    this.isLoading =!this.isLoading;
    this.assignmentService.getAll().subscribe((data: Assignment[]) => {
      console.log(data);
      this.filteredAssignments = this.assignments = data;
      this.isLoading =!this.isLoading;
    });
  }
  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      console.log(data.id);
      this.currentAcademicYearId = data.id;
      this.assignForm.get('academicYearId').patchValue(this.currentAcademicYearId.toString());
    });
  }
  ListSubjects() {
    this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
      this.classSubjects  = this.subjectFilter = this.subjects = data;
    });
  }
  ListClass() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      console.log(data);
      this.classcourses = data;
    });
  }

  ListBatch() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      console.log(data);
      this.searchBatches = data;
    });
  }

  onClassChange(value: string) {
    if (value === '-1') { return; }
    if (value === '0') {
      this.filteredAssignments = this.assignments; // = data;
    } else {
      this.filteredAssignments = this.assignments.filter((assignment: Assignment) =>
      assignment.classId.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      this.batchService.getAll().subscribe((data: Batch[]) => {
        this.searchBatches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
      });
    }
  }

  onBatchChange(value: string) {
    if (value === '-1') { return; }
    if (value === '0') {
      this.filteredAssignments = this.assignments; // = data;
    } else {
      this.filteredAssignments = this.assignments.filter((assignment: Assignment) =>{
      //assignment.batchIds.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      const splitArr = assignment.batchIds==null?"": assignment.batchIds.split('|');
      return splitArr.includes(value);
      });
    }
  }

  onClassSelected(value: string) {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
    });
  }

  getExistingBatches(value: string) {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
      this.batches.forEach(x => {
        for (let i = 0; i < this.resBatchIds.length; i++) {
          if (x.id.toString() === this.resBatchIds[i]) {
            console.log(x.id);
            x.checked = true;
          }
        }
      });
    });
  }

  onFileChange(event) {
    // const usersArray = this.admissionForm.controls.documentList as FormArray;
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
        const file = target.files[0];
        let result = '';
        const reader = new FileReader();
        reader.onload = (e => {
          result = reader.result.toString();
          this.assignForm.get('contentBase').patchValue(result);
          this.assignForm.get('uploadFileName').patchValue(file.name);
          // this.admissionForm.get('studentImage').patchValue(this.imageSrc);
        });

        reader.readAsDataURL(file);
    }
  }

  // onFileChange(event) {
  //   // Any file(s) selected from the input?
  //   if (event.target.files && event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     this.assignForm.get('file').patchValue(file);
  //     // this.files.push(file);
  //   }
  // }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.filteredAssignments = this.assignments;
    } else {
    this.filteredAssignments = this.assignments
                                .filter((assignment: Assignment) => assignment.assignmentTitle.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()) || assignment.className.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()) || assignment.assignmentTitle.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
    if (this.filteredAssignments.length==0) {
      this.filteredAssignments = this.assignments
                                .filter((assignment: Assignment) => assignment.subjectName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()) || assignment.className.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()) || assignment.assignmentTitle.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
    }
    }
  }

  validateDate(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {

      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      console.log(Date.parse(control.value));
      console.log(Date.parse(matchingControl.value));
      // set error on matchingControl if validation fails
      if (Date.parse(control.value) > Date.parse(matchingControl.value)) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
    };
}

  setCurrentDate() {
    const x = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.currentDate = days[x.getDay()] + ' ' + this.formatDate(x);
  }

  updateCurrentDate(date) {
    const x = new Date(date);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.currentDate = days[x.getDay()] + ' ' + this.formatDate(x);
  }

  viewCurrentDate(date) {
    const x = new Date(date);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.viewDate = days[x.getDay()] + ' ' + this.formatDate(x);
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  get totalRows(): number {
    return this.filteredAssignments.length;
  }
  get f() { return this.assignForm.controls; }

  resetForm() {
    this.submitted = false;
    this.batches = [];
    this.assignForm = this.fb.group({
      id: [0],
      classId: ['', Validators.required],
      batchIds: [''],
      subjectId: ['', Validators.required],
      assignmentDate: [this.formatDate(new Date()), Validators.required],
      submissionDate: ['', Validators.required],
      assignmentType: [''],
      assignmentTitle: ['', Validators.required],
      assignmentText: [''],
      contentBase: [''],
      uploadFileName: [''],
      status: ['true', Validators.required],
      academicYearId:[this.currentAcademicYearId]
   }, {
      validator: this.validateDate('assignmentDate', 'submissionDate')
   });
    $('.summernote').summernote('code', '');
  }

  checkAllCheckBox(ev) {
    this.batches.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.batches.every(p => p.checked);
  }

  // downloadDocFile(id, data) {
  //   this.assignmentService.downloadFile(id, data);
  // }

  public base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    b64Data = b64Data.split(',')[1];
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  onDownloadDocFile(response: string, filename: string) {
    // this.classworkService.downloadDocFile(id).subscribe((response) => {
        // console.log(response);
        // let file = new Blob([response], { type: 'application/pdf' });
        const mainData = response;
        const base64ContentArray = response;
        const extensn = base64ContentArray.split(';')[0].split('/')[1];
        const base64Data = response.split(',');
        const mimeType = base64Data[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];

        const blob = this.base64ToBlob(mainData, mimeType);
        saveAs(blob, filename);
    // });
  }


  uploadFile(files) {
    // const leavefile = (event.target as HTMLInputElement).files[0];
    // if (event.target.files && event.target.files.length > 0) {
    //   const sfile = event.target.files[0];
    //   // this.assignForm.get('file').patchValue(file);
    //   // this.files.push(file);
    //   this.assignForm.patchValue({
    //     file: sfile
    //   });
    //   this.assignForm.get('file').updateValueAndValidity();
    // }
    // if (files.length === 0) {
    //   return;
    // }

    // for (let file of files) {
    //   this.tempFile = file;
    // }
  }

  submitForm() {
    this.submitted = true;
    this.slectedBatchIds = '';
    let k = 1;
    const totalSelected = this.batches.filter(x => x.checked).length;
    this.batches.forEach((x, i) => {
      if (x.checked) {
        if (totalSelected !== k) {
          this.slectedBatchIds += x.id.toString() + '|';
        } else {
          this.slectedBatchIds += x.id.toString();
        }
        k++;
      }
    });
    this.assignForm.get('batchIds').patchValue(this.slectedBatchIds);
    this.assignForm.get('academicYearId').patchValue(this.currentAcademicYearId.toString());
    //this.assignForm.get('assignmentText').patchValue($('.summernote').summernote('code'));
    if (this.slectedBatchIds === '') {
      this.toastr.warning('Please select batch', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }
        // stop here if form is invalid
    if (this.assignForm.invalid) {
      if (this.f.submissionDate.errors != null) {
        this.toastr.warning('Submission Date should not less then Assignment date', 'WARNING!', {
          timeOut: 3000
        });
      }
      return;
    }
debugger;
    console.log(this.assignForm.get('assignmentDate').value);

    const formData: any = new FormData();
    formData.append('id', this.assignForm.get('id').value);
    formData.append('classId', this.assignForm.get('classId').value);
    formData.append('batchIds', this.assignForm.get('batchIds').value);
    formData.append('subjectId', this.assignForm.get('subjectId').value);
    formData.append('assignmentDate', this.formatDate(this.assignForm.get('assignmentDate').value));
    formData.append('submissionDate', this.formatDate(this.assignForm.get('submissionDate').value));
    formData.append('assignmentTitle', this.assignForm.get('assignmentTitle').value);
    formData.append('assignmentType', this.assignForm.get('assignmentType').value);
    formData.append('assignmentText', this.assignForm.get('assignmentText').value);
    formData.append('status', this.assignForm.get('status').value);
    formData.append('uploadFileName', this.assignForm.get('uploadFileName').value);
    formData.append('contentBase', this.assignForm.get('contentBase').value);
    formData.append('academicYearId', this.assignForm.get('academicYearId').value);
    // formData.append('uploadFile', this.assignForm.get('file').value);
    // formData.append('file', this.tempFile); // this.assignForm.get('file').value);
    console.log(formData);
    if (this.assignForm.get('id').value === 0) {
        console.log(this.assignForm.value);

        this.assignmentService.create(this.assignForm.value).subscribe(res => {
          console.log('subject created!');
          
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
          setTimeout(() => {
            this.ListAssignments();
          }, 2000);
        });
        } else {
          // this.assignmentService.update(this.assignForm.get('id').value, this.assignForm.value).subscribe(res => {
          this.assignmentService.update(this.assignForm.get('id').value, this.assignForm.value).subscribe(res => {
            console.log('subject updated!');
           
            this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submitted = false;
            this.resetForm();
            // this.casteForm.reset();
            setTimeout(() => {
              this.ListAssignments();
            }, 2000);
          });
        }
    this.ListAssignments();
}

      viewForm(id: number) {
        this.assignmentService.getDetails(id).subscribe((data: Assignment) => {
          console.log(data);
          this.viewCurrentDate(data.assignmentDate);
          this.batchNames = data.batchName.split('|');
          $('.summernotes').summernote({height: 300, toolbar: false});
          $('.summernotes').summernote('code', data.assignmentText);
          $('.summernotes').summernote('disable');
          this.assignmentData = data;
        });
      }

      updateForm(id: number) {
        this.assignmentService.getById(id).subscribe((data: Assignment) => {
          console.log(data);
          this.resBatchIds = data.batchIds.split('|');
          this.getExistingBatches(data.classId.toString());
          this.assignForm = this.fb.group({
            id: [data.id],
            classId: [data.classId.toString(), Validators.required],
            batchIds: [data.batchIds.toString(), Validators.required],
            subjectId: [data.subjectId.toString(), Validators.required],
            assignmentType: [data.assignmentType, Validators.required],
            assignmentDate: [this.formatDate(data.assignmentDate), Validators.required],
            submissionDate: [this.formatDate(data.submissionDate), Validators.required],
            assignmentTitle: [data.assignmentTitle, Validators.required],
            assignmentText: [data.assignmentText],
            contentBase: [data.contentBase],
            uploadFileName: [data.uploadFileName],
            status: [data.status.toString(), Validators.required],
            academicYearId:[data.academicYearId]
          });
          $('.summernote').summernote('code', data.assignmentText);
        });
      }
      deleteForm(id: number) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            this.assignmentService.delete(id).subscribe(res => {
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              );
              this.ListAssignments();
            });
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
          }
        });
      }

  ngOnInit(): void {
    this.currentAcademicYear();
    this.ListSubjects();
    this.ListClass();
    this.ListAssignments();
    this.assignForm = this.fb.group({
      id: [0],
      classId: ['', Validators.required],
      batchIds: [''],
      subjectId: ['', Validators.required],
      assignmentDate: [this.formatDate(new Date()), Validators.required],
      submissionDate: ['', Validators.required],
      assignmentType: [''],
      assignmentTitle: ['', Validators.required],
      assignmentText: [''],
      contentBase: [''],
      uploadFileName: [''],
      status: ['true', Validators.required],
      academicYearId:[this.currentAcademicYearId]
   }, {
      validator: this.validateDate('assignmentDate', 'submissionDate')
   });

    this.setCurrentDate();

    $('.summernote').summernote({height: 300});
    $('#assignmentDate').datepicker().on('changeDate', (e) => {
      this.assignForm.get('assignmentDate').patchValue(e.date);
    });
    $('#submitionDate').datepicker().on('changeDate', (e) => {
      this.assignForm.get('submissionDate').patchValue(e.date);
    });
  }

}
