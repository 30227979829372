import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudyplanRoutingModule} from './studyplan.routing.module';
import { AssignmentsComponent } from './assignments/assignments.component';
import { ClassworksComponent } from './classworks/classworks.component';
import { HomeworksComponent } from './homeworks/homeworks.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [AssignmentsComponent, ClassworksComponent, HomeworksComponent],
  imports: [
    CommonModule,
    StudyplanRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class StudyplanModule { }
