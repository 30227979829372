import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstitutionComponent } from './institution/institution.component';
import { InstitutionRoutingModule } from './institution-routing.module';


@NgModule({
  declarations: [InstitutionComponent],
  imports: [
    CommonModule,
    InstitutionRoutingModule
  ]
})
export class InstitutionModule { }
