export class ReportVm {
  liveSession: number;
  videoSession: number;
  textSession: number;
  audioSession: number;
    
  }

 
 

