import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private authService: AuthenticationService, private router: Router) { }

  canActivate() {
    if (this.authService.isLoggedIn()) {
      // debugger;
      this.router.navigate(['/login']);
    }
    return !this.authService.isLoggedIn();
  }
}
