import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Academicyear } from '../../../mastersettings/models/academicyear';
import { SettingsService } from '../../../mastersettings/settings.service';
@Component({
  selector: 'app-outstandingprint',
  templateUrl: './outstandingprint.component.html',
  styleUrls: ['./outstandingprint.component.css']
})
export class OutstandingprintComponent implements OnInit {

  constructor( private route: ActivatedRoute,private academicYearService: SettingsService ) { 
    this.GetCurrentAcademicYear();
  }
  lstoutstandingprint: any = []
  totalPaidAmount: any;
  dates:any;
  institution:any;
  logoUrl = '';
  currentAcademicYearId: number;
  academicYears: Academicyear[] = [];
  currentAcademicYearName = '';
  ngOnInit(): void {
    this.route.params.subscribe(params => {
     this.dates =params["details"];
    });
    var result = JSON.parse(localStorage.getItem('outstandingprint'));
    if (result.length > 0) {
      this.lstoutstandingprint = result;
      this.totalPaidAmount = this.lstoutstandingprint.map(item => parseFloat(item.totalDueAmount)).reduce((prev, curr) => prev + curr, 0);
    }
  }
  
// this.institution

//   this.institution = JSON.parse(localStorage.getItem('Institution'));
//   if(this.institution.logoUrl !== null ) {
//     this.institution.logoUrl = environment.imageUrl + this.institution.logoUrl;
//   } else if (this.institution.logoUrl === null) {
//     this.institution.logoUrl = 'assets/images/SchoolLogo/demologo.png';
//   }
//   this.logoUrl = this.institution.logoUrl;
//   this.institutionName = this.institution.institutionName;
//   this.address = this.institution.address;
// }

  GetCurrentAcademicYear(){
    this.academicYearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
      this.currentAcademicYear();
    });
  }
  
  currentAcademicYear() {
    this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.currentAcademicYearName = this.academicYears.find(x => x.id.toString() === data.id.toString()).name;
    });
  }
}