import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { SalaryProcess, SalaryProcessEmployeeLeaveDetails } from './models/salaryprocess';
import { LoanEMIDetails } from './models/bonusanddeduction';
//import { url } from 'inspector';

@Injectable({
  providedIn: 'root'
})
export class SalaryprocessService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  http: any;
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<SalaryProcess[]> {
    return this.httpClient.get<SalaryProcess[]>(environment.apiUrl + '/salaryprocess/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getFilterData(id): Observable<SalaryProcess[]> {
    //const url=environment.apiUrl + '/salaryprocessfilter/'+id;
    const url=environment.apiUrl + '/salaryprocessfilter/'+id+'/0/0/2023-08-05/2023-08-05/';
    return this.httpClient.get<SalaryProcess[]>(url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getFullFilterData(department, status, fromdate, todate): Observable<SalaryProcess[]> {
    //const url=environment.apiUrl + '/salaryprocessfilter/'+id;
    const url=environment.apiUrl + '/salaryprocessfilter/-1/'+department+'/'+status+'/'+fromdate+'/'
    +todate;
    return this.httpClient.get<SalaryProcess[]>(url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getsalaryprocessemployeedetails(EmpId): Observable<SalaryProcess[]> {
    return this.httpClient.get<SalaryProcess[]>(environment.apiUrl + '/salaryprocessemployeedetails/?EmpId=' + EmpId)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getById(EmpId): Observable<SalaryProcess[]> {
    return this.httpClient.get<SalaryProcess[]>(environment.apiUrl + '/salaryprocess/' + EmpId)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getLoanEMIDetailById(LoanAndAdvanceId): Observable<LoanEMIDetails[]> {
    return this.httpClient.get<LoanEMIDetails[]>(environment.apiUrl + '/loanEMIDetail/' + LoanAndAdvanceId)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  private formatDate(date) {  
      let dd = date.substr(0,2);
      let mm = date.substr(3,2);
      let yyyy = date.substr(6,4);  
      return ( mm + '-' + dd  +'-' + yyyy);  
    
  }
  create(academicyear,earningFilteredList,deductionFilteredList): Observable<SalaryProcess> {
    console.log(' before academicyear',  academicyear['paidDate']);
    academicyear['paidDate']=this.formatDate(academicyear['paidDate']);
    console.log(' after academicyear',  academicyear['paidDate']);
    academicyear.earningDetails=earningFilteredList;
    academicyear.deductionDetails=deductionFilteredList;

    // earningFilteredList.forEach(function (element) {
    // academicyear.earninganddeductionDetails.push({id:element.id,expression:element.expression,amount:element.amount});
    // });
    // deductionFilteredList.forEach(function (element) {
    // academicyear.earninganddeductionDetails.push({id:element.id,expression:element.expression,amount:element.amount});
    // });

    if(academicyear.status=='0')
    {
      academicyear.active=false;
    }

    return this.httpClient.post<SalaryProcess>(environment.apiUrl + '/insertsalaryprocessEmp/', JSON.stringify(academicyear), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  cancelPayslip(academicyear): Observable<SalaryProcess> {
    return this.httpClient.post<SalaryProcess>(environment.apiUrl + '/cancelsalaryprocess/', academicyear, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  CancelSalaryProcess(id, cancellReason): Observable<SalaryProcess> {
    // tslint:disable-next-line:max-line-length

    return this.httpClient.put<SalaryProcess>(environment.apiUrl + '/cancelsalaryprocess/' + id+'/'+cancellReason,this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  
  getemployeeLeavedetails(EmpId,paymonthId): Observable<SalaryProcessEmployeeLeaveDetails[]> {

    return this.httpClient.get<SalaryProcessEmployeeLeaveDetails[]>(environment.apiUrl + '/employeeleavedetails/?EmpId='+EmpId+'&paymonthId='+paymonthId)
    .pipe(
      catchError(this.errorHandler)
    );
  }

 

  update(id, academicyear): Observable<SalaryProcess> {
    // tslint:disable-next-line:max-line-length
    academicyear['paidDate']= this.formatDate(academicyear['paidDate']);
    if(academicyear.status=='0')
    {
      academicyear.active=false;
    }
    return this.httpClient.put<SalaryProcess>(environment.apiUrl + '/salaryprocesses/' + id, JSON.stringify(academicyear), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  sendWithAttachment(userData) {

    this.http.post("http://localhost:3000/uploadfile", userData).subscribe(data => {
          console.log("Sent Request is successful ", data);
        },
        error => {
          console.log("Error", error);
        }
      );
  }
  delete(id,academicyear) {
    return this.httpClient.put<SalaryProcess>(environment.apiUrl + '/salaryprocesses/' + id, JSON.stringify(academicyear), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  deleteSalaryProcess(id): Observable<SalaryProcess> {
   
    
    return this.httpClient.put<SalaryProcess>(environment.apiUrl + '/CancelSalaryProcess/' + id, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}