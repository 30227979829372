// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-centered td, .table-centered th {
    vertical-align: top !important;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/academic/offline-examination/consolidate-exam-report/consolidate-exam-report.component.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;AAClC","sourcesContent":[".table-centered td, .table-centered th {\r\n    vertical-align: top !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
