import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginPageConfig } from './loginpageconfig.model';
import { LoginPageConfigService } from './loginpageconfig.service';

@Component({
  selector: 'app-loginpageconfig',
  templateUrl: './loginpageconfig.component.html',
  styleUrls: ['./loginpageconfig.component.css']
})
export class LoginpageconfigComponent implements OnInit {

   loginpageconfigForm: UntypedFormGroup;
   loginpageconfigs: LoginPageConfig[] = [];
   loginPageConfigFilteredList: LoginPageConfig[] = [];
   imageSrc: string;
   submitted = false;

  constructor(
    public loginPageConfigService: LoginPageConfigService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  ListLoginPages() {
    this.loginPageConfigService.getAll().subscribe((data: LoginPageConfig[]) => {
      console.log(data);
      this.loginPageConfigFilteredList = this.loginpageconfigs = data;
    });
  }

  readURL(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const file = target.files[0];

      const reader = new FileReader();
      reader.onload = (e => {
        this.imageSrc = reader.result.toString();
        this.loginpageconfigForm.get('image').patchValue(this.imageSrc);
      });

      reader.readAsDataURL(file);
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode !== '0') {
            event.preventDefault();
    }
  }

get totalRows(): number {
  return this.loginPageConfigFilteredList.length;
}

get f() { return this.loginpageconfigForm.controls; }
resetForm() {
  this.submitted = false;
  this.loginpageconfigForm = this.fb.group({
    id: [0],
    title: ['', Validators.required],
    order: ['', Validators.required],
    image: ['', Validators.required],
    effect: ['', Validators.required],
    duration : ['', Validators.required],
    status: ['true', Validators.required],
    edumaqUrl: [''],
    whatsappUrl: [''],
    facebookUrl: [''],
    linkedinUrl: [''],
    playstoreUrl: [''],
    appstoreUrl: [''],
    youtubeUrl: ['']
  });
  this.imageSrc = '';
}

submitForm() {
  this.submitted = true;
   // stop here if form is invalid
  if (this.loginpageconfigForm.invalid) {
          return;
    }

  if (this.loginpageconfigForm.get('id').value === 0) {
      console.log(this.loginpageconfigForm.value);
      this.loginPageConfigService.create(this.loginpageconfigForm.value).subscribe(res => {
        console.log('Addon service created!');
        this.ListLoginPages();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.loginPageConfigService.update(this.loginpageconfigForm.get('id').value, this.loginpageconfigForm.value).subscribe(res => {
          console.log('Login page updated!');
          this.ListLoginPages();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
          this.ListLoginPages();
         });
      }
}
updateForm(id: number) {
 this.loginPageConfigService.getById(id).subscribe((data: LoginPageConfig) => {
    console.log(data);
    this.loginpageconfigForm = this.fb.group({
      id: [data.id],
      title: [data.title, Validators.required],
      order: [data.order, Validators.required],
      image: [data.image, Validators.required],
      effect: [data.effect, Validators.required],
      duration : [data.duration, Validators.required],
      status: [data.status, Validators.required],
      edumaqUrl: [data.edumaqUrl],
      whatsappUrl: [data.whatsappUrl],
      facebookUrl: [data.facebookUrl],
      linkedinUrl: [data.linkedinUrl],
      playstoreUrl: [data.playstoreUrl],
      appstoreUrl: [data.appstoreUrl],
      youtubeUrl: [data.youtubeUrl]
    });
    this.imageSrc = data.image;
  });

}

deleteForm(id: number) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You won\'t be able to revert this!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.loginPageConfigService.delete(id).subscribe(res => {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
        this.ListLoginPages();
      });
    // For more information about handling dismissals please visit
    // https://sweetalert2.github.io/#handling-dismissals
    } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire(
      'Cancelled',
      'Your imaginary file is safe :)',
      'error'
    );
    }
  });
}


  ngOnInit(): void {
    this.ListLoginPages();
    this.loginpageconfigForm = this.fb.group({
      id: [0],
      title: ['', Validators.required],
      order: ['', Validators.required],
      image: ['', Validators.required],
      effect: ['', Validators.required],
      duration : ['', Validators.required],
      status: ['true', Validators.required],
      edumaqUrl: [''],
      whatsappUrl: [''],
      facebookUrl: [''],
      linkedinUrl: [''],
      playstoreUrl: [''],
      appstoreUrl: [''],
      youtubeUrl: ['']
    });
  }

}
