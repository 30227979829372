import { Component, OnInit, AfterViewInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { StudentService} from './../student.service';
import { Student, StudentSibling, StudentDocument } from './../student.model';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { Nationality } from '../../mastersettings/models/nationality';
import { Religion } from '../../mastersettings/models/religion';
import { Category } from '../../mastersettings/models/category';
import { Caste } from '../../mastersettings/models/caste';
import { Country } from '../../mastersettings/models/country';
import { State } from '../../mastersettings/models/state';
import { City } from '../../mastersettings/models/city';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { SettingsService } from '../../mastersettings/settings.service';
import { NationalityService } from '../../mastersettings/nationality/nationality.service';
import { ReligionService } from '../../mastersettings/religion/religion.service';
import { CategoryService } from '../../mastersettings/category/category.service';
import { CasteService } from '../../mastersettings/caste/caste.service';
import { CountryService } from '../../mastersettings/country/country.service';
import { StateService } from '../../mastersettings/state/state.service';
import { CityService } from '../../mastersettings/city/city.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Stream } from '../../mastersettings/models/stream';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { StreamService } from '../../mastersettings/stream/stream.service';
import {ApplicantRegistrationService} from '../../applicantregistration/applicantregistration.service';
import { ApplicantRegistration } from '../../applicantregistration/applicantregistration.model';
import { House } from '../house.mode';
import { environment } from '../../../../../environments/environment';
//import { stderr } from 'process';
//import { debug } from 'console';
declare var $: any;

@Component({
  selector: 'app-admission',
  templateUrl: './admission.component.html',
  styleUrls: ['./admission.component.css']
})
export class AdmissionComponent implements OnInit {

  submitted = false;
  loading=false;
  admissionForm: UntypedFormGroup;
  admissions: Student[] = [];
  academicYears: Academicyear[] = [];
  classcourses: ClassCourse[] = [];
  nationalities: Nationality[] = [];
  religions: Religion[] = [];
  categories: Category[] = [];
  castes: Caste[] = [];
  countries: Country[] = [];
  states: State[] = [];
  cities: City[] = [];
  streams: Stream[] = [];
  houses: House[] = [];
  batches: Batch[] = [];
  siblings: StudentSibling[] = [];
  siblingsString: string='';
  filteredSiblings: StudentSibling[] = [];
  filteredDocuments: StudentDocument[] = [];
  files: any[] = [];
  documnets: StudentDocument[] = [];
  male: boolean;
  female: boolean;
  other: boolean;

  transport: boolean;
  hostel: boolean;
  library: boolean;
  messcantene: boolean;
  registrationid: number;
  imageSrc: string;
  admissionNo: string;
  admissionid: number;
  currentAcademicYearId: number;
  editMode = false;

  siblingAdmissionNo: string;
  siblingStudentName: string;
  siblingFatherName: string;
  academicId: any;
  availableSeats:string = '0';
  classId: string;

  constructor(
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public academicyearService: SettingsService,
    public nationalityService: NationalityService,
    public religionService: ReligionService,
    public categoryService: CategoryService,
    public casteService: CasteService,
    public countryService: CountryService,
    public stateService: StateService,
    public settingsService: SettingsService,
    public cityService: CityService,
    public batchService: BatchService,
    public streamService: StreamService,
    public registrationService: ApplicantRegistrationService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ListAcademicYear() {
    this.settingsService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  ListNationalities() {
    this.nationalityService.getAll().subscribe((data: Nationality[]) => {
      this.nationalities = data;
    });
  }

  ListReligions() {
    this.religionService.getAll().subscribe((data: Religion[]) => {
      this.religions = data;
    });
  }

  ListCategories() {
    this.categoryService.getAll().subscribe((data: Category[]) => {
      this.categories = data;
    });
  }

  ListCastes() {
    this.casteService.getAll().subscribe((data: Caste[]) => {
      this.castes = data;
    });
  }

  ListCountry() {
    this.countryService.getAll().subscribe((data: Country[]) => {
      console.log(data);
      this.countries = data;
    });
  }

  ListState(countryId: any) {
    this.stateService.getAll().subscribe((data: State[]) => {
      this.states = data;
    });
  }

  ListCity() {
    this.cityService.getAll().subscribe((data: City[]) => {
      console.log(data);
      this.cities = data;
    });
  }

  ListStream() {
    this.streamService.getAll().subscribe((data: Stream[]) => {
      console.log(data);
      this.streams = data;
    });
  }

  ListHouse() {
    this.studentService.getHouse().subscribe((data: House[]) => {
      console.log(data);
      this.houses = data;
    });
  }

  onHouseChange(event) {
    
    const index = event.target['selectedIndex'] - 1;
    const houseName = this.houses[index].houseName;
    const houseId = this.houses[index].id;
    this.admissionForm.get('houseName').patchValue(houseName);
    this.admissionForm.get('houseId').patchValue(houseId);
  }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      console.log(data.id);
      this.currentAcademicYearId = data.id;
      this.admissionForm.get('academicYearId').patchValue(this.currentAcademicYearId.toString());
    });
  }

  readURL(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
        const file = target.files[0];

        const reader = new FileReader();
        reader.onload = (e => {
          this.imageSrc = reader.result.toString();
          this.admissionForm.get('studentImage').patchValue(this.imageSrc);
        });

        reader.readAsDataURL(file);
    }
}

readFatherImageURL(event: Event): void {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files[0]) {
      const file = target.files[0];

      const reader = new FileReader();
      reader.onload = (e => {
        // this.imageSrc = reader.result.toString();
        this.admissionForm.get('fatherPhotograph').patchValue(reader.result.toString());
      });

      reader.readAsDataURL(file);
  }
}

readMotherImageURL(event: Event): void {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files[0]) {
      const file = target.files[0];

      const reader = new FileReader();
      reader.onload = (e => {
        // this.imageSrc = reader.result.toString();
        this.admissionForm.get('motherPhotograph').patchValue(reader.result.toString());
      });

      reader.readAsDataURL(file);
  }
}

  onChange(value: string, event: any) {
    if (value != null) {
      this.admissionForm.get('gender').patchValue(value);
    }
    if (value === '1' ) {
      this.male = true;
      this.female = false;
      this.other = false;
      if ( this.admissionForm.get('GenderFemale') != null) {
        this.admissionForm.get('GenderFemale').patchValue('');
      }
      if ( this.admissionForm.get('GenderOther') != null) {
        this.admissionForm.get('GenderOther').patchValue('');
      }
    } else if (value === '2' ) {
      this.female = true;
      this.male = false;
      this.other = false;
      if ( this.admissionForm.get('GenderMale') != null) {
        this.admissionForm.get('GenderMale').patchValue('');
      }
      if ( this.admissionForm.get('GenderOther') != null) {
        this.admissionForm.get('GenderOther').patchValue('');
      }
    } else if (value === '3' ) {
      this.other = true;
      this.female = false;
      this.male = false;
      if ( this.admissionForm.get('GenderMale') != null) {
        this.admissionForm.get('GenderMale').patchValue('');
      }
      if ( this.admissionForm.get('GenderFemale') != null) {
        this.admissionForm.get('GenderFemale').patchValue('');
      }
    }
  }

  onCopyChange(event: any) {
      if (event.target.checked) {
        this.admissionForm.get('permenantStateId').patchValue(this.admissionForm.get('localStateId').value);
        this.admissionForm.get('permenantCityId').patchValue(this.admissionForm.get('localCityId').value);
        this.admissionForm.get('permenantPINCode').patchValue(this.admissionForm.get('localPINCode').value);
        this.admissionForm.get('permenantContactNo').patchValue(this.admissionForm.get('localContactNo').value);
        this.admissionForm.get('permenantFullAddress').patchValue(this.admissionForm.get('localFullAddress').value);
      } else {
        this.admissionForm.get('permenantStateId').patchValue(0);
        this.admissionForm.get('permenantCityId').patchValue(0);
        this.admissionForm.get('permenantPINCode').patchValue('');
        this.admissionForm.get('permenantContactNo').patchValue('');
        this.admissionForm.get('permenantFullAddress').patchValue('');
      }
  }

  onClassSelected(value: string) {
    this.classId=value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString().toLocaleLowerCase() === value.toLocaleLowerCase());
    });
  }
  onBatchSelected(value:string){
    this.studentService.getStudentByAcademicYearId(this.currentAcademicYearId,this.classId,value,1).subscribe((data: any[])=>{
      this.availableSeats =(parseInt((this.batches.filter((batch: Batch) => batch.id.toString().toLocaleLowerCase() === value.toLocaleLowerCase()))[0].seatingCapacity)-data.length).toFixed();
    });
    
  }
  onCountrySelected(value: string) {
    this.stateService.getAll().subscribe((data: State[]) => {
      this.states = data.filter((state: State) => state.countryId.toString() == value.toLocaleLowerCase());
    });
  }

  onStateSelected(value: string) {
    console.log(value);
    this.cityService.getAll().subscribe((data: City[]) => {
      this.cities = data.filter((city: City) => city.stateId.toString() == value.toLocaleLowerCase());
    });
  }

  addToTable(value: string) {
    debugger;

    const student: any = this.filteredSiblings.filter((stud: StudentSibling) =>
    stud.admissionNo.toLowerCase().includes(value.toLowerCase()));
    const studentInSibling: any = this.siblings.filter((stud: StudentSibling) =>
    stud.admissionNo.toLowerCase().includes(value.toLowerCase()));
    if(studentInSibling.length!=0){
      this.toastr.warning('Sibling Already Exist !!!!', 'WAERNING!', {
        timeOut: 3000
      });
      return;
    }
    this.siblings.push(student[0]);
    this.siblings[this.siblings.length - 1].id = 0;
    this.siblings[this.siblings.length - 1].admissionId =student[0].admissionId;
    this.filteredSiblings = [];
    this.siblingAdmissionNo = '';
    this.siblingFatherName = '';
    this.siblingStudentName = '';
    //this.siblingsString+=student[0].admissionId+',';
    console.log("sibling : "+this.siblings)
  }

  removeFromTable(value: number) {
    console.log(value);
    this.siblings.splice(value, 1);
  }

  genRand() {
    const passwordnum = Math.floor(Math.random() * 89999 + 10000);
  }

  search() {
    this.filteredSiblings = [];
    let students: any;
    this.studentService.getAll().subscribe((data: Student[]) => {
      if ((this.siblingAdmissionNo === undefined || this.siblingAdmissionNo === '')
          && (this.siblingFatherName  === undefined || this.siblingFatherName === '')) {
        students =  data.filter((student: Student) => {
          return student.studentName.toString().toLowerCase().includes(this.siblingStudentName.toLowerCase());
        });
      } else if ((this.siblingStudentName === undefined || this.siblingStudentName === '')
        && (this.siblingFatherName  === undefined || this.siblingFatherName === '')) {
        students =  data.filter((student: Student) => {
          return student.admissionNo.toString().toLowerCase().includes(this.siblingAdmissionNo.toLowerCase());
        });
      } else if ((this.siblingAdmissionNo === undefined || this.siblingAdmissionNo === '')
        && (this.siblingStudentName  === undefined || this.siblingStudentName === '')) {
        students =  data.filter((student: Student) => {
          return student.fatherName.toString().toLowerCase().includes(this.siblingFatherName.toLowerCase());
        });
      } else if ((this.siblingAdmissionNo === undefined || this.siblingAdmissionNo === '')) {
          students =  data.filter((student: Student) => {
          return student.studentName.toString().toLowerCase().includes(this.siblingStudentName.toLowerCase()) &&
                 student.fatherName.toString().toLowerCase().includes(this.siblingFatherName.toLowerCase());
        });
      } else if ((this.siblingStudentName === undefined || this.siblingStudentName === '')) {
        students =  data.filter((student: Student) => {
          return student.admissionNo.toString().toLowerCase().includes(this.siblingAdmissionNo.toLowerCase()) &&
                 student.fatherName.toString().toLowerCase().includes(this.siblingFatherName.toLowerCase());
        });
      } else if ((this.siblingFatherName === undefined || this.siblingFatherName === '')) {
        students =  data.filter((student: Student) => {
          return student.studentName.toString().toLowerCase().includes(this.siblingStudentName.toLowerCase()) &&
                 student.admissionNo.toString().toLowerCase().includes(this.siblingAdmissionNo.toLowerCase());
        });
      } else {
        students =  data.filter((student: Student) => {
          return student.studentName.toString().toLowerCase().includes(this.siblingStudentName.toLowerCase()) &&
                 student.admissionNo.toString().toLowerCase().includes(this.siblingAdmissionNo.toLowerCase()) &&
                 student.fatherName.toString().toLowerCase().includes(this.siblingFatherName.toLowerCase());
        });
      }

      console.log(students);
     // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < students.length; i++) {
          const sibling: StudentSibling = new StudentSibling();
          sibling.admissionId = students[i].id;
          sibling.admissionNo = students[i].admissionNo;
          sibling.batchName = students[i].batchName;
          sibling.className = students[i].className;
          sibling.studentName = students[i].studentName;
          sibling.fatherName = students[i].fatherName;
          sibling.rollNo = students[i].rollNo;
          this.filteredSiblings.push(sibling);
        }
        if(this.filteredSiblings.length==0){
          this.toastr.error('Records not found !!!!', 'ERROR!', {
            timeOut: 3000
          });
          return;
        }
    });
  }

  generateAdmissionNo() {
    if (this.admissionid === 0 || this.admissionid === undefined) {
      this.studentService.generateAdmissionNo().subscribe(res => {
        this.admissionNo = res[0];
        this.admissionForm.get('admissionNo').patchValue(res[0]);
        this.admissionForm.get('admissionNoCounter').patchValue(res[1]);
        this.admissionForm.get('loginId').patchValue(res[0].toString().replace('/', '-'));
        this.admissionForm.get('admissionDate').patchValue(this.formatDate(new Date()));
      });
    }
  }

  onFileChange(event, i) {
    const usersArray = this.admissionForm.controls.documentList as UntypedFormArray;
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
        const file = target.files[0];
        const fileType = target.files[0].name.split('.').pop();
        let result = '';
        const reader = new FileReader();
        reader.onload = (e => {
          result = reader.result.toString();
          console.log(result);
          usersArray.controls[i].get('contentBase').patchValue(result);
          usersArray.controls[i].get('contentType').patchValue(fileType);
          // this.admissionForm.get('studentImage').patchValue(this.imageSrc);
        });

        reader.readAsDataURL(file);
    }
  }
    // onFileChange(event, i) {
    //   const usersArray = this.admissionForm.controls.documentList as FormArray;
    //   console.log(usersArray);
    //   const leavefile = (event.target as HTMLInputElement).files[0];
    //   const bytes = [];

    //   if (leavefile) {
    //     const file = leavefile;
    //     const reader = new FileReader();
    //     reader.onload = (e => {
    //       const rFile = reader.result.toString();
    //       console.log(rFile.length);
    //       for (let j = 0; j < rFile.length; ++j) {
    //         bytes.push(rFile.charCodeAt(j)); // bytes.push(0);
    //       }
    //       console.log(bytes);
    //     });
    //     reader.readAsBinaryString(file);
    //   }
    //   usersArray.controls[i].get('contentFile').patchValue(bytes);
    // }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode !== '0') {
            event.preventDefault();
    }
  }

  patch() {
    debugger;
    if(this.siblingsString!=null){
      this.studentService.getAll().subscribe(data=>{

        let siblingIds=this.siblingsString.split(',');
        siblingIds.forEach(sId=>{
          if(sId!=null && sId!=',' && sId!=this.admissionForm.get('id').value){
            let filtredstudent=data.filter(x=>x.id==parseInt(sId));
            let studentSibling=new StudentSibling()
              studentSibling.admissionId=filtredstudent[0].id;
              studentSibling.admissionNo=filtredstudent[0].admissionNo;
              studentSibling.fatherName=filtredstudent[0].fatherName;
              studentSibling.rollNo=filtredstudent[0].rollNo;
              studentSibling.studentName=filtredstudent[0].studentName;
              studentSibling.className=filtredstudent[0].className;
              studentSibling.batchName=filtredstudent[0].batchName;

              this.siblings.push(studentSibling); 
              //this.filteredSiblings.push(studentSibling);
          }
        })
      });
    }

    /*const control = this.admissionForm.get('studentSiblings') as FormArray;
  
    if (this.siblings!= undefined){
      this.siblings.forEach(x => {
        control.push(this.patchValues(x));   
      });
      
    }*/
    /*if (this.siblings!= undefined){
      this.siblings.forEach(x => {
        control.push(this.patchValues(x));   
      });
      
    }*/
    //this.admissionForm.get('studentSiblings').patchValue(this.siblingsString);
  }

  patchValues(sibling: StudentSibling) {
    return this.fb.group({
      id: sibling.id.toString(),
      admissionNo: sibling.admissionNo,
      studentName: sibling.studentName,
      className: sibling.className,
      batchName: sibling.batchName,
      rollNo: sibling.rollNo,
      fatherName: sibling.fatherName
    });
  }

  addDocumentToTable(value: string) {
    // const student: any = this.filteredSiblings.filter((stud: Student) => stud.admissionNo.
    // toString().toLowerCase().includes(value.toLowerCase()));
    const sd: StudentDocument = new StudentDocument();
    sd.fileName = '';
    sd.contentType = '';

    this.filteredDocuments.push(sd);
  }

  removeFormControl(i) {
    const usersArray = this.admissionForm.get('documentList') as UntypedFormArray;
    usersArray.removeAt(i);
  }

  addFormControl() {
    const documentArray = this.admissionForm.get('documentList') as UntypedFormArray;
    const arraylen = documentArray.length;

    const newUsergroup: UntypedFormGroup = this.fb.group({
      id: [0],
      academicYearId: [0],
      fileName: [''],
      contentType: [''],
      contentBase: [''],
      contentFile: ['']
    });

    documentArray.insert(arraylen, newUsergroup);

    console.log(documentArray);
  }

  patchDocument() {
    const control = this.admissionForm.get('documentList') as UntypedFormArray;
    this.documnets.forEach(x => {
      control.push(this.patchDocumentValues(x));
    });
  }

  patchDocumentValues(document: StudentDocument) {
    return this.fb.group({
      id: document.id.toString(),
      fileName: document.fileName,
      contentType: document.contentType,
      contentFile: document.contentFile
    });
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  get f() { return this.admissionForm.controls; }

  updateForm(id: number,academicId:number) {
    debugger;
    this.editMode = true;
    this.studentService.getdetailsWithAcademicId(id,academicId).subscribe((data: Student) => {
      console.log(data);
      this.admissionForm = this.fb.group({
        id: [data.id],
        academicYearId: [data.academicYearId.toString(), Validators.required],
        admissionNo:  [data.admissionNo],
        registrationNo:  [data.registrationNo],
        admissionDate:  [this.formatDate(data.admissionDate), Validators.required],
        studentName:  [data.studentName, Validators.required],
        classCourseId:  [data.classCourseId.toString(), Validators.required],
        batchId:  [data.batchId.toString(), Validators.required],
        streamId:  [data.streamId.toString(), Validators.required],
        //dob:  [this.formatDate(data.dob), Validators.required],
        dob:  [this.formatDate(data.dob)],
        //age:  [data.age.toString(), Validators.required],
        age:  [data.age.toString()],
        gender:  [data.gender, Validators.required],
        studentImage:  [data.studentImage],
        imageUrl:  [data.imageUrl],
        nationalityId:  [data.nationalityId].toString(),
        religionId:  [data.religionId.toString()],
        categoryId:  [data.categoryId.toString()],
        casteId:  [data.casteId.toString()],
        motherTounge:  [data.motherTounge],
        bloodGroup:  [data.bloodGroup],
        smsMobNumber:  [data.smsMobNumber],
        whatsAppNo:  [data.whatsAppNo],
        emailId:  [data.emailId],
        adharNumber:  [data.adharNumber],
        houseId:  [0],
        houseName:  [data.houseName],
        disability:  [data.disabilities],
        lastSchool:  [data.lastSchool],
        yearOfPassing:  [data.yearOfPassing],
        previousClassId:  [data.classCourseId],
        tcNumber:  [data.tcNumber],
        accountHolder:  [data.accountHolder],
        accountNo:  [data.accountNo],
        ifscCode:  [data.ifscCode],
        spclInterest:  [data.spclInterest],
        medicalHis:  [data.medicalHis],
        allergicTo:  [data.allergicTo],
        medicineAny:  [data.medicineAny],
        localStateId:  [data.localStateId.toString()],
        localCityId:  [data.localCityId.toString()],
        localPINCode:  [data.localPINCode],
        localContactNo:  [data.localContactNo],
        localFullAddress:  [data.localFullAddress],
        copyAddressStatus:  [data.copyAddressStatus],
        permenantStateId:  [data.permenantStateId.toString()],
        permenantCityId:  [data.permenantCityId.toString()],
        permenantPINCode:  [data.permenantPINCode],
        permenantContactNo:  [data.permenantContactNo],
        permenantFullAddress:  [data.permenantFullAddress],
        fatherName:  [data.fatherName],
        fatherQualification:  [data.fatherQualification],
        fatherMobileNo:  [data.fatherMobileNo],
        fatherEmailId:  [data.fatherEmailId],
        fatherAdhaarNumber:  [data.fatherAdhaarNumber],
        fatherJobDetails:  [data.fatherJobDetails],
        fatherDesignation:  [data.fatherDesignation],
        fatherOrganization:  [data.fatherOrganization],
        fatherAnnualIncome:  [data.fatherAnnualIncome],
        fatherPhotograph:  [data.fatherPhotograph],
        fatherImageUrl:  [data.fatherImageUrl],
        motherFullName:  [data.motherFullName],
        motherQualification:  [data.motherQualification],
        motherMobileNumber:  [data.motherMobileNumber],
        motherEmailId:  [data.motherEmailId],
        motherAdhaarNumber:  [data.motherAdhaarNumber],
        motherJobDetails:  [data.motherJobDetails],
        motherDesignation:  [data.motherDesignation],
        motherOrganization:  [data.motherOrganization],
        motherAnnualIncome:  [data.motherAnnualIncome],
        motherPhotograph:  [data.motherPhotograph],
        motherImageUrl:  [data.motherImageUrl],
        loginStatus:  [data.loginStatus.toString()],
        loginId:  [data.loginId],
        password:  [data.password],
        biometricCode:  [data.biometricCode],
        transportService:  [data.transportService],
        hostelService:  [data.hostelService],
        libraryService:  [data.libraryService],
        messService:  [data.messService],
        documentList: this.fb.array([]),
        //studentSiblings: this.fb.array([])
        studentSiblings: [data.studentSiblings],
        studentType:[data.studentType, Validators.required],
        availableSeats:['0']
        });
      this.onChange(data.gender.toString(), 'Sample');
      this.imageSrc = data.studentImage;
      this.imageSrc = data.imageUrl != null ? environment.imageUrl + data.imageUrl : null;
      this.onClassSelected(data.classCourseId.toString());
     /* this.siblings = data.studentSiblings.split(',').forEach(x=>
        {
          let student:StudentSibling;
          student.admissionId=parseInt(x)

        });
        stude.forEach(element => {
          
        });*/
        debugger;
      this.siblingsString=data.studentSiblings;
      this.patch();
      this.documnets = data.documentlist;
      this.patchDocument();
    });
  }
  calculateDOB(e){
      debugger;
      const dateParts = this.formatDate(e.date).split('-');
      const month = dateParts[0];
      const day = dateParts[1];
      const year = dateParts[2];
      let ageValue = 0;
      const condition1: number = (parseInt(month, 0) + parseInt(day, 0));
  
      const todayDate = new Date();
      const todayYear = (todayDate.getFullYear()).toString();
      const todayDay = (todayDate.getDate()).toString();
      const todayMonth = (todayDate.getMonth() + 1).toString();
      const condition2: number = parseInt(todayMonth, 0) + parseInt(todayDay, 0);
  
      if (condition2 >= condition1) {
        ageValue = parseInt(todayYear, 0) - (parseInt(year, 0));
      } else {
        ageValue = parseInt(todayYear, 0) - ((parseInt(year, 0) + 1));
      }
      // 0-11 is Month in JavaScript
      this.admissionForm.get('dob').patchValue(this.formatDate(e.date));
      this.admissionForm.get('age').patchValue(ageValue.toFixed(0));

  }
  ngOnInit(): void {
    this.registrationid = this.activatedRoute.snapshot.params.id;
    this.admissionid = this.activatedRoute.snapshot.params.admissionid;
    this.academicId = this.activatedRoute.snapshot.params.academicId;
    this.ListClassCourse();
    this.ListAcademicYear();
    this.ListCountry();
    this.ListState(1);
    this.ListCity();
    this.ListReligions();
    this.ListNationalities();
    this.ListCategories();
    this.ListStream();
    this.ListCastes();
    this.ListHouse();
    this.currentAcademicYear();

    if (this.registrationid !== 0 && this.registrationid !== undefined) {
      this.updatefromRegistrationForm(this.registrationid);
    }

    this.admissionForm = this.fb.group({
      id: [0],
      academicYearId: [0, Validators.required],
      admissionNo:  [''],
      admissionNoCounter:  [''],
      registrationNo:  [''],
      admissionDate:  ['', Validators.required],
      studentName:  ['', Validators.required],
      classCourseId:  ['', Validators.required],
      batchId:  ['', Validators.required],
      streamId:  ['', Validators.required],
      //dob:  ['', Validators.required],
      dob:  [''],
      //age:  ['', Validators.required],
      age:  [''],
      gender:  ['', Validators.required],
      studentImage:  [''],
      imageUrl: [''],
      nationalityId:  ['0'],
      religionId:  ['0'],
      categoryId:  ['0'],
      casteId:  ['0'],
      motherTounge:  [''],
      bloodGroup:  [''],
      smsMobNumber:  [''],
      whatsAppNo:  [''],
      emailId:  [''],
      adharNumber:  [''],
      houseId:  [0],
      houseName:  [''],
      disability:  ['false'],
      lastSchool:  [''],
      yearOfPassing:  [''],
      previousClassId:  ['0'],
      tcNumber:  [''],
      accountHolder:  [''],
      accountNo:  [''],
      ifscCode:  [''],
      spclInterest:  [''],
      medicalHis:  [''],
      allergicTo:  [''],
      medicineAny:  [''],
      localStateId:  ['0'],
      localCityId:  ['0'],
      localPINCode:  [''],
      localContactNo:  [''],
      localFullAddress:  [''],
      copyAddressStatus:  ['false'],
      permenantStateId:  ['0'],
      permenantCityId:  ['0'],
      permenantPINCode:  [''],
      permenantContactNo:  [''],
      permenantFullAddress:  [''],
      fatherName:  [''],
      fatherQualification:  [''],
      fatherMobileNo:  [''],
      fatherEmailId:  [''],
      fatherAdhaarNumber:  [''],
      fatherJobDetails:  [''],
      fatherDesignation:  [''],
      fatherOrganization:  [''],
      fatherAnnualIncome:  [''],
      fatherPhotograph:  [''],
      fatherImageUrl: [''],
      motherFullName:  [''],
      motherQualification:  [''],
      motherMobileNumber:  [''],
      motherEmailId:  [''],
      motherAdhaarNumber:  [''],
      motherJobDetails:  [''],
      motherDesignation:  [''],
      motherOrganization:  [''],
      motherAnnualIncome:  [''],
      motherPhotograph:  [''],
      motherImageUrl: [''],
      loginStatus:  ['true'],
      loginId:  [''],
      password:  [Math.floor(Math.random() * 89999 + 10000).toString()],
      biometricCode:  [''],
      transportService:  [false],
      hostelService:  [false],
      libraryService:  [false],
      messService:  [false],
      //studentSiblings: this.fb.array([]),
      studentSiblings:[''],
      studentType:['Day Scholar', Validators.required],
      //availableSeats['0'],
      documentList: this.fb.array([
        this.fb.group({
          id: [0],
          academicYearId: [0],
          fileName: [''],
          contentType: [''],
          contentBase: [''],
          contentFile: ['']
        })
      ]),
      GenderMale: [''],
      GenderFemale: [''],
      GenderOther: ['']
    });

    // this.patchDocument();
    // this.addFormControl();
    // this.addDocumentToTable('');

    //this.patch();
    this.generateAdmissionNo();

    $('#dob').datepicker().on('change', (e) => {
      console.log('jijjjjjj');
      const dateParts = this.formatDate(e.date).split('-');
      const month = dateParts[0];
      const day = dateParts[1];
      const year = dateParts[2];
      let ageValue = 0;
      const condition1: number = (parseInt(month, 0) + parseInt(day, 0));
  
      const todayDate = new Date();
      const todayYear = (todayDate.getFullYear()).toString();
      const todayDay = (todayDate.getDate()).toString();
      const todayMonth = (todayDate.getMonth() + 1).toString();
      const condition2: number = parseInt(todayMonth, 0) + parseInt(todayDay, 0);
  
      if (condition2 >= condition1) {
        ageValue = parseInt(todayYear, 0) - (parseInt(year, 0));
      } else {
        ageValue = parseInt(todayYear, 0) - ((parseInt(year, 0) + 1));
      }
      // 0-11 is Month in JavaScript
      this.admissionForm.get('dob').patchValue(this.formatDate(e.date));
      this.admissionForm.get('age').patchValue(ageValue.toFixed(0));
    });

    $('#admissionDate').datepicker().on('change', (e) => {
      debugger;
      this.admissionForm.get('admissionDate').patchValue(this.formatDate(e.date));
    });
    $('#admissionDate').datepicker().on('show', (e) => {
      if (this.admissionForm.get('admissionDate').value !== '') {
        $('#admissionDate').datepicker('setDate', this.admissionForm.get('admissionDate').value);
      } else {
        $('#admissionDate').datepicker('setDate', this.formatDate(new Date()));
      }
    });

    if (this.admissionid !== 0 && this.admissionid !== undefined) {
      debugger;
      this.updateForm(this.admissionid,this.academicId);
    }
  }

  ngAfterViewInit() {
    $('#admissionDate').datepicker().on('change', (e) => {
      debugger;
      this.admissionForm.get('admissionDate').patchValue(e.target.value);
    });
    $('#dob').datepicker().on('change', (e) => {
      debugger;
      const dateParts = e.target.value.split('-');
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];
      let ageValue = 0;
      const condition1: number = (parseInt(month, 0) + parseInt(day, 0));
  
      const todayDate = new Date();
      const todayYear = (todayDate.getFullYear()).toString();
      const todayDay = (todayDate.getDate()).toString();
      const todayMonth = (todayDate.getMonth() + 1).toString();
      const condition2: number = parseInt(todayMonth, 0) + parseInt(todayDay, 0);
  
      if (condition2 >= condition1) {
        ageValue = parseInt(todayYear, 0) - (parseInt(year, 0));
      } else {
        ageValue = parseInt(todayYear, 0) - ((parseInt(year, 0) + 1));
      }
      // 0-11 is Month in JavaScript
      this.admissionForm.get('dob').patchValue(e.target.value);
      this.admissionForm.get('age').patchValue(ageValue.toFixed(0));
    });
  }

  onAdmissionDateChange(event: any): void {
    debugger;
    const selectedDate = event.target.value;
    this.admissionForm.get('admissionDate').patchValue(this.formatDate(selectedDate));
  }

  updatefromRegistrationForm(id: number) {
    this.registrationService.getById(id).subscribe((data: ApplicantRegistration) => {
      console.log(data);
      // this.registerNo = data.registerNo;
      // this.tempDate = data.registrationDate;
      // this.updateCurrentDate(data.registrationDate);
      this.onClassSelected(data.classCourseId);
      this.admissionForm = this.fb.group({
        id: [0],
        academicYearId: [data.academicYearId, Validators.required],
        admissionNo:  [''],
        registrationNo:  [data.registerNo],
        admissionDate:  ['', Validators.required],
        studentName:  [data.studentName, Validators.required],
        classCourseId:  [data.classCourseId, Validators.required],
        batchId:  ['', Validators.required],
        streamId:  ['', Validators.required],
        //dob:  [this.formatDate(data.dob), Validators.required],
        dob:  [this.formatDate(data.dob)],
        //age:  [data.age, Validators.required],
        age:  [data.age],
        gender:  [data.gender.toString(), Validators.required],
        studentImage:  [''],
        nationalityId:  [data.nationalityId.toString()],
        religionId:  [data.religionId.toString()],
        categoryId:  [data.categoryId.toString()],
        casteId:  [data.casteId.toString()],
        motherTounge:  [''],
        bloodGroup:  [''],
        smsMobNumber:  [data.contactNumber, Validators.pattern('^[0-9]{10}$')],
        whatsAppNo:  [''],
        emailId:  [data.emailId],
        adharNumber:  [data.adharNumber],
        houseId:  [0],
        houseName:  [''],
        disability:  ['false'],
        lastSchool:  [data.previousSchoolName],
        yearOfPassing:  [data.yearOfPassing],
        previousClassId:  [data.classcourse.toString()],
        tcNumber:  [data.tcNumber],
        accountHolder:  [''],
        accountNo:  [''],
        ifscCode:  [''],
        spclInterest:  [''],
        medicalHis:  [''],
        allergicTo:  [''],
        medicineAny:  [''],
        localStateId:  [data.localStateId.toString()],
        localCityId:  [data.localCityId.toString()],
        localPINCode:  [data.localPINCode],
        localContactNo:  [''],
        localFullAddress:  [data.localFullAddress],
        copyAddressStatus:  ['false'],
        permenantStateId:  [data.permenantStateId.toString()],
        permenantCityId:  [data.permenantCityId.toString()],
        permenantPINCode:  [data.permenantPINCode],
        permenantContactNo:  [''],
        permenantFullAddress:  [data.permenantFullAddress],
        fatherName:  [data.fatherFullName],
        fatherQualification:  [data.fatherQualification],
        fatherMobileNo:  [data.fatherContactNumber],
        fatherEmailId:  [data.fatherEmailId],
        fatherAdhaarNumber:  [''],
        fatherJobDetails:  [''],
        fatherDesignation:  [''],
        fatherOrganization:  [''],
        fatherAnnualIncome:  [''],
        fatherPhotograph:  [''],
        motherFullName:  [data.motherFullName],
        motherQualification:  [data.motherQualification],
        motherMobileNumber:  [data.motherContactNumber],
        motherEmailId:  [data.motherEmailId],
        motherAdhaarNumber:  [''],
        motherJobDetails:  [''],
        motherDesignation:  [''],
        motherOrganization:  [''],
        motherAnnualIncome:  [''],
        motherPhotograph:  [''],
        loginStatus:  ['false'],
        loginId:  [''],
        password:  [''],
        biometricCode:  [''],
        status: ['false'],
        transportService:  [false],
        hostelService:  [false],
        libraryService:  [false],
        messService:  [false],
        studentSiblings: [''],
        studentType:['Day Scholar', Validators.required],
        availableSeats:['0'],
       // studentSiblings: this.fb.array([]),
        documentList: this.fb.array([
          this.fb.group({
            id: [0],
            academicYearId: [0],
            fileName: [''],
            contentType: [''],
            contentBase: [''],
            contentFile: ['']
          })
        ]),
        });
      this.male = data.gender.toString() === '1' ? true : false ;
      this.female = data.gender.toString() === '2' ? true : false;
      this.other = data.gender.toString() === '3' ? true : false ;
      this.generateAdmissionNo();
      // this.academicyears = data;
    });

  }

  resetForm() {
    this.submitted = false;
    this.loading=false;
    this.admissionForm = this.fb.group({
      id: [0],
      academicYearId: [this.currentAcademicYearId.toString(), Validators.required],
      admissionNo:  [''],
      admissionNoCounter:  [''],
      registrationNo:  [''],
      admissionDate:  ['', Validators.required],
      studentName:  ['', Validators.required],
      classCourseId:  ['', Validators.required],
      batchId:  ['', Validators.required],
      streamId:  ['', Validators.required],
      //dob:  ['', Validators.required],
      dob:  [''],
      //age:  ['', Validators.required],
      age:  [''],
      gender:  ['', Validators.required],
      studentImage:  [''],
      imageUrl:  [''],
      nationalityId:  ['0'],
      religionId:  ['0'],
      categoryId:  ['0'],
      casteId:  ['0'],
      motherTounge:  [''],
      bloodGroup:  [''],
      smsMobNumber:  [''],
      whatsAppNo:  [''],
      emailId:  [''],
      adharNumber:  [''],
      houseId:  [0],
      houseName:  [''],
      disability:  ['false'],
      lastSchool:  [''],
      yearOfPassing:  [''],
      previousClassId:  ['0'],
      tcNumber:  [''],
      accountHolder:  [''],
      accountNo:  [''],
      ifscCode:  [''],
      spclInterest:  [''],
      medicalHis:  [''],
      allergicTo:  [''],
      medicineAny:  [''],
      localStateId:  ['0'],
      localCityId:  ['0'],
      localPINCode:  [''],
      localContactNo:  [''],
      localFullAddress:  [''],
      copyAddressStatus:  ['false'],
      permenantStateId:  ['0'],
      permenantCityId:  ['0'],
      permenantPINCode:  [''],
      permenantContactNo:  [''],
      permenantFullAddress:  [''],
      fatherName:  [''],
      fatherQualification:  [''],
      fatherMobileNumber:  [''],
      fatherEmailId:  [''],
      fatherAdhaarNumber:  [''],
      fatherJobDetails:  [''],
      fatherDesignation:  [''],
      fatherOrganization:  [''],
      fatherAnnualIncome:  [''],
      fatherPhotograph:  [''],
      fatherImageUrl:  [''],
      motherFullName:  [''],
      motherQualification:  [''],
      motherMobileNumber:  [''],
      motherEmailId:  [''],
      motherAdhaarNumber:  [''],
      motherJobDetails:  [''],
      motherDesignation:  [''],
      motherOrganization:  [''],
      motherAnnualIncome:  [''],
      motherPhotograph:  [''],
      motherImageUrl:  [''],
      loginStatus:  ['true'],
      loginId:  [''],
      password:  [Math.floor(Math.random() * 89999 + 10000).toString()],
      biometricCode:  [''],
      transportService:  [false],
      hostelService:  [false],
      libraryService:  [false],
      messService:  [false],
      //studentSiblings: this.fb.array([]),
      studentSiblings: [''],
      studentType:['Day Scholar', Validators.required],
      availableSeats:['0'],
      documentList: this.fb.array([
        this.fb.group({
          id: [0],
          academicYearId: [0],
          fileName: [''],
          contentType: [''],
          contentBase: [''],
          contentFile: ['']
        })
      ]),
      GenderMale: [''],
      GenderFemale: [''],
      GenderOther: ['']
      });
    this.generateAdmissionNo();
    this.male = false;
    this.female = false;
    this.other = false;
    this.imageSrc = '';
  }

  submitForm() {
    
    this.submitted = true;
    this.loading=true;
        // stop here if form is invalid
    if (this.admissionForm.invalid) {
      this.loading=false;
      return;
    }
    this.siblingsString='';
    this.siblings.forEach(x=>
      this.siblingsString+=x.admissionId+','
      );
      this.admissionForm.get('studentSiblings').patchValue(this.siblingsString);
    this.admissionForm.get('copyAddressStatus').patchValue(this.admissionForm.get('copyAddressStatus').value.toString());
    this.admissionForm.get('transportService').patchValue(this.admissionForm.get('transportService').value.toString());
    this.admissionForm.get('hostelService').patchValue(this.admissionForm.get('hostelService').value.toString());
    this.admissionForm.get('libraryService').patchValue(this.admissionForm.get('libraryService').value.toString());
    this.admissionForm.get('messService').patchValue(this.admissionForm.get('messService').value.toString());
    this.admissionForm.get('messService').patchValue(this.admissionForm.get('messService').value.toString());

    this.patch();
    console.log(this.admissionForm.value);

    if (this.admissionForm.get('id').value === 0) {
        // this.patch();
        // this.registrationForm.get('RegistrationDate').patchValue(this.formatDate(new Date()));
        // this.registrationForm.get('RegisterNo').patchValue(this.registerNo);
        // this.registrationForm.get('ApplicationSource').patchValue('Offline');
        // this.registrationForm.get('ApplicationStatus').patchValue('Pending');
        // this.registrationForm.get('CopyAddressStatus').patchValue(this.registrationForm.get('CopyAddressStatus').value.toString());
        // console.log(this.registrationForm.value);
        console.log(this.admissionForm.value);
        this.studentService.create(this.admissionForm.value).subscribe(res => {
          console.log(res);
          console.log('Student created!');
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          // Swal.fire({
          //   title: 'Print Receipt?',
          //   text: 'Registration has been save successfully. Do You want to print Receipt..?',
          //   icon: 'warning',
          //   showCancelButton: true,
          //   confirmButtonText: 'Yes',
          //   cancelButtonText: 'No'
          // }).then((result) => {
          //   if (result.value) {
          //      this.router.navigate(['../reciept', res.id], {relativeTo: this.activatedRoute});
          //   } else if (result.dismiss === Swal.DismissReason.cancel) {

          //   }
          // });

          this.submitted = false;
          this.loading = false;
          this.resetForm();
          this.generateAdmissionNo();
        });
        } else {
          // this.admissionForm.get('RegistrationDate').patchValue(this.formatDate(this.tempDate));
          this.studentService.update(this.admissionForm.get('id').value, this.admissionForm.value).subscribe(res => {
          console.log('Registration updated!');
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          // Swal.fire({
          //   title: 'Print Receipt?',
          //   text: 'Admission has been update successfully. Do You want to print Receipt..?',
          //   icon: 'warning',
          //   showCancelButton: true,
          //   confirmButtonText: 'Yes',
          //   cancelButtonText: 'No'
          // }).then((result) => {
          //   if (result.value) {
          //      this.router.navigate(['../../reciept', res.id], {relativeTo: this.activatedRoute});
          //   } else if (result.dismiss === Swal.DismissReason.cancel) {
          //   }
          // });

          // navigate routee
          // this.router.navigate(['../../../admissionreport'], {relativeTo: this.activatedRoute});
          this.submitted = false;
          this.loading = false;
          setTimeout(()=>{
            window.close();
          },5000)
          
          // this.resetForm();
        });
      }
  }



}
