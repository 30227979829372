export enum ApplicationType {
    Image = 1,
    Text = 2,
    Stream = 3,
    Video = 4

    

  }
  

  export enum SessionType {
    Live = 1,
    Video = 2,
    Audio = 3,
    Document = 4,
    Text=5
  }
