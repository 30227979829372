import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ClassCourse } from '../../../academic/mastersettings/models/classcourse';
import { ClassCourseService } from '../../../academic/mastersettings/classcourse/classcourse.service';
import { Batch } from '../../../academic/mastersettings/models/batch';
import { BatchService } from '../../../academic/mastersettings/batch/batch.service';
import { Student } from './../../../academic/studentmanagement/student.model';
import { StudentService } from './../../../academic/studentmanagement/student.service';
import { InstallmentService } from '../../../academic/feemanagement/installment/installment.service';
import { Installment } from '../../../academic/feemanagement/installment/installment.model';
import { FeeMaster, FeeDetail, FeeDetailDetail } from '../../../academic/feemanagement/feecollection/feecollection.model';
import { FeeCollectionService } from '../../../academic/feemanagement/feecollection/feecollection.service';
import { FeeWaveoff } from '../../../academic/feemanagement/feewaveoff/feewaveoff.model';
import { FeeWaveoffService } from '../../../academic/feemanagement/feewaveoff/feewaveoff.service';
import { IAdmissionIssueBook, IBookIssue, IEmployeeIssueBook, IIssueBook, IStudentIssueBook } from '../../../../admin/OperationActivity/library/model/issueBookModel';
import { IssueBookService } from '../../../../admin/OperationActivity/library/service/issue-book.service';
import { certificateType, templateSetting } from '../../../administration/certificate/templatesetting/certificateType.models';
import { TemplateSettingsService } from '../../../administration/certificate/templatesetting/template-settings.service';
import { StudentCertificate } from '../../../administration/certificate/generatecertificate/generatecertificate.models';
import { GenerateCertificateService } from '../../../administration/certificate/generatecertificate/generatecertificate.service';
import { isFunction } from 'ngx-bootstrap/chronos/utils/type-checks';

@Component({
  selector: 'app-generatetc',
  templateUrl: './generatetc.component.html',
  styleUrls: ['./generatetc.component.css']
})
export class GeneratetcComponent implements OnInit {

  constructor(
    public feeCollectionService: FeeCollectionService,
    public installmentService: InstallmentService,
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public feewaveService: FeeWaveoffService,
    public batchService: BatchService,
    public issueBookService: IssueBookService,
    public templateSettingsService: TemplateSettingsService,
    public generateCertificateService: GenerateCertificateService,
    private toastr: ToastrService
  ) { }

  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  lookupAdmissions: Student[] = [];
  lAdmissions: Student[] = [];
  studentDetail: Student = {} as Student;
  installments: Installment[] = [];
  public studentIssueBookList: IStudentIssueBook[];
  templateSettingList:templateSetting[];
  studentCertificate: StudentCertificate;
  classId = '';
  batchId = '';
  admissionno = '';
  admissionId = '';
  remarks = '';
  installmentIds = '';
  waveOffDate = '';
  lookupMobileNo = '';
  lookupStudentName = '';
  lookupFatherName = '';
  studDetails = false;
  bookdue = false;
  dueTotal = '0.00';
  templateId = '';
  certificateTypeId = '';

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  ListStudents() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      console.log(data);
      this.lAdmissions = data;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }

  onClassSelected(value: string) {
    this.batches = [];
    this.admissions = [];
    this.studentDetail = {} as Student;
    this.resetEForm();
    this.classId = value;
    this.batchId = '';
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
      console.log(this.batches);
    });
  }

  onBatchChanged(value: string) {
    this.admissions = [];
    this.studentDetail = {} as Student;
    this.resetEForm();
    this.studentService.getAll().subscribe((data: Student[]) => {
     // this.ListFeeHead();
      this.admissions = data.filter((student: Student) => {
      return student.classCourseId.toString() === this.classId &&
        student.batchId.toString() === value.toString();
      });
    });
  }
  onStudentChanged(id: number) {
    console.log(id);
    this.resetEForm();
    this.admissionId = id.toString();
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.studentDetail = data.filter((student: Student) => {
        return student.id === id;
      })[0];
      this.studDetails = true;
      this.installmentService.getFeePayment(this.studentDetail.id).subscribe((inst: Installment[]) => {
        console.log(inst);
        this.installments = inst;
        const duesum = this.installments.filter(obj => !obj.isPaid && !obj.isWaved)
                                        .reduce((sum, obj) => sum + parseFloat(obj.amount), 0);
        this.dueTotal = duesum.toString();
      });
      this.issueBookService.getStudentBooksIssuedById(this.studentDetail.id).subscribe((studentIssueBookList: IStudentIssueBook[]) => {
        console.log(studentIssueBookList);
        this.studentIssueBookList = studentIssueBookList;
        if (this.studentIssueBookList.length > 0) {
          const bookduerecord = this.studentIssueBookList.filter(obj => !obj.returnDate);
          if(bookduerecord) {
            this.bookdue = true;
          }
        }
      });
      this.ListAllTemplates();
    });
  }

  GenerateTC() {
    if (this.classId == '') {
      this.toastr.warning('Please select class.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    if (this.batchId == '') {
      this.toastr.warning('Please select batch.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    if (this.admissionId == '') {
      this.toastr.warning('Please select student.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    const due = parseFloat(this.dueTotal);
    if (due > 0) {
      this.toastr.warning('Due fee pending for selected student.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    if (this.bookdue) {
      this.toastr.warning('Book due pending for selected student.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    if (this.templateId == '') {
      this.toastr.warning('TC template is not configured.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    debugger;
    this.studentCertificate = {
      id: 0,
      studentId: Number(this.admissionId),
      certificateTypeId: Number(this.certificateTypeId),
      templateId: Number(this.templateId),
      //certificateContent: this.getCertificateContent(item)
    };
    this.generateCertificateService.create(this.studentCertificate).subscribe(res => {
      console.log(' created!');
      this.toastr.success('TC generated sucessfully.', 'SUCCESS!', {
        timeOut: 3000
      });
      this.resetEForm(); 
    });

  }

  ListAllTemplates(){
    this.templateSettingsService.getAllTemplates().subscribe((data:templateSetting[])=>{
      this.templateSettingList=data.filter((o: any) => o.issueFor == 'Student');
      debugger;
      const tempId = this.templateSettingList.find(y => y.templateName == 'TC').id.toString();
      this.templateId = tempId;
      const ctypeid = this.templateSettingList.find(y => y.templateName == 'TC').certificateTypeId.toString();
      this.certificateTypeId = ctypeid;
    });
  }

  resetForm() {
    this.installments = [];
    this.studentIssueBookList = [];
    this.installmentIds = '';
    this.batches = [];
    this.classId = '';
    this.batchId = '';
    this.lookupAdmissions = [];
    this.admissionno = '';
    this.admissionId = '';
    this.remarks = '';
    this.lookupMobileNo = '';
    this.lookupStudentName = '';
    this.lookupFatherName = '';
    this.studentDetail = {} as Student;
    this.studDetails = false;
    this.admissions = [];
    this.dueTotal = '0.00';
    $('.select2').select2('data');
  }

  resetEForm() {
    this.installments = [];
    this.installmentIds = '';
    this.studentIssueBookList = [];
    this.lookupAdmissions = [];
    this.admissionno = '';
    this.admissionId = '';
    this.remarks = '';
    this.studentDetail = {} as Student;
    this.studDetails = false;
    this.dueTotal = '0.00';
    // $('.select2').select2('val', '');
  }

  ngOnInit(): void {
    this.ListStudents();
    const self = this;
    this.ListClassCourse();
    this.ListAllTemplates();
    $('.select2').select2();
    $('.select2').on('change', function() {
      self.onStudentChanged(Number($(this).val()));
      self.admissionId = $(this).val().toString();
     });
    // $('#waveoffDate').val(this.formatDate(new Date()));
    // this.waveOffDate = this.formatDate(new Date());
    // $('#waveoffDate').datepicker().on('changeDate', (e) => {
    //   self.waveOffDate =  this.formatDate(e.date);
    // });
  }

}
