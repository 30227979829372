import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StudentConcession } from './setstudentconcession.model';


@Injectable({
    providedIn: 'root'
  })
  export class SetStudentConcessionService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         enctype: 'multipart/form-data'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(academicYearId, admissionId, concessionTemplateId): Observable<StudentConcession[]> {
      return this.httpClient.get<StudentConcession[]>(environment.apiUrl + '/SetStudentConcession/GetAllSetStudentConcessions?AcademicYearId='+academicYearId+'&AdmissionId='+admissionId+'&ConcessionTemplateId='+concessionTemplateId)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getSetConcession(academicYearId, admissionId): Observable<StudentConcession> {
      return this.httpClient.get<StudentConcession>(environment.apiUrl + '/SetStudentConcession/GetSetStudentConcession?AcademicYearId='+academicYearId+'&AdmissionId='+admissionId)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<StudentConcession> {
      return this.httpClient.get<StudentConcession>(environment.apiUrl + '/setstudentconcession/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    // getByInstallmentId(id, classId): Observable<PDCEntry[]> {
    //   return this.httpClient.get<PDCEntry[]>(environment.apiUrl + '/feehead/GetFeeHeadByInstallment/' + id + '/' + classId)
    //   .pipe(
    //     catchError(this.errorHandler)
    //   );
    // }

    create(pdcentry): Observable<StudentConcession> {
      return this.httpClient.post<StudentConcession>(environment.apiUrl + '/setstudentconcession/', pdcentry)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    update(id, pdcentry): Observable<StudentConcession> {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.put<StudentConcession>(environment.apiUrl + '/setstudentconcession/' + id, JSON.stringify(pdcentry), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    delete(id) {
      return this.httpClient.delete<StudentConcession>(environment.apiUrl + '/setstudentconcession/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }

  }
