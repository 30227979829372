import { Component, Inject, OnInit } from '@angular/core';
import { DialogData } from '../../../academicsettings/gradescale/gradescale.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-add-edit-time-table-dialog',
  templateUrl: './add-edit-time-table-dialog.component.html',
  styleUrls: ['./add-edit-time-table-dialog.component.css']
})

export class AddEditTimeTableDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AddEditTimeTableDialogComponent>) { }

  ngOnInit(): void {
  }

}
