import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Student } from './../../studentmanagement/student.model';
import { StudentService } from './../../studentmanagement/student.service';
import { PDCEntry } from './pdcentry.model';
import { PDCEntryService } from './pdcentry.service';
declare var $: any;

@Component({
  selector: 'app-pdcentry',
  templateUrl: './pdcentry.component.html',
  styleUrls: ['./pdcentry.component.css']
})
export class PdcentryComponent implements OnInit {

  pdcEntryForm: UntypedFormGroup;
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  pdcEntries: PDCEntry[] = [];
  pdcEntryFilteredList: PDCEntry[] = [];
  chequeDate: string;
  classId = '';
  admissionId = '';
  submitted = false;

  constructor(
    public pdcEntryService: PDCEntryService,
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  ListPDCEntry() {
    this.pdcEntryService.getAll().subscribe((data: PDCEntry[]) => {
      console.log(data);
      this.pdcEntryFilteredList = this.pdcEntries = data;
    });
  }

  ListStudents() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.admissions = data;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  onClassSelected(value: string) {
    this.classId = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
    });
  }

  onBatchChanged(value: string) {
    this.studentService.getAll().subscribe((data: Student[]) => {
     // this.ListFeeHead();
      this.admissions = data.filter((student: Student) => {
      return student.classCourseId.toString() === this.classId &&
        student.batchId.toString() === value.toString();
      });
    });
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
      if (keyword === '') {
        this.pdcEntryFilteredList = this.pdcEntries;
      } else {
        this.pdcEntryFilteredList = this.pdcEntries
          .filter((pdcentry: PDCEntry) => pdcentry.chequeNo.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase()) ||
            pdcentry.bankName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase()) ||
            pdcentry.studentName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase()) ||
            pdcentry.admissionNo.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase())
          );
      }
  }

  keyPress(event: any) {
    const pattern = /[0-9\.+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode !== '0') {
            event.preventDefault();
    }
    // event.target.value = Number(event.target.value).toFixed(2);
  }

  onBlur() {
    this.pdcEntryForm.get('chequeDate').patchValue(this.formatDate(this.chequeDate));
  }

  onBlurAmount() {
    const amt = this.pdcEntryForm.get('amount').value;
    if (amt !== '') {
      this.pdcEntryForm.get('amount').patchValue(Number(amt).toFixed(2));
    }
  }

  get totalRows(): number {
    return this.pdcEntryFilteredList.length;
  }
  get f() { return this.pdcEntryForm.controls; }
  resetForm() {
    this.submitted = false;
    this.classes = [];
    this.batches = [];
    $('.select2').val('');
    this.pdcEntryForm = this.fb.group({
      id: [0],
      admissionId: ['', Validators.required],
      chequeNo: ['', Validators.required],
      chequeDate: ['', Validators.required],
      amount: ['', Validators.required],
      bankName: ['', Validators.required]
    });
  }
  submitForm() {
    

    this.pdcEntryForm.get('admissionId').patchValue(this.admissionId);
    this.pdcEntryForm.get('chequeDate').patchValue(this.formatDate(this.chequeDate));
    // const startDate = this.pdcEntryForm.get('startDate').value;
    this.submitted = true;
    console.log(this.pdcEntryForm.value);

        // stop here if form is invalid
    if (this.pdcEntryForm.invalid) {
            return;
      }
  
    if (this.pdcEntryForm.get('id').value === 0) {
        this.pdcEntryService.create(this.pdcEntryForm.value).subscribe(res => {
          console.log('PDC Entry created!');
          this.ListPDCEntry();
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
        } else {
          this.pdcEntryService.update(this.pdcEntryForm.get('id').value, this.pdcEntryForm.value).subscribe(res => {
            console.log('PDC Entry updated!');
            this.ListPDCEntry();
            this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submitted = false;
            this.resetForm();
          });
        }
  
  
      }
  
      updateForm(id: number) {
        this.pdcEntryService.getById(id).subscribe((data: PDCEntry) => {
          console.log(data);
          this.onClassSelected(data.classId);
          this.onBatchChanged(data.batchId);
          this.chequeDate = data.chequeDate;
          this.pdcEntryForm = this.fb.group({
            id: [data.id],
            admissionId: [data.admissionId, Validators.required],
            chequeNo: [data.chequeNo, Validators.required],
            chequeDate: [this.formatDate(data.chequeDate), Validators.required],
            amount: [data.amount, Validators.required],
            bankName: [data.bankName, Validators.required]
          });
        });
  
      }
  
      deleteForm(id: number) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            this.pdcEntryService.delete(id).subscribe(res => {
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              );
              this.ListPDCEntry();
            });
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
          }
        });
      }

  ngOnInit(): void {
    var self = this;
    this.ListClassCourse();
    this.ListPDCEntry();
    this.chequeDate = new Date().toString();
    $('#chequeDate').datepicker().on('changeDate', (e) => {
      self.chequeDate =  new Date(e.date).toString();
    });
    $('.select2').select2();
    $('.select2').on('change', function() {
      self.admissionId = $(this).val();
    });
    this.pdcEntryForm = this.fb.group({
      id: [0],
      admissionId: ['', Validators.required],
      chequeNo: ['', Validators.required],
      chequeDate: [this.formatDate(new Date()), Validators.required],
      amount: ['', Validators.required],
      bankName: ['', Validators.required]
    });
  }

}
