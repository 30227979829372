export class AttendanceSummary{
    total:number;
    present:number ;
    absent :number;
    onLeave :number;
    leaveRequest :number;
}
export class PendingToDoSummary{
    totalVisitorEnquiry:number;
    pendingVisitorEnquiry:number ;
    totalAdmissionEnquiry :number;
    pendingAdmissionEnquiry :number;
    totalFees :number;
    dueFees :number;
    totalLeaveRequestApproval :number;
    pendingLeaveRequestApproval :number;
    totalStudentComplaints :number;
    totalLeaveRequestPending:number;
    pendingLeaveRequestPending:number;
}

export class AcademicSummary{
    totalCLasses:number;
    totalBatches:number;
    activeStudents:number;
    inactiveStudents:number;
}
export class SessionWiseStudentReport{
    classeName:string;
    enquiryCount:number;
    registrationCount:number;
    admissionCount:number;
    oldCount:number;
    passoutCount:number;
    leftCount:number;
    availableCount:number;
}
export class SessionWiseStudentTotalsReport{
    enquiryCount:number;
    registrationCount:number;
    admissionCount:number;
    oldCount:number;
    passoutCount:number;
    leftCount:number;
    availableCount:number;
}

export class DashboardClassWiseStudentsSummary{
    classCourseName:string;
    batchName:string;
    nostudent:number;
    male:number;
    female:number;
    other:number;
    seatingCapacity:number;
    vaccent:number;
     
}

export class DashboardClassWiseStudentsTotalSummary{
    classCourseName:number;
    nostudent:number;
    male:number;
    female:number;
    other:number;
     
}


export class CategoryNameReligions{
    name:string;
    total:number;
    category:string;
 
     
     
}

export class DashboardDuesPaidDto{
    totalDueAmount:number;
    paidAmount:number;
 
     
     
}



export class FinanceDashboardMonthWiseFeeCollectionReportDto{
    installmentName:string;
    totalFee:number;
    fineAmount:number;
    discountAmount:number;
    paidAmount:number;
   
     
}



export class financeDashboardIncomeExpenseCollectionReport{
    srno:number;
    months:string;
    incomeAmount:number;
    expenseAmount:number;
    incomeExpenseCategory:string;
    entryType: string;
   
     
}

export interface DepartmentWiseEmployeeCountDto {
    totalMaleEmployees: number;
    totalFemaleEmployees: number;
    totalActiveEmployees: number;
    totalTeaching: number;
    totalNonTeaching: number;
    totalInActive: number;
    totalEmployees: number;
    departmentEmployeeClassifications: DepartmentEmployeeClassification[];
    employeeTypeSummaries: EmployeeTypeSummary[];
    jobTypeSummaries: JobTypeSummary[];
}

export interface DepartmentEmployeeClassification {
    departmentName: string;
    totalMale: number;
    totalFemale: number;
    subTotal: number;
}

export interface EmployeeTypeSummary {
    employeeType: string;
    count: number;
}

export interface JobTypeSummary {
    jobTypeName: string;
    count: number;
}

export interface DepartmentWiseSalaryDto {
    totalPaidSalary: number;
    totalUnpaidSalary: number;
    departmentSalaries: DepartmentSalaryDto[];
}

export interface DepartmentSalaryDto {
    departmentName: string;
    totalSalary: number;
}

export interface EmployeeJoiningAndExitSummaryReport {
    allEmployeesCount: number;
    oldEmployeesCount: number;
    newJoiningsCount: number;
    totalExitsCount: number;
    employeeJoiningsAndExits: EmployeeJoiningAndExit[];
}

export interface EmployeeJoiningAndExit {
    year: number;
    month: string;
    totalJoinings: number;
    totalExits: number;
}

export interface SessionWiseSalary {
    totalSalaryPaid: number;
    session: string;
}

export interface GetEmployeeLeaveAttendanceReport {
    totalWorkingDays: number;
    totalHolidays: number;
    totalPresentRatio: number;
    totalAbsentRatio: number;
    employeeAttendanceSummaryReports: EmployeeAttendanceSummaryReport[];
}

export interface EmployeeAttendanceSummaryReport {
    attendanceId: number;
    attendanceMonth: number;
    monthName: string;
    workingDays: number;
    presentCount: number;
    absentCount: number;
    leaves: number;
    holidays: number;
}