import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BillingRoutingModule } from './biling-routing.module';
import { UpdatePaymentComponent } from './update-payment/update-payment.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';



@NgModule({
  declarations: [UpdatePaymentComponent, PaymentHistoryComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BillingRoutingModule,
  ]
})
export class BillingModule { }
