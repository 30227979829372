import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FeeTransactionReportModel} from './feetransactionreport.model';
import { FeeReciept, FeeRecieptDetailDetail} from '../feereceipt/feereceipt.model';

import { environment } from '../../../../../../environments/environment';
import { PaymentMode } from '../../../mastersettings/models/paymentmode';

@Injectable({
  providedIn: 'root'
})
export class FeeTransactionReportService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }

  getAllFeeTransactionReport(): Observable<FeeTransactionReportModel[]> {
    return this.httpClient.get<FeeTransactionReportModel[]>(environment.apiUrl + '/FeeReport/GetFeeTransactionReport')
     .pipe(
       catchError(this.errorHandler)
     );
   }

 getFeeTransactionReport(transactionMode,payMode,paymentStatus,dateFrom,dateTo): Observable<FeeTransactionReportModel[]> {
   return this.httpClient.get<FeeTransactionReportModel[]>(environment.apiUrl + '/FeeReport/GetFeeTransactionReport/'+transactionMode+'/'+payMode+'/'+paymentStatus+'/'+dateFrom+'/'+dateTo)
    .pipe(
      catchError(this.errorHandler)
    );
  }

getAllpaymentMethod():Observable<PaymentMode[]>
{
  return this.httpClient.get<PaymentMode[]>(environment.apiUrl + '/PaymentMode')
    .pipe(
      catchError(this.errorHandler)
    );
}
getById(id): Observable<PaymentMode> {

  debugger
  return this.httpClient.get<PaymentMode>(environment.apiUrl + '/GetFeeCollectionDetail/' + id)
  .pipe(
    catchError(this.errorHandler)
  );
}

  
errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
