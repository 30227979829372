import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StudentService } from './../student.service';
import { Student } from './../student.model';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { saveAs as importedSaveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { StudentbirthdayreportComponent } from '../studentbirthdayreport/studentbirthdayreport.component';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { Batch } from 'src/app/admin/communication/broadcast/broadcast.model';
import { BatchService } from '../../mastersettings/batch/batch.service';


import { Nationality } from '../../mastersettings/models/nationality';
import { Religion } from '../../mastersettings/models/religion';
import { Category } from '../../mastersettings/models/category';
import { Caste } from '../../mastersettings/models/caste';
import { Country } from '../../mastersettings/models/country';
import { State } from '../../mastersettings/models/state';
import { City } from '../../mastersettings/models/city';

import { SettingsService } from '../../mastersettings/settings.service';
import { NationalityService } from '../../mastersettings/nationality/nationality.service';
import { ReligionService } from '../../mastersettings/religion/religion.service';
import { CategoryService } from '../../mastersettings/category/category.service';
import { CasteService } from '../../mastersettings/caste/caste.service';
import { CountryService } from '../../mastersettings/country/country.service';
import { StateService } from '../../mastersettings/state/state.service';
import { CityService } from '../../mastersettings/city/city.service';
import { Stream } from '../../mastersettings/models/stream';
import { StreamService } from '../../mastersettings/stream/stream.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
@Component({
  selector: 'app-bulkuploadstudent',
  templateUrl: './bulkuploadstudent.component.html',
  styleUrls: ['./bulkuploadstudent.component.css']
})
export class BulkuploadstudentComponent implements OnInit {
  @ViewChild('studentFile') studentFileVariable: ElementRef;
  admissions: Student[] = [];
  uploadError=false;
  admissionsfilteredlist: Student[] = [];
  selectedAdmissionsList: Student[] = [];
  arraylist: any[] = [];
  file: any;
  filelist: any[] = [];
  arrayBuffer: any;
  // toastr: any;
  academicYears: Academicyear[] = [];
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  nationalities: Nationality[] = [];
  religions: Religion[] = [];
  categories: Category[] = [];
  castes: Caste[] = [];
  countries: Country[] = [];
  states: State[] = [];
  cities: City[] = [];
  streams: Stream[] = [];
  currentAcademicYearId: number;
  invalidMesages = '';
  isLoading=false;
  constructor(
    public studentService: StudentService,
    private toastr: ToastrService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    public nationalityService: NationalityService,
    public religionService: ReligionService,
    public categoryService: CategoryService,
    public casteService: CasteService,
    public countryService: CountryService,
    public stateService: StateService,
    public settingsService: SettingsService,
    public cityService: CityService,
    public streamService: StreamService,
    public academicyearService: SettingsService
  ) { }

  ListStudents() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      console.log(data);
      //this.admissionsfilteredlist = this.admissions = data;
      this.admissions = data;
    });
  }
  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }
  ListBatches() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data;

    });
  }

  ListNationalities() {
    this.nationalityService.getAll().subscribe((data: Nationality[]) => {
      this.nationalities = data;
    });
  }

  ListReligions() {
    this.religionService.getAll().subscribe((data: Religion[]) => {
      this.religions = data;
    });
  }

  ListCategories() {
    this.categoryService.getAll().subscribe((data: Category[]) => {
      this.categories = data;
    });
  }

  ListCastes() {
    this.casteService.getAll().subscribe((data: Caste[]) => {
      this.castes = data;
    });
  }

  ListCountry() {
    this.countryService.getAll().subscribe((data: Country[]) => {
      console.log(data);
      this.countries = data;
    });
  }

  ListState(countryId: any) {
    this.stateService.getAll().subscribe((data: State[]) => {
      this.states = data;
    });
  }

  ListCity() {
    this.cityService.getAll().subscribe((data: City[]) => {
      console.log(data);
      this.cities = data;
    });
  }

  ListStream() {
    this.streamService.getAll().subscribe((data: Stream[]) => {
      console.log(data);
      this.streams = data;
    });
  }
  ListAcademicYear() {
    this.settingsService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
    });
  }
  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      console.log(data.id);
      this.currentAcademicYearId = data.id;
    });
  }
  get totalRows(): number {
    return this.admissionsfilteredlist.length;
  }
  checkAllstudentCheckBox(ev) {
    this.admissionsfilteredlist.forEach(x => x.checked = ev.target.checked);
  }

  isAllstudentCheckBoxChecked() {
    return this.admissionsfilteredlist.every(p => p.checked);
  }
  addfile(event) {
    debugger;
    console.log('ck: ' + event.target.files);
    this.file = event.target.files[0];
    if (this.file === '') {
      this.toastr.warning('Please select file', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
     let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary', cellDates: true });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        console.log(jsonData,"jsonDAta");
        return initial;
      }, {});
   //   const dataString = JSON.stringify(jsonData);
   console.log(" this.arraylist ", this.arraylist )
   const dataString = JSON.stringify(jsonData);
   console.log(dataString,"stringify data");
   this.arraylist = JSON.parse(dataString)
   console.log(this.arraylist,"parsed json");
 //  console.log(Object.keys(this.arraylist['data'][0]))
      //this.arraylist = jsonData;
     // document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
     
    }
    reader.readAsBinaryString(file);
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' , cellDates: true });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      this.arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      console.log(" this.arraylist ", this.arraylist )
      // this.admissionsfilteredlist = arraylist as Student[];
      // this.filelist = [];
    };
  }

  public base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    // b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    // b64Data = b64Data.split(',')[1];
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  resetForm() {
    this.admissionsfilteredlist = [];
    this.file = '';
    this.studentFileVariable.nativeElement.value = '';
    this.isLoading=false;
    this.invalidMesages='';
    this.uploadError=false;
    this.ListStudents();
  }

  downloadDocFile() {
    // const DocFileName = data.DocFile;
    const DocFile = 'template'; // DocFileName.slice(0, -5);
    this.studentService.downloadFile(DocFile).subscribe((data) => {
      console.log(data);
      // const blob = new Blob([data], { type: 'application/octet-stream' });
      const blob = this.base64ToBlob(data, 'application/octet-stream');
      importedSaveAs(blob, DocFile + '.xlsx');
      // const url = window.URL.createObjectURL(blob);
      // console.log(url);
      // window.open(url);
    });
  }

  uploadFile() {
    this.uploadError=!this.uploadError;
    this.admissionsfilteredlist = [];
    if(this.arraylist.length==0){
      this.toastr.warning('Please select file', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    this.arraylist.forEach((x) => {
     
      var count:number = 1;
      const student: Student = {} as Student;
     
      student.admissionNoCounter = (x['Admission No']).toString().replace( /^\D+/g, '');
      student.academicYearName = x['Academic Year'];
      student.admissionNo = x['Admission No'];
      student.admissionDate = x['Admission Date'];
      student.className = x.Class;
      student.batchName = x.Batch;
      student.studentType = x.studentType;
      var academicYEar = this.academicYears.filter(c => c.name.toString() === student.academicYearName.toString());
      if(academicYEar !== undefined && academicYEar.length > 0)
      {
        student.academicYearId = academicYEar[0].id.toString();
      }
      else{
        this.invalidMesages = "\r\n Invalid Academic Year " + x.academicYearName;
        this.uploadError=true;
      }
      var classCourseId = this.classes.filter(s => s.classCourseName.toString() === x.Class.toString());
      if(classCourseId !== undefined && classCourseId.length > 0)
      {
        student.classCourseId = classCourseId[0].id.toString();
      }
      else{
        this.invalidMesages = "\r\n Invalid Class course Id " + x.Class;
        this.uploadError=true;
      }
      /*var studentAdmissionNumber = this.admissions.filter(s => s.admissionNo.toString() ===x['Admission No'].toString());
      if(studentAdmissionNumber !== undefined && studentAdmissionNumber.length > 0)
      {
        student.admissionNo = x['Admission No'];
      }
      else{
        this.invalidMesages = "\r\n Duplicate AdmissionNo " + x['Admission No'];
      }*/
      if(x.Batch!=undefined){
      var batchId = this.batches.filter(c => c.batchName.toString() === x.Batch.toString() && c.classId.toString() == student.classCourseId)
      if(batchId !== undefined && batchId.length > 0)
      {
        student.batchId = batchId[0].id.toString();
      }
      else{
        this.invalidMesages += "\r\n Invalid Class course Id " + x.Batch;
        this.uploadError=true;
      }
    } else{
      this.invalidMesages += "\r\n Invalid Class course Id " + x.Batch;
      this.uploadError=true;
    }
      student.dob = x['Date Of Birth'] === undefined ? '' : x['Date Of Birth'].toString();
      student.fatherName = x['Father Name'] === undefined ? '' : x['Father Name'];
      student.gender = x.Gender === undefined ? '' : x.Gender;
      if(student.gender==''){
        this.invalidMesages += "\r\n Invalid Gender " + x.gender;
      }
      student.rollNo = x['Roll No'] === undefined ? '' : x['Roll No'];
      student.streamName = x.Stream === undefined ? '' : x.Stream;
      if (student.streamName !== '') {
        var streamId = this.streams.filter(s => s.streamName.toString() === x.Stream.toString());
        if(streamId !== undefined && streamId.length > 0)
        {
          student.streamId = streamId[0].id.toString();
        }
        else{
          this.invalidMesages += "\r\n Invalid Stream Id " + x.StreamId;
          this.uploadError=true;
        }
      }
      student.studentName = x['Student Name'] === undefined ? '' : x['Student Name'];
      student.religionName = x.Religion === undefined ? '' : x.Religion;
      if (student.religionName !== '') {
        var religionId = this.religions.filter(s => s.religionName.toString() === x.Religion.toString());
        if(religionId !== undefined && religionId.length > 0)
        {
          student.religionId = religionId[0].id.toString();
        }
        else{
          this.invalidMesages += "\r\n Invalid religion " + x.Religion;
          this.uploadError=true;
        }
      }
      student.streamName = x['Term/Sem'] === undefined ? '' : x['Term/Sem'];
      student.categoryName = x.Category === undefined ? '' : x.Category;
      if (student.categoryName !== '') {
        var categoryId = this.categories.filter(s => s.categoryName.toString() === x.Category.toString());
        if(categoryId !== undefined && categoryId.length > 0)
        {
          student.categoryId = categoryId[0].id.toString();
        }
        else{
          this.invalidMesages += "\r\n Invalid categoryId " + x.Category;
          this.uploadError=true;
        }
      }
      student.casteName = x.Caste === undefined ? '' : x.Caste;
      if (student.casteName !== '') {
        var casteId = this.castes.filter(s => s.casteName.toString() === x.Caste.toString());
        if(casteId !== undefined && casteId.length > 0)
        {
          student.casteId = casteId[0].id.toString();
        }
        else{
          this.invalidMesages += "\r\n Invalid casteId " + x.Caste;
          this.uploadError=true;
        }
      }
      student.fatherName = x['Father Name'] === undefined ? '' : x['Father Name'];
      student.motherFullName = x['Mother Name'] === undefined ? '' : x['Mother Name'];
      student.emailId = x.Email === undefined ? '' : x.Email;
      student.smsMobNumber = x['SMS Mobile No'] === undefined ? '' : x['SMS Mobile No'];
      student.whatsAppNo = x['WhatsApp No'] === undefined ? '' : x['WhatsApp No'];
      student.adharNumber = x['Aadhar No'] === undefined ? '' : x['Aadhar No'];
      student.houseName = x['House Name'] === undefined ? '' : x['House Name'];
      student.localStateName = x.State === undefined ? '' : x.State;
      if (student.localStateName !== '') {
        var localStateId = this.states.filter(s => s.stateName.toString() === x.State.toString());
        if(localStateId !== undefined && localStateId.length > 0)
        {
          student.localStateId = localStateId[0].id.toString();
        }
        else{
          this.invalidMesages += "\r\n Invalid localStateId " + x.State;
          this.uploadError=true;
        }
      }
      student.localCityName = x.City === undefined ? '' : x.City;
      if (student.localCityName !== '') {
        var localCityId = this.cities.filter(s => s.cityName.toString() === x.City.toString());
        if(localCityId !== undefined && localCityId.length > 0)
        {
          student.localCityId = localCityId[0].id.toString();
        }
        else{
          this.invalidMesages += "\r\n Invalid localCityId " + x.City;
          this.uploadError=true;
        }
      }
      student.localPINCode = x['Pin Code'] === undefined ? '' : x['Pin Code'];
      student.localFullAddress = x['Full Address'] === undefined ? '' : x['Full Address'];
      student.status = x.Status === undefined ? '' : x.Status;
      student.checked = false;
     // student.academicYearId = this.currentAcademicYearId.toString();
  
  
  /*   if (student.classCourseId === undefined) {
        this.invalidMesages += '\r\n Class is not valid for ' + student.studentName;
        this.uploadError=true;
      }
      if (student.batchId === undefined) {
        this.invalidMesages += '\r\n Batch is not valid for ' + student.studentName;
        this.uploadError=true;
      }*/
      if (student.academicYearId === undefined) {
        this.invalidMesages += '\r\n Academic year is not valid for ' + student.studentName;
        this.uploadError=true;
      }
      if(this.invalidMesages !== '')
      {
         this.invalidMesages += "\r\n Fix line number " + count;
         this.uploadError=true;
      }
      if(this.admissions.filter(x=> x.admissionNo == student.admissionNo).length > 0)
      {
          this.invalidMesages += "\r\n Admission Number already exist "+ student.admissionNo;
          this.uploadError=true;
      }
      student.studentType = x['StudentType'] === undefined ? '' : x['StudentType'];
      // else{
        this.admissionsfilteredlist.push(student);
      // }
      count++;
    });
    if (this.invalidMesages !== '') {
      this.invalidMesages = this.invalidMesages.replace("\r\n","\n");
      this.uploadError=true;
      this.toastr.error(this.invalidMesages, 'INVALID FIELDS!', {
        timeOut: 3000

      });
      this.isLoading=false;
      this.uploadError=false;
    }
  }

  OnVerifySave() {
    
    Swal.fire({
      title: 'Are you sure you want save the student info?',
      text: 'Once created can\'t be edited !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes !',
      cancelButtonText: 'No'
    }).then((result) => {
      
      if (result.value) {
        this.isLoading=true;
        this.admissionsfilteredlist.forEach((data) => {
          if (data.checked) {
           const d = new Date(data.admissionDate);
            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            const year = d.getFullYear();
            if (month.length < 2) {month = '0' + month; }
            if (day.length < 2) {day = '0' + day; }
            data.admissionDate = [day, month, year].join('-');

            ///
            const d1 = new Date(data.dob);
            let month1 = '' + (d1.getMonth() + 1);
            let day1 = '' + d1.getDate();
            const year1= d1.getFullYear();
            if (month1.length < 2) {month1 = '0' + month1; }
            if (day1.length < 2) {day1 = '0' + day1; }
            data.dob = [day1, month1, year1].join('-');
            
            this.selectedAdmissionsList.push(data);
          }
        });
        if (this.invalidMesages !== '') {
          this.toastr.error('Check the data and after save it', 'INVALID FIELDS!', {
            timeOut: 3000
          });
          this.isLoading=false;
          return false;
        }
        if (this.selectedAdmissionsList.length === 0) {
          this.toastr.error('Please select atleast one record', 'INVALID!', {
            timeOut: 3000
          });
          this.isLoading=false;
          return false;
        }
        // console.log(this.selectedAdmissionsList);
        
        this.studentService.bulkCreate(this.selectedAdmissionsList).subscribe(res => {
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.isLoading=false;
          this.resetForm();
          this.invalidMesages = '';
        });

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.isLoading=false;
      }
    });
  }

  ngOnInit(): void {
    this.ListAcademicYear();
    this.ListClassCourse();
    this.ListBatches();
    this.ListCountry();
    this.ListState(1);
    this.ListCity();
    this.ListReligions();
    this.ListNationalities();
    this.ListCategories();
    this.ListStream();
    this.ListCastes();
    this.currentAcademicYear();
    this.ListStudents();
  }

}
