import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StudentService } from '../../studentmanagement/student.service';
import { ClassCourseService } from './../../mastersettings/classcourse/classcourse.service';
import { Student, StudentDetails } from '../../studentmanagement/student.model';
import { ClassCourse } from './../../mastersettings/models/classcourse';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { SettingsService } from '../../mastersettings/settings.service';


@Component({
  selector: 'app-assignrollno',
  templateUrl: './assignrollno.component.html',
  styleUrls: ['./assignrollno.component.css']
})
export class AssignrollnoComponent implements OnInit {

  admissions: StudentDetails[] = [];
  admissionsfilteredlist: StudentDetails[] = [];
  admissionsselectedlist: StudentDetails[] = [];
  classcourses: ClassCourse[] = [];
  classcourseid = '-1';
  batchid = '-1';
  batches: Batch[] = [];
  academicYear: Academicyear = {} as Academicyear;
  isLoading :any =false; 

  constructor(
    private studentService: StudentService,
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    private toastr: ToastrService,
    private academicyearService: SettingsService
  ) { }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      console.log(data);
      this.academicYear = data;
      this.ListStudents();
    });
  }

  ListStudents() {
    this.studentService.getAll().subscribe((data: StudentDetails[]) => {
      console.log(data);
      this.admissions = data.filter((admission: Student) =>
      admission.academicYearId.toString() === this.academicYear.id.toString());
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  onClassSelected(value: string) {
    this.batches = [];
    this.admissionsfilteredlist = [];
    if (value === '-1') { return; }
    if (value === '0') {
      // this.admissionsselectedlist = this.admissionsfilteredlist = this.admissions; // = data;
    } else {
        this.batchService.getAll().subscribe((data: Batch[]) => {
          this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
        });
        // this.admissionsfilteredlist = this.admissions.filter((admission: Student) =>
        // admission.classCourseId.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
    }
  }

  onBatchSelected(value: string) {
    this.isLoading =true;
    console.log(value);
    if (value === '-1') { return; }
    if (value === '0') {
      this.admissionsselectedlist = this.admissionsfilteredlist = this.admissions; // = data;
    } else {
      this.admissionsselectedlist = this.admissionsfilteredlist = this.admissions.filter((admission: StudentDetails) =>
        admission.batchId.toString()  === value.toString());
    }
    this.isLoading =false;
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (this.admissionsselectedlist.length === 0) {
    if ( keyword === '') {
      this.admissionsfilteredlist = this.admissions;
    } else {
    this.admissionsfilteredlist = this.admissionsfilteredlist
                                .filter((admission: Student) =>
                                admission.studentName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()) ||
                                admission.admissionNo.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()) ||
                                admission.fatherName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
    }
  } else {
    if ( keyword === '') {
      this.admissionsfilteredlist = this.admissionsselectedlist;
    } else {
    this.admissionsfilteredlist = this.admissionsselectedlist
                                .filter((admission: Student) =>
                                admission.studentName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()) ||
                                admission.admissionNo.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()) ||
                                admission.fatherName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
    }
  }
  }

  get totalRows(): number {
    return this.admissionsfilteredlist.length;
  }

  generateRollNo() {
    this.admissionsfilteredlist.forEach((x, i) => {
      x.rollNo = (i + 1).toString();
    });
  }

  assignRollNo() {
    // debugger;
    this.isLoading =true;
    console.log(this.admissionsfilteredlist);
    this.studentService.assignrollno(this.admissionsfilteredlist).subscribe(res => {
        this.ListStudents();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.isLoading =false;
    });
  }

  ngOnInit(): void {
    this.ListClassCourse();
    this.currentAcademicYear();
  }

}
