import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ExamwiseattendenceService } from '../../services/examwiseattendence.service';
import { SubjectCreation } from '../../../../academic/academicsettings/subjectcreation/subjectcreation.model';
import { SubjectcreationService } from '../../../../academic/academicsettings/subjectcreation/subjectcreation.service';

import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-exam-wise-attendence',
  templateUrl: './exam-wise-attendence.component.html',
  styleUrls: ['./exam-wise-attendence.component.css']
})
export class ExamWiseAttendenceComponent implements OnInit {
  examWiseAttendenceForm:UntypedFormGroup;
  categorydropDown: any = [];
  finalexamNamedropDown:any=[];
  attendenceCount:number=0;
  absentCount:number=0;
  examWiseAttendanceList:any=[];
  selectedclass:string="";
  examWiseAttendanceStudentList:any=[];
  submitted:boolean=false;
  subjects: SubjectCreation[] = [];
  examNamedropDown:any=[];
  selectedRow:number;
  constructor(public fb: UntypedFormBuilder,public service:ExamwiseattendenceService,private toastr: ToastrService,public subjectService: SubjectcreationService,) { }

  ngOnInit(): void {
    this.examWiseAttendenceForm = this.fb.group({
      id: [0],
      examCategory: ["", Validators.required],
      examName:["", Validators.required],
      subjectName:["", Validators.required]
    });
    this.getCategoryDropDown();
    this.getSubjectList(0);
  }

  getCategoryDropDown() {
    this.service.getAllCatefgoryDropDown().subscribe((data) => {
      this.categorydropDown = data;
    });
  }

  getSubjectList(itemId){
    this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
      data = data.filter((item) => {
        return item.isDeleted==false;
      });
      console.log(data);
      this.subjects = data;
    });
  }
  onCategoryEvent() {
    this.examWiseAttendenceForm.value.examName = "";
    this.finalexamNamedropDown = [];
    this.examNamedropDown = [];
    this.getNameDropDown();
  }
  getNameDropDown() {
    this.service.getExamNameList().subscribe((data) => {
      this.examNamedropDown = data;
      this.finalexamNamedropDown = this.examNamedropDown.filter(
        (filterList: any) =>
          filterList.examCategoryId ===
          this.examWiseAttendenceForm.value.examCategory
      );
    });
  }
  // submitForm() {
  //   this.submitted=true;
  // }
  get f() {
    return this.examWiseAttendenceForm.controls;
  }
  getClassDropDown()
  {

  }
  reset(){
    this.submitted=false;
    this.examWiseAttendenceForm.reset();
    this.categorydropDown=[];
    this.finalexamNamedropDown=[];
    this.getCategoryDropDown();
    this.resetStudent();
    this.examWiseAttendanceList=[];
    this.examWiseAttendenceForm = this.fb.group({
      id: [0],
      examCategory: ["", Validators.required],
      examName:["", Validators.required],
      subjectName:["", Validators.required]
    });

  }
  getClass(){
    this.submitted=true;
    if (this.examWiseAttendenceForm.invalid) {
      this.toastr.warning("All field are required", "WARNING!!!", {
        timeOut: 3000,
      });
      return;
    }
    //debugger;
    let details={
      ExamCategoryId:this.examWiseAttendenceForm.value.examCategory,
      ExamId:this.examWiseAttendenceForm.value.examName,
      SubjectId:(this.examWiseAttendenceForm.value.subjectName=="" || this.examWiseAttendenceForm.value.subjectName==null)?0:this.examWiseAttendenceForm.value.subjectName
    };
  this.service.getClassList(details).subscribe((data) => {
    this.examWiseAttendanceList=data;
    this.selectedRow=null;
    this.selectedclass="";
    this.resetStudent();
  });
  }

  resetStudent(){
    this.examWiseAttendanceStudentList=[];
    this.attendenceCount=0;
    this.absentCount=0;    
  }
  getExamWiseStudent(d:any, i:number){
    this.selectedRow=i;
    let details={
      ClassID:d.classId,
      BatchId:d.batch,
      SubjectId:d.subjectId,
      ExamId:this.examWiseAttendenceForm.value.examName
    };
    this.selectedclass=d.className;
  this.service.getExamwiseStudentList(details).subscribe((data) => {
    
    this.examWiseAttendanceStudentList=data;
    
    let result=this.examWiseAttendanceStudentList.filter((x:any)=> x.attendance=='P');
    if(result.length>0){
      this.attendenceCount=result.length
    }else{
      this.attendenceCount=0;
    }

    let result1=this.examWiseAttendanceStudentList.filter((x:any)=> x.attendance=='A');
    if(result1.length>0){
      this.absentCount=result1.length
    }else{
      this.absentCount=0;
    }
  });
  }
}
