import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubjectcreationComponent } from './subjectcreation/subjectcreation.component';
import { GradescaleComponent } from './gradescale/gradescale.component';
import { AssignsubjectComponent } from './assignsubject/assignsubject.component';
import {AssignbookComponent} from './assignbook/assignbook.component';
import { EvalutiontypeComponent } from './evalutiontype/evalutiontype.component';

const routes: Routes = [
  {path: 'academicsettings/createsubject', component: SubjectcreationComponent},
  {path: 'academicsettings/gradescale', component: GradescaleComponent},
  {path: 'academicsettings/assignsubject', component: AssignsubjectComponent},
  {path: 'academicsettings/assignbook', component: AssignbookComponent},
  {path:'academicsettings/Evalutiontype',component:EvalutiontypeComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AcademicSettingsRoutingModule { }
