import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ExamPlannerService} from '../../services/exam-planner.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ExamPlanner } from '../../ExamSetting/models/exam-planner.model';
import { CbseWeightage } from '../../ExamSetting/models/cbse-weightage.model';
 

declare var $: any;
@Component({
  selector: 'app-exam-planner',
  templateUrl: './exam-planner.component.html',
  styleUrls: [
    './exam-planner.component.css']
})
export class ExamPlannerComponent implements OnInit {
  
   
   
  checkboxs: any;
  custom_chk: any;
  
  startDate: Date;
  endDate: Date;
  cbseWeightage=new CbseWeightage(0,null,0,null,0,null,null);
  examPlannerForm:UntypedFormGroup;
  criterias=[];
  categories=[];
  dropDown:any=[];
  submitted = false;
  classBatachData: any;
  getExamPlaneerList: any = [];
  tempList: any = [];
  gridListCount: any;
  serachText: any;

 
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
   
  constructor(public fb: UntypedFormBuilder,public service: ExamPlannerService,
    private toastr: ToastrService) {  
    }

  get f() { return this.examPlannerForm.controls; }

  ngOnInit(): void {
 

    $('#startDate').datepicker().on('changeDate', (e) => {
      // var dateParts = this.formatDate(e.date).split('-');
      this.examPlannerForm.get('startDate').patchValue(e.date);
      });

      
  
      $('#endDate').datepicker().on('changeDate', (e) => {
        // var dateParts = this.formatDate(e.date).split('-');
        this.examPlannerForm.get('endDate').patchValue(e.date);
      });

    this.examPlannerForm=this.fb.group({
      id:[0],
      examCategoryId:['',Validators.required],
      orderNumber:['',Validators.required],
      examName:['',Validators.required],
      startDate:['',Validators.required],
      endDate:['',Validators.required],
      status:[true,Validators.required],
      isEntrance: [false,[]],
      classBatch: this.fb.array([])
    }, {
      validator:  this.validateDate('startDate', 'endDate')
  });
    console.log(this.examPlannerForm)
    ///this.setForm();
      this.getAllCbseDropDown();  
      this.getClassBatch();
      this.getGridList();
  }



  validateDate(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
  
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
  
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }
  
      console.log(Date.parse(control.value));
      console.log(Date.parse(matchingControl.value));
      // set error on matchingControl if validation fails
      if (Date.parse(control.value) > Date.parse(matchingControl.value)) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
    };
    }
  setForm(dropDownOption:any=[]){
    this.criterias=dropDownOption.weightageContents;
    this.categories=dropDownOption.examCategories;    
  }

  getAllCbseDropDown(){
    this.service.getAllCbseDropDown().subscribe(data=>{
      this.dropDown=data;
      this.setForm(this.dropDown);
    })
  }
  getClassBatch() {
    this.service.getAllClassBatch().subscribe(data=>{
      this.classBatachData=data;
      for(let m of this.classBatachData){
        m.isChecked = false;
      }
      console.log(this.classBatachData)
    })
  }
  getGridList() {
    this.service.getExamPlannerList().subscribe(data=>{
      this.getExamPlaneerList=  this.tempList =data;
      this.gridListCount =this.getExamPlaneerList.length;
      let tempClass=[];
      for(let m of this.getExamPlaneerList){
        for(let x of m.assignToClass){
          tempClass.push(x.assignClasses)
          m.assignClass = tempClass.toString();
        
        }
        tempClass=[];
      }
      console.log(this.getExamPlaneerList)
    })
  }
  resetForm(){
    console.log(this.examPlannerForm.value)
    this.submitted = false;
    this.examPlannerForm=this.fb.group({
      id:[0],
      examCategoryId:['',Validators.required],
      orderNumber:['',Validators.required],
      examName:['',Validators.required],
      startDate:['',Validators.required],
      endDate:['',Validators.required],
      status:[true,Validators.required],
      isEntrance: [false,[]],
      classBatch: new UntypedFormArray([])
    }, {
      validator:  this.validateDate('startDate', 'endDate')
  });
    const formArray: UntypedFormArray = this.examPlannerForm.get('classBatch') as UntypedFormArray;
    formArray.reset();
    this.getClassBatch();
  }
  private formatDate(date) {
    const d = new Date(date);
    console.log("Date is "+d);
    
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    
    console.log([day, Number(month), Number(year)].join('-'));

    return [Number(year), Number(month),day].join('-');
 
  }
  formatSQLDate(date: any) {
    if (date !== '' && date !== null && date !== undefined) {
      const d = new Date(date);
      console.log("Date is "+d);

      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) { month = '0' + month; }
      if (day.length < 2) { day = '0' + day; }
       
      console.log([year,  Number(month), Number(day)].join('-'));

      return [year,  Number(month), Number(day)].join('-');
      
      
    } else {
      return null;
    }
  }
   convert(str) {
    var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
     
    //console.log("Date is "+date);
    return [date.getFullYear(), mnth, day].join("-");

   
    return date;
  }
  submitForm() {
    console.log(this.examPlannerForm.value)
    if(this.examPlannerForm.invalid){
      this.toastr.warning('All field are required','WARNING!!!',{timeOut:3000});
      return;
    }
    this.submitted = true;
    if (this.examPlannerForm.invalid) {
      if (this.f.endDate.errors.mustMatch) {
        this.toastr.warning('End date should not less than start date ', 'WARNING!!!', {
          timeOut: 3000
        });
      }
      return;
    }
    if (!this.examPlannerForm.value.classBatch || !this.examPlannerForm.value.classBatch.length) {
      this.toastr.warning('Select Atleast one classs', 'WARNING!!!', {
        timeOut: 3000
      });
      return;
    }
    if(this.examPlannerForm.get('id').value===0)
    {      
        this.service.createexamplanner(this.examPlannerForm.value).subscribe(res=>{      
        console.log(res)          
        this.toastr.success("Successfully created","SUCCESS!",{timeOut:3000});
        this.submitted=false;
        this.getGridList();
        this.resetForm();
      
      });    
    }
    else{
      console.log(this.examPlannerForm.value)   
       
      console.log("Update Records: "+ this.examPlannerForm.value.endDate);

       this.examPlannerForm.value.endDate = new Date(this.examPlannerForm.value.endDate).toUTCString();
       this.examPlannerForm.value.startDate = new Date(this.examPlannerForm.value.startDate).toUTCString();

      console.log("Updated after Records: "+ this.examPlannerForm.value.endDate);

      this.service.updateexamplanner(this.examPlannerForm.get('id').value,this.examPlannerForm.value).subscribe(res=>{      
        console.log(res)          
        this.toastr.success("Exam Planner successfully updated","SUCCESS!",{timeOut:3000});
        // this.submitted=false;
        this.getGridList();
      });    
    }
  }
  get classBatch() {
    return this.examPlannerForm.get("classBatch") as UntypedFormArray;
  }
  assignclassBatch(item: any,data) {
    console.log(item.currentTarget.checked)
    if(item.currentTarget.checked){
      this.classBatch.push(this.fb.group({ id: 0, examPlannerId: 0,batchId:data.id }))
    } else {
      const formArray: UntypedFormArray = this.examPlannerForm.get('classBatch') as UntypedFormArray;
      let i: number = 0;

      formArray.controls.forEach((ctrl: UntypedFormControl) => {
        if(ctrl.value.batchId == data.id) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
  
        i++;
      });
    }
    
    
  }

  selectAll(ev:any,item)
  {
     this.checkboxs = document.querySelectorAll('input[type="checkbox"]');
     this.custom_chk = document.getElementsByClassName('custom_chk');
    
     if(item.checked)
     {
        for(var i =0; i<this.custom_chk.length;i++ )
          {
            this.custom_chk[i].checked=false;
            item.checked=false;
            this.assignclassBatch(ev,this.custom_chk[i]);
          }
     } 
     else 
     {
        for(var i =0; i<this.custom_chk.length;i++ )
          {
            this.custom_chk[i].checked=true;
            item.checked=true;
            this.assignclassBatch(ev,this.custom_chk[i]);
          }
    }
  }

 


  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.getExamPlaneerList = this.tempList;
      this.gridListCount= this.getExamPlaneerList.length;
    } else {
    this.getExamPlaneerList = this.tempList
                                .filter((filterList: any) => filterList.examName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
    this.gridListCount=this.getExamPlaneerList.length;
    }
  }
  deleteExamplanner(id:number){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton:true,
      confirmButtonText:'Yes, delete it!',
      cancelButtonText:'No, keep it'
    }).then((result)=>{
      if(result.value){
        this.service.deleteExamplanner(id).subscribe(data=>{
          Swal.fire(
            'Deleted!',
            'Exam Planner Record has been deleted.',
            'success'
          );
          this.getGridList();
        })
      }
    })
  }
  editValue: any =[];
  editExamplan(id:number){
    this.classBatachData.forEach(y => {
      y.isChecked= false;
    });
    this.service.getExamPlannerById(id).subscribe((data: any)=>{
      this.editValue = data;
      console.log(data)
      this.examPlannerForm=this.fb.group({
        id:[data.id],
        examCategoryId:[data.examCategoryId,Validators.required],
        orderNumber:[data.orderNumber,Validators.required],
        examName:[data.examName,Validators.required],
        startDate:[this.formatDate(data.startDate),Validators.required],
        endDate:[this.formatDate(data.endDate),Validators.required],
        academicYearId: [data.academicYearId,[]],
        status:[data.status,Validators.required],
        isEntrance: [data.isEntrance,[]],
        classBatch: this.BindArr()
      }, {
        validator:  this.validateDate('startDate', 'endDate')
    });
    $('#startDate').datepicker('update', this.formatDate(data.startDate));
    $('#endDate').datepicker('update', this.formatDate(data.endDate));
    console.log(this.examPlannerForm.value)
    if(data.assignToClass){
      for(let m of data.assignToClass){
        this.classBatachData.find(obj=>obj.id==m.batchId).isChecked= true;
      }
    }
    })
  
  }
  BindArr() {
    console.log( this.editValue)
    let arr = new UntypedFormArray([])
    if(this.editValue.assignToClass){
      this.editValue.assignToClass.forEach(y => {
        arr.push(this.fb.group({
          id: y.id,
          examPlannerId: y.examPlannerId,
          batchId: y.batchId
        }))
  
    })
    return  arr;
    }
    
  }
  
}
