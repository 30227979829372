import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SubjectcreationService } from './subjectcreation.service';
import { SubjectCreation } from './subjectcreation.model';
import { from } from 'rxjs';
import { Term } from '../../mastersettings//models/Term';

@Component({
  selector: 'app-subjectcreation',
  templateUrl: './subjectcreation.component.html',
  styleUrls: ['./subjectcreation.component.css']
})
export class SubjectcreationComponent implements OnInit {

  subjectForm: UntypedFormGroup;
  // classcourses: ClassCourse [];
  terms: Term[];
  // streams: Stream [];
  subjects: SubjectCreation[] = [];
  subjectFilteredList: SubjectCreation[] = [];
  submitted = false;
  HighlightRow : Number;
  ClickedRow:any;

  constructor(
    // private classcourseService: ClassCourseService,
    // private termService: TermService,
    // private streamService: StreamService,
    public subjectService: SubjectcreationService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  )  

{ 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }

  }

  ListSubjects() {
    this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
      this.subjectFilteredList = this.subjects = data;
      console.log(this.subjectFilteredList);
    });
  }
  // ListClass() {
  //   this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
  //     console.log(data);
  //     this.classcourses = data;
  //   });
  // }
  // ListTerm(){
  //   this.termService.getAll().subscribe((data:Term[])=>{
  // console.log(data);
  // this.terms=data;
  //   });
  // }
  // ListStream(){
  //   this.streamService.getAll().subscribe((data:Stream[])=>{
  //     console.log(data);
  //     this.streams=data;
  //   })
  // }
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === '') {
      this.subjectFilteredList = this.subjects;
    } else {
      this.subjectFilteredList = this.subjects
        .filter((subjects: SubjectCreation) => subjects.subjectName.toLocaleLowerCase()
          .includes(keyword.toLocaleLowerCase()));
    }
  }


  get totalRows(): number {
    return this.subjectFilteredList.length;
  }
  get f() { return this.subjectForm.controls; }
  resetForm() {
    this.submitted = false;
    this.subjectForm = this.fb.group({
      id: [0],
      SubjectCode: ['', Validators.required],
      SubjectName: ['', Validators.required],
      SubjectDescription: ['', Validators.required],
      status: ['true', Validators.required]
    });
  }

  submitForm() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.subjectForm.invalid) {
      return;
    }

    if (this.subjectForm.get('id').value === 0) {
      console.log(this.subjectForm.value);
      this.subjectService.create(this.subjectForm.value).subscribe(res => {
        console.log('subject created!');
        this.ListSubjects();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    } else {
      this.subjectService.update(this.subjectForm.get('id').value, this.subjectForm.value).subscribe(res => {
        console.log('subject updated!');
        this.ListSubjects();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        // this.casteForm.reset();
      });
    }
  }

  updateForm(id: number) {
    this.subjectService.getById(id).subscribe((data: SubjectCreation) => {
      console.log(data);
      this.subjectForm = this.fb.group({
        id: [data.id],
        // ClassCourseId: [data.classCourseId.toString(), Validators.required],
        // TermId: [data.termId.toString(), Validators.required],
        //  StreamId: [data.streamId.toString(), Validators.required],
        //  BookId: [data.bookId, Validators.required],
        SubjectCode: [data.subjectCode, Validators.required],
        SubjectName: [data.subjectName, Validators.required],
        SubjectDescription: [data.subjectDescription, Validators.required],
        status: [data.status.toString(), Validators.required]
      });
    });

  }

  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.subjectService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListSubjects();
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }

  ngOnInit(): void {
    this.ListSubjects();
    // this.ListClass();
    // this.ListTerm();
    // this.ListStream();
    this.subjectForm = this.fb.group({
      id: [0],
      // ClassCourseId:['',Validators.required],
      // TermId:['',Validators.required],
      // StreamId: ['', Validators.required],
      SubjectCode: ['', Validators.required],
      SubjectName: ['', Validators.required],
      SubjectDescription: ['', Validators.required],
      // BookId: ['', Validators.required],
      status: ['true', Validators.required]
    });
  }

}



