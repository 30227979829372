import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import {  throwError, Observable } from 'rxjs';
import { CoursePlannerVM } from './model/CoursePlannerVM';
import { ChapterTopicPlanner } from '../course-chapter-planner/model/ChapterTopicPlanner';
import { Institution } from 'src/app/edumaq/billingmanagement/models/institutions';
import { AuthenticationService } from 'src/app/authentication/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class CoursePlannerService {
private httpClientForUpload:HttpClient;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'

    })
  };
    httpOptionsForupload = {
    headers: new HttpHeaders({
    //'Content-Type': 'application/form-data',
      //'Content-Type': 'application/json'
      'InstitutionCode': this.authService.institutionCode
    })
  };

  constructor(private httpClient: HttpClient, private handler:HttpBackend, private  authService: AuthenticationService) { 
    this.httpClientForUpload=new HttpClient(handler);
  }

  GetClass(): Observable<any> {
    const url = '/Class/GetAllClass';
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  GetLanguage(): Observable<any> {
    const url = '/Language/GetAllLanguage';
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllBatchByClass(param): Observable<any> {
    ;
    const url = '/Batch/GetAllBatchByClassID?ID='+param;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }



  

  GetSubject(): Observable<any> {
    const url = '/Subject/GetAllSubjects';
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  GetAllSubjectByBatchID(param): Observable<any> {
    ;
    const url = '/Subject/GetAllSubjectsByBatch?ID='+param;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllSubjectByClass(param): Observable<any> {
    ;
    const url = '/Subject/getAllSubjectByClass?ID='+param;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  

  GetAllSubject(): Observable<any> {
    ;
    const url = '/Subject/GetAllSubject';
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  


  SaveCoursePlan(param): Observable<CoursePlannerVM> {
    

    const url = '/CoursePlanner/Create';

    return this.httpClient.post<CoursePlannerVM>(environment.apiUrl + url, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  SaveChapterTopic(param): Observable<CoursePlannerVM> {
    

    const url = '/ChapterTopicPlanner/Create';
    ;

    return this.httpClient.post<CoursePlannerVM>(environment.apiUrl + url, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  

  
  UpdateCoursePlan(param): Observable<CoursePlannerVM> {
    ;
    const url = '/CoursePlanner/Update';

    ;
    return this.httpClient.put<CoursePlannerVM>(environment.apiUrl + url+"?Id="+param.Id, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  UpdateTopicPlanner(param): Observable<ChapterTopicPlanner> {
    ;
    const url = '/ChapterTopicPlanner/Update';

    ;
    return this.httpClient.put<ChapterTopicPlanner>(environment.apiUrl + url+"?Id="+param.Id, JSON.stringify(param), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  

  getAllCoursePlanByClass(param): Observable<any> {
    ;
    const url = '/CoursePlanner/GetByClassID?ClassId='+param;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllCoursePlanByClassBatch(param,batch): Observable<any> {
    ;
    const url = '/CoursePlanner/GetByClassBatch?ClassId='+param+ '&BatchId='+batch;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getAllCoursePlanByClassBatchSubject(param,batch,subject): Observable<any> {
    ;
    const url = '/CoursePlanner/GetByClassBatchSubject?ClassId='+param+'&BatchId='+batch+'&SubjectId='+subject;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllCoursePlanByClassSubject(param,subject): Observable<any> {
    ;
    const url = '/CoursePlanner/GetByClassSubject?ClassId='+param+'&SubjectId='+subject;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  getAllTopicChapterPlanByClass(param): Observable<any> {
    ;
    const url = '/ChapterTopicPlanner/GetByClass?ClassId='+param;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllTopicChapterPlanByClassSubject(param,subject): Observable<any> {
    ;
    const url = '/ChapterTopicPlanner/GetByClassSubject?ClassId='+param+'&SubjectId='+subject;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  getAllTopicChapterPlanByClassSubjectCourse(param,subject,course): Observable<any> {
    ;
    const url = '/ChapterTopicPlanner/GetByClassSubjectCourse?ClassId='+param+'&SubjectId='+subject+'&CourseId='+course;
    return this.httpClient.get<[]>(environment.apiUrl + url)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  deleteTopicPlan(id) {
    ;
    return this.httpClient.delete<CoursePlannerVM>(environment.apiUrl + '/ChapterTopicPlanner/DeleteTopicPlan?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  


  update(id, academicyear): Observable<CoursePlannerVM> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<CoursePlannerVM>(environment.apiUrl + '/CoursePlanner/' + id, JSON.stringify(academicyear), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    ;
    return this.httpClient.delete<CoursePlannerVM>(environment.apiUrl + '/CoursePlanner/DeleteCoursePlan?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  
  getById(id) {
    ;
    return this.httpClient.get<CoursePlannerVM>(environment.apiUrl + '/CoursePlanner/GetByCoursePlanId?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  getTopicPlanById(id) {
    ;
    return this.httpClient.get<ChapterTopicPlanner>(environment.apiUrl + '/ChapterTopicPlanner/GetTopicPlanByID?Id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  UploadPhoto(image, id) {
    return this.httpClientForUpload.post(environment.apiUrl + '/CoursePlanner/Upload?id='+id,<FormData>image, this.httpOptionsForupload)

    .pipe(
      catchError(this.errorHandler)
    ).toPromise();
  }

  UploadPhotoCoverImage(image, id) {
    debugger;
    return this.httpClientForUpload.post(environment.apiUrl + '/CoursePlanner/UploadCoverImage?id='+id,<FormData>image,this.httpOptionsForupload)
    .pipe(
      catchError(this.errorHandler)
    ).toPromise();
  }
  

  

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    ;
    console.log(errorMessage);
    return throwError(errorMessage);
 }

}
