// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    appearance: none;
    margin: 0; 
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
} 

.table-success
{
  font-weight: bold;
}

a.disabled {
    pointer-events: none;
    cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/academic/feemanagement/feecollectionold/feecollectionold.component.css"],"names":[],"mappings":"AAAA;;IAEI,wBAAwB;IAExB,gBAAgB;IAChB,SAAS;AACb;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;IACI,oBAAoB;IACpB,eAAe;AACnB","sourcesContent":["input[type=number]::-webkit-inner-spin-button, \r\ninput[type=number]::-webkit-outer-spin-button { \r\n    -webkit-appearance: none;\r\n    -moz-appearance: none;\r\n    appearance: none;\r\n    margin: 0; \r\n}\r\n\r\n/* Firefox */\r\ninput[type=number] {\r\n  -moz-appearance: textfield;\r\n} \r\n\r\n.table-success\r\n{\r\n  font-weight: bold;\r\n}\r\n\r\na.disabled {\r\n    pointer-events: none;\r\n    cursor: default;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
