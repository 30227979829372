import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudentattendanceComponent } from './studentattendance/studentattendance.component';
import { AttendancereportComponent } from './attendancereport/attendancereport.component';
import { StudentwisereportComponent } from './studentwisereport/studentwisereport.component';
import { AttendanceRoutingModule } from './attendance.routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AttendanceregisterComponent } from './attendanceregister/attendanceregister.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [StudentattendanceComponent, AttendancereportComponent, StudentwisereportComponent, AttendanceregisterComponent],
  imports: [
    CommonModule,
    FormsModule,
    AttendanceRoutingModule,
    SharedModule
  ]
})
export class AttendanceModule { }
