import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TemplatemanagerComponent } from './configuration/templatemanager/templatemanager.component';
import { CircularmanagerComponent} from './circularmanager/circularmanager.component';
import { CreditmanagerComponent } from './creditmanager/creditmanager.component';
import { BroadcastComponent } from './broadcast/broadcast.component';
import { CircularReportComponent } from './report/circularreport/circularreport.component';
import { CreditHistoryComponent } from './report/credit-history/credit-history.component';
import { BroadcastReportComponent } from './report/broadcast-report/broadcast-report.component';
import { UsesReportComponent } from './report/uses-report/uses-report.component';
import { SenderIDRequestComponent } from './configuration/sender-idrequest/sender-idrequest.component';
import { ActivitylogComponent } from './activitylog/activitylog.component';
import { AppbroadcastComponent } from './appbroadcast/appbroadcast.component';
import { AppbroadcastreportComponent } from './report/appbroadcastreport/appbroadcastreport.component';

const routes: Routes = [
  {path: 'config/template', component: TemplatemanagerComponent},
  {path: 'config/senderidrequest', component: SenderIDRequestComponent},
  {path: 'circular', component: CircularmanagerComponent},
  {path: 'credit', component: CreditmanagerComponent},
  {path: 'broadcast', component: BroadcastComponent},
  {path: 'appbroadcast', component: AppbroadcastComponent},
  {path: 'activitylog', component: ActivitylogComponent},
  {path: 'report/circularreport', component: CircularReportComponent},
  {path: 'report/credithistory', component: CreditHistoryComponent},
  {path: 'report/broadcastreport', component: BroadcastReportComponent},
  {path: 'report/appbroadcastreport', component: AppbroadcastreportComponent},
  {path: 'report/usesreport', component: UsesReportComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommunicationRoutingModule { }
