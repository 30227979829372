import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WeekdayService } from './weekday.service';
import { WeekDay} from './weekday.model';
import { Shift } from '../period/period.model';
import { ShiftService } from '../period/shift.service';

@Component({
  selector: 'app-weekday',
  templateUrl: './weekday.component.html',
  styleUrls: ['./weekday.component.css']
})
export class WeekdayComponent implements OnInit {
  HighlightRow : Number;
  ClickedRow:any;
  weekdays: WeekDay[] = [];
  shifts: Shift[] = [];
  weekdayForm: UntypedFormGroup;
  weekdayFilteredList: WeekDay[] = [];
  weekdayNameList: string[] = [];
  weekdayNames = '';
  chkSunday = false; chkMonday = false; chkTuesday = false;
  chkWednesday = false; chkThursday = false; chkFriday = false; chkSaturday = false;
  submitted = false;

  constructor(
    private weekdayService: WeekdayService,
    private shiftService: ShiftService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }

  }


  ListWeekdays() {
    this.weekdayService.getAll().subscribe((data: WeekDay[]) => {
      console.log(data);
      this.weekdayFilteredList = this.weekdays = data;
    });
  }

  ListShifts() {
    this.shiftService.getAll().subscribe((data: Shift[]) => {
      this.shifts = data;
    });
  }

  onCheckedChange(event: any, value: any) {
    if (event.target.checked) {
      this.weekdayNameList.push(value);
    } else {
      const ind = this.weekdayNameList.indexOf(value);
      this.weekdayNameList.splice(ind, 2);
    }
   
  }

//   onKeyUpEvent(event: any) {
//     this.filter(event.target.value);
//   }

// filter(keyword: any) {
//   if ( keyword === '') {
//     this.weekdayFilteredList = this.weekdays;
//   } else {
//   this.weekdayFilteredList = this.weekdays
//                               .filter((weekday: WeekDay) => weekday.weekdays.toLocaleLowerCase()
//                               .includes(keyword.toLocaleLowerCase()));
//   }
// }


get totalRows(): number {
  return this.weekdayFilteredList.length;
}
get f() { return this.weekdayForm.controls; }
resetForm() {
  this.submitted = false;
  this.weekdayForm = this.fb.group({
    id: [0],
    shifId: ['0', Validators.required],
    weekDayList: ['', Validators.required],
    status: ['true', Validators.required]
  });
  this.weekdayNameList = [];
  this.chkSunday = false; this.chkMonday = false; this.chkTuesday = false;
  this.chkWednesday = false; this.chkThursday = false; this.chkFriday = false; this.chkSaturday = false;
}

submitForm() {
  
  this.submitted = true;
  this.weekdayNames = '';
  this.weekdayNameList.forEach(x => {
    this.weekdayNames += x + '|';
  });

  this.weekdayForm.get('weekDayList').patchValue(this.weekdayNames);
      // stop here if form is invalid
  if (this.weekdayForm.invalid) {
         this.toastr.error('All fields are Required.');
          return;
    }

  if (this.weekdayForm.get('id').value === 0) {
      console.log(this.weekdayForm.value);
      this.weekdayService.create(this.weekdayForm.value).subscribe(res => {
        console.log('Caste created!');
        this.ListWeekdays();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.weekdayService.update(this.weekdayForm.get('id').value, this.weekdayForm.value).subscribe(res => {
          console.log('Caste updated!');
          this.ListWeekdays();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
          //this.casteForm.reset();
        });
      }


    }

    updateForm(id: number) {
      this.weekdayNameList = [];
      this.weekdayService.getById(id).subscribe((data: WeekDay) => {
        console.log(data);
        this.weekdayNameList = data.weekDayList.split('|');
        this.weekdayNameList.forEach(x => {
          switch (x) {
          case 'Sunday': this.chkSunday = true; break;
          case 'Monday': this.chkMonday = true; break;
          case 'Tuesday': this.chkTuesday = true; break;
          case 'Wednesday': this.chkWednesday = true; break;
          case 'Thursday': this.chkThursday = true; break;
          case 'Friday': this.chkFriday = true; break;
          case 'Saturday': this.chkSaturday = true; break;
          }
        });
        this.weekdayForm = this.fb.group({
          id: [data.id],
          shifId: [data.shifId.toString(), Validators.required],
          weekDayList: [data.weekDayList, Validators.required],
          status: [data.status.toString(), Validators.required]
        });
      });

    }

    deleteForm(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.weekdayService.delete(id).subscribe(res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            this.ListWeekdays();
          });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
        }
      });
    }

  ngOnInit(): void {
    this.ListWeekdays();
    this.ListShifts();
    this.weekdayForm = this.fb.group({
      id: [0],
      shifId: ['0', Validators.required],
      weekDayList: ['', Validators.required],
      status: ['true', Validators.required]
    });
  }

}
