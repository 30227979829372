import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {ClassWork} from './classwork.model';

@Injectable({
    providedIn: 'root'
  })
  export class ClassworkService {
  
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //enctype: 'multipart/form-data'
      })
    };
    httpOptionsC = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'enc-type': 'multipart/form-data'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<ClassWork[]> {
      return this.httpClient.get<ClassWork[]>(environment.apiUrl + '/classwork/', this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<ClassWork> {
      return this.httpClient.get<ClassWork>(environment.apiUrl + '/classwork/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getDetails(id): Observable<ClassWork> {
      return this.httpClient.get<ClassWork>(environment.apiUrl + '/classwork/GetClassworkDetails/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    public downloadFile(id: number, docFile: string): Observable < Blob > {
      return this.httpClient.get(environment.apiUrl  + '/classwork/GetFile/' + id + '/'  + docFile, {
          responseType: 'blob'
      });
    }

  /*   create(classwork): Observable<any> {
      return this.httpClient.post(environment.apiUrl + '/classwork/', classwork, {responseType: 'text'})
      .pipe(
        catchError(this.errorHandler)
      );
    } */
    create(classwork): Observable<ClassWork> {
      return this.httpClient.post<ClassWork>(environment.apiUrl + '/classwork/', JSON.stringify(classwork))
      .pipe(
        catchError(this.errorHandler)
      );
    }

   /*  update(id, classwork): Observable<any> {
      return this.httpClient.put(environment.apiUrl + '/classwork/' + id, classwork, {responseType: 'text'})
      .pipe(
        catchError(this.errorHandler)
      );
    } */
    update(id, classwork): Observable<ClassWork> {
      return this.httpClient.put<ClassWork>(environment.apiUrl + '/classwork/' + id, JSON.stringify(classwork),this.httpOptionsC)
      .pipe(
        catchError(this.errorHandler)
      );
    }


    // update(id, assignment): Observable<ClassWork> {
    //   // tslint:disable-next-line:max-line-length
    //   return this.httpClient.put<ClassWork>(environment.apiUrl + '/classwork/' + id, JSON.stringify(assignment), this.httpOptions)
    //   .pipe(
    //     catchError(this.errorHandler)
    //   );
    // }
  
    delete(id) {
      return this.httpClient.delete<ClassWork>(environment.apiUrl + '/classwork/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }
  
  
  }