import { Component, OnInit } from '@angular/core';
//import { dateSelectionJoinTransformer } from '@fullcalendar/angular';
import { ToastrService } from 'ngx-toastr';
import { Circular } from '../../circularmanager/circular.model';
import { CircularService } from '../../circularmanager/circular.service';
import { TemplateType } from '../../configuration/templatemanager/template.model';
import { TemplateService } from '../../configuration/templatemanager/template.service';
declare var $: any;

@Component({
  selector: 'app-circularreport',
  templateUrl: './circularreport.component.html',
  styleUrls: ['./circularreport.component.css']
})
export class CircularReportComponent implements OnInit {
  circularList: Circular[] = [];
  circulars: Circular[] = [];
  circularOriginalList: Circular[] = [];
  circularTypes: TemplateType[] = [];
  selectedCircularType = '';
  selectedAudience = '';
  selectedFromDate: Date;
  selectedToDate: Date;
  defaultValue = '';

  constructor(
    public circularService: CircularService,
    public templateService: TemplateService,
    private toastr: ToastrService
  ) { }



  ListCircular() {
    this.circularService.getAll().subscribe((data: Circular[]) => {
      this.circularOriginalList = this.circulars = data;
      console.log(this.circularList);
    });
  }
  ListCircularType() {
    this.templateService.getAllTemplateType().subscribe((data: TemplateType[]) => {
      this.circularTypes = data;
      console.log(this.circularTypes);
    });
  }
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
      if (keyword === '') {
        this.circularList = this.circulars;
      } else {
        this.circularList = this.circulars
          .filter((circ: Circular) => circ.audience.toLocaleLowerCase() // filter column need to be changed - audience is used for testing
            .includes(keyword.toLocaleLowerCase())
          );
      }
  }
  filterReport() {
    this.circulars = this.circularOriginalList;
    if (this.selectedFromDate !== undefined && this.selectedAudience !== '' &&
      this.selectedToDate !== undefined && this.selectedCircularType !== '') {

        this.circularList  = this.circulars.filter(element => element.circularTypeId === this.selectedCircularType &&
                                                            element.audience === this.selectedAudience &&
                                                            new Date(element.createdDate) >= this.selectedFromDate &&
                                                            new Date(element.createdDate) <= this.selectedToDate);
        if (this.circularList.length === 0) {
        this.toastr.warning('Records not found!', 'Sorry', {
          timeOut: 4000
        });
        this.circularList = this.circulars;
      } else {
        this.toastr.warning('All fields are mandatory !', 'Sorry', {
          timeOut: 4000
        });
      }
  } else if (this.selectedAudience !== '' && this.selectedCircularType !== '' &&
    this.selectedToDate === undefined && this.selectedFromDate === undefined) {
    this.circularList  = this.circulars.filter(element => element.circularTypeId === this.selectedCircularType &&
      element.audience === this.selectedAudience);
  } else if (this.selectedAudience === '' && this.selectedCircularType === '' &&
    this.selectedToDate !== undefined && this.selectedFromDate !== undefined) {
    this.circularList  = this.circulars.filter(element => new Date(element.createdDate) >= this.selectedFromDate &&
    new Date(element.createdDate) <= this.selectedToDate);
  } else if (this.selectedAudience === '' && this.selectedCircularType !== undefined &&
    this.selectedToDate === undefined && this.selectedFromDate === undefined) {
    this.circularList  = this.circulars.filter(element => element.circularTypeId === this.selectedCircularType);
  }
}
  clearFilter() {
    this.selectedAudience = this.selectedCircularType = '';
    this.defaultValue = null;
    this.circularList = this.circulars;
    // console.log(" clear");
  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  ngOnInit(): void {
    this.ListCircular();
    this.ListCircularType();
    const dValue = new Date();
    this.defaultValue = this.formatDate(dValue.toString());
    $('#fromDate').datepicker().on('changeDate', (e) => {
      this.selectedFromDate =  new Date(e.date);
    });

    $('#toDate').datepicker().on('changeDate', (e) => {
      this.selectedToDate = new Date(e.date);
    });

  }
}
