import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {FloorService} from './floor.service';
import {CampusBuildingService} from '../campusbuilding/campusbuilding.service';
import { Floor } from '../models/floor';
import { CampusBuilding } from '../models/campusbuilding';

@Component({
  selector: 'app-floor',
  templateUrl: './floor.component.html',
  styleUrls: ['./floor.component.css']
})
export class FloorComponent implements OnInit {
  options = {
    autoClose: true,
    keepAfterRouteChange: false
};

floorForm: UntypedFormGroup;
floors: Floor[] = [];
floorFilteredList: Floor[] = [];
campusBuildings: CampusBuilding[] = [];
submitted = false;
ClickedRow:any;
HighlightRow:number;

  constructor(
    public floorService: FloorService,
    public campusBuildingService: CampusBuildingService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) 
  { 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }
  }

  ListFloor() {
    this.floorService.getAll().subscribe((data: Floor[]) => {
      console.log(data);
      this.floorFilteredList = this.floors = data;
    });
}

ListCampusBuilding() {
  this.campusBuildingService.getAll().subscribe((data: CampusBuilding[]) => {
    console.log(data);
    this.campusBuildings = data;
  });
}

onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

filter(keyword: any) {
  if ( keyword === '') {
    this.floorFilteredList = this.floors;
  } else {
  this.floorFilteredList = this.floors
                              .filter((floor: Floor) => floor.floorName.toLocaleLowerCase()
                              .includes(keyword.toLocaleLowerCase()));
  }
}

get totalRows(): number {
  return this.floorFilteredList.length;
}
get f() { return this.floorForm.controls; }
resetForm() {
  this.floorForm = this.fb.group({
    id: [0],
    floorName: ['', Validators.required],
    campusBuildingId: ['', Validators.required],
    status: ['true', Validators.required]
  });
}
submitForm() {
  this.submitted = true;

      // stop here if form is invalid
  if (this.floorForm.invalid) {
          return;
    }

  if (this.floorForm.get('id').value === 0) {
      this.floorService.create(this.floorForm.value).subscribe(res => {
        console.log('Country created!');
        this.ListFloor();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.floorService.update(this.floorForm.get('id').value, this.floorForm.value).subscribe(res => {
          console.log('Country updated!');
          this.ListFloor();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
      }


    }

    updateForm(id: number) {
      this.floorService.getById(id).subscribe((data: Floor) => {
        console.log(data);
        this.floorForm = this.fb.group({
          id: [data.id],
          floorName: [data.floorName, Validators.required],
          campusBuildingId: [data.campusBuildingId.toString(), Validators.required],
          status: [data.status.toString(), Validators.required]
        });
      });

    }

    deleteForm(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.floorService.delete(id).subscribe(res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            this.ListFloor();
          });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
        }
      });
    }

  ngOnInit(): void {
    this.ListFloor();
    this.ListCampusBuilding();
    this.floorForm = this.fb.group({
      id: [0],
      floorName: ['', Validators.required],
      campusBuildingId: ['', Validators.required],
      status: ['true', Validators.required]
    });
  }

}
