import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Student } from './../../studentmanagement/student.model';
import { House } from './../../studentmanagement/house.mode';
import { StudentService } from './../../studentmanagement/student.service';
import { SettingsService } from '../../mastersettings/settings.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { environment } from '../../../../../environments/environment';
import { ReportAttribute } from '../../studentmanagement/report.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
/// import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable.js';
//import { debugOutputAstAsTypeScript } from '@angular/compiler';
declare var $: any;

@Component({
  selector: 'app-updatestudentdetails',
  templateUrl: './updatestudentdetails.component.html',
  styleUrls: ['./updatestudentdetails.component.css']
})
export class UpdatestudentdetailsComponent implements OnInit {

  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  filterAdmissions: Student[] = [];
  houses: House[] = [];
  classId = '';
  batchId = '';
  currentAcademicYearId: number;
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  institution: any;

  isAdmissionNoEditable: boolean;
  isAdhaarEditable: boolean;
  isBloodGroupEditable: boolean;
  isEmailIdEditable: boolean;
  isHouseEditable: boolean;
  isWhatsAppNoEditable: boolean;
  isSMSNoEditable: boolean;
  isBioMetricCodeEditable: boolean;

  constructor(
    public settingService: SettingsService,
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    private toastr: ToastrService
  ) { }

  ListStudents() {
    this.studentService.getAllStudentList().subscribe((data: Student[]) => {
      data.forEach((x) => {
        x.imageUrl = x.imageUrl != null ? environment.imageUrl + x.imageUrl : null;
      });
      this.admissions = data;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }

  ListHouse() {
    this.studentService.getHouse().subscribe((data: House[]) => {
      console.log(data);
      this.houses = data;
    });
  }

  currentAcademicYear() {
    this.settingService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      // this.search();
    });
  }

  onClassSelected(value: string) {
    this.filterAdmissions = [];
    this.batches = [];
    this.batchId = '';
    this.classId = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
    });
  }

  onBatchChanged(value: string) {
    debugger;
    if (value === '0') {
      if (this.classId !== '0') {
        this.filterAdmissions = this.admissions.filter((student: Student) => {
          return student.classCourseId.toString() === this.classId &&
          student.academicYearId.toString() === this.currentAcademicYearId.toString();
          });
      } else {
        this.filterAdmissions = this.admissions;
      }
    } else {
    // this.studentService.getAllStudentList().subscribe((data: Student[]) => {
     // this.ListFeeHead();
     this.filterAdmissions = this.admissions.filter((student: Student) => {
      return student.academicYearId.toString() === this.currentAcademicYearId.toString() &&
      student.classCourseId.toString() === this.classId &&
        student.batchId.toString() === value.toString();
      });
    // });
    }
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  get totalRows(): number {
    return this.filterAdmissions.length;
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      this.filterAdmissions = this.admissions;
    } else {
    this.filterAdmissions = this.admissions
                                .filter((admission: Student) =>
                                admission.studentName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  validateEmail(email) {
    const regularExpression =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }

  updateStudentDetails(id: number) {
    const studDetail = this.filterAdmissions[id];
    if (studDetail.admissionNo !== '' && studDetail.admissionNo.length < 3) {
      this.toastr.warning('Please enter valid Admission Number', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }
    if (studDetail.adharNumber !== '' && studDetail.adharNumber.length !== 12) {
      this.toastr.warning('Please enter valid Adhar Number', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }

    if (studDetail.emailId !== '' && !this.validateEmail(studDetail.emailId)) {
      this.toastr.warning('Please enter valid Email', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }

    if (studDetail.whatsAppNo !== '' && studDetail.whatsAppNo.length !== 10) {
      this.toastr.warning('Please enter valid WhatsApp Number', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }

    if (studDetail.smsMobNumber !== '' && studDetail.smsMobNumber.length !== 10) {
      this.toastr.warning('Please enter valid SMS Number', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }

    // studDetail.admissionDate = this.formatDate(studDetail.admissionDate);
    // studDetail.dob = this.formatDate(studDetail.dob);
    this.studentService.updateFields(studDetail.id, studDetail).subscribe(res => {
      console.log('Student updated!');
      this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
        timeOut: 3000
      });
    });
  }

  generateAttributes() {
    this.attributes = [
      {name: 'S NO', fieldName: 'index' },
      {name: 'IMAGE', fieldName: 'imageUrl' },
      {name: 'ACADEMIC', fieldName: 'academicYearName' },
      {name: 'ADMISSION NO', fieldName: 'admissionNo' },
      {name: 'STUDENT NAME', fieldName: 'studentName' },
      {name: 'FATHERS NAME', fieldName: 'fatherName' },
      {name: 'CLASS', fieldName: 'className' },
      {name: 'BATCH', fieldName: 'batchName' },
      {name: 'ROLL NO', fieldName: 'rollNo' },
      {name: 'TYPE', fieldName: 'studentType' },
      {name: 'STATUS', fieldName: 'status' }
    ];
  }

  generateExcel() {
    this.headerList =[];
    this.fieldList =[];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('STUDENT SUMMARY');
    worksheet.addRow();
    if (this.institution.logoUrl !== null) {
      const imageId2 = workbook.addImage({
        base64: environment.imageUrl + this.institution.logoUrl,
        extension: 'jpg',
      });
      worksheet.addImage(imageId2, 'A1:B1');
    }

    const titleRow = worksheet.addRow(['STUDENT SUMMARY']);
    const headerRow = worksheet.addRow(this.headerList);
    for(var i = 0 ; i < this.filterAdmissions.length ; i++)
    this.filterAdmissions[i]["index"] = i+1;
    for (const x1 of this.filterAdmissions) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'admissionDate') {
          temp.push(this.formatDate(x1[y]));
        } else {
          temp.push(x1[y]);
        }
        // x2.forEach((e) => {
        //   if (e.toLocaleLowerCase() === y.toLocaleLowerCase()) {
        //   }
        // });
      }
      worksheet.addRow(temp);
    }
    const fname = 'STUDENT SUMMARY';

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }

  generatePDF() {
    ;
    console.log(this.institution);
    this.headerList = [];
    this.fieldList = [];
    const attributes2 = [
      {name: 'S NO', fieldName: 'index' },
      {name: 'ACADEMIC', fieldName: 'academicYearName' },
      {name: 'ADMISSION NO', fieldName: 'admissionNo' },
      {name: 'STUDENT NAME', fieldName: 'studentName' },
      {name: 'FATHERS NAME', fieldName: 'fatherName' },
      {name: 'CLASS', fieldName: 'className' },
      {name: 'BATCH', fieldName: 'batchName' },
      {name: 'ROLL NO', fieldName: 'rollNo' },
      {name: 'TYPE', fieldName: 'studentType' },
      {name: 'STATUS', fieldName: 'status' }
    ];
    attributes2.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    for (let i = 0; i < this.filterAdmissions.length; i++) {
      this.filterAdmissions[i]["index"] = i + 1;
      if (this.filterAdmissions[i]["studentType"] == undefined)
        this.filterAdmissions[i]["studentType"] = "";
    }
    const widthList = [];
    for (const item of this.headerList) {
        widthList.push('auto');
    }
    const docDefinition = {
      content: [
        {
            table: {
                widths: ['auto', '*'],
                body: [
                    [ {
                        width: 100,
                        height: 100,
                        image: 'imageKey'
                    }, {
                        width: '*',
                        alignment: 'center',
                        stack: [
                            {
                                style: 'h1',
                                text: this.institution.institutionName
                            },
                            {
                              style: 'h2',
                              text: this.institution.address
                            },
                            {
                                style: 'h2',
                                text: '2021-22'
                            },
                            {
                                style: 'h1',
                                text: 'STUDENT SUMMARY'
                            }
                        ]
                    }
                    ]
                ]
            },
            layout: {
                hLineWidth: (line) => line === 1,
                vLineWidth: () => 0,
                paddingBottom: () => 5
            },
          },
          {
            table: {
              headerRows: 1,
              widths: widthList,
              body: [
                this.headerList,
                ...this.filterAdmissions.map((p) => {
                  const temp = [];
                  for (const y of this.fieldList) {
                    if (y === 'admissionDate') {
                      temp.push(this.formatDate(p[y]));
                    } else {
                      temp.push(p[y]);
                    }
                  }
                  return temp;
                })
              ]
            }
          }
      ],
      images: {
         imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl :
         environment.imageUrl + '/images/avatar-1.png'
         // via URL address, which can be referenced by this key
      },
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 16,
          bold: true
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        }
      }
    };
    pdfMake.createPdf(docDefinition).download();
  }

  ngOnInit(): void {
    this.generateAttributes();
    this.currentAcademicYear();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.ListStudents();
    this.ListClassCourse();
    this.ListHouse();
  }

}
