import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {StreamService} from './stream.service';
import { Stream } from '../models/stream';

@Component({
  selector: 'app-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.css']
})
export class StreamComponent implements OnInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false
};

streamForm: UntypedFormGroup;
streams: Stream[] = [];
streamFilteredList: Stream[] = [];
submitted = false;
ClickedRow:any;
HighlightRow:number;

  constructor(
    public streamService: StreamService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  )  { 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }
  }

  ListStream() {
    this.streamService.getAll().subscribe((data: Stream[]) => {
      console.log(data);
      this.streamFilteredList = this.streams = data;
    });
}

onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

filter(keyword: any) {
  if ( keyword === '') {
    this.streamFilteredList = this.streams;
  } else {
  this.streamFilteredList = this.streams
                              .filter((stream: Stream) => stream.streamName.toLocaleLowerCase()
                              .includes(keyword.toLocaleLowerCase()));
  }
}

get totalRows(): number {
  return this.streamFilteredList.length;
}
get f() { return this.streamForm.controls; }
resetForm() {
  this.submitted = false;
  this.streamForm = this.fb.group({
    id: [0],
    streamName: ['', Validators.required],
    status: ['true', Validators.required]
  });
}
submitForm() {
  this.submitted = true;

      // stop here if form is invalid
  if (this.streamForm.invalid) {
          return;
    }

  if (this.streamForm.get('id').value === 0) {
      this.streamService.create(this.streamForm.value).subscribe(res => {
        console.log('Stream created!');
        this.ListStream();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.streamService.update(this.streamForm.get('id').value, this.streamForm.value).subscribe(res => {
          console.log('Stream updated!');
          this.ListStream();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
      }


    }

    updateForm(id: number) {
      this.streamService.getById(id).subscribe((data: Stream) => {
        console.log(data);
        this.streamForm = this.fb.group({
          id: [data.id],
          streamName: [data.streamName, Validators.required],
          status: [data.status.toString(), Validators.required]
        });
      });

    }

    deleteForm(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.streamService.delete(id).subscribe(res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            this.ListStream();
          });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
        }
      });
    }


  ngOnInit(): void {
    this.ListStream();
    this.streamForm = this.fb.group({
      id: [0],
      streamName: ['', Validators.required],
      status: ['true', Validators.required]
    });
  }

}
