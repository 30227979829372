import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StudentInstallmentSummaryModel} from './studentinstallmentsummary.model';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentInstallmentSummaryService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  
  getStudentDetails(formData): Observable<any> {
  return this.httpClient.post(environment.apiUrl + '/FeeCollection/GetStudentDetailsPaymentReport/' ,formData)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  // getStudentInstallmentSummary(formData): Observable<any> {
  //   ;
  // return this.httpClient.post(environment.apiUrl + '/FeeReport/GetStudentInstallmentSummary/' ,formData)
  //    .pipe(
  //      catchError(this.errorHandler)
  //    );
  //  }

   getStudentInstallmentSummary(formData): Observable<any> {
  debugger
    return this.httpClient.get<any>(environment.apiUrl + '/FeeReport/getStudentInstallmentSummary?StudentId='+formData.studentId+'&ClassId='+formData.classId+'&BatchId='+formData.batchId+'&AcademicYearId='+formData.academicYearId+'&AdmissionNo='+formData.admissionNumber)
     .pipe(
       catchError(this.errorHandler)
     );
   }
  
errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
