export class IcseWeightage {
    constructor(
        public id:number,
        public weightages:string,        
        public reportMarkTypeId:number,
        public reportMarkType:string,
        public examCategoryId:number,
        public examCategory:string,
        public ea:number,
        public ia:number,
        public coCurricular: string,
        public weightageStatus:boolean
        ){}
}
