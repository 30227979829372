// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a:hover{
    
    color: #ea2e2e;
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/dataandreports/analyticalreportings/analyticalreportings.component.css"],"names":[],"mappings":"AAAA;;IAEI,cAAc;IACd,iBAAiB;AACrB","sourcesContent":["a:hover{\r\n    \r\n    color: #ea2e2e;\r\n    font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
