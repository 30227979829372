import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Employeeadmission } from '../../../OperationActivity/hrmspayroll/hrms/models/employeeadmission.model';
import {AssignSubjectTeacher, AssignSubjectEmployee} from './assignsubteacher.model';

@Injectable({
  providedIn: 'root'
})
export class AssignSubjectTeacherService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<AssignSubjectTeacher[]> {
    return this.httpClient.get<AssignSubjectTeacher[]>(environment.apiUrl + '/AssignSubjectTeacher/GetAllAssignedSubjectTeachers')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<AssignSubjectTeacher> {
    return this.httpClient.get<AssignSubjectTeacher>(environment.apiUrl + '/AssignSubjectTeacher/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  create(batch): Observable<AssignSubjectTeacher> {
    ;
    return this.httpClient.post<AssignSubjectTeacher>(environment.apiUrl +
       '/AssignSubjectTeacher/', JSON.stringify(batch), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  } 

  update(id, batch): Observable<AssignSubjectTeacher> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<AssignSubjectTeacher>(environment.apiUrl + '/AssignSubjectTeacher/' + id, JSON.stringify(batch), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<AssignSubjectTeacher>(environment.apiUrl + '/AssignSubjectTeacher/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAllEmployees(): Observable<AssignSubjectEmployee[]> {
    return this.httpClient.get<AssignSubjectEmployee[]>(environment.apiUrl + '/AssignSubjectTeacher/GetAllAssignedEmployeeSubjects')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
