import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbAlertModule, NgbDateParserFormatter, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SessionPlannerComponent } from './session-planner/session-planner.component';
import { CoursePlannerComponent, CustomDateParserFormatter } from './course-planner/course-planner/course-planner.component';
import { OnlineClassReportComponent } from './online-class-report/online-class-report.component';
import { SessionSummaryComponent } from './session-summary/session-summary.component';
import { LMSSettingComponent } from './lmssetting/lmssetting.component';
import { CoursesComponent } from './courses/courses.component';
import { CourseChapterPlannerComponent } from './course-chapter-planner/course-chapter-planner.component';
import { LMSAPISettingComponent } from './lms-api-setting/lms-api-setting.component';
import { LMSRoutingModule } from './lms-routing.module';
import { NgSelect2Module } from 'ng-select2';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AdminRoutingModule } from '../admin-routing.module';
import { AcademicModule } from '../academic/academic.module';
import { ClassSummaryFilterPipe } from './online-class-report/class-summary-filter.pipe';


@NgModule({
  declarations: [
    CourseChapterPlannerComponent,
    CoursePlannerComponent, 
    SessionPlannerComponent,
    OnlineClassReportComponent,
    OnlineClassReportComponent, 
    SessionSummaryComponent,
    LMSSettingComponent,
    CoursesComponent,
    LMSAPISettingComponent,
    ClassSummaryFilterPipe
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgSelect2Module,
    //BsDatepickerModule,
    LMSRoutingModule,
    AcademicModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbAlertModule

  ],
  providers: [
    {
      provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter
      
    }
  ]
})
export class LMSModuleModule { }
