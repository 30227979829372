import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edumaq',
  templateUrl: './edumaq.component.html',
  styleUrls: ['./edumaq.component.css']
})
export class EdumaqComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }
  logout() {
    sessionStorage.removeItem('JWT_TOKEN');
    localStorage.clear();
    this.router.navigate(['edumaq']);
  }
  ngOnInit(): void {
  }

}
