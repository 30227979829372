import { Component, OnInit, ViewChild } from '@angular/core';
import { ApexChart, ApexNonAxisChartSeries, ApexResponsive, ChartComponent, ChartType } from 'ng-apexcharts';
import { ToastrService } from 'ngx-toastr';
import { TemplatesettingComponent } from 'src/app/admin/administration/certificate/templatesetting/templatesetting.component';
import { TicketCommonServices } from '../commonServices/ticket.common.services';
import { TicketStatusRemark } from '../models/ticket-status-remark.model';
import { Ticket } from '../models/ticket.model';
declare var $: any;
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  legend: any;
  responsive: ApexResponsive[];
  labels: any;
};

@Component({
  selector: 'app-ticket-status',
  templateUrl: './ticket-status.component.html',
  styleUrls: ['./ticket-status.component.css']
})

export class TicketStatusComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;


  ticketList: Ticket[] = [];
  ticketListFiltered: Ticket[] = [];

  selectedStatus: string;
  selectedFromDate: Date;
  selectedToDate: Date;
  defaultValue = '';
  institutionNameArray: string[];
  totalTicketsCount = 0;
  pendingTicketsCount = 0;
  completedTicketsCount = 0;
  processingTicketsCount = 0;
  createdTicketsCount = 0;
  selectedTicket: Ticket = {} as Ticket;
  selectedTicket_temp: Ticket = {} as Ticket;
  searchTicketInstituteName = 'NOT SELECTED';
  remarks: string;
  ticketId: string;
  ticketStatusRemarkList: TicketStatusRemark[] = [];

  chartData: number[] = [];
  chartLables: string[] = [];
  chartType: ChartType = 'pie';

  constructor(private ticketCommonService: TicketCommonServices, private toster: ToastrService) {
    this.loadChartData();
   }


  getAllTickets() {
    this.totalTicketsCount = 0;
    this.ticketCommonService.getAllTickets().subscribe((data: Ticket[]) => {
      this.ticketList = this.ticketListFiltered = data;
      this.fetchAllInstitutionNameFormTicketList(data);
      this.setChart();
    });
  }
  setChart() {
    this.chartLables = this.createChartLabels(); // create chart label from ticket list
    this.chartData = this.createChartData(); // create chart values from ticket list
    this.loadChartData();
  }
  fetchAllInstitutionNameFormTicketList(ticketList: Ticket[]) {
    const nameArray = ticketList.map(ticket => ticket.institutionName);
    this.institutionNameArray = nameArray.filter((value, index) =>
      nameArray.indexOf(value) === index).filter((el) => {
        return el != null && el !== '';
      })

  }

  loadChartData() {
    this.chartOptions = {
      series: this.chartData, // chart data array
      chart: {
        width: 380,
        height: 273,
        type: this.chartType // chart type such as pie,donut etc
      },
      legend: {
        position: 'bottom'
      },
      labels: this.chartLables,    // chart labesl like Whatsapp,SMS etc.
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: { width: 200 }, legend: { position: 'bottom' }

          }
        }
      ]
    };
  }

  createChartLabels() {
    let chartlabel: string[] = [];
    this.ticketListFiltered.forEach((value) => { chartlabel.push(value.ticketStatus) });

    chartlabel = chartlabel.filter((value, index) =>
      chartlabel.indexOf(value) === index).filter((el) => {
        return el != null && el !== '';
      });
    return chartlabel;
  }
  createChartData() {
    var chartdata: any[] = [];
    var val: any, ticketCount: number;
    var len = this.chartLables.length;
    ticketCount = this.ticketListFiltered.length;
    this.totalTicketsCount = this.ticketListFiltered.length;
    for (let i = 0; i < len; i++) {

      val = this.ticketListFiltered.filter(ticket => ticket.ticketStatus == this.chartLables[i]).length;
      if (this.chartLables[i] === 'Created') {
        this.createdTicketsCount = val;
      } else if (this.chartLables[i] === 'Pending') {
        this.pendingTicketsCount += val;
      } else if (this.chartLables[i] === 'Processing') {
        this.processingTicketsCount += val;
      } else if (this.chartLables[i] === 'Completed') {
        this.completedTicketsCount += val;
      }
      chartdata[i] = parseInt(val);
    }
    return chartdata;
  }

  searchTicketById(ticketId: any) {
    this.ticketListFiltered = this.ticketList;
    if (ticketId === null) {
      this.ticketListFiltered = this.ticketList;
      return;
    }
    var temp = this.ticketList.find(element => element.customId == ticketId);
    if (temp != undefined) {
      this.searchTicketInstituteName = temp.institutionCode + ' - ' + temp.institutionName;
    }
    this.ticketListFiltered = this.ticketListFiltered.filter((element) => element.customId == ticketId);
    if (this.ticketListFiltered.length === 0) {
      this.toster.warning('No Tickets Found!', 'Sorry', {
        timeOut: 3000
      });
      this.ticketListFiltered = this.ticketList;
    }
  }

  searchByInstitutionName(institutionName: any) {
    this.pendingTicketsCount = 0;
    if (institutionName === '') {
      this.ticketListFiltered = this.ticketList;
      this.setChart();
      return;
    }
    // const tempNameList: string[] = institutionName.split('/');
    // const tempName = tempNameList[1] + ' ' + tempNameList[2];
    // institutionName = institutionName.substr(institutionName.lastIndexOf(' ')); // get institution name from id-name combination
    this.ticketListFiltered = this.ticketList;
    this.ticketListFiltered = this.ticketListFiltered.filter(element => element.institutionName == institutionName);
    if (this.ticketListFiltered.length === 0) {
      this.toster.warning('No Tickets Found!', 'Sorry', {
        timeOut: 3000
      });
      this.ticketListFiltered = this.ticketList;
    }
    this.setChart();
  }
  showUpdateStatus(ticketId: string) {
    let temp: Ticket;
    temp = this.ticketList.find(element => element.id === ticketId);
    this.ticketCommonService.getTicketDetailsById(ticketId).subscribe((data: Ticket) => {
      
      this.selectedTicket = data;
      this.selectedTicket_temp = { ...data };
      this.selectedTicket.institutionName = temp.institutionName;
      this.selectedTicket.location = temp.location;
      this.remarks = '';

      this.ticketCommonService.GetTicketRemarksByTicketId(ticketId).subscribe((data: TicketStatusRemark[]) => {
        this.ticketStatusRemarkList = data;
      });
    });
  }
  showTicketDetails(ticketId: string) {
    this.selectedTicket = this.ticketList.find(element => element.id === ticketId);
    this.ticketCommonService.GetTicketRemarksByTicketId(ticketId).subscribe((data: TicketStatusRemark[]) => {
      this.ticketStatusRemarkList = data;
    });

  }
  reset() {
    this.selectedTicket.ticketStatus = this.selectedTicket_temp.ticketStatus;
    this.remarks = '';
  }

  resetTable() {
    this.ticketListFiltered = this.ticketList;
    this.ticketId = '';
  }

  updateTicketStatus() {

    const ticketStatusRemark: TicketStatusRemark = new TicketStatusRemark();
    ticketStatusRemark.ticketId = this.selectedTicket.id;
    ticketStatusRemark.ticketStatus = this.selectedTicket.ticketStatus;
    ticketStatusRemark.remarks = this.remarks;

    this.ticketCommonService.update(this.selectedTicket.id, this.selectedTicket, ticketStatusRemark).subscribe(res => {
      this.ticketCommonService.createTicketStatusChanageRemarks(ticketStatusRemark).subscribe(res => {
        this.toster.success('Ticket status has been updated successfully', 'SUCCESS!', {
          timeOut: 3000

        });
        this.ticketCommonService.GetTicketRemarksByTicketId(this.selectedTicket.id).subscribe((data: TicketStatusRemark[]) => {
          this.ticketStatusRemarkList = data;
        });
      });
    });
    this.closeUpdateModal();
  }

  closeUpdateModal() {
    this.getAllTickets();
  }



  ngOnInit(): void {
    // this.selectedTicket = { id: '', ticketType: '', employeeIds: '', priority: '', requestedBy: '', contactNumber: '',
    //  description: '', attachmentName: '', attachmentExtension: '', contentBase: '', ticketStatus: '',
    //   createdDate: '', institutionId: '', institutionCode: '', institutionName: '', affiliationNumber: '',
    //    location: '', ticketStatusRemark: '' };
    this.selectedTicket_temp = { ...this.selectedTicket };
    this.getAllTickets();
    const self = this;
    $('.select2').select2();
    $('.select2').on('change', function() {
      self.searchByInstitutionName(String($(this).val()));
    });

  }

}
