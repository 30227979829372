import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { EventCalendar } from './../eventcalendar.model';
import { EventCalendarService } from './../eventcalendar.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { SettingsService } from '../../mastersettings/settings.service';

@Component({
  selector: 'app-eventreport',
  templateUrl: './eventreport.component.html',
  styleUrls: ['./eventreport.component.css']
})
export class EventreportComponent implements OnInit {

  events: EventCalendar[] = [];
  eventsFiltered: EventCalendar[] = [];
  eventsFileredTemp: EventCalendar[] = [];
  academicYear: Academicyear = {} as Academicyear;
  months: string[] = [];
  monthValue = '0';
  eventTypeValue = '-1';

  constructor(
    private eventCalendarService: EventCalendarService,
    private academicyearService: SettingsService
    ) { }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      console.log(data);
      this.academicYear = data;
      const startMonth = new Date(data.startDate).getMonth() + 1;
      const endMonth =  this.monthDiff(new Date(data.startDate), new Date(data.endDate)); // new Date(data.endDate).getMonth() + 1;
      const startDate = new Date(data.startDate);
      const tempStartDate = new Date(data.startDate);
      const tempCurrentDate = new Date(data.startDate);
      for (let i = 0; i <= endMonth; i++) {
        tempStartDate.setMonth(startDate.getMonth() + i);
        tempCurrentDate.setMonth(startDate.getMonth() + i);
        if ((startDate.getMonth() + i) > 12) {
          tempStartDate.setFullYear(tempCurrentDate.getFullYear() - ((startDate.getMonth() + i) - 12));
        }
        this.months.push(moment(tempStartDate).format('MMMM-yyyy'));
      }
    });
  }

  monthDiff(d1, d2) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  ListEvents() {
    this.eventCalendarService.getAll().subscribe((data: EventCalendar[]) => {
    this.eventsFiltered =  this.events = data;
    if (data.length > 0) {
       this.filterByAcademicYear();
    }
  });
 }
  filterByAcademicYear() {
    const academicStartDate = this.academicYear.startDate;
    const academicEndDate = this.academicYear.endDate;
    console.log(academicStartDate);
    this.eventsFileredTemp = this.events.filter((d) => {
      console.log(new Date(d.startDate).getTime());
      return new Date(d.startDate).getTime() >= new Date(academicStartDate).getTime() &&
      new Date(d.endDate).getTime() <= new Date(academicEndDate).getTime();
    });
    this.eventsFiltered = this.eventsFileredTemp;
  }
 /*sortByAcademicYear() {
    this.eventsFiltered=this.eventsFiltered.sort(d=>d.startDat
    )
  const academicStartDate='2020-11-02';
  const academicEndDate='2021-06-30';
  var academicSDate=new Date(academicStartDate);
  var academicEDate=new Date(academicEndDate);
  this.eventsFiltered= this.eventsFiltered.sort((a,b) => {
  var sdate=new Date(a.startDate);
  var  edate=new Date(a.endDate);
        return sdate.getTime() + academicSDate.getTime();

    });
 }   */
 resetFilter() {
    this.ListEvents();
    this.eventTypeValue = '-1';
    this.monthValue = '0';
}

  onMonthChange(value: string) {
    this.monthValue = value.split('-')[0];
    if (value === '0') {
      this.eventsFiltered = this.eventsFileredTemp;
    } else {
      this.eventsFiltered = this.eventsFileredTemp.filter((event: EventCalendar) => {
        const month = moment(event.startDate).format('MMMM');
        return value.split('-')[0] === month;
      });
    }
  }

  onEventTypeChange(value: string) {
    const month = this.monthValue;
    this.eventTypeValue = value;
    if (value === '0' || value === 'All' || value === '' || value === '-1') {
      if (month === '-1' || month === '0') {
        this.eventsFiltered = this.eventsFileredTemp;
      } else {
        this.eventsFiltered = this.eventsFileredTemp.filter(d => {
          const cmonth =  moment(d.startDate).format('MMMM');
          return ((cmonth.toString() === this.monthValue));
      });
      }} else {
        if (month === '-1' || month === '0') {
          this.eventsFiltered = this.eventsFileredTemp.filter(d => d.eventType.toString()
          .toLowerCase().trim() === (value.toLowerCase().trim()));
          } else {
            this.eventsFiltered = this.eventsFileredTemp.filter(d => {
            const cmonth =  moment(d.startDate).format('MMMM');
            return ((d.eventType.toString()
            .toLowerCase().trim() === (value.toLowerCase().trim())) &&
            (cmonth.toString() === this.monthValue));
          });
          }
      }
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  public noofDays(fromdate: any, todate: any): number {
    const fromDateParts = this.formatDate(fromdate).split('-');
    const month = fromDateParts[1];
    const day = fromDateParts[0];
    const year = fromDateParts[2];
    let ageValue = 0;
    const condition1: number = (parseInt(month, 0) + parseInt(day, 0));

    const toDateParts = this.formatDate(todate).split('-');
    const tomonth = toDateParts[1];
    const today = toDateParts[0];
    const toyear = toDateParts[2];
    const condition2: number = parseInt(tomonth, 0) + parseInt(today, 0);

    if (condition2 >= condition1) {
      ageValue = parseInt(today, 0) - (parseInt(day, 0));
    } else {
      ageValue = parseInt(today, 0) - ((parseInt(day, 0) + 1));
    }

    return ageValue;
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }
  filter(keyword: any) {
    if ( keyword === '') {
      this.onMonthChange(this.monthValue);
      this.onEventTypeChange(this.eventTypeValue);
    } else {
    this.eventsFiltered = this.eventsFileredTemp
                          .filter((event: EventCalendar) => event.eventName.toLocaleLowerCase()
                          .includes(keyword.toLocaleLowerCase()));
    }
  }

  get totalRows(): number {
    return this.eventsFileredTemp.length;
  }

  ngOnInit(): void {
    this.currentAcademicYear();
    this.ListEvents();
  }

}
