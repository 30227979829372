import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssignedSpecialFeeReportService } from './assignedspecialfeereport.service';
import { AssignedSpecialFeeReportModel } from './assignedspecialfeereport.model';
import { FeeHeadService } from './../../feehead/feehead.service';
import { FeeHead } from './../../feehead/feehead.model';
import { ClassCourse } from '../../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../../mastersettings/models/batch';
import { BatchService } from '../../../mastersettings/batch/batch.service';
import { Student } from './../../../studentmanagement/student.model';
import { StudentService } from './../../../studentmanagement/student.service';
declare var $: any;
@Component({
  selector: 'app-assignedspecialfeereport',
  templateUrl: './assignedspecialfeereport.component.html',
  styleUrls: ['./assignedspecialfeereport.component.css']
})
export class AssignedspecialfeereportComponent implements OnInit {
  classDrp: string;
  batchDrp: string;
  studentDrp: string;
  admissionNo: string;
  mode: string;
  fromDate: string;
  toDate: string;
  totalPaidAmount: any;
  dataCount: any;
  specialFeeType: any;
  assignedSpecialFeeReportList: AssignedSpecialFeeReportModel[] = [];
  assignedSpecialFeeReportFilteredList: AssignedSpecialFeeReportModel[] = [];
  assignedSpecialFeeReportTemp: AssignedSpecialFeeReportModel[] = [];
  specialFeeList: FeeHead[] = [];
  admissions: Student[] = [];
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  constructor(
    public assignedSpecialFeeReportService: AssignedSpecialFeeReportService,
    public feeHeadService: FeeHeadService,
    public classCourseService: ClassCourseService,
    public batchService: BatchService,
    public studentService: StudentService

  ) { }
  listStudents() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.admissions = data;
    });
  }
  listClassCourse() {
    this.classCourseService.getAll().subscribe((data: ClassCourse[]) => {

      this.classes = data;
    });
  }
  onClassSelected(value: string) {
    this.classDrp = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
    });
    this.onDrpFilter();
  }

  onBatchChanged(value: string) {
    this.studentService.getAll().subscribe((data: Student[]) => {
      // this.ListFeeHead();
      this.admissions = data.filter((student: Student) => {
        return student.classCourseId.toString() === this.classDrp &&
          student.batchId.toString() === value.toString();
      });
    });
    this.onDrpFilter();
  }

  onStudentChanged(id: number) {
  }
  getSpecialFeeList() {
    this.feeHeadService.getAll().subscribe((data: FeeHead[]) => {
      this.specialFeeList = data.filter((x) => x.feeType === 'Special');
    });
  }
  onSpecialFeeType() {
    this.onDrpFilter();
  }
  getAssignedSpecialFeeReport() {
    const self = this;
    this.assignedSpecialFeeReportService.getAssignedSpecialFeeReport().subscribe((data: AssignedSpecialFeeReportModel[]) => {
      this.assignedSpecialFeeReportFilteredList = this.assignedSpecialFeeReportList = data;
      this.dataCount = data.length;
    });
  }
  //
  onDrpFilter() {
    const feeTypeSelected = this.specialFeeType;
    const classSelected = this.classDrp;
    const batchSelected = this.batchDrp;
    const studentSelected = this.studentDrp;
  }
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword == '') {
      // this.onSearch();
      this.assignedSpecialFeeReportList = this.assignedSpecialFeeReportList;
    } else {
      this.assignedSpecialFeeReportTemp = this.assignedSpecialFeeReportTemp.filter(d => {
        return (d.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
          d.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
           d.rollNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
      });
      this.assignedSpecialFeeReportFilteredList = this.assignedSpecialFeeReportTemp;
    }
  }
  onAdmNoWiseSearch() {
    const keyword = this.admissionNo;
    this.assignedSpecialFeeReportTemp = this.assignedSpecialFeeReportList;
    this.assignedSpecialFeeReportTemp = this.assignedSpecialFeeReportTemp.filter(d => {
      return (d.admissionNo.toLocaleLowerCase() === keyword.toLocaleLowerCase());
    });
    this.assignedSpecialFeeReportFilteredList = this.assignedSpecialFeeReportTemp;
  }
  ngOnInit(): void {
    this.classDrp = 'Class';
    this.specialFeeType = 'Special Fee Type';
    this.batchDrp = 'Batch';
    this.studentDrp = 'Select Student';
    this.listClassCourse();
    this.listStudents();
    // this.getAssignedSpecialFeeReport();
    this.getSpecialFeeList();
  }

}


