import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GradescalegroupService } from '../../services/gradescalegroup.service';
import { GradeScaleGroup } from '../models/grade-scale-group.model';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { EvaluationType } from '../models/evaluation-type.model';
import { Classes } from '../models/classes.model';

@Component({
  selector: 'app-grade-scale',
  templateUrl: './grade-scale-group.component.html',
  styleUrls: ['./grade-scale-group.component.css']
})
export class GradeScaleGroupComponent implements OnInit {

  constructor(private gradeService: GradescalegroupService,
              private toastr:ToastrService,
              public fb:UntypedFormBuilder) { }

  gradeSc=new GradeScaleGroup(0,'','',null);
  gradeScale:GradeScaleGroup[]=[];
  evaluationType:EvaluationType[]=[];
  evaluationTypeTemp:EvaluationType[]=[];
  classes: Classes[]=[];
  gradeScaleFilteredList:GradeScaleGroup[]=[];
  gradeScaleForm:UntypedFormGroup;
  evaluationForm:UntypedFormGroup;
  
  evaluationTypeForm:UntypedFormGroup;
  classTypeForm:UntypedFormGroup;

  submitted=false;
  evaluationSubmitted=false;
  searchKeyword:string;
  gradeScaleCount:number;
  showGC:boolean=false;
  selected:number;
  trLoop:number;
  selectedId:number;

  ngOnInit(): void {
    this.gradeScaleForm=this.fb.group({
      id:[0],
      scaleGroup:['',Validators.required],
      description:['',Validators.required],
      gradeScaleStatus:[true,Validators.required]
    });

    this.evaluationForm=this.fb.group({
      id:[0],
      evaluationType:['',Validators.required],
      evaluationStatus:[true,Validators.required]
    });
    
    
    this.getAllGradeScale();
    //this.getAllEvaluationsType();
    this.getAllClass();
  }

  get f() { return this.gradeScaleForm.controls; }
  get e() { return this.evaluationForm.controls; }

  resetForm(){
    this.submitted = false;
    this.gradeScaleForm=this.fb.group({
      id:[0],
      scaleGroup:['',Validators.required],
      description:['',Validators.required],
      gradeScaleStatus:[true,Validators.required]
    })   
  }

  resetEvaluationForm(){
    this.evaluationSubmitted=false;    
    this.evaluationForm=this.fb.group({
      id:[0],
      evaluationType:['',Validators.required],
      evaluationStatus:[true,Validators.required]
    });
  }

  submitForm(){      
    this.submitted=true;
    if(this.gradeScaleForm.invalid){
      this.toastr.warning('All field are required','WARNING!!!',{timeOut:3000});
      return;
    }
    if(this.gradeScaleForm.get('id').value===0)
    {
        this.gradeService.createGradeScale(this.gradeScaleForm.value).subscribe(res=>{                
        this.toastr.success("Grade Scale successfully created","SUCCESS!",{timeOut:3000});
        this.submitted=false;
        this.resetForm();        
        this.getAllGradeScale();        
    });    
   }
   else{    
         this.gradeService.updateGradeScale(this.gradeScaleForm.get('id').value,this.gradeScaleForm.value).subscribe(res=>{           
           this.toastr.success("Grade Scale successfully updated","SUCCESS!",{timeOut:3000});
           this.getAllGradeScale();
         });       
   }
  }

  // submitEvaluationForm(){
  //   this.evaluationSubmitted=true;
  //   if(this.evaluationForm.invalid){
  //     this.toastr.warning('All field are required','WARNING!!!',{timeOut:3000});
  //     return;
  //   }

  //   if(this.evaluationForm.get('id').value===0)
  //   {
  //       this.gradeService.createEvaluationType(this.evaluationForm.value).subscribe(res=>{                
  //       this.toastr.success("Evaluation Type successfully created","SUCCESS!",{timeOut:3000});
  //       this.evaluationSubmitted=false;
  //       this.resetEvaluationForm();        
  //       this.getAllEvaluationsType();        
  //   });    
  //  }
  //  else{
  //   this.gradeService.updateEvaluationType(this.evaluationForm.get('id').value,this.evaluationForm.value).subscribe(res=>{           
  //     this.toastr.success("Evaluation Type successfully updated","SUCCESS!",{timeOut:3000});
  //     this.getAllEvaluationsType();
  //   }); 
  //  }

  // }

  getAllGradeScale(){
    this.gradeService.getAllGradeScale().subscribe((data:GradeScaleGroup[])=>{     
      this.gradeScale=data;  
      //this.gradeScaleCount= this.examCategoryFilteredList.length;    
    })
  }

  // getAllEvaluationsType(){
  //   this.gradeService.getAllEvaluationsType().subscribe((data:EvaluationType[])=>{     

  //     this.evaluationType=this.evaluationTypeTemp=data; 
  //   })
  // }

  getAllClass(){
    this.gradeService.getAllClass().subscribe((data:Classes[])=>{
      this.trLoop = Math.ceil(data.length/3)

      this.classes=data;
    })
  }

  editGradeScale(id:number){
    this.gradeService.getGradeScaleById(id).subscribe((data:GradeScaleGroup)=>{
      this.gradeScaleForm=this.fb.group({
        id:[data.id],
        scaleGroup:[data.scaleGroup, Validators.required],
        description:[data.description,Validators.required],
        gradeScaleStatus:[data.gradeScaleStatus,Validators.required]
      })
    })
  }

  editEvaluationType(id:number){
    this.gradeService.getEvaluationTypeById(id).subscribe((data:EvaluationType)=>{
      this.evaluationForm=this.fb.group({
        id:[data.id],
        evaluationType:[data.evaluationType, Validators.required],       
        evaluationStatus:[data.evaluationStatus,Validators.required]
      })    
    })
  }
  
  deleteGradeScale(id:number){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton:true,
      confirmButtonText:'Yes, delete it!',
      cancelButtonText:'No, keep it'
    }).then((result)=>{
      if(result.value){
        this.gradeService.deleteGradeScale(id).subscribe(data=>{
          Swal.fire(
            'Deleted!',
            'Grade Scale has been deleted.',
            'success'
          );
          this.getAllGradeScale();
        })
      }
    })
  }


  deleteEvaluationType(id:number){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton:true,
      confirmButtonText:'Yes, delete it!',
      cancelButtonText:'No, keep it'
    }).then((result)=>{
      if(result.value){
        this.gradeService.deleteEvaluationType(id).subscribe(data=>{
          Swal.fire(
            'Deleted!',
            'Evaluation Type has been deleted.',
            'success'
          );
          //this.getAllEvaluationsType();
        })
      }
    })
  }

  // addEvaluation(){
  //  this.getAllEvaluationsType()
  // }

  
  showGradeClass(id:number,i:number){
    this.showGC=true;
    this.selected=i+1    
    this.selectedId=id;
    //this.evaluationType= this.evaluationTypeTemp;
    
    
    this.gradeService.AllGradeScaleMapping(id).subscribe(res=>{
      var cObj:[] =res["gradeScaleToClass"];
      var eObj:[]=res["gradeScaleToEvaluation"];

      this.evaluationType.forEach(e=>{
        this.evaluationType.find(obj=>obj.id==e.id).isChecked=false;
      })
      
      cObj.forEach(element => {        
       this.classes.find(obj=>obj.id==element["classId"]).isChecked=true;
      
       if(id==element["gradeScaleId"])
           this.classes.find(obj=>obj.id==element["classId"]).isActive=true;
        else 
           this.classes.find(obj=>obj.id==element["classId"]).isActive=false;
      });

      
      eObj.forEach(element => {
        if(id==element["gradeScaleId"])
          this.evaluationType.find(obj=>obj.id==element["evaluationId"]).isChecked=true;
        // else
        //   this.evaluationType.find(obj=>obj.id!=element["evaluationId"]).isChecked=false;
      });
    })
  }

  EvaluationMapping(evalId,assignId){
    assignId= parseInt(assignId.id);
    this.gradeService.createMapping(1,evalId, assignId).subscribe(res=>{
      this.toastr.success("Evaluation Mapped Successfully","SUCCESS!",{timeOut:3000});
    })
  }

  ClassMapping(classId,assignId){
    assignId= parseInt(assignId.id);
    this.gradeService.createMapping(0,classId, assignId).subscribe(res=>{
      this.toastr.success("Classes Mapped Successfully","SUCCESS!",{timeOut:3000});
    })
  }


}
