import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Student } from './../../studentmanagement/student.model';
import { StudentService } from './../../studentmanagement/student.service';
import { InstallmentService } from './../installment/installment.service';
import { Installment } from './../installment/installment.model';
import { FeeMaster, FeeDetail, FeeDetailDetail } from '../feecollection/feecollection.model';
import { FeeCollectionService } from '../feecollection/feecollection.service';
import { FeeRefundService } from './feerefund.service';
import { FeeRefund, FeeRefundDetail, FeeRefundInstallment, FeeRefundPayment } from './feerefund.model';
declare var $: any;
@Component({
  selector: 'app-feerefund',
  templateUrl: './feerefund.component.html',
  styleUrls: ['./feerefund.component.css']
})
export class FeerefundComponent implements OnInit {
  
  constructor(
    public feeCollectionService: FeeCollectionService,
    public installmentService: InstallmentService,
    public feeRefundService: FeeRefundService,
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    private toastr: ToastrService
  ) { }

  
  showStudentDetail = false;
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  studentDetail: Student = {} as Student;
  classId = '';
  installmentId = '';
  admissionno = '';
  admissionId = '';
  refundMode = '';
  refundAmount = '';
  totalPaidAmount = 0;
  totalRefundedAmount = 0;
  totalRefundAmount = 0;
  
  description = '';
  feeRefundDetails: FeeRefundDetail[] = [];
  installments: FeeRefundInstallment[] = [];
  feeheadRefunds: FeeRefundPayment[] = [];
  refundDate: Date;
  isStudentDetail = false;


  showDetail() {
    this.showStudentDetail = !this.showStudentDetail;
  }

  ListStudents() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.admissions = data;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }

  onClassSelected(value: string) {
    this.studentDetail = {} as Student;
    this.installments = [];
    this.feeheadRefunds = [];
    this.batches = [];
    this.admissions = [];
    this.totalPaidAmount = 0;
    this.totalRefundedAmount = 0;
    this.totalRefundAmount = 0;
    this.classId = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toLocaleLowerCase());
    });
  }

  onBatchChanged(value: string) {
    this.studentDetail = {} as Student;
    this.installments = [];
    this.feeheadRefunds = [];
    this.totalPaidAmount = 0;
    this.totalRefundedAmount = 0;
    this.totalRefundAmount = 0;
    this.studentService.getAll().subscribe((data: Student[]) => {
     // this.ListFeeHead();
      this.admissions = data.filter((student: Student) => {
      return student.classCourseId.toString() === this.classId &&
        student.batchId.toString() === value.toString();
      });
    });
  }
    
  onStudentChanged(id: number) {
    console.log(id);
    this.studentDetail = {} as Student;
    this.installments = [];
    this.feeheadRefunds = [];
    this.totalPaidAmount = 0;
    this.totalRefundedAmount = 0;
    this.totalRefundAmount = 0;
    this.isStudentDetail = false;
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.studentDetail = data.filter((student: Student) => {
        return student.id === id;
      })[0];
      this.isStudentDetail = true;
      this.feeRefundService.getPayment(this.studentDetail.id).subscribe((inst: FeeRefundInstallment[]) => {
        this.installments = inst;
      });
    });
    // this.studentService.getById(id).subscribe((data: Student) => {
    //     this.studentDetail = data;
    //     this.installmentService.getFeePayment(this.studentDetail.id).subscribe((inst: Installment[]) => {
    //       this.installments = inst;
    //    });
    //  });
  }

  onInstallmentSelected(id: number, event: any) {
    console.log(id);
    this.installments.forEach((x, i) => {
      if (x.id !== id) {
        x.checked = false;
      }
    });
    this.feeheadRefunds = [];
    this.totalPaidAmount = 0;
    this.totalRefundAmount = 0;
    if (event.target.checked) {
      // this.installments[id].checked = true;
      this.feeRefundService.getFeePayment(id).subscribe((inst: FeeRefundPayment[]) => {
        console.log(inst);
        this.feeheadRefunds = inst;
        this.totalPaidAmount = this.feeheadRefunds.reduce((sum: number, current) => sum + parseFloat(current.amountPaid), 0);
        this.totalRefundedAmount = this.feeheadRefunds.reduce((sum: number, current) => sum + parseFloat(current.refundedAmount), 0);
      });
    } else {
      this.feeheadRefunds = [];
      this.totalPaidAmount = 0;
      this.totalRefundAmount = 0;
    }
  }

  onFeeheadSelected(event: any, id: number) {
    console.log(id);
    if (event.target.checked) {
      const feeHead = this.feeheadRefunds[id];
      feeHead.refundAmount = feeHead.amountPaid;
      this.calculateTotal(Number(feeHead.refundAmount), Number(feeHead.amountPaid), 'test');
    } else {
      const feeHead = this.feeheadRefunds[id];
      feeHead.refundAmount = '0';
      // this.calculateTotal(Number(feeHead.amountPaid));
      this.totalRefundAmount = this.totalRefundAmount - Number(feeHead.amountPaid);
    }
  }

  calculateTotal(value: number, paidAmount: number, event: any) {
    if (value > paidAmount) {
      event.target.value = paidAmount;
      event.preventDefault();
      return;
    }
    this.totalRefundAmount = 0;
    this.feeheadRefunds.forEach((x) => {
      console.log(x.refundAmount);
      if (x.refundAmount !== undefined) {
        this.totalRefundAmount = Number(this.totalRefundAmount) + Number(x.refundAmount);
      }
    });
  }

  onKeypressRefundAmount(value: number, paidAmount: number, event: any) {
    const amt = paidAmount - value;
    console.log(value < paidAmount);
    if (value > paidAmount) {
      console.log(value + '  ' + paidAmount);
      event.preventDefault();
      return;
    }
  }


  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

    resetForm() {
        this.feeheadRefunds = [];
        this.feeRefundDetails = [];
        this.installments = [];
        this.refundAmount = '0';
        $('#refundDate').val('');
        this.description = '';
        this.refundMode = '';
        this.totalRefundAmount = 0;
        this.totalRefundedAmount = 0;
        this.totalPaidAmount = 0;
        this.studentDetail = {} as Student;
        this.admissions = [];
        this.batches = [];
        this.classes = [];

        this.ListClassCourse();

    }

  submitForm() {
    
    if (this.totalRefundAmount === 0 ) {
      this.toastr.warning('Please Select atleast one fee head', 'WARNING!', {
        timeOut: 3000
      });
      return false;
    }

    if (this.refundMode === '') {
      this.toastr.warning('Please select Refund Mode', 'WARNING!', {
        timeOut: 3000
      });
      return false;
    }

    if ($('#refundDate').val() === '') {
      this.toastr.warning('Please select Refund Date', 'WARNING!', {
        timeOut: 3000
      });
      return false;
    }

    if (this.description === '') {
      this.toastr.warning('Please enter Remarks', 'WARNING!', {
        timeOut: 3000
      });
      return false;
    }

    this.feeheadRefunds.forEach((x) => {
        if (x.checked) {
          const feeRefundDetail: FeeRefundDetail = {} as FeeRefundDetail;
          feeRefundDetail.feeHeadId = x.id.toString();
          feeRefundDetail.paidAmount = x.amountPaid;
          feeRefundDetail.refundAmount = x.refundAmount;
          feeRefundDetail.refundedAmount = (Number(x.refundedAmount) + Number(x.refundAmount)).toString();
          this.feeRefundDetails.push(feeRefundDetail);
        }

    });

    const feeRefund: FeeRefund = {} as FeeRefund;
    feeRefund.id = 0;
    feeRefund.academicYearId = '0';
    feeRefund.admissionId = this.admissionId;
    feeRefund.description = this.description;
    feeRefund.reciptId = '';
    feeRefund.refundAmount = this.totalRefundAmount.toString();
    feeRefund.refundDate = this.formatDate(this.refundDate);
    feeRefund.refundMode = this.refundMode;
    feeRefund.feeRefundDetail = this.feeRefundDetails;

    this.feeRefundService.create(feeRefund).subscribe(res => {
      console.log('Fee collection created!');
      this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
        timeOut: 3000
      });
      // this.submitted = false;
      this.resetForm();
    });

  }

  ngOnInit(): void {
    const self = this;
    this.ListClassCourse();
    $('.select2').select2();
    $('.select2').on('change', function() {
      self.onStudentChanged(Number($(this).val()));
      self.admissionId = $(this).val();
     });
    
    $('#refundDate').datepicker().on('changeDate', (e) => {
      self.refundDate =  e.date;
    });


  }

}
