import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeeRefundReportModel } from './feerefundreport.model';
import { FeeRefundReportService } from './feerefundreport.service';
import { ClassCourse } from '../../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../../mastersettings/models/batch';
import { BatchService } from '../../../mastersettings/batch/batch.service';
declare var $: any;

@Component({
  selector: 'app-feerefundreport',
  templateUrl: './feerefundreport.component.html',
  styleUrls: ['./feerefundreport.component.css']
})
export class FeerefundreportComponent implements OnInit {
  classDrp: string;
  batchDrp: string;
  sumTotal: any = 0;
  fromDate: string;
  toDate: string;
  totalPaidAmount: any;
  feeRefundReportList: FeeRefundReportModel[] = [];
  feeRefundReportFilteredList: FeeRefundReportModel[] = [];
  feeRefundReportTemp: FeeRefundReportModel[] = [];
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  showDetails = false;
  constructor(
    public feeRefundReportService: FeeRefundReportService,
    public classCourseService: ClassCourseService,
    public batchService: BatchService,

  ) { }

  getFeeRefundReportList() {
    this.feeRefundReportService.getFeeRefundReportList().subscribe((data: FeeRefundReportModel[]) => {
      this.feeRefundReportFilteredList = this.feeRefundReportList = data;
      this.sumTotal = this.sumTotal = this.feeRefundReportFilteredList.map(item =>
         parseFloat(item.refundAmount)).reduce((prev, curr) => prev + curr, 0);
    });
  }

  onHideShow() {
    this.showDetails = !this.showDetails;
  }

  listClassCourse() {
    this.classCourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }
  onClassSelected(value: string) {
    this.classDrp = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
    });
    this.onDrpFilter();
  }

  onBatchChanged(value: string) {
    this.onDrpFilter();
  }
  onDrpFilter() {
    const classSelected = this.classDrp;
    const batchSelected = this.batchDrp;
    const fromDate = this.fromDate;
    const toDate = this.toDate;
    let startDt: Date;
    let endDt: Date;
    this.feeRefundReportTemp = this.feeRefundReportFilteredList = this.feeRefundReportList;

    if (typeof this.fromDate !== 'undefined' && typeof this.fromDate !== 'undefined' && this.fromDate !== '' && this.toDate !== '') {
      startDt = new Date(fromDate);
      endDt = new Date(toDate)
      this.feeRefundReportTemp = this.feeRefundReportTemp.filter(d => {
        const date = new Date(d.refundDate);
        return (startDt.getTime() <= date.getTime() && endDt.getTime() >= date.getTime());
      });
    }

    if (classSelected !== 'All' && classSelected !== '' && classSelected !== 'undefined' && classSelected !== 'Class') {
      this.feeRefundReportTemp = this.feeRefundReportTemp.filter(d => {
        return (d.class.toLocaleLowerCase().includes(classSelected.toLocaleLowerCase()));
      });
    }

    if (batchSelected !== 'All' && batchSelected !== '' && batchSelected !== 'undefined' && batchSelected !== 'Batch') {
      this.feeRefundReportTemp = this.feeRefundReportTemp.filter(d => {
        return (d.batch.toLocaleLowerCase().includes(batchSelected.toLocaleLowerCase()));
      });
    }

    this.feeRefundReportFilteredList = this.feeRefundReportTemp;
    this.sumTotal = this.feeRefundReportTemp.length;
  }
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === '') {
      this.onDrpFilter();
    } else {
      this.feeRefundReportTemp = this.feeRefundReportTemp.filter(d => {
        return (d.rollNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
          d.admissionNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
           d.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
      });
      this.sumTotal = this.feeRefundReportTemp.map(item => parseFloat(item.refundAmount)).reduce((prev, curr) => prev + curr, 0);
    }
  }
  ngOnInit(): void {
    const self = this;
    this.classDrp = 'Class';
    this.batchDrp = 'Batch';
    this.listClassCourse();
    this.getFeeRefundReportList();
    $('#dateFrom').datepicker().on('changeDate', (e) => {
      self.fromDate = e.date;
    });
    $('#dateTo').datepicker().on('changeDate', (e) => {
      self.toDate = e.date;
    });

  }

}
