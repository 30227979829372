import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Group } from './group.model';
import { GroupService } from './group.service';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { AssignedToGroupStudentType } from '../../mastersettings/models/assignedToGroupStudentType';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {

  groupForm: UntypedFormGroup;
  groups: Group[] = [];
  groupFilteredList: Group[] = [];
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  resClassIds: any[] = [];
  selectedClassIds = '';
  resBatchIds: any[] = [];
  selectedBatchIds = '';
  submitted = false;
  
  HighlightRow : Number;
  ClickedRow:any;
  checked:any;

  constructor(
    public groupService: GroupService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) 

{ 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }

  }

  ListGroup() {
    this.groupService.getAll().subscribe((data: Group[]) => {
      console.log(data);
      this.groupFilteredList = this.groups = data;
    });
  }

  onFilterSelected(value) {
    if (value === '') {
      this.groupFilteredList = this.groups;
    } else {
      this.groupFilteredList = this.groups
        .filter((group: Group) => group.stypeId === value);
       for (let i = 0; i < this.classes.length; i++) {
          this.classes[i].checked = null;
          for (let j = 0; j < this.classes[i].batches.length; j++) {
            this.classes[i].batches[j].assignedToGroupStudentType = AssignedToGroupStudentType.None;
            for(let k = 0; k< this.groupFilteredList.length; k++) {
              if(this.groupFilteredList[k].classIds) {
                if(this.groupFilteredList[k].batchIds) {
                  var splittedClassIds = this.groupFilteredList[k].classIds.split('|');
                  var splittedBatchIds = this.groupFilteredList[k].batchIds.split('|');
                  if(splittedClassIds.indexOf(this.classes[i].id+'') > -1) {
                    if(splittedBatchIds.indexOf(this.classes[i].batches[j].id+'') > -1) {
                      switch(this.groupFilteredList[k].stypeId) {
                        case 'Day Scholar':
                          this.classes[i].batches[j].assignedToGroupStudentType = AssignedToGroupStudentType.DayScholar;
                          break;
                        case 'Boarding':
                          this.classes[i].batches[j].assignedToGroupStudentType = AssignedToGroupStudentType.Boarding;
                          break;
                      }
                    }
                  }
                }
              }
            }
           }           
           if(this.classes[i].batches.length > 0 && this.classes[i].batches.every(x=>this.isBatchBelongstoAnyGroup(x))){
            this.classes[i].checked = true;
           }
         }
    }
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
      this.batchService.getAll().subscribe((bdata: Batch[]) => {
        this.classes.forEach(x => {
          const odata = bdata.filter((batch: Batch) => batch.classId==x.id);
          odata.forEach(b=>{
            b.assignedToGroupStudentType = AssignedToGroupStudentType.None;
          })
          x.batches = odata;
        });
      });
    });
  }

  disabledBatch(batch: Batch) {
    if(batch) {
      return (this.isBatchBelongstoAnyGroup(batch)) ? true : null;
    }
    return null;
  }

  onChangeClassEvent(e:any, classCrs: ClassCourse) {
    alert(classCrs);
    debugger;
    var splittedClassIds = this.selectedClassIds.length > 0 ? this.selectedClassIds.split('|') : [];
    
    if(e.checked === true) {
      splittedClassIds.push(classCrs.id+'');
    }
    else{
      splittedClassIds = splittedClassIds.filter(x => x !== classCrs.id+'');
    }
    this.selectedClassIds = splittedClassIds.length === 1 ? splittedClassIds[0] :  splittedClassIds.join('|'); 
    console.log("calls Ids :"+this.selectedClassIds);
  }

  onchangeClass(){
    alert("changed")
  }

  onChangeBatchEvent(e:any, batch: Batch) {
    var splittedBatchIds = this.selectedBatchIds.length > 0 ? this.selectedBatchIds.split('|') : [];
    
    if(e.checked === true) {
      //alert(batch.id);
      splittedBatchIds.push(batch.id+'');
    } 
    else{
      splittedBatchIds = splittedBatchIds.filter(x => x !== batch.id+'');
    }
    this.selectedBatchIds = splittedBatchIds.length === 1 ? splittedBatchIds[0] : splittedBatchIds.join('|'); 
  }
  
  private isBatchBelongstoAnyGroup(batch: Batch) {
    return batch.assignedToGroupStudentType === 1
      || batch.assignedToGroupStudentType === 2
      || batch.assignedToGroupStudentType === 3;
  }

  ListbatchCourse() {
        this.batchService.getAll().subscribe((bdata: Batch[]) => {
          this.batches = bdata;         
        });      
  }

  checkAllCheckBox(ev) {
    this.classes.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.classes.every(p => p.checked);
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }
  
  filter(keyword: any) {
      if (keyword === '') {
        this.groupFilteredList = this.groups;
      } else {
        this.groupFilteredList = this.groups
          .filter((group: Group) => group.groupName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase()) ||
            group.classIds.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase())
          );
      }
  }

  get totalRows(): number {
    return this.groupFilteredList.length;
  }
  get f() { return this.groupForm.controls; }
  resetForm() {
    this.selectedClassIds = '';
    this.selectedBatchIds = '';
    this.submitted = false;
    this.classes.forEach(x => x.checked = false);
    this.batches.forEach(x => x.checked = false);
    this.HighlightRow=-1;
    this.groupForm = this.fb.group({
      id: [0],
      groupName: ['', Validators.required],
      classIds: ['', Validators.required],
      batchIds: ['', Validators.required],
      status: ['true', Validators.required],
      stypeId:['', Validators.required]
    });
  }
  submitForm() {
    this.submitted = true;

    this.selectedClassIds = '';
    this.selectedBatchIds = '';
    let k = 1;
    const totalSelected = this.classes.filter(x => x.checked).length;
    this.classes.forEach((x, i) => {
      if (x.checked) {
        if (totalSelected !== k) {
          this.selectedClassIds += x.id.toString() + '|';
        } else {
          this.selectedClassIds += x.id.toString();
        }

         x.batches.forEach((y) => {
           if (y.checked) {
               this.selectedBatchIds += y.id.toString() + '|';
           }
         });

        k++;
      }
    });

    // if(this.selectedBatchIds.length > 0){
    //   this.selectedBatchIds = this.selectedBatchIds.substring(0, this.selectedBatchIds.length - 1);
    // }
debugger;
    let kk = 1;
    const totalbatchSelected = this.batches.filter(x => x.checked).length;
    this.batches.forEach((x, i) => {
      if (x.checked) {
        if (totalbatchSelected !== kk) {
          this.selectedBatchIds += x.id.toString() + '|';
        } else {
          this.selectedBatchIds += x.id.toString();
        }
        kk++;
      }
    }); 

    if (this.selectedClassIds.length === 0) {
      this.toastr.warning('Please select class', 'WARNING!', {
        timeOut: 3000
      });
    }

    if (this.selectedBatchIds.length === 0) {
      this.toastr.warning('Please select Batch', 'WARNING!', {
        timeOut: 3000
      });
    }
    else {
        var splittedClassIds = this.selectedClassIds.split('|');
        var splittedBatchIds = this.selectedBatchIds.split('|');
        var selectedClasses = this.classes.filter(cl => splittedClassIds.indexOf(cl.id+'') > -1);
        var selectedBatches = this.batches.filter(b => splittedBatchIds.indexOf(b.id+'') > -1);
        var validationError = false;
        selectedBatches.forEach(b => {
          if(!(selectedClasses.findIndex(cl=>b.classId === cl.id) > -1)) {
            this.toastr.warning('Please select Class', 'WARNING!', {
              timeOut: 3000
            });
            validationError = true;
            return;
          }
        });
        if(!validationError) {
          selectedClasses.forEach(cl => {
            var flagClassBatchNotAvailable = true;
            for (let i = 0; i < cl.batches.length; i++) {
                if(selectedBatches.findIndex(sb=>sb.id === cl.batches[i].id) > -1){
                  flagClassBatchNotAvailable=false;
                }
              }
              
              if(flagClassBatchNotAvailable) {
                this.toastr.warning('Please select Batch', 'WARNING!', {
                  timeOut: 3000
                });
                validationError = true;
                return;
              }            
          });
        }

        if(validationError){
          return;
        }
    }
//&& x.stypeId===this.groupForm.get('stypeId').value) > -1
    if (this.groupFilteredList.findIndex(x => x.groupName === this.groupForm.get('groupName').value)>-1) {
      this.toastr.warning('Unable to add same group with the same type of student', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }    

    this.groupForm.get('classIds').patchValue(this.selectedClassIds);
    this.groupForm.get('batchIds').patchValue(this.selectedBatchIds);
        // stop here if form is invalid
    if (this.groupForm.invalid) {
      return;
    }

    if (this.groupForm.get('id').value === 0) {
        this.groupService.create(this.groupForm.value).subscribe(res => {
          console.log('Group created!');
          this.ListGroup();
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
        } else {
          this.groupService.update(this.groupForm.get('id').value, this.groupForm.value).subscribe(res => {
            console.log('Group updated!');
            this.ListGroup();
            this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submitted = false;
            this.resetForm();
          });
        }
      }

      updateForm(id: number) {
        this.resClassIds=[];
        this.resBatchIds=[];
        this.classes.forEach(x=>{
          x.checked=false;
        });

        this.batches.forEach(x=>{
          x.checked=false;
        });
        this.groupService.getById(id).subscribe((data: Group) => {
          
          this.resClassIds = data.classIds.split('|');
          this.resBatchIds=data.batchIds.split('|');
          this.classes.forEach(x => {
            for (let i = 0; i < this.resClassIds.length; i++) {
              if (x.id.toString() === this.resClassIds[i]) {
                x.checked = true;
              }
              x.batches.forEach(batchSelected => {
                for (let i = 0; i < this.resBatchIds.length; i++) {
                  if (batchSelected.id.toString() === this.resBatchIds[i]) {
                    console.log(x.id);
                    batchSelected.checked = true;
                  }
                }
              });
            }
          });
        /*if(data.batchIds!==null)
        {
          this.resBatchIds = data.batchIds.split('|');
          console.log("batchIds : "+this.resBatchIds)
          this.batches.forEach(x => {
            for (let i = 0; i < this.resBatchIds.length; i++) {
              if (x.id.toString() === this.resBatchIds[i]) {
                console.log(x.id);
                x.checked = true;
              }
            }
          });
        }*/

          this.groupForm = this.fb.group({
            id: [data.id],
            groupName: [data.groupName, Validators.required],
            classIds: [data.classIds, Validators.required],
            batchIds: [data.batchIds, Validators.required],
            status: [data.status.toString(), Validators.required],
            stypeId:[data.stypeId, Validators.required]
          });
        });

        /*this.classes.forEach(x => {
          for (let i = 0; i < this.resClassIds.length; i++) {
            if (x.id.toString() === this.resClassIds[i]) {
              x.checked = true;
            }
            x.batches.forEach(batchSelected => {
              for (let i = 0; i < this.resBatchIds.length; i++) {
                if (batchSelected.id.toString() === this.resBatchIds[i]) {
                  console.log(x.id);
                  batchSelected.checked = true;
                }
              }
            });
          }
        });*/

      }

      deleteForm(id: number) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            this.groupService.delete(id).subscribe(res => {
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              );
              this.ListGroup();
            });
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
          }
        });
      }

  ngOnInit(): void {
    this.ListGroup();
    this.ListClassCourse();
    this.ListbatchCourse();
    this.groupForm = this.fb.group({
      id: [0],
      groupName: ['', Validators.required],
      classIds: ['', Validators.required],
      batchIds: ['', Validators.required],
      status: ['true', Validators.required],
      stypeId: ['', Validators.required],
    });
  }

}
