import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {AuthenticationService} from '../../authentication/authentication.service';

@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.css']
})
export class TeacherComponent implements OnInit {
  instCode:any;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService
  ) { }

  logout() {
    sessionStorage.removeItem('JWT_TOKEN');
    // localStorage.clear();
    this.router.navigate([this.authService.institutionCode]);
  }

  ngOnInit(): void {

  }

}
