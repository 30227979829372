import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Circular } from './circular.model';
import { Department } from '../../OperationActivity/hrmspayroll/master/models/departement.model';
import { Employeeadmission } from '../../OperationActivity/hrmspayroll/hrms/models/employeeadmission.model';

@Injectable({
    providedIn: 'root'
  })
  export class CircularService {

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         enctype: 'multipart/form-data'
      })
    };
    constructor(private httpClient: HttpClient) { }
    // Academic Year start
    getAll(): Observable<Circular[]> {
      return this.httpClient.get<Circular[]>(environment.apiUrl + '/circular/')
      .pipe(
        catchError(this.errorHandler)
      );
    }
    getById(id): Observable<Circular> {
      return this.httpClient.get<Circular>(environment.apiUrl + '/circular/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    create(feehead): Observable<any> {
      return this.httpClient.post(environment.apiUrl + '/circular/', feehead)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    update(id, feehead): Observable<Circular> {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.put<Circular>(environment.apiUrl + '/circular/' + id, JSON.stringify(feehead), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    delete(id) {
      return this.httpClient.delete<Circular>(environment.apiUrl + '/circular/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getAllEmployees(): Observable<Employeeadmission[]> {
      return this.httpClient.get<Employeeadmission[]>(environment.apiUrl + '/employeeadmission/GetEmployeeAdmission')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getAllDepartments(): Observable<Department[]> {
      return this.httpClient.get<Department[]>(environment.apiUrl + '/departments/')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }

  }
