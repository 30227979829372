import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BiomatricSettingListComponent } from './biomatric-setting-list/biomatric-setting-list.component';
import { BiomatricSettingAddComponent } from './biomatric-setting-add/biomatric-setting-add.component';
import { BiomatricSettingEditComponent } from './biomatric-setting-edit/biomatric-setting-edit.component';
import { BiomatricSettingService } from './biomatric-setting.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [BiomatricSettingListComponent, BiomatricSettingAddComponent, BiomatricSettingEditComponent],
  exports:[BiomatricSettingListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers:[BiomatricSettingService]
})
export class BiomatricSettingModule { }
