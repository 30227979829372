import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ExamPlannerComponent } from './exam-planner/exam-planner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
const routes: Routes = [
  {
    path:'examPlanner', component: ExamPlannerComponent
  }
];

@NgModule({
  declarations: [ExamPlannerComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule],
  exports: [RouterModule]
})
export class ExamPlannerModule { }
