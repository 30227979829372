import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {InstallmentSummaryService} from '../installmentsummary/installmentsummary.service';
import {InstallmentSummaryModel} from '../installmentsummary/installmentsummary.model'; 
import { ClassCourse } from '../../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../../mastersettings/classcourse/classcourse.service';
import { Academicyear } from '../../../mastersettings/models/academicyear';
import { SettingsService } from '../../../mastersettings/settings.service';
import { FineRuleMaster } from '../../../mastersettings/models/finerulemaster';
import { ReportAttribute } from '../../../studentmanagement/report.model';
import { environment } from '../../../../../../environments/environment';
import * as Excel from 'exceljs/dist/exceljs.js';
declare var $: any;
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
//const htmlToPdfmake = require("html-to-pdfmake");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-installmentsummary',
  templateUrl: './installmentsummary.component.html',
  styleUrls: ['./installmentsummary.component.css']
})
export class InstallmentsummaryComponent implements OnInit {
  @ViewChild('pdfDiv') pdfDiv!: ElementRef;
  installmentSummeryFilterHeader = "Installment Summery";
  classId = 0;
  stypeId=0;
  totalAmt="Total Amount :- 0";
  academicYearIdSelected:any;
  fineRules: FineRuleMaster[] = [];
  academicYears: Academicyear[] = [];
  classes: ClassCourse[] = [];
  instalmentSummaryList:any=[]; 
  instalmentSummaryFeeHeadList:any=[]; 
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  institution: any;
  defaultArray = [];
  currentAcademicYearId: number;
  currentAcademicYearName = '';
  dates: string;

  constructor(
    public classCourseService:ClassCourseService,
    public settingsService:SettingsService,
    public installmentSummaryService:InstallmentSummaryService
  ) { }

  listFineRules() {
    this.settingsService.getAllFineRules().subscribe((data: FineRuleMaster[]) => {
      this.fineRules = data;
      if(this.fineRules.length > 0)
      {
        this.installmentSummeryFilterHeader = this.fineRules[0].conditions;
      }
    });
  }

  listClassCourse() {
    this.classCourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }

  listAcademicYear() {
    this.settingsService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
      this.currentAcademicYear();
    });
  }

  currentAcademicYear() {
    this.settingsService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.academicYearIdSelected = data.id;
      this.currentAcademicYearName = this.academicYears.find(x => x.id.toString() === data.id.toString()).name;
    });
  }

  onAcademicYearChanged(id: number) {
   this.onSearch();
  }
  
  onClassSelected(value: string) {
    this.onSearch(); 
  } 

  filterFeeHeads(installmentId): any[] {  
    return this.instalmentSummaryFeeHeadList.filter(item => item.installmentId == installmentId);
  }

  onSearch()
  {
    debugger;
    if(this.classId!=0){
    this.installmentSummaryService.getInstallmentSummaryReport(this.academicYearIdSelected,this.classId,this.stypeId).subscribe((data) => {
      this.instalmentSummaryList = data.table;
      this.instalmentSummaryFeeHeadList=data.table1;
      this.totalAmt="Total Amount :- "+data.table2[0]["totalAmount"];
    });
  }
  }
  public formatDate(date,onlyDate =false) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return !onlyDate ? new Date().toLocaleDateString() : [day, month, year].join('-');
  }
  
  generatePDF() {
    // const pdffromdate = $('#dateFroms').val();
    // const pdftodate = $('#dateTos').val();
   debugger;
    //console.log(this.institution.logoUrl);
    this.headerList = [];
    this.fieldList = [];

    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const widthList = [];
    for (const item of this.headerList) {
      widthList.push('auto');
    }
    
    this.defaultArray = [];
    let totalAmount = 0;
    for (var i = 0; i < this.instalmentSummaryList.length; i++) {
      let data = {
        //"index": i + 1,
        "InstallmentName": this.instalmentSummaryList[i].installmentName == undefined ? "" : this.instalmentSummaryList[i].installmentName,
        //"studentName": this.instalmentSummaryList[i].studentName == undefined ? "" : this.titlecasePipe.transform(this.instalmentSummaryList[i].studentName),
        "DueDate": this.instalmentSummaryList[i].dueDate == undefined ? "" : this.instalmentSummaryList[i].dueDate,
        "LastPayDate": this.instalmentSummaryList[i].lastPayDate == undefined ? "" : this.instalmentSummaryList[i].lastPayDate,
        "TotalAmount": this.instalmentSummaryList[i].totalAmount == undefined ? "" : this.instalmentSummaryList[i].totalAmount,
        
      }
      this.defaultArray.push(data);
      totalAmount = totalAmount + Number(this.instalmentSummaryList[i].totalAmount);
    }
     let data1 = {
      "InstallmentName": "",
      "DueDate": "",
      "LastPayDate": "",
      "dueAmount": totalAmount,
    }
    this.defaultArray.push(data1);
    
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      content: [
        {
          table: {
            widths: ['auto', '*'],
            body: [
              [{
                width: 100,
                height: 100,
                image: 'imageKey'
              }, {
                width: '*',
                alignment: 'center',
                stack: [
                  {
                    style: 'h1',
                    text: this.institution?.institutionName
                  },
                  {
                    style: 'h2',
                    text: this.institution?.address
                  },
                  {
                    style: 'h2',
                    text: this.currentAcademicYearName
                  },
                  {
                    style: 'h3',
                    text: 'INSTALLMENT-SUMMERY REPORT'
                  }
                ]
              }
              ]
            ]
          },
          layout: {
            // hLineWidth: (line) => line === 1,
              hLineWidth: () =>  0,
            vLineWidth: () => 0,
            paddingBottom: () => 5,
           
          },
        },
        {
          table: {
            headerRows: 1,
            widths: widthList,
            body: [
              this.headerList,
              ...this.defaultArray.map((p) => {
                const temp = [];
                for (const y of this.fieldList) {
                  temp.push(p[y]);
                }
                return temp;
              })
            ]
          }
        }
      ],
      images: {
        // imageKey: environment.imageUrl + this.institution.logoUrl // via URL address, which can be referenced by this key
        imageKey: this.institution?.logoUrl != null ? environment.imageUrl + this.institution?.logoUrl : environment.imageUrl + '/images/avatar-1.png'
      },
      styles: {
        sectionHeader: {
         // bold: true,
         // decoration: 'underline',
        
          fontSize: 8,
          margin: [0, 10, 0, 15],
         
          
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 10,
          bold: true,
         
        },
        h2: {
          margin: [0, 5, 0, 0],
          fontSize: 10,
          bold: true,
         
        }
      }
    };

    pdfMake.createPdf(docDefinition).download('INSTALLMENT-SUMMERY.pdf');
  }

  public exportPDF() {
   
   // const pdfDiv = this.pdfDiv.nativeElement;
    // var html = htmlToPdfmake(pdfDiv.innerHTML);
    // const documentDefinition = { content: html };
    // pdfMake.createPdf(documentDefinition).download(); 
     
  }
  

  ngOnInit(): void {
   this.listFineRules();
   this.listClassCourse();
   this.listAcademicYear();
  }

}
