import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackupRestoreRoutingModule } from './backupandrestore-routing.module';
import { BackupRestoreComponent } from './backup-restore/backup-restore.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [BackupRestoreComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BackupRestoreRoutingModule
  ]
})
export class BackupRestoreModule { }
