import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { ManageleadComponent } from './managelead/managelead.component';
import { ManagedemoComponent } from './managedemo/managedemo.component';
import { LeadcreationComponent } from './leadcreation/leadcreation.component';
import { DemomanagementComponent } from './demomanagement/demomanagement.component';

const routes: Routes = [
{path: 'manage-lead', component: LeadcreationComponent},
{path: 'manage-demo', component: DemomanagementComponent}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class LeadManageRoutingModule
{
    
}