export interface ApplicantRegistration {
    id: number;
    academicYearId: string;
    classCourseId: string;
    applicationFee: string;
    entranceStatus : string;
    formNo: string;
    studentName: string;
    dob: string;
    age: string;
    gender: string;
    adharNumber: string;
    contactNumber: string;
    emailId: string;
    nationalityId: string;
    religionId: string;
    categoryId: string;
    casteId: string;
    previousSchoolName: string;
    yearOfPassing: string;
    classcourse: string;
    className: string;
    tcNumber: string;
    refferdBy: string;
    fatherFullName: string;
    fatherQualification: string;
    fatherOccupation: string;
    fatherContactNumber: string;
    fatherEmailId: string;
    motherFullName: string;
    motherQualification: string;
    motherOccupation: string;
    motherContactNumber: string;
    motherEmailId: string;
    localCountryId: string;
    localStateId: string;
    localCityId: string;
    localPINCode: string;
    localFullAddress: string;
    copyAddressStatus: string;
    permenantCountryId: string;
    permenantStateId: string;
    permenantCityId: string;
    permenantPINCode: string;
    permenantFullAddress: string;
    registerNo: string;
    registrationDate: string;
    applicationSource: string;
    applicationStatus: string;
    lastFollowupDate: string;
    nextFollowupDate: string;
    GenderMale: string;
    GenderFemale: string;
    GenderOther: string;
    enquiryNo: string;
    entranceDate: string;
    entranceTime: string;
    entranceReason: string;
    checked?: boolean;
}

export class EntranceResult {
    id: number;
    applicationStatus: string;
    entranceReason: string;
}
