import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-downloadfeesummary',
  templateUrl: './downloadfeesummary.component.html',
  styleUrls: ['./downloadfeesummary.component.css']
})
export class DownloadfeesummaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
