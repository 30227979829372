import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ClassCourseService } from './../../mastersettings/classcourse/classcourse.service';
import { Student, StudentDetails } from '../../studentmanagement/student.model';
import { ClassCourse } from './../../mastersettings/models/classcourse';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { SettingsService } from '../../mastersettings/settings.service';
import { AssignTeacher, AssignTeacherEmployee } from './assignteacher.model';
import { AssignTeacherService } from './assignteacher.service';
import { environment } from '../../../../../environments/environment';
import { ReportAttribute } from '../../studentmanagement/report.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
/// import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable.js';
declare var $: any;

@Component({
  selector: 'app-assignteacher',
  templateUrl: './assignteacher.component.html',
  styleUrls: ['./assignteacher.component.css']
})
export class AssignteacherComponent implements OnInit {

  classcourses: ClassCourse[] = [];
  classcourseid = '0';
  batchid = '-1';
  batches: Batch[] = [];
  academicYear: Academicyear = {} as Academicyear;
  assignedTeachers: AssignTeacher[] = [];
  selectedAssignedTeachers: AssignTeacher[] = [];
  employees: AssignTeacherEmployee[] = [];
  selectedClassId = 0;
  selectedBatchId = 0;
  selectedEmployeeId = 0;
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  institution: any;
  searchText:any='';
  
  loader: any = false;
showSelectedClassNameandBatchName:any;
  constructor(
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    private assignTeacherService: AssignTeacherService,
    private toastr: ToastrService,
    private academicyearService: SettingsService
  ) { }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      console.log(data);
      this.academicYear = data;
      // this.ListStudents();
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  ListBatch() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data;
    });
  }

  ListAllAssignedClasses() {
    this.loader=true;
    this.assignTeacherService.getAll().subscribe((data: AssignTeacher[]) => {
      console.log(data);
      this.selectedAssignedTeachers = this.assignedTeachers = data;
      this.loader=false;
    });
  }

  ListAllEmployees() {
    this.assignTeacherService.getAllEmployees().subscribe((data: AssignTeacherEmployee[]) => {
      this.employees = data;
    });
  }

  get totalRows(): number {
    return this.selectedAssignedTeachers.length;
  }

  get totalEmployeeRows(): number {
    return this.employees.length;
  }

  onClassSelected(value: string) {
    if (value=='0')
    {
      this.selectedAssignedTeachers = this.assignedTeachers
    }else
    {
      this.selectedAssignedTeachers = this.assignedTeachers
      .filter((data: AssignTeacher) => data.classId.toString() === value.toString());
    }

  }

  onCheckChanged(ev, id) {
    // console.log(this.selectedEmployeeId);
    // console.log(id);
    if (ev.target.checked) {
      this.selectedEmployeeId = id;
      this.employees.forEach(x => {
        if (x.id === id) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });
    } else {
      this.selectedEmployeeId = 0;
    }
  }

  onShowEmployee(classid: number, batchId: number,className:any, batchName:any) {
    this.selectedClassId = classid;
    this.selectedBatchId = batchId;
    this.showSelectedClassNameandBatchName=className+'  '+batchName
    console.log(this.selectedClassId);
    console.log(this.selectedBatchId);
  }

  asssignTeacher() {
    this.selectedEmployeeId = this.employees.filter((x) => x.checked).map(y => y.id)[0];
    const assignTeacher: AssignTeacher = {} as AssignTeacher;
    assignTeacher.classId = this.selectedClassId;
    assignTeacher.batchId = this.selectedBatchId;
    assignTeacher.academicYearId = 0;
    assignTeacher.employeeId = this.selectedEmployeeId;

    console.log(assignTeacher);

    this.assignTeacherService.create(assignTeacher).subscribe((data: AssignTeacher) => {
      this.ListAllAssignedClasses();
     this. ListAllEmployees();
      this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
        timeOut: 3000
      });
      $('#addTeacher').modal('hide');
    });

  }

  deallocateTeacher(id: any) {
    Swal.fire({
      title: 'Confirm',
      text: 'Do you want to deallocate teacher?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.assignTeacherService.delete(id).subscribe((data: AssignTeacher) => {
          this.ListAllAssignedClasses();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  generateAttributes() {
    this.attributes = [
      {name: 'CLASS', fieldName: 'className' },
      {name: 'BATCH', fieldName: 'batchName' },
      {name: 'CLASS TEACHER', fieldName: 'employeeName' },
      {name: 'Status', fieldName: 'status' }
    ];
  }

  generateExcel() {
    this.headerList = [];
    this.fieldList = [];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('ASSIGNED TEACHER LIST');
    // worksheet.addRow();
    // const imageId2 = workbook.addImage({
    //   base64: this.institution.institutionLogo,
    //   extension: 'jpg',
    // });
    // worksheet.addImage(imageId2, 'A1:B1');
    const titleRow = worksheet.addRow(['ASSIGNED TEACHER LIST']);
    const headerRow = worksheet.addRow(this.headerList);
    for (const x1 of this.selectedAssignedTeachers) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'dob') {
          temp.push(this.formatDate(x1[y]));
        } else {
          temp.push(x1[y]);
        }
      }
      worksheet.addRow(temp);
    }
    const fname = 'ASSIGNED TEACHER LIST';

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }

  generatePDF() {
    this.headerList = [];
    this.fieldList = [];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const widthList = [];
    for (const item of this.headerList) {
        widthList.push('25%');
    }
    const docDefinition = {
      content: [
        {
            table: {
                widths: ['auto', '*'],
                body: [
                    [ {
                        width: 100,
                        height: 100,
                        image: 'imageKey'
                    }, {
                        width: '*',
                        alignment: 'center',
                        stack: [
                            {
                                style: 'h1',
                                text: this.institution.institutionName
                            },
                            {
                              style: 'h2',
                              text: this.institution.address
                            },
                            {
                                style: 'h2',
                                text: '2021-22'
                            },
                            {
                                style: 'h2',
                                text: 'ASSIGNED TEACHER LIST'
                            }
                        ]
                    }
                    ]
                ]
            },
            layout: {
                hLineWidth: (line) => line === 1,
                vLineWidth: () => 0,
                paddingBottom: () => 5
            },
          },
          {
            table: {
              headerRows: 1,
              widths: widthList,
              style:'sectionHeader',
              body: [
                this.headerList,
                ...this.selectedAssignedTeachers.map((p) => {
                  const temp = [];
                  for (const y of this.fieldList) {
                    if (y === 'dob') {
                      temp.push(this.formatDate(p[y]));
                    } else {
                      temp.push(p[y]);
                    }
                  }
                  return temp;
                })
              ]
            }
          }
      ],
      images: {
        imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'

      },
      styles: {
        sectionHeader: {
          // bold: true,
          // decoration: 'underline',
          fontSize: 25,
          margin: [25, 15, 25, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 16,
          bold: true
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        }
      }
    };

    pdfMake.createPdf(docDefinition).download('ASSIGN TEACHER.pdf');

    // pdfMake.createPdf(docDefinition).download('');
  }

  ngOnInit(): void {
    this.generateAttributes();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.ListClassCourse();
    this.ListAllAssignedClasses();
    this.ListAllEmployees();
  }

}
