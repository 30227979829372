import { Time } from "@angular/common";

export class SessionPlannerVM {
    id: number;
    branchId: number;
    batchId: number;
    batchName: number;
    employeeId: number;
    sessionType: number;
    sessionDate: Date;
    time: string;
    duration: string;
    isDownload: boolean;
    isRecord: boolean;
    topicPlannerId: number;
    employeerName: string;
    subject: string;
    tutorName: string;
    students: string;
    presentStudents: number;
    topicPlannerTitle: string;
    approval: boolean;
    classId: number;
    class: number;
    subjectId: number;
    tutorId: number;
    coursePlannerId: number;
    courseName: string;
    sessionTitle:string;
    colorCode: string;
    meetingLink: string;
    sessionStatus: string;
    joinSessionCheck: boolean;
    status:boolean;
    language:string;
    canceled:boolean;
  }

 

