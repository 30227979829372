import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeeWaveOffReportService } from './feewaveoffreport.service';
import { FeewaveoffReportModel } from './feewaveoffreport.model';
import { ClassCourse } from '../../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../../mastersettings/models/batch';
import { BatchService } from '../../../mastersettings/batch/batch.service';
import { FeeCollectionReportModel } from '../feecollectionreport/feecollectionreport.model';
import { DatePipe } from '@angular/common';
import { ReportAttribute } from '../../../studentmanagement/report.model';

import { environment } from 'src/environments/environment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import { SettingsService } from '../../../mastersettings/settings.service';
import { Academicyear } from '../../../mastersettings/models/academicyear';

declare var $: any;
declare var xyz: any;
@Component({
  selector: 'app-feewaveoffreport',
  templateUrl: './feewaveoffreport.component.html',
  styleUrls: ['./feewaveoffreport.component.css']
})
export class FeewaveoffreportComponent implements OnInit {
  classDrp: string;
  addmissionDrp: string;
  search:string;
  batchDrp: string;
  mode: string;
  fromDate: string;
  toDate: string;
  totalAmount: number;
  dataCount: any;
  feewaveoffReportList: FeewaveoffReportModel[] = [];
  feewaveoffReportFilteredList: FeewaveoffReportModel[] = [];
  feewaveoffReportTemp: FeewaveoffReportModel[] = [];
  classes: ClassCourse[] = [];
  batches: Batch[] = [];

  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  defaultArray = [];
  institution: any;
  currentAcademicYearId: number;
  currentAcademicYearName = '';
  academicYears: Academicyear[] = [];

  constructor(
    public feeWaveOffReportService: FeeWaveOffReportService,
    public classCourseService: ClassCourseService,
    public batchService: BatchService,
    private academicYearService: SettingsService,
    private toastr: ToastrService,

  ) { }
  listClassCourse() {
    this.classCourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }
  onClassSelected(value: string) {
   
    this.classDrp = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
     
      this.batches = data.filter((batch: Batch) => batch.classId.toString().toLowerCase() === value.toLowerCase());
    });
    //this.onSearch();
    this.addmissionDrp = null;
    this.search = null;
  }
  currentAcademicYear() {
    this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.currentAcademicYearName = this.academicYears.find(x => x.id.toString() === data.id.toString()).name;
     
    });
  }
  onBatchChanged() {
   

  }

  onSearchAdmissionNumber() {
    this.classDrp = "Class";
    this.batchDrp = "Batch";
   this.fromDate = null;
   this.toDate = null;
    this.getFeewaveoffReport()
  }
  onSearch() {
   debugger;
   this.addmissionDrp = null;
    // const drpClass = this.classDrp;
    // const drpBatch = this.batchDrp;
    // const fromDate = this.fromDate;
    // const toDate = this.toDate;
    // var startDt: Date;
    // var endDt: Date;
    // this.feewaveoffReportTemp = this.feewaveoffReportFilteredList = this.feewaveoffReportList;

    // if (typeof this.fromDate != 'undefined' && typeof this.toDate != 'undefined' && this.fromDate != '' && this.toDate != '') {
    //   startDt = new Date(fromDate);
    //   endDt = new Date(toDate)
    //   this.feewaveoffReportTemp = this.feewaveoffReportTemp.filter(d => {
    //     var date = new Date(d.waveoffDate);
    //     return (startDt.getTime() <= date.getTime() && endDt.getTime() >= date.getTime());
    //   });
    // }

    // if (drpClass != "All" && drpClass != "" && drpClass != 'undefined' && drpClass != 'Select Class') {
    //   this.feewaveoffReportTemp = this.feewaveoffReportTemp.filter(d => {
    //     return (d.class.toLowerCase().includes(drpClass.toLowerCase()));
    //   });
    // }

    // if (drpBatch != "All" && drpBatch != "" && drpBatch != 'undefined' && drpBatch != 'Batch') {
    //   this.feewaveoffReportTemp = this.feewaveoffReportTemp.filter(d => {
    //     return (d.batch.toLowerCase().includes(drpBatch.toLowerCase()));
    //   });
    // }

    // this.feewaveoffReportFilteredList = this.feewaveoffReportTemp;

    this.getFeewaveoffReport();
    if (this.fromDate != undefined && this.fromDate != '' && this.fromDate != null &&
      this.toDate == undefined || this.toDate == '' || this.toDate == null) {
      this.toastr.warning('Please select date', 'WARNING!', {
        timeOut: 3000
      });
    }


    // this.totalAmount = this.feewaveoffReportFilteredList.filter(item => item.installmentWaveOffAmount)
    //                         .map(item => parseFloat(item.installmentWaveOffAmount))
    //                         .reduce((sum, current) => sum + current);

  }
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === '') {
      this.onSearch();
    } else {
      this.feewaveoffReportTemp = this.feewaveoffReportTemp.filter(d => {
        return (d.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
      });
      this.feewaveoffReportFilteredList = this.feewaveoffReportTemp;
    }
  }

  resetForm() {
    this.classes = [];
    this.batches = []; 
    this.classDrp = 'Class';
    this.addmissionDrp = null;
   // this.search = '';
    this.batchDrp = 'Batch';
   // this.mode = '';
    this.totalAmount = 0;    
    this.feewaveoffReportList = [];
    this.feewaveoffReportFilteredList = [];
    this.feewaveoffReportTemp = [];
    // $('#dateFrom').val(this.formatDate(new Date()));
    // this.fromDate = this.formatDate(new Date());
    // $('#dateTo').val(this.formatDate(new Date()));
    // this.toDate = this.formatDate(new Date());
    this.fromDate = null;
    this.toDate = null;
       
  }
  getFeewaveoffReport() {
    debugger;    
    const drpClass = this.classDrp == 'Class' ? 0 : parseInt(this.classDrp);
    const drpBatch = this.batchDrp == 'Batch' ? 0 : parseInt(this.batchDrp);

    if (this.fromDate != undefined && this.fromDate != '' && this.fromDate != null &&
      this.toDate != undefined && this.toDate != '' && this.toDate != null) {
      const datePipe = new DatePipe('en-Us');
      var fdt = datePipe.transform(this.fromDate, 'yyyy-MM-dd');
      var tdt = datePipe.transform(this.toDate, 'yyyy-MM-dd');

      this.feeWaveOffReportService.getFeewaveoffReport(drpClass, drpBatch, fdt, tdt, this.addmissionDrp == undefined? '':this.addmissionDrp).subscribe((data: FeewaveoffReportModel[]) => {
        this.feewaveoffReportFilteredList = this.feewaveoffReportList = data;
        this.totalAmount = data.map(item => parseFloat(item.installmentWaveOffAmount)).reduce((prev, curr) => prev + curr, 0);
      });
    }
    else if (this.addmissionDrp != null && this.addmissionDrp != undefined) {
      this.feeWaveOffReportService.getFeewaveoffReport(0, 0, '01-01-1990', '01-01-2099', this.addmissionDrp).subscribe((data: FeewaveoffReportModel[]) => {
        this.feewaveoffReportFilteredList = this.feewaveoffReportList = data;
        this.totalAmount = data.map(item => parseFloat(item.installmentWaveOffAmount)).reduce((prev, curr) => prev + curr, 0);
      });
    }
  }

  generatePDF() {
   
    const pdffromdate = $('#dateFrom').val();
    const pdftodate = $('#dateTo').val();
    this.headerList = [];
    this.fieldList = [];

    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const widthList = [];
    for (const item of this.headerList) {
      widthList.push('auto');
    }

    this.defaultArray = [];
    let totalAmount = 0;
    for (var i = 0; i < this.feewaveoffReportFilteredList.length; i++) {
      let data = {
        "index": i + 1,
        "waveoffDate": this.feewaveoffReportFilteredList[i].waveOffDate == undefined ? "" : this.feewaveoffReportFilteredList[i].waveOffDate,
        "admissionNo": this.feewaveoffReportFilteredList[i].admissionNo == undefined ? "" : this.feewaveoffReportFilteredList[i].admissionNo,
        "studentName": this.feewaveoffReportFilteredList[i].studentName == undefined ? "" : this.feewaveoffReportFilteredList[i].studentName,
        "class": this.feewaveoffReportFilteredList[i].class == undefined ? "" : this.feewaveoffReportFilteredList[i].class + " " + this.feewaveoffReportFilteredList[i].batch,
        "batch": this.feewaveoffReportFilteredList[i].batch == undefined ? "" : this.feewaveoffReportFilteredList[i].batch,
        "rollNo": this.feewaveoffReportFilteredList[i].rollNo == undefined ? "" : this.feewaveoffReportFilteredList[i].rollNo,
        "installment": this.feewaveoffReportFilteredList[i].installment == undefined ? "" : this.feewaveoffReportFilteredList[i].installment,
        "installmentWaveOffAmount": this.feewaveoffReportFilteredList[i].installmentWaveOffAmount == undefined ? "" : this.feewaveoffReportFilteredList[i].installmentWaveOffAmount,
        "waveOffBy": this.feewaveoffReportFilteredList[i].waveOffBy == undefined ? "" : this.feewaveoffReportFilteredList[i].waveOffBy,
        "remarks": this.feewaveoffReportFilteredList[i].remarks == undefined ? "" : this.feewaveoffReportFilteredList[i].remarks,
      }
      this.defaultArray.push(data);
      totalAmount = totalAmount + Number(this.feewaveoffReportFilteredList[i].installmentWaveOffAmount);
    }
    let data1 = {
      "index": "",
      "waveoffDate": "",
      "admissionNo": "",
      "studentName": "",
      "class": "",
      "batch": "",
      "rollNo": "",
      "installment": "Total WaveOff Amt",
      "installmentWaveOffAmount": totalAmount,
      "waveOffBy": "",
      "remarks": ""
    }
    this.defaultArray.push(data1);
    const docDefinition = {
      pageSize: 'A3',
      pageOrientation: 'portrait',
      content: [
        {
          table: {
            widths: ['auto', '*'],
            body: [
              [{
                width: 100,
                height: 100,
                image: 'imageKey'
              }, {
                width: '*',
                alignment: 'center',
                stack: [
                  {
                    style: 'h1',
                    text: this.institution?.institutionName
                  },
                  {
                    style: 'h2',
                    text: this.institution?.address
                  },
                  {
                    style: 'h2',
                    text: this.currentAcademicYearName
                  },
                  {
                    style: 'h3',
                    text: 'FEEWAVEOFF REPORT COLLECTION'
                  },
                  {
                    style: 'h3',
                    text: pdffromdate + '   to   ' + pdftodate
                  }
                ]
              }
              ]
            ]
          },
          layout: {
            hLineWidth: () => 0,
            vLineWidth: () => 0,
            paddingBottom: () => 3,
            paddingLeft: () => 5,
            paddingRight: () => 5
          },
        },
        {
          style: 'sectionHeader',
          table: {
            headerRows: 1,
            widths: widthList,
            body: [
              this.headerList,
              ...this.defaultArray.map((p) => {
                const temp = [];
                for (const y of this.fieldList) {
                  if (y === 'dob') {
                    temp.push(this.formatDate(p[y]));
                  } else {
                    temp.push(p[y]);
                  }
                }
                return temp;
              })
            ]
          }
        }
      ],
      images: {
        // imageKey: environment.imageUrl + this.institution.logoUrl // via URL address, which can be referenced by this key
        imageKey: this.institution?.logoUrl != null ? environment.imageUrl + this.institution?.logoUrl : environment.imageUrl + '/images/avatar-1.png'
      },
      styles: {
        sectionHeader: {
          fontSize: 8,
          margin: [0, 10, 0, 15],
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 13,
          bold: true,
        },
        h2: {
          margin: [0, 5, 0, 0],
          fontSize: 10,
          bold: true,
        }
      }
    };
    pdfMake.createPdf(docDefinition).download('FEEWAVEOFF REPORT COLLECTION.pdf');
  }

  generateExcel() {
   
    this.headerList = [];
    this.fieldList = [];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    this.defaultArray = [];
    let totalAmount = 0;
    for (var i = 0; i < this.feewaveoffReportFilteredList.length; i++) {
      let data = {
        "index": i + 1,
        "waveoffDate": this.feewaveoffReportFilteredList[i].waveOffDate == undefined ? "" : this.feewaveoffReportFilteredList[i].waveOffDate,
        "admissionNo": this.feewaveoffReportFilteredList[i].admissionNo == undefined ? "" : this.feewaveoffReportFilteredList[i].admissionNo,
        "studentName": this.feewaveoffReportFilteredList[i].studentName == undefined ? "" : this.feewaveoffReportFilteredList[i].studentName,
        "class": this.feewaveoffReportFilteredList[i].class == undefined ? "" : this.feewaveoffReportFilteredList[i].class + " " + this.feewaveoffReportFilteredList[i].batch,
        "batch": this.feewaveoffReportFilteredList[i].batch == undefined ? "" : this.feewaveoffReportFilteredList[i].batch,
        "rollNo": this.feewaveoffReportFilteredList[i].rollNo == undefined ? "" : this.feewaveoffReportFilteredList[i].rollNo,
        "installment": this.feewaveoffReportFilteredList[i].installment == undefined ? "" : this.feewaveoffReportFilteredList[i].installment,
        "installmentWaveOffAmount": this.feewaveoffReportFilteredList[i].installmentWaveOffAmount == undefined ? "" : this.feewaveoffReportFilteredList[i].installmentWaveOffAmount,
        "waveOffBy": this.feewaveoffReportFilteredList[i].waveOffBy == undefined ? "" : this.feewaveoffReportFilteredList[i].waveOffBy,
        "remarks": this.feewaveoffReportFilteredList[i].remarks == undefined ? "" : this.feewaveoffReportFilteredList[i].remarks,
      }
      this.defaultArray.push(data);
      totalAmount = totalAmount + Number(this.feewaveoffReportFilteredList[i].installmentWaveOffAmount);
    }
    let data1 = {
      "index": "",
      "waveoffDate": "",
      "admissionNo": "",
      "studentName": "",
      "class": "",
      "batch": "",
      "rollNo": "",
      "installment": "Total WaveOff Amt",
      "installmentWaveOffAmount": totalAmount,
      "waveOffBy": "",
      "remarks": ""
    }
    this.defaultArray.push(data1);
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('FEEWAVEOFF REPORT COLLECTION');

    // const imageId2 = workbook.addImage({
    //   filename: 'images/avatar-1.png',
    //   extension: 'png',
    // });
    // worksheet.addImage(imageId2, 'A1:B1');

    const instName = worksheet.addRow([this.institution.institutionName]);
    instName.eachCell(function (cell, colNum) {
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 10
      }
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'start'
      }
    })
    instName.height = 25;
    const instAddress = worksheet.addRow([this.institution.address]);
    instAddress.eachCell(function (cell, colNum) {
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 9
      }
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'start'
      }
    })
    instAddress.height = 20;

    const instSession = worksheet.addRow([this.currentAcademicYear.name]);
    instSession.eachCell(function (cell, colNum) {
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 10
      }
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'start'
      }
    })
    instSession.height = 20;

    worksheet.addRow([]);
    const titleRow = worksheet.addRow(['FEEWAVEOFF REPORT COLLECTION']);
    const fromDate = $('#dateFrom').val();
    const toDate = $('#dateTo').val();
    worksheet.addRow([`${fromDate} to ${toDate}`]);
    const headerRow = worksheet.addRow(this.headerList);
    for (var i = 0; i < this.defaultArray.length; i++)
      this.defaultArray[i]["index"] = i + 1;
    for (const x1 of this.defaultArray) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'payDate') {
          if (x1[y] == "" || x1[y] == undefined) {
            temp.push("");
          } else {
            temp.push(this.formatDate(x1[y]));
          }
        } else {
          if (y === "paidStatus") {
            if (x1[y] === true || x1[y] === false) {
              temp.push(x1[y] === true ? "Cancelled" : "Paid");
            } else {
              temp.push("")
            }
          }
          else {
            temp.push(x1[y]);
          }
        }
      }
      worksheet.addRow(temp);
    }
    const fname = 'FEEWAVEOFF REPORT COLLECTION';

    worksheet.eachRow((row, rowNumber) => {

      row.eachCell((cell, colNumber) => {
        if ([1, 2, 3, 4, 5, 6].includes(rowNumber)) {
        } else {
          // Set border of each cell 
          cell.width = 180;
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        }

      })
      //Commit the changed row to the stream
      row.commit();
    });

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }



  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return [day, month, year].join('-');
  }

  generateAttributes() {
    this.attributes = [
      { name: 'SNO', fieldName: 'index' },
      { name: 'WAVEOFFDATE', fieldName: 'waveoffDate' },
      { name: 'ADMSN NO', fieldName: 'admissionNo' },
      { name: 'STUDENT NAME', fieldName: 'studentName' },
      { name: 'CLASS', fieldName: 'class' },
      { name: 'BATCH', fieldName: 'batch' },
      { name: 'ROLL NO', fieldName: 'rollNo' },
      { name: 'INST', fieldName: 'installment' },
      { name: 'WAVEOFAMT', fieldName: 'installmentWaveOffAmount' },
      { name: 'WAVEOFFBY', fieldName: 'waveOffBy' },
      { name: 'REMARK', fieldName: 'remarks' }

    ];
  }


  ngOnInit(): void {
    const self = this;
    this.classDrp = "Class";
    this.batchDrp = "Batch";
    $('#dateFrom').datepicker().on('changeDate', (e) => {
     
      this.fromDate = e.date;
      self.fromDate = e.date;
    });
    $('#dateTo').datepicker().on('changeDate', (e) => {
      this.toDate = e.date;
      self.toDate = e.date;
    });
    this.listClassCourse();
    this.generateAttributes();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    //this.getFeewaveoffReport();
  }



}
