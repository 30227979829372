import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from './notification.service';
import { Notification } from './notification.model';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit, OnDestroy  {

  private subscriptionName: Subscription;
  notifications: Notification[] = [];

  constructor(
    public notificationService: NotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.subscriptionName = this.notificationService.getUpdate().subscribe
          ((message: Notification[]) => {
            this.notifications = message;
      });
   }

  ListNotification() {
    this.notificationService.getAll().subscribe((data: Notification[]) => {
      this.notifications = data;
      this.notificationService.setMessageCount(data.filter((x: Notification) => x.isViewed === false).length);
    });
  }

  UpdateNotification(messageId: number) {
    const nowUpdate = this.notifications.filter((x: Notification) => {
      return x.id === messageId;
    })[0];
    if (!nowUpdate.isViewed) {
      nowUpdate.isViewed = true;
      this.notificationService.updateIsViewed(messageId, nowUpdate).subscribe(res => {
        this.notificationService.setMessageCount(this.notifications.filter((x: Notification) => {
          return x.isViewed === false;
        }).length);
        this.router.navigate(['../admin/communication/activitylog'], {relativeTo: this.activatedRoute});
      });
    } else {
      this.router.navigate(['../admin/communication/activitylog'], {relativeTo: this.activatedRoute});
    }
  }

  viewAllNotification() {
      this.notificationService.updateAllViews().subscribe(res => {
        this.notificationService.setMessageCount(this.notifications.filter((x: Notification) => {
          return x.isViewed === false;
        }).length);
        this.router.navigate(['../admin/communication/activitylog'], {relativeTo: this.activatedRoute});
      });
  }

  get totalRows(): number {
    return this.notifications.length;
  }

  ngOnInit(): void {
    this.ListNotification();
  }

  ngOnDestroy() {
    this.subscriptionName.unsubscribe();
  }

}
