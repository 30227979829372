import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FeeHeadWiseReportResponse, FeeHeadWiseReportVm } from './feeheadwisecollectionreport.model';
import { environment } from '../../../../../../environments/environment';
import { convertObjectToHttpParams, removeWithoutValuePropertiesFromObject } from 'src/app/shared/helpers/utility';

@Injectable({
  providedIn: 'root'
})
export class FeeHeadwiseCollectionReportService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private httpClient: HttpClient) { }

  getFeeHeadWiseCollectionReport(feeHeadWiseReportVm: FeeHeadWiseReportVm): Observable<FeeHeadWiseReportResponse> {
    removeWithoutValuePropertiesFromObject(feeHeadWiseReportVm);
    const params = convertObjectToHttpParams(feeHeadWiseReportVm);
    
    return this.httpClient.get<FeeHeadWiseReportResponse>(environment.apiUrl + '/FeeReport/GetFeeHeadWiseReport', { params: params })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }


}
