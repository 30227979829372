import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModuleManagementServiceModel } from './modeumanagementmodel';


@Injectable({
  providedIn: 'root'
})
export class ModuleManagementService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<ModuleManagementServiceModel[]> {
    ;
    return this.httpClient.get<ModuleManagementServiceModel[]>(environment.apiUrl + '/MenuMainModule/')
    .pipe(
      catchError(this.errorHandler)
    );
  } 

  getAllActiveMenus(): Observable<ModuleManagementServiceModel[]> {
    ;
    return this.httpClient.get<ModuleManagementServiceModel[]>(environment.apiUrl + '/MenuMainModule/getallactivemenus')
    .pipe(
      catchError(this.errorHandler)
    );
  } 
  getById(id): Observable<ModuleManagementServiceModel> {
    return this.httpClient.get<ModuleManagementServiceModel>(environment.apiUrl + '/MenuMainModule/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getSubmenusByModuleId(id: Number): Observable<any> {
    return this.httpClient.get<any>(environment.apiUrl + '/MenuSubModule/?module_id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  deleteSubmenusById(id: Number): Observable<any> {
    return this.httpClient.delete<any>(environment.apiUrl + '/MenuSubModule/?module_id=' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(ModuleManagementServiceModel): Observable<ModuleManagementServiceModel> {
    return this.httpClient.post<ModuleManagementServiceModel>(environment.apiUrl + '/MenuMainModule/', JSON.stringify(ModuleManagementServiceModel), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  createSubMenu(data: any): Observable<string> {
    return this.httpClient.post<string>(environment.apiUrl + '/MenuSubModule/', JSON.stringify(data), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, ModuleManagementServiceModel): Observable<ModuleManagementServiceModel> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<ModuleManagementServiceModel>(environment.apiUrl + '/MenuMainModule/' + id, JSON.stringify(ModuleManagementServiceModel), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<ModuleManagementServiceModel>(environment.apiUrl + '/MenuMainModule/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }

}
