import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { InactiveStudent, Student } from './../../studentmanagement/student.model';
import { StudentService } from './../../studentmanagement/student.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-inactivestudentpromotion',
  templateUrl: './inactivestudentpromotion.component.html',
  styleUrls: ['./inactivestudentpromotion.component.css']
})
export class InactivestudentpromotionComponent implements OnInit {

  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  inactiveList: InactiveStudent[] = [];
  filterInactiveList: InactiveStudent[] = [];
  classId = '';
  batchId = '';
  admissionno = '';
  admissionId = '';
  selectedFilteredAdmissions: Student[] = [];
  filteredAdmissions: Student[] = [];
  admissions: Student[] = [];

  constructor(
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    private toastr: ToastrService) { }

    ListInactiveStudents() {
      this.studentService.getInactiveStudents().subscribe((data: InactiveStudent[]) => {
        console.log(data);
        this.inactiveList = JSON.parse(JSON.stringify(data));
        this.filterInactiveList = JSON.parse(JSON.stringify(data));
      });
    }

    ListClassCourse() {
      this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
        this.classes = data;
      });
    }

    onClassSelected(value: string) {
      this.batches = [];
      // this.resetEForm();
      this.classId = value;
      if (this.classId === '') {
        this.filterInactiveList = JSON.parse(JSON.stringify(this.inactiveList));
      } else {
        this.batchService.getAll().subscribe((data: Batch[]) => {
          this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
          console.log(this.batches);
        });
      }
    }

    onBatchChanged(value: string) {
      this.filterInactiveList = JSON.parse(JSON.stringify(this.inactiveList));
      if (this.batchId !== '') {
        this.filterInactiveList = this.filterInactiveList.filter((inactive: InactiveStudent) => {
          return inactive.classId.toString() === this.classId &&
          inactive.batchId.toString() === value.toString();
          });
      } else {
        this.filterInactiveList = this.filterInactiveList.filter((inactive: InactiveStudent) => {
          return inactive.classId.toString() === this.classId;
        });
      }
    }

    search() {
      this.filterInactiveList = JSON.parse(JSON.stringify(this.inactiveList));
      if (this.classId === '' && this.batchId === '') {
        this.filterInactiveList = this.filterInactiveList.filter((inactive: InactiveStudent) => {
          return inactive.admissionNo.toString().includes(this.admissionno.toString());
          });
      } else {
      this.filterInactiveList = this.filterInactiveList.filter((inactive: InactiveStudent) => {
        return inactive.classId.toString() === this.classId &&
        inactive.batchId.toString() === this.batchId.toString() &&
        inactive.admissionNo.toString() === this.admissionno.toString();
        });
      }
    }

  ActivateStudent(admissionId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Make Active!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.studentService.makeStudentActive(admissionId).subscribe((data) => {
          this.toastr.success('Student marked as active', 'SUCCESS!', {
            timeOut: 3000
          });
          this.ListInactiveStudents();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  ngOnInit(): void {
    this.ListInactiveStudents();
    this.ListClassCourse();
  }
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
   
    if (this.selectedFilteredAdmissions.length === 0) {
      if ( keyword === '') {
        this.filteredAdmissions = this.admissions;
      } else {
      this.filteredAdmissions = this.admissions
                                  .filter((admission: Student) =>
                                  admission.admissionNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.smsMobNumber.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.fatherName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
                                  );
      }
    } else {
      if ( keyword === '') {
        this.filteredAdmissions = this.selectedFilteredAdmissions;
      } else {
      this.filteredAdmissions = this.selectedFilteredAdmissions
                                  .filter((admission: Student) =>
                                  admission.admissionNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.fatherName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
                                  );
      }
    }
  }

}
