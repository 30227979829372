// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgRadius{
    border-radius: 15px;
    height: 35px;
    width: 35px;
}
.colFont{
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/academic/studentmanagement/studentsummary/studentsummary.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,WAAW;AACf;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".imgRadius{\r\n    border-radius: 15px;\r\n    height: 35px;\r\n    width: 35px;\r\n}\r\n.colFont{\r\n    font-weight: 500;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
