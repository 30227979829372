import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs"; 
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { SubjectCreation } from '../../../academic/academicsettings/subjectcreation/subjectcreation.model';
import { SubjectcreationService } from '../../../academic/academicsettings/subjectcreation/subjectcreation.service';
import { CreateQuestionService } from '../services/create-question.service';
import { Topic } from '../../mastersettings/models/topic';
import { Language } from '../../mastersettings/models/language';


@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.css'],
  providers: [FormGroupDirective],
})
export class QuestionListComponent implements OnInit {
  submitted = false; 
  finalexamNamedropDown: any = [];
  examClassdropDown: any = [];
  classDropDown: any = [];
  DetailsResult: any = [];
  subjects: SubjectCreation[] = [];
  topics: any = [];
  languageList:any=[];
  isLoading: boolean = false; 
  classes: ClassCourse[] = [];
  disabled = true;
  QuestionList: any = [];

  constructor(private toastr: ToastrService,
    public fb: UntypedFormBuilder,
    public service: CreateQuestionService,
    public subjectService: SubjectcreationService,
    public classcourseService: ClassCourseService,
    public formDirective: FormGroupDirective) { }

    questionList: UntypedFormGroup;
   

  ngOnInit(): void {
    this.questionList = this.fb.group({
      id: [0],
      classId: ["", Validators.required],
      subjectId: ["", Validators.required],
      languageId: ["", Validators.required],
      topicId: ["", Validators.required]
    });

    
    this.getClassDropDown();
    this.getSubjectList(0);
    this.getTopicList(0);
    this.getLanguageList(0);
  }

  get f() {
    return this.questionList.controls;
  }
  getSubjectList(itemId){
    this.subjectService.getAll().subscribe((data) => {
      data = data.filter((item) => {
        return item.isDeleted==false;
      });
      console.log(data);
      this.subjects = data;
    });
  }

  getTopicList(itemId){
    this.service.getAllTopicList().subscribe((data:Topic[]) => {
      this.topics = data.filter((item) => {
        return item.isDeleted==false;
      });
      console.log(data);      
    });
  }

  getLanguageList(itemId){
    this.service.getAllLanguageList().subscribe((data:Language[]) => {
      this.languageList = data;//.filter((item) => {
        //return item.isDeleted==false;
      //});
      console.log(data);      
    });
  }

  onClassEvent() {   
    this.checkingButton();   
    this.questionList = this.fb.group({
      id: [0],
      classId: [this.questionList.controls.classId.value, Validators.required],
      subjectId: ["all", Validators.required],
      languageId: ["all", Validators.required],
      topicId: ["all", Validators.required]
    }); 
  }

  onSubjectEvent() {   
    this.checkingButton();   
    this.questionList = this.fb.group({
      id: [0],
      classId: [this.questionList.controls.classId.value, Validators.required],
      subjectId: [this.questionList.controls.subjectId.value, Validators.required],
      languageId: ["all", Validators.required],
      topicId: ["all", Validators.required]
    }); 
  }

  onLanguageEvent() {   
    this.checkingButton();   
    this.questionList = this.fb.group({
      id: [0],
      classId: [this.questionList.controls.classId.value, Validators.required],
      subjectId: [this.questionList.controls.subjectId.value, Validators.required],
      languageId: [this.questionList.controls.languageId.value, Validators.required],
      topicId: ["all", Validators.required]
    }); 
  }

  onTopicEvent() {   
    this.checkingButton();   
    this.questionList = this.fb.group({
      id: [0],
      classId: [this.questionList.controls.classId.value, Validators.required],
      subjectId: [this.questionList.controls.subjectId.value, Validators.required],
      languageId: [this.questionList.controls.languageId.value, Validators.required],
      topicId: [this.questionList.controls.topicId.value, Validators.required]
    }); 
  }
  getClassDropDown() {       
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classDropDown = data;
      this.examClassdropDown = data;
      this.checkingButton();
    });
    
      this.questionList = this.fb.group({
        id: [0],
        classId: [this.questionList.controls.classId.value, Validators.required],
        subjectId: ["all", Validators.required],
        languageId: ["all", Validators.required],
        topicId: ["all", Validators.required]
      }); 
  }
  checkingButton() {
    if (
      this.questionList.controls.classId.value &&
      this.questionList.controls.subjectId.value &&
      this.questionList.controls.languageId.value &&
      this.questionList.controls.topicId.value 
    ) {
      this.disabled = false;
    } 
  }

  getAllQuestionList() {
    this.isLoading = true;
     if (this.questionList.valid) {
          this.service.GetQuestionList(
          this.questionList.controls.classId.value,
          this.questionList.controls.subjectId.value == "all" ? -1 : this.questionList.controls.subjectId.value,
          this.questionList.controls.languageId.value == "all" ? -1 : this.questionList.controls.languageId.value,
          this.questionList.controls.topicId.value == "all" ? -1 : this.questionList.controls.topicId.value
        ).subscribe((data) => {
          this.QuestionList = data;               
          this.isLoading = false;
        });
      }
      else{
        this.toastr.warning("All field are required", "WARNING!!!", {
          timeOut: 3000,
        });
        this.isLoading = false;
        return;
      }
  }

  getQuestionDetails(subid,i){    
    
    this.service.GetExamQuestionDetails(i).subscribe((data)=>{
      this.DetailsResult=data;
    });
  }

  deleteExamQuestion(subid,i){ 
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton:true,
      confirmButtonText:'Yes, delete it!',
      cancelButtonText:'No, keep it'
    }).then((result)=>{
      if(result.value){
        this.service.deleteExamQuestion(i).subscribe(data=>{
          Swal.fire(
            'Deleted!',
            'Question has been deleted.',
            'success'
          );
          this.getAllQuestionList();
        })
      }
    })
  }


  resetForm() {
    this.submitted = false;
    this.finalexamNamedropDown = [];
    this.classDropDown = [];
    this.QuestionList=[];
    this.formDirective.reset();
    this.questionList.reset();
    this.disabled = true;
    this.questionList = this.fb.group({
        id: [0],
        classId: ["", Validators.required],
        subjectId: ["all", Validators.required],
        languageId: ["all", Validators.required],
        topicId: ["all", Validators.required]
      });
  }

}
