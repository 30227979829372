import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Student, StudentClass, StudentAdmissionReq,  StudentMinimumInfo, StudentReq, InactiveStudent, StudentsSiblingsReport } from './student.model';
import { House } from './house.mode';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  httpOptionsC = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'enc-type': 'multipart/form-data'
    })
  };
  constructor(private httpClient: HttpClient) { }

  getAll(): Observable<Student[]> {
    return this.httpClient.get<Student[]>(environment.apiUrl + '/StudentAdmission/GetStudents')
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getAllStudentsReports(): Observable<Student[]> {
    return this.httpClient.get<Student[]>(environment.apiUrl + '/StudentAdmission/GetStudentReports')
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getCountStudents(academicYearId): Observable<number> {
    return this.httpClient.get<number>(environment.apiUrl + '/StudentAdmission/GetCountStudents/'+academicYearId)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getStudentByAcademicYearIdWithType(academicYearId,classId,batchId,status,studenttype): Observable<Student[]> {
    return this.httpClient.get<Student[]>(environment.apiUrl + '/StudentAdmission/GetAllStudentFilterByAcademicYearIdWithType/'+academicYearId+'/'+classId+'/'+batchId+'/'+status+'/'+studenttype)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getStudentByAcademicYearId(academicYearId,classId,batchId,status): Observable<Student[]> {
    return this.httpClient.get<Student[]>(environment.apiUrl + '/StudentAdmission/GetAllStudentFilterByAcademicYearId/'+academicYearId+'/'+classId+'/'+batchId+'/'+status)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getStudentById(id): Observable<Student> {
    return this.httpClient.get<Student>(environment.apiUrl + '/StudentAdmission/GetStudentById/'+id)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getAllStudentList(): Observable<Student[]> {
    return this.httpClient.get<Student[]>(environment.apiUrl + '/StudentAdmission/GetStudentsList')
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getAllStudentListForCertificate(): Observable<Student[]> {
    return this.httpClient.get<Student[]>(environment.apiUrl + '/StudentAdmission/GetStudentsListForCertificate')
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getAllReport(): Observable<Student[]> {
    return this.httpClient.get<Student[]>(environment.apiUrl + '/StudentAdmission/')
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getAllByAcademicYear(id): Observable<Student[]> {
    return this.httpClient.get<Student[]>(environment.apiUrl + '/StudentAdmission/GetStudentsByAcademicYear/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getById(id): Observable<Student> {
    return this.httpClient.get<Student>(environment.apiUrl + '/StudentAdmission/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getHouse(): Observable<House[]> {
    return this.httpClient.get<House[]>(environment.apiUrl + '/House/')
      .pipe(
        catchError(this.errorHandler)
    );
  }

  getInactiveStudents(): Observable<InactiveStudent[]> {
    return this.httpClient.get<InactiveStudent[]>(environment.apiUrl + '/StudentAdmission/GetInactiveStudents')
      .pipe(
        catchError(this.errorHandler)
    );
  }

  getStudentsSiblingsReports(): Observable<StudentsSiblingsReport[]> {
    return this.httpClient.get<StudentsSiblingsReport[]>(environment.apiUrl + '/StudentAdmission/GetStudentsSiblingsReports')
      .pipe(
        catchError(this.errorHandler)
    );
  }

  getdetails(id): Observable<Student> {
    return this.httpClient.get<Student>(environment.apiUrl + '/StudentAdmission/GetStudentAdmissionDetials/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getdetailsWithAcademicId(id,academicId): Observable<Student> {
    return this.httpClient.get<Student>(environment.apiUrl + '/StudentAdmission/GetStudentAdmissionDetailsWithAcademicId/'+ id+'/'+academicId)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getprofile(id): Observable<Student> {
    return this.httpClient.get<Student>(environment.apiUrl + '/StudentAdmission/GetStudentProfile/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  generateAdmissionNo() {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(environment.apiUrl + '/StudentAdmission/GenerateAdmissionNumber', { responseType: 'json' })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  makeStudentInactive(admission): Observable<string> {
    return this.httpClient.post<string>(environment.apiUrl + '/StudentAdmission/MakeStudentInactive', JSON.stringify(admission),
      this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  makeStudentActive(admissionId): Observable<string> {
    return this.httpClient.post<string>(environment.apiUrl + '/StudentAdmission/MakeStudentActive/' + admissionId, admissionId,
      this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  create(admission): Observable<Student> {
    // ;
    // var reqData = { 'Data': admission };
    // for (let i = 0; i < admission.length; i++) {
    return this.httpClient.post<Student>(environment.apiUrl + '/StudentAdmission', JSON.stringify(admission),
      this.httpOptionsC)
      .pipe(
        catchError(this.errorHandler)
      );
    // }
  }
  bulkCreate(admission): Observable<Student> {
    var reqData = { 'Data': admission };
    // for (let i = 0; i < admission.length; i++) {
    return this.httpClient.post<Student>(environment.apiUrl + '/StudentAdmission/CreateStudents', JSON.stringify(admission),
      this.httpOptionsC)
      .pipe(
        catchError(this.errorHandler)
      );
    // }
  }
  getAllStudentsMinimumInfo(req:StudentReq): Observable<StudentMinimumInfo[]> {    
    
    return this.httpClient.post<StudentMinimumInfo[]>(environment.apiUrl + '/StudentAdmission/GetStudentMinimunInfo',JSON.stringify(req),
    this.httpOptionsC)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  getStudentDetailsByAdmisiion(req:StudentAdmissionReq): Observable<Student> {    
    
    return this.httpClient.post<Student>(environment.apiUrl + '/StudentAdmission/StudentAdmissionDetail',JSON.stringify(req),
    this.httpOptionsC)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  // create(admission): Observable<any> {
  //   return this.httpClient.post(environment.apiUrl + '/StudentAdmission/', admission, {responseType: 'text'})
  //   .pipe(
  //     catchError(this.errorHandler)
  //   );
  // }

  // create(admission): Observable<any> {
  //     let object = {};
  //     admission.forEach((value, key) => {
  //       if (!Reflect.has(object, key)) {
  //         object[key] = value;
  //         return;
  //       }
  //       if (!Array.isArray(object[key])) {
  //         object[key] = [object[key]];
  //       }
  //       object[key].push(value);
  //     });
  //     let json = JSON.stringify(object);
  //     console.log(json);
  //     return this.httpClient.post(environment.apiUrl + '/StudentAdmission/', json, this.httpOptions)
  //   .pipe(
  //     catchError(this.errorHandler)
  //   );
  // }

  update(id, batch): Observable<Student> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<Student>(environment.apiUrl + '/StudentAdmission/' + id, JSON.stringify(batch), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  updateFields(id, batch): Observable<Student> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.post<Student>(environment.apiUrl + '/StudentAdmission/UpdateSelectedFields/' + id, JSON.stringify(batch), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  delete(id) {
    return this.httpClient.delete<Student>(environment.apiUrl + '/StudentAdmission/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  savebulkimage(resultList): Observable<Student> {
    return this.httpClient.post<Student>(environment.apiUrl +
      '/StudentAdmission/SaveBulkImage/', JSON.stringify(resultList), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json', responseType: 'text'
        })
    })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  assignrollno(resultList): Observable<Student> {
    return this.httpClient.post<Student>(environment.apiUrl +
      '/StudentAdmission/AssignRollNo/', JSON.stringify(resultList), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json', responseType: 'text'
        })
    })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  savestudentclass(resultList,isPassOut): Observable<Student> {
    return this.httpClient.post<Student>(environment.apiUrl +
      '/StudentAdmission/SaveClassPromotion/?isPassout='+isPassOut, JSON.stringify(resultList), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json', responseType: 'text'
        })
    })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  changeclass(resultList): Observable<StudentClass[]> {
    console.log(resultList);
    return this.httpClient.post<StudentClass[]>(environment.apiUrl +
      '/StudentAdmission/SaveChangeClass/', JSON.stringify(resultList), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json', responseType: 'text'
        })
    })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public downloadFile(docFile: string): Observable<string> {
    return this.httpClient.get(environment.apiUrl + '/StudentAdmission/GetFile/' + docFile, {
      responseType: 'text'
    });
  }

  public downloadDocFile(id: number) {
    return this.httpClient.get(environment.apiUrl + '/StudentAdmission/GetDocumentFile/' + id, {
      responseType: 'text'
    });
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
