import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeeConfiguration } from './feeconfiguration.model';
import { FeeConfigurationService } from './feeconfiguration.service';
import { AssignInstallmentToGroupService } from './../assignintallmenttogroup/assigninstallmenttogroup.service';
import { AssignInstallmentToGroup } from './../assignintallmenttogroup/assigninstallmenttogroup.model';
import { GroupService } from './../group/group.service';
import { Group } from './../group/group.model';
import { InstallmentService } from './../installment/installment.service';
import { Installment } from './../installment/installment.model';
import { FeeHeadService } from './../feehead/feehead.service';
import { FeeHead } from './../feehead/feehead.model';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';

@Component({
  selector: 'app-feeconfiguration',
  templateUrl: './feeconfiguration.component.html',
  styleUrls: ['./feeconfiguration.component.css']
})
export class FeeconfigurationComponent implements OnInit {

  assigngroups: AssignInstallmentToGroup[] = [];
  filteredAssigngroups: AssignInstallmentToGroup[] = [];
  installments: Installment[] = [];
  selectedinstallment: Installment = {} as Installment;
  grpClassIds: any[] = [];
  groups: Group[] = [];
  groupFilteredList: Group[] = [];
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  feeheads: FeeHead[] = [];
  selectedFeeConfigs: FeeConfiguration[] = [];
  classCourse = '';
  assigngroupsFilteredList: AssignInstallmentToGroup[] = [];
  searchFilteredList: AssignInstallmentToGroup[] = [];
  submitted = false;
  groupId = '';
  selectedinstallmentname = '';
  installmentId = '';
  copyToAll = false;
  currentInstaIndex = 0;
  HighlightRow : Number;
  ClickedRow:any;
  isLoading:boolean=false;

  constructor(
    public configService: FeeConfigurationService,
    public assignInstallmentToGroupService: AssignInstallmentToGroupService,
    public installmentService: InstallmentService,
    public groupService: GroupService,
    public feeheadService: FeeHeadService,
    public classService: ClassCourseService,
    public batchService: BatchService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) 
  { 
      this.ClickedRow = function(index){
        this.HighlightRow = index;
    }
  
    }

  ListGroup() {
    this.groupService.getAll().subscribe((data: Group[]) => {
      this.groupFilteredList= this.groups = data;
    });
  }
  Unselect(){
    this.copyToAll = false;
  }
  ListInstallment() {
    this.installmentService.getAll().subscribe((data: Installment[]) => {
      this.installments = data;
    });
  }

  ListFeeHead() {
    this.feeheadService.getAll().subscribe((data: FeeHead[]) => {
      this.feeheads = data;
      console.log(this.feeheads)
    });
  }

  checkAllCheckBox(ev) {
    this.feeheads.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.feeheads.every(p => p.checked);
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
      if (keyword === '') {
        this.filteredAssigngroups = this.assigngroups;
      } else {
        this.filteredAssigngroups = this.assigngroups
          .filter((group: AssignInstallmentToGroup) =>
            group.installmentName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase())
          );
      }
  }

  get totalRows(): number {
    return this.filteredAssigngroups.length;
  }

  onGroupChange(value: number) {
    
    
    this.isLoading =true;
    this.grpClassIds = [];
    this.classes = [];
    this.groupService.getById(value).subscribe((data: Group) => {

      this.grpClassIds = data.classIds.split('|');

      this.classService.getAll().subscribe((classData: ClassCourse[]) => {
        // this.classCourses = data.filter((batch: Batch) => batch.classId.toString().includes(value.toString()));
         classData.forEach(x => {
          for (let i = 0; i < this.grpClassIds.length; i++) {
            if (x.id.toString() === this.grpClassIds[i]) {
              this.batchService.getAll().subscribe((bdata: Batch[]) => {
                const odata = bdata.filter((batch: Batch) => batch.classId.toString() === this.grpClassIds[i].toString());
                x.batches = odata;
                this.classes.push(x);
              });
            }
          }
        });
      });
      this.assignInstallmentToGroupService.getAll().subscribe((gdata: AssignInstallmentToGroup[]) => {
        this.filteredAssigngroups = this.assigngroups = gdata.filter((batch: AssignInstallmentToGroup) => {
             return batch.feeGroupId.toString() === value.toString();
          });
      });
    });
    this.isLoading =false;
    this.HighlightRow=-1;
  }

  onInstallmentClick(value: string, installmentName: string, index: number) {
    debugger;
    this.ListFeeHead();
   // this.feeheads.forEach(x => x.checked = false);
    this.installmentId = value;
    this.currentInstaIndex = index;
    this.selectedinstallmentname = installmentName;
    this.configService.getAll().subscribe((data: FeeConfiguration[]) => {
      console.log(data);
      this.feeheads.forEach((x, i) => {
          x.configId = 0;
          x.amount = '0';
          console.log(x);
          data.filter(x => x.installmentId == value && x.feeGroupId == this.groupId).forEach((y) => {
             console.log(y.feeGroupId.toString());
             if (parseInt(y.feeHeadId) === x.id) {
                x.checked = y.isChecked.toString() == "True" ? true : false;
                x.amount = y.amount;
                x.configId = y.id;
             }
          });
      });
    });
    console.log(this.feeheads)
  }

  resetForm() {
    this.isLoading =!this.isLoading;
    this.submitted = false;
    this.feeheads.forEach(x => x.checked = false);
    this.copyToAll=false;
    this.isLoading =!this.isLoading;
  }
  submitForm() {
    debugger;
    this.submitted = true;
    this.selectedFeeConfigs = [];
    if (!this.copyToAll) {
    this.feeheads.forEach((x, i) => {
      if (x.checked) {
        const feeConf: FeeConfiguration = {} as FeeConfiguration;
        feeConf.feeHeadId = x.id.toString();
        feeConf.feeGroupId = this.groupId;
        feeConf.installmentId = this.installmentId.toString();
        feeConf.amount = x.amount;
        feeConf.id = x.configId;
        feeConf.isChecked = x.checked;
        this.selectedFeeConfigs.push(feeConf);
      }
      else if(x.checked == false)
      {
        const feeConf: FeeConfiguration = {} as FeeConfiguration;
        feeConf.feeHeadId = x.id.toString();
        feeConf.feeGroupId = this.groupId;
        feeConf.installmentId = this.installmentId.toString();
        feeConf.amount = "0";
        feeConf.id = x.configId;      
        feeConf.isChecked = x.checked;
        this.selectedFeeConfigs.push(feeConf);
      }
    });
  } else {
    for (let i = this.currentInstaIndex; i < this.filteredAssigngroups.length; i++) {
      const instid = this.filteredAssigngroups[i].installmentId;

      this.feeheads.forEach((x, j) => {
        if (x.checked) {
          const feeConf: FeeConfiguration = {} as FeeConfiguration;
          feeConf.feeHeadId = x.id.toString();
          feeConf.feeGroupId = this.groupId;
          feeConf.installmentId = instid.toString();
          feeConf.amount = x.amount;
          //feeConf.id = x.configId;
          feeConf.isChecked = x.checked;
          this.selectedFeeConfigs.push(feeConf);
        }
        else if(x.checked == false)
      {
        const feeConf: FeeConfiguration = {} as FeeConfiguration;
        feeConf.feeHeadId = x.id.toString();
        feeConf.feeGroupId = this.groupId;
        feeConf.installmentId = instid.toString();
        feeConf.amount = "0";
        //feeConf.id = x.configId;
        feeConf.isChecked = x.checked;
        this.selectedFeeConfigs.push(feeConf);
      }
      });
    }
  }
    console.log(this.selectedFeeConfigs)
    this.configService.create(this.selectedFeeConfigs).subscribe(res => {
      console.log('Config added!');
      // this.ListGroup();
      this.onGroupChange(parseInt(this.groupId, 0));
      this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
        timeOut: 3000
      });
      this.submitted = false;
      this.resetForm();
    });
  }

  onFilterSelected(value){
    //this.ListAssigns();
    if (value === '') {
      this.groupFilteredList = this.groups;
    } else {
      this.groupFilteredList = this.groups
        .filter((group: Group) => group.stypeId === value);
    }
  }

  ngOnInit(): void {

    this.ListGroup();
    this.ListFeeHead();
    this.resetForm()

  }

}
