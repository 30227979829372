import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnlineExaminationComponent } from './online-examination/online-examination.component';

const routes: Routes = [
  {
    path:'onlineexamination', component: OnlineExaminationComponent,
    children:[
    {
         path:'CreateQuestion',
         loadChildren: () =>
         import('./create-question/create-question.module').then(m => m.CreateQuestionModule)
     },
     {
          path:'QuestionList',
          loadChildren: () =>
          import('./question-list/question-list.module').then(m => m.QuestionListModule) 
     }      
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnlineExaminationRoutingModule { }
