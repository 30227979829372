import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {FeeConcessionService} from './feeconcessionreport.service';
import{FeeConcessionReportModel} from './feeconcessionreport.model';
import { ConcessionTemplate, ConcessionTemplateDetail } from './../../../feemanagement/concessiontemplate/concessiontemplate.model';
import { ConcessionTemplateService } from './../../../feemanagement/concessiontemplate/concessiontemplate.service';
import { FeeCollectionReportModel } from '../feecollectionreport/feecollectionreport.model';
import { DatePipe } from '@angular/common';
declare var $: any;

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import { environment } from 'src/environments/environment';
import { BatchAttribute } from '../../../classmanagement/batchattribute.model';
import { Batch } from '../../../mastersettings/models/batch';

@Component({
  selector: 'app-feeconcessionreport',
  templateUrl: './feeconcessionreport.component.html',
  styleUrls: ['./feeconcessionreport.component.css']
})
export class FeeconcessionreportComponent implements OnInit {
  fromDate:string;
  toDate:string;
  concessionTypeId:any = -1;
  admissionNo:string = '';
  concessionTypeList:ConcessionTemplate[]=[];
  feeConcessionReportFilteredList:FeeConcessionReportModel[] =[];
  feeConcessionReportTemp:FeeConcessionReportModel[]=[];

  institution: any;
  attributes:BatchAttribute[] = [];
  batches: Batch[] = [];
  headerList: string[] = [];
  fieldList: string[] = [];
  reportDataList:any=[]; 
  totalAmount: number=0;

  constructor(
    public feeConcessionService: FeeConcessionService,
    public concessionTemplateService:ConcessionTemplateService
  ) { }

  listConcession() {
    this.concessionTemplateService.getAll().subscribe((data: ConcessionTemplate[]) => {
      this.concessionTypeList = data;
    });
  }
  
  onRefresh() {
    this.admissionNo = '';
    this.concessionTypeId = -1;
    $('#fromDate').datepicker('setDate', null);
    $('#toDate').datepicker('setDate', null);
    this.feeConcessionReportFilteredList = [];
    this.feeConcessionReportTemp = [];
    //this.onSearch();
   }

  onSearch()
  {
    const datePipe = new DatePipe('en-Us');
    this.fromDate = datePipe.transform(this.fromDate, 'yyyy-MM-dd');
    this.toDate = datePipe.transform(this.toDate, 'yyyy-MM-dd');

    if(this.admissionNo == "")
    {
      if(this.fromDate == null || this.toDate == null) 
      {
        this.feeConcessionReportFilteredList = [];
        return;
      }
    }

    this.feeConcessionService.getFeeConcessionReport(this.concessionTypeId,this.admissionNo, this.fromDate, this.toDate).subscribe((data) => {
      this.feeConcessionReportFilteredList = data.table;
      this.getSum();
    });
    this.feeConcessionReportTemp=this.feeConcessionReportFilteredList;
 
  }


  getSum(){
      const sum = this.feeConcessionReportFilteredList.map(item => parseFloat(item.amount)).reduce((prev, curr) => prev + curr, 0);
      this.totalAmount = sum;
}

  onKeyUpEvent(event: any) {
   this.filter(event.target.value);
  }
  
  filter(keyword: any) {
   if ( keyword === '') 
   {
     this.onSearch();
   }
   else 
   {
      this.feeConcessionReportTemp = this.feeConcessionReportTemp.filter(d => {
        return (d.rollNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())||
        d.admissionNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())||d.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
       });
       this.feeConcessionReportFilteredList= this.feeConcessionReportTemp  
   }
  }


  generatePDF(reportType : number) {
    this.headerList = [];
    this.fieldList = [];

    this.generateAttributes(reportType);
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
  

    var reportName;
    if(reportType == 1) // main report
    {
      this.reportDataList = this.feeConcessionReportFilteredList;
      reportName = "FEE CONCESSION REPORT"; 
    }
    var fileName =  reportName + '.pdf'

    if(this.reportDataList.length == 0)
    {
      return;
    }

    const widthList = [];
    for (const item of this.headerList) {
        widthList.push('auto');
    }
    const docDefinition = {
      content: [
        {
            table: {
                widths: ['auto', '*'],
                body: [
                    [ {
                        width: 100,
                        height: 100,
                        image: 'imageKey'
                    }, {
                        width: '*',
                        alignment: 'center',
                        stack: [
                            {
                                style: 'h1',
                                text: this.institution.institutionName
                            },
                            {
                              style: 'h2',
                              text: this.institution.address
                            },
                            {
                                style: 'h2',
                                text: '2021-22'
                            },
                            {
                                style: 'h2',
                                text: reportName
                            }
                        ]
                    }
                    ]
                ]
            },
            layout: {
                hLineWidth: (line) => line === 1,
                vLineWidth: () => 0,
                paddingBottom: () => 5
            },
          },
          {
            style:'sectionHeader',
            table: {
              
              headerRows: 1,
              widths: widthList,
              
              body: [
                this.headerList,
                ...this.reportDataList.map((p) => {
                  const temp = [];
                  for (const y of this.fieldList) {
                    if (y === 'dob') {
                      temp.push(this.formatDate(p[y]));
                    } else {
                      temp.push(p[y]);
                    }
                  }
                  return temp;
                })
              ]
            }
          }
      ],
      images: {
        imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
  
      },
      styles: {
        sectionHeader: {
          // bold: true,
          // decoration: 'underline',
          fontSize: 12,
          margin: [15, 15, 15, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 14,
          bold: true
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        }
      }
    };
  
    pdfMake.createPdf(docDefinition).download(fileName);
  }


  generateExcel(reportType : number) {
    
    this.headerList = [];
    this.fieldList = [];

    this.generateAttributes(reportType);
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
  
    var reportName;
    if(reportType == 1) // main report
    {
      this.reportDataList = this.feeConcessionReportFilteredList;
      reportName = "FEE CONCESSION REPORT"; 
    }

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(reportName);
    
    const titleRow = worksheet.addRow([reportName]);
    const headerRow = worksheet.addRow(this.headerList);
    for (const x1 of this.reportDataList) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'dob') {
          temp.push(this.formatDate(x1[y]));
        } else {
          temp.push(x1[y]);
        }
      }
      worksheet.addRow(temp);
    }
    const fname = reportName;
  
    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });
  
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  generateAttributes(reportType : number ) {

    if(reportType == 1) //main report
    {
      this.attributes = [
        {name: 'SNO', fieldName: 'rowNo' },
        {name: 'Admsn NO', fieldName: 'admissionNo' },
        {name: 'Student Name', fieldName: 'studentName' },
        {name: 'Class', fieldName: 'class' },
        {name: 'Batch', fieldName: 'batch' },
        {name: 'Roll', fieldName: 'rollNo' },
        {name: 'Father Name', fieldName: 'fathersName' },
        {name: 'Con. Type', fieldName: 'concessionType' },
        {name: 'Assigned Date', fieldName: 'assignedDate' },
        {name: 'Amount | (%)', fieldName: 'amount' },
        {name: 'Status', fieldName: 'status' }
      ];
    }
  }


  ngOnInit(): void {
    const self=this;
    $('#dateFrom').datepicker().on('changeDate', (e) => {
      self.fromDate=e.date;
      });
     
    $('#dateTo').datepicker().on('changeDate', (e) => {
     self.toDate=e.date;
     });
     
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.listConcession();  
  }

}
