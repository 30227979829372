import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { throwError, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Institution, InstitutionBranch } from '../../institutionmanagement/models/institution.model';
import { PaymentDetails } from '../models/paymentDetails';



@Injectable({
    providedIn: 'root'
  })
  export class BillingCommonServices {
    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
           enctype: 'multipart/form-data'
        })
      };
      constructor(private httpClient: HttpClient) { }

      

      getAllInstitutions(): Observable<Institution[]> {
        return this.httpClient.get<Institution[]>(environment.apiUrl + '/institution/GetAllInstitutionDetails')
        .pipe(
          catchError(this.errorHandler)
        );
      }
      getAllBranches(institutionId): Observable<InstitutionBranch[]> {
        return this.httpClient.get<InstitutionBranch[]>(environment.apiUrl + '/institution/GetAllBranches/'+ institutionId)
        .pipe(
          catchError(this.errorHandler)
        );
      }
      getAllPaymentsByInstitutionId(institutionId): Observable<PaymentDetails[]> {
        return this.httpClient.get<PaymentDetails[]>(environment.apiUrl + '/payment/getallpayments/'+institutionId)
        .pipe(
          catchError(this.errorHandler)
        );
      }

      makePayment(paymentData): Observable<any> {
        return this.httpClient.post(environment.apiUrl + '/payment/',paymentData)
        .pipe(
          catchError(this.errorHandler)
        );
      }

    //   getTicketDetailsById(TicketId:string): Observable<Ticket> {
    //     return this.httpClient.get<Ticket>(environment.apiUrl + '/tickets/'+TicketId)
    //     .pipe(
    //       catchError(this.errorHandler)
    //     );
    //   }
    //   update(ticketId, ticket): Observable<Ticket> {
    //     // tslint:disable-next-line:max-line-length
    //     return this.httpClient.put<Ticket>(environment.apiUrl + '/tickets/' + ticketId, JSON.stringify(ticket), this.httpOptions)
    //     .pipe(
    //       catchError(this.errorHandler)
    //     );
    //   }

      errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.message;
        } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
     }

  }