import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CampusBuilding } from '../models/campusbuilding';

@Injectable({
  providedIn: 'root'
})
export class CampusBuildingService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<CampusBuilding[]> {
    return this.httpClient.get<CampusBuilding[]>(environment.apiUrl + '/campusbuilding/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<CampusBuilding> {
    return this.httpClient.get<CampusBuilding>(environment.apiUrl + '/campusbuilding/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(campusbuilding): Observable<CampusBuilding> {
    return this.httpClient.post<CampusBuilding>(environment.apiUrl + '/campusbuilding/', JSON.stringify(campusbuilding), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, campusbuilding): Observable<CampusBuilding> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<CampusBuilding>(environment.apiUrl + '/campusbuilding/' + id, JSON.stringify(campusbuilding), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<CampusBuilding>(environment.apiUrl + '/campusbuilding/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
