import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { PaymentGateway, PaymentMethod, PaymentProvider } from './paymentgateway.model';

import { PaymentGatewayService } from './paymentgateway.service';
import { PaymentMethodService } from './paymentmethod.service';
import { PaymentProviderService } from './paymentprovider.service';

@Component({
  selector: 'app-paymentgateway',
  templateUrl: './paymentgateway.component.html',
  styleUrls: ['./paymentgateway.component.css']
})
export class PaymentgatewayComponent implements OnInit {

  paymentMethod = false;
  paymentProvider = false;

  paymentGatewayForm: UntypedFormGroup;
  paymentMethodForm: UntypedFormGroup;
  paymentProviderForm: UntypedFormGroup;

  paymentGateways: PaymentGateway[] = [];
  paymentMethods: PaymentMethod[] = [];
  paymentProviders: PaymentProvider[] = [];

  logoSrc: string;
  submittedpaymentgateway = false;
  submittedpaymentmethod = false;
  submittedpaymentprovider = false;

  selectedgatewayId = 0;
  selectedMethodId = 0;
  selectedMethod = '';
  selectedgatewayIndex = 0;
  selectedMethodIndex = 0;

  constructor(
    private paymentGatewayService: PaymentGatewayService,
    private paymentMethodService: PaymentMethodService,
    private paymentProviderService: PaymentProviderService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }


  showPaymentMethod(gatewayId: number, index: number) {
    this.paymentGateways.forEach((x) => x.selected = false);
    this.paymentMethods.forEach((x) => x.selected = false);
    this.selectedgatewayId = gatewayId;
    this.selectedgatewayIndex = index;
    this.paymentGateways.filter((x: PaymentGateway) => x.id === gatewayId).map((x) => x.selected = true);
    this.paymentMethod = this.paymentGateways[index].selected;
    this.paymentProvider = false;
    this.ListPaymentMethod(gatewayId);
  }

  showPaymentProvider(methodId: number, methodName: string, index: number) {
    this.paymentMethods.forEach((x) => x.selected = false);
    this.paymentMethods.filter((x: PaymentMethod) => x.id === methodId).map((x) => x.selected = true);
    this.paymentProvider = this.paymentMethods[index].selected;
    this.selectedMethodId = methodId;
    this.selectedMethod = methodName;
    this.selectedMethodIndex = index;
    this.ListPaymentProvider(methodId);
  }

  readGatewayURL(event: Event, imageType: string): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const file = target.files[0];

      const reader = new FileReader();
      reader.onload = (e => {
        this.logoSrc = reader.result.toString();
        if (imageType === 'logo') {
          this.paymentGatewayForm.get('logo').patchValue(this.logoSrc);
        } else {
          this.paymentGatewayForm.get('ppnlogo').patchValue(this.logoSrc);
        }
      });

      reader.readAsDataURL(file);
    }
  }

  readMethodURL(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const file = target.files[0];

      const reader = new FileReader();
      reader.onload = (e => {
        this.logoSrc = reader.result.toString();
        this.paymentMethodForm.get('icon').patchValue(this.logoSrc);
      });

      reader.readAsDataURL(file);
    }
  }

  readProviderURL(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const file = target.files[0];

      const reader = new FileReader();
      reader.onload = (e => {
        this.logoSrc = reader.result.toString();
        this.paymentProviderForm.get('iconUrl').patchValue(this.logoSrc);
      });

      reader.readAsDataURL(file);
    }
  }

  // Payment Gateway Details

  ListPaymenetgateway() {
    this.paymentGatewayService.getAll().subscribe((data: PaymentGateway[]) => {
        this.paymentGateways = data;
    });
  }

  resetPaymentGatewayForm() {
    this.submittedpaymentgateway = false;
    this.paymentGatewayForm = this.fb.group({
      id: [0],
      gatewayname: ['', Validators.required],
      logo: ['', Validators.required],
      ppnlogo: ['', Validators.required],
      status: ['true', Validators.required],
      isDeleted: ['false', Validators.required]
    });
    this.logoSrc = '';
  }

  submitPaymentGatewayForm() {
    this.submittedpaymentgateway = true;
     // stop here if form is invalid
    if (this.paymentGatewayForm.invalid) {
      return;
    }

    if (this.paymentGatewayForm.get('id').value === 0) {
        console.log(this.paymentGatewayForm.value);
        this.paymentGatewayService.create(this.paymentGatewayForm.value).subscribe(res => {
          console.log('Addon service created!');
          this.ListPaymenetgateway();
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submittedpaymentgateway = false;
          this.resetPaymentGatewayForm();
        });
        } else {
          this.paymentGatewayService.update(this.paymentGatewayForm.get('id').value, this.paymentGatewayForm.value).subscribe(res => {
            console.log('Login page updated!');
            this.ListPaymenetgateway();
            this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submittedpaymentgateway = false;
            this.resetPaymentGatewayForm();
            // this.ListLoginPages();
           });
        }
  }
  updatePaymentGatewayForm(id: number) {
   this.paymentGatewayService.getById(id).subscribe((data: PaymentGateway) => {
      console.log(data);
      this.paymentGatewayForm = this.fb.group({
        id: [data.id],
        gatewayname: [data.gatewayName, Validators.required],
        logo: [data.logo],
        ppnlogo: [data.ppnLogo],
        status: ['true', Validators.required]
      });
      this.logoSrc = data.logo;
    });

  }

  deletePaymentGatewayForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.paymentGatewayService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListPaymenetgateway();
        });
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      );
      }
    });
  }

  get g() { return this.paymentGatewayForm.controls; }

    // Payment Method Details

    ListPaymentMethod(gatewayId: any) {
      this.paymentMethodService.getAll().subscribe((data: PaymentMethod[]) => {
          this.paymentMethods = data.filter((x: PaymentMethod) => x.gatewayId === gatewayId);
      });
    }

    resetPaymentMethodForm() {
      this.submittedpaymentmethod = false;
      this.paymentMethodForm = this.fb.group({
        id: [0],
        gatewayId: ['', Validators.required],
        paymentMethodName: ['', Validators.required],
        iconUrl: [''],
        status: ['true', Validators.required],
        isDeleted: ['false', Validators.required]
      });
      this.logoSrc = '';
    }

    submitPaymentMethodForm() {
      this.submittedpaymentmethod = true;
      this.paymentMethodForm.get('gatewayId').patchValue(this.selectedgatewayId);
       // stop here if form is invalid
      if (this.paymentMethodForm.invalid) {
        return;
      }

      if (this.paymentMethodForm.get('id').value === 0) {
          console.log(this.paymentMethodForm.value);
          this.paymentMethodService.create(this.paymentMethodForm.value).subscribe(res => {
            console.log('Addon service created!');
            this.ListPaymentMethod(this.selectedgatewayId);
            this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submittedpaymentmethod = false;
            this.resetPaymentMethodForm();
          });
          } else {
            this.paymentMethodService.update(this.paymentMethodForm.get('id').value, this.paymentMethodForm.value).subscribe(res => {
              console.log('Login page updated!');
              this.ListPaymentMethod(this.selectedgatewayId);
              this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
                timeOut: 3000
              });
              this.submittedpaymentmethod = false;
              this.resetPaymentMethodForm();
              // this.ListLoginPages();
             });
          }
    }
    updatePaymentMethodForm(id: number) {
     this.paymentMethodService.getById(id).subscribe((data: PaymentMethod) => {
        console.log(data);
        this.paymentMethodForm = this.fb.group({
          id: [data.id],
          gatewayId: [data.gatewayId],
          paymentMethodName: [data.paymentMethodName, Validators.required],
          icon: [data.icon],
          status: [data.status, Validators.required]
        });
        this.logoSrc = data.icon;
      });
    }

    deletePaymentMethodForm(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.paymentMethodService.delete(id).subscribe(res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            this.ListPaymentMethod(this.selectedgatewayId);
          });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
        }
      });
    }

    get m() { return this.paymentMethodForm.controls; }
    // Payment Provider Details

    ListPaymentProvider(methodId: any) {
      this.paymentProviderService.getAll().subscribe((data: PaymentProvider[]) => {
          this.paymentProviders = data.filter((x: PaymentProvider) => x.paymentMethodId === methodId);
      });
    }

    resetPaymentProviderForm() {
      this.submittedpaymentprovider = false;
      this.paymentProviderForm = this.fb.group({
        id: [0],
        paymentMethodId: ['', Validators.required],
        providerName: ['', Validators.required],
        displayName: ['', Validators.required],
        isDropdown: [''],
        iconUrl: [''],
        apiUrl: [''],
        status: ['true', Validators.required],
        isDeleted: ['false', Validators.required]
      });
      this.logoSrc = '';
    }

    submitPaymentProviderForm() {
      this.submittedpaymentprovider = true;
       // stop here if form is invalid
      this.paymentProviderForm.get('paymentMethodId').patchValue(this.selectedMethodId);
      if (this.paymentProviderForm.invalid) {
        return;
      }

      if (this.paymentProviderForm.get('id').value === 0) {
          console.log(this.paymentProviderForm.value);
          this.paymentProviderService.create(this.paymentProviderForm.value).subscribe(res => {
            console.log('Addon service created!');
            this.ListPaymentProvider(this.selectedMethodId);
            this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submittedpaymentprovider = false;
            this.resetPaymentProviderForm();
          });
          } else {
            this.paymentProviderService.update(this.paymentProviderForm.get('id').value, this.paymentProviderForm.value).subscribe(res => {
              console.log('Login page updated!');
              this.ListPaymentProvider(this.selectedMethodId);
              this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
                timeOut: 3000
              });
              this.submittedpaymentprovider = false;
              this.resetPaymentProviderForm();
              // this.ListLoginPages();
             });
          }
    }
    updatePaymentProviderForm(id: number) {
     this.paymentProviderService.getById(id).subscribe((data: PaymentProvider) => {
        this.paymentProviderForm = this.fb.group({
          id: [data.id],
          paymentMethodId: [data.paymentMethodId, Validators.required],
          providerName: [data.providerName, Validators.required],
          displayName: [data.displayName, Validators.required],
          isDropdown: [data.isDropdown],
          iconUrl: [data.iconUrl],
          apiUrl: [data.apiUrl],
          status: ['true', Validators.required]
        });
        this.logoSrc = data.iconUrl;
      });
    }

    deletePaymentProviderForm(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.paymentProviderService.delete(id).subscribe(res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            this.ListPaymentProvider(this.selectedMethodId);
          });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
        }
      });
    }

    get p() { return this.paymentProviderForm.controls; }

  ngOnInit(): void {
    this.paymentGatewayForm = this.fb.group({
      id: [0],
      gatewayname: ['', Validators.required],
      logo: ['', Validators.required],
      ppnlogo: ['', Validators.required],
      status: ['true', Validators.required],
      isDeleted: ['false', Validators.required]
    });

    this.paymentMethodForm = this.fb.group({
      id: [0],
      gatewayId: ['', Validators.required],
      paymentMethodName: ['', Validators.required],
      icon: [''],
      status: ['true', Validators.required],
      isDeleted: ['false', Validators.required]
    });
    this.paymentProviderForm = this.fb.group({
      id: [0],
      paymentMethodId: ['', Validators.required],
      providerName: ['', Validators.required],
      displayName: ['', Validators.required],
      isDropdown: [''],
      iconUrl: [''],
      apiUrl: ['', Validators.required],
      status: ['true', Validators.required],
      isDeleted: ['false', Validators.required]
    });
    this.ListPaymenetgateway();
  }

}
