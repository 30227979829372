import { Component, OnInit } from '@angular/core';
import { StudentService} from './../student.service';
import {ClassCourseService} from './../../mastersettings/classcourse/classcourse.service';
import { Student } from './../student.model';
import {ClassCourse} from './../../mastersettings/models/classcourse';
import { environment } from '../../../../../environments/environment';
import { ReportAttribute } from '../../studentmanagement/report.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
/// import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable.js';
import { SettingsService } from '../../mastersettings/settings.service';
import { Academicyear } from '../../mastersettings/models/academicyear';
declare var $: any;

@Component({
  selector: 'app-admissionreport',
  templateUrl: './admissionreport.component.html',
  styleUrls: ['./admissionreport.component.css']
})
export class AdmissionreportComponent implements OnInit {

  admissions: Student[] = [];
  admissionsfilteredlist: Student[] = [];
  selectedAdmissionsfilteredlist: Student[] = [];
  classcourses: ClassCourse[] = [];
  classcourseid = '0';
  fromDate: Date;
  toDate: Date;
  headerList: string[] = [];
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  institution: any;
  academicYear:Academicyear[]=[];
  currentAcademicYearId: number;
  currentAcademicYearName = '';
  
  isLoading:boolean=false;
  
  constructor(
    public studentService: StudentService,
    private classcourseService: ClassCourseService,
    private academicYearService: SettingsService,
  ) { 
    this.GetCurrentAllcademicYear()
  }

  ListStudents() {
    this.isLoading =!this.isLoading;
    this.studentService.getAll().subscribe((data: Student[]) => {
      console.log(data);
      this.admissionsfilteredlist = this.admissions = data;
      this.isLoading =!this.isLoading;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  onClassSelected(value: string) {
    console.log(value);
    if (value === '-1') { return; }
    if (value === '0') {
      // this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
        // console.log(data);
       this.admissionsfilteredlist = this.selectedAdmissionsfilteredlist = this.admissions; // = data;
      // });
    } else {
      // this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
        this.admissionsfilteredlist = this.selectedAdmissionsfilteredlist =  this.admissions.filter((admission: Student) =>
        admission.classCourseId.toString() === value.toString());
      // });
    }
  }

  search() {
    this.isLoading =true;
    if (this.selectedAdmissionsfilteredlist.length === 0) {
      this.admissionsfilteredlist = this.admissions;
      if (Number(this.classcourseid) > 0) {
        this.admissionsfilteredlist = this.admissionsfilteredlist.filter((item: Student) => {
          return new Date(item.admissionDate).getTime() >= new Date(this.fromDate).getTime() &&
          new Date(item.admissionDate).getTime() <= new Date(this.toDate).getTime() &&
          item.classCourseId.toString() === this.classcourseid;
        });
      } else {
      this.admissionsfilteredlist = this.admissionsfilteredlist.filter((item: Student) => {
        return new Date(item.admissionDate).getTime() >= new Date(this.fromDate).getTime() &&
        new Date(item.admissionDate).getTime() <= new Date(this.toDate).getTime();
      });
      }
    } else {
      this.admissionsfilteredlist = this.selectedAdmissionsfilteredlist;
      if (Number(this.classcourseid) > 0) {
        this.admissionsfilteredlist = this.admissionsfilteredlist.filter((item: Student) => {
          return new Date(item.admissionDate).getTime() >= new Date(this.fromDate).getTime() &&
          new Date(item.admissionDate).getTime() <= new Date(this.toDate).getTime() &&
          item.classCourseId.toString() === this.classcourseid;
        });
      } else {
      this.admissionsfilteredlist = this.selectedAdmissionsfilteredlist.filter((item: Student) => {
        return new Date(item.admissionDate).getTime() >= new Date(this.fromDate).getTime() &&
        new Date(item.admissionDate).getTime() <= new Date(this.toDate).getTime();
      });
      }
    }
    this.isLoading =false;
  }

  resetTable() {
    this.admissionsfilteredlist = this.admissions;
    this.classcourseid = '0';
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (this.selectedAdmissionsfilteredlist.length === 0) {
      if ( keyword === '') {
        this.admissionsfilteredlist = this.admissions;
      } else {
      this.admissionsfilteredlist = this.admissions
                                  .filter((admission: Student) =>
                                  admission.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.admissionNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.fatherName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
                                  );
      }
    } else {
      if ( keyword === '') {
        this.admissionsfilteredlist = this.selectedAdmissionsfilteredlist;
      } else {
      this.admissionsfilteredlist = this.selectedAdmissionsfilteredlist
                                  .filter((admission: Student) =>
                                  admission.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.admissionNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.fatherName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
                                  );
      }
    }
  }

  get totalRows(): number {
    return this.admissionsfilteredlist.length;
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  generateAttributes() {
    this.attributes = [
      {name: 'S NO', fieldName: 'index' },
      {name: 'ACADEMIC', fieldName: 'academicYearName' },
      {name: 'ADMISSION NO', fieldName: 'admissionNo' },
      {name: 'STUDENT NAME', fieldName: 'studentName' },
      {name: 'FATHERS NAME', fieldName: 'fatherName' },
      {name: 'CLASS', fieldName: 'className' },
      {name: 'BATCH', fieldName: 'batchName' },
      {name: 'ROLL NO', fieldName: 'rollNo' },
      {name: 'DATE', fieldName: 'admissionDate' }
    ];
  }

  generateExcel() {
    this.headerList= [];
  this.fieldList = [];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('ADMISSION REPORT');
    worksheet.addRow();
    const imageId2 = workbook.addImage({
      base64: this.institution.institutionLogo,
      extension: 'jpg',
    });
    worksheet.addImage(imageId2, 'A1:B1');
    const titleRow = worksheet.addRow(['ADMISSION REPORT']);
    const headerRow = worksheet.addRow(this.headerList);
    for(var i = 0 ; i < this.admissionsfilteredlist.length ; i++)
    this.admissionsfilteredlist[i]["index"] = i+1;
    for (const x1 of this.admissionsfilteredlist) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'admissionDate') {
          temp.push(this.formatDate(x1[y]));
        } else {
          temp.push(x1[y]);
        }
        // x2.forEach((e) => {
        //   if (e.toLocaleLowerCase() === y.toLocaleLowerCase()) {
        //   }
        // });
      }
      worksheet.addRow(temp);
    }
    const fname = 'ADMISSION REPORT';

    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }

  GetCurrentAllcademicYear() {
    this.academicYearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYear = data;
      this.currentAcademYear();
    });
  }

  currentAcademYear() {
    debugger
    this.academicYearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.currentAcademicYearName = this.academicYear.find(x => x.id.toString() === data.id.toString()).name;
    });
  }

  generatePDF() {
    this.headerList= [];
    this.fieldList = [];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });

    const widthList = [];
    for (const item of this.headerList) {
        widthList.push('auto');
    }
    for(var i = 0 ; i < this.admissionsfilteredlist.length ; i++)
    this.admissionsfilteredlist[i]["index"] = i+1;
    const docDefinition = {
      content: [
        {
            table: {
                widths: ['auto', '*'],
                body: [
                    [ {
                        width: 100,
                        height: 100,
                        image: 'imageKey'
                    }, {
                        width: '*',
                        alignment: 'center',
                        stack: [
                            {
                                style: 'h1',
                                text: this.institution.institutionName
                            },
                            {
                              style: 'h2',
                              text: this.institution.address
                            },
                            {
                                style: 'h2',
                                text: this.currentAcademicYearName
                            },
                            {
                                style: 'h1',
                                text: 'ADMISSION REPORT'
                            }
                        ]
                    }
                    ]
                ]
            },
            layout: {
              hLineWidth: () =>  0,
              vLineWidth: () => 0,
              paddingBottom: () => 5,
              paddingLeft:()=> 5,
              paddingRight:()=> 0
            },
          },
          {
            style:'header',
            table: {
              headerRows: 1,
              widths: ['5%','12%','16%', '17%', '17%', '8%', '7%', '8%', '12%'],
              body: [
                this.headerList,
                ...this.admissionsfilteredlist.map((p) => {
                  const temp = [];
                  for (const y of this.fieldList) {
                    if (y === 'admissionDate') {
                      temp.push(this.formatDate(p[y]));
                    } else {
                      temp.push(p[y]);
                    }
                  }
                  return temp;
                })
              ]
            }
          }
      ],
      images: {
        imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'

      },
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        },
        header: {
          fontSize: 8,
          margin: [0, 15, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 16,
          bold: true
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        }
      }
    };

    pdfMake.createPdf(docDefinition).download('ADMISSION REPORT.pdf');
  }

  ngOnInit(): void {
    this.generateAttributes();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.ListClassCourse();
    this.ListStudents();

    $('#fromDate').datepicker().on('changeDate', (e) => {
      this.fromDate =  new Date(e.date);
   });

    $('#toDate').datepicker().on('changeDate', (e) => {
     this.toDate = new Date(e.date);
   });

  }

}
