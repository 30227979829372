import { Component, NgModule, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Stream } from '../../mastersettings//models/stream';
import { StreamService } from '../../mastersettings/stream/stream.service';
import { Term } from '../../mastersettings//models/Term';
import { TermService } from '../../mastersettings/term/term.service';
import { SubjectcreationService } from '../subjectcreation/subjectcreation.service';
import { SubjectCreation } from '../subjectcreation/subjectcreation.model';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { assignbookservice } from './assignbook.service';
import { assignbook } from './assignbook.model';
@Component({
  selector: 'app-assignbook',
  templateUrl: './assignbook.component.html',
  styleUrls: ['./assignbook.component.css']
})
export class AssignbookComponent implements OnInit {
  classcourses: ClassCourse[];
  streams: Stream[];
  terms: Term[];
  subjects: SubjectCreation[] = [];
  submitted = false;
  AssignbookTosubjectForm: UntypedFormGroup;
  // defaultSubject: any = "Select Subject";
  assignbookFilteredList: assignbook[] = [];
  assignbookFilteredListTemp: assignbook[] = [];
  assignbookList: assignbook[] = [];
  totalRecords = 0;
  classValue = '0';
  subjectValue = '-1';
  constructor(
    private classcourseService: ClassCourseService,
    private termService: TermService,
    private streamService: StreamService,
    public subjectService: SubjectcreationService,
    public bookservice: assignbookservice,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  get f() { return this.AssignbookTosubjectForm.controls; }
  ListClass() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      // console.log(data);
      this.classcourses = data;
    });
  }
  ListTerm() {
    this.termService.getAll().subscribe((data: Term[]) => {
      // console.log(data);
      this.terms = data;
    });
  }
  ListStream() {
    this.streamService.getAll().subscribe((data: Stream[]) => {
      // console.log(data);
      this.streams = data;
    });
  }
  ListSubjects() {
    this.subjectService.getAll().subscribe((data: SubjectCreation[]) => {
      this.subjects = data;
      // console.log(this.subjects);
    });
  }

  ListBooks() {
    this.bookservice.getAll().subscribe((data: assignbook[]) => {
      this.assignbookFilteredListTemp =  this.assignbookFilteredList = this.assignbookList = data;
      this.totalRecords = this.assignbookFilteredList.length;
      // console.log(this.assignbookFilteredList);
    });
  }
  updateForm(id: number) {
    this.assignbookList.forEach((x) => {
      if (x.id === id) {
          x.selected = true;
      } else {
        x.selected = false;
      }
    });
    this.bookservice.getById(id).subscribe((data: assignbook) => {
      this.AssignbookTosubjectForm = this.fb.group({
        Id: [data.id],
        ClassCourseId: [data.classCourseId.toString(), Validators.required],
        TermId: [data.termId.toString(), Validators.required],
        StreamId: [data.streamId.toString(), Validators.required],
        SubjectId: [data.subjectId.toString(), Validators.required],
        BookName: [data.bookName.toString(), Validators.required],
        BookEdition: [data.bookEdition.toString(), Validators.required],
        BookId: [data.bookId.toString(), Validators.required],
        AssignBookToSubjectStatus: [data.assignBookToSubjectStatus, Validators.required],
        IsPracticalPaper: [data.isPracticalPaper === true ? 1 : 0, Validators.nullValidator],
        IsCoScholastic: [data.isCoScholastic === true ? 1 : 0, Validators.nullValidator]
      });
    });
  }

  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.bookservice.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListBooks();
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === '') {
      this.assignbookFilteredList = this.assignbookList;
    } else {
      this.assignbookFilteredList = this.assignbookList
        .filter((assignbookLst: assignbook) => assignbookLst.bookName.toLocaleLowerCase()
          .includes(keyword.toLocaleLowerCase()));
    }
  }


  onSubjectChanged(value: any) {
    // const contClass1 = this.classValue;
    // this.subjectValue = value;
    // if (value === 'Select Subject') {
    //   this.assignbookFilteredList = this.assignbookList;
    // } else {
    //   this.assignbookFilteredList = this.assignbookList.filter((data: assignbook) => {
    //     return data.subjectId.toString() == value;
    //   });
    // }




const contClass = this.classValue;
this.subjectValue = value;
if (value === '0' || value === 'All' || value === '' || value === '-1') {
  if (contClass === '-1' || contClass === '0') {
    this.assignbookFilteredList = this.assignbookFilteredListTemp;
  } else {
    this.assignbookFilteredList = this.assignbookFilteredListTemp.filter((data: assignbook) => {
      return data.subjectId.toString() === value && data.classCourseId.toString() === contClass;
    });
  }} else {
    if (contClass === '-1' || contClass === '0') {
      this.assignbookFilteredList = this.assignbookFilteredListTemp.filter((data: assignbook) => {
        return data.subjectId.toString() === value;
      });
      } else {
        this.assignbookFilteredList = this.assignbookFilteredListTemp.filter((data: assignbook) => {
          return data.subjectId.toString() === value && data.classCourseId.toString() === contClass;
        });
      }
  }




  }

  onClassChanged(value: any) {
    // this.classValue=value;
    // if (value === 'Select Class') {
    //   this.assignbookFilteredList = this.assignbookList;
    // } else {
    //   this.assignbookFilteredList = this.assignbookList.filter((data: assignbook) => {
    //     return data.classCourseId.toString() == value;
    //   });
    // }

    this.classValue = value;
    if (value === '0') {
      this.assignbookFilteredList = this.assignbookFilteredListTemp;
    } else {
      this.assignbookFilteredList = this.assignbookList.filter((data: assignbook) => {
             return data.classCourseId.toString() === value;
           });
    }
  }

  submitForm() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.AssignbookTosubjectForm.invalid) {
      return;
    }
    if (this.AssignbookTosubjectForm.get('Id').value === 0) {
      console.log(this.AssignbookTosubjectForm.value);
      this.bookservice.create(this.AssignbookTosubjectForm.value).subscribe(res => {
        this.ListBooks();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    } else {
      this.bookservice.update(this.AssignbookTosubjectForm.get('Id').value, this.AssignbookTosubjectForm.value).subscribe(res => {
        this.ListBooks();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    }
  }
  resetForm() {
    this.submitted = false;
    this.AssignbookTosubjectForm = this.fb.group({
      Id: [0],
      ClassCourseId: ['', Validators.required],
      TermId: ['', Validators.required],
      StreamId: ['', Validators.required],
      SubjectId: ['', Validators.required],
      BookName: ['', Validators.required],
      BookEdition: ['', Validators.required],
      BookId: ['', Validators.required],
      AssignBookToSubjectStatus: ['Active', Validators.required],
      IsPracticalPaper: [0, Validators.nullValidator],
      IsCoScholastic: [0, Validators.nullValidator]
    });
  }

  ngOnInit(): void {
    this.AssignbookTosubjectForm = this.fb.group({
      Id: [0],
      ClassCourseId: ['', Validators.required],
      TermId: ['', Validators.required],
      StreamId: ['', Validators.required],
      SubjectId: ['', Validators.required],
      BookName: ['', Validators.required],
      BookEdition: ['', Validators.required],
      BookId: ['', Validators.required],
      AssignBookToSubjectStatus: ['Active', Validators.required],
      IsPracticalPaper: [0, Validators.nullValidator],
      IsCoScholastic: [0, Validators.nullValidator]
    });

    this.ListBooks();
    this.ListClass();
    this.ListTerm();
    this.ListStream();
    this.ListSubjects();
  }
}
