import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ExamCategory } from '../ExamSetting/models/exam-category.model';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class ExamsettingService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  //This is the server URL
 // url:string="http://localhost:5000/";
  
  constructor(private http:HttpClient) { }

  //This service is used to get all the exam category
  getAllExamCategory():Observable<ExamCategory[]>{
    return this.http.get<ExamCategory[]>(environment.apiUrl+"/examcategory").pipe(
      catchError(this.errorHandler)
    )
  }
  
  //This service is used to get the exam category by id
  getExamCategoryById(id):Observable<ExamCategory>{
    return this.http.get<ExamCategory>(environment.apiUrl+"/examcategory/"+id).pipe(
      catchError(this.errorHandler)
    )
  }

  //This service is used to create Exam Category
  createExamCategory(data):Observable<ExamCategory> {
    return this.http.post<ExamCategory>(environment.apiUrl+"/examcategory",JSON.stringify(data),this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }

  //This service is used to update the existing Examination category
  updateExamCategory(id,data):Observable<ExamCategory>{
    return this.http.put<ExamCategory>(environment.apiUrl+"/examcategory/"+id,JSON.stringify(data),this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }

  //This service is used to delete the existing examination category
  deleteExamCategory(id){
    return this.http.delete<ExamCategory>(environment.apiUrl+"/examcategory/"+id,this.httpOptions)
          .pipe(
            catchError(this.errorHandler)
          )
  }

  // This code is for error handling
  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
