import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "../shared.service";
import { AuthenticationService } from "../../authentication/authentication.service";
import { Academicyear } from "../academic/mastersettings/models/academicyear";
import { SettingsService } from "../academic/mastersettings/settings.service";
import { NotificationService } from "../notification/notification.service";
import { Notification } from "rxjs";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { QuickSearch } from "../academic/mastersettings/models/quicksearch";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { environment } from "src/environments/environment";
import { InstitutionService } from "src/app/edumaq/institutionmanagement/newinstitution/institution.service";
import { ModuleManagementService } from "src/app/edumaq/configuration/modulemanagement/modulemanagement.service";
import {
  Institution,
  InstitutionAssignModule,
  InstitutionLicense,
} from "src/app/edumaq/institutionmanagement/models/institution.model";
import { debug } from "console";
import { SubscriptionManagerService } from "src/app/edumaq/configuration/subscriptionmanager/subscriptionmanager.service";
import { SubscriptionManagerModel } from "src/app/edumaq/configuration/subscriptionmanager/subscriptionmanager.model";
import { AuditService } from "../administration/Audit/audit.service";
import { RolesLinkedPagePermission } from "../administration/Audit/audit.model";
import Swal from "sweetalert2";

declare var $: any;

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"],
})
export class AdminComponent implements OnInit, AfterViewChecked, AfterViewInit {
  environment: string = environment.apiUrl;
  defaultZero: number = 0;
  instCode = "";
  academicYearIdSelected = "";
  academicYears: Academicyear[] = [];
  //quickSearchForm: FormGroup;
  quickSearches: QuickSearch[] = [];
  quickSearchFilteredList: QuickSearch[] = [];
  quickSearchKeyword: string = "menuName";
  isQuickSearchSelected: boolean = false;
  selectedQuickSearch: any = {};
  quickSearchPlaceholder: any;
  currentAcademicYearId: number;
  currentAcademicYearName = "";
  institution: any;
  notificationMessageCount = 0;
  tokenExpirySecond: number = 0;
  username: string;
  sessionExpiryTime: number;
  submitted = false;
  showSearchAutoComplete = false;
  //sessionExpiryTimeInSeconds: any;
  idleState = "Not started.";
  timedOut = false;
  timer: any;
  selectedCurrentYear: number;
  searchForm: UntypedFormGroup;
  institutionCode: any;
  keyword = "menuName";
  @ViewChild("OpenPopow", { static: false }) OpenPopow: ElementRef;
  @ViewChild("ClosePopow", { static: false }) ClosePopow: ElementRef;
  userConnected: any;
  avatarImage: string;
  focusElement: number = -1;
  showModal: boolean = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private sharedService: SharedService,
    private authService: AuthenticationService,
    private notificationService: NotificationService,
    public academicYearService: SettingsService,
    private toastr: ToastrService,
    public fb: UntypedFormBuilder,
    private idle: Idle,
    private institutionService: InstitutionService,
    private moduleManagementService: ModuleManagementService,
    private subscriptionManagerService: SubscriptionManagerService,
    private auditService: AuditService
  ) {

    this.showModal = localStorage.getItem('showModal') === 'true';
    this.notificationService
      .getMessageCount()
      .subscribe((messageCount: any) => {
        this.notificationMessageCount = messageCount;
      });
    this.getSessionExpiryTime();
    this.idleTimeSetting();
    this.idle.watch();
  }
  get f() {
    return this.searchForm.controls;
  }

  reset() {
    this.searchForm = this.fb.group({
      searchKey: [""],
    });
  }

  idleTimeSetting() {
    console.log(this.sessionExpiryTime.toString() + "-hsn");

    if (this.sessionExpiryTime == 0) return;

    this.idle.setIdle(this.sessionExpiryTime * 60);
    this.idle.setTimeout(this.sessionExpiryTime * 60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => (this.idleState = "Idle End"));
    this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      console.log("ontimeout");
    });
    this.idle.onIdleStart.subscribe(() => {
      let el: HTMLElement = this.OpenPopow.nativeElement as HTMLElement;
      el.click();
      this.tokenExpirySecond = 10;

      // this.idle.onIdleEnd.subscribe(() => this.idleState = 'Idle End');
      console.log("onIdleStart");
    });
    this.idle.onTimeoutWarning.subscribe((countDown) => {
      //alert('onTimeoutWarning')
      console.log("onTimeoutWarning");

      console.log(countDown);
      this.tokenExpirySecond = this.tokenExpirySecond - 1;
      if (this.tokenExpirySecond === 0) {
        this.idle.stop();
        document.getElementById("closeModal").click();
        this.logout();
      }

      // this.idle.stop()

      // this.timer = setInterval(()=>{
      //   this.tokenExpirySecond = this.tokenExpirySecond -1;
      //  // console.log(countDown) ;
      //   if(this.tokenExpirySecond===0)
      //   {
      //     document.getElementById("closeModal").click();
      //     clearInterval(this.timer);
      //     clearTimeout(this.timer);

      //      this.logout() ;

      //   }

      // },1000);
    });
    this.resetIdleWatch();
  }

  GetQuickSearch() {
    this.academicYearService
      .getAllQuickSearch()
      .subscribe((data: QuickSearch[]) => {
        this.quickSearchFilteredList = this.quickSearches = data;
        console.log("quickSearchFilteredList", this.quickSearchFilteredList);
      });
  }

  GetQuickSearchByInput(searchInput: string) {
    this.academicYearService

      .getQuickSearchItemByParam(searchInput)

      .subscribe((data: QuickSearch[]) => {
        this.quickSearchFilteredList = this.quickSearches = data;
        //  this.showSearchAutoComplete=this.searchQuery.length>0;
        console.log(
          " Hey quickSearchFilteredList",
          this.quickSearchFilteredList

        );
      });
  }

  filterItem(searchInput) {
    console.log(this.quickSearchFilteredList, 'Filtered Search List...1')
    if(searchInput.trim() !== "") {
      const filtered = this.quickSearchFilteredList.filter(item => 
        item.menuName.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
        );
        const menuIds = filtered.map(item=> item.menuId);
        this.quickSearchFilteredList = this.quickSearchFilteredList.filter(item=>
          menuIds.includes(0) && menuIds.includes(item.menuId));
          console.log(this.quickSearchFilteredList, 'Filtered Search List...2')
    } else {
      this.quickSearchFilteredList = [];
    }
  }
  // keyUpEvent(search){
  //   console.log(search);

  //   if(search=="undefined")
  //   {
  //     this.isQuickSearchSelected = false;
  //     this.selectedQuickSearch = {};
  //     this.quickSearchFilteredList=[];
  //     this.quickSearches=[];
  //   }else{
  //     this.GetQuickSearch();
  //   }

  // }

  inputCleared(val: string) {
    console.log(val);
    this.isQuickSearchSelected = false;
    this.selectedQuickSearch = {};
    this.quickSearchFilteredList = [];
    this.quickSearches = [];
  }
  onChangeSearch(val: string) {
    console.log(val);

    if (val == "undefined" || val == null || val == "") {
      this.isQuickSearchSelected = false;
      this.selectedQuickSearch = {};
      this.quickSearchFilteredList = [];
      this.quickSearches = [];
    } else {
      this.GetQuickSearch();
    }
  }
  onSearchInput(event: any) {
    // console.log("Hey the input value is: "+event.target.value);
    this.showSearchAutoComplete = event.target.value !== "";
    // console.log("Value of autocomplete is "+ this.showSearchAutoComplete);
    if (this.showSearchAutoComplete) {
      console.log('Serach');
      this.GetQuickSearchByInput(event.target.value);
    }
  }
  @HostListener("document:click")
  data() {
    this.showSearchAutoComplete = false;
  }

  onQuickSearchFocus() {
    this.focusElement = 0;
  }
  onQuickSearchBlur() {
    this.focusElement = -1;
  }

  onArrowUp() {
    if (this.focusElement > 0) {
      this.focusElement--;
    }
  }

  onArrowDown() {
    try {
      if (this.focusElement <= this.quickSearchFilteredList.length - 2) {
        this.focusElement++;
      }
    } catch { }
  }
  InvokeQuickSearch() {
    this.reset();
    console.log('Enter - test..');
    let quickSearch = this.quickSearchFilteredList[this.focusElement];
    const baseUrl = window.location.origin;
    const pathArray = window.location.pathname.split("/")[1];
    console.log(baseUrl);
    window.open(baseUrl + "/" + pathArray + quickSearch.pageUrl, "_self");
  }

  selectEvent(quickSearch) {
    console.log(quickSearch);
    this.isQuickSearchSelected = true;
    this.selectedQuickSearch = quickSearch;
    const baseUrl = window.location.origin;
    const pathArray = window.location.pathname.split("/")[1];
    console.log(baseUrl);
    window.open(baseUrl + "/" + pathArray + quickSearch.pageUrl);
  }

  extendtime() {
    this.getSessionExpiryTime();
    if (this.sessionExpiryTime != 0) {
      this.idle.setIdle(this.sessionExpiryTime * 60);
      this.idle.setTimeout(this.sessionExpiryTime * 60);
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      this.idle.watch();
      this.idleState = "Started";
      this.timedOut = false;
    }
  }
  refreshToken() {
    //this.idleTimeSetting();

    var uname = localStorage.getItem("uname");
    this.authService.newToken(uname, this.sessionExpiryTime).subscribe((_) => {
      localStorage.setItem("JWT_TOKEN", _.jwt);

      this.getSessionExpiryTime();
      this.idle.setIdle(this.sessionExpiryTime * 60);
      this.idle.setTimeout(this.sessionExpiryTime * 60);
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      this.idle.watch();
      this.idleState = "Started";
      this.timedOut = false;
      //  this.tokenExpirySecond=1000000;
      document.getElementById("closeModal").click();
    });
  }

  resetIdleWatch() {
    this.idle.watch();
    this.idleState = "Started";
    this.timedOut = false;
  }

  ListAcademicYear() {
    this.academicYearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
      localStorage.setItem(
        "academicYearList",
        JSON.stringify(this.academicYears)
      );
      this.currentAcademicYear();
      /*this.userConnected=JSON.stringify(localStorage.getItem('user_'+this.activatedRoute.snapshot.params.instcode));
    this.avatarImage=environment.imageUrl+this.userConnected.logoUrl;*/
    });
  }

  onAcademicYearChange(value: string) { }

  currentAcademicYear() {
    this.academicYearService
      .getCurrentAcademicYear()
      .subscribe((data: Academicyear) => {
        this.currentAcademicYearId = data.id;
        this.selectedCurrentYear = data.id;
        this.sharedService.academicYearId = data.id;
        console.log("academicYearId", data.id);
        localStorage.setItem("academicYearId", data.id.toString());
        this.currentAcademicYearName = this.academicYears.find(
          (x) => x.id.toString() === data.id.toString()
        ).name;
      });
  }

  setCurrentAcademicYear() {
    this.academicYearService
      .setCurrentAcademicYear(Number(this.selectedCurrentYear))
      .subscribe((data: Academicyear) => {
        this.sharedService.academicYearId = this.selectedCurrentYear;
        localStorage.setItem(
          "academicYearId",
          this.selectedCurrentYear.toString()
        );
        console.log("updated academicYearId", this.selectedCurrentYear);
        this.toastr.success("Currecnt Academic year updated", "SUCCESS!", {
          timeOut: 3000,
        });
        window.location.reload();
      });
  }

  logout() {
    this.idle.stop();
    $("#staticBackdrop").removeClass('fade').modal('hide');
    $("#staticBackdrop").modal('dispose');
    $("#staticBackdrop").remove();
    // sessionStorage.removeItem('JWT_TOKEN');
    // sessionStorage.removeItem('Institution');
    localStorage.removeItem("JWT_TOKEN");
    localStorage.removeItem("Institution");
    localStorage.removeItem(
      "user_" + this.activatedRoute.snapshot.params.instcode
    );
    localStorage.removeItem("showModal");
    // localStorage.clear();
    document.getElementById("closeModal").click();
    this.router.navigate([this.authService.institutionCode]);
    //window.location.reload();
  }
  isLoading: boolean = false;

  ngOnInit(): void {

    if(!this.showModal){
      localStorage.setItem('showModal','true');
      $("#staticBackdrop").modal('show');
    }

    this.isLoading = true;

    this.searchForm = this.fb.group({
      searchKey: [""],
    });

    // this.GetQuickSearch();

    this.sharedService.institutionCode =
      this.activatedRoute.snapshot.params.instcode;
    this.institutionCode = this.activatedRoute.snapshot.params.instcode;
    this.ListAcademicYear();
    const data = JSON.parse(
      localStorage.getItem(
        "Institution_" + this.activatedRoute.snapshot.params.instcode
      )
    );
    // if (data.medium === ''){
    //   data.medium = 'English';
    // }
    this.institution = data;
    //this.checkTokenExpiry();
    this.GetQuickSearch();
    this.userConnected = JSON.parse(
      localStorage.getItem(
        "user_" + this.activatedRoute.snapshot.params.instcode
      )
    );
    if (this.userConnected.userType === "Admin") {
      this.avatarImage = environment.imageUrl + this.userConnected.logoUrl;
      if (this.userConnected.logoUrl == "") {
        this.avatarImage = "../assets/images/users/avatar-1.jpg";
      }
    } else {
      this.avatarImage = environment.imageUrl + this.userConnected.imageURI;
      if (this.userConnected.imageURI == "") {
        this.avatarImage = "../assets/images/users/avatar-1.jpg";
      }
    }

    this.getInstitutionDetailByCode();
    this.getAllMenuMainModules();
    this.getAllSubscriptions();
    this.getRolesLinkedPermissions();
    console.log(this.avatarImage);
  }

  allSubscribtions: SubscriptionManagerModel[] = [];
  getAllSubscriptions() {
    this.subscriptionManagerService.getAll().subscribe((data: SubscriptionManagerModel[]) => {
      this.allSubscribtions = data;
      localStorage.setItem("allSubscribtions",JSON.stringify(data));
      console.log("allSubscribtions", data);
    });
  }

  institutionAssignedModules: InstitutionAssignModule[];
  institutionLicense: InstitutionLicense;
  getInstitutionDetailByCode() {
    this.institutionService
      .getInstitutionDetailByCode(this.activatedRoute.snapshot.params.instcode)
      .subscribe((data: Institution) => {
        console.log("GetInstitutionDetailsByCode...", data);
        this.institutionAssignedModules = data.institutionAssignModules;
        this.institutionLicense = data.institutionLicence;
      });
  }

  getRolesLinkedPagePermissions: RolesLinkedPagePermission[] = [];
  getRolesLinkedPermissions() {
    this.auditService.getRolesLinkedPermissions().subscribe((res: RolesLinkedPagePermission[]) => {
      this.getRolesLinkedPagePermissions = res;
    });
  }

  menuMainModules: any[];
  disAllowedPaths: any[] = [
    // "/admin/dashboard",
    //"/admin",
    "/admin/not-found1",
    // "/admin/userprofile",
    // "/admin/frontoffice/enquiry",
    // "/admin/academic/registration",
    // "/admin/academic/student/studentsummary",
    // "/admin/academic/feemanagement/feecollection",
    // "/admin/academic/feemanagement/report/feetransactionreport",
    // "/admin/operation/hrmspayroll/payroll/employeeattendance/attendanceregister",
    // "/admin/operation/inventory/saleinvoice",
  ];

  roleId: number;
  getAllMenuMainModules() {
    this.moduleManagementService.getAll().subscribe((data: any) => {
      console.log("getAllMenuMainModules", data);
      this.menuMainModules = data.filter((d) => d.isDeleted == "False");
      this.roleId = this.userConnected.roleId;

      if (this.roleId === undefined) {
        this.setfilterCheckForMenuMainModulesBySubscriptions();
        this.sethasAccessForMenuMainModulesByAssignModules();
        if (!this.institutionLicense.isSubscriptionLocked)
          this.menuMainModules = this.menuMainModules.filter(mmm => mmm.hasAccess || mmm.filterCheck)
            .map(function (mmm) {
              mmm.mainMenus = mmm.mainMenus.filter(mm => mm.hasAccess || mm.filterCheck)
                .map(function (mm) {
                  mm.subMenus = mm.subMenus.filter(sm => sm.hasAccess || sm.filterCheck)
                    .map(function (sm) {
                      sm.subMenus = sm.subMenus.filter(sm1 => sm1.hasAccess || sm1.filterCheck)
                        .map(function (sm1) {
                          sm1.subMenus = sm1.subMenus.filter(sm2 => sm2.hasAccess || sm2.filterCheck)
                            .map(function (sm2) {
                              sm2.subMenus = sm2.subMenus.filter(sm3 => sm3.hasAccess || sm3.filterCheck)
                                .map(function (sm3) {
                                  sm3.subMenus = sm3.subMenus.filter(sm4 => sm4.hasAccess || sm4.filterCheck)
                                    .map(function (sm4) {
                                      sm4.subMenus = sm4.subMenus.filter(sm5 => sm5.hasAccess || sm5.filterCheck)
                                        ; return sm4
                                    });
                                  return sm3
                                });
                              return sm2
                            });
                          return sm1
                        });
                      return sm
                    });
                  return mm;
                });
              return mmm;
            });
      }
      else {
        this.sethasAccessForMenuMainModulesByAssignModulesRoleBased();
        if (!this.institutionLicense.isSubscriptionLocked)
          this.menuMainModules = this.menuMainModules.filter(mmm => mmm.hasAccess)
            .map(function (mmm) {
              mmm.mainMenus = mmm.mainMenus.filter(mm => mm.hasAccess)
                .map(function (mm) {
                  mm.subMenus = mm.subMenus.filter(sm => sm.hasAccess)
                    .map(function (sm) {
                      sm.subMenus = sm.subMenus.filter(sm1 => sm1.hasAccess)
                        .map(function (sm1) {
                          sm1.subMenus = sm1.subMenus.filter(sm2 => sm2.hasAccess)
                            .map(function (sm2) {
                              sm2.subMenus = sm2.subMenus.filter(sm3 => sm3.hasAccess)
                                .map(function (sm3) {
                                  sm3.subMenus = sm3.subMenus.filter(sm4 => sm4.hasAccess)
                                    .map(function (sm4) {
                                      sm4.subMenus = sm4.subMenus.filter(sm5 => sm5.hasAccess)
                                        ; return sm4
                                    });
                                  return sm3
                                });
                              return sm2
                            });
                          return sm1
                        });
                      return sm
                    });
                  return mm;
                });
              return mmm;
            });
      }


      //AssignedModules - Filteration
      //if (!this.institutionLicense.isSubscriptionLocked)


      this.sharedService.setMenuMainModules(this.menuMainModules);

      const paths = JSON.stringify(this.disAllowedPaths);
      localStorage.setItem("disAllowedPaths", paths);
      this.isLoading = false;
    });
  }

  setfilterCheckForMenuMainModulesBySubscriptions() {
    var pages: { pagesId: any, isLocked: boolean }[] = [];

    this.allSubscribtions.forEach(sub =>
      sub.subscriptionDetails.forEach(subDet =>
        subDet.subModules.forEach(subMod => {
          if (subMod.subMenus.length === 0)
            pages.push({ pagesId: subMod.subModuleId, isLocked: sub.id === parseInt(this.institutionLicense.subscriptionType) })
          subMod.subMenus.forEach(subMenu =>
            pages.push({ pagesId: subMenu.pages, isLocked: sub.id === parseInt(this.institutionLicense.subscriptionType) }))
        })));

    //For fitlering records based on subscribtions
    this.menuMainModules.forEach((module) => {
      var isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => pages.some((a) => a.pagesId === submenu5.id && a.isLocked))))))));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => pages.some((a) => a.pagesId === submenu4.id && a.isLocked)))))));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => pages.some((a) => a.pagesId === submenu3.id && a.isLocked))))));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => pages.some((a) => a.pagesId === submenu2.id && a.isLocked)))));


      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => pages.some((a) => a.pagesId === submenu1.id && a.isLocked))));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => pages.some((a) => a.pagesId === submenu.id && a.isLocked)));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => pages.some((a) => a.pagesId === mainMenu.id && a.isLocked));

      module.filterCheck = isAllowModule;
      module.mainMenus.forEach((mainMenu) => {
        var isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => pages.some((a) => a.pagesId === submenu5.id && a.isLocked)))))));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => pages.some((a) => a.pagesId === submenu4.id && a.isLocked))))));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => pages.some((a) => a.pagesId === submenu3.id && a.isLocked)))));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => pages.some((a) => a.pagesId === submenu2.id && a.isLocked))));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => pages.some((a) => a.pagesId === submenu1.id && a.isLocked)));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => pages.some((a) => a.pagesId === submenu.id && a.isLocked));

        if (!isAllowmainMenu)
          isAllowmainMenu = pages.some((a) => a.pagesId === mainMenu.id && a.isLocked);

        mainMenu.filterCheck = isAllowmainMenu;
        // if (mainMenu.url !== "#" && !mainMenu.filterCheck)
        //   this.disAllowedPaths.push(mainMenu.url);

        mainMenu.subMenus.forEach((subMenu) => {
          var isAllowSubMenu = subMenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => pages.some((a) => a.pagesId === submenu5.id && a.isLocked))))));

          if (!isAllowSubMenu)
            isAllowSubMenu = subMenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => pages.some((a) => a.pagesId === submenu4.id && a.isLocked)))));

          if (!isAllowSubMenu)
            isAllowSubMenu = subMenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => pages.some((a) => a.pagesId === submenu3.id && a.isLocked))));

          if (!isAllowSubMenu)
            isAllowSubMenu = subMenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => pages.some((a) => a.pagesId === submenu2.id && a.isLocked)));

          if (!isAllowSubMenu)
            isAllowSubMenu = subMenu.subMenus.some((submenu1) => pages.some((a) => a.pagesId === submenu1.id && a.isLocked));

          if (!isAllowSubMenu)
            isAllowSubMenu = pages.some((a) => a.pagesId === subMenu.id && a.isLocked);

          subMenu.filterCheck = isAllowSubMenu

          // if (subMenu.url !== "#" && !subMenu.filterCheck)
          //   this.disAllowedPaths.push(subMenu.url);

          subMenu.subMenus.forEach((subMenu1) => {
            var isAllowSubMenu1 = subMenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => pages.some((a) => a.pagesId === submenu5.id && a.isLocked)))));

            if (!isAllowSubMenu1)
              isAllowSubMenu1 = subMenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => pages.some((a) => a.pagesId === submenu4.id && a.isLocked))));

            if (!isAllowSubMenu1)
              isAllowSubMenu1 = subMenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => pages.some((a) => a.pagesId === submenu3.id && a.isLocked)));

            if (!isAllowSubMenu1)
              isAllowSubMenu1 = subMenu1.subMenus.some((submenu2) => pages.some((a) => a.pagesId === submenu2.id && a.isLocked));

            if (!isAllowSubMenu1)
              isAllowSubMenu1 = pages.some((a) => a.pagesId === subMenu1.id && a.isLocked);

            subMenu1.filterCheck = isAllowSubMenu1

            // if (subMenu1.url !== "#" && !subMenu1.filterCheck)
            //   this.disAllowedPaths.push(subMenu1.url);

            subMenu1.subMenus.forEach((subMenu2) => {
              var isAllowSubMenu2 = subMenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => pages.some((a) => a.pagesId === submenu5.id && a.isLocked))));

              if (!isAllowSubMenu2)
                isAllowSubMenu2 = subMenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => pages.some((a) => a.pagesId === submenu4.id && a.isLocked)));

              if (!isAllowSubMenu2)
                isAllowSubMenu2 = subMenu2.subMenus.some((submenu3) => pages.some((a) => a.pagesId === submenu3.id && a.isLocked));

              if (!isAllowSubMenu2)
                isAllowSubMenu2 = pages.some((a) => a.pagesId === subMenu2.id && a.isLocked);

              subMenu2.filterCheck = isAllowSubMenu2

              // if (subMenu2.url !== "#" && !subMenu2.filterCheck)
              //   this.disAllowedPaths.push(subMenu2.url);

              subMenu2.subMenus.forEach((subMenu3) => {
                var isAllowSubMenu3 = subMenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => pages.some((a) => a.pagesId === submenu5.id && a.isLocked)));

                if (!isAllowSubMenu3)
                  isAllowSubMenu3 = subMenu3.subMenus.some((submenu4) => pages.some((a) => a.pagesId === submenu4.id && a.isLocked));

                if (!isAllowSubMenu3)
                  isAllowSubMenu3 = pages.some((a) => a.pagesId === subMenu3.id && a.isLocked);

                subMenu3.filterCheck = isAllowSubMenu3

                // if (subMenu3.url !== "#" && !subMenu3.filterCheck)
                //   this.disAllowedPaths.push(subMenu3.url);

                subMenu3.subMenus.forEach((subMenu4) => {
                  var isAllowSubMenu4 = subMenu4.subMenus.some((submenu5) => pages.some((a) => a.pagesId === submenu5.id && a.isLocked));

                  if (!isAllowSubMenu4)
                    isAllowSubMenu4 = pages.some((a) => a.pagesId === subMenu4.id && a.isLocked);

                  subMenu4.filterCheck = isAllowSubMenu4

                  // if (subMenu4.url !== "#" && !subMenu4.filterCheck)
                  //   this.disAllowedPaths.push(subMenu4.url);

                  subMenu4.subMenus.forEach((subMenu5) => {
                    subMenu5.filterCheck = pages.some((a) => a.pagesId === subMenu5.id && a.isLocked);
                    // if (subMenu5.url !== "#" && !subMenu5.filterCheck)
                    //   this.disAllowedPaths.push(subMenu5.url);
                  });
                });
              });
            });
          });
        });
      });
    });

    // this.menuMainModules = this.menuMainModules.filter(mmm => mmm.filterCheck)
    // .map(function(mmm){ mmm.mainMenus = mmm.mainMenus.filter(mm => mm.filterCheck)
    //     .map(function(mm) {mm.subMenus = mm.subMenus.filter(sm => sm.filterCheck)
    //       .map(function(sm) {sm.subMenus = sm.subMenus.filter(sm1 => sm1.filterCheck)
    //         .map(function(sm1) {sm1.subMenus = sm1.subMenus.filter(sm2 => sm2.filterCheck)
    //           .map(function(sm2) {sm2.subMenus = sm2.subMenus.filter(sm3 => sm3.filterCheck)
    //             .map(function(sm3) {sm3.subMenus = sm3.subMenus.filter(sm4 => sm4.filterCheck)
    //               .map(function(sm4) {sm4.subMenus = sm4.subMenus.filter(sm5 => sm5.filterCheck)
    //                 ; return sm4
    //               }); 
    //               return sm3
    //             }); 
    //             return sm2
    //           }); 
    //           return sm1
    //         }); 
    //         return sm
    //       });
    //       return mm;
    //     });
    //     return mmm;
    // });

  }

  sethasAccessForMenuMainModulesByAssignModules() {
    //For showing lock icon and set allowed modules paths
    this.menuMainModules.forEach((module) => {
      var isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => this.institutionAssignedModules.some((a) => a.pagesId === submenu5.id))))))));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => this.institutionAssignedModules.some((a) => a.pagesId === submenu4.id)))))));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => this.institutionAssignedModules.some((a) => a.pagesId === submenu3.id))))));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => this.institutionAssignedModules.some((a) => a.pagesId === submenu2.id)))));


      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => this.institutionAssignedModules.some((a) => a.pagesId === submenu1.id))));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => this.institutionAssignedModules.some((a) => a.pagesId === submenu.id)));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => this.institutionAssignedModules.some((a) => a.pagesId === mainMenu.id));

      module.hasAccess = isAllowModule;
      module.mainMenus.forEach((mainMenu) => {
        var isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => this.institutionAssignedModules.some((a) => a.pagesId === submenu5.id)))))));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => this.institutionAssignedModules.some((a) => a.pagesId === submenu4.id))))));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => this.institutionAssignedModules.some((a) => a.pagesId === submenu3.id)))));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => this.institutionAssignedModules.some((a) => a.pagesId === submenu2.id))));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => this.institutionAssignedModules.some((a) => a.pagesId === submenu1.id)));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => this.institutionAssignedModules.some((a) => a.pagesId === submenu.id));

        if (!isAllowmainMenu)
          isAllowmainMenu = this.institutionAssignedModules.some((a) => a.pagesId === mainMenu.id);

        mainMenu.hasAccess = isAllowmainMenu;
        if (mainMenu.url !== "#" && !mainMenu.hasAccess)
          this.disAllowedPaths.push(mainMenu.url);

        mainMenu.subMenus.forEach((subMenu) => {
          var isAllowSubMenu = subMenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => this.institutionAssignedModules.some((a) => a.pagesId === submenu5.id))))));

          if (!isAllowSubMenu)
            isAllowSubMenu = subMenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => this.institutionAssignedModules.some((a) => a.pagesId === submenu4.id)))));

          if (!isAllowSubMenu)
            isAllowSubMenu = subMenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => this.institutionAssignedModules.some((a) => a.pagesId === submenu3.id))));

          if (!isAllowSubMenu)
            isAllowSubMenu = subMenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => this.institutionAssignedModules.some((a) => a.pagesId === submenu2.id)));

          if (!isAllowSubMenu)
            isAllowSubMenu = subMenu.subMenus.some((submenu1) => this.institutionAssignedModules.some((a) => a.pagesId === submenu1.id));

          if (!isAllowSubMenu)
            isAllowSubMenu = this.institutionAssignedModules.some((a) => a.pagesId === subMenu.id);

          subMenu.hasAccess = isAllowSubMenu

          if (subMenu.url !== "#" && !subMenu.hasAccess)
            this.disAllowedPaths.push(subMenu.url);

          subMenu.subMenus.forEach((subMenu1) => {
            var isAllowSubMenu1 = subMenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => this.institutionAssignedModules.some((a) => a.pagesId === submenu5.id)))));

            if (!isAllowSubMenu1)
              isAllowSubMenu1 = subMenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => this.institutionAssignedModules.some((a) => a.pagesId === submenu4.id))));

            if (!isAllowSubMenu1)
              isAllowSubMenu1 = subMenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => this.institutionAssignedModules.some((a) => a.pagesId === submenu3.id)));

            if (!isAllowSubMenu1)
              isAllowSubMenu1 = subMenu1.subMenus.some((submenu2) => this.institutionAssignedModules.some((a) => a.pagesId === submenu2.id));

            if (!isAllowSubMenu1)
              isAllowSubMenu1 = this.institutionAssignedModules.some((a) => a.pagesId === subMenu1.id);

            subMenu1.hasAccess = isAllowSubMenu1

            if (subMenu1.url !== "#" && !subMenu1.hasAccess)
              this.disAllowedPaths.push(subMenu1.url);

            subMenu1.subMenus.forEach((subMenu2) => {
              var isAllowSubMenu2 = subMenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => this.institutionAssignedModules.some((a) => a.pagesId === submenu5.id))));

              if (!isAllowSubMenu2)
                isAllowSubMenu2 = subMenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => this.institutionAssignedModules.some((a) => a.pagesId === submenu4.id)));

              if (!isAllowSubMenu2)
                isAllowSubMenu2 = subMenu2.subMenus.some((submenu3) => this.institutionAssignedModules.some((a) => a.pagesId === submenu3.id));

              if (!isAllowSubMenu2)
                isAllowSubMenu2 = this.institutionAssignedModules.some((a) => a.pagesId === subMenu2.id);

              subMenu2.hasAccess = isAllowSubMenu2

              if (subMenu2.url !== "#" && !subMenu2.hasAccess)
                this.disAllowedPaths.push(subMenu2.url);

              subMenu2.subMenus.forEach((subMenu3) => {
                var isAllowSubMenu3 = subMenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => this.institutionAssignedModules.some((a) => a.pagesId === submenu5.id)));

                if (!isAllowSubMenu3)
                  isAllowSubMenu3 = subMenu3.subMenus.some((submenu4) => this.institutionAssignedModules.some((a) => a.pagesId === submenu4.id));

                if (!isAllowSubMenu3)
                  isAllowSubMenu3 = this.institutionAssignedModules.some((a) => a.pagesId === subMenu3.id);

                subMenu3.hasAccess = isAllowSubMenu3

                if (subMenu3.url !== "#" && !subMenu3.hasAccess)
                  this.disAllowedPaths.push(subMenu3.url);

                subMenu3.subMenus.forEach((subMenu4) => {
                  var isAllowSubMenu4 = subMenu4.subMenus.some((submenu5) => this.institutionAssignedModules.some((a) => a.pagesId === submenu5.id));

                  if (!isAllowSubMenu4)
                    isAllowSubMenu4 = this.institutionAssignedModules.some((a) => a.pagesId === subMenu4.id);

                  subMenu4.hasAccess = isAllowSubMenu4

                  if (subMenu4.url !== "#" && !subMenu4.hasAccess)
                    this.disAllowedPaths.push(subMenu4.url);

                  subMenu4.subMenus.forEach((subMenu5) => {
                    subMenu5.hasAccess = this.institutionAssignedModules.some((a) => a.pagesId === subMenu5.id);
                    if (subMenu5.url !== "#" && !subMenu5.hasAccess)
                      this.disAllowedPaths.push(subMenu5.url);
                  });
                });
              });
            });
          });
        });
      });
    });
  }

  sethasAccessForMenuMainModulesByAssignModulesRoleBased() {

    //For showing lock icon and set allowed modules paths
    this.menuMainModules.forEach((module) => {
      var isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu5.id && a.roleId === this.roleId))))))));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu4.id && a.roleId === this.roleId)))))));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu3.id && a.roleId === this.roleId))))));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu2.id && a.roleId === this.roleId)))));


      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu1.id && a.roleId === this.roleId))));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => mainMenu.subMenus.some((submenu) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu.id && a.roleId === this.roleId)));

      if (!isAllowModule)
        isAllowModule = module.mainMenus.some((mainMenu) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === mainMenu.id && a.roleId === this.roleId));

      module.hasAccess = isAllowModule;
      module.mainMenus.forEach((mainMenu) => {
        var isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu5.id && a.roleId === this.roleId)))))));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu4.id && a.roleId === this.roleId))))));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu3.id && a.roleId === this.roleId)))));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu2.id && a.roleId === this.roleId))));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => submenu.subMenus.some((submenu1) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu1.id && a.roleId === this.roleId)));

        if (!isAllowmainMenu)
          isAllowmainMenu = mainMenu.subMenus.some((submenu) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu.id && a.roleId === this.roleId));

        if (!isAllowmainMenu)
          isAllowmainMenu = this.getRolesLinkedPagePermissions.some((a) => a.pageId === mainMenu.id && a.roleId === this.roleId);

        mainMenu.hasAccess = isAllowmainMenu;
        if (mainMenu.url !== "#" && !mainMenu.hasAccess)
          this.disAllowedPaths.push(mainMenu.url);

        mainMenu.subMenus.forEach((subMenu) => {
          var isAllowSubMenu = subMenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu5.id && a.roleId === this.roleId))))));

          if (!isAllowSubMenu)
            isAllowSubMenu = subMenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu4.id && a.roleId === this.roleId)))));

          if (!isAllowSubMenu)
            isAllowSubMenu = subMenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu3.id && a.roleId === this.roleId))));

          if (!isAllowSubMenu)
            isAllowSubMenu = subMenu.subMenus.some((submenu1) => submenu1.subMenus.some((submenu2) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu2.id && a.roleId === this.roleId)));

          if (!isAllowSubMenu)
            isAllowSubMenu = subMenu.subMenus.some((submenu1) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu1.id && a.roleId === this.roleId));

          if (!isAllowSubMenu)
            isAllowSubMenu = this.getRolesLinkedPagePermissions.some((a) => a.pageId === subMenu.id && a.roleId === this.roleId);

          subMenu.hasAccess = isAllowSubMenu

          if (subMenu.url !== "#" && !subMenu.hasAccess)
            this.disAllowedPaths.push(subMenu.url);

          subMenu.subMenus.forEach((subMenu1) => {
            var isAllowSubMenu1 = subMenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu5.id && a.roleId === this.roleId)))));

            if (!isAllowSubMenu1)
              isAllowSubMenu1 = subMenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu4.id && a.roleId === this.roleId))));

            if (!isAllowSubMenu1)
              isAllowSubMenu1 = subMenu1.subMenus.some((submenu2) => submenu2.subMenus.some((submenu3) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu3.id && a.roleId === this.roleId)));

            if (!isAllowSubMenu1)
              isAllowSubMenu1 = subMenu1.subMenus.some((submenu2) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu2.id && a.roleId === this.roleId));

            if (!isAllowSubMenu1)
              isAllowSubMenu1 = this.getRolesLinkedPagePermissions.some((a) => a.pageId === subMenu1.id && a.roleId === this.roleId);

            subMenu1.hasAccess = isAllowSubMenu1

            if (subMenu1.url !== "#" && !subMenu1.hasAccess)
              this.disAllowedPaths.push(subMenu1.url);

            subMenu1.subMenus.forEach((subMenu2) => {
              var isAllowSubMenu2 = subMenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu5.id && a.roleId === this.roleId))));

              if (!isAllowSubMenu2)
                isAllowSubMenu2 = subMenu2.subMenus.some((submenu3) => submenu3.subMenus.some((submenu4) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu4.id && a.roleId === this.roleId)));

              if (!isAllowSubMenu2)
                isAllowSubMenu2 = subMenu2.subMenus.some((submenu3) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu3.id && a.roleId === this.roleId));

              if (!isAllowSubMenu2)
                isAllowSubMenu2 = this.getRolesLinkedPagePermissions.some((a) => a.pageId === subMenu2.id && a.roleId === this.roleId);

              subMenu2.hasAccess = isAllowSubMenu2

              if (subMenu2.url !== "#" && !subMenu2.hasAccess)
                this.disAllowedPaths.push(subMenu2.url);

              subMenu2.subMenus.forEach((subMenu3) => {
                var isAllowSubMenu3 = subMenu3.subMenus.some((submenu4) => submenu4.subMenus.some((submenu5) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu5.id && a.roleId === this.roleId)));

                if (!isAllowSubMenu3)
                  isAllowSubMenu3 = subMenu3.subMenus.some((submenu4) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu4.id && a.roleId === this.roleId));

                if (!isAllowSubMenu3)
                  isAllowSubMenu3 = this.getRolesLinkedPagePermissions.some((a) => a.pageId === subMenu3.id && a.roleId === this.roleId);

                subMenu3.hasAccess = isAllowSubMenu3

                if (subMenu3.url !== "#" && !subMenu3.hasAccess)
                  this.disAllowedPaths.push(subMenu3.url);

                subMenu3.subMenus.forEach((subMenu4) => {
                  var isAllowSubMenu4 = subMenu4.subMenus.some((submenu5) => this.getRolesLinkedPagePermissions.some((a) => a.pageId === submenu5.id && a.roleId === this.roleId));

                  if (!isAllowSubMenu4)
                    isAllowSubMenu4 = this.getRolesLinkedPagePermissions.some((a) => a.pageId === subMenu4.id && a.roleId === this.roleId);

                  subMenu4.hasAccess = isAllowSubMenu4

                  if (subMenu4.url !== "#" && !subMenu4.hasAccess)
                    this.disAllowedPaths.push(subMenu4.url);

                  subMenu4.subMenus.forEach((subMenu5) => {
                    subMenu5.hasAccess = this.getRolesLinkedPagePermissions.some((a) => a.pageId === subMenu5.id && a.roleId === this.roleId);
                    if (subMenu5.url !== "#" && !subMenu5.hasAccess)
                      this.disAllowedPaths.push(subMenu5.url);
                  });
                });
              });
            });
          });
        });
      });
    });
  }
  handleMenuClick(menuDetails: any) {
    console.log(menuDetails);
    const res=this.allSubscribtions.filter(x=>x.moduleName===menuDetails.moduleName);
    console.log(res);
    if (!menuDetails.hasAccess)
      Swal.fire({
        imageUrl: "https://cdn-icons-png.flaticon.com/512/891/891399.png",
        imageHeight: 80,
        imageAlt: "Features Locked",
        // imageUrl: "https://placeholder.pics/svg/300x1500",
        title: 'Features Locked',
        text:'This feature is available in Advanced plan',
        
        showCancelButton: true,
        cancelButtonText:'Close',
        // footer: '<a href="#">Why do I have this issue?</a>',
        // html: `
        // You can use <b>bold text</b>,
        // <a href="#">links</a>,
        // and other HTML tags`,
        confirmButtonText:'<a style="color:#ffffff" href="https://edumaq.in">View Plans</a>',
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      });
  }

  toggleCollapsed(item: any, menuList) {
    menuList.forEach((menuItem) => {
      if (menuItem !== item) {
        menuItem.isCollapsed = false;
      }
    });
    item.isCollapsed = !item.isCollapsed;
  }
  getSessionExpiryTime() {
    const token = localStorage.getItem("JWT_TOKEN");
    if (token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      let tokenProperty = JSON.parse(jsonPayload);

      //var expiredatetime=new Date(tokenProperty.jti.split("__")[1]);

      if (!this.sessionExpiryTime && this.sessionExpiryTime != 0) {
        this.sessionExpiryTime = tokenProperty["sessionExpiryTime"];

        //this.sessionExpiryTimeInSeconds = 0.05;
      }
    }
    console.log(this.sessionExpiryTime);
  }

  // checkTokenExpiry() {
  //   setInterval(() => {
  //     var t1 = new Date();
  //     var t2 = new Date();
  //     var dif = t1.getTime() - t2.getTime();
  //     this.tokenExpirySecond = Math.floor(dif / 1000);
  //     if (this.tokenExpirySecond < 11) {

  //       var classes = document.getElementById("tokenModal").className;
  //       if (!classes.includes("show")) {
  //         document.getElementById("openModal").click();
  //       }

  //       if (this.tokenExpirySecond <= 0) {
  //
  //         this.logout();
  //       }
  //     }
  //   }, 1000);
  // }

  //ngAfterViewInit(): void {
  //   let s = document.createElement('script');
  //   s.type = 'text/javascript';
  //   s.src = '../assets/apexcharts/apexcharts.min.js';
  //   this.elementRef.nativeElement.appendChild(s);
  //   s = document.createElement('script');
  //   s.type = 'text/javascript';
  //   s.src = '../assets/js/pages/apexcharts.init.js';
  //   this.elementRef.nativeElement.appendChild(s);
  // }

  ngAfterViewInit(): void {
    this.collapseWorking();
  }

  ngAfterViewChecked(): void {
    this.collapseWorking();
  }

  collapseWorking(): void {
    $("#side-menu").metisMenu();
    $("#vertical-menu-btn").on("click", (e) => {
      e.preventDefault();
      $("body").toggleClass("sidebar-enable"),
        992 <= $(window).width()
          ? $("body").toggleClass("vertical-collpsed")
          : $("body").removeClass("vertical-collpsed");

      $("body").toggleClass("sidebar-enable"),
        992 <= $(window).width()
          ? $(".connectOptions").toggle()
          : $(".connectOptions").hide();
    });
    $("#sidebar-menu a").each(() => {
      const e = window.location.href.split(/[?#]/)[0];
      // tslint:disable-next-line: triple-equals
      // tslint:disable-next-line: no-unused-expression
      $(this).href === e &&
        ($(this).addClass("active"),
          $(this).parent().addClass("mm-active"),
          $(this).parent().parent().addClass("mm-show"),
          $(this).parent().parent().prev().addClass("mm-active"),
          $(this).parent().parent().parent().addClass("mm-active"),
          $(this).parent().parent().parent().parent().addClass("mm-show"),
          $(this)
            .parent()
            .parent()
            .parent()
            .parent()
            .parent()
            .addClass("mm-active"));
    });
    $(".navbar-nav a").each(() => {
      const e = window.location.href.split(/[?#]/)[0];
      // tslint:disable-next-line:no-unused-expression
      $(this).href === e &&
        ($(this).addClass("active"),
          $(this).parent().addClass("active"),
          $(this).parent().parent().addClass("active"),
          $(this).parent().parent().parent().addClass("active"),
          $(this).parent().parent().parent().parent().addClass("active"));
    });
  }

  getRoute(searchResult): string {
    console.log(searchResult);
    if (searchResult.pageUrl = '#') {
      return '#';
    } else {
      return `${'/' + this.institutionCode + searchResult.pageUrl}`;
    }
  }
}
