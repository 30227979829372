import { Component, OnInit } from '@angular/core';
import { FeeGroupService } from './fee-group.service';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Batch } from '../../mastersettings/models/batch';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { FeeGroup } from './fee-group.model';
import { AssignedToGroupStudentType } from '../../mastersettings/models/assignedToGroupStudentType';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fee-group',
  templateUrl: './fee-group.component.html',
  styleUrls: ['./fee-group.component.css']
})
export class FeeGroupComponent implements OnInit {
  groupForm: UntypedFormGroup;
  groups: FeeGroup[] = [];
  groupFilteredList: FeeGroup[] = [];
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  resClassIds: any[] = [];
  selectedClassIds = '';
  resBatchIds: any[] = [];
  selectedBatchIds = '';
  submitted = false;
  
  HighlightRow : Number;
  ClickedRow:any;
  checked:any;
  indexType: number;

  constructor(
    public groupService: FeeGroupService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) 

{ 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }

  } 

  ListGroup() {
    this.groupService.getAll().subscribe((data: FeeGroup[]) => {
      console.log(data);
      this.groupFilteredList = this.groups = data;
    });
  }
  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
      this.batchService.getAll().subscribe((bdata: Batch[]) => {
        this.classes.forEach(x => {
          const odata = bdata.filter((batch: Batch) => batch.classId==x.id);
          odata.forEach(b=>{
            b.assignedToGroupStudentType = AssignedToGroupStudentType.None;
          })
          x.batches = odata;
        });
      });
    });
  }

  ListbatchCourse() {
    this.batchService.getAll().subscribe((bdata: Batch[]) => {
      this.batches = bdata;         
    });      
}
checkAllCheckBox(ev) {
  this.classes.forEach(x => x.checked = ev.target.checked);
}

isAllCheckBoxChecked() {
  return this.classes.every(p => p.checked);
}

onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

filter(keyword: any) {
  if (keyword === '') {
    this.groupFilteredList = this.groups;
  } else {
    this.groupFilteredList = this.groups
      .filter((group: FeeGroup) => group.groupName.toLocaleLowerCase()
        .includes(keyword.toLocaleLowerCase()) ||
        group.classIds.toLocaleLowerCase()
        .includes(keyword.toLocaleLowerCase())
      );
  }
}
get totalRows(): number {
  return this.groupFilteredList.length;
}
get f() { return this.groupForm.controls; }
deleteForm(id: number) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You won\'t be able to revert this!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.groupService.delete(id).subscribe(res => {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
        this.ListGroup();
      });
    // For more information about handling dismissals please visit
    // https://sweetalert2.github.io/#handling-dismissals
    } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire(
      'Cancelled',
      'Your imaginary file is safe :)',
      'error'
    );
    }
  });
}

resetForm() {
  this.selectedClassIds = '';
  this.selectedBatchIds = '';
  this.submitted = false;
  this.classes.forEach((x) => {
    x.checked=false;
    x.batches.forEach(b=>{     
          b.checked=false;      
    });
  });
  this.batches.forEach(x => x.checked = false);
  this.HighlightRow=-1;
  this.groupForm = this.fb.group({
    id: [0],
    groupName: ['', Validators.required],
    classIds: ['', Validators.required],
    batchIds: ['', Validators.required],
    status: ['true', Validators.required],
    stypeId:['', Validators.required]
  });
}

updateForm(id: number) {
  this.resetForm()
  this.resClassIds=[];
  this.resBatchIds=[];
  this.classes.forEach(x=>{
    x.checked=false;
  });

  this.batches.forEach(x=>{
    x.checked=false;
  });
  this.groupService.getById(id).subscribe((data: FeeGroup) => {
    
    this.resClassIds = data.classIds.split('|');
    this.resBatchIds=data.batchIds.split('|');
    this.classes.forEach(x => {
      for (let i = 0; i < this.resClassIds.length; i++) {
        if (x.id.toString() === this.resClassIds[i]) {
          x.checked = true;
        }
        x.batches.forEach(batchSelected => {
          for (let i = 0; i < this.resBatchIds.length; i++) {
            if (batchSelected.id.toString() === this.resBatchIds[i]) {
              console.log(x.id);
              batchSelected.checked = true;
            }
          }
        });
      }
    });
  /*if(data.batchIds!==null)
  {
    this.resBatchIds = data.batchIds.split('|');
    console.log("batchIds : "+this.resBatchIds)
    this.batches.forEach(x => {
      for (let i = 0; i < this.resBatchIds.length; i++) {
        if (x.id.toString() === this.resBatchIds[i]) {
          console.log(x.id);
          x.checked = true;
        }
      }
    });
  }*/

    this.groupForm = this.fb.group({
      id: [data.id],
      groupName: [data.groupName, Validators.required],
      classIds: [data.classIds, Validators.required],
      batchIds: [data.batchIds, Validators.required],
      status: [data.status.toString(), Validators.required],
      stypeId:[data.stypeId, Validators.required]
    });
  });

  /*this.classes.forEach(x => {
    for (let i = 0; i < this.resClassIds.length; i++) {
      if (x.id.toString() === this.resClassIds[i]) {
        x.checked = true;
      }
      x.batches.forEach(batchSelected => {
        for (let i = 0; i < this.resBatchIds.length; i++) {
          if (batchSelected.id.toString() === this.resBatchIds[i]) {
            console.log(x.id);
            batchSelected.checked = true;
          }
        }
      });
    }
  });*/

}

onChangeClassEvent(e:any, classCrs: ClassCourse) {
  var splittedClassIds = this.selectedClassIds.length > 0 ? this.selectedClassIds.split('|') : [];
  
  if(e.checked === true) {
    splittedClassIds.push(classCrs.id+'');
    this.classes.filter(x=>x.id==classCrs.id)[0].checked=true;
  }
  else{
    splittedClassIds = splittedClassIds.filter(x => x !== classCrs.id+'');
    classCrs.batches.forEach(x=>x.checked=false);
  }
  this.selectedClassIds = splittedClassIds.length === 1 ? splittedClassIds[0] :  splittedClassIds.join('|'); 
}
onChangeBatchEvent(e:any, batch: Batch) {
  debugger;
  var splittedBatchIds = this.selectedBatchIds.length > 0 ? this.selectedBatchIds.split('|') : [];
  let isAssigned=false;
  if(e.checked === true) {
      this.classes.filter(x=>x.id==batch.classId)[0].checked=false;
      this.classes.filter(x=>x.id==batch.classId)[0].checked=true;
    
    this.classes.forEach((x, i) => {
      x.batches.forEach(b=>{       
          if(b.id===batch.id ){
            if(batch.assignedToGroupStudentType==this.indexType){
              e.checked=false;
              isAssigned=true;
            }else{
            b.checked=true;
            splittedBatchIds.push(batch.id+'');
          }
        }
          
      });
    });
    
    if(isAssigned){
      this.toastr.warning('This Batch is already assigned to another group', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }

  } 
  else{
    splittedBatchIds = splittedBatchIds.filter(x => x !== batch.id+'');
    this.classes.forEach((x) => {
      x.batches.forEach(b=>{
          if(b.id===batch.id){
            b.checked=false;
          }
          if(x.batches.filter(x=>x.checked).length===0){
              x.checked=false
          }
      });
    });
  }
  this.selectedBatchIds = splittedBatchIds.length === 1 ? splittedBatchIds[0] : splittedBatchIds.join('|'); 
}

submitForm() {
  debugger;
  this.submitted = true;

  this.selectedClassIds = '';
  this.selectedBatchIds = '';
  let k = 1;
  const totalSelected = this.classes.filter(x => x.checked).length;
  this.classes.forEach((x, i) => {
    if (x.checked) {
      if (totalSelected !== k) {
        this.selectedClassIds += x.id.toString() + '|';
      } else {
        this.selectedClassIds += x.id.toString();
      }

       x.batches.forEach((y) => {
         if (y.checked) {
             this.selectedBatchIds += y.id.toString() + '|';
         }
       });

      k++;
    }
  });

  // if(this.selectedBatchIds.length > 0){
  //   this.selectedBatchIds = this.selectedBatchIds.substring(0, this.selectedBatchIds.length - 1);
  // }

  let kk = 1;
  const totalbatchSelected = this.batches.filter(x => x.checked).length;
 /* this.batches.forEach((x, i) => {
    if (x.checked) {
      if (totalbatchSelected !== kk) {
        this.selectedBatchIds += x.id.toString() + '|';
      } else {
        this.selectedBatchIds += x.id.toString();
      }
      kk++;
    }
  }); */

  if (this.selectedClassIds.length === 0) {
    this.toastr.warning('Please select class', 'WARNING!', {
      timeOut: 3000
    });
    return;
  }

  if (this.selectedBatchIds.length === 0) {
    this.toastr.warning('Please select batch', 'WARNING!', {
      timeOut: 3000
    });
    return;
  }
  /*if (this.selectedBatchIds.length === 0) {
    this.toastr.warning('Please select Batch', 'WARNING!', {
      timeOut: 3000
    });
  }
  else {
      var splittedClassIds = this.selectedClassIds.split('|');
      var splittedBatchIds = this.selectedBatchIds.split('|');
      var selectedClasses = this.classes.filter(cl => splittedClassIds.indexOf(cl.id+'') > -1);
      var selectedBatches = this.batches.filter(b => splittedBatchIds.indexOf(b.id+'') > -1);
      var validationError = false;
      selectedBatches.forEach(b => {
        if(!(selectedClasses.findIndex(cl=>b.classId === cl.id) > -1)) {
          this.toastr.warning('Please select Class', 'WARNING!', {
            timeOut: 3000
          });
          validationError = true;
          return;
        }
      });
      if(!validationError) {
        selectedClasses.forEach(cl => {
          var flagClassBatchNotAvailable = true;
          for (let i = 0; i < cl.batches.length; i++) {
              if(selectedBatches.findIndex(sb=>sb.id === cl.batches[i].id) > -1){
                flagClassBatchNotAvailable=false;
              }
            }
            
            if(flagClassBatchNotAvailable) {
              this.toastr.warning('Please select Batch', 'WARNING!', {
                timeOut: 3000
              });
              validationError = true;
              return;
            }            
        });
      }

      if(validationError){
        return;
      }
  }*/
//&& x.stypeId===this.groupForm.get('stypeId').value) > -1
debugger;
   

  this.groupForm.get('classIds').patchValue(this.selectedClassIds);
  this.groupForm.get('batchIds').patchValue(this.selectedBatchIds);
      // stop here if form is invalid
  if (this.groupForm.invalid) {
    return;
  }

  if (this.groupForm.get('id').value === 0) {
    if (this.groupFilteredList.findIndex(x => x.groupName === this.groupForm.get('groupName').value)>-1) {
      this.toastr.warning('Unable to add same group with the same type of student', 'WARNING!', {
        timeOut: 3000
      });
      return;
    } 
      this.groupService.create(this.groupForm.value).subscribe(res => {
        console.log('Group created!');
        this.ListGroup();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.groupService.update(this.groupForm.get('id').value, this.groupForm.value).subscribe(res => {
          console.log('Group updated!');
          this.ListGroup();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
      }
    }

    onFilterSelected(value) {
     this.indexType=0;
      switch(value) {
        case 'Day Scholar':
          this.indexType=1;
          break;
        case 'Boarding':
          this.indexType=2;
          break;
      }
      if (value === '') {
        this.groupFilteredList = this.groups;
      } else {
        this.groupFilteredList = this.groups
          .filter((group: FeeGroup) => group.stypeId === value);
         for (let i = 0; i < this.classes.length; i++) {
            this.classes[i].checked = null;
            for (let j = 0; j < this.classes[i].batches.length; j++) {
              this.classes[i].batches[j].assignedToGroupStudentType = AssignedToGroupStudentType.None;
              for(let k = 0; k< this.groupFilteredList.length; k++) {
                if(this.groupFilteredList[k].classIds) {
                  if(this.groupFilteredList[k].batchIds) {
                    var splittedClassIds = this.groupFilteredList[k].classIds.split('|');
                    var splittedBatchIds = this.groupFilteredList[k].batchIds.split('|');
                    if(splittedClassIds.indexOf(this.classes[i].id+'') > -1) {
                      if(splittedBatchIds.indexOf(this.classes[i].batches[j].id+'') > -1) {
                        switch(this.groupFilteredList[k].stypeId) {
                          case 'Day Scholar':
                            this.classes[i].batches[j].assignedToGroupStudentType = AssignedToGroupStudentType.DayScholar;
                            break;
                          case 'Boarding':
                            this.classes[i].batches[j].assignedToGroupStudentType = AssignedToGroupStudentType.Boarding;
                            break;
                        }
                      }
                    }
                  }
                }
              }
             } 

            /* if(this.classes[i].batches.length > 0 && this.classes[i].batches.every(x=>this.isBatchBelongstoAnyGroup(x))){
              this.classes[i].checked = true;
             }*/

           }
      }
      console.log("classe after filter "+this.classes);
      console.log("batches after filter "+this.batches);
    }
    private isBatchBelongstoAnyGroup(batch: Batch) {
      return batch.assignedToGroupStudentType === 1
        || batch.assignedToGroupStudentType === 2
        || batch.assignedToGroupStudentType === 3;
    }
  ngOnInit(): void {
    this.ListGroup();
    this.ListClassCourse();
    this.ListbatchCourse();
    this.groupForm = this.fb.group({
      id: [0],
      groupName: ['', Validators.required],
      classIds: ['', Validators.required],
      batchIds: ['', Validators.required],
      status: ['true', Validators.required],
      stypeId: ['', Validators.required],
    });
  }

}
