import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
import { EdumaqComponent } from './edumaq/edumaq.component';
import {EdumaqRoutingModule} from './edumaq-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '../authentication/token.interceptor';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ConfigurationModule } from './configuration/configuration.module';
import { SupportModule } from './support/support.module';
import { LeadmanageModule } from './leadmanage/leadmanage.module';
import { BillingModule } from './billingmanagement/billing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModulemanagementComponent } from './configuration/modulemanagement/modulemanagement.component';


@NgModule({
  declarations: [EdumaqComponent, DashboardComponent, ModulemanagementComponent],
  imports: [
    CommonModule,
    NgApexchartsModule,
    EdumaqRoutingModule,
    ConfigurationModule,
    SupportModule,
    LeadmanageModule,
    BillingModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class EdumaqModule { }
