import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
  })
export class AnalyticService {
    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
    constructor(private httpClient: HttpClient) { }

    getAnalyticData(periodType: number): Observable<any> {
        return this.httpClient.get<any>(environment.apiUrl + '/Analytic/GetAnalyticData/' + periodType)
        .pipe(
          catchError(this.errorHandler)
        );
    }

    getAnalyticGraphData(periodType: number): Observable<any[]> {
      return this.httpClient.get<any[]>(environment.apiUrl +
        '/Analytic/GetAnalyticsGraphData/' + periodType)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.message;
        } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
     }


}