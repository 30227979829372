import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { OfflineExaminationRoutingModule } from './offline-examination-routing.module';
//import { ExamCategoryComponent } from './ExamSetting/exam-category/exam-category.component';
import { OfflineExaminationComponent } from './offline-examination/offline-examination.component';
import { ExamSettingComponent } from './ExamSetting/exam-setting/exam-setting.component';
import { ExamSettingModule } from './ExamSetting/exam-setting.module';
//import { GradeScaleComponent } from './ExamSetting/grade-scale/grade-scale.component';
import { ExamPlannerModule } from './exam-planner/exam-planner.module'
import { ExamSchedulingModule } from './exam-scheduling/exam-scheduling.module'
import { AssignExamMarksModule } from './assign-exam-marks/assign-exam-marks.module';
import { MarksEntryModule } from './marks-entry/marks-entry.module';
import { FreezeExamMarkModule } from './freeze-exam-mark/freeze-exam-mark.module';
import {ExamremarksModule} from './examremarks/examremarks.module';
import {ExamrankingModule} from './examranking/examranking.module';
import { ExamWiseAttendenceModule } from './exam-wise-attendence/exam-wise-attendence.module';
import { ExamResultReportModule } from './exam-result-report/exam-result-report.module';
import { StudentWiseReportCardModule } from './student-wise-report-card/student-wise-report-card.module';
import { PublishResultReportModule } from './publish-result-report/publish-result-report.module';
import { FinalReportCardModule } from './final-report-card/final-report-card.module';
import { ExamReportCardExamWiseComponent } from './exam-report-card-exam-wise/exam-report-card-exam-wise.component';
import { ExamReportCardTermWiseComponent } from './exam-report-card-term-wise/exam-report-card-term-wise.component';
import { ExamReportCardComponent } from './exam-report-card/exam-report-card.component';
import { TeacherWiseExamReportModule } from './teacher-wise-exam-report/teacher-wise-exam-report.module';
import { SubjectWiseExamReportModule } from './subject-wise-exam-report/subject-wise-exam-report.module';
import { ConsolidateExamReportModule } from './consolidate-exam-report/consolidate-exam-report.module';

@NgModule({
  declarations: [ OfflineExaminationComponent, ExamSettingComponent,ExamReportCardExamWiseComponent,
    ExamReportCardTermWiseComponent,ExamReportCardComponent],
  imports: [
    CommonModule,
    OfflineExaminationRoutingModule,
    ExamPlannerModule,
    ExamSchedulingModule,
    AssignExamMarksModule,
    MarksEntryModule,
    FreezeExamMarkModule,
    ExamSettingModule,
    FormsModule,
    ReactiveFormsModule,
    ExamremarksModule,
    ExamrankingModule,
    ExamWiseAttendenceModule,
    ExamResultReportModule,
    StudentWiseReportCardModule,
    PublishResultReportModule,    
    FinalReportCardModule,
    SubjectWiseExamReportModule,
    TeacherWiseExamReportModule,
    ConsolidateExamReportModule
  ]
})
export class OfflineExaminationModule { }
