import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Stream } from '../models/stream';

@Injectable({
  providedIn: 'root'
})
export class StreamService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<Stream[]> {
    return this.httpClient.get<Stream[]>(environment.apiUrl + '/stream/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<Stream> {
    return this.httpClient.get<Stream>(environment.apiUrl + '/stream/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(stream): Observable<Stream> {
    return this.httpClient.post<Stream>(environment.apiUrl + '/stream/', JSON.stringify(stream), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, stream): Observable<Stream> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.put<Stream>(environment.apiUrl + '/stream/' + id, JSON.stringify(stream), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<Stream>(environment.apiUrl + '/stream/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
