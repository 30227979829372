import { HttpParams } from "@angular/common/http";

export function removeWithoutValuePropertiesFromObject(obj: object) {
    Object.keys(obj).forEach(key => {
        if (obj[key] === null || obj[key] === undefined) {
            delete obj[key];
        }
    });
}

export function convertObjectToHttpParams(objectItem: object): HttpParams {
    return new HttpParams({
        fromObject: objectItem as any
    });
}




  