export class CbseWeightage {
    constructor(
    public id:number,
    public weightages:string,       
    public criteriaId:number,
    public criteria:string,
    public examCategoryId:number,
    public examCategory:string,
    public weightageStatus:boolean,
        ){}
}

