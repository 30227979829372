import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {ApplicantRegistrationService} from '../applicantregistration/applicantregistration.service';
import { ApplicantRegistration } from '../applicantregistration/applicantregistration.model';
import { ClassCourse } from '../mastersettings/models/classcourse';
import { ClassCourseService } from '../mastersettings/classcourse/classcourse.service';
import { environment } from '../../../../environments/environment';
import { ReportAttribute } from '../studentmanagement/report.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
/// import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable.js';
import * as moment from 'moment';
import { FunctionService } from 'src/app/shared/function.service';
import { SettingsService } from '../mastersettings/settings.service';
import { Academicyear } from '../mastersettings/models/academicyear';

declare var $: any;

@Component({
  selector: 'app-applicantregistrationreport',
  templateUrl: './applicantregistrationreport.component.html',
  styleUrls: ['./applicantregistrationreport.component.css']
})
export class ApplicantregistrationreportComponent implements OnInit {

  applicantRegistrations: ApplicantRegistration[] = [];
  registrationfilteredlist: ApplicantRegistration[] = [];
  registrationselectedlist: ApplicantRegistration[] = [];
  classcourses: ClassCourse[] = [];
  // fromDate: string;
  // toDate: string;
  classcourseid = '0';
  status = 'All Status';
  source = 'All Source';
  fromDate: Date;
  toDate: Date;
  fileName = 'registrationReport.xlsx';
  headerList: string[] = [];
  dateValidation: boolean = false;
  fieldList: string[] = [];
  attributes: ReportAttribute[] = [];
  institution: any;
  currentAcademicYearId: number;
  currentAcademicYearName = '';
  academicYear:Academicyear[]=[];
  

  constructor(
    private registrationService: ApplicantRegistrationService,
    private classcourseService: ClassCourseService,
    private toastr: ToastrService,
    public fun: FunctionService,
    public settingsService:SettingsService
  ) {
    this.GetCurrentAcademicYear()
   }

  // exportAsPDF() {
  //   const data = document.getElementById('registrationtable');
  //   html2canvas(data).then(canvas => {
  //     const contentDataURL = canvas.toDataURL('image/png');
  //     const pdf = new jspdf('l', 'cm', 'a4'); // Generates PDF in landscape mode
  //     // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
  //     pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
  //     pdf.save('registrationReport.pdf');
  //   });
  // }

  // exportexcel(): void {
  //      /* table id is passed over here */
  //      const element = document.getElementById('registrationtable');
  //      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  //      ws.addRow(['1', '2', '333']);
  //      /* generate workbook and add the worksheet */
  //      const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //      /* save to file */
  //      XLSX.writeFile(wb, this.fileName);

  //   }

    generateAttributes() {
      this.attributes = [
        {name: 'S NO', fieldName: 'index' },
        {name: 'REG NO', fieldName: 'registerNo' },
        {name: 'FORM NO', fieldName: 'formNo' },
        {name: 'CLASS', fieldName: 'className' },
        {name: 'APPLICANT NAME', fieldName: 'studentName' },
        {name: 'FATHERS NAME', fieldName: 'fatherFullName' },
        {name: 'CONTACT NO', fieldName: 'contactNumber' },
        {name: 'REG DATE', fieldName: 'registrationDate' },
        {name: 'SOURCE', fieldName: 'applicationSource' }
      ];
    }

    generateExcel() {
      this.headerList =[];
      this.fieldList =[];
      this.attributes.forEach(x => {
        this.headerList.push(x.name);
        this.fieldList.push(x.fieldName);
      });

      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet('APPLICANT REGISTRATION');
      worksheet.addRow();
      const imageId2 = workbook.addImage({
        base64: this.institution.institutionLogo,
        extension: 'jpg',
      });
      worksheet.addImage(imageId2, 'A1:B1');
      const titleRow = worksheet.addRow(['APPLICANT REGISTRATION']);
      const headerRow = worksheet.addRow(this.headerList);
      for(var i = 0 ; i < this.registrationfilteredlist.length ; i++)
      this.registrationfilteredlist[i]["index"] = i+1;
      for (const x1 of this.registrationfilteredlist) {
        const x2 = Object.keys(x1);
        const temp = [];
        for (const y of this.fieldList) {
          if (y === 'dob') {
            temp.push(this.formatDate(x1[y]));
          } else {
            temp.push(x1[y]);
          }
          // x2.forEach((e) => {
          //   if (e.toLocaleLowerCase() === y.toLocaleLowerCase()) {
          //   }
          // });
        }
        worksheet.addRow(temp);
      }
      const fname = 'Student Report';

      // add data and file name and download
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
      });

    }

    tdtchange(event: any) {
      var tdt = event.target.value
      if (tdt == null || tdt == undefined || tdt == '') {
        this.dateValidation = false;
        this.toDate = null;
      }
    }
    fdtchange(event: any) {
      var tdt = event.target.value
      if (tdt == null || tdt == undefined || tdt == '') {
        this.dateValidation = false;
        this.fromDate = null;
      }
    }

    GetCurrentAcademicYear() {
      this.settingsService.getAll().subscribe((data: Academicyear[]) => {
        this.academicYear = data;
        this.currentAcademicYear();
      });
    }

    currentAcademicYear() {
      this.settingsService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
        this.currentAcademicYearId = data.id;
        this.currentAcademicYearName = this.academicYear.find(x => x.id.toString() === data.id.toString()).name;
      });
    }

    generatePDF() {
      this.headerList =[];
      this.fieldList =[];
      const pdffromdate = $('#fromDate').val();
      const pdftodate = $('#toDate').val();
      this.attributes.forEach(x => {
        this.headerList.push(x.name);
        this.fieldList.push(x.fieldName);
      });
      // const widthList = [];
      // for (const item of this.headerList) {
      //     widthList.push('auto');
      // }
      for(var i = 0 ; i < this.registrationfilteredlist.length ; i++)
      this.registrationfilteredlist[i]["index"] = i+1;
      const docDefinition = {
        content: [
          {
              table: {
                  widths: ['auto', '*'],
                  body: [
                      [ {
                          width: 100,
                          height: 100,
                          image: 'imageKey'
                      }, {
                          width: '*',
                          alignment: 'center',
                          stack: [
                              {
                                  style: 'h1',
                                  text: this.institution.institutionName
                              },
                              {
                                style: 'h2',
                                text: this.institution.address
                              },
                              {
                                  style: 'h2',
                                  text: this.currentAcademicYearName
                              },
                              {
                                  style: 'h1',
                                  text: 'APPLICANT REGISTRATION'
                              },
                              {
                                style: 'h2',
                                text: pdffromdate + '   to   ' + pdftodate
                              }
                          ]
                      }
                      ]
                  ]
              },
              layout: {
                hLineWidth: () =>  0,
                vLineWidth: () => 0,
                paddingBottom: () => 5,
                paddingLeft:()=> 5,
                paddingRight:()=> 0
              },
            },
            {
              style:'sectionHeader',
              table: {
                headerRows: 1,
                widths: ['5%','10%','10%','8%','18%','16%','14%',48,'10%'],
                alignment : 'center',
                body: [
                  this.headerList,
                  ...this.registrationfilteredlist.map((p) => {
                    const temp = [];
                    for (const y of this.fieldList) {
                      if (y === 'dob') {
                        temp.push(this.formatDate(p[y]));
                      } else {
                        temp.push(p[y]);
                      }
                    }
                    return temp;
                  })
                ]
              }
            }
        ],
        images: {
          imageKey: environment.imageUrl + this.institution.logoUrl // via URL address, which can be referenced by this key
        },
        styles: {
          sectionHeader: {
            fontSize: 8,
            margin: [0, 10, 0, 10],
          },
          h1: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
          },
          h2: {
              margin: [0, 5, 0, 0],
              fontSize: 10,
              bold: true
          }
        }
      };

      pdfMake.createPdf(docDefinition).download('APPLICANT REGISTRATION REPORT.pdf');
    }

  search() {    
    var fromDate = $('#fromDate').val();
    var fromDate2 = $('#fromDate').val();
    var toDate2 = $('#toDate').val();
    var toDate22 = $('#toDate').val();
     
     if (fromDate  === undefined || fromDate  === "" ) {
      this.toastr.warning('Please select from date', 'WARN!', {
        timeOut: 3000
      });
      return;
    }
    else{
      var dd =$('#fromDate').val().toString().substring(0,2)
      var mm =$('#fromDate').val().toString().substring(3,5)
      var yy = $('#fromDate').val().toString().substring(6,10)
      fromDate = mm + '-'  + dd + '-' + yy;
      fromDate = new Date(fromDate) ; 
     
    }
    if (toDate2 === undefined ||  toDate2 === "") {
      this.toastr.warning('Please select to date', 'WARN!', {
        timeOut: 3000
      });
      return;
    }
    else{
      var dd =$('#toDate').val().toString().substring(0,2)
      var mm =$('#toDate').val().toString().substring(3,5)
      var yy = $('#toDate').val().toString().substring(6,10)
      toDate2 = mm + '-'  + dd + '-' + yy;
      toDate2 = new Date(toDate2) ; 
      toDate2.setDate(toDate2.getDate()+1); 
      toDate22 = mm + '-'  + dd + '-' + yy;
      toDate22 = new Date(toDate22) ; 
      toDate22.setDate(toDate22.getDate()+1); 
    }
    this.registrationfilteredlist = this.applicantRegistrations;
    var sortList = this.applicantRegistrations;
    if (this.classcourseid != '0'){
      sortList = sortList.filter((applicantRegistration: ApplicantRegistration) =>
      applicantRegistration.classCourseId.toString() === this.classcourseid.toString());
    }
    if (this.source != 'All Source'){
      sortList = sortList.filter((applicantRegistration: ApplicantRegistration) =>
      applicantRegistration.applicationSource.toString() === this.source.toString());
    }
    if (this.status != 'All Status'){
      sortList = sortList.filter((applicantRegistration: ApplicantRegistration) =>
      applicantRegistration.applicationStatus.toString() === this.status.toString());
    }
    sortList =
    sortList.filter((applicantRegistration: ApplicantRegistration) => {
      if (Math.floor(new Date(toDate22).getTime() / 86400000) === Math.floor(new Date(fromDate).getTime() / 86400000)) {
        return  Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000)  ===
         Math.floor(new Date(toDate22).getTime() / 86400000);
      } else {
        return  new Date(applicantRegistration.registrationDate).getTime() >= new Date(fromDate).getTime() &&
        new Date(applicantRegistration.registrationDate).getTime() <= new Date(toDate2).getTime();
      }
    });
    this.registrationfilteredlist = sortList;


    // if (this.classcourseid === '0' && this.source === 'All Source' && this.status === 'All Status' && fromDate === '') {
    //   this.registrationfilteredlist = this.applicantRegistrations;
    // } else if (this.classcourseid !== '0' && this.source === 'All Source' && this.status === 'All Status' && fromDate === '') {
    //   this.registrationfilteredlist =
    //     this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) =>
    //     applicantRegistration.classCourseId.toString() === this.classcourseid.toString());
    // } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status === 'All Status' && fromDate === '') {
    //   this.registrationfilteredlist =
    //     this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
    //       return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
    //       applicantRegistration.applicationSource.toString() === this.source.toString();
    //     });
    // } else if (this.classcourseid !== '0' && this.source === 'All Source' && this.status !== 'All Status' && fromDate === '') {
    //   this.registrationfilteredlist =
    //     this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
    //       return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
    //       applicantRegistration.applicationStatus.toString() === this.status.toString();
    //     });
    // } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status !== 'All Status' && fromDate === '') {
    //   this.registrationfilteredlist =
    //     this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
    //       return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
    //       applicantRegistration.applicationSource.toString() === this.source.toString() &&
    //       applicantRegistration.applicationStatus.toString() === this.status.toString();
    //     });
    // } else if (this.classcourseid === '0' && this.source !== 'All Source' && this.status !== 'All Status' && fromDate !== '') {
    //   this.registrationfilteredlist =
    //     this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
    //       if (Math.floor(new Date(toDate2).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //         return applicantRegistration.applicationSource.toString() === this.source.toString() &&
    //         applicantRegistration.applicationStatus.toString() === this.status.toString() &&
    //         Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000)  ===
    //          Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //       } else {
    //         return applicantRegistration.applicationSource.toString() === this.source.toString() &&
    //         applicantRegistration.applicationStatus.toString() === this.status.toString() &&
    //         new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
    //         new Date(applicantRegistration.registrationDate).getTime() <= new Date(toDate2).getTime();
    //       }
    //     });
    // } else if (this.classcourseid === '0' && this.source === 'All Source' && this.status !== 'All Status' && fromDate !== '') {
    //   this.registrationfilteredlist =
    //     this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
    //       if (Math.floor(new Date(toDate2).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //         return applicantRegistration.applicationStatus.toString() === this.status.toString() &&
    //         Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000)  ===
    //          Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //       } else {
    //         return applicantRegistration.applicationStatus.toString() === this.status.toString() &&
    //         new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
    //         new Date(applicantRegistration.registrationDate).getTime() <= new Date(toDate2).getTime();
    //       }
    //     });
    // } else if (this.classcourseid === '0' && this.source === 'All Source' && this.status === 'All Status' && fromDate !== '') {
    //  
    //   this.registrationfilteredlist =
    //     this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
    //       if (Math.floor(new Date(toDate2).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //         return Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000)  ===
    //          Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //       } else {
    //         return new Date(applicantRegistration.registrationDate) >= new Date(this.fromDate)&&
    //         new Date(applicantRegistration.registrationDate) <= new Date(toDate2);
    //       }
    //     });
    // } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status !== 'All Status' && fromDate !== '') {
    //   this.registrationfilteredlist =
    //     this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
    //       if (Math.floor(new Date(toDate2).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
    //         applicantRegistration.applicationSource.toString() === this.source.toString() &&
    //         applicantRegistration.applicationStatus.toString() === this.status.toString() &&
    //         Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000) ===
    //          Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //       } else {
    //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
    //         applicantRegistration.applicationSource.toString() === this.source.toString() &&
    //         applicantRegistration.applicationStatus.toString() === this.status.toString() &&
    //         new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
    //         new Date(applicantRegistration.registrationDate).getTime() <= new Date(toDate2).getTime();
    //       }
    //     });
    // } else if (this.classcourseid !== '0' && this.source === 'All Source' && this.status !== 'All Status' && fromDate !== '') {
    //   this.registrationfilteredlist =
    //     this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
    //       if ((new Date(toDate2)) === (new Date(this.fromDate))) {
    //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
    //         applicantRegistration.applicationStatus.toString() === this.status.toString() &&
    //        new Date(applicantRegistration.registrationDate)  ===
    //         new Date(this.fromDate);
    //       } else {
    //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
    //         applicantRegistration.applicationStatus.toString() === this.status.toString() &&
    //         new Date(applicantRegistration.registrationDate) >= new Date(this.fromDate) &&
    //         new Date(applicantRegistration.registrationDate) <= new Date(toDate2);
    //       }
    //     });
    // } else if (this.classcourseid !== '0' && this.source === 'All Source' && this.status === 'All Status' && fromDate !== '') {
    //   this.registrationfilteredlist =
    //     this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
    //       if (Math.floor(new Date(toDate2).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
    //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
    //         Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000)  ===
    //          Math.floor(new Date(this.fromDate).getTime() / 86400000);
    //       } else {
    //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
    //         new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
    //         new Date(applicantRegistration.registrationDate).getTime() <= new Date(toDate2).getTime();
    //       }
    //    }
    //    );
    //  }
  }

  ListApplicantRegistration() {
    this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
      console.log(data);
      this.applicantRegistrations = data;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  // onClassSelected(value: string) {
  //   console.log(value);
  //   if (value === '-1') { return; }
  //   this.registrationfilteredlist = this.applicantRegistrations;
  //   const fromDate = $('#fromDate').val();
  //   if (this.classcourseid === '0' && this.source === 'All Source' && this.status === 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist = this.applicantRegistrations;
  //   } else if (this.classcourseid !== '0' && this.source === 'All Source' && this.status === 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //       console.log(applicantRegistration.classCourseId.toString());
  //       return applicantRegistration.classCourseId.toString() === this.classcourseid.toString(); });
  //   } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status === 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //         applicantRegistration.applicationSource.toString() === this.source.toString();
  //       });
  //   } else if (this.classcourseid !== '0' && this.source === 'All Source' && this.status !== 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //         applicantRegistration.applicationStatus.toString() === this.status.toString();
  //       });
  //   } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status !== 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //         applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //         applicantRegistration.applicationStatus.toString() === this.status.toString();
  //       });
  //   } else if (this.classcourseid === '0' && this.source !== 'All Source' && this.status !== 'All Status' && fromDate !== '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
  //           return applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //           applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000) - 1 ===
  //            Math.floor(new Date(this.fromDate).getTime() / 86400000);
  //         } else {
  //           return applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //           applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
  //           new Date(applicantRegistration.registrationDate).getTime() <= new Date(this.toDate).getTime();
  //         }
  //       });
  //   } else if (this.classcourseid === '0' && this.source === 'All Source' && this.status !== 'All Status' && fromDate !== '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
  //           return applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000) - 1 ===
  //            Math.floor(new Date(this.fromDate).getTime() / 86400000);
  //         } else {
  //           return applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
  //           new Date(applicantRegistration.registrationDate).getTime() <= new Date(this.toDate).getTime();
  //         }
  //       });
  //   } else if (this.classcourseid === '0' && this.source === 'All Source' && this.status === 'All Status' && fromDate !== '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
  //           return Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000) - 1 ===
  //            Math.floor(new Date(this.fromDate).getTime() / 86400000);
  //         } else {
  //           return new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
  //           new Date(applicantRegistration.registrationDate).getTime() <= new Date(this.toDate).getTime();
  //         }
  //       });
  //   } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status !== 'All Status' && fromDate !== '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
  //           return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //           applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //           applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000) - 1 ===
  //            Math.floor(new Date(this.fromDate).getTime() / 86400000);
  //         } else {
  //           return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //           applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //           applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
  //           new Date(applicantRegistration.registrationDate).getTime() <= new Date(this.toDate).getTime();
  //         }
  //       });
  //   }

  // }

  resetTable() {
    this.registrationfilteredlist = [];
    this.classcourseid = '0';
    this.status = 'All Status';
    this.source = 'All Source';
    $('#fromDate').val('');
    $('#toDate').val('');
    this.registrationselectedlist = [];
  }

  // onStatusSelected(value: string) {
  //   console.log(value);
  //   if (value === 'Select Status') { return; }
  //   this.registrationfilteredlist = this.applicantRegistrations;
  //   const fromDate = $('#fromDate').val();
  //   if (this.classcourseid === '0' && this.source === 'All Source' && this.status === 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist = this.applicantRegistrations;
  //   } else if (this.classcourseid !== '0' && this.source === 'All Source' && this.status === 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) =>
  //       applicantRegistration.classCourseId.toString() === this.classcourseid.toString());
  //   } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status === 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //         applicantRegistration.applicationSource.toString() === this.source.toString();
  //       });
  //   } else if (this.classcourseid !== '0' && this.source === 'All Source' && this.status !== 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //         applicantRegistration.applicationStatus.toString() === this.status.toString();
  //       });
  //   } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status !== 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //         applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //         applicantRegistration.applicationStatus.toString() === this.status.toString();
  //       });
  //   } else if (this.classcourseid === '0' && this.source !== 'All Source' && this.status !== 'All Status' && fromDate !== '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
  //           return applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //           applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000) - 1 ===
  //            Math.floor(new Date(this.fromDate).getTime() / 86400000);
  //         } else {
  //           return applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //           applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
  //           new Date(applicantRegistration.registrationDate).getTime() <= new Date(this.toDate).getTime();
  //         }
  //       });
  //   } else if (this.classcourseid === '0' && this.source === 'All Source' && this.status !== 'All Status' && fromDate !== '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
  //           return applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000) - 1 ===
  //            Math.floor(new Date(this.fromDate).getTime() / 86400000);
  //         } else {
  //           return applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
  //           new Date(applicantRegistration.registrationDate).getTime() <= new Date(this.toDate).getTime();
  //         }
  //       });
  //   } else if (this.classcourseid === '0' && this.source === 'All Source' && this.status === 'All Status' && fromDate !== '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
  //           return Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000) - 1 ===
  //            Math.floor(new Date(this.fromDate).getTime() / 86400000);
  //         } else {
  //           return new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
  //           new Date(applicantRegistration.registrationDate).getTime() <= new Date(this.toDate).getTime();
  //         }
  //       });
  //   } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status !== 'All Status' && fromDate !== '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
  //           return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //           applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //           applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000) - 1 ===
  //            Math.floor(new Date(this.fromDate).getTime() / 86400000);
  //         } else {
  //           return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //           applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //           applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
  //           new Date(applicantRegistration.registrationDate).getTime() <= new Date(this.toDate).getTime();
  //         }
  //       });
  //   }
  // }

  // onSourceSelected(value: string) {
  //   console.log(value);
  //   if (value === 'Select Source') { return; }
  //   this.registrationfilteredlist = this.applicantRegistrations;
  //   const fromDate = $('#fromDate').val();
  //   if (this.classcourseid === '0' && this.source === 'All Source' && this.status === 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist = this.applicantRegistrations;
  //   } else if (this.classcourseid !== '0' && this.source === 'All Source' && this.status === 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) =>
  //       applicantRegistration.classCourseId.toString() === this.classcourseid.toString());
  //   } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status === 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //         applicantRegistration.applicationSource.toString() === this.source.toString();
  //       });
  //   } else if (this.classcourseid !== '0' && this.source === 'All Source' && this.status !== 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //         applicantRegistration.applicationStatus.toString() === this.status.toString();
  //       });
  //   } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status !== 'All Status' && fromDate === '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //         applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //         applicantRegistration.applicationStatus.toString() === this.status.toString();
  //       });
  //   } else if (this.classcourseid === '0' && this.source !== 'All Source' && this.status !== 'All Status' && fromDate !== '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
  //           return applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //           applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000) - 1 ===
  //            Math.floor(new Date(this.fromDate).getTime() / 86400000);
  //         } else {
  //           return applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //           applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
  //           new Date(applicantRegistration.registrationDate).getTime() <= new Date(this.toDate).getTime();
  //         }
  //       });
  //   } else if (this.classcourseid === '0' && this.source === 'All Source' && this.status !== 'All Status' && fromDate !== '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
  //           return applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000) - 1 ===
  //            Math.floor(new Date(this.fromDate).getTime() / 86400000);
  //         } else {
  //           return applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
  //           new Date(applicantRegistration.registrationDate).getTime() <= new Date(this.toDate).getTime();
  //         }
  //       });
  //   } else if (this.classcourseid === '0' && this.source === 'All Source' && this.status === 'All Status' && fromDate !== '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
  //           return Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000) - 1 ===
  //            Math.floor(new Date(this.fromDate).getTime() / 86400000);
  //         } else {
  //           return new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
  //           new Date(applicantRegistration.registrationDate).getTime() <= new Date(this.toDate).getTime();
  //         }
  //       });
  //   } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status !== 'All Status' && fromDate !== '') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         if (Math.floor(new Date(this.toDate).getTime() / 86400000) === Math.floor(new Date(this.fromDate).getTime() / 86400000)) {
  //           return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //           applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //           applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           Math.floor(new Date(applicantRegistration.registrationDate).getTime() / 86400000) - 1 ===
  //            Math.floor(new Date(this.fromDate).getTime() / 86400000);
  //         } else {
  //           return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //           applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //           applicantRegistration.applicationStatus.toString() === this.status.toString() &&
  //           new Date(applicantRegistration.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
  //           new Date(applicantRegistration.registrationDate).getTime() <= new Date(this.toDate).getTime();
  //         }
  //       });
  //   }

  // }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (this.registrationselectedlist.length === 0) {
    if ( keyword === '') {
      this.registrationfilteredlist = this.applicantRegistrations;
    } else {
      this.registrationfilteredlist = this.applicantRegistrations
        .filter((applicantRegistration: ApplicantRegistration) => {
          return applicantRegistration.studentName.toLowerCase()
            .includes(keyword.toLowerCase()) ||
            applicantRegistration.contactNumber.toString().toLowerCase().includes(keyword.toLowerCase()) ||
            applicantRegistration.registerNo.toString().toLowerCase().includes(keyword.toLowerCase()) ||
            applicantRegistration.formNo.toString().toLowerCase().includes(keyword.toLowerCase()) ||
            applicantRegistration.fatherFullName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase());
        }
        );
    }
  } else {
    if ( keyword === '') {
      this.registrationfilteredlist = this.registrationselectedlist;
    } else {
      this.registrationfilteredlist = this.registrationselectedlist
        .filter((applicantRegistration: ApplicantRegistration) => {
          return applicantRegistration.studentName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase()) ||
            applicantRegistration.contactNumber.toString().toLowerCase().includes(keyword.toLowerCase()) ||
            applicantRegistration.registerNo.toString().toLowerCase().includes(keyword.toLowerCase()) ||
            applicantRegistration.formNo.toString().toLowerCase().includes(keyword.toLowerCase()) ||
            applicantRegistration.fatherFullName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase());
        }
        );
    }
  }
    // if (this.registrationfilteredlist.length === 0) {
    //   this.toastr.warning('No Records Found', 'WARNING!!', {
    //     timeOut: 3000
    //   });
    // }
  }

  get totalRows(): number {
    return this.registrationfilteredlist.length;
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  ngOnInit(): void {
    this.generateAttributes();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
    this.ListApplicantRegistration();
    this.ListClassCourse();

    $('#fromDate').datepicker().on('changeDate', (e) => {
       this.fromDate =  new Date(e.date);
    });

    $('#toDate').datepicker().on('changeDate', (e) => {
      this.toDate = new Date(e.date);
   });

  }

}
