import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EvaluationType } from '../../offline-examination/ExamSetting/models/evaluation-type.model';
import{EvalutiontypeService} from './evalutiontype.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-evalutiontype',
  templateUrl: './evalutiontype.component.html',
  styleUrls: ['./evalutiontype.component.css']
})
export class EvalutiontypeComponent implements OnInit {
  [x: string]: any;
  evaluationType: EvaluationType[] = [];
  evaluation: EvaluationType[]=[];
  evaluationSubmitted: boolean;
  evaluationForm:UntypedFormGroup;

  constructor( private EvalutiontypeService:EvalutiontypeService,
    private toastr:ToastrService,
    private fb:UntypedFormBuilder) { }
  

  ngOnInit(): void {
    this.getAllEvalutionTypes();

    this.evaluationForm=this.fb.group({
      id:[0],
      evaluationType:['',Validators.required],
      evaluationStatus:[true,Validators.required]
    });
  }

  getAllEvalutionTypes(){
    this.EvalutiontypeService.getAllEvalutaionTypes().subscribe((data:EvaluationType[])=>{
     
      this.evaluationType=data;
      console.log(data);
    });
}
  submitEvaluationForm(){
    this.evaluationSubmitted=true;
    if(this.evaluationForm.invalid){
      this.toastr.warning('All field are required','WARNING!!!',{timeOut:3000});
      return;
    }

    if(this.evaluationForm.get('id').value===0)
    {
        this.EvalutiontypeService.createEvaluationType(this.evaluationForm.value).subscribe(res=>{                
        this.toastr.success("Evaluation Type successfully created","SUCCESS!",{timeOut:3000});
        this.getAllEvalutionTypes(); 
    });    
   }
   else{
    this.EvalutiontypeService.updateEvaluationType(this.evaluationForm.get('id').value,this.evaluationForm.value).subscribe(res=>{           
      this.toastr.success("Evaluation Type successfully updated","SUCCESS!",{timeOut:3000});
      //this.getAllEvalutionTypes();
      this.getAllEvalutionTypes(); 
    }); 
   }
   this.evaluationSubmitted=false;
   this.resetEvaluationForm();        
   
  }
  editEvaluationType(id:number){
    this.EvalutiontypeService.getEvaluationTypeById(id).subscribe((data:EvaluationType)=>{
      console.log(data);
      this.evaluationForm=this.fb.group({
        id:[data.id],
        evaluationType:[data.evaluationType, Validators.required],       
        evaluationStatus:[data.evaluationStatus,Validators.required]
      })    
    })
  }
  deleteEvaluationType(id:number){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton:true,
      confirmButtonText:'Yes, delete it!',
      cancelButtonText:'No, keep it'
    }).then((result)=>{
      if(result.value){
        this.EvalutiontypeService.deleteEvaluationType(id).subscribe(data=>{
          Swal.fire(
            'Deleted!',
            'Evaluation Type has been deleted.',
            'success'
          );
          this.getAllEvalutionTypes();
        })
      }
    })
  }
  resetEvaluationForm(){
    this.evaluationSubmitted=false;    
    this.evaluationForm=this.fb.group({
      id:[0],
      evaluationType:['',Validators.required],
      evaluationStatus:[true,Validators.required]
    });
  }
}
