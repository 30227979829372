import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StudentService } from './../student.service';
import { ClassCourseService } from './../../mastersettings/classcourse/classcourse.service';
import { Student, StudentDetails, BulkImage } from './../student.model';
import { ClassCourse } from './../../mastersettings/models/classcourse';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { environment } from '../../../../../environments/environment';


declare var $: any;

@Component({
  selector: 'app-bulkimageupload',
  templateUrl: './bulkimageupload.component.html',
  styleUrls: ['./bulkimageupload.component.css'],
})
export class BulkimageuploadComponent implements OnInit  {
 
  admissions: StudentDetails[] = [];
  admissionsfilteredlist: StudentDetails[] = [];
  checkedAdmissionList: StudentDetails[] = [];
  checkedResultList: BulkImage[] = [];
  bulkimage: BulkImage = new BulkImage();
  classcourses: ClassCourse[] = [];
  classcourseid = '-1';
  batchid = '-1';
  batches: Batch[] = [];
  imageSrc: string;
  imagebaseUrl = environment.imageUrl;
  isLoading:boolean=false;

  constructor(
    private studentService: StudentService,
    private classcourseService: ClassCourseService,
    private batchService: BatchService,
    private toastr: ToastrService
  ) { }
  $priority?: number;



  ListStudents() {
    this.isLoading =!this.isLoading;
    this.studentService.getAll().subscribe((data: StudentDetails[]) => {
      console.log(data);
      data.forEach((x) => {
        x.imageUrlSrc = environment.imageUrl + x.imageUrl;
       });
      this.admissions = data;
      this.isLoading =!this.isLoading;
    });
  }

  ListClassCourse() {
    this.isLoading =!this.isLoading;
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
      this.isLoading =!this.isLoading;
    });
  }

  onClassSelected(value: string) {
    console.log(value);
    this.admissionsfilteredlist = [];
    if (value === '-1') { return; }
    if (value === '0') {
        this.admissionsfilteredlist = this.admissions; // = data;
    } else {
        this.batchService.getAll().subscribe((data: Batch[]) => {
          this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
        });
        // this.admissionsfilteredlist = this.admissions.filter((admission: Student) =>
        // admission.classCourseId.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
    }
  }

  onBatchSelected(value: string) {
    console.log(value);
    if (value === '-1') { return; }
    if (value === '0') {
        this.admissionsfilteredlist = this.admissions; // = data;
    } else {
        this.admissionsfilteredlist = this.admissions.filter((admission: Student) =>
        admission.batchId.toString() === value.toString());
    }
    if (this.admissionsfilteredlist.length === 0 ) {
      this.toastr.warning('No Records Found', 'WARNING!', {
        timeOut: 3000
      });
    }
  }

  readURL(event: Event, i: any): void {
   
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const file = target.files[0];

      const reader = new FileReader();
      reader.onload = (e => {
        const student = this.admissionsfilteredlist[i];
       
        this.admissionsfilteredlist[i].imageUrl='';
        this.admissionsfilteredlist[i].studentImage=reader.result.toString();
        student.studentImage = reader.result.toString();
        student.imageUrl='';
      });

      reader.readAsDataURL(file);
    }
  }

  get totalRows(): number {
    return this.admissionsfilteredlist.length;
  }

  checkAllCheckBox(ev) {
    this.admissionsfilteredlist.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    return this.admissionsfilteredlist.every(p => p.checked);
  }

  resetForm() {
    this.admissionsfilteredlist = [];
    this.batchid = '-1';
    this.batches = [];
    this.classcourseid = '-1';
  }

  submitForm() {
    debugger;
    this.isLoading=!this.isLoading;
    var studentList:BulkImage[] = [];
   
    this.admissionsfilteredlist.forEach(x => {
        var bulk:BulkImage = new BulkImage();
        bulk.id = x.id;
        bulk.admissionNo = x.admissionNo;
        bulk.admissionNoCounter = x.admissionNoCounter.toString();
        bulk.studentImage = x.studentImage;
        bulk.ImageUrl = x.imageUrl;
        studentList.push(bulk);
    });
   
    if (this.admissionsfilteredlist.length === 0 ) {
      this.toastr.warning('Please Select Student', 'WARNING!', {
        timeOut: 3000
      });
      return;
     
    }
    //console.log(studentList);

    this.studentService.savebulkimage(studentList).subscribe(res => {
        this.ListStudents();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
           });
           this.isLoading=!this.isLoading;
    });
    
  }

  ngOnInit(): void {
    this.ListClassCourse();
    this.ListStudents();
  }

}

