import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { Institution } from '../superadmin/institution.model';
import {SuperAdminService } from '../superadmin/superadmin.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  instCode = '';
  institution: any; // Institution = {} as Institution;
  constructor(
    private sharedService: SharedService,
    private adminService: SuperAdminService
  ) { }

  ListInstitutions() {
    // this.adminService.getByCode(this.instCode).subscribe((data: Institution) => {
    //   this.institution = data;
    //   console.log(data);
    // });
    const data = JSON.parse(sessionStorage.getItem('Institution'));
    if (data.branchCount.toString().length === 1) {
      data.branchCount = '0' + data.branchCount;
    }
    this.institution = data;
  }

  ngOnInit(): void {
    this.ListInstitutions();
  }

}
