import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AppBroadcastService } from './appbroadcast.service';
import { ToastrService } from 'ngx-toastr';
import { Template } from '../configuration/templatemanager/template.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Student } from '../../academic/studentmanagement/student.model';
import { AppBroadcast, AppBroadcastUserDetail } from './appbroadcast.model';
import { Employeeadmission } from '../../OperationActivity/hrmspayroll/hrms/models/employeeadmission.model';
import { Department } from '../../OperationActivity/hrmspayroll/master/models/departement.model';
import { Batch } from '../../academic/mastersettings/models/batch';

declare var $: any;
@Component({
  selector: 'app-appbroadcast',
  templateUrl: './appbroadcast.component.html',
  styleUrls: ['./appbroadcast.component.css']
})
export class AppbroadcastComponent implements OnInit {

  templates: Template[] = [];
  batchList: Batch[] = [];
  batchListPrimary: Batch[] = [];
  departmentList: Department[] = [];
  departmentListPrimary: Department[] = [];
  filteredlist: Student[] = [];
  studentfilteredlist: Student[] = [];
  students: Student[] = [];
  selecetedStudentCnt = 0;
  selecetedEmployeeCnt = 0;
  employees: Employeeadmission[];
  employeefilteredlist: Employeeadmission[] = [];
  filteredemployeelist: Employeeadmission[] = [];
  isStudentDetails = true;
  appBroadcastForm: UntypedFormGroup;
  appBroadcastuserDetails: AppBroadcastUserDetail[] = [];
  totalStudentCount = 0;
  totalEmployeeCount = 0;
  totalUserCount = 0;
  broadcastMessage = '';
  submitted = false;
  loader:any=false;

  constructor(
    private appBroadcastService: AppBroadcastService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder
  ) { }

  getTemplates() {
    this.appBroadcastService.getAllTemplates().subscribe((data: Template[]) => {
      this.templates = data;
    });
  }

  getAllBatchDetails() {
    this.appBroadcastService.getAllBatchDetails().subscribe((data: Batch[]) => {
      this.batchList = this.batchListPrimary = data; // this.findCurrentStudentCount(data);
      this.batchList.forEach((x, i) => {
        const totalStudents = this.students.filter((admission: Student) =>
          admission.batchId.toString() === x.id.toString()).length;
          // admission.academicYearId.toString() === this.academicYear.id.toString()).length;
        x.currentStudentsCount = totalStudents;
    });
    });
  }

  getAllDepartmentDetails() {
    this.appBroadcastService.getAllDepartments().subscribe((data: Department[]) => {
      this.departmentList = this.departmentListPrimary = data; // this.findCurrentStudentCount(data);
      this.departmentList.forEach((x, i) => {
        const totalEmployees = this.employees.filter((admission: Employeeadmission) =>
          admission.departmentId.toString() === x.id.toString()).length;
          // admission.academicYearId.toString() === this.academicYear.id.toString()).length;
        x.totalEmployees = totalEmployees;
    });
    });
  }

  listAllStudents() {
    this.appBroadcastService.getAllStudents().subscribe((data: Student[]) => {
      this.students = data;
      this.getAllBatchDetails();
    });
  }

  listAllEmployees() {
    this.appBroadcastService.getAllEmployees().subscribe((data: Employeeadmission[]) => {
      this.employees = data;
      this.getAllDepartmentDetails();
    });
  }

  findCurrentStudentCount(batchData: Batch[]) {
    let len: number;
    len = batchData.length;
    if (len > 0) {
      for (let i = 0; i < len; i++) {
        batchData[i].currentStudentsCount = this.getStudentCount(batchData[i].id);
      }
    }
    return batchData;

  }
  getStudentCount(batchId: any): number {
    return this.students.filter(element => element.batchId === batchId).length;
  }

  onStudentRadioChanged(event: any) {
    if (event) {
      this.isStudentDetails = true;
    }
  }

  onEmployeeRadioChanged(event: any) {
    if (event) {
      this.isStudentDetails = false;
    }
  }


  selecetAllBatch(evnt: any) {
    if (evnt.target.checked) {
      this.studentfilteredlist = this.students;
      this.filteredlist = this.studentfilteredlist;
      this.batchList.forEach(x => x.checked = true);

    } else {
      this.batchList.forEach(x => x.checked = false);
      this.students.forEach(x => x.checked = false);
      this.studentfilteredlist = [];
      this.filteredlist = this.studentfilteredlist;
      this.selectedStudentCount();
    }

  }

  onCheckBatch(evnt: any, batchId: any) {
    if (evnt.target.checked) { // on checked
      this.filterStudentList(batchId);
    } else {// on unchecked
      this.removeFromfilteredStudentList(batchId);
    }

  }

  removeFromfilteredStudentList(batchId: any) {
    this.studentfilteredlist = this.studentfilteredlist.filter(element => element.batchId !== batchId);
    this.filteredlist = this.studentfilteredlist;

  }
  filterStudentList(batchId: any) {
    this.studentfilteredlist = [...this.studentfilteredlist, ...this.students.filter(element => element.batchId === batchId)];
    this.studentfilteredlist = this.studentfilteredlist.filter((value, index) => this.studentfilteredlist.indexOf(value) === index)
      .filter((el) => {
        return el != null;
      });
    this.filteredlist = this.studentfilteredlist;
  }

  isAllCheckBoxChecked() {
    return this.batchList.every(p => p.checked);
  }
  selecetAllStudent(evnt: any) {
    this.studentfilteredlist.forEach(x => x.checked = evnt);
    this.filteredlist = this.studentfilteredlist;
    this.selectedStudentCount();
  }

  isAllStudentCheckBoxChecked() {
    if (this.studentfilteredlist.length === 0) {
      return false;
    }

    return this.studentfilteredlist.every(p => p.checked);
  }
  onCheckStudent(evnt: any, studentId: any) {
    this.selectedStudentCount();

  }
  selectedStudentCount() {
    this.selecetedStudentCnt = this.studentfilteredlist.filter(element => element.checked === true).length;
  }
  onChangeStudentStatusFilter(status: any) {
    let stat = false;
    if (status === '') {
      this.studentfilteredlist = this.filteredlist;
      return;
    }
    if (status === 'true') { stat = true; }
    this.studentfilteredlist = this.filteredlist.filter(element => Boolean(element.status) === stat);
  }
  onSearch(evnt: any) {
    if (this.isStudentDetails) {
      this.filter(evnt.target.value);
    } else {
      this.employeefilter(evnt.target.value);
    }
  }

  filter(keyword: any) {
    if (keyword === '') {
      this.studentfilteredlist = this.filteredlist;
    } else {
      this.studentfilteredlist = this.filteredlist
        .filter((stud: Student) => stud.studentName.toLocaleLowerCase() // filter column need to be changed - audience is used for testing
          .includes(keyword.toLocaleLowerCase())
        );
    }
  }

  employeefilter(keyword: any) {
    if (keyword === '') {
      this.employeefilteredlist = this.filteredemployeelist;
    } else {
      this.employeefilteredlist = this.filteredemployeelist
        .filter((employee: Employeeadmission) =>
        employee.employeeName.toLocaleLowerCase() // filter column need to be changed - audience is used for testing
        .includes(keyword.toLocaleLowerCase())
        );
    }
  }

  importContactNumbers() {
    const contactNumners: string[] = [];
    const contactEmployeeNumnbrs: string[] = [];
    let studentlen: number;
    let employeelen: number;
    let selectedStudents: Student[] = [];
    selectedStudents = this.studentfilteredlist.filter(element => element.checked === true);
    // console.log(selectedStudents);
    let selectedEmployees: Employeeadmission[] = [];
    selectedEmployees = this.employeefilteredlist.filter(element => element.checked === true);

    this.totalStudentCount = studentlen = selectedStudents.length;
    this.totalEmployeeCount = employeelen = selectedEmployees.length;
    if (studentlen === 0 && employeelen === 0) {
      this.toastr.error('Plese select atleast one Record!', 'WARN!', {
        timeOut: 3000
      });
    } else {
      this.totalUserCount = this.totalStudentCount + this.totalEmployeeCount;
      for (let i = 0; i < studentlen; i++) {
        // contactNumners.push(selectedStudents[i].localContactNo);
        const appBroadcastUserDetail: AppBroadcastUserDetail = {} as AppBroadcastUserDetail;
        appBroadcastUserDetail.studentId = selectedStudents[i].id;
        appBroadcastUserDetail.employeeId = -1;
        appBroadcastUserDetail.userType = 'Student';
        this.appBroadcastuserDetails.push(appBroadcastUserDetail);
      }
      // this.studentNumbers += ',';
      // this.studentNumbers += contactNumners.join(',');
      for (let i = 0; i < employeelen; i++) {
        const appBroadcastUserDetail: AppBroadcastUserDetail = {} as AppBroadcastUserDetail;
        appBroadcastUserDetail.studentId = -1;
        appBroadcastUserDetail.employeeId = selectedEmployees[i].id;
        appBroadcastUserDetail.userType = 'Employee';
        this.appBroadcastuserDetails.push(appBroadcastUserDetail);
      }
      $('#addContacts').modal('hide');
    }
  }

  resetStudentPopup() {
    if (this.isStudentDetails) {
      this.studentfilteredlist.forEach((x) => x.checked = false);
      this.studentfilteredlist = [];
      this.batchList.forEach((x) => x.checked = false);
      this.batchList = this.batchListPrimary;
      this.selecetedStudentCnt = 0;
    } else {
      this.employeefilteredlist.forEach((x) => x.checked = false);
      this.employeefilteredlist = [];
      this.departmentList.forEach((x) => x.checked = false);
      this.departmentList = this.departmentListPrimary;
      this.selecetedStudentCnt = 0;
    }
  }

  countChar(event, value) {
    console.log(value);
  }

  onTemplateChanged(id: any) {
    const temp: Template[] = [];
    const bod = '';
    this.appBroadcastService.getAllTemplates().subscribe((data: Template[]) => {
      this.broadcastMessage = data.find((template: Template) => template.id.toString() === id.toString()).templateBody;
      $('.summernote').summernote('code', this.broadcastMessage);
    });

  }

  // onKeyUpChange(evnt: any) {
  //   const t = evnt.currentTarget.innerText;
  //   if (evnt.keyCode !== 13 && evnt.keyCode !== 18 && evnt.keyCode !== 27) {
  //     this.incidentDescription = t.length;
  //     this.messageCount = parseInt((parseInt(t.length, 0) / 160).toString(), 0) + 1;
  //   }
  // }

  onCheckDepartment(evnt: any, departmentId: any) {
    if (evnt.target.checked) { // on checked
      this.filterEmployeeList(departmentId);
    } else {// on unchecked
      this.removeFromfilteredEmployeeList(departmentId);
    }

  }

  removeFromfilteredEmployeeList(departmentId: any) {
    this.employeefilteredlist = this.employeefilteredlist.filter(element => element.departmentId !== departmentId);
    this.filteredemployeelist = this.employeefilteredlist;

  }
  filterEmployeeList(departmentId: any) {
    this.employeefilteredlist = [...this.employeefilteredlist, ...this.employees.filter(element => element.departmentId === departmentId)];
    this.employeefilteredlist = this.employeefilteredlist.filter((value, index) => this.employeefilteredlist.indexOf(value) === index)
      .filter((el) => {
        return el != null;
      });
    this.filteredemployeelist = this.employeefilteredlist;
  }

  isAllEmployeeCheckBoxChecked() {
    if (this.employeefilteredlist.length === 0) {
      return false;
    }
    return this.employeefilteredlist.every(p => p.checked);
  }

  selecetAllEmployee(evnt: any) {
    this.employeefilteredlist.forEach(x => x.checked = evnt);
    this.filteredemployeelist = this.employeefilteredlist;
    this.selectedEmployeeCount();
  }

  onCheckEmployee(evnt: any, studentId: any) {
    this.selectedEmployeeCount();

  }


  selectedEmployeeCount() {
    this.selecetedStudentCnt = this.employeefilteredlist.filter(element => element.checked === true).length;
  }

  selecetAllDepartment(evnt: any) {
    if (evnt.target.checked) {
      this.employeefilteredlist = this.employees;
      this.filteredemployeelist = this.employeefilteredlist;
      this.departmentList.forEach(x => x.checked = true);

    } else {
      this.departmentList.forEach(x => x.checked = false);
      this.employees.forEach(x => x.checked = false);
      this.employeefilteredlist = [];
      this.filteredemployeelist = this.employeefilteredlist;
      this.selectedEmployeeCount();
    }

  }

  isAllCheckBoxDepartmentChecked() {
    return this.departmentList.every(p => p.checked);
  }
  get f() { return this.appBroadcastForm.controls; }
  resetForm() {
    this.appBroadcastForm = this.fb.group({
      id: [0],
      broadcastTitle: ['', Validators.required],
      broadcastDate: ['', Validators.required],
      broadcastTime: ['', Validators.required],
      messageBody: ['', Validators.required],
      messagetemplateId: ['0'],
      isAnnouncement: [false, Validators.required],
      isFlash: [false, Validators.required]
    });
    $('.summernote').summernote('code', '');
    this.totalUserCount = 0;
    this.totalStudentCount = 0;
    this.totalEmployeeCount = 0;
    this.appBroadcastuserDetails = [];
  }

  submitForm() {
    this.submitted = true;
    const mess = $('.summernote').summernote('code');
    this.appBroadcastForm.get('messageBody').patchValue(mess);
    console.log(this.appBroadcastForm.value);
    if (this.appBroadcastuserDetails.length === 0) {
      this.toastr.warning('Please select atleast one user', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }
    if (this.appBroadcastForm.invalid) {
      const controls = this.appBroadcastForm.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
              this.toastr.warning('Please enter ' + name, 'WARN!', {
                timeOut: 3000
              });
          }
      }
      return false;
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'After send broadcast unable to edit and delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, save it!',
      cancelButtonText: 'No, edit it'
    }).then((result) => {
      if (result.value) {
        this.loader=true;
        console.log(this.appBroadcastForm.value);
        const appbroadcast: AppBroadcast = {} as AppBroadcast;
        appbroadcast.broadcastTitle = this.appBroadcastForm.get('broadcastTitle').value;
        appbroadcast.broadcastDate = this.appBroadcastForm.get('broadcastDate').value;
        appbroadcast.broadcastTime = this.appBroadcastForm.get('broadcastTime').value;
        appbroadcast.messageBody = this.appBroadcastForm.get('messageBody').value;
        appbroadcast.messagetemplateId = this.appBroadcastForm.get('messagetemplateId').value;
        appbroadcast.isAnnouncement = this.appBroadcastForm.get('isAnnouncement').value;
        appbroadcast.isFlash = this.appBroadcastForm.get('isFlash').value;
        appbroadcast.userCount = this.totalUserCount.toString();
        appbroadcast.appBroadcastUserDetails = this.appBroadcastuserDetails;
        this.appBroadcastService.create(appbroadcast).subscribe(ress => {
          this.loader=false;
          this.toastr.success('Record has been successfully saved', 'SUCCESS!', {
              timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.submitted = false;
        Swal.fire(
          'Cancelled',
          'You can edit now!',
          'error'
        );
      }
    });
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  ngOnInit(): void {
    const self = this;
    $('.summernote').summernote({
      height: 300,
      visible: true,
      callbacks: {
        onKeyup: (e) => {
          // self.onKeyUpChange(e);
        },
        onChange: (contents, $editable) => {
        }
      }
    });

    this.appBroadcastForm = this.fb.group({
      id: [0],
      broadcastTitle: ['', Validators.required],
      broadcastDate: ['', Validators.required],
      broadcastTime: ['', Validators.required],
      messageBody: ['', Validators.required],
      messagetemplateId: ['0'],
      isAnnouncement: [false],
      isFlash: [false]
    });

    this.getTemplates();
    this.listAllStudents();
    this.listAllEmployees();
    $('#broadcastDate').datepicker().on('changeDate', (e) => {
      this.appBroadcastForm.get('broadcastDate').patchValue(this.formatDate(e.date));
    });
  }

}
