import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { certificateType, TemplateParameter, templateSetting } from './certificateType.models';
import { TemplateSettingsService } from './template-settings.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { validateDate } from 'src/app/shared/helpers/date-utility';

declare var $: any;

declare var $: any;
@Component({
  selector: 'app-templatesetting',
  templateUrl: './templatesetting.component.html',
  styleUrls: ['./templatesetting.component.css']
})
export class TemplatesettingComponent implements OnInit {

  certificateTypeForm:UntypedFormGroup;
  certificateTypeList:certificateType[];
  templateSettingList:templateSetting[];
  submitted = false;
  submittedTemplateSetting = false;
  textareaContent: string = '';
  divContent: SafeHtml = '';
  ctypes:certificateType[];
  selectedCType: string = '';
  selectedOption: string = '';

  templateParameters: TemplateParameter[] = [];
  templateParameterFilteredList: TemplateParameter[] = [];
  templateParameterFilteredListTemp: TemplateParameter[] = [];
  templateEmployeeParameters: TemplateParameter[] = [];
  templateEmployeeParameterFilteredList: TemplateParameter[] = [];
  showStudentParams = true;
  showEmployeeParams = false;
  parameters="";

  templateSettingForm: UntypedFormGroup;
  caretPos = 0;
  templateMessage = '';
  editMode = false;
  templateid: number;

  private editor: any;

  constructor(
    public templateSettingsService: TemplateSettingsService,
    public fb: UntypedFormBuilder,
    public fbTS: UntypedFormBuilder,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.templateid = this.activatedRoute.snapshot.params.id;
    this.onListType();
    this.getTeamplateParameters();
    if (this.templateid !== 0 && this.templateid !== undefined) {
      this.updateTemplateForm(this.templateid);
    }
    this.certificateTypeForm = this.fb.group({
      id: [0],
      certificateTypeName: ['', Validators.required],
      status: ['true', Validators.required]
    });

    const self = this;
    this.editor = $('.summernote').summernote({
      height: 300,
      visible: false,
      callbacks: {
        onKeydown: function (e) { 
          var t = e.currentTarget.innerText; 
        },
        onKeyup: (e) => {
          //self.onKeyUpChange(e);
        },
        onChange: (contents, $editable) => {
          this.divContent = this.sanitizer.bypassSecurityTrustHtml(contents);
        }
      }
    });

    this.templateSettingForm = this.fbTS.group({
      id: [0],
      templateName: ['', Validators.required],
      certificateTypeId: ['', Validators.required],
      issueFor: ['Student', Validators.required],
      templateText: ['']
    });

  }

  onListType(){
    this.templateSettingsService.getAll().subscribe((data:certificateType[])=>{
      this.certificateTypeList=data;
      this.ctypes = data.filter((o: any) => o.status === true);
    });
  }
  get f() { return this.certificateTypeForm.controls; }

  resetForm() {
    this.submitted = false;
    this.certificateTypeForm = this.fb.group({
      id: [0],
      certificateTypeName: ['', Validators.required],
      status: ['true', Validators.required]
    });
    $('.summernote').summernote('code', '');
  }

  submitForm(){
    this.submitted=true;
    if(this.certificateTypeForm.invalid){ return ;}
    if(this.certificateTypeForm.get('id').value===0){
      this.templateSettingsService.create(this.certificateTypeForm.value).subscribe(res => {
        console.log(' created!');
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        this.onListType();
      });
    }
    else {
      this.templateSettingsService.update(this.certificateTypeForm.get('id').value,this.certificateTypeForm.value).subscribe(res => {
        console.log(' updated!');
        this.onListType();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        this.onListType();
      });
    }
  }

  updateForm(id: number) {
    this.templateSettingsService.getById(id).subscribe((data: certificateType) => {
      this.certificateTypeForm = this.fb.group({
        id: [data.id],
        certificateTypeName: [data.certificateTypeName, Validators.required],
        status: [data.status, Validators.required]
      });
    });
  }

  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.templateSettingsService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.onListType();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }

  onKeyUpChange(evnt: any) {
    const t = evnt.currentTarget.innerHTML;
    this.textareaContent = t;
    this.divContent = this.sanitizer.bypassSecurityTrustHtml(t);
  }

  onSelectionChange(selectedValue: string){
    this.selectedOption = selectedValue;
    if(this.selectedOption=='Student') {
        this.showStudentParams = true;
        this.showEmployeeParams = false;
    } else {
      this.showEmployeeParams = true;
      this.showStudentParams = false;
    }
  }

  checkAllCheckBox(ev) {
    if(ev.target.checked===true){
    this.parameters=this.templateParameters.map(m=>m.dbMappedColumn).join(",");
    }else{
      this.parameters="";
    }
    this.templateParameters.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    this.templateParameters.every(p => p.checked);
  }

  submitTemplateForm(){
    this.submittedTemplateSetting=true;
    debugger;
    if(this.templateSettingForm.invalid){ return ;}
    if (this.templateSettingForm.get('id').value === 0) {
      const currentContent = $('.summernote').summernote('code');
      if(currentContent == "<p><br></p>"){
        this.templateSettingForm.value.templateText = '';
      } else {
        this.templateSettingForm.value.templateText = currentContent;
      }
      if(this.templateSettingForm.value.templateText == '') {
        this.toastr.warning('Please enter template content', 'WARN!', {
          timeOut: 3000
        });
        return;
      }
      this.templateSettingsService.createTemplateSetting(this.templateSettingForm.value).subscribe(res => {
        console.log(' created!');
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
      });
      this.submittedTemplateSetting = false;
      this.resetTemplateForm();
    } else {
      const currentContent = $('.summernote').summernote('code');
      if(currentContent == "<p><br></p>"){
        this.templateSettingForm.value.templateText = '';
      } else {
        this.templateSettingForm.value.templateText = currentContent;
      }
      if(this.templateSettingForm.value.templateText == '') {
        this.toastr.warning('Please enter template content', 'WARN!', {
          timeOut: 3000
        });
        return;
      }
      this.templateSettingsService.updateTemplate(this.templateSettingForm.get('id').value,this.templateSettingForm.value).subscribe(res => {
        console.log(' updated!');
        this.onListType();
        this.toastr.success('Records has been updated sucessfully', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submittedTemplateSetting = false;
      });
    }
  }
  get tf() { return this.templateSettingForm.controls; }

  updateTemplateForm(id: number) {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
    this.templateSettingsService.getByTemplateId(id).subscribe((data: templateSetting) => {
      this.templateSettingForm = this.fbTS.group({
        id: [data.id],
        templateName: [data.templateName, Validators.required],
        certificateTypeId: [data.certificateTypeId, Validators.required],
        issueFor: [data.issueFor, Validators.required],
        templateText: [data.templateText]
      });
      this.onSelectionChange(data.issueFor);
      this.editor.summernote('code', data.templateText);
    });
  }

  resetTemplateForm() {
    this.submittedTemplateSetting = false;
    this.templateParameters.forEach(x => x.checked = false);
    this.templateSettingForm = this.fbTS.group({
      id: [0],
      templateName: ['', Validators.required],
      certificateTypeId: ['', Validators.required],
      issueFor: ['', Validators.required],
      templateText: ['']
    });
    this.templateSettingForm.get('certificateTypeId').setValue('');
    this.templateSettingForm.get('issueFor').setValue('Student');
    this.onSelectionChange('Student');
    $('.summernote').summernote('code', '');
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart === '0') {
      this.caretPos = oField.selectionStart;
    }
  }

  getCurrentPosition(): any {
    const range = this.editor.summernote('getRange');
    if(range && range.collapsed) {
      return range.getStart();
    }
    return null;
  }

  setTextToCurrentPos(value: any, evnt: any) {
    if (evnt.target.checked) {
      const contentToAdd = '{{' + value + '}}';
      this.editor.summernote('saveRange');
      this.editor.summernote('restoreRange');
      this.editor.summernote('focus');
      this.editor.summernote('insertText', contentToAdd);
      //const currentCursonPosition = this.getCurrentPosition();
      //this.editor.summernote('pasteHTML', contentToAdd);
      //this.editor.summernote('pasteHTML', currentCursonPosition, contentToAdd);
      //const range = this.editor.summernote('createRange');
      //range.pasteHTML(contentToAdd);
      //const newNode = $(contentToAdd);
      //range.collapse(false);
      //range.insertNode(newNode[0]);
      //range.setStartAfter(newNode[0]);
      //range.setEndAfter(newNode[newNode.length - 1]);
      //$('.summernote').summernote('insertNode',newNode[0]);
      //$('.summernote').summernote('code', currentContent);
    } else {
      const currentContent = $('.summernote').summernote('code');
      const contentToReplace = '{{' + value + '}}';
      const modifiedContent = currentContent.replace(contentToReplace, '');
      this.editor.summernote('code', modifiedContent);
      this.editor.summernote('focus');
    }

  }

  get transformed3columnData(): any[] {
    const rows: any[] = [];
    const columns = 3;
    for(let i=0; i<this.templateParameterFilteredListTemp.length; i+=columns) {
      const row: any[] = [];
      for(let j=0;j<columns;j++) {
        if (i + j < this.templateParameterFilteredListTemp.length) {
          row.push(this.templateParameterFilteredListTemp[i+j]);
        } else {
          row.push('');
        }
      }
      rows.push(row);
    }
    return rows;
  }

  getTeamplateParameters() {
    this.templateParameterFilteredList = this.templateParameters =
      [{ id: 1, name: 'Admission No', checked: false,dbMappedColumn:'AdmissionNo' },
      { id: 1, name: 'Admission Date', checked: false,dbMappedColumn:'AdmissionDate' },
      { id: 2, name: 'Student Name', checked: false ,dbMappedColumn:'StudentName'},
      { id: 3, name: 'Academic Year', checked: false ,dbMappedColumn:'AcademicYearId'},
      { id: 4, name: 'Class', checked: false ,dbMappedColumn:'ClassCourseName'},
      { id: 5, name: 'Batch', checked: false ,dbMappedColumn:'BatchName'},
      { id: 6, name: 'Stream', checked: false,dbMappedColumn:'StreamName' },
      { id: 7, name: 'Date of Birth', checked: false,dbMappedColumn:'DOB' },
      { id: 8, name: 'Gender', checked: false ,dbMappedColumn:'Gender'},
      { id: 9, name: 'SMS Mob No', checked: false ,dbMappedColumn:'SMSMobNumber'},
      { id: 10, name: 'WhatsApp No', checked: false,dbMappedColumn:'WhatsAppNo' },
      { id: 11, name: 'Email ID', checked: false ,dbMappedColumn:'EmailId'},
      { id: 12, name: 'Aadhar No', checked: false,dbMappedColumn:'AdharNumber' },
      { id: 13, name: 'House Name', checked: false,dbMappedColumn:'HouseName' },
      { id: 14, name: 'Father\'s Name', checked: false,dbMappedColumn:'FatherName' },
      { id: 15, name: 'Father\'s Email Id', checked: false ,dbMappedColumn:'FatherEmailId'},
      { id: 16, name: 'Father\'s Mobile No', checked: false ,dbMappedColumn:'FatherMobileNo'},
      { id: 17, name: 'Mother\'s Name', checked: false ,dbMappedColumn:'MotherFullName'},
      { id: 18, name: 'Login Id', checked: false ,dbMappedColumn:'LoginId'},
      { id: 19, name: 'Password', checked: false,dbMappedColumn:'Password' },
      { id: 20, name: 'TC No', checked: false ,dbMappedColumn:'TCNo'},
      { id: 21, name: 'Current Date', checked: false,dbMappedColumn:'CurrentDate' },
      ];
    this.templateEmployeeParameterFilteredList = this.templateEmployeeParameters =
      [{ id: 1, name: 'Employee ID', checked: false,dbMappedColumn:'EmployeeID' },
      { id: 2, name: 'Date of Birth', checked: false ,dbMappedColumn:'DOB'},
      { id: 3, name: 'Employee Name', checked: false ,dbMappedColumn:'EmployeeName'},
      { id: 4, name: 'Gender', checked: false ,dbMappedColumn:'Gender'},
      { id: 5, name: 'Mobile No', checked: false,dbMappedColumn:'MobileNo' },
      { id: 6, name: 'Department', checked: false ,dbMappedColumn:'Department'},
      { id: 7, name: 'Designation', checked: false ,dbMappedColumn:'Designation'},
      { id: 8, name: 'Position', checked: false ,dbMappedColumn:'Position'},
      { id: 9, name: 'Employee Type', checked: false,dbMappedColumn:'EmployeeType' },
      { id: 10, name: 'Job Type', checked: false,dbMappedColumn:'JobType' },
      { id: 11, name: 'User Id', checked: false ,dbMappedColumn:'UserId'},
      { id: 12, name: 'Password', checked: false,dbMappedColumn:'Password' },
      { id: 13, name: 'ErpUser Id', checked: false,dbMappedColumn:'ErpUserId' },
      { id: 14, name: 'Erp Password', checked: false ,dbMappedColumn:'ErpPassword'},
      { id: 15, name: 'Joining Date', checked: false ,dbMappedColumn:'JoiningDate'}
      ];
    this.templateParameterFilteredListTemp = this.templateParameterFilteredList;
  }
}