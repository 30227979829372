import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApplicantRegistrationService } from '../applicantregistration/applicantregistration.service';
import { ClassCourseService } from '../mastersettings/classcourse/classcourse.service';
import { ClassCourse } from '../mastersettings/models/classcourse';
import { environment } from '../../../../environments/environment';

import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-registrationreciept',
  templateUrl: './registrationreciept.component.html',
  styleUrls: ['./registrationreciept.component.css']
})
export class RegistrationrecieptComponent implements OnInit {

  id: number;
  ApplicantName: string;
  Date: string;
  Class: string;
  FatherName: string;
  MobileNo: string;
  Amount: string;
  RegistrationNumber: string;
  ClassName: string;
  classcourses: ClassCourse[];
  institueProfileInfo: any;
  applicationNo: any;
  instituteImage: any;
  registrationDt: any;
  imageUrl: string;
  constructor(
    private applicantRegistrationService: ApplicantRegistrationService,
    private classcourseService: ClassCourseService,
    private route: ActivatedRoute,
    private router: Router,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.institueProfileInfo = JSON.parse(localStorage.getItem('Institution'));
    console.log(this.institueProfileInfo);
    console.log(this.route.snapshot.params.id);
    this.imageUrl = environment.imageUrl + this.institueProfileInfo.logoUrl;
    this.id = this.route.snapshot.params.id;

    forkJoin({
      classList: this.classcourseService.getAll(),
      applicantData: this.applicantRegistrationService.getById(this.id)
    }).subscribe(({classList, applicantData }) => {
      console.log(classList);
      console.log(applicantData);

      this.classcourses = classList;
      this.FatherName = applicantData.fatherFullName;
      this.ApplicantName = applicantData.studentName;
      this.Class = this.classcourses.find(c => c.id === parseInt(applicantData.classCourseId, 0))?.classCourseName;
      this.Amount = applicantData.applicationFee;
      this.MobileNo = applicantData.contactNumber;
      this.RegistrationNumber = applicantData.registerNo;
      this.applicationNo = applicantData.formNo;
      this.registrationDt = applicantData.registrationDate;
      console.log(this.applicationNo);
      this.instituteImage = this._sanitizer.bypassSecurityTrustResourceUrl(this.institueProfileInfo.institutionLogo);
    });


    // this.ListClass();
    

  //   this.applicantRegistrationService.getById(this.id).subscribe((data: ApplicantRegistration) => {
  //     console.log(data);
  //     this.FatherName = data.fatherFullName;
  //     this.ApplicantName = data.studentName;
  //     this.Class = this.classcourses.find(c => c.id == parseInt(data.classCourseId))?.classCourseName;
  //     this.Amount = data.applicationFee;
  //     this.MobileNo = data.contactNumber;
  //     this.RegistrationNumber = data.registerNo;
  //     this.applicationNo = data.formNo;
  //     console.log(this.applicationNo);
  //     this.instituteImage = this.institueProfileInfo.institutionLogo;
  //   });
  // }
  // ListClass() {
  //   this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
  //     this.classcourses = data;
  //   });
  }
  printpage() {
    // const data = document.getElementById('print');
    // html2canvas(data).then(canvas => {
    //   const contentDataURL = canvas.toDataURL('image/png');
    //   const pdf = new jspdf('l', 'cm', 'a4');
    //   pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
    //   pdf.Print();
    // });
    window.print();
  }

}
