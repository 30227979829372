import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StudentmanagementModule } from './studentmanagement.module';
import { AdmissionComponent } from './admission/admission.component';
import { AdmissionreportComponent } from './admissionreport/admissionreport.component';
import { StudentprofileComponent } from './studentprofile/studentprofile.component';
import { BulkimageuploadComponent } from './bulkimageupload/bulkimageupload.component';
import { BulkuploadstudentComponent } from './bulkuploadstudent/bulkuploadstudent.component';
import { ChangeclassComponent } from './changeclass/changeclass.component';
import { AsignadditionalsubjectComponent } from './asignadditionalsubject/asignadditionalsubject.component';
import { StudentbirthdayreportComponent } from './studentbirthdayreport/studentbirthdayreport.component';
import { StudentsummaryComponent } from './studentsummary/studentsummary.component';
import { ClasspromotionComponent } from './classpromotion/classpromotion.component';
import { UpdatestudentdetailsComponent } from './updatestudentdetails/updatestudentdetails.component';
import { InactivestudentpromotionComponent } from './inactivestudentpromotion/inactivestudentpromotion.component';
import { MakestudentinactiveComponent } from './makestudentinactive/makestudentinactive.component';
import { AdmissionformComponent } from './admissionform/admissionform.component';

const routes: Routes = [
  {path: 'student/admission', component: AdmissionComponent},
  {path: 'student/admission/:id', component: AdmissionComponent},
  {path: 'student/admission/edit/:admissionid/:academicId', component: AdmissionComponent},
  {path: 'student/admissionreport', component: AdmissionreportComponent},
  {path: 'student/bulkimageupload', component: BulkimageuploadComponent},
  {path: 'student/bulkupload', component: BulkuploadstudentComponent},
  {path: 'student/studentprofile/:id', component: StudentprofileComponent},
  {path: 'student/classpromotion', component: ClasspromotionComponent},
  {path: 'student/asignadditionalsubject', component: AsignadditionalsubjectComponent},
  {path: 'student/changeclass', component: ChangeclassComponent},
  {path: 'student/birthdaylist', component: StudentbirthdayreportComponent},
  {path: 'student/studentsummary', component: StudentsummaryComponent},
  {path: 'student/updatestudentdetails', component: UpdatestudentdetailsComponent},
  {path: 'student/inactivestudent', component: InactivestudentpromotionComponent},
  {path: 'student/makeinactive', component: MakestudentinactiveComponent},
  {path: 'student/admissionform', component: AdmissionformComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudentManagementRoutingModule { }
