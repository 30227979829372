import { Component, OnInit } from '@angular/core';
import {ApplicantRegistrationService} from '../applicantregistration/applicantregistration.service';
import { ApplicantRegistration } from '../applicantregistration/applicantregistration.model';
import {ApplicantRegistrationFollowup} from '../applicantregistrationfollowup/applicantregistrationfollowup.model';
import { ClassCourse } from '../mastersettings/models/classcourse';
import { ToastrService } from 'ngx-toastr';
import { ClassCourseService } from '../mastersettings/classcourse/classcourse.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-applicantregistrationfollowup',
  templateUrl: './applicantregistrationfollowup.component.html',
  styleUrls: ['./applicantregistrationfollowup.component.css']
})
export class ApplicantregistrationfollowupComponent implements OnInit {
  applicantRegistrations: ApplicantRegistration[] = [];
  registrationfilteredlist: ApplicantRegistration[] = [];
  registrationSearchList: ApplicantRegistration[] = [];
  application: ApplicantRegistration;
  followupList: ApplicantRegistrationFollowup[] = [];
  followupForm: UntypedFormGroup;
  classcourses: ClassCourse[] = [];
  classcourseid = '0';
  status = 'All Status';
  source = 'All Source';
  submitted = false;
  applicationid: number;
  registrationno: string;
  registrationdate: string;
  applicantname: string;
  contactnumber: string;
  fathername: string;
  classN: string;
  date: any;
  abc: Date;
  isLoading=false;
  constructor(
    private registrationService: ApplicantRegistrationService,
    private classcourseService: ClassCourseService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  // search() {
  //   this.registrationfilteredlist = this.applicantRegistrations;
  //   this.registrationfilteredlist = this.registrationfilteredlist.filter((item: ApplicantRegistration) => {
  //     return new Date(item.registrationDate).getTime() >= new Date(this.fromDate).getTime() &&
  //     new Date(item.registrationDate).getTime() <= new Date(this.toDate).getTime();
  //   });
  // }

  ListApplicantRegistration() {
    this.isLoading=true;
    this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
      console.log(data);
      ;
        const d = new Date();
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) {month = '0' + month; }
        if (day.length < 2) {day = '0' + day; }
        var DateData = [day, month, year].join('-');      
        for(var i = 0 ; i< data.length ;i++){
          data[i]["lastFollowupDate"] = DateData;
        }
      this.registrationfilteredlist = this.applicantRegistrations = data;
      this.isLoading=false;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }


  search(){
   ;
   this.isLoading=true;
   this.registrationfilteredlist = this.applicantRegistrations;
   var sortArray = [];
   sortArray =  this.applicantRegistrations;
   if (this.classcourseid != '0'){
    sortArray = sortArray.filter((applicantRegistration: ApplicantRegistration) =>
      applicantRegistration.classCourseId.toString() === this.classcourseid.toString());
   }
   if (this.source != 'All Source'){
    sortArray = sortArray.filter((applicantRegistration: ApplicantRegistration) =>
      applicantRegistration.applicationSource.toString() === this.source.toString());
   }
   if (this.status != 'All Status'){
    sortArray = sortArray.filter((applicantRegistration: ApplicantRegistration) =>
      applicantRegistration.applicationStatus.toString() === this.status.toString());
   }
  
    this.registrationfilteredlist = sortArray;
    this.isLoading=false;
  }
  // onClassSelected(value: string) {
  //   console.log(value);
  //   this.classcourseid = value;
  //   // this.registrationfilteredlist = this.applicantRegistrations;
  //   // if (this.classcourseid === '0' && this.source === 'All Source' && this.status === 'All Status') {
  //   //   this.registrationfilteredlist = this.applicantRegistrations;
  //   // } else if (this.classcourseid !== '0' && this.source === 'All Source' && this.status === 'All Status') {
  //   //   this.registrationfilteredlist =
  //   //     this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) =>
  //   //     applicantRegistration.classCourseId.toString() === this.classcourseid.toString());
  //   // } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status === 'All Status') {
  //   //   this.registrationfilteredlist =
  //   //     this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //   //       return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //   //       applicantRegistration.applicationSource.toString() === this.source.toString();
  //   //     });
  //   // } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status !== 'All Status') {
  //   //   this.registrationfilteredlist =
  //   //     this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //   //       return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //   //       applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //   //       applicantRegistration.applicationStatus.toString() === this.status.toString();
  //   //     });
  //   // }
  // }

  // onStatusSelected(value: string) {
  //   console.log(value);
  //   this.registrationfilteredlist = this.applicantRegistrations;
    
  //   if (this.classcourseid === '0' && this.source === 'All Source' && this.status === 'All Status') {
  //     this.registrationfilteredlist = this.applicantRegistrations;
  //   } else if (this.classcourseid !== '0' && this.source === 'All Source' && this.status === 'All Status') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) =>
  //       applicantRegistration.classCourseId.toString() === this.classcourseid.toString());
  //   } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status === 'All Status') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //         applicantRegistration.applicationSource.toString() === this.source.toString();
  //       });
  //   } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status !== 'All Status') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //         applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //         applicantRegistration.applicationStatus.toString() === this.status.toString();
  //       });
  //   }
  // }

  // onSourceSelected(value: string) {
  //   console.log(value);
  //   this.registrationfilteredlist = this.applicantRegistrations;
    
  //   if (this.classcourseid === '0' && this.source === 'All Source' && this.status === 'All Status') {
  //     this.registrationfilteredlist = this.applicantRegistrations;
  //   } else if (this.classcourseid !== '0' && this.source === 'All Source' && this.status === 'All Status') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) =>
  //       applicantRegistration.classCourseId.toString() === this.classcourseid.toString());
  //   } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status === 'All Status') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //         applicantRegistration.applicationSource.toString() === this.source.toString();
  //       });
  //   } else if (this.classcourseid !== '0' && this.source !== 'All Source' && this.status !== 'All Status') {
  //     this.registrationfilteredlist =
  //       this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) => {
  //         return applicantRegistration.classCourseId.toString() === this.classcourseid.toString() &&
  //         applicantRegistration.applicationSource.toString() === this.source.toString() &&
  //         applicantRegistration.applicationStatus.toString() === this.status.toString();
  //       });
  //   }
  // }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (this.registrationSearchList.length > 0) {
      if (keyword === '') {
        this.registrationfilteredlist = this.registrationSearchList;
      } else {
        this.registrationfilteredlist = this.registrationSearchList
          .filter((applicantRegistration: ApplicantRegistration) =>
            applicantRegistration.studentName.toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase()) ||
            applicantRegistration.fatherFullName.toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase()) ||
            applicantRegistration.contactNumber.toString()
              .includes(keyword.toString()) ||
            applicantRegistration.registerNo.toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          );
      }
    } else {
      if (keyword === '') {
        this.registrationfilteredlist = this.applicantRegistrations;
      } else {
        this.registrationfilteredlist = this.applicantRegistrations
          .filter((applicantRegistration: ApplicantRegistration) =>
            applicantRegistration.studentName.toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase()) ||
            applicantRegistration.fatherFullName.toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase()) ||
            applicantRegistration.contactNumber.toString()
              .includes(keyword.toString()) ||
            applicantRegistration.registerNo.toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          );
      }
    }
  }

  get totalRows(): number {
    return this.registrationfilteredlist.length;
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  setDate(date) {
    console.log(date);
    // this.Anotherdate = date;
    return this.formatDate(Date.parse(date)); // this.Anotherdate;
  }

  ListApplicantRegistrationFollowup(id: number) {
    this.registrationService.getAllFollowup(id).subscribe((data: ApplicantRegistrationFollowup[]) => {
      console.log(data);
      this.followupList = data;
    });
  }

  resetTable() {
    this.registrationfilteredlist = this.applicantRegistrations;
    this.classcourseid = '-1';
    this.status = 'Select Status';
    this.source = 'Select Source';

  }

  openFollowUp(id: number) {
    console.log(id);
    this.applicationid = id;
    this.fillForm(id);
    this.ListApplicantRegistrationFollowup(id);
  }
  get f() { return this.followupForm.controls; }
  resetForm() {
    this.submitted = false;
    this.followupForm = this.fb.group({
      id: [0],
      applicantRegistrationId: ['', Validators.required],
      nextFollowupDate: [''],
      applicationStatus: [''],
      remarks: ['', Validators.required]
    });
  }

  submitForm() {
    this.submitted = true;
    this.followupForm.get('applicantRegistrationId').patchValue(this.applicationid.toString());
    console.log(this.followupForm.get('applicationStatus').value);
    if (this.followupForm.get('applicationStatus').value !== 'Rejected'
    && this.followupForm.get('applicationStatus').value !== 'Selected') {
      this.followupForm.get('nextFollowupDate').setValidators(Validators.required);
      this.followupForm.get('nextFollowupDate').updateValueAndValidity();
    } else {
      this.followupForm.get('nextFollowupDate').clearValidators();
      this.followupForm.get('nextFollowupDate').updateValueAndValidity();
    }

    if (this.followupForm.invalid) {
      return;
    }
    if (this.followupForm.get('id').value === 0) {
      this.registrationService.createFollowup(this.followupForm.value).subscribe(res => {
        console.log('folowup created!');
        this.ListApplicantRegistrationFollowup(this.applicationid);
        this.ListApplicantRegistration();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    }
  }

  fillForm(id: number) {
    // this.followupForm.get('applicationStatus').patchValue('Select Status');
    this.application = this.applicantRegistrations
    .filter((applicantRegistration: ApplicantRegistration) =>
    applicantRegistration.id === id)[0];
    // this.registrationService.getById(id).subscribe((data: ApplicantRegistration) => {
    this.applicationid = this.application.id;
    this.registrationno = this.application.registerNo;
    this.registrationdate = this.formatDate(this.application.registrationDate);
    this.applicantname = this.application.studentName;
    this.contactnumber = this.application.contactNumber;
    this.fathername = this.application.fatherFullName;
    this.classN = this.application.className;
    // });
  }

  ngOnInit(): void {
    this.ListClassCourse();
    this.ListApplicantRegistration();
    $('#followupDate').datepicker().on('changeDate', (e) => {
      this.followupForm.get('nextFollowupDate').patchValue(this.formatDate(e.date));
    });
    this.followupForm = this.fb.group({
      id: [0],
      applicantRegistrationId: ['', Validators.required],
      nextFollowupDate: [''],
      applicationStatus: [''],
      remarks: ['', Validators.required]
    });
  }

}
