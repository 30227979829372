import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { LeaveApprovar } from './models/leaveapprovar';
import { LeaveRequest, LeaveRequestDetail } from './models/leaverequest';

@Injectable({
  providedIn: 'root'
})
export class LeaverequestService {


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<LeaveRequest[]> {
    return this.httpClient.get<LeaveRequest[]>(environment.apiUrl + '/leaverequests/')
    .pipe(
      catchError(this.errorHandler)
    );
  }

    getAllRequestForApproval(): Observable<LeaveRequest[]> {
    return this.httpClient.get<LeaveRequest[]>(environment.apiUrl + '/leaverequestsapproval/')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getRequestForApproval(status,from,to): Observable<LeaveRequest[]> {
    return this.httpClient.get<LeaveRequest[]>(environment.apiUrl + '/leaverequestsapproval/'
    +status+'/'+from+'/'+to+'')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  FilterLeaveRequest(Fromdate,Todate,EmpId,DepId,status): Observable<LeaveRequest[]> {
    ;
    return this.httpClient.get<LeaveRequest []>(environment.apiUrl + '/Filterleaverequests?Fromdate=' + Fromdate+'&Todate='+Todate +'&EmpId='+EmpId +'&DepId='+DepId +'&status='+status )
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getByEmpId(EmpId): Observable<LeaveRequest[]> {
    return this.httpClient.get<LeaveRequest[]>(environment.apiUrl + '/leaverequests/' + EmpId+"/"+0)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<LeaveRequest[]> {
    return this.httpClient.get<LeaveRequest[]>(environment.apiUrl + '/leaverequests/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getLeaveDetailById(id): Observable<LeaveRequestDetail[]> {
    return this.httpClient.get<LeaveRequestDetail[]>(environment.apiUrl + '/leaverequestsdetail/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  isCurrentAcademicYearExits(): Observable<LeaveRequest> {
    return this.httpClient.get<LeaveRequest>(environment.apiUrl + '/leaverequest/IsCurrentAcademicYearExits')
    .pipe(
      catchError(this.errorHandler)
    );
  }
  create(academicyear,leaveRequestDetail,leaveDays): Observable<LeaveRequest> {
    ;
    if(academicyear.status=='0')
    {
      academicyear.active=false;
      academicyear.status = false;
    }else{
      academicyear.status = true;
    }
    //academicyear.approvalStatus='P';
    academicyear.leaveDays=leaveDays;
    academicyear.leaveRequestDetail=leaveRequestDetail;
    return this.httpClient.post<LeaveRequest>(environment.apiUrl + '/leaverequests/', JSON.stringify(academicyear), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getEmployeeById(id): Observable<LeaveApprovar> {
    return this.httpClient.get<LeaveApprovar>(environment.apiUrl + '/leaveapprovar/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  
  update(id, academicyear,leaveRequestDetail,leaveDays): Observable<LeaveRequest> {
    // tslint:disable-next-line:max-line-length
    if(academicyear.status=='0')
    {
      academicyear.active=false;
    }
    //academicyear.approvalStatus='P';
    academicyear.leaveDays=leaveDays;
    academicyear.leaveRequestDetail=leaveRequestDetail;
    return this.httpClient.put<LeaveRequest>(environment.apiUrl + '/leaverequests/' + id, JSON.stringify(academicyear), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  deleteRequestDetails(id): Observable<LeaveRequest> {
    // tslint:disable-next-line:max-line-length
    
    //academicyear.approvalStatus='P';
    
    return this.httpClient.put<LeaveRequest>(environment.apiUrl + '/leaverequestsdelete/' + id, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id,academicyear) {
    return this.httpClient.put<LeaveRequest>(environment.apiUrl + '/leaverequests/' + id, JSON.stringify(academicyear), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}