import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { CoursePlannerService } from '../course-planner/course-planner.service';
import { ClassVM } from '../course-planner/model/ClassVM';
import { OnlineClassReportService } from '../online-class-report.service';
import { SessionPlannerVM } from '../session-planner/model/SessionPlannerVM';
import { CourseSummary, CourseVm } from './model/CourseVm';
import { BehaviorSubject, EMPTY, combineLatest, merge } from 'rxjs';
import { LoaderService } from 'src/app/shared/loader.service';
import { concatMap, debounceTime, delay, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';


@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoursesComponent implements OnInit {


  public lstSessionPlannerVM: SessionPlannerVM[] = new Array<SessionPlannerVM>();
  public lstCourseVm: CourseSummary[] = new Array<CourseSummary>();

  public lstClass: ClassVM[] = new Array<ClassVM>();
  isLoading: boolean;


  constructor(private onlineClassReportService: OnlineClassReportService, private coursePlannerService: CoursePlannerService,
    private loader: LoaderService,
    private formBuilder: UntypedFormBuilder) { }

  coursePlanForm: UntypedFormGroup;


  ngOnInit(): void {

    //this.getAllClass();

    this.loader$.next(true);
    this.coursePlanForm = this.formBuilder.group({

      ClassId: [0],
      ClassCourseName: []

    });


  }

/*   public getAllClass() {
    ;
    this.coursePlannerService.GetClass().subscribe((response) => {
      this.lstClass = response;
    });
  } */

  // public getClassId(){

  //   var ClassID = this.coursePlanForm.get('ClassId').value;
  //   if(ClassID>0){
  //     this.getAllBatchByClass(ClassID);
  //     this.getAllCoursePlanByClass(ClassID);

  //   }
  // }

/*   public changesearch() {

    ;
    var ClassCourseName = this.coursePlanForm.get('ClassCourseName').value;

    if (ClassCourseName == "") {
      this.getClassId();
    }
    else {
      this.lstCourseVm = this.lstCourseVm.filter((item: CourseSummary) => {
        return item.courseCourseTitle.toLowerCase().includes(ClassCourseName.toLowerCase());
      });
    }

  } */

  classes$ = this.coursePlannerService.GetClass();
  searchQuery$ = new BehaviorSubject<string>('');
  selectedClassSubject$ = new BehaviorSubject<string>('0');
  loader$ = new BehaviorSubject<Boolean>(false);
  isLoading$ = this.loader$.asObservable();
  selectClassAction$ = this.selectedClassSubject$.asObservable();

  selectedCourses$ =combineLatest([this.selectClassAction$,this.searchQuery$]).pipe(
    debounceTime(200),
    distinctUntilChanged(),
    tap(e => this.loader$.next(true)),
    switchMap(([classId,searchText]) => this.onlineClassReportService.GetCoursesByChannelId(classId).pipe(
      //delay(1500),
      map(data => {
        //return (...data,courseCover:data.courseCover != null ? environment.imageUrl + courseCover : null)
        if(classId=='0')
        {
          return EMPTY;
        }else{
          data.forEach((x) => {
            x.courseCover = x.courseCover != null ? environment.imageUrl + x.courseCover : null;
          });
          return data.filter(x=>x.courseCourseTitle.toLowerCase().includes(searchText)
          )
        }
        
      }),
      tap(e => this.loader$.next(false)),
    ))
  );
  public getClassId() {

    this.lstCourseVm = [];
    ;
    var ClassID = parseInt(this.coursePlanForm.get('ClassId').value);
    this.onlineClassReportService.GetCoursesByChannelId(ClassID).subscribe((response) => {

      if (response.length == 0) {
        this.lstCourseVm = [];
      }
      else {
        /*var Course = response.map(item => item.topicPlannerId)
        .filter((value, index, self) => self.indexOf(value) === index);
   
        Course.forEach(element => {
          
         ;
         var select = response.filter(x=> x.topicPlannerId == element);
         if(select != null){
           var course = new CourseVm();
           course.TopicPlannerTitle = select[0].topicPlannerTitle;
           course.TotalSession = select.length;
  
            this.lstCourseVm.push(course);
  
         }
         
        });*/

      }

      response.forEach((x) => {
        x.courseCover = x.courseCover != null ? environment.imageUrl + x.courseCover : null;
      });
      this.lstCourseVm = response;
      console.log(this.lstCourseVm);
    });



    this.lstCourseVm = [];
    //selectedClassId$=
  }

  onClassChange(event: Event) {
    let selectedClassId = (event.target as HTMLInputElement).value;
    this.selectedClassSubject$.next(selectedClassId);
  }
  onSearchUpdated(event: Event) {
    let searchQuery = (event.target as HTMLInputElement).value;
    this.searchQuery$.next(searchQuery);
}
}
