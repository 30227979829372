import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamwiseattendenceService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private http:HttpClient) { }
  //url:string="http://localhost:5000/";
  getAllCatefgoryDropDown(){
    return this.http.get(environment.apiUrl+"/examcategory").pipe(
      catchError(this.errorHandler)
    )
  }
  getExamNameList() {
    return this.http.get(environment.apiUrl+"/examplanner").pipe(
      catchError(this.errorHandler)
    )
  }
  getClassList(details:any){
    return this.http.get(environment.apiUrl+'/GetExamWiseAttendanceSummary?ExamCategoryId='+details.ExamCategoryId+'&ExamId='+details.ExamId+'&SubjectId='+details.SubjectId).pipe(
      catchError(this.errorHandler)
    )
  }
  getExamwiseStudentList(details:any){
    return this.http.get(environment.apiUrl+'/GetExamWiseAttendanceStudent?ClassID='+details.ClassID+'&BatchId='+details.BatchId+'&SubjectId='+details.SubjectId+'&ExamId='+details.ExamId).pipe(
      catchError(this.errorHandler)
    )
  }

  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
