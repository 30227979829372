import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit, AfterViewInit{
  @ViewChild('myModal') myModal: ModalDirective;

  ngOnInit(): void {
    
  }
  ngAfterViewInit(): void {
    this.myModal.show();
  }
}
