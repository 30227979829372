import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancelledfeereceipt',
  templateUrl: './cancelledfeereceipt.component.html',
  styleUrls: ['./cancelledfeereceipt.component.css']
})
export class CancelledfeereceiptComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
