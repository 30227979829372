import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BiomatricSettingDomain } from '../biomatric-setting-domain';
import { BiomatricSettingService } from '../biomatric-setting.service';
declare var $: any;
@Component({
  selector: 'app-biomatric-setting-add',
  templateUrl: './biomatric-setting-add.component.html',
  styleUrls: ['./biomatric-setting-add.component.css']
})
export class BiomatricSettingAddComponent extends BiomatricSettingDomain implements OnInit, OnDestroy {


  constructor(private biomatricSettingService: BiomatricSettingService,
    private toastr: ToastrService,
    ) {
    super();
  }


  ngOnInit(): void {
    this.biomatricSettingFormGroup = this.bindFormGroup();
    this.showComponent = true;
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  add() {
    this.submitted = true;
    if(this.biomatricSettingFormGroup.valid){
      this.subscription.push(this.biomatricSettingService.create(this.biomatricSettingFormGroup.value).subscribe(response => {
        this.ngOnInit();
        this.submitted = false;
        $('#addBiometricDetails').modal('hide');
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.loadBiomatricSetting.emit(true);
      }))
    }
  }

  

  

}
