import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormArray } from '@angular/forms';
import { StudentService} from './../../studentmanagement/student.service';
import { Student, StudentSibling, StudentDocument } from './../../studentmanagement/student.model';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Leave } from '../leave.model';
import { LeaveService } from '../leave.service';
import { SettingsService } from '../../mastersettings/settings.service';
import { environment } from '../../../../../environments/environment';
import { Academicyear } from '../../mastersettings/models/academicyear';
declare var $: any;

@Component({
  selector: 'app-leave',
  templateUrl: './leave.component.html',
  styleUrls: ['./leave.component.css']
})
export class LeaveComponent implements OnInit {
  isLoading:boolean=false;
  leaveForm: UntypedFormGroup;
  leaves: Leave[] = [];
  filteredLeaves: Leave[] = [];
  selectedFilteredLeaves: Leave[] = [];
  admissions: Student[] = [];
  admissionFilteredList: Student[] = [];
  studentData: Student = {} as Student;
  studentDataTemp: Student = {} as Student;
  currentDate: any;
  leaveData: Leave = {} as Leave;
  batches: Batch[] = [];
  classcourses: ClassCourse[] = [];
  currentAcademicYearId: number;
  submitted = false;
  imageSrc = '';
  searchNo = '';
  classId = '0';
  batchId = '0';
  statusId = '0';

  constructor(
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    public leaveService: LeaveService,
    public settingService: SettingsService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  ListLeaves() {
    this.isLoading=true;
    this.leaveService.getAll().subscribe((data: Leave[]) => {
      this.leaves = data;
      this.filteredLeaves = JSON.parse(JSON.stringify(this.leaves));
      this.isLoading=false;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  currentAcademicYear() {
    this.settingService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.currentAcademicYearId = data.id;
      this.ListAdmissions();
    });
  }

  ListAdmissions() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.admissions = data.filter((batch: Student) => batch.academicYearId.toString() === this.currentAcademicYearId.toString());
      this.admissionFilteredList = JSON.parse(JSON.stringify(this.admissions));
    });
  }

  onChangeBatch(value: string) {
    this.filteredLeaves = this.leaves;
    this.selectedFilteredLeaves =  this.filteredLeaves = this.filteredLeaves.filter((leave: Leave) => leave.batchId.toString()
    .toLocaleLowerCase().includes(value.toLocaleLowerCase()));
  }

  onClassSelected(value: string) {
    this.admissionFilteredList = [];
    this.searchNo = '';
    this.batches = [];
    this.studentData = {} as Student;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
    });
  }

  onBatchSelected(value: string) {
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.admissionFilteredList = data.filter((batch: Student) => batch.batchId.toString() === value.toString()
      && batch.academicYearId.toString() === this.currentAcademicYearId.toString());
      console.log(this.admissionFilteredList);
    });
  }

  onChangeLeaveStatus(value: string) {
    if (value === 'Select Status') { return false; }
    if (this.selectedFilteredLeaves.length !== 0) {
      if (value === 'All') {
        this.filteredLeaves = this.selectedFilteredLeaves;
      } else {
        // this.filteredLeaves = this.leaves;
        this.filteredLeaves = this.selectedFilteredLeaves.filter((leave: Leave) => leave.leaveStatus.toString()
        .toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      }
    } else {
      if (value === 'All') {
        this.filteredLeaves = this.leaves;
      } else {
        // this.filteredLeaves = this.leaves;
        this.filteredLeaves = this.filteredLeaves.filter((leave: Leave) => leave.leaveStatus.toString()
        .toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      }
    }
  }

  onAdmissionSelected(value: string) {
    this.studentData.studentName = '';
    this.studentData.rollNo = '';
    this.studentData.admissionNo = '';
    this.studentData.className = '';
    this.studentData.batchName = '';
    if (value !== '' && value !== 'undefind') {
      this.leaveForm.get('admissionId').patchValue(value);
      this.leaveForm.get('source').patchValue('Offline');
      this.studentService.getprofile(value).subscribe((data: Student) => {
        this.studentData = data;
        this.studentDataTemp = data;
      });
    }

  }

  onKeyUpEvent(event: any) {
    this.filterLeave(event.target.value);
  }

  filterLeave(keyword: any) {
    if ( keyword === '') {
      this.filteredLeaves = JSON.parse(JSON.stringify(this.leaves));
    } else {
      this.filteredLeaves = this.leaves
                                .filter((leave: Leave) => leave.admissionNo.toLocaleLowerCase()
                                === keyword.toLocaleLowerCase() || leave.studentName.toLocaleLowerCase()
                                === keyword.toLocaleLowerCase() 
                                );
    }
  }

  onKeyUpAdmissionEvent(event: any) {
    this.studentData = this.studentDataTemp;
    this.filter(event.target.value);
  }

  filter(keyword: any) {

    if ( keyword === '') {
      this.admissionFilteredList = JSON.parse(JSON.stringify(this.admissions));
    } else {
    this.admissionFilteredList = this.admissions
                                .filter((admission: Student) => admission.admissionNo.toLocaleLowerCase()
                                === (keyword.toLocaleLowerCase()));
    }
    console.log(this.admissionFilteredList);
    if (this.admissionFilteredList.length === 1 ) {
      this.studentData = this.admissionFilteredList[0];
      // this.leaveForm.get('classId').patchValue(this.studentData.classCourseId);
      // this.leaveForm.get('batchId').patchValue(this.studentData.batchId);
      // this.leaveForm.get('admissionId').patchValue(this.studentData.id);
    } else {
      this.toastr.warning('Record not found', 'WARNING!', {
        timeOut: 3000
      });
    }

  }

  uploadFile(event) {
    const leavefile = (event.target as HTMLInputElement).files[0];
    this.leaveForm.patchValue({
      file: leavefile
    });
    this.leaveForm.get('file').updateValueAndValidity();
  }

  setCurrentDate() {
    const x = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.currentDate = days[x.getDay()] + ' ' + this.formatDate(x);
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  get totalRows(): number {
    return this.filteredLeaves.length;
  }
  get f() { return this.leaveForm.controls; }

  resetForm() {
    this.submitted = false;
    this.leaveForm = this.fb.group({
      id: [0],
      classId: ['', Validators.required],
      batchId: ['', Validators.required],
      admissionId: ['', Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      requiredDays: ['', Validators.required],
      reason: ['', Validators.required],
      source: ['', Validators.required],
      approvedBy: [''],
      remarks: [''],
      leaveStatus: ['true', Validators.required],
      file:['']
    }, {
      validator:  this.validateDate('fromDate', 'toDate')
    });
    this.studentData = {} as Student;
    this.searchNo = '';
    this.admissionFilteredList = [];
    $('.select2').select2('val', '');
  }

  submitForm() {
    this.submitted = true;
    console.log(this.leaveForm.value);
    // this.leaveForm.get('source').patchValue('Online');
        // stop here if form is invalid
    if (this.leaveForm.invalid) {
      if (this.f.toDate.errors != null && this.f.toDate.errors.mustMatch) {
        this.toastr.warning('To date should not less than from date ', 'WARNING!!!', {
          timeOut: 3000
        });
      }
      return;
    }
debugger;
    if (this.leaveForm.get('id').value === 0) {
      this.leaveForm.get('source').patchValue('Online');
      const formData: any = new FormData();
      formData.append('id', this.leaveForm.get('id').value);
      formData.append('classId', this.leaveForm.get('classId').value);
      formData.append('batchId', this.leaveForm.get('batchId').value);
      formData.append('admissionId', this.leaveForm.get('admissionId').value);
      formData.append('fromDate', this.leaveForm.get('fromDate').value);
      formData.append('toDate', this.leaveForm.get('toDate').value);
      formData.append('requiredDays', this.leaveForm.get('requiredDays').value);
      formData.append('reason', this.leaveForm.get('reason').value);
      formData.append('source', 'Online');
      formData.append('approvedBy', this.leaveForm.get('approvedBy').value);
      formData.append('leaveStatus', 'Pending');
      if(this.leaveForm.get('file').value!=null){
        formData.append('file', this.leaveForm.get('file').value);
      }
   
console.log(formData.value);
      this.leaveService.create(this.leaveForm.value).subscribe(res => {
        console.log('leave created!');
        this.ListLeaves();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    } else {
      this.leaveService.update(this.leaveForm.get('id').value, this.leaveForm.value).subscribe(res => {
        console.log('leave updated!');
        this.ListLeaves();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
        // this.casteForm.reset();
      });
    }
    this.ListLeaves();

  }

  viewForm(id: string) {
    this.studentService.getdetails(id).subscribe((data: Student) => {
      console.log(data);
      this.imageSrc = data.imageUrl != null ? environment.imageUrl + data.imageUrl : null;
      this.studentData = data;
    });
  }

  viewLeave(id: number) {
    this.leaveService.getById(id).subscribe((data: Leave) => {
      this.viewForm(data.admissionId);
      this.leaveData = data;
    });
  }

  updateForm(id: number) {
    this.leaveService.getById(id).subscribe((data: Leave) => {
      console.log(data);
      this.viewForm(data.admissionId);
      this.leaveData = data;

      this.leaveForm = this.fb.group({
        id: [data.id],
        reason: [data.reason, Validators.required],
        remarks: [''],
        leaveStatus: [data.leaveStatus.toString(), Validators.required]
      });
    });
  }

  deleteForm(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.leaveService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.ListLeaves();
        });
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      );
      }
    });
  }
  validateDate(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }
      let fromDte = control.value;
      fromDte = new Date(fromDte.replace( /(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'));
      let toDate = matchingControl.value;
      toDate = new Date(toDate.replace( /(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'));
      // set error on matchingControl if validation fails
      if (fromDte.getTime() > toDate.getTime()) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
    };
}
  ngOnInit(): void {
    this.currentAcademicYear();
    this.ListClassCourse();
    this.ListLeaves();
    // this.ListAdmissions();
    this.leaveForm = this.fb.group({
      id: [0],
      classId: ['', Validators.required],
      batchId: ['', Validators.required],
      admissionId: ['', Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      requiredDays: ['', Validators.required],
      reason: ['', Validators.required],
      source: ['', Validators.required],
      approvedBy: [''],
      remarks: [''],
      leaveStatus: ['true', Validators.required],
      file: ['']
    }, {
      validator:  this.validateDate('fromDate', 'toDate')
   });
    this.setCurrentDate();
    $('.select2').select2();
    const self = this;
    $('.select2').on('change', function() {
      self.onAdmissionSelected(($(this).val()));
    });
    $('#fromDate').datepicker().on('changeDate', (e) => {
        const selectedDate = new Date(e.date);
        const today = new Date();
        if ( selectedDate.getTime() < today.getTime()) {
          this.toastr.warning('You cannot enter a date in the past!.', 'WARNING!!!', {
            timeOut: 3000
          });
          this.leaveForm.controls.fromDate.setValue('');
          return;
        } else {
          this.leaveForm.get('fromDate').patchValue(this.formatDate(e.date));
        }
    });


    $('#toDate').datepicker().on('changeDate', (e) => {
      const dateParts = this.formatDate(e.date).split('-');
      const month = dateParts[1];
      const day = dateParts[0];
      const year = dateParts[2];
      let ageValue = 0;
      const condition1: number = ( parseInt(day, 0));

      const fromDate = this.leaveForm.get('fromDate').value;
      const fromDateParts = fromDate.split('-');
      const toMonth = fromDateParts[1];
      const toDay = fromDateParts[0];
      const toYear = fromDateParts[2];
      const condition2: number = parseInt(toDay, 0);
      console.log(condition1);
      console.log(condition2);
      if (condition1 >= condition2) {
        ageValue = parseInt(day, 0) - (parseInt(toDay, 0));
      } else {
        ageValue = parseInt(toDay, 0) - ((parseInt(day, 0) + 1));
      }
      if (ageValue === 0) {
        ageValue = 1;
      } else {
        ageValue = ageValue + 1;
      }
      // 0-11 is Month in JavaScript
      this.leaveForm.get('toDate').patchValue(this.formatDate(e.date));
      this.leaveForm.get('requiredDays').patchValue(ageValue.toFixed(0));
    });

  }
}
