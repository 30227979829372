import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IAdmissionIssueBook, IEmployeeIssueBook, IIssueBook, ILibraryWaring, IRenewBook, IReturnBook, IStudentIssueBook, IUnBlockAdmissionType } from '../model/issueBookModel';
@Injectable({
  providedIn: 'root'
})
export class IssueBookService {

  private basePath;
  constructor(private httpClient: HttpClient) {

    this.basePath = environment.apiUrl;
  }

  getIssueBookAll() {
    return this.httpClient.get<IIssueBook[]>(this.basePath + '/issueBook');
  }

  getStudentIssueBooksAll() {
    return this.httpClient.get<IStudentIssueBook[]>(this.basePath + '/studentIssueBook');
  }

  getStudentBooksIssuedById(studentId: number) {
    return this.httpClient.get<IStudentIssueBook[]>(this.basePath + '/GetStudentBooksIssuedById?Id=' + studentId);
  }

  getEmployeeIssueBooksAll() {
    return this.httpClient.get<IEmployeeIssueBook[]>(this.basePath + '/employeeIssueBook');
  }

  getBookBorrowDetail(bookId: number) {
    return this.httpClient.get<IEmployeeIssueBook[]>(this.basePath + '/bookBorrowDetail/' + bookId);
  }

  getLibraryFineCollection(startDate: Date, endDate: Date) {
    return this.httpClient.get<IEmployeeIssueBook[]>(this.basePath + '/libraryFineCollection?startDate=' + startDate.toISOString() + '&endDate=' + endDate.toISOString());
  }

  getBooksIssuedAll(startDate: Date, endDate: Date) {
    let url = this.basePath + '/getBooksIssuedAll';
    if (startDate && endDate) {
      url += '?startDate=' + startDate.toISOString() + '&endDate=' + endDate.toISOString();
    }
    return this.httpClient.get<IAdmissionIssueBook[]>(url);
  }

  getLibraryWarningAll(issuedTo: number, admissionType: number){
    return this.httpClient.get<ILibraryWaring[]>(this.basePath + '/libraryWarning?issuedTo=' + issuedTo + '&admissionType=' + admissionType);
  }

  saveIssueBook(issueBook: IIssueBook, isEdit: Boolean) {
    if (isEdit) {
      return this.updateIssueBook(issueBook);
    }
    else {
      return this.createIssueBook(issueBook);
    }
  }

  deleteIssueBook(issueBookId: number) {
    return this.httpClient.delete<IIssueBook>(this.basePath + '/issueBook/' + issueBookId);
  }

  returnBook(returnBook: IReturnBook) {
    return this.httpClient.request<IIssueBook>('post', this.basePath + '/returnBook',
      {
        body: returnBook,
      });
  }

  renewBook(renewBook: IRenewBook) {
    return this.httpClient.request<IIssueBook>('post', this.basePath + '/renewBook',
      {
        body: renewBook,
      });
  }

  addWarning(libraryWarning: ILibraryWaring) {
    return this.httpClient.request<IIssueBook>('post', this.basePath + '/libraryWarning',
      {
        body: libraryWarning,
      });
  }

  unBlockAdmissionType(unBlockAdmissionType: IUnBlockAdmissionType) {
    return this.httpClient.request<IIssueBook>('post', this.basePath + '/unBlockAdmissionType',
      {
        body: unBlockAdmissionType,
      });
  }


  private createIssueBook(issueBook: IIssueBook) {
    return this.httpClient.request<IIssueBook>('post', this.basePath + '/issueBook',
      {
        body: issueBook,
      });
  }

  private updateIssueBook(issueBook: IIssueBook) {
    return this.httpClient.request<IIssueBook>('put', this.basePath + '/issueBook/' + issueBook.id,
      {
        body: issueBook,
      });
  }

}
