import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {SortPipe} from '../sortpipe';
import { ClassManagementRoutingModule } from './classmanagement-routing.module';
import { AssignrollnoComponent } from './assignrollno/assignrollno.component';
import { ClasssummmaryComponent } from './classsummmary/classsummmary.component';
import { BatchsummmaryComponent } from './batchsummmary/batchsummmary.component';
import { AssignteacherComponent } from './assignteacher/assignteacher.component';
import { AssignsubteacherComponent } from './assignsubteacher/assignsubteacher.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

// search module


@NgModule({
  declarations: [AssignrollnoComponent, ClasssummmaryComponent, BatchsummmaryComponent,
     SortPipe, AssignteacherComponent, AssignsubteacherComponent],
  imports: [
    CommonModule,
    FormsModule,
    ClassManagementRoutingModule,
    //Ng2SearchPipeModule,
    SharedModule
  ]
})
export class ClassmanagementModule { }
