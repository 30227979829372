import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamDesignationService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private http:HttpClient) { }
  //url:string="http://localhost:5000/";
  getClassdropdown(){
    return this.http.get(environment.apiUrl+"/Designation/Classes").pipe(
      catchError(this.errorHandler)
    )
  }

  addDesignation(data:any){
   
    return this.http.post(environment.apiUrl+"/Designation",JSON.stringify(data),this.httpOptions).pipe(
      catchError(this.errorHandler)
    )
  }

  getAlldesignations(){
    return this.http.get(environment.apiUrl+"/Designation").pipe(
      catchError(this.errorHandler)
    )
  }
  deleteDesignation(id:any){
    return this.http.delete(environment.apiUrl+"/Designation/"+id).pipe(
      catchError(this.errorHandler)
    )
  }

  getDesignationbyid(id:any){
    return this.http.get(environment.apiUrl+"/Designation/"+id).pipe(
      catchError(this.errorHandler)
    )

  }

  saveDesignation(id:any,value:any){
   
    return this.http.put(environment.apiUrl+"/Designation/"+id,JSON.stringify(value),this.httpOptions).pipe(
      catchError(this.errorHandler)
    )
  }
  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
