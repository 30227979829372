import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from './user.service';
import { Employee, EmployeePermission, ActionEvent } from './user.model';

@Component({
  selector: 'app-usermanage',
  templateUrl: './usermanage.component.html',
  styleUrls: ['./usermanage.component.css']
})
export class UsermanageComponent implements OnInit {

  userForm: UntypedFormGroup;
  employees: Employee[];
  employeeFilteredList: Employee[] = [];
  employeePermissions: EmployeePermission[];
  selEmployeePermissions: EmployeePermission[] = [];
  actions: ActionEvent[];
  imageSrc: string;
  searchKeyword = '';
  isAgent = false;
  submitted = false;

  constructor(
    public employeeService: UserService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  ListEmployees() {
    this.employeeService.getAll().subscribe((data: Employee[]) => {
      console.log(data);
      this.employeeFilteredList = this.employees = data;
    });
  }

  readURL(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const file = target.files[0];

      const reader = new FileReader();
      reader.onload = (e => {
        this.imageSrc = reader.result.toString();
        this.userForm.get('employeeImage').patchValue(this.imageSrc);
      });

      reader.readAsDataURL(file);
    }
  }

  onRoleChange(value: string) {
      if (value === 'Agent') {
        this.isAgent = true;
        this.userForm.get('agentType').enable();
      } else {
        this.isAgent = false;
        this.userForm.get('agentType').disable();
      }
  }

generateEmployeeeCode() {
  this.employeeService.generateEmployeeCode().subscribe(res => {
    // this.admissionNo = res;
    this.userForm.get('employeeCode').patchValue(res);
  });
}

generateUserId() {
  this.employeeService.generateUserId().subscribe(res => {
    // this.admissionNo = res;
    this.userForm.get('userId').patchValue(res);
  });
}

onKeyUpEvent(event: any) {
  this.filter(event.target.value);
}

filter(keyword: any) {
  if ( keyword === '') {
    this.employeeFilteredList = this.employees;
  } else {
  this.employeeFilteredList = this.employees
                              .filter((employee: Employee ) => employee.employeeName.toLocaleLowerCase()
                              .includes(keyword.toLocaleLowerCase()) ||
                              employee.employeeCode.toLocaleLowerCase()
                              .includes(keyword.toLocaleLowerCase()) ||
                              employee.mobileNo.toLocaleLowerCase()
                              .includes(keyword.toLocaleLowerCase()));
  }
}

searchKeyUp(evt: any) {
  if ( this.searchKeyword === '') {
    this.employeeFilteredList = this.employees;
  } else {
    this.employeeFilteredList = this.employees
                              .filter((employee: Employee ) => employee.employeeCode.toLocaleLowerCase()
                              .includes(this.searchKeyword.toLocaleLowerCase()));
  }
}

search() {
  console.log(this.searchKeyword);
  if ( this.searchKeyword === '') {
    this.employeeFilteredList = this.employees;
  } else {
    this.employeeFilteredList = this.employees
                              .filter((employee: Employee ) => employee.employeeCode.toLocaleLowerCase()
                              .includes(this.searchKeyword.toLocaleLowerCase()));
  }
}

resetTable() {
  this.employeeFilteredList = this.employees;
}

checkAllCheckBox(ev) {
  this.employeePermissions.forEach(x => x.checked = ev.target.checked);
}

isAllCheckBoxChecked() {
  return this.employeePermissions.every(p => p.checked);
}

checkAllPermissionCheckBox(ev, position) {
  this.employeePermissions[position].actions.forEach(x => x.checked = ev.target.checked);
}

isAllPermissionCheckBoxChecked(position) {
  return this.employeePermissions[position].actions.every(p => p.checked);
}

get totalRows(): number {
  return this.employeeFilteredList.length;
}

get f() { return this.userForm.controls; }

getExistingPermissions(value: number) {
  this.employeeService.getEmployeePermission(value).subscribe((data: EmployeePermission[]) => {
    // this.employeePermissions.forEach((x) => {
    //   data.forEach((y) => {
    //     if (x.moduleName === y.moduleName) {
    //       x.checked = true;
    //       x.employeeId = y.employeeId;
    //       x.actions.forEach((k)=>{
    //         y.permission.
    //       });
    //     }
    //   });
    // });
    data.forEach( (x) => {
      this.employeePermissions.forEach((y) => {
         if (x.moduleName === y.moduleName) {
            y.id = x.id;
            y.checked = true;
            x.permission.split('|').forEach((z) => {
                y.actions.forEach((u) => {
                    if (z === u.actionName) {
                        u.checked = true;
                    }
                });
            });
        }
      });
   });

  });
}

resetForm() {
  this.submitted = false;
  this.userForm = this.fb.group({
      id: [0],
      employeeName: ['', Validators.required],
      employeeCode: ['', Validators.required],
      mobileNo: ['', Validators.required],
      emailId: ['', Validators.required],
      aadharNo: ['', Validators.required],
      employeeRoleType: ['', Validators.required],
      departmentName: ['', Validators.required],
      agentType: [''],
      userId: ['', Validators.required],
      password: ['', Validators.required],
      employeeImage : [''],
      status: ['true', Validators.required]
  });
  this.imageSrc = '';
  this.selEmployeePermissions = [];
  this.employeePermissions.forEach((x) => {
    x.actions.forEach((y) => y.checked = false);
    x.checked = false;
  });
  this.generateEmployeeeCode();
  this.generateUserId();
}

submitForm() {
  this.submitted = true;
   // stop here if form is invalid

  

  if (this.userForm.invalid) {
          return;
    }
  this.selEmployeePermissions = [];
  this.employeePermissions.forEach((x) => {
    if (x.checked) {
      let k = 1;
      const totalSelected = x.actions.filter(a => a.checked).length;
      x.actions.forEach((a) => {
        if (a.checked) {
          if (totalSelected !== k) {
            x.permission += a.actionName.toString() + '|';
          } else {
            x.permission += a.actionName.toString();
          }
          k++;
        }
      });
      this.selEmployeePermissions.push(x);
    }
  });  

  if (this.selEmployeePermissions.length === 0) {
    this.toastr.warning('Please select Permission', 'WARNING!', {
      timeOut: 3000
    });
    return false;
  }

  if (this.userForm.get('employeeRoleType').value === 'Agent' && this.userForm.get('agentType').value === '' ) {
    this.toastr.warning('Please select Agent Type', 'WARNING!', {
      timeOut: 3000
    });
    return false;
  }

  const employee = {} as Employee;
  employee.employeeCode = this.userForm.get('employeeCode').value;
  employee.employeeName = this.userForm.get('employeeName').value;
  employee.mobileNo = this.userForm.get('mobileNo').value;
  employee.emailId = this.userForm.get('emailId').value;
  employee.aadharNo = this.userForm.get('aadharNo').value;
  employee.employeeRoleType = this.userForm.get('employeeRoleType').value;
  employee.departmentName =  this.userForm.get('departmentName').value;
  employee.agentType =  this.userForm.get('agentType').value;
  employee.employeeImage = this.userForm.get('employeeImage').value;
  employee.userId = this.userForm.get('userId').value;
  employee.password = this.userForm.get('password').value;
  employee.status = this.userForm.get('status').value;
  employee.employeePermissions = this.selEmployeePermissions;

  if (this.userForm.get('id').value === 0) {
      console.log(this.userForm.value);
      this.employeeService.create(employee).subscribe(res => {
        console.log('Employee created!');
        this.ListEmployees();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
      } else {
        this.employeeService.update(this.userForm.get('id').value, this.userForm.value).subscribe(res => {
          console.log('Employee updated!');
          this.ListEmployees();
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
         });
      }
}
updateForm(id: number) {
 this.employeeService.getById(id).subscribe((data: Employee) => {
    console.log(data);
    this.getExistingPermissions(data.id);
    this.userForm = this.fb.group({
      id: [data.id],
      employeeName: [data.employeeName, Validators.required],
      employeeCode: [data.employeeCode, Validators.required],
      mobileNo: [data.mobileNo, Validators.required],
      emailId: [data.emailId, Validators.required],
      aadharNo: [data.aadharNo],
      employeeRoleType: [data.employeeRoleType, Validators.required],
      departmentName: [data.departmentName, Validators.required],
      agentType: [data.agentType],
      userId: [data.userId, Validators.required],
      password: [data.password, Validators.required],
      employeeImage : [data.employeeImage],
      status: [data.status, Validators.required]
    });
    this.imageSrc = data.employeeImage;
  });

}

deleteForm(id: number) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You won\'t be able to revert this!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.employeeService.delete(id).subscribe(res => {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
        this.ListEmployees();
      });
    // For more information about handling dismissals please visit
    // https://sweetalert2.github.io/#handling-dismissals
    } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire(
      'Cancelled',
      'Your imaginary file is safe :)',
      'error'
    );
    }
  });
}

  ngOnInit(): void {
    this.ListEmployees();
    this.employeePermissions = [
       {id: 0, employeeId: '0', moduleName: 'DASHBOARD', checked: false, actions: [{actionName: 'Create', checked: false},
       {actionName: 'Edit', checked: false},
       {actionName: 'Delete', checked: false},
       {actionName: 'View', checked: false}], permission: '' },
       {id: 0, employeeId: '0', moduleName: 'INSTITUTION MANAGEMENT', checked: false, actions: [{actionName: 'Create', checked: false},
       {actionName: 'Edit', checked: false},
       {actionName: 'Delete', checked: false},
       {actionName: 'View', checked: false}], permission: ''  },
       {id: 0, employeeId: '0', moduleName: 'BILLING MANAGEMENT', checked: false, actions: [{actionName: 'Create', checked: false},
       {actionName: 'Edit', checked: false},
       {actionName: 'Delete', checked: false},
       {actionName: 'View', checked: false}], permission: ''  },
       {id: 0, employeeId: '0', moduleName: 'LEAD MANAGEMENT', checked: false, actions: [{actionName: 'Create', checked: false},
       {actionName: 'Edit', checked: false},
       {actionName: 'Delete', checked: false},
       {actionName: 'View', checked: false}], permission: ''  },
       {id: 0, employeeId: '0', moduleName: 'CONFIGURATIONS', checked: false, actions: [{actionName: 'Create', checked: false},
       {actionName: 'Edit', checked: false},
       {actionName: 'Delete', checked: false},
       {actionName: 'View', checked: false}], permission: ''  },
       {id: 0, employeeId: '0', moduleName: 'SUPPORT', checked: false, actions: [{actionName: 'Create', checked: false},
       {actionName: 'Edit', checked: false},
       {actionName: 'Delete', checked: false},
       {actionName: 'View', checked: false}], permission: ''  }
    ];

    this.userForm = this.fb.group({
      id: [0],
      employeeName: ['', Validators.required],
      employeeCode: [''],
      mobileNo: ['', Validators.required],
      emailId: ['', Validators.required],
      aadharNo: [''],
      employeeRoleType: ['', Validators.required],
      departmentName: ['', Validators.required],
      agentType: [''],
      userId: ['', Validators.required],
      password: ['', Validators.required],
      employeeImage : [''],
      status: ['true', Validators.required]
     });
    this.generateEmployeeeCode();
    this.generateUserId();
  }

}
