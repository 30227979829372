import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { Student } from '../../studentmanagement/student.model';
import { StudentService } from '../../studentmanagement/student.service';
import { FeeHeadService } from '../feehead/feehead.service';
import { FeeHead } from '../feehead/feehead.model';
import { InstallmentService } from '../installment/installment.service';
import { Installment } from '../installment/installment.model';
import { FeeMaster, FeeDetail, FeeDetailDetail, FeeMasterTransaction } from './feecollectionold.model';
import { FeeCollectionService } from './feecollectionold.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Academicyear } from '../../mastersettings/models/academicyear';
import { SettingsService } from '../../mastersettings/settings.service';
import { FeeRecieptService } from '../feemanagementreports/feereceipt/feereceipt.service';
//import { debug, log } from 'console';
import { PaymentMode } from '../../mastersettings/models/paymentmode';
import { PaymentModeService } from '../../mastersettings/paymentmode/paymentmode.service';
import cloneDeep from 'lodash'
import { dA } from '@fullcalendar/core/internal-common';
import { debug } from 'console';
declare var $: any;
declare var xyz: any;

@Component({
  selector: 'app-feecollection',
  templateUrl: './feecollectionold.component.html',
  styleUrls: ['./feecollectionold.component.css']
})
export class FeecollectionoldComponent implements OnInit {
  
  showFeeDetail: boolean[] = [];
  showStudentDetail = false;
  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  lAdmissions: Student[] = [];
  lookupAdmissions: Student[] = [];
  selectedStudentClassId = '';
  selectedStudentBatchId = '';
  classId = '';
  admissionno = '';
  admissionId = '';
  feeHeads: FeeHead[] = [];
  extraFeeHeads: FeeHead[] = [];
  installments: Installment[] = [];
  filteredInstallments: Installment[] = [];
  feeDetailList: FeeDetail[] = [];
  studentDetail: Student = {} as Student;
  lookupMobileNo = '';
  lookupStudentName = '';
  batchId = 'Select Batch';
  lookupFatherName = '';
  feeTotal: number[] = [];
  feeDiscountTotal: number[] = [];
  feePaidTotal: number[] = [];
  feePaibaleAmount: number[] = [];
  feeDueTotal: number[] = [];
  feeReciepts: FeeMaster[] = [];
  grandTotal :number= 0;
  grandDiscountTotal:number = 0;
  grandPayableAmount:number = 0;
  grandPaidTotal:number = 0;
  grandDueTotal:number = 0;
  grandAdvanceTotal :number= 0;
  isAdvancePay = false;
  xyz = this;
  chequedate: Date;
  chequeDateText: '';
  extraFeeInstallmentId :number= 0;
  paymentMode = '0';
  bankName = '';
  chequeNo = '';
  paymentNote = '';
  totalPaid :number= 0;
  currentDue :number= 0;
  totalDue :number= 0;
  IsChequeSelected = true;
  IsBankSelected = true;
  dueAdvanceLabel = 'Due';
  cancelReason = '';
  selectedMasterId = 0;
  isDueAvanceShow = false;
  totalDueAmount :number= 0;
  dueFineAmount: number = 0
  dueFineAmountPayble: number=0;
  academicYears: Academicyear[] = [];
  AcademicYearId:any=0;
  studentFeeCollection: any[]=[];
  studentTravellerFee: any = 0;
  studentTravellerInstallmentId: any = 0;
  studentPayableAmount: any = 0;
  paymentsModes:PaymentMode[];
  paymentModeNameSelected: PaymentMode={} as PaymentMode;
  loader:boolean=false;
  disabled:boolean=false;
  constructor(
    public feeCollectionService: FeeCollectionService,
    public installmentService: InstallmentService,
    public studentService: StudentService,
    public feeHeadService: FeeHeadService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    private toastr: ToastrService,
    private router: Router,private feeReceiptService: FeeRecieptService,
    private activatedRoute: ActivatedRoute,
    public academicyearService: SettingsService,
    public settingsService: SettingsService,
    public paymentModeService:PaymentModeService
  ) { }
  ngOnInit(): void {
    this.loader=false;
    const self = this;
    this.IsBankSelected = false;
    this.IsChequeSelected = false;

    this.ListPaymentModes();
    this.ListAcademicYear();
    this.ListClassCourse();
    // this.ListStudents();
    this.ListExtraFee();
    //this.onBatchChanged();
   // this.ListReciepts();


    this.paymentMode = '0';
    //this.onPaymentmodeChange('Cash');

    $('.select2').select2();
    $('.select2').on('change', function () {
      self.onStudentChanged(Number($(this).val()));
    });

    $('#chequeDate').datepicker().on('changeDate', (e) => {

      this.chequedate = new Date(e.date);
      console.log('chequedate:'+this.chequedate);
      //$('#chequeDate').patchValue(this.formatDate(e.date));

    });
  }
  ListPaymentModes() {
  this.paymentModeService.getAll().subscribe((data:PaymentMode[])=>
  {
    this.paymentsModes=data;
  });  
  }

  onChequeDataChange(value: string) {
    debugger;
    this.chequedate = new Date(value);
  }

  onPaymentmodeChange(value: string) {
   this.paymentModeNameSelected=this.paymentsModes.filter(x=>x.id==parseInt(value))[0];
    if (this.paymentModeNameSelected.paymentModeName === 'Cash') {
      this.IsBankSelected = false;
      this.IsChequeSelected = false;
    } else if (this.paymentModeNameSelected.paymentModeName=== 'Bank') {
      this.IsChequeSelected = false;
      this.IsBankSelected = true;
    } else if (this.paymentModeNameSelected.paymentModeName === 'Cheque') {
      this.IsChequeSelected = true;
      this.IsBankSelected = true;
    } else {
      this.IsChequeSelected = false;
      this.IsBankSelected = false;
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9\.\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode !== '0') {
      event.preventDefault();
    }
  }
  keyPress1(event): boolean {
    
    const charCode = event.which ? event.which : event.keyCode;
    const value = event.target.value;
    if (("" + event.target.value).indexOf(".") !== -1) {
      if (("" + event.target.value).split(".")[1].length > 0) {
        return false;
      }
    }
    if (event.target.value > 100) {
      event.preventDefault();
      return false;

    }

    if (charCode === 46) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  showDetail() {
    if (this.admissionId === '') {
      this.toastr.warning('Please select student', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }else{
      this.showStudentDetail = !this.showStudentDetail;
    }
   
  }

  
  ListStudents() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.lAdmissions =this.admissions= data;
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }

  ListReciepts() {
    this.feeReciepts = [];
    if (this.admissionId === '') {
      this.toastr.warning('Please select student', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }
    this.feeCollectionService.getRecieptsByStudentId(this.admissionId).subscribe((data: FeeMaster[]) => {
      this.feeReciepts = data;
      console.log(this.feeReciepts);
    });
  }
  onClassSelected(value: string) {
    this.loader=true;
    
   this.resetAllForm(); 
   this.batchId='';
    this.classId = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
    });
    if(this.selectedStudentBatchId!=''){
      this.batchId=this.selectedStudentBatchId;
    }

    this.loader=false;
  }

  onBatchChanged(value?: string) {
    //debugger
    this.resetAllFormForBatch();
    this.loader =!this.loader;
    this.admissions=[];
    this.studentService.getAll().subscribe((data: Student[]) => {
     // this.ListFeeHead();     
     console.log('student'+data);
      if(value){
        this.admissions = data.filter((student: Student) => {
          console.log(value, 'batch');
          console.log(this.classId);
          
          return student.classCourseId.toString() === this.classId &&
            student.batchId.toString() === value.toString();
        });
      } else {
        this.admissions = [];
      }
    });
    if(value){
      /*this.admissions = this.admissions.filter((student: Student) => {
        console.log(value);
        console.log(this.classId);
        
        return student.classCourseId.toString() === this.classId &&
          student.batchId.toString() === value.toString();
      });*/
      this.admissions=this.lAdmissions.filter(student=>student.classCourseId.toString() === this.classId &&
      student.batchId.toString() === value.toString());
    }
   
     if(this.batchId!=''){

      this.admissions = this.admissions.filter((student: Student) => {
        console.log(value);
        console.log(this.classId);
        
        return student.classCourseId.toString() === this.classId &&
          student.batchId.toString() === value.toString();
      });
      this.admissions=this.lAdmissions.filter(student=>student.classCourseId.toString() === this.classId &&
      this.batchId.toString() === value.toString());
    } 
    console.log(this.admissions);
    this.loader =!this.loader;
  }
  ListAcademicYear() {
    this.settingsService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
      let AcademicYearItem = this.academicYears.find(year=>year.isCurrentAcademicYear==true);
     this.AcademicYearId = AcademicYearItem.id;
    });
  }

  onStudentChanged(id: number) {
    
    this.feeDetailList = [];
    this.installments=[];
    this.feeTotal = [];
    this.totalDue = 0;
    this.totalPaid = 0;
    this.currentDue = 0;
    this.grandPaidTotal = 0;
    this.grandDiscountTotal = 0;
    this.grandDueTotal = 0;
    this.grandTotal = 0;
    this.grandAdvanceTotal = 0;
    this.grandPayableAmount = 0;
    this.isDueAvanceShow = false;
  
    //const currentMonth = moment(new Date()).format('MMM-YYYY');
    const currentMonth = new Date().getMonth()+1;
    const currentDat = new Date().getTime();
   //alert('cant'+currentMonth)
    this.studentService.getStudentById(id).subscribe((data: Student) => {
      this.studentDetail = data;
      this.selectedStudentClassId = this.studentDetail.classCourseId;
      this.selectedStudentBatchId=this.studentDetail.batchId;
      this.admissionId = this.studentDetail.id.toString();
      this.installmentService.getFeePayment(this.admissionId).subscribe((inst: Installment[]) => {         

      // this.installmentService.getFeePayment(this.studentDetail.id).subscribe((inst: Installment[]) => {   

        inst.forEach((x) => x.dueAmount = Math.abs(Number(x.dueAmount)).toString());
        // inst.forEach((x) => !x.isPaid?x.checked=true:x.checked=false);
        inst.forEach((x) => {    
             //alert('sub'+new Date(x.startDate).getMonth()+1)  
             let installemntMonth = new Date(x.startDate).getMonth()+1;   
             //alert('sub'+installemntMonth)
          if ( currentMonth== installemntMonth && !x.isPaid && !x.isWaved) {
                x.checked=true;
          }
        }); 
        console.log("On Change Install");
        console.log(inst);
        
        this.installments = inst.filter((x)=>!x.isDeleted);
        //this.installmentService.getFeePaymentCollection(this.studentDetail.id).subscribe((data) => { 
        this.installmentService.getFeePaymentCollection(this.admissionId).subscribe((data) => { 
          
          this.studentFeeCollection = data['table1'];
          console.log("getFeePaymentCollection : "+this.studentFeeCollection);
          if(this.studentFeeCollection!=undefined)
          {
            if(this.studentFeeCollection.length != 0)
          {              
          this.studentTravellerFee  = this.studentFeeCollection[0].amount;
          this.studentTravellerInstallmentId =this.studentFeeCollection[0].installmentId;
          /*this.installments.forEach((x=>{
            if(x.installmentId==)
          }))*/
          }
          else
          {
            this.studentTravellerFee = 0;
            this.studentTravellerInstallmentId = 0;
          }
          }
          this.duedInstallmentsSelected(this.installments);
        })
       // this.studentPaymentDetailsFilteredList.filter(x => x.studentName)[0];
      // this.duedInstallmentsSelected(inst);

      });
     
    });

  }


  onStudentChangedLookUp(id: number) {
    debugger;
    this.feeDetailList = [];
    this.feeTotal = [];
    this.totalDue = 0;
    this.totalPaid = 0;
    this.currentDue = 0;
    this.grandPaidTotal = 0;
    this.grandDiscountTotal = 0;
    this.grandDueTotal = 0;
    this.grandTotal = 0;
    this.grandAdvanceTotal = 0;
    this.grandPayableAmount = 0;
    this.isDueAvanceShow = false;
  

   
    this.studentService.getStudentById(id).subscribe((data: Student) => {
      this.studentDetail = data;
      this.selectedStudentClassId = this.studentDetail.classCourseId;
      this.selectedStudentBatchId=this.studentDetail.batchId;
      this.admissionId = this.studentDetail.id.toString();
      const currentMonth = moment(new Date()).format('MMM-YYYY');
      const currentDat = new Date().getTime();
    
      this.installmentService.getFeePayment(this.admissionId).subscribe((inst: Installment[]) => {         

      // this.installmentService.getFeePayment(this.studentDetail.id).subscribe((inst: Installment[]) => {   

        inst.forEach((x) => x.dueAmount = Math.abs(Number(x.dueAmount)).toString());
        inst.forEach((x) => {
          if (currentMonth == moment(new Date(x.startDate)).format('MMM-YYYY')&& !x.isPaid && !x.isWaved) {
                x.checked=true;
          }
        } 
        );        
        
        this.installments = inst;
        //this.installmentService.getFeePaymentCollection(this.studentDetail.id).subscribe((data) => { 
        this.installmentService.getFeePaymentCollection(this.admissionId).subscribe((data) => { 
          
          this.studentFeeCollection = data['table1'];
          if(this.studentFeeCollection!=undefined)
          {
            if(this.studentFeeCollection.length != 0)
          {              
          this.studentTravellerFee  = this.studentFeeCollection[0].amount;
          this.studentTravellerInstallmentId =this.studentFeeCollection[0].installmentId;
          /*this.installments.forEach((x=>{
            if(x.installmentId==)
          }))*/
          }
          else
          {
            this.studentTravellerFee = 0;
            this.studentTravellerInstallmentId = 0;
          }
          }
          this.duedInstallmentsSelected(this.installments);
        })
       // this.studentPaymentDetailsFilteredList.filter(x => x.studentName)[0];
      // this.duedInstallmentsSelected(inst);
       /* this.classId=this.selectedStudentClassId;      
      this.onClassSelected(this.classId) ;
      this.batchId=this.selectedStudentBatchId;
      this.onBatchChanged(this.selectedStudentBatchId);  */
      });
      
    });

  }

  showFee(value: number, id: number) {
    this.feeHeads = [];
    this.showFeeDetail[id] = !this.showFeeDetail[id];
  }

  getPayableAmount(id:number,amount:number,fineamount:number,paidAmount:number){
    let payableAmount =0;
    if(id>=this.studentTravellerInstallmentId)
    {
      /*  if(paidAmount<=0)
      {
      payableAmount = amount+fineamount+this.studentTravellerFee;
      }else
      {
        payableAmount = paidAmount;
      } */
      payableAmount = amount+fineamount+this.studentTravellerFee;
    }else
    {
      payableAmount = amount+fineamount;
    }
    return payableAmount;
  }

  getDueAmount(id:number,amount:number,fineAmount:number,paidAmount:number,dueAmount:number=0){
    //let dueAmount =0;
    if(id>=this.studentTravellerInstallmentId)
    {
      if(paidAmount<=0)
      {
      dueAmount = amount+fineAmount+this.studentTravellerFee - paidAmount;
      }      
      else if(amount!=paidAmount){
        return dueAmount;
      }
      else
      {
        dueAmount = 0;
      }
    }else
    {
      dueAmount = amount+fineAmount -paidAmount;
    }
    return dueAmount;
  }

  onKeyupEvent(event: any, rowindex: number, id: number) {
    debugger;
    let discAmount = event.target.value==""?"0":event.target.value;
   
    const valamount=this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amount
    if (parseFloat(discAmount) > parseFloat(valamount)) {
      this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].discountRate = valamount;
      discAmount = valamount;
      Swal.fire({
        title: 'Alert ?',
        html: "You can't enter discount more than amount",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
       
      })
      .then((result) => {
        this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].discountRate = valamount;
        discAmount = valamount;
        this.FeeCalculation(discAmount, rowindex, id)          
      })       
    }     
    this.FeeCalculation(discAmount, rowindex, id)      
  }
FeeCalculation(discAmount:any, rowindex: number, id: number)
{
  debugger;
let CramountPayable=this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amountPayable; 
let amount= (Number(this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amount));
let amountPaid = this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amountPaid;
let feeCollectionDetailDetailDtos=this.feeDetailList[id].feeCollectionDetailDetailDtos.reduce((sum: number, current) =>sum + Number(current.discountRate), 0);
this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amountPayable =(Number(this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amount) - Number(discAmount)).toString();
this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amountPaid =(Number(this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amount) - Number(discAmount)).toString();
this.feeDiscountTotal[id] = this.feeDetailList[id].feeCollectionDetailDetailDtos.reduce(( sum: any, current) =>sum + Number(current.discountRate), 0);
this.feeTotal[id] = this.feeDetailList[id].feeCollectionDetailDetailDtos.reduce((sum: number, current) =>sum + Number(current.amountPayable), 0);
this.feePaidTotal[id] = this.feeDetailList[id].feeCollectionDetailDetailDtos.reduce((sum: number, current) =>sum + Number(current.amountPaid), 0);
this.feeDetailList[id].paidAmount = this.feePaidTotal[id].toFixed(2);  
let payableAmount =0
let grandPayableAmount=0;    
this.grandDiscountTotal =   this.feeDiscountTotal.reduce((sum: number, current) => sum + Number(current.toFixed()), 0);

if(this.grandDiscountTotal>=0)
grandPayableAmount= this.grandTotal-this.grandDiscountTotal;
else
grandPayableAmount=(this.grandTotal+this.dueFineAmount)-this.grandDiscountTotal;

this.grandPayableAmount=grandPayableAmount;
this.grandPaidTotal=this.grandPayableAmount;

//ajay 
if (this.grandPaidTotal > this.grandPayableAmount) {
  
  this.isDueAvanceShow = true;
  this.grandDueTotal = ((this.grandPayableAmount)-(this.grandPaidTotal)-this.grandDiscountTotal);        
  this.dueAdvanceLabel = 'Advance';
} else if (this.grandPaidTotal < this.grandPayableAmount) {
  this.isDueAvanceShow = true;
  this.grandDueTotal =((this.grandPayableAmount)-(this.grandPaidTotal)-this.grandDiscountTotal);      
  this.dueAdvanceLabel = 'Due';
} else {
  this.isDueAvanceShow = false;
}
}
  onPaidKeypressEvent(event: any, rowindex: number, id: number) {
    const pattern = /[0-9\.+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode !== '0') {
      event.preventDefault();
    }
    const paidAmount = event.target.value;
    if (paidAmount > this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amount) {
      event.preventDefault();
      return;
    }
  }

  onPaidKeyupEvent(event: any, rowindex: number, id: number) {
 debugger;
    const paidAmount = event.target.value;
    if (paidAmount > this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amount) {
      return;
    }
    this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].duesAmount =
      (Number(this.feeDetailList[id].feeCollectionDetailDetailDtos[rowindex].amountPayable) - Number(paidAmount)).toString();
    // console.log(this.feeHeads[rowindex].paidAmount);
    this.feePaidTotal[id] = this.feeDetailList[id].feeCollectionDetailDetailDtos
      .reduce((sum: number, current) => sum + parseFloat(current.amountPaid), 0);
    this.feeDueTotal[id] = this.feeDetailList[id].feeCollectionDetailDetailDtos.reduce((sum: number, current) =>
      sum + parseFloat(current.duesAmount), 0);
    this.feeDetailList[id].paidAmount = this.feePaidTotal[id].toFixed(2);
    this.feeDetailList[id].dueAmount = this.feeDueTotal[id].toFixed(2);

    // this.grandDiscountTotal = this.feePaidTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0);
    this.grandPaidTotal = this.feeDetailList.reduce((sum: number, current) => sum + parseFloat(current.paidAmount), 0);
    this.grandDueTotal = this.feeDueTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed(2)), 0);
  }

  onGrandPaidTotalKeyup(event: any) {
    debugger;
    if (this.grandPaidTotal < this.grandPayableAmount) {
      this.isDueAvanceShow = true;
      if(this.grandDiscountTotal>0)
        this.grandDueTotal = Number(this.grandPayableAmount) - Number(this.grandPaidTotal);   
      else
       this.grandDueTotal = ((this.grandPayableAmount)-(this.grandPaidTotal)-this.grandDiscountTotal);        
      this.dueAdvanceLabel = 'Advance';
    } else if (this.grandPaidTotal > this.grandPayableAmount) {
      this.isDueAvanceShow = true;
      if(this.grandDiscountTotal>0)
        this.grandDueTotal = Number(this.grandPayableAmount) - Number(this.grandPaidTotal);
      else
        this.grandDueTotal = ((this.grandPayableAmount)-(this.grandPaidTotal)-this.grandDiscountTotal);      
      this.dueAdvanceLabel = 'Due';
    } else {
      this.isDueAvanceShow = false;
    }
  }

  totalAmount: any;
  totalPayableAmount: any;
  addSelected(id: number, event: any) {
    debugger;
   let advFee = 0;
    this.dueFineAmount =0;
    if (event.target.checked) {
      this.showFeeDetail.push(false);
      this.filteredInstallments.push(this.installments[id]);

      const installment = this.installments[id]; 
      debugger;
      let ad= this.installments.reduce((sum: number, current) => sum + parseFloat(current.advance), 0);
     
      if (Number(ad) !== 0) { 
        this.grandAdvanceTotal = Number(ad);
        advFee= this.grandAdvanceTotal;
        this.isAdvancePay = true;
      }
      const feeDetail = {} as FeeDetail;
      feeDetail.installmentId = installment.id.toString();
      feeDetail.installmentName = installment.installmentName;
      feeDetail.fineAmount = installment.fineAmount;
      //feeDetail.paidAmount = (parseFloat(installment.amount) - parseFloat(installment.dueAmount)).toString();
      feeDetail.paidAmount=installment.paid;
      feeDetail.payableAmount = this.getPayableAmount(installment.id,Number(installment.amount),installment.fineAmount,Number(installment.paid)).toString(); 
      if (Number(installment.dueAmount) !== 0) {
        feeDetail.dueAmount = installment.dueAmount.toString();
      } else {           
        feeDetail.dueAmount = this.getDueAmount(installment.id,Number(installment.amount),installment.fineAmount,Number(installment.paid)).toString(); // (Number(installment.amount) - Number(installment.paid)).toString();
      }
      feeDetail.feeCollectionDetailDetailDtos = [];
      this.feeHeadService.getByInstallmentId(installment.id, this.selectedStudentClassId,this.selectedStudentBatchId, this.admissionId)
        .subscribe((data: FeeHead[]) => {

          const FeeHead = {} as FeeHead;
          FeeHead.id=19;
          FeeHead.feeType="Common";
          FeeHead.feeHeadName="fine am";
          FeeHead.amount=installment.fineAmount.toString();
          FeeHead.discountRate="0"
          FeeHead.amountPaid=installment.fineAmount.toString();
          data.push(FeeHead);            
          this.feeHeads = data;
          let totalpayamount = 0;
          data.forEach((x, y) => {
            if (y !== 0) {
              totalpayamount += Number(x.amount);
            }
          });
          this.feeHeads.forEach((x, y) => {  
            ////;            
            const discAmmount = x.discountRate !== undefined ? x.discountRate : 0.00;
            const feeDetailDet = {} as FeeDetailDetail;
            feeDetailDet.feeHeadId = x.id.toString();
            feeDetailDet.feeType = x.feeType;
            feeDetailDet.feeHeadName = x.feeHeadName;
            if (Number(installment.dueAmount) !== 0 && y === 0) {
              feeDetailDet.amountPaid = (Number(x.amount) - Number(installment.dueAmount)).toFixed(2);
              feeDetailDet.duesAmount = Number(installment.dueAmount).toFixed(2);
            } else if (installment.isPaid) {
              feeDetailDet.amountPaid = (Number(x.amount) - Number(discAmmount)).toString();
            } else {
              ////hichamchanges
              //feeDetailDet.amountPaid = Number(0).toFixed(2);
              /////
              feeDetailDet.amountPaid = Number(x.amount).toFixed(2);
              feeDetailDet.duesAmount = (0).toFixed(2);
            }
            feeDetailDet.amountPayable = (Number(x.amount) - Number(discAmmount)).toString();
            feeDetailDet.discountRate = (0).toFixed(2);
            feeDetailDet.amount = x.amount;
            feeDetailDet.isExtra = false;
           // feeDetailDet.totalAmount = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);                       
            feeDetail.feeCollectionDetailDetailDtos.push(feeDetailDet);


           
            this.feeDiscountTotal[this.feeDetailList.length - 1] = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.discountRate), 0);
            this.feeDueTotal[this.feeDetailList.length - 1] = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.duesAmount), 0);
            if (Number(installment.dueAmount) !== 0) {
              this.feeTotal[this.feeDetailList.length - 1] = Number(installment.dueAmount);
              this.feePaidTotal[this.feeDetailList.length - 1] = Number(installment.dueAmount);
            } else {
              this.feeTotal[this.feeDetailList.length - 1] = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);
              this.feePaidTotal[this.feeDetailList.length - 1] = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);
            }
            this.grandDiscountTotal = this.feeDiscountTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0);
           // this.grandTotal = this.grandPayableAmount= this.feeTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), );
          // this.totalAmount = this.totalPayableAmount = this.feeDetailList.map(x => Number.parseInt(x.payableAmount)).reduce((acc, curr) => acc + curr, 0);
           //this.grandTotal = this.grandPayableAmount= this.feeDetailList.map(x => Number.parseInt(x.payableAmount)).reduce((acc, curr) => acc + curr, 0);
           // this.grandPaidTotal = this.feePaidTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0)-advFee;
           //this.grandPaidTotal = this.feeDetailList.map(x => Number.parseInt(x.paidAmount)).reduce((acc, curr) => acc + curr, 0)-advFee;
            this.grandDueTotal = this.grandPayableAmount - this.grandPaidTotal;

            if (Number(installment.advance) !== 0) {
              if (this.grandAdvanceTotal > this.grandTotal) {
                this.grandPaidTotal = this.grandPayableAmount = 0;
                this.grandDueTotal = this.grandAdvanceTotal - this.grandTotal;
                if (this.grandDueTotal > 0) {
                  this.isDueAvanceShow = true;
                  this.dueAdvanceLabel = 'Advance';
                } else {
                  this.isDueAvanceShow = false;
                  this.dueAdvanceLabel = 'Due';
                }
              } else {
                this.grandPaidTotal = this.grandPayableAmount = this.grandTotal + this.grandAdvanceTotal;
                this.grandDueTotal = this.grandPayableAmount - this.grandPaidTotal;
              }
            }
          });
           ///////////////
           const feeDetailDett = {} as FeeDetailDetail;
           feeDetailDett.feeHeadId = "25".toString();
           feeDetailDett.feeType = "common";
           feeDetailDett.feeHeadName = "Payable Amount";            
           feeDetailDett.amount = (feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0)).toString();
           ////hicham
           feeDetailDett.discountRate=(0).toFixed();
           feeDetailDett.amountPaid=(0).toFixed();
           //// hicham

           feeDetail.feeCollectionDetailDetailDtos.push(feeDetailDett);

           //////////////
        });
       // feeDetail.totaldueAmount = advFee -parseInt(feeDetail.dueAmount) + feeDetail.fineAmount;
       feeDetail.totaldueAmount = this.grandPayableAmount - this.grandPaidTotal;
      this.feeDetailList.push(feeDetail);
      console.log(this.feeDetailList);

      let array =[];
      let arrayData =[];
      let numn = 0;
      let numnData = 0;

      this.feeDetailList.forEach(function(elm,index){
        // array.push(parseInt(elm.dueAmount));
         arrayData.push(elm.fineAmount);
        array.push(parseInt(elm.dueAmount));
      })

      for(let i =0; i < array.length; i++){
        numn += parseInt(array[i]);
      }
      for(let i =0; i < arrayData.length; i++){
        numnData += parseInt(arrayData[i]);
      }
      this.grandTotal = 0;
      this.dueFineAmount =0;
      setTimeout(() => {
        if(this.grandDiscountTotal>0){
       // this.grandTotal = (numn+numnData);
       // this.grandPayableAmount = (advFee -numn+numnData) - this.grandDiscountTotal;
       // this.grandPaidTotal = (advFee-numn  + numnData) - this.grandDiscountTotal;
       this.grandTotal = this.grandPayableAmount = numn;
       this.grandPaidTotal = (advFee-numn) - this.grandDiscountTotal;
        this.dueFineAmount = numnData;
        }
        else{
         // this.grandTotal = this.grandPayableAmount = numn+numnData+this.studentTravellerFee;
         this.grandTotal = this.grandPayableAmount = numn;
        //this.grandPayableAmount = advFee - (numn+numnData);
       // this.grandPaidTotal = advFee - (numn  + numnData);
       // this.grandPaidTotal = advFee - (numn);
       this.grandPaidTotal = (numn);
        this.dueFineAmount = numnData;
        }
      }, 100);
            
    } 
    else {
      const totPaidCOunt = this.installments.filter((x: Installment) => {
        return x.isPaid.toString() === 'true';
      }).length;
      const toWaveOff = this.installments.filter((x: Installment) => x.isWaved.toString() === 'true').length;      
      let currentindex = 0;
      for (let i = 0; i <= this.feeDetailList.length - 1; i++) {
        if (this.feeDetailList[i].installmentId.toString() === this.installments[id].id.toString()) {
          currentindex = i;
        }
      }
      console.log('Before: ' + this.feeDetailList.length);
      this.feeDetailList.splice(currentindex, 1);
      this.feeDiscountTotal.splice(currentindex, 1);
      this.feeDueTotal.splice(currentindex, 1);
      this.feePaidTotal.splice(currentindex, 1);
      this.feeTotal.splice(currentindex, 1);        
      console.log('After: ' + this.feeDetailList.length);
      this.grandDiscountTotal = this.feeDiscountTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0);
      // this.grandTotal = this.grandPayableAmount
      //   = this.feeTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0);
     // this.totalAmount = this.totalPayableAmount = this.feeDetailList.map(x => Number.parseInt(x.payableAmount)).reduce((acc, curr) => acc + curr, 0);
     this.grandTotal = this.grandPayableAmount = this.feeDetailList.map(x => Number.parseInt(x.payableAmount)).reduce((acc, curr) => acc + curr, 0);
      this.grandPaidTotal = this.feePaidTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0);
      this.grandDueTotal = this.grandPayableAmount - this.grandPaidTotal;
      if (Number(this.grandAdvanceTotal) !== 0) {
        if (this.grandAdvanceTotal > this.grandTotal) {
          this.grandPaidTotal = this.grandPayableAmount = 0;
          this.grandDueTotal = this.grandAdvanceTotal - this.grandTotal;
          if (this.grandDueTotal > 0) {
            this.isDueAvanceShow = true;
            this.dueAdvanceLabel = 'Advance';
          } else {
            this.isDueAvanceShow = false;
            this.dueAdvanceLabel = 'Due';
          }
        } else {
          this.grandPaidTotal = this.grandPayableAmount = this.grandTotal + this.grandAdvanceTotal;
          this.grandDueTotal = this.grandPayableAmount - this.grandPaidTotal;
        }
      }
      if (this.feeDetailList.length === 0) {
        this.grandTotal = 0;
        this.grandDueTotal = 0;
        this.grandDiscountTotal = 0;
        this.grandPaidTotal = 0;
        this.grandPayableAmount = 0;
        this.grandAdvanceTotal = 0;
        this.isAdvancePay = false;
        this.isDueAvanceShow = false;
        this.totalAmount = this.totalPayableAmount = 0;
      }else{
        let array =[];
        let arrayData =[];
        let numn = 0;
        let numnData = 0;
  
        this.feeDetailList.forEach(function(elm,index){       
          var dd:any=(+elm.payableAmount)-(+elm.fineAmount)
          array.push(parseInt(dd));
          arrayData.push(elm.fineAmount);
        })
  
        for(let i =0; i < array.length; i++){
          numn += parseInt(array[i]);
        }
        for(let i =0; i < arrayData.length; i++){
          numnData += parseInt(arrayData[i]);
        }
        this.grandTotal = 0;
        this.dueFineAmount =0;
        setTimeout(() => {
            // this.grandTotal = numn;
            // this.grandPayableAmount = numn+numnData;
            // this.grandPaidTotal = numn  + numnData;
            // this.dueFineAmount = numnData;

            if(this.grandDiscountTotal>0){
              this.grandTotal = (numn+numnData);
              this.grandPayableAmount = (numn+numnData) - this.grandDiscountTotal;
              this.grandPaidTotal = (numn  + numnData) - this.grandDiscountTotal;
              this.dueFineAmount = numnData;
              }
              else{
                this.grandTotal = numn+numnData;
              this.grandPayableAmount = numn+numnData;
              this.grandPaidTotal = numn  + numnData;
              this.dueFineAmount = numnData;
              }
          }, 100);
              }
          }


  }
  openLookUp() {
    this.resetLookUpStudent();
  }
  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('-');
  }

  getReciept(id: any) {
    this.router.navigate(['../report/feereceipt', id], { relativeTo: this.activatedRoute });
  }

  resetAllForm() {
    this.classId = '';
    this.batches = [];//onClassSelected
    this.batchId='';
    this.installments = [];
    this.lookupAdmissions = [];
    this.admissions = [];
    this.feeDetailList = [];
    this.feeTotal = [];
    this.studentDetail = {} as Student;
    this.admissionno = '';
    this.grandPaidTotal = 0;
    this.grandDiscountTotal = 0;
    this.grandDueTotal = 0;
    this.grandAdvanceTotal = 0;
    this.grandTotal = 0;
    this.grandPayableAmount = 0;
    this.isAdvancePay = false;
    this.chequeNo = '';
    this.bankName = '';
    this.paymentMode = '0';
    this.paymentNote = '';
    this.totalPaid = 0;
    this.currentDue = 0;
    this.totalDue = 0;
    this.isDueAvanceShow = false;
    //this.onPaymentmodeChange('0');
    this.studentTravellerFee = 0;
    this.feeReciepts = [];
    this.admissionId='';
    this.showStudentDetail=false;
  }

  resetAllFormForBatch() {
   // this.classId = '';
   // this.batches = [];
    this.installments = [];
    this.lookupAdmissions = [];
    this.admissions = [];
    this.feeDetailList = [];
    this.feeTotal = [];
    this.studentDetail = {} as Student;
    this.admissionno = '';
    this.grandPaidTotal = 0;
    this.grandDiscountTotal = 0;
    this.grandDueTotal = 0;
    this.grandAdvanceTotal = 0;
    this.grandTotal = 0;
    this.grandPayableAmount = 0;
    this.isAdvancePay = false;
    this.chequeNo = '';
    this.bankName = '';
    this.paymentMode = '0';
    this.paymentNote = '';
    this.totalPaid = 0;
    this.currentDue = 0;
    this.totalDue = 0;
    this.isDueAvanceShow = false;
    //this.onPaymentmodeChange('0');
    this.studentTravellerFee = 0;
    this.feeReciepts = [];
    this.admissionId='';
    this.showStudentDetail=false;
  }


  resetEForm() {
    this.classId = '';
    this.batches = [];
    this.installments = [];
    this.lookupAdmissions = [];
    this.admissions = [];
    this.feeDetailList = [];
    this.studentDetail = {} as Student;
    this.grandPaidTotal = 0;
    this.grandDiscountTotal = 0;
    this.grandDueTotal = 0;
    this.grandAdvanceTotal = 0;
    this.grandTotal = 0;
    this.grandPayableAmount = 0;
    this.isAdvancePay = false;
    this.chequeNo = '';
    this.bankName = '';
    this.paymentMode = '1';
    this.paymentNote = '';
    this.totalPaid = 0;
    this.currentDue = 0;
    this.totalDue = 0;
    this.isDueAvanceShow = false;
    this.onPaymentmodeChange('1');
    this.showDetail();
    this.showStudentDetail=false;
  }

  resetForm() {
    this.installments = [];
    this.lookupAdmissions = [];
    this.feeDetailList = [];
    this.grandPaidTotal = 0;
    this.grandDiscountTotal = 0;
    this.grandDueTotal = 0;
    this.grandAdvanceTotal = 0;
    this.grandTotal = 0;
    this.grandPayableAmount = 0;
    this.isAdvancePay = false;
    this.totalPaid = 0;
    this.totalDue = 0;
    this.currentDue = 0;
    this.chequeNo = '';
    this.bankName = '';
    this.paymentMode = '1';
    this.paymentNote = '';
    this.isDueAvanceShow = false;
    this.onPaymentmodeChange('1');
    this.onStudentChanged(Number(this.admissionId));
    this.studentTravellerFee = 0;
  }

  onCancel(id: number) {
    this.selectedMasterId = id;
  }
  onPrint(id: number) {
    this.selectedMasterId = id;
    return "../report/feereceipt/"+id; 
  }
  cancelReciept() {
    this.feeCollectionService.cancel(this.selectedMasterId, this.cancelReason).subscribe(() => {        
      $('#cancelFeeReceipt').modal('toggle');
      this.ListReciepts();
      this.resetForm();
    });
  }
  
  resetLookUpStudent() {
    this.lookupAdmissions = [];
    this.lookupStudentName = '';
    this.lookupMobileNo = '';
    this.lookupFatherName = '';
  }
  searchStudent() {
    this.feeDetailList = [];
    this.feeTotal = [];
    this.totalDue = 0;
    this.totalPaid = 0;
    this.currentDue = 0;
    this.grandPaidTotal = 0;
    this.grandDiscountTotal = 0;
    this.grandDueTotal = 0;
    this.grandTotal = 0;
    this.grandAdvanceTotal = 0;
    this.grandPayableAmount = 0;
    this.isDueAvanceShow = false;
    this.studentDetail = {} as Student;
    const currentMonth = new Date().getMonth();
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.studentDetail = data.filter((student: Student) => {
        return student.admissionNo.toLowerCase() === this.admissionno.toLowerCase();
      })[0];        
      if (this.studentDetail !== undefined) {
        
        this.selectedStudentClassId = this.studentDetail.classCourseId;
        this.selectedStudentBatchId=this.studentDetail.batchId;
        this.classId = this.selectedStudentClassId;
        this.batchId = this.studentDetail.batchId;
        this.admissionId = this.studentDetail.id.toString();
        this.installmentService.getFeePayment(this.studentDetail.id).subscribe((inst: Installment[]) => {
          console.log("before mapping"+inst);
          debugger;
          inst.forEach((x) => x.dueAmount = Math.abs(Number(x.dueAmount)).toString());
          inst.forEach((x) => {    
            //alert('sub'+new Date(x.startDate).getMonth()+1)  
            let installemntMonth = new Date(x.startDate).getMonth();   
            //alert('sub'+installemntMonth)
         if ( currentMonth== installemntMonth && !x.isPaid && !x.isWaved) {
               x.checked=true;
         }
       });
          
          this.installments = inst;
          console.log("after mapping"+inst);
          this.installmentService.getFeePaymentCollection(this.studentDetail.id).subscribe((data) => { 
           ;
            this.studentFeeCollection = data['table1'];
            if(this.studentFeeCollection.length != 0)
            {              
            this.studentTravellerFee  = this.studentFeeCollection[0].amount;
            this.studentTravellerInstallmentId =this.studentFeeCollection[0].installmentId;
            }
            else
            {
              this.studentTravellerFee = 0;
              this.studentTravellerInstallmentId = 0;
            }
          });
          this.duedInstallmentsSelected(inst);
        });
      } else {
        this.resetEForm();
        this.classId = '';
        this.batches = [];
        this.admissions = [];
        this.toastr.warning('No student records found', 'WARNING!', {
          timeOut: 3000
        });
      }
    });
  }
  isAllCheckBoxChecked() {
    return this.installments.every(p => p.checked);
  }

  ListExtraFee() {
    this.feeHeadService.getAll().subscribe((data: FeeHead[]) => {
      this.extraFeeHeads = data.filter((x: FeeHead) => {
        return x.feeType === 'Extra';
      });
    });
  }

  searchLookupStudent() {      
    if (this.lookupStudentName !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
        return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase());
      });        
    }
    if (this.lookupFatherName !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
        return data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase());
      });
    }
    if (this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
        return data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase());
      });
    }

    if (this.lookupStudentName !== '' && this.lookupFatherName !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
        return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase()) &&
          data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase());
      });
    }
    if (this.lookupStudentName !== '' && this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
        return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase()) &&
          data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase());
      });
    }
    if (this.lookupFatherName !== '' && this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
        return data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase()) &&
          data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase());
      });
    }
    if (this.lookupStudentName !== '' && this.lookupFatherName !== '' && this.lookupMobileNo !== '') {
      this.lookupAdmissions = this.lAdmissions.filter((data: Student) => {
        return data.studentName.toLocaleLowerCase().includes(this.lookupStudentName.toLocaleLowerCase()) &&
          data.fatherName.toLocaleLowerCase().includes(this.lookupFatherName.toLocaleLowerCase()) &&
          data.localContactNo.toLocaleLowerCase().includes(this.lookupMobileNo.toLocaleLowerCase());
      });
    }
  }

   duedInstallmentsSelected(inst: Installment[]) {
    console.log("element",inst);

    const currentMonth = moment(new Date()).format('MM-YYYY');
    const currentDat = new Date().getTime();
    this.grandTotal = 0;
    this.grandPayableAmount = 0;
   // this.totalAmount = this.totalPayableAmount = 0;
    this.grandPaidTotal = 0  ;
    this.dueFineAmount = 0;
    let k = 0;
    this.feeTotal=[];
    let advfee = 0;
    let advfee1 = 0;
    inst.forEach(async (element, i) => {
debugger;
      if (element.isPaid || parseFloat(element.paid)>0) {
        this.totalPaid += parseFloat(element.paid);
        this.totalPaid.toFixed(2);
        console.log("totalPaid",this.totalPaid);
        
      }
      if (element.advance) {
        advfee+=parseFloat(element.advance);
        advfee1 = advfee;
        this.grandAdvanceTotal = parseFloat(element.advance);
        this.isAdvancePay = true;
      }
      if ((!element.isPaid || parseFloat(element.dueAmount) !== 0) && !element.isWaved) {
        if (!element.isPaid) {
          this.totalDue +=parseFloat( element.amount + element.fineAmount + this.studentTravellerFee);
        }
        this.totalDue += Number(element.dueAmount);
        this.totalDue.toFixed(2);
      }
      if (currentDat > new Date(element.startDate).getTime() && (!element.isPaid || parseFloat(element.dueAmount) !== 0) && !element.isWaved) {          
        this.currentDue += parseFloat(element.amount + element.fineAmount + this.studentTravellerFee);
        this.currentDue.toFixed(2);
      }
      //alert('element'+moment(new Date(element.startDate)).format('MM-YYYY'))
      if (currentMonth === moment(new Date(element.startDate)).format('MM-YYYY') && !element.isPaid && !element.isWaved) {
       // element.checked = true;
        this.showFeeDetail.push(false);
        this.filteredInstallments.push(element);
        const installment = element;
        console.log();
        const feeDetail = {} as FeeDetail;
        console.log("uuuu",installment);
        feeDetail.installmentName = installment.installmentName;
        feeDetail.installmentId = installment.id.toString();
        feeDetail.paidAmount = (installment.paid).toString();
        feeDetail.payableAmount = this.getPayableAmount(installment.id,parseFloat(installment.amount),installment.fineAmount,parseFloat(installment.paid)).toString();//(installment.amount)+installment.fineAmount + this.studentTravellerFee;               
        feeDetail.dueAmount = this.getDueAmount(installment.id,parseFloat(installment.amount),installment.fineAmount,parseFloat(installment.paid)).toString();//installment.dueAmount;
        feeDetail.fineAmount = installment.fineAmount;
     
        if(installment.dueAmount!=="0")
        {
          feeDetail.totaldueAmount = parseFloat(installment.dueAmount);
        }
        else{
          feeDetail.totaldueAmount = parseInt(installment.amount) + installment.fineAmount-advfee1;
          advfee1=0;
        }
        feeDetail.feeCollectionDetailDetailDtos = [];    
        
        
     // let data  = await this.feeHeadService.getByInstallmentId_promis(installment.id, this.selectedStudentClassId, this.admissionId);
     
        this.feeHeadService.getByInstallmentId(installment.id, this.selectedStudentClassId,this.selectedStudentBatchId, this.admissionId)
          .subscribe((data: FeeHead[]) => {
            k++;
            const FeeHead = {} as FeeHead;
            FeeHead.id=19;
            FeeHead.feeType="Common";
            FeeHead.feeHeadName="Fine";
            FeeHead.amount=installment.fineAmount.toString();
            FeeHead.discountRate="0"
            FeeHead.amountPaid=installment.fineAmount.toString();
            data.push(FeeHead);
            console.log("getByInstallmentId"+data);
         
            this.feeHeads = data;
            this.feeHeads.forEach((x, y) => {
              const discAmmount = x.discountRate !== undefined ? x.discountRate : 0.00;
              const paidAmt = x.amountPaid !== undefined ? x.amountPaid : 0.00;
              const dueAmt = x.dueAmount !== undefined ? x.dueAmount : 0.00;
              const feeDetailDet = {} as FeeDetailDetail;
              feeDetailDet.feeHeadId = x.id.toString();
              feeDetailDet.feeType = x.feeType;
              feeDetailDet.feeHeadName = x.feeHeadName;
              feeDetailDet.amountPayable = (Number(x.amount) - Number(discAmmount)).toString();
              feeDetailDet.amountPaid = (Number(x.amount) - Number(paidAmt)).toString();
              feeDetailDet.duesAmount = Number(dueAmt).toFixed(2);
              feeDetailDet.discountRate = (0).toFixed(2);
              feeDetailDet.amount = x.amount;
              feeDetailDet.isExtra = false; 
              //feeDetailDet.totalAmount = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);                           ; 
              
             // feeDetailDet.totalAmount = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);                        
              feeDetail.feeCollectionDetailDetailDtos.push(feeDetailDet);  
              this.feeDiscountTotal[k - 1] = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) =>sum + parseFloat(current.discountRate), 0);
              this.feeDueTotal[k - 1] = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.duesAmount), 0);
              this.feePaidTotal[k - 1] = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPaid), 0);
              this.feeTotal[k - 1] = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);                           
              //this.grandTotal = this.feeDetailList.reduce((sum: number, current) => sum + Number(current.payableAmount)-parseFloat(current.paidAmount),0);
              this.grandDueTotal = this.feeDetailList.reduce((sum: number, current) => sum + parseFloat(current.dueAmount), 0);
              this.grandDiscountTotal = this.feeDiscountTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0);
              //this.grandPayableAmount = this.feeTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0)-advfee;
              this.grandPaidTotal = this.feeDetailList.reduce((sum: number, current) => sum + Number(current.payableAmount) - parseFloat(current.paidAmount) , 0)-advfee;
              this.dueFineAmount = this.feeDetailList.reduce((sum: number, current) => sum + current.fineAmount, 0);
             // this.totalAmount = this.totalPayableAmount = this.feeDetailList.map(x => Number.parseInt(x.payableAmount)).reduce((acc, curr) => acc + curr, 0);
              this.grandTotal = this.grandPayableAmount = this.feeDetailList.map(x => Number.parseInt(x.payableAmount)).reduce((acc, curr) => acc + curr, 0);
              //this.grandPayableAmount = this.grandPayableAmount-Number(paidAmt);
            });  

            const feeDetailDet = {} as FeeDetailDetail;
            feeDetailDet.feeHeadId = "25".toString();
            feeDetailDet.feeType = "common";
            feeDetailDet.feeHeadName = "Payable Amount"; 
            feeDetailDet.discountRate=(0).toFixed();
           feeDetailDet.amountPaid=(0).toFixed();           
            feeDetailDet.amount = (feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0)).toString();
            
            //feeDetailDet.totalAmount = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);                           ; 
            
           // feeDetailDet.totalAmount = feeDetail.feeCollectionDetailDetailDtos.reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);                        
            feeDetail.feeCollectionDetailDetailDtos.push(feeDetailDet);  
          }); 
              
        this.feeDetailList.push(feeDetail);
      }
    });
  }



dataAddOn(){
var totalPaid  = 0;
var dueAmount  = 0;
var payable  = 0;
var paidAmount  = 0;
  this.installments.forEach((x) => {
    if(x.isPaid == false && x.checked == true)
    {
      totalPaid += Number(x.amount);
    }
  });
}


  checkAllCheckBox(ev) {
    debugger;
    this.feeDetailList = [];
      this.grandTotal = 0;
      this.grandDueTotal = 0;
      this.grandDiscountTotal = 0;
      this.grandPaidTotal = 0;
      this.grandPayableAmount = 0;
      this.grandAdvanceTotal = 0;
      this.dueFineAmount = 0;
      this.isAdvancePay = false;
      this.isDueAvanceShow = false;
    if (ev.target.checked) {
      this.feeDetailList = [];
      this.grandDueTotal =0;
      let index=0;
      this.installments.forEach((x) => {
        debugger;
        /* if ((!x.isPaid || x.dueAmount !== '0') && !x.isWaved) { */
        if ((!x.isPaid || this.getDueAmount(x.id,parseFloat(x.amount),x.fineAmount,parseFloat(x.paid)) != 0) && !x.isWaved) {
          x.checked = true;
          //this.addSelected(x.id-1, ev);
          this.addSelected(index, ev);
          
        }
        index++;
      });
    } else {
      this.installments.forEach((x) => {
        debugger;
        if ((!x.isPaid || x.dueAmount !== '0') && !x.isWaved) {
          x.checked = false;
        }
      });
      //  let ad= this.installments.reduce((sum: number, current) => sum + parseFloat(current.advance), 0);
      // if (Number(this.installments.reduce((sum: number, current) => sum + parseFloat(current.advance), 0)) !== 0) {
      //  this.grandAdvanceTotal = Number(ad);
      //    this.isAdvancePay = true;
      //  }
      this.feeDetailList = [];
      this.grandTotal = 0;
      this.grandDueTotal = 0;
      this.grandDiscountTotal = 0;
      this.grandPaidTotal = 0;
      this.grandPayableAmount = 0;
      this.grandAdvanceTotal = 0;
      this.dueFineAmount = 0;
      this.isAdvancePay = false;
      this.isDueAvanceShow = false;
    }
  }

  

  addExtraFeeHead(rowindex: number) {
    this.resetExtraFee();
    this.extraFeeInstallmentId = rowindex;
  }

  addExtraFee() {
    
    const installment = this.feeDetailList[this.extraFeeInstallmentId];      
    this.extraFeeHeads.forEach((x) => {
      if (x.amount !== '0' && !x.checked) {
        this.toastr.warning('Please select fee head', 'WARNING!', {
          timeOut: 3000
        });
        return;
      }
      if (x.checked) {          
        const feeDetailDetail: FeeDetailDetail = {} as FeeDetailDetail;
        const discAmount = x.discountRate === undefined ? (0).toFixed(2) : x.discountRate;        
        if (x.amount === '0' || x.amount === undefined) {
          this.toastr.warning('Please enter amount', 'WARNING!', {
            timeOut: 3000
          });
          return;
        }
        feeDetailDetail.feeHeadId = x.id.toString();
        feeDetailDetail.amount = x.amount;
        feeDetailDetail.duesAmount = (0).toFixed(2);
        feeDetailDetail.discountRate = discAmount;
        feeDetailDetail.feeHeadName = x.feeHeadName;
        feeDetailDetail.feeType = x.feeType;
        feeDetailDetail.amountPayable = (Number(x.amount) - Number(discAmount)).toString();
        feeDetailDetail.amountPaid = (Number(x.amount) - Number(discAmount)).toFixed(2);
        feeDetailDetail.isExtra = true;
        this.feeDetailList[this.extraFeeInstallmentId].feeCollectionDetailDetailDtos.push(feeDetailDetail);
        this.feeDiscountTotal[this.feeDetailList.length - 1] = this.feeDetailList[this.extraFeeInstallmentId].feeCollectionDetailDetailDtos
          .reduce((sum: number, current) => sum + parseFloat(current.discountRate), 0);
        this.feeDueTotal[this.feeDetailList.length - 1] = this.feeDetailList[this.extraFeeInstallmentId].feeCollectionDetailDetailDtos
          .reduce((sum: number, current) => sum + parseFloat(current.duesAmount), 0);
        if (Number(installment.dueAmount) !== 0) {
          this.feeTotal[this.feeDetailList.length - 1] = (Number(installment.dueAmount) + Number(feeDetailDetail.amountPaid));
          this.feePaidTotal[this.feeDetailList.length - 1] = (Number(installment.dueAmount) + Number(feeDetailDetail.amountPaid));
          this.feeDetailList[this.feeDetailList.length - 1].dueAmount =
            (Number(installment.dueAmount) + Number(feeDetailDetail.amountPaid)).toFixed(2);
        } else {
          this.feeTotal[this.feeDetailList.length - 1] = this.feeDetailList[this.extraFeeInstallmentId].feeCollectionDetailDetailDtos
            .reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);
          this.feePaidTotal[this.feeDetailList.length - 1] = this.feeDetailList[this.extraFeeInstallmentId].feeCollectionDetailDetailDtos
            .reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);
          this.feeDetailList[this.feeDetailList.length - 1].dueAmount = this.feePaidTotal[this.feeDetailList.length - 1].toFixed(2);
        } }
      $('#ExtraCharges').modal('toggle');
    });

    this.grandDiscountTotal = this.feeDiscountTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0);
    this.grandTotal = this.grandPayableAmount
      = this.feeTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0);
    this.grandPaidTotal = this.feePaidTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0);
    this.grandDueTotal = this.grandPayableAmount - this.grandPaidTotal;

  if (this.grandAdvanceTotal > this.grandTotal) {
      this.grandPaidTotal = this.grandPayableAmount = 0;
      this.grandDueTotal = this.grandAdvanceTotal - this.grandTotal;
      if (this.grandDueTotal > 0) {
        this.isDueAvanceShow = true;
        this.dueAdvanceLabel = 'Advance';
      } else {
        this.isDueAvanceShow = false;
        this.dueAdvanceLabel = 'Due';
      }
    } else {
      this.grandPaidTotal = this.grandPayableAmount = this.grandTotal - this.grandAdvanceTotal;
      this.grandDueTotal = this.grandPayableAmount - this.grandPaidTotal;
    }

  }

  removeExtraFee(instIndex: any, index: any) {
    this.feeDetailList[instIndex].feeCollectionDetailDetailDtos.splice(index, 1);

    this.feeDiscountTotal[this.feeDetailList.length - 1] =
      this.feeDetailList[instIndex].feeCollectionDetailDetailDtos.reduce((sum: number, current) =>
        sum + parseFloat(current.discountRate), 0);

    this.feeDueTotal[this.feeDetailList.length - 1] =
      this.feeDetailList[instIndex].feeCollectionDetailDetailDtos
        .reduce((sum: number, current) => sum + parseFloat(current.amountPayable), 0);

    this.feeTotal[this.feeDetailList.length - 1] =
      this.feeDetailList[instIndex].feeCollectionDetailDetailDtos
        .reduce((sum: number, current) => sum + parseFloat(current.amount), 0);

    this.feeDetailList[instIndex].payableAmount =
      this.feeTotal[this.feeDetailList.length - 1].toFixed(2);
    this.feeDetailList[instIndex].dueAmount = this.feeTotal[this.feeDetailList.length - 1].toFixed(2);


    this.grandDiscountTotal = this.feeDiscountTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0);
    this.grandTotal = this.grandPayableAmount
      = this.feeTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0);
    this.grandPaidTotal = this.feePaidTotal.reduce((sum: number, current) => sum + parseFloat(current.toFixed()), 0);
    this.grandDueTotal = this.grandPayableAmount - this.grandPaidTotal;

    if (Number(this.grandAdvanceTotal) !== 0) {
      if (this.grandAdvanceTotal > this.grandTotal) {
        this.grandPaidTotal = this.grandPayableAmount = 0;
        this.grandDueTotal = this.grandAdvanceTotal - this.grandTotal;
        if (this.grandDueTotal > 0) {
          this.isDueAvanceShow = true;
          this.dueAdvanceLabel = 'Advance';
        } else {
          this.isDueAvanceShow = false;
          this.dueAdvanceLabel = 'Due';
        }
      } else {
        this.grandPaidTotal = this.grandPayableAmount = this.grandTotal - this.grandAdvanceTotal;
        this.grandDueTotal = this.grandPayableAmount - this.grandPaidTotal;
      }
    }
  }

  resetExtraFee() {

    this.ListExtraFee();
    this.extraFeeHeads.forEach(x => {
      x.checked = false;
      x.amount = '0';
      x.discountRate = undefined;
    });
  }

  saveData() {
    
    if (this.feeDetailList.length === 0) {
      this.toastr.warning('Please select installament', 'WARNING!', {
        timeOut: 3000
      });
      return;
    }
    if (this.paymentMode === '0') {
      this.toastr.warning('Please select Payment Mode', 'WARNING!', {
        timeOut: 3000
      });
      return false;
    }

    if (this.paymentModeNameSelected.paymentModeName === 'Paytm' && this.paymentNote === '') {
      this.toastr.warning('Please enter Payment Note', 'WARNING!', {
        timeOut: 3000
      });
      return false;
    }
    
    if(this.feeDetailList.length>1){
    //   let amount=this.feeDetailList.reduce((sum: number, current) => sum + parseFloat(current.payableAmount), 0);        
    //   amount=amount-parseFloat(this.feeDetailList[this.feeDetailList.length-1].payableAmount)
    //   let validate=true;
    //   if(this.grandPaidTotal<=amount){    
    //     var message="Please pay more then "+amount;
    //     validate=false;    
    //     this.toastr.warning(message, 'WARNING!', {
    //       timeOut: 3000
    //     });
    //     return false;     
    //   }        
     }

    Swal.fire({
      title: 'Confirm Payment ?',
      html: '<i class="fa-solid fa-indian-rupee-sign"></i> ' + this.grandPayableAmount.toString(),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        debugger;
        const formData = {} as FeeMaster;
        formData.id = 0;
        formData.AcademicYearId = this.AcademicYearId;
        formData.BranchId = '0';
        formData.ClassId = this.classId;
        formData.FeeCollectionTypeId = '0';
        formData.BatchId = this.batchId;
        formData.admissionId = this.admissionId;
        formData.amount = this.grandPayableAmount.toString();
        formData.chequeDate = this.formatDate(this.chequedate);
        formData.chequeNo = this.chequeNo;
        formData.bankName = this.bankName;
        formData.payDate = this.formatDate(new Date());
        formData.discount =  this.grandDiscountTotal.toString();
        formData.dueAdvance = (this.grandPayableAmount - this.grandTotal).toString();
        formData.paid = this.grandPayableAmount.toString();
        formData.payableAmount = this.grandPayableAmount.toString();

        // condition changed by thiru, i.e., PaidTotal 30< payable 40, so paying extra 10, consider advance
        if (this.grandPaidTotal < this.grandPayableAmount) {
          formData.dueAdvance = '0';
          formData.advance = this.grandDueTotal.toFixed(2).toString();
          formData.isAdvance = 'true';
        } else {
          if (this.grandAdvanceTotal !== 0) {
            if (this.grandAdvanceTotal > this.grandTotal) {
              formData.advance = '-' + (this.grandTotal).toFixed(2).toString();
              formData.isAdvance = 'true';
            } else {
              formData.advance = '-' + this.grandAdvanceTotal.toFixed(2).toString();
            }
          } else {
            formData.advance = '0';
          }
        }

        
        formData.paymentMode = this.paymentMode;
        formData.paymentNote = this.paymentNote;
        const payementName=this.paymentsModes.find(x=>x.id.toString()==this.paymentMode).paymentModeName;
        // if(payementName=='Cash' || payementName=='Cheque'){
        //   formData.transactionMode='Offline';
        // }else{
        //   formData.transactionMode='Online';
        // }

        // sorting the array list
        this.feeDetailList.sort((a,b)=> parseInt(a.installmentId) - parseInt(b.installmentId));

        formData.transactionMode="Offline";
        /* Commented by Thiru
        this.feeDetailList.forEach((x, i) => {
          
          if (i !== this.feeDetailList.length - 1) {
            if (x.paidAmount === '0' || Number(x.paidAmount) === 0) {
              if (this.grandAdvanceTotal < this.grandTotal) {
                x.paidAmount = x.dueAmount;
              }
              x.dueAmount = '0';
            } else {
              x.paidAmount = x.dueAmount;
              x.dueAmount = '-' + x.dueAmount;
            }
          }
        });                
        if (this.grandPaidTotal > this.grandPayableAmount)
        {
            if (Number(this.feeDetailList[this.feeDetailList.length - 1].paidAmount) > 0) 
            {
              this.feeDetailList[this.feeDetailList.length - 1].dueAmount = '-' + this.feeDetailList[this.feeDetailList.length - 1].dueAmount;
            } 
          else {
              this.feeDetailList[this.feeDetailList.length - 1].dueAmount = '0';
          }
            this.feeDetailList[this.feeDetailList.length - 1].paidAmount = Number(this.grandPaidTotal).toFixed(2);
          } else if (this.grandPaidTotal < this.grandPayableAmount) {

          if (Number(this.feeDetailList[this.feeDetailList.length - 1].paidAmount) > 0 && this.grandAdvanceTotal === 0) {               
              this.feeDetailList[this.feeDetailList.length - 1].dueAmount = '-' + Number(this.grandPaidTotal).toFixed(2);

          } else {
            this.feeDetailList[this.feeDetailList.length - 1].dueAmount = Number(this.grandDueTotal).toFixed(2);
          }
          this.feeDetailList[this.feeDetailList.length - 1].paidAmount = Number(this.grandPaidTotal).toFixed(2);
        } else if ((this.grandPaidTotal === this.grandPayableAmount)) {
          if (Number(this.feeDetailList[this.feeDetailList.length - 1].paidAmount) > 0) {
            this.feeDetailList[this.feeDetailList.length - 1].dueAmount = '-' + this.feeDetailList[this.feeDetailList.length - 1].dueAmount;
          } else {
            this.feeDetailList[this.feeDetailList.length - 1].dueAmount = '0';
          }                        
          this.feeDetailList[this.feeDetailList.length - 1].paidAmount = Number(this.grandPaidTotal).toFixed(2);
        }
        */
        //ajay Sahai for corrrect due and advance payment corrrecton
        //console.log(this.feeDetailList);
        if(this.grandPayableAmount==this.grandPaidTotal){
          formData.dueAdvance="0"
        }else if (this.grandPaidTotal>this.grandPayableAmount){
        //  var v=this.grandPayableAmount-this.grandPaidTotal
        var v = this.grandPayableAmount - this.grandTotal
          formData.dueAdvance=v.toString();
        } // this below advance consition changed by thiru - due to condition check was wrong
        else if(this.grandPaidTotal<this.grandPayableAmount){
          formData.advance=(this.grandPayableAmount-this.grandPaidTotal).toString()
        }
      // added by thiru
      let feeDetailsDeepCopy= JSON.parse(JSON.stringify(this.feeDetailList));
      if(feeDetailsDeepCopy.length>1){
        if(this.grandPaidTotal>=this.grandPaidTotal)
          {
          
          let payGrandAmount= this.grandPayableAmount;
          feeDetailsDeepCopy.forEach((x,rowIndex)=>{
              const locPayAmount= parseFloat(x.paidAmount)===0? parseFloat(x.payableAmount) : parseFloat(x.dueAmount);            
              if(locPayAmount<=payGrandAmount){
                x.paidAmount = locPayAmount.toString();
                payGrandAmount -= locPayAmount;
                x.dueAmount=(locPayAmount-parseFloat(x.paidAmount)).toString();
              }
              else if(locPayAmount>payGrandAmount && payGrandAmount>0 ) {
                x.paidAmount = (payGrandAmount).toString();
                payGrandAmount-=locPayAmount;
                x.dueAmount=(locPayAmount-parseFloat(x.paidAmount)).toString();
              } else {
                x.paidAmount="0";
                payGrandAmount=0;
                x.dueAmount=locPayAmount.toString();
              }            
              x.totaldueAmount=locPayAmount;       
              
            
          })
        }
      }
      // added by Thiru for single Installment
      if(feeDetailsDeepCopy.length === 1){
        feeDetailsDeepCopy.forEach((x,i)=>{
            x.totaldueAmount= parseFloat(x.payableAmount);
            x.paidAmount= formData.paid;
            x.dueAmount = (parseFloat(x.payableAmount)-parseFloat(formData.paid)).toString();
        });
      }
      debugger;
        formData.feeCollectionDetailDtos = feeDetailsDeepCopy;
        formData.feeCollectionDetailDtos.forEach((x) => {            
        });
        formData.isCancelled = 'false'; 
        formData.studentInstallmentId = this.studentTravellerInstallmentId;     
        formData.transportFee = this.studentTravellerFee.toString();
        formData.cancelReason = '';
        debugger;
        console.log(formData);
        this.feeCollectionService.create(formData).subscribe(res => {  
         let TransactionId = res; 
                          
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.resetForm();
          this.paymentMode = '0';
          Swal.fire({
            title: 'Payment done successfully.',
            html: 'Do you want to print receipt?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then((presult) => {
            
            if (presult.value) {
              this.feeCollectionService.getRecieptsByStudentId(this.admissionId).subscribe((data: FeeMaster[]) => {
                 var result:any=data.filter((x)=>x.id==TransactionId);
                 if(result.length>0){
                  this.feeReceiptService.getFeeReciept(result[0].receiptNo).subscribe((data) => {
                    var d=data;
                  const newRelativeUrl = this.router.createUrlTree(['../report/feereceipt/','' /*res*/], { relativeTo: this.activatedRoute });
            
                  
                  const baseUrl = window.location.href.replace(this.router.url, '');
                  window.open(baseUrl + newRelativeUrl+result[0].receiptNo, '_blank');
                  });
                 }
               
              });

// ####################BUG####################
              const newRelativeUrl = this.router.createUrlTree(['../report/feereceipt/','' /*res*/], { relativeTo: this.activatedRoute });
             
              const baseUrl = window.location.href.replace(this.router.url, '');
              window.open(baseUrl + newRelativeUrl+TransactionId, '_blank');
// >>>>>>> HRMSPayroll


            } else if ( presult.dismiss === Swal.DismissReason.cancel) {
            }
          });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });

  }

  CSSApllyinstalmentTable(installment:any){
    if(installment.dueAmount > 0){
      return "bg-danger text-white";
    }
//console.log(installment.installmentName+' paid: '+installment.isPaid+' due: '+installment.dueAmount+'  paid: '+installment.paid+' waved: '+installment.isWaved)
    if(installment.isPaid==true && (installment.dueAmount == 0 && installment.paid>0)){
      return "tdcolor";
    }
    if(this.getDueAmount(installment.id,installment.amount,installment.fineAmount,installment.paid)==0
    && this.getPayableAmount(installment.id,installment.amount,installment.fineAmount,installment.paid)!=0){
      return "tdcolor";
    }
    // if(installment.amount+installment.fineAmount > installment.paid){
    //   installment.isPaid=true;
    //   installment.dueAmount=0;
    //   installment.isWaved=true
    //   return "tdcolor";
    // }
    else if(installment.isWaved==true || installment.dueAmount != 0){
      return "bg-danger text-white"
    }
    
   
  }
  
}
