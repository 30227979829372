// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-success
{
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/academic/feemanagement/feemanagementreports/feesummaryheadwise/feesummaryheadwise.component.css"],"names":[],"mappings":"AAAA;;EAEE,iBAAiB;AACnB","sourcesContent":[".table-success\r\n{\r\n  font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
