import { Component, OnInit } from '@angular/core';
import { data } from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { TicketCommonServices } from '../commonServices/ticket.common.services';
import { TicketStatusRemark } from '../models/ticket-status-remark.model';
import { Ticket } from '../models/ticket.model';
declare var $: any;

@Component({
  selector: 'app-ticket-report',
  templateUrl: './ticket-report.component.html',
  styleUrls: ['./ticket-report.component.css']
})
export class TicketReportComponent implements OnInit {

  ticketList: Ticket[] = [];
  ticketListFiltered: Ticket[] = [];

  selectedStatus: string = "";
  selectedFromDate: Date;
  selectedToDate: Date;
  defaultValue: string = '';
  institutionNameArray: string[];
  selectedTicket: Ticket = {} as Ticket;
  ticketStatusRemarkList: TicketStatusRemark[] = [];
  ticketId:any = null;
  institutionId:any = null;
  InstitutionName:string = "";

  constructor(private ticketCommonService: TicketCommonServices, private toster: ToastrService) { }


  getAllTickets() {
    let fiterData = [];
    this.ticketCommonService.getAllTickets().subscribe((data: Ticket[]) => {
      data.forEach(element => {
        element.createdDate = new Date(element.createdDate).toLocaleDateString()
        fiterData.push(element)
      });
      this.fetchAllInstitutionNameFormTicketList(data);
    });
    this.ticketList = this.ticketListFiltered = fiterData;
  }

  fetchAllInstitutionNameFormTicketList(ticketList: Ticket[]) {
    var nameArray = ticketList.map(ticket => ticket.institutionName);
    this.institutionNameArray = nameArray.filter((value, index) =>
      nameArray.indexOf(value) === index).filter(function (el) {
        return el != null && el != "";
      });

    }

  // searchTicketById(ticketId: any) {
  //   if (ticketId == '') {
  //     this.ticketListFiltered = this.ticketList;
  //     return;
  //   }
  //   this.ticketListFiltered = this.ticketList.filter(element => element.id == ticketId);
  //   if (this.ticketListFiltered.length == 0) {
  //     this.toster.warning('No Tickets Found!', 'Sorry', {
  //       timeOut: 3000
  //     });
  //     this.ticketListFiltered = this.ticketList;
  //   }
  // }

  // searchTicketByInstitutionId(institutionId: any) {
  //   if (institutionId == '') {
  //     this.ticketListFiltered = this.ticketList;
  //     return;
  //   }
  //   this.ticketListFiltered = this.ticketList.filter(element => element.institutionId == institutionId);
  //   if (this.ticketListFiltered.length == 0) {
  //     this.toster.warning('No Tickets Found!', 'Sorry', {
  //       timeOut: 3000
  //     });
  //     this.ticketListFiltered = this.ticketList;
  //   }
  // }

  filterReport() {
    if(this.ticketId != null && this.ticketId != undefined && this.ticketId != ""){
      this.ticketListFiltered = this.ticketList.filter(element =>  element.customId == this.ticketId);  
    }else if(this.institutionId != null && this.institutionId != undefined && this.institutionId != "") {
      this.ticketListFiltered = this.ticketList.filter(element =>  parseInt(element.institutionCode) == this.institutionId);
    }
    else if((
      this.institutionId != null && this.institutionId != undefined && this.institutionId != "") 
      && 
      (this.ticketId != null && this.ticketId != undefined && this.ticketId != "")) {
      this.ticketListFiltered = this.ticketList.filter(element =>  parseInt(element.institutionCode) == this.institutionId && element.customId == this.ticketId);
    }
    else{
      this.ticketListFiltered = this.ticketList;
      if (this.selectedFromDate != undefined &&
        this.selectedToDate != undefined) {
        if (this.selectedStatus == "all") {
          this.ticketListFiltered = this.ticketList.filter(element =>
            new Date(element.createdDate) >= this.selectedFromDate &&
            new Date(element.createdDate) <= this.selectedToDate);
        }
        else {
          this.ticketListFiltered = this.ticketList.filter(element => element.ticketStatus == this.selectedStatus &&
            new Date(element.createdDate) >= this.selectedFromDate &&
            new Date(element.createdDate) <= this.selectedToDate);
        }
        if (this.ticketListFiltered.length == 0) {
          this.toster.warning('No Tickets Found!', 'Sorry', {
            timeOut: 4000
          });
          this.ticketListFiltered.length = 0;
          this.institutionId = '';
          this.ticketId = '';
          this.selectedStatus = "";
          this.selectedFromDate = null;
          this.selectedToDate = null;
        }
      }else{
      this.toster.warning('All fields are mandatory!', 'Sorry', {
        timeOut: 4000
      });
      this.ticketListFiltered = this.ticketList;
      }
    }
  }

  searchByInstitutionName(institutionName: any) {
    if (institutionName == "") {
      this.ticketListFiltered = this.ticketList;
      return;
    }
    this.ticketListFiltered = this.ticketList.filter(element => element.institutionName == institutionName);
    if (this.ticketListFiltered.length == 0) {
      this.toster.warning('No Tickets Found!', 'Sorry', {
        timeOut: 3000
      });
      this.ticketListFiltered = this.ticketList;
    }
  }
  showTicketDetails(ticketId: string)
  {
    this.selectedTicket = this.ticketList.find(element => element.id == ticketId);
    this.ticketCommonService.GetTicketRemarksByTicketId(ticketId).subscribe((data: TicketStatusRemark[]) => {
      this.ticketStatusRemarkList = data;
    });

  }

  ngOnInit(): void {
    
    $('#fromDate').datepicker().on('changeDate', (e) => {
      this.selectedFromDate = new Date(e.date);
    });

    $('#toDate').datepicker().on('changeDate', (e) => {
      this.selectedToDate = new Date(e.date);
    });

    this.getAllTickets();
  }

  //method for resoring/refreshing total list in table by click
  refreshTable() : void{
    this.ticketListFiltered = this.ticketList;
    this.institutionId = '';
    this.ticketId = '';
    this.selectedStatus = "";
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.InstitutionName = "";
  }

}
