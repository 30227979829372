import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {PaymentCancelledReportService} from './paymentcancelledreport.service';
import{PaymentCancelledReportModel} from './paymentcancelledreport.model';
import { DatePipe } from '@angular/common';
import { ClassCourseService } from '../../../../academic/mastersettings/classcourse/classcourse.service';
import { BatchService } from '../../../../academic/mastersettings/batch/batch.service';
import { Batch } from '../../../../academic/mastersettings/models/batch';
import { ClassCourse } from '../../../../academic/mastersettings/models/classcourse';
import { environment } from 'src/environments/environment';
import { Academicyear } from '../../../mastersettings/models/academicyear';
import { SettingsService } from '../../../mastersettings/settings.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-paymentcancelledreport',
  templateUrl: './paymentcancelledreport.component.html',
  styleUrls: ['./paymentcancelledreport.component.css']
})
export class PaymentcancelledreportComponent implements OnInit {
  classId:string;
  batch:string;
  mode:string;
  fromDate:string;
  toDate:string;
  totalPaidAmount:any;
  dataCount:any;
  batchList: Batch[];
  filteredBatchList: Batch[];
  classCourseList: ClassCourse[];
  paymentCancelledReportList:PaymentCancelledReportModel[] =[];
  paymentCancelledReportFilteredList:PaymentCancelledReportModel[] =[];
  isLoading:Boolean=false;
  dateFrom: Date;
  dateTo: Date;
  studentAdmissionId: any;
  headerList: any[];
  fieldList: string[];
  attributes: { name: string; fieldName: string; }[];
  defaultArray: any[];
  institution: any;
  currentAcademicYearName: any;
  constructor(
    public paymentCancelledReportService: PaymentCancelledReportService,
    private classCourseService: ClassCourseService,
    private batchService: BatchService,
    private settingsService:SettingsService,
  ) { }

  setCurrentDate() {
    const datePipe = new DatePipe('en-Us');
    let date = new Date();
    this.fromDate=datePipe.transform(new Date(date.getFullYear(), date.getMonth(), date.getDate()), 'yyyy-MM-dd');
    this.toDate=datePipe.transform(new Date(date.getFullYear(), date.getMonth(), date.getDate()), 'yyyy-MM-dd');
  }
  getPaymentCancelledReport()
  {
    debugger;
    this.isLoading=!this.isLoading;
    const datePipe = new DatePipe('en-Us');
    this.fromDate = datePipe.transform(this.fromDate, 'yyyy-MM-dd');
    this.toDate = datePipe.transform(this.toDate, 'yyyy-MM-dd');
    const self=this;
    if(this.fromDate==null|| this.fromDate=="2001-01-01" )
    {
      this.fromDate='1/1/0001';
    }
    if(this.toDate==null || this.toDate=="2001-01-01")
    {
      this.toDate='1/1/0001';
    }
    this.paymentCancelledReportService.getPaymentCancelledReport(this.classId,this.batch,this.fromDate,this.toDate).subscribe((data:PaymentCancelledReportModel[]) => {
      this.paymentCancelledReportList =[];
      this.paymentCancelledReportFilteredList =[];
      this.totalPaidAmount = 0;
      data.forEach(x=>{
         this.totalPaidAmount = this.totalPaidAmount + (x.payAmount==undefined?0:parseInt(x.payAmount));
        this.paymentCancelledReportList.push({
          date:x.date,
          receiptNo:x.receiptNo,
          admissionNo:x.admissionNo,
          studentName:x.studentName,
          class:x.class,
          batch:x.batch,
          rollNo:x.rollNo,
          payAmount:x.payAmount,
          payMode:x.payMode,
          cancelledDate:x.cancelledDate,
          cancelledBy:x.cancelledBy,
          reason:x.reason
        });
      });
      this.paymentCancelledReportFilteredList = this.paymentCancelledReportList;
      this.isLoading=!this.isLoading;
    });

  }
  public formatDate(date,onlyDate =false) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return !onlyDate ? new Date().toLocaleDateString() : [day, month, year].join('-');
  }
  getClassCourseAll() {
    const subscription = this.classCourseService.getAll().subscribe(classCourseList => {
      this.classCourseList = classCourseList
    },
      error => {
        console.log(error);
      }
    );
  }
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }
  
  filter(keyword: any) {
    debugger;
    let tempdata:PaymentCancelledReportModel[]=[];
    if (keyword === '') {
      this.getPaymentCancelledReport();
    } else {
      
      tempdata = this.paymentCancelledReportFilteredList.filter(d => {
        return (d.admissionNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
          d.receiptNo.toString().includes(keyword.toLocaleLowerCase()) ||
          d.payMode.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
          d.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())||
          d.class.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())||
          d.batch.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())||
          d.payAmount.toString().includes(keyword.toLocaleLowerCase())
          );
          
      });
      this.paymentCancelledReportFilteredList = tempdata;


    }
  }


  getBatchAll() {
    const subscription = this.batchService.getAll()
      .subscribe(batchList => {
        this.batchList = batchList;
      },
        error => {
          console.log(error);
        }
      );
  }
  filterBatch(classId) {
    this.filteredBatchList = this.batchList;
    if (classId != 0) {
      this.classId = classId;
      this.filteredBatchList = this.filteredBatchList.filter(item => item.classId == classId);
    }
  }
  selectedBatch(batchId){
    if(batchId!=0){
      this.batch = batchId;
    }
  }
  studentSearch(){
    debugger;
   if(this.studentAdmissionId!=''){
    this.paymentCancelledReportFilteredList=this.paymentCancelledReportFilteredList.filter(x=>x.admissionNo==this.studentAdmissionId);
   }else  {
    this.getPaymentCancelledReport();
   }
   
  }
  generateAttributes() {
      
    this.attributes = [
      
      {name: 'SNO', fieldName: 'index' },
      {name: 'DATE', fieldName: 'date' },
      {name: 'RECEIPT NO', fieldName: 'receiptNo' },
      {name: 'ADMS No', fieldName: 'admissionNo' },
      {name: 'STUDENT NAME', fieldName: 'studentName' },
      {name: 'CLASS', fieldName: 'class' },
      {name: 'BATCH', fieldName: 'batch' },
      {name: 'ROLL', fieldName: 'rollNo' },
      {name: 'AMOUNT', fieldName: 'payAmount' },
      {name: 'PAY MODE', fieldName: 'payMode' },
      {name: 'CANC DATE', fieldName: 'cancelledDate' },
      {name: 'BY', fieldName: 'cancelledBy' },
      {name: 'REASION', fieldName: 'reason' },
     // {name: 'paidAmount', fieldName: 'paidAmount' },
      
    ];
  }

  generatePDF() {
   
    var pdffromdate=this.formatDate(this.fromDate);
    var pdftodate=this.formatDate(this.toDate);
    this.headerList = [];
    this.fieldList = [];
    
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    //debugger
    for (let i = 0; i < this.paymentCancelledReportFilteredList.length; i++) {
      this.paymentCancelledReportFilteredList[i]["index"] = i + 1;
    }
    const widthList = [];
    for (const item of this.headerList) {
        widthList.push('auto');
    }
    //console.log("widthList", widthList);
    this.defaultArray = [];

    for(var i = 0 ; i < this.paymentCancelledReportFilteredList.length ; i++)
    {
     
      let data ={
        "index" : i+1,
        "date" : this.paymentCancelledReportFilteredList[i].date == undefined ? "":this.paymentCancelledReportFilteredList[i].date,
        "receiptNo" : this.paymentCancelledReportFilteredList[i].receiptNo == undefined ?"": this.paymentCancelledReportFilteredList[i].receiptNo ,
        "admissionNo" :this.paymentCancelledReportFilteredList[i].admissionNo == undefined ? "" : this.paymentCancelledReportFilteredList[i].admissionNo,
        "studentName" : this.paymentCancelledReportFilteredList[i].studentName == undefined ? "" :  this.paymentCancelledReportFilteredList[i].studentName,
        "class" : this.paymentCancelledReportFilteredList[i].class == undefined ? "" : this.paymentCancelledReportFilteredList[i].class,
        "batch" : this.paymentCancelledReportFilteredList[i].batch == undefined ? "":this.paymentCancelledReportFilteredList[i].batch,
        "rollNo" : this.paymentCancelledReportFilteredList[i].rollNo == undefined ? "" : this.paymentCancelledReportFilteredList[i].rollNo,
        "payAmount" : this.paymentCancelledReportFilteredList[i].payAmount == undefined ? "" : this.paymentCancelledReportFilteredList[i].payAmount,
        "payMode" : this.paymentCancelledReportFilteredList[i].payMode == undefined ? "" : this.paymentCancelledReportFilteredList[i].payMode,
        "cancelledDate" : this.paymentCancelledReportFilteredList[i].cancelledDate == undefined ? "" : this.paymentCancelledReportFilteredList[i].cancelledDate,
        "cancelledBy" : this.paymentCancelledReportFilteredList[i].cancelledBy == undefined ? "" : this.paymentCancelledReportFilteredList[i].cancelledBy,
        "reason" : this.paymentCancelledReportFilteredList[i].reason== undefined ? "" : this.paymentCancelledReportFilteredList[i].reason,
        //"paidAmount" : this.paymentCancelledReportFilteredList[i].amount == undefined ? "" : this.paymentCancelledReportFilteredList[i].amount,
        
      }
      this.defaultArray.push(data);
    }
    console.log("defaultarray", this.defaultArray);
    let data1 ={
      "index" : "",
      "date" : "",
      "receiptNo" : "" ,
      "admissionNo" :"",
      "studentName" : "",
      "class" : "",
      "batch" : "",
      "rollNo" : "",
      "payAmount" :"",
      "payMode" :"",
      "cancelledDate" :"",
      "cancelledBy" : "",
      "reason" : "",
        
        //"paidStatus" : "",
        //"rollNo" : "Total Amount",
        //"paidAmount" :this.totalTransactionAmount,
    }
    
    this.defaultArray.push(data1);
    const docDefinition = {
      pageSize: 'A4',
      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      // pageMargins: [ 40, 60, 40, 60 ],
      content: [
        {
            table: {
                widths: ['auto', '*'],
                body: [
                    [ {
                        width: 100,
                        height: 100,
                        image: 'imageKey'
                    }, {
                        width: '*',
                        alignment: 'center',
                        stack: [
                            {
                                style: 'h1',
                                text: this.institution.institutionName
                            },
                            {
                              style: 'h2',
                              text: this.institution.address
                            },
                            {
                                style: 'h2',
                                text: this.currentAcademicYearName
                            },
                            {
                                style: 'h3',
                                text: 'CANCLLED PAYEMENT REPORT'
                            },
                            /*{
                              style: 'h3',
                              text: pdffromdate +'   to   '+  pdftodate
                            }*/
                        ]
                    }
                    ]
                ]
            },
            layout: {
                hLineWidth: () =>  0,
                vLineWidth: () => 0,
                paddingBottom: () => 5,
                paddingLeft:()=> 5,
                paddingRight:()=> 5
            },
          },
          {
            style:'sectionHeader',
            table: {
              headerRows: 1,
              widths: widthList,
              body: [
                this.headerList,
                ...this.defaultArray.map((p) => {
                  const temp = [];
                  for (const y of this.fieldList) {
                    if (y === 'cancelledDate') {
                     debugger;
                      if(p[y]=="" || p[y]==undefined){
                        temp.push("");
                      }else{
                        temp.push(this.formatDate(p[y],true));
                      }
                    } else {
                      if(y === 'date')
                      {
                        if(p[y]=="" || p[y]==undefined){
                          temp.push("");
                        }else{
                          temp.push(this.formatDate(p[y],true));
                        }
                      }
                      else{
                        temp.push(p[y]);
                      }

                       
                    }
                  }
                  console.log("temp",temp);
                  return temp;
                }),
                
                // {
                //   width: '*',
                //   alignment: 'center',
                //   stack: [
                //       {
                //           style: 'h1',
                //           text: "Total Paid Amount :" + this.totalPaidAmount
                //       }
                //     ]
                //   }
              ]
            }
          },
          // { text: 'Total Paid Amount: '+ this.totalPaidAmount, alignment: 'center',fontSize: 14 },
      ],
      images: {
         imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
         // via URL address, which can be referenced by this key
      },
      styles: {
        
        sectionHeader: {
          // bold: true,
          // decoration: 'underline',
          fontSize: 8,
          bold:true,
          margin: [0, 10, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 13,
          bold: true,
          // fontWeight:800
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        },
       
      }
    };
    pdfMake.createPdf(docDefinition).download('CANCELLED REPORT.pdf');
  }
  generateExcel() {
    this.headerList =[];
    this.fieldList =[];
    this.attributes.forEach(x => {
      this.headerList.push(x.name);
      this.fieldList.push(x.fieldName);
    });
    this.defaultArray = [];
    for(var i = 0 ; i < this.paymentCancelledReportFilteredList.length ; i++)
    {
      let data ={
        "index" : i+1,
        "date" : this.paymentCancelledReportFilteredList[i].date == undefined ? "":this.paymentCancelledReportFilteredList[i].date,
        "receiptNo" : this.paymentCancelledReportFilteredList[i].receiptNo == undefined ?"": this.paymentCancelledReportFilteredList[i].receiptNo ,
        "admissionNo" :this.paymentCancelledReportFilteredList[i].admissionNo == undefined ? "" : this.paymentCancelledReportFilteredList[i].admissionNo,
        "studentName" : this.paymentCancelledReportFilteredList[i].studentName == undefined ? "" :  this.paymentCancelledReportFilteredList[i].studentName,
        "class" : this.paymentCancelledReportFilteredList[i].class == undefined ? "" : this.paymentCancelledReportFilteredList[i].class,
        "batch" : this.paymentCancelledReportFilteredList[i].batch == undefined ? "":this.paymentCancelledReportFilteredList[i].batch,
        "rollNo" : this.paymentCancelledReportFilteredList[i].rollNo == undefined ? "" : this.paymentCancelledReportFilteredList[i].rollNo,
        "payAmount" : this.paymentCancelledReportFilteredList[i].payAmount == undefined ? "" : this.paymentCancelledReportFilteredList[i].payAmount,
        "payMode" : this.paymentCancelledReportFilteredList[i].payMode == undefined ? "" : this.paymentCancelledReportFilteredList[i].payMode,
        "cancelledDate" : this.paymentCancelledReportFilteredList[i].cancelledDate == undefined ? "" : this.paymentCancelledReportFilteredList[i].cancelledDate,
        "cancelledBy" : this.paymentCancelledReportFilteredList[i].cancelledBy == undefined ? "" : this.paymentCancelledReportFilteredList[i].cancelledBy,
        "reason" : this.paymentCancelledReportFilteredList[i].reason== undefined ? "" : this.paymentCancelledReportFilteredList[i].reason,
        //"paidAmount" : this.paymentCancelledReportFilteredList[i].amount == undefined ? "" : this.paymentCancelledReportFilteredList[i].amount,
        
      }
      this.defaultArray.push(data);
    }
    let data1 ={
      "index" : "",
      "date" : "",
      "receiptNo" : "" ,
      "admissionNo" :"",
      "studentName" : "",
      "class" : "",
      "batch" : "",
      "rollNo" : "",
      "payAmount" :"",
      "payMode" :"",
      "cancelledDate" :"",
      "cancelledBy" : "",
      "reason" : "",
        
        //"paidStatus" : "",
        //"rollNo" : "Total Amount",
        //"paidAmount" :this.totalTransactionAmount,
    }
    this.defaultArray.push(data1);
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('CANCLLED PAYEMENT SUMMARY');
    worksheet.addRow();

    const titleRow = worksheet.addRow(['CANCLLED PAYEMENT SUMMARY']);
    const headerRow = worksheet.addRow(this.headerList);
    for(var i = 0 ; i < this.defaultArray.length ; i++)
    this.defaultArray[i]["index"] = i+1;
    for (const x1 of this.defaultArray) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of this.fieldList) {
        if (y === 'cancelledDate') {
         debugger;
          if(x1[y]=="" || x1[y]==undefined){
            temp.push("");
          }else{
            temp.push(this.formatDate(x1[y],true));
          }
        } else {
          if(y === 'date')
          {
            if(x1[y]=="" || x1[y]==undefined){
              temp.push("");
            }else{
              temp.push(this.formatDate(x1[y],true));
            }
          }
          else{
            temp.push(x1[y]);
          }
          //temp.push(x1[y]);
           
        }
        
      }
      worksheet.addRow(temp);
    }
    const fname = 'CANCLLED PAYEMENT SUMMARY';
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });

  }


  currentAcademicYear() {
    this.settingsService.getCurrentAcademicYear().subscribe((data: Academicyear) => {

      this.currentAcademicYearName = data.name;
     
    });
  }


  ngOnInit(): void {
   this.generateAttributes();
   this.currentAcademicYear();
    this.classId='0';
    this.batch='0';
    this.getBatchAll();
    this.getClassCourseAll();
  this.getPaymentCancelledReport();
  this.institution = JSON.parse(localStorage.getItem('Institution'));
  //this.setCurrentDate();
  //(<any>$('#fromDate') ).datepicker();
  const self = this;

    (<any>$('#fromDate')).datepicker().on('changeDate', (e) => {
      self.fromDate = e.date;
    });

    (<any>$('#toDate')).datepicker().on('changeDate', (e) => {
      self.toDate = e.date;
    });

}

}
