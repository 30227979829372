import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErpsettingsComponent } from './erpsettings/erpsettings.component';
import { AdminComponent } from './admin/admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InstitutionGuard } from '../authentication/guards/institution.guard';
import { AnalyticalreportingsComponent } from './dataandreports/analyticalreportings/analyticalreportings.component';
import { LoginComponent } from '../authentication/login/login.component';
import { EdumaqComponent } from '../edumaq/edumaq/edumaq.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RandomGuard } from '../authentication/guards/random.guard';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { WelcomeComponent } from './welcome/welcome.component';






const routes: Routes = [
  {
    path: ':instcode/admin', component: AdminComponent,
    canActivate: [InstitutionGuard],
    canLoad: [InstitutionGuard],
    canActivateChild: [InstitutionGuard],
    data: {
      role: 'Admin',
    },
    children: [
      {
        path: '', component: DashboardComponent, canActivate: [InstitutionGuard],
        data: { roles: ['Admin'] }
      },
      {
        path: 'dashboard', component: DashboardComponent, canActivate: [InstitutionGuard],
        data: { roles: ['Admin'] }
      },
      {
        path: 'userprofile', component: UserprofileComponent, canActivate: [InstitutionGuard],
        data: { roles: ['Admin'] }
      },
      {
        path: 'setting', component: ErpsettingsComponent, canActivate: [InstitutionGuard],
        data: { roles: ['Admin'] }
      },
      {
        path: 'academic', loadChildren: () => import('./academic/academic.module').then(m => m.AcademicModule),
        canActivate: [InstitutionGuard],
        data: { roles: ['Admin'] }
      },
      { path: 'frontoffice', loadChildren: () => import('./frontoffice/frontoffice.module').then(m => m.FrontofficeModule) },
      { path: 'communication', loadChildren: () => import('./communication/communication.module').then(m => m.CommunicationModule) },
      //{ path: 'analyticalreportings', loadChildren: () => import('./dataandreports/analyticalreportings.module').then(m => m.AnalyticalreportingsModule) },
      { path: 'analyticalreportings', component:AnalyticalreportingsComponent },
      { path: 'operation', loadChildren: () => import('./OperationActivity/OperationActivity.module').then(m => m.OperationActivityModule) },
      { path: 'administration', loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule) },
      { path: 'LMS', loadChildren: () => import('./LMS/lmsmodule.module').then(m => m.LMSModuleModule) },
      {
        path: '**', component: NotFoundComponent,
      }

    ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', component: DashboardComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'frontoffice', loadChildren: () => import('./frontoffice/frontoffice.module').then(m => m.FrontofficeModule) },

      //{ path: 'edumaq', component: LoginComponent },
      { path: 'academic', loadChildren: () => import('./academic/academic.module').then(m => m.AcademicModule) },
      { path: 'analyticalreportings', component:AnalyticalreportingsComponent },
      { path: 'operation', loadChildren: () => import('./OperationActivity/OperationActivity.module').then(m => m.OperationActivityModule) },
      { path: 'administration', loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule) },
      { path: 'LMS', loadChildren: () => import('./LMS/lmsmodule.module').then(m => m.LMSModuleModule) },
      {
        path: '**', component: NotFoundComponent,
      }
    ]
  },
  /*{
    path: ':instcode/edumaq',component:LoginComponent,
  canActivate: [RandomGuard],
    canLoad: [RandomGuard],
  },*/
  /*{
    path: '**', component: NotFoundComponent,
  }*/



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
