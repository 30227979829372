// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal.modal-fullscreen .modal-dialog {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    max-width: none; 
  }
  
  .modal.modal-fullscreen .modal-content {
    height: auto;
    height: 100vh;
    border-radius: 0;
    border: none; 
  }
  
  .modal.modal-fullscreen .modal-body {
    overflow-y: auto; 
  }

  .modal-header .close {
    display: none;
  }

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px; /* adjust the height as needed */
  }`, "",{"version":3,"sources":["webpack://./src/app/admin/welcome/welcome.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,SAAS;IACT,UAAU;IACV,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa,EAAE,gCAAgC;EACjD","sourcesContent":[".modal.modal-fullscreen .modal-dialog {\r\n    width: 100vw;\r\n    height: 100vh;\r\n    margin: 0;\r\n    padding: 0;\r\n    max-width: none; \r\n  }\r\n  \r\n  .modal.modal-fullscreen .modal-content {\r\n    height: auto;\r\n    height: 100vh;\r\n    border-radius: 0;\r\n    border: none; \r\n  }\r\n  \r\n  .modal.modal-fullscreen .modal-body {\r\n    overflow-y: auto; \r\n  }\r\n\r\n  .modal-header .close {\r\n    display: none;\r\n  }\r\n\r\n  .modal-body {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 200px; /* adjust the height as needed */\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
