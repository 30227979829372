import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AssignedspecialfeereportComponent} from './assignedspecialfeereport/assignedspecialfeereport.component'
import { FeecollectionreportComponent } from './feecollectionreport/feecollectionreport.component';
import {ChequestatusComponent} from './chequestatus/chequestatus.component';
import {FeeconcessionreportComponent} from './feeconcessionreport/feeconcessionreport.component';
import {PaymentcancelledreportComponent} from './paymentcancelledreport/paymentcancelledreport.component';
import {OutstandingduereportComponent} from './outstandingduereport/outstandingduereport.component';
import {FeewaveoffreportComponent} from './feewaveoffreport/feewaveoffreport.component';
import {CancelledfeereceiptComponent} from './cancelledfeereceipt/cancelledfeereceipt.component';
import {ClasswisefeesummaryComponent} from './classwisefeesummary/classwisefeesummary.component';
import {ConsolidatedreportComponent} from './consolidatedreport/consolidatedreport.component';
import {DownloadfeesummaryComponent} from './downloadfeesummary/downloadfeesummary.component';
import {FeeheadwisecollectionreportComponent} from './feeheadwisecollectionreport/feeheadwisecollectionreport.component';
import {InstallmentwisesummaryComponent} from './installmentwisesummary/installmentwisesummary.component';
import {StudentinstallmentsummaryComponent} from './studentinstallmentsummary/studentinstallmentsummary.component';
import {StudentpaymentreportComponent} from './studentpaymentreport/studentpaymentreport.component';
import {FeereceiptComponent} from './feereceipt/feereceipt.component';
import { FeerefundreportComponent} from './feerefundreport/feerefundreport.component';
import {FeesummaryheadwiseComponent} from './feesummaryheadwise/feesummaryheadwise.component';
import { from } from 'rxjs';
import { InstallmentsummaryComponent } from './installmentsummary/installmentsummary.component';
import { FeetransactionreportComponent } from './feetransactionreport/feetransactionreport.component';
import { FeecollecctionprintComponent } from '../feecollecctionprint/feecollecctionprint.component';
import { OutstandingprintComponent } from './outstandingprint/outstandingprint.component';
import { OutstandingduesummaryComponent } from './outstandingduesummary/outstandingduesummary.component';
import { OutstandingduereportnewComponent } from './outstandingduereportnew/outstandingduereportnew.component';
 
const routes: Routes = [
  {path: 'assignedspecialfee', component: AssignedspecialfeereportComponent},
  {path: 'feecollection', component: FeecollectionreportComponent},
  {path: 'chequestatus', component: ChequestatusComponent},
  {path: 'feeconcession', component: FeeconcessionreportComponent},
  {path: 'paymentcancelled', component: PaymentcancelledreportComponent},
  {path: 'outstandingdue', component: OutstandingduereportComponent},
  {path: 'outstandingduenew', component: OutstandingduereportnewComponent},
  {path: 'feewaveoff', component: FeewaveoffreportComponent},
  {path: 'cancelledfeereceipt', component: CancelledfeereceiptComponent},
  {path: 'classwisefee', component: ClasswisefeesummaryComponent},
  {path: 'consolidated', component: ConsolidatedreportComponent},
  {path: 'downloadfeesummary', component: DownloadfeesummaryComponent},
  {path: 'feeheadwisecollection', component: FeeheadwisecollectionreportComponent},
  {path: 'installmentwisesummary', component: InstallmentwisesummaryComponent},
  {path: 'outstandingduesummary', component: OutstandingduesummaryComponent},
 
  {path: 'studentpaymentreport', component: StudentpaymentreportComponent},
  {path: 'feereceipt/:id', component: FeereceiptComponent},
  {path: 'feereceiptprint/:dates', component: FeecollecctionprintComponent},
  {path: 'outstandingprint/:details',component:OutstandingprintComponent},
  {path: 'feerefund', component: FeerefundreportComponent},
  {path: 'feesummaryheadwise', component: FeesummaryheadwiseComponent},
  {path:'installmentsummary',component:InstallmentsummaryComponent},
  {path: 'studentinstallmentsummary', component: StudentinstallmentsummaryComponent},
  {path:'feetransactionreport',component:FeetransactionreportComponent},
   
 // {path:'studentinstallmentsummary',component:StudentinstallmentsummaryComponent}
 ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeeManagementReportsRoutingModule { }
