// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-control {
    margin-top: 0px !important;
}


.checkbox-label{
    margin-left: 35px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/erpsettings/erpsettings.component.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;;AAGA;IACI,iBAAiB;AACrB","sourcesContent":[".custom-control {\r\n    margin-top: 0px !important;\r\n}\r\n\r\n\r\n.checkbox-label{\r\n    margin-left: 35px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
