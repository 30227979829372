import { Component, OnInit } from '@angular/core';
import { Institution, InstitutionDetail, InstitutionBranch } from '../../institutionmanagement/models/institution.model';
import { InstitutionService } from '../../institutionmanagement/newinstitution/institution.service';
import { Country} from '../../institutionmanagement/models/country';
import { State} from '../../institutionmanagement/models/state';
import { City} from '../../institutionmanagement/models/city';
declare var $: any;

@Component({
  selector: 'app-institutionstatistics',
  templateUrl: './institutionstatistics.component.html',
  styleUrls: ['./institutionstatistics.component.css']
})
export class InstitutionstatisticsComponent implements OnInit {

  institutions: Institution[];
  filteredInstitutions: Institution[];
  institutionBranchess: InstitutionBranch[];
  institution: Institution = {} as Institution;
  institutionDetail: InstitutionDetail = {} as InstitutionDetail;
  showBranch: boolean[] = [];

  countries: Country[] = [];
  states: State[] = [];
  cities: City[] = [];
  countryId = 'Country';
  stateId = 'State';
  cityId = 'City';

  constructor(
    public institutionService: InstitutionService
  ) { }


  ListInstitutions() {
    this.institutionService.getAllInstitutionList().subscribe((data: Institution[]) => {
      console.log(data);
      this.institutions = data;
      this.institutions.forEach((x) => {
        this.institutionService.getAllBranches(x.id).subscribe((branch: InstitutionBranch[]) => {
           x.institutionBranch = branch;
           this.showBranch.push(false);
        });
      });
      this.filteredInstitutions = this.institutions;
    });
  }

  ListCountry() {
    this.institutionService.getAllCountry().subscribe((data: Country[]) => {
      console.log(data);
      this.countries = data;
    });
  }

  onCountrySelected(value: string) {
    if (value !== 'Country') {
      this.institutionService.getAllState().subscribe((data: State[]) => {
        this.states = data.filter((state: State) => state.countryId.toString() === value.toString());
      });
    }
  }

  onStateSelected(value: string) {
    console.log(value);
    if (value !== 'State') {
      this.institutionService.getAllCity().subscribe((data: City[]) => {
        this.cities = data.filter((city: City) => city.stateId.toString() ===  value.toString());
      });
    }
  }

  onCitySelected(value: string) {
    console.log(value);
    this.filteredInstitutions = this.institutions;
    if (value !== 'City') {
      this.filteredInstitutions = this.filteredInstitutions.filter((x) => x.cityId.toString() === value);
    }
  }

  onInstitutionChange(id: any) {
    this.filteredInstitutions = this.institutions;
    this.filteredInstitutions = this.filteredInstitutions.filter((x) => x.id === id);
  }

  search(value: string) {
    this.filteredInstitutions = this.institutions;
    if (value !== '') {
      this.filteredInstitutions = this.filteredInstitutions.filter((x) => x.institutionCode.toLowerCase()
      .includes(value.toLowerCase()));
    }
  }


  viewInstitutionDetail(id: number) {

    this.institutionService.getById(id).subscribe((data: InstitutionDetail) => {
      console.log(data);
      this.institutionDetail = data;
    });
  }


  ngOnInit(): void {
    const self = this;
    $('.select2').select2();
    $('.select2').on('change', function() {
      self.onInstitutionChange(Number($(this).val()));
    });
    this.ListCountry();
    this.ListInstitutions();
  }



}
