import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiConfigurationService } from './apiconfiguration.service';
import { ApiConfigurationModel, ProviderServiceModel } from './apiconfiguration.model';
import { AddonService } from '../addonservice/addonservice.service';
import { AddonserviceModel } from '../addonservice/addonservice.model';
import { AddServiceModel } from '../addonservice/addServiceModel';
@Component({
  selector: 'app-apiconfiguration',
  templateUrl: './apiconfiguration.component.html',
  styleUrls: ['./apiconfiguration.component.css']
})
export class ApiconfigurationComponent implements OnInit {
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  apiconfigurationForm: UntypedFormGroup;
  apiproviderServiceForm: UntypedFormGroup;
  apiServiceForm:UntypedFormGroup;
  apiconfigurationService: ApiConfigurationModel[] = [];
  apiProviderServiceModelList: ProviderServiceModel[] = [];
  apiconfigurationFilteredList: ApiConfigurationModel[] = [];
  apiconfigurationProviderList: ProviderServiceModel[] = [];
  addonserviceFilteredList: AddonserviceModel[] = [];
  addserviceList:AddServiceModel[]=[];
  imageSrc: string;
  submitted = false;
  servicesubmitted = false;
  detailsShow = false;
  providerId = 0;
  providerUserId: string;
  providerPassword: string;
  providerAccessId: string;
  providerAccessToken: string;
  updateProviderService: number;
  serviceId=0;
  serviceName:string;
  constructor(
    public apiConfigurationService: ApiConfigurationService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public addonService: AddonService
  ) { }
  getProviderList() {
    this.apiConfigurationService.getAll().subscribe((data: ApiConfigurationModel[]) => {
      this.apiconfigurationFilteredList = this.apiconfigurationService = data;
    });
  }
  /*getServiceList()
  {
    this.apiConfigurationService.getAllService(this.providerId).subscribe((data: ProviderServiceModel[]) => {
    });
  }*/
  getServiceListProviderId(id) {
    this.apiConfigurationService.GetApiConfigProviderServices(id).subscribe((data: ProviderServiceModel[]) => {
      this.apiconfigurationProviderList = data;
    });
  }
  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === '') {

    } else {
      this.apiconfigurationFilteredList = this.apiconfigurationService
        .filter((addonService: ApiConfigurationModel) => ApiConfigurationService.name.toLocaleLowerCase()
          .includes(keyword.toLocaleLowerCase()));
    }
  }
  get totalRows(): number {
    return this.apiconfigurationFilteredList.length;
  }

  get f() { return this.apiconfigurationForm.controls; }
  resetForm() {
    this.submitted = false;
    this.servicesubmitted = false;
    this.apiconfigurationForm = this.fb.group({
      id: [0],
      provider: ['', Validators.required],
      displayName: ['', Validators.required],
      gatewayUrl: ['', Validators.required],
      userId: ['', Validators.required],
      password: ['', Validators.required],
      accessId: [''],
      status: ['true', Validators.required],
      accessToken: ['', Validators.required]
    });
  }
  submitForm() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.apiconfigurationForm.invalid) {
      return;
    }

    if (this.apiconfigurationForm.get('id').value === 0) {
      console.log(this.apiconfigurationForm.value);
      this.apiConfigurationService.create(this.apiconfigurationForm.value).subscribe(res => {
        console.log('Api configuration  created!');
        this.getProviderList();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    } else {
      this.apiConfigurationService.update(this.apiconfigurationForm.get('id').value, this.apiconfigurationForm.value).subscribe(res => {
        console.log('Service updated!');
        this.getProviderList();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.submitted = false;
        this.resetForm();
      });
    }
  }
  updateForm(id: number) {
    this.providerId = id;
    this.detailsShow = false;
    this.apiConfigurationService.getById(id).subscribe((data: ApiConfigurationModel) => {
      console.log(data);
      this.apiconfigurationForm = this.fb.group({
        id: [id],
        provider: [data.provider, Validators.required],
        displayName: [data.displayName, Validators.required],
        gatewayUrl: [data.gatewayUrl, Validators.required],
        userId: [data.userId, Validators.required],
        password: [data.password, Validators.required],
        accessId: [data.accessId, Validators.required],
        status: [data.status, Validators.required],
        accessToken: [data.accessToken, Validators.required]
      });
      this.details(id);
    });

  }
  details(id: number) {
    this.providerId = id;
    this.detailsShow = !this.detailsShow;
    this.apiConfigurationService.getById(id).subscribe((data: ApiConfigurationModel) => {
      this.providerUserId = data.userId;
      this.providerPassword = data.password;
      this.providerAccessId = data.accessId;
      this.providerAccessToken = data.accessToken;
    });
    this.getServiceListProviderId(id);
  }
  deleteForm(id: number) {
    this.detailsShow = false;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.apiConfigurationService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.getProviderList();
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }
  addService(providerId: number) {
    this.providerId = providerId;
    this.addonService.getAll().subscribe((data: AddonserviceModel[]) => {
      this.addonserviceFilteredList = data;
    });
   
    this.apiproviderServiceForm = this.fb.group({
      id: [0],
      providerId: [this.providerId],
      serviceId: ['', Validators.required],
      apiUrl: ['', Validators.required],
      apiUrlParameters: [''],
      deliveryApiUrl: [''],
      deliveryApiUrlParameters: [''],
      balanceApiUrl: [''],
      balanceApiUrlParameters: [''],
    });
   
  }
  saveServices() {
    const self = this;
    this.apiproviderServiceForm.get('providerId').patchValue(this.providerId);
    this.servicesubmitted = true;
    // stop here if form is invalid
    if (this.apiproviderServiceForm.invalid) {
      return;
    }
    if (this.apiproviderServiceForm.get('id').value === 0) {
      this.apiConfigurationService.saveServices(this.apiproviderServiceForm.value).subscribe((data: ProviderServiceModel) => {
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
        this.getServiceListProviderId(self.providerId);
        this.apiproviderServiceForm = this.fb.group({
          id: [0],
          providerId: ['', Validators.required],
          serviceId: ['', Validators.required],
          apiUrl: ['', Validators.required],
          apiUrlParameters: ['', Validators.required],
          deliveryApiUrl: [''],
          deliveryApiUrlParameters: [''],
          balanceApiUrl: [''],
          balanceApiUrlParameters: [''],
        });
        this.servicesubmitted = false;
      });
    } else {
      this.apiConfigurationService.updateService(this.apiproviderServiceForm.get('id').value,
       this.apiproviderServiceForm.value).subscribe(res => {
        console.log('Service updated!');
        this.getProviderList();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
        this.servicesubmitted = false;
        // this.getServiceListProviderId(self.providerId)
      });
      this.apiproviderServiceForm = this.fb.group({
        id: [0],
        providerId: ['', Validators.required],
        serviceId: ['', Validators.required],
        apiUrl: ['', Validators.required],
        apiUrlParameters: ['', Validators.required],
        deliveryApiUrl: [''],
        deliveryApiUrlParameters: [''],
        balanceApiUrl: [''],
        balanceApiUrlParameters: [''],
      });

    }


  }
  editService(serviceId: number) {
    this.addonService.getAll().subscribe((data: AddonserviceModel[]) => {
      this.addonserviceFilteredList = data;
    });
    this.apiConfigurationService.GetProvederServiceById(serviceId).subscribe((data: ProviderServiceModel) => {
      this.updateProviderService = this.providerId;
      this.apiproviderServiceForm = this.fb.group({
        id: [data.providerServiceId],
        providerId: [data.providerId],
        serviceId: [data.serviceId, Validators.required],
        apiUrl: [data.apiUrl, Validators.required],
      });

    });
  }

  deleteService(serviceId: number) {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.apiConfigurationService.deleteService(serviceId).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.getServiceListProviderId(self.providerId);
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }
  ngOnInit(): void {
    this.getProviderList();
    this.apiconfigurationForm = this.fb.group({
      id: [0],
      provider: ['', Validators.required],
      displayName: ['', Validators.required],
      gatewayUrl: ['', Validators.required],
      userId: ['', Validators.required],
      password: ['', Validators.required],
      accessId: [''],
      status: ['true', Validators.required],
      accessToken: ['', Validators.required]
    });
    this.apiproviderServiceForm = this.fb.group({
      id: [0],
      providerId: ['', Validators.required],
      serviceId: ['', Validators.required],
      apiUrl: ['', Validators.required],
      apiUrlParameters: ['', Validators.required],
      deliveryApiUrl: [''],
      deliveryApiUrlParameters: [''],
      balanceApiUrl: [''],
      balanceApiUrlParameters: [''],
    });
    this.apiServiceForm = this.fb.group({
      id: [0],
      serviceId: [this.serviceId],
      serviceName:[this.serviceName],
      apiName: ['', Validators.required],
      apiUrl: ['', Validators.required],
      apiTypeIdConfig: ['0'],
      apiTypeNameConfig:[''],
      apiStatus: ['']
    });
  }

  getServiceAPIList(serviceId) {
    
    this.apiConfigurationService.GetServiceAPI(this.serviceId).subscribe((data: AddServiceModel[]) => {
      this.addserviceList  = data;
      console.log(data);
    });
    //this.addserviceList=[];
  }


  addServicesAPI(serviceId: number,serviceName:string){
    this.serviceId = serviceId;
    this.serviceName = serviceName;
    this.apiServiceForm = this.fb.group({
      id: [0],
      serviceId: [this.serviceId],
      serviceName:[this.serviceName],
      apiName: ['', Validators.required],
      apiUrl: ['', Validators.required],
      apiTypeIdConfig: ['0'],
      apiTypeNameConfig:[''],
      apiStatus: ['0']
    });
    this.getServiceAPIList(this.serviceId);
  }
  changeSuit(e){
    let aname = e.target.options[e.target.options.selectedIndex].text;
    this.apiServiceForm.get('apiTypeNameConfig').setValue(aname,{onlySelf:true})
  }
  saveServicesApi(){
    const self = this;
    this.apiServiceForm.get('serviceId').patchValue(this.serviceId);
    this.servicesubmitted = true;
    // stop here if form is invalid
    if (this.apiServiceForm.invalid) {
      return;
    }
    console.log(this.apiServiceForm.value);
    if (this.apiServiceForm.get('id').value === 0) {
      this.apiConfigurationService.AddsaveServicesAPI(this.apiServiceForm.value).subscribe((data: AddServiceModel) => {
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
       // this.getServiceListProviderId(self.providerId);
        this.apiServiceForm = this.fb.group({
          id: [0],
          serviceId: [this.serviceId],
          serviceName:[this.serviceName],
          apiName: ['', Validators.required],
          apiUrl: ['', Validators.required],
          apiTypeIdConfig: ['0'],
          apiTypeNameConfig:[''],
          apiStatus: ['0'],
          
        });
        this.servicesubmitted = false;
        this.getServiceAPIList(this.serviceId);
      });
    } else {
      this.apiConfigurationService.updateServiceAPI(this.apiServiceForm.get('id').value,
       this.apiServiceForm.value).subscribe(res => {
        console.log('Service updated!');
        this.getProviderList();
        this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
          timeOut: 3000
        });
       // this.servicesubmitted = false;
        // this.getServiceListProviderId(self.providerId)
      });
      this.apiServiceForm = this.fb.group({
        id: [0],
        serviceId: [this.serviceId],
        serviceName:[this.serviceName],
        apiName: ['', Validators.required],
        apiUrl: ['', Validators.required],
        apiTypeIdConfig: ['0'],
        apiTypeNameConfig:[''],
        apiStatus: ['0'],
        
      });
      this.servicesubmitted = false;
      this.getServiceAPIList(this.serviceId);
  }
}

editServiceAPI(serviceId: number) {
  // this.addonService.getAll().subscribe((data: AddonserviceModel[]) => {
  //   this.addonserviceFilteredList = data;
  // });
  this.apiConfigurationService.GetServiceAPIById(serviceId).subscribe((data: AddServiceModel) => {
   // this.updateProviderService = this.providerId;
   console.log(data);
    this.apiServiceForm = this.fb.group({
      id: [data.id],
      //providerId: [data.providerId],
      serviceId: [data.serviceId, Validators.required],
      serviceName:[data.serviceName],
      apiTypeNameConfig:[data.apiTypeNameConfig],
      apiUrl: [data.apiUrl, Validators.required],
      apiName:[data.apiName,Validators.required],
      apiTypeIdConfig:[data.apiTypeIdConfig],
      apiStatus: [data.apiStatus],
    });

  });
}
  deleteServiceAPI(serviceId:number){
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.apiConfigurationService.deleteServiceAPI(serviceId).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.getServiceAPIList(this.serviceId);
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        );
      }
    });
  }
}

