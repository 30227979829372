import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FeeHead } from './feehead.model';
import { FeeHeadService } from './feehead.service';

@Component({
  selector: 'app-feehead',
  templateUrl: './feehead.component.html',
  styleUrls: ['./feehead.component.css']
})
export class FeeheadComponent implements OnInit {

  feeHeadForm: UntypedFormGroup;
  feeHeads: FeeHead[] = [];
  feeHeadFilteredList: FeeHead[] = [];
  searchFilteredList: FeeHead[] = [];
  submitted = false;
  HighlightRow : Number;
  ClickedRow:any;

  constructor(
    public feeHeadService: FeeHeadService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  )  

{ 
    this.ClickedRow = function(index){
      this.HighlightRow = index;
  }

  }

  ListFeeHead() {
    this.feeHeadService.getAll().subscribe((data: FeeHead[]) => {
      console.log(data);
      this.feeHeadFilteredList = this.feeHeads = data;
    });
  }

  onFeeTypeChanged(value: any) {
    if (value === 'All') {
     this.searchFilteredList = this.feeHeadFilteredList = this.feeHeads;
    } else {
      this.searchFilteredList = this.feeHeadFilteredList = this.feeHeads.filter((feehead: FeeHead) => {
        return feehead.feeType.toLocaleLowerCase() === value.toLocaleLowerCase();
      });
    }
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (this.searchFilteredList.length > 0) {
      if (keyword === '') {
        this.searchFilteredList = this.feeHeads;
      } else {
        this.searchFilteredList = this.feeHeads
          .filter((feehead: FeeHead) => feehead.feeHeadName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase()) ||
            feehead.feeType.toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          );
      }
    } else {
      if (keyword === '') {
        this.feeHeadFilteredList = this.feeHeads;
      } else {
        this.feeHeadFilteredList = this.feeHeads
          .filter((feehead: FeeHead) => feehead.feeHeadName.toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase()) ||
            feehead.feeType.toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          );
      }
    }
  }

  get totalRows(): number {
    return this.feeHeadFilteredList.length;
  }
  get f() { return this.feeHeadForm.controls; }
  resetForm() {
    this.submitted = false;
    this.HighlightRow=-1;
    this.feeHeadForm = this.fb.group({
      id: [0],
      feeHeadName: ['', Validators.required],
      feeType: ['', Validators.required],
      isRefundable: [false],
      description: [''],
      status: ['true', Validators.required]
    });
  }
  submitForm() {
    this.submitted = true;
    console.log(this.feeHeadForm.value);
        // stop here if form is invalid
    if (this.feeHeadForm.invalid) {
            return;
      }

    if (this.feeHeadForm.get('id').value === 0) {
        this.feeHeadService.create(this.feeHeadForm.value).subscribe(res => {
          console.log('Fee head created!');
          this.ListFeeHead();
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
        });
        } else {
          this.feeHeadService.update(this.feeHeadForm.get('id').value, this.feeHeadForm.value).subscribe(res => {
            console.log('Fee head updated!');
            this.ListFeeHead();
            this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
              timeOut: 3000
            });
            this.submitted = false;
            this.resetForm();
          });
        }
      }

      updateForm(id: number) {
        this.feeHeadService.getById(id).subscribe((data: FeeHead) => {
          console.log(data);
          this.feeHeadForm = this.fb.group({
            id: [data.id],
            feeHeadName: [data.feeHeadName, Validators.required],
            feeType: [data.feeType, Validators.required],
            isRefundable: [data.isRefundable],
            description: [data.description.toString()],
            status: [data.status.toString(), Validators.required]
          });
        });
      }

      deleteForm(id: number) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            this.feeHeadService.delete(id).subscribe(res => {
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              );
              this.ListFeeHead();
            });
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
          }
        });
        this.HighlightRow=-1;
      }

  ngOnInit(): void {
    this.ListFeeHead();
    this.feeHeadForm = this.fb.group({
      id: [0],
      feeHeadName: ['', Validators.required],
      feeType: ['', Validators.required],
      isRefundable: [false],
      description: [''],
      status: ['true', Validators.required]
    });

  }

}
