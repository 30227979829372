import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuestionListComponent } from './question-list.component';
const routes: Routes = [
  {
    path:'QuestionList', component: QuestionListComponent
  }
];


@NgModule({
  declarations: [QuestionListComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class QuestionListModule { }
