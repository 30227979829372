import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';

import { Academicyear } from '../../../academic/mastersettings/models/academicyear';
import { ClassCourse } from '../../../academic/mastersettings/models/classcourse';
import { Batch } from '../../../academic/mastersettings/models/batch';
import { Category } from '../../../academic/mastersettings/models/category';
import { Religion } from '../../../academic/mastersettings/models/religion';

import { BatchService } from '../../../academic/mastersettings/batch/batch.service';
import { ClassCourseService } from '../../../academic/mastersettings/classcourse/classcourse.service';
import { SettingsService } from '../../../academic/mastersettings/settings.service';
import { Student, StudentSibling, StudentDocument } from '../../../academic/studentmanagement/student.model';
import { StudentService } from '../../../academic/studentmanagement/student.service';
import { CategoryService } from '../../../academic/mastersettings/category/category.service';
import { ReligionService } from '../../../academic/mastersettings/religion/religion.service';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Excel from 'exceljs/dist/exceljs.js';
import * as fs from 'file-saver';
import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable.js';

@Component({
  selector: 'app-studentreligionreport',
  templateUrl: './studentreligionreport.component.html',
  styleUrls: ['./studentreligionreport.component.css']
})
export class StudentreligionreportComponent implements OnInit {

  academicYears: Academicyear[] = [];
  classcourses: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  sortedAdmissions: Student[] = [];
  categories: Category[] = [];
  religions: Religion[] = [];

  classId = '';
  batchId = '';
  institution: any;
  academicYearId = '0';
  seqno = 1;

  constructor(
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public categoryService: CategoryService,
    public religionService: ReligionService,
    public batchService: BatchService,
    private toastr: ToastrService,
    public academicyearService: SettingsService
  ) { }

  ListAcademicYear() {
    this.academicyearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
    });
  }

  currentAcademicYear() {
    this.academicyearService.getCurrentAcademicYear().subscribe((data: Academicyear) => {
      this.academicYearId = data.id.toString();
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  ListCategories() {
    this.categoryService.getAll().subscribe((data: Category[]) => {
      this.categories = data;
    });
  }

  selectAllCatgeory(evnt: any) {
    if (evnt.target.checked) {
      this.categories.forEach(x => x.checked = true);
    } else {
      this.categories.forEach(x => x.checked = false);
    }
    this.sortAdmissions();
  }

  isAllCategoryCheckBoxChecked() {
    return this.categories.every(p => p.checked);
  }

  onCheckCategory(evnt: any, id: any) {
    if (evnt.target.checked) { // on checked
      const categoryIds: any[] = [];
      // const religionIds: any[] = [];
      this.categories.forEach(x => {
        if (x.checked) {
          categoryIds.push(x.id);
        }
      });
      // this.religions.forEach(x => {
      //   if (x.checked) {
      //     religionIds.push(x.id);
      //   }
      // });
      // if (this.religions.every(p => !p.checked)) {
      //   this.sortedAdmissions = this.admissions.filter((x) => {
      //     return x.classCourseId.toString() === this.classId.toString()
      //     && x.batchId.toString() === this.batchId.toString()
      //     && categoryIds.map(y => y).includes(x.categoryId);
      //   });
      // } else {
      //   this.sortedAdmissions = this.admissions.filter((x) => {
      //     return x.classCourseId.toString() === this.classId.toString()
      //     && x.batchId.toString() === this.batchId.toString()
      //     && categoryIds.map(y => y).includes(x.categoryId)
      //     && religionIds.map(y => y).includes(x.religionId);
      //   });
      // }
      this.sortAdmissions();
    } else {
      this.sortAdmissions();
    }
  }

  ListReligions() {
    this.religionService.getAll().subscribe((data: Religion[]) => {
      this.religions = data;
    });
  }

  selectAllReligion(evnt: any) {
    if (evnt.target.checked) {
      this.religions.forEach(x => x.checked = true);
    } else {
      this.religions.forEach(x => x.checked = false);
    }
    this.sortAdmissions();
  }

  isAllReligionCheckBoxChecked() {
    return this.religions.every(p => p.checked);
  }

  onCheckReligion(evnt: any, id: any) {
    if (evnt.target.checked) { // on checked
      const religionIds: any[] = [];
      this.religions.forEach(x => {
        if (x.checked) {
          religionIds.push(x.id);
        }
      });
      this.sortAdmissions();
      // this.sortedAdmissions = this.admissions.filter((x) => {
      //   return x.classCourseId.toString() === this.classId.toString()
      //   && x.batchId.toString() === this.batchId.toString()
      //   && religionIds.map(y => y).includes(x.religionId);
      // });
    } else {
      this.sortAdmissions();
    }
  }


  sortAdmissions() {
    debugger;
      const categoryIds: any[] = [];
      const religionIds: any[] = [];
      this.religions.forEach(x => {
        if (x.checked) {
          religionIds.push(x.id);
        }
      });
      this.categories.forEach(x => {
        if (x.checked) {
          categoryIds.push(x.id);
        }
      });

      if(this.classId == '') {
        this.sortedAdmissions = this.admissions;
      } else if (this.classId != '' && this.batchId == '') {
        this.sortedAdmissions = this.admissions.filter((x) => x.classCourseId.toString() === this.classId.toString());
      } else if (this.classId != '' && this.batchId != '') {
        this.sortedAdmissions = this.admissions.filter((x) => x.classCourseId.toString() === this.classId.toString()
        && x.batchId.toString() === this.batchId.toString());
      }

      if (religionIds.length > 0) {
        this.sortedAdmissions = this.sortedAdmissions.filter((x) => religionIds.includes(x.religionId));
      }

      if (categoryIds.length > 0) {
        this.sortedAdmissions = this.sortedAdmissions.filter((x) => categoryIds.includes(x.categoryId));
      }
      return this.sortedAdmissions;
      // if (this.religions.every(p => !p.checked) && this.categories.every(p => !p.checked)) {
      //   this.sortedAdmissions = this.admissions.filter((x) => {
      //     return x.classCourseId.toString() === this.classId.toString()
      //     && x.batchId.toString() === this.batchId.toString();
      //   });
      // } else if (this.religions.every(p => !p.checked)) {
      //   this.sortedAdmissions = this.admissions.filter((x) => {
      //     return x.classCourseId.toString() === this.classId.toString()
      //     && x.batchId.toString() === this.batchId.toString()
      //     && categoryIds.map(y => y).includes(x.categoryId);
      //   });
      // } else if (this.categories.every(p => !p.checked)) {
      //   this.sortedAdmissions = this.admissions.filter((x) => {
      //     return x.classCourseId.toString() === this.classId.toString()
      //     && x.batchId.toString() === this.batchId.toString()
      //     && religionIds.map(y => y).includes(x.religionId);
      //   });
      // } else {
      //   this.sortedAdmissions = this.admissions.filter((x) => {
      //     return x.classCourseId.toString() === this.classId.toString()
      //     && x.batchId.toString() === this.batchId.toString()
      //     && categoryIds.map(y => y).includes(x.categoryId)
      //     && religionIds.map(y => y).includes(x.religionId);
      //   });
      // }
  }

  onClassSelected(value: string) {
    debugger;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toLocaleLowerCase());
    });
    this.classId = value;
  }

  getAllBatchDetails() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      console.log(data);
      this.batches = data;
    });
  }

  ListStudents() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.admissions = data;
      console.log(data);
    });
  }

  LoadStudents() {
    // this.sortedAdmissions = this.admissions.filter((x) => {
    //   return x.classCourseId.toString() === this.classId.toString()
    //   && x.batchId.toString() === this.batchId.toString();
    // });
    return this.sortAdmissions();
  }

  generatePDF() {
    //this.sortAdmissions();
    if (this.sortedAdmissions.length === 0) {
      this.toastr.warning('No records found.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }

    this.seqno = 1;
    this.sortedAdmissions.forEach((x) => {
      x.sno = this.seqno++;
    });

    const academicYearId = this.academicYearId;
    const header = ["S.No.", "ADMSN", "NAME", "CLASS", "BATCH", "ROLL", "RELIGION", "CATEGORY", "FATHER'S NAME", "MOTHER'S NAME"];
    const fields = ["sno", "admissionNo", "studentName", "className", "batchName", "rollNo", "religionName", "categoryName", "fatherName", "motherFullName"];
    const widthList = [];
    for (const item of header) {
        widthList.push('auto');
    }
    var i = 0;
    const docDefinition = {
      pageOrientation: 'landscape',
      content: [
        {
          table: {
              widths: ['auto', '*'],
              body: [
                  [ {
                      width: 100,
                      height: 100,
                      image: 'imageKey'
                  }, {
                      width: '*',
                      alignment: 'center',
                      stack: [
                          {
                              style: 'h1',
                              text: this.institution.institutionName
                          },
                          {
                            style: 'h2',
                            text: this.institution.address
                          },
                          {
                              style: 'h2',
                              text: this.academicYears.find(x => x.id.toString() === this.academicYearId.toString()).name
                          },
                          {
                              style: 'h1',
                              text: 'STUDENT REPORT'
                          }
                      ]
                  }
                  ]
              ]
          },
          layout: {
              hLineWidth: (line) => line === 1,
              vLineWidth: () => 0,
              paddingBottom: () => 5
          },
        },
        {
          
          table: {
            headerRows: 1,
            widths: widthList,
            style:'sectionHeader',
            body: [
              header,
              ...this.sortedAdmissions.map((p) => {
                const temp = [];
                for (const y of fields) {
                   temp.push(p[y]);                   
                }
                return temp;
              })
            ]
          }
        }, 
      ],
      images: {
        imageKey: this.institution.logoUrl != null ? environment.imageUrl + this.institution.logoUrl : environment.imageUrl + '/images/avatar-1.png'
        // via URL address, which can be referenced by this key
     },
      styles: {
        sectionHeader: {
          // bold: true,
          // decoration: 'underline',
          fontSize: 5,
         margin: [0, 15, 0, 15]
        },
        h1: {
          margin: [0, 5, 0, 0],
          fontSize: 16,
          bold: true
        },
        h2: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true
        }
      }
    };
    pdfMake.createPdf(docDefinition).download('STUDENT RELIGION CATEGORY REPORT.pdf');
  }

  generateExcel() {
    if (this.sortedAdmissions.length === 0) {
      this.toastr.warning('No records found.', 'WARN!', {
        timeOut: 3000
      });
      return false;
    }

    this.seqno = 1;
    this.sortedAdmissions.forEach((x) => {
      x.sno = this.seqno++;
    });

    const academicYearId = this.academicYearId;
    const header = ["S.No.", "ADMSN", "NAME", "CLASS", "BATCH", "ROLL", "RELIGION", "CATEGORY", "FATHER'S NAME", "MOTHER'S NAME"];
    const fields = ["sno", "admissionNo", "studentName", "className", "batchName", "rollNo", "religionName", "categoryName", "fatherName", "motherFullName"];
    
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Student Data');
    const headerRow = worksheet.addRow(header);
    for (const x1 of this.sortedAdmissions) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of fields) {
        temp.push(x1[y]);
      }
      worksheet.addRow(temp);
    }
    const fname = 'Student Religion Category Report';
    // add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });
    
  }

  resetAll() {
    this.sortedAdmissions = [];
    this.classId = '';
    this.batchId = '';
    this.categories.forEach(x => x.checked = false);
    this.religions.forEach(x => x.checked = false);
  }

  get totalRows(): number {
    return this.sortedAdmissions.length;
  }

  ngOnInit(): void {
    this.ListClassCourse();
    this.ListAcademicYear();
    this.currentAcademicYear();
    this.ListCategories();
    this.ListReligions();
    this.ListStudents();
    this.institution = JSON.parse(localStorage.getItem('Institution'));
  }

}
