import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Academicyear } from '../../../academic/mastersettings/models/academicyear';
import { ClassCourse } from '../../../academic/mastersettings/models/classcourse';
import { Batch } from '../../../academic/mastersettings/models/batch';
import { certificateType, TemplateParameter, templateSetting } from '../../../administration/certificate/templatesetting/certificateType.models';
import { StudentCertificate } from '../generatecertificate/generatecertificate.models';

import { BatchService } from '../../../academic/mastersettings/batch/batch.service';
import { ClassCourseService } from '../../../academic/mastersettings/classcourse/classcourse.service';
import { StreamService } from '../../../academic/mastersettings/stream/stream.service';
import { SettingsService } from '../../../academic/mastersettings/settings.service';
import { Student, StudentSibling, StudentDocument } from '../../../academic/studentmanagement/student.model';
import { StudentService } from '../../../academic/studentmanagement/student.service';
import { TemplateSettingsService } from '../../../administration/certificate/templatesetting/template-settings.service';
import { GenerateCertificateService } from '../generatecertificate/generatecertificate.service';

import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as fs from 'file-saver';
import * as $ from 'jquery';

import {  DatePipe, formatDate } from '@angular/common';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ReportAttribute } from '../../downloads/employeereport/employeereport';

@Component({
  selector: "app-printcertificate",
  templateUrl: "./printcertificate.component.html",
  styleUrls: ["./printcertificate.component.css"],
})
export class PrintcertificateComponent implements OnInit {
  academicYears: Academicyear[] = [];
  classcourses: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: any[] = [];
  sortedAdmissions: any[] = [];
  certificateTypeList: certificateType[];
  templateSettingList: templateSetting[];
  studentCertificateList: any[] = [];
  filtertedStudentCertificateList: any[] = [];
  ctypes: certificateType[];
  studentCertificate: StudentCertificate;
  sortedAdmissionsList: any[] = [];

  classId = "";
  batchId = "";
  certificateTypeId = "";
  templateId = "";
  institution: any;
  academicYearId = "0";
  StudentCertificateId = "";
  StudentId = "";
  templateContent = "";
  divContent: SafeHtml = "";
  divContentForMultiple: SafeHtml = "";
  templateParameters: TemplateParameter[] = [];
  templateParameterFilteredList: TemplateParameter[] = [];
  scids = "";
  CertificateNumber = "";
  scIdsArray: string[] = [];
  HtmlContentArray: any[] = [];
  streams: any[] = [];

  constructor(
    public studentService: StudentService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    private toastr: ToastrService,
    public academicyearService: SettingsService,
    public templateSettingsService: TemplateSettingsService,
    public generateCertificateService: GenerateCertificateService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public streamService: StreamService
  ) {}

  ListAcademicYear() {
    this.academicyearService.getAll().subscribe((data: Academicyear[]) => {
      this.academicYears = data;
    });
  }

  ListStream() {
    this.streamService.getAll().subscribe((data: any[]) => {
      console.log(data);
      this.streams = data;
    });
  }

  currentAcademicYear() {
    this.academicyearService
      .getCurrentAcademicYear()
      .subscribe((data: Academicyear) => {
        this.academicYearId = data.id.toString();
      });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  onClassSelected(value: string) {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter(
        (batch: Batch) => batch.classId.toString() === value.toLocaleLowerCase()
      );
    });
    this.classId = value;
  }

  getAllBatchDetails() {
    this.batchService.getAll().subscribe((data: Batch[]) => {
      console.log(data);
      this.batches = data;
    });
  }

  ListCertificateTypes() {
    this.templateSettingsService
      .getAll()
      .subscribe((data: certificateType[]) => {
        this.certificateTypeList = data;
        this.ctypes = data.filter((o: any) => o.status === true);
      });
  }

  LoadStudents() {
    this.studentService
      .getAllStudentListForCertificate()
      .subscribe((data: any[]) => {
        this.admissions = data;
     
        if (this.StudentCertificateId == "-1") {
          this.scIdsArray.forEach((x) => {
            const scid = x;
            const tId = this.studentCertificateList
              .find((o) => o.id.toString() === scid.toString())
              .templateId.toString();
            const sId = this.studentCertificateList
              .find((o) => o.id.toString() === scid.toString())
              .studentId.toString();
            const tText = this.templateSettingList.find(
              (o) => o.id.toString() === tId.toString()
            ).templateText;
            const cn = this.studentCertificateList.find(
              (o) => o.id.toString() === scid.toString()
            ).certificateNo;
            const templateString = this.replaceTemplateContent(tText, sId, cn);
            //templateString.replace('{{TCNo}}', cn);
            this.divContentForMultiple = "";
            this.divContentForMultiple =
              this.sanitizer.bypassSecurityTrustHtml(templateString);
            this.HtmlContentArray.push(this.divContentForMultiple);
          });
        } else {
          const templateString = this.replaceTemplateContent(
            this.templateContent,
            this.StudentId,
            this.CertificateNumber
          );
          //templateString.replace('{{TCNo}}', this.CertificateNumber);
          // const studentdetails =  this.admissions.find(y => y.id.toString() === this.StudentId);
          // this.templateContent = this.templateContent.replace('{{StudentName}}', studentdetails.studentName);
          // this.templateContent = this.templateContent.replace('{{FatherName}}', studentdetails.fatherName);
          // this.templateContent = this.templateContent.replace('{{AdmissionDate}}', studentdetails.admissionDate);
          // this.templateContent = this.templateContent.replace('{{AdmissionNo}}', studentdetails.admissionNo);
          this.divContent =
            this.sanitizer.bypassSecurityTrustHtml(templateString);
        }
        //console.log(data);
        //this.sortAdmissions();
      });
  }

  replaceTemplateContent(tContent: string, sID: string, certificateNo: string) {
    console.log(tContent, "tcontent");
    const studentdetails = this.admissions.find((y) => y.id.toString() === sID);
    tContent = tContent.replace("{{AdmissionNo}}", studentdetails.admissionNo);
    tContent = tContent.replace(
      "{{AdmissionDate}}",
      moment(studentdetails.admissionDate).format("DD-MM-YYYY")
    );
    tContent = tContent.replace("{{StudentName}}", studentdetails.studentName);
    tContent = tContent.replace(
      "{{AcademicYearId}}",
      studentdetails.academicYearName
    );
    tContent = tContent.replace(
      "{{ClassCourseName}}",
      studentdetails.className
    );
    tContent = tContent.replace("{{BatchName}}", studentdetails.batchName);
    tContent = tContent.replace(
      "{{StreamName}}",
      this.getStreamNameById(studentdetails.streamId.toString())
    );
    tContent = tContent.replace(
      "{{DOB}}",
      moment(studentdetails.dob).format("DD-MM-YYYY")
    );
    tContent = tContent.replace("{{Gender}}", studentdetails.genderName);
    tContent = tContent.replace(
      "{{SMSMobNumber}}",
      studentdetails.smsMobNumber
    );
    tContent = tContent.replace("{{WhatsAppNo}}", studentdetails.whatsAppNo);
    tContent = tContent.replace("{{EmailId}}", studentdetails.emailId);
    tContent = tContent.replace("{{AdharNumber}}", studentdetails.adharNumber);
    tContent = tContent.replace("{{HouseName}}", studentdetails.houseName);
    tContent = tContent.replace("{{FatherName}}", studentdetails.fatherName);
    tContent = tContent.replace(
      "{{FatherEmailId}}",
      studentdetails.fatherEmailId
    );
    tContent = tContent.replace(
      "{{FatherMobileNo}}",
      studentdetails.fatherMobileNo
    );
    tContent = tContent.replace(
      "{{MotherFullName}}",
      studentdetails.motherFullName
    );
    tContent = tContent.replace("{{LoginId}}", studentdetails.loginId);
    tContent = tContent.replace("{{Password}}", studentdetails.password);
    tContent = tContent.replace("{{TCNo}}", certificateNo);
    tContent = tContent.replace(
      "{{CurrentDate}}",
      moment(new Date()).format("DD-MM-YYYY")
    );
    return tContent;
  }

  sortAdmissions() {
    this.sortedAdmissions = this.admissions;
    this.sortedAdmissions.forEach((item) => {
      const slist = this.filtertedStudentCertificateList.find(
        (y) => y.studentId === item.id
      );
      (item as any).certificateTypeId = slist ? slist.certificateTypeId : "";
      (item as any).certificateTypeName = slist
        ? this.getValueById(slist.certificateTypeId)
        : "";
      (item as any).generatedDate = slist ? slist.createdDate : "";
      (item as any).generatedBy = slist ? slist.createdBy : "";
      (item as any).templateId = slist ? slist.templateId : "";
    });
    this.sortedAdmissionsList = this.sortedAdmissions;
  }

  ListAllCertificates() {
    this.generateCertificateService
      .getAll()
      .subscribe((data: StudentCertificate[]) => {
        this.studentCertificateList = data;
        if (this.StudentCertificateId == "-1") {
        } else {
          this.templateId = this.studentCertificateList
            .find(
              (o) => o.id.toString() === this.StudentCertificateId.toString()
            )
            .templateId.toString();
          this.StudentId = this.studentCertificateList
            .find(
              (o) => o.id.toString() === this.StudentCertificateId.toString()
            )
            .studentId.toString();
          this.CertificateNumber = this.studentCertificateList
            .find(
              (o) => o.id.toString() === this.StudentCertificateId.toString()
            )
            .certificateNo.toString();
        }
      });
  }

  ListAllTemplates() {

    this.templateSettingsService
      .getAllTemplates()
      .subscribe((data: templateSetting[]) => {
        this.templateSettingList = data.filter(
          (o: any) => o.issueFor == "Student"
        );
        if (this.StudentCertificateId == "-1") {
        } else {
          const tempText = this.templateSettingList.find(
            (o) => o.id.toString() === this.templateId.toString()
          ).templateText;
          this.templateContent = tempText;
        }
        this.LoadStudents();
      });
  }

  getValueById(id: number) {
    const dataItem = this.ctypes.find((item) => item.id === id);
    return dataItem ? dataItem.certificateTypeName : "";
  }

  getStreamNameById(id: string) {
    const dataItem = this.streams.find((item) => item.id.toString() === id);
    return dataItem ? dataItem.streamName : "";
  }

  ngOnInit(): void {
    this.institution = JSON.parse(localStorage.getItem("Institution"));

    this.StudentCertificateId = this.activatedRoute.snapshot.params.id;
    if (this.StudentCertificateId == "-1") {
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params["values"]) {
          this.scids = params["values"];
        }
      });
      this.scIdsArray = this.scids.split(",").map((item) => item.trim());
    }
    this.ListStream();
    this.getTeamplateParameters();
    this.ListClassCourse();
    this.ListAcademicYear();
    this.currentAcademicYear();
    this.ListCertificateTypes();
    this.ListAllCertificates();
    this.ListAllTemplates();
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if (keyword === "") {
      this.sortedAdmissions = this.sortedAdmissionsList;
    } else {
      this.sortedAdmissions = this.sortedAdmissionsList.filter((record) => {
        return (
          record.studentName.toLowerCase().includes(keyword.toLowerCase()) ||
          record.admissionNo
            .toString()
            .toLowerCase()
            .includes(keyword.toLowerCase()) ||
          record.fatherName
            .toString()
            .toLowerCase()
            .includes(keyword.toLowerCase()) ||
          record.smsMobNumber
            .toString()
            .toLowerCase()
            .includes(keyword.toLowerCase()) ||
          record.className
            .toString()
            .toLowerCase()
            .includes(keyword.toLowerCase()) ||
          record.batchName
            .toString()
            .toLowerCase()
            .includes(keyword.toLowerCase())
        );
      });
    }
  }

  // generatePDF() {
  //   const certificateIds: any[] = [];
  //   this.sortedAdmissions.forEach(x => {
  //     if (x.checked) {
  //       certificateIds.push({tempId: x.templateId, StudentId: x.id});
  //     }
  //   });
  //   for (const item of certificateIds) {
  //     this.generate(item);
  //   }
  // }

  // generate(item: any) {
  //   //const templateRecord=this.templateSettingList.filter((o: any) => o.id.toString() === tempId.toString());
  //   const templateRecord=this.templateSettingList.find(o => o.id.toString() === item.tempId.toString());
  //   const templateText = templateRecord.templateText;
  //   const pdf = new jspdf('p', 'mm', 'a4');
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = pdf.internal.pageSize.getHeight();

  //   const div = document.createElement('div');
  //   div.innerHTML = templateText;
  //   const clonedDiv = div.cloneNode(true) as HTMLDivElement;
  //   //document.body.appendChild(div);
  //   html2canvas(div).then(canvas => {
  //     const imgData = canvas.toDataURL('image/png');
  //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //     pdf.save(item.StudentId + '.pdf');
  //   })
  // }

  public convetToPDF() {
        let DATA: any = document.getElementById("StudentCertificatePrint");
        debugger;
        const margins = {
          top: 60,
          left: 40,
          bottom: 60,
          width: 500,
          right: 40,
        };
        let doc = new jsPDF("p", "pt", "a4");
        doc.fromHTML( DATA.innerHTML, margins.left, margins.top, { width: margins.width },
          function () {
            doc.save("export.pdf");
          }
        );

    // html2canvas(DATA).then((canvas) => {
    //   let fileWidth = 208;
    //   let fileHeight = (canvas.height * fileWidth) / canvas.width;
    //   const FILEURI = canvas.toDataURL('image/png');
    //   let PDF = new jsPDF('p', 'mm', 'a4');
    //   let position = 0;
    //   PDF.addImage(FILEURI, 'PNG', 10, position, fileWidth-20, fileHeight);
    //   PDF.save(this.StudentCertificateId + '.pdf');
    // });
  }

  getTeamplateParameters() {
    this.templateParameterFilteredList = this.templateParameters = [
      {
        id: 1,
        name: "Admission No",
        checked: false,
        dbMappedColumn: "AdmissionNo",
      },
      {
        id: 1,
        name: "Admission Date",
        checked: false,
        dbMappedColumn: "AdmissionDate",
      },
      {
        id: 2,
        name: "Student Name",
        checked: false,
        dbMappedColumn: "StudentName",
      },
      {
        id: 3,
        name: "Academic Year",
        checked: false,
        dbMappedColumn: "AcademicYearId",
      },
      {
        id: 4,
        name: "Class",
        checked: false,
        dbMappedColumn: "ClassCourseName",
      },
      { id: 5, name: "Batch", checked: false, dbMappedColumn: "BatchName" },
      { id: 6, name: "Stream", checked: false, dbMappedColumn: "StreamName" },
      { id: 7, name: "Date of Birth", checked: false, dbMappedColumn: "DOB" },
      { id: 8, name: "Gender", checked: false, dbMappedColumn: "Gender" },
      {
        id: 9,
        name: "SMS Mob No",
        checked: false,
        dbMappedColumn: "SMSMobNumber",
      },
      {
        id: 10,
        name: "WhatsApp No",
        checked: false,
        dbMappedColumn: "WhatsAppNo",
      },
      { id: 11, name: "Email ID", checked: false, dbMappedColumn: "EmailId" },
      {
        id: 12,
        name: "Aadhar No",
        checked: false,
        dbMappedColumn: "AdharNumber",
      },
      {
        id: 13,
        name: "House Name",
        checked: false,
        dbMappedColumn: "HouseName",
      },
      {
        id: 14,
        name: "Father's Name",
        checked: false,
        dbMappedColumn: "FatherName",
      },
      {
        id: 15,
        name: "Father's Email Id",
        checked: false,
        dbMappedColumn: "FatherEmailId",
      },
      {
        id: 16,
        name: "Father's Mobile No",
        checked: false,
        dbMappedColumn: "FatherMobileNo",
      },
      {
        id: 17,
        name: "Mother's Name",
        checked: false,
        dbMappedColumn: "MotherFullName",
      },
      { id: 18, name: "Login Id", checked: false, dbMappedColumn: "LoginId" },
      { id: 19, name: "Password", checked: false, dbMappedColumn: "Password" },
    ];
  }

}
