import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Employee, EmployeePermission} from './user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }
  // Academic Year start
  getAll(): Observable<Employee[]> {
    return this.httpClient.get<Employee[]>(environment.apiUrl + '/employee/')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getEmployeesWithTicketCount(): Observable<Employee[]> {
    return this.httpClient.get<Employee[]>(environment.apiUrl + '/employee/GetEmployeesWithTicketCount')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getById(id): Observable<Employee> {
    return this.httpClient.get<Employee>(environment.apiUrl + '/employee/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  generateEmployeeCode() {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(environment.apiUrl + '/employee/GenerateEmployeeCode', {responseType: 'text'})
    .pipe(
      catchError(this.errorHandler)
    );
  }

  generateUserId() {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(environment.apiUrl + '/membership/GenerateUserId', {responseType: 'text'})
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getEmployeePermission(employeeId): Observable<EmployeePermission[]> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<EmployeePermission[]>(environment.apiUrl + '/employee/GetEmployeePermission/' + employeeId)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  create(employee): Observable<Employee> {
  return this.httpClient.post<Employee>(environment.apiUrl + '/employee/', JSON.stringify(employee), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, employee): Observable<Employee> {
    return this.httpClient.put<Employee>(environment.apiUrl + '/employee/' + id, JSON.stringify(employee), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id) {
    return this.httpClient.delete<Employee>(environment.apiUrl + '/employee/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
