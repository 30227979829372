import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ExamremarksComponent} from './examremarks/examremarks.component';


const routes: Routes = [
  {
    path:'examremarks', component: ExamremarksComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExamremarksRoutingModule { }
