import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {ApplicantRegistrationService} from '../applicantregistration/applicantregistration.service';
import { ApplicantRegistration } from '../applicantregistration/applicantregistration.model';
import { ClassCourse } from '../mastersettings/models/classcourse';
import { ClassCourseService } from '../mastersettings/classcourse/classcourse.service';
declare var $: any;

@Component({
  selector: 'app-setentrancedate',
  templateUrl: './setentrancedate.component.html',
  styleUrls: ['./setentrancedate.component.css']
})
export class SetentrancedateComponent implements OnInit {

  applicantRegistrations: ApplicantRegistration[] = [];
  registrationfilteredlist: ApplicantRegistration[] = [];
  registrationSearchList: ApplicantRegistration[] = [];
  checkedRegistrationList: number[] = [];
  classcourses: ClassCourse[] = [];
  classcourseid = '0';
  tempDate: any;
  entranceDate: string;
  entranceTime: string;
  status = 'Select Status';

  constructor(
    private registrationService: ApplicantRegistrationService,
    private classcourseService: ClassCourseService,
    private toastr: ToastrService
  ) { }

  ListApplicantRegistration() {
    this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
      console.log(data);
      this.applicantRegistrations = data;
      if (this.classcourseid !== '0') {
        this.registrationfilteredlist = this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) =>
        applicantRegistration.classCourseId.toString().toLocaleLowerCase().includes(this.classcourseid.toLocaleLowerCase()));
      }
    });
  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classcourses = data;
    });
  }

  onClassSelected(value: string) {
    console.log(value);
    if (value === '-1') { return; }
    if (value === '0') {
      // this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
        // console.log(data);
        this.registrationfilteredlist = this.applicantRegistrations; // = data;
      // });
    } else {
     // this.registrationService.getAll().subscribe((data: ApplicantRegistration[]) => {
        this.registrationfilteredlist = this.applicantRegistrations.filter((applicantRegistration: ApplicantRegistration) =>
        applicantRegistration.classCourseId.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      // });
    }
  }

  // onStatusSelected(value: string) {
  //   console.log(value);
  //   if (value === 'Select Status') { return; }
  //   if (value === 'All') {
  //       this.registrationfilteredlist = this.applicantRegistrations;
  //   } else {
  //     this.registrationSearchList = this.registrationfilteredlist = this.applicantRegistrations
  //     .filter((applicantRegistration: ApplicantRegistration) =>
  //     applicantRegistration.applicationStatus.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()));
  //   }
  // }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
    if ( keyword === '') {
      if (this.registrationSearchList.length > 0) {
        this.registrationfilteredlist = this.registrationSearchList;
      } else {
        this.registrationfilteredlist = this.applicantRegistrations;
      }
    } else {
      if (this.registrationSearchList.length > 0) {
        this.registrationfilteredlist = this.registrationSearchList
                                .filter((applicantRegistration: ApplicantRegistration) =>
                                applicantRegistration.studentName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
      } else {
        this.registrationfilteredlist = this.applicantRegistrations
                                .filter((applicantRegistration: ApplicantRegistration) =>
                                applicantRegistration.studentName.toLocaleLowerCase()
                                .includes(keyword.toLocaleLowerCase()));
      }
    }
  }

  validateDate(entDate: string) {
    const specificDate = new Date(entDate);
    const currentDate = new Date();
    if (currentDate.getTime() > specificDate.getTime()) {
      return true;
    } else {
      return false;
    }
  }

  get totalRows(): number {
    return this.registrationfilteredlist.length;
  }

  checkAllCheckBox(ev) {
	  this.registrationfilteredlist.forEach(x => x.checked = ev.target.checked);
	}

	isAllCheckBoxChecked() {
		return this.registrationfilteredlist.every(p => p.checked);
	}

  checkCurrentCheckBox(ev) {
  // tslint:disable-next-line:indent
    this.registrationfilteredlist.forEach(x => x.checked = ev.target.checked);
  }
  

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {month = '0' + month; }
    if (day.length < 2) {day = '0' + day; }
    return [day, month, year].join('-');
  }

  submitForm() {
    this.checkedRegistrationList = [];
    this.registrationfilteredlist.forEach(x => {
      if (x.checked) {
        this.checkedRegistrationList.push(x.id);
      }
    });

    if (this.validateDate(this.tempDate)) {
      this.toastr.warning('Please check entrance date', 'WARN!', {
        timeOut: 3000
      });
      return;
    }

    if (this.checkedRegistrationList.length === 0) {
      this.toastr.warning('Select Applicant', 'WARN!', {
        timeOut: 3000
      });
      return;
    }

    this.registrationService.setentrancedate(this.checkedRegistrationList,
        this.entranceDate, this.entranceTime).subscribe(res => {
          
        this.ListApplicantRegistration();
        this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
          timeOut: 3000
        });
    });
  }

  resetForm() {
    this.checkedRegistrationList = [];
    this.entranceDate = '';
    this.entranceTime = '';
  }

  ngOnInit(): void {
    this.ListClassCourse();
    this.ListApplicantRegistration();

    $('#entranceDate').datepicker().on('changeDate', (e) => {
      this.tempDate = e.date;
      this.entranceDate = this.formatDate(e.date);
    });
  }

}
