import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LeadCreationService} from './leadcreation.service';
import { LeadCreationModel } from './leadcreation.model';

@Component({
  selector: 'app-managelead',
  templateUrl: './managelead.component.html',
  styleUrls: ['./managelead.component.css']
})
export class ManageleadComponent implements OnInit {

  @ViewChild('closeBtn') closeBtn: ElementRef;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
   };

   leadCreationForm: UntypedFormGroup;
   leadcreationServiceList: LeadCreationModel[] = [];
   leadCreationFilteredList: LeadCreationModel[] = [];
   submitted = false;
  constructor(
    public leadCreationService: LeadCreationService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
  ) { }

  leadlist() {
    this.leadCreationService.getAll().subscribe((data: LeadCreationModel[]) => {
      this.leadCreationFilteredList = this.leadcreationServiceList = data;
    });

  }
 
  createLead() {
    this.submitted = true;
   // stop here if form is invalid
    if (this.leadCreationForm.invalid) {
      return;
    }

    if (this.leadCreationForm.get('id').value === 0) {
        this.leadCreationService.create(this.leadCreationForm.value).subscribe(res => {
          this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
          this.closeModal();
        });
      } else {
        this.leadCreationService.update(this.leadCreationForm.get('id').value, this.leadCreationForm.value).subscribe(res => {
          this.toastr.success('Records has been sucessfully updated', 'SUCCESS!', {
            timeOut: 3000
          });
          this.submitted = false;
          this.resetForm();
          this.closeModal();
         });
      }
}
get f() { return this.leadCreationForm.controls; }
resetForm() {
  this.submitted = false;
  this.leadCreationForm = this.fb.group({
    id: [0],
    leadcode: ['1001', Validators.required],
    leaddate: [''],
    enquiredBy: ['', Validators.required],
    institutionName: ['', Validators.required],
    location: ['', Validators.required],
    institutionType: ['', Validators.required],
    affiliationType: ['', Validators.required],
    affiliationCode: ['', Validators.required],
    medium: ['', Validators.required],
    contactPerson: ['', Validators.required],
    mobileNo: ['', Validators.required],
    emailId: ['', Validators.required],
    demobyemployeeid: ['', Validators.required],
    remarks: ['', Validators.required],
    status: ['true', Validators.required],
    demodate: ['']
  });

}
  ngOnInit(): void {
    this.leadlist();
    this.leadCreationForm = this.fb.group({
      id: [0],
      leadcode: ['1001', Validators.required],
      leaddate: [''],
      enquiredBy: ['', Validators.required],
      institutionName: ['', Validators.required],
      location: ['', Validators.required],
      institutionType: ['', Validators.required],
      affiliationType: ['', Validators.required],
      affiliationCode: ['', Validators.required],
      medium: ['', Validators.required],
      status: [true, Validators.required],
      contactPerson: ['', Validators.required],
      mobileNo: ['', Validators.required],
      emailId: ['', Validators.required],
      demoTime: ['', Validators.required],
      demodate: [''],
      demobyemployeeid: ['', Validators.required],
      remarks: ['', Validators.required],

    });
  }

  private closeModal(): void {
    this.closeBtn.nativeElement.click();
  }

}
