// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*table {
    /* display: block;
    height: 400px;
    overflow-y: auto;
    width: 100% !important; */
    /* th { position: sticky; top: 0; z-index: 1; } 
  }*/

  .table-responsive {
    max-height:450px;
}
  thead {
    position: sticky;
    top: -1px;
    background-color: white;
  }`, "",{"version":3,"sources":["webpack://./src/app/admin/academic/feemanagement/feemanagementreports/classwisefeesummary/classwisefeesummary.component.css"],"names":[],"mappings":"AAAA;;;;6BAI6B;IACzB;IACA;;EAEF;IACE,gBAAgB;AACpB;EACE;IACE,gBAAgB;IAChB,SAAS;IACT,uBAAuB;EACzB","sourcesContent":["/*table {\r\n    /* display: block;\r\n    height: 400px;\r\n    overflow-y: auto;\r\n    width: 100% !important; */\r\n    /* th { position: sticky; top: 0; z-index: 1; } \r\n  }*/\r\n\r\n  .table-responsive {\r\n    max-height:450px;\r\n}\r\n  thead {\r\n    position: sticky;\r\n    top: -1px;\r\n    background-color: white;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
