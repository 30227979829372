import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ParentchildComponent } from './parentchild/parentchild.component';
import { ParentChildRoutingModule } from './parentchild-routing.module';



@NgModule({
  declarations: [DashboardComponent, ParentchildComponent],
  imports: [
    CommonModule,
    ParentChildRoutingModule
  ]
})
export class ParentchildModule { }
