import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { ClassCourse } from '../../mastersettings/models/classcourse';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClassCourseService } from '../../mastersettings/classcourse/classcourse.service';
import { Batch } from '../../mastersettings/models/batch';
import { BatchService } from '../../mastersettings/batch/batch.service';
import { FeeHeadService } from './../feehead/feehead.service';
import { FeeHead } from './../feehead/feehead.model';
import { Student } from './../../studentmanagement/student.model';
import { StudentService } from './../../studentmanagement/student.service';
import { InstallmentService } from './../installment/installment.service';
import { Installment } from './../installment/installment.model';
import { AssignSpecialFeeService } from './assignspecialfee.service';
import { AssignSpecialFee,FeeCollectionInstallment } from './assignspecialfee.model';
//import { resourceUsage } from 'process';
@Component({
  selector: 'app-assignspecialfee',
  templateUrl: './assignspecialfee.component.html',
  styleUrls: ['./assignspecialfee.component.css']
})
export class AssignspecialfeeComponent implements OnInit {

  classes: ClassCourse[] = [];
  batches: Batch[] = [];
  admissions: Student[] = [];
  admissionsfilteredlist: Student[] = [];
  filtredStudent:Student[]=[];
  feeHeads: FeeHead[] = [];
  feeHeadsList: any[] = [];
  feeHeadsToDelete: any[] = [];
  specialFees: AssignSpecialFee[] = [];
  FeeCollectionInstallment:FeeCollectionInstallment[]=[];
  selectedSpecialFees: AssignSpecialFee[] = [];
  assignedSpecialFeesForStudent: AssignSpecialFee[] = [];
  installments: Installment[] = [];
  assignedFeeHeads: AssignSpecialFee[] = [];
  selectedFeeHead = '';
  selectedinstallments='';
  classId = '';
  stypeId='';
  isAssigned = false;
  HighlightRow : Number;
  HighlightRoww : Number;
  ClickedRow:any;
  isLoading: boolean;
  academicYearId:string;
  installmentsFilterList: Installment[] = [];
  installmentsList: any[] = [];
  paymentInstallments: Installment[] = [];
  batchId: string;
  installName:string='';
  feeHeadName:string='';
  feestatus:string='';
  assignspecialfee: UntypedFormGroup;
  @ViewChild('closebutton') closebutton;

  constructor(
    private assignSpecialFeeService: AssignSpecialFeeService,
    public installmentService: InstallmentService,
    public studentService: StudentService,
    public feeHeadService: FeeHeadService,
    public classcourseService: ClassCourseService,
    public batchService: BatchService,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  ListStudents() {
    this.studentService.getAll().subscribe((data: Student[]) => {
      this.admissions = data;
    });
  }
onHideCheckStudentAssignement(admissonId:string):boolean{
  if(this.specialFees.filter(x=>x.admissionId==admissonId).length!==0){
    return true;
  }
  return false;
}


  ListAssignSpecialFees() {
    this.assignSpecialFeeService.getAll().subscribe((data: AssignSpecialFee[]) => {
      this.specialFees = data;
      this.assignedSpecialFeesForStudent=data;
    });

  }

  ListClassCourse() {
    this.classcourseService.getAll().subscribe((data: ClassCourse[]) => {
      this.classes = data;
    });
  }

  ListFeeCollectionInstallmentDetail(admissionId) {
    this.assignSpecialFeeService.getFeeCollectionInstallmentDetails(admissionId).subscribe((data: FeeCollectionInstallment[]) => {
      this.FeeCollectionInstallment = data;
    });
  }

  ListPaymentDetails(admissionId) {
    this.installmentService.getFeePayment(admissionId).subscribe((inst: Installment[]) => {
      this.paymentInstallments = inst;
    });
  }
  

  ListFeeHead() {
    this.feeHeadService.getSpecialFeeHeads().subscribe((data: FeeHead[]) => {
      this.feeHeads = data;
      this.feeHeadsList = data;
      this.feeHeadsToDelete= data;
      this.ListInstallment();
      console.log("Fee Heads: "+this.feeHeadsList)
    });
  }

  getFeeHeadName(feeHeadid: string) {
    this.feeHeadName='';
    this.feeHeads.forEach((y) => {
      if (y.id.toString() == feeHeadid.toString()) {
        this.feeHeadName=y.feeHeadName;
      } 
     });
    return  this.feeHeadName
  }

  ListInstallment() {
    this.installmentService.getAll().subscribe((data: Installment[]) => {
      this.installmentsFilterList=this.installments = data;
      if (this.assignspecialfee?.controls['stypeId']?.value === '') {
        this.installmentsFilterList=this.installments;
      } else {      
          this.installmentsFilterList = this.installments
          .filter((installment: Installment) => installment.stypeId === this.assignspecialfee?.controls['stypeId']?.value);
      }
      this.feeHeadsList.forEach((y) => {
        let newArray = this.installmentsFilterList.map(obj => ({
        //let newArray = this.paymentInstallments.map(obj => ({
          ...obj,           // Copy existing object properties
          feeHeadIds: y.id
        }));
        y.installmentsList = newArray;
      });
      // let rows: any[] = [];
      // rows = this.installmentsFilterList;
      // for (let i = 0; i < this.feeHeadsList.length; i++) {
      //   let newArray = rows.map(obj => ({
      //     ...obj,           // Copy existing object properties
      //     feeHeadIds: this.feeHeadsList[i].id
      //   }));
      //   this.feeHeadsList[i].installmentsList = newArray;
      //   this.installmentsList = [];
      // }
      console.log("feeheads"+this.feeHeadsList);
    });
  }

  onFilterSelected(value){
    //this.ListInstallment();
    // if (value === '') {
    //   this.installmentsFilterList=this.installments;
    // } else {      
    //     this.installmentsFilterList = this.installments
    //     .filter((installment: Installment) => installment.stypeId === value);
    // }
    if (value === '') {
      this.resetForm();
    } else {
      this.resetFormOnStudentType(value);
    }
  }

  getinstallmentName(installmentid: string){
    this.installName='';
    this.installments.forEach((y) => {
      if (y.id.toString() == installmentid.toString()) {
        this.installName=y.installmentName;
      } 
    });
    return  this.installName
  }

  onClassSelected(value) {
    //this.resetForm();
    this.classId = value;
    this.batchService.getAll().subscribe((data: Batch[]) => {
      this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
    });
  }

  onStudentTypeSelected(value: string) {
    // this.resetForm();
    // this.classId = value;
    // this.batchService.getAll().subscribe((data: Batch[]) => {
    //   this.batches = data.filter((batch: Batch) => batch.classId.toString() === value.toString());
    // });
  }

  onBatchChanged(value) {
    this.batchId=value;
    if(value!=''){
      this.isLoading=true;
      this.ListFeeHead();
      //this.ListInstallment();
 //alert(this.assignspecialfee?.controls['stypeId']?.value);
      this.studentService.getAll().subscribe((data: Student[]) => {
        /*this.feeHeadService.getSpecialByClassId(this.classId).subscribe((data: FeeHead[]) => {
          console.log(data);
          this.feeHeads = data;
        });*/
        console.log("rrr  "+data);
        this.admissionsfilteredlist = this.admissions = data.filter((student: Student) => {
        return student.classCourseId.toString() === this.classId 
        &&  student.batchId.toString() === value.toString() && student.studentType===this.assignspecialfee?.controls['stypeId']?.value;
        });
        this.academicYearId = data[0].academicYearId;
        //console.log(this.specialFees);
        console.log(this.admissionsfilteredlist);
        this.admissionsfilteredlist.forEach((x) => {
          //console.log(x.id);
          this.specialFees.forEach((y) => {
             //console.log(y.admissionId);
             if (x.id.toString() == y.admissionId.toString()) {
               x.isAssigned = true;
             } 
          });
        });
        this.isLoading=false;
      });
    }

  }

  onStudentSelect(studId: string,index: number) {    
    //console.log(studId);
    debugger;
    this.assignedFeeHeads = [];
    this.filtredStudent=[];  
    this.HighlightRow = index;
    //this.HighlightRoww = index;
    //this.ListFeeCollectionInstallmentDetail(studId);
    //this.ListPaymentDetails(studId);
    this.paymentInstallments=[];
    this.installmentService.getFeePayment(studId).subscribe((inst: Installment[]) => {
      this.paymentInstallments = inst;
      /* this.paymentInstallments.forEach((inst)=>
      {
        if(inst.isWaved || inst.isPaid){
          inst.disabled=true;
        }
      }); */
      this.installmentsFilterList.forEach((x) => {
        this.FeeCollectionInstallment.forEach((y) => {
          if (x.id.toString() == y.InstallmentId.toString()) {
            x.isWaved = true;
            this.feestatus = 'Waved Off';
          } 
        });
      });
debugger;
      this.feeHeadsList.forEach((y) => {
        y.installmentsList.forEach((x)=>{
            this.paymentInstallments.forEach((z)=>{
                if(z.id==x.id){
                  x.isPaid=z.isPaid;
                  x.isWaved=z.isWaved;
                }
            });
        })
        
      }); 
      console.log("afterclick"+this.feeHeadsList);

      this.feeHeadsToDelete.forEach(x=>{
        x.checked=false;
        x.amount='0';
        x.installmentsList.forEach(xx => {           
          xx.checked = false;
          this.paymentInstallments.forEach((y) => {
            if (y.id.toString() === xx.id.toString()) {
              if (y.isPaid || y.isWaved) {
                xx.disabled = true;
                if(y.isPaid){
                  this.feestatus = 'Paid';
                  y.status = 'P'
                }
                if(y.isWaved)
                 this.feestatus = 'Waved Off';
              } else {
                xx.disabled = false;
                this.feestatus = '';
              }
            }
          });
        });
      });

      // this.installments.forEach(x=>{
      //   x.checked=false;
      // });
      // this.installmentsFilterList.forEach(x=>{
      //   x.checked=false;
      // });

      this.filtredStudent= this.admissionsfilteredlist.filter(x=>x.id.toString()== studId.toString());
      const studs = this.assignedSpecialFeesForStudent.filter((asf: AssignSpecialFee) => asf.admissionId.toString() === studId.toString());
      studs.forEach((s)=>{
        this.feeHeadsToDelete.forEach((y) => {
          if (s.feeHeadIds === y.id.toString()) {
            y.amount=s.amount.toString();
            const fee:AssignSpecialFee ={} as AssignSpecialFee;
            fee.amount=s.amount;
            fee.id=s.id;
            fee.feeHeadIds=s.feeHeadIds;
            fee.admissionId=s.admissionId;
            fee.feehead=y;
            this.assignedFeeHeads.push(fee);
            this.installments.forEach(xx => {           
                if (xx.id.toString() === s.installmentId.toString()) {
                  xx.checked = true;
                }            
            });
            this.installmentsFilterList.forEach(xx => {           
              if (xx.id.toString() === s.installmentId.toString()) {
                xx.checked = true;
              }            
            });
            y.installmentsList.forEach(xx => {
              if (xx.id.toString() === s.installmentId.toString()) {
                xx.checked = true;
              }
            });
            this.feeHeadsToDelete.forEach(x => {  
              if (x.id.toString() === s.feeHeadIds) {
                x.checked = true;
              }            
            });
          }
        });
      });

      /* this.installmentsList.forEach((x) => {
        x.installmentsList.forEach((y) => {
          if (x.id.toString() == y.InstallmentId.toString()) {
            x.isWaved = true;
            this.feestatus = 'Waved Off';
          } 
        });
      }); */
    });
  }

  /*onInstaSelect(studId: string) {
    console.log(studId);
    this.assignedFeeHeads = [];
    const studs = this.specialFees.filter((asf: AssignSpecialFee) => asf.admissionId.toString() === studId.toString())[0];
    const xfeeHeads = studs.feeHeadIds.split('|');
    console.log(xfeeHeads);
    xfeeHeads.forEach((x) => {
      this.feeHeads.forEach((y) => {
          if (x === y.id.toString()) {
            this.assignedFeeHeads.push(y);
          }
      });
    });
  }*/

  checkAllFeeHeadCheckBox(ev) {
    //this.feeHeads.forEach(x => x.checked = ev.target.checked);
    this.feeHeadsList.forEach(x => x.checked = ev.target.checked);
  }

  checkAllInstallmentCheckBox(event, obj) {
    //this.feeHeadsList.forEach(x => x.checked = event.target.checked);
    obj.installmentsList.forEach(x => x.checked = event.target.checked);
    
  }

  feeHeadCheckboxClick(event) {
    event.stopPropagation();
  }

  isAllFeeHeadCheckBoxChecked() {
    //return this.feeHeads.every(p => p.checked);
    return this.feeHeadsList.every(p => p.checked);
  }
  checkAllfeeToDeleteheckBox(ev) {
    this.assignedFeeHeads.forEach(x => x.checked = ev.target.checked);
  }

  isAllfeeToDeleteChecked() {
    return this.assignedFeeHeads.every(p => p.checked);
  }
  checkAllStudentCheckBox(ev) {
    this.admissionsfilteredlist.forEach(x => x.checked = ev.target.checked);
  }

  isAllStudentCheckBoxChecked() {
    return this.admissionsfilteredlist.every(p => p.checked);
  }

  get totalRows(): number {
    return this.admissionsfilteredlist.length;
  }

  onKeyUpEvent(event: any) {
    this.filter(event.target.value);
  }

  filter(keyword: any) {
      if ( keyword === '') {
        this.admissionsfilteredlist = this.admissions;
      } else {
      this.admissionsfilteredlist = this.admissions
                                  .filter((admission: Student) =>
                                  admission.studentName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.admissionNo.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                                  admission.rollNo === keyword
                                  );
      }
  }

  resetFormOnStudentType(value) {
    this.classId = '';
    this.batches = [];
    this.feeHeads = [];
    this.feeHeadsList = [];
    this.admissions = [];
    this.admissionsfilteredlist = [];
    this.filtredStudent=[];
    this.HighlightRow=-1;
    this.installmentsFilterList = [];
    this.selectedFeeHead = '';
    this.ListAssignSpecialFees();
    this.assignspecialfee = this.fb.group({
      id: [0],
      batchid: ['', Validators.required],
      classId: ['', Validators.required],
      stypeId: ['', Validators.required],
      admissionId: ['', Validators.required]
    });
    this.assignspecialfee.get('stypeId').setValue(value);
  }
  resetForm() {
    this.classId = '';
    this.batches = [];
    this.feeHeads = [];
    this.feeHeadsList = [];
    this.admissions = [];
    this.admissionsfilteredlist = [];
    this.filtredStudent=[];
    //this.installments = [];
    this.HighlightRow=-1;
    this.installmentsFilterList = [];
    this.selectedFeeHead = '';
    this.ListAssignSpecialFees();
    this.assignspecialfee = this.fb.group({
      id: [0],
      batchid: ['', Validators.required],
      classId: ['', Validators.required],
      stypeId:['', Validators.required],
      admissionId:['', Validators.required]
    });
  }
  onDelete() {
    if(this.assignedFeeHeads == null)
    {
      this.toastr.error('No record found', 'Error!', {
        timeOut: 3000
      });
      return;
    }
    const assignIds:number[]=[];
    this.assignedFeeHeads.filter(x=>x.checked).forEach((x)=>{
      assignIds.push(x.id);
    });
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       this.assignSpecialFeeService.deleteList(assignIds).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your record has been deleted.',
            'success'
          );
          this.closebutton.nativeElement.click();
          this.resetForm();
          //this.installments = [];
          this.installmentsFilterList = [];
        })
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your record is safe :)',
          'error'
        );
      }
    });
  }
  saveSpecialFee() {
    debugger;
    //console.log(this.feeHeads.filter(x => x.checked).length);
    // console.log(this.feeHeads.filter(x => x.checked).length);
    if (this.feeHeads.filter(x => x.checked).length === 0) {
      this.toastr.warning('Please select fee head and admission', 'WARN!', {
        timeOut: 3000
      });
      return;
    }

    for (let k = 0; k < this.feeHeadsList.length; k++) {
      if(this.feeHeadsList[k].checked) {
        if (this.feeHeadsList[k].amount <= 0 ) {
          this.toastr.warning('Please enter amount for selected fee head !', 'WARN!', {
            timeOut: 3000
          });
          return;
        }
        if (this.feeHeadsList[k].installmentsList.filter(x => x.checked).length === 0) {
          this.toastr.warning('Please select installments !', 'WARN!', {
            timeOut: 3000
          });
          return;
        } else {
          if (this.feeHeadsList[k].installmentsList.filter(x => x.disabled).length === this.feeHeadsList[k].installmentsList.filter(x => x.checked).length) {
            this.toastr.warning('Please select installments !', 'WARN!', {
              timeOut: 3000
            });
            return;
          }
        }
      } else {
        if (this.feeHeadsList[k].amount > 0 ) {
          this.toastr.warning('Please select fee head which having amount values.', 'WARN!', {
            timeOut: 3000
          });
          return;
        }
      }
    }

    // if (this.installments.filter(x => x.checked).length === 0) {
    //   this.toastr.warning('Please select installments !', 'WARN!', {
    //     timeOut: 3000
    //   });
    //   return;
    // }

    this.selectedSpecialFees=[];
    this.selectedFeeHead = '';
    this.selectedinstallments = '';
    
   /* filtredStudent.forEach((y) => {
      if (y.checked) {
        const assignSpecialFee: AssignSpecialFee = {} as AssignSpecialFee;
        assignSpecialFee.admissionId = y.id.toString();
        this.selectedFeeHead = '';
        //let k = 1;
        //const totalSelected = this.feeHeads.filter(x => x.checked).length;
       /* this.feeHeads.forEach((x, i) => {
          if (x.checked) {
            /*if (totalSelected !== k) {
              this.selectedFeeHead += x.id.toString() + '|';
              assignSpecialFee.amount=parseFloat(x.amount);
              assignSpecialFee.academicYearId=parseInt(this.academicYearId);
            } else {
              this.selectedFeeHead += x.id.toString();
              assignSpecialFee.amount=parseFloat(x.amount);
              assignSpecialFee.academicYearId=parseInt(this.academicYearId);
            }
            k++;
          }
        }
        });*/
       /* for (let index = 0; index < this.feeHeads.length; index++) {
          const assignSpecialFee: AssignSpecialFee = {} as AssignSpecialFee;
          const element = this.feeHeads[index];
        
        filtredStudent.forEach((y) => {
          if(element.checked){
            assignSpecialFee.admissionId = y.id.toString();
            assignSpecialFee.amount=parseFloat(element.amount);
            assignSpecialFee.feeHeadIds=element.id.toString();
                assignSpecialFee.academicYearId=parseInt(this.academicYearId);
                this.selectedSpecialFees.push(assignSpecialFee);
          }
        });
        
          
        //assignSpecialFee.feeHeadIds = this.selectedFeeHead;
        //this.selectedSpecialFees.push(assignSpecialFee);
      }
    }
    });*/
    // let kk = 1;
    // const totalSelected = this.installmentsFilterList.filter(x => x.checked).length;
    // this.installmentsFilterList.forEach((x, i) => {
    //   if (x.checked) {
        
    //       for (let index = 0; index < this.feeHeads.length; index++) {
    //         const element = this.feeHeads[index];
    //       for (let k = 0; k < this.filtredStudent.length; k++) {
    //         const student = this.filtredStudent[k];
    //         if(element.checked){
    //           const assignSpecialFee={} as AssignSpecialFee;
    //           assignSpecialFee.amount=parseFloat(element.amount);
    //           assignSpecialFee.feeHeadIds=element.id.toString();
    //           assignSpecialFee.admissionId = student.id.toString();
    //           assignSpecialFee.academicYearId=parseInt(this.academicYearId);
    //           assignSpecialFee.installmentId=x.id.toString();
    //           this.selectedSpecialFees.push(assignSpecialFee);
    //         }      
    //       }
    //     }
    //     kk++;
    //   }
    // });
    //assignSpecialFee.feeHeadIds = this.selectedFeeHead;
    //this.selectedSpecialFees.push(assignSpecialFee);
    this.feeHeadsList.forEach((y) => {
      y.installmentsList.forEach((x) => {
        if (x.checked) {
          for (let k = 0; k < this.filtredStudent.length; k++) {
            const student = this.filtredStudent[k];
            if(true){
              const assignSpecialFee={} as AssignSpecialFee;
              assignSpecialFee.amount=parseFloat(y.amount);
              assignSpecialFee.feeHeadIds=y.id.toString();
              assignSpecialFee.admissionId = student.id.toString();
              assignSpecialFee.academicYearId=parseInt(this.academicYearId);
              assignSpecialFee.installmentId=x.id.toString();
              this.selectedSpecialFees.push(assignSpecialFee);
            }
          }
        }
      });
    });

    //console.log(this.selectedSpecialFees);
    this.assignSpecialFeeService.create(this.selectedSpecialFees).subscribe(res => {
      //console.log('Special fee assigned!');
      this.toastr.success('Records has been sucessfully saved', 'SUCCESS!', {
        timeOut: 3000
      });
      this.resetForm();
     
    });
  }

  CSSApllyinstalmentTable(installment:any){
    if(installment.disabled) {
       if(installment.isPaid){
         this.feestatus = 'Paid';
         installment.status = 'Paid';
         return "bg-success text-white"
       }
       if(installment.isWaved){
        this.feestatus = 'Waved Off';
        installment.status = 'Waved Off';
        return "bg-danger text-white"
       }
      return "tdcolor";
    } else {
      //return "bg-danger text-white"
    }
  }
  ngOnInit(): void {
    this.ListAssignSpecialFees();
    this.ListClassCourse();
    
    this.assignspecialfee = this.fb.group({
      id: [0],
      batchid: ['', Validators.required],
      classId: ['', Validators.required],
      stypeId: ['', Validators.required],
      admissionId: ['', Validators.required],
    });
    // this.ListStudents();
  }

}
