import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { StreamingAPISetting } from '../lms-api-setting/model/StreamingAPISetting';
import { SessionPlannerService } from '../session-planner.service';

@Component({
  selector: 'app-lmssetting',
  templateUrl: './lmssetting.component.html',
  styleUrls: ['./lmssetting.component.css']
})
export class LMSSettingComponent implements OnInit {

  constructor(private coursePlannerService: SessionPlannerService, 
    private toastr: ToastrService, private formBuilder: UntypedFormBuilder) { }
    Id= 0;

    submitted: boolean;

  sessionPlannerForm: UntypedFormGroup;
  public lstStreamingAPI: StreamingAPISetting[] = new Array<StreamingAPISetting>();
  public lstAcademicStreamingAPI: StreamingAPISetting[] = new Array<StreamingAPISetting>();


  ngOnInit(): void {

    this.sessionPlannerForm = this.formBuilder.group({
      Id: 0,
      ProviderId: [, Validators.required],
      UserAccount: [, Validators.required],
      APIKey: "",
      APISecret: "",
      Status: true,
     
     });


      this.gettAllStreamingAPI();
      this.gettAllAcademicStreamingAPI();


  }

  public gettAllStreamingAPI(){

    this.coursePlannerService.getAllStreamingAPISetting().subscribe((response) => {
      ;
      this.lstStreamingAPI = response;


      
    });
  }

  public gettAllAcademicStreamingAPI(){

    this.coursePlannerService.getAllAcademicStreamingAPISetting().subscribe((response) => {
      ;
      this.lstAcademicStreamingAPI = response;


      
    });
  }

  public saveSessionPlanner() {

    ;
    this.submitted = true;

    // this.load.show();

  //  var sDate = this.sessionPlannerForm.get('sessionDate').value;

  //  var day = moment(this.formatDate(this.sessionDate));
    // var date = new Date(this.formatDate(this.sessionDate));
    // this.sessionPlannerForm.get('sessionDate').patchValue(date);

    // this.sessionPlannerForm.value
    if (this.sessionPlannerForm.valid) {
      if(this.Id == 0){
        
        //this.sessionPlannerForm.get('sessionDate').patchValue( sDate.year +"-"+ (sDate.month > 2 ? sDate.month : "0"+ sDate.month) +"-"+(sDate.day > 2 ? sDate.day : "0"+ sDate.day) );


        this.coursePlannerService.SaveAcademicStreamingAPISetting(this.sessionPlannerForm.value).subscribe((response) => {
          ;
          
        
          this.resetForm();
          this.toastr.success("Succesfully Added Course Plan", 'Success!',{timeOut: 1000});
        });
      }
      else{
       
      //  this.sessionPlannerForm.get('sessionDate').patchValue( sDate.year +"-"+ (sDate.month > 2 ? sDate.month : "0"+ sDate.month) +"-"+(sDate.day > 2 ? sDate.day : "0"+ sDate.day) );

        this.coursePlannerService.UpdateAcademicStreamingAPISetting(this.sessionPlannerForm.value).subscribe((response) => {
        
           ;
          
        
          this.resetForm();
          this.toastr.success("Succesfully Updated Session Plan", 'Success!',{timeOut: 1000});
        });
      }
    } 
  
  }


  public resetForm(){
    this.sessionPlannerForm = this.formBuilder.group({
      Id: 0,
      ProviderId: [, Validators.required],
      UserAccount: [, Validators.required],
      APIKey: "",
      APISecret: "",
      Status: true,
     
     });
  
  
      
  }

  get f() { return this.sessionPlannerForm.controls; }

  
  deleteForm(id: number) {

  }


  updateForm(id: number) {
 
   ;

    this.coursePlannerService.GetAcademicStreamingAPISettingById(id).subscribe((data: any) => {
  
    // this.GetAllSubjectByBatchID(data.batchId);
;
    ;
    this.Id= data.id;
      
    this.sessionPlannerForm = this.formBuilder.group({
      id: data.id,
      ProviderId: [data.providerId, Validators.required],
      UserAccount: [data.userAccount, Validators.required],
      APIKey: [data.apiKey, Validators.required],
      APISecret: [data.apiSecret, Validators.required],
      Status: true,
     
     });

  });
}

}
