import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BroadcastStatus, BroadcastTemplate } from './broadcast-report.model';
import { Credit } from '../../creditmanager/credit.model';



@Injectable({
    providedIn: 'root'
  })
  export class BroadcastReportService {
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         enctype: 'multipart/form-data'
      })
    };
    constructor(private httpClient: HttpClient) { }
// broadcast: BroadcastStatus[] = [{id:'1',name:'Ram Krishnan',contactNumber:'8567231230',msgStatus:true },
//                          {id:'2',name:'Manu M',contactNumber:'9067231230',msgStatus:false },
//                          {id:'3',name:'Ashik Abu',contactNumber:'9467231230',msgStatus:true },
//                          {id:'4',name:'Monisha S',contactNumber:'9967231230',msgStatus:false }];
broadcast: BroadcastStatus[] = [];
    getAllBroadastRecords(): Observable<BroadcastTemplate[]> {
      return this.httpClient.get<BroadcastTemplate[]>(environment.apiUrl + '/broadcast/GetAllBroadcastRecordsForReport/')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getAllServices(): Observable<Credit[]> {
        return this.httpClient.get<Credit[]>(environment.apiUrl + '/service/')
            .pipe(
                catchError(this.errorHandler)
            );
    }
    getAllMessageSentStatus(): Observable<BroadcastStatus[]> {
        return of(this.broadcast); /// THIS IS A HARD CODED ARRAY - Need to  change as API call
      }
    errorHandler(error) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }

  }
