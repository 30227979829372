// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#body{
    background-color: white;
}
.text-box {
    border: none !important;
    border-bottom: 0.09em solid #ccc !important;
    padding: 5px !important;
    width: 100% !important;
    box-sizing: border-box !important;
    font-size: 16px !important;
    border-radius: 0% !important;
    background-color: #ffff !important;
  }
  body {
              --body-background-color: #ffffff !important;
              background-color: var(--body-background-color) !important;
              transition: background-color 0.5s ease !important;
          }
          input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
      -webkit-transition: background-color 5000s;
      transition: background-color 5000s;
      /* -webkit-text-fill-color: #fff !important; */
      -webkit-text-fill-color: #000000 !important;
    }`, "",{"version":3,"sources":["webpack://./src/app/authentication/login/login.component.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;AACA;IACI,uBAAuB;IACvB,2CAA2C;IAC3C,uBAAuB;IACvB,sBAAsB;IACtB,iCAAiC;IACjC,0BAA0B;IAC1B,4BAA4B;IAC5B,kCAAkC;EACpC;EACA;cACY,2CAA2C;cAC3C,yDAAyD;cACzD,iDAAiD;UACrD;UACA;;;;MAIJ,0CAAkC;MAAlC,kCAAkC;MAClC,8CAA8C;MAC9C,2CAA2C;IAC7C","sourcesContent":["#body{\r\n    background-color: white;\r\n}\r\n.text-box {\r\n    border: none !important;\r\n    border-bottom: 0.09em solid #ccc !important;\r\n    padding: 5px !important;\r\n    width: 100% !important;\r\n    box-sizing: border-box !important;\r\n    font-size: 16px !important;\r\n    border-radius: 0% !important;\r\n    background-color: #ffff !important;\r\n  }\r\n  body {\r\n              --body-background-color: #ffffff !important;\r\n              background-color: var(--body-background-color) !important;\r\n              transition: background-color 0.5s ease !important;\r\n          }\r\n          input:-webkit-autofill,\r\n    input:-webkit-autofill:hover, \r\n    input:-webkit-autofill:focus, \r\n    input:-webkit-autofill:active  {\r\n      transition: background-color 5000s;\r\n      /* -webkit-text-fill-color: #fff !important; */\r\n      -webkit-text-fill-color: #000000 !important;\r\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
