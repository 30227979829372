import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PeriodComponent } from './period/period.component';
import { TimetableComponent } from './timetable/timetable.component';
import { WeekdayComponent } from './weekday/weekday.component';
import { ReportComponent } from './report/report.component';

const routes: Routes = [
  {path: 'timetable/period', component: PeriodComponent},
  {path: 'timetable/timetable', component: TimetableComponent},
  {path: 'timetable/weekday', component: WeekdayComponent},
  {path: 'timetable/report', component: ReportComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TimetableRoutingModule { }
