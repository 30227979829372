import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { SenderIDRequestService } from './senderid-request.service';
import { SenderIDRequest } from './senderid.model';
import { Service } from '../../creditmanager/service.model';

@Component({
  selector: 'app-sender-idrequest',
  templateUrl: './sender-idrequest.component.html',
  styleUrls: ['./sender-idrequest.component.css']
})
export class SenderIDRequestComponent implements OnInit {

  senerIDRequestList: SenderIDRequest[] = [];
  senderIDForm: UntypedFormGroup;
  submitted: boolean = false;
  serviceList: Service[] = [];
  constructor(private senderIDRequestService: SenderIDRequestService, private fb: UntypedFormBuilder, private toster: ToastrService) { }

  get f() {
    return this.senderIDForm.controls;
  }
  getAllSenderIDRequest() {
    this.senderIDRequestService.getAllSenderIDRequests().subscribe((data: SenderIDRequest[]) => {
      this.senerIDRequestList = data;
    });
  }
  getAllServices() {
    this.senderIDRequestService.getAllServices().subscribe((data: Service[]) => {
      console.log(data);
      this.serviceList = data;
    });
  }
  updateSenderIdRequest(Id: any) {
    this.resetForm();
    const selectedRequest = this.senerIDRequestList.find(element => element.id === Id);
    this.senderIDForm.get('senderId').patchValue(selectedRequest.senderId);
    this.senderIDForm.get('purpose').patchValue(selectedRequest.purpose);
    this.senderIDForm.get('id').patchValue(selectedRequest.id);
    this.senderIDForm.get('serviceId').patchValue(selectedRequest.serviceId);
    this.senderIDForm.get('requestStatus').patchValue(selectedRequest.requestStatus);
  }

  resetForm() {
    this.submitted = false;
    this.senderIDForm = this.fb.group({
      id: [0],
      senderId: ['', Validators.required],
      purpose: ['', Validators.required],
      serviceId: ['', Validators.required],
      requestStatus: ['Pending']
    });
  }
  submitForm() {
    this.submitted = true;
    if (this.senderIDForm.invalid) {
      // this.toster.warning('The form is not valid!', 'Warning!!', {
      //   timeOut: 3000
      // });
      return;
    }
    const senderId=this.senderIDForm.get('senderId').value.length;
    if(this.senderIDForm.get('serviceId').value==1 && (senderId<10 || senderId>10)){
      this.toster.warning('Invalid sender Id, should be of 10 digits only', 'Warning!!', {
        timeOut: 3000
      });
    }
    else if(this.senderIDForm.get('serviceId').value==2 && (senderId<6 || senderId>6)){
      this.toster.warning('Invalid sender Id, should be of 6 digits only', 'Warning!!', {
        timeOut: 3000
      });
    }
    else if ( this.senderIDForm.get('id').value === 0) {
      this.senderIDRequestService.create(this.senderIDForm.value).subscribe(result => {
        this.toster.success('The sender ID request has been saved successfully!', 'Success', {
          timeOut: 3000
        });
        this.getAllSenderIDRequest();
        this.resetForm();
      });
    } else {
      this.senderIDRequestService.update(this.senderIDForm.get('id').value, this.senderIDForm.value).subscribe(res => {
        this.toster.success('Sender request has been updated sucessfully!', 'SUCCESS!', {
          timeOut: 3000
        });
        this.getAllSenderIDRequest();
        this.getAllServices();
        this.resetForm();
      });
    }
    
  }
  deleteRequest(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.senderIDRequestService.delete(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'The senderID request has been deleted.',
            'success'
          );
          this.getAllSenderIDRequest();
        });
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      );
      }
    });
  }

  ngOnInit(): void {
    this.resetForm();
    this.getAllSenderIDRequest();
    this.getAllServices();
  }

}
